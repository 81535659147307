import {map} from 'lodash';

import faArrowAltUp from 'app/fontawesome-pro-light/faArrowAltUp';
import faArrowAltDown from 'app/fontawesome-pro-light/faArrowAltDown';
import faArrowAltRight from 'app/fontawesome-pro-light/faArrowAltRight';

export const STRATEGY_TREND = {
  UP: {
    key: 'Up',
    icon: faArrowAltUp,
    tooltipText: 'increased',
  },
  DOWN: {
    key: 'Down',
    icon: faArrowAltDown,
    tooltipText: 'decreased',
  },
  SAME: {
    key: 'Same',
    icon: faArrowAltRight,
    tooltipText: 'remained the same',
  },
};

export const STRATEGY_TRENDS = map(STRATEGY_TREND, value => value);
