import {Callback, Middleware} from './types';
import {CompanyTabsCard} from 'v2/components/organisms/TabsCards';
import {Container, Wrapper} from 'v2/components/atoms/Containers';
import {BackButton} from 'v2/components/molecules/BackButtons';
import {useCompanySummary} from 'v2/hooks/useCompanySummary';
import {CompanyInfoBar} from 'v2/components/organisms/CompanyInfoBar';
import {ROUTE} from 'v2/constants/routes';

const Layout: React.FC<{}> = ({children}) => {
  const {data: company, isLoading, isSuccess} = useCompanySummary();

  return (
    <Container>
      <Wrapper padding="1.5rem 0" margin="0 auto">
        <BackButton path={ROUTE.UT1.COMPANIES.INDEX.path}>Return to Companies</BackButton>
      </Wrapper>
      <CompanyInfoBar
        title={company.name}
        description={company.description}
        country={company.country}
        industry={company.industry}
        sector={company.sector}
        transparency={company.transparency}
        overall={company.overall}
        carbonNeutralPledgeSbtiStatus={company.carbonNeutralPledgeSbtiStatus}
        isEngaged={company.isEngaged}
        isLoading={isLoading && !isSuccess}
      />
      <CompanyTabsCard />
      {children}
    </Container>
  );
};

export const companiesInfoBar: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
