import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {compose, lifecycle, withHandlers, withProps, withState} from 'recompose';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import debounceHandlers from '@hocs/debounce-handler';

import faTimes from 'app/fontawesome-pro-light/faTimes';
import {VoidLink} from './VoidLink';

const SideDrawer = compose(
  withProps(() => ({
    onRef: React.createRef(),
  })),
  withState('isOpen', 'setIsOpen', ({isOpenByDefault}) => isOpenByDefault),
  withHandlers({
    close:
      ({setIsOpen}) =>
      () =>
        setIsOpen(false),
    open:
      ({setIsOpen}) =>
      () =>
        setIsOpen(true),
  }),
  debounceHandlers('close', 100),
  debounceHandlers('open', 100),
  withHandlers({
    handleWindowClick:
      ({close, isOpen, onRef}) =>
      e => {
        if (isOpen && !onRef.current.contains(e.target)) close();
      },
  }),
  lifecycle({
    componentDidMount() {
      const {open, exposeHandlers} = this.props;
      exposeHandlers && exposeHandlers({open});

      window.addEventListener('click', this.props.handleWindowClick);
    },
    componentWillUnmount() {
      window.removeEventListener('click', this.props.handleWindowClick);
    },
  }),
)(({children, close, footer, isOpen, position, onRef, title, titleIcon}) => (
  <div
    ref={onRef}
    className={cn('side-drawer', {
      [`is-${position}`]: position,
      'is-open': isOpen,
    })}
  >
    <div className="side-drawer-header">
      <div className="side-drawer-title">
        {titleIcon && <Icon icon={titleIcon} className="mr-3" />}
        {title}
      </div>
      <VoidLink onClick={close} className="side-drawer-close ml-8">
        <Icon icon={faTimes} /> Close
      </VoidLink>
    </div>
    <div className="side-drawer-content">{children}</div>
    {!!footer && <div className="side-drawer-footer">{footer}</div>}
  </div>
));

SideDrawer.propTypes = {
  children: PropTypes.node,
  exposeHandlers: PropTypes.func,
  footer: PropTypes.node,
  isOpenByDefault: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.node,
  titleIcon: PropTypes.object,
};

SideDrawer.defaultProps = {position: 'right'};

export {SideDrawer};
