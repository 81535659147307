import {useParams} from 'react-router';
import {useGetStrategyAggregatePercentileRankIndexesQuery} from 'v2/redux/typeormEndpoints';

export const useMarketIndexRating = () => {
  //@ts-ignore
  const {strategyId} = useParams();
  const {data: indexData, isLoading, isError} = useGetStrategyAggregatePercentileRankIndexesQuery({strategyId});

  const getMarketIndexPercentileRank = () => {
    let totalWeight = 0;
    let totalWeightedRanks = 0;

    (indexData ?? []).forEach((datum: any) => {
      totalWeight += Number(datum?.weight);
      totalWeightedRanks += Number(datum?.weight) * Number(datum?.company_pct_rank);
    });

    const percentileRank = Math.round((totalWeightedRanks / totalWeight) * 100) / 100;
    return percentileRank;
  };
  return {
    getMarketIndexPercentileRank,
    isLoading,
    isError,
  };
};
