import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_PERCENTILE_DATA_REQUEST: 'GET_PERCENTILE_DATA_REQUEST',
  GET_PERCENTILE_DATA_RECIEVE: 'GET_PERCENTILE_DATA_RECEIVE',
};

const getPercentileDataRequest = createAction(ACTION.GET_PERCENTILE_DATA_REQUEST);
const getPercentileDataRecieve = createAction(ACTION.GET_PERCENTILE_DATA_RECIEVE);

const api = {
  getPercentileData: companyId => fetchAuthJSON(`company/${companyId}/percentileRanking`, {method: 'get'}),
};

export function getRequestData(companyId) {
  return dispatch => {
    dispatch(getPercentileDataRequest({companyId}));
    return dispatch(getPercentileDataRecieve(api.getPercentileData(companyId)));
  };
}

// REDUCER
export const percentileData = (state = initialState, {error: hasError, payload, type}) => {
  switch (type) {
    case ACTION.GET_PERCENTILE_DATA_REQUEST:
      return Object.assign({}, state, {isFetching: true});
    case ACTION.GET_PERCENTILE_DATA_RECIEVE:
      return Object.assign({isFetching: false}, hasError ? {error: payload} : {issues: payload});
    default:
      return state;
  }
};
