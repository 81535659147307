import {ROUTE} from 'app/constants';
import {
  userType1MenuProps as settingsMenuPropsUT1,
  userType3MenuProps as settingsMenuPropsUT3,
} from 'app/constants/settingsNavigation';
import {env} from 'v2/utilities/helpers';
import {ROUTE as V2ROUTES} from 'v2/constants/routes';

const defaultMenuProps = {
  logoRoute: ROUTE.DASHBOARD,
  primaryMenuRoutes: [],
  secondaryMenuRoutes: [],
  showNotifications: false,
  notificationCount: 0,
  showSearch: false,
  showLogout: false,
};
export const adminMenuProps = Object.assign({}, defaultMenuProps, {
  logoRoute: ROUTE.ADMIN_DASHBOARD,
  primaryMenuRoutes: [
    ROUTE.ADMIN_USERS,
    ROUTE.ADMIN_FIRMS,
    ROUTE.ADMIN_SECURITY_LISTING,
    ROUTE.ADMIN_HARMONY_UPLOADS,
    ROUTE.ADMIN_CLIENT_HOLDINGS_UPLOAD,
    ROUTE.ADMIN_MARKET_INDEXES,
    ROUTE.ADMIN_EMPLOYEES,
    ROUTE.ADMIN_SITE_SETTINGS,
    ROUTE.V2_RESOURCE_CENTER,
  ],
  showLogout: true,
});
export const publicMenuProps = Object.assign({}, defaultMenuProps, {secondaryMenuRoutes: [ROUTE.LOGIN]});
const userType1MenuProps = Object.assign({}, defaultMenuProps, {
  primaryMenuRoutes: [
    ROUTE.DASHBOARD,
    ROUTE.ESG_EXPLORER,
    {...V2ROUTES.UT1.STRATEGIES.INDEX, path: () => V2ROUTES.UT1.STRATEGIES.INDEX.path},
    {...V2ROUTES.UT1.COMPANIES.INDEX, path: () => V2ROUTES.UT1.COMPANIES.INDEX.path},
    {...ROUTE.RATINGS_CENTER, title: 'Resource Center'},
  ],
  secondaryMenuRoutes: [
    Object.assign({}, ROUTE.SETTINGS, {
      submenu: {
        align: 'left',
        items: settingsMenuPropsUT1,
      },
    }),
  ],
  showNotifications: false,
  showSearch: false,
  showLogout: true,
});

export {userType1MenuProps};
export const demoAccountMenuProps = {
  ...userType1MenuProps,
  primaryMenuRoutes: [ROUTE.PROGRESS_REPORT, ROUTE.PLAN, ROUTE.MANAGERS, ROUTE.COMPANIES],
};
export const userType3MenuProps = Object.assign({}, defaultMenuProps, {
  primaryMenuRoutes: [ROUTE.ALL_PLANS],
  secondaryMenuRoutes: [
    // ROUTE.UPLOAD_PORTFOLIO_HEADER,
    Object.assign({}, ROUTE.SETTINGS, {
      submenu: {
        align: 'left',
        items: settingsMenuPropsUT3,
      },
    }),
  ],
  showNotifications: false,
  showSearch: false,
  showLogout: true,
});
export const userType4MenuProps = Object.assign({}, defaultMenuProps, {
  primaryMenuRoutes: [ROUTE.COMPANY_DASHBOARD, ROUTE.COMPANY_DATA, ROUTE.COMPANY_ROADMAP],
  secondaryMenuRoutes: [],
  showNotifications: false,
  showSearch: false,
  showLogout: true,
});
