/* eslint-disable no-param-reassign */
import {createAction} from 'redux-actions';
import concat from 'lodash/concat';
import get from 'lodash/get';
import map from 'lodash/map';
import reject from 'lodash/reject';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {PORTFOLIO_STATUS, PORTFOLIO_MANAGEMENT_TYPE} from 'app/constants';
import {prepareSecuritiesForDataTable} from 'app/utilities';

const initialState = {
  bookmarkedSecurities: {},
  pagedSecurities: {},
  portfolio: {},
};

// ACTION
export const ACTION = {
  ADD_HOLDING_REQUEST: 'compliance-manager/add-holding/request',
  ADD_HOLDING_RECEIVE: 'compliance-manager/add-holding/receive',
  GET_BOOKMARKED_SECURITIES_REQUEST: 'compliance-manager/get-bookmarked-securities/request',
  GET_BOOKMARKED_SECURITIES_RECEIVE: 'compliance-manager/get-bookmarked-securities/receive',
  GET_PAGED_SECURITIES_REQUEST: 'compliance-manager/get-paged-securities/request',
  GET_PAGED_SECURITIES_RECEIVE: 'compliance-manager/get-paged-securities/receive',
  GET_PORTFOLIO_REQUEST: 'compliance-manager/get-portfolio/request',
  GET_PORTFOLIO_RECEIVE: 'compliance-manager/get-portfolio/receive',
  CREATE_STAGING_PORTFOLIO_REQUEST: 'compliance-manager/create-staging-portfolio/request/silent',
  CREATE_STAGING_PORTFOLIO_RECEIVE: 'compliance-manager/create-staging-portfolio/receive/silent',
  CREATE_PORTFOLIO_REQUEST: 'compliance-manager/create-portfolio/request',
  CREATE_PORTFOLIO_RECEIVE: 'compliance-manager/create-portfolio/receive',
  DELETE_HOLDING_REQUEST: 'compliance-manager/delete-holding/request',
  DELETE_HOLDING_RECEIVE: 'compliance-manager/delete-holding/receive',
  RESET_TRIAL_PORTFOLIO_REQUEST: 'compliance-manager/reset-trial-portfolio/request',
  RESET_TRIAL_PORTFOLIO_RECEIVE: 'compliance-manager/reset-trial-portfolio/receive',
  UPDATE_HOLDING_REQUEST: 'compliance-manager/update-holding/request',
  UPDATE_HOLDING_RECEIVE: 'compliance-manager/update-holding/receive',
  UNFREEZE_SORT: 'compliance-manager/unfreeze-sort',
};

const addHoldingRequest = createAction(ACTION.ADD_HOLDING_REQUEST);
const addHoldingReceive = createAction(ACTION.ADD_HOLDING_RECEIVE);
const getBookmarkedSecuritiesRequest = createAction(ACTION.GET_BOOKMARKED_SECURITIES_REQUEST);
const getBookmarkedSecuritiesReceive = createAction(ACTION.GET_BOOKMARKED_SECURITIES_RECEIVE);
const getPagedSecuritiesRequest = createAction(ACTION.GET_PAGED_SECURITIES_REQUEST);
const getPagedSecuritiesReceive = createAction(ACTION.GET_PAGED_SECURITIES_RECEIVE);
const getPortfolioRequest = createAction(ACTION.GET_PORTFOLIO_REQUEST);
const getPortfolioReceive = createAction(ACTION.GET_PORTFOLIO_RECEIVE);
const createStagingPortfolioRequest = createAction(ACTION.CREATE_STAGING_PORTFOLIO_REQUEST);
const createStagingPortfolioReceive = createAction(ACTION.CREATE_STAGING_PORTFOLIO_RECEIVE);
const createPortfolioRequest = createAction(ACTION.CREATE_PORTFOLIO_REQUEST);
const createPortfolioReceive = createAction(ACTION.CREATE_PORTFOLIO_RECEIVE);
const deleteHoldingRequest = createAction(ACTION.DELETE_HOLDING_REQUEST);
const deleteHoldingReceive = createAction(ACTION.DELETE_HOLDING_RECEIVE);
const resetTrialPortfolioRequest = createAction(ACTION.RESET_TRIAL_PORTFOLIO_REQUEST);
const resetTrialPortfolioReceive = createAction(ACTION.RESET_TRIAL_PORTFOLIO_RECEIVE);
const updateHoldingRequest = createAction(ACTION.UPDATE_HOLDING_REQUEST);
const updateHoldingReceive = createAction(ACTION.UPDATE_HOLDING_RECEIVE);
const unfreezeSortAction = createAction(ACTION.UNFREEZE_SORT);

/**
 * Prepares the results of the portfolio for display on the Portfolio Centerr
 */
const preparePortfolioForComplianceManager = results => {
  const isPassiveHarmonyIndex =
    get(results, 'marketIndex.isHarmonyIndex') && results.portfolioManagementType === PORTFOLIO_MANAGEMENT_TYPE.PASSIVE;

  const computedCompliance = prepareSecuritiesForDataTable(
    results.securities,
    results.planSponsor,
    isPassiveHarmonyIndex,
  );

  return Object.assign(
    {},
    results,
    {
      compliance:
        results.policyViolation && results.policyViolation.length ? PORTFOLIO_STATUS.OUT : PORTFOLIO_STATUS.IN,
    },
    results.isTrial
      ? {
          trialCompliance: computedCompliance.compliance,
          trialPolicyViolation: computedCompliance.policyViolation,
        }
      : null,
  );
};

const api = {
  addHolding: (strategyId, securityIsin) => {
    return fetchAuthJSON(`complianceManager/createTrialPortfolioHolding/${strategyId}`, {
      method: 'post',
      body: JSON.stringify({securityIsin}),
    });
  },
  getBookmarkedSecurities: (options = {}) => {
    let url = 'userSecurityBookmark/pagedBookmarks?';
    url += `page=${options.page || 1}`;
    url += `&pageSize=${options.pageSize || 10000}`;

    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${options.search || ''}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getPagedSecurities: (strategyId, options = {}) => {
    let url = `securities/list/${strategyId}?`;
    url += `page=${options.page || 1}`;
    url += `&pageSize=${options.pageSize || 10}`;

    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${options.search || ''}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getPortfolio: strategyId => {
    return fetchAuthJSON(`complianceManager/getPortfolio/${strategyId}`, {method: 'get'}).then(results =>
      Object.assign({strategyId: parseInt(strategyId, 10)}, results),
    );
  },
  createPortfolio: (strategyId, fileKey) => {
    return fetchAuthJSON(`complianceManager/createTrialPortfolio/${strategyId}`, {
      method: 'post',
      body: JSON.stringify({fileKey}),
    });
  },
  createStagingPortfolio: fileKey => {
    return fetchAuthJSON('complianceManager/uploadStagingTrialPortfolio', {
      method: 'post',
      body: JSON.stringify({fileKey}),
    });
  },
  deleteHolding: strategyTrialHoldingId => {
    return fetchAuthJSON(`complianceManager/deleteTrialPortfolioHolding/${strategyTrialHoldingId}`, {
      method: 'delete',
    });
  },
  resetTrialPortfolio: strategyId => {
    return fetchAuthJSON(`complianceManager/resetTrialPortfolio/${strategyId}`, {method: 'post'}).then(results =>
      Object.assign({strategyId: parseInt(strategyId, 10)}, results),
    );
  },
  updateHolding: (strategyTrialHoldingId, values) => {
    return fetchAuthJSON(`complianceManager/updateTrialPortfolioHolding/${strategyTrialHoldingId}`, {
      method: 'put',
      body: JSON.stringify(values),
    });
  },
};

export function addHolding(strategyId, securityIsin) {
  return dispatch => {
    dispatch(addHoldingRequest({strategyId, securityIsin}));
    return dispatch(addHoldingReceive(api.addHolding(strategyId, securityIsin)));
  };
}

export function getBookmarkedSecurities(options) {
  return dispatch => {
    dispatch(getBookmarkedSecuritiesRequest({options}));
    return dispatch(getBookmarkedSecuritiesReceive(api.getBookmarkedSecurities(options)));
  };
}

export function getPagedSecurities(strategyId, options) {
  return dispatch => {
    dispatch(getPagedSecuritiesRequest({strategyId, options}));
    return dispatch(getPagedSecuritiesReceive(api.getPagedSecurities(strategyId, options)));
  };
}

export function getPortfolio(strategyId) {
  return dispatch => {
    dispatch(getPortfolioRequest({strategyId: parseInt(strategyId, 10)}));
    return dispatch(getPortfolioReceive(api.getPortfolio(strategyId)));
  };
}

export function createStagingPortfolio(fileKey) {
  return dispatch => {
    dispatch(createStagingPortfolioRequest({fileKey}));
    return dispatch(createStagingPortfolioReceive(api.createStagingPortfolio(fileKey)));
  };
}

export function createPortfolio(strategyId, fileKey) {
  return dispatch => {
    dispatch(createPortfolioRequest({strategyId: parseInt(strategyId, 10), fileKey}));
    return dispatch(
      createPortfolioReceive(
        api.createPortfolio(strategyId, fileKey).then(payload => {
          dispatch(getPortfolio(strategyId));
          return payload;
        }),
      ),
    );
  };
}

export function deleteHolding(strategyTrialHoldingId) {
  return dispatch => {
    dispatch(deleteHoldingRequest(parseInt(strategyTrialHoldingId, 10)));
    return dispatch(deleteHoldingReceive(api.deleteHolding(strategyTrialHoldingId)));
  };
}

export function resetTrialPortfolio(strategyId) {
  return dispatch => {
    dispatch(resetTrialPortfolioRequest({strategyId: parseInt(strategyId, 10)}));
    return dispatch(resetTrialPortfolioReceive(api.resetTrialPortfolio(strategyId)));
  };
}

export function updateHolding(strategyTrialHoldingId, values) {
  return dispatch => {
    dispatch(updateHoldingRequest({strategyTrialHoldingId: parseInt(strategyTrialHoldingId, 10), values}));
    return dispatch(updateHoldingReceive(api.updateHolding(strategyTrialHoldingId, values)));
  };
}

export function unfreezeSort() {
  return dispatch => {
    return dispatch(unfreezeSortAction());
  };
}

// REDUCER
export const complianceManager = (state = {...initialState}, {error: hasError, payload, type}) => {
  switch (type) {
    case ACTION.CREATE_STAGING_PORTFOLIO_REQUEST:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          isCreatingStagingPortfolio: true,
          hasStagingPortfolioError: false,
          isFetching: true,
        },
      };
    case ACTION.GET_BOOKMARKED_SECURITIES_REQUEST:
      return {
        ...state,
        bookmarkedSecurities: {
          ...state.bookmarkedSecurities,
          isFetching: true,
        },
      };
    case ACTION.GET_PAGED_SECURITIES_REQUEST:
      return {
        ...state,
        pagedSecurities: {
          ...state.pagedSecurities,
          isFetching: true,
        },
      };
    case ACTION.RESET_TRIAL_PORTFOLIO_REQUEST:
    case ACTION.GET_PORTFOLIO_REQUEST:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          ...(payload.strategyId !== get(state.portfolio, 'data.strategyId') ? {hasFetched: false} : {}),
          isFetching: true,
        },
      };
    case ACTION.CREATE_STAGING_PORTFOLIO_RECEIVE:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          isCreatingStagingPortfolio: false,
          hasStagingPortfolioError: hasError,
          isFetching: false,
          isSortFrozen: false,
        },
      };
    case ACTION.GET_BOOKMARKED_SECURITIES_RECEIVE:
      return {
        ...state,
        bookmarkedSecurities: {
          ...(hasError ? {error: payload} : {data: payload}),
          hasError,
          hasFetched: true,
          isFetching: false,
        },
      };
    case ACTION.GET_PAGED_SECURITIES_RECEIVE:
      return {
        ...state,
        pagedSecurities: {
          ...(hasError ? {error: payload} : {data: payload}),
          hasError,
          hasFetched: true,
          isFetching: false,
        },
      };
    case ACTION.RESET_TRIAL_PORTFOLIO_RECEIVE:
    case ACTION.GET_PORTFOLIO_RECEIVE:
      return {
        ...state,
        portfolio: {
          ...(hasError ? {...state.portfolio, error: payload} : {data: preparePortfolioForComplianceManager(payload)}),
          hasError,
          hasFetched: true,
          isFetching: false,
          isSortFrozen: false,
        },
      };
    case ACTION.ADD_HOLDING_RECEIVE:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          ...(hasError
            ? {error: payload}
            : {
                data: preparePortfolioForComplianceManager({
                  ...state.portfolio.data,
                  securities: concat(state.portfolio.data.securities, {
                    ...payload,
                    isNew: true,
                  }),
                  savedAt: new Date().toISOString(),
                }),
              }),
          hasError,
          isSortFrozen: true,
        },
      };
    case ACTION.UPDATE_HOLDING_RECEIVE:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          ...(hasError
            ? {error: payload}
            : {
                data: preparePortfolioForComplianceManager({
                  ...state.portfolio.data,
                  securities: map(state.portfolio.data.securities, security => {
                    return security.key === payload.key ? payload : security;
                  }),
                  savedAt: new Date().toISOString(),
                }),
              }),
          hasError,
          isSortFrozen: true,
        },
      };
    case ACTION.DELETE_HOLDING_RECEIVE:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          ...(hasError
            ? {error: payload}
            : {
                data: preparePortfolioForComplianceManager({
                  ...state.portfolio.data,
                  securities: reject(state.portfolio.data.securities, {
                    key: parseInt(payload.strategyTrialHoldingId, 10),
                  }),
                  savedAt: new Date().toISOString(),
                }),
              }),
          hasError,
        },
      };
    case ACTION.UNFREEZE_SORT:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          isSortFrozen: false,
        },
      };
    default:
      return state;
  }
};
