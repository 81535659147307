import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Chart as ChartJS, defaults as chartJsDefaults} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import * as AnnotationsPlugin from 'chartjs-plugin-annotation';
import {IssueDataCardChartLegendItem} from './IssueDataCardChartLegendItem';
import {ISSUE, CONFIG} from '../constants';
import {formatNumberCommas} from '../utilities/formatNumberCommas';
import {zeroCompensation} from '../utilities/zeroCompensation';
import {DATA_STATUS} from 'v2/constants/dataStatus';

/**
 * This chart style is for percentage data, and is displayed as a bar chart
 */

class IssueDataCardChartStyle2 extends Component {
  constructor() {
    super();

    chartJsDefaults.global.defaultFontFamily = 'futura-pt';
    // chartJsDefaults.global.defaultFontSize = '16';

    const tooltipPlugin = ChartJS.registry.getPlugin('tooltip');
    tooltipPlugin.positioners.center = function (elements, eventPosition) {
      if (!elements[0]) {
        return {x: 0, y: 0};
      }
      const {x, y, base} = elements[0].element;
      const height = base - y;
      return {x, y: y + height / 2};
    };
  }

  render() {
    const {data: issueData} = this.props;
    const {id: issueId, data} = issueData;
    this.thisYear = CONFIG.REPORTING_YEAR;
    this.keyYears = Array.from({length: CONFIG.REPORTING_YEAR_OFFSET}, (_, i) => this.thisYear - i).reverse();
    const precision = !!ISSUE?.[issueId] && ISSUE[issueId].precision;

    const actualData = this.keyYears
      .map((year, i) => {
        if (data[year] !== undefined && data[year].value !== undefined && data[year].value !== null) {
          return {
            x: i + 1,
            y: data[year].value,
          };
        }
        return null;
      })
      .filter(value => value !== null);

    const harmonyStandardData = this.keyYears
      .map((year, i) => {
        if (
          data[year] !== undefined &&
          data[year].harmonyStandardLower !== undefined &&
          data[year].harmonyStandardLower !== null &&
          data[year].harmonyStandardUpper !== undefined &&
          data[year].harmonyStandardUpper !== null
        ) {
          return {
            drawTime: 'beforeDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            yScaleID: 'y',
            xMin: i,
            xMax: i + 1,
            yMin: data[year].harmonyStandardLower,
            yMax: data[year].harmonyStandardUpper,
            borderColor: 'transparent',
            backgroundColor: '#DDECF2',
            borderWidth: 0,
          };
        }

        return null;
      })
      .filter(value => value !== null);

    const chartData = {
      labels: this.keyYears,
      datasets: [
        {
          label: 'Actual',
          fill: false,
          lineTension: 0,
          pointRadius: 5,
          backgroundColor: '#003462',
          borderColor: '#003462',
          data: actualData,
        },
      ],
    };

    const chartOptions = {
      annotation: {
        annotations: harmonyStandardData,
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: false,
        tooltip: {
          position: 'center',
          displayColors: false,
          padding: 10,
          callbacks: {
            title(tooltipItem) {
              return tooltipItem[0].label;
            },
            label(tooltipItem) {
              if (tooltipItem.formattedValue === undefined) {
                return DATA_STATUS.NONE;
              }
              if (tooltipItem.formattedValue >= 10 ** precision) {
                return formatNumberCommas(parseFloat(tooltipItem.formattedValue).toFixed(0));
              }
              return formatNumberCommas(
                parseFloat(parseFloat(tooltipItem.formattedValue.replaceAll(',', '')).toPrecision(precision)),
              );
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.4,
      scales: {
        x: {
          barPercentage: 0.4,
          offset: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          barPercentage: 0.4,
          min: 0,
          max: CONFIG.REPORTING_YEAR_OFFSET - 1,
          ticks: {
            min: 1,
            max: CONFIG.REPORTING_YEAR_OFFSET - 1,
            stepSize: 1,
            callback(value, index) {
              return chartData.labels[index];
            },
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            min: 0,
            max: 100,
            stepSize: 10,
            callback(value) {
              return `${value}%`;
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    return (
      <div className="w-100 h-100 d-flex flex-column">
        <div style={{flex: 1}}>
          <Bar plugins={[AnnotationsPlugin, zeroCompensation]} options={chartOptions} data={chartData} />
        </div>
        <div className="w-100 d-flex flex-row justify-content-center">
          {actualData.length > 0 && <IssueDataCardChartLegendItem color="#003462" label="Actual" />}
          {harmonyStandardData.length > 0 && <IssueDataCardChartLegendItem color="#DDECF2" label="Benchmark" />}
        </div>
      </div>
    );
  }
}

IssueDataCardChartStyle2.propTypes = {
  data: PropTypes.object.isRequired,
};

export {IssueDataCardChartStyle2};
