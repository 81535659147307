import React from 'react';
import PropTypes from 'prop-types';
import faChevronDown from 'app/fontawesome-pro-light/faChevronDown';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {COLOR} from 'app/constants/color';

const CHECKBOX_CONTAINER_WIDTH = 32;
const MAX_CONTENT_HEIGHT = 300;
const PADDING = 16;

class AccordionCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.setIsOpen = this.setIsOpen.bind(this);
  }

  setIsOpen(isOpen) {
    this.setState({isOpen});
  }

  render() {
    const {isOpen} = this.state;
    const {title, column1, column2} = this.props;

    const toggleIsOpen = () => this.setIsOpen(!isOpen);
    const chevronTransform = `rotate(${isOpen ? 180 : 0}deg)`;
    const contentMaxHeight = isOpen ? MAX_CONTENT_HEIGHT : 0;
    const contentOpacity = isOpen ? 1 : 0;

    return (
      <div style={{background: 'white', marginBottom: PADDING, boxShadow: '0 2px 8px rgba(0,0,0,0.15)'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div
            onClick={toggleIsOpen}
            style={{flex: '1 0 auto', padding: PADDING, cursor: 'pointer', fontWeight: 'bold'}}
          >
            {title}
          </div>
          <div>
            <Icon
              icon={faChevronDown}
              onClick={toggleIsOpen}
              style={{
                color: COLOR.BLUE,
                marginRight: 15,
                transition: 'transform 0.6s',
                transform: chevronTransform,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <div
          style={{
            maxHeight: contentMaxHeight,
            overflow: 'hidden',
            transition: 'all 0.3s',
            opacity: contentOpacity,
          }}
        >
          <div
            style={{
              padding: PADDING,
              paddingTop: 0,
              paddingBottom: PADDING * 2,
              paddingLeft: CHECKBOX_CONTAINER_WIDTH + PADDING,
              display: 'flex',
            }}
          >
            <div style={{flex: '1 1 auto', paddingRight: 50}}>{column1}</div>
            <div style={{flex: '0 0 auto', width: 250}}>{column2}</div>
          </div>
        </div>
      </div>
    );
  }
}

AccordionCard.propTypes = {
  title: PropTypes.node,
  column1: PropTypes.node,
  column2: PropTypes.node,
};

export {AccordionCard};
