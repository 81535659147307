import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {compose} from 'recompose';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

// Local Imports
import {FORM_MESSAGE, ROUTE, ROLE} from 'app/constants';
import {Alert, ProgressIndicator, OnboardingESGForm} from 'app/components';
import {updatePlanSponsorByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';

class OnboardingESGPage extends Component {
  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    // Check if the form should be disabled
    const isReadOnly = this.props.userRole === ROLE.USER_TYPE_2;

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          <h1 className="mb-4">{ROUTE.ONBOARDING_ESG_STRATEGY.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <Fragment>
              {isReadOnly ? (
                <p className="mb-3">
                  You do not currently have the required access level to edit the ESG information for this plan. Only a
                  user with the role of "Admin" can edit the ESG information. If you believe you have the wrong access
                  level, contact your plan "Admin" and they can modify your access level via their "User Manager" in
                  "Settings".
                </p>
              ) : (
                <p className="mb-3">
                  Any user with the role of "Admin" on this plan can edit the information below and add more information
                  after their account is set up by going to the "Settings" portion of their account. Users with the role
                  of "Support" are not permitted to change ESG information.
                </p>
              )}
              <OnboardingESGForm {...this.props} isReadOnly={isReadOnly} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;
      const updateData = {
        ...values,
        onboardingStep: onboardingSteps.ESG.onboardingStepName
          ? onboardingSteps.ESG.onboardingStepName
          : onboardingSteps.ESG.key,
      };

      return dispatch(updatePlanSponsorByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_INVESTMENT_MANAGERS.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE});
        });
    },
    dispatch,
  };
};

export const OnboardingESG = compose(withOnboardingUser, connect(null, mapDispatchToProps))(OnboardingESGPage);
