import React, {Component} from 'react';

export class LegendItem extends Component {
  render() {
    const {title, color} = this.props;

    return (
      <div className={'d-inline-block mx-3'}>
        <span className={'marker'} style={{backgroundColor: color}} />
        <span>{title}</span>
      </div>
    );
  }
}
