import {CONFIG} from 'app/constants';
import {format} from 'date-fns';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import ReactS3Uploader from 'react-s3-uploader';
import styled from 'styled-components/macro';
import {CardContainer, Container, FlexContainer} from 'v2/components/atoms/Containers';
import {SubtitleM, TitleS} from 'v2/components/atoms/Typeface';
import {CircleX, Folder, Paperclip, Upload} from 'v2/components/atoms/icons';
import {Theme} from 'v2/components/atoms/theme';
import {HarmonyButton} from 'v2/components/molecules/Button';
import {TextFormGroup} from 'v2/components/molecules/TextInput';
import {useOutsideClick} from 'v2/hooks/helpers/useOutsideClick';
import {useCompanyIssueFileUploads} from 'v2/hooks/useCompanyIssueFileUploads';
import {useDeleteCompanyIssuesFileUploadsMutation} from 'v2/redux/harmonyApi';
import {
  useCreateCompanyIssuesFileUploadsMutation,
  useLazyGetCompanyIssuesFileUploadsQuery,
} from 'v2/redux/typeormEndpoints';
import {
  IFileBrowserWithUploadInput,
  SavedFile,
  UploadState,
  UploadedFile,
} from 'v2/utilities/types/components/FileBrowserWithUpload';
import ReactTooltip from 'react-tooltip';
import {getSessionJwt} from 'app/utilities';

const token = getSessionJwt();
const S3_UPLOADER_PROPS = {
  s3path: 'uploads/',
  server: CONFIG.API_URL,
  signingUrl: '/s3/sign',
  signingUrlMethod: 'GET',
  signingUrlQueryParams: {bearer: token},
  uploadRequestHeaders: {'x-amz-acl': 'private'},
};

const FileInput = styled(ReactS3Uploader)`
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  display: none;
`;

const InputContainer = styled(FlexContainer)``;

const Button = styled.button.attrs({type: 'button'})<{height?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({height}) => height ?? '2.75rem'};
  border: 0;
  background-color: transparent;
`;

const LabelContainer = styled(FlexContainer).attrs({flex: 1, items: 'center', justify: 'center'})`
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 15.625rem;
`;

const ListItem = styled(FlexContainer).attrs({
  as: 'button',
  items: 'center',
})`
  border: 0;
  background: transparent;
  &&& {
    min-height: 2.75rem;
  }
`;

const ItemLabel = styled.span`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-all;
`;

const FileItemButton = styled(HarmonyButton)<React.ComponentProps<typeof HarmonyButton> & {selected: boolean}>`
  border: 1px solid transparent;
  height: auto;
  ${({selected, theme}) => selected && `background-color: ${theme.colors.grey_2}`};
  &:disabled {
    background-color: ${Theme.colors.grey_2};
  }
`;

const FileItemWWrapper = styled(FlexContainer)`
  flex: 0;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

// User can choose to upload a file or select a file from the list
// Files from the list are files associated with the company.

export const FileBrowserWithUploadInput = ({
  width,
  height,
  hideError,
  placeholder = 'No File Uploaded',
  icon = Paperclip,
  companyId,
  issueId,
  isDisabled,
  name,
  initialFile,
  onDeleteFile,
  ...rest
}: IFileBrowserWithUploadInput) => {
  const uploaderRef = useRef(null);
  const panelRef = useRef(null);

  const [popupState, setPopupState] = useState<UploadState>('initial');
  const [selectedFile, setSelectedFile] = useState<SavedFile | null>(null);
  const [deleteFile, {isLoading: isDeleting}] = useDeleteCompanyIssuesFileUploadsMutation();
  const [getFileUploads, {isLoading}] = useLazyGetCompanyIssuesFileUploadsQuery();
  const [createFileUpload] = useCreateCompanyIssuesFileUploadsMutation();
  const [uploadProgress, setUploadProgress] = useState<any[]>();

  const {fileList} = useCompanyIssueFileUploads({companyId});

  const {change: setValue} = useForm();
  const {meta, input} = useField(name);

  const reset = () => {
    setPopupState('initial');
  };

  useOutsideClick({ref: panelRef, onOutsideClick: reset});

  const handleUploadClick = () => {
    reset();
    // @ts-ignore
    uploaderRef.current?.click?.();
    setUploadProgress(undefined);
  };

  // Reset the popup state if all files have been deleted
  useEffect(() => {
    if (!fileList?.length) {
      reset();
    }
  }, [fileList?.length]);

  const handleSubmitFile = async ({fileName, filePath}: any) => {
    const body = {companyId, fileName, filePath: filePath, issueId};
    const res = await createFileUpload(body).unwrap();
    return res;
  };

  const handleFinishUpload = async (s3File: any, fileInfo: File) => {
    const res = await handleSubmitFile({fileName: fileInfo.name, filePath: s3File.fileKey});
    if (!res.error) {
      const {id, createdAt} = res;
      setSelectedFile({id, fileName: fileInfo.name, sourceLink: s3File.fileKey, createdAt});
    }
    setValue(name, s3File.fileKey);
    setValue('fileName', fileInfo.name);
  };

  const handleProgress = (progress: number, status: number, file: File) => {
    setUploadProgress([progress, status, file.name]);
  };

  const uploaderProps = {
    ...S3_UPLOADER_PROPS,
    autoUpload: true,
    disabled: false,
    onFinish: handleFinishUpload,
    onProgress: handleProgress,
    // ...input,
  };

  const handleShowMenu = () => {
    setPopupState(prevState => {
      if (prevState === 'type') {
        return 'initial';
      }

      return 'type';
    });
  };

  const handleFileListClick = () => {
    setPopupState('filelist');
  };

  const handleItemDouble = (file: SavedFile) => {
    setSelectedFile(file as UploadedFile);
    handleSubmitSelectedItem();
  };

  const handleSelectItem = (file: SavedFile) => {
    setSelectedFile(file as UploadedFile);
  };

  const handleSubmitSelectedItem = useCallback(() => {
    if (selectedFile) {
      setValue(name, selectedFile?.sourceLink);
      setValue('fileName', selectedFile?.fileName);
      reset();
    }
  }, [selectedFile]);

  const handleDeleteFile = async (id: number, fileName: string) => {
    try {
      if (window.confirm(`Are you sure you want to delete ${fileName}?`)) {
        const res = await deleteFile(id).unwrap();
        if (!res.error) {
          onDeleteFile && (await onDeleteFile(id));
          setSelectedFile(null);
          setValue(name, null);
          setValue('fileName', null);
          getFileUploads({companyId});
        } else {
          console.error(res.error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sourceFile = input.value?.split('_').length && input.value.split('_').slice(1).join('');
  const value = selectedFile?.fileName || sourceFile || input.value;

  const hasFiles = !!fileList?.length;

  // We need the fileId to decide if this is a new file or an existing file
  useEffect(() => {
    const fileId = fileList?.find(file => file.sourceLink === input.value)?.id;
    setValue('fileId', fileId);
  }, [fileList, input.value]);

  // Reset the selected file if the input value changes
  useEffect(() => {
    setSelectedFile(input.value);
  }, [input.value]);

  useEffect(() => {
    if (popupState === 'filelist') {
      setTimeout(() => {
        console.log('rebuild');
        ReactTooltip.rebuild();
      }, 100);
    }
  }, [popupState, fileList?.length]);

  return (
    <Container>
      <InputContainer gap="0.6875rem">
        <FlexContainer flex="1">
          <FileInput inputRef={uploaderRef} {...uploaderProps} {...rest} />
          <TextFormGroup
            width="100%"
            meta={meta.touched && meta}
            placeholder={placeholder}
            input={{value}}
            onClick={handleShowMenu}
            disabled={isDisabled}
          />
        </FlexContainer>
        <Container>
          <Button onClick={handleShowMenu} type="button" disabled={isDisabled}>
            {React.createElement(icon, {
              size: 20,
              color: Theme.colors.harmony,
            })}
          </Button>
          <Container style={{position: 'absolute', width: 0, height: 0, overflow: 'visible'}} ref={panelRef}>
            {popupState === 'type' && (
              <CardContainer
                minHeight="8rem"
                minWidth="18.75rem"
                style={{
                  padding: '1rem',
                  position: 'relative',
                  top: '0.625rem',
                  right: '18.5625rem',
                  zIndex: 10,
                }}
              >
                <FlexContainer gap="1rem" direction="column">
                  <ListItem onClick={hasFiles ? handleFileListClick : () => null}>
                    <Container padding="0.125rem 0 0">
                      <Folder color={hasFiles ? Theme.colors.harmony : Theme.colors.grey_3} size={20} />
                    </Container>
                    <LabelContainer>
                      <SubtitleM color={hasFiles ? Theme.colors.harmony : Theme.colors.grey_3}>
                        Select from Existing File
                      </SubtitleM>
                    </LabelContainer>
                  </ListItem>
                  <ListItem onClick={handleUploadClick}>
                    <Container padding="0.125rem 0 0">
                      <Upload color={Theme.colors.harmony} size={20} />
                    </Container>
                    <LabelContainer>
                      <SubtitleM>Upload from my computer</SubtitleM>
                    </LabelContainer>
                  </ListItem>
                </FlexContainer>
              </CardContainer>
            )}
            {popupState === 'filelist' && (
              <CardContainer
                minWidth="100vw"
                maxHeight="100vh"
                style={{
                  padding: '3.375rem 2.5rem',
                  position: 'fixed',
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  top: 0,
                  left: 0,
                  zIndex: 1000,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FlexContainer
                  direction="column"
                  style={{
                    flex: 1,
                    width: 600,
                    backgroundColor: 'white',
                    padding: '50px 40px',
                    maxHeight: '600px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    gap: '1.25rem',
                  }}
                >
                  <FlexContainer>
                    <FlexContainer flex="1" style={{paddingLeft: 10}}>
                      <TitleS>File Name</TitleS>
                    </FlexContainer>
                    <FlexContainer flex="1" justify="flex-end" style={{paddingRight: 10}}>
                      <TitleS>Date Uploaded</TitleS>
                    </FlexContainer>
                    <FlexContainer minWidth="7rem" />
                  </FlexContainer>
                  <FlexContainer
                    flex="1"
                    direction="column"
                    style={{overflow: 'visible', overflowY: 'auto', gap: '16px'}}
                  >
                    {!!fileList?.length ? (
                      // @ts-ignore
                      fileList?.map(({createdAt, fileName, sourceLink, id, inUse}) => (
                        <FileItemWWrapper>
                          <FileItemButton
                            onClick={() => handleSelectItem({fileName, sourceLink, id})}
                            onDoubleClick={() => handleItemDouble({fileName, sourceLink})}
                            style={{height: 'auto', padding: '0 10px', position: 'relative'}}
                            type="button"
                            variant="ghost"
                            selected={selectedFile?.id === id}
                          >
                            <FlexContainer flex="1" style={{whiteSpace: 'normal'}}>
                              <ItemLabel>{fileName}</ItemLabel>
                            </FlexContainer>
                            <FlexContainer flex="1" justify="flex-end">
                              <ItemLabel>{format(new Date(createdAt), 'PP')}</ItemLabel>
                            </FlexContainer>
                          </FileItemButton>
                          <FlexContainer
                            flex="0"
                            data-tip={inUse ? 'File is in use on another issue.' : ''}
                            data-for="form-tooltip"
                          >
                            <FileItemButton
                              variant="ghost"
                              disabled={isDisabled || isDeleting || isLoading || inUse}
                              onClick={() => handleDeleteFile(id, fileName)}
                              type="button"
                            >
                              <CircleX
                                size={20}
                                color={
                                  isDisabled || isDeleting || isLoading || inUse
                                    ? Theme.colors.grey_4
                                    : Theme.colors.red
                                }
                              />
                            </FileItemButton>
                          </FlexContainer>
                        </FileItemWWrapper>
                      ))
                    ) : (
                      <ItemLabel>No files found</ItemLabel>
                    )}
                  </FlexContainer>
                  <FlexContainer justify="flex-end" gap="1rem">
                    <FileItemButton variant="ghost" width="6.9375rem" onClick={reset}>
                      Cancel
                    </FileItemButton>
                    <FileItemButton width="6.9375rem" onClick={handleSubmitSelectedItem} disabled={!selectedFile}>
                      Select
                    </FileItemButton>
                  </FlexContainer>
                </FlexContainer>
              </CardContainer>
            )}
          </Container>
        </Container>
      </InputContainer>

      {!!uploadProgress && (
        <ProgressBar progress={uploadProgress[0]} status={uploadProgress[1]} label={uploadProgress[2]} />
      )}
    </Container>
  );
};

// ProgressBar component with props progress, total, label
const ProgressBar = ({progress = 0, status, label = ''}: {progress: number; status?: string; label: string}) => {
  return (
    <Container
      style={{
        width: '100%',
        height: '0.5rem',
        backgroundColor: '#E5E5E5',
        borderRadius: '0.25rem',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Container
        style={{
          width: `${(progress / 100) * 100}%`,
          height: '100%',
          backgroundColor: '#00A6FF',
          borderRadius: '0.25rem',
          flex: 1,
        }}
      />
      <Container
        style={{
          // color: '#00A6FF',
          flex: 1,
        }}
      >
        {label} {status}
      </Container>
    </Container>
  );
};
