import {get, map} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faTrash from 'app/fontawesome-pro-light/faTrash';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';

import faSearch from 'app/fontawesome-pro-light/faSearch';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {deleteFirm, getAllByCurrentUserPlanSponsor, getAllFirmsForSettingsPaged} from 'app/redux/firm';
import {Alert, Button, BootstrapTableRemote, APreventDefault} from 'app/components';
import {withViewportSize} from 'app/utilities';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {toastSuccess, toastError} from 'app/utilities/toast';
import {deleteStrategy} from 'app/redux/strategy';

/**
 * Format the content of a Held By cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
function editCellFormatter(cell, row) {
  return (
    <Link to={ROUTE.SETTINGS_FIRMS_EDIT.path(row.id)}>
      <Icon icon={faPencil} />
    </Link>
  );
}

/**
 * Format the content of a Edit cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
function editHeaderFormatter() {
  return <span className="sr-only">Edit</span>;
}

/**
 * Format the content of a Remove cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
function removeHeaderFormatter() {
  return <span className="sr-only">Delete</span>;
}

const getColumns = (removeCellFormatter, isSmallerLayout, responsiveCellFormatter) => {
  if (isSmallerLayout) {
    return [
      {
        dataField: 'userData',
        text: 'Investment Firms & Strategies',
        formatter: responsiveCellFormatter,
      },
    ];
  }

  return [
    {
      dataField: 'name',
      text: 'Firm Name',
      sort: true,
      style: {width: 250},
      headerStyle: {width: 250},
    },
    {
      dataField: 'strategyName',
      text: 'Strategy',
    },
    {
      dataField: 'uploadFrequency',
      text: 'Upload Frequency',
    },
    {
      dataField: 'email',
      text: 'Contact Email',
      style: {width: 220},
      headerStyle: {width: 220},
      classes: 'table-overflow-ellipsis',
    },
    {
      dataField: 'Edit',
      text: '',
      style: {width: 30},
      headerStyle: {width: 30},
      formatter: editCellFormatter,
      headerFormatter: editHeaderFormatter,
    },
    {
      dataField: 'Remove',
      text: '',
      style: {width: 30},
      headerStyle: {width: 30},
      formatter: removeCellFormatter,
      headerFormatter: removeHeaderFormatter,
    },
  ];
};

class SettingsFirmManagerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.search,
      firmId: props.firmId,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.changeField = this.changeField.bind(this);
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    // TODO(PeterJ): Setup for fetchifneeded for firms for drop-down
    this.props.dispatch(getAllByCurrentUserPlanSponsor('id,name'));

    this.fetchData(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.props.search,
      this.props.firmId,
    );

    animateScroll.scrollToTop();
  }

  handleDelete = strategyId => {
    return this.props
      .dispatch(deleteStrategy(strategyId))
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }

        toastSuccess('The strategy has been deleted.');

        animateScroll.scrollToTop();

        // update querystring which will force update of data
        this.updateCurrentQueryString();
      })
      .catch(() => {
        animateScroll.scrollToTop();
        toastError('Error deleting strategy.');
      });
  };

  /**
   * Event handler for when the delete icon / link is clicked.  Opens the delete fimr modal
   * @param {Object} firm - The firm for the row the delete button is clicked on
   */
  onDeleteClick(firm) {
    const self = this;
    this.props.dispatch(
      openSimpleModal({
        title: 'Delete Strategy From Plan',
        message: `Are you sure you want to remove <strong>${firm.strategyName}</strong> from your plan?`,
        buttons: [
          {
            label: 'Cancel',
            close: true,
          },
          {
            className: 'btn-red ml-auto',
            label: 'Yes, Remove Strategy',
            clickHandler: () => self.handleDelete(firm.strategyId),
            close: true,
          },
        ],
      }),
    );
  }

  /**
   * Format the content of a delete cell
   * @param {Array} cell - The content to be used for outputting
   * @param {Object} row - The content used for the row
   * @returns {Any} - The HTML to display
   */
  removeCellFormatter = (cell, row) => {
    return (
      <APreventDefault className="text-danger" onClick={() => this.onDeleteClick(row)}>
        <Icon icon={faTrash} />
      </APreventDefault>
    );
  };

  /**
   * Format the content of a responsive cell
   * @param {Array} cell - The content to be used for outputting
   * @param {Object} row - The content used for the row
   * @returns {Any} - The HTML to display
   */
  responsiveCellFormatter = (cell, row) => {
    return (
      <div className="bootstrap-table-card">
        <div className="table-card-title">{row.name}</div>
        <div>{row.strategyName} </div>
        <div>{row.uploadFrequency}</div>
        <div>{row.email}</div>
        <div className="d-flex mt-3">
          <div className="mr-4">
            <Link to={ROUTE.SETTINGS_FIRMS_EDIT.path(row.id)}>
              <Icon icon={faPencil} /> Edit
            </Link>
          </div>
          <div>
            <APreventDefault href="#" className="text-danger" onClick={() => this.onDeleteClick(row)}>
              <Icon icon={faTrash} /> Delete
            </APreventDefault>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Intermediate function called from changeField when the firm dropdown is used.
   * State is not update dby the time updateCurrentQueryString is called, so pass it in manually
   */
  updateCurrentQueryStringByUserType(firmId) {
    this.updateCurrentQueryString(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.state.search,
      firmId,
    );
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
    firmId = this.state.firmId,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search) {
      updatedPage = 1;
    }

    // Setup to pass extra filters only if they have data
    const extraFilters = {};
    if (firmId) {
      extraFilters.firmId = firmId;
    }

    this.props.dispatch(
      push({
        search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search, extraFilters),
      }),
    );
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    if (event.target.id === 'firmId') {
      this.updateCurrentQueryStringByUserType(event.target.value);
    }
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      // data,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
      // filters,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search} = this.props;

    this.props.dispatch(
      push({
        pathname: ROUTE.SETTINGS_FIRMS_EDIT.path(row.id),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search),
      }),
    );
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleSearch = () => {
    this.updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search, firmId) => {
    this.props.dispatch(
      getAllFirmsForSettingsPaged({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        firmId,
      }),
    );
  };

  render() {
    const {data, hasError, sortField, sortOrder, page, pageSize, totalSize, firmFilters, viewportWidth} = this.props;

    const isLayoutSmall = viewportWidth < 1020;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <Fragment>
        <div className="d-flex justify-content-between mb-8">
          <h1>{ROUTE.SETTINGS_FIRMS_MANAGER.title}</h1>
          <div>
            <Link className="btn btn-outline" to={ROUTE.SETTINGS_FIRMS_ADD.path()}>
              Add New Firms with Strategies
            </Link>
          </div>
        </div>

        {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

        {this.props.data && (
          <Fragment>
            <form method="GET" onSubmit={this.handleSearch}>
              <div className="bootstrap-table-controls">
                <div className="bootstrap-table-search">
                  <div className="form-control-and-icon">
                    <input
                      className="form-control is-subtle"
                      type="text"
                      id="search"
                      name="search"
                      placeholder="Search by Firm, Strategy Name or Email"
                      autoComplete="firm-search"
                      value={this.state.search}
                      onChange={this.changeField}
                    />
                    <div className="icon">
                      <Icon icon={faSearch} />
                    </div>
                  </div>
                  <div>
                    <Button color="gray" type="submit">
                      Search
                    </Button>
                  </div>
                </div>
                <div className="bootstrap-table-filter">
                  <label className="col-form-label">Filter Firms</label>
                  <select
                    id="firmId"
                    name="firmId"
                    value={this.state.firmId}
                    onChange={this.changeField}
                    className="custom-select form-control"
                  >
                    <option value="">Select</option>
                    {map(firmFilters, (option, i) => (
                      <option key={i} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
            <BootstrapTableRemote
              keyField="strategyId"
              columns={getColumns(this.removeCellFormatter, isLayoutSmall, this.responsiveCellFormatter)}
              data={data}
              page={page}
              sizePerPage={pageSize}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              defaultSorted={defaultSorted}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

SettingsFirmManagerPage.defaultProps = {
  data: [],
  totalSize: 0,
};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(
    ownProps,
    {
      search: '',
      page: 1,
      pageSize: 25,
      sortField: 'name',
      sortOrder: 'asc',
    },
    ['firmId'],
  );
  newProps.data = get(state, 'firm.allSettingsFirms');
  // if (newProps.data && newProps.data.length === 1) {
  //   // add 1 as fix for pagination
  //   newProps.data.push({ id: '-1' });
  // }
  newProps.totalSize = get(state, 'firm.allSettingsFirmsTotal');
  newProps.hasError = get(state, 'firm.hasError');
  newProps.isFetching = get(state, 'firm.isFetching');
  newProps.firmFilters = get(state, 'firm.allPlanSponsorFirms');

  return newProps;
};

export const SettingsFirmManager = compose(connect(mapStateToProps), withViewportSize)(SettingsFirmManagerPage);
