import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import faUsdCircle from 'app/fontawesome-pro-light/faUsdCircle';
import {DATA_CATEGORY} from '../constants';
import {IssueDataTableRow} from '.';
import {reportingYears} from 'v2/utilities/helpers/reportingYears';

const IssueDataTable = ({category, data}) => {
  const getHeaderGradient = () => {
    switch (category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return 'linear-gradient(to right, #27A37D, #34B991)';
      case DATA_CATEGORY.SOCIAL:
        return 'linear-gradient(to right, #008BB7, #009BCC)';
      case DATA_CATEGORY.GOVERNANCE:
        return 'linear-gradient(to right, #034682, #003462)';
      case DATA_CATEGORY.FINANCIAL:
        return 'linear-gradient(to right, #7E7E7E, #6D6D6D)';
      default:
        return '';
    }
  };

  const renderIcon = () => {
    switch (category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return <Icon icon={faSeedling} className="header-icon" />;
      case DATA_CATEGORY.SOCIAL:
        return <Icon icon={faHandHeart} className="header-icon" />;
      case DATA_CATEGORY.GOVERNANCE:
        return <Icon icon={faLandmark} className="header-icon" />;
      case DATA_CATEGORY.FINANCIAL:
        return <Icon icon={faUsdCircle} className="header-icon" />;
      default:
        return <div />;
    }
  };

  const styles = {
    tableHeader: {
      background: getHeaderGradient(),
    },
  };

  const keyYears = reportingYears();

  return (
    <div className="issue-data-group">
      <div className="issue-data-group-header" style={styles.tableHeader}>
        <table>
          <thead>
            <tr>
              <th
                className="title-col"
                style={{width: '14%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
              >
                {renderIcon()}
                <span className="issue-data-group-title">{category}</span>
              </th>
              <th className="restate-col" style={{width: '8%'}} />
              {keyYears.map(year => (
                <th className="data-col" key={year} style={{width: '10.5%'}}>
                  {year}
                </th>
              ))}
              <th className="data-col" style={{textAlign: 'right', width: '14%'}}>
                Change {keyYears[keyYears.length - 2]}/{keyYears[keyYears.length - 1]}
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="issue-data-table-body">
        <table style={{tableLayout: 'fixed'}}>
          <thead style={{height: 0}}>
            <tr>
              <th className="title-col" style={{width: '15%'}} />
              <th className="restate-col" style={{width: '8%'}} />
              {keyYears.map(year => (
                <th className="data-col" key={year} style={{width: '11%'}} />
              ))}
              <th className="data-col" style={{width: '14%'}} />
            </tr>
          </thead>
          <tbody>
            {data.map((datum, i) => {
              return <IssueDataTableRow key={`data-table-${i}`} data={datum} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

IssueDataTable.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export {IssueDataTable};
