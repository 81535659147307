import {Theme} from '../theme';

export const SeparationOfPowersPolicyIcon = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M4.14062 20H15.8594C16.8287 20 17.6172 19.2115 17.6172 18.2422V5.85938H13.5156C12.5463 5.85938 11.7578 5.07086 11.7578 4.10156V0H4.14062C3.17133 0 2.38281 0.788516 2.38281 1.75781V18.2422C2.38281 19.2115 3.17133 20 4.14062 20ZM6.48438 8.24219H13.5156C13.8395 8.24219 14.1016 8.50426 14.1016 8.82812C14.1016 9.15199 13.8395 9.41406 13.5156 9.41406H6.48438C6.16051 9.41406 5.89844 9.15199 5.89844 8.82812C5.89844 8.50426 6.16051 8.24219 6.48438 8.24219ZM6.48438 10.5859H13.5156C13.8395 10.5859 14.1016 10.848 14.1016 11.1719C14.1016 11.4957 13.8395 11.7578 13.5156 11.7578H6.48438C6.16051 11.7578 5.89844 11.4957 5.89844 11.1719C5.89844 10.848 6.16051 10.5859 6.48438 10.5859ZM6.48438 12.9297H13.5156C13.8395 12.9297 14.1016 13.1918 14.1016 13.5156C14.1016 13.8395 13.8395 14.1016 13.5156 14.1016H6.48438C6.16051 14.1016 5.89844 13.8395 5.89844 13.5156C5.89844 13.1918 6.16051 12.9297 6.48438 12.9297ZM6.48438 15.2734H11.1719C11.4957 15.2734 11.7578 15.5355 11.7578 15.8594C11.7578 16.1832 11.4957 16.4453 11.1719 16.4453H6.48438C6.16051 16.4453 5.89844 16.1832 5.89844 15.8594C5.89844 15.5355 6.16051 15.2734 6.48438 15.2734Z" />
      <path d="M13.5156 4.68744H17.2739L12.9297 0.343262V4.1015C12.9297 4.42479 13.1923 4.68744 13.5156 4.68744Z" />
    </svg>
  );
};
