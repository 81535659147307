import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import {Bar} from 'react-chartjs-2';

import {Theme} from '../atoms/theme';
import {ChartWithoutLabelProps, ColoredBarChartProps, ProgressBarChartProps} from '../interfaces/ChartProps';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const ColorBarChart: React.FC<ColoredBarChartProps> = ({
  labels,
  data,
  colors,
  breakLabels,
  onClick,
  barThickness = 45,
}) => {
  const chartData = {
    labels: breakLabels ? labels.map(label => label.split(' ')) : labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderRadius: 4,
        barThickness: barThickness,
      },
    ],
  };

  return (
    <Bar
      options={{
        responsive: true,
        //@ts-ignore
        onClick: onClick ? e => onClick(e) : null,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: breakLabels ? 0 : 70,
              minRotation: 0,
            },
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              //@ts-ignore
              stepSize: 400,
              color: Theme.colors.grey_4,
            },
            grid: {
              drawBorder: false,
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            displayColors: false,
            backgroundColor: Theme.colors.white,
            borderColor: Theme.colors.grey_3,
            borderWidth: 1,
            yAlign: 'bottom',
            bodyColor: Theme.colors.black,
            padding: {left: 20, right: 20, top: 6, bottom: 6},
            callbacks: {
              title: () => '',
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      }}
      //@ts-ignore
      data={chartData}
    />
  );
};

export const SmallBarChart: React.FC<ChartWithoutLabelProps> = ({data, colors, onClick}) => {
  const chartData = {
    labels: data.map(() => ''),
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderRadius: 4,
        barThickness: 31,
      },
    ],
  };

  return (
    <Bar
      options={{
        //@ts-ignore
        onClick: onClick,
        responsive: true,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              stepSize: 400,
              color: Theme.colors.grey_4,
            },
            grid: {
              display: true,
              drawBorder: false,
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            displayColors: false,
            backgroundColor: Theme.colors.white,
            borderColor: Theme.colors.grey_3,
            borderWidth: 1,
            yAlign: 'bottom',
            bodyColor: Theme.colors.black,
            padding: {left: 20, right: 20, top: 6, bottom: 6},
            callbacks: {
              title: () => '',
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        //@ts-ignore
      }}
      data={chartData}
    />
  );
};

export const ProgressBarChart: React.FC<ProgressBarChartProps> = ({
  labels,
  data,
  colors,
  dataTotals,
  colorsTotals,
  breakLabels,
  onClick,
  barThickness = 45,
  isAnimated = undefined,
}) => {
  const chartData = {
    labels: breakLabels ? labels.map(label => label.split(' ')) : labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderRadius: 4,
        barThickness: barThickness,
      },
      {
        data: [dataTotals[0] - data[0]],
        backgroundColor: colorsTotals,
        borderRadius: 4,
        barThickness: barThickness,
      },
    ],
  };

  return (
    <Bar
      options={{
        responsive: true,
        //@ts-ignore
        animation: isAnimated,
        //@ts-ignore
        onClick: onClick ? e => onClick(e) : null,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: breakLabels ? 0 : 70,
              minRotation: 0,
            },
            grid: {
              display: false,
            },
            stacked: true,
          },
          y: {
            ticks: {
              stepSize: 400,
              color: Theme.colors.grey_4,
            },
            grid: {
              drawBorder: false,
            },
            stacked: true,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            displayColors: false,
            backgroundColor: Theme.colors.white,
            borderColor: Theme.colors.grey_3,
            borderWidth: 1,
            yAlign: 'bottom',
            bodyColor: Theme.colors.black,
            padding: {left: 20, right: 20, top: 6, bottom: 6},
            callbacks: {
              title: () => '',
              label: (e: any) => {
                if (e.datasetIndex === 0) {
                  return 'Engaged: ' + e?.formattedValue;
                }
                let total = e?.parsed?._stacks.y[1] + e?.parsed?._stacks.y[0];
                return 'Total: ' + total;
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      }}
      //@ts-ignore
      data={chartData}
    />
  );
};
