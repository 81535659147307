export const zeroCompensation = {
  id: 'zeroCompensation',

  beforeDatasetsDraw(chart) {
    const minBarHeight = 5;

    const dataSets = chart.config.data.datasets;

    dataSets?.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach(d => {
        const barHeight = d?._view?.base - d?._view?.y;
        if (Math.abs(barHeight) < minBarHeight) {
          // eslint-disable-next-line no-param-reassign
          d._view.y = d._view.base - minBarHeight * (Math.sign(barHeight) || 1);
        }
      });
    });
  },
};
