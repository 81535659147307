import React, {Component} from 'react';
import {keyBy, chunk, get} from 'lodash';
import {LegendItem, PageContainer, BarChartPanel} from '../components';

class ESGMeasuresBarGraphPage extends Component {
  formatData = (data, chartMetrics) => {
    return keyBy(
      data.filter(datum => chartMetrics.includes(datum.reportMetricId)),
      'reportMetricId',
    );
  };

  render() {
    const {companyName, title, chartMetrics, subjectHeader, targetHeader, subjectData, targetData} = this.props;

    const chartMetricsChunked = chunk(chartMetrics, 3);

    const subject = this.formatData(subjectData, chartMetrics);

    const target = this.formatData(targetData, chartMetrics);

    return (
      <PageContainer
        headerType={PageContainer.HEADER_TYPE.TYPE_2}
        companyName={companyName}
        pageNumber={this.props.page}
      >
        <h2 className={'text-uppercase'}>ESG Measures</h2>
        <div className={'w-100 d-flex justify-content-between'}>
          <h3 className={'text-uppercase'}>{title}</h3>
          <div>
            <LegendItem title={subjectHeader} color={'#34B991'} />
            <LegendItem title={targetHeader} color={'#034682'} />
          </div>
        </div>
        {chartMetricsChunked.map((currentChunk, i) => {
          return (
            <div className={'d-flex w-100 justify-content-between'} style={{marginTop: i === 0 ? '30px' : '40px'}}>
              {currentChunk.map(metric => {
                const {name, unit, description} = target[metric].reportMetric;
                return (
                  <BarChartPanel
                    title={name}
                    unit={unit}
                    description={description}
                    widthPercent={30}
                    aspectRatio={150 / 169}
                    datasets={[[get(subject, `${metric}.value`)], [get(target, `${metric}.value`)]]}
                  />
                );
              })}
              {currentChunk.length < 3 &&
                [...Array(3 - currentChunk.length).keys()].map(() => {
                  return <BarChartPanel empty widthPercent={30} aspectRatio={150 / 169} />;
                })}
            </div>
          );
        })}
      </PageContainer>
    );
  }
}

export {ESGMeasuresBarGraphPage};
