import PropTypes from 'prop-types';
import React from 'react';

import {COLOR} from 'app/constants';
import {imgSrcFromSvgPath} from 'app/utilities';

const RectangleImage = ({fill, onRef, ...props}) => {
  const svgString =
    typeof fill === 'string'
      ? `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="none" version="1.1" viewBox="0 0 100 100" fill="${fill}">
    <rect x="0" y="0" width="100" height="100"/>
  </svg>`
      : `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="none" version="1.1" viewBox="0 0 100 100">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style="stop-color:${fill[0]}" />
        <stop offset="100%" style="stop-color:${fill[1]}" />
      </linearGradient>
    </defs>
    <rect x="0" y="0" width="100" height="100" fill="url(#gradient)" />
  </svg>`;

  return <img src={imgSrcFromSvgPath(svgString)} ref={onRef} {...props} />;
};

RectangleImage.propTypes = {
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onRef: PropTypes.any,
};

RectangleImage.defaultProps = {fill: [COLOR.DARK_GREEN, COLOR.NAVY]};

export {RectangleImage};
