import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchIfNeeded} from 'app/services/http';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_ALL_REQUEST: 'ISSUE_GET_ALL_REQUEST',
  GET_ALL_RECEIVE: 'ISSUE_GET_ALL_RECEIVE',
};

const getAllRequest = createAction(ACTION.GET_ALL_REQUEST);
const getAllReceive = createAction(ACTION.GET_ALL_RECEIVE);

const api = {get: () => fetchAuthJSON('issue', {method: 'get'})};

export function fetchIssues() {
  return dispatch => {
    dispatch(getAllRequest());
    return dispatch(getAllReceive(api.get()));
  };
}

export function fetchIssuesIfNeeded() {
  return fetchIfNeeded('issue', 'issues', () => fetchIssues());
}

// REDUCER
export const issue = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_ALL_REQUEST:
      return Object.assign({}, state, {isFetching: true});
    case ACTION.GET_ALL_RECEIVE:
      return Object.assign({}, state, {issues: action.payload, isFetching: false});
    default:
      return state;
  }
};
