import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchIfNeeded} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {hasFetchedEditPlanSponsor: false, isFetching: false};

// ACTION
export const ACTION = {
  GET_BY_CURRENT_USER_REQUEST: 'PLAN_SPONSOR_GET_BY_CURRENT_USER_REQUEST',
  GET_BY_CURRENT_USER_RECEIVE: 'PLAN_SPONSOR_GET_BY_CURRENT_USER_RECEIVE',
  GET_BY_CURRENT_USERS_FIRM_REQUEST: 'PLAN_SPONSOR_GET_BY_CURRENT_USERS_FIRM_REQUEST',
  GET_BY_CURRENT_USERS_FIRM_RECEIVE: 'PLAN_SPONSOR_GET_BY_CURRENT_USERS_FIRM_RECEIVE',
  LIST_ALL_REQUEST: 'PLAN_SPONSOR_LIST_ALL_REQUEST',
  LIST_ALL_RECEIVE: 'PLAN_SPONSOR_LIST_ALL_RECEIVE',
  LIST_ALL_REQUEST_SILENT: 'PLAN_SPONSOR_LIST_ALL_REQUEST_SILENT',
  LIST_ALL_RECEIVE_SILENT: 'PLAN_SPONSOR_LIST_ALL_RECEIVE_SILENT',
  UPDATE_PLAN_BY_CURRENT_USER_REQUEST: 'PLAN_SPONSOR_UPDATE_BY_CURRENT_USER_REQUEST',
  UPDATE_PLAN_BY_CURRENT_USER_RECEIVE: 'PLAN_SPONSOR_UPDATE_BY_CURRENT_USER_RECEIVE',
  GET_ENGAGEMENTS_FOR_CURRENT_USER_REQUEST: 'GET_ENGAGEMENTS_FOR_CURRENT_USER_REQUEST',
  GET_ENGAGEMENTS_FOR_CURRENT_USER_RECEIVE: 'GET_ENGAGEMENTS_FOR_CURRENT_USER_RECEIVE',
  GET_ESG_MEASURES_FOR_CURRENT_USER_REQUEST: 'GET_ESG_MEASURES_FOR_CURRENT_USER_REQUEST',
  GET_ESG_MEASURES_FOR_CURRENT_USER_RECEIVE: 'GET_ESG_MEASURES_FOR_CURRENT_USER_RECEIVE',
};

const planSponsorForCurrentUserRequest = createAction(ACTION.GET_BY_CURRENT_USER_REQUEST);
const planSponsorForCurrentUserReceive = createAction(ACTION.GET_BY_CURRENT_USER_RECEIVE);
const planSponsorForCurrentUsersFirmRequest = createAction(ACTION.GET_BY_CURRENT_USERS_FIRM_REQUEST);
const planSponsorForCurrentUsersFirmReceive = createAction(ACTION.GET_BY_CURRENT_USERS_FIRM_RECEIVE);
const planSponsorListAllRequest = createAction(ACTION.LIST_ALL_REQUEST);
const planSponsorListAllReceive = createAction(ACTION.LIST_ALL_RECEIVE);
const planSponsorListAllRequestSilent = createAction(ACTION.LIST_ALL_REQUEST_SILENT);
const planSponsorListAllReceiveSilent = createAction(ACTION.LIST_ALL_RECEIVE_SILENT);
const planSponsorUpdateByCurrentUserRequest = createAction(ACTION.UPDATE_PLAN_BY_CURRENT_USER_REQUEST);
const planSponsorUpdateByCurrentUserReceive = createAction(ACTION.UPDATE_PLAN_BY_CURRENT_USER_RECEIVE);
const planSponsorEngagementsForCurrentUserRequest = createAction(ACTION.GET_ENGAGEMENTS_FOR_CURRENT_USER_REQUEST);
const planSponsorEngagementsForCurrentUserReceive = createAction(ACTION.GET_ENGAGEMENTS_FOR_CURRENT_USER_RECEIVE);
const esgMeasuresForCurrentUserRequest = createAction(ACTION.GET_ESG_MEASURES_FOR_CURRENT_USER_REQUEST);
const esgMeasuresForCurrentUserReceive = createAction(ACTION.GET_ESG_MEASURES_FOR_CURRENT_USER_RECEIVE);

const api = {
  get: areUserCountsIncluded =>
    fetchAuthJSON(`planSponsor${areUserCountsIncluded ? '?expand=user.count' : ''}`, {method: 'get'}),
  getForCurrentUser: () => fetchAuthJSON('planSponsor/user', {method: 'get'}),
  updateByCurrentUser: values =>
    fetchAuthJSON('planSponsor/user', {
      method: 'put',
      body: JSON.stringify(values),
    }),
  getForCurrentUsersFirm: () => fetchAuthJSON('planSponsor/currentByFirm', {method: 'get'}),
  getEngagementsForCurrentUser: () =>
    fetchAuthJSON('planSponsor/current/holdings/engagements/grouped', {method: 'get'}),
  getEsgMeasuresForCurrentUser: () => fetchAuthJSON('planSponsor/current/esgMeasures', {method: 'get'}),
};

export function getPlanSponsorForCurrentUser() {
  return dispatch => {
    dispatch(planSponsorForCurrentUserRequest());
    return dispatch(planSponsorForCurrentUserReceive(api.getForCurrentUser()));
  };
}

export function getPlanSponsorForCurrentUsersFirm() {
  return dispatch => {
    dispatch(planSponsorForCurrentUsersFirmRequest());
    return dispatch(planSponsorForCurrentUsersFirmReceive(api.getForCurrentUsersFirm()));
  };
}

export function fetchPlanSponsors({areUserCountsIncluded = false, isFetchSilent = false} = {}) {
  const listAllRequest = isFetchSilent ? planSponsorListAllRequestSilent : planSponsorListAllRequest;
  const listAllReceive = isFetchSilent ? planSponsorListAllReceiveSilent : planSponsorListAllReceive;
  return dispatch => {
    dispatch(listAllRequest({areUserCountsIncluded}));
    return dispatch(listAllReceive(api.get(areUserCountsIncluded)));
  };
}

export function fetchPlanSponsorsIfNeeded({areUserCountsIncluded = false} = {}) {
  return fetchIfNeeded('planSponsor', 'planSponsors', () => fetchPlanSponsors({areUserCountsIncluded}));
}

export function fetchCurrentPlanSponsorIfNeeded() {
  return fetchIfNeeded('planSponsor', 'editPlanSponsor', () => getPlanSponsorForCurrentUser());
}

export function updatePlanSponsorByCurrentUser(payload) {
  return dispatch => {
    dispatch(planSponsorUpdateByCurrentUserRequest());
    return dispatch(planSponsorUpdateByCurrentUserReceive(api.updateByCurrentUser(payload)));
  };
}

export function getEngagementsForCurrentUser() {
  return dispatch => {
    dispatch(planSponsorEngagementsForCurrentUserRequest());
    return dispatch(planSponsorEngagementsForCurrentUserReceive(api.getEngagementsForCurrentUser()));
  };
}

export function getEsgMeasuresForCurrentUser() {
  return dispatch => {
    dispatch(esgMeasuresForCurrentUserRequest());
    return dispatch(esgMeasuresForCurrentUserReceive(api.getEsgMeasuresForCurrentUser()));
  };
}

// REDUCER
export const planSponsor = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.LIST_ALL_REQUEST_SILENT:
    case ACTION.LIST_ALL_REQUEST:
    case ACTION.GET_BY_CURRENT_USER_REQUEST:
    case ACTION.GET_BY_CURRENT_USERS_FIRM_REQUEST:
    case ACTION.GET_ENGAGEMENTS_FOR_CURRENT_USER_REQUEST:
    case ACTION.GET_ESG_MEASURES_FOR_CURRENT_USER_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.LIST_ALL_RECEIVE_SILENT:
    case ACTION.LIST_ALL_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, {planSponsors: payload}));
    case ACTION.GET_BY_CURRENT_USERS_FIRM_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, {planSponsorsByFirm: payload}));
    case ACTION.GET_BY_CURRENT_USER_RECEIVE:
    case ACTION.UPDATE_PLAN_BY_CURRENT_USER_RECEIVE:
      return assignMergedState(
        Object.assign(
          {hasFetchedEditPlanSponsor: true, isFetching: false},
          hasError ? {error: payload} : {editPlanSponsor: payload},
        ),
      );
    case ACTION.GET_ENGAGEMENTS_FOR_CURRENT_USER_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, {engagements: payload}));
    case ACTION.GET_ESG_MEASURES_FOR_CURRENT_USER_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, {esgMeasures: payload}));
    default:
      return state;
  }
};
