import {useMemo, useState, useEffect, ReactElement} from 'react';
import styled from 'styled-components/macro';
import {useTable, useSortBy, useFilters, usePagination} from 'react-table';
import {DataTableProps} from 'v2/utilities/types/components/DataTable';

import {ChevronDown, ChevronUp} from '../atoms/icons';
import {ReactTablePagination} from 'v2/components/molecules/ReactTablePagination';
import {useHistory} from 'react-router';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  width: auto;
  min-width: 56.25rem;
  margin: 2rem 4rem 0;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${props => props.theme.colors.black}26;
  border-radius: 8px;
`;

const FooterWrapper = styled.div<{slim?: boolean}>`
  margin: ${({slim}) => (slim ? '0' : '0 3rem')};
`;

const FlexRow = styled.div<{align?: string}>`
  display: flex;
  justify-content: ${({align}) => align ?? 'flex-start'};
`;

const Flexcolumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 24px 0px 14px;
`;

const TableContainer = styled.table``;

const TableHead = styled.thead`
  border: 0px;
`;

const TableHeaders = styled.th<{spaced?: boolean; slim?: boolean}>`
  white-space: nowrap;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #a3a3a3;

  padding: ${({slim}) => (slim ? '.6rem 1.875rem' : '1.625rem 0rem 1rem 0rem')};

  &:first-child {
    padding-left: ${({spaced}) => (spaced ? '1.875rem' : 0)};
  }

  &:last-child {
    float: right;
    padding-right: ${({spaced}) => (spaced ? '0.09rem' : 0)};
  }
`;

const TableRow = styled.tr<{noHover?: boolean}>`
  border-bottom: 1px #d0d6de solid;
  cursor: ${({noHover}) => (noHover ? 'default' : 'pointer')};

  &:first-of-type {
    border-top: 1px #d0d6de solid;
  }

  &:last-of-type {
    border: none;
  }

  &:hover {
    background-color: ${({theme, noHover}) => (noHover ? 'transparent' : theme.colors.grey_2)};
  }
`;

const TableCell = styled.td<{spaced?: boolean; slim?: boolean}>`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #464255;

  padding: ${({spaced, slim}) => (spaced ? '1rem 1.5rem 1rem 0rem' : slim ? '.6rem 1.875rem' : '1rem 0rem 1rem 0rem')};

  &:first-child {
    padding-left: ${({spaced}) => (spaced ? '1.875rem' : 0)};
  }

  &:last-child {
    padding-left: ${({spaced}) => (spaced ? '1.875rem' : 0)};
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 1.5625rem;
  flex: 1;
`;

const Wrapper = ({hideCard, children}: {hideCard: boolean; children: ReactElement}) => {
  if (!hideCard) {
    return <CardWrapper>{children}</CardWrapper>;
  }

  return <>{children}</>;
};

export const DataTable = ({
  data = [],
  columns = [],
  withSorting = false,
  spaced = false,
  withHover = false,
  path,
  hideCard = false,
  withPagination = false,
  slim = false,
  initialState = {},
}: DataTableProps) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, []);
  const history = useHistory();
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      disableSortBy: !withSorting,
      initialState: initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const handleRowClick = (id: string | number) => {
    if (path) {
      history.push(path(id));
    }
  };

  const activePage = pageIndex + 1;

  const filterPages = (visiblePages: number[], pageCount: number) => {
    return visiblePages.filter((page: number) => page <= pageCount);
  };

  const getVisiblePages = (page: number) => {
    if (pageCount < 7) {
      return filterPages([1, 2, 3, 4, 5], pageCount);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < pageCount) {
        return [1, page - 1, page, page + 1, pageCount];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= pageCount) {
        return [1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      } else {
        return [1, 2, 3, 4, 5, pageCount];
      }
    }
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(activePage);

    setVisiblePages(visiblePages);
  }, [activePage, pageCount]);

  const dataLength = memoizedData.length;

  return (
    <>
      <Wrapper hideCard={hideCard}>
        <CardContainer>
          <TableContainer {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()} noHover>
                  {headerGroup.headers.map(column => {
                    return (
                      <TableHeaders
                        {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                        style={(column as any).style}
                        spaced={spaced}
                        slim={slim}
                      >
                        <FlexRow>
                          {column.render('Header')}
                          <span>
                            {withSorting &&
                              (column.isSorted ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <>
                                      <Flexcolumn>
                                        <ChevronUp size={10} color="#A3A3A3" />
                                        <ChevronDown size={10} color="#000000" />
                                      </Flexcolumn>
                                    </>
                                  ) : (
                                    <>
                                      <Flexcolumn>
                                        <ChevronUp size={10} color="#000000" />
                                        <ChevronDown size={10} color="#A3A3A3" />
                                      </Flexcolumn>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <Flexcolumn>
                                      <ChevronUp size={10} color="#A3A3A3" />
                                      <ChevronDown size={10} color="#A3A3A3" />
                                    </Flexcolumn>
                                  </>
                                )
                              ) : (
                                <>
                                  <Flexcolumn>
                                    <ChevronUp size={10} color="#A3A3A3" />
                                    <ChevronDown size={10} color="#A3A3A3" />
                                  </Flexcolumn>
                                </>
                              ))}
                          </span>
                        </FlexRow>
                      </TableHeaders>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => handleRowClick((row.original as any).id ?? '')}
                    noHover={!withHover}
                  >
                    {row.cells.map(cell => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            style: (cell as any).column.style,
                          })}
                          spaced={spaced}
                          slim={slim}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </tbody>
          </TableContainer>
        </CardContainer>
      </Wrapper>
      {dataLength > 0 && withPagination && (
        <FooterWrapper slim={slim}>
          {/*ts-ignore */}
          <ReactTablePagination
            {...{
              canPreviousPage,
              canNextPage,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              visiblePages,
              pageSize,
              activePage,
              dataLength,
              slim,
              allowPageSizeChange: false,
            }}
          />
        </FooterWrapper>
      )}
    </>
  );
};
