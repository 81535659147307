import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {SpinnerDisplay} from './SpinnerDisplay';

class SpinnerComponent extends Component {
  render() {
    const {pendingTasks} = this.props;
    return <SpinnerDisplay isActive={pendingTasks > 0} />;
  }
}

const mapStateToProps = state => ({pendingTasks: state.pendingTasks});

SpinnerComponent.propTypes = {pendingTasks: PropTypes.number};

export const Spinner = connect(mapStateToProps)(SpinnerComponent);
