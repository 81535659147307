import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {map} from 'lodash';
import cn from 'classnames';

import {RadioDot} from 'app/components';

const RadioLineItem = ({input, label, disabled}) => {
  return (
    <Fragment>
      <RadioDot tag="label" shadowed={false} className={cn('radio-line-item radio-dot', {'is-blue': input.checked})}>
        <input type="radio" {...input} disabled={disabled} />
        {label}
      </RadioDot>
    </Fragment>
  );
};
RadioLineItem.propTypes = {
  input: PropTypes.object,
  label: PropTypes.node,
};

const RadioLine = ({className, name, options, optionValueProp, optionLabelProp, disabled}) => {
  let radioOptions = options.slice(0); // clone the array
  if (options.length > 0 && typeof options[0] === 'string') {
    radioOptions = map(radioOptions, option => ({
      [optionLabelProp]: option,
      [optionValueProp]: option,
    }));
  }
  return (
    <div className={cn('radio-line', className)} style={disabled ? {pointerEvents: 'none'} : null}>
      {map(radioOptions, (option, index) => (
        <Field key={index} name={name} type="radio" component={RadioLineItem} {...option} disabled={disabled} />
      ))}
    </div>
  );
};
RadioLine.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  optionValueProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
};

RadioLine.defaultProps = {
  optionValueProp: 'value',
  optionLabelProp: 'label',
};

export {RadioLine};
