/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import {toastError} from 'app/utilities/toast';
import JsZip from 'jszip';
import {saveAs} from 'file-saver';
import {getAnnualPendingIssueData} from '../redux/companyAnnualIssues';
import {getRequestData} from '../redux/percentileRanking';
import {MedalDescriptions, BadgeDisplay} from '../components';
import {getIssueBadgeAssetUrl, getRatingBadgeAssetUrl} from '../utilities';
import {generatePdfFromPage} from '../utilities/generatePdfFromPage';
import {CompanyReport} from '../pdf-pages';
import {getCompanyBadges} from '../utilities/getCompanyBadges';
import {DATA_CATEGORY} from '../constants';

class CompanyRatingSystemPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadingBadges: false,
      generatingReport: false,
    };

    if (props.authUser) {
      props.dispatch(getAnnualPendingIssueData(this.props.companyPortals[0].id));
      props.dispatch(getRequestData(this.props.companyPortals[0].id));
    }
  }

  downloadBadges = async () => {
    this.setState({downloadingBadges: true});

    const companyBadges = getCompanyBadges(this.props.companyAnnualIssues);
    companyBadges.push(getRatingBadgeAssetUrl(this.props.companyPortals[0].rating));

    if (companyBadges.length > 0) {
      const zip = new JsZip().folder('harmony_analytics_badges');

      await Promise.all(
        companyBadges.map(async url => {
          const response = await fetch(url);
          const imageBlob = await response.blob();
          const fileName = url.split('/').pop();
          const imageFile = new File([imageBlob], fileName);
          zip.file(fileName, imageFile);
        }),
      );

      const zipFile = await zip.generateAsync({type: 'blob'});
      saveAs(zipFile, 'harmony_analytics_badges.zip');
    } else {
      toastError(`
      It looks like your company hasn't earned any badges yet.
      If you believe this is a mistake, please visit the "Your Data" page to update your company's issue data.
      `);
    }

    this.setState({downloadingBadges: false});
  };

  generateReport = async () => {
    const {companyPortals, companyAnnualIssues, percentileRanking} = this.props;
    this.setState({generatingReport: true});
    await generatePdfFromPage(
      <CompanyReport
        companyPortal={companyPortals[0]}
        companyAnnualIssues={companyAnnualIssues}
        percentileRanking={percentileRanking}
      />,
    );
    this.setState({generatingReport: false});
  };

  renderBadges = () => {
    if (this.props.companyAnnualIssues) {
      const companyBadges = getCompanyBadges(this.props.companyAnnualIssues);

      return companyBadges.map(url => (
        <div className="company-rating-award-badge flex-grow-0">
          <img crossOrigin="anonymous" src={url} />
        </div>
      ));
    }

    return <React.Fragment />;
  };

  render() {
    const medalDescriptions = [
      {
        image: getRatingBadgeAssetUrl(1),
        title: 'HARMONY PLATINUM',
        description:
          'Platinum medal is the highest award level and granted to top rated companies in the Harmony universe.',
      },
      {
        image: getRatingBadgeAssetUrl(2),
        title: 'HARMONY GOLD',
        description:
          'Gold medal is the second highest level and awarded to companies that have high performing ESG issues in the Harmony universe.',
      },
      {
        image: getRatingBadgeAssetUrl(3),
        title: 'HARMONY SILVER',
        description:
          'Silver medal is the third highest level and awarded to companies that have average ESG issues amongst the Harmony universe.',
      },
      {
        image: getRatingBadgeAssetUrl(4),
        title: 'HARMONY BRONZE',
        description:
          'Bronze medal is the lowest rating and awarded to companies that have multiple ESG issues that need improvements but still outperform the substandard group.',
      },
      {
        image: getRatingBadgeAssetUrl(5),
        title: 'SUBSTANDARD',
        description:
          'These companies score the lowest and on a wide range of ESG issues and need to reevaluate their ESG approach.',
      },
    ];

    const environmentalBadges = [
      getIssueBadgeAssetUrl(11),
      getIssueBadgeAssetUrl(12),
      getIssueBadgeAssetUrl(13),
      getIssueBadgeAssetUrl(15),
      getIssueBadgeAssetUrl(16),
    ];

    const socialBadges = [
      getIssueBadgeAssetUrl(30),
      getIssueBadgeAssetUrl(31),
      getIssueBadgeAssetUrl(32),
      getIssueBadgeAssetUrl(33),
      getIssueBadgeAssetUrl(19),
      getIssueBadgeAssetUrl(20),
      getIssueBadgeAssetUrl(21),
      getIssueBadgeAssetUrl(22),
      getIssueBadgeAssetUrl(24),
    ];

    const governanceBadges = [
      getIssueBadgeAssetUrl(25),
      getIssueBadgeAssetUrl(26),
      getIssueBadgeAssetUrl(27),
      getIssueBadgeAssetUrl(28),
      getIssueBadgeAssetUrl(29),
    ];

    const {name, rating} = this.props.companyPortals[0];

    return (
      <React.Fragment>
        <div className="company-rating-backdrop position-absolute w-100" />
        <div className="padded-container company-rating-container futura">
          <div className="company-rating-card container-fluid mb-8">
            <div className="row">
              <div className="col company-rating-title-card">
                <div>
                  <span className="page-title futura-bold">HARMONY</span>
                </div>
                <div>
                  <span className="page-title futura-bold">RATING SYSTEM</span>
                </div>
                <div className="page-description">
                  <p>
                    Harmony recognizes companies that are working toward improving how they impact our environment,
                    society and the long term success of their business. Harmony awards four medals, Platinum, Gold,
                    Silver and Bronze based on companies' overall performance. We also acknowledge top performers
                    awarding badges per issue.
                  </p>
                  <p>
                    {' '}
                    Medals and badges can be utilized as a recognition of your company's overall performance and
                    excellence on specific issues. Medals and Badges can be downloaded and placed on your company's
                    website, marketing materials, annual and sustainability reports.
                  </p>
                  <p>
                    Our team will continue to work with your company and help you achieve better levels of ESG
                    performance and enhance your position.
                  </p>
                </div>
              </div>
              <div className="col company-rating-title-report flex-1">
                <div className="row company-info">
                  <div className="col">
                    <div className="sub-title text-uppercase">
                      <span>/ AWARDS</span>
                    </div>
                    <div className="company-title text-uppercase">
                      <span>{name}</span>
                    </div>
                  </div>
                  <div className="col company-rating align-items-end justify-content-end d-flex flex-grow-0 ml-5">
                    <img crossOrigin="anonymous" src={getRatingBadgeAssetUrl(rating)} alt="rating" />
                  </div>
                </div>
                <div className="company-rating-award-container d-inline-flex flex-wrap flex-row flex-grow-0 w-100 row">
                  {this.renderBadges()}
                </div>
                <div className="row company-rating-row">
                  <div className="col flex-grow-1 w-100 flex-fill mr-6">
                    <button
                      type="button"
                      className="btn btn-outline btn-block company-rating-button w-100"
                      disabled={this.state.downloadingBadges}
                      onClick={this.downloadBadges}
                    >
                      DOWNLOAD MY BADGES
                    </button>
                  </div>
                  <div className="col flex-grow-1 w-100 flex-fill">
                    {/* <button
                      type="button"
                      className={`btn btn-outline btn-block company-rating-button w-100 ${this.state.generatingReport ? 'disabled' : ''}`}
                      disabled={this.state.generatingReport}
                      onClick={this.generateReport}
                    >
                      GENERATE REPORT
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="company-rating-card flex-grow-0 mb-8">
            <div className="rating-header">
              <span>
                Medals are more than images, they represent your accomplishments, and your standings against Harmony's
                universe of thousands of global companies, highlighting the improvements your organization has made and
                the standards you're setting.
              </span>
            </div>
            <div>
              <MedalDescriptions data={medalDescriptions} />
            </div>
          </div>
          <div className="company-rating-card">
            <div className="rating-header">
              <span>
                Badges are given to companies that are top performers in a specific ESG issue. The top performers are
                considered when a company is in the top 20% of their sector or the whole Harmony universe of companies.
                These badges are given out to companies on an annual basis.
              </span>
            </div>
            <div>
              <BadgeDisplay
                data={{
                  icon: faSeedling,
                  title: DATA_CATEGORY.ENVIRONMENT,
                  badges: environmentalBadges,
                }}
              />
              <BadgeDisplay
                data={{
                  icon: faHandHeart,
                  title: DATA_CATEGORY.SOCIAL,
                  badges: socialBadges,
                }}
              />
              <BadgeDisplay
                data={{
                  icon: faLandmark,
                  title: DATA_CATEGORY.GOVERNANCE,
                  badges: governanceBadges,
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyAnnualIssues: state.companyAnnualIssues.data,
    companyPortals: state.auth.impersonatedUser
      ? state.auth.impersonatedUser.companyPortals
      : state.auth.companyPortals,
    percentileRanking: state.percentileRanking.issues,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const CompanyRatingSystem = connect(mapStateToProps, null, mergeProps)(CompanyRatingSystemPage);
