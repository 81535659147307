import {createAction} from 'redux-actions';
import {concat, find} from 'lodash';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';
import {ACTION as STRATEGY_ACTION} from './strategy';
import isNull from 'redux-actions/lib/utils/isNull';

const initialState = {
  hasError: false,
  isFetching: false,
  newPassiveStrategyIds: [],
};

// ACTION
export const ACTION = {
  CREATE_FROM_SETTINGS_REQUEST: 'FIRM_SETTINGS_CREATE_REQUEST',
  CREATE_FROM_SETTINGS_RECEIVE: 'FIRM_SETTINGS_CREATE_RECEIVE',
  DELETE_FROM_SETTINGS_REQUEST: 'FIRM_SETTINGS_DELETE_REQUEST',
  DELETE_FROM_SETTINGS_RECEIVE: 'FIRM_SETTINGS_DELETE_RECEIVE',
  GET_ALL_BY_CURRENT_USER_REQUEST: 'FIRM_GET_ALL_BY_CURRENT_USER_REQUEST',
  GET_ALL_BY_CURRENT_USER_RECEIVE: 'FIRM_GET_ALL_BY_CURRENT_USER_RECEIVE',
  GET_ALL_MANAGER_PAGED_REQUEST: 'MANAGER_GET_ALL_PAGED_REQUEST',
  GET_ALL_MANAGER_PAGED_RECEIVE: 'MANAGER_GET_ALL_PAGED_RECEIVE',
  GET_ALL_FIRM_FOR_SETTINGS_PAGED_REQUEST: 'FIRM_GET_ALL_FOR_SETTINGS_PAGED_REQUEST',
  GET_ALL_FIRM_FOR_SETTINGS_PAGED_RECEIVE: 'FIRM_GET_ALL_FOR_SETTINGS_PAGED_RECEIVE',
  GET_ALL_FIRM_PAGED_REQUEST: 'FIRM_GET_ALL_PAGED_REQUEST',
  GET_ALL_FIRM_PAGED_RECEIVE: 'FIRM_GET_ALL_PAGED_RECEIVE',
  GET_BY_CURRENT_USER_REQUEST: 'FIRM_GET_BY_CURRENT_USER_REQUEST',
  GET_BY_CURRENT_USER_RECEIVE: 'FIRM_GET_BY_CURRENT_USER_RECEIVE',
  GET_BY_ID_REQUEST: 'FIRM_GET_BY_ID_REQUEST',
  GET_BY_ID_RECEIVE: 'FIRM_GET_BY_ID_RECEIVE',
  GET_BY_ID_FOR_SETTINGS_REQUEST: 'FIRM_GET_BY_ID_FOR_SETTINGS_REQUEST',
  GET_BY_ID_FOR_SETTINGS_RECEIVE: 'FIRM_GET_BY_ID_FOR_SETTINGS_RECEIVE',
  UPDATE_REQUEST: 'FIRM_UPDATE_REQUEST',
  UPDATE_RECEIVE: 'FIRM_UPDATE_RECEIVE',
  UPDATE_BY_CURRENT_USER_REQUEST: 'FIRM_UPDATE_BY_CURRENT_USER_REQUEST',
  UPDATE_BY_CURRENT_USER_RECEIVE: 'FIRM_UPDATE_BY_CURRENT_USER_RECEIVE',
  UPDATE_FROM_SETTINGS_USER_REQUEST: 'FIRM_UPDATE_FROM_SETTINGS_USER_REQUEST',
  UPDATE_FROM_SETTINGS_USER_RECEIVE: 'FIRM_UPDATE_FROM_SETTINGS_USER_RECEIVE',
  CLEAR_NEW_PASSIVE_STRATEGY_IDS: 'FIRM_CLEAR_NEW_PASSIVE_STRATEGY_IDS',
  UPDATE_MANAGER_EMPLOYEES: 'UPDATE_MANAGER_EMPLOYEES',
};

const firmCreateFromSettingsRequest = createAction(ACTION.CREATE_FROM_SETTINGS_REQUEST);
const firmCreateFromSettingsReceive = createAction(ACTION.CREATE_FROM_SETTINGS_RECEIVE);
const firmDeleteFromSettingsRequest = createAction(ACTION.DELETE_FROM_SETTINGS_REQUEST);
const firmDeleteFromSettingsReceive = createAction(ACTION.DELETE_FROM_SETTINGS_RECEIVE);
const managersGetAllRequest = createAction(ACTION.GET_ALL_MANAGER_PAGED_REQUEST);
const managersGetAllReceive = createAction(ACTION.GET_ALL_MANAGER_PAGED_RECEIVE);
const firmsGetAllByCurrentUserRequest = createAction(ACTION.GET_ALL_BY_CURRENT_USER_REQUEST);
const firmsGetAllByCurrentUserReceive = createAction(ACTION.GET_ALL_BY_CURRENT_USER_RECEIVE);
const firmsGetAllPagedRequest = createAction(ACTION.GET_ALL_FIRM_PAGED_REQUEST);
const firmsGetAllPagedReceive = createAction(ACTION.GET_ALL_FIRM_PAGED_RECEIVE);
const firmsGetAllForSettingsPagedRequest = createAction(ACTION.GET_ALL_FIRM_FOR_SETTINGS_PAGED_REQUEST);
const firmsGetAllForSettingsPagedReceive = createAction(ACTION.GET_ALL_FIRM_FOR_SETTINGS_PAGED_RECEIVE);
const firmGetByCurrentUserRequest = createAction(ACTION.GET_BY_CURRENT_USER_REQUEST);
const firmGetByCurrentUserReceive = createAction(ACTION.GET_BY_CURRENT_USER_RECEIVE);
const firmGetByIdRequest = createAction(ACTION.GET_BY_ID_REQUEST);
const firmGetByIdReceive = createAction(ACTION.GET_BY_ID_RECEIVE);
const firmGetByIdForSettingsRequest = createAction(ACTION.GET_BY_ID_FOR_SETTINGS_REQUEST);
const firmGetByIdForSettingsReceive = createAction(ACTION.GET_BY_ID_FOR_SETTINGS_RECEIVE);
const firmUpdateRequest = createAction(ACTION.UPDATE_REQUEST);
const firmUpdateReceive = createAction(ACTION.UPDATE_RECEIVE);
const firmUpdateByCurrentUserRequest = createAction(ACTION.UPDATE_BY_CURRENT_USER_REQUEST);
const firmUpdateByCurrentUserReceive = createAction(ACTION.UPDATE_BY_CURRENT_USER_RECEIVE);
const firmUpdateFromSettingsUserRequest = createAction(ACTION.UPDATE_FROM_SETTINGS_USER_REQUEST);
const firmUpdateFromSettingsUserReceive = createAction(ACTION.UPDATE_FROM_SETTINGS_USER_RECEIVE);
const firmClearNewPassiveStrategyIds = createAction(ACTION.CLEAR_NEW_PASSIVE_STRATEGY_IDS);
const employeesDataUpdated = createAction(ACTION.UPDATE_MANAGER_EMPLOYEES);

const api = {
  deleteFromSettings: firmId => {
    return fetchAuthJSON(`firm/user/settings/${firmId}`, {method: 'delete'});
  },
  createFromSettings: values => {
    return fetchAuthJSON('firm/user/settings', {
      method: 'post',
      body: JSON.stringify(values),
    });
  },
  getAllByCurrentUser: fields => {
    let url = 'firm/user/filter';
    if (fields) {
      url += `?fields=${fields}`;
    }
    return fetchAuthJSON(url, {method: 'get'});
  },
  getAllManagersPaged: options => {
    let url = 'firm/managers/list?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    url += `&search=${options.search || ''}`;
    if (options.filter) {
      // format field:direction
      url += `&filter=${options.filter}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getAllFirmsPaged: options => {
    let url = 'firm/admin/list?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getAllFirmsForSettingsPaged: options => {
    let url = 'firm/user/settings/list?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`;
    }
    if (options.firmId) {
      url += `&firmId=${options.firmId}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getByCurrentUser: () => fetchAuthJSON('firm/user', {method: 'get'}),
  getById: firmId => fetchAuthJSON(`firm/${firmId}?fields=id,name,strategy.name`, {method: 'get'}),
  getByIdForSettings: firmId => fetchAuthJSON(`firm/user/settings/${firmId}`, {method: 'get'}),
  update: (firmId, firm) =>
    fetchAuthJSON(`firm/${firmId}`, {
      method: 'put',
      body: JSON.stringify(firm),
    }),
  updateByCurrentUser: firm =>
    fetchAuthJSON('firm/user', {
      method: 'put',
      body: JSON.stringify(firm),
    }),
  updateFromSettings: (firmId, values) => {
    return fetchAuthJSON(`firm/user/settings/${firmId}`, {
      method: 'put',
      body: JSON.stringify(values),
    });
  },
  updateEmployees: values => {
    const validateValues = values.map(value => {
      if (!value.count) {
        value.count = 0;
      }
      return value;
    });
    return fetchAuthJSON('firm/employees', {
      method: 'post',
      body: JSON.stringify(validateValues),
    });
  },
};

export function createFromSettingsFirm(name, investmentManagersToInvite) {
  return dispatch => {
    dispatch(firmCreateFromSettingsRequest(name, investmentManagersToInvite));
    return dispatch(firmCreateFromSettingsReceive(api.createFromSettings(name, investmentManagersToInvite)));
  };
}

export function deleteFirm(firmId) {
  return dispatch => {
    dispatch(firmDeleteFromSettingsRequest(firmId));
    return dispatch(firmDeleteFromSettingsReceive(api.deleteFromSettings(firmId)));
  };
}

export function getAllByCurrentUserPlanSponsor(fields) {
  return dispatch => {
    dispatch(firmsGetAllByCurrentUserRequest());
    return dispatch(firmsGetAllByCurrentUserReceive(api.getAllByCurrentUser(fields)));
  };
}

export function getAllManagersPaged(options) {
  return dispatch => {
    dispatch(managersGetAllRequest());
    return dispatch(managersGetAllReceive(api.getAllManagersPaged(options)));
  };
}

export function getAllFirmsPaged(options) {
  return dispatch => {
    dispatch(firmsGetAllPagedRequest());
    return dispatch(firmsGetAllPagedReceive(api.getAllFirmsPaged(options)));
  };
}

export function getAllFirmsForSettingsPaged(options) {
  return dispatch => {
    dispatch(firmsGetAllForSettingsPagedRequest());
    return dispatch(firmsGetAllForSettingsPagedReceive(api.getAllFirmsForSettingsPaged(options)));
  };
}

export function getByCurrentUserFirm() {
  return dispatch => {
    dispatch(firmGetByCurrentUserRequest());
    return dispatch(firmGetByCurrentUserReceive(api.getByCurrentUser()));
  };
}

export function getByIdFirm(firmId) {
  return dispatch => {
    dispatch(firmGetByIdRequest());
    return dispatch(firmGetByIdReceive(api.getById(firmId)));
  };
}

export function getByIdForSettingsFirm(firmId) {
  return dispatch => {
    dispatch(firmGetByIdForSettingsRequest());
    return dispatch(firmGetByIdForSettingsReceive(api.getByIdForSettings(firmId)));
  };
}

export function updateFirm(firmId, firm) {
  return dispatch => {
    dispatch(firmUpdateRequest());
    return dispatch(firmUpdateReceive(api.update(firmId, firm)));
  };
}

export function updateByCurrentUserFirm(firm) {
  return dispatch => {
    dispatch(firmUpdateByCurrentUserRequest());
    return dispatch(firmUpdateByCurrentUserReceive(api.updateByCurrentUser(firm)));
  };
}

export function upsertEmployees(employees) {
  return dispatch => {
    return dispatch(employeesDataUpdated(api.updateEmployees(employees)));
  };
}

export function updateFromSettingsFirm(firmId, values) {
  return dispatch => {
    dispatch(firmUpdateFromSettingsUserRequest());
    return dispatch(firmUpdateFromSettingsUserReceive(api.updateFromSettings(firmId, values)));
  };
}

export function clearNewPassiveStrategyIds() {
  return dispatch => {
    dispatch(firmClearNewPassiveStrategyIds());
  };
}

// REDUCER
export const firm = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_ALL_MANAGER_PAGED_REQUEST:
    case ACTION.GET_ALL_FIRM_PAGED_REQUEST:
    case ACTION.GET_BY_ID_REQUEST:
    case ACTION.UPDATE_REQUEST:
    case ACTION.GET_ALL_FIRM_FOR_SETTINGS_PAGED_REQUEST:
    case ACTION.GET_BY_CURRENT_USER_REQUEST:
    case ACTION.UPDATE_BY_CURRENT_USER_REQUEST:
    case ACTION.GET_ALL_BY_CURRENT_USER_REQUEST:
    case ACTION.CREATE_FROM_SETTINGS_REQUEST:
    case ACTION.GET_BY_ID_FOR_SETTINGS_REQUEST:
    case ACTION.UPDATE_FROM_SETTINGS_USER_REQUEST:
    case ACTION.DELETE_FROM_SETTINGS_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.CREATE_FROM_SETTINGS_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                newPassiveStrategyIds: concat(state.newPassiveStrategyIds, payload.newPassiveStrategyIds),
              },
        ),
      );
    case ACTION.DELETE_FROM_SETTINGS_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {}));
    case ACTION.GET_ALL_BY_CURRENT_USER_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {allPlanSponsorFirms: payload}),
      );
    case ACTION.GET_ALL_MANAGER_PAGED_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                allFirmsWithStrategy: payload.items,
                allFirmsWithStrategyTotal: payload.total,
              },
        ),
      );
    case ACTION.GET_ALL_FIRM_FOR_SETTINGS_PAGED_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError ? {error: payload} : {allSettingsFirms: payload.items, allSettingsFirmsTotal: payload.total},
        ),
      );
    case ACTION.GET_ALL_FIRM_PAGED_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError ? {error: payload} : {allFirms: payload.items, allFirmsTotal: payload.total},
        ),
      );
    case ACTION.GET_BY_ID_RECEIVE:
    case ACTION.GET_BY_ID_FOR_SETTINGS_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {editFirm: payload}));
    case ACTION.UPDATE_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {editFirm: payload}));
    case ACTION.UPDATE_FROM_SETTINGS_USER_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                newPassiveStrategyIds: concat(state.newPassiveStrategyIds, payload.newPassiveStrategyIds),
              },
        ),
      );
    case ACTION.GET_BY_CURRENT_USER_RECEIVE:
    case ACTION.UPDATE_BY_CURRENT_USER_RECEIVE:
    case ACTION.UPDATE_MANAGER_EMPLOYEES:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {editUserFirm: payload}),
      );
    case ACTION.CLEAR_NEW_PASSIVE_STRATEGY_IDS:
      return assignMergedState({newPassiveStrategyIds: []});
    case STRATEGY_ACTION.UPDATE_RECEIVE:
      if (hasError) return state;
      // Merge payload into existing firms data, by mutating state (!)
      Object.assign(find(state.allFirmsWithStrategy, {id: parseInt(payload.id, 10)}), payload);
      return assignMergedState();
    default:
      return state;
  }
};
