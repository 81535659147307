import styled from 'styled-components';
import {Theme} from '../atoms/theme';
import {TooltipProps, Position, Size} from '../interfaces/Tooltip';

interface InfoProps extends TooltipProps {
  isVisible: boolean;
  color?: string;
  backgroundColor?: string;
}

const InfoSmallContainer = styled.span<InfoProps & {size: Size; position: Position}>`
  position: absolute;
  display: block;
  z-index: 2;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  background-color: ${({theme, backgroundColor}) => backgroundColor ?? theme.colors.white};
  color: ${({theme, color}) => color ?? theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadow_1};
  border-radius: ${props => props.theme.constants.border_radius};
  padding: 1.8rem 2rem;
  width: ${props => (props.size === Size.Small ? '10rem' : Size.Medium ? '18.875rem' : '21.25rem')};
  text-align: ${props => (props.size === Size.Small ? 'center' : 'left')};
  ${({position}) => {
    switch (position) {
      case Position.Top:
        return `
            bottom: 120%;
          `;
      case Position.Bottom:
        return `
            top: 120%;
          `;
      case Position.Left:
        return `
            top: 0;
            right: 120%;
          `;
      case Position.Right:
        return `
            top: 0;
            left: 120%;
          `;
    }
  }}
`;

export const Info_1: React.FC<InfoProps> = ({
  children,
  isVisible = false,
  position = Position.Left,
  size = Size.Small,
  color = Theme.colors.dark,
  backgroundColor = '#FFFFFF',
}) => {
  return (
    <InfoSmallContainer
      size={size}
      position={position}
      isVisible={isVisible}
      backgroundColor={backgroundColor}
      color={color}
    >
      {children}
    </InfoSmallContainer>
  );
};
