import {isFinite} from 'lodash';

import {toFriendlyPrecision} from './toFriendlyPrecision';

export const formatNumber = (value, defaultResult = 0) => {
  // Confirm that the value is a valid finite number, if not then return default result
  if (!isFinite(value)) {
    return defaultResult;
  }

  if (value >= 1000000) {
    return toFriendlyPrecision(value / 1000000).toLocaleString() + 'mm';
  }
  if (value >= 1000) {
    return toFriendlyPrecision(value / 1000).toLocaleString() + 'k';
  }
  return toFriendlyPrecision(value).toLocaleString();
};
