import {Theme} from '../theme';

export const TrendingDown = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 18L13.5 8.5L8.5 13.5L1 6"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M17 18H23V12" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
