import React from 'react';
import styled from 'styled-components/macro';
import {ParagraphM} from '../atoms/Typeface';

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height 2rem;
    background-color: ${({theme}) => theme.colors.grey_2};
    border-radius: 100%;
`;

const Label = styled(ParagraphM)`
  font-weight: 600;
`;

export const NumberedPoint = ({number = '1'}: {number?: string | number}) => (
  <Circle>
    <Label>{number}</Label>
  </Circle>
);
