import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import {connect} from 'react-redux';
import {compose, withHandlers} from 'recompose';
import PropTypes from 'prop-types';

import {closeGenericModal} from './redux';

const GenericModal = compose(
  connect(({genericModal}, {name}) => ({isOpen: !!genericModal[name]}), {closeGenericModal}),
  withHandlers({
    handleCloseClick: props => () => {
      props.closeGenericModal(props.name);
    },
  }),
)(({bodyClasses, children, handleCloseClick, header, isOpen}) => (
  <Modal isOpen={isOpen} style={{maxWidth: 710}}>
    <ModalHeader toggle={handleCloseClick} tag="h2">
      {header}
    </ModalHeader>
    <ModalBody className={bodyClasses}>{children({handleCloseClick})}</ModalBody>
  </Modal>
));

GenericModal.propTypes = {
  bodyClasses: PropTypes.string,
  children: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export {GenericModal};
