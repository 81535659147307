import React from 'react';
import PropTypes from 'prop-types';

const APreventDefault = props => {
  let moddedProps = {...props};

  if (!props.href) {
    moddedProps.href = '#';
  }

  if (props.onclick || props.onClick) {
    const propName = props.onclick ? 'onclick' : 'onClick';

    moddedProps[propName] = (ev, ...args) => {
      if (ev && ev.preventDefault) {
        ev.preventDefault();
      }

      return props[propName](...args);
    };
  }

  return <a {...moddedProps}>{props.children}</a>;
};

APreventDefault.propTypes = {
  onClick: PropTypes.func,
};

export {APreventDefault};
