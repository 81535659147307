import React, {FC, useState} from 'react';
import styled from 'styled-components/macro';
import {
  IconButtonProps,
  BorderlessTextButtonProps,
  SelectableIconButtonProps,
  LinkButtonProps,
  SelectableTextButtonProps,
} from 'v2/utilities/types/components/Button';
import * as Icons from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {Batao, Body2, Overline} from '../atoms/Typeface';

export const Button = styled.button`
  & {
    align-items: center;
    background: ${props => props.theme.colors.white};
    border-radius: 0.5rem;
    border: 1px solid #003462;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex: 1;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    height: 2.75rem;
    min-height: 2.75rem;
    justify-content: center;
    line-height: 1.0625rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: 100%;
    &:hover {
      background: ${props => props.theme.colors.harmony};
      color: ${props => props.theme.colors.white};
      fill: ${props => props.theme.colors.white};
    }

    &:active:not(:disabled) {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.black};
      border: 1px solid ${props => props.theme.colors.blue};
    }
  }
`;

export const PrimaryButton = styled(Button)`
  & {
    background: ${props => props.theme.colors.harmony}!important;
    border-color: ${props => props.theme.colors.harmony};
    color: ${props => props.theme.colors.white};
    gap: 12px;
    line-height: unset;
  }

  &:hover {
    background: ${props => props.theme.colors.green}!important;
    color: ${props => props.theme.colors.white};
    fill: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.green};
  }

  &:active {
    background: ${props => props.theme.colors.harmony};
    color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.harmony};
  }
`;

export const SecondaryButton = styled(Button)`
  & {
    background: ${props => props.theme.colors.grey_2};
    border-color: ${props => props.theme.colors.grey_2};
    color: ${props => props.theme.colors.black};
    line-height: unset;
    transition: all 0.2s ease-in-out;
    padding-right: 12px;
    padding-left: 12px;
    width: auto;
    justify-content: center;
    flex: 0;
  }

  &:hover {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    fill: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.primary};
  }

  &:active {
    background: ${props => props.theme.colors.grey_2};
    color: ${props => props.theme.colors.black};
    border-color: ${props => props.theme.colors.grey_2};
  }
  &:disabled {
    background: ${props => props.theme.colors.grey_2};
    color: ${props => props.theme.colors.grey_4};
  }
`;

export const BorderlessButton = styled(Button)<{isSelected?: boolean; hoverColor?: string}>`
  &&& {
    color: ${props => (props.isSelected ? props.hoverColor : props.theme.colors.dark)};
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    border: 0.0625rem solid ${props => props.theme.colors.white};
    flex: 0;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    margin-right: -2.5em;
    &:hover {
      background: none;
      color: ${props => props.hoverColor || props.theme.colors.blue};
    }
    &:active {
      border: none;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const TextButton = styled(BorderlessButton)<{isSelected?: boolean; hoverColor?: string}>`
  &&& {
    background: none;
  }
`;

const IconWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 0.25em;
`;

const SecondaryIconWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 0;
`;

const TextWrapper = styled.div`
  white-space: nowrap;
`;

const FlexFill = styled.div`
  flex: 1;
`;

const ScrollButton = styled.button`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0.3rem 0.75rem 0.2rem 0.5rem;
  margin: 0;
`;

const ScrollButtonLabel = styled(Batao)`
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 0.8rem;
  color: ${props => props.theme.colors.harmony};
  padding: 0.125rem 0 0 0.35rem;
`;

const StateButton = styled.button<{selected?: boolean; size?: string}>`
  background-color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.grey_6)};
  height: ${props => props.size};
  width: ${props => props.size};
  border: ${props => (props.selected ? '2px solid ' + props.theme.colors.green : 'none')};
  border-radius: ${props => props.theme.constants.border_radius};
  display: flex;
  justify-content: center;
  align-items: center;
  &&&:focus {
    outline: none;
  }
`;

const GenericStateButton = styled.button<{selected: boolean; color?: string; selectedColor: string}>`
  background-color: ${props => (props.selected ? props.selectedColor : props.color)};
  border-radius: ${props => props.theme.constants.border_radius};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  &&&:focus {
    outline: none;
  }
`;

const FlexColumn = styled.div<{size: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.size};
`;

const StateButtonTitle = styled(Overline)`
  padding-top: 1rem;
  display: block;
  text-align: center;
`;

export const LinkButton = styled.a<LinkButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: ${({background, theme}) => background ?? theme.colors.harmony};
  color: ${({color, theme}) => color ?? theme.colors.white};
  border-radius: ${({theme}) => theme.constants.border_radius};
  width: ${({width}) => width ?? '100%'};
  height: 2.75rem;

  &:hover {
    background: ${({theme}) => theme.colors.green};
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
  }
`;

export const Link = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  color: ${({theme}) => theme.colors.harmony};

  &:hover {
    text-decoration: none;
  }
`;

export const IconButton: FC<IconButtonProps> = ({iconName, text, size = '0.875rem', className}) => {
  const [isLight, setIsLight] = useState(false);

  return (
    <Button
      onMouseEnter={() => {
        setIsLight(true);
      }}
      onMouseLeave={() => {
        setIsLight(false);
      }}
      onMouseDown={() => {
        setIsLight(false);
      }}
      onMouseUp={() => {
        setIsLight(true);
      }}
      className={className}
    >
      <IconWrapper>
        {React.createElement((Icons as any)[iconName], {
          color: isLight ? Theme.colors.white : Theme.colors.dark,
          size,
        })}
      </IconWrapper>
      <TextWrapper>{text}</TextWrapper>
      <FlexFill />
    </Button>
  );
};

export const PrimaryIconButton = ({
  iconName,
  text,
  size = '0.875rem',
  onClick = () => {},
  rotate = 0,
}: IconButtonProps) => {
  return (
    <PrimaryButton onClick={onClick}>
      <IconWrapper>
        {React.createElement((Icons as any)[iconName], {
          color: Theme.colors.white,
          size,
          rotate,
        })}
      </IconWrapper>
      <TextWrapper>{text}</TextWrapper>
      <FlexFill />
    </PrimaryButton>
  );
};

export const SecondaryIconButton: FC<IconButtonProps> = ({
  iconName,
  text,
  size = '0.875rem',
  className,
  color,
  ...props
}) => {
  const [isLight, setIsLight] = useState(false);

  const selectedColor = () => {
    switch (true) {
      case props.disabled:
        return Theme.colors.grey_4;
      case isLight:
        return Theme.colors.white;
      default:
        return Theme.colors.dark;
    }
  };

  return (
    <SecondaryButton
      onMouseEnter={() => {
        setIsLight(true);
      }}
      onMouseLeave={() => {
        setIsLight(false);
      }}
      onMouseDown={() => {
        setIsLight(false);
      }}
      onMouseUp={() => {
        setIsLight(true);
      }}
      className={className}
      {...props}
    >
      <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '12px', color: color}}>
        <SecondaryIconWrapper>
          {React.createElement((Icons as any)[iconName], {
            color: color || selectedColor(),
            size,
          })}
        </SecondaryIconWrapper>
        {!!text && <TextWrapper>{text}</TextWrapper>}
      </div>
    </SecondaryButton>
  );
};

export const BorderlessIconButton: React.FC<IconButtonProps> = ({
  iconName,
  text,
  size = '0.875rem',
  onClick,
  className,
}) => {
  const [isLight, setIsLight] = useState(false);

  return (
    <BorderlessButton
      onMouseEnter={() => {
        setIsLight(true);
      }}
      onMouseLeave={() => {
        setIsLight(false);
      }}
      onMouseDown={() => {
        setIsLight(false);
      }}
      onMouseUp={() => {
        setIsLight(true);
      }}
      onClick={onClick}
      className={className}
    >
      <IconWrapper>
        {React.createElement((Icons as any)[iconName], {
          color: isLight ? Theme.colors.blue : Theme.colors.dark,
          size,
        })}
      </IconWrapper>
      <TextWrapper>{text}</TextWrapper>
      <FlexFill />
    </BorderlessButton>
  );
};

export const IconOnlyButton: React.FC<IconButtonProps> = ({iconName, size = '0.875rem', onClick, className}) => {
  const [isLight, setIsLight] = useState(false);

  return (
    <BorderlessButton
      onMouseEnter={() => {
        setIsLight(true);
      }}
      onMouseLeave={() => {
        setIsLight(false);
      }}
      onMouseDown={() => {
        setIsLight(false);
      }}
      onMouseUp={() => {
        setIsLight(true);
      }}
      onClick={onClick}
      className={className}
    >
      <IconWrapper style={{margin: 0, justifyContent: 'inherit'}}>
        {React.createElement((Icons as any)[iconName], {
          color: isLight ? Theme.colors.blue : Theme.colors.dark,
          size,
        })}
      </IconWrapper>
    </BorderlessButton>
  );
};

export const BorderlessTextButton = ({
  text,
  onClick,
  hoverColor = '',
  isSelected = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}: BorderlessTextButtonProps) => {
  return (
    //@ts-ignore
    <BorderlessButton
      onClick={onClick}
      hoverColor={hoverColor}
      isSelected={isSelected}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TextWrapper>{text}</TextWrapper>
      <FlexFill />
    </BorderlessButton>
  );
};

export const ScrollToTopButton = (props: any) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <ScrollButton onClick={handleClick} {...props}>
      <Icons.CarretUp size={12} color={Theme.colors.harmony} />
      <ScrollButtonLabel>Top</ScrollButtonLabel>
    </ScrollButton>
  );
};

export const SelectableIconButton = ({
  iconName,
  selected,
  onClick,
  title,
  size = '5rem',
}: SelectableIconButtonProps) => {
  return (
    <FlexColumn size={size}>
      <StateButton selected={selected} size={size} onClick={() => onClick?.()}>
        {React.createElement((Icons as any)[iconName], {
          color: selected ? Theme.colors.green : Theme.colors.white,
          size: '37%',
        })}
      </StateButton>
      <StateButtonTitle>{title}</StateButtonTitle>
    </FlexColumn>
  );
};

export const SelectableTextButton = ({
  text,
  selected,
  onClick,
  color,
  selectedColor,
  textColor,
  selectedTextColor,
}: SelectableTextButtonProps) => {
  return (
    //@ts-ignore
    <GenericStateButton selected={selected} color={color} selectedColor={selectedColor} onClick={onClick}>
      <Body2 color={selected ? selectedTextColor : textColor}>{text}</Body2>
    </GenericStateButton>
  );
};

type HarmonyButtonVariant = 'default' | 'ghost' | 'light' | 'secondary';

interface HarmonyButtonProps {
  width?: string;
  height?: string;
  padding?: string;
  variant?: HarmonyButtonVariant;
}

export const HarmonyButton = styled(Button)<HarmonyButtonProps>`
  min-width: ${({width}) => width ?? '8.9375rem'};
  width: ${({width}) => width ?? 'auto'};
  max-width: ${({width}) => width ?? 'auto'};
  height: ${({height}) => height ?? 'auto'};
  padding: ${({padding}) => padding ?? '0 2.5rem'};
  background-color: ${({theme, variant}) => {
    switch (variant) {
      case 'light':
        return theme.colors.grey_2;
      case 'ghost':
        return 'transparent';
      case 'secondary':
        return theme.colors.blue_1;
      case 'default':
      default:
        return theme.colors.harmony;
    }
  }};
  color: ${({theme, variant}) => {
    switch (variant) {
      case 'light':
      case 'ghost':
        return theme.colors.dark;
      case 'secondary':
      case 'default':
      default:
        return '#fff';
    }
  }};
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  border: 0;

  transition: background-color ${({theme}) => theme.transitions.default};

  &:hover:not(:disabled) {
    background-color: ${({theme, variant}) => {
      switch (variant) {
        case 'light':
          return theme.colors.blue_1;
        case 'ghost':
          return theme.colors.grey_3;
        case 'default':
        default:
          return theme.colors.green;
      }
    }};
    color: ${({theme, variant}) => {
      switch (variant) {
        case 'ghost':
          return theme.colors.dark;
        case 'light':
        case 'default':
        default:
          return '#fff';
      }
    }};
  }

  &:disabled {
    background-color: ${({theme, variant}) => {
      switch (variant) {
        case 'light':
          return theme.colors.grey_1;
        case 'default':
        default:
          return theme.colors.grey_3;
      }
    }};

    cursor: default;
    color: ${({theme}) => theme.colors.grey_1};
  }

  &&& {
    min-width: 5.25rem;
    width: auto;
    height: 1.25rem;
    text-decoration: none;
  }
`;
