/* eslint-disable radix */
import React, {Component} from 'react';
import {get} from 'lodash';
import {PageContainer} from '../components';
import {ISSUE_CATEGORY, ISSUE_SUB_CATEGORY} from '../../constants';

class CompanyEngagementPage extends Component {
  render() {
    const {companyName, data} = this.props;

    const dataCategorized = [
      {
        name: ISSUE_CATEGORY.ENVIRONMENT,
        subCategories: [
          {
            name: ISSUE_SUB_CATEGORY.ENVIRONMENT,
            totalCount: get(data, '[4].totalCount', 0),
            engagedCount: get(data, '[4].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[4].actionPlansReceivedCount', 0),
          },
          {
            name: ISSUE_SUB_CATEGORY.ENVIRONMENTAL_POLICIES,
            totalCount: get(data, '[5].totalCount', 0),
            engagedCount: get(data, '[5].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[5].actionPlansReceivedCount', 0),
          },
        ],
      },
      {
        name: ISSUE_CATEGORY.SOCIAL,
        subCategories: [
          {
            name: ISSUE_SUB_CATEGORY.DIVERSITY_INCLUSION,
            totalCount: get(data, '[6].totalCount', 0),
            engagedCount: get(data, '[6].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[6].actionPlansReceivedCount', 0),
          },
          {
            name: ISSUE_SUB_CATEGORY.COMPENSATION_EQUITY,
            totalCount: get(data, '[7].totalCount', 0),
            engagedCount: get(data, '[7].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[7].actionPlansReceivedCount', 0),
          },
          {
            name: ISSUE_SUB_CATEGORY.WORKFORCE_WELL_BEING,
            totalCount: get(data, '[8].totalCount', 0),
            engagedCount: get(data, '[8].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[8].actionPlansReceivedCount', 0),
          },
        ],
      },
      {
        name: ISSUE_CATEGORY.GOVERNANCE,
        subCategories: [
          {
            name: ISSUE_SUB_CATEGORY.BOARD_GOVERNANCE,
            totalCount: get(data, '[9].totalCount', 0),
            engagedCount: get(data, '[9].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[9].actionPlansReceivedCount', 0),
          },
          {
            name: ISSUE_SUB_CATEGORY.CORPORATE_TRUST,
            totalCount: get(data, '[10].totalCount', 0),
            engagedCount: get(data, '[10].engagedCount', 0),
            actionPlansReceivedCount: get(data, '[10].actionPlansReceivedCount', 0),
          },
        ],
      },
    ];

    dataCategorized.forEach((category, i) => {
      dataCategorized[i].totals = category.subCategories.reduce((a, b) => {
        return {
          totalCount: parseInt(a.totalCount) + parseInt(b.totalCount),
          engagedCount: parseInt(a.engagedCount) + parseInt(b.engagedCount),
          actionPlansReceivedCount: parseInt(a.actionPlansReceivedCount) + parseInt(b.actionPlansReceivedCount),
        };
      });
    });

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} companyName={companyName} pageNumber={12}>
        <div className={'company-engagement-page'}>
          <h2 className="text-uppercase">Company Engagement</h2>
          <div className={'mt-3 w-100'}>
            {dataCategorized.map((category, i) => {
              return (
                <table className={'w-100 mt-5'}>
                  <thead>
                    <td width="52%">{category.name} Issues</td>
                    <td width="16%">{i === 0 ? 'Number of Engagements' : ''}</td>
                    <td width="16%">{i === 0 ? 'Engaged' : ''}</td>
                    <td width="16%">{i === 0 ? 'Action Plans Received' : ''}</td>
                  </thead>
                  {category.subCategories.map(subCategory => {
                    return (
                      <tr className={'data'}>
                        <td className={'pl-3'}>{subCategory.name}</td>
                        <td>{subCategory.totalCount}</td>
                        <td>{subCategory.engagedCount}</td>
                        <td>{subCategory.actionPlansReceivedCount}</td>
                      </tr>
                    );
                  })}
                  <tr className={'total'}>
                    <td className={'pl-3'}>Total</td>
                    <td>{get(category, 'totals.totalCount', 0)}</td>
                    <td>{get(category, 'totals.engagedCount', 0)}</td>
                    <td>{get(category, 'totals.actionPlansReceivedCount', 0)}</td>
                  </tr>
                </table>
              );
            })}
          </div>
        </div>
      </PageContainer>
    );
  }
}

export {CompanyEngagementPage};
