import {RootStateOrAny, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useSideDrawer} from 'v2/components/molecules/Drawer/Drawer';
import {FilterDrawer} from 'v2/components/organisms/FilterDrawer/FilterDrawer';
import {FilterHeader} from 'v2/components/organisms/PageHeaders';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {Callback, Middleware} from './types';
import {useFilterNodes} from '../../hooks/filters/useFilterNodes';

const ContentContainer = styled.div<{fullWidth?: boolean}>`
  flex: 1;
  width: auto;
  max-width: ${({theme, fullWidth}) => (fullWidth ? '100%' : theme.constants.fixWidth)};
  padding: 3.188rem 0;
  margin: 0 auto;
`;

const Layout: React.FC<{containerFullWidth: boolean; pageTitle: string}> = ({
  children,
  pageTitle,
  containerFullWidth = false,
}) => {
  const companyTitle = (useSelector<RootStateOrAny>(state => state?.planDashboard?.details?.name) ??
    DATA_STATUS.NONE) as string;

  const rootNode = useFilterNodes();

  const {isOpen, handleOpen, handleClose} = useSideDrawer();

  return (
    <>
      <FilterHeader companyTitle={companyTitle} pageTitle={pageTitle} filters={[]} onOpenFilterClick={handleOpen} />
      <FilterDrawer isOpen={isOpen} onClose={handleClose} rootNode={rootNode}>
        <div style={{position: 'relative', padding: '0 20px 20px', flexDirection: 'column', display: 'flex', gap: 20}}>
          {Array(3)
            .fill('Hello')
            .map((text, i) => (
              <div key={i} style={{margin: '0 16px'}}>
                {text}
              </div>
            ))}
        </div>
      </FilterDrawer>
      <ContentContainer fullWidth={containerFullWidth}>{children}</ContentContainer>
    </>
  );
};

export const filterHeader =
  ({fullWidth = false, title = '', component}: {fullWidth?: boolean; title?: string; component?: any}): Middleware =>
  async (next: Callback) => {
    const response = await next();

    const Component = component;

    return (
      <Layout containerFullWidth={fullWidth} pageTitle={title}>
        {component && <Component />}
        {response}
      </Layout>
    );
  };
