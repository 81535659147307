export const Theme = {
  colors: {
    green: '#34B991',
    grey_1: '#FCFCFC',
    grey_2: '#F3F2F7',
    grey_3: '#D0D6DE',
    grey_4: '#B9BBBD',
    grey_5: '#A3A3A3',
    grey_6: '#E5E5E5',
    grey_7: '#677D8B',
    grey_8: '#636363',
    grey_9: '#333A46',
    grey_10: '#DCDCDC',
    grey_11: '#EAEBED',
    dark: '#464255',
    harmony: '#003462',
    dark_harmony: '#003449',
    light_green: '#ADE03F',
    light_green_2: '#42B23A',
    light_green_3: '#36C8A1',
    dark_green: '#288D6F',
    orange: '#F09655',
    red: '#F0556B',
    red_2: '#FA5252',
    danger: '#E03131',
    success: '#2A992C',
    white: '#FFFFFF',
    yellow: '#EEE32B',
    blue: '#009BCC',
    blue_1: '#286EA0',
    blue_2: '#53A2CF',
    blue_3: '#AAC6D2',
    blue_4: '#D9DEE0',
    blue_5: '#C5EAF7',
    blue_6: '#7194B1',
    blue_7: '#5D81A3',
    blue_8: '#8FD1EF',
    black: '#000000',
    calypso: '#26718B',
    transparent: '#00FFFFFF',
    gold: '#BF9763',
    silver: '#B4B2AD',
    bronze: '#967867',
    harmonyGradientStart: '#008087',
    harmonyGradientEnd: '#00446D',
    blueGradientStart: '#003462',
    blueGradientEnd: '#002854',
  },
  gradients: {
    harmony_to_green: 'linear-gradient(29.07deg, #008087 -32.48%, #00446D 54.39%)',
    harmony_blue: 'linear-gradient(180deg, #003462 0%, #002854 100%)',
    harmony_horizontal_blue: 'linear-gradient(327.02deg, #003462 7.57%, #104E86 97.31%)',
    harmony_multi_legend:
      ' linear-gradient(90deg, #F0556B 0%, #F09655 26.61%, #EEE32B 52.06%, #ADE03F 77.27%, #34B991 100%)',
  },
  shadows: {
    shadow_1: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2);',
    shadow_2: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);',
    shadow_3:
      '0px 32px 72px rgba(50, 50, 93, 0.12), 0px 10px 15px rgba(0, 0, 0, 0.07), 0px -4px 16px rgba(0, 0, 0, 0.03);',
  },
  transitions: {
    default: '200ms ease-in-out',
  },
  constants: {
    fixWidth: '71.25rem',
    border_radius: '.5rem',
    info_bar_border_radius: '.25rem',
  },
};
