import {createElement, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {ChevronRight, ChevronDown} from '../atoms/icons';
import {Link1, Title8} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';
import {LinkType} from '../interfaces/Accordion';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{isOpen: boolean}>`
  max-height: ${props => (props.isOpen ? '100vh' : 0)};
  padding: 0 0 1rem 0;
  overflow: hidden;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: all ${props => props.theme.transitions.default};
`;

const Button = styled.button<{color: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.color};
  border: 0;
  border-radius: ${props => props.theme.constants.border_radius};
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.grey_2};
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 1rem 0 0;
  padding: 0;
`;

const SubList = styled(List)<{isOpen?: boolean}>`
  list-style-type: none;
  margin: 0;
  padding: 0 1rem;
  max-height: ${props => (props.isOpen ? '100vh' : 0)};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: all ${props => props.theme.transitions.default};
  overflow: hidden;
`;

const ListItem = styled.li`
  display: block;
  width: 100%;
`;

const ListLink = styled(Link1)<{active: boolean | undefined; activeColor: string}>`
  display: flex;
  align-items: center;
  height: 3rem;
  width: inherit;
  padding: 0 2rem;
  color: ${props => (props.active ? props.activeColor : Theme.colors.dark)};
  text-decoration: none;
  border: 0;
  background: transparent;
  transition: color ${props => props.theme.transitions.default};
  &:hover {
    color: ${props => (props.active ? Theme.colors.harmony : Theme.colors.grey_4)};
  }
`;

const Title = styled(Title8)`
  margin: 0;
`;

export const Accordion: React.FC<{
  title: string;
  closedBackgroundColor?: string;
  openBackgroundColor?: string;
  onClick?: () => any;
  isOpen?: boolean;
}> = ({
  title,
  closedBackgroundColor = Theme.colors.grey_1,
  openBackgroundColor = Theme.colors.harmony + '1A',
  isOpen = false,
  onClick,
  children,
}) => {
  const backgroundColor = isOpen ? openBackgroundColor : closedBackgroundColor;

  return (
    <Wrapper>
      <Button onClick={onClick} color={backgroundColor}>
        <Title color={Theme.colors.dark}>{title}</Title>
        {createElement(isOpen ? ChevronDown : ChevronRight, {
          size: 13,
          color: Theme.colors.grey_5,
        })}
      </Button>
      <Container isOpen={isOpen}>{children}</Container>
    </Wrapper>
  );
};

export const SubAccordion: React.FC<{
  data: any[];
  link?: any;
  activeColor?: string;
  isOpen?: boolean;
  onClick?: any;
}> = ({data, link, activeColor = Theme.colors.harmony, isOpen = false, onClick}) => {
  const {name} = link;
  return (
    <List>
      <ListItem>
        <ListLink as="button" active={isOpen} activeColor={activeColor} onClick={() => onClick()}>
          {name}
        </ListLink>
      </ListItem>
      <SubList isOpen={isOpen}>
        {data?.map(({issue, id, isActive, onClick}) => (
          <ListItem key={issue.id}>
            <ListLink as="button" active={isActive} activeColor={activeColor} onClick={() => onClick()}>
              {issue.name}
            </ListLink>
          </ListItem>
        ))}
      </SubList>
    </List>
  );
};

export const AccordionList: React.FC<{
  data: any[];
  activeColor?: string;
}> = ({data, activeColor = Theme.colors.harmony}) => {
  return (
    <List>
      {data?.map(({issue, topics, isActive, onClick}, i) => {
        if (topics) {
          return (
            <SubAccordion
              data={topics}
              activeColor={activeColor}
              isOpen={isActive}
              onClick={() => onClick()}
              link={issue}
            />
          );
        }

        return (
          <ListItem key={i}>
            <ListLink as="button" active={true} activeColor={activeColor} onClick={() => onClick()}>
              {issue?.name}
            </ListLink>
          </ListItem>
        );
      })}
    </List>
  );
};
