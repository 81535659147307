/* eslint no-script-url: 0 */
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// Local Imports
import {ROUTE} from 'app/constants';
import {APreventDefault} from 'app/components';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import {logout} from 'app/redux/auth';

class NotAuthorizedPage extends Component {
  render() {
    return this.props.isAdmin ? (
      <div className="p-content-lg">
        <div className="card is-white">
          <h1>
            <Icon icon={faExclamationTriangle} /> You are currently logged in as an Admin
          </h1>
          <span>
            <p>You can only access this page by being logged in as an end user.</p>
            <p>
              Please{' '}
              <APreventDefault
                onClick={() => {
                  this.props.dispatch(logout());
                }}
              >
                log out
              </APreventDefault>{' '}
              and log back in with an appropriate account. If one does not exist, it is recommended that you create one
              in the <Link to={ROUTE.ADMIN_USERS_ADD.path()}>user manager</Link>.
            </p>
          </span>
        </div>
      </div>
    ) : (
      <div className="p-content-lg">
        <div className="card is-white">
          <h1>
            <Icon icon={faExclamationTriangle} /> You are not authorized to view this page
          </h1>
          <span>
            <p>Unfortunately you are not authorized to view that page.</p>
            <p>
              You can use the <Link to={ROUTE.SITE_SEARCH.path()}>site search</Link> to find what you are looking for,
              or you can return to your <Link to={ROUTE.DASHBOARD.path()}>dashboard</Link> to start a new task.
            </p>
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const authUser = state.auth.impersonatedUser || state.auth;
  return {authUser};
}
export const NotAuthorized = connect(mapStateToProps)(NotAuthorizedPage);
