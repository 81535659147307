export const COLOR = {
  NAVY: '#003463',
  BLUE: '#027AA2',
  RED: '#FF5865',
  YELLOW: '#FFF32F',
  GREEN: '#00C596',
  TEAL: '#007878',
  CRIMSON: '#C63748',
  ORANGE: '#FFA64A',

  BLACK: '#000000',
  ALMOST_BLACK: '#17171B',
  DARKEST_GRAY: '#28292F',
  DARKER_GRAY: '#333a46',
  DARK_GRAY: '#40424B',
  GRAY: '#636974',
  LIGHT_GRAY: '#979EA8',
  LIGHTER_GRAY: '#C7CBCF',
  LIGHTEST_GRAY: '#E8E9EB',
  ALMOST_WHITE: '#F5F6F7',
  WHITE: '#FFFFFF',

  DARK_GREEN: '#27BD9B', // 10% NAVY, 90% GREEN
  TRANSPARENT: 'transparent',

  PLATINUM: '#B4B1AC',
  GOLD: '#BF9763',
  SILVER: '#C9C7C3',
  BRONZE: '#967766',
  SUB_STANDARD: '#333A46',
};
