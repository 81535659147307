import React from 'react';

function ContactUs() {
  return (
    <div className="p-content-lg mx-auto" style={{maxWidth: 850}}>
      <div className="card is-white">
        <h1>Contact Us</h1>
        <p>
          We’re excited to have you be a part of the ESG movement. Please let us know how we can help by contacting us
          today.
        </p>
        <a href="mailto:support@harmonyanalytics.org" className="btn" style={{maxWidth: 250}}>
          Contact Us
        </a>
      </div>
    </div>
  );
}

export {ContactUs};
