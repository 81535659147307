import {Theme} from '../theme';

export const Briefcase = ({color = Theme.colors.dark, size = 32, style = {}}) => (
  <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M17.1667 5.8335H3.83332C2.91285 5.8335 2.16666 6.57969 2.16666 7.50016V15.8335C2.16666 16.754 2.91285 17.5002 3.83332 17.5002H17.1667C18.0871 17.5002 18.8333 16.754 18.8333 15.8335V7.50016C18.8333 6.57969 18.0871 5.8335 17.1667 5.8335Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8333 17.5V4.16667C13.8333 3.72464 13.6577 3.30072 13.3452 2.98816C13.0326 2.67559 12.6087 2.5 12.1667 2.5H8.83332C8.3913 2.5 7.96737 2.67559 7.65481 2.98816C7.34225 3.30072 7.16666 3.72464 7.16666 4.16667V17.5"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
