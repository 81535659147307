import React, {createElement} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {uniqueId} from 'lodash';

import {ToolTip} from 'app/components';

const RadioDot = ({children, className, color, floating, style, tag, tooltip, shadowed}) => {
  const id = `tooltip_${uniqueId()}`;

  let toolTipNode;
  if (tooltip) {
    toolTipNode = (
      <ToolTip key="tooltip" target={id}>
        {tooltip}
      </ToolTip>
    );
  }

  return createElement(
    tag,
    {
      className: cn(
        'radio-dot',
        {[`is-${color}`]: color},
        {'is-floating': floating, 'has-shadow': shadowed},
        className,
      ),
      id,
      style,
    },
    [
      <div key="outer" className="radio-dot-outer" />,
      <div key="inner" className="radio-dot-inner" />,
      ...children,
      toolTipNode,
    ],
  );
};

RadioDot.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  color: PropTypes.string,
  floating: PropTypes.bool,
  style: PropTypes.object,
  tag: PropTypes.string,
  tooltip: PropTypes.node,
  shadowed: PropTypes.bool,
};

RadioDot.defaultProps = {
  tag: 'div',
  children: [],
  shadowed: true,
};

export {RadioDot};
