import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {SubmissionError} from 'redux-form';

// Local Imports
import {Alert, OnboardingAccountForm, ProgressIndicator} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {updateOnboardingUserByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';

class OnboardingAccountPage extends Component {
  render() {
    // Ensure OnboardingAccountForm doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: this.props.hasUnexpectedError ? 850 : 400}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          <h1>{ROUTE.ONBOARDING_CREATE_ACCOUNT.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <OnboardingAccountForm {...this.props} />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;
      const onboardingStep = onboardingSteps.ACCOUNT.onboardingStepName
        ? onboardingSteps.ACCOUNT.onboardingStepName
        : onboardingSteps.ACCOUNT.key;
      const updateData = {
        password: values.password,
        onboardingStep: onboardingStep,
      };

      if (onboardingSteps.ACCOUNT.isComplete) {
        animateScroll.scrollToTop();

        return dispatch(push(ROUTE.ONBOARDING_PROFILE.path(invitationCode)));
      }

      return dispatch(updateOnboardingUserByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_PROFILE.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const OnboardingAccount = compose(withOnboardingUser, connect(null, mapDispatchToProps))(OnboardingAccountPage);
