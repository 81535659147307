import React from 'react';
import {connect} from 'react-redux';
import {compose, getContext, withContext, withHandlers} from 'recompose';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import {FORM_MESSAGE} from 'app/constants';
import faPlusCircle from 'app/fontawesome-pro-light/faPlusCircle';
import {addHolding} from 'app/redux/complianceManager';
import {toastError} from 'app/utilities/toast';
import {TooltipLink} from './TooltipLink';
import {VoidLink} from './VoidLink';

const AddLink = compose(
  connect(({complianceManager}, {securityIsin}) => ({
    isAdded: !!find(get(complianceManager, 'portfolio.data.securities', []), {securityIsin}),
  })),
  getContext({
    handleHoldingAdd: PropTypes.func,
  }),
  withHandlers({
    handleClick:
      ({handleHoldingAdd, securityIsin}) =>
      () => {
        handleHoldingAdd(securityIsin);
      },
  }),
)(({handleClick, isAdded}) => {
  return isAdded ? (
    <strong className="text-light-gray">Added</strong>
  ) : (
    <strong>
      <VoidLink onClick={handleClick}>
        <Icon icon={faPlusCircle} className="mr-1" />
        Add
      </VoidLink>
    </strong>
  );
});

const columns = [
  {
    dataField: 'name',
    text: 'Company Name',
    sort: true,
    headerStyle: {width: 150},
  },
  {
    dataField: 'ticker',
    text: 'Ticker',
    sort: true,
    headerStyle: {width: 100},
  },
  {
    dataField: 'rating',
    text: 'Rating',
    sort: true,
    headerStyle: {width: 100},
  },
  {
    dataField: 'isin',
    text: 'Add',
    headerFormatter: () => (
      <TooltipLink label={null}>
        To add a company to your Current Portfolio, click on "Add" in the corresponding row.
      </TooltipLink>
    ),
    formatter: value => <AddLink securityIsin={value} />,
    headerStyle: {textAlign: 'right', width: 55},
    style: {textAlign: 'right'},
  },
];

const ComplianceManagerSecurityMiniListing = compose(
  connect(null, {addHolding}),
  withHandlers({
    handleHoldingAdd: props => securityIsin => {
      props
        .addHolding(props.strategyId, securityIsin)
        .then(response => {
          if (response.hasError) throw response.error;
        })
        .catch(() => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE));
    },
  }),
  withContext({handleHoldingAdd: PropTypes.func}, ({handleHoldingAdd}) => ({handleHoldingAdd})),
)(({isFetching, securities, tableProps}) => (
  <BootstrapTable
    bordered={false}
    keyField="isin"
    data={securities}
    columns={columns}
    wrapperClasses="mb-0"
    classes="table-compact mb-0"
    style={isFetching ? {opacity: 0.5} : null}
    {...tableProps}
  />
));

ComplianceManagerSecurityMiniListing.propTypes = {
  isFetching: PropTypes.bool,
  securities: PropTypes.array.isRequired,
  strategyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tableProps: PropTypes.object,
};

export {ComplianceManagerSecurityMiniListing};
