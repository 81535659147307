// These keys should the same as the keys in the data above
// and in the desired order

export const COLUMN_LABELS = {
  companies: {
    name: 'Company Name',
    engaged: 'Engaged',
    transparency: 'Transparency',
    country: 'Country',
    countryCode: 'Country Code',
    sector: 'Sector',
    pctRank: 'Percentile Rank',
    held: 'Held',
  },
  managers: {
    firmName: 'Manager',
    product: 'Product',
    index: 'Index',
    strategy: 'Strategy',
    percentileRank: 'Percentile Rank',
    vsIndexRating: 'Vs Index',
    portfolioValue: 'Portfolio Value',
  },
  clients: {
    firmName: 'Client',
    product: 'Product',
    index: 'Index',
    strategy: 'Strategy',
    percentileRank: 'Percentile Rank',
    vsIndexRating: 'Vs Index',
    portfolioValue: 'Portfolio Value',
  },
};
