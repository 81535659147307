import React, {Fragment} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {isFinite, isNil, map, sumBy, throttle} from 'lodash';
import {
  compose,
  getContext,
  lifecycle,
  shouldUpdate,
  withContext,
  withHandlers,
  withProps,
  withPropsOnChange,
} from 'recompose';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toastError} from 'app/utilities/toast';
import {animateScroll} from 'react-scroll';

import faTrash from 'app/fontawesome-pro-light/faTrash';
import {COLOR, FORM_MESSAGE} from 'app/constants';
import {normalizePercentFloat, withViewportSize} from 'app/utilities';
import {deleteHolding, updateHolding, unfreezeSort} from 'app/redux/complianceManager';
import {RectangleImage} from './RectangleImage';
import {ToolTip} from './Tooltip';
import {VoidLink} from './VoidLink';
import {DATA_STATUS} from 'v2/constants/dataStatus';

const sorted = [{dataField: 'compliance', order: 'asc'}];
const editableSorted = [{dataField: 'compliance', order: 'asc'}];

const formatFriendlyToFixed2 = value => {
  if (isNil(value)) return '0.00';
  return (value * 100).toFixed(2);
};

const formatFriendlyFloat = value => {
  return parseFloat(formatFriendlyToFixed2(value));
};

const formatFriendlyPercent = value => {
  if (isNil(value)) return DATA_STATUS.NONE;
  return formatFriendlyToFixed2(value) + '%';
};

const getSecurityRowClasses = ({isNew, weight}) => {
  return cn('transition', {
    'bg-light-blue-pulse': isNew,
    'text-light-gray': !weight && !isNew,
  });
};

const warnAboutNegativeCash = throttle(() => {
  toastError('The weights of your current portfolio are unbalanced. Review your weights before you export.');
}, 10000);

const EditableWeight = compose(
  getContext({
    handleHoldingWeightChange: PropTypes.func,
  }),
  withHandlers({
    handleBlur:
      ({handleHoldingWeightChange, strategyHoldingId, value}) =>
      e => {
        const targetValue = e.target.value || 0;
        const weight = Math.min((parseFloat(targetValue) || 0) / 100, 1);
        // Only propagate change if new value is different
        if (weight !== value) {
          handleHoldingWeightChange(strategyHoldingId, weight);
        }
        e.target.value = formatFriendlyToFixed2(weight); // eslint-disable-line no-param-reassign
      },
    handleChange: () => e => {
      e.target.value = normalizePercentFloat(e.target.value); // eslint-disable-line no-param-reassign
    },
  }),
  withHandlers({
    handleKeyPress:
      ({handleBlur}) =>
      e => {
        if (e.key === 'Enter') {
          handleBlur(e);
        }
      },
  }),
)(({handleBlur, handleChange, handleKeyPress, strategyHoldingId, value}) => (
  <div className="input-group input-group-sm">
    <input
      type="text"
      className="form-control text-right"
      defaultValue={formatFriendlyToFixed2(value)}
      maxLength={6}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      key={`${strategyHoldingId}_weight`}
    />
    <div className="input-group-append">
      <div className="input-group-text">%</div>
    </div>
  </div>
));

const DeleteLinkIcon = compose(
  getContext({
    handleHoldingDelete: PropTypes.func,
  }),
  withHandlers({
    handleClick:
      ({handleHoldingDelete, strategyHoldingId}) =>
      () => {
        handleHoldingDelete(strategyHoldingId);
      },
  }),
)(({handleClick}) => (
  <VoidLink className="text-danger" onClick={handleClick}>
    <Icon icon={faTrash} />
  </VoidLink>
));

const ScrollGrabbingRectangle = compose(
  withPropsOnChange([], () => ({
    onRef: React.createRef(),
  })),
  lifecycle({
    componentDidMount() {
      const {scrollOnMount, onRef} = this.props;
      if (scrollOnMount && onRef.current) {
        // Determine the amount to scroll so that the element will be centered in the viewport
        const scrollOffset =
          onRef.current.getBoundingClientRect().top + (onRef.current.height - window.innerHeight) / 2;
        animateScroll.scrollMore(scrollOffset);
      }
    },
  }),
)(({scrollOnMount, ...restProps}) => <RectangleImage {...restProps} />);

ScrollGrabbingRectangle.propTypes = {
  scrollOnMount: PropTypes.bool,
};

// List of securities and the current index in the dataTable. Used for when freezing the sort.
const mappedIndexes = {};

const columnDefinitions = {
  id: {
    dataField: 'key',
    text: '',
    formatter: (value, {compliance, isNew}, index) => {
      // Store the key and index on any render to be used as a guide for when the sort "freezes"
      mappedIndexes[value] = index;

      const fill = (compliance && compliance.color) || COLOR.TRANSPARENT;
      return <ScrollGrabbingRectangle fill={fill} scrollOnMount={isNew} />;
    },
    headerClasses: 'table-status-block',
    classes: 'table-status-block',
    style: (value, {compliance}) => {
      const fill = (compliance && compliance.color) || COLOR.TRANSPARENT;
      return {backgroundColor: fill};
    },
  },
  name: {
    dataField: 'name',
    metaDataField: 'metaName',
    text: 'Company Name',
    sort: true,
    formatter: (value, {name, ticker}) => (
      <Fragment>
        {name}
        {ticker && (
          <Fragment>
            <br />
            {ticker}
          </Fragment>
        )}
      </Fragment>
    ),
  },
  sector: {
    dataField: 'sectorName',
    metaDataField: 'metaSectorName',
    text: 'Sector',
    sort: true,
  },
  industry: {
    dataField: 'industryName',
    metaDataField: 'metaIndustryName',
    text: 'Industry',
    sort: true,
  },
  country: {
    dataField: 'countryCode',
    metaDataField: 'metaCountryCode',
    text: 'Country',
    sort: true,
    headerStyle: {width: 120},
  },
  sectorIndustryAndCountry: {
    dataField: 'sectorName',
    text: 'Sector & Country',
    formatter: (sectorName, {industryName, countryCode}) => (
      <Fragment>
        <div>{sectorName}</div>
        <div>{industryName}</div>
        <div>{countryCode}</div>
      </Fragment>
    ),
  },
  rating: {
    dataField: 'rating',
    metaDataField: 'metaRating',
    text: 'Rating',
    sort: true,
    formatter: (value, {rating}) => <strong>{rating}</strong>,
    headerStyle: {width: 120},
  },
  startWeight: {
    dataField: 'startWeight',
    text: 'Start Weight',
    formatter: formatFriendlyPercent,
    headerStyle: {width: 75},
    style: {textAlign: 'right'},
  },
  weight: {
    dataField: 'weight',
    text: 'Weight',
    formatter: formatFriendlyPercent,
    headerStyle: {textAlign: 'right', width: 110},
    style: {textAlign: 'right'},
    sort: true,
  },
  editableWeight: {
    dataField: 'weight',
    metaDataField: 'metaWeight',
    text: 'Weight',
    formatter: (value, {key, weight}) => <EditableWeight strategyHoldingId={key} value={weight} />,
    headerStyle: {textAlign: 'right', width: 110},
    style: {textAlign: 'right'},
    sort: true,
  },
  compliance: {
    dataField: 'compliance',
    text: 'Compliance',
    sort: true,
    sortFunc: (x, y, order) => {
      const a = x ? x.sortOrder || 2147483647 : 2147483647;
      const b = y ? y.sortOrder || 2147483647 : 2147483647;

      return order === 'asc' ? a - b : b - a;
    },
    formatter: (value, {key}) => {
      if (!value) return null;
      const {className, icon, label, tooltip} = value;
      const tooltipId = `tooltip_${key}_compliance`;
      return (
        <Fragment>
          {label && (
            <strong className={className} id={tooltipId}>
              <Icon icon={icon} /> {label}
            </strong>
          )}
          {tooltip && (
            <ToolTip target={tooltipId}>
              <div className="text-white">{tooltip}</div>
            </ToolTip>
          )}
        </Fragment>
      );
    },
    headerStyle: {width: 135},
  },
  delete: {
    dataField: 'securityIsin',
    text: '',
    formatter: (value, {key}) => <DeleteLinkIcon strategyHoldingId={key} />,
    headerStyle: {width: 35},
  },
};

/**
 * Shapes the Column Definition accounting for when the sort is frozen
 */
const getColumnDefinitionForFrozenSorts = (columnDefinition, props) => {
  /**
   * Sort logic for when the sort is frozen, looking at the last index of each record and using that as the sort guide
   */
  const frozenSortFunc = (x, y) => {
    return mappedIndexes[x.key] - mappedIndexes[y.key];
  };

  /**
   * Create a sort function that can be used with the meta fields, using the "value" property for comparison
   */
  const sortFunc = (x, y, order) => {
    const a = isNil(x.value) ? x : x.value;
    const b = isNil(y.value) ? y : y.value;

    // If number use number sort logic
    if (isFinite(a) || isFinite(b)) {
      return order === 'asc' ? a - b : b - a;
    }

    // Use string (with coercion) sort logic
    return order === 'asc' ? (a + '').localeCompare(b) : (b + '').localeCompare(a);
  };

  const hasMetaDataField = !!columnDefinition.metaDataField;
  const isFormatterNeeded = hasMetaDataField && !columnDefinition.formatter;

  const definition = {
    ...columnDefinition,
    // Use the metaDataField to get additional properties for formatter and sorting
    ...(hasMetaDataField && {dataField: columnDefinition.metaDataField}),
    // User a formatter to handle metaData, if formatter wasn't already provided
    ...(isFormatterNeeded && {formatter: value => <Fragment>{value.value || value}</Fragment>}),
    // Specify which sort to use depending on whether the sort is frozen and if a sortFunc is provided
    ...(props.isSortFrozen ? {sortFunc: frozenSortFunc} : {sortFunc: columnDefinition.sortFunc || sortFunc}),
    // In case sort is frozen, unfreeze the sort upon clicking on the sortable column header
    onSort: props.unfreezeSort,
  };

  return definition;
};

const largeColumns = [
  columnDefinitions.id,
  columnDefinitions.name,
  columnDefinitions.sector,
  columnDefinitions.industry,
  columnDefinitions.country,
  columnDefinitions.rating,
  columnDefinitions.weight,
  columnDefinitions.compliance,
];

const smallColumns = [
  columnDefinitions.id,
  columnDefinitions.name,
  columnDefinitions.sectorIndustryAndCountry,
  columnDefinitions.rating,
  columnDefinitions.weight,
  columnDefinitions.compliance,
];

const getLargeEditableColumns = props => [
  columnDefinitions.id,
  getColumnDefinitionForFrozenSorts(columnDefinitions.name, props),
  getColumnDefinitionForFrozenSorts(columnDefinitions.sector, props),
  getColumnDefinitionForFrozenSorts(columnDefinitions.industry, props),
  getColumnDefinitionForFrozenSorts(columnDefinitions.country, props),
  getColumnDefinitionForFrozenSorts(columnDefinitions.rating, props),
  columnDefinitions.startWeight,
  getColumnDefinitionForFrozenSorts(columnDefinitions.editableWeight, props),
  getColumnDefinitionForFrozenSorts(columnDefinitions.compliance, props),
  columnDefinitions.delete,
];

const getMediumEditableColumns = props => [
  columnDefinitions.id,
  getColumnDefinitionForFrozenSorts(columnDefinitions.name, props),
  columnDefinitions.sectorIndustryAndCountry,
  getColumnDefinitionForFrozenSorts(columnDefinitions.rating, props),
  columnDefinitions.startWeight,
  columnDefinitions.editableWeight,
  getColumnDefinitionForFrozenSorts(columnDefinitions.compliance, props),
  columnDefinitions.delete,
];

const getSmallEditableColumns = props => [
  columnDefinitions.id,
  getColumnDefinitionForFrozenSorts(columnDefinitions.name, props),
  getColumnDefinitionForFrozenSorts(columnDefinitions.rating, props),
  columnDefinitions.startWeight,
  columnDefinitions.editableWeight,
  getColumnDefinitionForFrozenSorts(columnDefinitions.compliance, props),
  columnDefinitions.delete,
];

const getColumns = props => {
  const {isEditable, viewportWidth} = props;
  if (isEditable) {
    if (viewportWidth < 960) {
      return getSmallEditableColumns(props);
    }
    if (viewportWidth < 1290) {
      return getMediumEditableColumns(props);
    }
    return getLargeEditableColumns(props);
  }

  return viewportWidth < 1180 ? smallColumns : largeColumns;
};

const ComplianceSecuritiesListing = compose(
  connect(null, {
    deleteHolding,
    updateHolding,
    unfreezeSort,
  }),
  withViewportSize,
  withProps(props => {
    const {data, isEditable, isSortFrozen} = props;
    return {
      cash: 1 - parseFloat(sumBy(data, 'weight').toFixed(4)),
      columns: getColumns(props),
      defaultSorted: isEditable ? editableSorted : sorted,
      isSortFrozen,
    };
  }),
  withHandlers({
    handleHoldingDelete: props => strategyHoldingId => {
      props
        .deleteHolding(strategyHoldingId)
        .then(response => {
          if (response.hasError) throw response.error;
        })
        .catch(() => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE));
    },
    handleHoldingWeightChange: props => (strategyHoldingId, weight) => {
      props
        .updateHolding(strategyHoldingId, {weight})
        .then(response => {
          if (response.hasError) throw response.error;
        })
        .catch(() => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE));
    },
  }),
  withContext(
    {
      handleHoldingDelete: PropTypes.func,
      handleHoldingWeightChange: PropTypes.func,
    },
    ({handleHoldingDelete, handleHoldingWeightChange}) => ({
      handleHoldingDelete,
      handleHoldingWeightChange,
    }),
  ),
  shouldUpdate((props, nextProps) => {
    const cash = formatFriendlyFloat(props.cash);
    const nextCash = formatFriendlyFloat(nextProps.cash);
    if (nextCash < 0 && cash !== nextCash) {
      warnAboutNegativeCash();
    }
    return true;
  }),
)(({cash, columns, data, defaultSorted}) => (
  <Fragment>
    <BootstrapTable
      bordered={false}
      keyField="key"
      data={data}
      columns={columns}
      wrapperClasses="mb-0"
      classes="table-compact mb-0"
      rowClasses={getSecurityRowClasses}
      defaultSorted={defaultSorted}
    />
    <table className="table table-compact">
      <tbody>
        <tr>
          {map(columns, ({dataField, headerClasses, headerStyle}) => (
            <td className={headerClasses} style={headerStyle} key={dataField}>
              {(dataField === 'name' || dataField === 'metaName') && 'Cash & Other Securities'}
              {(dataField === 'weight' || dataField === 'metaWeight') && (
                <strong className={cn({'text-danger': cash < 0})}>{formatFriendlyPercent(cash)}</strong>
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  </Fragment>
));

ComplianceSecuritiesListing.propTypes = {
  isEditable: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

export {ComplianceSecuritiesListing};
