import styled from 'styled-components';
import {TextBlock, RectShape} from 'react-placeholder/lib/placeholders';
import {Container, FlexContainer, AttachedWrapper} from 'v2/components/atoms/Containers';
import {SubtitleM, ParagraphS} from 'v2/components/atoms/Typeface';
import {OverallESGPanel, ESGIssuePannel} from 'v2/components/molecules/ESGDisplays';
import {esgRiskDescription} from 'v2/constants/esgPageDescriptions';
import {usePrefetchManager} from 'v2/hooks/prefetching/PrefetchManager';
import {
  useOverallStrategyAggregatePercentile,
  useStrategyAggregatePercentileByIssue,
} from 'v2/hooks/strategyAggregatePrecentileHoldings';
import ESGChartExample from '../../../assets/images/ESG_Risk_chart.svg';
import {Theme} from 'v2/components/atoms/theme';

const PercentileRankeTitle = styled(SubtitleM)`
  display: block;
  text-transform: uppercase;
  padding-bottom: 1.0625rem;
`;

const ESGRow = styled(Container)`
  border-bottom: 1px solid #d0d6de;
  &:last-of-type {
    border-bottom: none;
  }
`;

const ESGChart = styled.img`
  max-width: 28.375rem;
  margin-bottom: 5rem;
`;

const ReactPlaceholderESGRisk = () => (
  <AttachedWrapper>
    <FlexContainer padding="2.8125rem 2.8125rem 2.8125rem 4.75rem" gap="14rem">
      <FlexContainer direction="column" justify="space-between">
        <Container>
          <Container>
            <RectShape color={Theme.colors.grey_2} style={{width: 275, height: 100}} />
          </Container>
          <Container padding="2.875rem 0 0">
            <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
            <br></br>
            <TextBlock rows={4} color={Theme.colors.grey_2} />
          </Container>
        </Container>
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 450, height: 180}} />
        </Container>
      </FlexContainer>
      <Container minWidth="321px">
        <FlexContainer direction="column">
          <ESGRow padding=".6875rem 0.5rem 3.5rem">
            <TextBlock rows={3} color={Theme.colors.grey_2} />
          </ESGRow>
          <ESGRow padding="3.5rem 0.5rem">
            <TextBlock rows={3} color={Theme.colors.grey_2} />
          </ESGRow>
          <ESGRow padding="3.5rem 0.5rem">
            <TextBlock rows={3} color={Theme.colors.grey_2} />
          </ESGRow>
        </FlexContainer>
      </Container>
    </FlexContainer>
  </AttachedWrapper>
);

export const UT1ManagersEsgRisk = () => {
  const {data: overallData, isLoading: overallLoading} = useOverallStrategyAggregatePercentile();

  const {data: environmentalData, isLoading: environmentalLoading} = useStrategyAggregatePercentileByIssue(1);
  const {data: socialData, isLoading: socialLoading} = useStrategyAggregatePercentileByIssue(2);
  const {data: governanceData, isLoading: governanceLoading} = useStrategyAggregatePercentileByIssue(3);

  const isLoading = overallLoading || environmentalLoading || socialLoading || governanceLoading;

  usePrefetchManager();

  if (isLoading) {
    return <ReactPlaceholderESGRisk />;
  }

  return (
    <AttachedWrapper>
      <FlexContainer padding="2.8125rem 2.8125rem 0 4.75rem" gap="14rem">
        <FlexContainer direction="column" justify="space-between">
          <Container>
            <Container>
              <OverallESGPanel
                quintile={overallData.quintile}
                percentile={overallData.percentileRank}
                benchmark={overallData.benchmark}
              />
            </Container>
            <Container padding="2.875rem 0 0">
              <PercentileRankeTitle>Percentile Rank</PercentileRankeTitle>
              <ParagraphS as="p">{esgRiskDescription}</ParagraphS>
            </Container>
          </Container>
          <Container>
            <ESGChart src={ESGChartExample} />
          </Container>
        </FlexContainer>
        <Container minWidth="321px">
          <FlexContainer direction="column">
            <ESGRow padding=".6875rem 0.5rem 3.5rem">
              <ESGIssuePannel
                title="Environmental"
                percentile={environmentalData.percentileRank}
                benchmark={environmentalData.benchmark}
                quintile={environmentalData.quintile}
              />
            </ESGRow>
            <ESGRow padding="3.5rem 0.5rem">
              <ESGIssuePannel
                title="Social"
                percentile={socialData.percentileRank}
                benchmark={socialData.benchmark}
                quintile={socialData.quintile}
              />
            </ESGRow>
            <ESGRow padding="3.5rem 0.5rem">
              <ESGIssuePannel
                title="Governance"
                percentile={governanceData.percentileRank}
                benchmark={governanceData.benchmark}
                quintile={governanceData.quintile}
              />
            </ESGRow>
          </FlexContainer>
        </Container>
      </FlexContainer>
    </AttachedWrapper>
  );
};
