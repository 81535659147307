import {useEffect} from 'react';
import cn from 'classnames';
import {Field} from 'react-final-form';
import {AUTOCOMPLETE, PHONE_TYPES} from 'app/constants';
import {normalizeExtensionlessPhone} from 'app/utilities';
import {PhoneFormGroup} from 'v2/components/molecules/PhoneFormGroup';
import {SelectFormGroup} from 'v2/components/molecules/DropDown';
import {SecondaryIconButton} from 'v2/components/molecules/Button';
import {Flex0, Flex1, FormGridRow, RowsWrapper} from '../FormStyles';
import {phoneType} from '../UT3OnboardingProfile';

export const PhonesSection = ({fields: phones}: any) => {
  useEffect(() => {
    if (phones?.value && phones.length === 0) {
      phones.push({});
    }
  }, [phones]);

  return (
    <RowsWrapper>
      {phones.map((phone: phoneType, index: number) => {
        const isFirst = index === 0;
        return (
          <FormGridRow style={{gap: '2rem', flexWrap: 'nowrap'}} key={index}>
            <Flex1>
              <Field
                label={isFirst ? 'Phone Number' : null}
                name={`${phone}.number`}
                type="text"
                component={PhoneFormGroup}
                normalize={normalizeExtensionlessPhone}
                autoComplete={isFirst ? AUTOCOMPLETE.PHONE : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                totalRows={phones.length}
              />
            </Flex1>
            <Flex1 style={{minWidth: '10rem', maxWidth: '10rem'}}>
              <Field
                label={isFirst ? 'Phone Type' : null}
                name={`${phone}.type`}
                component={SelectFormGroup}
                options={PHONE_TYPES}
                shouldInsertDefault={true}
                style={{flex: 1}}
              />
            </Flex1>
            <Flex0>
              <div className={cn('flex-fixed w-8r form-grid-button', {'pt-0': !isFirst})}>
                {phones.length > 1 && (
                  <SecondaryIconButton
                    iconName="Cross"
                    onClick={() => phones.remove(index)}
                    className="btn-throttled"
                    size="10px"
                    style={{width: '44px', flex: 0}}
                    type="button"
                  />
                )}
              </div>
            </Flex0>
          </FormGridRow>
        );
      })}
    </RowsWrapper>
  );
};
