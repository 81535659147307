import styled from 'styled-components/macro';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {AdminCompanyInfo as AdminCompanyInfoProps} from 'v2/utilities/types/components/AdminCompanyInfo';
import {Container, FlexContainer} from '../atoms/Containers';
import {EditPencil} from '../atoms/icons';
import {ParagraphM, SubtitleS, SubtitleM, TitleS, TitleXS} from '../atoms/Typeface';

const Underline = styled(SubtitleM)`
  text-decoration: underline;
  white-space: nowrap;
`;

const SmallTitle = styled.span`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const IconButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const AdminCompanyInfo = ({
  description,
  country,
  email,
  ceo,
  carbonNeutralPledge,
  editEmail,
  editPledge,
}: AdminCompanyInfoProps) => {
  const handleEditEmail = () => {
    return editEmail?.();
  };

  const handleEditPledge = () => {
    return editPledge?.({...carbonNeutralPledge});
  };

  return (
    <FlexContainer direction="column" fullWidth>
      <Container>
        <TitleS>Company Info:</TitleS>
      </Container>
      <FlexContainer justify="space-between" padding="1.875rem 0.75rem 1.625rem" gap="5rem" wrap="wrap" fullWidth>
        <FlexContainer flex="1 1 0" direction="column">
          <Container maxWidth="37.125rem">
            <ParagraphM>{description ?? DATA_STATUS.NONE}</ParagraphM>
          </Container>
          <FlexContainer maxWidth="37.125rem" padding="1.625rem 0" gap="0.5625rem" items="center">
            <SubtitleM>Country Of Domicile:</SubtitleM>
            <SubtitleS>{country}</SubtitleS>
          </FlexContainer>
          <FlexContainer items="center" gap="1.875rem">
            <Container>
              <TitleXS>CEO:</TitleXS>
            </Container>
            <FlexContainer gap="1.125rem" direction="column">
              <Container>
                <Underline>Prefix</Underline>
              </Container>
              <Container>
                <SubtitleS>{ceo?.prefix ?? DATA_STATUS.NONE}</SubtitleS>
              </Container>
            </FlexContainer>
            <FlexContainer gap="1.125rem" direction="column">
              <Container>
                <Underline>First Name</Underline>
              </Container>
              <Container>
                <SubtitleS>{ceo?.firstName ?? DATA_STATUS.NONE}</SubtitleS>
              </Container>
            </FlexContainer>
            <FlexContainer gap="1.125rem" direction="column">
              <Container>
                <Underline>Last Name</Underline>
              </Container>
              <Container>
                <SubtitleS>{ceo?.lastName ?? DATA_STATUS.NONE}</SubtitleS>
              </Container>
            </FlexContainer>
            <FlexContainer gap="1.125rem" direction="column">
              <Container>
                <Underline>Suffix</Underline>
              </Container>
              <Container>
                <SubtitleS>{ceo?.suffix ?? DATA_STATUS.NONE}</SubtitleS>
              </Container>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer flex="1 1 0" direction="column">
          <FlexContainer>
            <Container width="min-content" minWidth="12.5rem">
              <SmallTitle>Carbon Neutral Pledge:</SmallTitle>
            </Container>
            <FlexContainer gap="1.875rem">
              <FlexContainer gap="1.125rem" direction="column">
                <Container>
                  <Underline>Pledge Status</Underline>
                </Container>
                <Container>
                  <SubtitleS>{carbonNeutralPledge?.pledgeStatus ?? DATA_STATUS.NONE}</SubtitleS>
                </Container>
              </FlexContainer>
              <FlexContainer gap="1.125rem" direction="column">
                <Container>
                  <Underline>Target Year</Underline>
                </Container>
                <Container>
                  <SubtitleS>{carbonNeutralPledge?.targetYear ?? DATA_STATUS.NONE}</SubtitleS>
                </Container>
              </FlexContainer>
              <FlexContainer gap="1.125rem" direction="column">
                <Container>
                  <Underline>Date Signed</Underline>
                </Container>
                <Container>
                  <SubtitleS style={{whiteSpace: 'nowrap'}}>
                    {carbonNeutralPledge?.dateSigned ?? DATA_STATUS.NONE}
                  </SubtitleS>
                </Container>
                <FlexContainer justify="flex-end" padding="1.625rem 0 0">
                  <IconButton onClick={handleEditPledge}>
                    <EditPencil />
                  </IconButton>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer items="center" gap="1.875rem">
            <Container width="min-content">
              <SmallTitle>Contact Email:</SmallTitle>
            </Container>
            <Container>
              <SubtitleM>{email ?? DATA_STATUS.NONE}</SubtitleM>
            </Container>
            <Container width="min-content">
              <IconButton onClick={handleEditEmail}>
                <EditPencil />
              </IconButton>
            </Container>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
