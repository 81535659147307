import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchJSON, fetchIfNeeded} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
  hasGetUserError: false,
};

// ACTION
export const ACTION = {
  CREATE_REQUEST: 'USER_CREATE_REQUEST',
  CREATE_RECEIVE: 'USER_CREATE_RECEIVE',
  CREATE_FROM_SETTINGS_REQUEST: 'USER_CREATE_FROM_SETTINGS_REQUEST',
  CREATE_FROM_SETTINGS_RECEIVE: 'USER_CREATE_FROM_SETTINGS_RECEIVE',
  FORGOT_PASSWORD_REQUEST: 'USER_FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_RECEIVE: 'USER_FORGOT_PASSWORD_RECEIVE',
  GET_ALL_REQUEST: 'USER_GET_ALL_REQUEST',
  GET_ALL_RECEIVE: 'USER_GET_ALL_RECEIVE',
  GET_ALL_FOR_SETTINGS_REQUEST: 'USER_GET_ALL_FOR_SETTINGS_REQUEST',
  GET_ALL_FOR_SETTINGS_RECEIVE: 'USER_GET_ALL_FOR_SETTINGS_RECEIVE',
  GET_BY_ID_REQUEST: 'USER_GET_BY_ID_REQUEST',
  GET_BY_ID_RECEIVE: 'USER_GET_BY_ID_RECEIVE',
  GET_CURRENT_REQUEST: 'USER_GET_CURRENT_USER_REQUEST',
  GET_CURRENT_RECEIVE: 'USER_GET_CURRENT_USER_RECEIVE',
  DELETE_REQUEST: 'USER_DELETE_REQUEST',
  DELETE_RECEIVE: 'USER_DELETE_RECEIVE',
  UPDATE_CURRENT_REQUEST: 'USER_UPDATE_CURRENT_REQUEST',
  UPDATE_CURRENT_RECEIVE: 'USER_UPDATE_CURRENT_RECEIVE',
  UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_RECEIVE: 'USER_UPDATE_RECEIVE',
  RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_RECEIVE: 'USER_RESET_PASSWORD_RECEIVE',
};

const userCreateRequest = createAction(ACTION.CREATE_REQUEST);
const userCreateReceive = createAction(ACTION.CREATE_RECEIVE);
const userCreateFromSettingsRequest = createAction(ACTION.CREATE_FROM_SETTINGS_REQUEST);
const userCreateFromSettingsReceive = createAction(ACTION.CREATE_FROM_SETTINGS_RECEIVE);
const userForgotPasswordRequest = createAction(ACTION.FORGOT_PASSWORD_REQUEST);
const userForgotPasswordReceive = createAction(ACTION.FORGOT_PASSWORD_RECEIVE);
const userGetAllRequest = createAction(ACTION.GET_ALL_REQUEST);
const userGetAllReceive = createAction(ACTION.GET_ALL_RECEIVE);
const userGetAllForSettingsRequest = createAction(ACTION.GET_ALL_FOR_SETTINGS_REQUEST);
const userGetAllForSettingsReceive = createAction(ACTION.GET_ALL_FOR_SETTINGS_RECEIVE);
const userGetByIdRequest = createAction(ACTION.GET_BY_ID_REQUEST);
const userGetByIdReceive = createAction(ACTION.GET_BY_ID_RECEIVE);
const userGetCurrentRequest = createAction(ACTION.GET_CURRENT_REQUEST);
const userGetCurrentReceive = createAction(ACTION.GET_CURRENT_RECEIVE);
const userDeleteRequest = createAction(ACTION.DELETE_REQUEST);
const userDeleteReceive = createAction(ACTION.DELETE_RECEIVE);
const userUpdateRequest = createAction(ACTION.UPDATE_REQUEST);
const userUpdateReceive = createAction(ACTION.UPDATE_RECEIVE);
const userUpdateCurrentRequest = createAction(ACTION.UPDATE_CURRENT_REQUEST);
const userUpdateCurrentReceive = createAction(ACTION.UPDATE_CURRENT_RECEIVE);
const userResetPasswordRequest = createAction(ACTION.RESET_PASSWORD_REQUEST);
const userResetPasswordReceive = createAction(ACTION.RESET_PASSWORD_RECEIVE);

const api = {
  create: user =>
    fetchAuthJSON('user', {
      method: 'post',
      body: JSON.stringify(user),
    }),
  createFromSettings: user =>
    fetchAuthJSON('user/settings', {
      method: 'post',
      body: JSON.stringify(user),
    }),
  forgotPassword: email =>
    fetchJSON('user/forgotPassword', {
      method: 'put',
      body: JSON.stringify(email),
    }),
  resetPassword: (resetToken, password) =>
    fetchJSON(`user/resetPassword/${resetToken}`, {
      method: 'put',
      body: JSON.stringify({password}),
    }),
  getAll: () => fetchAuthJSON('user', {method: 'get'}),
  getAllPagedForSettings: options => {
    let url = 'user/settings/list?';
    url += 'fields=id,firstName,lastName,email,roles,jobRole';
    url += `&page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`;
    }
    if (options.userType) {
      url += `&userType=${options.userType}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getById: (userId, fields) => {
    let url = `user/${userId}`;
    if (fields) {
      url += `?fields=${fields}`;
    }
    return fetchAuthJSON(url, {method: 'get'});
  },
  getCurrent: () => fetchAuthJSON('user/current', {method: 'get'}),
  delete: userId => fetchAuthJSON(`user/${userId}`, {method: 'delete'}),
  update: (userId, user) =>
    fetchAuthJSON(`user/${userId}`, {
      method: 'put',
      body: JSON.stringify(user),
    }),
  updateCurrent: user =>
    fetchAuthJSON('user/current', {
      method: 'put',
      body: JSON.stringify(user),
    }),
};

export function createUser(user) {
  return dispatch => {
    dispatch(userCreateRequest(user));
    return dispatch(userCreateReceive(api.create(user)));
  };
}

export function createFromSettingsUser(user) {
  return dispatch => {
    dispatch(userCreateFromSettingsRequest(user));
    return dispatch(userCreateFromSettingsReceive(api.createFromSettings(user)));
  };
}

export function forgotPassword(email) {
  return dispatch => {
    dispatch(userForgotPasswordRequest());
    return dispatch(userForgotPasswordReceive(api.forgotPassword(email)));
  };
}

export function getAllUsers() {
  return dispatch => {
    dispatch(userGetAllRequest());
    return dispatch(userGetAllReceive(api.getAll()));
  };
}

export function getAllPagedForSettingsUsers(options) {
  return dispatch => {
    dispatch(userGetAllForSettingsRequest());
    return dispatch(userGetAllForSettingsReceive(api.getAllPagedForSettings(options)));
  };
}

export function getByIdUser(userId, fields) {
  return dispatch => {
    dispatch(userGetByIdRequest());
    return dispatch(userGetByIdReceive(api.getById(userId, fields)));
  };
}

export function getCurrentUser() {
  return dispatch => {
    dispatch(userGetCurrentRequest());
    return dispatch(userGetCurrentReceive(api.getCurrent()));
  };
}

export function deleteUser(userId) {
  return dispatch => {
    dispatch(userDeleteRequest());
    return dispatch(userDeleteReceive(api.delete(userId)));
  };
}

export function updateCurrentUser(user) {
  return dispatch => {
    dispatch(userUpdateCurrentRequest());
    return dispatch(userUpdateCurrentReceive(api.updateCurrent(user)));
  };
}

export function updateUser(userId, user) {
  return dispatch => {
    dispatch(userUpdateRequest());
    return dispatch(userUpdateReceive(api.update(userId, user)));
  };
}

export function resetPassword(token, password) {
  return dispatch => {
    dispatch(userResetPasswordRequest());
    return dispatch(userResetPasswordReceive(api.resetPassword(token, password)));
  };
}

export function fetchCurrentUserIfNeeded() {
  return fetchIfNeeded('user', 'currentUser', () => getCurrentUser());
}

// REDUCER
export const user = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.CREATE_REQUEST:
    case ACTION.CREATE_FROM_SETTINGS_REQUEST:
    case ACTION.GET_ALL_REQUEST:
    case ACTION.DELETE_REQUEST:
    case ACTION.UPDATE_REQUEST:
    case ACTION.UPDATE_CURRENT_REQUEST:
    case ACTION.FORGOT_PASSWORD_REQUEST:
    case ACTION.RESET_PASSWORD_REQUEST:
    case ACTION.GET_ALL_FOR_SETTINGS_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_CURRENT_REQUEST:
    case ACTION.GET_BY_ID_REQUEST:
      return assignMergedState({isFetching: true, hasGetUserError: false});
    case ACTION.CREATE_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {createdAccount: payload}),
      );
    case ACTION.GET_ALL_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {allUsers: payload}));
    case ACTION.GET_BY_ID_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload, hasGetUserError: true} : {editUser: payload}),
      );
    case ACTION.CREATE_FROM_SETTINGS_RECEIVE:
    case ACTION.UPDATE_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {editUser: payload}));
    case ACTION.FORGOT_PASSWORD_RECEIVE:
    case ACTION.RESET_PASSWORD_RECEIVE:
    case ACTION.DELETE_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {}));
    case ACTION.GET_CURRENT_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload, hasGetUserError: true} : {currentUser: payload}),
      );
    case ACTION.UPDATE_CURRENT_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {currentUser: payload}),
      );
    case ACTION.GET_ALL_FOR_SETTINGS_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError ? {error: payload} : {allUsersSettings: payload.items, allUsersSettingsTotal: payload.total},
        ),
      );
    default:
      return state;
  }
};
