import {ChangeEvent, KeyboardEvent, FC, InputHTMLAttributes} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {ErrorField} from './ErrorField';
import {ParagraphS} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';

const Input = styled.input.attrs(() => ({
  type: 'text',
}))`
  background-color: #f3f2f7;
  border-radius: 0.5rem;
  width: ${({width}) => width ?? '29.5rem'};
  border: 1px solid transparent;
  height: 2.75rem;
  padding: 0.75rem;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;

  &::placeholder {
    color: ${props => props.theme.colors.grey_4};
  }

  &:focus-within {
    border-color: ${({theme}) => theme.colors.harmony};
    box-shadow: 0 0 0 0.25rem rgba(143, 209, 239, 0.5);
  }
`;

interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> {
  onChange?: (value: string) => any;
  width?: string;
}

export const TextInput: React.FC<TextInputProps> = ({value, onChange, placeholder, onKeyUp, style, width, ...rest}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    return onChange?.(e.target.value);
  };

  const handleKeyEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    return onKeyUp?.(e);
  };

  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyUp={handleKeyEvent}
      style={style}
      width={width}
      {...rest}
    />
  );
};

export interface ITextFormGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  input: any;
  label?: string;
  labelHint?: string;
  children?: React.ReactNode;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  autoComplete?: string;
  className?: string;
  addOnPrepend?: React.ReactNode;
  hideError?: boolean;
  addOnAppend?: React.ReactNode;
  inputClassName?: string;
  id?: string;
  autoFocus?: boolean;
  hasSplitFeedback?: boolean;
  totalRows?: number;
  meta: any;
  width?: string;
}

export const TextFormGroup: FC<ITextFormGroupProps> = ({
  input,
  label,
  labelHint,
  children,
  placeholder,
  type,
  maxLength,
  disabled,
  readOnly,
  autoComplete,
  className,
  addOnPrepend,
  hideError,
  addOnAppend,
  inputClassName,
  id,
  autoFocus,
  hasSplitFeedback,
  meta: {invalid, error, submitError},
  width,
  ...rest
}) => {
  let inputElement = (
    <TextInput
      {...input}
      id={id || input.name}
      maxLength={maxLength}
      autoComplete={autoComplete}
      disabled={disabled}
      readOnly={readOnly}
      type={type}
      className={cn('form-control', {'is-invalid': invalid && error}, inputClassName)}
      autoFocus={autoFocus}
      placeholder={placeholder}
      style={{width: width ?? '100%', flex: 1, color: Theme.colors.dark}}
      {...rest}
    />
  );

  // The div that shows feedback that the field is invalid (e.g. "Required")
  const invalidFieldFeedback = (
    <div className={cn('invalid-feedback', {'w-auto float-right': hasSplitFeedback})}>{error}</div>
  );

  // If there prepend/append, then ensure it is located correctly next to the input along with any validation feedback
  if (addOnPrepend || addOnAppend) {
    inputElement = (
      <div className="input-group">
        {addOnPrepend ? <span className="input-group-addon">{addOnPrepend}</span> : null}
        {inputElement}
        {addOnAppend ? <span className="input-group-addon">{addOnAppend}</span> : null}
        {invalidFieldFeedback}
      </div>
    );
  }

  return (
    <div style={{flex: 1}}>
      <div>
        {label && (
          <ParagraphS as="label" htmlFor={id || input.name}>
            {label}
          </ParagraphS>
        )}
        {labelHint && <span className="label-hint">{labelHint}</span>}
      </div>
      {inputElement}
      {children}
      {!hideError && !addOnPrepend && !addOnAppend && <ErrorField error={invalid && (error || submitError)} />}
    </div>
  );
};

/**
 * Function that accepts a phone number and returns
 * object with the prefix and number
 */
export const parsePhoneNumber = (phoneNumber: string) => {
  const prefix = phoneNumber.substring(0, 3);
  const number = phoneNumber.substring(3);
  return {prefix, number};
};

/**
 * Function that accepts an object with the prefix and number
 * and returns the full phone number
 */
export const formatPhoneNumber = ({prefix, phoneNumber}: {prefix: string; phoneNumber: string}) => {
  return `${prefix}${phoneNumber}`;
};
