import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import {ISSUE_TYPE} from 'app/constants';
import {
  BooleanComparisonDoughnut,
  BooleanLegend,
  DoughnutLegend,
  IssueCard,
  PercentileRating,
  RadioDotLegend,
} from 'app/components';
import faCheck from 'app/fontawesome-pro-light/faCheck';
import faTimes from 'app/fontawesome-pro-light/faTimes';

const IssueCardWithData = ({iconic, issue: {code, name, description, type, data, unit}}) => (
  <IssueCard id={code}>
    <Fragment key="front">
      <div className="issue-card-header flex-fill">
        <strong>{code}</strong> {name}
      </div>
      {type === ISSUE_TYPE.PERCENTILE && data && (
        <Fragment>
          <PercentileRating values={data} />
          <div className="quartile-legend">
            {map(data, ({label}) => (
              <div key={label} className="quartile-legend-label">
                {label}
              </div>
            ))}
          </div>
        </Fragment>
      )}
      {type === ISSUE_TYPE.BOOLEAN && data && (
        <Fragment>
          {iconic ? (
            <Fragment>
              <div className={cn('iconic-badge', data[0].noCount === 0 ? 'is-green' : 'is-red')}>
                <div className="iconic-badge-inner">
                  {data[0].noCount === 0 ? <Icon icon={faCheck} /> : <Icon icon={faTimes} />}
                </div>
              </div>
              <BooleanLegend data={data} iconic />
            </Fragment>
          ) : (
            <Fragment>
              <BooleanComparisonDoughnut data={data} unit={unit} />
              <BooleanLegend data={data} />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>

    <Fragment key="back">
      <div className="issue-card-header">
        <strong>{code}</strong> {name}
      </div>
      <div className="issue-card-description flex-fill">
        <strong>{description}</strong>
      </div>
      <div className="issue-card-footer">
        {type === ISSUE_TYPE.PERCENTILE && (
          <Fragment>
            <h6 className="mb-3">Chart Legend</h6>
            <RadioDotLegend />
          </Fragment>
        )}
        {type === ISSUE_TYPE.BOOLEAN && !iconic && (
          <Fragment>
            <h6 className="mb-3">Chart Legend</h6>
            <DoughnutLegend />
          </Fragment>
        )}
      </div>
    </Fragment>
  </IssueCard>
);

IssueCardWithData.propTypes = {
  iconic: PropTypes.bool,
  issue: PropTypes.object.isRequired,
};

export {IssueCardWithData};
