import {getIssueBadgeAssetUrl} from './getIssueBadgeAssetUrl';

export const getCompanyBadges = companyAnnualIssues => {
  const lastYear = new Date().getFullYear() - 1;
  const companyBadges = [];

  Object.values(companyAnnualIssues).forEach(issues => {
    issues.forEach(issue => {
      if (issue.data[lastYear] && issue.data[lastYear].receivesBadge) {
        companyBadges.push(getIssueBadgeAssetUrl(issue.id));
      }
    });
  });

  return companyBadges;
};
