import {last} from 'lodash';

const getIssueDataDisplayPercent = data => {
  return Math.round((100 * data.yesCount) / (data.yesCount + data.noCount));
};

export const getIssueDataAnalysis = data => {
  // always compare first to last
  const performance = getIssueDataDisplayPercent(data[0]);
  const reference = getIssueDataDisplayPercent(last(data));

  return {
    isPromising: performance >= reference,
    isWorrisome: performance < reference,
  };
};
