import React, {useState} from 'react';
import ReactModal from 'react-modal';
import {Theme} from 'v2/components/atoms/theme';
import {BorderlessButton, IconOnlyButton, PrimaryIconButton} from '../Button';
import styled from 'styled-components';
import '../../atoms/atoms.css';
import {Title3, TitleS} from 'v2/components/atoms/Typeface';

export const getDrawerStyles = ({width = '23.75rem'}) => ({
  content: {
    height: '100%',
    width: width,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    padding: '0',
    backgroundColor: Theme.colors.white,
    boxShadow: Theme.shadows.shadow_3,
    justifyContent: 'space-between',
  } as React.CSSProperties,
  overlay: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: `${Theme.colors.dark}80`,
    inset: 0,
    position: 'fixed',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 1001,
  } as React.CSSProperties,
});

const TitleRow = styled.div`
  &&& {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 600;
    align-items: center;
    flex: 0;
    width: 100%;
    &:active {
      border: none;
      outline: none;
    }
  }
`;

const DrawerSection = styled.div`
  padding: 0;
  display: flex;
  padding: 1.75rem;
  flex-direction: column;
  gap: 1rem;
`;

const BodySection = styled(DrawerSection)`
  flex: 1;
  overflow-x: hidden;
  padding: 0;
`;

const FooterSection = styled(DrawerSection)`
  flex: 0;
  border-top: 1px solid #d0d6de;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
`;

const HeaderButtonLeft = styled(IconOnlyButton)`
  &&& {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    justify-content: flex-start;
    flex: 1;
  }
`;

const HeaderButtonRight = styled(HeaderButtonLeft)`
  &&& {
    justify-content: flex-end;
  }
`;

const ResetButton = styled(BorderlessButton)`
  &&& {
    &:hover {
      background: ${props => props.theme.colors.grey_2};
      color: ${props => props.theme.colors.dark};
    }
  }
`;

const Title = styled(TitleS)`
  display: block;
  flex: 9;
  font-size: 1.5rem;
  text-align: center;
`;

export const useSideDrawer = () => {
  const [isOpen, setDrawerOpen] = useState(false);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleOpen = () => {
    setDrawerOpen(true);
  };

  return {isOpen, handleOpen, handleClose};
};

export interface DrawerProps {
  isOpen: boolean;
  onClose: (args?: any) => any;
  title?: string;
}

/**
 * SideDrawer is a component that renders a drawer on the right side of the screen.
 * The body section does not have padding, so the wrapped content should provide it.
 */
export const SideDrawer: React.FC<{
  isOpen: boolean;
  onClose: (args?: any) => any;
  title?: string;
  onBack?: () => void;
  onApply?: () => void;
  onReset?: () => void;
}> = ({children, isOpen, onClose = () => {}, onReset = () => {}, onApply = () => {}, onBack, title}) => {
  return (
    <ReactModal
      closeTimeoutMS={200}
      isOpen={isOpen}
      className="my-drawer-content"
      overlayClassName={'my-drawer-overlay'}
      style={getDrawerStyles({width: '23.75rem'})}
      onRequestClose={onClose}
    >
      <DrawerSection>
        <TitleRow>
          {onBack ? (
            <HeaderButtonLeft
              size="0.875rem"
              style={{justifyContent: 'flex-start'}}
              iconName={'ChevronLeft'}
              onClick={onBack}
            />
          ) : (
            <div style={{flex: 1}}></div>
          )}
          <Title>{title}</Title>
          <HeaderButtonRight
            size="0.875rem"
            style={{justifyContent: 'flex-end'}}
            iconName={'Cross'}
            onClick={onClose}
          />
        </TitleRow>
      </DrawerSection>
      <BodySection>{children}</BodySection>
      <FooterSection>
        <PrimaryIconButton size="1rem" iconName="FilterList" text="Apply Filter" onClick={onApply} rotate={90} />
        <ResetButton onClick={onReset}>Reset Filters</ResetButton>
      </FooterSection>
    </ReactModal>
  );
};
