/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';
import {PageContainer} from '../components';
import {ratingFormatter} from '../../utilities/ratingFormatter';
import {formatCurrency} from '../../utilities/formatCurrency';

export class HoldingsPerformancePage extends Component {
  renderTable(data) {
    return data.map(item => (
      <tr key={item.isin}>
        <td>
          <div className="company-name-container">{item.companies.name}</div>
        </td>
        <td>{item.isin}</td>
        <td>{item.ticker}</td>
        <td className="text-center">{item.country.code}</td>
        <td>{item.sector.name}</td>
        <td className="text-center">
          <div>
            <span style={{display: 'inline-block'}}>{ratingFormatter(item.companies.rating)}</span>
          </div>
        </td>
        <td className="text-right">{formatCurrency(item.heldAmount)}</td>
      </tr>
    ));
  }

  render() {
    const {companyName, topHoldings, riskHoldings, page} = this.props;

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} companyName={companyName} pageNumber={page}>
        <div>
          <h2 className="text-uppercase report-page-header-blue">Holdings</h2>
        </div>
        <div className="pt-8 pb-3">
          <h3 className="text-uppercase pb-2">Most Held</h3>
          <div className="report-holdings-table-container">
            <table className="table">
              <thead>
                <tr className="headers">
                  <th width={'28%'}>Company Name</th>
                  <th width={'15%'}>ISIN</th>
                  <th width={'9%'}>Ticker</th>
                  <th width={'10%'} className="text-center">
                    Country
                  </th>
                  <th width={'20%'}>Sector</th>
                  <th width={'8%'} className="text-center">
                    Rating
                  </th>
                  <th width={'10%'} className="text-center">
                    Held
                  </th>
                </tr>
                <tr className="spacing" />
              </thead>
              <tbody>{topHoldings && this.renderTable(topHoldings)}</tbody>
            </table>
          </div>
        </div>
        <div className="pt-7">
          <h3 className="text-uppercase pb-2">High Risk</h3>
          <div className="report-holdings-table-container">
            <table className="table">
              <thead>
                <tr className="headers">
                  <th width={'28%'}>Company Name</th>
                  <th width={'15%'}>ISIN</th>
                  <th width={'9%'}>Ticker</th>
                  <th width={'10%'} className="text-center">
                    Country
                  </th>
                  <th width={'20%'}>Sector</th>
                  <th width={'8%'} className="text-center">
                    Rating
                  </th>
                  <th width={'10%'} className="text-center">
                    Held
                  </th>
                </tr>
                <tr className="spacing" />
              </thead>
              <tbody>{riskHoldings && this.renderTable(riskHoldings)}</tbody>
            </table>
          </div>
        </div>
      </PageContainer>
    );
  }
}
