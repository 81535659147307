module.exports = {
  prefix: 'fal',
  iconName: 'landmark',
  icon: [
    512,
    512,
    [],
    'f498',
    'M565.62 92.11L299.24 2.04C295.62.68 291.81 0 288 0s-7.62.68-11.24 2.04L10.38 92.11A16.001 16.001 0 0 0 0 107.09V144c0 8.84 7.16 16 16 16h544c8.84 0 16-7.16 16-16v-36.91c0-6.67-4.14-12.64-10.38-14.98zM544 128H32v-9.42L288 32l256 86.56V128zm24 352h-24v-64c0-17.67-16.37-32-36.57-32H496V192h-32v192h-96V192h-32v192h-96V192h-32v192h-96V192H80v192H68.57C48.37 384 32 398.33 32 416v64H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h560c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-56 0H64v-62.72c.7-.52 2.21-1.28 4.57-1.28h438.86c2.37 0 3.87.76 4.57 1.28V480z',
  ],
};
