import {useEffect} from 'react';
import {useParams} from 'react-router';
import {useGetStrategyQuery, usePrefetch} from 'v2/redux/typeormEndpoints';
import {usePrefetch as useHarmonyApiPrefetch} from 'v2/redux/harmonyApi';

export const usePrefetchManager = () => {
  const {strategyId = ''} = useParams<{strategyId: string}>();
  const {data: strategyData} = useGetStrategyQuery({strategyId}, {skip: !strategyId});
  const firmId = strategyData?.firm?.id ?? 0;
  const prefetchEmployees = usePrefetch('getFirmEmployees');
  useHarmonyApiPrefetch('getStrategyHoldings')({strategyId});
  const prefetchStrategy = usePrefetch('getStrategy');
  const prefetchIndexes = usePrefetch('getStrategyAggregatePercentileRankIndexes');

  const prefetchLetters = usePrefetch('getStrategyEngagementLetters');

  useEffect(() => {
    if (firmId) {
      prefetchEmployees({firmId});
    }
  }, [firmId, prefetchEmployees]);

  useEffect(() => {
    if (strategyId) {
      prefetchStrategy({strategyId});
      prefetchIndexes({strategyId, issueId: 1});
      prefetchIndexes({strategyId, issueId: 2});
      prefetchIndexes({strategyId, issueId: 3});
      prefetchLetters({strategyId});
    }
  }, [prefetchIndexes, prefetchLetters, prefetchStrategy, strategyId]);
};
