import PropTypes from 'prop-types';
import React from 'react';

import {COLOR} from 'app/constants';
import {imgSrcFromSvgPath} from 'app/utilities';

const IssueIconImage = ({path, fill = COLOR.WHITE, backgroundFill = COLOR.NAVY, ...props}) => {
  const svgString = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1" viewBox="0 0 126 126" fill="${fill}">
    <circle fill="${backgroundFill}" cx="63" cy="63" r="63" />
    <path d="${path}"/>
    </svg>`;

  return <img src={imgSrcFromSvgPath(svgString)} {...props} />;
};

IssueIconImage.propTypes = {
  path: PropTypes.string.isRequired,
  fill: PropTypes.string,
};

export {IssueIconImage};
