module.exports = {
  prefix: 'fal',
  iconName: 'door-closed',
  icon: [
    640,
    512,
    [],
    'f52a',
    'M400 288c17.67 0 32-14.33 32-32s-14.33-32-32-32-32 14.33-32 32 14.33 32 32 32zm232 192H512V32c0-17.67-14.33-32-32-32H160c-17.67 0-32 14.33-32 32v448H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h624c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-152 0H160V32h320v448z',
  ],
};
