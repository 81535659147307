import React, {Fragment} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import {compose, lifecycle, withHandlers, withPropsOnChange, withState} from 'recompose';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import throttleHandler from '@hocs/throttle-handler';

import {PORTFOLIO_STATUS} from 'app/constants';
import {ToolTip} from 'app/components';
import faQuestionCircle from 'app/fontawesome-pro-light/faQuestionCircle';

const ComplianceStatus = compose(
  withPropsOnChange([''], () => ({
    tooltipId: `tooltip_${uniqueId()}`,
  })),
  withPropsOnChange(['policyViolations'], ({policyViolations}) => ({
    hasPolicyViolations: !!(policyViolations && policyViolations.length),
  })),
)(({hasPolicyViolations, label, status, policyViolations, tooltipId}) => (
  <div className="compliance-status mr-4">
    {label}:{' '}
    <span
      className={cn({
        [`text-${status.color}`]: status.color,
        'cursor-pointer': hasPolicyViolations,
      })}
      id={tooltipId}
    >
      {status.label}{' '}
      {hasPolicyViolations && (
        <Fragment>
          <Icon icon={faQuestionCircle} />
          <ToolTip target={tooltipId}>
            <ul className="text-white text-left mb-0 ml-3 p-0">
              {map(policyViolations, (policyViolation, index) => (
                <li key={index} className="mb-1">
                  {policyViolation}
                </li>
              ))}
            </ul>
          </ToolTip>
        </Fragment>
      )}
    </span>
  </div>
));

const ComplianceStatusBar = compose(
  withPropsOnChange([], () => ({
    rootRef: React.createRef(),
  })),
  withState('isStuck', 'setIsStuck', false),
  withHandlers({
    handleScrollOrResize:
      ({rootRef, setIsStuck}) =>
      () => {
        if (rootRef && rootRef.current) {
          const {top} = rootRef.current.getBoundingClientRect();
          setIsStuck(top === 0);
        }
      },
  }),
  throttleHandler('handleScrollOrResize', 100),
  lifecycle({
    componentDidMount() {
      window.addEventListener('scroll', this.props.handleScrollOrResize);
    },
    componentWillUnmount() {
      window.removeEventListener('scroll', this.props.handleScrollOrResize);
    },
  }),
)(
  ({
    contentClasses,
    contentStyle,
    classes,
    containerClasses,
    containerStyle,
    isStuck,
    lastUploadedCompliance,
    lastUploadedPolicyViolations,
    rootRef,
    style,
    trialCompliance,
    trialPolicyViolations,
  }) => (
    <div
      ref={rootRef}
      className={cn('compliance-status-bar p-content-lg py-0', {'is-stuck': isStuck}, classes)}
      style={style}
    >
      <div className={cn('compliance-status-bar-container', containerClasses)} style={containerStyle}>
        <div className={cn('compliance-status-bar-content', contentClasses)} style={contentStyle}>
          {lastUploadedCompliance && (
            <ComplianceStatus
              label="Last Uploaded Portfolio"
              status={lastUploadedCompliance}
              policyViolations={lastUploadedPolicyViolations}
            />
          )}
          {trialCompliance && (
            <ComplianceStatus
              label="Current Portfolio"
              status={trialCompliance}
              policyViolations={trialPolicyViolations}
            />
          )}
        </div>
      </div>
    </div>
  ),
);

ComplianceStatusBar.propTypes = {
  lastUploadedCompliance: PropTypes.object,
  lastUploadedPolicyViolations: PropTypes.array,
  trialCompliance: PropTypes.object,
  trialPolicyViolations: PropTypes.array,
};

ComplianceStatusBar.defaultProps = {
  lastUploadedCompliance: PORTFOLIO_STATUS.NA,
  trialCompliance: PORTFOLIO_STATUS.NA,
};

export {ComplianceStatusBar};
