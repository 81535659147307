import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {filter, find, get, indexOf, last, map} from 'lodash';
import {push} from 'connected-react-router';
import cn from 'classnames';
import {compose, withHandlers, withPropsOnChange} from 'recompose';
import PropTypes from 'prop-types';

import {
  EXAMPLE_HARMONY_INDEX_MAPPINGS,
  INDEX_CONVERSION_STEP,
  INDEX_CONVERSION_STEPS,
  PORTFOLIO_MANAGEMENT_TYPE,
  ROUTE,
} from 'app/constants';
import {Button, ProgressIndicator} from 'app/components';
import faCheck from 'app/fontawesome-pro-light/faCheck';
import {getAllManagersPaged, clearNewPassiveStrategyIds} from 'app/redux/firm';
import {fetchMarketIndexesIfNeeded} from 'app/redux/marketIndex';
import {updateStrategy as updateStrategyAction} from 'app/redux/strategy';

class IndexCenterPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchMarketIndexesIfNeeded());
    props.dispatch(
      getAllManagersPaged({
        search: '',
        page: 1,
        pageSize: 500, // effectively not paged
        sortField: 'name',
        sortOrder: 'asc',
      }),
    );

    // Copy these initial values into state
    this.state = {newPassiveStrategyIds: [...this.props.newPassiveStrategyIds]};
  }

  componentDidMount() {
    // Once loaded into state, clear the values from redux store
    this.props.dispatch(clearNewPassiveStrategyIds());
  }

  handleAssignClick = (strategyId, marketIndexId) => {
    this.props.dispatch(
      updateStrategyAction(strategyId, {
        harmonyIndexConversionStep: INDEX_CONVERSION_STEP.DRAFT_EMAIL.step,
        harmonyIndexConversionMarketIndexId: marketIndexId,
      }),
    );
    return strategyId;
  };

  handleDraftClick = strategyId => {
    this.props.dispatch(push(ROUTE.HARMONY_INDEX_DRAFT.path(strategyId)));
  };

  render() {
    const {exampleHarmonyIndexMappings, isFetching, passiveStrategies, isUserType1} = this.props;

    const hasPassiveStrategies = passiveStrategies && passiveStrategies.length > 0;

    return (
      <Fragment>
        <div className="index-center-header">
          <div className="index-center-intro">
            <h1 className="mb-6">{ROUTE.HARMONY_INDEX_CENTER.title}</h1>
            <p>
              Harmony Analytics has made it easy to follow great ESG standards by creating market indexes with an ESG
              focus in mind. Once you have assigned a passively managed strategy to use a Harmony Index, the investment
              manager will then get notifications when their portfolio it out of compliance with the Harmony Index.
            </p>
            <p>
              Please <Link to={ROUTE.CONTACT.path()}>contact us</Link> with any questions about our indexes.
            </p>
            <Link to={ROUTE.CONTACT.path()} className="btn btn-primary btn-lg" style={{maxWidth: 350}}>
              Contact Us for Whitepaper
            </Link>
            {!isFetching && !hasPassiveStrategies && (
              <p className="mt-6">
                <b>Note: You do not currently have any passively managed strategies under your plan.</b>
              </p>
            )}
          </div>
          <div className="index-center-breakdown">
            <h1 className="index-center-breakdown-title mb-5">How do Harmony Indexes compare?</h1>
            <p>
              Below is the list of the Harmony Indexes and the Indexes they are modeled after. However Harmony Indexes
              take ESG factors into consideration.
            </p>
            <div className="breakdown-list-item is-header">
              <div>Standard Index</div>
              <strong>Harmony Index</strong>
            </div>
            <ul className="breakdown-list">
              {map(exampleHarmonyIndexMappings, this.renderExampleHarmonyIndexMappings)}
            </ul>
          </div>
        </div>

        {!isFetching && hasPassiveStrategies && (
          <Fragment>
            <h3 className="mt-12">Passively Managed Strategies</h3>
            <ul className="index-center-list">
              {isUserType1
                ? map(passiveStrategies, this.renderIndexCenterListItem)
                : map(passiveStrategies, this.renderNonEditableIndexCenterListItem)}
            </ul>
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderIndexCenterListItem = strategy => (
    <IndexCenterListItem
      key={strategy.id}
      strategy={strategy}
      harmonyIndexes={this.props.harmonyIndexes}
      onAssignClick={this.handleAssignClick}
      onDraftClick={this.handleDraftClick}
      isNewPassive={this.isNewPassive(strategy)}
    />
  );

  isNewPassive(strategy) {
    return indexOf(this.state.newPassiveStrategyIds, strategy.id) >= 0;
  }

  renderNonEditableIndexCenterListItem = strategy => (
    <IndexCenterListItem
      key={strategy.id}
      strategy={strategy}
      harmonyIndexes={this.props.harmonyIndexes}
      onAssignClick={this.handleAssignClick}
      onDraftClick={this.handleDraftClick}
      isEditable={false}
      isNewPassive={this.isNewPassive(strategy)}
    />
  );

  renderExampleHarmonyIndexMappings = ({exampleMappedIndexName, name}) => (
    <li key={name} className="breakdown-list-item">
      <div>{exampleMappedIndexName}</div>
      <strong>{name}</strong>
    </li>
  );
}

IndexCenterPage.defaultProps = {exampleHarmonyIndexMappings: EXAMPLE_HARMONY_INDEX_MAPPINGS};

class IndexCenterListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleAssignClick = () => {
    const {strategy, onAssignClick} = this.props;
    const {marketIndexId} = this.state;
    if (marketIndexId) {
      onAssignClick(strategy.id, marketIndexId);
    }
  };

  handleMarketIndexChange = event => {
    this.setState({marketIndexId: event.target.value});
  };

  render() {
    const {harmonyIndexes, isEditable, strategy, isNewPassive} = this.props;
    const {marketIndexId} = this.state;

    const marketIndexName = get(strategy, 'marketIndex.name', 'Not specified');
    const isHarmonyIndex = get(strategy, 'marketIndex.isHarmonyIndex', false);
    const currentStepKey = get(strategy, 'harmonyIndexConversionStep') || INDEX_CONVERSION_STEP.ASSIGN_INDEX.step;

    return (
      <li className={cn('index-center-list-item', {'is-new-passive': isNewPassive})}>
        <div className="index-center-list-item-header">
          <div className="index-center-list-item-title">
            {strategy.firm.name}:<br /> {strategy.name}
          </div>
          <div className="index-center-list-item-current-index">
            Current Index:
            <br /> {marketIndexName}
          </div>

          {isHarmonyIndex && (
            <Fragment>
              <div className="index-center-list-item-status text-light-gray">
                <Icon icon={faCheck} /> Assignment successful
              </div>
              <div className="index-center-list-item-actions">
                {!!marketIndexName && (
                  <Link className="btn btn-outline btn-block w-100" to={ROUTE.HARMONY_INDEX_DRAFT.path(strategy.id)}>
                    Copy Email &amp; IMA Template
                  </Link>
                )}
              </div>
            </Fragment>
          )}
          {!isHarmonyIndex && currentStepKey === INDEX_CONVERSION_STEP.ASSIGN_INDEX.step && (
            <Fragment>
              {isEditable ? (
                <Fragment>
                  <div className="index-center-list-item-options">
                    <span className="w-5r">Assign:</span>
                    <select className="custom-select flex-fill" onChange={this.handleMarketIndexChange}>
                      <option value="">Select one</option>
                      {map(harmonyIndexes, ({id, name}) => (
                        <option key={name} value={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="index-center-list-item-actions">
                    <Button solid className="d-block w-100" disabled={!marketIndexId} onClick={this.handleAssignClick}>
                      Assign Harmony Index
                    </Button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="index-center-list-item-recommendation">
                    Consider recommending a Harmony Index to your plan sponsor for this strategy.
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
          {!isHarmonyIndex && currentStepKey !== INDEX_CONVERSION_STEP.ASSIGN_INDEX.step && (
            <Fragment>
              <div className="index-center-list-item-status">
                New Index:
                <br /> {strategy.harmonyIndexConversionMarketIndex.name}
              </div>
              <div className="index-center-list-item-actions" />
            </Fragment>
          )}
        </div>

        {!isHarmonyIndex && currentStepKey !== INDEX_CONVERSION_STEP.ASSIGN_INDEX.step && (
          <IndexCenterListItemContent
            currentStepKey={currentStepKey}
            plannedMarketIndex={strategy.harmonyIndexConversionMarketIndex}
            strategyId={strategy.id}
          />
        )}
      </li>
    );
  }
}

IndexCenterListItem.defaultProps = {isEditable: true};

const IndexCenterListItemContent = compose(
  withPropsOnChange(['currentStepKey'], ({currentStepKey}) => {
    let found = false;
    let nextStepKey = null;
    const steps = map(INDEX_CONVERSION_STEPS, step => {
      if (found && !nextStepKey) {
        nextStepKey = step.step;
      }
      if (step.step === currentStepKey) found = true;
      return {
        ...step,
        isComplete: !found,
      };
    });
    const currentStep = find(steps, {step: currentStepKey});

    return {currentStep, nextStepKey, steps};
  }),
  withPropsOnChange(['currentStep', 'strategyId'], ({currentStep, strategyId}) => ({
    completeActionLabel: currentStep.completeActionLabel,
    description: currentStep.renderDescription ? currentStep.renderDescription({strategyId}) : currentStep.description,
  })),
  connect(null, {updateStrategy: updateStrategyAction}),
  withHandlers({
    handleCancelClick:
      ({strategyId, updateStrategy}) =>
      () => {
        updateStrategy(strategyId, {
          harmonyIndexConversionStep: null,
          harmonyIndexConversionMarketIndexId: null,
        });
      },
    handleCompleteClick:
      ({currentStep, nextStepKey, plannedMarketIndex, strategyId, steps, updateStrategy}) =>
      () => {
        if (currentStep === last(steps)) {
          updateStrategy(strategyId, {
            harmonyIndexConversionMarketIndexId: null,
            harmonyIndexConversionStep: null,
            marketIndexId: plannedMarketIndex.id,
          });
        } else {
          updateStrategy(strategyId, {
            harmonyIndexConversionStep: nextStepKey,
          });
        }
      },
  }),
)(({completeActionLabel, currentStep, description, handleCancelClick, handleCompleteClick, steps}) => (
  <div className="index-center-list-item-content">
    <div className="index-center-list-item-wizard">
      <strong>Conversion Status</strong>
      <ProgressIndicator
        small
        className="pt-2"
        steps={steps}
        currentStep={currentStep}
        nextAvailableStep={currentStep}
      />
    </div>
    <div className="index-center-list-item-description">
      <strong>Next Step: {currentStep.title}</strong>
      <div>{description}</div>
    </div>
    <div className="index-center-list-item-actions">
      <Button className="d-block w-100" solid onClick={handleCompleteClick}>
        {completeActionLabel}
      </Button>
      <Button className="d-block w-100" color="red" onClick={handleCancelClick}>
        Cancel Conversion
      </Button>
    </div>
  </div>
));
IndexCenterListItemContent.propTypes = {
  currentStepKey: PropTypes.string.isRequired,
  strategyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  plannedMarketIndex: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
  }),
};

const mapStateToProps = ({firm, marketIndex}) => {
  // Prepare Harmony Index records for display
  const harmonyIndexes = filter(marketIndex.all, 'isHarmonyIndex');

  // Database-driven examples (my use in the future)
  // const topMappedHarmonyIndexes = filter(harmonyIndexes, ({ marketIndexMappings }) => {
  //   return marketIndexMappings && marketIndexMappings.length > 0;
  // }).slice(0, 5);
  // const exampleHarmonyIndexMappings = map(topMappedHarmonyIndexes, ({ id, name, marketIndexMappings }) => ({
  //   id,
  //   name,
  //   exampleMappedIndexName: marketIndexMappings[0].name,
  // }));

  const passiveStrategies = filter(firm.allFirmsWithStrategy, {
    portfolioManagementType: PORTFOLIO_MANAGEMENT_TYPE.PASSIVE,
  });

  return {
    harmonyIndexes,
    isFetching: firm.isFetching || marketIndex.isFetching,
    passiveStrategies: map(passiveStrategies, passiveStrategy => ({
      ...passiveStrategy,
      // Look up the full index so we can get a name
      harmonyIndexConversionMarketIndex: find(marketIndex.all, {
        id: passiveStrategy.harmonyIndexConversionMarketIndexId,
      }),
    })),
    newPassiveStrategyIds: firm.newPassiveStrategyIds,
  };
};

const mapDispatchToProps = dispatch => {
  return {dispatch};
};

export const IndexCenter = compose(connect(mapStateToProps, mapDispatchToProps))(IndexCenterPage);
