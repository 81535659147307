/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';
import {keyBy} from 'lodash';
import {PageContainer, IssueMatrix} from '../components';
import {ORGANIZATION_SELECTORS, RATINGS} from '../../constants';
import {DATA_STATUS} from 'v2/constants/dataStatus';

export class ESGRatingsPage2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: ORGANIZATION_SELECTORS.UNIVERSE.value,
    };
  }

  renderIssueRating(issueId, issues) {
    const keyedIssues = keyBy(issues, 'id');
    const rating = RATINGS[keyedIssues[issueId].rating] || DATA_STATUS.NONE;
    switch (rating) {
      case 'A':
      case 'B':
      case 'C':
        return (
          <div className="report-page-esg-rating-score green d-flex align-items-center justify-content-center">
            {rating}
          </div>
        );
      case 'D':
      case 'E':
        return (
          <div className="report-page-esg-rating-score red d-flex align-items-center justify-content-center">
            {rating}
          </div>
        );
      default:
        return (
          <div className="report-page-esg-rating-score d-flex align-items-center justify-content-center">{rating}</div>
        );
    }
  }

  render() {
    const {companyName, issues} = this.props;

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} companyName={companyName} pageNumber={5}>
        <div className="report-page-esg-rating-summary">
          <div>
            <h4 className="report-page-esg-issue-header pb-2">Governance Issues</h4>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Board Governance</span> evaluate the practices and policies
                  that govern the corporate structure of an organization. Organizations are rated based on their
                  decision-making independence and ability to mitigate potential conflicts of interest.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>Board Independence</li>
                  <li>Separation of Powers</li>
                </ul>
              </div>
              <div className="flex-grow-1 d-flex justify-content-end pl-1">{this.renderIssueRating(9, issues)}</div>
            </div>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Corporate Trust</span> evaluate an organization’s oversight of
                  its internal and external relationships. Organizations are rated on the security, transparency, and
                  moral principles outlined in their policies.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>Code of Conduct</li>
                  <li>Ethics & Anti-corruption</li>
                  <li>Data Privacy</li>
                </ul>
              </div>
              <div className="d-flex flex-grow-1 justify-content-end pl-1">{this.renderIssueRating(10, issues)}</div>
            </div>
          </div>
        </div>
        <div className="report-page-issue-matrix pt-9">
          <IssueMatrix issues={issues} title="" />
        </div>
      </PageContainer>
    );
  }
}
