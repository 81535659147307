import React from 'react';

import {ProgressReportDataContainer, ProgressReportFormContainer, ProgressReportDisplay} from 'app/components';

const ProgressReport = () => (
  <ProgressReportDataContainer>
    {dataProps => (
      <ProgressReportFormContainer {...dataProps}>
        {formProps => <ProgressReportDisplay {...dataProps} {...formProps} />}
      </ProgressReportFormContainer>
    )}
  </ProgressReportDataContainer>
);

export {ProgressReport};
