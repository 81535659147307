import {GreyPill} from 'v2/components/molecules/Pills';

export const getTransparency = (transparency: number) => {
  if (transparency > 0.66) {
    return <GreyPill>High</GreyPill>;
  }

  if (transparency > 0.33) {
    return <GreyPill>Medium</GreyPill>;
  }

  return <GreyPill>Low</GreyPill>;
};
