import React, {Component} from 'react';
import {HarmonyLogo} from './HarmonyLogo';

export class PageHeader2 extends Component {
  render() {
    const {companyName} = this.props;
    return (
      <div className="report-page-header-2 w-100 d-flex justify-content-between align-items-center">
        <div>
          <span className="m-0 report-page-title">{companyName}</span>
        </div>
        <div>
          <HarmonyLogo size="30px" color />
        </div>
      </div>
    );
  }
}

PageHeader2.defaultProps = {
  companyName: 'Harmony Analytics Foundaition',
};
