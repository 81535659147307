import React, {Component} from 'react';
import {isEmpty, isNumber} from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faArrowAltUp from 'app/fontawesome-pro-light/faArrowAltUp';
import faArrowAltDown from 'app/fontawesome-pro-light/faArrowAltDown';
import {PageContainer, HoldingsPerformanceBarChart} from '../components';
import {COLOR, CONFIG, RATINGS, ESG_APPROACH, ESG_MEASUREMENT} from 'app/constants';
import line from '../../assets/images/vertical-line.png';
import {checkDueDates} from '../../utilities';
import {formatCurrency} from '../../utilities/formatCurrency';
import {calculatePercentDifference} from '../../utilities/formattedPercentDifference';
import {ratingFormatter} from '../../utilities/ratingFormatter';

class ProductOverviewPage extends Component {
  addFontIcon = value => {
    let newValue = Math.abs(value);

    if (Math.sign(value) > 0) {
      return (
        <React.Fragment>
          <Icon icon={faArrowAltDown} className="bad-color" />
          <span className="bench-mark bad-color">{newValue}%</span>
        </React.Fragment>
      );
    } else if (Math.sign(value) < 0) {
      return (
        <React.Fragment>
          <Icon icon={faArrowAltUp} className="good-color" />
          <span className="bench-mark good-color">{newValue}%</span>
        </React.Fragment>
      );
    } else {
      newValue = value;
      return <span className="bench-mark neutral-color">{newValue}%</span>;
    }
  };

  render() {
    const {
      planDistributions,
      indexUniverseDistributions,
      page,
      companyName,
      productData,
      benchMarkData,
      esgApproach,
      esgMeasurement,
    } = this.props;

    let uploadDates = checkDueDates(productData.lastHoldingsUploadAt, productData.uploadFrequency);
    const isCompliant = !productData.policyViolation;
    const holdingsData = planDistributions.map(distribution => {
      return Math.round(distribution.percent, 0);
    });
    const universeData = indexUniverseDistributions.map(distribution => distribution.percent);

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} pageNumber={page} companyName={companyName}>
        <h2>Product Overview</h2>
        <div className="top-section-container">
          <div className="grid-container">
            <div className="grid-col" style={{gridArea: 'product'}}>
              <p className="m-0">PRODUCT</p>
              <p className="mt-1 mb-4 font-weight-bold text-overflow-ellipsis-one-line">{productData.name}</p>
            </div>
            <div className="grid-col" style={{gridArea: 'score'}}>
              <p className="m-0">SCORE</p>
              <p className="mt-1 mb-4 font-weight-bold">{ratingFormatter(productData.weightedAverageRating)}</p>
            </div>
            <div className="grid-col" style={{gridArea: 'vs-benchmark'}}>
              <p className="margin-b-twenty">VS BENCHMARK</p>
              <p className="mt-1 font-weight-bold">
                {this.addFontIcon(
                  calculatePercentDifference(
                    Number(benchMarkData.strategyWeightedAverage),
                    Number(benchMarkData.marketIndexWeightedAverage),
                  ),
                )}
              </p>
            </div>
            {/* <div className="grid-col" style={{ gridArea: 'last-upload' }}>
              <p className="m-0">LAST UPLOAD</p>
              <p className="mt-1 mb-4 font-weight-bold">
                {uploadDates.hasUpload ? moment(uploadDates.lastUploadDate).format('MM/DD/YYYY') : 'Not Uploaded Yet'}
              </p>
            </div> */}
            <div className="grid-col" style={{gridArea: 'portfolio-value'}}>
              <p className="m-0">PORTFOLIO VALUE</p>
              <p className="mt-1 mb-4 font-weight-bold">{formatCurrency(productData.portfolioValue)}</p>
            </div>
            {/* <div className="grid-col" style={{ gridArea: 'next-upload' }}>
              <p className="m-0">NEXT UPLOAD</p>
              <p className="mt-1 mb-4 font-weight-bold">{moment(uploadDates.nextUploadDate).format('MM/DD/YYYY')}</p>
            </div> */}
            <div className="grid-col" style={{gridArea: 'effective-date'}}>
              <p className="m-0">EFFECTIVE DATE</p>
              <p className="mt-1 mb-4 font-weight-bold">{moment(productData.equityDate).format('MM/DD/YYYY')}</p>
            </div>
            <div className="grid-col" style={{gridArea: 'strategy'}}>
              <p className="m-0">STRATEGY</p>
              <p className="mt-1 mb-4 font-weight-bold">{productData.portfolioManagementType}</p>
            </div>
            <div className="grid-col" style={{gridArea: 'benchmark'}}>
              <p className="m-0">BENCHMARK</p>
              <p className="mt-1 mb-4 font-weight-bold">{productData.marketIndex.name}</p>
            </div>
            {/* <div className="grid-col" style={{ gridArea: 'in-compliance' }}>
              <p className="m-0">IN COMPLIANCE</p>
              <p
                className={cn(
                  `text-${isCompliant ? 'green mt-1 mb-4 font-weight-bold' : 'red mt-1 mb-4 font-weight-bold'}`,
                )}
              >
                {isCompliant ? 'Yes' : 'No'}
              </p>
            </div> */}
          </div>
        </div>

        {/* <div className="top-section-container">
          <table className="top-section-table">
            <tr>
              <td>
                <p className="m-0">PRODUCT</p>
                <p className="mt-1 mb-4 font-weight-bold">{productData.name}</p>
              </td>
              <td rowSpan={2}>
                <p className="m-0">SCORE</p>
                <p className="mt-1 mb-4 font-weight-bold">{this.ratingFormatter(productData.weightedAverageRating)}</p>
              </td>
              <td>
                <p className="margin-b-twenty">VS BENCHMARK</p>
                <p className="mt-1 font-weight-bold">
                  {this.addFontIcon(
                    calculatePercentDifference(
                      benchMarkData.strategyWeightedAverage,
                      benchMarkData.marketIndexWeightedAverage,
                    ),
                  )}
                </p>
              </td>
              <td>
                <p className="m-0">LAST UPLOAD</p>
                <p className="mt-1 mb-4 font-weight-bold">
                  {uploadDates.hasUpload ? moment(uploadDates.lastUploadDate).format('MM/DD/YYYY') : 'Not Uploaded Yet'}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="m-0">PORTFOLIO VALUE</p>
                <p className="mt-1 mb-4 font-weight-bold">{formatCurrency(productData.portfolioValue)}</p>
              </td>
              <td></td>
              <td>
                <p className="m-0">NEXT UPLOAD</p>
                <p className="mt-1 mb-4 font-weight-bold">{moment(uploadDates.nextUploadDate).format('MM/DD/YYYY')}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="m-0">STRATEGY</p>
                <p className="mt-1 mb-4 font-weight-bold">{productData.portfolioManagementType}</p>
              </td>
              <td>
                <p className="m-0">BENCHMARK</p>
                <p className="mt-1 mb-4 font-weight-bold">{productData.marketIndex.name}</p>
              </td>
              <td></td>
              <td>
                <div className="w-100">
                  <div>
                    <p className="m-0">IN COMPLIANCE</p>
                    <p
                      className={cn(
                        `text-${isCompliant ? 'green mt-1 mb-4 font-weight-bold' : 'red mt-1 mb-4 font-weight-bold'}`,
                      )}
                    >
                      {isCompliant ? 'Yes' : 'No'}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div> */}

        <h2 className="margin-t-thirty">ESG INTEGRATION</h2>
        <div className="flex-1 d-flex flex-column justify-content-between">
          <div className="top-section-container justify-content-between">
            <div className="forty-seven-percent">
              <p className="esg-headers">ESG Approach</p>
              <p className="mt-1 small text-overflow-ellipsis">{isEmpty(esgApproach) ? ESG_APPROACH : esgApproach}</p>
              <div className="d-flex">
                <p className="m-0 esg-header-no-margin">ESG Associations</p>
                <img src={line} className="line-styles" />
                <img src={`${CONFIG.ASSETS_URL}/images/pri.png`} height={16} />
              </div>
            </div>
            <div className="forty-seven-percent">
              <p className="esg-headers">ESG Measurement</p>
              <p className="mt-1 small text-overflow-ellipsis">
                {isEmpty(esgMeasurement) ? ESG_MEASUREMENT : esgMeasurement}
              </p>
            </div>
          </div>
          <div style={{marginBottom: '15px'}}>
            <h2 className="negative-margin-twenty-five">Rating distribution of product</h2>
            <HoldingsPerformanceBarChart
              mode={HoldingsPerformanceBarChart.MODE.BY_WEIGHT}
              holdingsData={holdingsData}
              universeData={universeData}
              legendTitle1={'Product'}
              legendTitle2={'vs Benchmark'}
            />
          </div>
        </div>
      </PageContainer>
    );
  }
}

export {ProductOverviewPage};
