/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';
import {HarmonyLogo} from '.';
import {getDateStamps} from 'app/utilities/getReportDateStamps';

export class PageHeader1 extends Component {
  render() {
    const {date, year, quarter} = getDateStamps();
    return (
      <div className="report-page-header w-100 d-flex justify-content-between">
        <div>
          <HarmonyLogo className="harmony-logo" color />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-start">
          <h3 className="m-0 report-page-title">Progress Report</h3>
          <h4 className="m-0 text-uppercase report-page-quarter">{`Q${quarter} ${year}`}</h4>
        </div>
      </div>
    );
  }
}
