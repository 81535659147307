import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {ROUTE} from 'v2/constants/routes';
import {DefaultLink} from 'v2/components/atoms/Typeface';
import {ArrowLeft, ChevronLeft} from 'v2/components/atoms/icons';
import {Theme} from '../atoms/theme';

const Wrapper = styled.div<{background?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme, background}) => background ?? 'transparent'};
  height: 4.125rem;
`;

const Container = styled.div`
  flex: 1;
  max-width: ${props => props.theme.constants.fixWidth};
`;

const LinkBox = styled(DefaultLink)<{maxWidth?: string}>`
  display: flex;
  justify-content: center;
  max-width: ${({maxWidth}) => maxWidth ?? 'auto'};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;
  color: ${({theme}) => theme.colors.dark};
  background-color: transparent;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  &:hover {
    background-color: ${({theme}) => theme.colors.grey_2};
    color: ${({theme}) => theme.colors.dark};
    text-decoration: none;
  }
`;

const IconContainer = styled.div`
  padding: 0.3rem 1rem 0 0;
`;

export const BackButton: React.FC<{path: string}> = ({children, path}) => (
  <LinkBox as={Link} to={path} maxWidth="14rem">
    <IconContainer>
      <ArrowLeft size={20} />
    </IconContainer>
    <span>{children}</span>
  </LinkBox>
);
