import {isNil} from 'lodash';

/**
 * Custom middleware that returns an impersonated user
 * @returns {function(*): Function}
 */
export let currentImpersonatedUser;

const impersonatedUserMiddleware = () => {
  return ({getState}) =>
    next =>
    action => {
      const {
        auth: {impersonatedUser},
      } = getState();
      if (!isNil(impersonatedUser)) {
        currentImpersonatedUser = impersonatedUser;
      } else {
        currentImpersonatedUser = null;
      }

      return next(action);
    };
};

export const reduxGetImpersonatedUser = impersonatedUserMiddleware();
