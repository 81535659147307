import styled from 'styled-components/macro';

export const Title1 = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.75rem;
  color: ${props => props.theme.colors.black};
`;

export const Title2 = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 2.75rem;
  color: ${props => props.theme.colors.black};
`;

export const Title3 = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: ${props => props.theme.colors.black};
`;

export const Title4 = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: ${props => props.theme.colors.black};
`;

export const Title5 = styled.h5`
  &&& {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${props => props.color || props.theme.colors.black};
  }
`;

export const Title6 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9375rem;
  line-height: 1.1344rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Title7 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.9375rem;
  line-height: 2.5625rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Title8 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Title9 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.2856rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Link1 = styled.a`
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1875rem;
  text-align: left;
  align-items: center;
  text-decoration-line: underline;
  color: ${props => props.theme.colors.black};
`;

export const Link2 = styled.a`
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.0625rem;
  text-align: left;
  align-items: center;
  text-decoration-line: underline;
  color: ${props => props.theme.colors.black};
`;

export const DefaultLink = styled(Link1)`
  color: ${props => props.theme.colors.blue};
`;

export const Caption1 = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Caption2 = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Caption3 = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0.445em;
  text-transform: uppercase;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Caption4 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.5rem;
  line-height: 0.625rem;
  margin: 0;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Caption5 = styled.h6`
  font-family: Inter;
  font-style: normal;
  font-weight: normal !important;
  font-size: 0.8125rem;
  line-height: 0.9831rem;
  margin: 0;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Body1 = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Body2 = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const ParagraphS = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const ParagraphM = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const Batao = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${props => props.color || props.theme.colors.black};
`;

export const Rubrica = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${props => props.theme.colors.black};
`;

export const Overline = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${({color, theme}) => color || theme.colors.black};
`;

export const TitleXL = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 4.5rem;
  letter-spacing: 0.05rem;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const TitleL = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: 0.05rem;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const TitleM = styled.h4`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const TitleS = styled.h5`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const TitleXS = styled.h6`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const Subtitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const SubtitleS = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const SubtitleM = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: capitalize;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const AG = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.5625rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const ButtonM = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: capitalize;
  color: ${({color, theme}) => color || theme.colors.grey_5};
`;

export const EmbeddedLink = styled(Body1).attrs(({as}) => ({
  as: as || 'a',
}))`
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({color, theme}) => color || theme.colors.dark};
  &:hover {
    color: ${({theme}) => theme.colors.blue};
    text-decoration: none;
  }
`;

export const Content = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({color, theme}) => color || theme.colors.dark};
`;

export const HR = styled.hr`
  border: 1px solid ${({theme}) => theme.colors.grey_2};
  margin: 0;
`;
