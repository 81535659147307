import {intersection} from 'lodash';
import {getSessionJwt} from 'app/utilities';
import {NotAuthorizedPage} from 'v2/pages';
import {Middleware} from './types';
import {ROLE} from 'app/constants';

export const userTypeMiddleware =
  (allowedRoles: string[]): Middleware =>
  async (next: any) => {
    const sessionJwt = await getSessionJwt(true);

    const isAdmin: boolean = sessionJwt?.contents?.roles.includes(ROLE.ADMIN);

    if (allowedRoles.length > 0 && intersection(allowedRoles, sessionJwt.contents.roles).length === 0) {
      return <NotAuthorizedPage isAdmin={isAdmin} />;
    }

    return next();
  };
