import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const MANAGER_LISTING_FILTER = {
  all: 'Managers and Products',
  manager: 'Manager',
  product: 'Product',
};

export const MANAGER_LISTING_FILTERS = map(MANAGER_LISTING_FILTER, value => value);
