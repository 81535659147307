import numeral from 'numeral';

/**
 * Formats a number into a currency format that should be displayed throughout the app
 * @param {number }value - The number to return into a currency format
 * @returns {string} The formatted number in currency form
 */
export const formatCurrency = value => {
  // Numbers less than $100,000 are rounded up to the nearest millions of max. 1 decimal point with a '<' sign
  if (Number(value) < 100000) {
    return '< $0.1M';
  }

  // Dividing number by 1,000,000 to show in million format
  const valueInMillions = Number(value) / 1000000;

  // Format the value to display commas and correct decimal places
  const formattedValue = numeral(valueInMillions).format('0,0.0');

  // Return formatted value with currency and "M" for millions
  return `$${formattedValue}M`;
};
