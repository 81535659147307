import {Theme} from '../theme';

export const HealthAndSafetyPolicy = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2593_10569)">
        <path d="M7.70861 18.5641C8.10537 18.9124 8.51493 19.2719 8.93622 19.6445C8.93908 19.647 8.94202 19.6496 8.94495 19.6521L10.3465 20.8627C10.5342 21.025 10.7673 21.1061 11.0003 21.1061C11.2334 21.1061 11.4665 21.0249 11.6543 20.8627L13.0555 19.6521C13.0584 19.6496 13.0613 19.6471 13.0642 19.6445C15.8386 17.1909 17.9187 15.3125 19.4236 13.4805C21.1814 11.3409 22.0001 9.40623 22.0001 7.39219C22.0001 3.74833 19.1458 0.894043 15.502 0.894043C13.8644 0.894043 12.2462 1.51071 11.0003 2.57968C9.75413 1.51064 8.13597 0.894043 6.49822 0.894043C2.85436 0.893976 0 3.74833 0 7.39213C0 11.7968 3.06667 14.4889 7.70861 18.5641ZM6.99913 8.99968H9.66631V6.33236H12.3336V8.99968H15.0009V11.6669H12.3336V14.3341H9.66631V11.6669H6.99913V8.99968Z" />
      </g>
      <defs>
        <clipPath id="clip0_2593_10569">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
