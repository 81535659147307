import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components/macro';
import {Caption2, Title3, Title4, Body2, Overline} from 'v2/components/atoms/Typeface';
import {IssueRankingSummary} from 'v2/components/organisms/IssueRankingSummary';
import {IssueSummaryChart} from 'v2/components/organisms/IssueSummaryChart';
import {IssueRankingSummaryProps, IssueSummaryChartProps} from 'v2/components/interfaces/IssueProps';
import {Path} from 'v2/components/interfaces/LayoutProps';
import {Theme} from 'v2/components/atoms/theme';
import {DATA_STATUS} from 'v2/constants/dataStatus';

interface IconPathProps extends Path {
  icon: React.ElementType;
  active?: boolean;
  isSmall?: boolean;
}

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.constants.border_radius};
`;

const FlexRow = styled.div`
  display: flex;
`;

const FlexCol = styled.div<{flex?: number}>`
  flex: ${props => (props.flex ? props.flex : 1)};
`;

const Content = styled.div<{padding?: string | number}>`
  padding: ${props => (props.padding ? props.padding : '2rem')};
`;

const Seperator = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey_10};
`;

const SectionTitle = styled(Title4)`
  margin-top: 0;
`;

const IssueTitle = styled(Title3)`
  font-size: 1.125rem;
  font-weight: normal !important;
`;

const IssueDescription = styled(Caption2)`
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.059rem;
`;

const linkDemension = (isSmall?: boolean) => (isSmall ? '3.764rem' : '5rem');
const linkContainerDimensions = (isSmall?: boolean) => (isSmall ? '4.063rem' : '5.875rem');

const Link = styled.a<{active?: boolean; color: string; isSmall?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  width: ${props => (props.active ? `calc(${linkDemension(props.isSmall)} - 4px)` : linkDemension(props.isSmall))};
  height: ${props => linkDemension(props.isSmall)};
  border-radius: ${props => props.theme.constants.border_radius};
  border: ${props => (props.active ? `2px solid ${props.color}` : 'none')};
  background: ${props => (props.active ? props.theme.colors.white : props.theme.colors.grey_10)};
  &:hover {
    background: ${props => (props.active ? props.theme.colors.white : props.color + '4D')};
  }
`;

const LinkLabel = styled(Overline)<{isSmall?: boolean}>`
  margin: 1rem 0.1rem 0;
  text-align: center;
`;

const LinkContainer = styled(Overline)<{isSmall?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => linkContainerDimensions(props.isSmall)};
  margin: 0 0.8rem;
  &:last-of-type {
    margin: 0 0 0 0.8rem;
  }
  &:first-of-type {
    margin: 0 0.8rem 0 0;
  }
`;

export const DataExplorerPanel: React.FC<{
  title: string;
  description: string;
  rankingSummaryProps: IssueRankingSummaryProps;
  paths: any[];
  color: string;
  subCategoryProps?: {
    title: string;
    description: string;
    paths: any[];
    rankingSummaryProps: IssueRankingSummaryProps;
    summaryChartProps?: IssueSummaryChartProps;
  };
  tertiaryCategoryProps?: {
    title: string;
    description: string;
    rankingSummaryProps: IssueRankingSummaryProps;
    summaryChartProps?: IssueSummaryChartProps;
  };
}> = ({
  title,
  description,
  rankingSummaryProps,
  paths,
  color = Theme.colors.harmony,
  subCategoryProps,
  tertiaryCategoryProps,
}) => {
  const IconButton: React.FC<{
    active: boolean;
    onClick?: any;
    label: string;
    icon: any;
    isSmall: boolean;
  }> = ({active, onClick, label, icon, isSmall}) => {
    return (
      <LinkContainer isSmall={isSmall}>
        <Link active={active} color={color} isSmall={isSmall} as="button" onClick={onClick}>
          {React.createElement(icon, {
            color: active ? color : Theme.colors.white,
            size: 20,
          })}
        </Link>
        <LinkLabel>{label}</LinkLabel>
      </LinkContainer>
    );
  };

  const DataExplorerDescription: React.FC<{
    title: string;
    description: string;
  }> = ({title, description}) => (
    <Content>
      <IssueTitle>{title}</IssueTitle>
      <IssueDescription>{description}</IssueDescription>
    </Content>
  );

  return (
    <Container>
      <FlexRow>
        <Content padding="2rem 2rem 0">
          <SectionTitle>{title}</SectionTitle>
        </Content>
      </FlexRow>
      <FlexRow>
        <FlexCol>
          <Content padding="0 2rem 0">
            <Body2>{description}</Body2>
          </Content>
        </FlexCol>
        <FlexCol>
          <Content padding={'0 2rem 0'}>
            <IssueRankingSummary {...rankingSummaryProps} />
          </Content>
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <Content>
          <FlexRow>
            {paths.map((path, i) => {
              return <IconButton key={i} {...path} />;
            })}
          </FlexRow>
        </Content>
      </FlexRow>
      {subCategoryProps && <Seperator />}
      {subCategoryProps &&
        (subCategoryProps.summaryChartProps ? (
          <FlexRow>
            <FlexCol>
              <DataExplorerDescription title={subCategoryProps.title} description={subCategoryProps.description} />
              <Content padding={'0 1rem'}>
                <IssueRankingSummary {...subCategoryProps.rankingSummaryProps} />
              </Content>
            </FlexCol>
            <FlexCol>
              <Content>
                <IssueSummaryChart {...subCategoryProps.summaryChartProps} />
              </Content>
            </FlexCol>
          </FlexRow>
        ) : (
          <>
            <FlexRow>
              <FlexCol>
                <Content>
                  <IssueTitle>{subCategoryProps?.title || DATA_STATUS.NONE}</IssueTitle>
                  <IssueDescription>{subCategoryProps?.description || DATA_STATUS.NONE}</IssueDescription>
                </Content>
              </FlexCol>
              <FlexCol>
                <Content padding={'5rem 2rem 0'}>
                  <IssueRankingSummary {...subCategoryProps?.rankingSummaryProps} />
                </Content>
              </FlexCol>
            </FlexRow>
            <FlexRow>
              <Content padding="0 2rem 2rem">
                <FlexRow>
                  {subCategoryProps &&
                    subCategoryProps.paths.map((path, i) => {
                      return <IconButton key={i} {...path} isSmall />;
                    })}
                </FlexRow>
              </Content>
            </FlexRow>
          </>
        ))}
      {tertiaryCategoryProps &&
        (tertiaryCategoryProps.summaryChartProps ? (
          <FlexRow>
            <FlexCol>
              <DataExplorerDescription
                title={tertiaryCategoryProps.title}
                description={tertiaryCategoryProps.description}
              />
              <Content padding={'0 1rem'}>
                <IssueRankingSummary {...tertiaryCategoryProps.rankingSummaryProps} />
              </Content>
            </FlexCol>
            <FlexCol>
              <Content>
                <IssueSummaryChart {...tertiaryCategoryProps.summaryChartProps} />
              </Content>
            </FlexCol>
          </FlexRow>
        ) : (
          <FlexRow>
            <FlexCol>
              <Content>
                <IssueTitle>{tertiaryCategoryProps?.title || DATA_STATUS.NONE}</IssueTitle>
                <IssueDescription>{tertiaryCategoryProps?.description || DATA_STATUS.NONE}</IssueDescription>
              </Content>
            </FlexCol>
            <FlexCol>
              <Content padding={'5rem 2rem 0'}>
                <IssueRankingSummary {...tertiaryCategoryProps?.rankingSummaryProps} />
              </Content>
            </FlexCol>
          </FlexRow>
        ))}
    </Container>
  );
};
