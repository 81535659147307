import {get, isEmpty, find, map, orderBy} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {change, SubmissionError, untouch} from 'redux-form';

// Local Imports
import {Alert, UploadPortfolioForm} from 'app/components';
import {
  createStagingUpload,
  getPlanSelectionByIdForUpload,
  fetchPlanSelectionIfNeeded,
  getSelectedStrategyForUpload,
  updateStrategyAndProcessUploads,
} from 'app/redux/strategy';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {fetchMarketIndexesIfNeeded} from 'app/redux/marketIndex';
import {toastSuccess} from 'app/utilities/toast';

class UploadPortfolioPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchMarketIndexesIfNeeded());
    props.dispatch(fetchPlanSelectionIfNeeded());

    const initialStrategyId = get(props, 'match.params.strategyId');

    if (get(props.state, `strategy.selectedStrategyForUpload_${initialStrategyId}`)) {
      props.dispatch(getSelectedStrategyForUpload(initialStrategyId));
    } else if (initialStrategyId) {
      props.dispatch(getPlanSelectionByIdForUpload(initialStrategyId));
    } else {
      props.dispatch(getSelectedStrategyForUpload(null));
    }
  }

  render() {
    const initialStrategyId = get(this.props, 'match.params.strategyId');
    const isFormReadyToLoad =
      (!initialStrategyId || this.props.strategy) && this.props.marketIndexes && this.props.strategies;

    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <h1 className="mb-4">Upload Portfolio</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>
          ) : (
            <Fragment>
              <p className="mb-3">Upload a new portfolio for an existing Strategy.</p>
              {isFormReadyToLoad && <UploadPortfolioForm {...this.props} />}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const strategies = orderBy(
    map(get(state, 'strategy.planSelection'), strategyToMap => ({
      name: `${strategyToMap.planSponsor.name}: ${strategyToMap.name}`,
      id: strategyToMap.id,
    })),
    ['name', 'id'],
  );

  const selectedStrategyIdForUpload = get(state, 'strategy.selectedStrategyIdForUpload');

  const hasUnexpectedError = get(state, 'marketIndex.hasError') || get(state, 'strategy.error');
  return {
    hasUnexpectedError,
    strategies,
    strategy: get(state, `strategy.selectedStrategyForUpload_${selectedStrategyIdForUpload}`),
    marketIndexes: get(state, 'marketIndex.all'),
    isCreatingStagingUpload: get(state, 'strategy.stagingUpload.isCreatingStagingUpload'),
    hasStagingUploadError: get(state, 'strategy.stagingUpload.hasStagingUploadError'),
    state,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  const {marketIndexes} = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    createStagingUpload: fileKey => dispatch(createStagingUpload(fileKey)),
    onStrategyChange: event => {
      dispatch(push(ROUTE.UPLOAD_PORTFOLIO_STRATEGY.path(event.target.value)));
    },
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const marketIndex = find(marketIndexes, {name: values.marketIndex});

      const updateData = {
        ...values,
        marketIndexId: isEmpty(marketIndex) ? null : marketIndex.id,
        fileKey: values.planHoldingsUpload[0].name,
      };

      return dispatch(updateStrategyAndProcessUploads(updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('Strategy Data and Portfolio Upload has been saved.');
          dispatch(change('upload-portfolio', 'planHoldingsUpload', 'EMPTY'));
          dispatch(untouch('upload-portfolio', 'planHoldingsUpload'));

          animateScroll.scrollToTop();
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
  };
};

export const UploadPortfolio = compose(connect(mapStateToProps, null, mergeProps))(UploadPortfolioPage);
