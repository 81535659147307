import {useRef} from 'react';
import {Form, useForm} from 'react-final-form';
import styled from 'styled-components/macro';
import {CardContainer, Container, FlexContainer} from 'v2/components//atoms/Containers';
import {useOutsideClick} from 'v2/hooks/helpers/useOutsideClick';
import {AdminFormCard as AdminFormCardProps} from 'v2/utilities/types/components/AdminFormCard';
import {HarmonyButton} from './Button';
import {LastUpdatedBy} from './LastUpdatedBy';

const Title = styled.h1`
  font-family: Inter;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
  text-transform: none;
  color: ${({theme}) => theme.colors.black};
`;

const FormFooter = ({handleClose}: {handleClose: any}) => {
  const {getState} = useForm();
  const {dirty} = getState();

  return (
    <FlexContainer justify="flex-end" gap="0.5rem">
      <HarmonyButton type="button" width="6.9375rem" variant="ghost" onClick={handleClose}>
        Cancel
      </HarmonyButton>
      <HarmonyButton type="submit" width="6.9375rem" disabled={!dirty}>
        Save
      </HarmonyButton>
    </FlexContainer>
  );
};

export const AdminFormCard = ({
  children,
  fields, // fields to use for filtering when issueId is nor provided
  initialValues,
  issueId,
  onClose,
  onOutsideClick,
  onSubmit,
  title,
  validate,
  year,
  id,
}: AdminFormCardProps) => {
  const containerRef = useRef(null);

  const handleSubmit = (values: any) => {
    return onSubmit?.(values, id);
  };

  const handleClose = () => {
    return onClose?.();
  };

  const handleOnOutsideClick = () => {
    return onOutsideClick?.();
  };

  const refWithOutsideclick = useOutsideClick({ref: containerRef, onOutsideClick: handleOnOutsideClick});

  return (
    <CardContainer ref={refWithOutsideclick} maxWidth="50.625rem" padding="3.125rem">
      <Form
        onSubmit={values => handleSubmit(values)}
        initialValues={initialValues}
        validate={validate}
        keepDirtyOnReinitialize
        mutators={{
          setValue: ([field, value], state, {changeValue}) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {({handleSubmit}) => (
          <form method="POST" onSubmit={handleSubmit}>
            <FlexContainer direction="column">
              <Container>
                <Title>{title}</Title>
              </Container>
              <Container padding="2.1875rem 0 0">{children}</Container>
              <FlexContainer
                style={{
                  flexDirection: 'row',
                  padding: '5.25rem 0 0',
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FlexContainer style={{flex: 1}}>
                  <LastUpdatedBy fields={fields} issueId={issueId} year={year} />
                </FlexContainer>
                <FormFooter handleClose={handleClose} />
              </FlexContainer>
            </FlexContainer>
          </form>
        )}
      </Form>
    </CardContainer>
  );
};
