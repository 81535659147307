export const EXAMPLE_HARMONY_INDEX_MAPPINGS = [
  {
    exampleMappedIndexName: 'Russell 1000® Index',
    name: 'Harmony 1000',
  },
  {
    exampleMappedIndexName: 'Russell 2000® Index',
    name: 'Harmony 2000',
  },
  {
    exampleMappedIndexName: 'Russell 3000® Index',
    name: 'Harmony 3000',
  },
  {
    exampleMappedIndexName: 'MSCI WORLD',
    name: 'Harmony Global',
  },
  {
    exampleMappedIndexName: 'MSCI EM',
    name: 'Harmony Emerging Markets',
  },
];
