import {useEffect, useState} from 'react';
import {useDispatch, useSelector, RootStateOrAny} from 'react-redux';
import {useLocation} from 'react-router';
import {reduce} from 'lodash';
import styled from 'styled-components/macro';

import {DataExplorerPanel} from 'v2/components/organisms/DataExplorerPanel';
import {SectorSelector} from 'v2/components/molecules/SectorSelector';
import {Theme} from 'v2/components/atoms/theme';
import {SectionSummary} from './SectionSummary';
import {ENVIRONMENTAL, SOCIAL, GOVERNANCE} from 'v2/constants/esgFilters';
import {ISSUES} from 'v2/constants/issues';
import {DSectorSelector} from 'v2/utilities/delegates/DSectorSelector';
import {DSectionSummary} from 'v2/utilities/delegates/DSectionSummary';
import {DDataExplorer} from 'v2/utilities/delegates/DDataExplorer';
import {updateCategory, updateTopic, updateSubTopic, updateTertiaryTopic} from 'v2/redux/esgFilter';
import {
  useGetAggregatePercentileUniverseQuery,
  useGetAggregatePercentileHoldingsQuery,
  useGetIssuesQuery,
} from 'v2/redux/harmonyApi';
import {getData} from 'v2/utilities/helpers/getData';
import {getFilterOptions} from 'v2/utilities/helpers/getFilterOptions';
import {getSessionJwt} from 'app/utilities';
import {updateFilterOptions} from 'v2/redux/filterOptions';
import {useAppliedFilters} from 'v2/hooks/filters/useAppliedFilters';
import {useGetPlanSponsorStrategiesQuery, useGetAggregateMarketIndexBenchmarkQuery} from 'v2/redux/typeormEndpoints';
import {Strategy} from 'v2/utilities/types/typeOrm';
import {filterHoldings} from 'v2/utilities/helpers/calculateStatistics';

const FlexRow = styled.div<{direction?: string; align?: string; justify?: string}>`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-items: ${props => (props.align ? props.align : ' stretch')};
  justify-content: ${props => (props.justify ? props.justify : ' flex-start')};
`;

const FlexItem = styled.div<{
  flex?: number;
  maxWidth?: number | string;
}>`
  flex: ${props => (props.flex ? props.flex : 0)};
  width: auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
`;

const ESGContainer = styled(FlexRow)`
  padding-top: 1rem;
`;

const SectorContainer = styled(FlexItem)`
  margin-right: 1rem;
`;

const SectorItem = styled.div`
  margin-bottom: 1rem;
`;

export const ESGExplorer = () => {
  const dispatch = useDispatch();
  const getIssues = useGetIssuesQuery({});

  const issues = getIssues?.data
    ?.filter((issue: any) => issue.enabledEsgExplorer === true)
    .sort((a: any, b: any) => a.displayOrder - b.displayOrder);

  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const holdingsQueries = [
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 1}),
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 2}),
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 3}),
  ];

  const universeQueries = [
    useGetAggregatePercentileUniverseQuery({planSponsorId, issueId: 1}),
    useGetAggregatePercentileUniverseQuery({planSponsorId, issueId: 2}),
    useGetAggregatePercentileUniverseQuery({planSponsorId, issueId: 3}),
  ];

  const {data: strategies} = useGetPlanSponsorStrategiesQuery({planSponsorId});

  const marketIndexes = strategies?.map((strategy: any) => strategy.marketIndexId) ?? [];
  const marketIndexQueries = [
    useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId: 1}),
    useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId: 2}),
    useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId: 3}),
  ];

  let holdings = reduce(holdingsQueries, getData, []);
  let universe = reduce(universeQueries, getData, []);
  let marketIndex = reduce(marketIndexQueries, getData, []);
  //TODO: if filters are applied, universe=holdings

  const filteredOptions = getFilterOptions(holdings, (strategies ?? []) as Strategy[]);
  const {pathname} = useLocation();

  if (filteredOptions) {
    dispatch(updateFilterOptions({pathname, options: filteredOptions}));
  }

  const type = useSelector((state: any) => {
    return state?.strategyFilter?.typeValue?.value;
  });

  const {getSelectedTags} = useAppliedFilters({listItemId: ''});
  const selectedTags = getSelectedTags();

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const esgFilters = useSelector<RootStateOrAny>(state => state.esgFilters);

  const issueHoldingsQueries = [
    useGetAggregatePercentileHoldingsQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.category.id,
    }),
    useGetAggregatePercentileHoldingsQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.topic?.id || 0,
    }),
    useGetAggregatePercentileHoldingsQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.subTopic?.id || 0,
    }),
    useGetAggregatePercentileHoldingsQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.tertiaryTopic?.id || 0,
    }),
  ];

  const universeHoldingsQueries = [
    useGetAggregatePercentileUniverseQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.category.id,
    }),
    useGetAggregatePercentileUniverseQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.topic?.id || 0,
    }),
    useGetAggregatePercentileUniverseQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.subTopic?.id || 0,
    }),
    useGetAggregatePercentileUniverseQuery({
      planSponsorId,
      // @ts-ignore
      issueId: esgFilters.tertiaryTopic?.id || 0,
    }),
  ];

  const marketIndexHoldingQueries = [
    useGetAggregateMarketIndexBenchmarkQuery({
      marketIndexes,
      // @ts-ignore
      issueId: esgFilters?.category?.id || 0,
    }),
    useGetAggregateMarketIndexBenchmarkQuery({
      marketIndexes,
      // @ts-ignore
      issueId: esgFilters.topic?.id || 0,
    }),
    useGetAggregateMarketIndexBenchmarkQuery({
      marketIndexes,
      // @ts-ignore
      issueId: esgFilters.subTopic?.id || 0,
    }),
    useGetAggregateMarketIndexBenchmarkQuery({
      marketIndexes,
      // @ts-ignore
      issueId: esgFilters.tertiaryTopic?.id || 0,
    }),
  ];

  const issueHoldings = reduce(issueHoldingsQueries, getData, []);
  const issueUniverse = reduce(universeHoldingsQueries, getData, []);
  const issueMarketIndex = reduce(marketIndexHoldingQueries, getData, []);

  const isActiveIssue = (category: any): boolean => {
    // @ts-ignore
    return esgFilters.category.id === category.id;
  };

  const setDefaultTopic = (path: any) => {
    const issue = issues.find((issue: any) => issue.parentId === path.id);
    if (issue) {
      dispatch(updateTopic(issue));
    }
  };

  const setIssue = (path: any) => {
    const issue = issues.find((issue: any) => issue.id === path.id);
    if (issue) {
      dispatch(updateCategory(issue));
    }
    setDefaultTopic(path);
  };

  const setTopic = (path: any) => {
    const issue = issues.find((issue: any) => issue.id === path.id);
    if (issue) {
      dispatch(updateTopic(issue));
    }
  };

  const setSubTopic = (path: any) => {
    const issue = issues.find((issue: any) => issue.id === path.id);
    if (issue) {
      dispatch(updateSubTopic(issue));
    }
  };

  const setTertiaryTopic = (path: any) => {
    const issue = issues.find((issue: any) => issue.id === path.id);
    if (issue) {
      dispatch(updateTertiaryTopic(issue));
    }
  };

  const currentColor = () => {
    // @ts-ignore
    switch (esgFilters.category.key) {
      case ENVIRONMENTAL.key:
        return Theme.colors.green;
      case SOCIAL.key:
        return Theme.colors.blue;
      case GOVERNANCE.key:
      default:
        return Theme.colors.harmony;
    }
  };

  const dataExplorer = new DDataExplorer(
    issues ?? [],
    currentColor(),
    esgFilters,
    issueHoldings ?? [],
    issueUniverse ?? [],
    issueMarketIndex ?? [],
    type ?? 'all',
    selectedTags,
    filters ?? {},
    strategies ?? [],
    setSubTopic,
    setTertiaryTopic,
  );

  const environmentalSelector = new DSectorSelector(ISSUES.ENVIRONMENTAL, setIssue);
  const socialSelector = new DSectorSelector(ISSUES.SOCIAL, setIssue);
  const governanceSelector = new DSectorSelector(ISSUES.GOVERNANCE, setIssue);

  holdings = filterHoldings(holdings, selectedTags ?? 'all', strategies ?? []);
  if (selectedTags && selectedTags.length > 0) {
    universe = holdings;
  }
  const environmentalSummary = new DSectionSummary(
    ISSUES.ENVIRONMENTAL.id,
    issues ?? [],
    holdings ?? [],
    universe ?? [],
    marketIndex ?? [],
    filters ?? {},
    esgFilters,
    setTopic,
    setSubTopic,
    setTertiaryTopic,
    Theme.colors.blue,
    Theme.colors.white + '1A',
  );

  const socialSummary = new DSectionSummary(
    ISSUES.SOCIAL.id,
    issues ?? [],
    holdings ?? [],
    universe ?? [],
    marketIndex ?? [],
    filters ?? {},
    esgFilters,
    setTopic,
    setSubTopic,
    setTertiaryTopic,
    Theme.colors.blue,
    Theme.colors.white + '1A',
  );

  const governanceSummary = new DSectionSummary(
    ISSUES.GOVERNANCE.id,
    issues ?? [],
    holdings ?? [],
    universe ?? [],
    marketIndex ?? [],
    filters ?? {},
    esgFilters,
    setTopic,
    setSubTopic,
    setTertiaryTopic,
    Theme.colors.blue,
    Theme.colors.white + '1A',
  );

  // Initialize state on page change
  const [issuesLoaded, setIssuesLoaded] = useState(false);
  useEffect(() => {
    if (getIssues.status === 'fulfilled' && !issuesLoaded) {
      setIssue(ISSUES.ENVIRONMENTAL);
      setIssuesLoaded(false);
    }
  }, [getIssues, issuesLoaded]);

  return (
    <ESGContainer>
      <SectorContainer flex={2}>
        <SectorItem>
          {!isActiveIssue(ISSUES.ENVIRONMENTAL) ? (
            <SectorSelector
              title={environmentalSelector.title()}
              onClick={() => environmentalSelector.onClick()} // onClick={() => history.push(ESG_EXPLORER.path(ENVIRONMENTAL.path))}
            />
          ) : (
            <SectionSummary
              title={environmentalSummary.title()}
              quintile={environmentalSummary.quintile()}
              percentile={environmentalSummary.percentile()}
              rating={environmentalSummary.rating()}
              universe={environmentalSummary.benchmark()}
              navigation={environmentalSummary.navigation()}
              activeColor={environmentalSummary.activeColor()}
              inactiveColor={environmentalSummary.inactiveColor()}
            />
          )}
        </SectorItem>
        <SectorItem>
          {!isActiveIssue(ISSUES.SOCIAL) ? (
            <SectorSelector
              title={socialSelector.title()}
              onClick={() => socialSelector.onClick()} // onClick={() => history.push(ESG_EXPLORER.path(ENVIRONMENTAL.path))}
            />
          ) : (
            <SectionSummary
              title={socialSummary.title()}
              quintile={socialSummary.quintile()}
              percentile={socialSummary.percentile()}
              rating={socialSummary.rating()}
              universe={socialSummary.benchmark()}
              navigation={socialSummary.navigation()}
              activeColor={socialSummary.activeColor()}
              inactiveColor={socialSummary.inactiveColor()}
            />
          )}
        </SectorItem>
        <SectorItem>
          {!isActiveIssue(ISSUES.GOVERNANCE) ? (
            <SectorSelector
              title={governanceSelector.title()}
              onClick={() => governanceSelector.onClick()} // onClick={() => history.push(ESG_EXPLORER.path(ENVIRONMENTAL.path))}
            />
          ) : (
            <SectionSummary
              title={governanceSummary.title()}
              quintile={governanceSummary.quintile()}
              percentile={governanceSummary.percentile()}
              rating={governanceSummary.rating()}
              universe={governanceSummary.benchmark()}
              navigation={governanceSummary.navigation()}
              activeColor={governanceSummary.activeColor()}
              inactiveColor={governanceSummary.inactiveColor()}
            />
          )}
        </SectorItem>
      </SectorContainer>
      <FlexItem flex={4}>
        <DataExplorerPanel
          title={dataExplorer.title()}
          description={dataExplorer.description()}
          rankingSummaryProps={dataExplorer.rankingSummaryProps()}
          paths={dataExplorer.paths()}
          color={dataExplorer.color()}
          subCategoryProps={dataExplorer.subCategoryProps()}
          tertiaryCategoryProps={dataExplorer.tertiaryCategoryProps()}
        />
      </FlexItem>
    </ESGContainer>
  );
};
