import {isEmpty} from 'lodash';
import {Theme} from 'v2/components/atoms/theme';
import {ESG_CONTENT} from 'v2/constants/esgContent';
import {getClosest} from '../helpers';
import {
  calculateESGUniverseStatistics,
  calculateESGHoldingsStatistics,
  setTypesFilters,
  calculateFilteredESGUniverseStatistics,
  calculateESGBenchmarktatistics,
} from '../helpers/calculateStatistics';
import {
  AggregatePercentileUniverse,
  AggregatePercentileHolding,
  MarketIndexBenchmark,
  Issue,
  IssueType as DataIssues,
  Strategy,
} from '../types/typeOrm';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ISSUES} from 'v2/constants/issues';
import {TagProps} from '../types/components/TagHeader';

export class DDataExplorer {
  private themeColor: string;
  private esgFilters: any;
  private filters: {[key: string]: string};
  private issues: {[key: number]: Issue} = {};
  private dataIssues: DataIssues[] = [];
  private rootIssue: DataIssues | undefined;
  private handleLinkClick: any;
  private handleSubLinkClick: any;
  private tags: TagProps[];

  private universePercentiles: {[key: number]: number} = {};
  private percentiles: {[key: number]: number} = {};
  private quintiles: {[key: number]: number} = {};
  private rawPercentileValue: {[key: number]: number} = {};
  private rawUniverseValue: {[key: number]: number} = {};
  private companies: Set<number>;
  private strategies: Strategy[];

  private issueNavigation: any[];

  constructor(
    issues: DataIssues[],
    themeColor: string,
    esgFilters: any,
    holdingsData: AggregatePercentileHolding[],
    universeData: AggregatePercentileUniverse[],
    marketIndexData: MarketIndexBenchmark[],
    type: 'Liquid' | 'Illiquid' | 'all',
    tags: TagProps[],
    filters: {[key: string]: string},
    strategies: Strategy[],
    handleLinkClick: any,
    handleSubLinkClick: any,
  ) {
    this.dataIssues = issues;
    this.themeColor = themeColor;
    this.esgFilters = esgFilters;
    this.filters = filters;
    this.handleLinkClick = handleLinkClick;
    this.handleSubLinkClick = handleSubLinkClick;
    this.companies = new Set();
    this.tags = tags;
    this.strategies = strategies;

    this.issueNavigation = this.getIssueNavigation();
    this.rootIssue = this.getRootIssue();

    this.calculateHoldingsStatistics(holdingsData);
    // this.calculateUniverseStatistics(universeData, holdingsData);
    this.calculateBenchmarkStatistics(marketIndexData);
  }

  private findIssue = (id?: number) => {
    if (id) {
      return this.dataIssues.find(issue => issue.id === id);
    }
  };

  private getRootIssue() {
    const rootIssue = this.dataIssues.find(issue => issue.id === this.esgFilters.category.id);

    return rootIssue;
  }

  private getIssueNavigation() {
    const categoryIssues = this.dataIssues.filter(issue => issue.parentId === this.esgFilters.category.id);

    let paths: any = [];

    categoryIssues.forEach(issue => {
      paths.push({
        issue,
        isActive: issue.id === this.esgFilters.topic?.id,
        topics: this.dataIssues
          .filter(currentIssue => {
            return currentIssue.parentId === issue.id;
          })
          .map(currentIssue => {
            return {
              issue: currentIssue,
              isActive: currentIssue.id === this.esgFilters.subTopic?.id,
              topics: this.dataIssues
                .filter(tertIssue => {
                  return tertIssue.parentId === currentIssue.id;
                })
                .map(currentIssue => {
                  return {
                    issue: currentIssue,
                    isActive: currentIssue.id === this.esgFilters.tertiaryTopic?.id,
                  };
                }),
            };
          }),
      });
    });

    return paths;
  }

  private setPercentile() {
    const percentile: any = {};

    if (this.esgFilters.category) {
      percentile[this.esgFilters.category.id] = 0;
    }

    if (this.esgFilters.topic) {
      percentile[this.esgFilters.topic.id] = 0;
    }

    if (this.esgFilters.subTopic) {
      percentile[this.esgFilters.subTopic.id] = 0;
    }

    if (this.esgFilters.tertiaryTopic) {
      percentile[this.esgFilters.tertiaryTopic.id] = 0;
    }

    return percentile;
  }

  private calculateHoldingsStatistics(data: AggregatePercentileHolding[]) {
    const percentiles = this.setPercentile();
    const rawPercentiles = this.setPercentile();

    const holdingsStatistics = calculateESGHoldingsStatistics(
      percentiles,
      rawPercentiles,
      this.companies,
      this.issues,
      this.esgFilters,
      this.tags,
      this.strategies,
      data,
    );

    this.percentiles = holdingsStatistics.percentiles;
    this.quintiles = holdingsStatistics.quintiles;
    this.rawPercentileValue = holdingsStatistics.rawPercentileValue;
  }

  private calculateUniverseStatistics(data: AggregatePercentileUniverse[], holdingsData: AggregatePercentileHolding[]) {
    const percentiles = this.setPercentile();
    const rawPercentiles = this.setPercentile();
    let universeStatistics;
    if (this.tags.length > 0) {
      universeStatistics = calculateFilteredESGUniverseStatistics(
        percentiles,
        rawPercentiles,
        this.companies,
        this.issues,
        this.esgFilters,
        this.tags,
        this.strategies,
        holdingsData,
      );
    } else {
      universeStatistics = calculateESGUniverseStatistics(
        percentiles,
        rawPercentiles,
        this.companies,
        this.esgFilters,
        data,
      );
    }

    this.universePercentiles = universeStatistics.universePercentiles;
    this.rawUniverseValue = universeStatistics.rawUniverseValue;
  }

  private calculateBenchmarkStatistics(data: MarketIndexBenchmark[]) {
    const percentiles = this.setPercentile();
    const rawPercentiles = this.setPercentile();
    let universeStatistics;
    universeStatistics = calculateESGBenchmarktatistics(
      percentiles,
      rawPercentiles,
      this.companies,
      this.esgFilters,
      data,
    );

    this.universePercentiles = universeStatistics.universePercentiles;
    this.rawUniverseValue = universeStatistics.rawUniverseValue;
  }

  isFilteredOut(datum: any) {
    if (isEmpty(this.filters.region) || datum.region === this.filters.region) {
      if (isEmpty(this.filters.class) || datum.class === this.filters.class) {
        if (this.filters.type === 'all' || datum.type === this.filters.type) {
          return false;
        }
      }
    }
    return true;
  }

  title(): string {
    return this.esgFilters?.topic?.name ?? DATA_STATUS.NONE;
  }

  description(): string {
    return this.esgFilters?.topic?.description ?? DATA_STATUS.NONE;
  }

  paths(): any[] {
    let paths = this.issueNavigation.find(item => item.issue.id === this.esgFilters?.topic?.id)?.topics ?? [];
    paths = paths.map((item: any) => {
      return {
        active: item.issue.id === this.esgFilters?.subTopic?.id,
        onClick: () => this.handleLinkClick(item.issue),
        label: item.issue.name,
        icon: ESG_CONTENT[item.issue.id].icon,
        isSmall: false,
      };
    });

    return paths;
  }

  private maxQuintile = (val: number) => {
    const max = 5;
    return val > max ? max : val;
  };

  private numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private roundMillion = (value: number) => {
    if (value < 1 && value !== 0) {
      return value.toFixed(5);
    }
    if (String(value).length > 6) {
      return this.numberWithCommas(String(Math.round(value)).slice(0, 5)) + 'm';
    } else if (String(value).length > 3) {
      return this.numberWithCommas(String(Math.round(value)).slice(0, 5));
    }
    if (value > 1) {
      return Math.round(value);
    }
    return value;
  };

  private formatValue = (unit: string, value: number) => {
    switch (unit) {
      case 'TCIR':
        return value.toFixed(4);
      case '%':
        return this.numberWithCommas(Math.round(value)) + '%';
      case 'TCM':
      case 'MMt':
        return this.numberWithCommas(value.toFixed(0));
      case 'MWh':
        if (String(value).length > 6) {
          return this.numberWithCommas(Math.round(value));
        }
        return this.numberWithCommas(Math.round(value));
      default:
        if (value < 1) {
          return value;
        }
        return this.numberWithCommas(Math.round(value));
    }
  };

  rankingSummaryProps(): any {
    return {
      percentile: this.validateValue(this.percentiles[this.esgFilters.topic.id]),
      universe: this.validateValue(this.universePercentiles[this.esgFilters.topic.id]),
      quintile: this.maxQuintile(this.quintiles[this.esgFilters.topic.id] ?? 0),
    };
  }

  private validateValue(value?: number) {
    if (!value || isNaN(value)) {
      return 0;
    }

    return Math.round(value);
  }

  private setComparison(a: number, b: number, target?: string) {
    let difference, color, trend;
    const issue = this.esgFilters.subTopic?.id ? this.findIssue(this.esgFilters.subTopic?.id) : null;
    const subIssue = this.esgFilters.tertiaryTopic?.id ? this.findIssue(this.esgFilters.tertiaryTopic?.id) : null;

    if (target === 'MAX') {
      color = a > b ? Theme.colors.green : Theme.colors.red;
    } else if (target === 'MIN') {
      color = a > b ? Theme.colors.red : Theme.colors.green;
    } else if (!isNaN(Number(target))) {
      const closest = getClosest(a, b, Number(target));
      color = a === closest ? Theme.colors.green : Theme.colors.red;
    } else {
      color = a > b ? Theme.colors.green : Theme.colors.red;
    }

    if (a > b) {
      difference = 100 * Math.abs((a - b) / ((a + b) / 2));
      trend = '+';
    } else {
      difference = 100 * Math.abs((b - a) / ((b + a) / 2));
      trend = '-';
    }

    if ((issue?.id ?? 0) === ISSUES.CEO_COMPENSATION_RATIO.id) {
      if (a > b) {
        difference = 100 * Math.abs((a - b) / b);
      } else {
        difference = 100 * Math.abs((b - a) / a);
      }

      trend = a > b ? '+' : '-';
      color = a > b ? Theme.colors.red : Theme.colors.green;
    }

    if ((subIssue?.id ?? 0) === ISSUES.WOMEN_IN_LEADERSHIP.id) {
      difference = 100 * Math.abs((a - b) / b);
      trend = a > b ? '+' : '-';
      color = a > b ? Theme.colors.green : Theme.colors.red;
    }

    return {
      trend,
      difference: isNaN(difference) ? 0 : difference.toFixed(1),
      color,
    };
  }

  subCategoryProps(): any {
    if (this.esgFilters?.subTopic) {
      let paths = this.issueNavigation.find((item: any) => {
        return item.issue.id === this.esgFilters?.topic?.id;
      }).topics;

      let subPaths =
        paths.find((item: any) => {
          return item.issue.id === this.esgFilters?.subTopic?.id;
        }).topics ?? [];

      subPaths = subPaths.map((item: any) => {
        return {
          active: item.issue.id === this.esgFilters?.tertiaryTopic?.id,
          onClick: () => this.handleSubLinkClick(item.issue),
          label: item.issue.name,
          icon: ESG_CONTENT[item.issue.id].icon,
          isSmall: false,
        };
      });

      let data: any = {
        title: this.esgFilters?.subTopic?.name ?? DATA_STATUS.NONE,
        description: this.esgFilters?.subTopic?.description ?? DATA_STATUS.NONE,
        paths: subPaths,
        rankingSummaryProps: {
          percentile: this.validateValue(this.percentiles[this.esgFilters.subTopic.id]),
          universe: this.validateValue(this.universePercentiles[this.esgFilters.subTopic.id]),
          quintile: this.maxQuintile(this.quintiles[this.esgFilters.subTopic.id] ?? 0),
        },
      };

      const percentile = this.validateValue(this.rawPercentileValue[this.esgFilters.subTopic.id]);
      const universe = this.validateValue(this.rawUniverseValue[this.esgFilters.subTopic.id]);
      const comparison = this.setComparison(percentile, universe, this.findIssue(this.esgFilters.subTopic.id)?.target);

      if (this.esgFilters.subTopic.target) {
        data.summaryChartProps = {
          title: this.esgFilters.subTopic?.name ?? DATA_STATUS.NONE,
          subtitle: this.esgFilters.subTopic?.description ?? DATA_STATUS.NONE,
          units: `(${this.esgFilters.subTopic?.unit})` ?? '',
          secondaryUnit: this.esgFilters.subTopic?.unit ?? '',
          data: universe !== 0 && percentile !== 0 ? [percentile, universe] : [0, 0],
          labels: ['PORTFOLIO', 'BENCHMARK'],
          colors: [Theme.colors.harmony, Theme.colors.grey_4],
          comparisonText: universe !== 0 && percentile !== 0 ? comparison.trend + comparison.difference + ' %' : '0 %',
          comparisonColor: universe !== 0 && percentile !== 0 ? comparison.color : Theme.colors.green,
          barLabelFormatter: (val: number) =>
            val > 1 ? this.formatValue(this.esgFilters.subTopic?.unit ?? '', val) : val.toFixed(5),
          yTickFormatter: this.roundMillion,
        };
      }

      return data;
    }

    return null;
  }

  tertiaryCategoryProps(): any {
    if (this.esgFilters?.tertiaryTopic) {
      let data: any = {
        title: this.esgFilters?.tertiaryTopic?.name ?? DATA_STATUS.NONE,
        description: this.esgFilters?.tertiaryTopic?.description ?? DATA_STATUS.NONE,
        rankingSummaryProps: {
          percentile: Math.round(this.percentiles[this.esgFilters.tertiaryTopic.id] ?? 0),
          universe: Math.round(this.universePercentiles[this.esgFilters.tertiaryTopic.id] ?? 0),
          quintile: this.quintiles[this.esgFilters.tertiaryTopic.id] ?? 0,
        },
      };

      const percentile = this.validateValue(this.rawPercentileValue[this.esgFilters.tertiaryTopic.id]);
      const universe = this.validateValue(this.rawUniverseValue[this.esgFilters.tertiaryTopic.id]);
      const comparison = this.setComparison(percentile, universe);

      if (this.esgFilters.tertiaryTopic?.target) {
        data.summaryChartProps = {
          title: this.esgFilters.tertiaryTopic?.name ?? DATA_STATUS.NONE,
          subtitle: this.esgFilters.tertiaryTopic?.description ?? DATA_STATUS.NONE,
          units: `(${this.esgFilters.tertiaryTopic?.unit})` ?? '',
          secondaryUnit: this.esgFilters.tertiaryTopic?.unit ?? '',
          data: universe !== 0 && percentile !== 0 ? [percentile, universe] : [0, 0],
          // labels: ['PORTFOLIO', 'UNIVERSE'],
          labels: ['PORTFOLIO', 'BENCHMARK'],
          colors: [Theme.colors.harmony, Theme.colors.grey_4],
          comparisonText: universe !== 0 && percentile !== 0 ? comparison.trend + comparison.difference + ' %' : '0 %',
          comparisonColor: universe !== 0 && percentile !== 0 ? comparison.color : Theme.colors.green,
          barLabelFormatter: (val: number) => this.formatValue(this.esgFilters.tertiaryTopic?.unit ?? '', val),
          yTickFormatter: this.roundMillion,
        };
      }

      return data;
    }

    return null;
  }

  color(): string {
    return this.themeColor;
  }
}
