import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  concat,
  each,
  fill,
  findIndex,
  indexOf,
  isEmpty,
  isFinite,
  isNil,
  map,
  max,
  sortBy,
  sumBy,
  uniqueId,
} from 'lodash';
import cn from 'classnames';

import {RATING_OPTIONS} from 'app/constants';
import {convertPeriodKeyToFloat} from 'app/utilities';
import {APreventDefault} from 'app/components';
import {RectangleImage, ToolTip} from '.';
import {Overline} from 'v2/components/atoms/Typeface';
import {RATING_OPTIONS_REVERSED} from 'app/constants/ratingOptions';

const BAR_SPREAD = 3.5; // percent for transform offset of each bar

const getMaxValueInDatasets = datasets => {
  let allValues = [];
  each(datasets, dataset => {
    allValues = concat(allValues, dataset.data);
  });
  return max(allValues);
};

const getChangeObject = (datasets, datasetIndex, dataIndex, chronologicalPeriodKeys) => {
  if (isEmpty(datasets)) return null;

  const chronologicalDataIndex = indexOf(chronologicalPeriodKeys, datasets[datasetIndex].label);
  if (!(chronologicalDataIndex > 0)) return null;

  const previousPeriodKey = chronologicalPeriodKeys[chronologicalDataIndex - 1];
  const datasetIndexToCompare = findIndex(datasets, {label: previousPeriodKey});

  return {
    value: datasets[datasetIndex].data[dataIndex],
    previousValue: datasets[datasetIndexToCompare].data[dataIndex],
  };
};

const getPercentChange = (datasets, datasetIndex, dataIndex, chronologicalPeriodKeys) => {
  if (isEmpty(datasets)) return null;

  const changeObject = getChangeObject(datasets, datasetIndex, dataIndex, chronologicalPeriodKeys);
  if (!changeObject) return null;

  return ((changeObject.value - changeObject.previousValue) / changeObject.previousValue) * 100;
};

const getChangeLabel = (_changeValue, unit = '%') => {
  const changeValue = Math.round(_changeValue);
  const changeSign = changeValue > 0 ? '+' : '';
  return `${changeSign}${changeValue}${unit} change`;
};

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.id = uniqueId(); // used for tooltips
  }

  handleDistributionClick(props) {
    const {onDistributionClick} = this.props;
    onDistributionClick && onDistributionClick(props);
  }

  render() {
    const {
      className,
      datasets,
      hasOverlap,
      labels,
      lineInterval,
      selectedDataIndex,
      selectedDatasetIndex,
      size,
      tooltipMode,
      style,
      showPercentiles,
    } = this.props;
    let {datasetsVisible = []} = this.props;

    // Fallback datasetsVisible to all if not supplied
    if (datasetsVisible.length === 0) datasetsVisible = fill(Array(datasets.length), true);

    const hasSelection = !isNil(selectedDatasetIndex);
    const visibleDatasetsCount = hasSelection ? 1 : sumBy(datasetsVisible, visible => (visible ? 1 : 0));
    const isComparison = visibleDatasetsCount > 1 && !hasSelection; // "comparison" charts have more than one data set.
    const maxValue = getMaxValueInDatasets(datasets);

    // The maximum line value in the graph is the next interval above the highest value
    const maxLineValue = Math.ceil(maxValue / lineInterval) * lineInterval + lineInterval;

    const lines = [];
    for (let lineValue = lineInterval; lineValue <= maxLineValue; lineValue += lineInterval) {
      lines.push(
        <div
          key={lineValue}
          className="bar-chart-line"
          data-value={`${lineValue}%`}
          style={lineValue > 100 ? {visibility: 'hidden'} : null}
        />,
      );
    }

    const barChartClassName = cn('bar-chart', className, {
      'is-comparison': isComparison,
      [`is-${size}`]: size,
      'has-bar-labels': !labels, // each bar has its own label
      'has-overlap': hasOverlap,
      'has-selection': hasSelection,
    });

    let datasetCount = 0;

    let chronologicalPeriodKeys = [];
    if (tooltipMode === 'change') {
      // Create an object that has all the period keys...
      const periods = {};
      each(datasets, ({isHidden, label, data}) => {
        if (!isHidden) {
          periods[label] = data;
        }
      });
      // ... just so we can sort it
      chronologicalPeriodKeys = sortBy(Object.keys(periods), convertPeriodKeyToFloat);
    }

    return (
      <>
        <div className={barChartClassName} style={style}>
          <div className="bar-chart-line-set">{lines}</div>

          {map(datasets, ({color, data: values, label}, datasetIndex) => {
            const isPrimary = datasetIndex === 0; // The first dataset is "primary"

            const isVisible = datasetsVisible[datasetIndex] && (!hasSelection || selectedDatasetIndex === datasetIndex); // hide non-selected datasets

            const datasetClassName = cn('bar-chart-data-set', {
              'is-primary': isPrimary,
              'is-hidden': !isVisible,
            });

            // Fan the bars out evenly beside each other
            const barOffset = BAR_SPREAD * datasetCount - (BAR_SPREAD / 2) * (visibleDatasetsCount - 1);
            if (isVisible) datasetCount += 1;

            return (
              <div
                key={datasetIndex}
                className={datasetClassName}
                style={{
                  zIndex: 10 - datasetIndex,
                  transform: `translateX(${barOffset}%)`,
                }}
              >
                {map(values, (value, dataIndex) => {
                  const id = `bar-chart-${this.id}-data-${datasetIndex}-${dataIndex}`;
                  const height = `${(100 * value) / maxLineValue}%`;
                  const isSelected = selectedDataIndex === dataIndex;

                  const barLabel = labels ? isPrimary && labels[dataIndex] : `${value}%`;
                  const barClassName = cn('bar-chart-data', {
                    'is-selected': isSelected,
                    'has-label': barLabel,
                  });

                  let tooltip;

                  if (tooltipMode === 'change') {
                    const changeValue = getPercentChange(datasets, datasetIndex, dataIndex, chronologicalPeriodKeys);

                    if (isFinite(changeValue)) {
                      const changeLabel = getChangeLabel(changeValue);
                      tooltip = (
                        <ToolTip target={id}>
                          <span className="text-white">{changeLabel}</span>
                        </ToolTip>
                      );
                    }
                  } else if (tooltipMode !== 'none') {
                    tooltip = (
                      <ToolTip target={id}>
                        <strong className="text-white d-block">{Math.round(value)}%</strong>
                        {label || 'Click for more'}
                      </ToolTip>
                    );
                  }

                  return (
                    <div key={dataIndex} className={barClassName} style={{height, overflow: 'visible'}}>
                      <APreventDefault
                        className="bar-chart-data-label"
                        style={{color}}
                        id={id}
                        onClick={() => this.handleDistributionClick({datasetIndex, dataIndex})}
                      >
                        {barLabel}
                      </APreventDefault>
                      <div className="bar-chart-data-fill">
                        <RectangleImage fill={color} />
                      </div>
                      {tooltip}
                      {isPrimary && showPercentiles && (
                        <Overline style={{position: 'relative', top: 'calc(100% + .5rem)', zIndex: 1000}}>
                          {(4 - dataIndex + 1) * 20 + '-' + (4 - dataIndex) * 20}
                        </Overline>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

BarChart.propTypes = {
  className: PropTypes.string,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  datasetsVisible: PropTypes.arrayOf(PropTypes.bool),
  hasOverlap: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  lineInterval: PropTypes.number,
  onDistributionClick: PropTypes.func,
  selectedDataIndex: PropTypes.number,
  selectedDatasetIndex: PropTypes.number,
  size: PropTypes.string,
  tooltipMode: PropTypes.string,
  style: PropTypes.object,
};

BarChart.defaultProps = {
  hasOverlap: true,
  labels: RATING_OPTIONS.reverse(),
  lineInterval: 10,
  tooltipMode: 'default',
};

export {BarChart};
