import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Field, Form} from 'react-final-form';
import styled from 'styled-components';
import {FORM_ERROR} from 'final-form';
import {get} from 'lodash';

// Local Imports
import {CardContainer, FlexContainer, Container} from 'v2/components/atoms/Containers';
import {PopCheckCircle} from 'v2/components/atoms/icons';
import {Theme} from 'v2/components/atoms/theme';
import {Link1, ParagraphM, ParagraphS, TitleM, TitleXS} from 'v2/components/atoms/Typeface';
import {Checkbox} from 'v2/components/molecules/Checkbox';
import {PasswordInput} from 'v2/components/molecules/PasswordInput';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {Separator} from 'v2/components/atoms/Separator';
import {OnboardingFooter} from 'v2/components/organisms/OnboardingFooter';
import {useDispatch} from 'react-redux';
import {updateOnboardingUserByInvitationCode} from 'app/redux/onboardingUser';
import {errorRequiredFields} from 'app/utilities';
import {handleSave} from './handleSave';
import {FormFeedback} from 'app/components';
import {SpinnerDisplay} from 'app/components/Spinner/SpinnerDisplay';

const ContentContainer = styled(FlexContainer)`
  flex: 2;
`;

const StyledLink = styled(Link1)`
  text-decoration: none;
  color: ${({theme}) => theme.colors.blue_1};
`;

const Title = styled(TitleM)`
  text-transform: none;
`;

const SecondaryTitle = styled(TitleXS)`
  text-transform: none;
`;

const CheckLabel = styled(ParagraphM)`
  margin: 0;
`;

export const UT3OnboardingAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {onboardingUser, invitationCode, onboardingSteps, requestedStep, isLoading} = useOnboardingUser();

  const accountComplete = get(onboardingSteps, 'ACCOUNT.isComplete', false);
  const nextRoute = get(onboardingSteps, 'PROFILE.route.path', '').replace(':invitationCode', invitationCode);

  const onboardingStep = get(requestedStep, 'step', '');

  const handleSubmitCallback = React.useCallback(
    async (values: any) => {
      try {
        if (!accountComplete) {
          const res = await handleSave({
            values,
            onboardingStep,
            dispatch,
            action: updateOnboardingUserByInvitationCode,
            invitationCode,
            nextRoute,
          });
          return res ? res : {[FORM_ERROR]: 'Something went wrong'};
        } else {
          history.push(nextRoute);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [accountComplete, dispatch, history, invitationCode, nextRoute, onboardingStep],
  );

  const validate = (values: any) => {
    const errors: any = errorRequiredFields(values, ['password', 'confirmPassword', 'agreeToTerms']);

    if (values?.confirmPassword !== values?.password) {
      errors.confirmPassword = 'Must match Password.';
    }

    if (values?.password && values?.password?.length < 8) {
      errors.password = 'Minimum of 8 characters.';
    }

    if (!values?.agreeToTerms?.length) {
      errors.agreeToTerms = 'Must agree to terms.';
    }

    return accountComplete ? {} : errors;
  };

  return (
    <Form
      onSubmit={handleSubmitCallback}
      validate={validate}
      render={({
        handleSubmit,
        error,
        invalid,
        submitFailed,
        submitError,
        form: {
          mutators: {push},
          submit,
        },
      }) => {
        const isErrorFormFeedbackVisible = !!error || (!!submitFailed && invalid);

        return (
          <form method="POST" onSubmit={handleSubmit}>
            <CardContainer>
              <FlexContainer fullWidth>
                <ContentContainer direction="column">
                  <Title color={Theme.colors.dark}>Create an Account</Title>
                  <FlexContainer padding="3.0625rem 0 2.8125rem" direction="column" gap="0.6875rem">
                    <Container>
                      <ParagraphS color="#000">Email (Username)</ParagraphS>
                    </Container>
                    <Container>
                      <ParagraphM color={Theme.colors.harmony}>
                        {onboardingUser?.user?.email ?? DATA_STATUS.NONE}
                      </ParagraphM>
                    </Container>
                  </FlexContainer>
                </ContentContainer>
                {accountComplete && (
                  <FlexContainer justify="flex-end">
                    <PopCheckCircle size={70} />
                  </FlexContainer>
                )}
              </FlexContainer>
              <Separator />
              {!!submitError && (
                <Container padding="0 0 0">
                  <FormFeedback message={submitError} visible={isErrorFormFeedbackVisible} children={undefined} />
                </Container>
              )}
              {accountComplete ? (
                <FlexContainer direction="column" maxWidth="30.25rem" padding="2.125rem 0 0" gap="1.25rem">
                  <SecondaryTitle color={Theme.colors.harmony}>You have already created your account</SecondaryTitle>
                  <ParagraphM>
                    You will be able to change your password once you have completed the account setup process. If at
                    any time you forget your password, please use the{' '}
                    <StyledLink as={Link} to="/forgot-password">
                      forgot password
                    </StyledLink>{' '}
                    flow to reset it.
                  </ParagraphM>
                </FlexContainer>
              ) : (
                <>
                  <FlexContainer flex={1} padding="2.125rem 0 0" gap="2.5625rem">
                    <Container>
                      <Field name="password">
                        {props => (
                          <PasswordInput
                            {...props.input}
                            id="password"
                            label="Choose a Password"
                            requirementText="Minimum of 8 characters"
                            hasError={props.meta.error && props.meta.touched}
                            required
                          />
                        )}
                      </Field>
                    </Container>
                    <Container>
                      <Field name="confirmPassword">
                        {props => (
                          <PasswordInput
                            {...props.input}
                            id="confirmPassword"
                            label="Confirm Your Password"
                            requirementText="Minimum of 8 characters"
                            hasError={props.meta.error && props.meta.touched}
                            required
                          />
                        )}
                      </Field>
                    </Container>
                  </FlexContainer>
                  <FlexContainer items="center" gap="1.125rem" padding="3.5rem 0 0">
                    <Field name="agreeToTerms" type="checkbox" value="yes">
                      {props => (
                        <>
                          <Checkbox
                            id="agreeToTerms"
                            {...props.input}
                            hasError={props.meta.error && props.meta.touched}
                          />
                          <CheckLabel as="label" htmlFor="agreeToTerms" color={Theme.colors.dark}>
                            I agree with the Terms of Service*
                          </CheckLabel>
                        </>
                      )}
                    </Field>
                  </FlexContainer>
                </>
              )}
            </CardContainer>
            <OnboardingFooter state="initial" onContinue={() => submit()} />
            <SpinnerDisplay isActive={isLoading} />
          </form>
        );
      }}
    />
  );
};
