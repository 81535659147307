import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, FlexContainer} from 'v2/components/atoms/Containers';
import {ParagraphM, TitleS} from 'v2/components/atoms/Typeface';
import Windows from 'v2/assets/images/ResouceCenterWindows.png';
import {ResourceTable} from 'v2/components/organisms/ResourceTable';
import {isAdmin, isImpersonating} from 'app/utilities';
import {HarmonyButton} from 'v2/components/molecules/Button';
import {Theme} from 'v2/components/atoms/theme';
import {PlusSquare} from 'v2/components/atoms/icons';
import {Overlay} from 'v2/components/atoms/Overlay';
import {AdminResourceCard} from 'v2/components/organisms/AdminResourceCard';
import {ResourceCenterFile} from 'v2/utilities/types/typeOrm/ResourceCenterFile';
import {
  useCreateResourceCenterFileMutation,
  useDeleteResourceCenterFileMutation,
  useGetResourceCenterFilesQuery,
  useUpdateResourceCenterFileMutation,
} from 'v2/redux/typeormEndpoints';
import {CONFIG} from 'app/constants';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';

const Button = styled(HarmonyButton).attrs({
  variant: 'default',
})`
  padding: 0.75rem 1rem;
  &&& {
    border: 0;
  }
`;

const WindowImage = styled.img.attrs({
  alt: 'Resource Center',
  src: Windows,
})`
  width: 25.8125rem;
`;

const Aside = styled(FlexContainer).attrs({
  as: 'aside',
  padding: '1.75rem 0 0',
  minWidth: '25.8125rem',
  direction: 'column',
  fullWidth: true,
})``;

const SideContainer = styled(Container).attrs({
  padding: '2.1875rem 0 6rem',
})``;

const Wrapper = styled(FlexContainer).attrs({
  gap: '10.625rem',
  padding: '2rem 6.25rem',
  justify: 'center',
  fullWidth: true,
})`
  &&& {
    @media (max-width: 1250px) {
      flex-direction: column;
      gap: 2rem;

      ${WindowImage} {
        display: none;
      }

      ${SideContainer} {
        padding: 2.1875rem 0 0;
      }
    }
  }
`;

const Title = styled(TitleS)`
  text-transform: none;
`;

export const ResourcCenterPage = () => {
  const isAdminAcct = isAdmin() && !isImpersonating();
  const [showOverlay, setShowOverlay] = useState<boolean | number>(false);
  const addNewFile = () => {
    setShowOverlay(0);
  };
  //@ts-ignore
  const {data: resourceCenterFiles, isLoading} = useGetResourceCenterFilesQuery();
  const [updateFile] = useUpdateResourceCenterFileMutation();
  const [createFile] = useCreateResourceCenterFileMutation();
  const [deleteFile] = useDeleteResourceCenterFileMutation();
  const handleClose = () => {
    setShowOverlay(false);
  };

  const handleSubmitIssue = (val: any, id: number) => {
    if (id) {
      updateFile({...val, id, title: val?.resourceName});
    } else {
      createFile({...val, title: val?.resourceName});
    }
    setShowOverlay(false);
  };

  const handleEdit = (resource: ResourceCenterFile) => {
    setShowOverlay(resource.id);
  };

  const handleDelete = (resource: ResourceCenterFile) => {
    deleteFile({id: resource.id});
  };

  const handleDownload = (resource: ResourceCenterFile) => {
    const url = urlWithToken(`${CONFIG.API_URL}/s3/uploads/${resource.fileUrl}`);
    //Navigate to the url defined above in a seperate tab
    window.open(url, '_blank');
  };

  const formattedFiles = resourceCenterFiles?.map((file: ResourceCenterFile, i: number) => ({
    ...file,
    updatedAt: new Date(file.updatedAt),
    label: file.category,
    canEdit: isAdminAcct,
    canDelete: isAdminAcct,
    onEdit: () => handleEdit(file),
    onDelete: () => handleDelete(file),
    onDownload: () => handleDownload(file),
  }));

  //This adds one extra form for adding a new file
  const filesWithNewForm = resourceCenterFiles?.concat({id: 0, category: '', fileName: '', fileUrl: ''});

  return (
    <Wrapper>
      <Aside>
        <Container>
          <Title>Materials to help you reach your goals</Title>
        </Container>
        <SideContainer>
          <ParagraphM>
            Harmony Analytics is a research and data analytics firm dedicated to transforming the world through data.
            Our Resource Center is designed for capital owners who want to learn more about the Harmony methodology. We
            are dedicated to our partners' success - if you cannot find what you are looking for, please get in touch.
          </ParagraphM>
        </SideContainer>
        <Container className="image-container">
          <WindowImage />
        </Container>
      </Aside>
      <FlexContainer flex="1" minWidth="50rem" fullWidth direction="column" gap=".75rem">
        <ResourceTable
          title="Understanding The Data"
          rows={formattedFiles}
          canEdit={isAdminAcct}
          canDelete={isAdminAcct}
          onEdit={(file: ResourceCenterFile) => handleEdit(file)}
          onDelete={(file: ResourceCenterFile) => handleDelete(file)}
          onDownload={(file: ResourceCenterFile) => handleDownload(file)}
        />
        <FlexContainer margin="0 0 0 auto">
          {isAdminAcct && (
            <Button onClick={() => addNewFile?.()} variant="light">
              <FlexContainer as="span" gap="0.75rem">
                <PlusSquare size={17} color={Theme.colors.white} />
                <span>Add New File</span>
              </FlexContainer>
            </Button>
          )}
        </FlexContainer>
      </FlexContainer>
      {filesWithNewForm?.map((file: ResourceCenterFile) => {
        return (
          <Overlay visible={showOverlay === file.id} items="center" justify="center" fullWidth key={file.id}>
            <AdminResourceCard
              resourceName={file?.title}
              category={file?.category}
              fileName={file?.fileName}
              fileUrl={file?.fileUrl}
              initialValues={{
                resourceName: file?.title,
                category: file?.category,
                fileName: file?.fileName,
                fileUrl: file?.fileUrl,
              }}
              onSubmit={handleSubmitIssue}
              onOutsideClick={() => showOverlay === file.id && handleClose()}
              onClose={handleClose}
              id={file?.id}
            ></AdminResourceCard>
          </Overlay>
        );
      })}
    </Wrapper>
  );
};
