import React, {Fragment} from 'react';
import {each, last, map, orderBy, slice} from 'lodash';
import {Link as ScrollLink} from 'react-scroll';

import {ISSUE_TYPE} from 'app/constants';
import {getIssueDataAnalysis} from 'app/utilities';

const ExposureList = ({exposures, issueClassName}) =>
  exposures.length > 0 ? (
    <dl className="exposure-list">
      {map(exposures, ({issue: {code, name}}) => (
        <Fragment key={code}>
          <dt>{code}</dt>
          <dd className={issueClassName}>
            <ScrollLink to={`issue-card-${code}`} offset={-125} smooth={true} duration={500} className="cursor-pointer">
              {name}
            </ScrollLink>
          </dd>
        </Fragment>
      ))}
    </dl>
  ) : null;

const Exposures = ({issues}) => {
  let promisingExposures = [];
  let worrisomeExposures = [];
  each(issues, issue => {
    if (issue.type === ISSUE_TYPE.BOOLEAN) {
      const issueDataAnalysis = getIssueDataAnalysis(issue.data);
      if (issueDataAnalysis.isPromising) promisingExposures.push({issue, order: -1});
      if (issueDataAnalysis.isWorrisome) worrisomeExposures.push({issue, order: 101});
    } else if (issue.type === ISSUE_TYPE.PERCENTILE) {
      const performance = last(issue.data).percentile;

      if (performance >= 75) promisingExposures.push({issue, order: performance});
      if (performance <= 25) worrisomeExposures.push({issue, order: performance});
    }
  });
  promisingExposures = orderBy(promisingExposures, ['order'], ['desc']);
  worrisomeExposures = orderBy(worrisomeExposures, ['order'], ['asc']);

  return (
    <Fragment>
      {promisingExposures.length > 0 && (
        <div>
          <h4>Most Promising Exposures</h4>
          <ExposureList exposures={slice(promisingExposures, 0, 5)} />
        </div>
      )}
      {worrisomeExposures.length > 0 && (
        <div>
          <h4>Most Worrisome Exposures</h4>
          <ExposureList exposures={slice(worrisomeExposures, 0, 5)} issueClassName="text-danger" />
        </div>
      )}
    </Fragment>
  );
};

export {Exposures};
