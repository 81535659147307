import {Theme} from '../theme';

export const FileText = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 6H18C16.4087 6 14.8826 6.63214 13.7574 7.75736C12.6321 8.88258 12 10.4087 12 12V60C12 61.5913 12.6321 63.1174 13.7574 64.2426C14.8826 65.3679 16.4087 66 18 66H54C55.5913 66 57.1174 65.3679 58.2426 64.2426C59.3679 63.1174 60 61.5913 60 60V24L42 6Z"
        stroke="#464255"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M48 51H24" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M48 39H24" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M30 27H27H24" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M42 6V24H60" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
