import {assign, get, isDate, isNil, pickBy, pick} from 'lodash';
import {addMinutes, compareAsc} from 'date-fns';
import {currentImpersonatedUser} from 'app/utilities';

// Local Imports
import {CONFIG} from 'app/constants';
import {getAuthJwt, getSessionJwt} from 'app/utilities/tokenFunctions';
import {store} from '../../index';
/**
 * Calculate the default HTTP headers to use with every fetch
 * @returns {Promise<Object>} - A Promise that resolves with hash
 *   of HTTP headers
 */
function getDefaultHeaders() {
  return Promise.resolve({
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  });
}

/**
 * Calculate the HTTP headers with which to authenticate the user
 * @returns {Promise<Object>} - A Promise that resolves with hash
 *   of HTTP headers
 */
export function getAuthHeaders() {
  const token = getSessionJwt();

  return Promise.resolve({
    Authorization: 'Bearer ' + token,
    'Cache-Control': 'no-cache,no-store',
  });
}

/**
 * Merge a hash of headers with a hash of fetch options with might
 * already include headers.
 * @param {Object} options - Options for fetch
 * @param {Object} headers - Headers to use with fetch
 * @returns {Object} - Options for fetch that include the headers
 */
function mergeHeadersIntoOptions(options, headers) {
  // Preserve any addition header properties if provided
  // @TODO: Consider replacing lodash assign with something that will merge deep properties
  const mergedHeaders = assign({}, options.headers || {}, headers);
  return assign({}, options, {headers: mergedHeaders});
}

/**
 * Wrapper for fetch which throws if HTTP status code >= 200 but < 300
 * @param path {string} Path to perform AJAX request
 * @param options {object} Fetch options
 * @return {Promise} Fetch response wrapped in a promise
 * @see https://github.com/github/fetch#usage
 * @example
 * fetchRejectErrors(path, {
 *   method: 'PUT',
 *   body: JSON.stringify({ values }),
 * }).then(responseBody => console.log(responseBody));
 */
function fetchRejectErrors(path, options = {}) {
  function _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    return response.json().then(errorTxt => {
      const error = new Error(response.statusText);
      error.message = errorTxt.error;
      throw error;
    });
  }
  return fetch(path, options).then(_checkStatus);
}

/**
 * Wrapper for fetchRejectErrors which parses JSON
 * @param path {string} Path to perform AJAX request
 * @param options {object} Fetch options
 * @return {Promise} Fetch response wrapped in a promise
 * @see https://github.com/github/fetch#usage
 * @example
 * fetchJSON(path, {
 *   method: 'PUT',
 *   body: JSON.stringify({ values }),
 * }).then(jsonResponseBody => console.log(jsonResponseBody));
 */
export function fetchJSON(path, options = {}) {
  function _mergeHeaders(headers) {
    return mergeHeadersIntoOptions(options, headers);
  }
  function _parseJSON(response) {
    if (response.status === 204) {
      // Status 204 contains no body and would error if we try to parse as JSON
      return Promise.resolve(null);
    }
    return response.json();
  }
  return getDefaultHeaders()
    .then(_mergeHeaders)
    .then(mergedOptions => fetchRejectErrors(`${CONFIG.API_URL}/${path}`, mergedOptions))
    .then(_parseJSON);
}

/**
 * Wrapper for fetchJSON which automatically adds an Authorization header
 * @param path {string} Path to perform AJAX request
 * @param options {object} Fetch options
 * @return {Promise} Fetch response wrapped in a promise
 * @see https://github.com/github/fetch#usage
 * @example
 * fetchAuthJSON(path, {
 *   method: 'PUT',
 *   body: JSON.stringify({ values }),
 * }).then(jsonResponseBody => console.log(jsonResponseBody));
 */
export function fetchAuthJSON(path, options = {}) {
  function _mergeHeaders(headers) {
    return mergeHeadersIntoOptions(options, headers);
  }
  function _fetchJSON(calculatedOptions) {
    return fetchJSON(path, calculatedOptions);
  }
  return getAuthHeaders().then(_mergeHeaders).then(_fetchJSON);
}

/**
 *
 * Determine if should fetch data.
 * This pattern is mainly adapted from redux documentation
 *
 * This is an opinionated way to setup your initial state for your particular reducer;
 *  @see: http://redux.js.org/docs/advanced/AsyncActions.html#designing-the-state-shape
 *
 * @param {Object} reducerState
 * @param {string} reducerItemsName
 * @param {Number} validForMinutes - If supplied, performs a time validation
 * @returns {*}
 */
export function shouldFetch(reducerState, reducerItemsName, validForMinutes) {
  const reducerItems = get(reducerState, reducerItemsName, null);
  const isFetching = get(reducerState, 'isFetching', false);
  const didInvalidate = get(reducerState, 'didInvalidate', false);
  if (isNil(reducerItems) || !isFetching) {
    return true;
  }

  if (isFetching) {
    return false;
  }

  // if supplied, check timespan
  if (validForMinutes) {
    const timestamp = get(reducerState, `${reducerItemsName}Timestamp`, null);
    if (!isNil(timestamp) && isDate(timestamp)) {
      // if timestamp + minutes - valid until
      const validUntil = addMinutes(timestamp, validForMinutes);

      /* check if the validUntil time is before the current datetime
       * if first date before the second = -1 */
      if (compareAsc(validUntil, new Date()) < 0) {
        return true;
      }
    }
  }

  return didInvalidate;
}

/**
 *
 * Fetch data if it's determined that you need to.
 *
 * Return a Promise by default so that other dependencies maybe able to continue
 * chaining.
 *
 * @param {string} reducerName
 * @param {string} reducerItemsName
 * @param {function} actionFetchFn
 * @param {Number} validForMinutes - If supplied, performs a time validation (name is ${reducerItemsName}Timestamp)
 * @returns {function(*, *)}
 */
export function fetchIfNeeded(reducerName, reducerItemsName, actionFetchFn, validForMinutes) {
  const isImpersonatedUser = isNil(currentImpersonatedUser);
  return (dispatch, getState) => {
    const reducerState = get(getState(), reducerName);
    if (shouldFetch(reducerState, reducerItemsName, validForMinutes) || !isImpersonatedUser) {
      return dispatch(actionFetchFn());
    }
    return new Promise(res => res(reducerState));
  };
}

/**
 * Sanitize an object in order to ensure no disallowed fields are sent to the API
 *
 * To use, first pass an array of strings to represent all allowed properties.
 * Function will return a closure which can be passed the object which must be
 * sanitized. Null values will be excluded however empty strings will not
 * @param {array} allowedFields list of allowed properties
 * @return {function(*=)} accepts objects to be sanitized
 * @example
 * const sanitize = prepareData(['name', 'description']);
 * dispatch(doAPISubmission(sanitize(data)))
 */
export function prepareData(allowedFields) {
  return data => {
    return pickBy(pick(data, allowedFields), value => !!value || value === '');
  };
}
