import {ISSUES} from './issues';

export const ENVIRONMENTAL = {
  key: 'ENVIRONMENTAL',
  title: 'Environmental',
  issue: ISSUES.ENVIRONMENTAL,
};

export const ENVIRONMENT = {
  key: 'ENVIRONMENT',
  title: 'Environment',
  issue: ISSUES.ENVIRONMENT,
};

export const CARBON_EMISSION = {
  key: 'CARBON_EMISSION',
  title: 'Carbon Emission',
  issue: ISSUES.CARBON_EMISSIONS,
};

export const ENERGY_USE = {
  key: 'ENERGY_USE',
  title: 'Energy Use',
  issue: ISSUES.ENERGY_USE,
};

export const WATER_CONSUMPTION = {
  key: 'WATER_CONSUMPTION',
  title: 'Water Consumption',
  issue: ISSUES.WATER_CONSUMPTION,
};

export const ENVIRONMENTAL_POLICIES = {
  key: 'ENVIRONMENTAL_POLICIES',
  title: 'Environmental Policies',
  issue: ISSUES.ENVIRONMENTAL_POLICIES,
};

export const CLIMATE_CHANGE_POLICY = {
  key: 'CLIMATE_CHANGE_POLICY',
  title: 'Climate Change Policy',
  issue: ISSUES.CLIMATE_CHANGE_POLICY,
};

export const ENIRONMENTAL_RISK_MANAGEMENT = {
  key: 'ENIRONMENTAL_RISK_MANAGEMENT',
  title: 'Environmental Risk Management',
  issue: ISSUES.ENVIRONMENTAL_RISK_POLICY,
};

export const ENVIRONMENTAL_INCENTIVES_POLICY = {
  key: 'ENVIRONMENTAL_INCENTIVES_POLICY',
  title: 'Environmental Incentives Policy',
  issue: ISSUES.ENVIRONMENTAL_INCENTIVES_POLICY,
};

export const SOCIAL = {
  key: 'SOCIAL',
  title: 'Social',
  issue: ISSUES.SOCIAL,
};

export const DIVERSITY_AND_INCLUSION = {
  key: 'DIVERSITY_AND_INCLUSION',
  title: 'Diversity and Inclusion',
  issue: ISSUES.DIVERSITY_AND_INCLUSION,
};

export const GENDER_EQUALITY = {
  key: 'GENDER_EQUALITY',
  title: 'Gender Equality',
  issue: ISSUES.GENDER_EQUALITY,
};

export const WOMEN_IN_LEADERSHIP = {
  key: 'WOMEN_IN_LEADERSHIP',
  title: 'Women In Leadership',
  issue: ISSUES.WOMEN_IN_LEADERSHIP,
};

export const WOMEN_ON_BOARD = {
  key: 'WOMEN_ON_BOARD',
  title: 'Women On Board',
  issue: ISSUES.WOMEN_ON_BOARD,
};

export const ETHNIC_AND_CULTURAL_IDENTITY = {
  key: 'ETHNIC_AND_CULTURAL_IDENTITY',
  title: 'Ethnic & Cultural Identity',
  issue: ISSUES.ETHNIC_AND_CULTURAL_IDENTITY,
};

export const COMPENSATION_EQUITY = {
  key: 'COMPENSATION_EQUITY',
  title: 'Compensation Equity',
  issue: ISSUES.COMPENSATION_EQUITY,
};

export const CEO_COMPENSATION_RATION = {
  key: 'CEO_COMPENSATION_RATION',
  title: 'CEO Compensation Ratio',
  issue: ISSUES.CEO_COMPENSATION_RATIO,
};

export const WORKFACE_WELL_BEING = {
  key: 'WORKFACE_WELL_BEING',
  title: 'Workforce Wellbeing',
  issue: ISSUES.WORKFORCE_WELLBEING,
};

export const INJURY_FREQUENCT = {
  key: 'INJURY_FREQUENCT',
  title: 'Injury Frequency',
  issue: ISSUES.INJURY_FREQUENCY,
};

export const HUMAN_RIGHTS_POLICY = {
  key: 'HUMAN_RIGHTS_POLICY',
  title: 'Human Rights Policy',
  issue: ISSUES.HUMAN_RIGHTS_POLICY,
};

export const NON_DISCRIMINATION_POLICY = {
  key: 'NON_DISCRIMINATION_POLICY',
  title: 'Non-Discrimination Policy',
  issue: ISSUES.NON_DISCRIMINATION_POLICY,
};

export const HEALTH_AND_SAFETY_POLICY = {
  key: 'HEALTH_AND_SAFETY_POLICY',
  title: 'Health & Safety Policy',
  issue: ISSUES.HEALTH_AND_SAFETY_POLICY,
};

export const CHILD_AND_FORCED_LABOR_POLICY = {
  key: 'CHILD_AND_FORCED_LABOR_POLICY',
  title: 'Child & Forced Labor Policy',
  issue: ISSUES.CHILD_AND_FORCED_LABOR_POLICY,
};

export const GOVERNANCE = {
  key: 'GOVERNANCE',
  title: 'Governance',
  issue: ISSUES.GOVERNANCE,
};

export const BOARD_GOVERNANCE = {
  key: 'BOARD_GOVERNANCE',
  title: 'Board Governance',
  issue: ISSUES.BOARD_GOVERNANCE,
};

export const BOARD_INDEPENDANCE = {
  key: 'BOARD_INDEPENDANCE',
  title: 'Board Independance',
  issue: ISSUES.BOARD_INDEPENDENCE,
};

export const SEPERATION_OF_POWERS_POLICY = {
  key: 'SEPERATION_OF_POWERS_POLICY',
  title: 'Seperation Of Powers Policy',
  issue: ISSUES.SEPARATION_OF_POWERS_POLICY,
};

export const CORPORATE_TRUST = {
  key: 'CORPORATE_TRUST',
  title: 'Corporate Trust',
  issue: ISSUES.CORPORATE_TRUST,
};

export const CODE_OF_CONDUCT_POLICY = {
  key: 'CODE_OF_CONDUCT_POLICY',
  title: 'Code Of Conduct Policy',
  issue: ISSUES.CODE_OF_CONDUCT_POLICY,
};

export const ETHICS_AND_ANTICORRUPTION_POLICY = {
  key: 'ETHICS_AND_ANTICORRUPTION_POLICY',
  title: 'Ethics & Anti-Corruption Policy',
  issue: ISSUES.ETHICS_AND_ANTI_CORRUPTION_POLICY,
};

export const DATA_PRIVACY_POLICY = {
  key: 'DATA_PRIVACY_POLICY',
  title: 'Data Privacy Policy',
  issue: ISSUES.DATA_PRIVACY_POLICY,
};
