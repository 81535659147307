import {SelectOption} from 'v2/utilities/types/components/Select';

export const holdingOptions: SelectOption[] = [
  {label: 'Most Held', value: 'most_held'},
  {label: 'Leaders', value: 'esg_leaders'},
  {label: 'Laggards', value: 'esg_laggards'},
];

export const distributionOptions = [
  {label: 'Weight', value: 'weight'},
  {label: 'Count', value: 'count'},
];
