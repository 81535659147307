import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import {Link} from 'react-router-dom';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';

// Local Imports
import {REGEX_PATTERN, ROUTE} from 'app/constants';
import {errorRequiredFields, normalizeEmail} from 'app/utilities';
import {Alert, Button, FormFeedback} from '.';
import {TextFormGroup} from './forms';

const validate = values => {
  const errors = errorRequiredFields(values, ['email']);

  if (!errors.email && !REGEX_PATTERN.VALID_EMAIL.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

class ForgotPasswordFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);
    const isSuccess = !this.props.error && !this.props.submitting && this.props.submitSucceeded;

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        {!(isSuccess || this.props.error) && (
          <p>
            Enter the email address on the account and submit the form. You will receive an email with further
            instructions.
          </p>
        )}
        {isSuccess && (
          <Alert color="success">
            If your email address was found in our system, an email will be sent with your reset password instructions.
            You can resend the Forgot Password email by using the form below.
          </Alert>
        )}
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <Field
          label="Email Address*"
          name="email"
          type="text"
          autoComplete="email"
          component={TextFormGroup}
          normalize={normalizeEmail}
          className="col-6"
        />
        <div className="form-footer justify-content-between">
          <Link to={ROUTE.LOGIN.path()} className="btn btn-link pl-0 text-muted">
            <Icon icon={faArrowLeft} className="btn-icon-before" /> Return to {ROUTE.LOGIN.title}
          </Link>
          <Button type="submit">Send Email</Button>
        </div>
      </form>
    );
  }
}

ForgotPasswordFormComponent.propTypes = {handleSubmit: PropTypes.func};

const mapStateToProps = (state, ownProps) => {
  return {form: ownProps.formName};
};

const mapDispatchToProps = dispatch => ({dispatch});

export const ForgotPasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({validate})(ForgotPasswordFormComponent));
