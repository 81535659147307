import React, {Component, Fragment} from 'react';
import {get, find, isEmpty, map, filter} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {fetchMarketIndexesIfNeeded} from 'app/redux/marketIndex';
import {Alert, SettingsFirmManagerEditForm} from 'app/components';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {getByIdForSettingsFirm, updateFromSettingsFirm} from 'app/redux/firm';
import {toastSuccess} from 'app/utilities/toast';
import {withUser} from 'app/utilities';

class SettingsFirmManagerEditPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchMarketIndexesIfNeeded());
  }

  componentDidMount() {
    if (this.props.firmId) {
      this.props.dispatch(getByIdForSettingsFirm(this.props.firmId));
    }
  }

  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="settings-form-lg">
        <h1>{ROUTE.SETTINGS_FIRMS_EDIT.title}</h1>
        {this.props.hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT}</Alert>}
        {
          /* If new user or have an existing user to edit */
          (!this.props.firmId || this.props.editFirm) && this.props.marketIndexes && (
            <Fragment>
              <SettingsFirmManagerEditForm {...this.props} />
            </Fragment>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: {firmId},
    },
  } = ownProps;

  const isFetching = get(state, 'firm.isFetching');

  let editFirm = !isFetching && firmId ? get(state, 'firm.editFirm') : null;

  const editFirmId = get(editFirm, 'id', '');
  if (firmId !== editFirmId.toString()) {
    editFirm = null;
  }

  return {
    firmId,
    hasError: get(state, 'user.hasError'),
    isFetching,
    marketIndexes: get(state, 'marketIndex.all'),
    editFirm,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    isUserType1,
    match: {
      params: {firmId},
    },
  } = ownProps;

  const {dispatch} = dispatchProps;
  const {marketIndexes} = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const managers = get(values, 'investmentManagersToInvite');
      const filteredManagers = filter(managers, m => m.email);
      const investmentManagersToInvite = map(filteredManagers, manager => {
        const marketIndex = find(marketIndexes, {name: manager.marketIndex});

        return {
          ...manager,
          marketIndexId: isEmpty(marketIndex) ? null : marketIndex.id,
        };
      });

      const updateData = {
        ...values,
        investmentManagersToInvite,
      };

      return dispatch(updateFromSettingsFirm(firmId, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          // Open modal suggesting Harmony Index for new Passive Strategies
          const hasNewPassiveStrategies = !!get(response, 'payload.newPassiveStrategyIds', []).length;
          if (hasNewPassiveStrategies) {
            dispatch(
              openSimpleModal({
                title: 'Passive Strategy',
                message: FORM_MESSAGE.NEW_PASSIVE_STRATEGIES(isUserType1),
                buttons: [
                  {
                    label: 'Close',
                    close: true,
                  },
                  {
                    className: 'ml-auto',
                    label: 'Go to Index Center',
                    path: ROUTE.HARMONY_INDEX_CENTER.path(),
                    close: true,
                  },
                ],
              }),
            );
          }

          toastSuccess('Firm and/or Strategy has been updated.');

          dispatch(push(ROUTE.SETTINGS_FIRMS_MANAGER.path()));
        })
        .catch(err => {
          const message = err.message || FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT;

          animateScroll.scrollToTop();

          throw new SubmissionError({_error: message});
        });
    },
    dispatch,
  };
};

export const SettingsFirmManagerEdit = compose(
  withUser,
  connect(mapStateToProps, null, mergeProps),
)(SettingsFirmManagerEditPage);
