import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError, change} from 'redux-form';

// Local Imports
import {AdminEmployeeForm} from 'app/components';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {getByIdUser} from 'app/redux/user';
import {getEditAdminPermissions, updateAdminPermissions} from 'app/redux/adminPermissions';
import {toastSuccess} from 'app/utilities/toast';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';
import {refreshAuthTokens} from 'app/utilities/tokenFunctions';

const formName = 'admin-edit-employee';

/**
 * Formats the user data, retrieved from the API, into the format needed by the edit form
 * @returns {Object} An object formatted for the admin user form
 */

class AdminEditEmployeePage extends Component {
  constructor(props) {
    super(props);
    this.state = {permissions: null};
  }

  componentWillMount() {
    const {
      match: {
        params: {userId},
      },
      permissions,
    } = this.props;

    // pass in admin so it knows to setup response for admin edit
    this.props
      .dispatch(getByIdUser(userId, 'admin'))
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }

        this.props.dispatch(getEditAdminPermissions(userId)).then(response => {
          if (!response.hasError) {
            let permissions = get(response, 'payload.adminPermissions');
            this.setState({
              permissions: permissions,
            });
          }
        });

        animateScroll.scrollToTop();
      })
      .catch(() => {
        animateScroll.scrollToTop();
      });
  }

  render() {
    const {
      match: {
        params: {userId},
      },
    } = this.props;
    return (
      <div className="p-content-lg">
        <Link
          className="d-inline-block mb-4 small"
          to={{
            pathname: ROUTE.ADMIN_EMPLOYEES.path(),
            search: this.props.location.search,
          }}
        >
          <Icon icon={faArrowLeft} className="mr-2" />
          Return to {ROUTE.ADMIN_EMPLOYEES.title}
        </Link>
        <h1 className="mb-2">{userId ? ROUTE.ADMIN_USERS_EDIT.title : ROUTE.ADMIN_USERS_ADD.title}</h1>
        {
          // this.props.adminPermissions.hasError
          //   ? <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>
          //   : (
          this.state.permissions && (
            <AdminEmployeeForm
              {...this.props}
              formName={formName}
              userData={this.state.permissions}
              canWrite={this.props.canWrite}
            />
          )
          // )
        }
      </div>
    );
  }
}

AdminEditEmployeePage.propTypes = {match: PropTypes.object.isRequired};

const mapStateToProps = state => {
  const hasError = get(state, 'adminPermissions.hasError');
  const permissions = get(state, 'auth.adminPermission') || get(state, 'user.hasGetUserError');
  return {
    hasError,
    planSponsors: get(state, 'planSponsor.planSponsors'),
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_ADMIN_PERMISSIONS),
    permissions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: {userId},
    },
  } = ownProps;
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const editedUser = values.userId;
      const currentUser = ownProps.authUser.id;
      return dispatch(updateAdminPermissions(userId, values))
        .then(response => {
          if (response.hasError) {
            console.error({response});
            throw response.error;
          } else {
            if (editedUser === currentUser) {
              refreshAuthTokens();
            }
            toastSuccess('The user permissions have been updated.');
          }

          animateScroll.scrollToTop();

          dispatch(reset(formName));

          dispatch(
            push({
              pathname: ROUTE.ADMIN_EMPLOYEES.path(),
              search: ownProps.location.search,
            }),
          );
        })
        .catch(error => {
          animateScroll.scrollToTop();
          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    changeFieldValue: (field, value) => {
      dispatch(change(formName, field, value));
    },
    dispatch,
  };
};

export const AdminEditEmployee = connect(mapStateToProps, mapDispatchToProps)(AdminEditEmployeePage);
