/* eslint-disable no-unused-vars */
import React from 'react';
import {find, get, isEmpty, map, merge, pick, orderBy, some} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {branch, renderComponent} from 'recompose';
import {Redirect} from 'react-router-dom';

import {ONBOARDING_STEPS, ROUTE, ROLE} from 'app/constants';
import {getUserType4ByCode} from 'app/redux/onboardingUser';

const mapStateToProps = (state, ownProps) => {
  const {invitationCode} = ownProps.match.params;
  const onboardingCompany = get(state, 'onboardingUser.company', {});

  return {
    invitationCode,
    error: get(state, 'onboardingUser.error'),
    hasInvitationCodeError: get(state, 'onboardingUser.hasError'),
    isFetching: get(state, 'onboardingUser.isFetching'),
    onboardingCompany,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  const {error, hasInvitationCodeError, invitationCode, isFetching, onboardingCompany} = stateProps;

  const hasUnexpectedError = hasInvitationCodeError;

  // If redux store is empty, go fetch the onboarding info, otherwise determine if redirects are needed
  if (isEmpty(onboardingCompany) && !hasInvitationCodeError && !isFetching) {
    dispatch(getUserType4ByCode(invitationCode));
  }

  return Object.assign({}, stateProps, dispatchProps, ownProps, {hasUnexpectedError});
};

export const withOnboardingUserType4 = compose(
  // First check for the user in state
  connect(mapStateToProps, null, mergeProps),
);
