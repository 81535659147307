import {Theme} from '../theme';

export const EthicsAntiIcon = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2593_10603)">
        <path d="M22.995 12.525L19.9477 5.40135C20.0079 5.24008 20.0426 5.06623 20.0426 4.88437C20.0426 4.06735 19.38 3.40549 18.5633 3.40549C18.0689 3.40549 17.6327 3.64873 17.3643 4.0216H13.9536C13.617 3.22554 12.8278 2.66663 11.909 2.66663C10.9898 2.66663 10.2014 3.22554 9.86509 4.02236H6.45441C6.18562 3.64949 5.74909 3.40625 5.25461 3.40625C4.43797 3.40625 3.77611 4.06811 3.77611 4.88513C3.77611 5.06699 3.81081 5.24046 3.87104 5.40211L0.823697 12.525H0.818359C0.818359 14.8385 2.80506 16.715 5.25499 16.715C7.70568 16.715 9.69124 14.8385 9.69124 12.525H9.6859L6.78686 5.74752H9.86509C10.0347 6.1486 10.3192 6.48944 10.6768 6.72887V17.7009H6.24052V18.6868C6.24052 20.0426 7.34959 21.1516 8.70532 21.1516H15.1134C16.4691 21.1516 17.5782 20.0426 17.5782 18.6868V17.7009H13.1416V6.72848C13.4992 6.48868 13.7836 6.14822 13.9533 5.74752H17.0311L14.1279 12.525H14.1275V12.5254L14.126 12.5289L14.1279 12.5296C14.1305 14.8411 16.1157 16.715 18.5641 16.715C21.0148 16.715 23.0004 14.8385 23.0004 12.525C23.0004 12.5254 22.995 12.5254 22.995 12.525ZM8.34542 12.525H2.16418L4.82684 6.29996C4.96219 6.34075 5.10554 6.36324 5.25423 6.36324C5.40291 6.36324 5.54703 6.34075 5.68275 6.29958L8.34542 12.525ZM15.468 12.525L18.1348 6.29996C18.2705 6.34113 18.4143 6.36363 18.5633 6.36363C18.7124 6.36363 18.8561 6.34113 18.9919 6.29996L21.6549 12.525H15.468Z" />
      </g>
      <defs>
        <clipPath id="clip0_2593_10603">
          <rect width="22.182" height="22.182" fill="white" transform="translate(0.818359 0.817993)" />
        </clipPath>
      </defs>
    </svg>
  );
};
