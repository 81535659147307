import React from 'react';
import {Link} from 'react-router-dom';

import {ROUTE} from 'app/constants';
import {LogoGlyph} from 'app/components/LogoGlyph';

const Footer = () => (
  <footer className="footer">
    <ul className="footer-menu">
      <li>&copy; {new Date().getFullYear()} Harmony Analytics</li>
      <li>
        <a href={ROUTE.TERMS.path()} target="_blank" rel="noopener noreferrer">
          {ROUTE.TERMS.title}
        </a>
      </li>
      <li>
        <a href="https://www.harmonyanalytics.org" target="_blank" rel="noopener noreferrer">
          HarmonyAnalytics.org
        </a>
      </li>
      <li>
        <a href={'/changelog'} rel="noopener noreferrer">
          {process.env.REACT_APP_VERSION || '0.1.0'}
        </a>
      </li>
    </ul>
    <div className="footer-logo">
      <LogoGlyph />
    </div>
  </footer>
);

export {Footer};
