import {ROUTE as OLD_ROUTE} from 'app/constants';
import {ROUTE} from './routes';

export const userType1MenuProps = [
  OLD_ROUTE.SETTINGS_USER_PROFILE,
  OLD_ROUTE.SETTINGS_USERS,
  OLD_ROUTE.SETTINGS_PLAN_PROFILE,
  OLD_ROUTE.HARMONY_INDEX_CENTER,
  OLD_ROUTE.SETTINGS_FIRMS_MANAGER,
];

export const userType3MenuProps = [ROUTE.SETTINGS.USER_PROFILE, ROUTE.SETTINGS.MANAGER_PROFILE];
