import React, {Component} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
// Local Imports
import {ROUTE} from 'app/constants';
import {APreventDefault} from 'app/components';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import {logout} from 'app/redux/auth';

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.constants.border_radius};
  margin: 6rem auto;
  padding: 3rem 3.5rem;
  max-width: ${props => props.theme.constants.fixWidth};
`;

export const NotAuthorizedPage: React.FC<{isAdmin?: boolean}> = ({isAdmin = false}) => {
  const dispatch = useDispatch();

  if (isAdmin) {
    return (
      <Container>
        <h1>
          <Icon
            // @ts-ignore
            icon={faExclamationTriangle}
          />{' '}
          You are currently logged in as an Admin
        </h1>
        <span>
          <p>You can only access this page by being logged in as an end user.</p>
          <p>
            Please {/* @ts-ignore */}
            <APreventDefault onClick={() => dispatch(logout())}>log out</APreventDefault> and log back in with an
            appropriate account. If one does not exist, it is recommended that you create one in the{' '}
            <Link to={ROUTE.ADMIN_USERS_ADD.path()}>user manager</Link>.
          </p>
        </span>
      </Container>
    );
  }

  return (
    <Container>
      <h1>
        <Icon
          // @ts-ignore
          icon={faExclamationTriangle}
        />
        You are not authorized to view this page
      </h1>
      <span>
        <p>Unfortunately you are not authorized to view that page.</p>
        <p>
          You can use the <Link to={ROUTE.SITE_SEARCH.path()}>site search</Link> to find what you are looking for, or
          you can return to your <Link to={ROUTE.DASHBOARD.path()}>dashboard</Link> to start a new task.
        </p>
      </span>
    </Container>
  );
};
