/* eslint no-script-url: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import {find, map, isEmpty, includes} from 'lodash';
import {Link, NavLink} from 'react-router-dom';
import cn from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';

import {PORTFOLIO_MANAGEMENT_TYPE, ROUTE, ROLE} from 'app/constants';
import {isImpersonating, isAdmin, getSessionJwt} from 'app/utilities';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {APreventDefault, HeaderSearch, LogoGlyph} from 'app/components';
import faAngleDown from 'app/fontawesome-pro-light/faAngleDown';
import faBullhorn from 'app/fontawesome-pro-light/faBullhorn';
import {getAdminPrimaryMenuRoutes} from 'app/utilities';

import {NavigationBar} from 'v2/components/organisms/NavigationBar';
import {ROUTE as V2_ROUTES} from 'v2/constants/routes';
const getStrategyLabel = ({planSponsor, strategyName}) => `${planSponsor} : ${strategyName}`;

const renderMenuItems = items =>
  map(items, ({path, menuTitle, title, submenu}, index) => (
    <li key={index}>
      <NavLink exact to={path()}>
        {menuTitle || title}
      </NavLink>
      {submenu && submenu.items && (
        <ul className={cn(`is-${submenu.align}`, `has-${submenu.items.length}`)}>{renderMenuItems(submenu.items)}</ul>
      )}
    </li>
  ));

const renderStrategyLink = (strategy, route, render) => {
  if (isEmpty(strategy)) {
    return null;
  }

  // default to dashboard link if no route specified
  let path = ROUTE.STRATEGY_DASHBOARD.path(strategy.strategyId);
  let title = getStrategyLabel(strategy);

  // use a specific route given
  if (route) {
    path = route.path(strategy.strategyId);
    ({title} = route);
  }

  // Set the default Link element
  let LinkElement = NavLink;
  let linkProps = {exact: true};

  // replacements for onboarding or pay-wall, based on acct status
  if (strategy.incompleteInviteCode) {
    LinkElement = Link;
    linkProps = null;
    // path = ROUTE.ONBOARDING_LANDING.path(strategy.incompleteInviteCode);
    path = ROUTE.PAYWALL.path(strategy.strategyId);
  } else if (!strategy.licensedDate) {
    LinkElement = Link;
    linkProps = null;
    path = ROUTE.PAYWALL.path(strategy.strategyId);
  } else if (route === ROUTE.STRATEGY_COMPANIES) {
    linkProps = null;
  }

  // render the link, using a custom function if given
  return render ? (
    render(path, title)
  ) : (
    <LinkElement {...linkProps} to={path}>
      {title}
    </LinkElement>
  );
};

const HeaderDisplay = ({
  currentStrategyId,
  showSearch,
  isStrategyMenuVisible,
  logoRoute,
  primaryMenuRoutes,
  secondaryMenuRoutes,
  showNotifications,
  showLogout,
  notificationCount,
  onLogoutClick,
  strategies,
}) => {
  const currentStrategy = find(strategies, {strategyId: currentStrategyId});
  const state = useSelector(state => ({
    isAdmin: includes(state.auth.roles, ROLE.ADMIN),
    adminPermissions: state.auth.adminPermission,
    impersonationEmail: state?.auth?.impersonatedUser?.email,
  }));

  const userType = getSessionJwt(true)?.contents?.roles;

  if (userType && userType.includes(ROLE.USER_TYPE_1)) {
    const dispatch = useDispatch();

    return (
      <NavigationBar
        navItems={primaryMenuRoutes.map(({path, title}) => ({
          path: path(),
          label: title,
        }))}
        menuOptions={[
          {label: 'Alerts', path: '/alerts'},
          isImpersonating()
            ? {label: 'Stop Impersonating', path: '#', onClick: () => dispatch(removeImpersonationTokensUser())}
            : {label: 'Logout', path: '#', onClick: () => dispatch(logout())},
        ]}
        settingsPath={V2_ROUTES.SETTINGS.path}
      />
    );
  } else {
    return (
      <header className={cn('header', {'has-secondary': isStrategyMenuVisible})}>
        <div className={cn('header-primary', {'has-search': showSearch})}>
          <Link to={logoRoute.path()} className="header-logo">
            <LogoGlyph />
          </Link>
          {primaryMenuRoutes && (
            <ul className="header-menu is-primary">
              {renderMenuItems(
                isAdmin() && !isImpersonating() ? getAdminPrimaryMenuRoutes(state.adminPermissions) : primaryMenuRoutes,
              )}
            </ul>
          )}
          <ul className="header-menu">
            {secondaryMenuRoutes && renderMenuItems(secondaryMenuRoutes)}
            {isImpersonating() ? (
              <li>
                <APreventDefault
                  title={state.impersonationEmail ?? ''}
                  style={{textAlign: 'center'}}
                  onClick={onLogoutClick}
                >
                  Stop Impersonating Account
                </APreventDefault>
              </li>
            ) : (
              showLogout && (
                <li>
                  <APreventDefault onClick={onLogoutClick}>Log out</APreventDefault>
                </li>
              )
            )}
            {showNotifications && (
              <li>
                <Link to={ROUTE.ALERTS.path()} className="icon has-badge">
                  <Icon icon={faBullhorn} />
                  <span className="icon-badge" style={{opacity: Math.min(notificationCount, 1)}}>
                    {notificationCount}
                  </span>
                </Link>
              </li>
            )}
          </ul>
          {showSearch && <HeaderSearch />}
        </div>
        {isStrategyMenuVisible && !isEmpty(strategies) && (
          <div className="header-secondary">
            <ul className="header-menu">
              <li className="header-plan-selector">
                <div className="header-plan-selector-active">
                  {!isEmpty(currentStrategy) && getStrategyLabel(currentStrategy)}
                  <Icon icon={faAngleDown} className="ml-2" />
                </div>
                <ul className={cn('is-left', `has-${strategies.length}`)}>
                  {map(strategies, strategy => {
                    return <li key={strategy.strategyId}>{renderStrategyLink(strategy)}</li>;
                  })}
                </ul>
              </li>
              <li>{renderStrategyLink(currentStrategy, ROUTE.STRATEGY_DASHBOARD)}</li>
              {currentStrategy?.portfolioManagementType === PORTFOLIO_MANAGEMENT_TYPE.ACTIVE && (
                <li>{renderStrategyLink(currentStrategy, ROUTE.STRATEGY_COMPANIES)}</li>
              )}
              <li>{renderStrategyLink(currentStrategy, ROUTE.STRATEGY_COMPLIANCE_MANAGER)}</li>
              <li>
                {renderStrategyLink(currentStrategy, ROUTE.STRATEGY_ALERTS, path => (
                  <Link to={path} className="icon has-badge">
                    <Icon icon={faBullhorn} />
                    <span className="icon-badge" style={{opacity: Math.min(notificationCount, 1)}}>
                      {notificationCount}
                    </span>
                  </Link>
                ))}
              </li>
            </ul>
          </div>
        )}
      </header>
    );
  }
};

const routeShape = {
  path: PropTypes.func.isRequired,
  title: PropTypes.string,
};

HeaderDisplay.propTypes = {
  currentStrategyId: PropTypes.number,
  showSearch: PropTypes.bool,
  isStrategyMenuVisible: PropTypes.bool,
  logoRoute: PropTypes.shape(routeShape),
  primaryMenuRoutes: PropTypes.arrayOf(PropTypes.shape(routeShape)),
  secondaryMenuRoutes: PropTypes.arrayOf(PropTypes.shape(routeShape)),
  showNotifications: PropTypes.bool,
  showLogout: PropTypes.bool,
  notificationCount: PropTypes.number,
  onLogoutClick: PropTypes.func.isRequired,
  strategies: PropTypes.arrayOf(
    PropTypes.shape({
      licensedDate: PropTypes.string,
      strategyId: PropTypes.number,
      planSponsor: PropTypes.string,
      strategyName: PropTypes.string,
      incompleteInviteCode: PropTypes.string,
    }),
  ),
};

export {HeaderDisplay};
