import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, isEmpty} from 'lodash';
import React from 'react';

// Local Imports
import {FORM_MESSAGE} from 'app/constants';
import {APreventDefault} from 'app/components';
import faCheckCircle from 'app/fontawesome-pro-light/faCheckCircle';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import {formatUtcDate} from './index';

/**
 * Parses an s3 folder/file path.
 * @param {String} filePath - The folder and file path of the file in the s3 bucket
 * @returns {String}
 */
const parseS3File = filePath => {
  // get last / for folder
  let pos = filePath.lastIndexOf('/');
  // get first _ after last / (UUID_ORIGINALFILENAME)
  pos = filePath.indexOf('_', pos);

  if (pos >= 0) {
    return filePath.substring(pos + 1);
  }

  return filePath;
};

/**
 * Converts a filepath into an array of objects used by MultiUploader and related components
 * @param {DATE} lastUploaded - When the last upload occured
 * @param {String} s3FileName - The upload frequency constant (UPLOAD_FREQUENCY)
 * @param {String} actualFileName - The actual filename to display.  It is generated if not supplied.
 * @returns {Array}
 */
const setupFileInfoIntoArray = (s3FileName, actualFileName) => {
  if (!s3FileName) {
    return null;
  }

  return [
    {
      name: actualFileName || parseS3File(s3FileName),
      fileKey: s3FileName,
    },
  ];
};

/**
 * Returns null or the fileKey if there is an object supplied
 * @param {Array} files - The array list of custom file objects
 * @returns {Array}
 */
const retrieveFirstFileKeyFromArray = files => {
  if (isEmpty(files)) {
    return null;
  }

  return files[0].fileKey;
};

/**
 * Returns null or the fileKey if there is an object supplied
 * @param {Array} files - The array list of custom file objects
 * @param {bool} shouldHaveFile - If false, no filename is returned
 * @returns {Array}
 */
const retrieveFirstFileKeyIfTrueFromArray = (files, shouldHaveFile) => {
  if (!shouldHaveFile) {
    return null;
  }

  return retrieveFirstFileKeyFromArray(files);
};

const validateUploadedHarmonyData = (createStagingUpload, onMoreInfoModal) => (fileInfo, s3file, multiUploader) => {
  createStagingUpload(s3file.fileKey)
    .then(response => {
      if (response.hasError) {
        throw response.error;
      }

      const {invalidRecords, statistics} = response.payload;
      const meta = isEmpty(invalidRecords) ? (
        <APreventDefault
          onClick={() => onMoreInfoModal('Upload Complete', FORM_MESSAGE.UPLOAD_HARMONY_DATA_COMPLETE(statistics))}
        >
          Validation Complete <Icon icon={faCheckCircle} className="ml-1" />
        </APreventDefault>
      ) : (
        <APreventDefault
          onClick={() =>
            onMoreInfoModal(
              'Upload Complete with Skipped Records',
              FORM_MESSAGE.UPLOAD_HARMONY_DATA_COMPLETE_WITH_SKIPPED_RECORDS(invalidRecords, statistics),
            )
          }
        >
          Validation Complete <Icon icon={faExclamationTriangle} className="ml-1" />
        </APreventDefault>
      );
      Object.assign(fileInfo, {meta}); // mutate
      multiUploader.forceUpdate(); // trigger render
    })
    .catch(exception => {
      if (
        exception.message === 'Invalid CSV Format' ||
        new RegExp(/The [a-zA-Z0-9_-]+ field is required/).test(exception.message)
      ) {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_ERROR(exception.message))}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      } else if (
        exception.message === 'Invalid CSV Format' ||
        new RegExp(/The [a-zA-Z0-9_-]+ field is optional but if empty requires supporting data/).test(exception.message)
      ) {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_ERROR(exception.message))}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      } else {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_UNEXPECTED_ERROR)}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      }
    });
};

const validateSecurityMarketIndexUpload =
  (createStagingUpload, onMoreInfoModal) => (fileInfo, s3file, multiUploader) => {
    createStagingUpload(s3file.fileKey)
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }

        const {invalidRecords, statistics} = response.payload;
        const meta = isEmpty(invalidRecords) ? (
          <APreventDefault
            onClick={() =>
              onMoreInfoModal('Upload Complete', FORM_MESSAGE.UPLOAD_SECURITY_MARKET_INDEX_COMPLETE(statistics))
            }
          >
            Validation Complete <Icon icon={faCheckCircle} className="ml-1" />
          </APreventDefault>
        ) : (
          <APreventDefault
            onClick={() =>
              onMoreInfoModal(
                'Upload Complete with Skipped Records',
                FORM_MESSAGE.UPLOAD_SECURITY_MARKET_INDEX_COMPLETE_WITH_SKIPPED_RECORDS(invalidRecords, statistics),
              )
            }
          >
            Validation Complete <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      })
      .catch(exception => {
        if (exception.message === 'Invalid CSV Format') {
          const meta = (
            <APreventDefault
              onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_ERROR(exception.message))}
            >
              Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
            </APreventDefault>
          );
          Object.assign(fileInfo, {meta}); // mutate
          multiUploader.forceUpdate(); // trigger render
        } else {
          const meta = (
            <APreventDefault
              onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_UNEXPECTED_ERROR)}
            >
              Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
            </APreventDefault>
          );
          Object.assign(fileInfo, {meta}); // mutate
          multiUploader.forceUpdate(); // trigger render
        }
      });
  };

const validateStrategyUpload = (createStagingPortfolio, onMoreInfoModal) => (fileInfo, s3file, multiUploader) => {
  createStagingPortfolio(s3file.fileKey)
    .then(response => {
      if (response.hasError) {
        throw response.error;
      }

      const {dateFrom, dateTo, invalidRecords} = response.payload;
      const formattedDateFrom = formatUtcDate(dateFrom, 'MMM DD, YYYY');
      const formattedDateTo = formatUtcDate(dateTo, 'MMM DD, YYYY');
      const isDateRangeValid = formattedDateFrom && formattedDateTo;
      const isValid = isEmpty(invalidRecords) && isDateRangeValid;
      const dateRange = isDateRangeValid ? `${formattedDateFrom} - ${formattedDateTo}` : 'Invalid Dates';

      const meta = isValid ? (
        <APreventDefault onClick={() => onMoreInfoModal('Upload Complete', FORM_MESSAGE.UPDATE_PORTFOLIO_COMPLETE)}>
          {dateRange} <Icon icon={faCheckCircle} className="ml-1" />
        </APreventDefault>
      ) : (
        <APreventDefault
          onClick={() =>
            onMoreInfoModal(
              'Upload Complete with Skipped Records',
              FORM_MESSAGE.UPDATE_PORTFOLIO_COMPLETE_WITH_SKIPPED_RECORDS(invalidRecords),
            )
          }
        >
          {dateRange} <Icon icon={faExclamationTriangle} className="ml-1" />
        </APreventDefault>
      );
      Object.assign(fileInfo, {meta}); // mutate
      multiUploader.forceUpdate(); // trigger render
    })
    .catch(exception => {
      // TODO Abstract into reusable code
      if (exception.message === 'Invalid CSV Format') {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_ERROR(exception.message))}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      } else {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_UNEXPECTED_ERROR)}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      }
    });
};

const validateStrategyTrialUpload = (createStagingPortfolio, onMoreInfoModal) => (fileInfo, s3file, multiUploader) => {
  createStagingPortfolio(s3file.fileKey)
    .then(response => {
      const {invalidRecords, duplicateRecords, statistics} = response.payload;
      const meta =
        isEmpty(invalidRecords) && isEmpty(duplicateRecords) && !get(statistics, 'isMaxRecordsExceeded') ? (
          <APreventDefault
            onClick={() => {
              onMoreInfoModal('Upload Complete', FORM_MESSAGE.UPLOAD_COMPLETE(statistics));
            }}
          >
            Validation Complete <Icon icon={faCheckCircle} className="ml-1" />
          </APreventDefault>
        ) : (
          <APreventDefault
            onClick={() =>
              onMoreInfoModal(
                'Upload Complete with issues',
                FORM_MESSAGE.UPLOAD_COMPLETE_WITH_ISSUES(invalidRecords, duplicateRecords, statistics),
              )
            }
          >
            Validation Complete <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
      Object.assign(fileInfo, {meta}); // mutate
      multiUploader.forceUpdate(); // trigger render
    })
    .catch(exception => {
      // TODO Abstract into reusable code
      if (exception.message === 'Invalid CSV Format') {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_ERROR(exception.message))}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      } else {
        const meta = (
          <APreventDefault
            onClick={() => onMoreInfoModal('The upload has failed', FORM_MESSAGE.UPLOAD_UNEXPECTED_ERROR)}
          >
            Upload Failed <Icon icon={faExclamationTriangle} className="ml-1" />
          </APreventDefault>
        );
        Object.assign(fileInfo, {meta}); // mutate
        multiUploader.forceUpdate(); // trigger render
      }
    });
};

export {
  parseS3File,
  retrieveFirstFileKeyFromArray,
  retrieveFirstFileKeyIfTrueFromArray,
  setupFileInfoIntoArray,
  validateUploadedHarmonyData,
  validateSecurityMarketIndexUpload,
  validateStrategyUpload,
  validateStrategyTrialUpload,
};
