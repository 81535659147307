import styled from 'styled-components/macro';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {TextBlock, RectShape} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';
import {Container, AttachedWrapper, FlexContainer} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {ESGIssuePannel, OverallESGPanel} from 'v2/components/molecules/ESGDisplays';
import {ParagraphS, SubtitleM} from 'v2/components/atoms/Typeface';
import {esgPercentileRankDescription} from 'v2/constants/esgPageDescriptions';
import ESGChartExample from '../../../assets/images/ESG_Risk_chart.svg';
import {LinkButton} from 'v2/components/molecules/Button';
import {ROUTE} from 'v2/constants/routes';
import {usePrefetchPlanSponsorDashboard} from 'v2/hooks/prefetching/PrefetchPlanSponsorDashboard';
import {useESGRisk} from 'v2/hooks/useESGRisk';
import {formatOrdinalValue} from 'v2/utilities/helpers/formatOrdinalValue';

const PercentileRankeTitle = styled(SubtitleM)`
  display: block;
  text-transform: uppercase;
  padding-bottom: 1.0625rem;
`;

const ESGRow = styled(Container)`
  border-bottom: 1px solid #d0d6de;
  &:last-of-type {
    border-bottom: none;
  }
`;

const ESGChart = styled.img`
  max-width: 28.375rem;
  margin-bottom: 5rem;
`;

const ReactPlaceholderESGRisk = () => {
  return (
    <>
      <FlexContainer padding="2.8125rem 2.8125rem 0 4.75rem" gap="14rem">
        <FlexContainer direction="column" justify="space-between">
          <Container>
            <Container>
              <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 120}} />
            </Container>
            <Container padding="2.875rem 0 2rem">
              <RectShape color={Theme.colors.grey_2} style={{width: 250, height: 30}} />
            </Container>
            <Container padding="0">
              <TextBlock rows={4} color={Theme.colors.grey_2} style={{width: 450, height: 140}} />
            </Container>
          </Container>
          <Container padding="0 0 5rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 50}} />
          </Container>
          <Container padding="0 0 4rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 450, height: 75}} />
          </Container>
        </FlexContainer>
        <Container minWidth="321px">
          <FlexContainer direction="column">
            <ESGRow padding=".6875rem 0.5rem 3.5rem">
              <Container padding="0 0 1rem">
                <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 30}} />
              </Container>
              <RectShape color={Theme.colors.grey_2} style={{width: 250, height: 70}} />{' '}
            </ESGRow>
            <ESGRow padding="3.5rem 0.5rem">
              <Container padding="0 0 1rem">
                <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 30}} />
              </Container>
              <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 70}} />
            </ESGRow>
            <ESGRow padding="3.5rem 0.5rem">
              <Container padding="0 0 1rem">
                <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 30}} />
              </Container>
              <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 70}} />
            </ESGRow>
          </FlexContainer>
        </Container>
      </FlexContainer>
    </>
  );
};

export const UT1DashboardEsgRisk = () => {
  const {data, isLoading} = useESGRisk();
  const hasPendingTasks = useSelector((state: any) => state?.pendingTasks);

  usePrefetchPlanSponsorDashboard();

  return (
    <AttachedWrapper>
      <ReactPlaceholder ready={!hasPendingTasks && !isLoading} customPlaceholder={<ReactPlaceholderESGRisk />}>
        <FlexContainer padding="2.8125rem 2.8125rem 0 4.75rem" gap="14rem">
          <FlexContainer direction="column" justify="space-between">
            <Container>
              <Container>
                <OverallESGPanel
                  percentile={formatOrdinalValue(data.overall.percentile)}
                  benchmark={formatOrdinalValue(data.overall.universe)}
                  quintile={data.overall.quintile}
                />
              </Container>
              <Container padding="2.875rem 0 0">
                <PercentileRankeTitle>Percentile Rank</PercentileRankeTitle>
                <ParagraphS as="p">{esgPercentileRankDescription}</ParagraphS>
              </Container>
            </Container>
            <Container padding="0 0 5rem">
              <LinkButton as={Link} to={ROUTE.UT1.DATA_EXPLORER.path} width="16.9375rem">
                Data Explorer
              </LinkButton>
            </Container>
            <Container>
              <ESGChart src={ESGChartExample} />
            </Container>
          </FlexContainer>
          <Container minWidth="321px">
            <FlexContainer direction="column">
              <ESGRow padding=".6875rem 0.5rem 3.5rem">
                <ESGIssuePannel
                  title="Environmental"
                  percentile={formatOrdinalValue(data.environmental.percentile)}
                  benchmark={formatOrdinalValue(data.environmental.universe)}
                  quintile={data.environmental.quintile}
                />
              </ESGRow>
              <ESGRow padding="3.5rem 0.5rem">
                <ESGIssuePannel
                  title="Social"
                  percentile={formatOrdinalValue(data.social.percentile)}
                  benchmark={formatOrdinalValue(data.social.universe)}
                  quintile={data.social.quintile}
                />
              </ESGRow>
              <ESGRow padding="3.5rem 0.5rem">
                <ESGIssuePannel
                  title="Governance"
                  percentile={formatOrdinalValue(data.governance.percentile)}
                  benchmark={formatOrdinalValue(data.governance.universe)}
                  quintile={data.governance.quintile}
                />
              </ESGRow>
            </FlexContainer>
          </Container>
        </FlexContainer>
      </ReactPlaceholder>
    </AttachedWrapper>
  );
};
