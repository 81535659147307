import {Theme} from '../theme';

export const ArrowLeft = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="transparent" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8337 10H4.16699" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.0003 15.8327L4.16699 9.99935L10.0003 4.16602"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
