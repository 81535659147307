import React, {Component} from 'react';
import {CONFIG} from '../../constants/config';

export class BackCoverPage extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const date = new Date();
    const year = date.getFullYear();

    return (
      <div className="back-page-bg-container w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        <div className="flex-grow-1">
          <img src={`${CONFIG.ASSETS_URL}/logos/ha-logo_white.svg`} className="back-page-logo-styles" />
        </div>
        <div className="flex-grow-1">
          <p className="back-page-footer-text text-white">HARMONY ANALYTICS&#8482; &#169; {year}</p>
        </div>
        <div
          className="w-100 back-page-container"
          style={{
            backgroundImage: `url(${CONFIG.ASSETS_URL}/images/plansponsor_report-back_cover-texture.png)`,
          }}
        ></div>
      </div>
    );
  }
}
