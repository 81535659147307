import React, {Component, Fragment} from 'react';
import {get, isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ROLE, ROUTE} from 'app/constants';
import {Alert, SettingsUserManagerForm, SettingsUserManagerUT3Form} from 'app/components';
import {createFromSettingsUser, getByIdUser, updateUser} from 'app/redux/user';
import {getPlanSponsorForCurrentUsersFirm} from 'app/redux/planSponsor';
import {toastSuccess} from 'app/utilities/toast';
import {withUser} from 'app/utilities';

const generatePageTitle = (userId, editorRole) => {
  const toUse = userId ? ROUTE.SETTINGS_USERS_EDIT : ROUTE.SETTINGS_USERS_ADD;

  if (editorRole === ROLE.USER_TYPE_3 && toUse.titleUT3) {
    return toUse.titleUT3;
  }

  return toUse.title;
};

class SettingsUserManagerEditPage extends Component {
  componentDidMount() {
    if (this.props.userId) {
      this.props.dispatch(getByIdUser(this.props.userId));
    } else if (this.props.editorRole === ROLE.USER_TYPE_3) {
      this.props.dispatch(getPlanSponsorForCurrentUsersFirm());
    }
  }

  renderForm() {
    if (this.props.editorRole === ROLE.USER_TYPE_1 || this.props.editorRole === ROLE.USER_TYPE_2) {
      return <SettingsUserManagerForm {...this.props} />;
    }
    return <SettingsUserManagerUT3Form {...this.props} />;
  }

  render() {
    return (
      <div className="settings-form">
        <h1>{generatePageTitle(this.props.userId, this.props.editorRole)}</h1>
        {this.props.hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}
        {
          /* If new user or have an existing user to edit */
          (!this.props.userId || this.props.editUser) && <Fragment>{this.renderForm()}</Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: {userId},
    },
  } = ownProps;
  const editorRole = !isEmpty(get(ownProps, 'authUser.roles')) ? ownProps.authUser.roles[0] : null;
  const isUserType3 = editorRole === ROLE.USER_TYPE_3;

  const isFetching = get(state, 'user.isFetching');
  const isFetchingPlanSponsor = isUserType3 && !userId && get(state, 'planSponsor.isFetching');
  const planSponsors =
    !isFetchingPlanSponsor && isUserType3 && !userId ? get(state, 'planSponsor.planSponsorsByFirm') || [] : [];

  let editUser = !isFetching && userId ? get(state, 'user.editUser') : null;
  if (!isEmpty(editUser) && userId !== editUser.id.toString()) {
    editUser = null;
  }

  return {
    userId,
    editorRole,
    editorEmail: get(ownProps, 'authUser.email'),
    hasError: get(state, 'user.hasGetUserError'),
    isFetching: isFetching || isFetchingPlanSponsor,
    editUser,
    planSponsors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: {userId},
    },
  } = ownProps;
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const isEditing = !!userId;
      return dispatch(userId ? updateUser(userId, values) : createFromSettingsUser(values))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          if (isEditing) {
            toastSuccess('User has been updated.');
          } else {
            toastSuccess('User has been created.');
          }

          dispatch(push(ROUTE.SETTINGS_USERS.path()));
        })
        .catch(error => {
          animateScroll.scrollToTop();
          if (error.message.trim() === 'email must be unique') {
            throw new SubmissionError({
              _error: FORM_MESSAGE.DEFAULT_ERROR_SUMMARY_MESSAGE,
              email: 'That email address already exists.',
            });
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const SettingsUserManagerEdit = compose(
  withUser,
  connect(mapStateToProps, mapDispatchToProps),
)(SettingsUserManagerEditPage);
