import {ISSUE} from 'app/constants';
import {getSessionJwt} from 'app/utilities';
import {reduce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ISSUES} from 'v2/constants/issues';
import {useGetAggregatePercentileHoldingsQuery, useGetAggregatePercentileUniverseQuery} from 'v2/redux/harmonyApi';
import {updateFilterOptions} from 'v2/redux/strategyFilter';
import {DAggregateHoldingsAndUniverse} from 'v2/utilities/delegates/DAggregateHoldingsAndUniverse';
import {getData} from 'v2/utilities/helpers';
import {
  AggregatePercentileHolding,
  AggregatePercentileUniverse,
  MarketIndexBenchmark,
} from 'v2/utilities/types/typeOrm';
import {useGetAggregateMarketIndexBenchmarkQuery, useGetPlanSponsorStrategiesQuery} from 'v2/redux/typeormEndpoints';

export const useESGRisk = () => {
  const dispatch = useDispatch();

  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const holdingsQueries = [
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 1}),
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 2}),
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 3}),
  ];

  const universeQueries = [
    useGetAggregatePercentileUniverseQuery({planSponsorId, issueId: 1}),
    useGetAggregatePercentileUniverseQuery({planSponsorId, issueId: 2}),
    useGetAggregatePercentileUniverseQuery({planSponsorId, issueId: 3}),
  ];

  const strategyQueries = [useGetPlanSponsorStrategiesQuery({planSponsorId})];
  const strategies: any = reduce(strategyQueries, getData, []);
  const marketIndexes = strategies?.map((strategy: any) => strategy.marketIndexId) ?? [];

  const marketIndexQueries = [
    useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId: 1}),
    useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId: 2}),
    useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId: 3}),
  ];

  const isLoading = [...holdingsQueries, ...universeQueries, ...strategyQueries, ...marketIndexQueries]
    .map(({isLoading}) => isLoading)
    .includes(true);
  const isError = [...holdingsQueries, ...universeQueries, ...strategyQueries, ...marketIndexQueries]
    .map(({isError}) => isError)
    .includes(true);

  const holdings: AggregatePercentileHolding[] = reduce(holdingsQueries, getData, []);
  const universe: AggregatePercentileUniverse[] = reduce(universeQueries, getData, []);
  const marketIndex: MarketIndexBenchmark[] = reduce(marketIndexQueries, getData, []);

  const type = useSelector((state: any) => {
    return state?.strategyFilter?.typeValue?.value;
  });

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const delegate = new DAggregateHoldingsAndUniverse(
    holdings ?? [],
    universe ?? [],
    type ?? 'all',
    filters ?? {},
    marketIndex ?? [],
  );
  dispatch(updateFilterOptions(delegate.getAllFilters()));
  let data = {
    overall: {
      title: delegate?.getIssueName(ISSUES.GLOBAL.id) ?? DATA_STATUS.NONE,
      percentile: delegate?.getPercentile(ISSUES.GLOBAL.id),
      quintile: delegate?.getQuintile(ISSUES.GLOBAL.id),
      universe: delegate?.getBenchmarkPercentile(ISSUES.GLOBAL.id),
    },
    environmental: {
      title: delegate?.getIssueName(ISSUES.ENVIRONMENTAL.id) ?? DATA_STATUS.NONE,
      percentile: delegate?.getPercentile(ISSUES.ENVIRONMENTAL.id),
      quintile: delegate?.getQuintile(ISSUES.ENVIRONMENTAL.id),
      universe: delegate?.getBenchmarkPercentile(ISSUES.ENVIRONMENTAL.id),
    },
    social: {
      title: delegate?.getIssueName(ISSUES.SOCIAL.id) ?? DATA_STATUS.NONE,
      percentile: delegate?.getPercentile(ISSUES.SOCIAL.id),
      quintile: delegate?.getQuintile(ISSUES.SOCIAL.id),
      universe: delegate?.getBenchmarkPercentile(ISSUES.SOCIAL.id),
    },
    governance: {
      title: delegate?.getIssueName(ISSUES.GOVERNANCE.id) ?? DATA_STATUS.NONE,
      percentile: delegate?.getPercentile(ISSUES.GOVERNANCE.id),
      quintile: delegate?.getQuintile(ISSUES.GOVERNANCE.id),
      universe: delegate?.getBenchmarkPercentile(ISSUES.GOVERNANCE.id),
    },
  };

  return {
    data,
    isError,
    isLoading,
  };
};
