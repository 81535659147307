import styled from 'styled-components';
import {uniq} from 'lodash';
import {ResourceTable as ResourceTableProps} from 'v2/utilities/types/components/ResourceTable';
import {Container, FlexContainer} from '../atoms/Containers';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {TitleXS} from '../atoms/Typeface';
import {HarmonyButton} from '../molecules/Button';
import {useMemo, useState} from 'react';
import {useTable} from 'react-table';
import {ResourceRow} from '../molecules/ResourceRow';

const HeaderContainer = styled(FlexContainer).attrs({
  fullWidth: true,
  items: 'center',
  height: '5.5rem',
  wrap: 'wrap',
})`
  border-bottom: 2px solid ${({theme}) => theme.colors.grey_3};
  text-indent: 0.5rem;
  justify-content: center;

  @media screen and (max-width: 1497px) {
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem 0;
    height: auto;
  }
`;

const FilterButton = styled(HarmonyButton)`
  &&& {
    min-width: min-content;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    border: 0;
  }
`;

export const ResourceTable = ({rows = [], title, onEdit, onDelete, onDownload, ...rest}: ResourceTableProps) => {
  const [activeFilter, setActiveFilter] = useState<string>('All');

  const columns: any = useMemo(
    () => [{accessor: 'title'}, {accessor: 'updatedAt'}, {accessor: 'fileSize'}, {accessor: 'label'}],
    [],
  );

  const data = useMemo(() => {
    if (activeFilter === 'All') {
      return rows;
    }

    return rows?.filter(row => row.label === activeFilter);
  }, [activeFilter, rows]);

  const labelFilterButtons = useMemo(() => uniq(rows?.map(row => row.label)), [rows]);

  const isActive = (value: string): boolean => {
    return value === activeFilter;
  };

  const setIsActive = (value: string) => {
    if (value === 'All') {
      setActiveFilter(value);
    } else if (value === activeFilter) {
      setActiveFilter('All');
    } else {
      setActiveFilter(value);
    }
  };

  const {getTableProps, rows: tableRows, prepareRow} = useTable({columns, data});

  const handleEdit = (values: any) => {
    return onEdit?.(values);
  };

  const handleDelete = (values: any) => {
    return onDelete?.(values);
  };

  const handleDownload = (values: any) => {
    return onDownload?.(values);
  };

  return (
    <Container style={{flex: 1}}>
      <HeaderContainer>
        <FlexContainer flex={1} minWidth="17.5rem" fullWidth>
          <TitleXS>{title ?? DATA_STATUS.NONE}</TitleXS>
        </FlexContainer>
        <FlexContainer gap="1rem">
          <FilterButton variant={isActive('All') ? 'secondary' : 'light'} onClick={() => setIsActive('All')}>
            All
          </FilterButton>
          {labelFilterButtons.map((label, i) => (
            <FilterButton
              variant={isActive(label ?? '') ? 'secondary' : 'light'}
              onClick={() => setIsActive(label ?? '')}
              key={i}
            >
              {label}
            </FilterButton>
          ))}
        </FlexContainer>
      </HeaderContainer>
      <FlexContainer {...getTableProps()} direction="column" gap="1rem" padding="1.75rem 0 0" fullWidth>
        {tableRows.map(row => {
          prepareRow(row);

          return (
            <Container {...row.getRowProps()}>
              <ResourceRow
                onEdit={handleEdit}
                onDelete={handleDelete}
                onDownload={handleDownload}
                canEdit={rest.canEdit}
                {...row.original}
                {...row.values}
                {...rest}
              />
            </Container>
          );
        })}
      </FlexContainer>
    </Container>
  );
};
