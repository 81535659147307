import {filter, isEmpty, map, orderBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {PARENT_ISSUES, RATINGS} from 'app/constants';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {RadioDot} from 'app/components';

class IssueMatrixComponent extends Component {
  render() {
    const {issues, onIssueClick, title} = this.props;

    if (isEmpty(issues)) {
      return <span />;
    }

    return (
      <div className="issue-grid">
        <h2 className="h2-alt">{title}</h2>
        <div className="issue-grid-headers">
          <span>A</span>
          <span>B</span>
          <span>C</span>
          <span>D</span>
          <span>E</span>
        </div>

        <div className="issue-grid-groups">
          {map(PARENT_ISSUES, issue => {
            const childIssues = orderBy(filter(issues, {parentId: issue.id}), ['displayOrder']);
            return (
              <div className="issue-grid-group" key={issue.id}>
                <h3>{issue.name}</h3>
                {map(childIssues, childIssue => {
                  const grandchildIssues = orderBy(filter(issues, {parentId: childIssue.id}), ['displayOrder']);
                  let radioDotColor;
                  switch (RATINGS[childIssue.rating]) {
                    case 'A':
                    case 'B':
                    case 'C':
                      radioDotColor = 'green';
                      break;
                    case 'D':
                    case 'E':
                    case 'F':
                      radioDotColor = 'red';
                      break;
                    default:
                      radioDotColor = 'light';
                  }

                  return (
                    <div className="col-group" key={childIssue.id}>
                      <span className="col col-title">
                        <a href="javascript:;" onClick={() => onIssueClick(issue, childIssue, grandchildIssues)}>
                          {childIssue.name}
                        </a>
                      </span>
                      <span
                        className={'col col-rating col-rating-' + (RATINGS[childIssue.rating]?.toLowerCase() || '')}
                      >
                        <a href="javascript:;" onClick={() => onIssueClick(issue, childIssue, grandchildIssues)}>
                          <RadioDot color={radioDotColor} />
                          <span className="sr-only">Rating: {RATINGS[childIssue.rating]}</span>
                        </a>
                      </span>
                      <span className="col"></span>
                      <span className="col"></span>
                      <span className="col"></span>
                      <span className="col"></span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

IssueMatrixComponent.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onIssueClick: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onIssueClick: (parentIssue, issue, childIssues) => {
      dispatch(
        openSimpleModal({
          title: parentIssue.name,
          customClassName: 'modal-padded',
          message: (
            <div>
              <b>{issue.name}</b>
              <div>{issue.description}</div>
              <table className="mt-5" style={{width: '100%'}}>
                <thead>
                  <tr>
                    <td>
                      <b>Issues</b>
                    </td>
                    <td style={{width: 47, textAlign: 'center'}}>
                      <b>Rating</b>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {map(childIssues, childIssue => (
                    <tr key={childIssue.id}>
                      <td>{childIssue.name}</td>
                      <td style={{textAlign: 'center'}}>{RATINGS[childIssue.rating]}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <b>{issue.name}</b>
                    </td>
                    <td style={{textAlign: 'center'}}>
                      <b>{RATINGS[issue.rating]}</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ),
        }),
      );
    },
  };
};

export const IssueMatrix = connect(null, mapDispatchToProps)(IssueMatrixComponent);
