import React, {Component} from 'react';
import {PageContainer} from '../components';

export class AboutPage extends Component {
  render() {
    return (
      <PageContainer
        headerType={PageContainer.HEADER_TYPE.TYPE_2}
        companyName={this.props.companyName}
        pageNumber={this.props.page}
      >
        <div style={{marginTop: 200}}>
          <div style={{marginBottom: 50}} className="futura-bold">
            <h1 className="table-contents-company-name-styles futura-bold" style={{color: '#34B991'}}>
              About
            </h1>
            <h1 className="table-contents-company-name-styles futura-bold">Harmony</h1>
            <h1 className="table-contents-company-name-styles futura-bold">Analytics</h1>
          </div>
          <div className="futura-normal" style={{width: '50%'}}>
            <p>
              Harmony Analytics is a world-class research and data analytics firm. Harmony's goal is to help
              Corporations take responsibility for their ESG impact to define a better future.
            </p>
            <p>
              To do so, Harmony empowers Capital owners to take ownership of their ESG exposure by providing them with
              the first integrated approach to ESG management that is principled, comprehensive, and transparent.
            </p>
            <p>
              Harmony's team is comprised of global financial experts, top industry developers, and first-class data
              scientists and engineers. Our team has over 15 years of experience implementing advanced computer science
              techniques, creating statistical models, and procuring accurate and unbiased data.
            </p>
            <p>
              As a liaison for the ESG space, Harmony is building a network of NGOs and research institutions to support
              a new generation of corporations and Changing How the World Wins™.
            </p>
          </div>
        </div>
      </PageContainer>
    );
  }
}
