import React from 'react';
import styled from 'styled-components';

import {SingleBarChartProps} from '../interfaces/ChartProps';
import {Theme} from '../../components/atoms/theme';
import {Link2, TitleXS, TitleL} from '../atoms/Typeface';
import {ChartLabel} from '../molecules/ChartLabel';
import {LargeContentCard} from '../molecules/Card';
import {ColorBarChart, ProgressBarChart} from '../molecules/ColoredBarChart';

const ChartContentContainer = styled.div`
  height: 19rem;
`;

const ChartHeaderContainer = styled.div`
  margin-bottom: 65px;
`;

const ChartTitle = styled(TitleXS)`
  color: ${props => props.theme.colors.dark};
  margin-bottom: 10px;
`;

const ChartCount = styled(TitleL)`
  color: ${props => props.theme.colors.dark};
`;

export const SingleBarChart: React.FC<SingleBarChartProps> = ({
  colors,
  colorsTotals,
  data,
  dataTotals,
  labels,
  title,
  titleCount,
  onClick,
  barThickness = 45,
  isAnimated = undefined,
}) => {
  return (
    <>
      <ChartHeaderContainer>
        <ChartTitle>{title}</ChartTitle>
        <ChartCount>{titleCount}</ChartCount>
      </ChartHeaderContainer>
      <ChartContentContainer>
        <ProgressBarChart
          labels={labels}
          data={data}
          colors={colors}
          dataTotals={dataTotals}
          colorsTotals={colorsTotals}
          breakLabels
          onClick={onClick}
          barThickness={barThickness}
          isAnimated={isAnimated}
        />
      </ChartContentContainer>
    </>
  );
};
