import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTE} from 'app/constants/route';

function BrowserStorageRequired() {
  return (
    <div>
      <h1>Cookies are required</h1>
      <span>
        <p>Unfortunately the site requires cookies to continue.</p>
        <p>
          Please turn on cookies to use this site, or otherwise <Link to={ROUTE.CONTACT.path()}>contact us</Link> for
          further support.
        </p>
      </span>
    </div>
  );
}

export {BrowserStorageRequired};
