import React from 'react';
import styled from 'styled-components/macro';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import {PasswordInput as PasswordInputProps} from 'v2/utilities/types/components/PasswordInput';
import {Container, FlexContainer} from '../atoms/Containers';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {ParagraphS} from '../atoms/Typeface';

const StyledInput = styled.input<PasswordInputProps>`
  flex: 1;
  background-color: transparent;
  border-width: 0;
  height: inherit;
  text-indent: 0.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: ${({theme}) => theme.colors.dark};

  &:focus {
    outline: none;
  }
`;

const InputContainer = styled(FlexContainer)<React.HTMLAttributes<HTMLDivElement> & {hasError?: boolean}>`
  height: ${({height}) => height ?? '2.75rem'};
  width: 100%;
  background-color: ${({theme}) => theme.colors.grey_6};
  border-radius: ${({theme}) => theme.constants.border_radius};
  border: 0.0625rem solid transparent;

  &:focus-within {
    border-color: ${({theme}) => theme.colors.harmony};
    box-shadow: 0px 0px 0px 4px rgba(0, 52, 98, 0.4);
  }

  &&& {
    ${({hasError, theme}) =>
      hasError &&
      `
    border-color: ${theme.colors.red_2};
    box-shadow: 0px 0px 0px 4px rgba(250, 82, 82, 0.4);
    `}
  }
`;

const IconButton = styled.button`
  position: relative;
  background-color: transparent;
  border-width: 0;
  height: inherit;
  width: 2.75rem;
  color: ${({theme}) => theme.colors.grey_4};

  svg {
    position: relative;
    top: -2px;
  }
`;

const Subtext = styled(ParagraphS)`
  font-size: 0.75rem;
  color: ${({theme}) => theme.colors.grey_5};
`;

export const PasswordInput = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof StyledInput>>(
  ({id, label, required, requirementText, containerProps, hasError, ...rest}, ref) => {
    const [visible, setVisible] = React.useState(false);

    const showPassword = () => {
      setVisible(true);
    };

    const hidePassword = () => {
      setVisible(false);
    };

    return (
      <FlexContainer flex={1} direction="column" minWidth="18.75rem" fullWidth>
        {label && (
          <Container padding="0 0 0.125rem">
            <ParagraphS as="label" htmlFor={id}>
              {label}
              {required && '*'}
            </ParagraphS>
          </Container>
        )}
        <InputContainer hasError={hasError} {...containerProps}>
          <StyledInput ref={ref} type={visible ? 'text' : 'password'} id={id} required={required} {...rest} />
          <IconButton
            type="button"
            // @ts-ignore
            tabIndex="-1"
            onMouseDown={showPassword}
            onMouseUp={hidePassword}
            onMouseLeave={hidePassword}
          >
            <Icon icon={!visible ? faEye : faEyeSlash} className="icon" type="button" />
          </IconButton>
        </InputContainer>
        {requirementText && (
          <Container>
            <Subtext>{requirementText}</Subtext>
          </Container>
        )}
      </FlexContainer>
    );
  },
);
