module.exports = {
  prefix: 'fal',
  iconName: 'arrow-alt-down',
  icon: [
    448,
    512,
    [],
    'f354',
    'M176 32h96c13.3 0 24 10.7 24 24v200h103.8c21.4 0 32.1 25.8 17 41L241 473c-9.4 9.4-24.6 9.4-34 0L31.3 297c-15.1-15.1-4.4-41 17-41H152V56c0-13.3 10.7-24 24-24z',
  ],
};
