import {Theme} from 'v2/components/atoms/theme';
import {Body1} from 'v2/components/atoms/Typeface';
import styled from 'styled-components/macro';
import {GreyedDropDown} from './DropDown';
import {ChevronLeft, ChevronRight} from '../atoms/icons';
import {ReactTablePaginationProps} from 'v2/utilities/types/components/ReactTable';
import {FlexContainer} from '../atoms/Containers';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  &.active {
    background-color: ${Theme.colors.harmony};
    color: ${Theme.colors.white};
  }
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #464255;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  &:focus {
    outline: none;
  }
`;

const Label = styled(Body1)`
  color: ${({theme}) => theme.colors.grey_5};
`;

const pageSizeOptions: any[] = [
  {
    label: '10 per page',
    value: 10,
  },
  {
    label: '25 per page',
    value: 25,
  },
  {
    label: '50 per page',
    value: 50,
  },
  {
    label: '100 per page',
    value: 100,
  },
];

export const ReactTablePagination = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  visiblePages = [],
  pageSize,
  activePage,
  dataLength,
  allowPageSizeChange = true,
}: ReactTablePaginationProps) => {
  const handlePageSizeChange = (e: {value: number}) => {
    setPageSize(e.value);
  };

  const currentPageEnd = pageSize * activePage > dataLength ? dataLength : pageSize * activePage;
  return (
    <div
      className="pagination"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 1rem',
      }}
    >
      {allowPageSizeChange ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{minWidth: 184}}>
            <GreyedDropDown
              value={pageSize}
              onChange={handlePageSizeChange}
              options={pageSizeOptions}
              placeholder={`${pageSize} per page`}
            />
          </div>
          <Label style={{margin: '0 1rem'}}>
            Showing {pageSize * activePage - pageSize + 1} to {currentPageEnd} of {dataLength}
          </Label>
        </div>
      ) : (
        <div></div>
      )}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeft size={15} />
        </Button>
        <ButtonContainer>
          {visiblePages.map((page, index, array) => (
            <Button key={page} onClick={() => gotoPage(page - 1)} className={page === activePage ? 'active' : ''}>
              {array[index - 1] + 2 < page ? `...${page}` : page}
            </Button>
          ))}
        </ButtonContainer>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRight size={15} />
        </Button>{' '}
      </div>
    </div>
  );
};

export const CompactReactTablePagination = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  visiblePages = [],
  activePage,
}: ReactTablePaginationProps) => {
  return (
    <FlexContainer direction="column" items="flex-end" padding="1rem 0 1.1rem">
      <FlexContainer direction="row" justify="center" items="center">
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeft size={15} />
        </Button>
        <ButtonContainer>
          {visiblePages.map((page, index, array) => (
            <Button key={page} onClick={() => gotoPage(page - 1)} className={page === activePage ? 'active' : ''}>
              {array[index - 1] + 2 < page ? `...${page}` : page}
            </Button>
          ))}
        </ButtonContainer>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRight size={15} />
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};
