import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

import {convertPeriodKeyToFloat} from './convertPeriodKeyToFloat';

export const getPercentChangeInPeriodData = periodData => {
  if (!periodData) return null;

  const periodKeys = Object.keys(periodData);
  if (isEmpty(periodKeys)) return null;

  if (periodKeys.length === 1) return null;

  const periodKeysByDate = sortBy(periodKeys, convertPeriodKeyToFloat);

  const lastPeriodData = periodData[last(periodKeysByDate)];
  const firstPeriodData = periodData[periodKeysByDate[0]];

  const ratio = lastPeriodData / firstPeriodData;
  return Math.round((ratio - 1) * 100);
};
