import {Theme} from '../theme';

export const Sliders = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 3C21 2.44772 20.5523 2 20 2C19.4477 2 19 2.44772 19 3V12C19 12.5523 19.4477 13 20 13C20.5523 13 21 12.5523 21 12V3ZM8 8C8 7.44772 8.44771 7 9 7H11V3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3V7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H12H9C8.44771 9 8 8.55228 8 8ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V12ZM0 14C0 13.4477 0.447715 13 1 13H4H7C7.55228 13 8 13.4477 8 14C8 14.5523 7.55228 15 7 15H5V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V15H1C0.447715 15 0 14.5523 0 14ZM16 16C16 15.4477 16.4477 15 17 15H20H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17H21V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V17H17C16.4477 17 16 16.5523 16 16ZM5 3C5 2.44772 4.55228 2 4 2C3.44772 2 3 2.44772 3 3V10C3 10.5523 3.44772 11 4 11C4.55228 11 5 10.5523 5 10V3Z"
        fill={color}
      />
    </svg>
  );
};
