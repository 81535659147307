import {isBoolean} from 'lodash';
import React, {CSSProperties, FC} from 'react';
import {Field, useField} from 'react-final-form';
import {FlexContainer} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {ParagraphS} from 'v2/components/atoms/Typeface';
import {RadioButton, RadioRingButton} from 'v2/components/molecules/RadioButton';

export const RadioField: FC<{field: string; style?: CSSProperties; adjustChecked?: boolean}> = ({
  field,
  style,
  adjustChecked,
}) => {
  const myfield = useField(field);
  const value = myfield?.input?.value;

  let checked = false;
  let notChecked = false;
  if (isBoolean(value)) {
    checked = value;
    notChecked = !value;
  } else {
    checked = value === 'true';
    notChecked = value === 'false';
  }

  return (
    <FlexContainer gap="3.6875rem" style={style}>
      <Field
        name={field}
        type="radio"
        value="true"
        render={({input}) => {
          return (
            <RadioRingButton {...input} {...(adjustChecked && {checked: checked})}>
              <ParagraphS color={Theme.colors.dark}>Yes</ParagraphS>
            </RadioRingButton>
          );
        }}
      />
      <Field
        name={field}
        type="radio"
        value="false"
        render={({input}) => {
          return (
            <RadioRingButton {...input} {...(adjustChecked && {checked: notChecked})} c>
              <ParagraphS color={Theme.colors.dark}>No</ParagraphS>
            </RadioRingButton>
          );
        }}
      />
    </FlexContainer>
  );
};
