import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const ALERT_TYPE = {
  COMPLIANCE_VIOLATION: 'Compliance Violations',
  LATE_UPLOAD: 'Late Uploads',
  NEW_UPLOAD: 'New Uploads',
};

export const ALERT_TYPES = map(ALERT_TYPE, value => value);
