import {each} from 'lodash';

import {COLOR} from 'app/constants';
import {getColorRange} from './getColorRange';
import {mixHexColors} from './mixHexColors';

export const prepDataForChart = data => {
  const {datasets, labels} = data;
  const segments = labels ? labels.length : 0;

  const colorProps = {
    backgroundColor: getColorRange({
      from: COLOR.NAVY,
      to: mixHexColors(COLOR.NAVY, COLOR.GREEN, 15),
      segments,
    }),
    hoverBackgroundColor: getColorRange({
      from: mixHexColors(COLOR.NAVY, COLOR.WHITE),
      to: mixHexColors(COLOR.GREEN, COLOR.WHITE),
      segments,
    }),
  };

  each(datasets, dataset => Object.assign(dataset, colorProps));

  return data;
};
