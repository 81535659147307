import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import styled from 'styled-components/macro';

const GrayInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  background-color: #f3f2f7;
  border-radius: 8px;
  width: 12.5rem;
  border: 0.0625rem solid transparent;
  height: 2.75rem;
  padding: 0.75rem;
  margin: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;

  &::placeholder {
    color: ${props => props.theme.colors.grey_4};
  }

  &:focus-within {
    border-color: ${({theme}) => theme.colors.harmony};
    box-shadow: 0rem 0rem 0rem 0.25rem rgba(143, 209, 239, 0.5);
  }
`;

const TextFormGroup = ({
  input,
  label,
  labelHint,
  children,
  placeholder,
  type,
  maxLength,
  disabled,
  readOnly,
  autoComplete,
  className,
  addOnPrepend,
  addOnAppend,
  inputClassName,
  id,
  autoFocus,
  hasSplitFeedback,
  variant = 'default',
  meta: {touched, error},
}) => {
  let inputElement = (
    <input
      {...input}
      id={id || input.name}
      maxLength={maxLength}
      autoComplete={autoComplete}
      disabled={disabled}
      readOnly={readOnly}
      type={type}
      className={cn('form-control', {'is-invalid': touched && error}, inputClassName)}
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
  );

  if (variant === 'gray') {
    inputElement = (
      <GrayInput
        {...input}
        id={id || input.name}
        maxLength={maxLength}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readOnly}
        type={type}
        className={cn('form-control', {'is-invalid': touched && error}, inputClassName)}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    );
  }

  // The div that shows feedback that the field is invalid (e.g. "Required")
  const invalidFieldFeedback = (
    <div className={cn('invalid-feedback', {'w-auto float-right': hasSplitFeedback})}>{touched && error}</div>
  );

  // If there prepend/append, then ensure it is located correctly next to the input along with any validation feedback
  if (addOnPrepend || addOnAppend) {
    inputElement = (
      <div className="input-group">
        {addOnPrepend ? <span className="input-group-addon">{addOnPrepend}</span> : null}
        {inputElement}
        {addOnAppend ? <span className="input-group-addon">{addOnAppend}</span> : null}
        {invalidFieldFeedback}
      </div>
    );
  }

  return (
    <div className={cn('form-group', className)}>
      <div className="label-hint-container">
        {label && <label htmlFor={id || input.name}>{label}</label>}
        {labelHint && <span className="label-hint">{labelHint}</span>}
      </div>
      {inputElement}
      {children}
      {!addOnPrepend && !addOnAppend && invalidFieldFeedback}
    </div>
  );
};

TextFormGroup.propTypes = {
  children: PropTypes.node,
  input: PropTypes.object,
  label: PropTypes.string,
  labelHint: PropTypes.node,
  autoComplete: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  meta: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  addOnPrepend: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  addOnAppend: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  inputClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  autoFocus: PropTypes.bool,
  hasSplitFeedback: PropTypes.bool,
};

export {TextFormGroup};
