import React from 'react';

import {RadioDot} from './RadioDot';

const RadioDotLegend = () => (
  <div className="radio-dot-legend">
    <RadioDot color="green" />
    <div className="radio-dot-legend-label">Promising</div>
    <RadioDot color="light" />
    <div className="radio-dot-legend-label">Neutral</div>
    <RadioDot color="red" />
    <div className="radio-dot-legend-label">Worrisome</div>
  </div>
);

export {RadioDotLegend};
