import React, {Component, Fragment} from 'react';
import {get, isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ONBOARDING_STEPS, ROLE, ROUTE} from 'app/constants';
import {Alert, OnboardingESGForm} from 'app/components';
import {fetchCurrentPlanSponsorIfNeeded, updatePlanSponsorByCurrentUser} from 'app/redux/planSponsor';
import {toastSuccess} from 'app/utilities/toast';
import {withUser} from 'app/utilities';

class SettingsESGPage extends Component {
  componentDidMount() {
    this.props.dispatch(fetchCurrentPlanSponsorIfNeeded());
  }

  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    // Check if the form should be disabled
    const isReadOnly = this.props.userRole === ROLE.USER_TYPE_2;

    return (
      <div className="p-content-lg" style={{maxWidth: 1000}}>
        <h1>{ROUTE.MANAGE_ESG.title}</h1>
        {this.props.hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT}</Alert>}
        {this.props.onboardingPlanSponsor && (
          <Fragment>
            {isReadOnly && (
              <p className="mb-3">
                You do not currently have the required access level to edit the ESG information for this plan. Only a
                user with the role of "Admin" can edit the ESG information. If you believe you have the wrong access
                level, contact your plan "Admin" and they can modify your access level via their "User Manager" in
                "Settings".
              </p>
            )}
            <OnboardingESGForm {...this.props} isReadOnly={isReadOnly} isImaVisible={false} />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {authUser} = ownProps;
  const userRole = !isEmpty(authUser.roles) ? authUser.roles[0] : ROLE.USER_TYPE_1;

  // Using Onboarding specific form.  Expects 'onboardingPlanSponsor' property passed in to populate it
  return {
    hasError: get(state, 'planSponsor.hasError'),
    isFetching: get(state, 'planSponsor.isFetching'),
    onboardingPlanSponsor: get(state, 'planSponsor.editPlanSponsor'),
    userRole,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {authUser} = ownProps;
  const userRole = !isEmpty(authUser.roles) ? authUser.roles[0] : ROLE.USER_TYPE_1;
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const updateData = {
        ...values,
        onboardingStep: ONBOARDING_STEPS[userRole].ESG.key,
      };
      return dispatch(updatePlanSponsorByCurrentUser(updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('Manage ESG has been updated.');
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const SettingsESG = compose(withUser, connect(mapStateToProps, mapDispatchToProps))(SettingsESGPage);
