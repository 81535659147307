import {get, isNaN, isNull, isUndefined} from 'lodash';
import React, {useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import {useBlockLayout, useTable} from 'react-table';
import {useSticky} from 'react-table-sticky';
import styled from 'styled-components';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ISSUE_UNITS, ISSUE_UNIT_DESCRIPTIONS} from 'v2/constants/issueOptions';
import {IssueMetricTable as IssueMetricTableProps} from 'v2/utilities/types/components/IssueMetricTable';
import {InfoCircle} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {convertUnits} from '../../utilities/helpers/convertUnits';
import {CONFIG} from 'app/constants';
import {FlexContainer} from '../atoms/Containers';
import {SubtitleS, TitleS} from '../atoms/Typeface';
import {usePageDimensions} from 'v2/hooks/helpers/usePageDimensions';
import {defaultIssueUnit, defaultIssueValue} from 'v2/utilities/helpers/defaultIssue';

const Styles = styled.div`
  .table {
    font-family: 'Inter';
    font-style: normal;
    margin: 0;
    width: 100%;
    color: ${({theme}) => theme.colors.dark};

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th {
      height: 5.875rem;
      background-color: ${({color, theme}) => color ?? theme.colors.white};
      color: ${({theme}) => theme.colors.white};
    }

    .td {
      height: 5rem;
      background-color: ${({color, theme}) => theme.colors.white};
    }

    .th,
    .td {
      border-bottom: 1px solid ${({theme}) => theme.colors.grey_4};
      overflow: hidden;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 5px 0 3px 0 ${({theme}) => theme.colors.black + '4D'};
      }

      [data-sticky-first-right-td] {
        box-shadow: -5px 0 3px 0 ${({theme}) => theme.colors.black + '4D'};
      }
    }
  }
`;

const ButtonContainer = styled(FlexContainer).attrs({as: 'button'})<
  React.ComponentProps<typeof FlexContainer> & {highlighted?: boolean}
>`
  &&& {
    background-color: ${({highlighted, theme}) => (highlighted ? theme.colors.blue_8 : 'transparent')};
    &:hover {
      background-color: ${({theme}) => theme.colors.grey_3};
    }

    transition: 200ms background-color ease-in-out;

    border: 0;
  }
`;

export const IssueMetricTable = ({
  title,
  icon,
  color = Theme.colors.grey_5,
  data = [],
  onItemClick,
}: IssueMetricTableProps) => {
  const {width} = usePageDimensions();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  const handleItemClick = (values: any) => {
    onItemClick?.(values);
  };

  const getItemLabel = ({currentValue, unit = '', displayUnit}: any) => {
    if (isUndefined(currentValue) || isNull(currentValue)) {
      return DATA_STATUS.NONE;
    }

    if (isNaN(Number(currentValue))) {
      return currentValue;
    }

    if ([ISSUE_UNITS.PERCENT, ISSUE_UNITS.USD].includes(unit)) {
      return Number(currentValue).toFixed(2);
    }

    const nCurrentValue = Number(convertUnits(Number(currentValue), unit, displayUnit, 9));

    if (nCurrentValue > 1000) {
      return `${Math.round(nCurrentValue).toLocaleString('en-US')}`;
    }

    if (nCurrentValue < 0.001) {
      return `${nCurrentValue.toLocaleString('en-US')}`;
    }

    return `${nCurrentValue.toLocaleString('en-US')}`;
  };

  const memoizedColumns: any[] = React.useMemo(() => {
    const arr: any[] = [
      {
        Header: '',
        accessor: 'issueId',
      },
      {
        Header: title ?? DATA_STATUS.NONE,
        accessor: 'title',
        sticky: 'left',
        width: 280,
        Cell: (row: any) => (
          <FlexContainer padding="0 2.5rem" minWidth="100%" minHeight="100%" items="center">
            {row.value}
          </FlexContainer>
        ),
      },
      {
        Header: '',
        accessor: 'info',
        sticky: 'left',
        width: 40,
        Cell: (row: any) => (
          <FlexContainer
            data-for="table-tooltip"
            data-tip={row.value}
            minWidth="100%"
            minHeight="100%"
            justify="center"
            items="center"
          >
            <InfoCircle size={24} color={Theme.colors.harmony} />
          </FlexContainer>
        ),
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        sticky: 'left',
        width: 140,
        Cell: (row: any) => {
          return (
            <FlexContainer
              data-for="table-tooltip"
              data-tip={ISSUE_UNIT_DESCRIPTIONS(row.value === ISSUE_UNITS.MMTCO2E ? ISSUE_UNITS.MTCO2E : row.value)}
              minWidth="100%"
              minHeight="100%"
              justify="center"
              items="center"
            >
              {defaultIssueUnit(row?.cell?.row.original.issueId ?? '', row?.value ?? '')}
            </FlexContainer>
          );
        },
      },
    ];

    const dataCols = Array.from({length: CONFIG.ADMIN_REPORTING_YEAR_OFFSET}, (_, i) => ({
      Header: CONFIG.ADMIN_REPORTING_YEAR - i,
      accessor: (CONFIG.ADMIN_REPORTING_YEAR - i).toString(),
      width: 200,
      Cell: (row: any) => {
        const year = row.cell.column.id;
        const index = row.row.index;
        const currentData = get(data, `${index}`, {});
        const issueData = get(data, `${index}.issue.data.${year}`);

        const rowUnit = !!currentData?.unit && currentData?.unit !== 'null' ? currentData.unit : null;
        const editUnit =
          !!row?.value?.unit && row.value.unit !== 'null' ? row.value.unit : defaultIssueUnit(1, rowUnit);

        const value = defaultIssueValue(
          row?.cell?.row.original.issueId ?? '',
          editUnit ?? rowUnit ?? '',
          row.value.value ?? row.value,
        );

        return (
          <ButtonContainer
            // @ts-ignore
            highlighted={row?.value?.highlighted ?? false}
            // @ts-ignore
            onClick={() =>
              handleItemClick({
                ...currentData,
                year,
                value: row.value,
                ...issueData,
              })
            }
            minWidth="100%"
            minHeight="100%"
            items="center"
            justify="center"
          >
            {getItemLabel({
              currentValue: value,
              unit: rowUnit,
              displayUnit: rowUnit,
            })}
          </ButtonContainer>
        );
      },
    })).reverse();

    const end = {
      Header: `Change ${CONFIG.ADMIN_REPORTING_YEAR - 1}/${CONFIG.ADMIN_REPORTING_YEAR}`,
      accessor: 'diff',
      sticky: 'right',
      width: 220,
      Cell: (row: any) => {
        const index = row.row.index;
        const issueId = get(row, 'cell.row.original.issueId', '');

        const values = get(row, 'row.values', {});
        const currentData = get(data, `${index}`, {});

        const last = values?.[CONFIG.ADMIN_REPORTING_YEAR];
        const secondLast = values?.[CONFIG.ADMIN_REPORTING_YEAR - 1];

        const lastValue = Number(last?.value ?? last);
        const secondLastValue = Number(secondLast?.value ?? secondLast);

        const lastUnit = last?.unit ?? currentData?.unit;
        const secondLastUnit = secondLast?.unit ?? currentData?.unit;

        const convertedLast = Number(defaultIssueValue(issueId, lastUnit, lastValue));

        const convertedSecondLast = Number(defaultIssueValue(issueId, secondLastUnit, secondLastValue));

        const value = () => {
          if (currentData?.issue?.issueType !== 'Numeric' || isNaN(convertedLast) || isNaN(convertedSecondLast)) {
            return 'Not Applicable';
          }

          const change = ((convertedLast - convertedSecondLast) / convertedSecondLast) * 100;

          return `${change <= 0 ? '' : '+'}${(change / 100).toLocaleString('en-US', {style: 'percent'})}`;
        };

        return (
          <FlexContainer minWidth="100%" minHeight="100%" items="center" justify="center">
            {value()}
          </FlexContainer>
        );
      },
    };

    return [...arr, ...dataCols, end];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, title]);

  const memoizedData = React.useMemo(
    () =>
      data.map(datum => {
        const years = Array.from(
          {length: CONFIG.ADMIN_REPORTING_YEAR_OFFSET},
          (_, j) => CONFIG.ADMIN_REPORTING_YEAR - j,
        ).reverse();

        const items: any = {
          issueId: datum.issueId ?? '',
          title: datum.label ?? DATA_STATUS.NONE,
          info: datum.info ?? null,
          unit: datum.unit ?? '',
          diff: datum.diff ?? null,
        };

        years.forEach((year, i) => {
          // @ts-ignore
          items[year] = datum?.values?.[i] ?? DATA_STATUS.NONE;
        });

        return items;
      }),
    [data],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        hiddenColumns: ['issueId'],
      },
    },
    useBlockLayout,
    useSticky,
  );

  return (
    <Styles color={color}>
      <div {...getTableProps()} className="table sticky" style={{maxWidth: width - 360}}>
        <div className="header">
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map(column => {
                if (column.id === 'title') {
                  return (
                    <div {...column.getHeaderProps()} className="th">
                      <FlexContainer
                        width="100%"
                        height="100%"
                        padding="0 0 0 3rem"
                        gap="2rem"
                        items="center"
                        justify="start"
                      >
                        {!isUndefined(icon) &&
                          React.createElement(icon, {
                            color: Theme.colors.white,
                            size: '1.4375rem',
                          })}
                        <TitleS color={Theme.colors.white}>{column.render('Header')}</TitleS>
                      </FlexContainer>
                    </div>
                  );
                } else {
                  return (
                    <div {...column.getHeaderProps()} className="th">
                      <FlexContainer width="100%" height="100%" items="center" justify="center">
                        <SubtitleS color={Theme.colors.white}>{column.render('Header')}</SubtitleS>
                      </FlexContainer>
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map(row => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => (
                  <div {...cell.getCellProps()} className="td">
                    <FlexContainer width="100%" height="100%" items="center">
                      {cell.render('Cell')}
                    </FlexContainer>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};
