import {assignWithState} from 'app/redux/helpers';
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';

const initialState = {isFetching: false};

// ACTION
export const ACTION = {
  CREATE_STAGING_UPLOAD_REQUEST: 'admin-market-index/create-staging-upload/request/silent',
  CREATE_STAGING_UPLOAD_RECEIVE: 'admin-market-index/create-staging-upload/receive/silent',
  GET_REQUEST: 'ADMIN_MARKET_INDEX_GET_REQUEST',
  GET_RECEIVE: 'ADMIN_MARKET_INDEX_GET_RECEIVE',
  GET_PAGED_REQUEST: 'ADMIN_MARKET_INDEX_GET_PAGED_REQUEST',
  GET_PAGED_RECEIVE: 'ADMIN_MARKET_INDEX_GET_PAGED_RECEIVE',
  SAVE_REQUEST: 'ADMIN_MARKET_INDEX_SAVE_REQUEST',
  SAVE_RECEIVE: 'ADMIN_MARKET_INDEX_SAVE_RECEIVE',
};

const createStagingUploadRequest = createAction(ACTION.CREATE_STAGING_UPLOAD_REQUEST);
const createStagingUploadReceive = createAction(ACTION.CREATE_STAGING_UPLOAD_RECEIVE);
const getRequest = createAction(ACTION.GET_REQUEST);
const getReceive = createAction(ACTION.GET_RECEIVE);
const getPagedRequest = createAction(ACTION.GET_PAGED_REQUEST);
const getPagedReceive = createAction(ACTION.GET_PAGED_RECEIVE);
const saveRequest = createAction(ACTION.SAVE_REQUEST);
const saveReceive = createAction(ACTION.SAVE_RECEIVE);

const api = {
  createStagingUpload: fileKey => {
    return fetchAuthJSON('stagingSecurityMarketIndexUpload', {
      method: 'post',
      body: JSON.stringify({fileKey}),
    });
  },
  get: marketIndexId => {
    return fetchAuthJSON(`marketIndex/${marketIndexId}`, {method: 'get'}).then(results => ({
      results,
      parameters: {marketIndexId},
    }));
  },
  getPaged: options => {
    let url = 'marketIndex/paged?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  save: (marketIndexId, marketIndex, securityMarketIndexUploadData) => {
    return fetchAuthJSON(`marketIndex/${marketIndexId}`, {
      method: 'put',
      body: JSON.stringify({marketIndex, securityMarketIndexUploadData}),
    }).then(results => ({results, parameters: {marketIndexId}}));
  },
};

export function createStagingUpload(fileKey) {
  return dispatch => {
    dispatch(createStagingUploadRequest({fileKey}));
    return dispatch(createStagingUploadReceive(api.createStagingUpload(fileKey)));
  };
}

export function getMarketIndex(marketIndexId) {
  return dispatch => {
    dispatch(getRequest({marketIndexId}));
    return dispatch(getReceive(api.get(marketIndexId)));
  };
}

export function getMarketIndexPaged(options) {
  return dispatch => {
    dispatch(getPagedRequest({options}));
    return dispatch(getPagedReceive(api.getPaged(options)));
  };
}

export function saveMarketIndex(marketIndexId, marketIndex, securityMarketIndexUploadData) {
  return dispatch => {
    dispatch(saveRequest({marketIndexId, marketIndex, securityMarketIndexUploadData}));
    return dispatch(saveReceive(api.save(marketIndexId, marketIndex, securityMarketIndexUploadData)));
  };
}

// REDUCER
export const adminMarketIndex = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.CREATE_STAGING_UPLOAD_REQUEST:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: true,
          hasStagingUploadError: false,
          isFetching: true,
        },
      };
    case ACTION.CREATE_STAGING_UPLOAD_RECEIVE:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: false,
          hasStagingUploadError: hasError,
          isFetching: false,
        },
      };
    case ACTION.GET_RECEIVE:
      return assignMergedState(
        hasError || !payload ? {error: payload} : {edit: {[payload.parameters.marketIndexId]: {data: payload.results}}},
      );
    case ACTION.GET_PAGED_RECEIVE:
      return assignMergedState(
        hasError ? {error: payload} : {listing: {data: payload.items, totalSize: payload.total}},
      );
    case ACTION.SAVE_RECEIVE: {
      // TODO: Merge data into listing when the ability to modify name is available

      return assignMergedState(
        hasError || !payload ? {error: payload} : {edit: {[payload.parameters.marketIndexId]: {data: payload.results}}},
      );
    }
    default:
      return state;
  }
};
