import {SortByFn} from 'react-table';

export const compareNumbersWithNan: SortByFn<any> = (rowA, rowB, id, desc) => {
  let a = rowA.values[id];
  let b = rowB.values[id];
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};
