import styled from 'styled-components';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ESGProps, ESGCompanyProps} from 'v2/utilities/types/components/ESGDisplay';
import {FlexContainer, Container} from '../atoms/Containers';
import {Body1, Title1, Title5} from '../atoms/Typeface';
import {Quintile} from '../organisms/Quintile';

const DataText = styled(Body1)`
  &&& {
    margin: 0;
    color: ${props => props.theme.colors.dark};
  }
`;

const RatingLabel = styled(Title1)`
  &&& {
    font-size: 2.125rem;
    margin: 0;
    letter-spacing: 0;
    text-transform: lowercase;
    color: ${props => props.theme.colors.dark};
  }
`;

const BenchmarkText = styled(Title5)`
  &&& {
    margin: 0;
    letter-spacing: 0;
    text-transform: lowercase;
    color: ${props => props.theme.colors.blue_1};
  }
`;

export const CompanyPercentileRank = ({
  percentile = DATA_STATUS.NONE,
  quintile = 1,
  industry = DATA_STATUS.NONE,
}: ESGCompanyProps) => {
  return (
    <FlexContainer>
      <Container>
        <Quintile size="24px" percent={percentile} quintile={quintile} displayCondensed rounded />
      </Container>
      <Container>
        <RatingLabel>{percentile}</RatingLabel>
        <DataText>Industry</DataText>
        <BenchmarkText>{industry}</BenchmarkText>
      </Container>
    </FlexContainer>
  );
};

export const StrategyPercentileRank = ({
  percentile = DATA_STATUS.NONE,
  quintile = 1,
  benchmark = DATA_STATUS.NONE,
}: ESGProps) => {
  return (
    <FlexContainer>
      <Container>
        <Quintile size="24px" percent={percentile} quintile={quintile} displayCondensed rounded />
      </Container>
      <Container>
        <RatingLabel>{percentile}</RatingLabel>
        <DataText>Benchmark</DataText>
        <BenchmarkText>{benchmark}</BenchmarkText>
      </Container>
    </FlexContainer>
  );
};
