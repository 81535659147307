import {Theme} from '../theme';

export const ChildAndForcedLaborPolicy = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 13 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6914 10.1424L10.6302 9.96609C9.94674 8.59914 8.18292 7.68005 6.24 7.68005C4.34167 7.68005 2.61386 8.55658 1.84244 10.035C1.80553 10.1057 1.78021 10.1818 1.76363 10.2599L0.256644 17.3522C0.12447 17.9743 0.598868 18.5601 1.23481 18.5601H1.62047C2.17275 18.5601 2.62047 19.0078 2.62047 19.5601V23.0001C2.62047 23.5523 3.06818 24.0001 3.62047 24.0001H8.84353C9.39582 24.0001 9.84353 23.5523 9.84353 23.0001V19.5601C9.84353 19.0078 10.2913 18.5601 10.8435 18.5601H11.2452C11.8811 18.5601 12.3555 17.9743 12.2234 17.3522L10.6914 10.1424Z" />
      <path d="M9.59992 3.8315C9.59992 1.7093 7.89062 0 5.76843 0C3.64623 0 1.91992 1.7093 1.91992 3.8315C1.91992 5.95369 3.64623 7.68 5.76843 7.68C7.89062 7.68 9.59992 5.95369 9.59992 3.8315Z" />
    </svg>
  );
};
