import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_DETAILS_REQUEST: 'PLAN_DASHBOARD_GET_DETAILS_REQUEST',
  GET_DETAILS_RECEIVE: 'PLAN_DASHBOARD_GET_DETAILS_RECEIVE',
  GET_DISTRIBUTIONS_REQUEST: 'PLAN_DASHBOARD_GET_DISTRIBUTIONS_REQUEST',
  GET_DISTRIBUTIONS_RECEIVE: 'PLAN_DASHBOARD_GET_DISTRIBUTIONS_RECEIVE',
  GET_SECTORS_BY_DISTRIBUTION_REQUEST: 'PLAN_DASHBOARD_GET_SECTORS_BY_DISTRIBUTION_REQUEST',
  GET_SECTORS_BY_DISTRIBUTION_RECEIVE: 'PLAN_DASHBOARD_GET_SECTORS_BY_DISTRIBUTION_RECEIVE',
  GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST: 'PLAN_DASHBOARD_GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST',
  GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE: 'PLAN_DASHBOARD_GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE',
  GET_ISSUES_REQUEST: 'PLAN_DASHBOARD_GET_ISSUES_REQUEST',
  GET_ISSUES_RECEIVE: 'PLAN_DASHBOARD_GET_ISSUES_RECEIVE',
  GET_ISSUE_MATRIX_REQUEST: 'PLAN_DASHBOARD_GET_ISSUE_MATRIX_REQUEST',
  GET_ISSUE_MATRIX_RECEIVE: 'PLAN_DASHBOARD_GET_ISSUE_MATRIX_RECEIVE',
};

const getDetailsRequest = createAction(ACTION.GET_DETAILS_REQUEST);
const getDetailsReceive = createAction(ACTION.GET_DETAILS_RECEIVE);
const getDistributionsRequest = createAction(ACTION.GET_DISTRIBUTIONS_REQUEST);
const getDistributionsReceive = createAction(ACTION.GET_DISTRIBUTIONS_RECEIVE);
const getSectorsByDistributionRequest = createAction(ACTION.GET_SECTORS_BY_DISTRIBUTION_REQUEST);
const getSectorsByDistributionReceive = createAction(ACTION.GET_SECTORS_BY_DISTRIBUTION_RECEIVE);
const getCompaniesByDistributionAndSectorRequest = createAction(
  ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST,
);
const getCompaniesByDistributionAndSectorReceive = createAction(
  ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE,
);
const getIssuesRequest = createAction(ACTION.GET_ISSUES_REQUEST);
const getIssuesReceive = createAction(ACTION.GET_ISSUES_RECEIVE);
const getIssueMatrixRequest = createAction(ACTION.GET_ISSUE_MATRIX_REQUEST);
const getIssueMatrixReceive = createAction(ACTION.GET_ISSUE_MATRIX_RECEIVE);

const api = {
  getDetails: () =>
    fetchAuthJSON(
      'planSponsor/current?fields=id,name,isEmpty,peerRankings,peerRankingsInOrgType,planSponsorRankingOverrides',
      {method: 'get'},
    ),
  getDistributions: () => fetchAuthJSON('planSponsor/current/distribution', {method: 'get'}),
  getSectorsByDistribution: distribution =>
    fetchAuthJSON(`planSponsor/current/distribution/${distribution}/sector`, {method: 'get'}).then(results => ({
      results,
      parameters: {distribution},
    })),
  getCompaniesByDistributionAndSector: (distribution, sectorId) =>
    fetchAuthJSON(`planSponsor/current/distribution/${distribution}/sector/${sectorId}`, {method: 'get'}).then(
      results => ({results, parameters: {distribution, sectorId}}),
    ),
  getIssues: () => fetchAuthJSON('planSponsor/current/issue', {method: 'get'}),
  getIssueMatrix: () => fetchAuthJSON('planSponsor/current/issueMatrix', {method: 'get'}),
};

export function getDetails() {
  return dispatch => {
    dispatch(getDetailsRequest());
    return dispatch(getDetailsReceive(api.getDetails()));
  };
}

export function getDistributions() {
  return dispatch => {
    dispatch(getDistributionsRequest());
    return dispatch(getDistributionsReceive(api.getDistributions()));
  };
}

export function getSectorsByDistribution(distribution) {
  return dispatch => {
    dispatch(getSectorsByDistributionRequest({distribution}));
    return dispatch(getSectorsByDistributionReceive(api.getSectorsByDistribution(distribution)));
  };
}

export function getCompaniesByDistributionAndSector(distribution, sectorId) {
  const apiCall = api.getCompaniesByDistributionAndSector;
  return dispatch => {
    dispatch(getCompaniesByDistributionAndSectorRequest({distribution, sectorId}));
    return dispatch(getCompaniesByDistributionAndSectorReceive(apiCall(distribution, sectorId)));
  };
}

export function getIssues() {
  return dispatch => {
    dispatch(getIssuesRequest());
    return dispatch(getIssuesReceive(api.getIssues()));
  };
}

export function getIssueMatrix() {
  return dispatch => {
    dispatch(getIssueMatrixRequest());
    return dispatch(getIssueMatrixReceive(api.getIssueMatrix()));
  };
}

// REDUCER
export const planDashboard = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  switch (type) {
    case ACTION.GET_DETAILS_REQUEST:
    case ACTION.GET_DISTRIBUTIONS_REQUEST:
    case ACTION.GET_SECTORS_BY_DISTRIBUTION_REQUEST:
    case ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST:
    case ACTION.GET_ISSUES_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_DETAILS_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {details: payload}));
    case ACTION.GET_DISTRIBUTIONS_RECEIVE: {
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {distributions: payload}),
      );
    }
    case ACTION.GET_SECTORS_BY_DISTRIBUTION_RECEIVE: {
      const sectors = {};
      sectors[payload.parameters.distribution] = payload.results;
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                currentDistributionSectorsTimeStamp: new Date(),
                currentDistributionSectors: payload.results,
                sectors,
              },
        ),
      );
    }
    case ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE: {
      const companies = {};
      companies[payload.parameters.distribution] = {[payload.parameters.sectorId]: payload.results};
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                currentSectorCompaniesTimeStamp: new Date(),
                currentSectorCompanies: payload.results,
                companies,
              },
        ),
      );
    }
    case ACTION.GET_ISSUES_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {issues: payload}));
    case ACTION.GET_ISSUE_MATRIX_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {issues: payload}));
    default:
      return state;
  }
};
