import {createSlice} from '@reduxjs/toolkit';
import {closeGenericModal} from 'app/components/GenericModal/redux';

const initialState = {};

export const ESGExplorerIssuesSlice = createSlice({
  name: 'ESGExplorerIssues',
  initialState,
  reducers: {
    updateSelected: (state, action) => {
      state[action.payload.key] = action.payload.index;
    },
  },
});

export const {updateSelected} = ESGExplorerIssuesSlice.actions;

export default ESGExplorerIssuesSlice.reducer;
