import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchJSON, fetchIfNeeded} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';
import {getPermissionsArray} from 'app/utilities';

// The initial state, including expected shape of the state
const initialState = {
  permissions: [],
  hasError: false,
  error: null,
  isFetching: false,
};

// The default state for REQUEST actions
const defaultRequestState = {hasError: false, error: null, isFetching: true};

export const ACTION = {
  CREATE_UPDATE_PERMISSION_REQUEST: 'admin/update/permission',
  CREATE_UPDATE_PERMISSION_RECIEVE: 'admin/recieve/permission',
  CREATE_EDIT_PERMISSION_REQUEST: 'admin/update/edit/permission',
  CREATE_EDIT_PERMISSION_RECIEVE: 'admin/recieve/edit/permission',
};

// ACTION
const adminPermissionUpdateRequest = createAction(ACTION.CREATE_UPDATE_PERMISSION_REQUEST);
const adminPermissionUpdateRecieve = createAction(ACTION.CREATE_UPDATE_PERMISSION_RECIEVE);
const adminPermissionEditUpdateRequest = createAction(ACTION.CREATE_EDIT_PERMISSION_REQUEST);
const adminPermissionEditUpdateRecieve = createAction(ACTION.CREATE_EDIT_PERMISSION_RECIEVE);

const api = {
  get: userId => {
    return fetchAuthJSON(`user/${userId}/permissions`, {method: 'get'});
  },
  update: (userId, permissions) => {
    const body = {id: userId, permissions};
    return fetchAuthJSON('user/permissions', {
      method: 'put',
      body: JSON.stringify(body),
    });
  },
};

export function getEditAdminPermissions(userId) {
  return dispatch => {
    dispatch(adminPermissionEditUpdateRequest({userId}));
    return dispatch(adminPermissionEditUpdateRecieve(api.get(userId)));
  };
}

export function updateAdminPermissions(userId, permissions) {
  return dispatch => {
    dispatch(adminPermissionUpdateRequest());
    return dispatch(adminPermissionUpdateRecieve(api.update(userId, permissions)));
  };
}

// REDUCER
const adminPermissions = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.CREATE_UPDATE_PERMISSION_REQUEST:
    case ACTION.CREATE_EDIT_PERMISSION_REQUEST:
      return assignMergedState({
        ...defaultRequestState,
      });
    case ACTION.CREATE_UPDATE_PERMISSION_RECIEVE:
      return assignMergedState({
        isFetching: false,
        error: hasError ? payload : null,
      });
    case ACTION.CREATE_EDIT_PERMISSION_RECIEVE:
      return assignMergedState({
        hasError,
        isFetching: false,
      });
    default:
      return state;
  }
};

export {adminPermissions};
