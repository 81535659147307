import {clone, get, includes, map} from 'lodash';
import {
  HarmonyIndexDraft,
  IndexCenter,
  SettingsESGDraft,
  SettingsFirmManager,
  SettingsFirmManagerAdd,
  SettingsFirmManagerEdit,
  SettingsFirmProfile,
  SettingsPlan,
  SettingsProfile,
  SettingsUserManager,
  SettingsUserManagerEdit,
} from 'app/pages';
import {getSessionJwt} from 'app/utilities';
import {PrivateRoute, SettingsSideNav} from 'app/components';
import {ROLE} from 'v2/constants/role';
import {Link, Switch, useLocation} from 'react-router-dom';
import {ROUTE} from 'v2/constants/routes';
import {ROUTE as OLD_ROUTES} from 'app/constants/route';
import {EmptyLayout} from 'app/components/EmptyLayout';
import {userType1MenuProps, userType3MenuProps} from 'v2/constants/settingsNavigation';

const renderSingleCard = (item: any) => {
  const {path, menuMessage, menuTitle, title} = item;

  let route = typeof path === 'string' ? path : path();
  return (
    <div className="setting-card" key={route}>
      <div className="setting-card-content">
        <div className="setting-card-header">
          <div className="setting-card-name">{menuTitle ?? title}</div>
        </div>
        <div className="setting-card-body">
          <p>{menuMessage}</p>
        </div>
        <div className="setting-card-footer">
          <Link className="w-100 btn btn-outline" to={route}>
            View Settings
          </Link>
        </div>
      </div>
    </div>
  );
};

const renderAllCards = (menuProps: any) => {
  return map(menuProps, item => renderSingleCard(item));
};

export const Settings = () => {
  const location = useLocation();
  const authUser = getSessionJwt(true)?.contents;
  let menuProps: any = null;

  if (includes(authUser.roles, ROLE.USER_TYPE_1)) {
    menuProps = clone(userType1MenuProps);
  } else if (includes(authUser.roles, ROLE.USER_TYPE_2)) {
    menuProps = clone(userType1MenuProps);
  } else if (includes(authUser.roles, ROLE.USER_TYPE_3)) {
    menuProps = clone(userType3MenuProps);
  }

  if (get(location, 'pathname') === ROUTE.SETTINGS.path) {
    return (
      <div className="p-content-lg">
        <h1>Settings</h1>
        <div className="setting-card-grid">{renderAllCards(menuProps)}</div>
      </div>
    );
  }

  return (
    <div className="flex-grow-1 d-flex">
      <div className="setting-nav">
        <div className="settings-nav-title">
          <Link to={ROUTE.SETTINGS.path}>{ROUTE.SETTINGS.title}</Link>
        </div>
        <SettingsSideNav menuRoutes={menuProps} />
      </div>
      <div className="settings-body">
        <Switch>
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={ROUTE.SETTINGS.USER_PROFILE.path}
            component={SettingsProfile}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={ROUTE.SETTINGS.MANAGER_PROFILE.path}
            component={SettingsFirmProfile}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_ESG_DRAFT.path()}
            component={SettingsESGDraft}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_FIRMS_ADD.path()}
            component={SettingsFirmManagerAdd}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_FIRMS_EDIT.path(':firmId')}
            component={SettingsFirmManagerEdit}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_FIRMS_MANAGER.path()}
            component={SettingsFirmManager}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_FIRM_PROFILE.path()}
            component={SettingsFirmProfile}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.HARMONY_INDEX_CENTER.path()}
            component={IndexCenter}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.HARMONY_INDEX_DRAFT.path(':strategyId')}
            component={HarmonyIndexDraft}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_PLAN_PROFILE.path()}
            component={SettingsPlan}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_USER_PROFILE.path()}
            component={SettingsProfile}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_USERS.path()}
            component={SettingsUserManager}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_USERS_ADD.path()}
            component={SettingsUserManagerEdit}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
          <PrivateRoute
            exact
            layout={EmptyLayout}
            path={OLD_ROUTES.SETTINGS_USERS_EDIT.path(':userId')}
            component={SettingsUserManagerEdit}
            allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
          />
        </Switch>
      </div>
    </div>
  );
};
