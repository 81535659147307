import {Theme} from '../theme';

export const UpDownChevron = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_847_542)">
        <path
          d="M3 8.04492L6 11.0449L9 8.04492"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 4.04492L6 1.04492L3 4.04492"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_847_542">
          <rect width="12" height="12" fill={color} transform="translate(12 0.0449219) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
};
