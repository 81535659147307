import PropTypes from 'prop-types';
import React from 'react';

// Harmony Analytics Logo
const LogoHorizontal = ({color, ...props}) => (
  <svg className="logo-horizontal" fill={color} viewBox="0 0 210 57" {...props}>
    <path
      d="M6.37740944,38.9596709 L15.7084094,2.99867092 C26.3544094,-2.32832908 39.6474094,-0.560329078
      48.5284094,8.32167092 C57.5084094,17.3006709 59.2214094,30.7896709 53.6774094,41.4946709
      L53.2644094,41.4946709 C46.5714094,41.4946709 41.0694094,37.1686709 36.2154094,33.3516709
      C32.6524094,30.5506709 29.5754094,28.1316709 26.4724094,27.6596709 C20.8274094,26.7936709
      15.0864094,29.2426709 9.87040944,34.7226709 C8.48540944,36.1766709 7.31540944,37.6526709
      6.37740944,38.9596709 Z M13.8474094,4.01367092 L3.81840944,42.6636709 C-2.48459056,31.7876709
      -0.985590556,17.6306709 8.32440944,8.32167092 C10.0204094,6.62567092 11.8784094,5.19167092 13.8474094,4.01367092
      Z M35.2604094,34.5676709 C40.2084094,38.4566709 45.8044094,42.8436709 52.8234094,43.0226709
      C51.6474094,44.9846709 50.2184094,46.8356709 48.5284094,48.5256709 C37.4264094,59.6266709
      19.4264094,59.6266709 8.32440944,48.5256709 C6.98640944,47.1886709 5.81440944,45.7496709 4.79940944,44.2356709
      C4.90740944,44.1646709 5.00140944,44.0686709 5.06340944,43.9466709 C5.14940944,43.7796709 13.7704094,27.2986709
      26.2394094,29.1876709 C28.9394094,29.5986709 31.8694094,31.9016709 35.2604094,34.5676709 Z M91.7344094,16.7326709
      L91.7344094,10.5696709 L93.4264094,10.5696709 L93.4264094,25.7606709 L91.7344094,25.7606709 L91.7344094,18.3046709
      L83.9584094,18.3046709 L83.9584094,25.7606709 L82.2654094,25.7606709 L82.2654094,10.5696709 L83.9584094,10.5696709
      L83.9584094,16.7326709 L91.7344094,16.7326709 Z M102.873409,21.4486709 L101.040409,25.7606709
      L99.1854094,25.7606709 L106.198409,9.84367092 L113.027409,25.7606709 L111.153409,25.7606709 L109.359409,21.4486709
      L102.873409,21.4486709 Z M106.157409,13.7916709 L103.557409,19.8756709 L108.696409,19.8756709
      L106.157409,13.7916709 Z M120.679409,25.7596709 L118.988409,25.7596709 L118.988409,10.5686709
      L121.184409,10.5686709 C124.065409,10.5686709 126.746409,11.3346709 126.746409,14.7596709 C126.746409,17.0956709
      125.274409,18.7476709 122.938409,18.9496709 L127.772409,25.7596709 L125.697409,25.7596709 L121.105409,19.0906709
      L120.679409,19.0906709 L120.679409,25.7596709 Z M120.679409,17.6006709 L121.205409,17.6006709
      C123.118409,17.6006709 125.051409,17.2366709 125.051409,14.8806709 C125.051409,12.3816709 123.218409,12.1396709
      121.184409,12.1396709 L120.679409,12.1396709 L120.679409,17.6006709 Z M136.434409,9.70167092
      L141.955409,22.4546709 L147.496409,9.70167092 L150.578409,25.7596709 L148.844409,25.7596709
      L146.890409,15.4836709 L146.849409,15.4836709 L141.955409,26.3846709 L137.079409,15.4836709
      L137.040409,15.4836709 L135.086409,25.7596709 L133.352409,25.7596709 L136.434409,9.70167092
      Z M171.608409,18.1836709 C171.608409,22.5556709 168.023409,26.0216709 163.691409,26.0216709
      C159.360409,26.0216709 155.773409,22.5556709 155.773409,18.1836709 C155.773409,13.8326709 159.360409,10.3066709
      163.691409,10.3066709 C168.023409,10.3066709 171.608409,13.8326709 171.608409,18.1836709 Z M157.466409,18.1636709
      C157.466409,21.5886709 160.246409,24.4496709 163.691409,24.4496709 C167.136409,24.4496709 169.917409,21.5886709
      169.917409,18.1636709 C169.917409,14.7176709 167.177409,11.8786709 163.691409,11.8786709 C160.207409,11.8786709
      157.466409,14.7176709 157.466409,18.1636709 Z M178.033409,9.92467092 L189.879409,22.3346709
      L189.879409,10.5686709 L191.571409,10.5686709 L191.571409,26.4446709 L179.725409,14.0546709 L179.725409,25.7596709
      L178.033409,25.7596709 L178.033409,9.92467092 Z M197.736409,10.5686709 L199.690409,10.5686709
      L203.557409,17.3376709 L207.426409,10.5686709 L209.380409,10.5686709 L204.404409,19.2316709 L204.404409,25.7596709
      L202.711409,25.7596709 L202.711409,19.2316709 L197.736409,10.5686709 Z M84.0414094,42.3706709
      L82.8944094,45.0646709 L81.7364094,45.0646709 L86.1184094,35.1196709 L90.3844094,45.0646709 L89.2144094,45.0646709
      L88.0944094,42.3706709 L84.0414094,42.3706709 Z M86.0914094,37.5866709 L84.4684094,41.3876709
      L87.6784094,41.3876709 L86.0914094,37.5866709 Z M99.0354094,35.1706709 L106.439409,42.9246709
      L106.439409,35.5716709 L107.496409,35.5716709 L107.496409,45.4926709 L100.091409,37.7506709
      L100.091409,45.0656709 L99.0354094,45.0656709 L99.0354094,35.1706709 Z M118.450409,42.3706709
      L117.304409,45.0646709 L116.145409,45.0646709 L120.527409,35.1196709 L124.795409,45.0646709 L123.624409,45.0646709
      L122.504409,42.3706709 L118.450409,42.3706709 Z M120.501409,37.5866709 L118.878409,41.3876709
      L122.088409,41.3876709 L120.501409,37.5866709 Z M134.754409,44.0836709 L137.373409,44.0836709
      L137.373409,45.0656709 L133.697409,45.0656709 L133.697409,35.5716709 L134.754409,35.5716709
      L134.754409,44.0836709 Z M144.738409,35.5716709 L145.960409,35.5716709 L148.378409,39.8026709
      L150.794409,35.5716709 L152.015409,35.5716709 L148.905409,40.9866709 L148.905409,45.0656709 L147.848409,45.0656709
      L147.848409,40.9866709 L144.738409,35.5716709 Z M163.448409,45.0656709 L162.390409,45.0656709
      L162.390409,36.5536709 L160.099409,36.5536709 L160.099409,35.5716709 L165.763409,35.5716709 L165.763409,36.5536709
      L163.448409,36.5536709 L163.448409,45.0656709 Z M174.742409,45.0656709 L174.742409,35.5716709
      L175.799409,35.5716709 L175.799409,45.0656709 L174.742409,45.0656709 Z M192.834409,37.6486709
      C192.078409,36.8686709 191.034409,36.3896709 189.939409,36.3896709 C187.824409,36.3896709
      186.111409,38.2286709 186.111409,40.3196709 C186.111409,42.4076709 187.835409,44.2476709 189.962409,44.2476709
      C191.034409,44.2476709 192.078409,43.7426709 192.834409,42.9886709 L192.834409,44.2966709 C192.016409,44.8886709
      191.008409,45.2296709 190.001409,45.2296709 C187.321409,45.2296709 185.053409,43.0506709 185.053409,40.3566709
      C185.053409,37.6376709 187.281409,35.4076709 190.001409,35.4076709 C191.058409,35.4076709 191.991409,35.7106709
      192.834409,36.3406709 L192.834409,37.6486709 Z M206.796409,37.4226709 C206.419409,36.7676709 205.965409,36.3886709
      205.161409,36.3886709 C204.317409,36.3886709 203.586409,36.9946709 203.586409,37.8636709 C203.586409,38.6806709
      204.430409,39.0596709 205.072409,39.3476709 L205.702409,39.6256709 C206.936409,40.1666709 207.981409,40.7846709
      207.981409,42.2946709 C207.981409,43.9576709 206.646409,45.2296709 204.997409,45.2296709 C203.473409,45.2296709
      202.340409,44.2476709 202.038409,42.7736709 L203.071409,42.4836709 C203.210409,43.4536709 203.953409,44.2476709
      204.971409,44.2476709 C205.992409,44.2476709 206.922409,43.4656709 206.922409,42.3966709 C206.922409,41.2886709
      206.053409,40.9096709 205.173409,40.5076709 L204.593409,40.2556709 C203.487409,39.7526709 202.530409,39.1836709
      202.530409,37.8256709 C202.530409,36.3506709 203.776409,35.4076709 205.186409,35.4076709 C206.243409,35.4076709
      207.137409,35.9506709 207.641409,36.8816709 L206.796409,37.4226709 Z"
    />
  </svg>
);

LogoHorizontal.propTypes = {color: PropTypes.string};

LogoHorizontal.defaultProps = {color: 'currentColor'};

export {LogoHorizontal};
