import styled from 'styled-components/macro';
import {Theme} from '../atoms/theme';
import {Quintile} from './Quintile';
import {Title9, Title7, Overline, Title8} from '../atoms/Typeface';
import {PlainPill} from '../../components/molecules/PlainPill';
import {getOrdinal} from 'v2/utilities/helpers';
import {IssueRankingSummaryProps} from 'v2/components/interfaces/IssueProps';
import {isNumber} from 'lodash';
import {DATA_STATUS} from 'v2/constants/dataStatus';

const Container = styled.div`
width: 18.75rem;
height: 9.5rem;
display: flex;
flex-direction: column;
justify-content flex-start;
padding-left: 1.375rem;
background: ${props => props.theme.colors.white}
`;

const FlexRow1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const FlexRow2 = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const FlexRow3 = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Title9)`
  padding-right: 3.1875rem;
  padding-top: 8px;
  padding-bottom: 6px;
  margin: 0;
`;

const PercentText = styled(Title7)`
  margin: 0;
  // padding-bottom: .5rem;
`;

const PillContainer = styled.div`
  margin-right: 1rem;
`;

const PaddedOverline = styled(Overline)`
  padding-top: 0rem;
  padding-right: 0.5rem;
`;

const SmallPercent = styled(Title8)`
  margin: 0;
  padding-top: 0rem;
  // padding-bottom: .3rem;
`;
const isValid = (value: any) => {
  return value && isNumber(value) && value !== 0;
};

export const IssueRankingSummary = ({percentile, title, quintile, universe}: IssueRankingSummaryProps) => {
  const percentileOrdinal = getOrdinal(percentile);
  const universeOrdinal = getOrdinal(universe);
  const quintileDisplay = quintile + getOrdinal(quintile) + ' Quintile';
  return (
    <Container>
      {title && (
        <FlexRow1>
          <Title>{title}</Title>
          <PillContainer>
            <PlainPill text={quintile ? quintileDisplay : '-th'} />
          </PillContainer>
        </FlexRow1>
      )}
      <FlexRow2>
        <Quintile percent={percentile} quintile={quintile} />
        <PercentText color={Theme.colors.grey_8}>
          {isValid(percentile) ? percentile : DATA_STATUS.NONE}
          <sup>{percentileOrdinal}</sup>
        </PercentText>
      </FlexRow2>
      <FlexRow3>
        <PaddedOverline>BENCHMARK</PaddedOverline>
        <SmallPercent color={Theme.colors.harmony}>
          {isValid(universe) ? universe : DATA_STATUS.NONE}
          <sup>{universeOrdinal}</sup>
        </SmallPercent>
      </FlexRow3>
    </Container>
  );
};
