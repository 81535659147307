import React from 'react';
import {connect} from 'react-redux';
import {compose, withHandlers, withProps, withState} from 'recompose';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import debounceHandler from '@hocs/debounce-handler';

import {getPagedSecurities} from 'app/redux/complianceManager';
import {ComplianceManagerSecurityMiniListing} from './ComplianceManagerSecurityMiniListing';

const renderNoData = () => {
  return 'No companies matched your search.';
};

const defaultSorted = [
  {
    dataField: 'rating',
    order: 'asc',
  },
];

const ComplianceManagerSecuritySearch = compose(
  connect(
    ({complianceManager}) => ({
      hasFetched: get(complianceManager, 'pagedSecurities.hasFetched'),
      isFetching: get(complianceManager, 'pagedSecurities.isFetching'),
      securities: get(complianceManager, 'pagedSecurities.data.items', []),
    }),
    {getPagedSecurities},
  ),
  withState('options', 'setOptions'),
  withHandlers({
    handleSearchChange: props => e => {
      const newOptions = {
        ...props.options,
        search: e.target.value,
      };
      props.setOptions(newOptions);
      props.getPagedSecurities(props.strategyId, newOptions);
    },
    handleTableChange:
      props =>
      (type, {sortField, sortOrder}) => {
        const newOptions = {
          ...props.options,
          sortField,
          sortOrder,
        };
        props.setOptions(newOptions);
        props.getPagedSecurities(props.strategyId, newOptions);
      },
  }),
  debounceHandler('handleSearchChange', 300),
  debounceHandler('handleTableChange', 200),
  withProps(({handleTableChange}) => ({
    tableProps: {
      defaultSorted,
      noDataIndication: renderNoData,
      onTableChange: handleTableChange,
      remote: true,
    },
  })),
)(({handleSearchChange, hasFetched, isFetching, securities, strategyId, tableProps}) => (
  <div style={{fontSize: '1rem'}}>
    <div className="form-group min-h-0 mb-4">
      <label htmlFor="search" className="font-weight-bold">
        Search Companies
      </label>
      <input
        className="form-control"
        type="text"
        id="complianceManagerSecuritySearchInput"
        name="search"
        onChange={handleSearchChange}
      />
    </div>
    {hasFetched && (
      <ComplianceManagerSecurityMiniListing
        isFetching={isFetching}
        securities={securities}
        strategyId={strategyId}
        tableProps={tableProps}
      />
    )}
  </div>
));

ComplianceManagerSecuritySearch.propTypes = {
  strategyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export {ComplianceManagerSecuritySearch};
