import {useEffect, useRef, useState} from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartArea} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Bar} from 'react-chartjs-2';
import {Theme} from '../atoms/theme';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const HorizontalBarChart: React.FC<{
  labels: (string | string[])[];
  datasets: any[];
  handleChart?: Function;
  height?: number | string;
  width?: number | string;
  hideDataLabels?: boolean;
  responsive?: boolean;
}> = ({datasets, labels, handleChart, height, width, hideDataLabels = false, responsive = true}) => {
  const chartRef = useRef(null);

  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: datasets,
  });

  useEffect(() => {
    if (handleChart && chartRef.current) {
      setChartData(handleChart(chartRef.current, labels, datasets));
      return;
    }

    if (datasets && labels) {
      setChartData({
        labels,
        datasets,
      });
    }
  }, [datasets, labels, handleChart]);

  if (datasets[0].data[0] != chartData.datasets[0].data[0] && handleChart && chartRef.current) {
    setChartData(handleChart(chartRef.current, labels, datasets));
  }

  return (
    <Bar
      ref={chartRef}
      //@ts-ignore
      data={chartData}
      options={{
        indexAxis: 'y',
        responsive,
        scales: {
          x: {
            stacked: true,
            grid: {
              lineWidth: 0,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              drawBorder: false,
              lineWidth: 0,
            },
            ticks: {
              display: false,
            },
          },
        },
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            backgroundColor: Theme.colors.white,
            borderColor: Theme.colors.grey_3,
            borderWidth: 1,
            yAlign: 'bottom',
            bodyColor: Theme.colors.black,
            padding: {left: 20, right: 20, top: 6, bottom: 6},
            callbacks: {
              title: () => '',
              label: context => {
                return context.dataset.label + ': ' + context.formattedValue + '%';
              },
            },
          },
          datalabels: {
            display: !hideDataLabels,
            formatter: val => (Number(val) <= 10 ? '' : val + '%'),
            color: 'blue',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
      }}
      height={height}
      width={width}
    />
  );
};
