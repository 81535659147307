import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {includes} from 'lodash';

import {withUser} from 'app/utilities';
import {ROLE} from 'app/constants';
import {Header} from './Header';
import {Footer} from './Footer';

const PageLayoutComponent = ({authUser, children}) => (
  <div
    className={cn(
      'd-flex flex-column flex-grow-1',
      includes(authUser.roles, ROLE.USER_TYPE_3) ? 'pt-header-with-secondary' : 'pt-header',
    )}
  >
    <Header />
    <div id="page-content" className="flex-grow-1 d-flex flex-column">
      {children}
    </div>
    <Footer />
  </div>
);

PageLayoutComponent.propTypes = {children: PropTypes.node};

export const PageLayout = withUser(PageLayoutComponent);
