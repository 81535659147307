import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {Chart} from 'react-google-charts';
import {Rubrica, TitleXS, SubtitleM} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';
import {string} from 'prop-types';

const Container = styled.div<{align: string}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.align};
`;

const ColorBox = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.125rem;
  margin-right: 5px;
  background-color: ${props => props.color};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.9375rem;
`;

const SmallLegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.625rem;
`;

const SmallLegendLabel = styled(Rubrica)`
  font-size: 0.625rem;
  padding-left: 0.688rem;
`;

const SingleLegendItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 70px;
`;

const SingleLegendLabel = styled(SubtitleM)`
  margin-right: 12px;
`;

const LargeLabel = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  color: ${({theme}) => theme.colors.dark};
  margin: 0 0 10px 0;
`;

export const Legend: React.FC<{labels: string[]; colors: string[]; align: 'left' | 'center' | 'right'}> = ({
  labels,
  colors,
  align,
}) => {
  const alignToCss = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };
  return (
    <Container align={alignToCss[align]}>
      {labels.map((label, i) => {
        return (
          <LegendItem>
            <ColorBox color={colors[i]} />
            <Rubrica>{label}</Rubrica>
          </LegendItem>
        );
      })}
    </Container>
  );
};

export const SmallLegend: React.FC<{labels: string[]; colors: string[]; align: 'left' | 'center' | 'right'}> = ({
  labels,
  colors,
  align,
}) => {
  const alignToCss = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };
  return (
    <Container align={alignToCss[align]}>
      {labels.map((label, i) => {
        return (
          <SmallLegendItem>
            <ColorBox color={colors[i]} />
            <SmallLegendLabel>{label}</SmallLegendLabel>
          </SmallLegendItem>
        );
      })}
    </Container>
  );
};

export const SingleLegend: React.FC<{
  labels: string;
  colors: string;
  align: 'left' | 'center' | 'right';
  sum?: number;
  large?: boolean;
}> = ({labels, colors, align, sum, large = false}) => {
  const alignToCss = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };
  return (
    <Container align={alignToCss[align]}>
      <SingleLegendItem>
        <ColorBox color={colors} />
        <SingleLegendLabel>{labels}</SingleLegendLabel>
        {large ? <LargeLabel>{sum}</LargeLabel> : <SingleLegendLabel>{sum}</SingleLegendLabel>}
      </SingleLegendItem>
    </Container>
  );
};
