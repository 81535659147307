import {Theme} from '../theme';

export const LogoBadge1 = ({size = 270}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 269 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M229.254 39.369C187.34 -2.6471 124.492 -11.025 74.2172 14.1931L30.1299 184.327C34.5738 178.132 40.1005 171.176 46.6187 164.27C71.2851 138.34 98.4055 126.752 125.062 130.871C139.708 133.096 154.263 144.543 171.09 157.793C194.005 175.865 220.043 196.303 251.656 196.303H253.59C279.768 145.676 271.689 81.8497 229.254 39.369Z"
        fill="#E5E5E5"
      />
      <path
        d="M39.341 39.3689C-4.64793 83.4126 -11.7427 150.407 18.0286 201.836L65.4207 18.9734C56.118 24.5563 47.3287 31.3219 39.341 39.3689Z"
        fill="#E5E5E5"
      />
      <path
        d="M123.972 138.108C65.048 129.167 24.3147 207.137 23.8858 207.94C23.6045 208.51 23.1756 208.933 22.6553 209.313C27.4718 216.459 33.0056 223.274 39.2917 229.61C91.7465 282.13 176.799 282.13 229.254 229.61C237.242 221.612 243.999 212.861 249.526 203.589C216.4 202.688 189.983 181.969 166.596 163.566C150.579 150.928 136.734 140.044 123.972 138.108Z"
        fill="#E5E5E5"
      />
    </svg>
  );
};
