import React, {useCallback, useEffect, useState} from 'react';
import {FormSpy} from 'react-final-form';
import {ValidationErrors, setIn, FORM_ERROR} from 'final-form';
import {isEqual} from 'lodash';

type Props = {
  debounce: number;
  disabled?: boolean;
  onSave: (values: any) => Promise<void>;
  values: any;
  errors?: ValidationErrors;
};

/**
 * AutoSave when included in a final-form Form, will automatically call the save
 * method if any field value changes after a debounce timeout.
 *
 * AutoSave is based on the example from: https://codesandbox.io/s/5w4yrpyo7k
 */
const AutoSave: React.FC<Props> = ({values, debounce, errors, onSave, disabled}) => {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timer | null>(null);
  const [previousValues, setValues] = useState(values);
  const [promise, setPromise] = useState<Promise<void> | null>(null);

  const handleSave = useCallback(async () => {
    if (promise) {
      await promise;
    }

    if (!disabled && errors && !Object.keys(errors)?.length && !isEqual(previousValues, values)) {
      setValues(values);
      const res: any = onSave(values);
      setPromise(res);
      await res;
      setPromise(null);
      //set final-form-error
      return res;
    }
  }, [values]);

  useEffect(() => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    setTimeoutRef(setTimeout(handleSave, debounce));

    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [values]);

  return null;
};

export default function AutoSaveWrapper({values, ...rest}: Props) {
  return (
    <FormSpy subscription={{values: true, errors: true}}>
      {({values, errors}) => <AutoSave values={values} errors={errors} {...rest} />}
    </FormSpy>
  );
}
