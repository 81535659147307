import React from 'react';
import {IssueIconImage} from '.';
import {ISSUE, DATA_CATEGORY} from '../constants';
import {DATA_STATUS} from 'v2/constants/dataStatus';

const BooleanCard = ({data}) => {
  const {id, category, name, value, year, sector, universe} = data;

  const issuePath = ISSUE[id].iconPath;

  const getIssueColor = () => {
    switch (category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return '#34B991';
      case DATA_CATEGORY.SOCIAL:
        return '#009BCC';
      case DATA_CATEGORY.GOVERNANCE:
        return '#003462';
      case DATA_CATEGORY.FINANCIAL:
        return '#6D6D6D';
      default:
        return '';
    }
  };

  const renderValue = () => {
    if (value === undefined || value === null) {
      return <span className="stats-value text-uppercase">{DATA_STATUS.NONE}</span>;
    }

    const parsedValue = Boolean(parseInt(value, 10));

    if (parsedValue) {
      return (
        <span className="stats-value text-uppercase" style={{color: '#34B991'}}>
          Yes
        </span>
      );
    }

    return (
      <span className="stats-value text-uppercase" style={{color: '#E80000'}}>
        No
      </span>
    );
  };

  return (
    <div className="d-flex flex-column boolean-card-container">
      <div className="flex-0 row">
        <div className="col boolean-card-icon-wrapper flex-grow-0">
          {data && (
            <IssueIconImage path={issuePath} className="boolean-card-header-icon" backgroundFill={getIssueColor()} />
          )}
        </div>
        <div className="col boolean-card-title d-flex align-items-center justify-content-start">
          {data && name && <span>{name}</span>}
        </div>
      </div>
      <div className="flex-0 boolean-card-h-border" />

      <div className="flex-1 row">
        <div className="col boolean-card-stats d-flex flex-column justify-content-end flex-grow-1">
          <div className="boolean-card-value-container text-center">{renderValue(value)}</div>
          <div className="label-container text-center">
            <span className="stats-footer">{data && year ? year : new Date().getFullYear() - 1}</span>
          </div>
        </div>
        <div className="boolean-card-v-border flex-grow-0" />
        <div className="col boolean-card-stats d-flex flex-column justify-content-end flex-grow-1">
          {data && sector !== undefined && sector !== null && !isNaN(sector) ? (
            <div className="stat-section d-flex justify-content-between align-items-center">
              <span className="stats-percentage text-uppercase">{sector}%</span>
              <span className="stats-label text-uppercase">Sector</span>
            </div>
          ) : (
            <div className="stat-section d-flex justify-content-between align-items-center">
              <span className="stats-percentage text-uppercase">{DATA_STATUS.NONE}</span>
              <span className="stats-label text-uppercase">Sector</span>
            </div>
          )}
          {data && universe !== undefined && universe !== null && !isNaN(universe) ? (
            <div className="stat-section d-flex justify-content-between align-items-center">
              <span className="stats-percentage text-uppercase">{universe}%</span>
              <span className="stats-label text-uppercase">Universe</span>
            </div>
          ) : (
            <div className="stat-section d-flex justify-content-between align-items-center">
              <span className="stats-percentage text-uppercase">{DATA_STATUS.NONE}</span>
              <span className="stats-label text-uppercase">Universe</span>
            </div>
          )}
          <div className="text-center">
            <span className="stats-footer text-uppercase text-center">% of companies</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export {BooleanCard};
