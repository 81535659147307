import {FlexContainer, AttachedWrapper} from 'v2/components/atoms/Containers';
import {CompanyIssueDataTable} from 'v2/components/organisms/CompanyIssueDataTable';
import {CompanyEngagementHeader} from 'v2/components/organisms/CompanyEngagementHeader';
import {useCompanyEngagements} from 'v2/hooks/useCompanyEngagement';
import ReactPlaceholder from 'react-placeholder';
import {RectShape} from 'react-placeholder/lib/placeholders';
import {Theme} from 'v2/components/atoms/theme';

export const UT1CompaniesEngagement = () => {
  const {issuesData, engagementLetterData, isLoading} = useCompanyEngagements();

  const ReactPlaceholderEngagements = () => (
    <div style={{marginBottom: 0}}>
      <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20, marginTop: 0}} />
      <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 40, margin: 0, float: 'right'}} />

      <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 65}} />
      <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 15}} />
      <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 15}} />
    </div>
  );

  return (
    <AttachedWrapper>
      <FlexContainer padding="2.8125rem 2.8125rem 4.75rem 4.75rem" direction="column">
        <ReactPlaceholder ready={!isLoading} customPlaceholder={<ReactPlaceholderEngagements />}>
          <CompanyEngagementHeader latestEngagementLetter={engagementLetterData} />
          <CompanyIssueDataTable data={issuesData} />
        </ReactPlaceholder>
      </FlexContainer>
    </AttachedWrapper>
  );
};
