export const CHARACTER_LIMIT = {
  ADDRESS_LINES: 255,
  FIRST_NAME: 50,
  LAST_NAME: 124,
  NAME_SUFFIX: 50,
  PROFILE_TITLE: 124,
  PROFILE_ROLE: 124,
  CITY: 124,
  URL: 124,
  EMAIL: 256,
  ORGANIZATION_NAME: 256,
  INVESTMENT_STRATEGY: 256,
  STRATEGY_NAME: 256,
  TOTAL_ASSETS: 19,
};
