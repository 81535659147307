/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';
import {get, keyBy, isNumber, isNil} from 'lodash';
import {formattedPercentDifference} from 'app/utilities/formattedPercentDifference';
import {PageContainer} from '../components';
import {formatNumberCommas} from '../../utilities/formatNumberCommas';
import {REPORT_METRICS, ESG_MEASUREMENT} from '../../constants';
import {DATA_STATUS} from 'v2/constants/dataStatus';

export class ESGMeasuresPage extends Component {
  hasValue = val => {
    return !isNil(val) && !isNumber(val);
  };

  renderData(keys) {
    const subjectData = keyBy(this.props.subjectData, 'reportMetricId');
    const targetData = keyBy(this.props.targetData, 'reportMetricId');

    return keys.map((key, index) => {
      const colorClass = index % 2 === 0 ? 'color' : '';

      const precision = REPORT_METRICS?.[key].precision && REPORT_METRICS[key].precision;

      let subjectValue = get(subjectData, `${key}.value`, null);
      let subjectDisplayValue;

      if (!isNil(subjectValue)) {
        subjectValue = parseFloat(subjectValue);
        subjectDisplayValue = formatNumberCommas(
          subjectValue >= 10 ** precision
            ? subjectValue.toFixed(0)
            : subjectValue !== 0
            ? subjectValue.toPrecision(precision)
            : subjectValue,
        );
      }

      let targetValue = get(targetData, `${key}.value`, null);
      let targetDisplayValue;
      if (!isNil(targetValue)) {
        targetValue = parseFloat(targetValue);
        targetDisplayValue = formatNumberCommas(
          targetValue >= 10 ** precision ? targetValue.toFixed(0) : targetValue.toPrecision(precision),
        );
      }

      const target = get(targetData, `[${key}].reportMetric.target`);

      return (
        <tr key={key}>
          <td className={`esg-measure-row ${colorClass}`}>{get(targetData, `[${key}].reportMetric.name`)}</td>
          <td className={`esg-measure-row ${colorClass} text-center`}>
            <span>
              {subjectDisplayValue || DATA_STATUS.NONE}
              {subjectDisplayValue && (
                <span className="pl-2" style={{fontSize: '13px'}}>
                  {get(targetData, `[${key}].reportMetric.unit`)}
                </span>
              )}
            </span>
          </td>
          <td className={`esg-measure-row ${colorClass} text-center`}>
            <span>
              {targetDisplayValue || DATA_STATUS.NONE}
              {targetDisplayValue && (
                <span className="pl-2" style={{fontSize: '13px'}}>
                  {get(targetData, `[${key}].reportMetric.unit`)}
                </span>
              )}
            </span>
          </td>
          <td className={`esg-measure-row ${colorClass} text-center`}>
            {formattedPercentDifference(subjectValue, targetValue, target, '%')}
          </td>
        </tr>
      );
    });
  }

  render() {
    const {companyName, metrics, subjectHeader, targetHeader, comparisonHeader} = this.props;

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} companyName={companyName} pageNumber={6}>
        <div className={'esg-measures-page'}>
          <h2 style={{marginBottom: 50}}>Holdings Metrics</h2>
          {Object.keys(metrics).map(key => {
            return (
              <table key={key} style={{width: '100%', borderCollapse: 'collapse', marginBottom: 75}}>
                <thead>
                  <tr style={{borderBottom: '1px solid black'}}>
                    <th className="esg-measure-table-header" width={'40%'}>
                      {key}
                    </th>
                    <th className="esg-measure-table-header text-center" width={'20%'}>
                      {subjectHeader}
                    </th>
                    <th className="esg-measure-table-header text-center" width={'20%'}>
                      {targetHeader}
                    </th>
                    <th className="esg-measure-table-header text-center" width={'20%'}>
                      <span>vs</span> {comparisonHeader}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{paddingBottom: 20}} />
                  </tr>
                  {this.renderData(metrics[key])}
                </tbody>
              </table>
            );
          })}
        </div>
      </PageContainer>
    );
  }
}

ESGMeasuresPage.defaultProps = {
  subjectHeader: 'Portfolio',
  targetHeader: 'Universe',
  comparisonHeader: 'Universe',
  subjectData: [],
  targetData: [],
};
