/* eslint-disable max-len */
import React from 'react';
import faArrowAltRight from 'app/fontawesome-pro-light/faArrowAltRight';
import faArrowAltUp from 'app/fontawesome-pro-light/faArrowAltUp';
import faArrowAltDown from 'app/fontawesome-pro-light/faArrowAltDown';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {getPercentChange, getTrendType} from '../utilities/dataAnalyzer';
import {TREND_TYPE, TREND_ARROW_COLOR, ISSUE, DATA_CATEGORY, CONFIG} from '../constants';
import {IssueIconImage} from '.';
import {formatNumberCommas} from '../utilities/formatNumberCommas';
import {toSciNotation} from 'v2/utilities/helpers/toScientificNotation';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ISSUE_UNITS} from 'v2/constants/issueOptions';
import {convertUnits} from 'v2/utilities/helpers/convertUnits';
import {ISSUES} from 'v2/constants/issues';
import {isObject} from 'lodash';

const NumericalCard = ({data}) => {
  const {id, issues, year, target, name, unit, category} = data;
  let startYear = 0;
  Object.keys(issues).forEach(issue => {
    if (
      (issues[issue]?.value || issue[issue]?.value === 0) &&
      issue > startYear &&
      issue !== year &&
      issue > CONFIG.REPORTING_YEAR - 4
    ) {
      startYear = issue;
    }
  });
  if (startYear === 0) {
    startYear = year - 1;
  }
  const dataStart = issues[startYear] && issues[startYear].value;
  const dataEnd = issues[year] && issues[year].value;
  const percentage = () => {
    let percent = Math.abs(getPercentChange(dataStart, dataEnd).toFixed(1));
    if (isNaN(percent)) {
      return DATA_STATUS.NONE;
    }
    if (percent > 100) {
      percent = '>' + '100';
    }
    return percent + '%';
  };

  const trendColor = () => {
    switch (getTrendType(target, dataStart, dataEnd)) {
      case TREND_TYPE.GOOD:
        return TREND_ARROW_COLOR.GOOD;
      case TREND_TYPE.BAD:
        return TREND_ARROW_COLOR.BAD;
      case TREND_TYPE.NEUTRAL:
      default:
        return TREND_ARROW_COLOR.NEUTRAL;
    }
  };
  const formatValue = () => {
    if (dataEnd === undefined || dataEnd === null) {
      return DATA_STATUS.NONE;
    }

    const precision = ISSUE?.[id].precision && ISSUE[id].precision;

    const value =
      id === ISSUES.CARBON_EMISSIONS.id
        ? parseFloat(Number(convertUnits(dataEnd, ISSUE_UNITS.MMTCO2E, ISSUE_UNITS.MTCO2E, precision)).toFixed(2))
        : parseFloat(dataEnd);

    const max5Digits = 999999;
    if (value > max5Digits) {
      return toSciNotation(value, 2);
    }

    if (value >= 10 ** precision) {
      return formatNumberCommas(value.toFixed(0));
    }

    if (value !== 0) {
      return formatNumberCommas(value.toPrecision(precision));
    }

    return formatNumberCommas(value);
  };
  const trendDirection = () => {
    const percentChange = getPercentChange(dataStart, dataEnd);

    if (percentChange > 0) {
      return faArrowAltUp;
    }
    if (percentChange < 0) {
      return faArrowAltDown;
    }
    if (percentChange === 0) {
      return faArrowAltRight;
    }

    return '';
  };

  const getIssueColor = () => {
    switch (category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return '#34B991';
      case DATA_CATEGORY.SOCIAL:
        return '#009BCC';
      case DATA_CATEGORY.GOVERNANCE:
        return '#003462';
      case DATA_CATEGORY.FINANCIAL:
        return '#6D6D6D';
      default:
        return '';
    }
  };

  const issueTitle = title => {
    if (title === 'Scope 1&2 Emissions') {
      return 'Carbon Emissions';
    }

    return title;
  };

  const issueUnit = unit => {
    switch (unit) {
      case ISSUE_UNITS.MMTCO2E:
        return ISSUE_UNITS.MTCO2E;
      default:
        return unit;
    }
  };

  const displayValue = () => {
    console.log({formatValue: formatValue()});
    if (formatValue() === 'NaN') {
      return DATA_STATUS.NONE;
    }

    return (
      <>
        {formatValue().coefficient ? formatValue().coefficient + 'x10' : formatValue()}
        {formatValue().exponent && <sup>{formatValue().exponent}</sup>}
      </>
    );
  };

  return (
    <div className="d-flex flex-column numerical-card-container">
      <div className="flex-0 row">
        <div className="col flex-grow-0">
          {data && (
            <IssueIconImage
              path={ISSUE[id].iconPath}
              className="numerical-card-header-icon"
              backgroundFill={getIssueColor()}
            />
          )}
        </div>
        <div className="col numerical-card-title d-flex align-items-center justify-content-start">
          {data && name && <span>{issueTitle(name)}</span>}
        </div>
      </div>
      <div className="flex-0 numerical-card-border" />
      <div className="flex-1 row">
        <div className="col d-flex flex-column justify-content-between">
          <div className="d-flex flex-column justify-content-center align-items-center numerical-card-value-text">
            <div className="d-block">
              <span className="d-inline-block">{displayValue()}</span>
              {data && dataEnd && (
                <span className={`${unit === '%' ? '' : 'ml-1'} d-inline-block`}>{issueUnit(unit)}</span>
              )}
            </div>
          </div>
          <span className="numerical-card-footer mt-3 text-center">
            {data && year ? year : new Date().getFullYear() - 1}
          </span>
        </div>
        <div className="col d-flex flex-column justify-content-between">
          <div className="d-flex flex-column justify-content-center align-items-center numerical-card-percentage-container">
            <div className="d-flex flex-row justify-content-end">
              <Icon className="numerical-increase-icon" icon={trendDirection()} style={{color: trendColor()}} />
              <div className="numerical-card-percentage">
                {data && dataStart && dataEnd ? <span>{percentage()}</span> : <span>{DATA_STATUS.NONE}</span>}
              </div>
            </div>
          </div>
          <div className="numerical-card-footer text-uppercase text-center mt-3">
            <span>from {data && startYear ? startYear : new Date().getFullYear() - 2}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export {NumericalCard};
