import {filter, isEmpty, map, orderBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {PARENT_ISSUES, RATINGS} from 'app/constants';

export class IssueMatrix extends Component {
  render() {
    const {issues, title} = this.props;

    if (isEmpty(issues)) {
      return <span />;
    }

    return (
      <div className="issue-grid">
        <h2 className="h2-alt font-weight-bold">{title}</h2>
        <div className="issue-grid-groups">
          {map(PARENT_ISSUES, issue => {
            const childIssues = orderBy(filter(issues, {parentId: issue.id}), ['displayOrder']);
            return (
              <div className="issue-grid-group" key={issue.id}>
                <div className="col-group table-heading">
                  <h3 className="col col-title justify-content-start py-2">{issue.name}</h3>
                  <span className="col">A</span>
                  <span className="col">B</span>
                  <span className="col">C</span>
                  <span className="col">D</span>
                  <span className="col">E</span>
                </div>
                {map(childIssues, childIssue => {
                  let radioDotColor;
                  switch (RATINGS[childIssue.rating]) {
                    case 'A':
                    case 'B':
                    case 'C':
                      radioDotColor = 'green';
                      break;
                    case 'D':
                    case 'E':
                    case 'F':
                      radioDotColor = 'red';
                      break;
                    default:
                      radioDotColor = 'light';
                  }

                  return (
                    <div className="col-group" key={childIssue.id}>
                      <span className="col col-title justify-content-start py-2">
                        <span>{childIssue.name}</span>
                      </span>
                      <span className={'col col-rating col-rating-' + RATINGS[childIssue.rating].toLowerCase()}>
                        <a href="javascript:;">
                          <div
                            className="pdf-radio-button d-flex align-items-center justify-content-center"
                            style={{
                              borderRadius: '50%',
                              width: '20px',
                              height: '20px',
                              backgroundColor: radioDotColor === 'green' ? '#9ADCC8' : '#FE8080',
                            }}
                          >
                            <div
                              className="w-50 h-50"
                              style={{
                                borderRadius: '50%',
                                backgroundColor: radioDotColor === 'green' ? '#34B991' : '#FD0000',
                              }}
                            />
                          </div>
                          <span className="sr-only">Rating: {RATINGS[childIssue.rating]}</span>
                        </a>
                      </span>
                      <span className="col"></span>
                      <span className="col"></span>
                      <span className="col"></span>
                      <span className="col"></span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

IssueMatrix.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
