import {useSelector, RootStateOrAny} from 'react-redux';
import {useLocation} from 'react-router';
import {Theme} from 'v2/components/atoms/theme';
import {PageLayout} from 'v2/components/organisms/PageLayout';
import {MENU_ROUTES} from 'v2/constants/routes';
import {DASHBOARD_TAB_ROUTES} from 'v2/constants/tabRoutes';
import {USER_OPTIONS} from 'v2/constants/userOptions';
import {Callback, Middleware} from './types';

const Layout: React.FC<{}> = ({children}) => {
  const {pathname} = useLocation();

  const companyTitle = useSelector((state: RootStateOrAny) => state?.planDashboard?.details?.name);
  let background = Theme.colors.grey_1;

  return (
    <PageLayout
      menuOptions={USER_OPTIONS}
      pageRoutes={MENU_ROUTES}
      tabs={DASHBOARD_TAB_ROUTES}
      pageTitle={'Dashboard'}
      selectedTab={pathname}
      selectedPath={pathname}
      background={background}
      companyTitle={companyTitle}
    >
      {children}
    </PageLayout>
  );
};

export const pageLayout: Middleware = async (next: Callback) => {
  return <Layout>{await next()}</Layout>;
};
