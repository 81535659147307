import {sum, drop} from 'lodash';
import styled from 'styled-components/macro';
import {MANAGER_ROLES} from 'v2/constants/managers';
import {useStrategyFirm} from 'v2/hooks/useStrategyFirm';
import {useWorkforceDiversity} from 'v2/hooks/useWorkforceDiversity';
import {useRadioList} from 'v2/hooks/components/useRadioList';
import {AttachedWrapper, Container, FloatingWrapper} from 'v2/components/atoms/Containers';
import {ESGIntegrationView} from 'v2/components/organisms/EsgIntegrationView';
import {EsgAssociationsView} from 'v2/components/organisms/EsgAssociationsView';
import {WorkforceDiversityView} from 'v2/components/organisms/WorkforceDiversityView';
import {useParams} from 'react-router-dom';

const Spacer = styled.div<{space?: string}>`
  height: ${({space}) => space ?? '1rem'};
`;

const Associations = styled.div`
  padding: 0 3rem;
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.grey_1};
  height: 7rem;
  width: auto;
`;

export const UT1ManagersProfile = () => {
  const {strategyId = ''} = useParams<{strategyId: string}>();

  const RADIO_LABELS = ['All', 'Board & C-level', 'Professionals', 'Support'];
  const RADIO_VALUES = [MANAGER_ROLES.ALL, MANAGER_ROLES.BOARD, MANAGER_ROLES.PROFESSIONALS, MANAGER_ROLES.SUPPORT];

  const {selected, handleChange} = useRadioList(RADIO_VALUES, 0);

  const {data: strategy, isLoading: strategyIsLoading} = useStrategyFirm();
  const {data: workforceData, isLoading: workforceisLoading} = useWorkforceDiversity({filter: selected, strategyId});

  const hasGenderData =
    sum(
      drop(
        workforceData.genderData().map(a => a[1]),
        1,
      ),
    ) > 0;
  const hasEthnicityData =
    sum(
      drop(
        workforceData.ethnicityData().map(a => a[1]),
        1,
      ),
    ) > 0;

  const isLoading = workforceisLoading && workforceisLoading;

  return (
    <Container>
      <AttachedWrapper>
        <ESGIntegrationView {...strategy} isLoading={isLoading} />
        <Associations>
          <EsgAssociationsView associations={strategy.esgAssociations} isLoading={strategyIsLoading} />
        </Associations>
      </AttachedWrapper>
      <Spacer space="1rem" />
      <FloatingWrapper>
        <WorkforceDiversityView
          isLoading={isLoading}
          genderData={workforceData.genderData()}
          ethnicityData={workforceData.ethnicityData()}
          hasEthnicityData={hasEthnicityData}
          hasGenderedData={hasGenderData}
          radioLabels={RADIO_LABELS}
          radioValues={RADIO_VALUES}
          handleRadioChange={handleChange}
          workforceDiversity={strategy.workforceDiversity}
          selected={selected}
        />
      </FloatingWrapper>
      <Spacer space="8rem" />
    </Container>
  );
};
