import React, {useState} from 'react';
import {Button} from 'app/components';
import {useGetMissingLettersQuery, useDeleteMissingLettersMutation} from 'v2/redux/typeormEndpoints';

export const MissingEngagementLetters = () => {
  const [show, setShow] = useState(false);
  const {data, isLoading} = useGetMissingLettersQuery(null);

  const [deleteMissingLetters, {isLoading: isUpdating}] = useDeleteMissingLettersMutation();

  const handleToggle = () => {
    setShow(!show);
  };

  const handleDelete = () => {
    try {
      deleteMissingLetters();
    } catch (error) {
      console.log(error);
    }
  };

  const label = `${show ? 'Hide' : 'Show'} Missing Engagement Letters`;

  return (
    <div style={{paddingBottom: 16}}>
      <div style={{justifyContent: 'flex-start', display: 'flex'}}>
        <Button disabled={!data?.length} color="gray" onClick={handleToggle}>
          {!data?.length && !isLoading ? 'No Missing Engagement Letters' : label}
        </Button>
      </div>
      {show && (
        <div style={{paddingTop: 16}}>
          <h4 style={{marginBottom: 4}}>Missing Engagement Letters</h4>
          <p>
            Shows engagement letter links that are stored in the DB but missing from the <i>ha-platform-uploads-prod</i>{' '}
            S3 bucket.
          </p>
          {isLoading ? (
            <>Loading...</>
          ) : (
            <div style={{flexDirection: 'row', gap: '2rem', display: 'flex'}}>
              {data?.length > 0 ? (
                <Button disabled={!data?.length} color="red" onClick={handleDelete} style={{maxHeight: '3rem'}}>
                  Remove Missing Links
                </Button>
              ) : (
                <></>
              )}
              <div style={{flex: 1}}>
                {data?.length ? (
                  data?.map((item, index) => {
                    return (
                      <div key={index} style={{display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                        <div style={{flex: 1}}>{item.name}</div>
                        <div style={{flex: 3}}>{item.filePath}</div>
                      </div>
                    );
                  })
                ) : (
                  <em>No Missing Engagement Letters Found</em>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
