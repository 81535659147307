export const ETHNICITIES = {
  ASIAN: 'Asian',
  HISPANIC: 'Hispanic',
  BLACK: 'Black',
  WHITE: 'White',
  NATIVE_AMERICAN: 'Native American',
  PACIFIC_ISLANDER: 'Pacific Islander',
  OTHER: 'Other',
  MULTIPLE: 'Multiple',
};
