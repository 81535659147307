import {Theme} from '../theme';

export const NonDiscriminationPolicy = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.34445 6.97176C6.8942 5.77295 5.73177 4.96692 4.4513 4.96692C3.19822 4.96692 2.05804 5.73784 1.55092 7.03777C1.52807 7.09633 1.51264 7.15764 1.50273 7.21972L0.485824 13.5883C0.408709 14.0712 0.781806 14.5086 1.27087 14.5086C1.70994 14.5086 2.06587 14.8646 2.06587 15.3036V18.2795C2.06587 18.8318 2.51358 19.2795 3.06587 19.2795H5.82598C6.37826 19.2795 6.82598 18.8318 6.82598 18.2795V15.3086C6.82598 14.8668 7.18413 14.5086 7.62592 14.5086C8.11804 14.5086 8.49345 14.0685 8.41586 13.5826L7.38497 7.1264L7.34445 6.97176Z" />
      <path d="M6.82554 2.58143C6.82554 1.26602 5.76627 0.206543 4.45086 0.206543C3.13545 0.206543 2.06543 1.26602 2.06543 2.58143C2.06543 3.89684 3.13545 4.96686 4.45086 4.96686C5.76627 4.96686 6.82554 3.89684 6.82554 2.58143Z" />
      <path d="M16.7802 6.97176C16.3297 5.77295 15.1673 4.96692 13.8868 4.96692C12.6338 4.96692 11.4938 5.73781 10.9865 7.03771C10.9636 7.09631 10.9482 7.15766 10.9383 7.21978L9.92137 13.5883C9.84426 14.0712 10.2174 14.5086 10.7064 14.5086C11.1455 14.5086 11.5014 14.8646 11.5014 15.3036V18.2795C11.5014 18.8318 11.9491 19.2795 12.5014 19.2795H15.2617C15.814 19.2795 16.2617 18.8318 16.2617 18.2795V15.3085C16.2617 14.8667 16.6198 14.5086 17.0616 14.5086C17.5536 14.5086 17.929 14.0686 17.8514 13.5827L16.8205 7.1264L16.7802 6.97176Z" />
      <path d="M16.2728 2.58143C16.2728 1.26602 15.2028 0.206543 13.8874 0.206543C12.572 0.206543 11.502 1.26602 11.502 2.58143C11.502 3.89684 12.572 4.96686 13.8874 4.96686C15.2028 4.96686 16.2728 3.89684 16.2728 2.58143Z" />
    </svg>
  );
};
