import {isImpersonating} from 'app/utilities';
import {sumBy} from 'lodash';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useGetAggregatePercentileRankUniverseQuery, useGetFirmHoldingsByIdQuery} from 'v2/redux/typeormEndpoints';
import {filter} from 'v2/utilities/helpers/filter';
import {AggregatePercentileRankUniverse, FirmHolding} from 'v2/utilities/types/typeOrm';

export const useUT3DashboardHoldingDistribution = ({issueId = 1}: {issueId?: number}) => {
  const {
    data: universeData,
    isLoading: universeIsLoading,
    isError: universeIsError,
    isSuccess: universeIsSuccess,
  } = useGetAggregatePercentileRankUniverseQuery({issueId}, {skip: !issueId});

  const firmId = useSelector((state: any) =>
    isImpersonating() ? state.auth.impersonatedUser.firmId ?? 0 : state.auth.firmId ?? 0,
  );

  const {
    data: firmHoldingData,
    isLoading: firmHoldingIsLoading,
    isError: firmHoldingIsError,
    isSuccess: firmHoldingIsSuccess,
  } = useGetFirmHoldingsByIdQuery({firmId});

  const AggregatePercentileRankUniverse: AggregatePercentileRankUniverse[] = universeData ?? [];

  let FirmHoldings: FirmHolding[] = firmHoldingData ?? [];

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const filterParams = [];
  if (filters.type !== 'all') {
    filterParams.push({key: 'type', target: filters.type, operation: 'eq'});
  }
  if (filters.class !== '') {
    filterParams.push({key: 'class', target: filters.class, operation: 'eq'});
  }
  if (filters.region !== '') {
    filterParams.push({key: 'region', target: filters.region, operation: 'eq'});
  }

  //@ts-ignore
  FirmHoldings = filter({data: FirmHoldings, filterItems: filterParams});

  const graphTiers = (): {[key: string]: number} => ({
    '100-81': 0,
    '80-61': 0,
    '60-41': 0,
    '40-21': 0,
    '20-0': 0,
  });

  const holdingsTotal = useMemo(() => sumBy(FirmHoldings, o => Number(o.amountHeld)), [FirmHoldings]);

  const holdingsByWeight = () => {
    const total = holdingsTotal;
    let counts = graphTiers();

    (FirmHoldings as FirmHolding[]).forEach(({companyPctRank, amountHeld}) => {
      if (companyPctRank >= 0.81) {
        counts['100-81'] += Number(amountHeld) / total;
      } else if (companyPctRank >= 0.61) {
        counts['80-61'] += Number(amountHeld) / total;
      } else if (companyPctRank >= 0.41) {
        counts['60-41'] += Number(amountHeld) / total;
      } else if (companyPctRank >= 0.21) {
        counts['40-21'] += Number(amountHeld) / total;
      } else {
        counts['20-0'] += Number(amountHeld) / total;
      }
    });

    let roundedCounts = graphTiers();

    for (let tier in counts) {
      roundedCounts[tier] = Math.floor(counts[tier] * 100);
    }

    return Object.values(roundedCounts);
  };

  const holdingsByCount = () => {
    const total = FirmHoldings.length;
    let counts = graphTiers();

    (FirmHoldings as FirmHolding[]).forEach(({companyPctRank}) => {
      if (companyPctRank >= 0.81) {
        counts['100-81'] += 1;
      } else if (companyPctRank >= 0.61) {
        counts['80-61'] += 1;
      } else if (companyPctRank >= 0.41) {
        counts['60-41'] += 1;
      } else if (companyPctRank >= 0.21) {
        counts['40-21'] += 1;
      } else {
        counts['20-0'] += 1;
      }
    });

    let calculatedCounts = graphTiers();

    for (let tier in counts) {
      calculatedCounts[tier] = Math.floor(100 * (counts[tier] / total));
    }

    return Object.values(calculatedCounts);
  };
  const universeTotal = useMemo(
    () => sumBy(AggregatePercentileRankUniverse, o => Number(o.marketCap)),
    [AggregatePercentileRankUniverse],
  );
  const benchmarkByWeight = () => {
    const total = universeTotal;
    let counts = graphTiers();

    AggregatePercentileRankUniverse.forEach(({companyPctRank, marketCap}) => {
      if (companyPctRank >= 0.81) {
        counts['100-81'] += marketCap / total;
      } else if (companyPctRank >= 0.61) {
        counts['80-61'] += marketCap / total;
      } else if (companyPctRank >= 0.41) {
        counts['60-41'] += marketCap / total;
      } else if (companyPctRank >= 0.21) {
        counts['40-21'] += marketCap / total;
      } else {
        counts['20-0'] += marketCap / total;
      }
    });

    let roundedCounts = graphTiers();

    for (let tier in counts) {
      roundedCounts[tier] = Math.floor(counts[tier] * 100);
    }

    return Object.values(roundedCounts);
  };

  const benchmarkByCount = () => {
    const total = AggregatePercentileRankUniverse.length;
    let counts = graphTiers();

    AggregatePercentileRankUniverse.forEach(({companyPctRank}) => {
      if (companyPctRank >= 0.81) {
        counts['100-81'] += 1;
      } else if (companyPctRank >= 0.61) {
        counts['80-61'] += 1;
      } else if (companyPctRank >= 0.41) {
        counts['60-41'] += 1;
      } else if (companyPctRank >= 0.21) {
        counts['40-21'] += 1;
      } else {
        counts['20-0'] += 1;
      }
    });

    let calculatedCounts = graphTiers();

    for (let tier in counts) {
      calculatedCounts[tier] = Math.floor(100 * (counts[tier] / total));
    }

    return Object.values(calculatedCounts);
  };

  const holdings = {
    weight: holdingsByWeight(),
    count: holdingsByCount(),
  };

  const benchmark = {
    weight: benchmarkByWeight(),
    count: benchmarkByCount(),
  };

  const aggregatePercentileRankHoldings = useMemo(() => {
    return FirmHoldings.map(holding => {
      return {
        id: holding.companyId,
        company: holding.companyName ?? DATA_STATUS.NONE,
        sector: holding.sectorName ?? DATA_STATUS.NONE,
        held: holding.amountHeld ?? 0,
        pctRank: holding.companyPctRank ?? 0,
      };
    });
  }, [FirmHoldings]);

  return {
    data: {
      holdings,
      benchmark,
      aggregatePercentileRankHoldings,
    },
    isLoading: universeIsLoading || firmHoldingIsLoading,
    isSuccess: universeIsSuccess && firmHoldingIsSuccess,
    isError: universeIsError && firmHoldingIsError,
  };
};
