import {RootStateOrAny, useSelector} from 'react-redux';
import {get, isEmpty} from 'lodash';
import {isImpersonating} from 'app/utilities';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ROLE} from 'v2/constants/role';

export const usePageTitle = (): string => {
  const currentUser = useSelector((state: RootStateOrAny) =>
    get(state, isImpersonating() ? 'auth.impersonatedUser' : 'auth', {}),
  );

  const companyTitle = useSelector((state: RootStateOrAny) =>
    get(state, 'planDashboard.details.name', DATA_STATUS.NONE),
  );

  const clientTitle = !isEmpty(currentUser)
    ? `${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`
    : DATA_STATUS.NONE;

  const isUT3: boolean = currentUser?.roles?.length && currentUser?.roles?.includes(ROLE.USER_TYPE_3);

  return isUT3 ? clientTitle : companyTitle;
};
