import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import {v4 as uuidv4} from 'uuid';
import {isEmpty} from 'lodash';
import {ToolTip} from './index';

/**
 * This chart style is for numerical data, and is displayed as a line chart
 */
class IssueDataCardChartLegendItem extends Component {
  constructor(props) {
    super(props);

    this.uuid = uuidv4();
  }

  render() {
    const {color, label, type = 'block', description} = this.props;

    return (
      <div className="mx-2 d-flex align-items-center">
        <div
          className="d-inline-block mr-2"
          style={{
            height: type === 'line' ? '3px' : '12px',
            width: '30px',
            backgroundColor: color,
          }}
        />
        <span className="d-inline-block">{label}</span>
        {!isEmpty(description) && (
          <Fragment>
            <Icon id={`tooltip-${this.uuid}`} icon={faInfoCircle} className="ml-1 tooltip-info" />
            <ToolTip target={`#tooltip-${this.uuid}`}>{description}</ToolTip>
          </Fragment>
        )}
      </div>
    );
  }
}

IssueDataCardChartLegendItem.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
};

export {IssueDataCardChartLegendItem};
