import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {SubmissionError} from 'redux-form';

// Local Imports
import {Alert, OnboardingUt3ManagerForm, ProgressIndicator} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {updateFirmByInvitationCode, getInvitationByCode} from 'app/redux/onboardingUser';
import {upsertEmployees} from 'app/redux/firm';
import {withOnboardingUser} from 'app/utilities';
import {retrieveFirstFileKeyIfTrueFromArray, retrieveFirstFileKeyFromArray} from 'app/utilities/fileFunctions';

class OnboardingUt3ManagerPage extends Component {
  componentDidMount() {
    this.props.dispatch(getInvitationByCode(this.props.invitationCode));
  }
  render() {
    // Ensure OnboardingAccountForm doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          <h1 className="mb-4">{ROUTE.ONBOARDING_UT3_MANAGER.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <Fragment>
              <p className="mb-3">
                Please complete as much information as possible. You can edit the information below and add more
                information after your account is setup by going to the "Settings" portion of your account.
              </p>
              <OnboardingUt3ManagerForm {...this.props} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFileRemove: (fileInfo, multiUploader) => {
      const {invitationCode, onboardingSteps} = ownProps;
      const updateData = {
        onboardingStep: onboardingSteps.MANAGER.key,
        [multiUploader.props.name || multiUploader.props.id]: null,
      };

      return dispatch(updateFirmByInvitationCode(invitationCode, updateData));
    },
    onFileUpload: (fileInfo, s3file, multiUploader) => {
      const {invitationCode, onboardingSteps} = ownProps;
      const updateData = {
        onboardingStep: onboardingSteps.MANAGER.key,
        [multiUploader.props.name || multiUploader.props.id]: s3file.fileKey,
      };
      return dispatch(updateFirmByInvitationCode(invitationCode, updateData));
    },
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;
      const associations = [];
      if (values.hasPriAssociation) {
        associations.push('PRI');
      }

      if (values.hasGriAssociation) {
        associations.push('GRI');
      }

      if (values.otherAssociations) {
        associations.push(values.otherAssociations);
      }

      const updateData = {
        ...values,
        associations: associations.length > 0 ? associations : null,
        onboardingStep: onboardingSteps.MANAGER.key,
      };

      // convert arrays to files
      updateData.adv1FilePath = retrieveFirstFileKeyFromArray(updateData.adv1FilePath);
      updateData.adv2FilePath = retrieveFirstFileKeyFromArray(updateData.adv2FilePath);
      updateData.priDocFilePath = retrieveFirstFileKeyIfTrueFromArray(
        updateData.priDocFilePath,
        updateData.hasPriAssociation,
      );
      updateData.griDocFilePath = retrieveFirstFileKeyIfTrueFromArray(
        updateData.griDocFilePath,
        updateData.hasGriAssociation,
      );
      updateData.esgPolicyFilePath = retrieveFirstFileKeyFromArray(updateData.esgPolicyFilePath);
      updateData.esgPolicyEstablishedAt = updateData.esgPolicyEstablishedAt || null;

      return dispatch(updateFirmByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          if (values.employees) {
            dispatch(upsertEmployees(values.employees)).then(response => {
              if (response.hasError) {
                throw response.error;
              }
            });
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_UT3_STRATEGIES.path(invitationCode)));
        })
        .finally(() => {
          dispatch(getInvitationByCode(invitationCode));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const OnboardingUt3Manager = compose(
  withOnboardingUser,
  connect(null, mapDispatchToProps),
)(OnboardingUt3ManagerPage);
