import {get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {animateScroll} from 'react-scroll';

import {getPlanSelection, getPlanSelectionById} from 'app/redux/strategy';
import {getUnreadCount} from 'app/redux/userStrategyAlert';

class PayWallPage extends Component {
  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    const {strategyId, strategies, unreadCounts} = this.props;

    if (!strategies) {
      this.props.dispatch(getPlanSelection());
      this.props.dispatch(getUnreadCount());
    }

    if (strategyId) {
      this.props.dispatch(getPlanSelectionById(this.props.strategyId));
    }

    animateScroll.scrollToTop();
  }

  render() {
    const {currentPlan} = this.props;

    return (
      <div className="p-content-lg">
        <div className="card is-white mx-auto" style={{maxWidth: 800}}>
          <h1>{get(currentPlan, 'planSponsor.name')}</h1>
          <h3>{get(currentPlan, 'name')}</h3>
          <p>
            Thank you for joining the Harmony Analytics platform. A subscription is required to view the ESG ratings for
            this strategy. Please contact our support team for more information regarding our licenses.{' '}
          </p>
          <a href="mailto:support@harmonyanalytics.org" className="btn btn-primary btn-lg align-self-start">
            Contact Us
          </a>
        </div>
      </div>
    );
  }
}

PayWallPage.defaultProps = {currentPlan: {}};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: {strategyId},
    },
  } = ownProps;

  return {
    strategyId,
    currentPlan: get(state, 'strategy.currentPlan'),
    hasError: get(state, 'strategy.hasError'),
    strategies: get(state, 'strategy.planSelection', null),
  };
};

export const PayWall = connect(mapStateToProps)(PayWallPage);
