import styled from 'styled-components';
import {Body1, Title1, Title3} from '../atoms/Typeface';
import {FilterHeaderProps, SimpleHeaderProps} from '../interfaces/LayoutProps';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {Container, FlexContainer} from '../atoms/Containers';
import {Theme} from '../atoms/theme';
import {TagBar} from './TagBar';
import {FilterList} from '../atoms/icons';
import {useAppliedFilters} from 'v2/hooks/filters/useAppliedFilters';
import {env} from 'v2/utilities/helpers';

const Wrapper = styled.div`
  background: ${props => props.theme.gradients.harmony_blue};
  min-height: 8.5rem;
`;

const TitleFlexWrapper = styled.div`
  display: flex;
  min-height: 8.5rem;
`;

const CompanyTitle = styled(Title1)`
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1rem;
  color: ${props => props.theme.colors.white};
`;

const PageTitle = styled(Title3)`
  text-transform: none;
  line-height: 1rem;
  font-weight: normal !important;
  color: ${props => props.theme.colors.green};
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.grey_2};
  border-radius: ${props => props.theme.constants.border_radius};
  border: none;
  height: 2.75rem;
  width: 11.063rem;
  padding: 0 2.5rem;
  cursor: pointer;
`;

const FilterButtonLabel = styled(Body1)`
  display: block;
  white-space: nowrap;
  padding: 0 0.5rem;
  flex: 2;
`;

const FilterButtonFlex = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const SimpleHeader = ({companyTitle, pageTitle}: SimpleHeaderProps) => {
  return (
    <Wrapper>
      <Container margin="0 auto" maxWidth={Theme.constants.fixWidth} height="inherit" padding="0 1rem">
        <TitleFlexWrapper>
          <FlexContainer flex={1} direction="column" justify="center" padding="1rem 0 0 0">
            <CompanyTitle>{companyTitle ?? DATA_STATUS.NONE}</CompanyTitle>
            <PageTitle>{pageTitle}</PageTitle>
          </FlexContainer>
        </TitleFlexWrapper>
      </Container>
    </Wrapper>
  );
};

export const FilterHeader = ({companyTitle, pageTitle, filters, onOpenFilterClick}: FilterHeaderProps) => {
  const {getSelectedTags} = useAppliedFilters();

  return (
    <Wrapper>
      <FlexContainer margin="0 auto" height="inherit" padding="0 1rem">
        <FlexContainer flex={1} direction="column">
          <TitleFlexWrapper>
            <FlexContainer flex={1} direction="column" justify="center" padding="1rem 0 0 0">
              <CompanyTitle>{companyTitle ?? DATA_STATUS.NONE}</CompanyTitle>
              <PageTitle>{pageTitle}</PageTitle>
            </FlexContainer>
          </TitleFlexWrapper>
        </FlexContainer>
        <FlexContainer direction="column" justify="flex-end" padding="0 0 2.25rem">
          {env.isStaging() && (
            <FilterButton onClick={onOpenFilterClick}>
              <FilterButtonFlex>
                <FilterList size={18} />
              </FilterButtonFlex>
              <FilterButtonLabel>All Filters</FilterButtonLabel>
              <FilterButtonFlex />
            </FilterButton>
          )}
        </FlexContainer>
      </FlexContainer>
      {getSelectedTags().length > 0 && <TagBar tags={getSelectedTags()} />}
    </Wrapper>
  );
};
