import queryString from 'query-string';
import {isEmpty, pick} from 'lodash';

/**
 * Generate the querystring from the supplied parameters.
 * Assumes only a single search value is used
 * @param sortField The sortfield name
 * @param sortOrder The direction - should be asc or desc
 * @param page The page number to display
 * @param pageSize The number of rows per page
 * @param search The value to search on
 * @param filter The value to filter on
 * @returns {string} Does not include a ? character
 */
const setupQueryForLink = (sortField, sortOrder, page, pageSize, search, filter, additionalFields = []) => {
  return queryString.stringify({
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    filter,
    ...additionalFields,
  });
};

/**
  Assign fields to object based on props (query data)
  Return updated state if email is supplied since it is required
  * @param {Object} ownProps The props from the component
  * @param {Object} defaultValues The base default props
  * @param {Array} customPickList The list of properties to also return on the object (no type conversion)
  * @returns {Object} Contains default values or parameters from props
  */
const getPrametersFromQuery = (ownProps, defaultValues = null, customPickList = null) => {
  const values = queryString.parse(ownProps.location.search);
  const defaults = {
    search: '',
    page: 1,
    pageSize: 25,
    sortField: '',
    sortOrder: '',
    filter: '',
  };

  const newState = Object.assign({}, defaults, defaultValues);

  // if nothing in querystring, return defaultValues
  if (isEmpty(values)) {
    return newState;
  }

  if (values.page) {
    newState.page = Number(values.page);
  }
  if (values.pageSize) {
    newState.pageSize = Number(values.pageSize);
  }
  if (values.sortField) {
    newState.sortField = values.sortField;
  }
  if (values.sortOrder) {
    newState.sortOrder = values.sortOrder;
  }
  if (values.search) {
    newState.search = values.search;
  }
  if (values.filter) {
    newState.filter = values.filter;
  }

  // if pick list is specified
  if (!isEmpty(customPickList)) {
    const customValues = pick(values, customPickList);
    // if properties found and object setup
    if (!isEmpty(customValues)) {
      return Object.assign({}, newState, customValues);
    }
  }

  // Return updated object
  return newState;
};

export {getPrametersFromQuery, setupQueryForLink};
