import {isNumber} from 'lodash';

export const getOrdinal = (value: number | undefined) => {
  if (value && isNumber(value)) {
    // Always th
    if (value > 3 && value < 21) return 'th';

    // Return ordinal based on remainder
    switch (value % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  return '';
};
