import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {CONFIG} from '../../app/constants/config';

export const harmonyOnboardingApi = createApi({
  reducerPath: 'harmonyOnboardingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${CONFIG.API_URL}/`,
    // prepareHeaders: (headers, {getState}) => {
    //   headers.set('Authorization', `Bearer ${token}`);
    //   return headers;
    // },
  }),
  tagTypes: ['InvitationUser'],
  endpoints: builder => ({
    getInvitationUser: builder.query({
      query: invitationCode => {
        let route = `invitation/code/${invitationCode}`;
        return {
          url: route,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json, text/plain, */*',
          },
        };
      },
      providesTags: ['InvitationUser'],
    }),
    updateFirmInvitation: builder.mutation({
      query: ({invitationCode, values}) => {
        let route = `firm/invitationCode/${invitationCode}`;
        return {
          url: route,
          method: 'POST',
          body: values,
        };
      },
      invalidatesTags: ['InvitationUser'],
    }),
    updateUserInvitation: builder.mutation({
      query: ({invitationCode, values}) => {
        let route = `user/invitationCode/${invitationCode}`;
        return {
          url: route,
          method: 'POST',
          body: values,
        };
      },
      invalidatesTags: ['InvitationUser'],
    }),
  }),
});

export const {useGetInvitationUserQuery, useUpdateFirmInvitationMutation, useUpdateUserInvitationMutation} =
  harmonyOnboardingApi;
