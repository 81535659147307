import {CONFIG, ISSUE} from '../constants';

export const getIssueBadgeAssetUrl = issueType => {
  const issue = Number.isInteger(issueType) ? ISSUE[issueType] : issueType;
  const year = CONFIG.BADGE_ASSETS_YEAR; // TODO(HA-422): Update to be database-driven

  if (issue === undefined || issue.badgeAssetBaseName === undefined) {
    return undefined;
  }

  return `${CONFIG.ASSETS_URL}/badges/badge-${year}-${issue.badgeAssetBaseName}.svg`;
};
