import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError} from 'redux-form';

// Local Imports
import {Alert} from 'app/components';
import {AdminAddUserForm} from 'app/components/AdminAddUserForm';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {fetchOrganizationTypesIfNeeded} from 'app/redux/organizationType';
import {fetchPlanSponsors, fetchPlanSponsorsIfNeeded} from 'app/redux/planSponsor';
import {createUser} from 'app/redux/user';
import {toastSuccess} from 'app/utilities/toast';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';

const formName = 'admin-add-user';

const initialValues = {shouldSendInvite: true};

class AdminAddUserPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchPlanSponsorsIfNeeded({areUserCountsIncluded: true}));
    props.dispatch(fetchOrganizationTypesIfNeeded());
  }

  render() {
    const {
      match: {
        params: {userId},
      },
    } = this.props;
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 800}}>
          <Link
            className="d-inline-block mb-4 small"
            to={{
              pathname: ROUTE.ADMIN_USERS.path(),
              search: this.props.location.search,
            }}
          >
            <Icon icon={faArrowLeft} className="mr-2" />
            Return to {ROUTE.ADMIN_USERS.title}
          </Link>
          <h1 className="mb-2">{userId ? ROUTE.ADMIN_USERS_EDIT.title : ROUTE.ADMIN_USERS_ADD.title}</h1>
          {this.props.hasError ? (
            <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>
          ) : (
            <AdminAddUserForm {...this.props} formName={formName} userData={initialValues} />
          )}
        </div>
      </div>
    );
  }
}

AdminAddUserPage.propTypes = {match: PropTypes.object.isRequired};

const mapStateToProps = state => {
  const organizationTypes = get(state, 'organizationType.organizationTypes', []);
  const hasError = get(state, 'organizationType.hasError') || get(state, 'planSponsor.hasError');
  return {
    hasError,
    planSponsors: get(state, 'planSponsor.planSponsors'),
    organizationTypes,
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_USERS),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      return dispatch(createUser(values))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          if (values.shouldSendInvite) {
            toastSuccess('The user has been added and the invite has been sent.');
          } else {
            toastSuccess('The user has been added, however the invite still needs to be sent.');
          }

          animateScroll.scrollToTop();

          dispatch(fetchPlanSponsors({areUserCountsIncluded: true, isFetchSilent: true}));
          dispatch(reset(formName));

          if (!values.shouldAddAnotherOnSave) {
            dispatch(
              push({
                pathname: ROUTE.ADMIN_USERS.path(),
                search: ownProps.location.search,
              }),
            );
          }
        })
        .catch(error => {
          animateScroll.scrollToTop();
          if (error.message.trim() === 'email must be unique') {
            throw new SubmissionError({
              _error: FORM_MESSAGE.DEFAULT_ERROR_SUMMARY_MESSAGE,
              email: FORM_MESSAGE.EMAIL_EXISTS_ERROR_MESSAGE,
            });
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const AdminAddUser = connect(mapStateToProps, mapDispatchToProps)(AdminAddUserPage);
