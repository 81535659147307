import {assignWith, get, isEmpty, pick} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm} from 'redux-form';
import {Link} from 'react-router-dom';

// Local Imports
import {Button, FormFeedback} from 'app/components';
import {SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {AUTOCOMPLETE, CHARACTER_LIMIT, REGEX_PATTERN, ROUTE} from 'app/constants';
import {errorRequiredFields, getEmailPrimaryDomain, normalizeEmail} from 'app/utilities';

const validate = (values, allValues) => {
  const requiredFields = ['email', 'confirmEmail'];
  if (!get(allValues, 'userId')) {
    requiredFields.push('planSponsorId');
  }
  const errors = errorRequiredFields(values, requiredFields);

  if (!errors.email && !REGEX_PATTERN.VALID_EMAIL.test(values.email)) {
    errors.email = 'Invalid email address';
  } else if (getEmailPrimaryDomain(values.email) !== allValues.allowedEmailPrimaryDomain) {
    errors.email = `Email address must end with "${allValues.allowedEmailPrimaryDomain}"`;
  }

  if (values.confirmEmail !== values.email) {
    errors.confirmEmail = 'Must match Email Address';
  }

  return errors;
};

class SettingsUserManagerUT3FormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);
    const disableEmail = !!get(this.props, 'userId');

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <Field
            label="First Name"
            name="firstName"
            type="text"
            autoComplete={AUTOCOMPLETE.FIRST_NAME}
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.FIRST_NAME}
            className="col-3"
            disabled={true}
          />

          <Field
            label="Last Name"
            name="lastName"
            type="text"
            autoComplete={AUTOCOMPLETE.LAST_NAME}
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.LAST_NAME}
            className="col-3"
            disabled={true}
          />

          <Field
            label="Role"
            name="jobRole"
            type="text"
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.PROFILE_ROLE}
            className="col-3"
            disabled={true}
          />
          <div className="col-3"></div>
          <Field
            label="Email Address*"
            name="email"
            type="text"
            autoComplete="email"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3"
            disabled={disableEmail}
          />

          <Field
            label="Confirm Email Address*"
            name="confirmEmail"
            type="text"
            autoComplete="email"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3"
            disabled={disableEmail}
          />

          {!this.props.userId && (
            <Field
              label="Plan Sponsor (for invitation)*"
              name="planSponsorId"
              shouldInsertDefault={true}
              component={SelectFormGroup}
              optionValueProp="id"
              optionLabelProp="name"
              options={this.props.planSponsors}
              className="col-3"
            />
          )}
        </div>

        <div className="form-footer is-right mt-12">
          <Link to={ROUTE.SETTINGS_USERS.path()} className="btn btn-outline">
            Cancel
          </Link>
          <Button type="submit" solid>
            {this.props.userId ? 'Save User' : 'Save User & Send Invite'}
          </Button>
        </div>
      </form>
    );
  }
}

SettingsUserManagerUT3FormComponent.propTypes = {
  editUser: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {editUser = {}, editorEmail} = ownProps;

  const defaultValues = {};

  const allowedEmailPrimaryDomain = getEmailPrimaryDomain(editorEmail);

  // Pick which values will pre-populate the form.
  const userValues = pick(editUser, ['email', 'roles', 'jobRole', 'firstName', 'lastName']);

  if (userValues.email) {
    userValues.confirmEmail = userValues.email;
  }
  if (!isEmpty(userValues.roles)) {
    [userValues.userType] = userValues.roles;
    delete userValues.roles;
  }

  return Object.assign(
    {},
    ownProps,
    {allowedEmailPrimaryDomain},
    {initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue)},
  );
};

export const SettingsUserManagerUT3Form = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'settings-user-edit-ut3',
    validate,
  }),
)(SettingsUserManagerUT3FormComponent);
