/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
import React, {Component, Fragment} from 'react';
import {get, keyBy} from 'lodash';
import {PageContainer} from '../components';
import {RANKING_TIER, ORGANIZATION_SELECTORS, RATINGS} from '../../constants';
import {DATA_STATUS} from 'v2/constants/dataStatus';

export class ESGRatingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: ORGANIZATION_SELECTORS.UNIVERSE.value,
    };
  }

  renderPeerRanking(issueId, peerRankings, rankingOverrides) {
    if (rankingOverrides[issueId]) {
      return RANKING_TIER[rankingOverrides[issueId].value];
    }
    if (peerRankings[issueId]) {
      return peerRankings[issueId].tier !== 'INADEQUATE' ? (
        RANKING_TIER[peerRankings[issueId].tier]
      ) : (
        <span>{RANKING_TIER.INADEQUATE}</span>
      );
    }
    return DATA_STATUS.NONE;
  }

  renderIssueRating(issueId, issues) {
    const keyedIssues = keyBy(issues, 'id');
    const rating = RATINGS[keyedIssues[issueId].rating] || DATA_STATUS.NONE;
    switch (rating) {
      case 'A':
      case 'B':
      case 'C':
        return (
          <div className="report-page-esg-rating-score green d-flex align-items-center justify-content-center">
            {rating}
          </div>
        );
      case 'D':
      case 'E':
        return (
          <div className="report-page-esg-rating-score red d-flex align-items-center justify-content-center">
            {rating}
          </div>
        );
      default:
        return (
          <div className="report-page-esg-rating-score d-flex align-items-center justify-content-center">{rating}</div>
        );
    }
  }

  render() {
    const {companyName, issues, planDashboard, hidePeerRankings} = this.props;

    const rankingOverrides = keyBy(get(planDashboard, 'details.planSponsorRankingOverrides'), 'issueId');

    let peerRankings = {};

    if (planDashboard && planDashboard.details) {
      peerRankings =
        this.state.organization === ORGANIZATION_SELECTORS.UNIVERSE.value
          ? keyBy(planDashboard.details.peerRankings, 'issueId')
          : keyBy(planDashboard.details.peerRankingsInOrgType, 'issueId');
    }

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} companyName={companyName} pageNumber={4}>
        <h2>ESG by Issue</h2>
        {hidePeerRankings ? null : (
          <Fragment>
            <div>
              <h3 className="text-uppercase futura-normal">Peer Rankings</h3>
              <span className="futura-normal font-weight-light">
                Harmony utilizes a Taxonomic Classification System to more accurately organize and rate issues. Our
                classification system allows for significant volumes of information to be processed and displayed to our
                clients efficiently and consistently. Harmony's list is under constant review and may grow to include
                new issues.
              </span>
            </div>
            <div className="d-flex mt-9 futura-normal">
              <div className="flex-1 mr-9">
                <div className="d-flex justify-content-between pb-3 report-page-esg-rating-header green">
                  <span>Environmental Issues</span>
                  <span className="text-uppercase">{this.renderPeerRanking(1, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Environment</span>
                  <span className="text-uppercase">{this.renderPeerRanking(4, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Environmental Policies</span>
                  <span className="text-uppercase">{this.renderPeerRanking(5, peerRankings, rankingOverrides)}</span>
                </div>
              </div>
              <div className="flex-1 mr-9">
                <div className="d-flex justify-content-between pb-3 report-page-esg-rating-header blue">
                  <span>Social Issues</span>
                  <span className="text-uppercase">{this.renderPeerRanking(2, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Diversity & Inclusion</span>
                  <span className="text-uppercase">{this.renderPeerRanking(6, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Compensation Equity</span>
                  <span className="text-uppercase">{this.renderPeerRanking(7, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Workforce Well-being</span>
                  <span className="text-uppercase">{this.renderPeerRanking(8, peerRankings, rankingOverrides)}</span>
                </div>
              </div>
              <div className="flex-1 mr-9">
                <div className="d-flex justify-content-between pb-3 report-page-esg-rating-header navy">
                  <span>Governance Issues</span>
                  <span className="text-uppercase">{this.renderPeerRanking(3, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Board Governance</span>
                  <span className="text-uppercase">{this.renderPeerRanking(9, peerRankings, rankingOverrides)}</span>
                </div>
                <div className="d-flex justify-content-between pt-4 font-weight-light">
                  <span>Corporate Trust</span>
                  <span className="text-uppercase">{this.renderPeerRanking(10, peerRankings, rankingOverrides)}</span>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {hidePeerRankings ? (
          <span className="futura-normal font-weight-light">
            Harmony utilizes a Taxonomic Classification System to more accurately organize and rate issues. Our
            classification system allows for significant volumes of information to be processed and displayed to our
            clients efficiently and consistently. Harmony's list is under constant review and may grow to include new
            issues.
          </span>
        ) : null}
        <div className="report-page-esg-rating-summary pt-9">
          <h3 className="text-uppercase futura-normal pb-1">Issue Ratings</h3>
          <div>
            <h4 className="report-page-esg-issue-header pb-2">Environmental Issues</h4>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Environment</span> quantifies the direct impact an
                  organization has on global environmental health. Organizations are currently rated on their resource
                  consumption and contribution to climate change.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>Carbon Emissions</li>
                  <li>Energy Efficiency</li>
                  <li>Water Consumption</li>
                </ul>
              </div>
              <div className="flex-grow-1 d-flex justify-content-end pl-1">{this.renderIssueRating(4, issues)}</div>
            </div>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Environmental Policies</span> evaluates an organization’s
                  intention to operate sustainably. Organizations are currently rated on how well their internal
                  environmental policies and programs align with sustainability goals.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>Change Policy</li>
                  <li>Environmental Risk Management</li>
                  <li>Environmental Incentives</li>
                </ul>
              </div>
              <div className="d-flex flex-grow-1 justify-content-end pl-1">{this.renderIssueRating(5, issues)}</div>
            </div>
          </div>
        </div>
        <div className="report-page-esg-rating-summary">
          <div>
            <h4 className="report-page-esg-issue-header pb-2">Social Issues</h4>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Diversity & Inclusion</span> measures leadership diversity
                  within an organization. Organizations are currently rated by the inclusion of talent from diversified
                  backgrounds on their board of directors and executive and management teams.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>Woman on the Board</li>
                  <li>Woman in Leadership</li>
                  <li>Board Diversity</li>
                  <li>Leadership Diversity</li>
                </ul>
              </div>
              <div className="flex-grow-1 d-flex justify-content-end pl-1">{this.renderIssueRating(6, issues)}</div>
            </div>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Compensation Equity</span> quantifies how wealth generated by
                  an organization is distributed to stakeholders. Organizations are currently rated on the proportion of
                  compensation allocated to executives and employees.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>CEO Compensation Ratio</li>
                </ul>
              </div>
              <div className="d-flex flex-grow-1 justify-content-end pl-1">{this.renderIssueRating(7, issues)}</div>
            </div>
            <div className="d-flex">
              <div className="report-page-esg-rating-desc pr-1">
                <p>
                  <span className="text-uppercase strong">Workforce Well-Being</span> evaluates the employee protections
                  defined by an organization. Organizations are currently rated on the quality and performance of their
                  procedures and standards.
                </p>
              </div>
              <div className="report-page-esg-rating-bullets px-1">
                <ul>
                  <li>Injury Frequency</li>
                  <li>Human Rights</li>
                  <li>Non-discrimination</li>
                  <li>Health & Safety</li>
                  <li>Child & Forced Labor</li>
                </ul>
              </div>
              <div className="d-flex flex-grow-1 justify-content-end pl-1">{this.renderIssueRating(8, issues)}</div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}
