import {Theme} from '../theme';

export const ClimateChangePolicy = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2593_10513)">
        <path d="M10.3023 6.2063C7.36984 6.2063 4.98438 8.59177 4.98438 11.5243C4.98438 12.2157 5.11609 12.8869 5.36594 13.5085C6.03719 13.2038 6.77922 13.0285 7.56016 13.0144C8.72359 11.5951 10.4017 10.7115 12.2444 10.5558C12.887 9.9638 13.6178 9.48755 14.4241 9.13646C14.5956 9.06193 14.7695 8.99349 14.9448 8.93161C14.0322 7.3013 12.2861 6.2063 10.3023 6.2063Z" />
        <path d="M24.0002 16.336C24.0002 19.8887 21.1098 22.7785 17.5567 22.7785H7.66375C5.35938 22.7785 3.48438 20.904 3.48438 18.5991C3.48438 16.2948 5.35938 14.4198 7.66375 14.4198C7.86016 14.4198 8.05563 14.4334 8.24875 14.4605C9.23734 12.8916 10.9473 11.9377 12.8308 11.9377C12.8364 11.9377 12.842 11.9377 12.8477 11.9382C14.065 10.6337 15.7619 9.89258 17.5567 9.89258C21.1098 9.89258 24.0002 12.7829 24.0002 16.336Z" />
        <path d="M4.68227 6.89532L3.01928 5.23154C2.74478 4.95694 2.74488 4.51172 3.01956 4.23718C3.29411 3.96272 3.73933 3.96272 4.01392 4.23746L5.67691 5.90124C5.95141 6.17583 5.95131 6.62105 5.67663 6.8956C5.40217 7.16996 4.95691 7.17014 4.68227 6.89532Z" />
        <path d="M14.9287 6.89562C14.6541 6.62112 14.6539 6.1759 14.9284 5.90126L16.5914 4.23748C16.8659 3.96279 17.3111 3.96265 17.5858 4.23719C17.8604 4.51169 17.8606 4.95691 17.5861 5.23155L15.9231 6.89534C15.6487 7.16988 15.2035 7.17021 14.9287 6.89562Z" />
        <path d="M3.0555 12.2272H0.703125C0.314812 12.2272 0 11.9124 0 11.524C0 11.1357 0.314812 10.8209 0.703125 10.8209H3.0555C3.44381 10.8209 3.75863 11.1357 3.75863 11.524C3.75863 11.9124 3.44381 12.2272 3.0555 12.2272Z" />
        <path d="M10.3027 4.98001C9.91442 4.98001 9.59961 4.6652 9.59961 4.27689V1.92456C9.59961 1.53625 9.91442 1.22144 10.3027 1.22144C10.691 1.22144 11.0059 1.53625 11.0059 1.92456V4.27694C11.0059 4.66525 10.691 4.98001 10.3027 4.98001Z" />
      </g>
      <defs>
        <clipPath id="clip0_2593_10513">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
