import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {compose, withProps} from 'recompose';
import moment from 'moment';

// Local Imports
import {AUTOCOMPLETE, CHARACTER_LIMIT, STATES, USER_PREFIXES, YES_NO_OPTIONS} from 'app/constants';
import {normalizeExtensionlessPhone, normalizeZip} from 'app/utilities';
import {Button, FormFeedback} from '.';
import {SelectFormGroup, TextFormGroup} from './forms';

const AdminSecurityEditFormComponent = ({
  error,
  handleSubmit,
  isErrorFormFeedbackVisible,
  security,
  canWrite,
  initialize,
}) => {
  React.useEffect(() => {
    initialize(security);
  }, []);

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <FormFeedback message={error} visible={isErrorFormFeedbackVisible} />
      <div className="form-grid">
        <h3>CEO Name</h3>
        <Field
          label="Prefix"
          name="ceoPrefix"
          component={SelectFormGroup}
          shouldInsertDefault={true}
          options={USER_PREFIXES}
          className="col-1"
          disabled={!canWrite}
        />

        <Field
          label="First Name"
          name="ceoFirstName"
          type="text"
          autoComplete="given-name"
          maxLength={CHARACTER_LIMIT.FIRST_NAME}
          component={TextFormGroup}
          className="col-2"
          disabled={!canWrite}
        />

        <Field
          label="Last Name"
          name="ceoLastName"
          type="text"
          autoComplete="family-name"
          maxLength={CHARACTER_LIMIT.LAST_NAME}
          component={TextFormGroup}
          className="col-2"
          disabled={!canWrite}
        />

        <Field
          label="Suffix"
          name="ceoSuffix"
          type="text"
          autoComplete="suffix"
          maxLength={CHARACTER_LIMIT.NAME_SUFFIX}
          component={TextFormGroup}
          className="col-1"
          placeholder={'Ex: "CFA"'}
          disabled={!canWrite}
        />

        <h3 className="mt-2">Address Information</h3>

        <Field
          label="Street Address"
          name="addressLine1"
          type="text"
          component={TextFormGroup}
          maxLength={CHARACTER_LIMIT.ADDRESS_LINES}
          autoComplete={AUTOCOMPLETE.ADDRESS_LINE_1}
          className="min-h-0 mb-3"
          disabled={!canWrite}
        />
        <Field
          name="addressLine2"
          type="text"
          component={TextFormGroup}
          maxLength={CHARACTER_LIMIT.ADDRESS_LINES}
          autoComplete={AUTOCOMPLETE.ADDRESS_LINE_2}
          className="min-h-0 mb-3"
          disabled={!canWrite}
        />
        <Field
          name="addressLine3"
          type="text"
          component={TextFormGroup}
          className="min-h-0 mb-3"
          maxLength={CHARACTER_LIMIT.ADDRESS_LINES}
          disabled={!canWrite}
        />
        <Field
          name="addressLine4"
          type="text"
          component={TextFormGroup}
          className="min-h-0 mb-4"
          maxLength={CHARACTER_LIMIT.ADDRESS_LINES}
          disabled={!canWrite}
        />
        <Field
          label="City"
          name="city"
          type="text"
          component={TextFormGroup}
          autoComplete={AUTOCOMPLETE.CITY}
          maxLength={CHARACTER_LIMIT.CITY}
          className="col-2 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <Field
          label="State/US Territory"
          name="state"
          component={SelectFormGroup}
          autoComplete={AUTOCOMPLETE.STATE}
          options={STATES}
          shouldInsertDefault={true}
          className="col-2 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <Field
          label="Zip"
          name="zip"
          type="text"
          component={TextFormGroup}
          autoComplete={AUTOCOMPLETE.ZIP}
          normalize={normalizeZip}
          className="col-2 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <Field
          label="Phone Number"
          name="phone"
          type="text"
          component={TextFormGroup}
          normalize={normalizeExtensionlessPhone}
          autoComplete={AUTOCOMPLETE.PHONE}
          placeholder="(       )        -"
          className="col-2 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <div className="form-group col-4" />

        <div className="col-2 pr-3 mt-2">
          <strong>Include in Empowering Change</strong>
        </div>
        <Field
          name="isEmpoweringChange"
          component={SelectFormGroup}
          options={YES_NO_OPTIONS}
          className="col-1 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <div className="col-3 mt-2">
          {security.empowerChangeIncludeAt && moment(security.empowerChangeIncludeAt).format('MMM D, YYYY hh:mm A')}
        </div>

        <div className="col-2 pr-3 mt-2">
          <strong>Contacted by HA</strong>
        </div>
        <Field
          name="hasBeenContactedToEmpowerChange"
          component={SelectFormGroup}
          options={YES_NO_OPTIONS}
          className="col-1 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <div className="col-3 mt-2">
          {security.contactedToEmpowerChangeAt &&
            moment(security.contactedToEmpowerChangeAt).format('MMM D, YYYY hh:mm A')}
        </div>

        <div className="col-2 pr-3 mt-2">
          <strong>Mark Responded</strong>
        </div>
        <Field
          name="hasRespondedToEmpowerChange"
          component={SelectFormGroup}
          options={YES_NO_OPTIONS}
          className="col-1 min-h-0 mb-4"
          disabled={!canWrite}
        />
        <div className="col-3 mt-2">
          {security.respondedToEmpowerChangeAt &&
            moment(security.respondedToEmpowerChangeAt).format('MMM D, YYYY hh:mm A')}
        </div>

        <div className="form-footer is-right">
          <Button type="submit" disabled={!canWrite}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

AdminSecurityEditFormComponent.propTypes = {
  form: PropTypes.string.isRequired,
  security: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

// Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
const withErrorFormFeedbackVisible = withProps(({error, invalid, submitFailed, security}) => {
  return {isErrorFormFeedbackVisible: !!error || (!!submitFailed && invalid)};
});

export const AdminSecurityEditForm = compose(reduxForm(), withErrorFormFeedbackVisible)(AdminSecurityEditFormComponent);
