import styled from 'styled-components';
import {useState, useEffect, useMemo} from 'react';
import {TitleXS, TitleL} from '../../../components/atoms/Typeface';
import {Theme} from '../../../components/atoms/theme';
import {SingleBarChart} from 'v2/components/organisms/SingleBarChart';
import {useHoldings} from 'v2/hooks/useHoldings';
import {useEngagements} from 'v2/hooks/useEngagements';
import {useIssueTypes} from 'v2/hooks/useIssueTypes';
import {ColorBarChart} from 'v2/components/molecules/ColoredBarChart';
import {BorderlessTextButton} from 'v2/components/molecules/Button';
import {ManagersEngagementsDoughnutChart} from 'v2/components/molecules/AnnotatedDoughnutChart';
import {values, groupBy, map, pick, flatten} from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import {TextBlock, MediaBlock, TextRow, RectShape, RoundShape} from 'react-placeholder/lib/placeholders';
import {AttachedWrapper, Container, FlexContainer, TableCell} from 'v2/components/atoms/Containers';
import {Briefcase, Globe, Users} from 'v2/components/atoms/icons';
import {ModalTableWithStateButtons} from 'v2/components/organisms/ModalTableWithStateButtons';
import {formatMillions} from 'v2/utilities/helpers';

const FlexRow = styled.div`
  display: flex;
`;

const Column1 = styled.div`
  width: 30%;
  padding-right: 65px;
`;

const Column2 = styled.div`
  border-left: ${props => `1px solid ${props.theme.colors.grey_3}`};
  padding-left: 65px;
  width: 25%;
`;

const Column3 = styled.div`
  padding-left: 65px;
  width: 45%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  flex: 1;
`;

const ChartContentContainer = styled.div`
  padding: 1rem 2rem 1.6rem 2rem;
  height: 25.75rem;
`;

const ChartFooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 0px 0px 8px 8px;
  height: 5rem;
`;

const ChartHeaderContainer = styled.div`
  margin-bottom: 65px;
`;

const ChartTitle = styled(TitleXS)`
  color: ${props => props.theme.colors.dark};
  margin-bottom: 10px;
`;

const ChartCount = styled(TitleL)`
  color: ${props => props.theme.colors.dark};
`;

const ReactPlaceholderEngagement = () => (
  <div>
    <FlexRow>
      <Column1>
        <RectShape color={Theme.colors.grey_2} style={{width: 175, height: 20}} />
        <RectShape color={Theme.colors.grey_2} style={{width: 75, height: 60, marginTop: 10}} />
        <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 300, marginTop: 50}} />
      </Column1>
      <Column2>
        <ChartHeaderContainer>
          <RectShape color={Theme.colors.grey_2} style={{width: 175, height: 20}} />
          <RectShape color={Theme.colors.grey_2} style={{width: 75, height: 60, marginTop: 10}} />
          <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 300, marginTop: 50}} />
        </ChartHeaderContainer>
      </Column2>

      <Column3>
        <ChartFooterContainer>
          <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 20}} />
          <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 20}} />
          <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 20}} />
        </ChartFooterContainer>
        <ChartContentContainer>
          <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 350}} />
        </ChartContentContainer>
      </Column3>
    </FlexRow>
  </div>
);

export const UT1ManagersEngagement = () => {
  const companyColumns = useMemo(
    () => [
      {
        Header: () => (
          <TableCell>
            <Container padding="0 30px">Companies</Container>
          </TableCell>
        ),
        Cell: ({value}: {value: string}) => (
          <TableCell>
            <Container padding="0 30px">{value}</Container>
          </TableCell>
        ),
        style: {
          width: '50%',
        },
        accessor: 'company',
      },
      {
        Header: () => <TableCell>Sectors</TableCell>,
        Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
        accessor: 'sector',
      },
      {
        Header: () => (
          <TableCell>
            <Container padding="0 30px"> Held</Container>
          </TableCell>
        ),
        Cell: ({value}: {value: number}) => (
          <TableCell>
            <FlexContainer justify="flex-end">{formatMillions(value, 1, '$')}</FlexContainer>
          </TableCell>
        ),
        accessor: 'held',
      },
    ],
    [],
  );

  const {companyHoldingsCount} = useHoldings();

  const [modalIsVisible, setIsModalVisable] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);
  const handleClose = () => setIsModalVisable(false);

  const {
    data: engagementData,
    isLoading,
    totalEngagementCount,
    getIssuesEngagedCount,
    getIssuesEngagedByCategory,
    getIssuesEngagedByNestedSubcategory,
    getCompaniesEngagedForIssue,
  } = useEngagements();

  const {data: issueTypes} = useIssueTypes();

  const [isLoaded, setIsLoaded] = useState(false);

  const issuesEngagedCount = getIssuesEngagedCount(engagementData);
  const donutChartData = getIssuesEngagedByCategory();

  const [isEnvHovered, setIsEnvHovered] = useState(false);
  const [isSocHovered, setIsSocHovered] = useState(false);
  const [isGovHovered, setIsGovHovered] = useState(false);

  const [isAnimated, setIsAnimated] = useState({
    onComplete: () => {
      //@ts-ignore
      setIsAnimated(false);
    },
  });

  const onBarChartClick = (e: any) => {
    const myChart = e.chart;
    const selectedElement = myChart.getElementsAtEventForMode(e, 'nearest', {intersect: true}, true)[0]?.index;
    if (selectedElement || selectedElement === 0) {
      setSelectedButton(selectedElement);
      setIsModalVisable(true);
    }
  };

  useEffect(() => {
    if (donutChartData.length > 1) {
      setIsLoaded(true);
    }
  }, [donutChartData]);

  const barChartColors = [[Theme.colors.green], [Theme.colors.blue], [Theme.colors.blue_6]];

  const barChartCategories = [issueTypes.ENVIRONMENTAL, issueTypes.SOCIAL, issueTypes.GOVERNANCE];

  const barChartIssueTypes = [
    issueTypes.CARBON_EMISSIONS,
    issueTypes.ENERGY_USE,
    issueTypes.WATER_CONSUMPTION,
    issueTypes.CEO_COMPENSATION_RATIO,
    issueTypes.WOMEN_IN_LEADERSHIP,
    issueTypes.WOMEN_ON_BOARD,
    issueTypes.BOARD_INDEPENDENCE,
  ];

  const categoryWithIssues: string[] = [];
  let firstCategoryWithIssues: number | null = null;
  donutChartData.forEach((category, i) => {
    if (category[1] !== 0) {
      categoryWithIssues.push(category[0]);
    }

    // Select the first non empty category are the first selected
    if (parseInt(category[1]) > 0 && firstCategoryWithIssues === null) {
      firstCategoryWithIssues = i - 1;
    }
  });
  const initialSelectedCategory = firstCategoryWithIssues || 0;
  const [selectedCategory, setSelectedCategory] = useState(initialSelectedCategory);

  const getBarChartLabels = (barChartIssueTypes: any[]) => {
    let issuesByCategory = values(groupBy(barChartIssueTypes, 'topLevelParentId'));
    let barChartLabels: any[] = [];

    issuesByCategory.forEach(category => {
      let categoryArray: any[] = [];
      category.forEach(subcategory => {
        categoryArray.push(subcategory.name);
      });
      barChartLabels.push(categoryArray);
    });

    return barChartLabels;
  };

  const barChartLabels = getBarChartLabels(barChartIssueTypes);

  const [sector, setSector] = useState({value: null, label: 'All Sectors'});
  const modalLabels = ['ENVIRONMENTAL - ISSUES ENGAGED', 'SOCIAL - ISSUES ENGAGED', 'GOVERNANCE - ISSUES ENGAGED'];
  const modalButtonLabels = [
    ['Carbon Emission', 'Energy Use', 'Water Consumption'],
    ['CEO Compensation Ratio', 'Women in Leadership', 'Women on Board'],
    ['Board Independence'],
  ];
  const issueIds = [
    [issueTypes.CARBON_EMISSIONS.id, issueTypes.ENERGY_USE.id, issueTypes.WATER_CONSUMPTION.id],
    [issueTypes.CEO_COMPENSATION_RATIO.id, issueTypes.WOMEN_IN_LEADERSHIP.id, issueTypes.WOMEN_ON_BOARD.id],
    [issueTypes.BOARD_INDEPENDENCE.id],
  ];
  return (
    <AttachedWrapper>
      <CardContainer>
        <ReactPlaceholder ready={!isLoading} customPlaceholder={<ReactPlaceholderEngagement />}>
          <FlexRow>
            <Column1>
              <SingleBarChart
                title={'Companies Engaged'}
                titleCount={totalEngagementCount}
                colors={[Theme.colors.harmony]}
                colorsTotals={[Theme.colors.grey_3]}
                data={totalEngagementCount}
                dataTotals={companyHoldingsCount}
                labels={['']}
                isAnimated={isAnimated}
              />
            </Column1>
            <Column2>
              <ChartHeaderContainer>
                <ChartTitle>{'Issues Engaged'}</ChartTitle>
                <ChartCount>{issuesEngagedCount}</ChartCount>
              </ChartHeaderContainer>
              {isLoaded && categoryWithIssues.length > 1 && (
                <ManagersEngagementsDoughnutChart
                  data={donutChartData}
                  colors={[Theme.colors.green, Theme.colors.blue, Theme.colors.blue_6]}
                  hideLabel
                  singleLegend
                  usesExternalState={true}
                  selectedArc={selectedCategory + 1}
                  setSelectedArc={setSelectedCategory}
                  sum={getIssuesEngagedByNestedSubcategory(barChartIssueTypes)[selectedCategory].reduce(
                    (partialSum, a) => partialSum + a,
                    0,
                  )}
                  largeLegend={true}
                />
              )}
            </Column2>

            <Column3>
              <ChartFooterContainer>
                {categoryWithIssues.includes(issueTypes.ENVIRONMENTAL.name) && (
                  <BorderlessTextButton
                    text={
                      <>
                        <Globe
                          size={20}
                          color={isEnvHovered || selectedCategory === 0 ? Theme.colors.green : Theme.colors.dark}
                          style={{marginRight: '8px', marginBottom: '-5px'}}
                        />
                        {barChartCategories[0].name ?? ''}
                      </>
                    }
                    onClick={() => setSelectedCategory(0)}
                    hoverColor={Theme.colors.green}
                    isSelected={selectedCategory === 0}
                    onMouseEnter={() => setIsEnvHovered(true)}
                    onMouseLeave={() => setIsEnvHovered(false)}
                  />
                )}
                {categoryWithIssues.includes(issueTypes.SOCIAL.name) && (
                  <BorderlessTextButton
                    text={
                      <>
                        <Users
                          size={20}
                          color={isSocHovered || selectedCategory === 1 ? Theme.colors.blue : Theme.colors.dark}
                          style={{marginRight: '8px', marginBottom: '-5px'}}
                        />
                        {barChartCategories[1].name ?? ''}
                      </>
                    }
                    onClick={() => setSelectedCategory(1)}
                    hoverColor={Theme.colors.blue}
                    isSelected={selectedCategory === 1}
                    onMouseEnter={() => setIsSocHovered(true)}
                    onMouseLeave={() => setIsSocHovered(false)}
                  />
                )}
                {categoryWithIssues.includes(issueTypes.GOVERNANCE.name) && (
                  <BorderlessTextButton
                    text={
                      <>
                        <Briefcase
                          size={20}
                          color={isGovHovered || selectedCategory === 2 ? Theme.colors.blue_6 : Theme.colors.dark}
                          style={{marginRight: '8px', marginBottom: '-5px'}}
                        />
                        {barChartCategories[2].name ?? ''}
                      </>
                    }
                    onClick={() => setSelectedCategory(2)}
                    hoverColor={Theme.colors.blue_6}
                    isSelected={selectedCategory === 2}
                    onMouseEnter={() => setIsGovHovered(true)}
                    onMouseLeave={() => setIsGovHovered(false)}
                  />
                )}
              </ChartFooterContainer>
              <ChartContentContainer>
                <ColorBarChart
                  labels={barChartLabels[selectedCategory]}
                  data={getIssuesEngagedByNestedSubcategory(barChartIssueTypes)[selectedCategory]}
                  colors={barChartColors[selectedCategory]}
                  breakLabels
                  barThickness={45}
                  onClick={(event: any) => onBarChartClick(event)}
                />
              </ChartContentContainer>
            </Column3>
          </FlexRow>
        </ReactPlaceholder>
        <ModalTableWithStateButtons
          isOpen={modalIsVisible}
          setClose={handleClose}
          data={getCompaniesEngagedForIssue(issueIds[selectedCategory][selectedButton], sector)?.holdings}
          columns={companyColumns}
          title={modalLabels[selectedCategory]}
          buttons={modalButtonLabels[selectedCategory]}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          setSector={setSector}
          sector={sector}
          sectorOptions={getCompaniesEngagedForIssue(issueIds[selectedCategory][selectedButton], sector)?.sectors}
        />
      </CardContainer>
    </AttachedWrapper>
  );
};
