export const percentileRankDescription =
  'The Percentile Rank represents the weighted average of the percentile rank of companies in your portfolio. The median company would fall at the 50th percentile and a lower risk company would be in the top 20th percentile.';

export const bannerDescription =
  'Low percentile ranks indicate the highest performing companies with the lowest risk exposures.';

export const bannerLabel = 'Ranking System';

export const riskDescription =
  'Low percentile ranks indicate the highest performing companies with the lowest risk exposures.';

export const esgRiskDescription =
  'Represents the weighted average of the percentile rank of companies in your portfolio. The median company would fall at the 50th percentile and a high risk company would be in the 80th percentile.';

export const esgCompanyDescription =
  'Represents the percentile rank of a company. A median company falls at the 50th percentile and a high risk company is in the 80th percentile.';

export const esgPercentileRankDescription =
  'Represents the weighted average of the percentile rank of companies in your portfolio. The median company would fall at the 50th percentile and a high risk company would be in the 80th percentile.';
