import {Link, useParams} from 'react-router-dom';
import {useGetStrategySecurityHoldingsQuery} from 'v2/redux/harmonyApi';
import {StrategyHoldings} from 'v2/utilities/types/typeOrm';
import {EmbeddedLink} from 'v2/components/atoms/Typeface';
import {holdingOptions} from 'v2/constants/selectOptions';
import {map, orderBy, sum} from 'lodash';
import {ROUTE} from 'app/constants';

export const useHoldingsTable = (filter: string) => {
  const {strategyId = ''} = useParams<{strategyId: string}>();
  const {isLoading, isSuccess, isError, data} = useGetStrategySecurityHoldingsQuery({strategyId});

  const sortColumn = filter === holdingOptions[0].value ? 'market_value' : 'pctRank';
  const sortOrder = filter === holdingOptions[1].value ? 'asc' : 'desc';
  const allHoldings = ((data ?? []) as StrategyHoldings[]).map(datum => ({
    ...datum,
    market_value: Number(datum.market_value),
  }));

  const totalMarketValue = sum(map(allHoldings, objs => objs.market_value));

  const filteredHoldings = allHoldings.filter(
    holding =>
      holding.market_value / totalMarketValue > 0.001 &&
      (filter === holdingOptions[0].value || !holding.carbon_emissions_is_predicted),
  );

  const holdings = orderBy(filteredHoldings, sortColumn, sortOrder).slice(0, 5);

  const formattedData = () =>
    holdings.map(holding => ({
      company_id: holding.company_id,
      name: holding.name,
      engaged: Number(holding.engaged) > 0,
      transparency: holding.transparency,
      rating: holding.rating,
      rank: Math.round(holding.pctRank * 100),
      held: holding.market_value,
      weight: (holding.market_value / totalMarketValue).toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 1,
      }),
    }));

  return {
    data: formattedData,
    isError,
    isLoading,
    isSuccess,
  };
};
