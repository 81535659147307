import {useState, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import {ChevronLeft, ChevronRight} from 'v2/components/atoms/icons';

import {Theme} from 'v2/components/atoms/theme';
import {ellipses, usePagination} from 'v2/utilities/hooks/usePagination';
import {FlexContainer} from '../atoms/Containers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PageContainer = styled.div`
  flex: 1;
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.875rem 0;
  flex: 1;
`;

const NumberButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  font-size: 0.938rem;
  width: 1.563rem;
  height: 1.563rem;
  margin: 0 0.063rem;
  cursor: pointer;
  color: ${props => props.theme.colors.black};
  border-radius: ${props => props.theme.constants.border_radius};
  &:hover {
    background: ${props => props.theme.colors.grey_2};
  }
`;

const ActiveNumberButton = styled(NumberButton)`
  background: ${props => props.theme.colors.harmony};
  color: ${props => props.theme.colors.white};
  &:hover {
    background: ${props => props.theme.colors.harmony};
    color: ${props => props.theme.colors.white};
  }
`;

const IconButton = styled(NumberButton)`
  background: none;
  &:hover {
    background: none;
  }
`;

const EllipsesButton = styled(IconButton)`
  cursor: default;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 2.563rem;
  border-bottom: 1px solid ${props => props.theme.colors.grey_6};
  color: ${props => props.theme.colors.dark};
`;

const Title = styled(ListItem)`
  color: ${props => props.theme.colors.grey_5};
`;

const LinkContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const PaginatedList: React.FC<{
  title?: string;
  totalCount: number;
  data: string[];
  pageSize: number;
  onPageChange?: (data: any) => any;
  siblingCount: number;
  bottomLink?: any;
}> = ({title, totalCount, data, pageSize, onPageChange, siblingCount, bottomLink = null}) => {
  const [currentPage, setCurrentPage] = useState(1);

  let paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [totalCount, data, pageSize, siblingCount, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  useEffect(() => {
    if (onPageChange) {
      onPageChange({
        totalCount,
        data,
        pageSize,
        siblingCount,
        currentPage,
        currentData,
      });
    }
  }, [totalCount, data, pageSize, siblingCount, currentPage, currentData]);

  const last = paginationRange?.[paginationRange.length - 1];

  const nextIsDisabled = last ? currentPage >= last : true;

  const previousIsDisabled = currentPage <= 1;

  function next() {
    if (!nextIsDisabled) {
      setCurrentPage(currentPage + 1);
    }
  }

  function previous() {
    if (!previousIsDisabled) {
      setCurrentPage(currentPage - 1);
    }
  }

  function seek(val: number) {
    setCurrentPage(val);
  }

  return (
    <Container>
      <PageContainer>
        {title && <Title as="span">{title}</Title>}
        <List>
          {currentData.map(data => (
            <ListItem>{data}</ListItem>
          ))}
        </List>
      </PageContainer>
      <FlexContainer>
        {!!bottomLink && <LinkContainer>{bottomLink}</LinkContainer>}
        <ControlContainer>
          <IconButton onClick={previous} disabled={previousIsDisabled}>
            <ChevronLeft size={19} color={previousIsDisabled ? Theme.colors.dark : Theme.colors.grey_3} />
          </IconButton>
          {paginationRange?.length === 0 && <ActiveNumberButton>1</ActiveNumberButton>}
          {paginationRange?.map(current => {
            if (current === currentPage) {
              return <ActiveNumberButton onClick={() => seek(Number(current))}>{current}</ActiveNumberButton>;
            }

            if (current === ellipses) {
              return <EllipsesButton>{current}</EllipsesButton>;
            }

            return <NumberButton onClick={() => seek(Number(current))}>{current}</NumberButton>;
          })}
          <IconButton onClick={next} disabled={nextIsDisabled}>
            <ChevronRight size={19} color={nextIsDisabled ? Theme.colors.dark : Theme.colors.grey_3} />
          </IconButton>
        </ControlContainer>
      </FlexContainer>
    </Container>
  );
};
