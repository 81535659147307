// A wrapper component that handles pulling a selected segment out of Doughnut chart.
// By itself it does nothing; state must be maintained in the containing component.
// Note: props.children must consist of exactly one function.
//
// Usage:
//
// <DoughnutSelector datasetIndex={datasetIndex} dataIndex={dataIndex} onSegmentClick={this.onSegmentClick}>
//   {({props, options}) => (
//     <Doughnut {...props} data={EXAMPLE_CHART_DATA} options={merge({}, EXAMPLE_CHART_OPTIONS, options)} />
//   )}
// </DoughnutSelector>

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {debounce, isNil} from 'lodash';

import {pullOutChartJsSegment} from 'app/utilities';

class DoughnutSelector extends Component {
  constructor(props) {
    super(props);

    this.didIntroAnimationComplete = false;

    this.onElementsClick = this.onElementsClick.bind(this);
    this.onAnimationComplete = this.onAnimationComplete.bind(this);

    this.debouncedHandleResize = debounce(this.handleResize.bind(this), 400);
    // this.setRef = this.setRef.bind(this);
    this.chart = this.props.chartRef;

    window.addEventListener('resize', this.debouncedHandleResize);
  }

  onElementsClick(elements) {
    // when the chart is clicked, pass the info along to the container
    if (elements.length > 0) {
      const datasetIndex = elements[0]._datasetIndex;
      const dataIndex = elements[0]._index;
      this.props.onSegmentClick({datasetIndex, dataIndex});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  handleResize() {
    const {dataIndex, datasetIndex} = this.props;
    this.pullOutSegment({datasetIndex, dataIndex});
  }

  shouldComponentUpdate(nextProps) {
    const {dataIndex, datasetIndex} = nextProps;

    // if selected segment has changed, then adjust the geometry
    if (dataIndex !== this.props.dataIndex || datasetIndex !== this.props.datasetIndex) {
      this.pullOutSegment({datasetIndex, dataIndex});
    }

    return true;
  }

  pullOutSegment({datasetIndex, dataIndex}) {
    const chartInstance = this.chart;
    // chartInstance.update();
    if (!isNil(datasetIndex) && !isNil(dataIndex)) {
      pullOutChartJsSegment({chartInstance, datasetIndex, dataIndex});
    }
  }

  onAnimationComplete() {
    // after first intro animation is complete, pull out the selected segment
    if (!this.didIntroAnimationComplete) {
      const {dataIndex, datasetIndex} = this.props;
      this.pullOutSegment({datasetIndex, dataIndex});
      this.didIntroAnimationComplete = true;
    }
  }

  // setRef(element) {
  //   console.log({ element });
  //   if (element) {
  //     this.chart = element;
  //     this.props.chartRef && this.props.chartRef(element);
  //   }
  // }

  render() {
    return this.props.children({
      props: {
        ref: this.chart,
        getElementAtEvent: this.onElementsClick,
      },
      options: {animation: {onComplete: this.onAnimationComplete}},
    });
  }
}

DoughnutSelector.propTypes = {
  chartRef: PropTypes.func,
  children: PropTypes.func.isRequired,
  dataIndex: PropTypes.number,
  datasetIndex: PropTypes.number,
  onSegmentClick: PropTypes.func.isRequired,
};

export {DoughnutSelector};
