import {FORM_MESSAGE} from 'app/constants';
import React from 'react';
import {useHistory} from 'react-router';
import {Alert} from 'reactstrap';
import styled from 'styled-components/macro';
import {FlexContainer, Container} from 'v2/components/atoms/Containers';
import {OnboardingProgressController} from 'v2/components/organisms/OnboardingProgressController';
import {PageFooter} from 'v2/components/organisms/PageFooter';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {Callback, Middleware} from './types';

const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${'3.75rem'});
`;

const ContentContainer = styled(Container)`
  flex: 1;
  max-width: 100%;
`;

const Layout: React.FC<{}> = ({children}) => {
  const history = useHistory();
  const {onboardingStepsArray, hasUnexpectedError, isFetching} = useOnboardingUser();

  return (
    <OnboardingContainer>
      <FlexContainer flex={1} padding="4.1875rem 0" margin="0 6.25rem" gap="4.75rem" fullWidth>
        <Container padding="1.75rem 0 0 0.875rem">
          <OnboardingProgressController
            steps={onboardingStepsArray ?? []}
            onStepClick={step => {
              // @ts-ignore
              history.push(step?.path);
            }}
          />
        </Container>
        <FlexContainer flex={1} direction="column" fullWidth>
          {!isFetching && hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <ContentContainer>{children}</ContentContainer>
          )}
        </FlexContainer>
      </FlexContainer>
      <PageFooter />
    </OnboardingContainer>
  );
};

export const onboardingLayoutMiddleware: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
