import {find, head, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const FormGroup = ({
  input,
  label,
  children,
  className,
  inputClassName,
  isAsync,
  typeahead,
  multiple,
  options = [],
  labelKey = 'label',
  meta: {touched, error},
}) => {
  const wrapperProps = {};
  const inputProps = {};

  if (typeahead) {
    // split props on Typeahead between the wrapper and input
    Object.assign(wrapperProps, {
      className: cn({'is-invalid': touched && error}),
      labelKey,
      multiple,
      options,
    });
    if (isAsync || multiple) {
      Object.assign(wrapperProps, {
        selected: input.value || [],
        onChange: input.onChange,
      });
    } else {
      Object.assign(wrapperProps, {
        defaultInputValue: input.value,
        onBlur: event => {
          if (!isEmpty(options)) {
            const validOption = find(options, {name: event.target.value});

            if (validOption) {
              input.onChange(validOption.name);
            } else {
              input.onChange('');
            }
          } else {
            input.onChange(event.target.value);
          }
        },
        onChange: value => {
          if (!isEmpty(value)) {
            input.onChange(head(value)[labelKey]);
          }
        },
      });
    }
    Object.assign(inputProps, input, {className: cn(inputClassName, {'is-invalid': touched && error})});
    delete inputProps.value;
    delete inputProps.onBlur;
    delete inputProps.onFocus;
    delete inputProps.onChange;
    delete inputProps.onDragStart;
    delete inputProps.onDrop;
  } else {
    Object.assign(inputProps, input, {className: cn('form-control', inputClassName, {'is-invalid': touched && error})});
  }

  return (
    <div className={cn('form-group', className)}>
      {label && <label>{label}</label>}
      {children(inputProps, wrapperProps)}
      <div className="invalid-feedback">{touched && error}</div>
    </div>
  );
};

FormGroup.propTypes = {
  children: PropTypes.func,
  input: PropTypes.object,
  label: PropTypes.string,
  isAsync: PropTypes.bool,
  typeahead: PropTypes.bool,
  multiple: PropTypes.bool,
  meta: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  inputClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

export {FormGroup};
