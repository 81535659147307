import {Theme} from '../theme';

export const WomenOnTheBoard = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 26" fill={color} stroke={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7829 11.2213C17.989 14.4264 17.989 19.641 14.7829 22.8462C11.5768 26.0513 6.36074 26.0513 3.1546 22.8462C-0.0515332 19.641 -0.0515324 14.4264 3.15461 11.2213C4.45524 9.9211 6.08712 9.14845 7.78076 8.90319L7.78076 6.70426L6.15492 6.70426C5.4989 6.70426 4.967 6.17259 4.967 5.51662C4.967 4.86066 5.49889 4.32898 6.15492 4.32898L7.78076 4.32898L7.78076 1.93767C7.78082 1.28169 8.31271 0.750028 8.96878 0.750028C9.6248 0.750028 10.1567 1.2817 10.1567 1.93767L10.1567 4.32898L11.7826 4.32898C12.4386 4.32898 12.9705 4.86066 12.9705 5.51662C12.9705 6.17259 12.4386 6.70426 11.7826 6.70426L10.1567 6.70426L10.1567 8.90314C11.8504 9.14845 13.4823 9.9211 14.7829 11.2213ZM4.83457 12.901C2.55516 15.1797 2.55516 18.8879 4.83457 21.1665C7.11398 23.4452 10.8234 23.4453 13.103 21.1665C15.3824 18.8879 15.3824 15.1797 13.103 12.901C10.8235 10.6223 7.11402 10.6223 4.83457 12.901Z"
        strokeWidth="0.5"
      />
    </svg>
  );
};
