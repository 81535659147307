import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DATA_STATUS} from 'v2/constants/dataStatus';

class DisplayInputField extends Component {
  constructor(props) {
    super(props);

    if (props.pending) {
      this.pendingClass = ' futura-gray';
    }
    this.setValue = this.setValue.bind(this);
  }

  setValue(event) {
    const {onChange} = this.props;

    let newValue = event.target.value;

    if (newValue === 'true') {
      newValue = true;
    } else if (newValue === 'false') {
      newValue = false;
    }

    if (onChange) {
      onChange(newValue);
    }
  }

  render() {
    const {form, editing, displayClassName, inputClassName, type = 'text', options, value} = this.props;

    if (!editing) {
      return type === 'select' ? (
        <span className={displayClassName + this.pendingClass}>
          {(options.find(o => o.value === value) && options.find(o => o.value === value).name) || DATA_STATUS.NONE}
        </span>
      ) : (
        <span className={displayClassName + this.pendingClass}>{value !== undefined ? value : DATA_STATUS.NONE}</span>
      );
    }

    return type !== 'select' ? (
      <input
        form={form}
        className={inputClassName + this.pendingClass}
        type={type}
        value={value?.toString().replace(',', '')}
        onChange={this.setValue}
      />
    ) : (
      <select className={inputClassName + this.pendingClass} onChange={this.setValue} value={value}>
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    );
  }
}

DisplayInputField.propTypes = {
  form: PropTypes.string,
  editing: PropTypes.bool.isRequired,
  value: PropTypes.any,
  displayClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
};

export {DisplayInputField};
