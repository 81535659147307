import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const Checkbox = ({children, className, disabled, input, id, label, meta: {touched, error}}) => (
  <div className={cn('custom-control custom-checkbox', className)}>
    <input
      {...input}
      disabled={disabled}
      type="checkbox"
      checked={!!input.value}
      className={cn('custom-control-input', {'is-invalid': touched && error})}
      id={id || input.name}
    />
    <label className="custom-control-label" htmlFor={id || input.name}>
      {label} {touched && error && `(${error})`}
    </label>
    {children}
  </div>
);

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  meta: PropTypes.object,
};

export {Checkbox};
