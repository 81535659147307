import {faBullhorn} from '@fortawesome/free-solid-svg-icons';
import {getAlertNotificationText, getParams} from 'app/components/Header';
import {HeaderDisplay} from 'app/components/Header/HeaderDisplay';
import {publicMenuProps} from 'app/components/Header/menuProps';
import {ROLE, ROUTE} from 'app/constants';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {getSessionJwt, isImpersonating, toast} from 'app/utilities';
import {find, get, includes, intersection, sumBy} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import styled from 'styled-components/macro';
import {Callback, Middleware} from './types';

const Spacer = styled.div`
  height: 4.4375rem;
`;
const Layout: React.FC<{}> = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const unreadCounts = useSelector(state => get(state, 'userStrategyAlert.unreadCounts', []) || []);
  const locationData = useSelector(state => get(state, 'router.location.pathname', []) || []);
  const authUser = getSessionJwt(true)?.contents;
  const showNotification = intersection(authUser?.roles, [ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]).length > 0;
  let menuProps = publicMenuProps;

  const paramList: any = getParams(locationData);
  const strategyId = Number(paramList.strategyId);

  const secondaryHeaderProps = {
    currentStrategyId: strategyId ?? 0,
    strategies: null,
    isStrategyMenuVisible: false,
  };

  let unreadCount = 0;
  let didToastPlanAlerts = false;
  let didToastStrategyAlerts: any[] = [];
  let notificationToastTimeout: any = null;
  if (includes(authUser?.roles, ROLE.USER_TYPE_3)) {
    unreadCount = get(find(unreadCounts, {strategyId: Number(strategyId)}), 'count', 0);
    if (unreadCount > 0 && !didToastStrategyAlerts[strategyId]) {
      didToastStrategyAlerts[strategyId] = true;
      notificationToastTimeout = setTimeout(() => {
        toast({
          text: getAlertNotificationText(unreadCount),
          faIcon: faBullhorn,
          callback: () => {
            history.push(ROUTE.STRATEGY_ALERTS.path(strategyId));
          },
        });
      }, 3500);
    }
  } else {
    unreadCount = sumBy(unreadCounts, 'count');
    if (unreadCount > 0 && !didToastPlanAlerts) {
      didToastPlanAlerts = true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      notificationToastTimeout = setTimeout(() => {
        toast({
          text: getAlertNotificationText(unreadCount),
          faIcon: faBullhorn,
          callback: () => {
            history.push(ROUTE.ALERTS.path());
          },
        });
      }, 3500);
    }
  }

  function handleLogoutClick() {
    if (isImpersonating()) {
      dispatch(removeImpersonationTokensUser());
    } else {
      dispatch(logout());
    }
  }

  return (
    <>
      <Spacer />
      <HeaderDisplay
        {...menuProps}
        {...secondaryHeaderProps}
        onLogoutClick={handleLogoutClick}
        showNotifications={showNotification}
        notificationCount={unreadCount}
        // showLogout={!!authUser}
      />
      {children}
    </>
  );
};

export const oldNavigationHeaderMiddleware: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
