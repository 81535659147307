/* eslint-disable react/jsx-pascal-case */
import {useState} from 'react';
import styled from 'styled-components';
import {TooltipProps} from '../interfaces/Tooltip';
import {Info_1} from './Info';
import {Info, InfoCircle} from '../atoms/icons';
import {Theme} from '../atoms/theme';

const InlineIconButton = styled.button.attrs({type: 'button'})`
  position: relative;
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
`;

export const Tooltip: React.FC<TooltipProps> = ({children, position, size, backgroundColor, color, variant}) => {
  const [isVisible, setIsVisible] = useState(false);

  const Icon = () => {
    switch (variant) {
      case 'outline':
        return <InfoCircle size={24} color={Theme.colors.harmony} />;
      case 'default':
      default:
        return <Info size={24} color={Theme.colors.grey_3} />;
    }
  };
  return (
    <InlineIconButton onMouseOver={() => setIsVisible(true)} onMouseOut={() => setIsVisible(false)}>
      <Icon />
      <Info_1 position={position} isVisible={isVisible} size={size} backgroundColor={backgroundColor} color={color}>
        {children}
      </Info_1>
    </InlineIconButton>
  );
};
