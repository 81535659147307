import {ROOT_NODE} from 'v2/constants/rootNode';
import {FilteredOptionsProps, FilterListOptionProps, NodeIds} from '../types/components/FilterList';
import {Strategy} from '../types/typeOrm';

function setDisabledOptions(id: NodeIds, fetchedOptions: Record<string, string>) {
  const options = ROOT_NODE.nodes?.find(node => node.id === id)?.options || [];
  return options.map(option => ({
    ...option,
    ...(!fetchedOptions.hasOwnProperty(option.value) ? {disabled: false} : {}),
  }));
}

export const getFilterOptions = (holdings: any, strategies: Strategy[]) => {
  const regionOptions: any = {};
  const classOptions: any = {};
  const typeOptions: Record<string, string> = {};
  const strategyOptions: any = {};
  const managerOptions: any = {};
  const indexOptions: any = {};
  const productOptions: any = {};

  holdings.forEach((holding: any) => {
    const {class: newClassOption, type, region} = holding;
    if (region && !regionOptions.hasOwnProperty(region)) {
      // @ts-ignore
      regionOptions[`${region}`] = region;
    }

    if (newClassOption && !newClassOption.hasOwnProperty(region)) {
      // @ts-ignore
      classOptions[`${newClassOption}`] = newClassOption;
    }

    if (type && !typeOptions.hasOwnProperty(type)) {
      // @ts-ignore
      typeOptions[`${type}`] = type;
    }
  });

  strategies.forEach(strategy => {
    const {name, firm: manager, marketIndex: index} = strategy;
    if (strategy && !productOptions.hasOwnProperty(strategy)) {
      // @ts-ignore
      productOptions[`${name}`] = strategy;
    }

    if (manager && !managerOptions.hasOwnProperty(manager)) {
      // @ts-ignore
      managerOptions[`${manager.name}`] = manager;
    }

    if (index && !indexOptions.hasOwnProperty(index)) {
      // @ts-ignore
      indexOptions[`${index.name}`] = index;
    }
  });

  const strategyOptionsArray: FilterListOptionProps[] = [];
  for (const key of Object.keys(strategyOptions)) {
    const newStrategyOption: FilterListOptionProps = {label: key, value: strategyOptions[key].id};
    strategyOptionsArray.push(newStrategyOption);
  }

  const productOptionsArray: FilterListOptionProps[] = [];
  for (const key of Object.keys(productOptions)) {
    const newProductOption: FilterListOptionProps = {label: key, value: productOptions[key].id};
    productOptionsArray.push(newProductOption);
  }

  const managerOptionsArray: FilterListOptionProps[] = [];
  for (const key of Object.keys(managerOptions)) {
    const newManagerOption: FilterListOptionProps = {label: key, value: managerOptions[key].id};
    managerOptionsArray.push(newManagerOption);
  }

  const indexOptionsArray: FilterListOptionProps[] = [];
  for (const key of Object.keys(indexOptions)) {
    const newIndexOption: FilterListOptionProps = {label: key, value: indexOptions[key].id};
    indexOptionsArray.push(newIndexOption);
  }

  //TODO: find out if we need to know what's disabled
  // when we filter on the ESG page
  const combinedFilterOptions: FilteredOptionsProps = {
    type: setDisabledOptions('type', typeOptions),
    class: setDisabledOptions('class', classOptions),
    region: setDisabledOptions('region', regionOptions),
    strategy: setDisabledOptions('strategy', strategyOptions),
    manager: managerOptionsArray,
    index: indexOptionsArray,
    product: productOptionsArray,
  };

  return combinedFilterOptions;
};
