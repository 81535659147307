import {MANAGER_ETHNICITIES, MANAGER_GENDERS, MANAGER_ROLES} from 'v2/constants/managers';
import {ETHNICITIES as ETHNICITY_LABELS} from 'v2/constants/ethnicities';
import {useGetFirmEmployeesQuery} from 'v2/redux/typeormEndpoints';
import {FirmEmployee} from 'v2/utilities/types/typeOrm';
import {sum} from 'lodash';

export const useWorkforceDiversityByFirmId = ({filter, firmId}: {filter: string; firmId: number | string}) => {
  const {isLoading, isSuccess, isError, data: firmData} = useGetFirmEmployeesQuery({firmId}, {skip: !firmId});

  const employees = (firmData ?? []) as FirmEmployee[];

  const reportingYear = employees.reduce((acc, {year}) => {
    if (acc < year) {
      return year;
    }

    return acc;
  }, 0);

  const filteredEmployees = employees.filter(({category, year}) => {
    if (year === reportingYear) {
      if (filter === MANAGER_ROLES.ALL) {
        return true;
      }

      return filter === category;
    }

    return false;
  });

  const ethnicityData = () => {
    const supportedEthnicities = [
      MANAGER_ETHNICITIES.ASIAN,
      MANAGER_ETHNICITIES.HISPANIC,
      MANAGER_ETHNICITIES.BLACK,
      MANAGER_ETHNICITIES.WHITE,
      MANAGER_ETHNICITIES.OTHER,
    ];

    if (!employees.length) {
      return [
        ['Ethnicity', 'Percent'],
        ['Asian', 0],
        ['Hispanic', 0],
        ['Black', 0],
        ['White', 0],
        ['Other', 0],
      ];
    }

    let ethnicityCounts = {
      [MANAGER_ETHNICITIES.ASIAN]: 0,
      [MANAGER_ETHNICITIES.HISPANIC]: 0,
      [MANAGER_ETHNICITIES.BLACK]: 0,
      [MANAGER_ETHNICITIES.WHITE]: 0,
      [MANAGER_ETHNICITIES.OTHER]: 0,
    };

    const total = sum(filteredEmployees.map(({count}) => count));

    filteredEmployees.forEach(({ethnicity, count}) => {
      if (supportedEthnicities.includes(ethnicity)) {
        ethnicityCounts[ethnicity] += count;
      } else {
        ethnicityCounts[MANAGER_ETHNICITIES.OTHER] += count;
      }
    });

    const data = Object.keys(ethnicityCounts).map((key: string) => {
      const ethnicityCalc = Math.round((ethnicityCounts[key] / total) * 100);
      // @ts-ignore
      return [ETHNICITY_LABELS[key], isNaN(ethnicityCalc) ? 0 : ethnicityCalc];
    });

    const headers = ['Ethnicity', 'Percent'];

    const orderedData = data.sort((a, b) => {
      if (a[1] === b[1]) {
        return 0;
      } else {
        return a[1] > b[1] ? -1 : 1;
      }
    });

    orderedData.unshift(headers);

    return orderedData;
  };

  const genderData = () => {
    if (!employees) {
      return [
        ['Gender', 'Percent'],
        ['Women', 0],
        ['Men', 0],
      ];
    }

    let men = sum(filteredEmployees.filter(({gender}) => gender === MANAGER_GENDERS.MALE).map(({count}) => count));
    let women = sum(filteredEmployees.filter(({gender}) => gender === MANAGER_GENDERS.FEMALE).map(({count}) => count));
    let total = sum(filteredEmployees.map(({count}) => count));

    const menCalculated = Math.round((men / total) * 100);
    const womenCalculated = Math.round((women / total) * 100);

    const data = [
      ['Women', isNaN(womenCalculated) ? 0 : womenCalculated],
      ['Men', isNaN(menCalculated) ? 0 : menCalculated],
    ];

    const orderedData = data.sort((a, b) => {
      if (a[1] === b[1]) {
        return 0;
      } else {
        return a[1] > b[1] ? -1 : 1;
      }
    });

    orderedData.unshift(['Gender', 'Percent']);

    return orderedData;
  };

  const formattedData = {
    ethnicityData,
    genderData,
  };

  return {
    isError,
    isLoading,
    isSuccess,
    data: formattedData,
  };
};
