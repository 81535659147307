import React from 'react';

export const HoldingsToggle = ({checked, label, onChange, inputProps, className}) => {
  return (
    <div
      className={
        className
          ? `dashboard-toggle d-flex align-items-center ${className}`
          : 'dashboard-toggle d-flex align-items-center'
      }
    >
      <label htmlFor="toggleInput" className="toggle-label pr-2">
        {label}
      </label>
      <label className="switch">
        <input id="toggleInput" type="checkbox" value={checked} checked={checked} onChange={onChange} {...inputProps} />
        <span className="slider" />
      </label>
    </div>
  );
};

HoldingsToggle.defaultProps = {
  checked: true,
  label: 'Toggle',
};
