import {createSlice} from '@reduxjs/toolkit';
import {ISSUES} from '../constants/issues';

const defaultValues = {
  environmental: {
    category: ISSUES.ENVIRONMENTAL,
    topic: ISSUES.ENVIRONMENT,
    subTopic: null,
    tertiaryTopic: null,
  },
  social: {
    category: ISSUES.SOCIAL,
    topic: ISSUES.DIVERSITY_AND_INCLUSION,
    subTopic: null,
    tertiaryTopic: null,
  },
  governance: {
    category: ISSUES.GOVERNANCE,
    topic: ISSUES.BOARD_GOVERNANCE,
    subTopic: null,
    tertiaryTopic: null,
  },
};

const initialState = {
  ...defaultValues.environmental,
};

export const esgFilterSlice = createSlice({
  name: 'esgFilters',
  initialState,
  reducers: {
    updateCategory: (state, action) => {
      switch (action.payload.id) {
        case ISSUES.GOVERNANCE.id:
          state.category = defaultValues.governance.category;
          state.topic = defaultValues.governance.topic;
          state.subTopic = defaultValues.governance.subTopic;
          state.tertiaryTopic = defaultValues.governance.tertiaryTopic;
          break;
        case ISSUES.SOCIAL.id:
          state.category = defaultValues.social.category;
          state.topic = defaultValues.social.topic;
          state.subTopic = defaultValues.social.subTopic;
          state.tertiaryTopic = defaultValues.social.tertiaryTopic;
          break;
        case ISSUES.ENVIRONMENTAL.id:
        default:
          state.category = defaultValues.environmental.category;
          state.topic = defaultValues.environmental.topic;
          state.subTopic = defaultValues.environmental.subTopic;
          state.tertiaryTopic = defaultValues.environmental.tertiaryTopic;
          break;
      }
    },
    updateTopic: (state, action) => {
      state.topic = action.payload;
      state.subTopic = null;
      state.tertiaryTopic = null;
    },
    updateSubTopic: (state, action) => {
      state.subTopic = action.payload;
      state.tertiaryTopic = null;
    },
    updateTertiaryTopic: (state, action) => {
      state.tertiaryTopic = action.payload;
    },
  },
});

export const {updateCategory, updateTopic, updateSubTopic, updateTertiaryTopic} = esgFilterSlice.actions;

export default esgFilterSlice.reducer;
