import {ROUTE} from './route';

export const ONBOARDING_STEPS = {
  UserType1: {
    ACCOUNT: {
      key: 'ACCOUNT',
      route: ROUTE.ONBOARDING_CREATE_ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: 'PROFILE',
      route: ROUTE.ONBOARDING_PROFILE_V2,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: 'PLAN',
      route: ROUTE.ONBOARDING_PLAN_INFO,
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: 'ESG',
      route: ROUTE.ONBOARDING_ESG_STRATEGY,
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
    },
    MANAGERS: {
      key: 'MANAGERS',
      route: ROUTE.ONBOARDING_INVESTMENT_MANAGERS,
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
    },
    FINALIZE: {
      key: 'FINALIZE',
      route: ROUTE.ONBOARDING_FINALIZE,
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
  UserType2: {
    ACCOUNT: {
      key: 'ACCOUNT',
      route: ROUTE.ONBOARDING_CREATE_ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: 'PROFILE',
      route: ROUTE.ONBOARDING_PROFILE,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: 'PLAN',
      route: ROUTE.ONBOARDING_PLAN_INFO,
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: 'ESG',
      route: ROUTE.ONBOARDING_ESG_STRATEGY,
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
    },
    MANAGERS: {
      key: 'MANAGERS',
      route: ROUTE.ONBOARDING_INVESTMENT_MANAGERS,
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
    },
    FINALIZE: {
      key: 'FINALIZE',
      route: ROUTE.ONBOARDING_FINALIZE,
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
  UserType3: {
    ACCOUNT: {
      key: 'ACCOUNT',
      route: ROUTE.ONBOARDING_CREATE_ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: 'PROFILE',
      route: ROUTE.ONBOARDING_PROFILE,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    MANAGER: {
      key: 'MANAGER',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'Manager',
      isComplete: false,
      stepOrder: 3,
    },
    MANAGER_INFORMATION: {
      key: 'MANAGER_INFORMATION',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'Manager Information',
      isComplete: false,
      isSubStep: true,
      stepOrder: 4,
    },
    ESG_INTGRATION: {
      key: 'ESG_INTGRATION',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'ESG Integration',
      isComplete: false,
      isSubStep: true,
      stepOrder: 5,
    },
    ESG_ASSOCIATIONS: {
      key: 'ESG_ASSOCIATIONS',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'ESG Associations',
      isComplete: false,
      isSubStep: true,
      stepOrder: 6,
    },
    // STRATEGIES: {
    //   key: 'STRATEGIES',
    //   route: ROUTE.ONBOARDING_UT3_STRATEGIES,
    //   shortTitle: 'Strategies',
    //   isComplete: false,
    //   stepOrder: 4,
    // },
    // INVITE: {
    //   key: 'INVITE',
    //   route: ROUTE.ONBOARDING_UT3_INVITE,
    //   shortTitle: 'Invite',
    //   isComplete: false,
    //   stepOrder: 5,
    // },
    FINALIZE: {
      key: 'FINALIZE',
      route: ROUTE.ONBOARDING_FINALIZE,
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
};

export const ONBOARDING_STEPS_V2 = {
  UserType1: {
    ACCOUNT: {
      key: '1',
      onboardingStepName: 'ACCOUNT',
      route: ROUTE.ONBOARDING_CREATE_ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      route: ROUTE.ONBOARDING_PROFILE,
      onboardingStepName: 'PROFILE',
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: '3',
      route: ROUTE.ONBOARDING_PLAN_INFO,
      onboardingStepName: 'PLAN',
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: '3.1',
      route: ROUTE.ONBOARDING_ESG_STRATEGY,
      onboardingStepName: 'ESG',
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
      subItem: true,
    },
    MANAGERS: {
      key: '3.2',
      route: ROUTE.ONBOARDING_INVESTMENT_MANAGERS,
      onboardingStepName: 'MANAGERS',
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
      subItem: true,
    },
    FINALIZE: {
      key: '4',
      route: ROUTE.ONBOARDING_FINALIZE,
      onboardingStepName: 'FINALIZE',
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
  UserType2: {
    ACCOUNT: {
      key: '1',
      route: ROUTE.ONBOARDING_CREATE_ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      route: ROUTE.ONBOARDING_PROFILE_V2,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: '3',
      route: ROUTE.ONBOARDING_PLAN_INFO,
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: '4',
      route: ROUTE.ONBOARDING_ESG_STRATEGY,
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
    },
    MANAGERS: {
      key: '5',
      route: ROUTE.ONBOARDING_INVESTMENT_MANAGERS,
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
    },
    FINALIZE: {
      key: '6',
      route: ROUTE.ONBOARDING_FINALIZE,
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
  UserType3: {
    ACCOUNT: {
      key: '1',
      route: ROUTE.ONBOARDING_CREATE_ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      route: ROUTE.ONBOARDING_PROFILE,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    MANAGER: {
      key: '3',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'Manager',
      isComplete: false,
      stepOrder: 3,
    },
    MANAGER_INFORMATION: {
      key: '3.1',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'Manager Information',
      isComplete: false,
      isSubStep: true,
      stepOrder: 4,
      subItem: true,
    },
    ESG_INTGRATION: {
      key: '3.2',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'ESG Integration',
      isComplete: false,
      isSubStep: true,
      stepOrder: 5,
      subItem: true,
    },
    ESG_ASSOCIATIONS: {
      key: '3.3',
      route: ROUTE.ONBOARDING_UT3_MANAGER,
      shortTitle: 'ESG Associations',
      isComplete: false,
      isSubStep: true,
      stepOrder: 6,
      subItem: true,
    },
    // STRATEGIES: {
    //   key: 'STRATEGIES',
    //   route: ROUTE.ONBOARDING_UT3_STRATEGIES,
    //   shortTitle: 'Strategies',
    //   isComplete: false,
    //   stepOrder: 4,
    // },
    // INVITE: {
    //   key: 'INVITE',
    //   route: ROUTE.ONBOARDING_UT3_INVITE,
    //   shortTitle: 'Invite',
    //   isComplete: false,
    //   stepOrder: 5,
    // },
    FINALIZE: {
      key: '4',
      route: ROUTE.ONBOARDING_FINALIZE,
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
};
