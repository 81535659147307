export const ORGANIZATION_SELECTORS = {
  UNIVERSE: {
    title: 'Universe',
    value: 'universe',
  },
  PUBLIC_PLANS: {
    title: 'Public Plans',
    value: 'public-plans',
  },
};
