import {ISSUE_ICON_PATH} from './issueIconPath';
import {CARD_CHART_STYLE} from './cardChartStyle';

export const ISSUE = {
  11: {
    // Carbon Emissions
    iconPath: ISSUE_ICON_PATH.V2.CO2,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'scope_1_2_greenhouse_gas_emissions',
    precision: 3,
  },
  12: {
    // Energy Use
    iconPath: ISSUE_ICON_PATH.V2.ENERGY,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'total_energy_consumption',
    precision: 3,
  },
  13: {
    // Water Consumption
    iconPath: ISSUE_ICON_PATH.V2.WATER,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'total_water_consumption',
    precision: 3,
  },
  14: {
    // Climate Change Policy
    iconPath: ISSUE_ICON_PATH.V2.HOLDING_PLANT,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    precision: 0,
  },
  15: {
    // Environmental Risk Management
    iconPath: ISSUE_ICON_PATH.V2.HOLDING_PLANT,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'environmental_risk_management',
    precision: 0,
  },
  16: {
    // Environmental Incentives
    iconPath: ISSUE_ICON_PATH.V2.HOLDING_PLANT,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'environmental_incentives',
    precision: 0,
  },
  19: {
    // CEO Compensation Ratio
    iconPath: ISSUE_ICON_PATH.V2.HOLDING_MONEY,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'leadership_pay_ratio',
    precision: 0,
  },
  20: {
    // Injury Frequency
    iconPath: ISSUE_ICON_PATH.V2.HEART_CROSS,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'severe_injury_rate_policy',
    precision: 3,
  },
  21: {
    // Human Rights
    iconPath: ISSUE_ICON_PATH.V2.HOLDING_PERSON,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'human_rights_policy',
    precision: 0,
  },
  22: {
    // Non-discrimination
    iconPath: ISSUE_ICON_PATH.V2.BOOK,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'non_discrimination_policy',
    precision: 0,
  },
  23: {
    // Health and Safety
    iconPath: ISSUE_ICON_PATH.V2.HEART_CROSS,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    precision: 0,
  },
  24: {
    // Child & Forced Labor
    iconPath: ISSUE_ICON_PATH.V2.PERSON,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'child_forced_labor_policy',
    precision: 0,
  },
  25: {
    // Board Independence
    iconPath: ISSUE_ICON_PATH.V2.MEETING_TABLE,
    cardChartStyle: CARD_CHART_STYLE.STYLE_2,
    badgeAssetBaseName: 'board_independence',
    precision: 0,
  },
  26: {
    // Separation of Powers
    iconPath: ISSUE_ICON_PATH.V2.SCALES,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'separation_of_power',
    precision: 0,
  },
  27: {
    // Code of Conduct
    iconPath: ISSUE_ICON_PATH.V2.PAPER,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'code_of_conduct',
    precision: 0,
  },
  28: {
    // Ethics & Anti-corruption
    iconPath: ISSUE_ICON_PATH.V2.BOOK,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'ethic_anti_corruption_policy',
    precision: 0,
  },
  29: {
    // Data Privacy
    iconPath: ISSUE_ICON_PATH.V2.LOCK_SHIELD,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    badgeAssetBaseName: 'data_privacy_policy',
    precision: 0,
  },
  30: {
    // Women in Board
    iconPath: ISSUE_ICON_PATH.V2.WOMAN,
    cardChartStyle: CARD_CHART_STYLE.STYLE_2,
    badgeAssetBaseName: 'women_on_board',
    precision: 0,
  },
  31: {
    // Women in Leadership
    iconPath: ISSUE_ICON_PATH.V2.WOMAN,
    cardChartStyle: CARD_CHART_STYLE.STYLE_2,
    badgeAssetBaseName: 'women_in_leadership',
    precision: 0,
  },
  32: {
    // Board Diversity
    iconPath: ISSUE_ICON_PATH.V2.MEETING_TABLE,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'board_ethnic_diversity',
    precision: 2,
  },
  33: {
    // Leadership Diversity
    iconPath: ISSUE_ICON_PATH.V2.HOLDING_PERSON,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    badgeAssetBaseName: 'leadership_ethnic_diversity',
    precision: 2,
  },
  34: {
    // Carbon Footprint Offsets
    iconPath: ISSUE_ICON_PATH.V2.CO2,
    cardChartStyle: CARD_CHART_STYLE.STYLE_1,
    precision: 3,
  },
  35: {
    // Carbon Neutral Pledged
    iconPath: ISSUE_ICON_PATH.V2.THERMOMETER,
    cardChartStyle: CARD_CHART_STYLE.STYLE_3,
    precision: 0,
  },
};
