import {useState} from 'react';
import {Link as ReactLink} from 'react-router-dom';
import styled from 'styled-components/macro';
import {ROUTE} from 'v2/constants/routes';
import {Container} from 'reactstrap';
import {HoldingDistributionModalProps} from 'v2/utilities/types/components/Modal';
import {FlexContainer, TableCell} from '../atoms/Containers';
import {Theme} from '../atoms/theme';
import {Title5, SubtitleM} from '../atoms/Typeface';
import {GreyedDropDown} from '../molecules/DropDown';
import {GenericModalV2} from '../molecules/Modal';
import {Link} from '../molecules/Button';
import {CompactPaginatedReactTable} from './ReactTable';
import {SelectOption} from 'v2/utilities/types/components';
import {includes, orderBy, uniqBy} from 'lodash';
import {dynamicRoute, formatMillions} from 'v2/utilities/helpers';
import {getSessionJwt} from 'app/utilities';
import {ROLE} from 'v2/constants/role';

const Swatch = styled.div<{color?: string}>`
  margin-top: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  background: ${({color, theme}) => color ?? theme.colors.grey_4};
  border-radius: 0.125rem;
`;

const LinkContainer = styled(Container)`
  margin: -3rem 1.875rem 0;
`;

const CompanyColumns = [
  {
    Header: 'Companies',
    Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
    style: {
      maxWidth: 'auto',
      minWidth: '45%',
      width: '45%',
    },
    accessor: 'company',
  },
  {
    Header: 'Sectors',
    Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
    style: {
      maxWidth: 'auto',
      minWidth: '40%',
      width: '40%',
    },
    accessor: 'sector',
  },
  {
    Header: 'Held',
    Cell: ({value}: {value: number}) => <TableCell>{formatMillions(value, 1, '$')}</TableCell>,
    style: {
      maxWidth: 'auto',
      minWidth: '15%',
      width: '15%',
    },
    accessor: 'held',
  },
];

export const HoldingDistributionModal = ({
  isOpen,
  setClose,
  color,
  data = [],
  columns = CompanyColumns,
  label = '',
  defaultCompanyPath,
}: HoldingDistributionModalProps) => {
  const authUser = getSessionJwt(true)?.contents;

  const companyLink = (): string => {
    if (includes(authUser.roles, ROLE.USER_TYPE_3)) {
      return ROUTE.UT3.COMPANIES.basePath;
    }
    return ROUTE.UT1.COMPANIES.INDEX.path;
  };
  const emptyOption: SelectOption = {label: 'All', value: ''};

  const companyPath = (id: string) => {
    return dynamicRoute({
      route: ROUTE.UT1.COMPANIES.RISK.path,
      replace: ':companyId',
      value: id,
    });
  };

  const getOptions = (): SelectOption[] => {
    const holdings: SelectOption[] = orderBy(
      uniqBy(data ?? [], 'sector')
        .map(holding => ({
          value: holding.sector,
          label: holding.sector,
        }))
        .filter(option => option.value !== '-'),
      ['label'],
      ['asc'],
    );

    return [emptyOption, ...holdings];
  };

  const [filter, setFilter] = useState(emptyOption);

  const filteredData = () => {
    if (filter.value !== '') {
      return data.filter(holding => holding.sector === filter.value);
    }

    return data;
  };

  return (
    <GenericModalV2 width="1185px" isOpen={isOpen} setClose={setClose}>
      <FlexContainer direction="column" margin="-32px 0 0">
        <Container padding="0 1.875rem">
          <Title5 color={Theme.colors.dark}>HOLDINGS DISTRIBUTION</Title5>
        </Container>
        <FlexContainer padding="0 1.875rem" justify="space-between" items="center" wrap="wrap" gap="1rem">
          <FlexContainer items="center" gap="1rem">
            <FlexContainer flex="0">
              <SubtitleM>Sector:</SubtitleM>
            </FlexContainer>
            <FlexContainer flex="1" width="18rem">
              <Container>
                <GreyedDropDown value={filter} options={getOptions()} onChange={setFilter} />
              </Container>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer gap="1rem" items="center" justify="center">
            <Container>
              <SubtitleM style={{whiteSpace: 'nowrap', fontWeight: 400}}>Percentile Rank</SubtitleM>
            </Container>
            <Container>
              <Swatch color={color} />
            </Container>
            <Container>
              <SubtitleM style={{whiteSpace: 'nowrap', fontWeight: 400}}>{label}</SubtitleM>
            </Container>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer padding="2rem 0 0" direction="column" flex="1">
          <CompactPaginatedReactTable
            data={filteredData()}
            columns={columns}
            path={defaultCompanyPath ? defaultCompanyPath : companyPath}
            contentHeight="40vh"
            withHover
          />
        </FlexContainer>
      </FlexContainer>
      <LinkContainer>
        <Link as={ReactLink} to={companyLink()}>
          Go to Companies
        </Link>
      </LinkContainer>
    </GenericModalV2>
  );
};
