import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchIfNeeded} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  CREATE_STAGING_UPLOAD_REQUEST: 'strategy/create-staging-upload/request/silent',
  CREATE_STAGING_UPLOAD_RECEIVE: 'strategy/create-staging-upload/receive/silent',
  GET_PLAN_SELECTION_REQUEST: 'STRATEGY_GET_PLAN_SELECTION_REQUEST',
  GET_PLAN_SELECTION_RECEIVE: 'STRATEGY_GET_PLAN_SELECTION_RECEIVE',
  GET_PLAN_SELECTION_PAGED_REQUEST: 'STRATEGY_GET_PLAN_SELECTION_PAGED_REQUEST',
  GET_PLAN_SELECTION_PAGED_RECEIVE: 'STRATEGY_GET_PLAN_SELECTION_PAGED_RECEIVE',
  GET_PLAN_SELECTION_BY_ID_REQUEST: 'STRATEGY_GET_PLAN_SELECTION_BY_ID_REQUEST',
  GET_PLAN_SELECTION_BY_ID_RECEIVE: 'STRATEGY_GET_PLAN_SELECTION_BY_ID_RECEIVE',
  GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_REQUEST: 'STRATEGY_GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_REQUEST',
  GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_RECEIVE: 'STRATEGY_GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_RECEIVE',
  UPDATE_AND_PROCESS_UPLOADS_REQUEST: 'STRATEGY_UPDATE_AND_PROCESS_UPLOADS_REQUEST',
  UPDATE_AND_PROCESS_UPLOADS_RECEIVE: 'STRATEGY_UPDATE_AND_PROCESS_UPLOADS_RECEIVE',
  GET_SELECTED_FOR_UPLOAD_REQUEST: 'STRATEGY_GET_SELECTED_FOR_UPLOAD_REQUEST',
  GET_SELECTED_FOR_UPLOAD_RECEIVE: 'STRATEGY_GET_SELECTED_FOR_UPLOAD_RECEIVE',
  GET_BY_ID_WITH_FIRM_REQUEST: 'STRATEGY_GET_BY_ID_WITH_FIRM_REQUEST',
  GET_BY_ID_WITH_FIRM_RECEIVE: 'STRATEGY_GET_BY_ID_WITH_FIRM_RECEIVE',
  UPDATE_REQUEST: 'STRATEGY_UPDATE_REQUEST',
  UPDATE_RECEIVE: 'STRATEGY_UPDATE_RECEIVE',
  GET_BENCHMARK_PERFORMANCE_REQUEST: 'GET_BENCHMARK_PERFORMANCE_REQUEST',
  GET_BENCHMARK_PERFORMANCE_RECEIVE: 'GET_BENCHMARK_PERFORMANCE_RECEIVE',
  DELETE_FROM_SETTINGS_REQUEST: 'STRATEGY_SETTINGS_DELETE_REQUEST',
  DELETE_FROM_SETTINGS_RECEIVE: 'STRATEGY_SETTINGS_DELETE_RECEIVE',
};

const createStagingUploadRequest = createAction(ACTION.CREATE_STAGING_UPLOAD_REQUEST);
const createStagingUploadReceive = createAction(ACTION.CREATE_STAGING_UPLOAD_RECEIVE);
const strategyGetPlanSelectionRequest = createAction(ACTION.GET_PLAN_SELECTION_REQUEST);
const strategyGetPlanSelectionReceive = createAction(ACTION.GET_PLAN_SELECTION_RECEIVE);
const strategyGetPlanSelectionPagedRequest = createAction(ACTION.GET_PLAN_SELECTION_PAGED_REQUEST);
const strategyGetPlanSelectionPagedReceive = createAction(ACTION.GET_PLAN_SELECTION_PAGED_RECEIVE);
const strategyGetPlanSelectionByIdRequest = createAction(ACTION.GET_PLAN_SELECTION_BY_ID_REQUEST);
const strategyGetPlanSelectionByIdReceive = createAction(ACTION.GET_PLAN_SELECTION_BY_ID_RECEIVE);
const strategyGetPlanSelectionByIdForUploadRequest = createAction(ACTION.GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_REQUEST);
const strategyGetPlanSelectionByIdForUploadReceive = createAction(ACTION.GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_RECEIVE);
const updateStrategyAndProcessUploadsRequest = createAction(ACTION.UPDATE_AND_PROCESS_UPLOADS_REQUEST);
const updateStrategyAndProcessUploadsReceive = createAction(ACTION.UPDATE_AND_PROCESS_UPLOADS_RECEIVE);
const strategyGetSelectedForUploadRequest = createAction(ACTION.GET_SELECTED_FOR_UPLOAD_REQUEST);
const strategyGetSelectedForUploadReceive = createAction(ACTION.GET_SELECTED_FOR_UPLOAD_RECEIVE);
const strategyGetByIdWithFirmRequest = createAction(ACTION.GET_BY_ID_WITH_FIRM_REQUEST);
const strategyGetByIdWithFirmReceive = createAction(ACTION.GET_BY_ID_WITH_FIRM_RECEIVE);
const updateStrategyRequest = createAction(ACTION.UPDATE_REQUEST);
const updateStrategyReceive = createAction(ACTION.UPDATE_RECEIVE);
const getBenchmarkPerformanceRequest = createAction(ACTION.GET_BENCHMARK_PERFORMANCE_REQUEST);
const getBenchmarkPerformanceRecieve = createAction(ACTION.GET_BENCHMARK_PERFORMANCE_RECEIVE);
const strategyDeleteFromSettingsRequest = createAction(ACTION.DELETE_FROM_SETTINGS_REQUEST);
const strategyDeleteFromSettingsReceive = createAction(ACTION.DELETE_FROM_SETTINGS_RECEIVE);

const api = {
  createStagingUpload: fileKey => {
    return fetchAuthJSON('stagingStrategyUpload/uploadStagingPortfolio', {
      method: 'post',
      body: JSON.stringify({fileKey}),
    });
  },
  getPlanSelection: () => {
    const url = 'strategy/planSelection';

    return fetchAuthJSON(url, {method: 'get'});
  },
  getPlanSelectionPaged: options => {
    let url = 'strategy/planSelection';
    url += '?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    url += `&search=${options.search || ''}`;

    return fetchAuthJSON(url, {method: 'get'});
  },
  getPlanSelectionById: (strategyId, fields) => {
    let url = `strategy/planSelection/${strategyId}`;
    if (fields) {
      url += `?fields=${fields}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getBenchmarkPerformanceById: strategyId =>
    fetchAuthJSON(`strategy/${strategyId}/benchmarkPerformance`, {
      method: 'get',
    }),
  updateStrategy: (strategyId, values) =>
    fetchAuthJSON(`strategy/${strategyId}`, {
      method: 'put',
      body: JSON.stringify(values),
    }),
  updateStrategyAndProcessUploads: values =>
    fetchAuthJSON('strategy/updateAndCreatePortfolio', {
      method: 'post',
      body: JSON.stringify(values),
    }),
  getStrategyByIdWithFirm: strategyId => fetchAuthJSON(`strategy/${strategyId}/firm`, {method: 'get'}),
  deleteFromSettings: strategyId => {
    return fetchAuthJSON(`strategy/${strategyId}`, {method: 'delete'});
  },
};

export function createStagingUpload(fileKey) {
  return dispatch => {
    dispatch(createStagingUploadRequest({fileKey}));
    return dispatch(createStagingUploadReceive(api.createStagingUpload(fileKey)));
  };
}

export function getPlanSelection() {
  return dispatch => {
    dispatch(strategyGetPlanSelectionRequest());
    return dispatch(strategyGetPlanSelectionReceive(api.getPlanSelection()));
  };
}

export function getPlanSelectionPaged(options) {
  return dispatch => {
    dispatch(strategyGetPlanSelectionPagedRequest());
    return dispatch(strategyGetPlanSelectionPagedReceive(api.getPlanSelectionPaged(options)));
  };
}

export function getPlanSelectionById(strategyId, fields) {
  return dispatch => {
    dispatch(strategyGetPlanSelectionByIdRequest());
    return dispatch(strategyGetPlanSelectionByIdReceive(api.getPlanSelectionById(strategyId, fields)));
  };
}

export function getPlanSelectionByIdForUpload(strategyId) {
  return dispatch => {
    dispatch(strategyGetPlanSelectionByIdForUploadRequest({strategyId}));
    return dispatch(strategyGetPlanSelectionByIdForUploadReceive(api.getPlanSelectionById(strategyId, 'uploads')));
  };
}

export function getBenchmarkPerformanceById(strategyId) {
  return dispatch => {
    dispatch(getBenchmarkPerformanceRequest());
    return dispatch(getBenchmarkPerformanceRecieve(api.getBenchmarkPerformanceById(strategyId)));
  };
}

export function fetchPlanSelectionIfNeeded() {
  return fetchIfNeeded('strategy', 'planSelection', () => getPlanSelection());
}

export function getSelectedStrategyForUpload(strategyId) {
  return dispatch => {
    dispatch(strategyGetSelectedForUploadRequest({strategyId}));
    return dispatch(strategyGetSelectedForUploadReceive({id: strategyId}));
  };
}

export function updateStrategy(strategyId, payload) {
  return dispatch => {
    dispatch(updateStrategyRequest({strategyId, payload}));
    return dispatch(updateStrategyReceive(api.updateStrategy(strategyId, payload)));
  };
}

export function updateStrategyAndProcessUploads(payload) {
  return dispatch => {
    dispatch(updateStrategyAndProcessUploadsRequest(payload));
    return dispatch(updateStrategyAndProcessUploadsReceive(api.updateStrategyAndProcessUploads(payload)));
  };
}

export function getStrategyByIdWithFirm(strategyId) {
  return dispatch => {
    dispatch(strategyGetByIdWithFirmRequest({strategyId}));
    return dispatch(strategyGetByIdWithFirmReceive(api.getStrategyByIdWithFirm(strategyId)));
  };
}

export function deleteStrategy(strategyId) {
  return dispatch => {
    dispatch(strategyDeleteFromSettingsRequest(strategyId));
    return dispatch(strategyDeleteFromSettingsReceive(api.deleteFromSettings(strategyId)));
  };
}

// REDUCER
export const strategy = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.CREATE_STAGING_UPLOAD_REQUEST:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: true,
          hasStagingUploadError: false,
          isFetching: true,
        },
      };
    case ACTION.CREATE_STAGING_UPLOAD_RECEIVE:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: false,
          hasStagingUploadError: hasError,
          isFetching: false,
        },
      };
    case ACTION.GET_BY_ID_WITH_FIRM_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_PLAN_SELECTION_RECEIVE:
      return assignMergedState(hasError ? {error: payload} : {planSelection: payload.items});
    case ACTION.GET_PLAN_SELECTION_PAGED_RECEIVE:
      return assignMergedState(
        hasError ? {error: payload} : {planSelectionPaged: payload.items, planSelectionPagedTotal: payload.total},
      );
    case ACTION.GET_PLAN_SELECTION_BY_ID_RECEIVE:
      return assignMergedState(hasError ? {error: payload} : {currentPlan: payload});
    case ACTION.UPDATE_RECEIVE:
      return hasError ? assignMergedState({error: payload}) : state;
    case ACTION.UPDATE_AND_PROCESS_UPLOADS_RECEIVE:
    case ACTION.GET_PLAN_SELECTION_BY_ID_FOR_UPLOAD_RECEIVE:
      return assignMergedState(
        hasError
          ? {error: payload}
          : {
              selectedStrategyIdForUpload: payload.id,
              [`selectedStrategyForUpload_${payload.id}`]: payload,
            },
      );
    case ACTION.GET_SELECTED_FOR_UPLOAD_RECEIVE: {
      return assignMergedState({selectedStrategyIdForUpload: payload.id});
    }
    case ACTION.GET_BY_ID_WITH_FIRM_RECEIVE: {
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {strategyWithFirm: payload}),
      );
    }
    case ACTION.GET_BENCHMARK_PERFORMANCE_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_BENCHMARK_PERFORMANCE_RECEIVE: {
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {currentPlanRating: payload}),
      );
    }
    default:
      return state;
  }
};
