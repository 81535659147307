import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {debounce, get, map, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {compose, getContext, withContext, withHandlers} from 'recompose';

// Local Imports
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {Alert, Button, BootstrapTableRemote, TooltipLink} from 'app/components';
import {getPagedAdmin} from 'app/redux/adminUser';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {deleteUser} from 'app/redux/user';
import {toastError, toastSuccess} from 'app/utilities/toast';
import {openSimpleModal} from 'app/components/SimpleModal/redux';

const EditLinkIcon = compose(
  getContext({
    getEditLink: PropTypes.func,
  }),
)(({getEditLink, userId}) => (
  <Link className="text-info" to={getEditLink(userId)}>
    <Icon icon={faPencil} />
  </Link>
));

const columns = [
  {
    dataField: 'lastName',
    text: 'Name',
    sort: true,
    formatter: (cell, row) => (
      <div>
        {row.firstName} {row.lastName}
      </div>
    ),
    headerStyle: {minWidth: 200},
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    headerStyle: {minWidth: 200},
    classes: 'table-overflow-ellipsis',
  },
  {
    dataField: 'edit',
    text: '',
    formatter: (value, {id}) => <EditLinkIcon userId={id} />,
    headerFormatter: () => <span className="sr-only">Edit</span>,
    headerStyle: {width: 30},
  },
];

class AdminEmployeeManagerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.search,
      filter: props.filter,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleSearchDebounced = debounce(this.handleSearch, 500);
  }

  shouldComponentUpdate(nextProps) {
    return !!nextProps.data;
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.props.search,
      this.props.filter,
    );

    // this.searchInput.focus();
    animateScroll.scrollToTop();
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
    filter = this.state.filter,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search) {
      updatedPage = 1;
    }

    this.props.dispatch(push({search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search, filter)}));
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    this.handleSearchDebounced();
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search, filter} = this.props;

    this.props.dispatch(
      push({
        pathname: ROUTE.ADMIN_USERS_EDIT.path(row.id),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, {filter}),
      }),
    );
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleSearch = () => {
    this.updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search, filter) => {
    this.props.dispatch(
      getPagedAdmin({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        filter,
      }),
    );
  };

  render() {
    const {data, hasError, sortField, sortOrder, page, search, filter, pageSize, totalSize} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg">
        <div>
          {/* <Link className="btn btn-outline float-right" to={{
            pathname: ROUTE.ADMIN_USERS_ADD.path(),
            search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, { filter }),
          }}>
            {ROUTE.ADMIN_USERS_ADD.title}
          </Link> */}
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <h1>{ROUTE.ADMIN_EMPLOYEES.title}</h1>
            {this.props.canCreate && (
              <Link to="/admin/employees/add">
                <button type="button" class="btn btn-outline">
                  Add New Employee
                </button>
              </Link>
            )}
          </div>

          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          {/* <form method="GET" onSubmit={this.handleSearch}>
            <div className="bootstrap-table-controls">
              <div className="bootstrap-table-search" style={{ width: '65%' }}>
                <div className="form-control-and-icon">
                  <input
                    className="form-control"
                    ref={(input) => { this.searchInput = input; }}
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search by Names or Email"
                    value={this.state.search}
                    onChange={this.changeField}
                  />
                  <div className="icon">
                    <Icon icon={faSearch} />
                  </div>
                  <TooltipLink className="float-right mt-1" label="Search Techniques" id="adminSearch">
                    Surround your search with double quotes to do an exact search. Additionally when
                    using double quotes, you can do wildcard searches. Simply use a percent sign ( % ) to match any
                    character zero or more times and an underscore ( _ ) to match any character exactly one time.
                    For example, "H_rmony%" will find a result for <i>Harmony Analytics</i> as would "%Analytic_".
                  </TooltipLink>
                </div>
                <div>
                  <Button color="gray" type="submit">Search</Button>
                </div>
              </div>
            </div>
          </form> */}

          {data && (
            <BootstrapTableRemote
              tableProps={{hover: false}}
              keyField="id"
              columns={columns}
              data={data}
              page={page}
              sizePerPage={pageSize}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              defaultSorted={defaultSorted}
            />
          )}
        </div>
      </div>
    );
  }
}

AdminEmployeeManagerPage.defaultProps = {totalSize: 0};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(
    ownProps,
    {
      search: '',
      page: 1,
      pageSize: 25,
      sortField: 'planSponsor.name',
      sortOrder: 'asc',
    },
    ['filter'],
  );
  newProps.isFetching = get(state, 'adminUser.listing.isFetching');
  newProps.data = get(state, 'adminUser.listing.data');
  newProps.totalSize = get(state, 'adminUser.listing.totalSize');
  newProps.hasError = get(state, 'adminUser.listing.hasError');
  newProps.canCreate = includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_USERS);
  return newProps;
};

export const AdminEmployeeManager = compose(
  connect(mapStateToProps, {
    deleteUser,
    openSimpleModal,
  }),
  withHandlers({
    handleUserDelete: props => userId => {
      return props.openSimpleModal({
        title: 'Delete Confirmation',
        message: 'Do you really want to delete this user?',
        buttons: [
          {
            label: 'Cancel',
            close: true,
          },
          {
            className: 'btn-red ml-auto',
            label: 'Yes, Delete User',
            clickHandler: () =>
              props
                .deleteUser(userId)
                .then(response => {
                  if (response.hasError) throw response.error;
                  toastSuccess('User has been deleted');
                })
                .catch(() => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE)),
            close: true,
          },
        ],
      });
    },
    getEditLink: props => userId => {
      const {sortField, sortOrder, page, pageSize, search, filter} = props;
      return {
        pathname: ROUTE.ADMIN_EMPLOYEES_EDIT.path(userId),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search),
      };
    },
  }),
  withContext(
    {
      handleUserDelete: PropTypes.func,
      getEditLink: PropTypes.func,
    },
    ({handleUserDelete, getEditLink}) => ({
      handleUserDelete,
      getEditLink,
    }),
  ),
)(AdminEmployeeManagerPage);
