import React, {Component} from 'react';
import {get, isEmpty, keyBy} from 'lodash';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {ROUTE, RANKING_TIER, ORGANIZATION_SELECTORS} from 'app/constants';

import {getDetails} from 'app/redux/planDashboard';
import {getAll as getDashboardData} from 'app/redux/dashboard';
import {getGlobalSettings} from 'app/redux/settings';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import faChevronRight from 'app/fontawesome-pro-light/faChevronRight';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import {ComparisonDoughnut, PlanSponsorReportDownloadButton} from 'app/components';

import {ratingFormatter} from 'app/utilities/ratingFormatter';
import {formatCurrency} from 'app/utilities/formatCurrency';

import numeral from 'numeral';
import {DATA_STATUS} from 'v2/constants/dataStatus';

class DashboardUserType1Component extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPeerRanking: 1,
      organization: ORGANIZATION_SELECTORS.UNIVERSE.value,
    };

    this.setPeerRanking = this.setPeerRanking.bind(this);

    /**
     * Handle initial loading of the data
     */
    props.dispatch(getDetails());
    props.dispatch(getDashboardData());
    props.dispatch(getGlobalSettings());
  }

  setPeerRanking(peerRanking) {
    this.setState({selectedPeerRanking: peerRanking});
  }

  // eslint-disable-next-line class-methods-use-this
  renderPeerRanking(issueId, peerRankings, rankingOverrides) {
    if (rankingOverrides[issueId]) {
      return RANKING_TIER[rankingOverrides[issueId].value];
    }
    if (peerRankings[issueId]) {
      return peerRankings[issueId].tier !== 'INADEQUATE' ? (
        RANKING_TIER[peerRankings[issueId].tier]
      ) : (
        <Link
          to={ROUTE.MANAGE_ESG.path()}
          style={
            issueId !== null
              ? {
                  fontSize: '1rem',
                  color: '#fff',
                  textDecoration: 'underline',
                }
              : {}
          }
        >
          {RANKING_TIER.INADEQUATE}
        </Link>
      );
    }
    return DATA_STATUS.NONE;
  }

  /**
   * Outputs the Company Engagement section
   * @returns {JSX.Element}
   */
  renderCompanyEngagement = () => {
    const {dashboard, settings} = this.props;

    // Confirm there is a dashboard to display
    if (isEmpty(dashboard)) {
      return <span />;
    }

    const {year, environmentalIssuesCount, socialIssuesCount, governanceIssuesCount, companiesCount} = get(
      dashboard,
      'data.companyEngagementData',
      {},
    );

    // Get the count for all of the issues combined
    const allIssuesCount = environmentalIssuesCount + socialIssuesCount + governanceIssuesCount;

    // Create local function that formats the counts within this section
    const formatCount = count => numeral(count).format('0,0');

    // Determine whether the Company Engagement Link is visible
    const isCompanyEngagementPageLinkVisible = get(settings, 'isCompanyEngagementPageLinkVisible');

    return (
      <div className="card">
        <div className="card-body">
          <h2 className="h2-alt">{year} Company Engagement</h2>
          <div className="stat-group">
            <div className="stat">
              <Icon icon={faSeedling} />
              <span className="stat-measure">{formatCount(environmentalIssuesCount)}</span>
              <span className="stat-title">Environmental Issues</span>
            </div>
            <div className="stat">
              <Icon icon={faHandHeart} />
              <span className="stat-measure">{formatCount(socialIssuesCount)}</span>
              <span className="stat-title">Social Issues</span>
            </div>
            <div className="stat">
              <Icon icon={faLandmark} />
              <span className="stat-measure">{formatCount(governanceIssuesCount)}</span>
              <span className="stat-title">Governance Issues</span>
            </div>
          </div>
          <div className="stats-total">
            <strong>{formatCount(companiesCount)} Companies</strong> engaged on{' '}
            <strong>{formatCount(allIssuesCount)} Issues</strong>
          </div>
        </div>
        {isCompanyEngagementPageLinkVisible && (
          <div className="link-footer bottom-right">
            <span>
              <Link to={ROUTE.COMPANY_ENGAGEMENT.path()}>
                View Company Engagement <Icon icon={faChevronRight} />
              </Link>
            </span>
          </div>
        )}
      </div>
    );
  };

  renderManagerChartCard = (data, description, labels, path, index) => (
    <div className="card" key={index}>
      <div className="card-body">
        <div className="chart-group">
          <div className="chart">
            <ComparisonDoughnut
              data={[{active: data, inActive: 100 - data}]}
              labels={labels}
              unit={'%'}
              caption={data + '%'}
            />
          </div>
          <div className="chart-text">
            {description}
            <Link to={path}>
              View Managers <Icon icon={faChevronRight} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      details,
      planSponsorName,
      rankingOverrides,
      compliantManagersPct,
      engagedManagersPct,
      esgManagersPct,
      topHoldings,
    } = this.props;

    const {selectedPeerRanking} = this.state;

    // eslint-disable-next-line no-nested-ternary
    const peerRankings = details
      ? this.state.organization === ORGANIZATION_SELECTORS.UNIVERSE.value
        ? keyBy(details.peerRankings, 'issueId')
        : keyBy(details.peerRankingsInOrgType, 'issueId')
      : {};

    const managerCards = [
      {
        data: esgManagersPct,
        description: 'Managers Integrating ESG',
        labels: ['Integrating', 'Not Integrating'],
        path: ROUTE.MANAGERS.path(),
      },
      {
        data: compliantManagersPct,
        description: 'Products Compliant with Guidelines',
        labels: ['Compliant', 'Not Compliant'],
        path: ROUTE.MANAGERS.path(),
      },
      {
        data: engagedManagersPct,
        description: 'Managers Engaged on Harmony Platform',
        labels: ['Engaged', 'Not Engaged'],
        path: ROUTE.MANAGERS.path(),
      },
    ];

    return (
      <div>
        <div className="dashboard-container pt-12 px-8">
          <div className="d-flex justify-content-between">
            <h1>{planSponsorName} Dashboard</h1>
            {/* <PlanSponsorReportDownloadButton /> */}
          </div>

          <div className="card-deck">
            <div style={{display: 'block'}} className="card card-ranking">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="h2-alt pb-4">Peer Ranking</h2>
                  </div>
                  {/* <div className="flex-grow-0">
                    <DashboardOrganizationToggle
                      value={this.state.organization}
                      onChange={e => this.setState({ organization: e.target.value })}
                    />
                  </div> */}
                </div>
                <div className="card-ranking-group">
                  <svg width="85" height="85" viewBox="0 0 85 85">
                    <defs>
                      <linearGradient id="a" x1="0.5" x2="0.5" y2="0.983" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#008087" />
                        <stop offset="1" stopColor="#00446d" />
                      </linearGradient>
                      <clipPath id="b">
                        <circle cx="42.5" cy="42.5" r="42.5" transform="translate(0 25.65)" />
                      </clipPath>
                    </defs>
                    <g transform="translate(0 -25.65)">
                      <circle cx="42.5" cy="42.5" r="42.5" transform="translate(0 25.65)" fill="url(#a)" />
                      <g clipPath="url(#b)">
                        <path
                          d="M23.155,0C21.522,25.74,9.114,59.331,2.088,84.314c-5.222,18.566.222,2.678,6.751-4.48,
                6.592-7.227,14.7-10.358,19.621-10.53,5.57-.2,8.834,0,15.787,4.593S56.38,81.486,59.5,83.432c4.6,
                2.867,12.547,6.391,17.5,4.8"
                          transform="translate(7.94)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                        />
                      </g>
                    </g>
                  </svg>
                  <span className="card-ranking-text">
                    <h3>
                      <span>{this.renderPeerRanking(null, peerRankings, rankingOverrides)}</span>
                    </h3>
                  </span>
                </div>
              </div>
            </div>
            <div style={{display: 'block'}} className="card card-double">
              <div className="card-col-group">
                <div className="card-col">
                  <div className="card-body">
                    <h2 className="h2-alt">ESG Peer Ranking</h2>
                    <a
                      href="javascript:;"
                      onClick={() => this.setPeerRanking(1)}
                      className={`panel-link ${selectedPeerRanking === 1 ? 'active' : ''}`}
                    >
                      <span className="issue-icon">
                        <Icon icon={faSeedling} />
                      </span>
                      <span className="panel-link-text">Environmental Issues</span>
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => this.setPeerRanking(2)}
                      className={`panel-link ${selectedPeerRanking === 2 ? 'active' : ''}`}
                    >
                      <span className="issue-icon">
                        <Icon icon={faHandHeart} />
                      </span>
                      <span className="panel-link-text">Social Issues</span>
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => this.setPeerRanking(3)}
                      className={`panel-link ${selectedPeerRanking === 3 ? 'active' : ''}`}
                    >
                      <span className="issue-icon">
                        <Icon icon={faLandmark} />
                      </span>
                      <span className="panel-link-text">Governance Issues</span>
                    </a>
                  </div>
                </div>
                <div className="card-col card-panels">
                  <div className={`card-panel ${selectedPeerRanking === 1 ? 'active' : ''}`}>
                    <div className="card-body">
                      <table>
                        <thead>
                          <tr>
                            <th>Environmental Issues</th>
                            <th>
                              <span>{this.renderPeerRanking(1, peerRankings, rankingOverrides)}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Environment</td>
                            <td>
                              <span>{this.renderPeerRanking(4, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Environmental Policies</td>
                            <td>
                              <span>{this.renderPeerRanking(5, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={`card-panel ${selectedPeerRanking === 2 ? 'active' : ''}`}>
                    <div className="card-body">
                      <table>
                        <thead>
                          <tr>
                            <th>Social Issues</th>
                            <th>
                              <span>{this.renderPeerRanking(2, peerRankings, rankingOverrides)}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Diversity & Inclusion</td>
                            <td>
                              <span>{this.renderPeerRanking(6, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Compensation Equality</td>
                            <td>
                              <span>{this.renderPeerRanking(7, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Workforce Well-being</td>
                            <td>
                              <span>{this.renderPeerRanking(8, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={`card-panel ${selectedPeerRanking === 3 ? 'active' : ''}`}>
                    <div className="card-body">
                      <table>
                        <thead>
                          <tr>
                            <th>Governance Issues</th>
                            <th>
                              <span>{this.renderPeerRanking(3, peerRankings, rankingOverrides)}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Board Governance</td>
                            <td>
                              <span>{this.renderPeerRanking(9, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Corporate Trust</td>
                            <td>
                              <span>{this.renderPeerRanking(10, peerRankings, rankingOverrides)}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {managerCards.map((card, index) =>
              this.renderManagerChartCard(card.data, card.description, card.labels, card.path, index),
            )}

            {this.renderCompanyEngagement()}

            <div className="card card-double">
              <div className="card-body">
                <h2 className="h2-alt">Holdings</h2>
                <div className="react-bootstrap-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th className="text-center">Harmony Rating</th>
                        <th style={{width: 100}} className="text-center">
                          Weight
                        </th>
                        <th style={{width: 100}} className="text-right">
                          Held
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topHoldings.map(holding => (
                        <tr key={holding.isin}>
                          <td style={{color: 'rgb(2, 122, 162)'}}>
                            <Link to={ROUTE.COMPANY_DETAIL.path(holding.isin) + '?ref=dashboard'}>
                              {holding.companyName}
                            </Link>
                          </td>
                          <td className="text-center">
                            <div>
                              <span style={{display: 'inline-block'}}>{ratingFormatter(holding.companyRating)}</span>
                            </div>
                          </td>
                          <td className="text-center">{numeral(holding.weight).format('0.00%')}</td>
                          <td className="text-right">{formatCurrency(holding.heldAmount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="link-footer">
                  <span>
                    <Link to={ROUTE.PLAN.path()}>
                      View Holdings Distribution <Icon icon={faChevronRight} />
                    </Link>
                  </span>
                  <span>
                    <Link to={ROUTE.COMPANIES.path()}>
                      View Companies <Icon icon={faChevronRight} />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    details: get(state, 'planDashboard.details'),
    planSponsorName: get(state, 'planDashboard.details.name'),
    rankingOverrides: keyBy(get(state, 'planDashboard.details.planSponsorRankingOverrides'), 'issueId'),
    compliantManagersPct: (get(state, 'dashboard.data.compliantManagersRatio') * 100).toFixed(0),
    engagedManagersPct: (get(state, 'dashboard.data.engagedManagersRatio') * 100).toFixed(0),
    esgManagersPct: (get(state, 'dashboard.data.esgManagersRatio') * 100).toFixed(0),
    topHoldings: get(state, 'dashboard.data.topHoldings'),
    settings: get(state, 'settings.global'),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const DashboardUserType1 = compose(connect(mapStateToProps, null, mergeProps))(DashboardUserType1Component);
