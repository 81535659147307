import {isNull, omitBy} from 'lodash';
import {useMemo} from 'react';
import {useGetCompanyByIdQuery, useGetManualEditCompanyIssuesQuery} from 'v2/redux/typeormEndpoints';
import {Company, ManualEditCompanyIssue} from 'v2/utilities/types/typeOrm';

export type useAdminCompanyIssuesProps = {
  companyId?: number;
};

export const useAdminCompanyIssues = ({companyId}: useAdminCompanyIssuesProps) => {
  const {
    data: companyData,
    isSuccess: companyIsSuccess,
    isLoading: companyIsLoading,
    isError: companyIsError,
  } = useGetCompanyByIdQuery({companyId}, {skip: !companyId});

  const {
    data: edit,
    isError: editIsError,
    isLoading: editIsLoading,
    isSuccess: editIsSuccess,
  } = useGetManualEditCompanyIssuesQuery({companyId}, {skip: !companyId});

  const isSuccess = companyIsSuccess && editIsSuccess;
  const isLoading = companyIsLoading && editIsLoading;
  const isError = companyIsError && editIsError;

  const data: Company = useMemo(() => {
    if (isLoading || !isSuccess) {
      return {};
    }

    const latestEdit = ((edit ?? []) as ManualEditCompanyIssue[])
      .filter(
        ({
          carbonNeutralPledgePlan,
          carbonNeutralPledgeStatus,
          carbonNeutralPledgeSigned,
          carbonNeutralPledgeTargetYear,
          carbonNeutralSourceName,
          fileName,
        }) => {
          return (
            !isNull(carbonNeutralPledgePlan) ||
            !isNull(carbonNeutralPledgeStatus) ||
            !isNull(carbonNeutralPledgeSigned) ||
            !isNull(carbonNeutralPledgeTargetYear) ||
            !isNull(carbonNeutralSourceName) ||
            !isNull(fileName)
          );
        },
      )
      .sort((x: any, y: any) => {
        const prev = new Date(x.createdAt);
        const cur = new Date(y.createdAt);

        return Number(cur) - Number(prev);
      })[0];

    const mergedData = {...companyData, ...omitBy(latestEdit, isNull)};
    return mergedData;
  }, [companyData, edit, isLoading, isSuccess]);

  return {
    data,
    isSuccess,
    isLoading,
    isError,
  };
};
