import {UNIT_PROPERTIES} from 'v2/utilities/helpers/unitFilters';

export const convertUnits = (amount: number, from: string, to: string, precision: number) => {
  const toConversion = UNIT_PROPERTIES[to]?.factor || 0;
  const fromConversion = UNIT_PROPERTIES[from]?.factor || 0;

  return fromConversion && toConversion
    ? ((amount * toConversion) / fromConversion).toPrecision(precision || 6)
    : amount;
};

export const getPrecision = (amount: number) => {
  if (amount) {
    const [whole, decimal] = amount.toString().split('.');
    if (!+whole) {
      return decimal?.length || 0;
    } else if (!decimal) {
      return whole.length || 0;
    }
    return whole.length + decimal.length;
  }
  return 0;
};
