import React, {Component} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

class BadgeDisplay extends Component {
  render() {
    const {data} = this.props;

    const renderBadges = () => {
      const badgesArray = [];
      if (data && data.badges) {
        data.badges.forEach((badge, i) => {
          badgesArray.push(
            <div className="col badge-display-image-container flex-grow-0" key={i}>
              <img crossOrigin="anonymous" src={badge} className="badge-image" alt="Badge" />
            </div>,
          );
        });
      }
      return badgesArray;
    };

    return (
      <div className="badge-display-container">
        <div className="badge-display-container-title d-flex">
          <div className="badge-header-icon-container">
            {data && data.icon && <Icon icon={data.icon} className="header-icon badge-header-icon" />}
          </div>
          <div className="badge-header-text">{data && data.title && <span>{data.title}</span>}</div>
        </div>
        <div className="badge-row row flex-wrap">{renderBadges()}</div>
      </div>
    );
  }
}

export {BadgeDisplay};
