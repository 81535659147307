import {createAction} from 'redux-actions';

// Local Imports
import {assignWithState} from 'app/redux/helpers';
import {fetchAuthJSON} from 'app/services/http';

// The initial state, including expected shape of the state
const initialState = {hasError: false, isFetching: false, listing: null};

// The default state for REQUEST actions
const defaultRequestState = {hasError: false, error: null, isFetching: true};

// ACTION
export const ACTION = {
  GET_REQUEST: 'admin-security/get/request',
  GET_RECEIVE: 'admin-security/get/receive',
  GET_PAGED_REQUEST: 'admin-security/get-paged/request',
  GET_PAGED_RECEIVE: 'admin-security/get-paged/receive',
  SAVE_REQUEST: 'admin-security/save/request',
  SAVE_RECEIVE: 'admin-security/save/receive',
};

const getRequest = createAction(ACTION.GET_REQUEST);
const getReceive = createAction(ACTION.GET_RECEIVE);
const getPagedRequest = createAction(ACTION.GET_PAGED_REQUEST);
const getPagedReceive = createAction(ACTION.GET_PAGED_RECEIVE);
const saveRequest = createAction(ACTION.SAVE_REQUEST);
const saveReceive = createAction(ACTION.SAVE_RECEIVE);

const api = {
  get: id => {
    return fetchAuthJSON(`securities/${id}`, {method: 'get'}).then(results => ({results, parameters: {id}}));
  },
  getPaged: options => {
    let url = 'securities/pagedAdmin?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`;
    }
    if (options.filter) {
      url += `&filter=${encodeURIComponent(options.filter)}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  save: (id, values) => {
    return fetchAuthJSON(`securities/${id}`, {
      method: 'put',
      body: JSON.stringify(values),
    }).then(results => ({results, parameters: {id}}));
  },
};

export function getSecurity(securityId) {
  return dispatch => {
    dispatch(getRequest({securityId}));
    return dispatch(getReceive(api.get(securityId)));
  };
}

export function getPaged(options) {
  return dispatch => {
    dispatch(getPagedRequest({options}));
    return dispatch(getPagedReceive(api.getPaged(options)));
  };
}

export function saveSecurity(securityId, values) {
  return dispatch => {
    dispatch(saveRequest({securityId, values}));
    return dispatch(saveReceive(api.save(securityId, values)));
  };
}

// REDUCER
export const adminSecurity = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_REQUEST:
    case ACTION.SAVE_REQUEST:
      return assignMergedState({edit: {...state.edit, ...defaultRequestState}});
    case ACTION.GET_PAGED_REQUEST:
      return assignMergedState({listing: defaultRequestState});
    case ACTION.GET_RECEIVE:
      return assignMergedState({
        edit: {
          ...state.edit,
          hasError,
          isFetching: false,
          ...(hasError ? {error: payload} : {[payload.parameters.id]: {data: payload.results}}),
        },
      });
    case ACTION.GET_PAGED_RECEIVE:
      return assignMergedState({
        listing: {
          hasError,
          isFetching: false,
          ...(hasError ? {error: payload} : {data: payload.items, totalSize: payload.total}),
        },
      });
    case ACTION.SAVE_RECEIVE: {
      return assignMergedState({
        edit: {
          ...state.edit,
          hasError,
          isFetching: false,
          ...(hasError ? {error: payload} : {[payload.parameters.id]: {data: payload.results}}),
        },
      });
    }
    default:
      return state;
  }
};
