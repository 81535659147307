import {useEffect} from 'react';
import {useSelector, RootStateOrAny, useDispatch} from 'react-redux';
import {useScrollPosition} from '@n8tb1t/use-scroll-position';
import {Callback, Middleware} from './types';
import {updateScrollPosition} from 'v2/redux/scrollPosition';

const Layout: React.FC<{}> = ({children}) => {
  const scrollPositionY = useSelector((state: RootStateOrAny) => state.scrollPosition.y);
  const dispatch = useDispatch();

  useScrollPosition(({currPos}) => {
    if (currPos.y !== 0) {
      dispatch(updateScrollPosition(currPos.y));
    }
  });

  useEffect(() => {
    window.scrollTo({top: -scrollPositionY});
  }, []);

  useEffect(() => {
    if (scrollPositionY) {
      window.scrollTo({top: -scrollPositionY});
    }
  }, [scrollPositionY]);

  return <>{children}</>;
};

export const scrollPreservation: Middleware = async (next: Callback) => {
  return <Layout>{await next()}</Layout>;
};
