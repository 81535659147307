import {sum, map} from 'lodash';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {Container, AttachedWrapper} from 'v2/components/atoms/Containers';
import {EmbeddedLink} from 'v2/components/atoms/Typeface';
import {DataTable} from 'v2/components/organisms/ManagersDataTable';
import {ROUTE} from 'v2/constants/routes';
import {useOwnership} from 'v2/hooks/useOwnership';
import {formatMillions} from 'v2/utilities/helpers';
import ReactPlaceholder from 'react-placeholder';
import {RectShape} from 'react-placeholder/lib/placeholders';
import {Theme} from 'v2/components/atoms/theme';
import {ReactTable} from 'v2/components/organisms/ReactTable';
import {useMemo} from 'react';
import {CellProps} from 'react-table';
import {DATA_STATUS} from 'v2/constants/dataStatus';

const Cell = styled.span<{align?: string}>`
  &&& {
    font-size: 1rem;
  }
`;

const TotalCell = styled(Cell).attrs(() => ({
  align: 'right',
}))`
  font-weight: 600;
`;

const TotalLabel = styled.span`
  color: ${({theme}) => theme.colors.grey_5};
`;

const ReactPlaceholderOwnership = () => (
  <div style={{marginBottom: 40}}>
    <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 30}} />
    <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 10}} />
    <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 10}} />
    <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 10}} />
    <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 10}} />
    <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 40, marginTop: 10}} />
  </div>
);

export const UT1CompaniesOwnership = () => {
  const {data: strategy, isLoading: strategyLoading} = useOwnership();

  const isLoading = strategyLoading;

  const memoizedData = useMemo(
    () => strategy.tableData.sort((a, b) => b.amountHeld - a.amountHeld),
    [strategy, isLoading],
  );

  type OwnershipColumn = typeof memoizedData[0];
  const memoizedColumns = useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
      },
      {
        Header: () => <Cell>Manager</Cell>,
        Cell: (cell: CellProps<OwnershipColumn, string | undefined>) => {
          const id = cell.row.original.id;
          return id ? (
            <Cell>
              <EmbeddedLink as={Link} to={ROUTE.UT1.STRATEGIES.PROFILE.path.replace(':strategyId', String(id))}>
                {cell.value ?? DATA_STATUS.NONE}
              </EmbeddedLink>
            </Cell>
          ) : (
            <></>
          );
        },
        Footer: (
          <Cell>
            <TotalLabel>Total</TotalLabel>
          </Cell>
        ),
        accessor: 'manager',
      },
      {
        Header: () => <Cell align="right">{'Amount Held'}</Cell>,
        Cell: (cell: CellProps<OwnershipColumn, number>) => (
          <Cell align="right">{formatMillions(cell.value, 1, '$')}</Cell>
        ),
        Footer: () => {
          return <TotalCell>{formatMillions(strategy.total, 1, '$')}</TotalCell>;
        },
        accessor: 'amountHeld',
      },
    ],
    [strategy, isLoading],
  );

  return (
    <AttachedWrapper>
      <Container padding="2.875rem 2.875rem">
        <ReactPlaceholder ready={!isLoading} customPlaceholder={<ReactPlaceholderOwnership />}>
          <ReactTable
            columns={memoizedColumns}
            data={memoizedData}
            initialState={{hiddenColumns: ['id']}}
            isPadded={false}
            withFooter
          />
        </ReactPlaceholder>
      </Container>
    </AttachedWrapper>
  );
};
