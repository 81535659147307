import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const PHONE_TYPE = {
  PHONE_TYPE_OFFICE: 'Office',
  PHONE_TYPE_DIRECT: 'Direct',
  PHONE_TYPE_MOBILE: 'Mobile',
  PHONE_TYPE_OTHER: 'Other',
};

export const PHONE_TYPES = map(PHONE_TYPE, value => value);
