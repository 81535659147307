import cn from 'classnames';
import {each, uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';

import {Toggleable} from 'app/components';

class StrategyCard extends Component {
  constructor(props) {
    super(props);
    this.tooltipId = 'tooltip_' + uniqueId();
  }

  render() {
    const {children, wrapperClassName} = this.props;

    const front = [];
    const back = [];
    const frontAlert = [];
    const backAlert = [];

    if (children) {
      if (children.length) {
        each(children, child => {
          if (child.key === 'back') {
            back.push(child);
          } else if (child.key === 'front-alert') {
            frontAlert.push(child);
          } else if (child.key === 'back-alert') {
            backAlert.push(child);
          } else {
            front.push(child);
          }
        });
      } else if (children.key === 'back') {
        back.push(children);
      } else if (children.key === 'front-alert') {
        frontAlert.push(children);
      } else if (children.key === 'back-alert') {
        backAlert.push(children);
      } else {
        front.push(children);
      }
    }

    const isFlippable = back.length > 0;

    return (
      <Toggleable>
        {({isActive, toggle}) => (
          <div className={cn('strategy-card', {'is-flipped': isActive})}>
            <div className={cn('strategy-card-front', wrapperClassName)}>
              <div className="strategy-card-alert">
                {/* Alternate class is 'warning', 'danger' */}
                {isFlippable && (
                  <Fragment>
                    <a href="#" className="strategy-card-toggle is-ringed" onClick={toggle} id={this.tooltipId}>
                      {frontAlert}
                    </a>
                  </Fragment>
                )}
              </div>
              {front}
            </div>
            {isFlippable && (
              <div className="strategy-card-back">
                <a href="#" className="strategy-card-toggle text-white" onClick={toggle}>
                  {backAlert}
                </a>
                {back}
              </div>
            )}
          </div>
        )}
      </Toggleable>
    );
  }
}

StrategyCard.propTypes = {
  children: PropTypes.any,
  wrapperClassName: PropTypes.string,
};

export {StrategyCard};
