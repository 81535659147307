import React from 'react';
import styled from 'styled-components';
import {Overline} from '../atoms/Typeface';

const StyledPill = styled.div`
  width: 4.625rem;
  height: 1.188rem;
  border: 0.063rem solid #464255;
  box-sizing: border-box;
  border-radius: 0.594rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlainPill = ({text}) => {
  return (
    <StyledPill>
      <Overline>{text}</Overline>
    </StyledPill>
  );
};
