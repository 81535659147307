import {isNull, omitBy} from 'lodash';
import {useGetManualEditCompanyIssuesQuery} from 'v2/redux/typeormEndpoints';
import {ManualEditCompanyIssue} from 'v2/utilities/types/typeOrm';

/**
 * Use this hook to get all the edits for a company
 * and filter by issueId and year or fields when issueId is not provided
 */
export const useManualEditCompanyIssues = ({
  companyId,
  fields,
  issueId,
  skip,
  year,
}: {
  companyId: number;
  fields?: string[];
  issueId?: number;
  skip?: boolean;
  year?: number;
}) => {
  const {data: auditData = [], isLoading} = useGetManualEditCompanyIssuesQuery(
    {companyId, skip: !companyId || skip},
    {skip: !companyId || skip},
  );

  const data: ManualEditCompanyIssue[] = [...auditData]
    ?.sort((a: any, b: any) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    })
    ?.filter((item: any) =>
      issueId
        ? item.issueId === issueId && item.year === year
        : fields?.some(field => Object.keys(omitBy(item, isNull)).includes(field)),
    );

  const lastUpdatedBy = {date: data?.[0]?.createdAt, name: data?.[0]?.userEmail};

  return {data, isLoading, lastUpdatedBy};
};
