import {keyBy} from 'lodash';

export const ISSUES = {
  GLOBAL: {
    id: 0,
    parentId: null,
    topLevelParentId: 0,
    name: '',
  },
  ENVIRONMENTAL: {
    id: 1,
    parentId: null,
    topLevelParentId: 1,
    name: 'Environmental',
  },
  SOCIAL: {
    id: 2,
    parentId: null,
    topLevelParentId: 2,
    name: 'Social',
  },
  GOVERNANCE: {
    id: 3,
    parentId: null,
    topLevelParentId: 3,
    name: 'Governance',
  },
  ENVIRONMENT: {
    id: 4,
    parentId: 1,
    topLevelParentId: 1,
    name: 'Environment',
  },
  ENVIRONMENTAL_POLICIES: {
    id: 5,
    parentId: 1,
    topLevelParentId: 1,
    name: 'Environmental Policies',
  },
  DIVERSITY_AND_INCLUSION: {
    id: 6,
    parentId: 2,
    topLevelParentId: 2,
    name: 'Diversity and Inclusion',
  },
  COMPENSATION_EQUITY: {
    id: 7,
    parentId: 2,
    topLevelParentId: 2,
    name: 'Compensation Equity',
  },
  WORKFORCE_WELLBEING: {
    id: 8,
    parentId: 2,
    topLevelParentId: 2,
    name: 'Workforce Wellbeing',
  },
  BOARD_GOVERNANCE: {
    id: 9,
    parentId: 3,
    topLevelParentId: 3,
    name: 'Board Governance',
  },
  CORPORATE_TRUST: {
    id: 10,
    parentId: 3,
    topLevelParentId: 3,
    name: 'Corporate Trust',
  },
  CARBON_EMISSIONS: {
    id: 11,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Carbon Emissions',
  },
  ENERGY_USE: {
    id: 12,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Energy Consumption',
  },
  WATER_CONSUMPTION: {
    id: 13,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Water Consumption',
  },
  CLIMATE_CHANGE_POLICY: {
    id: 14,
    parentId: 5,
    topLevelParentId: 1,
    name: 'Climate Change Policy',
  },
  ENVIRONMENTAL_RISK_POLICY: {
    id: 15,
    parentId: 5,
    topLevelParentId: 1,
    name: 'Environmental Risk Policy',
  },
  ENVIRONMENTAL_INCENTIVES_POLICY: {
    id: 16,
    parentId: 5,
    topLevelParentId: 1,
    name: 'Environmental Incentives Policy',
  },
  GENDER_EQUALITY: {
    id: 17,
    parentId: 6,
    topLevelParentId: 2,
    name: 'Gender Equality',
  },
  ETHNIC_AND_CULTURAL_IDENTITY: {
    id: 18,
    parentId: 6,
    topLevelParentId: 2,
    name: 'Ethnic and Cultural Identity',
  },
  CEO_COMPENSATION_RATIO: {
    id: 19,
    parentId: 7,
    topLevelParentId: 2,
    name: 'CEO Compensation Ratio',
  },
  INJURY_FREQUENCY: {
    id: 20,
    parentId: 8,
    topLevelParentId: 2,
    name: 'Injury Frequency',
  },
  HUMAN_RIGHTS_POLICY: {
    id: 21,
    parentId: 8,
    topLevelParentId: 2,
    name: 'Human Rights Policy',
  },
  NON_DISCRIMINATION_POLICY: {
    id: 22,
    parentId: 8,
    topLevelParentId: 2,
    name: 'Non-Discrimination Policy',
  },
  HEALTH_AND_SAFETY_POLICY: {
    id: 23,
    parentId: 8,
    topLevelParentId: 2,
    name: 'Health and Safety Policy',
  },
  CHILD_AND_FORCED_LABOR_POLICY: {
    id: 24,
    parentId: 8,
    topLevelParentId: 2,
    name: 'Child and Forced Labor Policy',
  },
  BOARD_INDEPENDENCE: {
    id: 25,
    parentId: 9,
    topLevelParentId: 3,
    name: 'Board Independence',
  },
  SEPARATION_OF_POWERS_POLICY: {
    id: 26,
    parentId: 9,
    topLevelParentId: 3,
    name: 'Separation of Powers Policy',
  },
  CODE_OF_CONDUCT_POLICY: {
    id: 27,
    parentId: 10,
    topLevelParentId: 3,
    name: 'Code of Conduct Policy',
  },
  ETHICS_AND_ANTI_CORRUPTION_POLICY: {
    id: 28,
    parentId: 10,
    topLevelParentId: 3,
    name: 'Ethics and Anti-Corruption Policy',
  },
  DATA_PRIVACY_POLICY: {
    id: 29,
    parentId: 10,
    topLevelParentId: 3,
    name: 'Data Privacy Policy',
  },
  WOMEN_IN_LEADERSHIP: {
    id: 30,
    parentId: 17,
    topLevelParentId: 2,
    name: 'Women in Leadership',
  },
  WOMEN_ON_BOARD: {
    id: 31,
    parentId: 17,
    topLevelParentId: 2,
    name: 'Women On Board',
  },
  BOARD_DIVERSITY: {
    id: 32,
    parentId: 18,
    topLevelParentId: 2,
    name: 'Board Diversity',
  },
  LEADERSHIP_DIVERSITY: {
    id: 33,
    parentId: 18,
    topLevelParentId: 2,
    name: 'Leadership Diversity',
  },
  CARBON_FOOTPRINT_OFFSETS: {
    id: 34,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Carbon Footprint Offsets',
  },
  CARBON_NEUTRAL_PLEDGE: {
    id: 35,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Carbon Neutral Pledge',
  },
  SCOPE_ONE_EMISSIONS: {
    id: 36,
    parentId: 11,
    topLevelParentId: 1,
    name: 'Scope 1 Emissions',
  },
  SCOPE_TWO_EMISSIONS: {
    id: 37,
    parentId: 11,
    topLevelParentId: 1,
    name: 'Scope 2 Emissions',
  },
  SCOPE_THREE_EMISSIONS: {
    id: 38,
    parentId: 11,
    topLevelParentId: 1,
    name: 'Scope 3 Emissions',
  },
  RENEWABLE_ENERGY_CONSUMPTION: {
    id: 39,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Renewable Energy Consumption',
  },
  ELECTRICITY_CONSUMPTION: {
    id: 40,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Electricity Consumption',
  },
  FUELS_CONSUMPTION: {
    id: 41,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Fuels Consumption',
  },
  WATER_WITHDRAWAL: {
    id: 42,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Water Withdrawal',
  },
  WATER_DISCHARGE: {
    id: 43,
    parentId: 4,
    topLevelParentId: 1,
    name: 'Water Discharge',
  },
  CEO_COMPENSATION: {
    id: 44,
    parentId: 19,
    topLevelParentId: 2,
    name: 'CEO Compensation',
  },
  MEDIAN_EMPLOYEE_PAY: {
    id: 45,
    parentId: 19,
    topLevelParentId: 2,
    name: 'Median Employee Pay',
  },
  SEVERE_INJURY_FREQUENCY: {
    id: 46,
    parentId: 19,
    topLevelParentId: 2,
    name: 'Sever Injury Frequency',
  },
};

export const ENGAGEMENT_CHART_VALUES = [
  ISSUES.CARBON_EMISSIONS,
  ISSUES.ENERGY_USE,
  ISSUES.WATER_CONSUMPTION,
  ISSUES.CEO_COMPENSATION_RATIO,
  ISSUES.WOMEN_IN_LEADERSHIP,
  ISSUES.WOMEN_ON_BOARD,
  ISSUES.BOARD_GOVERNANCE,
];

export const ENGAGEMENT_CHART_CATEGORIES = {
  [ISSUES.ENVIRONMENTAL.id]: [ISSUES.CARBON_EMISSIONS, ISSUES.ENERGY_USE, ISSUES.WATER_CONSUMPTION],
  [ISSUES.SOCIAL.id]: [ISSUES.CEO_COMPENSATION_RATIO, ISSUES.WOMEN_IN_LEADERSHIP, ISSUES.WOMEN_ON_BOARD],
  [ISSUES.GOVERNANCE.id]: [ISSUES.BOARD_GOVERNANCE],
};

export const ISSUES_BY_ID = keyBy(Object.values(ISSUES), 'id');

export const TOP_LEVEL_ISSUES = keyBy(
  Object.values(ISSUES).filter(issue => issue.parentId === null),
  'id',
);
