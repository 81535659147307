import {isNaN} from 'lodash';
import {useParams} from 'react-router-dom';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {
  useGetStrategyAggregatePercentileRankHoldingsQuery,
  useGetStrategyAggregatePercentileRankIndexesQuery,
} from 'v2/redux/typeormEndpoints';
import {getOrdinal} from 'v2/utilities/helpers';
import {formatOrdinalValue} from 'v2/utilities/helpers/formatOrdinalValue';
import {limitPctRank} from 'v2/utilities/helpers/limitPctRank';
import {AggregatePercentileHolding, AggregatePercentileRankIndex} from 'v2/utilities/types/typeOrm';

export const useStrategyAggregatePercentileByIssue = (issueId: string | number) => {
  const {strategyId = ''} = useParams<{strategyId: string}>();
  const {
    data: strategyData1,
    isError: isError1,
    isSuccess: isSuccess1,
    isLoading: isLoading1,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 1});
  const {
    data: strategyData2,
    isError: isError2,
    isSuccess: isSuccess2,
    isLoading: isLoading2,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 2});
  const {
    data: strategyData3,
    isError: isError3,
    isSuccess: isSuccess3,
    isLoading: isLoading3,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 3});
  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const isError = isError1 || isError2 || isError3;
  const isSuccess = isSuccess1 && isSuccess2 && isSuccess3;
  const strategyData = strategyData1?.concat(strategyData2, strategyData3);

  const strategies = isLoading
    ? []
    : ((strategyData ?? []) as AggregatePercentileHolding[]).filter(datum => datum?.issueId === issueId);

  const calcPercentileRank = () => {
    let totalAmountHeld = 0,
      totalWeight = 0;

    strategies.forEach(datum => {
      totalAmountHeld += Number(datum.amountHeld);
      totalWeight += Number(datum.issuePctRank) * Number(datum.amountHeld);
    });

    let overallPercentile = Math.round(100 * (totalWeight / totalAmountHeld));
    overallPercentile = limitPctRank(overallPercentile);

    return isNaN(overallPercentile) ? DATA_STATUS.NONE : String(overallPercentile) + getOrdinal(overallPercentile);
  };

  const calcBenchmark = () => {
    let totalMarketCap = 0,
      totalWeight = 0;

    strategies.forEach(datum => {
      totalMarketCap += Number(datum.marketCap);
      totalWeight += Number(datum.issuePctRank) * Number(datum.marketCap);
    });

    const overallBenchmark = Math.round(100 * (totalWeight / totalMarketCap));

    return isNaN(overallBenchmark) ? DATA_STATUS.NONE : String(overallBenchmark) + getOrdinal(overallBenchmark);
  };

  const calcQuintile = () => {
    let totalAmountHeld = 0,
      totalWeight = 0;

    strategies.forEach(datum => {
      totalAmountHeld += Number(datum.amountHeld);
      totalWeight += Number(datum.issuePctRank) * Number(datum.amountHeld);
    });

    const overallPercentile = 100 * (totalWeight / totalAmountHeld);

    return Math.floor(overallPercentile / 20 + 1);
  };

  const percentileRank = calcPercentileRank();
  const benchmark = calcBenchmark();
  const quintile = calcQuintile();

  return {
    data: {
      percentileRank,
      benchmark,
      quintile,
    },
    isError,
    isLoading,
    isSuccess,
  };
};

export const useOverallStrategyAggregatePercentile: any = () => {
  const {strategyId = ''} = useParams<{strategyId: string}>();

  const {
    data: strategyData,
    isError,
    isSuccess,
    isLoading,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId});

  const {
    data: indexData,
    isError: indexIsError,
    isSuccess: indexIsSuccess,
    isLoading: indexIsLoading,
  } = useGetStrategyAggregatePercentileRankIndexesQuery({strategyId});

  const strategies = (strategyData as AggregatePercentileHolding[]) ?? [];
  const indexes = (indexData as AggregatePercentileRankIndex[]) ?? [];

  const calcPercentileRank = () => {
    let totalAmountHeld = 0,
      totalWeight = 0;

    strategies.forEach(datum => {
      totalAmountHeld += Number(datum.amountHeld);
      totalWeight += Number(datum.companyPctRank) * Number(datum.amountHeld);
    });

    const overallPercentile = Math.round(100 * (totalWeight / totalAmountHeld));

    return formatOrdinalValue(overallPercentile);
  };

  const calcBenchmark = () => {
    let totalWeightedRanks = 0,
      totalWeight = 0;

    indexes.forEach(datum => {
      totalWeightedRanks += Number(datum.weight) * Number(datum.company_pct_rank);
      totalWeight += Number(datum.weight);
    });

    let overallBenchmark = Math.round(100 * (totalWeightedRanks / totalWeight));

    return formatOrdinalValue(limitPctRank(overallBenchmark));
  };

  const calcQuintile = () => {
    let totalAmountHeld = 0,
      totalWeight = 0;

    strategies.forEach(datum => {
      totalAmountHeld += Number(datum.amountHeld);
      totalWeight += Number(datum.companyPctRank) * Number(datum.amountHeld);
    });

    const overallPercentile = Math.round(100 * (totalWeight / totalAmountHeld));

    return formatOrdinalValue(Math.floor(overallPercentile / 20 + 1));
  };

  const percentileRank = calcPercentileRank();
  const benchmark = calcBenchmark();
  const quintile = calcQuintile();

  return {
    data: {
      percentileRank,
      benchmark,
      quintile,
    },
    isError: isError && indexIsError,
    isLoading: isLoading && indexIsLoading,
    isSuccess: isSuccess && indexIsSuccess,
  };
};
