/* eslint-disable no-param-reassign */
import React, {Component} from 'react';
import {zeroCompensation} from '../../utilities/zeroCompensation';
import {formatNumberCommas} from '../../utilities/formatNumberCommas';
import {Bar} from 'react-chartjs-2';

export class BarChartPanel extends Component {
  render() {
    const {empty, title, unit, description, widthPercent, aspectRatio, datasets} = this.props;

    if (empty) {
      return <div style={{width: `${widthPercent}%`}} />;
    }

    const chartData = {
      labels: [''],
      datasets: [
        {
          label: 'Portfolio',
          fill: false,
          borderColor: '#34B991',
          backgroundColor: '#34B991',
          data: datasets[0],
        },
      ],
    };

    if (datasets[1]) {
      chartData.datasets[1] = {
        label: 'Universe',
        fill: false,
        borderColor: '#034682',
        backgroundColor: '#034682',
        data: datasets[1],
      };
    }

    const chartOptions = {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
      animation: false,
      barPercentage: datasets.length === 1 ? 0.9 : 0.7,
      scales: {
        x: {
          offset: true,
          grid: {
            borderWidth: 1,
            display: false,
            drawBorder: true,
            drawTicks: false,
            borderColor: '#404040',
          },
          min: 1,
          max: 1,
          ticks: {
            stepSize: 1,
            callback(value, index) {
              return chartData.labels[index];
            },
          },
        },
        y: {
          min: 0,
          ticks: {
            font: {size: 28},
            padding: 30,
            callback(value) {
              return formatNumberCommas(value);
            },
          },
          grid: {
            display: false,
            drawBorder: false,
            drawTicks: false,
            color: '#404040',
          },
        },
      },
    };

    return (
      <div style={{width: `${widthPercent}%`}}>
        <div className={'bar-chart-panel w-100'}>
          <div className={'d-flex content pt-5 px-5 flex-column justify-content-around'}>
            <Bar
              plugins={[zeroCompensation]}
              data={chartData}
              options={chartOptions}
              width={widthPercent / 100}
              height={(widthPercent / 100) * (1 / aspectRatio)}
            />
          </div>
          <h3 className={'title-box text-center'} style={{lineHeight: unit ? '30px' : '60px'}}>
            <span>{title}</span>
            {unit ? (
              <React.Fragment>
                <br />
                <span>({unit})</span>
              </React.Fragment>
            ) : (
              ''
            )}
          </h3>
          <div style={{paddingTop: `${(1 / aspectRatio) * 100}%`, backgroundColor: 'transparent'}} />
        </div>
        <div className={'d-flex w-100 p-5 justify-content-around'}>
          <span className={'d-table-cell w-100 text-center'}>{description}</span>
        </div>
      </div>
    );
  }
}
