import React from 'react';

const DoughnutLegend = () => (
  <div className="doughnut-legend">
    <div className="doughnut-legend-label is-green">Promising</div>
    <div className="doughnut-legend-label is-red">Worrisome</div>
  </div>
);

export {DoughnutLegend};
