import cn from 'classnames';
import {isValid} from 'date-fns';
import {isEmpty, get, map, orderBy, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import moment from 'moment';

// Local Imports
import securityMarketIndexTemplateCsv from 'app/assets/docs/security-market-index-template.csv';
import {AUTOCOMPLETE, CONFIG, FORM_MESSAGE} from 'app/constants';
import {Button, FormFeedback, MultiUploaderWrapper, TooltipLink} from 'app/components';
import {errorRequiredFields, formatUtcDate, normalizeMMDDYYYY} from 'app/utilities';
import {validateSecurityMarketIndexUpload} from 'app/utilities/fileFunctions';
import {toastError} from 'app/utilities/toast';
import {openSimpleModal} from './SimpleModal/redux';
import {Checkbox, TextFormGroup} from './forms';
import {PERMISSIONS} from '../constants/permissions';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';

const validate = values => {
  const errors = errorRequiredFields(values, ['securityMarketIndexUpload', 'securityMarketIndexUploadDate']);

  if (isEmpty(values.securityMarketIndexUpload)) {
    errors.securityMarketIndexUpload = 'Required';
  }

  if (!errors.securityMarketIndexUploadDate && !isValid(new Date(values.securityMarketIndexUploadDate))) {
    errors.securityMarketIndexUploadDate = 'Invalid Date';
  }

  return errors;
};

class SecurityMarketIndexUploader extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.input.value !== this.props.input.value && nextProps.input.value === 'EMPTY' && this.multiUploader) {
      this.multiUploader.removeAllFiles();
    }

    return true;
  }

  render() {
    const {
      onFinish,
      meta: {touched, error},
    } = this.props;
    return (
      <div className={cn('form-group', {'is-invalid': touched && error})}>
        <div className="label-hint-container uploader mb-3">
          <label>Select & Upload a CSV File* {touched && error && `(${error})`}</label>
          <span className="label-hint">
            <a href={securityMarketIndexTemplateCsv} target="_blank">
              Download CSV Template
            </a>
          </span>
        </div>
        <MultiUploaderWrapper
          value={this.props.input.value}
          isValidatingFileVisible={true}
          onFilesChanged={(files, s3file) => {
            if (!s3file) {
              this.props.input.onChange([]);
            } else {
              this.props.input.onChange([{name: get(s3file, 'fileKey', null), isNewFile: true}]);
            }
          }}
          multiUploaderProps={{
            onRef: e => {
              this.multiUploader = e;
            },
          }}
          uploaderProps={{
            accept: 'text/csv, .csv',
            onError: () => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE),
            onFinish,
            onSignedUrl: () => {
              /* Suppress Console logs */
            },
          }}
        />
      </div>
    );
  }
}

class AdminMarketIndexEditFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const pastUploads = orderBy(this.props.securityMarketIndexUploads || [], ['date', 'createdAt'], ['desc', 'desc']);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />

        <div className="form-grid">
          <Field label="Name" name="name" type="text" component={TextFormGroup} className="col-6" disabled={true} />
          <Field
            label="Is Harmony Index"
            name="isHarmonyIndex"
            type="checkbox"
            component={Checkbox}
            className="col-6 ml-6"
            disabled={true}
          />

          <div className="label-hint-container mt-12">
            <h3>Upload Index Securities</h3>
          </div>

          <Field
            label="Effective Date*"
            labelHint={
              <TooltipLink className="float-right" id="effectiveDateTooltip">
                The data in the uploaded CSV should represent a snapshot for a specific date.
              </TooltipLink>
            }
            name="securityMarketIndexUploadDate"
            type="text"
            component={TextFormGroup}
            autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            normalize={normalizeMMDDYYYY}
            className="col-2"
            maxLength="10"
            placeholder="MM/DD/YYYY"
            disabled={!this.props.canWrite}
          />
          <div className="form-group col-4" />
        </div>
        {this.props.canWrite && (
          <div className="mb-9">
            <Field
              name="securityMarketIndexUpload"
              component={SecurityMarketIndexUploader}
              onFinish={this.props.getCsvFileMeta}
            />
          </div>
        )}

        <div className="form-footer is-right mt-12">
          <Button
            type="submit"
            disabled={
              isEmpty(this.props.securityMarketIndexUpload) ||
              this.props.isCreatingStagingUpload ||
              this.props.hasStagingUploadError ||
              !this.props.canWrite
            }
            solid
          >
            Save
          </Button>
        </div>
        <div className="mb-9">
          <div className="label-hint-container mt-12">
            <h3>History of Processed Uploads</h3>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Filename</th>
                <th scope="col">Effective Date</th>
                <th scope="col">Date Uploaded</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(pastUploads) ? (
                map(pastUploads, (pastUpload, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/${pastUpload.uploadFilePath}`)}
                        target="_blank"
                      >
                        {pastUpload.originalFileName}
                      </a>
                    </td>
                    <td>{moment.utc(pastUpload.date).format('MMM DD, YYYY')}</td>
                    <td>{moment(pastUpload.createdAt).format('MMM DD, YYYY hh:mm A')}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">There are no uploads at this time</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </form>
    );
  }
}

AdminMarketIndexEditFormComponent.propTypes = {handleSubmit: PropTypes.func};

const mapStateToProps = state => {
  const form = 'admin-market-index-edit-form';
  const selector = formValueSelector(form);
  return {
    form,
    securityMarketIndexUpload: selector(state, 'securityMarketIndexUpload'),
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_REFERENCE_INDEXES),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadInfoModal: (title, message) =>
      dispatch(
        openSimpleModal({
          title,
          message,
          customClassName: 'modal-lg',
        }),
      ),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const initialValues = ownProps.marketIndex;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initialValues,
    getCsvFileMeta: validateSecurityMarketIndexUpload(ownProps.createStagingUpload, dispatchProps.onUploadInfoModal),
  };
};

export const AdminMarketIndexEditForm = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
)(AdminMarketIndexEditFormComponent);
