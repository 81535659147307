import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchIfNeeded} from 'app/services/http';

const initialState = {isFetching: false};

// ACTION
export const ACTION = {
  LIST_ALL_REQUEST: 'ORGANIZATION_TYPE_LIST_ALL_REQUEST',
  LIST_ALL_RECEIVE: 'ORGANIZATION_TYPE_LIST_ALL_RECEIVE',
};

const organizationTypeListAllRequest = createAction(ACTION.LIST_ALL_REQUEST);
const organizationTypeListAllReceive = createAction(ACTION.LIST_ALL_RECEIVE);

const api = {get: () => fetchAuthJSON('organizationType', {method: 'get'})};

export function fetchOrganizationTypes() {
  return dispatch => {
    dispatch(organizationTypeListAllRequest());
    return dispatch(organizationTypeListAllReceive(api.get()));
  };
}

export function fetchOrganizationTypesIfNeeded() {
  return fetchIfNeeded('organizationType', 'organizationTypes', () => fetchOrganizationTypes());
}

// REDUCER
export const organizationType = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LIST_ALL_REQUEST:
      return Object.assign({}, state, {isFetching: true});
    case ACTION.LIST_ALL_RECEIVE:
      return Object.assign({}, state, {organizationTypes: action.payload, isFetching: false});
    default:
      return state;
  }
};
