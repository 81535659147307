import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {Chart} from 'react-google-charts';
import {Title4, Body1, Title6, Title5} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';
import {Legend, SingleLegend} from './Legend';
import {RectShape, TextBlock} from 'react-placeholder/lib/placeholders';
import {FlexContainer} from '../atoms/Containers';

const PercentText = styled(Title4)<{top?: string}>`
  margin: 0;
  position: relative;
  top: ${({top}) => top ?? '8.25rem'};
  padding-bottom: 0.3125rem;
  padding-left: 0.625rem;
`;

const LabelText = styled(Body1)<{top?: string}>`
  position: relative;
  top: ${({top}) => top ?? '8.25rem'};
  margin: 0;
  max-width: 6.25rem;
`;

const Title = styled(Title6)<{top?: string}>`
  margin: 0;
  position: relative;
  top: ${({top}) => top ?? '-3.125rem'};
`;

const TextContainer = styled.div`
  width: 14.0625rem;
  height: 19.0625rem;
  position: relative;
  top: -17.1875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;

const Container = styled.div`
  align-text: center;
  height: 19.0625rem;
`;

const SmallPercent = styled(Title5)`
  margin: 0;
`;

const SmallTextContainer = styled(TextContainer)`
  width: 100px;
  height: 100px;
  top: -100px;
  justify-content: center;
`;

const Wrapper = styled.div`
  max-width: 225px;
  position: relative;
  top: 220px;
`;

export const SmallAnnotatedDoughnutChart: React.FC<{data: any; colors: string[]}> = ({data, colors}) => {
  return (
    <Container>
      <Chart
        width="100px"
        height="100px"
        chartType="PieChart"
        //@ts-ignore
        backgroundColor={{fill: 'transparent'}}
        data={data}
        options={{
          pieSliceBorderColor: 'none',
          pieHole: 0.77,
          enableInteractivity: false,
          legend: {position: 'none'},
          tooltip: {
            trigger: 'none',
          },
          colors: colors,
          pieSliceText: 'none',
          chartArea: {width: '80', height: '80', top: 10, left: 10},
        }}
      />
      <SmallTextContainer>
        <SmallPercent color={Theme.colors.dark}>{data[1][1] + '%'}</SmallPercent>
      </SmallTextContainer>
    </Container>
  );
};

export const AnnotatedDoughnutChart: React.FC<{
  data: any;
  title?: string;
  colors: string[];
  bottomTitle?: boolean;
  hideLabel?: boolean;
  singleLegend?: boolean;
  usesExternalState?: boolean;
  selectedArc?: number;
  setSelectedArc?: Function;
  legendAlign?: 'left' | 'center' | 'right';
}> = ({
  data,
  title,
  colors,
  bottomTitle,
  hideLabel,
  singleLegend,
  usesExternalState = false,
  selectedArc = 1,
  setSelectedArc,
  legendAlign = 'center',
}) => {
  const [row, setRow] = useState(selectedArc);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (usesExternalState) {
      setRow(selectedArc);
    }
  }, [selectedArc]);
  const labels = data.map((row: string[]) => {
    return row[0];
  });
  labels.shift();
  return (
    <Container>
      <Chart
        width="225px"
        height="225px"
        chartType="PieChart"
        data={data}
        //@ts-ignore
        onLoad={() => setIsLoading(false)}
        loader={
          <FlexContainer direction="column" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 200}} />
            <TextBlock rows={2} color={Theme.colors.grey_2} style={{width: 200, height: 40, marginBottom: 20}} />
          </FlexContainer>
        }
        options={{
          pieHole: 0.6,
          legend: {
            position: 'none',
          },
          tooltip: {
            trigger: 'none',
          },
          colors: colors,
          pieSliceText: 'none',
          chartArea: {width: '80%', height: '80%', top: bottomTitle ? 5 : 20},
        }}
        chartEvents={[
          {
            eventName: 'select',
            callback: ({chartWrapper, props, google, eventArgs}) => {
              const chart = chartWrapper.getChart();
              const selected = chart.getSelection();
              const [selectedItem] = selected;
              if (selectedItem && selectedItem.row + 1 !== row) {
                setRow(selectedItem.row + 1);
                if (usesExternalState && setSelectedArc) {
                  //@ts-ignore
                  setSelectedArc(selectedItem?.row);
                }
                //@ts-ignore
                chart.setSelection(selected);
              } else {
                //@ts-ignore
                chart.setSelection([{row: row - 1, column: undefined}]);
              }
            },
          },
          {
            eventName: 'ready',
            callback: ({chartWrapper, props, google, eventArgs}) => {
              const chart = chartWrapper.getChart();
              //@ts-ignore
              chart.setSelection([{row: row - 1, column: undefined}]);
            },
          },
        ]}
      />
      {!isLoading && (
        <TextContainer>
          <PercentText color={Theme.colors.dark} top={bottomTitle ? '7.4rem' : '9rem'}>
            {data[row][1] + '%'}
          </PercentText>
          {hideLabel || (
            <LabelText color={Theme.colors.dark} top={bottomTitle ? '7.5rem' : undefined}>
              {data[row][0]}
            </LabelText>
          )}

          <Title color={Theme.colors.dark} top={bottomTitle ? '12rem' : undefined}>
            {title}
          </Title>
          <Wrapper>
            {singleLegend || <Legend labels={labels} colors={colors} align={legendAlign} />}
            {singleLegend && <SingleLegend labels={labels[row - 1]} colors={colors[row - 1]} align={legendAlign} />}
          </Wrapper>
        </TextContainer>
      )}
    </Container>
  );
};

export const ManagersEngagementsDoughnutChart: React.FC<{
  data: any;
  title?: string;
  colors: string[];
  bottomTitle?: boolean;
  hideLabel?: boolean;
  singleLegend?: boolean;
  usesExternalState?: boolean;
  selectedArc?: number;
  setSelectedArc?: Function;
  sum?: number;
  largeLegend?: boolean;
}> = ({
  data,
  title,
  colors,
  bottomTitle,
  hideLabel,
  singleLegend,
  usesExternalState = false,
  selectedArc = 1,
  setSelectedArc,
  sum,
  largeLegend = false,
}) => {
  const [row, setRow] = useState(selectedArc);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (usesExternalState) {
      setRow(selectedArc);
    }
  }, [selectedArc]);

  const labels = data.map((row: string[]) => {
    return row[0];
  });

  useEffect(() => {
    if (data.length > 1 && usesExternalState) {
      setPercentage(data[selectedArc][1]);
    }
  });

  labels.shift();

  return (
    <Container>
      <Chart
        width="225px"
        height="225px"
        chartType="PieChart"
        data={data}
        options={{
          pieHole: 0.6,
          legend: {
            position: 'none',
          },
          tooltip: {
            trigger: 'none',
          },
          colors: colors,
          pieSliceText: 'none',
          chartArea: {width: '80%', height: '80%', top: bottomTitle ? 5 : 20},
        }}
        chartEvents={[
          {
            eventName: 'select',
            callback: ({chartWrapper, props, google, eventArgs}) => {
              const chart = chartWrapper.getChart();
              const selected = chart.getSelection();
              const [selectedItem] = selected;
              if (selectedItem && selectedItem.row + 1 !== row) {
                setRow(selectedItem.row + 1);
                if (usesExternalState && setSelectedArc) {
                  //@ts-ignore
                  setSelectedArc(selectedItem?.row);
                  setPercentage(data[row][selectedArc]);
                }
                //@ts-ignore
                chart.setSelection(selected);
                setPercentage(data[row][selectedArc]);
              } else {
                //@ts-ignore
                chart.setSelection([{row: row - 1, column: undefined}]);
                setPercentage(data[row][selectedArc]);
              }
            },
          },
          {
            eventName: 'ready',
            callback: ({chartWrapper, props, google, eventArgs}) => {
              const chart = chartWrapper.getChart();
              //@ts-ignore
              chart.setSelection([{row: row - 1, column: undefined}]);
            },
          },
        ]}
      />
      <TextContainer>
        <PercentText color={Theme.colors.dark} top={bottomTitle ? '7.4rem' : '9rem'}>
          {percentage}%
        </PercentText>
        {hideLabel || <LabelText color={Theme.colors.dark} top={bottomTitle ? '7.5rem' : undefined}></LabelText>}

        <Title color={Theme.colors.dark} top={bottomTitle ? '12rem' : undefined}>
          {title}
        </Title>
        <Wrapper>
          {singleLegend || <Legend labels={labels} colors={colors} align={'center'} />}
          {singleLegend && (
            <SingleLegend
              labels={labels[row - 1]}
              colors={colors[row - 1]}
              align={'center'}
              sum={sum}
              large={largeLegend}
            />
          )}
        </Wrapper>
      </TextContainer>
    </Container>
  );
};
