import {useParams} from 'react-router-dom';
import moment from 'moment';
import {getSessionJwt, isImpersonating} from 'app/utilities';
import {useGetCompanyByFirmQuery} from 'v2/redux/typeormEndpoints';
import {Company, StrategyHolding} from 'v2/utilities/types/typeOrm';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {RootStateOrAny, useSelector} from 'react-redux';

export const useUT3Ownership = () => {
  const firmId: number =
    useSelector((state: RootStateOrAny) =>
      isImpersonating() ? state.auth.impersonatedUser.firmId : state.auth.firmId,
    ) ?? 0;

  const {companyId = ''} = useParams<{companyId: string}>();

  const {data: companyData, isLoading, isError, isSuccess} = useGetCompanyByFirmQuery({companyId, firmId});

  const company = (companyData as Company) ?? {};

  const holdings = company.holdings ?? [];

  const equityDate = holdings[0]?.equityDate ? moment(holdings[0].equityDate).format('MM/DD/YYYY') : DATA_STATUS.NONE;

  const formatManager = ({strategy: {firm, name, id}}: StrategyHolding) => {
    if (!firm?.name) {
      return name;
    }

    return `${firm.name}: ${name}`;
  };

  const tableData = holdings.map(holding => {
    return {
      id: holding.strategy.id,
      manager: formatManager(holding),
      amountHeld: Number(holding.marketValue),
    };
  });

  return {
    data: {
      equityDate,
      tableData,
    },
    isLoading,
    isError,
    isSuccess,
  };
};
