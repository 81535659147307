/* eslint no-script-url: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import {NavLink} from 'react-router-dom';

const renderMenuItems = items =>
  map(items, ({path, menuTitle, title}, index) => (
    <li key={index}>
      <NavLink to={typeof path === 'string' ? path : path()}>{menuTitle || title}</NavLink>
    </li>
  ));

const SettingsSideNav = ({menuRoutes}) => {
  return <ul className="">{menuRoutes && renderMenuItems(menuRoutes)}</ul>;
};

const routeShape = {
  path: PropTypes.func.isRequired,
  title: PropTypes.string,
};

SettingsSideNav.propTypes = {menuRoutes: PropTypes.arrayOf(PropTypes.shape(routeShape))};

export {SettingsSideNav};
