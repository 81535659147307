// A wrapper component that provides a a toggleable state.
// Note: props.children must consist of exactly one function.
//
// Usage:
//
// <Toggleable isActiveByDefault>
//   {({ isActive, toggle }) => (
//     <Button onClick={toggle}>
//       <Icon icon={isActive ? faMinusCircle : faPlusCircle} /> Toggle
//     </Button>
//   )}
// </Toggleable>

import {Component} from 'react';
import PropTypes from 'prop-types';
import {setPreventDefault} from 'app/utilities';

class Toggleable extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {isActive: props.isActiveByDefault};
  }

  toggle(e) {
    setPreventDefault(e);
    this.setState({isActive: !this.state.isActive});
  }

  render() {
    return this.props.children({...this.state, toggle: this.toggle});
  }
}

Toggleable.propTypes = {
  children: PropTypes.func.isRequired,
  isActiveByDefault: PropTypes.bool,
};

export {Toggleable};
