import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import faMinusCircle from 'app/fontawesome-pro-light/faMinusCircle';
import faPlusCircle from 'app/fontawesome-pro-light/faPlusCircle';
import {IssueCardWithData, Toggleable} from 'app/components';

class IssueGroup extends Component {
  render() {
    const {cardProps, header, issues} = this.props;

    return (
      <Toggleable isActiveByDefault>
        {({isActive, toggle}) => (
          <Fragment>
            <h4 className="mb-8">
              <a href="#" onClick={toggle} className="text-body text-link-hover">
                <Icon icon={isActive ? faMinusCircle : faPlusCircle} className="d-print-none" /> {header}
              </a>
            </h4>
            {isActive && (
              <div className="issue-card-grid mb-8">
                {map(issues, issue => (
                  <IssueCardWithData key={issue.code} issue={issue} {...cardProps} />
                ))}
              </div>
            )}
          </Fragment>
        )}
      </Toggleable>
    );
  }
}

IssueGroup.propTypes = {
  cardProps: PropTypes.object,
  header: PropTypes.node.isRequired,
  issues: PropTypes.arrayOf(PropTypes.object),
};

export {IssueGroup};
