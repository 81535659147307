import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {uniqueId} from 'lodash';
import {withProps} from 'recompose';

import {IssueCard, IssueProgress, ToolTip} from 'app/components';

const IssueCardWithProgressComponent = ({children, isESGFocus, issue, periodData, tooltipId}) => (
  <IssueCard size="tall" id={issue.code} isESGFocus={isESGFocus}>
    <Fragment key="front">
      <div className="issue-card-header">
        <strong className="issue-card-header-code" id={tooltipId}>
          {issue.code}
        </strong>{' '}
        {issue.name}
        {isESGFocus && (
          <ToolTip target={tooltipId} placement="bottom">
            <span className="text-white">
              This issue is in your <br /> Plan ESG Focus.
            </span>
          </ToolTip>
        )}
      </div>
      <div className="issue-card-description flex-fill">
        <IssueProgress periodData={periodData} issue={issue} />
      </div>
    </Fragment>

    <Fragment key="back">
      <div className="issue-card-header">
        <strong>{issue.code}</strong> {issue.name}
      </div>
      <div className="issue-card-description flex-fill">
        <strong>{issue.description}</strong>
      </div>
      <div className="issue-card-footer" />
    </Fragment>

    {children}
  </IssueCard>
);

IssueCardWithProgressComponent.propTypes = {
  children: PropTypes.node,
  isESGFocus: PropTypes.bool,
  issue: PropTypes.object.isRequired,
  periodData: PropTypes.object,
};

const withTooltipId = withProps(() => ({tooltipId: 'tooltip_' + uniqueId()}));

export const IssueCardWithProgress = withTooltipId(IssueCardWithProgressComponent);
