import React from 'react';
import styled from 'styled-components/macro';
import {CheckmarkProps, CheckboxProps} from 'v2/utilities/types/components/Checkbox';

const CheckSVG = `
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L3.5 6.5L1 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

const CheckboxWrapper = styled.label<CheckmarkProps>`
  display: inline-flex;
  position: relative;
  top: -0.6rem;
  padding-left: 1.4rem;
  margin-bottom: 0;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input ~ span {
    box-shadow: 0 0 0 0.25rem ${({theme, active}) => (active ? theme.colors.harmony + '40' : 'transparent')};
    border: 0.0625rem solid
      ${({theme, active, disabled}) =>
        active ? theme.colors.harmony : disabled ? theme.colors.grey_2 : theme.colors.grey_3};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }

  // checked
  input:checked ~ span {
    background: ${({theme}) => theme.colors.harmony} !important;
    border: 0.0625rem solid ${({theme}) => theme.colors.harmony};
    height: 1.25rem;
    width: 1.25rem;
  }

  // checked with hover
  input:checked:hover ~ span {
    border-color: $ ${({theme, disabled}) => (!disabled ? theme.colors.harmony : theme.colors.grey_3)};
    background: ${({theme}) => theme.colors.harmony} !important;
  }

  // no checked hover
  input:hover ~ span {
    box-shadow: 0 0 0 0.25rem
      ${({theme, disabled}) => (!disabled ? theme.colors.harmony + '40 !important' : 'transparent !important')};
    border: 0.0625rem solid
      ${({theme, disabled}) => (!disabled ? theme.colors.harmony + '!important' : theme.colors.grey_2 + '!important')};
  }

  input:checked ~ span:after {
    display: block;
  }

  &&& {
    input ~ span {
      ${({hasError, theme}) =>
        hasError &&
        `
      border-color: ${theme.colors.red_2};
      box-shadow: 0px 0px 0px 4px rgba(250, 82, 82, 0.4);
    `}
    }
  }
`;

const Checkmark = styled.span<CheckmarkProps>`
  position: absolute;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  border: 0.0625rem solid ${({theme}) => theme.colors.grey_3};
  border-radius: 20%;

  &:after {
    filter: invert(1);
    display: none;
  }

  &:after {
    position: relative;
    top: -0.125rem;
    content: url('data:image/svg+xml, ${CheckSVG}');
  }
`;

const CustomCheckbox = styled.input.attrs(() => ({type: 'checkbox'}))`
  position: absolute;
  opacity: 0;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
`;

export const Checkbox = (props: CheckboxProps) => {
  return <CheckboxInput {...props} />;
};

export const CheckboxInput = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof CustomCheckbox>>(
  ({checked, onChange, active, disabled, id, hasError, ...rest}, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
    };

    return (
      <CheckboxWrapper checked={checked} active={active} disabled={disabled} hasError={hasError}>
        <CustomCheckbox id={id} ref={ref} checked={checked} disabled={disabled} onChange={handleChange} {...rest} />
        <Checkmark checked={checked} disabled={disabled} />
      </CheckboxWrapper>
    );
  },
);
