import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {compose} from 'recompose';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

// Local Imports
import {FORM_MESSAGE, ROLE, ROUTE} from 'app/constants';
import {Alert, OnboardingFinalizeForm, OnboardingUt3FinalizeForm, ProgressIndicator} from 'app/components';
import {finalizeOnboarding} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';
import {toastSuccess} from 'app/utilities/toast';

class OnboardingFinalizePage extends Component {
  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg">
        <ProgressIndicator
          steps={this.props.onboardingStepsArray}
          currentStep={this.props.requestedStep}
          nextAvailableStep={this.props.nextAvailableStep}
          className="pt-0 pb-12"
          style={{marginLeft: '-1.5rem'}}
        />
        <h1 className="mb-4">{ROUTE.ONBOARDING_FINALIZE.title}</h1>
        {this.props.hasUnexpectedError ? (
          <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
        ) : (
          <Fragment>
            {this.props.userRole === ROLE.USER_TYPE_3 ? (
              <OnboardingUt3FinalizeForm {...this.props} />
            ) : (
              <OnboardingFinalizeForm {...this.props} />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: () => {
      const {invitationCode} = ownProps;

      return dispatch(finalizeOnboarding(invitationCode))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess({
            text:
              'Congratulations, you have completed your account setup. Any invites that you created have ' +
              'now been sent. You can send additional invites by going to Settings, then to Manage Users.',
            timeout: 15000,
          });

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.DASHBOARD.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE});
        });
    },
    dispatch,
  };
};

export const OnboardingFinalize = compose(
  withOnboardingUser,
  connect(null, mapDispatchToProps),
)(OnboardingFinalizePage);
