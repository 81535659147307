import {each, head, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {animateScroll} from 'react-scroll';
import {compose} from 'recompose';
import {reduxForm} from 'redux-form';

// Local Imports
import {Button, FormFeedback, SimpleTable} from 'app/components';
import {MANAGER_TYPE, ROUTE} from 'app/constants';
import {withViewportSize} from 'app/utilities';

const PLAN_USER_TABLE_COLUMNS = {
  email: 'Primary Contact Email',
  role: 'Role',
};

const INVESTMENT_MANAGER_TABLE_COLUMNS = {
  firmName: 'Investment Manager Name',
  email: 'Primary Contact Email',
  strategy: 'Investment Strategy',
  type: 'Manager Type',
  portfolioManagementType: 'Approach',
};

const arrayHasData = array => {
  return array && array.length > 0 && !isEmpty(array[0]);
};

class OnboardingFinalizeFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const {
      invitationCode,
      onboardingInvitation: {planUsersToInvite, investmentManagersToInvite},
      viewportWidth,
    } = this.props;

    // split managers between invites and non-invites
    const investmentManagersBeingInvited = [];
    const investmentManagersNotBeingInvited = [];
    each(investmentManagersToInvite, manager => {
      const managerToAdd = {...manager, firmName: !isEmpty(manager.firm) ? head(manager.firm).name : ''};
      if (manager.email) {
        if (Object.values(MANAGER_TYPE).includes(manager.type)) {
          investmentManagersBeingInvited.push(managerToAdd);
        } else {
          investmentManagersNotBeingInvited.push(managerToAdd);
        }
      }
    });

    const hasInvitesToSend = arrayHasData(planUsersToInvite) || arrayHasData(investmentManagersBeingInvited);

    const isLayoutSmall = viewportWidth < 1200;

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div style={{maxWidth: viewportWidth >= 1400 ? 1200 : null}}>
          <div style={{maxWidth: viewportWidth >= 1150 ? 704 : null}}>
            {hasInvitesToSend ? (
              <p className="mb-12">
                You have now finished the setup process. Please review the individuals listed below and confirm that
                their contact information is correct. Once you click on "Confirm & Send Invites" we will automatically
                send the invitation to these individuals to join your plan on Harmony Analytics.
              </p>
            ) : (
              <p className="mb-12">
                You have opted not to invite any Users or Investment Managers at this time. You can invite individuals
                at a later time by accessing the User Manager in your Settings menu.
              </p>
            )}

            <Link
              to={ROUTE.ONBOARDING_PLAN_INFO.path(invitationCode)}
              className="btn btn-outline float-right"
              style={{marginTop: '-0.25rem'}}
              onClick={() => {
                animateScroll.scrollToTop();
              }}
            >
              Edit Plan Users
            </Link>
            <h3 className="mb-5">Plan Users</h3>
            {arrayHasData(planUsersToInvite) ? (
              <SimpleTable columns={PLAN_USER_TABLE_COLUMNS} dataset={planUsersToInvite} className="mb-12" />
            ) : (
              <p>You won't be inviting any plan users at this time.</p>
            )}
          </div>

          <Link
            to={ROUTE.ONBOARDING_INVESTMENT_MANAGERS.path(invitationCode)}
            className="btn btn-outline float-right"
            style={{marginTop: '-0.25rem'}}
            onClick={() => {
              animateScroll.scrollToTop();
            }}
          >
            Edit Managers
          </Link>
          <h3 className="mb-5">Investment Managers</h3>
          {arrayHasData(investmentManagersBeingInvited) ? (
            <SimpleTable
              columns={INVESTMENT_MANAGER_TABLE_COLUMNS}
              dataset={investmentManagersBeingInvited}
              className="mb-12"
              style={{fontSize: isLayoutSmall ? '1rem' : null}}
            />
          ) : (
            <p>You won't be inviting any investment managers at this time.</p>
          )}

          {arrayHasData(investmentManagersNotBeingInvited) && (
            <Fragment>
              <div style={{backgroundColor: 'white', padding: isLayoutSmall ? '1.5rem 2rem' : '2rem 2.5rem'}}>
                <h3 className="mb-5">Investment Managers not being invited</h3>
                <p>
                  As our system currently only supports Equity and Fixed Income Managers, the invitations for the
                  following managers will be held in queue and we will inform you when you are able to send their
                  invitations.
                </p>
                <SimpleTable
                  columns={INVESTMENT_MANAGER_TABLE_COLUMNS}
                  dataset={investmentManagersNotBeingInvited}
                  style={{fontSize: isLayoutSmall ? '1rem' : null}}
                />
              </div>
            </Fragment>
          )}

          <div className="form-footer is-right">
            <Button type="submit" solid>
              {hasInvitesToSend ? 'Confirm & Send Invites' : 'Continue Without Sending'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

OnboardingFinalizeFormComponent.propTypes = {
  invitationCode: PropTypes.string.isRequired,
  onboardingInvitation: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export const OnboardingFinalizeForm = compose(reduxForm({form: 'onboarding-finalize'}))(
  withViewportSize(OnboardingFinalizeFormComponent),
);
