import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import map from 'lodash/map';

import {COLOR} from 'app/constants';
import {getPercentChangeInPeriodData, formatNumber} from 'app/utilities';
import {IssueDataChangeDescription, IssueIconImage} from 'app/components';

const renderIssueIcon = (issue, percentChange) => {
  let fillColor = COLOR.NAVY;

  if (issue.isHigherWorse) {
    if (percentChange > 0) {
      fillColor = COLOR.RED;
    } else if (percentChange < 0) {
      fillColor = COLOR.GREEN;
    }
  } else {
    if (percentChange > 0) {
      // eslint-disable-line no-lonely-if
      fillColor = COLOR.GREEN;
    } else if (percentChange < 0) {
      fillColor = COLOR.RED;
    }
  }

  return (
    <div className="issue-progress-icon">
      <IssueIconImage path={issue.iconPath} backgroundFill={fillColor} />
    </div>
  );
};

const IssueProgress = ({className, issue, periodData, style, type}) => {
  const percentChange = getPercentChangeInPeriodData(periodData);

  return (
    <div className={cn('issue-progress', {[`is-${type}`]: type}, className)} style={style}>
      {renderIssueIcon(issue, percentChange)}
      <div>
        <IssueDataChangeDescription
          classes="d-block font-weight-bold issue-progress-change"
          issue={issue}
          percentChange={percentChange}
        />
        {type === 'print' && <div className="issue-progress-name">{issue.name}</div>}
        <ul className="issue-progress-data">
          {map(periodData, (data, period) => (
            <li className="issue-progress-data-point" key={period}>
              <span>{period}</span>
              {type === 'print' && <span className="mr-2">:</span>}
              <strong dangerouslySetInnerHTML={{__html: formatNumber(data) + issue.unit}} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

IssueProgress.defaultProps = {type: 'card'};

IssueProgress.propTypes = {
  className: PropTypes.string,
  issue: PropTypes.object,
  periodData: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.string,
};

export {IssueProgress};
