import {find, some} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Typeahead, Highlighter} from 'react-bootstrap-typeahead';
import {connect} from 'react-redux';
import {change, Field, reduxForm, formValueSelector} from 'redux-form';

// Local Imports
import {ROLE, REGEX_PATTERN, USER_PREFIXES} from 'app/constants';
import {errorRequiredFields, normalizeEmail} from 'app/utilities';
import {Button, FormFeedback} from '.';
import {FormGroup, Checkbox, SelectFormGroup, TextFormGroup} from './forms';

const USER_ROLES = [
  {
    value: ROLE.USER_TYPE_1,
    label: 'Decision Maker (User Type 1)',
  },
  {
    value: ROLE.USER_TYPE_2,
    label: 'Support (User Type 2)',
  },
  {
    value: ROLE.USER_TYPE_3,
    label: 'Firms (User Type 3)',
  },
  {
    value: ROLE.USER_TYPE_4,
    label: 'Company (User Type 4)',
  },
];

const validate = values => {
  const requiredFields = ['userType', 'email', 'confirmEmail'];
  if (values.userType === ROLE.USER_TYPE_1 || values.userType === ROLE.USER_TYPE_2) {
    requiredFields.push('internalPlanSponsorName', 'organizationType');
  }
  const errors = errorRequiredFields(values, requiredFields);

  if (!errors.email && !REGEX_PATTERN.VALID_EMAIL.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (values.confirmEmail !== values.email) {
    errors.confirmEmail = 'Must match Email Address';
  }

  return errors;
};

/**
 * Format the label of the send invites checkbox based on passed in initialValue flag
 * @param {object} initialValues - The object corresponding the the initialValues
 * @returns {string} The label of the checkbox
 */
function formatInviteLabel(initialValues) {
  const resend = initialValues && initialValues.hasInvitationBeenSent;
  return (resend ? 'Resend' : 'Send') + ' Invite on Save';
}

const renderTypeaheadPlanSponsor = (option, props) => (
  <div className="d-flex">
    <div className="flex-grow-1">
      <Highlighter search={props.text}>{option.internalName}</Highlighter>
    </div>
    <div>
      {option.userCount.toLocaleString()} user{option.userCount === 1 ? '' : 's'}
    </div>
  </div>
);

class AdminAddUserFormComponent extends Component {
  updateOrganizationType = (event, newValue, oldValue) => {
    const planSponsorForNewValue = find(this.props.planSponsors, {internalName: newValue});
    const planSponsorForOldValue = find(this.props.planSponsors, {internalName: oldValue});
    if (planSponsorForNewValue) {
      this.props.changeFieldValue('organizationType', planSponsorForNewValue.organizationTypeId);
    }
    if (!planSponsorForNewValue && planSponsorForOldValue) {
      this.props.changeFieldValue('organizationType', '');
    }
  };

  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <Field
            label="User Type*"
            name="userType"
            shouldInsertDefault={true}
            component={SelectFormGroup}
            options={USER_ROLES}
            disabled={!this.props.canWrite}
          />

          <Field
            label="Internal Plan Sponsor Name*"
            name="internalPlanSponsorName"
            onChange={this.updateOrganizationType}
            component={props => (
              <FormGroup {...props} typeahead labelKey="internalName">
                {(inputProps, wrapperProps) => (
                  <Typeahead
                    {...wrapperProps}
                    inputProps={inputProps}
                    options={this.props.planSponsors}
                    renderMenuItemChildren={renderTypeaheadPlanSponsor}
                    disabled={this.props.isPlanSponsorDisabled || !this.props.canWrite}
                  />
                )}
              </FormGroup>
            )}
            disabled={!this.props.canWrite}
          />

          <Field
            label="Organization Type*"
            name="organizationType"
            component={SelectFormGroup}
            optionValueProp="id"
            optionLabelProp="name"
            shouldInsertDefault={true}
            options={this.props.organizationTypes}
            disabled={this.props.isOrganizationTypeDisabled || this.props.isPlanSponsorDisabled || !this.props.canWrite}
          />

          <Field
            label="Prefix"
            name="prefix"
            component={SelectFormGroup}
            shouldInsertDefault={true}
            options={USER_PREFIXES}
            className="col-1"
            disabled={!this.props.canWrite}
          />

          <Field
            label="First Name"
            name="firstName"
            type="text"
            autoComplete="given-name"
            disabled={!this.props.canWrite}
            component={TextFormGroup}
            className="col-2"
          />

          <Field
            label="Last Name"
            name="lastName"
            type="text"
            autoComplete="family-name"
            disabled={!this.props.canWrite}
            component={TextFormGroup}
            className="col-2"
          />

          <Field
            label="Suffix"
            name="suffix"
            type="text"
            autoComplete="suffix"
            disabled={!this.props.canWrite}
            component={TextFormGroup}
            className="col-1"
            placeholder={'Ex: "CFA"'}
          />

          <Field
            label="Email Address*"
            name="email"
            type="text"
            autoComplete="email"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3"
            disabled={!this.props.canWrite}
          />

          <Field
            label="Confirm Email Address*"
            name="confirmEmail"
            type="text"
            autoComplete="email"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3"
            disabled={!this.props.canWrite}
          />

          <div className="form-footer is-right">
            <Field
              label={formatInviteLabel(this.props.initialValues)}
              name="shouldSendInvite"
              disabled={!this.props.canWrite}
              component={Checkbox}
            />
            {(!this.props.userData || !this.props.userData.editMode) && (
              <Button
                type="submit"
                color="gray"
                disabled={!this.props.canWrite}
                onClick={this.props.handleSubmit(values =>
                  this.props.onSubmit({
                    ...values,
                    shouldAddAnotherOnSave: true,
                  }),
                )}
              >
                Save &amp; Add Another
              </Button>
            )}
            <Button type="submit" disabled={!this.props.canWrite}>
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

AdminAddUserFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  planSponsors: PropTypes.array,
  organizationTypes: PropTypes.array,
  fieldValues: PropTypes.object,
  changeFieldValue: PropTypes.func,
};

AdminAddUserFormComponent.defaultProps = {
  planSponsors: [],
  organizationTypes: [],
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  const fieldValues = selector(state, 'internalPlanSponsorName', 'organizationType', 'userType');

  return {
    fieldValues,
    isOrganizationTypeDisabled: some(ownProps.planSponsors, {internalName: fieldValues.internalPlanSponsorName}),
    isPlanSponsorDisabled: fieldValues.userType === ROLE.USER_TYPE_3 || fieldValues.userType === ROLE.USER_TYPE_4,
    form: ownProps.formName,
    initialValues: ownProps.userData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFieldValue: (field, value) => {
    dispatch(change(ownProps.formName, field, value));
  },
  dispatch,
});

export const AdminAddUserForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({validate, enableReinitialize: true})(AdminAddUserFormComponent));
