/* eslint-disable no-param-reassign */
import moment from 'moment';
import {each, isEmpty, get, filter, find, includes, map, orderBy, slice} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import queryString from 'query-string';

// Local Imports
import {CONFIG, FORM_MESSAGE, RATINGS, ROLE, ROUTE} from 'app/constants';
import {ROUTE as UT1ROUTE} from 'v2/constants/routes';
import {Alert, Button, Collapsible, CompanyBookmarkToggle, IssueMatrix, TooltipLink} from 'app/components';
import {getEsgEngagementIssues, getIssueMatrix, getAllManagers} from 'app/redux/companyDetail';
import {getAllBySecurityId as getAllCompanyEngagementLetters} from 'app/redux/companyEngagementLetter';
import {fetchIssuesIfNeeded} from 'app/redux/issue';
import {getGlobalSettings} from 'app/redux/settings';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';
import faPrint from 'app/fontawesome-pro-light/faPrint';
import faAngleDown from 'app/fontawesome-pro-light/faAngleDown';
import faAngleUp from 'app/fontawesome-pro-light/faAngleUp';
import faPaperclip from 'app/fontawesome-pro-light/faPaperclip';
import {getByIdSecurity} from 'app/redux/security';
import {canBookmarkCompaniesForStrategy, getRecursiveIssueDisplayOrder, formatCurrency, withUser} from 'app/utilities';
import {env} from 'v2/utilities/helpers';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';

const MANAGER_LIST_BATCH_SIZE = 1000;

/**
 * Sums all of the marketvalue totals for the firms/strategy combinations
 * @param {Array} managers The list of ownership records
 * @returns {Number} The total of all marketvalues in the array
 */
const getOverallTotalValue = managers => {
  if (isEmpty(managers)) {
    return -1;
  }

  let sum = 0;
  each(managers, item => {
    sum += item.totalMarketValue * 1;
  });
  return sum;
};

/**
 * Converts the supplied marketvalue into a percentage using the total value
 * @param {Number} value The indiviual marketvalue
 * @param {Number} total The sum of all marketvalues for the Ownership planSelection
 * @returns {Number} Math.round-ed number
 */
const adjustForPercentage = (value, total) => {
  const adjusted = Math.round(100 * ((value * 1) / (total * 1)));

  return adjusted;
};

/**
 * Toggles between asc and desc
 * @param {string} currentDirection - The current direction being sorted on
 * @returns {string} The opposite direction
 */
const toggleSortDirection = currentDirection => (currentDirection === 'desc' ? 'asc' : 'desc');

/**
 * Determines the lodash orderBy's column array
 * @param {string} sortColumn - column identifier
 * @returns {Array} The list of columns (properties) to be sorted
 */
const setupOrderByColumns = sortColumn => {
  return sortColumn === 'totalMarketValue' ? ['totalMarketValue'] : ['firmName', 'strategyName'];
};

/**
 * Determines the lodash orderBy's column array
 * @param {string} sortColumn - column identifier
 * @param {string} sortDirection - column order identifier
 * @returns {Array} The direction the column(s) (properties) will be sorted on
 */
const setupOrderByDirection = (sortColumn, sortDirection) => {
  return sortColumn === 'totalMarketValue' ? [sortDirection] : [sortDirection, sortDirection];
};

class CompanyDetailPage extends Component {
  constructor(props) {
    super(props);

    this.sortTable = this.sortTable.bind(this);

    this.state = {
      sortColumn: 'totalMarketValue',
      sortDirection: 'desc',
      managerDisplayCount: MANAGER_LIST_BATCH_SIZE,
    };
  }

  componentDidMount() {
    const {isUserType3, securityId, strategyId} = this.props;

    this.props.dispatch(getGlobalSettings());
    this.props.dispatch(fetchIssuesIfNeeded());

    if (!isUserType3) {
      this.props.dispatch(getByIdSecurity(securityId));
      this.props.dispatch(getAllManagers(securityId));
    } else {
      this.props.dispatch(getByIdSecurity(securityId, strategyId));
    }

    this.props.dispatch(getIssueMatrix(isUserType3, securityId, strategyId));
    this.props.dispatch(getEsgEngagementIssues(securityId));
    this.props.dispatch(getAllCompanyEngagementLetters(securityId));
  }

  /**
   * The column header click handler.  Adjusts state per the clicked column identifier
   */
  sortTable = column => {
    this.setState(prevState => {
      return {
        sortColumn: column,
        sortDirection:
          prevState.sortColumn !== column ? prevState.sortDirection : toggleSortDirection(prevState.sortDirection),
      };
    });
  };

  /**
   * Generates the sort icon based on the column and direction
   * @param {string} currentColumn - The column identifier to be sorted
   * @returns {HTML}
   */
  generateSortIcon = currentColumn => {
    if (this.state.sortColumn !== currentColumn) {
      return '';
    }
    return <Icon icon={this.state.sortDirection === 'asc' ? faAngleUp : faAngleDown} className="ml-2" />;
  };

  sortManagerData = managers => {
    const {sortColumn, sortDirection} = this.state;

    return orderBy(managers, setupOrderByColumns(sortColumn), setupOrderByDirection(sortColumn, sortDirection));
  };

  showMoreManagers = () => {
    this.setState({managerDisplayCount: this.state.managerDisplayCount + MANAGER_LIST_BATCH_SIZE});
  };

  /**
   * Outputs the Engagement section if managers in state
   */
  renderEsgEngagementSection = () => {
    const {esgEngagementIssues, companyEngagementLetters, issues, globalSettings} = this.props;

    if (isEmpty(companyEngagementLetters) || isEmpty(issues)) {
      return <span />;
    }

    const engagementLetter = companyEngagementLetters[0];

    const issueWithDisplayOrder = orderBy(
      map(esgEngagementIssues, issue => ({
        ...issue,
        displayOrder: getRecursiveIssueDisplayOrder(issue.issueId, issues),
      })),
      ['displayOrder'],
      ['asc'],
    );

    return (
      <Fragment>
        <div className="p-content-lg background-divider">
          <div className="portfolio-page-section">
            <div className="h1 mb-5" style={{fontSize: '1.375rem'}}>
              Engagement
            </div>
            <div className="form-grid mb-5">
              <label style={{width: 125}} className="col-1 col-form-label">
                Letter Sent
              </label>
              <select className="custom-select col-1">
                {map(companyEngagementLetters, letter => (
                  <option key={letter.id}>{moment(letter.sentAt).format('MMM YYYY')}</option>
                ))}
              </select>
              <a
                className="col-1 col-form-label"
                href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/${engagementLetter.filePath}`)}
                target="_blank"
              >
                <Icon icon={faPaperclip} className="ml-2" />
              </a>
              <div className="col-3"></div>
            </div>
            <table className="table" style={{width: 'auto'}}>
              <thead>
                <tr>
                  <th scope="col" className="pr-12">
                    Issues
                  </th>

                  {globalSettings.isEngagementStep1Visible ? (
                    <th className="text-center text-nowrap pr-12" scope="col">
                      {globalSettings.engagementStep1Title || 'Company Engaged'}
                      {!isEmpty((globalSettings.engagementStep1Description || '').trim()) && (
                        <TooltipLink className="ml-2" id="step1Tooltip" label="">
                          {globalSettings.engagementStep1Description}
                        </TooltipLink>
                      )}
                    </th>
                  ) : null}

                  {globalSettings.isEngagementStep2Visible ? (
                    <th className="text-center text-nowrap pr-12" scope="col">
                      {globalSettings.engagementStep2Title || 'Action Plan Received'}
                      {!isEmpty((globalSettings.engagementStep2Description || '').trim()) && (
                        <TooltipLink className="ml-2" id="step2Tooltip" label="">
                          {globalSettings.engagementStep2Description}
                        </TooltipLink>
                      )}
                    </th>
                  ) : null}

                  {globalSettings.isEngagementStep3Visible ? (
                    <th className="text-center text-nowrap pr-12" scope="col">
                      {globalSettings.engagementStep3Title || 'Action Plan Check'}
                      {!isEmpty((globalSettings.engagementStep3Description || '').trim()) && (
                        <TooltipLink className="ml-2" id="step3Tooltip" label="">
                          {globalSettings.engagementStep3Description}
                        </TooltipLink>
                      )}
                    </th>
                  ) : null}

                  {globalSettings.isEngagementStep4Visible ? (
                    <th className="text-center text-nowrap pr-12" scope="col">
                      {globalSettings.engagementStep4Title || 'Completion Date Check'}
                      {!isEmpty((globalSettings.engagementStep4Description || '').trim()) && (
                        <TooltipLink className="ml-2" id="step4Tooltip" label="">
                          {globalSettings.engagementStep4Description}
                        </TooltipLink>
                      )}
                    </th>
                  ) : null}

                  {globalSettings.isEngagementStep5Visible ? (
                    <th className="text-center text-nowrap pr-12" scope="col">
                      {globalSettings.engagementStep5Title || 'Target Met'}
                      {!isEmpty((globalSettings.engagementStep5Description || '').trim()) && (
                        <TooltipLink className="ml-2" id="step5Tooltip" label="">
                          {globalSettings.engagementStep5Description}
                        </TooltipLink>
                      )}
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {map(issueWithDisplayOrder, item => {
                  return (
                    <tr key={item.issueId}>
                      <td scope="row" className="text-nowrap">
                        {item.issueName}
                      </td>
                      {globalSettings.isEngagementStep1Visible ? (
                        <td className="text-center pr-12">
                          {item.step1CompletedAt && moment(item.step1CompletedAt).format('MM/DD/YYYY')}
                        </td>
                      ) : null}
                      {globalSettings.isEngagementStep2Visible ? (
                        <td className="text-center pr-12">
                          {item.step2CompletedAt && moment(item.step2CompletedAt).format('MM/DD/YYYY')}
                        </td>
                      ) : null}
                      {globalSettings.isEngagementStep3Visible ? (
                        <td className="text-center pr-12">
                          {item.step3CompletedAt && moment(item.step3CompletedAt).format('MM/DD/YYYY')}
                        </td>
                      ) : null}
                      {globalSettings.isEngagementStep4Visible ? (
                        <td className="text-center pr-12">
                          {item.step4CompletedAt && moment(item.step4CompletedAt).format('MM/DD/YYYY')}
                        </td>
                      ) : null}
                      {globalSettings.isEngagementStep5Visible ? (
                        <td className="text-center pr-12">
                          {item.step5CompletedAt && moment(item.step5CompletedAt).format('MM/DD/YYYY')}
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  };

  /**
   * Outputs the Ownership section if managers in state
   */
  renderOwnershipSection = () => {
    const {managers, overallManagerTotal, companyEngagementLetters} = this.props;
    const {managerDisplayCount} = this.state;

    if (isEmpty(managers)) {
      return <span />;
    }

    return (
      <Fragment>
        <div className={`p-content-lg ${isEmpty(companyEngagementLetters) ? 'background-divider' : ''}`}>
          <div className="portfolio-page-section">
            <div className="h1 mb-5" style={{fontSize: '1.375rem'}}>
              Ownership
            </div>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="cursor-pointer" scope="col" onClick={() => this.sortTable('name')}>
                    Manager
                    {this.generateSortIcon('name')}
                  </th>
                  <th
                    className="cursor-pointer text-right"
                    scope="col"
                    onClick={() => this.sortTable('totalMarketValue')}
                  >
                    Held
                    {this.generateSortIcon('totalMarketValue')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {map(this.sortManagerData(slice(managers, 0, this.state.managerDisplayCount)), item => {
                  return (
                    <tr key={item.strategyId}>
                      <td scope="row">
                        <Link to={ROUTE.MANAGER_DETAIL.path(item.strategyId)}>
                          {get(item, 'firmName')}: {get(item, 'strategyName')}
                        </Link>
                      </td>
                      <td className="text-right">
                        <Link to={ROUTE.MANAGER_DETAIL.path(item.strategyId)}>
                          {formatCurrency(item.totalMarketValue)}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td scope="row">&nbsp;</td>
                  <td className="text-right">
                    <span style={{marginRight: 50}}>
                      <b>Total</b>
                    </span>
                    {formatCurrency(overallManagerTotal)}
                  </td>
                </tr>
              </tfoot>
            </table>
            {managerDisplayCount < managers.length && (
              <div className="ownership-table-controls d-flex justify-content-end d-print-none">
                <Button onClick={this.showMoreManagers}>Load More</Button>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const {details, isBookmarkToggleVisible, issuesForMatrix, isUserType3, hasUnexpectedError, strategyId} = this.props;

    if (hasUnexpectedError) {
      return (
        <div className="p-content-lg">
          <div className="card is-white mx-auto" style={{maxWidth: 800}}>
            <h1>An Error has Occurred</h1>
            {FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}
          </div>
        </div>
      );
    }
    if (isEmpty(details)) {
      return <span />;
    }

    const ratingLetter = RATINGS[get(details, 'company.rating')] || 'UR';
    const ratingLabel = {
      A: 'Harmony Platinum',
      B: 'Harmony Gold',
      C: 'Harmony Silver',
      D: 'Harmony Bronze',
      E: 'Substandard',
    };

    const isIssueDataValid = !isEmpty(issuesForMatrix);

    // Build the back link
    let backLink;
    const params = queryString.parse(this.props.location.search);
    const referringPage = params.ref;
    const referringStrategyId = params.strategyId;

    const defaultLink = env.isProduction() ? ROUTE.COMPANIES.path() : UT1ROUTE.UT1.COMPANIES.INDEX.path;

    const ut3Link = !env.isProduction()
      ? UT1ROUTE.UT1.STRATEGIES.HOLDINGS.path.replace(':strategyId', strategyId)
      : ROUTE.COMPANIES.path();

    switch (referringPage) {
      case 'dashboard':
        backLink = {
          label: 'Return to Dashboard',
          to: ROUTE.DASHBOARD.path(),
        };
        break;
      case 'holdings':
        backLink = {
          label: 'Return to Holdings',
          to: ROUTE.PLAN.path(),
        };
        break;
      case 'managerDetail':
        backLink = {
          label: 'Return to Manager',
          to: ROUTE.MANAGER_DETAIL.path(referringStrategyId),
        };
        break;
      case 'strategyDashboard':
        backLink = {
          label: 'Return to Dashboard',
          to: ROUTE.STRATEGY_DASHBOARD.path(strategyId),
        };
        break;
      default:
        backLink = {
          label: 'Return to List',
          to: {
            pathname: isUserType3
              ? UT1ROUTE.UT1.STRATEGIES.HOLDINGS.path.replace(':strategyId', strategyId)
              : UT1ROUTE.UT1.COMPANIES.INDEX.path,
            search: this.props.location.search,
          },
        };
    }

    return (
      <Fragment>
        <div className="p-content-lg">
          <div className="portfolio-page-section">
            <div className="d-flex">
              <div className="w-50 pr-10">
                <Link to={backLink.to} className="d-inline-block d-print-none mb-6 small">
                  <Icon icon={faArrowLeft} className="mr-2" />
                  {backLink.label}
                </Link>
                <div className="mb-12">
                  <h1 className="mb-0">{details.name}</h1>
                  {isBookmarkToggleVisible && (
                    <CompanyBookmarkToggle className="d-inline-block small" security={details} />
                  )}
                </div>
                {hasUnexpectedError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}
                <Collapsible collapsedContainerHeight={144}>
                  {({container, content, isOpen, isUseless, toggle}) => (
                    <div className="mb-12">
                      <div className="print-h-auto transition" {...container}>
                        <div {...content}>{details.description}</div>
                      </div>
                      {isUseless || (
                        <div className="d-print-none">
                          (
                          <a href="#" title="Read more of the Company Description" onClick={toggle}>
                            {isOpen ? 'Read Less' : 'Read More'}
                          </a>
                          ...)
                        </div>
                      )}
                    </div>
                  )}
                </Collapsible>

                <div className="my-1">
                  <strong>ISIN:</strong> {details.isin}
                </div>
                <div className="my-1">
                  <strong>Ticker:</strong> {details.ticker}
                </div>
                <div className="my-1">
                  <strong>Country:</strong> {get(details, 'company.country.name')}
                </div>
                <div className="my-1">
                  <strong>Sector:</strong> {get(details, 'sector.name')}
                </div>
                <div className="my-1">
                  <strong>Industry:</strong> {get(details, 'industry.name')}
                </div>
                <Button className="mt-12 d-print-none" onClick={window.print} iconAfter={faPrint}>
                  Print Company Details
                </Button>
              </div>
              <div className="company-rating-panel w-50">
                <div className="company-rating-panel-header">
                  <div className="col">
                    <span className={cn('rating-circle', `is-${ratingLetter}`)}>{ratingLetter}</span>
                  </div>
                  <div className="col">
                    <span className="company-rating-heading">Company Rating</span>
                    <span className="company-rating-name">{ratingLabel[ratingLetter]}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderEsgEngagementSection()}
        {this.renderOwnershipSection()}
        {isIssueDataValid && (
          <div className="p-content-lg">
            <div className="portfolio-page-section">
              <IssueMatrix issues={issuesForMatrix} title="Issue Rating for Company" />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

CompanyDetailPage.defaultProps = {
  details: {},
  managers: [],
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: {securityId, strategyId},
    },
  } = ownProps;
  const hasUnexpectedError = get(state, 'security.hasError') || get(state, 'companyDetail.error');

  let overallManagerTotal = 0;

  let managers = null;
  const isUserType3 = includes(ownProps.authUser.roles, ROLE.USER_TYPE_3);
  if (!isUserType3) {
    // update manager data
    managers = get(state, 'companyDetail.allFirmsWithStrategy');
    managers = filter(managers, 'totalMarketValue'); // Remove any holdings uploaded with nil value
    overallManagerTotal = getOverallTotalValue(managers);
    if (overallManagerTotal > -1) {
      managers = map(managers, item => {
        item.totalMarketValue *= 1;
        item.percentage = adjustForPercentage(item.totalMarketValue, overallManagerTotal);
        return item;
      });
    }
  }

  let isBookmarkToggleVisible = false;
  if (isUserType3) {
    const strategy = find(state.strategy.planSelection, ({id}) => {
      return parseInt(id, 10) === parseInt(strategyId, 10);
    });
    isBookmarkToggleVisible = canBookmarkCompaniesForStrategy(strategy);
  }

  return {
    securityId,
    strategyId,
    isUserType3,
    hasUnexpectedError,
    details: !get(state, 'security.isFetching') && get(state, 'security.securityDetail'),
    issues: !get(state, 'issue.isFetching') && get(state, 'issue.issues'),
    issuesForMatrix: !get(state, 'companyDetail.isFetching') && get(state, 'companyDetail.issues'),
    esgEngagementIssues: !get(state, 'companyDetail.isFetching') && get(state, 'companyDetail.esgEngagementIssues'),
    companyEngagementLetters:
      !get(state, 'companyEngagementLetter.isFetching') &&
      get(state, 'companyEngagementLetter.companyEngagementLetters'),
    globalSettings: !get(state, 'settings.isGlobalSettingsFetching') && get(state, 'settings.global', {}),
    managers,
    overallManagerTotal,
    isBookmarkToggleVisible,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const CompanyDetail = compose(withUser, connect(mapStateToProps, null, mergeProps))(CompanyDetailPage);
