import {filter, get} from 'lodash';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';

// Local Imports
import {Alert, LogoGlyph, ParticleBackground} from 'app/components';
import {FORM_MESSAGE, ROLE, ROUTE} from 'app/constants';
import {ROUTE as V2_ROUTE} from 'v2/constants/routes';
import {withOnboardingUser} from 'app/utilities';

class OnboardingLandingPage extends Component {
  render() {
    const {
      hasInvitationCodeError,
      hasOnboardingStarted,
      hasUnexpectedError,
      isFetching,
      isOnboardingComplete,
      invitationCode,
      nextAvailableStep,
      onboardingSteps,
      userRole,
    } = this.props;

    const onboardingPath = () =>
      userRole === ROLE.USER_TYPE_3
        ? V2_ROUTE.UT3.ONBOARDING.ACCOUNT.path.replace(':invitationCode', invitationCode)
        : ROUTE.ONBOARDING_CREATE_ACCOUNT.path(invitationCode);

    const nextStep = userRole === ROLE.USER_TYPE_3 ? `/v2${nextAvailableStep?.path}` : nextAvailableStep?.path;

    // Ensure OnboardingLandingForm doesn't flash and doesn't display an invalid error while fetching
    if (isFetching) return null;

    const shouldWelcomeUserBack = isOnboardingComplete || hasOnboardingStarted;

    const isOnboardingAccountComplete = get(this.props, 'onboardingUser.isOnboardingAccountComplete');

    return (
      <div className="landing-layout">
        <ParticleBackground />
        <div className="landing-layout-wrapper">
          <div className="mx-auto">
            <LogoGlyph color="white" />
          </div>
          <div className="landing-layout-inner">
            <h1>{shouldWelcomeUserBack ? 'Welcome Back' : ROUTE.ONBOARDING_LANDING.title}</h1>
            {(() => {
              let message = <div />;
              if (hasUnexpectedError) {
                message = <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>;
              } else if (hasInvitationCodeError) {
                message = (
                  <Alert color="danger">
                    There was an issue retrieving your invitation from our system. Please confirm your invitation URL is
                    correct and try again. If you are still experiencing issues, please{' '}
                    <Link to={ROUTE.CONTACT.path()}>contact us</Link> for further assistance.
                  </Alert>
                );
              } else if (isOnboardingComplete) {
                message = (
                  <div>
                    <p>
                      Congratulations! You've completed your account creation! You are now ready to log in and to start
                      using Harmony Analytics.
                    </p>

                    <div className="landing-btn-wrapper">
                      <Link className="btn btn-outline btn-lg btn-white" to={ROUTE.LOGIN.path(invitationCode)}>
                        Log in
                      </Link>
                    </div>
                  </div>
                );
              } else if (hasOnboardingStarted) {
                if (
                  userRole === ROLE.USER_TYPE_3 &&
                  isOnboardingAccountComplete &&
                  filter(onboardingSteps, {isComplete: true}).length === 1
                ) {
                  message = (
                    <div>
                      <p>
                        You are about to begin the account setup for your firm and the plan you were invited to join.
                        You may want to have information at your disposal, such as the email addresses of any colleagues
                        you would like to invite, as well as the necessary investment portfolio(s) for your client. You
                        will be able to update the information you provide at a later time.
                      </p>
                      <p>
                        We have pre-filled the first few steps of the setup process based on the your information
                        currently in the Harmony Analytics site. Please review each step to ensure all your information
                        is still valid.
                      </p>
                      <div className="landing-btn-wrapper">
                        <Link className="btn btn-outline btn-lg btn-white" to={nextStep}>
                          Continue
                        </Link>
                      </div>
                    </div>
                  );
                } else {
                  message = (
                    <div>
                      <p>
                        Your account creation was temporarily interrupted. Your progress has been saved, and you can now
                        pick up where you left.
                      </p>
                      <div className="landing-btn-wrapper">
                        <Link className="btn btn-outline btn-lg btn-white" to={nextStep}>
                          Continue
                        </Link>
                      </div>
                    </div>
                  );
                }
              } else if (userRole === ROLE.USER_TYPE_3) {
                message = (
                  <div>
                    <p>Congratulations on starting the journey to more sustainable investing.</p>

                    <p>
                      You are about to begin the account setup for your firm and the plan you were invited to join. You
                      may want to have information at your disposal, such as the email addresses of any colleagues you
                      would like to invite, as well as the necessary investment portfolio(s) for your client. You will
                      be able to update the information you provide at a later time.
                    </p>

                    <div className="landing-btn-wrapper">
                      <Link className="btn btn-outline btn-lg btn-white" to={onboardingPath()}>
                        Get Started
                      </Link>
                    </div>
                  </div>
                );
              } else {
                message = (
                  <div>
                    <p>Congratulations on starting the journey to more sustainable investing.</p>

                    <p>
                      You are about to begin the account setup for your plan, and so you may want to have information at
                      your disposal, such as the email addresses of any employees you would like to invite, as well as
                      the email addresses for your investment managers. You will be able to update the information you
                      provide at a later time.
                    </p>

                    <div className="landing-btn-wrapper">
                      <Link className="btn btn-outline btn-lg btn-white" to={onboardingPath()}>
                        Get Started
                      </Link>
                    </div>
                  </div>
                );
              }
              return message;
            })()}
          </div>
        </div>
      </div>
    );
  }
}

export const OnboardingLanding = compose(withOnboardingUser)(OnboardingLandingPage);
