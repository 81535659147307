import {find, get, debounce, map} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';

import {COLOR, FORM_MESSAGE, ROUTE, STRATEGY_TREND, MANAGER_LISTING_FILTER} from 'app/constants';
import {Alert, Button, BootstrapTableRemote, ToolTip, TooltipLink} from 'app/components';
import {getAllManagersPaged} from 'app/redux/firm';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import {ratingFormatter} from 'app/utilities/ratingFormatter';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {STRATEGY_TRENDS} from '../constants/strategyTrend';

function managerRatingFormatter(cell, row) {
  if (!cell || cell.length === 0) {
    return <span></span>;
  }

  const rating = ratingFormatter(cell);

  const tooltipId = 'rating_' + row.id;
  return (
    <div>
      <span id={tooltipId} style={{display: 'inline-block'}}>
        {rating}
      </span>
      <ToolTip target={tooltipId}>
        <span className="text-white">{cell === 'UR' ? 'Unrated' : `Weighted Average Rating: "${cell}"`}</span>
      </ToolTip>
    </div>
  );
}

/**
 * Format the content of the Trend cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
function trendFormatter(cell, row) {
  if (!cell || cell.length === 0 || row.weightedAverageRating === 'UR') {
    return <span></span>;
  }

  const trend = find(STRATEGY_TRENDS, {key: row.trending}) || STRATEGY_TREND.SAME;

  const displayText = <Icon className={`is-${trend.key}`} icon={trend.icon} />;
  // setup tooltip text
  const tooltipDisplay = (
    <React.Fragment>
      The Weighted Average Rating for this manager's most recent upload has <b>{trend.tooltipText}</b> when compared to
      the previous upload.
    </React.Fragment>
  );

  const tooltipId = 'delta_' + row.id;
  return (
    <div className="trend-arrow">
      <span id={tooltipId}>{displayText}</span>
      <ToolTip target={tooltipId}>
        <span className="text-white">{tooltipDisplay}</span>
      </ToolTip>
    </div>
  );
}

const columns = [
  {
    dataField: 'firm.name',
    text: 'Manager',
    sort: true,
    style: {color: COLOR.BLUE, width: 340},
    headerStyle: {width: 340},
  },
  {
    dataField: 'name',
    text: 'Product',
    sort: true,
    style: {width: 280},
    headerStyle: {width: 280},
  },
  {
    dataField: 'marketIndex.name',
    text: 'Index',
    sort: true,
    style: {width: '200px'},
    headerStyle: {width: '200px'},
  },
  {
    dataField: 'portfolioManagementType',
    text: 'Strategy',
    sort: true,
    style: {textAlign: 'center', width: '140px'},
    headerStyle: {textAlign: 'center', width: '140px'},
  },
  {
    dataField: 'policyViolation',
    text: 'Compliance',
    sort: true,
    style: {textAlign: 'center', width: '140px'},
    headerStyle: {textAlign: 'center', width: '140px'},
  },
  // {
  //   dataField: 'weightedAverageRating',
  //   text: 'Rating',
  //   sort: true,
  //   formatter: managerRatingFormatter,
  //   style: {
  //     textAlign: 'center',
  //   },
  //   headerStyle: { textAlign: 'center', width: 125 },
  // },
  {
    dataField: 'id',
    sort: true,
    text: 'Trend',
    formatter: trendFormatter,
    style: {textAlign: 'center'},
    headerStyle: {textAlign: 'center', width: 85, whiteSpace: 'nowrap'},
  },
];

class ManagersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.search,
      filter: props.filter,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchDebounced = debounce(this.handleSearch, 500);
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.props.search,
      this.props.filter,
    );

    this.searchInput.focus();
    animateScroll.scrollToTop();
  }

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleSearch = () => {
    this.updateCurrentQueryString();
  };

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    search = this.state.search,
    filter = this.state.filter,
  ) {
    this.props.dispatch(push({search: setupQueryForLink(sortField, sortOrder, null, null, search, filter)}));
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    this.handleSearchDebounced();
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      // data,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
      // filters,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, search, filter} = this.props;

    this.props.dispatch(
      push({
        pathname: ROUTE.MANAGER_DETAIL.path(row.id),
        search: setupQueryForLink(sortField, sortOrder, search, {filter}),
      }),
    );
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search, filter) => {
    this.props.dispatch(
      getAllManagersPaged({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        filter,
      }),
    );
  };

  render() {
    const {data, hasError, sortField, sortOrder, page, pageSize, totalSize} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg">
        <div>
          <h1>{ROUTE.MANAGERS.title}</h1>

          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          <form method="GET" onSubmit={this.handleSearch}>
            <div className="bootstrap-table-controls">
              <div className="bootstrap-table-search" style={{width: '65%'}}>
                <div className="form-control-and-icon">
                  <input
                    className="form-control"
                    ref={input => {
                      this.searchInput = input;
                    }}
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search by Managers, Products"
                    value={this.state.search}
                    onChange={this.changeField}
                  />
                  <div className="icon">
                    <Icon icon={faSearch} />
                  </div>
                  <TooltipLink className="float-right mt-1" label="Search Techniques" id="adminSecurityListingSearch">
                    Surround your search with double quotes to do an exact search. Additionally when using double
                    quotes, you can do wildcard searches. Simply use a percent sign ( % ) to match any character zero or
                    more times and an underscore ( _ ) to match any character exactly one time. For example, "H_rmony%"
                    will find a result for <i>Harmony Analytics</i> as would "%Analytic_".
                  </TooltipLink>
                </div>
                <div>
                  <Button color="gray" type="submit">
                    Search
                  </Button>
                </div>
              </div>
              <div className="bootstrap-table-filter" style={{width: '35%'}}>
                <label className="col-form-label">Filter By</label>
                <select
                  id="filter"
                  name="filter"
                  value={this.state.filter}
                  onChange={this.changeField}
                  className="custom-select form-control"
                >
                  {map(MANAGER_LISTING_FILTER, (label, value) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>

          <BootstrapTableRemote
            columns={columns}
            data={data}
            page={page}
            sizePerPage={pageSize}
            totalSize={totalSize}
            onTableChange={this.handleTableChange}
            defaultSorted={defaultSorted}
            selectRow={{
              mode: 'radio',
              hideSelectColumn: true,
              clickToSelect: true,
              onSelect: this.handleOnSelect,
            }}
            disablePagination={true}
          />
        </div>
      </div>
    );
  }
}

ManagersPage.defaultProps = {
  data: [],
  totalSize: 0,
};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(ownProps, {
    search: '',
    page: 1,
    pageSize: 500,
    sortField: 'name',
    sortOrder: 'asc',
  });

  // override paging info from queryString
  newProps.page = 1;
  newProps.pagesize = 500;

  newProps.data = get(state, 'firm.allFirmsWithStrategy');
  newProps.isFetching = get(state, 'firm.isFetching');
  // if (newProps.data && newProps.data.length === 1) {
  //   // add 1 as fix for pagination
  //   newProps.data.push({ id: '-1' });
  // }
  newProps.totalSize = get(state, 'firm.allFirmsWithStrategyTotal');
  newProps.hasError = get(state, 'firm.hasError');
  return newProps;
};

export const Managers = connect(mapStateToProps)(ManagersPage);
