import React, {useMemo, useState} from 'react';
import styled from 'styled-components/macro';
import {Link} from 'react-router-dom';
import {Title5} from 'v2/components/atoms/Typeface';
import {PaddinglessModal} from './Modal';
import {PaginatedList} from 'v2/components/organisms/PaginatedList';
import {FlexContainer} from 'v2/components/atoms/Containers';
import {ROUTE} from 'v2/constants/routes';
import {useDispatch} from 'react-redux';
import {updateManagerSearch} from 'v2/redux/searchFilters';

const Container = styled.div`
  align-text: center;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.875rem;
`;

const Title = styled(Title5)`
  margin: 0;
  text-transform: uppercase;
`;

const ListContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
`;

const ToggleButton = styled('div')<{isSelected?: boolean}>`
  width: 80px;
  height: 44px;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.isSelected ? props.theme.colors.harmony : props.theme.colors.grey_2)};
  color: ${props => (props.isSelected ? props.theme.colors.white : props.theme.colors.dark)};
  cursor: pointer;
`;

const LinkContainer = styled(Link)`
  color: ${props => props.theme.colors.dark};
  text-decoration: none !important;

  &:hover {
    color: ${props => props.theme.colors.dark} !important;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const ManagerModal: React.FC<{
  title: string;
  data: any;
  isOpen: boolean;
  handleClose: Function;
}> = ({title, data, isOpen, handleClose}) => {
  const [isYesSelected, setIsYesSelected] = useState(true);
  const dispatch = useDispatch();

  let listData = useMemo(
    () =>
      data?.[isYesSelected ? 'yes' : 'no'].map((m: any) => (
        <LinkContainer to={ROUTE.UT1.STRATEGIES.INDEX.path} onClick={() => dispatch(updateManagerSearch(m.name))}>
          {m.name}
        </LinkContainer>
      )),
    [data, isYesSelected],
  );
  const handleToggleYesSelected = () => setIsYesSelected(!isYesSelected);

  return (
    <PaddinglessModal
      //@ts-ignore
      style={{paddingTop: 0}}
      isOpen={isOpen}
      setClose={() => handleClose(false)}
      width={'1015px'}
    >
      <Container id="12">
        <Title>{title}</Title>
        <FlexContainer gap="0.75rem" margin="0.875rem 0">
          <ToggleButton isSelected={isYesSelected} onClick={handleToggleYesSelected}>
            Yes
          </ToggleButton>
          <ToggleButton isSelected={!isYesSelected} onClick={handleToggleYesSelected}>
            No
          </ToggleButton>
        </FlexContainer>

        <ListContainer>
          <PaginatedList
            data={listData}
            title="Manager Name"
            totalCount={listData.length}
            pageSize={10}
            siblingCount={2}
            bottomLink={<Link to={ROUTE.UT1.STRATEGIES.INDEX.path}>Go to Managers</Link>}
          />
        </ListContainer>
      </Container>
    </PaddinglessModal>
  );
};
