import {get, isEmpty, map, take} from 'lodash';
import numeral from 'numeral';
import React, {Fragment} from 'react';

import {ROUTE} from './route';

export const FORM_MESSAGE = {
  DEFAULT_ERROR_SUMMARY_MESSAGE: 'Please correct the issues below.',
  DEFAULT_API_ERROR_MESSAGE: 'There was an unexpected error. Please try again or contact us for further assistance.',
  NEW_PASSIVE_STRATEGIES: canApplyHarmonyIndex => {
    return canApplyHarmonyIndex ? (
      <div>
        You have selected that a strategy is passive. You now have the ability to assign that strategy to use a Harmony
        Index. You can learn more about the Harmony Index and assign your passive strategies to it in the Index Center,
        accessible in your settings menu.
      </div>
    ) : (
      <div>
        You have selected that a strategy is passive. Your organization now has the ability to assign that strategy to
        use a Harmony Index. You can learn more about the Harmony Index and your organization admin can assign the
        passive strategies to it in the Index Center, accessible in the settings menu.
      </div>
    );
  },
  UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT: (
    <div>
      There was an unexpected error. Please try submitting again or <a href={ROUTE.CONTACT.path()}>contact us</a> for
      further assistance.
    </div>
  ),
  UNEXPECTED_ERROR_MESSAGE: (
    <div>
      There was an unexpected error. Please try refreshing or <a href={ROUTE.CONTACT.path()}>contact us</a> for further
      assistance.
    </div>
  ),
  EMAIL_EXISTS_ERROR_MESSAGE: (
    <div>
      That email address already exists. Check the <a href={ROUTE.ADMIN_USERS.path()}>User Manager</a> to invite or edit
      the user.
    </div>
  ),
  RESET_PASSWORD_UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT: (
    <div>
      There was an unexpected error. Please try submitting the form again, check that you are using the most recently
      sent Forgot Password Email, or <a href={ROUTE.CONTACT.path()}>contact us</a> for further assistance.
    </div>
  ),
  INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT: (
    <div>
      <div>
        There was an unexpected issue with your invitation. Please try submitting again or
        <span>
          {' '}
          <a href={ROUTE.CONTACT.path()}>contact us</a>{' '}
        </span>
        for further assistance.
      </div>
    </div>
  ),
  INVALID_CREDENTIALS: 'Your email and/or password was incorrect.',
  PASSWORD_MISMATCH: 'Your password fields do not match.',
  ONBOARDING_UNEXPECTED_ERROR: (
    <div>
      There was an unexpected issue with your invitation. Please confirm your invitation url is correct and try
      refreshing. If you are still experiencing issues, please <a href={ROUTE.CONTACT.path()}>contact us</a> for further
      assistance.
    </div>
  ),
  STRATEGY_UPLOAD_ERROR: (
    <div>
      An error occurred when trying to process your plan holdings. Please try uploading again or{' '}
      <a href={ROUTE.CONTACT.path()}>contact us</a> for further assistance.
    </div>
  ),
  ONBOARDING_STRATEGY_UPLOAD_PROCESSING: (
    <div>
      You have uploaded a large file which may take up to five minutes to process. Feel free to continue the onboarding
      process as the file is imported in the background.
    </div>
  ),
  UPLOAD_PORTFOLIO_PROCESSING: (
    <div>
      <p>
        The upload will continue to process in the background. You can save the form, and move through the rest of the
        website without disrupting the upload. Once processing is complete, the data on your Strategy Dashboard will be
        updated, and you will be able to see the processed filed listed on the Strategy's "Upload Portfolio".
      </p>

      <p>
        If your Strategy Dashboard does not reflect the data you have uploaded within the next 24 hours, or if you have
        any questions please <a href={ROUTE.CONTACT.path()}>contact us</a> for further assistance.
      </p>
    </div>
  ),
  UPDATE_PORTFOLIO_COMPLETE: <div>The upload has completed successfully.</div>,
  UPDATE_PORTFOLIO_COMPLETE_WITH_SKIPPED_RECORDS: invalidRecords => {
    const maxInvalidRecordsToDisplay = 25;
    return (
      <div>
        <p>
          The upload has completed successfully, however, {invalidRecords.length} item(s) were skipped. Please review
          your upload file and ensure that the data is formatted as specified by the CSV Template provided. If you are
          still having issues please <a href={ROUTE.CONTACT.path()}>contact us</a> for further assistance.
        </p>
        <p>
          For your convenience, we have listed the top {Math.min(maxInvalidRecordsToDisplay, invalidRecords.length)}{' '}
          item(s) that were skipped:
        </p>
        <ul className="mt-5">
          {map(take(invalidRecords, maxInvalidRecordsToDisplay), (invalidRecord, index) => {
            const {errors, record} = invalidRecord;
            if (errors[0].indexOf('Security Not Found') >= 0) {
              return (
                <li key={index}>
                  {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for the following:{' '}
                  <strong>{record.securityIsin ? record.securityIsin : 'N/A'}</strong>{' '}
                </li>
              );
            }
            return (
              <li key={index}>
                {errors} for Security: <strong>{record.securityName ? record.securityName : 'N/A'}</strong> ID:{' '}
                <strong>{record.identifier ? record.identifier : 'N/A'}</strong>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
  COMPLIANCE_VIOLATIONS: violations => (
    <div>
      <p>The following Investment Policies were violated:</p>
      <ul className="mt-5">
        {map(violations, (violation, index) => (
          <li key={index}>{violation}</li>
        ))}
      </ul>
    </div>
  ),
  UPLOAD_ERROR: error => (
    <div>
      <div>
        The following error has occurred when trying to validate your upload:
        <ul>
          <li>"{error}"</li>
        </ul>
      </div>
      <p>
        Please review your upload file and ensure that the data is formatted as specified by the CSV Template provided.
      </p>
    </div>
  ),
  UPLOAD_UNEXPECTED_ERROR: (
    <div>
      An error occurred when trying to validate your upload. Please try uploading again or contact support for further
      assistance.
    </div>
  ),
  UPLOAD_PROCESSING: (
    <div>
      <p>
        The upload will continue to process in the background. You can save the form, and move through the rest of the
        website without disrupting the upload.
      </p>
    </div>
  ),
  UPLOAD_HARMONY_DATA_COMPLETE: statistics => {
    const {insertedRecordsCount, recordsNotFoundCount} = statistics;
    const formattedInsertedSecuritiesCount = numeral(insertedRecordsCount).format(0, 0);
    const missedSecurities =
      recordsNotFoundCount &&
      ` This will still leave ${numeral(recordsNotFoundCount).format(0, 0)} securities without data. `;
    return (
      <div>
        <p>
          The upload completed and the system was able to validate {formattedInsertedSecuritiesCount} securities, all of
          which will be inserted upon save. {missedSecurities} securities were not able to be validated and will not be
          inserted upon save.
        </p>
      </div>
    );
  },
  UPLOAD_HARMONY_DATA_COMPLETE_WITH_SKIPPED_RECORDS: (invalidRecords, statistics) => {
    const maxInvalidRecordsToDisplay = 25;
    const {insertedRecordsCount, recordsNotFoundCount} = statistics;
    const formattedInsertedSecuritiesCount = numeral(insertedRecordsCount).format(0, 0);
    const missedSecurities =
      recordsNotFoundCount &&
      ` This will still leave ${numeral(recordsNotFoundCount).format(0, 0)} securities without data. `;
    const formattedInvalidRecordsCount = numeral(invalidRecords.length).format(0, 0);
    return (
      <div>
        {formattedInsertedSecuritiesCount > 0 && (
          <p>
            The upload completed and the system was able to validate {formattedInsertedSecuritiesCount} securities, all
            of which will be inserted upon save. {missedSecurities}
          </p>
        )}
        <p>
          {formattedInsertedSecuritiesCount > 0 && 'Additionally, '}
          {formattedInvalidRecordsCount} issue(s) were found and will be skipped. Please review your upload file and
          ensure that the data is formatted as specified by the CSV Template provided. For your convenience, we have
          listed the {maxInvalidRecordsToDisplay <= invalidRecords.length && ' top '}
          {Math.min(maxInvalidRecordsToDisplay, invalidRecords.length)} issues(s) that will be skipped below:
        </p>
        <ul className="mt-5">
          {map(take(invalidRecords, maxInvalidRecordsToDisplay), (invalidRecord, index) => {
            const {errors, record} = invalidRecord;
            if (errors[0].indexOf('Data Duplicated') >= 0 || errors[0].indexOf('Security Not Found') >= 0) {
              return (
                <li key={index}>
                  {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for the following ISIN(s):{' '}
                  <strong>{record.securityIsin ? record.securityIsin : 'N/A'}</strong>{' '}
                </li>
              );
            }
            return (
              <li key={index}>
                {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for Security ISIN:{' '}
                <strong>{record.securityIsin ? record.securityIsin : 'N/A'}</strong>{' '}
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
  UPLOAD_SECURITY_MARKET_INDEX_COMPLETE: statistics => {
    const {cashRecordsCount, insertedRecordsCount} = statistics;
    const formattedInsertedRecordsCount = numeral(insertedRecordsCount).format(0, 0);
    return (
      <div>
        <p>
          The upload completed and the system was able to validate {formattedInsertedRecordsCount} records, all of which
          will be inserted upon save.
          {cashRecordsCount > 0 && (
            <Fragment>
              {' '}
              Cash records were found as well which will be taken into account in determining the total weight of all of
              the securities in the Reference Index.
            </Fragment>
          )}
        </p>
      </div>
    );
  },
  UPLOAD_SECURITY_MARKET_INDEX_COMPLETE_WITH_SKIPPED_RECORDS: (invalidRecords, statistics) => {
    const maxInvalidRecordsToDisplay = 25;
    const {cashRecordsCount, insertedRecordsCount} = statistics;
    const formattedInsertedRecordsCount = numeral(insertedRecordsCount).format(0, 0);
    const formattedInvalidRecordsCount = numeral(invalidRecords.length).format(0, 0);
    return (
      <div>
        <p>
          The upload completed and the system was able to validate {formattedInsertedRecordsCount} records, all of which
          will be inserted upon save.
          {cashRecordsCount > 0 && (
            <Fragment>
              {' '}
              Cash records were found as well which will be taken into account in determining the total weight of all of
              the securities in the Reference Index.
            </Fragment>
          )}
        </p>
        <p>
          Additionally, {formattedInvalidRecordsCount} issue(s) were found and will be skipped. Please review your
          upload file and ensure that the data is formatted as specified by the CSV Template provided. For your
          convenience, we have listed the {maxInvalidRecordsToDisplay <= invalidRecords.length && ' top '}
          {Math.min(maxInvalidRecordsToDisplay, invalidRecords.length)} issues(s) that will be skipped below:
        </p>
        <ul className="mt-5">
          {map(take(invalidRecords, maxInvalidRecordsToDisplay), (invalidRecord, index) => {
            const {errors, record} = invalidRecord;
            if (errors[0].indexOf('Data Duplicated') >= 0 || errors[0].indexOf('Security Not Found') >= 0) {
              return (
                <li key={index}>
                  {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for the following ISIN(s):{' '}
                  <strong>{record.securityIsin ? record.securityIsin : 'N/A'}</strong>{' '}
                </li>
              );
            }
            return (
              <li key={index}>
                {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for:{' '}
                <ul>
                  <li>
                    <strong>
                      [ISIN: {record.securityIsin ? record.securityIsin : 'N/A'}]{' '}
                      {record.securityName ? record.securityName : 'N/A'} (Weight:{' '}
                      {record.weight ? record.weight : 'N/A'})
                    </strong>
                  </li>
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
  UPLOAD_COMPLETE: statistics => {
    const {insertedRecordsCount} = statistics;
    const formattedInsertedRecordsCount = numeral(insertedRecordsCount).format(0, 0);
    return (
      <div>
        <p>The upload completed and the system was able to validate {formattedInsertedRecordsCount} records.</p>
      </div>
    );
  },
  UPLOAD_COMPLETE_WITH_ISSUES: (invalidRecords, duplicateRecords, statistics) => {
    const maxInvalidRecordsToDisplay = 25;
    const {insertedRecordsCount} = statistics;
    const formattedInsertedRecordsCount = numeral(insertedRecordsCount).format(0, 0);
    const formattedInvalidRecordsCount = numeral(invalidRecords.length).format(0, 0);
    return (
      <div>
        <p>
          The upload completed and the system was able to validate {formattedInsertedRecordsCount} records, all of which
          will be inserted upon save.
        </p>
        {get(statistics, 'isMaxRecordsExceeded') && (
          <p>
            Due to the large amount of records uploaded, the Portfolio Center will limit the display results to the 500
            most relevant securities:{' '}
          </p>
        )}
        {!isEmpty(duplicateRecords) && (
          <p>
            Unfortunately, there were duplicate records during validation. Please review your upload to confirm that the
            following duplicated records were intended:{' '}
            <strong>{map(duplicateRecords, 'securityIsin').join(', ')}</strong>
          </p>
        )}
        {!isEmpty(invalidRecords) && (
          <Fragment>
            <p>
              Additionally, {formattedInvalidRecordsCount} issue(s) were found and will be skipped. Please review your
              upload file and ensure that the data is formatted as specified by the CSV Template provided. For your
              convenience, we have listed the {maxInvalidRecordsToDisplay <= invalidRecords.length && ' top '}
              {Math.min(maxInvalidRecordsToDisplay, invalidRecords.length)} issues(s) that will be skipped below:
            </p>
            <ul className="mt-5">
              {map(take(invalidRecords, maxInvalidRecordsToDisplay), (invalidRecord, index) => {
                const {errors, record} = invalidRecord;
                if (errors[0].indexOf('Security Not Found') >= 0) {
                  return (
                    <li key={index}>
                      {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for the following:{' '}
                      <strong>{record.securityIsin ? record.securityIsin : 'N/A'}</strong>{' '}
                    </li>
                  );
                }
                return (
                  <li key={index}>
                    {errors.length > 1 ? `${errors.length} errors were found` : errors[0]} for:{' '}
                    <ul>
                      <li>
                        <strong>
                          [ISIN: {record.securityIsin ? record.securityIsin : 'N/A'}]{' '}
                          {record.securityName ? record.securityName : 'N/A'} (Weight:{' '}
                          {record.weight ? record.weight : 'N/A'})
                        </strong>
                      </li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </Fragment>
        )}
      </div>
    );
  },
};
