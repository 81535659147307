import {MouseEvent, ChangeEvent, useEffect, useRef, useState} from 'react';
import {getElementAtEvent, getElementsAtEvent, getDatasetAtEvent} from 'react-chartjs-2';
import styled from 'styled-components/macro';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {distributionOptions} from 'v2/constants/selectOptions';
import {SelectOption} from 'v2/utilities/types/components/Select';
import {FlexContainer, Container} from '../atoms/Containers';
import {Subtitle, SubtitleM} from '../atoms/Typeface';
import {GreyedDropDown} from '../molecules/DropDown';
import {Theme} from '../atoms/theme';
import {DashboardDistributionChartProps} from 'v2/utilities/types/components/HoldingDistributionChart';
import {Checkbox} from '../molecules/Checkbox';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TableTitle = styled(SubtitleM)`
  font-weight: 700l;
`;

const TableSubtitle = styled(Subtitle)``;

const LegendLabel = styled(Container)`
  position: relative;
  top: 25.525rem;
  left: 0.2rem;
  width: 1.5625rem;
  white-space: nowrap;
  font-size: 0.75rem;
  color: ${({theme}) => theme.colors.dark};
`;

const HoldingsSwatch = styled(Container).attrs(() => ({
  width: '2.25rem',
  height: '.75rem',
  margin: '.25rem 0 0',
}))`
  background: ${({theme}) => theme.colors.light_green};
  background: ${({theme}) => theme.gradients.harmony_multi_legend};
`;

export const DashboardHoldingsDistributionChart = ({
  title = '',
  subTitle = '',
  options = {},
  labels = [],
  colors = [],
  indexColor = '',
  onDistributionChange,
  onSectorClick,
  onDistributionsChange,
  datasets = [],
}: DashboardDistributionChartProps) => {
  const [option, setOption] = useState<SelectOption>(distributionOptions[0]);
  const [showBenchmark, setShowBenchmark] = useState(false);

  const chartRef = useRef(null);

  useEffect(() => {
    onDistributionsChange?.(option.value);
  }, [option]);

  const handleSetOption = (e: SelectOption) => {
    setOption(e);
  };

  const handleSetBenchmark = (e: ChangeEvent<HTMLInputElement>) => {
    setShowBenchmark(e.target.checked);
  };

  useEffect(() => {
    onDistributionChange?.(option.value);
  }, [option]);

  const data = {
    labels,
    datasets: datasets
      .map((dataset: any, i) => {
        dataset.borderRadius = 4;

        if (i === 1) {
          dataset.backgroundColor = [indexColor];
        } else {
          dataset.backgroundColor = colors;
        }

        return dataset;
      })
      .filter((dataset: any) => {
        if (!showBenchmark) {
          return dataset.label !== 'Benchmark';
        }

        return dataset;
      }),
    pointBackgroundColor: ['red', 'green', 'blue', 'yellow'],
  };

  let min = 0,
    max = 0;

  datasets.forEach(dataset => {
    dataset.data.forEach((datum: number) => {
      if (datum > max) {
        max = Math.round((datum + 10) / 10) * 10;
      }
    });
  });

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const chartInstance = chartRef.current;

    let element, data;

    if (chartInstance) {
      element = getElementAtEvent(chartInstance, event)[0];
      if (element || element === 0) {
        data = datasets[getElementAtEvent(chartInstance, event)[0]?.datasetIndex];
        onSectorClick?.({element, data});
      }
    }
  };

  return (
    <Container>
      <FlexContainer items="center" justify="space-between">
        <Container padding="0 0 0.25rem 0">
          <TableTitle>{title.toUpperCase()}</TableTitle>
        </Container>
        <Container>
          <FlexContainer items="center" gap=".625rem">
            <Container>
              <Subtitle>Holdings %</Subtitle>
            </Container>
            <HoldingsSwatch />
            <Container margin="-0.5rem 0 0" padding="0 0 0 1rem">
              <Checkbox id="benchmark" checked={showBenchmark} onChange={handleSetBenchmark} />
            </Container>
            <Container padding="0 1.5rem 0 0">
              <Subtitle>Benchmark</Subtitle>
            </Container>
            <GreyedDropDown value={option} options={distributionOptions} onChange={handleSetOption} />
          </FlexContainer>
        </Container>
      </FlexContainer>
      <FlexContainer padding="0 0 3.5rem">
        <TableSubtitle>{subTitle}</TableSubtitle>
      </FlexContainer>
      <FlexContainer>
        <FlexContainer height="0" width="0">
          <LegendLabel>Percentile Rank</LegendLabel>
        </FlexContainer>
        <FlexContainer height="27rem" flex="1">
          <Bar
            ref={chartRef}
            options={{
              onHover: (e: any, chartElement) => {
                e?.native?.target &&
                  Object.assign(e.native.target.style, {cursor: chartElement.length > 0 ? 'pointer' : 'default'});
              },
              categoryPercentage: showBenchmark ? 0.55 : 0.6,
              barPercentage: showBenchmark ? 0.9 : 0.5,
              scales: {
                x: {
                  offset: true,
                  grid: {
                    drawBorder: false,
                    display: false,
                  },
                },
                y: {
                  min,
                  max,
                  ticks: {
                    base: 1,
                    callback: (val, i, ticks) => {
                      if (val !== 0) {
                        return val + '%    ';
                      }
                      return;
                    },
                  },
                  grid: {
                    drawBorder: false,
                    borderColor: 'transparent',
                    color: (ctx, options) => (ctx.tick.value === 0 ? 'transparent' : Theme.colors.grey_6),
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: context => {
                      const {
                        dataset: {label, data},
                        dataIndex,
                      } = context;

                      const value = data[dataIndex];
                      if (value <= 1) {
                        return `${label}: <1%`;
                      }
                      return `${label}: ${value}%`;
                    },
                  },
                },
              },
              ...options,
            }}
            data={data}
            onClick={onClick}
          />
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};
