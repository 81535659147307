import {assignWith, get, isEmpty, map, pick, filter, find} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Link} from 'react-router-dom';
import cn from 'classnames';

// Local Imports
import {Button, FormFeedback} from 'app/components';
import {FormGroup, SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {
  AUTOCOMPLETE,
  CHARACTER_LIMIT,
  MANAGER_TYPES,
  PORTFOLIO_MANAGEMENT_TYPES,
  REGEX_PATTERN,
  ROUTE,
  UPLOAD_FREQUENCIES,
} from 'app/constants';
import {errorRequiredFields, normalizeEmail, validateFieldArray, withViewportSize} from 'app/utilities';
import {CheckboxFormGroup} from './forms/CheckboxFormGroup';

const BATCH_SIZE = 3;
const INVESTMENT_MANAGER_INITIAL_VALUES = {
  class: MANAGER_TYPES[0],
  uploadFrequency: UPLOAD_FREQUENCIES[0],
};

const validate = values => {
  const errors = errorRequiredFields(values, ['name']);

  // Check to see if anything in the listing has data
  const investmentManagersHas = validateFieldArray(values.investmentManagersToInvite, (manager, ownErrors) => {
    if (manager.email || manager.strategy) {
      ownErrors.email = 'Exists';
    }
  });

  // if no data in listing, show required on first row
  if (isEmpty(investmentManagersHas)) {
    errors.investmentManagersToInvite = [{email: 'Required', strategy: 'Required'}];
  } else {
    // check for valid rows / data in the rows
    const investmentManagersToInviteErrors = validateFieldArray(
      values.investmentManagersToInvite,
      (manager, ownErrors) => {
        if (manager.email || manager.strategy) {
          if (!manager.email) ownErrors.email = 'Required';
          if (!manager.strategy) ownErrors.strategy = 'Required';
        }

        if (!ownErrors.email && manager.email) {
          if (!REGEX_PATTERN.VALID_EMAIL.test(manager.email)) {
            ownErrors.email = 'Invalid email address';
          }
        }
      },
    );
    investmentManagersToInviteErrors && (errors.investmentManagersToInvite = investmentManagersToInviteErrors);
  }

  return errors;
};

function SettingsFirmManagerEditFormComponent(props) {
  const {viewportWidth} = props;

  // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
  const isErrorFormFeedbackVisible = !!props.error || (!!props.submitFailed && props.invalid);

  const isLayoutSmall = viewportWidth < 1360;

  return (
    <form method="POST" onSubmit={props.handleSubmit}>
      <FormFeedback message={props.error} visible={isErrorFormFeedbackVisible} />
      <div className="form-grid">
        <Field label="Firm Name*" name="name" type="text" component={TextFormGroup} className="col-3" disabled={true} />
      </div>

      <FieldArray
        name="investmentManagersToInvite"
        component={renderInvestmentManagersToInvite}
        marketIndexes={props.marketIndexes}
      />

      <div className="form-footer is-right mt-12">
        <Link to={ROUTE.SETTINGS_FIRMS_MANAGER.path()} className="btn btn-outline">
          Cancel
        </Link>
        <Button type="submit" solid>
          Save Changes
        </Button>
      </div>
    </form>
  );
}

SettingsFirmManagerEditFormComponent.propTypes = {
  editFirm: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {editFirm = {}, marketIndexes = []} = ownProps;

  const defaultValues = {};

  // Pick which values will pre-populate the form.
  const itemValues = pick(editFirm, ['id', 'name']);

  let managers = map(get(editFirm, 'strategies'), item => {
    const marketIndex = find(marketIndexes, {id: item.marketIndexId});

    return {
      id: item.id,
      userId: item.user ? item.user.id : null,
      email: item.user ? item.user.email : null,
      strategy: item.name,
      type: item.type,
      class: item.class,
      marketIndex: !isEmpty(marketIndex) ? marketIndex.name : null,
      uploadFrequency: item.uploadFrequency,
      portfolioManagementType: item.portfolioManagementType,
    };
  });

  // Make sure it's an array
  if (!managers || !managers.length) managers = [];

  // Fill to next BATCH_SIZE with empty rows
  const numberOfRows = Math.max(Math.ceil(managers.length / BATCH_SIZE) * BATCH_SIZE, BATCH_SIZE);
  for (let i = managers.length; i < numberOfRows; i += 1) {
    managers.push({...INVESTMENT_MANAGER_INITIAL_VALUES});
  }

  return Object.assign({}, ownProps, {
    initialValues: assignWith(
      {},
      defaultValues,
      {investmentManagersToInvite: managers},
      itemValues,
      (objValue, srcValue) => srcValue || objValue,
    ),
  });
};

export const SettingsFirmManagerEditForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'settings-firm-edit',
    validate,
  }),
  withViewportSize,
)(SettingsFirmManagerEditFormComponent);

function renderInvestmentManagersToInvite({fields: investmentManagersToInvite, marketIndexes, isLayoutSmall}) {
  const standardMarketIndexes = !isEmpty(marketIndexes) ? filter(marketIndexes, {isHarmonyIndex: false}) : [];
  const formFieldClassName = 'min-h-0 mb-3';
  const formFieldClassNameLg = cn(formFieldClassName, isLayoutSmall ? 'col-3' : 'col-1');
  const formFieldClassNameSm = cn(formFieldClassName, isLayoutSmall ? 'col-2' : 'flex-fixed w-10r');
  const hrClassName = cn('my-6 w-100', isLayoutSmall ? 'col-6' : 'col-3');

  return (
    <Fragment>
      {investmentManagersToInvite.map((investmentManagerToInvite, index) => {
        const isFirst = index === 0;
        const doShowLabel = isFirst || isLayoutSmall;
        return (
          <div className="form-grid" key={index}>
            {isLayoutSmall && !isFirst && <hr className={hrClassName} />}
            <Field
              label={doShowLabel ? 'Manager Email*' : null}
              name={`${investmentManagerToInvite}.email`}
              type="text"
              component={TextFormGroup}
              normalize={normalizeEmail}
              className={formFieldClassNameLg}
              autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              maxLength={CHARACTER_LIMIT.EMAIL}
            />
            <Field
              label={doShowLabel ? 'Investment Strategy*' : null}
              name={`${investmentManagerToInvite}.strategy`}
              type="text"
              component={TextFormGroup}
              className={formFieldClassNameLg}
              maxLength={CHARACTER_LIMIT.INVESTMENT_STRATEGY}
            />
            <Field
              label={doShowLabel ? 'Manager Type*' : null}
              name={`${investmentManagerToInvite}.class`}
              component={SelectFormGroup}
              options={MANAGER_TYPES}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Index*' : null}
              name={`${investmentManagerToInvite}.marketIndex`}
              component={props => (
                <FormGroup {...props} typeahead options={standardMarketIndexes}>
                  {(inputProps, wrapperProps) => (
                    <Typeahead
                      id="idekwhotfcares"
                      {...wrapperProps}
                      ref={typeahead => {
                        typeahead = typeahead;
                      }}
                      placeholder="Select a Reference Index"
                      inputProps={inputProps}
                      labelKey="name"
                    />
                  )}
                </FormGroup>
              )}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Upload Frequency*' : null}
              name={`${investmentManagerToInvite}.uploadFrequency`}
              component={SelectFormGroup}
              options={UPLOAD_FREQUENCIES}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Portfolio Type*' : null}
              name={`${investmentManagerToInvite}.portfolioManagementType`}
              component={SelectFormGroup}
              options={PORTFOLIO_MANAGEMENT_TYPES}
              className={formFieldClassNameSm}
            />
            <div className={formFieldClassNameSm}>
              {doShowLabel && <label>Send Invite on Save</label>}
              <Field
                label={null}
                name={`${investmentManagerToInvite}.sendInvite`}
                component={CheckboxFormGroup}
                className={cn(formFieldClassNameSm, 'text-center', 'mt-2')}
                style={{paddingTop: '100px'}}
              />
            </div>
            {/* Hid Delete for now, per Michelle - new story needed for more fleshing out */}
            {/* <Button className="">Delete</Button> */}
          </div>
        );
      })}

      <Button
        className="mt-6"
        onClick={() => {
          for (let i = 0; i < BATCH_SIZE; i += 1) {
            investmentManagersToInvite.push({...INVESTMENT_MANAGER_INITIAL_VALUES});
          }
        }}
      >
        Add Another Strategy
      </Button>
    </Fragment>
  );
}
