/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import {map} from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

// LOCAL IMPORTS
import {ROUTE} from 'app/constants';
import {adminMenuProps} from 'app/components/Header/menuProps';
import {getAdminPrimaryMenuRoutes} from 'app/utilities';

const renderSingleCard = item => {
  return (
    <div className="setting-card" key={item.path()}>
      <div className="setting-card-content">
        <div className="setting-card-header">
          <div className="setting-card-name">{item.menuTitle || item.title}</div>
        </div>
        <div className="setting-card-body">
          <p>{item.menuMessage}</p>
        </div>
        <div className="setting-card-footer">
          <Link className="w-100 btn btn-outline" to={item.path()}>
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

const renderAllCards = menuProps => {
  return map(menuProps, item => renderSingleCard(item));
};

const AdminDashboard = () => {
  const adminPermissions = useSelector(state => state.auth.adminPermission);
  return (
    <div className="p-content-lg">
      <h1>{ROUTE.ADMIN_DASHBOARD.title}</h1>
      {renderAllCards(getAdminPrimaryMenuRoutes(adminPermissions))}
    </div>
  );
};

export {AdminDashboard};
