import React from 'react';
import PropTypes from 'prop-types';

function Toggle({children, className, disabled, input, id, label, meta: {touched, error}}) {
  return (
    <div
      className={
        className
          ? `admin-settings-toggle d-flex align-items-center ${className}`
          : 'admin-settings-toggle d-flex align-items-center'
      }
    >
      <label htmlFor="toggleInput" className="toggle-label pr-2">
        {label}
      </label>
      <label className="switch">
        <input {...input} disabled={disabled} type="checkbox" checked={!!input.value} id={id || input.name} />
        <span className="slider" />
      </label>
      {children}
    </div>
  );
}

Toggle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  meta: PropTypes.object,
};

export {Toggle};
