import {find, get, isEmpty, map} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {compose} from 'recompose';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

// Local Imports
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {fetchMarketIndexesIfNeeded} from 'app/redux/marketIndex';
import {Alert, ProgressIndicator, OnboardingInvestmentManagersForm} from 'app/components';
import {updatePlanSponsorByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';

class OnboardingInvestmentManagersPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchMarketIndexesIfNeeded());
  }

  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg" style={{width: '100%'}}>
        <ProgressIndicator
          steps={this.props.onboardingStepsArray}
          currentStep={this.props.requestedStep}
          nextAvailableStep={this.props.nextAvailableStep}
          className="pt-0 pb-12"
          style={{marginLeft: '-1.5rem'}}
        />
        <h1 className="mb-4">{ROUTE.ONBOARDING_INVESTMENT_MANAGERS.title}</h1>
        {this.props.hasUnexpectedError ? (
          <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
        ) : (
          <Fragment>
            <p className="mb-3">
              In order for your plan to show your current Harmony ratings, you will need to have your Investment
              Managers upload your portfolio. On this page you can invite your Investment Managers to your plan. You can
              edit the information below and add more information after you finish your account setup by going to the
              "Settings" portion of your account. Users invited to the system will not be invited until you complete
              your account setup process.
            </p>
            <OnboardingInvestmentManagersForm {...this.props} />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  marketIndexes: get(state, 'marketIndex.all'),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  const {marketIndexes} = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;

      const investmentManagersToInvite = map(get(values, 'investmentManagersToInvite'), manager => {
        const marketIndex = find(marketIndexes, {name: manager.marketIndex});

        return {
          ...manager,
          marketIndexId: isEmpty(marketIndex) ? null : marketIndex.id,
        };
      });

      const updateData = {
        ...values,
        onboardingStep: onboardingSteps.MANAGERS.onboardingStepName
          ? onboardingSteps.MANAGERS.onboardingStepName
          : onboardingSteps.MANAGERS.key,
        investmentManagersToInvite,
      };

      return dispatch(updatePlanSponsorByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_FINALIZE.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE});
        });
    },
    dispatch,
  };
};

export const OnboardingInvestmentManagers = compose(
  withOnboardingUser,
  connect(mapStateToProps, null, mergeProps),
)(OnboardingInvestmentManagersPage);
