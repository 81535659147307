import React from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import cn from 'classnames';

import {Button} from 'app/components';

const FileList = ({className, files, onRemoveFileClick}) => {
  if (!(files && files.length)) return null;
  return (
    <ul className={cn('file-list', className)}>
      {map(files, (file, index) => {
        const filename = file.name.includes('_') ? file.name.split('_').slice(1).join('') : file.name;

        return (
          <li key={index} className="file-list-item">
            <span className="file-list-item-name">{filename}</span>
            <span className="file-list-item-meta">{file.meta}</span>
            {onRemoveFileClick && (
              <Button color="red" onClick={() => onRemoveFileClick(file)}>
                Remove
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

FileList.propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  onRemoveFileClick: PropTypes.func,
};

export {FileList};
