import {ISSUES} from 'v2/constants/issues';
import {filter} from 'lodash';

export const useIssueTypes = () => {
  const data = ISSUES;

  const categories = filter(data, function (issue) {
    return issue.parentId === null && issue.id !== 0;
  });

  const subcategories = filter(data, function (issue) {
    return issue.parentId !== null;
  });

  return {
    data,
    categories,
    subcategories,
  };
};
