/* eslint-disable no-param-reassign */

export const showChartJsTooltip = ({chartInstance, datasetIndex, dataIndex}) => {
  const activeElement = [{datasetIndex: datasetIndex, index: dataIndex}];
  try {
    chartInstance.tooltip.setActiveElements(activeElement);
    chartInstance.draw();
  } catch (e) {
    // error
  }
};
