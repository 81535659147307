import {createAction} from 'redux-actions';

const initialState = {isOpen: false};

const ACTION = {
  OPEN: 'SIMPLE_MODAL_OPEN',
  CLOSE: 'SIMPLE_MODAL_CLOSE',
};

export const openSimpleModal = createAction(ACTION.OPEN);
export const closeSimpleModal = createAction(ACTION.CLOSE);

const simpleModal = (state = {...initialState}, action) => {
  switch (action.type) {
    case ACTION.OPEN:
      return {...action.payload, isOpen: true};
    case ACTION.CLOSE:
      return {...state, isOpen: false};
    default:
      return state;
  }
};

export {simpleModal};
