export function isValidEmail(value: string) {
  if (value) {
    var lastAtPos = value.lastIndexOf('@');
    var lastDotPos = value.lastIndexOf('.');
    return (
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      // eslint-disable-next-line eqeqeq
      value.indexOf('@@') == -1 &&
      lastDotPos > 2 &&
      value.length - lastDotPos > 2
    );
  }
  return false;
}
