import React, {Component} from 'react';
import {compact, each, get, indexOf, isEmpty, last, map, sortBy} from 'lodash';
import cn from 'classnames';

import {COLOR, ISSUE_OLD} from 'app/constants';
import {convertPeriodKeyToFloat} from 'app/utilities';
import EsgAssociationPriImage from 'app/assets/images/ESG-association_PRI.png';
import EsgAssociationGriImage from 'app/assets/images/ESG-association_GRI.png';
import {
  BarChart,
  BarChartLegend,
  EmpoweringChangeStats,
  IssueProgress,
  LogoHorizontal,
  RectangleImage,
} from 'app/components';

const ISSUE_PRE_COUNT = 9;

const style = {
  fill: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  impactIssues: {margin: '-1rem'},
  impactIssue: {
    padding: '1rem',
    width: '33%',
  },
  intro: {maxWidth: 580},
  logo: {
    height: '4.75rem',
    width: '17.5rem',
  },
  reportTitle: {
    fontSize: '1.9375rem',
    lineHeight: 1,
  },
  reportSubtitle: {
    fontSize: '1.1875rem',
    lineHeight: 1.5,
  },
};

const ImpactIssues = ({issues}) => (
  <div className="d-flex flex-wrap" style={style.impactIssues}>
    {map(issues, ({issue, periodData}) => (
      <div key={issue.code} style={style.impactIssue}>
        <IssueProgress periodData={periodData} issue={issue} type="print" />
      </div>
    ))}
  </div>
);

class ProgressReportPrintDisplay extends Component {
  componentDidMount() {
    document.documentElement.classList.add('print-simulation');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('print-simulation');
  }

  render() {
    const {
      className,
      config,
      details,
      distributionData,
      isAUMStatHidden,
      isCompaniesEngagedStatHidden,
      isCompaniesRespondedStatHidden,
      isManagersInComplianceStatHidden,
      isManagersWithESGPolicyStatHidden,
      isStatsHidden,
      issueData,
      options,
      stats,
    } = this.props;

    const periods = compact(get(config, 'periods', []));
    const periodKeysByDate = sortBy(periods, convertPeriodKeyToFloat);
    const firstPeriodKey = periodKeysByDate[0];
    const lastPeriodKey = last(periodKeysByDate);
    const timeSpan =
      firstPeriodKey !== lastPeriodKey ? `from ${firstPeriodKey} to ${lastPeriodKey}` : `in ${lastPeriodKey}`;

    // Split up issues before the bar chart and after
    const beforeIssues = [];
    const afterIssues = [];
    let count = 0;
    each(issueData, (periodData, code) => {
      if (isEmpty(periodData)) return null;

      const isIncluded = indexOf(config.includedIssues, code) >= 0;
      if (!isIncluded) return null;

      const issue = ISSUE_OLD[code];
      if (count < ISSUE_PRE_COUNT) {
        beforeIssues.push({periodData, issue});
      } else {
        afterIssues.push({periodData, issue});
      }
      count += 1;

      return count;
    });

    const certificationFigures = {
      gri: get(options, 'managerCertifications.counts.gri', 0),
      pri: get(options, 'managerCertifications.counts.pri', 0),
      total: get(options, 'managerCertifications.total'),
    };

    return (
      <div className={cn('print-page-container futura font-weight-normal', className)}>
        <div className="print-page d-flex flex-column">
          <div className="p-12 pb-8 mb-auto">
            <div className="d-flex mb-10 text-navy justify-content-between">
              <LogoHorizontal style={style.logo} />
              <div className="d-flex flex-column justify-content-center text-right">
                <div style={style.reportTitle}>Progress Report</div>
                <div style={style.reportSubtitle}>{lastPeriodKey}</div>
              </div>
            </div>
            <div className="d-flex">
              <div className="pr-12">
                <h2 className="text-navy">{details.name}</h2>
                <div style={style.intro}>
                  Ushering in harmony between corporations and the world requires influence from the owners of the
                  companies. Harmony Progress Report is a collective of investors that have come together for the sole
                  purpose of promoting change and spurring progress to create a more harmonious world. Your
                  participation has impacted the world. This Progress Report reflects that impact specific to your
                  investors on how you have safeguarded a world in which everyone wins.
                </div>
              </div>
              {!isStatsHidden && (
                <div className="flex-grow-0 flex-shrink-0 text-blue" style={{width: '25rem'}}>
                  <h2 className="text-blue text-uppercase">Empowering Change</h2>
                  <EmpoweringChangeStats
                    isAUMStatHidden={isAUMStatHidden}
                    isCompaniesEngagedStatHidden={isCompaniesEngagedStatHidden}
                    isCompaniesRespondedStatHidden={isCompaniesRespondedStatHidden}
                    isManagersInComplianceStatHidden={isManagersInComplianceStatHidden}
                    isManagersWithESGPolicyStatHidden={isManagersWithESGPolicyStatHidden}
                    stats={stats}
                    type="print"
                  />
                </div>
              )}
            </div>
          </div>
          {!isEmpty(beforeIssues) && (
            <div className="px-12 py-10 mb-auto position-relative">
              <RectangleImage fill={COLOR.ALMOST_WHITE} style={style.fill} />
              <div className="position-relative">
                <h2 className="text-navy pb-4">Your Impact {timeSpan}</h2>
                <ImpactIssues issues={beforeIssues} />
              </div>
            </div>
          )}
          <div className="p-12 mb-auto">
            <div className="d-flex">
              <div className="flex-fill">
                <h2 className="text-navy pb-8">Your Portfolio</h2>
                <BarChart
                  datasets={distributionData}
                  datasetsVisible={map(distributionData, ({isHidden}) => !isHidden)}
                  hasOverlap={false}
                  labels={null}
                  tooltipMode="none"
                  size={beforeIssues.length > 3 ? 'sm' : null}
                />
                <BarChartLegend
                  datasets={distributionData}
                  datasetsVisible={map(distributionData, ({isHidden}) => !isHidden)}
                />
              </div>
              {!!config.progressReportIncludeCertification && !!certificationFigures.total && (
                <div className="flex-grow-0 flex-shrink-0" style={{marginLeft: '4rem', width: '25rem'}}>
                  <h2 className="text-light-gray pb-7">Manager Certifications</h2>
                  <ul className="esg-certification-counts">
                    <li className="esg-certification-count">
                      <div className="esg-certification-count-image">
                        <img src={EsgAssociationPriImage} />
                      </div>
                      <span className="esg-certification-count-data">
                        {certificationFigures.pri} of {certificationFigures.total}
                      </span>
                    </li>
                    <li className="esg-certification-count">
                      <div className="esg-certification-count-image">
                        <img src={EsgAssociationGriImage} />
                      </div>
                      <span className="esg-certification-count-data">
                        {certificationFigures.gri} of {certificationFigures.total}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isEmpty(afterIssues) && (
          <div className="print-page p-12 position-relative">
            <RectangleImage fill={COLOR.ALMOST_WHITE} style={style.fill} />
            <div className="position-relative">
              <h2 className="text-navy mb-2">Your Impact {timeSpan}</h2>
              <div className="text-light-gray mb-12">
                <i>(Continued)</i>
              </div>
              <ImpactIssues issues={afterIssues} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export {ProgressReportPrintDisplay};
