import {useMemo, useState} from 'react';
import styled from 'styled-components/macro';
import {orderBy} from 'lodash';
import {Theme} from 'v2/components/atoms/theme';
import {distributionOptions, holdingOptions} from 'v2/constants/selectOptions';
import {useHoldingsTable} from 'v2/hooks/useHoldingsTable';
import {Container, FlexContainer, AttachedWrapper, TableCell, FloatingWrapper} from 'v2/components/atoms/Containers';
import {useManagerHoldingsDistribution} from 'v2/hooks/useManagerHoldingsDistribution';
import {CheckCircle} from 'v2/components/atoms/icons';
import {formatMillions, getOrdinal, getTransparency} from 'v2/utilities/helpers';
import ReactPlaceholder from 'react-placeholder';
import {RectShape, TextRow} from 'react-placeholder/lib/placeholders';
import {DashboardHoldingsDistributionChart} from 'v2/components/organisms/DashboardHoldingsDistributionChart';
import {DashboardHoldingsTable} from 'v2/components/organisms/DashboardHoldingsTable';
import {HoldingDistributionModal} from 'v2/components/organisms/HoldingDistributionModal';
import {ROUTE} from 'v2/constants/routes';

const Sup = styled.span`
  position: relative;
  top: -0.1875rem;
  font-size: 0.5625rem;
`;

const DistributionPlaceholder = () => (
  <FlexContainer direction="column" gap="4rem">
    <FlexContainer gap="2rem">
      <RectShape color={Theme.colors.grey_2} style={{height: 60, flex: 1}} />
      <RectShape color={Theme.colors.grey_2} style={{height: 60, width: 300}} />
    </FlexContainer>
    <FlexContainer gap="2rem">
      <RectShape color={Theme.colors.grey_2} style={{height: 400, flex: 1}} />
    </FlexContainer>
  </FlexContainer>
);

const HoldingsPlaceholder = () => (
  <FlexContainer direction="column" gap="2rem" padding="3rem">
    <FlexContainer gap="2rem">
      <RectShape color={Theme.colors.grey_2} style={{height: 60, flex: 1}} />
      <RectShape color={Theme.colors.grey_2} style={{height: 60, width: 200}} />
    </FlexContainer>
    <Container>
      <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
      <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
      <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
      <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
      <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
    </Container>
  </FlexContainer>
);

export const UT3ClientHoldings = () => {
  const [distributionFilter, setDistributionFilter] = useState(distributionOptions[0].value);
  const [holdingsFilter, setHoldingsFilter] = useState(holdingOptions[0].value);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [currentPct, setCurrentPct] = useState<{element: any; data: any}>({element: null, data: null});

  const handleClose = () => {
    setModalIsVisible(false);
  };

  const handleSectorClick = ({element, data}: any) => {
    if (data.label === 'Holdings') {
      setCurrentPct({element, data});
      setModalIsVisible(true);
    }
  };
  const {
    data: holdingsDistributions,
    isLoading: holdingsDistLoading,
    isSuccess: holdingsDistIsSuccess,
  } = useManagerHoldingsDistribution();
  const {data: holdings, isLoading: holdingsLoading} = useHoldingsTable(String(holdingsFilter));

  const isLoading = holdingsDistLoading || holdingsLoading;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const labels = ['100 - 81', '80 - 61', '60 - 41', '40 - 21', '20 - 0'];

  const memoizedColumns = useMemo(
    () => [
      {
        Header: 'Company Name',
        Cell: (row: any) => row.value,
        style: {
          maxWidth: 'auto',
          minWidth: '16rem',
          width: '16rem',
        },
        accessor: 'name',
      },
      {
        Header: 'Engaged',
        Cell: (row: any) => (row.value ? <CheckCircle size={24} /> : <></>),
        style: {
          maxWidth: 'auto',
          minWidth: '8rem',
          width: '8rem',
          justifyContent: 'center',
        },
        accessor: 'engaged',
      },
      {
        Header: 'Transparency',
        Cell: (row: any) => getTransparency(row.value),
        style: {
          maxWidth: 'auto',
          minWidth: '10rem',
          width: '10rem',
          justifyContent: 'center',
        },
        accessor: 'transparency',
      },
      {
        Header: 'Percentile Rank',
        Cell: (row: any) => (
          <TableCell>
            {row.value < 1 ? (
              <>
                1<Sup>st</Sup>
              </>
            ) : (
              <>
                {row.value}
                <Sup>{getOrdinal(row.value)}</Sup>
              </>
            )}
          </TableCell>
        ),
        style: {
          maxWidth: 'auto',
          minWidth: '9rem',
          width: '9rem',
          justifyContent: 'center',
        },
        accessor: 'rank',
      },
      {
        Header: 'Held',
        Cell: (row: any) => formatMillions(Number(row.value), 1, '$'),
        style: {
          maxWidth: 'auto',
          minWidth: '6rem',
          width: '6rem',
          justifyContent: 'center',
        },
        accessor: 'held',
      },
      {
        Header: 'Weight',
        Cell: (row: any) => <TableCell>{row.value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '6rem',
          width: '6rem',
        },
        accessor: 'weight',
      },
    ],
    [],
  );

  const memoizedDistributionDatasets = useMemo(() => {
    if (!holdingsDistIsSuccess) {
      return [
        {
          label: 'Holdings',
          data: [],
        },
        {
          label: 'Benchmark',
          data: [],
        },
      ];
    }

    return [
      {
        label: 'Holdings',
        data:
          distributionFilter === 'count' ? holdingsDistributions.holdings.count : holdingsDistributions.holdings.weight,
      },
      {
        label: 'Benchmark',
        data:
          distributionFilter === 'count'
            ? holdingsDistributions.benchmark.count
            : holdingsDistributions.benchmark.weight,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingsDistributions, holdingsDistLoading, holdingsDistIsSuccess]);

  const memoizedData = useMemo(() => holdings(), [holdings]);

  const handleDistributionChange = (option: string) => {
    setDistributionFilter(option);
  };

  const handleHoldingsChange = (option: string) => {
    setHoldingsFilter(option);
  };
  const companyPath = (id: string) => {
    return `${ROUTE.UT3.COMPANIES.path.replace(':companyId', id)}${ROUTE.UT3.COMPANIES.RISK.search}`;
  };

  const memoizedCompaniesByTier = useMemo(() => {
    return orderBy(
      holdingsDistributions.aggregatePercentileRankHoldings,
      holding => Number(holding.held),
      'desc',
    ).filter(holding => {
      const tier = labels[currentPct?.element?.index ?? ''];

      if (tier === '100 - 81') {
        return holding.pctRank >= 0.81;
      } else if (tier === '80 - 61') {
        return holding.pctRank >= 0.61 && holding.pctRank < 0.8;
      } else if (tier === '60 - 41') {
        return holding.pctRank >= 0.41 && holding.pctRank < 0.6;
      } else if (tier === '40 - 21') {
        return holding.pctRank >= 0.21 && holding.pctRank < 0.4;
      } else {
        return holding.pctRank <= 0.21;
      }
    });
  }, [holdingsDistributions.aggregatePercentileRankHoldings, labels, currentPct?.element?.index]);

  return (
    <>
      <AttachedWrapper height="38.25rem" padding="2.75rem 2.1875rem 2.1875rem">
        <ReactPlaceholder ready={true} customPlaceholder={<DistributionPlaceholder />}>
          <DashboardHoldingsDistributionChart
            title="Holding Distribution"
            subTitle="Distribution of Company Ratings for Holdings"
            labels={labels}
            indexColor={Theme.colors.grey_4}
            colors={[
              Theme.colors.red,
              Theme.colors.orange,
              Theme.colors.yellow,
              Theme.colors.light_green,
              Theme.colors.green,
            ]}
            datasets={memoizedDistributionDatasets}
            onSectorClick={handleSectorClick}
            onDistributionsChange={handleDistributionChange}
          />
        </ReactPlaceholder>
      </AttachedWrapper>
      <FloatingWrapper padding="0 0 1.8rem">
        <ReactPlaceholder ready={true} customPlaceholder={<HoldingsPlaceholder />}>
          <DashboardHoldingsTable
            title="Holdings"
            data={memoizedData}
            columns={memoizedColumns}
            path={companyPath}
            onHoldingsChange={handleHoldingsChange}
          />
        </ReactPlaceholder>
      </FloatingWrapper>
      <HoldingDistributionModal
        isOpen={modalIsVisible}
        setClose={handleClose}
        color={currentPct?.data?.backgroundColor[currentPct?.element?.index]}
        data={memoizedCompaniesByTier}
        label={labels[currentPct?.element?.index ?? '']}
        defaultCompanyPath={companyPath}
      />
    </>
  );
};
