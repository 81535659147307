import {createSlice} from '@reduxjs/toolkit';

// {
//   "pathname": {
//     "listItemId": {
//       "filterName": true
//     }
//   }
// }
const initialState = {};

export const appliedFiltersSlice = createSlice({
  name: 'appliedFilters',
  initialState,
  reducers: {
    updateOptions: (state, action) => {
      const {options, pathname} = action.payload;
      state[pathname] = {...state[pathname], ...options};
    },
    removeOption: (state, action) => {
      const {pathname, listItemId, optionId} = action.payload;
      delete state[pathname][listItemId][optionId];
    },
    resetOptions: (state, action) => {
      const {pathname} = action.payload;
      state[pathname] = {};
    },
    resetAllOptions: (state, action) => {
      Object.keys(state).forEach(path => (state[path] = {}));
    },
  },
});

export const {resetOptions, updateOptions, removeOption, resetAllOptions} = appliedFiltersSlice.actions;

export default appliedFiltersSlice.reducer;
