export const MANAGER_ROLES = {
  BOARD: 'BOARD',
  PROFESSIONALS: 'PROFESSIONALS',
  SUPPORT: 'SUPPORT',
  ALL: 'ALL',
};

export const MANAGER_ETHNICITIES = {
  ASIAN: 'ASIAN',
  HISPANIC: 'HISPANIC',
  BLACK: 'BLACK',
  WHITE: 'WHITE',
  NATIVE_AMERICAN: 'NATIVE_AMERICAN',
  PACIFIC_ISLANDER: 'PACIFIC_ISLANDER',
  OTHER: 'OTHER',
  MULTIPLE: 'MULTIPLE',
};

export const MANAGER_GENDERS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};
