import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {isNil} from 'lodash';
import {CONFIG, ROLE} from 'app/constants';

/**
 * Set the JWT and Refresh Token for the root authenticated user.
 * @returns {*}
 */
export const setAuthTokens = (jwt, rt) => {
  if (!isNil(jwt) && !isNil(rt)) {
    const jwtDecoded = jwtDecode(jwt);
    const rtDecoded = jwtDecode(rt);

    Cookies.set('auth-jwt', jwt, {
      expires: jwtDecoded.exp * 1000,
      secure: true,
    });
    Cookies.set('auth-rt', rt, {
      expires: rtDecoded.exp * 1000,
      secure: true,
    });
  }
};

/**
 * Get the JWT for the root authenticated user.
 * i.e. If an admin is logged in and impersonating another user, this returns the JWT for the admin user.
 * @returns {*}
 */
export const getAuthJwt = (decoded = false) => {
  let token = Cookies.get('auth-jwt');

  if (decoded && !isNil(token)) {
    token = jwtDecode(token);
  }

  return token;
};

/**
 * Get the Refresh Token for the root authenticated user.
 * i.e. If an admin is logged in and impersonating another user, this returns the Refresh Token for the admin user.
 * @returns {*}
 */
export const getAuthRt = (decoded = false) => {
  let token = Cookies.get('auth-rt');

  if (decoded && !isNil(token)) {
    token = jwtDecode(token);
  }

  return token;
};

/**
 * Set the JWT and Refresh Token for an admin impersonated user.
 * @returns {*}
 */
export const setImpersonationTokens = (jwt, rt) => {
  if (!isNil(jwt) && !isNil(rt)) {
    const jwtDecoded = jwtDecode(jwt);
    const rtDecoded = jwtDecode(rt);

    Cookies.set('impersonation-jwt', jwt, {
      expires: jwtDecoded.exp * 1000,
      secure: true,
    });
    Cookies.set('impersonation-rt', rt, {
      expires: rtDecoded.exp * 1000,
      secure: true,
    });
  }
};

/**
 * Get the JWT for the root authenticated user.
 * i.e. If an admin is logged in and impersonating another user, this returns the JWT for the impersonated user.
 * @returns {*}
 */
export const getImpersonationJwt = (decoded = false) => {
  let token = Cookies.get('impersonation-jwt');

  if (decoded && !isNil(token)) {
    token = jwtDecode(token);
  }

  return token;
};

/**
 * Get the Refresh Token for the root authenticated user.
 * i.e. If an admin is logged in and impersonating another user, this returns the Refresh Token for the impersonated user.
 * @returns {*}
 */
export const getImpersonationRt = (decoded = false) => {
  let token = Cookies.get('impersonation-rt');

  if (decoded && !isNil(token)) {
    token = jwtDecode(token);
  }

  return token;
};

/**
 * Determine if the provided token is valid and not expired
 * @param token
 * @returns {boolean}
 */
export const tokenIsExpired = token => {
  if (!isNil(token)) {
    let decodedToken = token;

    if (typeof token === 'string') {
      decodedToken = jwtDecode(token);
    }

    return decodedToken.exp * 1000 < new Date().getTime();
  }

  return true;
};

/**
 * Determine if the current user is logged in
 * @returns {boolean}
 */
export const isAuthenticated = () => {
  return !tokenIsExpired(getAuthRt(true));
};

/**
 * Determine if the current user is an admin impersonating another user
 * @returns {boolean}
 */
export const isImpersonating = () => {
  return !tokenIsExpired(getImpersonationRt(true));
};

/**
 * Get the JWT for the current acing user.
 * i.e. If an admin is logged in and impersonating a user, this returns the JWT for the impersonated user.
 * Otherwise, it returns the JWT for the current user.
 * @returns {*}
 */
export const getSessionJwt = (decoded = false) => {
  return isImpersonating() ? getImpersonationJwt(decoded) : getAuthJwt(decoded);
};

/**
 * Remove JWT and Refresh Token for admin impersonated users
 */
export const removeImpersonationTokens = () => {
  Cookies.remove('impersonation-jwt');
  Cookies.remove('impersonation-rt');
};

/**
 * Remove JWT and Refresh Token for the current auth user
 */
export const removeAuthTokens = () => {
  removeImpersonationTokens();
  Cookies.remove('auth-jwt');
  Cookies.remove('auth-rt');
};

export const refreshAuthTokens = async () => {
  const refreshToken = getAuthRt();

  if (!tokenIsExpired(refreshToken)) {
    const {data} = await axios.post(`${CONFIG.API_URL}/auth/refresh_token`, {refreshToken});
    setAuthTokens(data.access_token, data.refresh_token);
  } else {
    return false;
  }

  return true;
};

export const refreshImpersonationTokens = async () => {
  const refreshToken = getImpersonationRt();
  if (!tokenIsExpired(refreshToken)) {
    const response = await axios.post(`${CONFIG.API_URL}/auth/refresh_token`, {refreshToken});
    setImpersonationTokens(response.data.access_token, response.data.refresh_token);
  } else {
    return false;
  }

  return true;
};

export const isAdmin = () => {
  const roles = getAuthJwt(true)?.contents?.roles || [];
  return roles.includes(ROLE.ADMIN);
};
