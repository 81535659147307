import {SelectOption} from '../components/interfaces/Select';

export const TypeValues = {
  allAssets: {
    label: 'All Types',
    value: 'all',
    valueForClass: 'all',
  },
  liquid: {
    label: 'Liquid Assets',
    value: 'Liquid Assets',
    valueForClass: 'Liquid',
    isDisabled: true,
  },
  illiquid: {
    label: 'Illiquid Assets',
    value: 'Illiquid Assets',
    valueForClass: 'Illiquid',
    isDisabled: true,
  },
};

export const LiquidClassValues = {
  allProducts: {
    label: 'All Classes',
    value: '',
  },
  publicEquity: {
    label: 'Public Equity',
    value: 'Public Equity',
    isDisabled: true,
  },
  fixedIncome: {
    label: 'Fixed Income',
    value: 'Fixed Income',
    isDisabled: true,
  },
};

export const IlliquidClassValues = {
  allProducts: {
    label: 'All Classes',
    value: '',
  },
  private: {
    label: 'Private',
    value: 'Private',
    isDisabled: true,
  },
  ventureCapital: {
    label: 'Venture Capital',
    value: 'Venture Capital',
    isDisabled: true,
  },
  other: {
    label: 'Other',
    value: 'Other',
    isDisabled: true,
  },
};

export const allAssetsClassValues = {
  allProducts: {
    label: 'All Classes',
    value: '',
  },
  publicEquity: {
    label: 'Public Equity',
    value: 'Public Equity',
    isDisabled: true,
  },
  fixedIncome: {
    label: 'Fixed Income',
    value: 'Fixed Income',
    isDisabled: true,
  },
  private: {
    label: 'Private',
    value: 'Private',
    isDisabled: true,
  },
  ventureCapital: {
    label: 'Venture Capital',
    value: 'Venture Capital',
    isDisabled: true,
  },
  other: {
    label: 'Other',
    value: 'Other',
    isDisabled: true,
  },
};

export const RegionValues = {
  overall: {
    label: 'All Regions',
    value: '',
  },
  nonUSDeveloped: {
    label: 'Non-US developed',
    value: 'Non-US Developed',
    isDisabled: true,
  },
  US: {
    label: 'US',
    value: 'US',
    isDisabled: true,
  },
  emergingMarkets: {
    label: 'Emerging Markets',
    value: 'Emerging Markets',
    isDisabled: true,
  },
  frontierMarkets: {
    label: 'Frontier Markets',
    value: 'Frontier Markets',
    isDisabled: true,
  },
};

export const labelToKey = {
  ['Liquid Assets']: 'liquid',
  ['Illiquid Assets']: 'illiquid',
  ['Public Equity']: 'publicEquity',
  ['Fixed Income']: 'fixedIncome',
  ['Private']: 'private',
  ['Venture Capital']: 'ventureCapital',
  ['Other']: 'other',
  ['All Regions']: 'overall',
  ['Non-US Developed']: 'nonUSDeveloped',
  ['US']: 'US',
  ['Emerging Markets']: 'emergingMarkets',
  ['Frontier Markets']: 'frontierMarkets',
};

export const TypeFilters: SelectOption[] = [TypeValues.allAssets, TypeValues.liquid, TypeValues.illiquid];

export const LiquidClassFilters: SelectOption[] = [
  LiquidClassValues.allProducts,
  LiquidClassValues.publicEquity,
  LiquidClassValues.fixedIncome,
];

export const IlliquidClassFilters: SelectOption[] = [
  IlliquidClassValues.allProducts,
  IlliquidClassValues.private,
  IlliquidClassValues.ventureCapital,
  IlliquidClassValues.other,
];

export const allAssetsClassFilters: SelectOption[] = [
  allAssetsClassValues.allProducts,
  allAssetsClassValues.publicEquity,
  allAssetsClassValues.fixedIncome,
  allAssetsClassValues.private,
  allAssetsClassValues.ventureCapital,
  allAssetsClassValues.other,
];

export const RegionFilters: SelectOption[] = [
  RegionValues.overall,
  RegionValues.nonUSDeveloped,
  RegionValues.US,
  RegionValues.emergingMarkets,
  RegionValues.frontierMarkets,
];
