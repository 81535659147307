import {Theme} from '../theme';

export const FullHarmonyLogo = ({color = Theme.colors.dark, size = 32}) => (
  <svg
    width={size * 3.66}
    height={size}
    viewBox={`0 0 ${size * 3.66} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2_64)">
      <path
        d="M3.56509 21.7706C4.09036 21.0386 4.74415 20.2171 5.51528 19.4013C8.43218 16.3391 11.6396 14.9701 14.7912 15.4562C16.5235 15.7189 18.2446 17.0711 20.2339 18.6357C22.944 20.7703 26.023 23.1843 29.7613 23.1843H29.9904C33.0861 17.2052 32.1306 9.66713 27.1126 4.64917C22.1561 -0.312907 14.7242 -1.30197 8.77863 1.67639L3.56509 21.7706Z"
        fill={color}
      />
      <path
        d="M7.73928 2.24075C6.63846 2.90013 5.5991 3.6992 4.65474 4.64915C-0.547615 9.85151 -1.3858 17.764 2.13459 23.8381L7.73928 2.24075Z"
        fill={color}
      />
      <path
        d="M19.703 19.3175C17.8087 17.8255 16.1714 16.5403 14.6627 16.3112C7.69456 15.255 2.87777 24.4639 2.82748 24.5589C2.79395 24.626 2.74366 24.6763 2.68219 24.721C3.25216 25.5648 3.90595 26.3694 4.64914 27.1182C10.8517 33.3208 20.91 33.3208 27.1126 27.1182C28.057 26.1738 28.856 25.1401 29.5098 24.0448C25.5927 23.9387 22.469 21.4912 19.703 19.3175Z"
        fill={color}
      />
      <path
        d="M51.258 9.3486V5.90643H52.208V14.3945H51.258V10.2315H46.9162V14.3945H45.9719V5.90643H46.9162V9.3486H51.258Z"
        fill={color}
      />
      <path
        d="M59.3214 7.70573L57.8686 11.1032H60.7407L59.3214 7.70573ZM57.483 11.9861L56.4604 14.3945H55.4266L59.3438 5.4985L63.1603 14.3945H62.1154L61.1151 11.9861H57.483Z"
        fill={color}
      />
      <path
        d="M67.4351 9.83475H67.7257C68.7929 9.83475 69.877 9.63358 69.877 8.31483C69.877 6.91785 68.8544 6.78374 67.7145 6.78374H67.4351V9.83475ZM67.4351 14.3945H66.4907V5.90643H67.7201C69.3294 5.90643 70.827 6.3367 70.827 8.24778C70.827 9.55535 70.0055 10.4774 68.698 10.5891L71.3969 14.3945H70.2346L67.6698 10.6673H67.4351V14.3945Z"
        fill={color}
      />
      <path
        d="M76.2361 5.42029L79.3206 12.5449L82.4219 5.42029L84.143 14.3945H83.1707L82.0811 8.65011H82.0587L79.3206 14.7409L76.5993 8.65011H76.5769L75.4873 14.3945H74.515L76.2361 5.42029Z"
        fill={color}
      />
      <path
        d="M87.9931 10.1477C87.9931 12.0644 89.5465 13.6625 91.4688 13.6625C93.3966 13.6625 94.9501 12.0644 94.9501 10.1477C94.9501 8.21986 93.419 6.63289 91.4688 6.63289C89.5242 6.63848 87.9931 8.22545 87.9931 10.1477ZM95.8944 10.1589C95.8944 12.6008 93.8884 14.5398 91.4688 14.5398C89.0492 14.5398 87.0432 12.6008 87.0432 10.1589C87.0432 7.72812 89.0492 5.75558 91.4688 5.75558C93.8884 5.76117 95.8944 7.72812 95.8944 10.1589Z"
        fill={color}
      />
      <path
        d="M99.4818 5.54324L106.104 12.4779V5.90646H107.048V14.7745L100.432 7.85106V14.3945H99.4818V5.54324Z"
        fill={color}
      />
      <path
        d="M110.496 5.90643H111.585L113.748 9.68946L115.91 5.90643H117L114.217 10.7456V14.3945H113.273V10.7456L110.496 5.90643Z"
        fill={color}
      />
      <path
        d="M48.1064 21.005L47.2012 23.1284H48.9949L48.1064 21.005ZM46.9609 23.676L46.3183 25.1848H45.6701L48.1176 19.6248L50.5037 25.1848H49.8499L49.224 23.676H46.9609Z"
        fill={color}
      />
      <path
        d="M55.3372 19.6527L59.4779 23.9834V19.8763H60.0702V25.4195L55.9295 21.0944V25.1848H55.3372V19.6527Z"
        fill={color}
      />
      <path
        d="M67.3345 21.005L66.4293 23.1284H68.223L67.3345 21.005ZM66.189 23.676L65.5464 25.1848H64.8982L67.3457 19.6248L69.7317 25.1848H69.0779L68.4521 23.676H66.189Z"
        fill={color}
      />
      <path d="M75.2973 24.6315H76.7613V25.1847H74.7106V19.8762H75.2973V24.6315Z" fill={color} />
      <path
        d="M80.8797 19.8762H81.5614L82.9137 22.2399L84.2604 19.8762H84.9477L83.2098 22.9049V25.1847H82.6175V22.9049L80.8797 19.8762Z"
        fill={color}
      />
      <path d="M91.3347 25.1847H90.7423V20.4238H89.4627V19.8762H92.6255V20.4238H91.3347V25.1847Z" fill={color} />
      <path d="M98.2357 19.8762H97.6434V25.1792H98.2357V19.8762Z" fill={color} />
      <path
        d="M107.752 21.0386C107.327 20.6027 106.746 20.3345 106.131 20.3345C104.952 20.3345 103.991 21.3627 103.991 22.5305C103.991 23.6984 104.952 24.7266 106.143 24.7266C106.741 24.7266 107.322 24.4416 107.746 24.0225V24.7545C107.288 25.0842 106.724 25.2742 106.165 25.2742C104.667 25.2742 103.399 24.056 103.399 22.5529C103.399 21.033 104.645 19.7869 106.165 19.7869C106.757 19.7869 107.277 19.9545 107.746 20.3065V21.0386H107.752Z"
        fill={color}
      />
      <path
        d="M115.558 20.91C115.346 20.5468 115.094 20.3345 114.642 20.3345C114.172 20.3345 113.765 20.6753 113.765 21.1559C113.765 21.6141 114.234 21.8264 114.592 21.9885L114.944 22.1449C115.631 22.4467 116.218 22.7931 116.218 23.6369C116.218 24.5645 115.474 25.2742 114.552 25.2742C113.703 25.2742 113.066 24.7266 112.898 23.8995L113.474 23.7375C113.552 24.2795 113.966 24.721 114.536 24.721C115.106 24.721 115.625 24.2851 115.625 23.6872C115.625 23.0669 115.139 22.8546 114.647 22.6311L114.323 22.4914C113.703 22.212 113.172 21.8935 113.172 21.1335C113.172 20.3121 113.871 19.7812 114.659 19.7812C115.251 19.7812 115.748 20.083 116.033 20.6027L115.558 20.91Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2_64">
        <rect width={size * 3.66} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
