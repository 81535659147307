import {ISSUE_CATEGORIES} from 'app/constants';
import {isEmpty} from 'lodash';
import {ISSUES, ISSUES_BY_ID} from 'v2/constants/issues';
import {
  AggregatePercentileUniverse,
  AggregatePercentileHolding,
  Issue,
  Strategy,
  MarketIndexBenchmark,
} from '../types/typeOrm';
import {
  allAssetsClassValues,
  IlliquidClassValues,
  labelToKey,
  LiquidClassValues,
  RegionValues,
  TypeValues,
} from 'v2/constants/strategyFilters';
import {AppliedFiltersByListItem, AppliedFiltersProps} from '../types/components/FilterList';
import {TagProps} from '../types/components/TagHeader';
import {strategy} from 'app/redux/strategy';

const ENVIRONMENTAL_ISSUES = [ISSUES.CARBON_EMISSIONS, ISSUES.ENERGY_USE, ISSUES.WATER_CONSUMPTION];

const MEDIAN_VALUES = {
  [ISSUES.BOARD_INDEPENDENCE.id]: 30,
  [ISSUES.CARBON_EMISSIONS.id]: 0.025763264437881,
  [ISSUES.CEO_COMPENSATION_RATIO.id]: 84,
  [ISSUES.ENERGY_USE.id]: 66291.99346638689,
  [ISSUES.INJURY_FREQUENCY.id]: 0.01507575757575755,
  [ISSUES.WATER_CONSUMPTION.id]: 234.67927421271764,
  [ISSUES.WOMEN_IN_LEADERSHIP.id]: 15.38461538461538,
  [ISSUES.WOMEN_ON_BOARD.id]: 20,
};

const isFilteredOut = (data: any, filters: any) => {
  if (isEmpty(filters.region) || data.region === filters.region) {
    if (isEmpty(filters.class) || data.class === filters.class) {
      if (filters.type === 'all' || data.type === filters.type) {
        return false;
      }
    }
  }
  return true;
};

export const filterHoldings = (data: AggregatePercentileHolding[], tags: TagProps[], strategies: Strategy[]) => {
  const filtered = data.filter(datum => matchesSelectedTags({data: datum, tags, strategies}));
  return filtered;
};

const matchesSelectedTags = ({
  data,
  tags,
  strategies,
}: {
  data: AggregatePercentileHolding;
  tags: TagProps[];
  strategies: Strategy[];
}) => {
  let filters = {
    type: true,
    class: true,
    region: true,
    strategy: true,
    manager: true,
    index: true,
    product: true,
  };

  if (tags.length === 0) {
    return true;
  }

  const strategy = strategies.find(strategy => strategy.id === Number(data.strategyId));

  const typeOptions = tags.filter(tag => tag.listItemId === 'type').map(tag => tag.value);
  const classOptions = tags.filter(tag => tag.listItemId === 'class').map(tag => tag.value);
  const regionOptions = tags.filter(tag => tag.listItemId === 'region').map(tag => tag.value);
  const strategyOptions = tags.filter(tag => tag.listItemId === 'strategy').map(tag => tag.value);
  const managerOptions = tags.filter(tag => tag.listItemId === 'manager').map(tag => Number(tag.value));
  const indexOptions = tags.filter(tag => tag.listItemId === 'index').map(tag => Number(tag.value));
  const productOptions = tags.filter(tag => tag.listItemId === 'product').map(tag => Number(tag.value));

  tags.forEach(tag => {
    switch (tag.listItemId) {
      case 'type':
        filters.type = typeOptions.includes(data.type);
        break;
      case 'class':
        filters.class = classOptions.includes(data.class);
        break;
      case 'region':
        filters.region = regionOptions.includes(data.region);
        break;
      case 'strategy':
        filters.strategy = strategyOptions.includes(strategy?.portfolioManagementType);
        break;
      case 'manager':
        filters.manager = managerOptions.includes(strategy?.firmId ?? 0);
        break;
      case 'index':
        filters.index = indexOptions.includes(strategy?.marketIndexId ?? 0);
        break;
      case 'product':
        filters.product = productOptions.includes(data.strategyId);
        break;
      default:
        break;
    }
  });

  return Object.values(filters).every(bool => bool === true);
};

export const calculateUniverseStatistics = (
  percentiles: {[x: number]: number},
  companies: Set<number>,
  data: AggregatePercentileUniverse[],
) => {
  let total = 0;

  let universePercentiles: {[key: number]: number} = {};

  data.forEach(datum => {
    if (Object.keys(ISSUE_CATEGORIES).includes(datum?.issueId?.toString())) {
      if (companies.has(datum.companyId)) {
        percentiles[datum.issueId] += datum.marketCap * datum.issuePctRank;

        // Pick any arbitrary issue to only run this calculation once per company
        if (datum.issueId === ISSUES.ENVIRONMENTAL.id) {
          percentiles[ISSUES.GLOBAL.id] += datum.marketCap * datum.companyPctRank;
          total += datum.marketCap;
        }
      }
    }
  });

  Object.keys(percentiles).forEach((key: string) => {
    universePercentiles[parseInt(key)] = (100 * percentiles[parseInt(key)]) / total;
  });

  return universePercentiles;
};

export const calculateHoldingsStatistics = (
  percentiles: {[x: number]: number},
  companies: Set<number>,
  filters: {[key: string]: string},
  issues: {[key: number]: Issue},
  data: AggregatePercentileHolding[],
) => {
  let total = 0;

  let currentPercentiles: any = {};
  let currentQuintiles: any = {};

  data.forEach(datum => {
    if (datum.issueId in ISSUE_CATEGORIES && !isFilteredOut(datum, filters)) {
      companies.add(datum.companyId);
      if (!(datum.issueId in issues)) {
        issues[datum.issueId] = {
          id: datum.issueId,
          name: datum.issue,
        };
      }

      percentiles[datum.issueId] += Number(datum.amountHeld) * datum.issuePctRank;

      // Pick any arbitrary issue to only run this calculation once per company
      if (datum.issueId === ISSUES.ENVIRONMENTAL.id) {
        if (!(ISSUES.GLOBAL.id in issues)) {
          issues[ISSUES.GLOBAL.id] = {
            id: ISSUES.GLOBAL.id,
            name: 'Overall',
          };
        }

        percentiles[ISSUES.GLOBAL.id] += Number(datum.amountHeld) * datum.companyPctRank;
        total += Number(datum.amountHeld);
      }
    }
  });

  Object.keys(percentiles).forEach((key: string) => {
    const percentile = (100 * percentiles[parseInt(key)]) / total;

    currentPercentiles[parseInt(key)] = percentile;
    currentQuintiles[parseInt(key)] = Math.floor(percentile / 20) + 1;
  });

  return {
    percentiles: currentPercentiles,
    quintiles: currentQuintiles,
  };
};

export const calculateBenchmarkStatistics = (
  percentiles: {[x: number]: number},
  companies: Set<number>,
  data: MarketIndexBenchmark[],
) => {
  let total = 0;

  const universePercentiles: {[key: number]: number} = {};
  //@ts-ignore
  let issueIds = [...new Set(data.map(datum => datum.issue_id))];

  let uniqueCompanyIds: any = {};
  issueIds.forEach(issueId => {
    uniqueCompanyIds[issueId] = new Set();
  });
  data.forEach(datum => {
    if (Object.keys(ISSUE_CATEGORIES).includes(datum?.issue_id?.toString())) {
      if (!uniqueCompanyIds[datum.issue_id]?.has(datum.company_id)) {
        percentiles[datum.issue_id] += datum.market_cap * datum.issue_pct_rank;

        // Pick any arbitrary issue to only run this calculation once per company
        if (datum.issue_id === ISSUES.ENVIRONMENTAL.id) {
          percentiles[ISSUES.GLOBAL.id] += datum.market_cap * datum.company_pct_rank;
          total += datum.market_cap;
        }
        uniqueCompanyIds[datum.issue_id].add(datum.company_id);
      }
    }
  });

  Object.keys(percentiles).forEach((key: string) => {
    universePercentiles[parseInt(key)] = (100 * percentiles[parseInt(key)]) / total;
  });

  return universePercentiles;
};

export const calculateESGBenchmarktatistics = (
  percentiles: {[x: number]: number},
  rawPercentiles: {[x: number]: number},
  companies: Set<number>,
  esgFilters: any,
  data: MarketIndexBenchmark[],
) => {
  let totalMarketCap = 0;

  const universePercentiles: {[key: number]: number} = {};
  const rawUniverseValue: {[key: number]: number} = {};
  //@ts-ignore
  let issueIds = [...new Set(data.map(datum => datum.issue_id))];

  let uniqueCompanyIds: any = {};
  issueIds.forEach(issueId => {
    uniqueCompanyIds[issueId] = new Set();
  });

  data.forEach(datum => {
    if (!uniqueCompanyIds[datum.issue_id]?.has(datum.company_id)) {
      const rawValue = Number(datum.issue_raw_value) ?? MEDIAN_VALUES[datum.issue_id];

      percentiles[datum.issue_id] += datum.market_cap * datum.issue_pct_rank;

      if (rawValue) {
        if (ENVIRONMENTAL_ISSUES.filter(({id}) => esgFilters.subTopic.id === id).length > 0) {
          rawPercentiles[datum.issue_id] += rawValue;
        } else {
          rawPercentiles[datum.issue_id] += datum.market_cap * rawValue;
        }
      }

      // Pick any arbitrary issue to only run this calculation once per company
      if (datum.issue_id === esgFilters.category.id) {
        percentiles[ISSUES.GLOBAL.id] += datum.market_cap * rawValue;
        totalMarketCap += datum.market_cap;
      }
    }
    uniqueCompanyIds[datum.issue_id].add(datum.company_id);
  });

  rawPercentiles[ISSUES.CARBON_EMISSIONS.id] = rawPercentiles[ISSUES.CARBON_EMISSIONS.id] * 10 ** 6;

  Object.keys(percentiles).forEach((key: string) => {
    universePercentiles[parseInt(key)] = (100 * percentiles[parseInt(key)]) / totalMarketCap;
  });

  Object.keys(rawPercentiles).forEach((key: string) => {
    rawUniverseValue[parseInt(key)] = rawPercentiles[parseInt(key)] / totalMarketCap;
  });

  return {
    universePercentiles,
    rawUniverseValue,
  };
};

export const calculateFilteredESGUniverseStatistics = (
  percentiles: {[x: number]: number},
  rawPercentiles: {[x: number]: number},
  companies: Set<number>,
  issues: {[key: number]: Issue},
  esgFilters: any,
  tags: TagProps[],
  strategies: Strategy[],
  data: AggregatePercentileHolding[],
) => {
  let currentPercentiles = percentiles;
  let currentRawPercentiles = rawPercentiles;

  let totalMarketValue = 0;

  let universePercentiles: {[key: number]: number} = {};
  let universeQuintiles: {[key: number]: number} = {};
  let rawPercentileValue: {[key: number]: number} = {};

  data.forEach(datum => {
    if (matchesSelectedTags({data: datum, tags, strategies})) {
      if (datum.issueId === esgFilters.category.id) {
        totalMarketValue += Number(datum.marketCap);
      }

      if (!(ISSUES.GLOBAL.id in issues)) {
        issues[ISSUES.GLOBAL.id] = {
          id: ISSUES.GLOBAL.id,
          name: 'Overall',
        };
      }
    }
  });

  data.forEach(datum => {
    if (matchesSelectedTags({data: datum, tags, strategies})) {
      companies.add(datum.companyId);
      currentPercentiles[datum.issueId] += Number(datum.marketCap) * datum.issuePctRank;

      const rawValue = datum.issue_raw_value ?? MEDIAN_VALUES[datum.issueId];

      if (!(datum.issueId in issues)) {
        issues[datum.issueId] = {
          id: datum.issueId,
          name: datum.issue,
        };
      }
      if (datum.issueId === esgFilters.category.id) {
        currentPercentiles[datum.issueId] += Number(datum.marketCap) * datum.companyPctRank;
      }

      if (esgFilters.subTopic && ENVIRONMENTAL_ISSUES.filter(({id}) => esgFilters.subTopic.id === id).length > 0) {
        if (datum.marketCap) {
          currentRawPercentiles[datum.issueId] += Number(rawValue); //TODO reassess
        }
      } else {
        if (datum.marketCap) {
          currentRawPercentiles[datum.issueId] += Number(rawValue) * Number(datum.marketCap);
        }
      }
    }
  });

  rawPercentiles[ISSUES.CARBON_EMISSIONS.id] = rawPercentiles[ISSUES.CARBON_EMISSIONS.id] * 10 ** 6;

  Object.keys(currentPercentiles).forEach((key: string) => {
    const percentile = (100 * currentPercentiles[parseInt(key)]) / totalMarketValue;

    universePercentiles[parseInt(key)] = percentile;
    universeQuintiles[parseInt(key)] = percentile !== 0 ? Math.floor(percentile / 20) + 1 : -1;
  });

  Object.keys(currentRawPercentiles).forEach((key: string) => {
    const rawPercentile = currentRawPercentiles[parseInt(key)] / totalMarketValue;

    rawPercentileValue[parseInt(key)] = rawPercentile;
  });

  return {
    universePercentiles: universePercentiles,
    quintiles: universeQuintiles,
    rawUniverseValue: rawPercentileValue,
  };
};

export const calculateESGUniverseStatistics = (
  percentiles: {[x: number]: number},
  rawPercentiles: {[x: number]: number},
  companies: Set<number>,
  esgFilters: any,
  data: AggregatePercentileUniverse[],
) => {
  let totalMarketCap = 0;

  let universePercentiles: {[key: number]: number} = {};
  let rawUniverseValue: {[key: number]: number} = {};

  data.forEach(datum => {
    if (companies.has(datum.companyId)) {
      const rawValue = Number(datum.issue_raw_value) ?? MEDIAN_VALUES[datum.issueId];

      percentiles[datum.issueId] += datum.marketCap * datum.issuePctRank;

      if (rawValue) {
        if (ENVIRONMENTAL_ISSUES.filter(({id}) => esgFilters.subTopic.id === id).length > 0) {
          rawPercentiles[datum.issueId] += rawValue;
        } else {
          rawPercentiles[datum.issueId] += datum.marketCap * rawValue;
        }
      }

      // Pick any arbitrary issue to only run this calculation once per company
      if (datum.issueId === esgFilters.category.id) {
        percentiles[ISSUES.GLOBAL.id] += datum.marketCap * rawValue;
        totalMarketCap += datum.marketCap;
      }
    }
  });

  rawPercentiles[ISSUES.CARBON_EMISSIONS.id] = rawPercentiles[ISSUES.CARBON_EMISSIONS.id] * 10 ** 6;

  Object.keys(percentiles).forEach((key: string) => {
    universePercentiles[parseInt(key)] = (100 * percentiles[parseInt(key)]) / totalMarketCap;
  });

  Object.keys(rawPercentiles).forEach((key: string) => {
    rawUniverseValue[parseInt(key)] = rawPercentiles[parseInt(key)] / totalMarketCap;
  });

  return {
    universePercentiles,
    rawUniverseValue,
  };
};

export const calculateESGHoldingsStatistics = (
  percentiles: {[x: number]: number},
  rawPercentiles: {[x: number]: number},
  companies: Set<number>,
  issues: {[key: number]: Issue},
  esgFilters: any,
  tags: TagProps[],
  strategies: Strategy[],
  data: AggregatePercentileHolding[],
) => {
  let currentPercentiles = percentiles;
  let currentRawPercentiles = rawPercentiles;

  let totalMarketValue = 0;

  let universePercentiles: {[key: number]: number} = {};
  let universeQuintiles: {[key: number]: number} = {};
  let rawPercentileValue: {[key: number]: number} = {};

  data.forEach(datum => {
    if (matchesSelectedTags({data: datum, tags, strategies})) {
      if (datum.issueId === esgFilters.category.id) {
        totalMarketValue += Number(datum.amountHeld);
      }

      if (!(ISSUES.GLOBAL.id in issues)) {
        issues[ISSUES.GLOBAL.id] = {
          id: ISSUES.GLOBAL.id,
          name: 'Overall',
        };
      }
    }
  });

  data.forEach(datum => {
    if (matchesSelectedTags({data: datum, tags, strategies})) {
      companies.add(datum.companyId);
      currentPercentiles[datum.issueId] += Number(datum.amountHeld) * datum.issuePctRank;

      const rawValue = datum.issue_raw_value ?? MEDIAN_VALUES[datum.issueId];

      if (!(datum.issueId in issues)) {
        issues[datum.issueId] = {
          id: datum.issueId,
          name: datum.issue,
        };
      }
      if (datum.issueId === esgFilters.category.id) {
        currentPercentiles[datum.issueId] += Number(datum.amountHeld) * datum.companyPctRank;
      }

      if (esgFilters.subTopic && ENVIRONMENTAL_ISSUES.filter(({id}) => esgFilters.subTopic.id === id).length > 0) {
        if (datum.marketCap) {
          currentRawPercentiles[datum.issueId] += (Number(datum.amountHeld) * rawValue) / datum.marketCap;
        }
      } else {
        currentRawPercentiles[datum.issueId] += Number(datum.amountHeld) * rawValue;
      }
    }
  });

  rawPercentiles[ISSUES.CARBON_EMISSIONS.id] = rawPercentiles[ISSUES.CARBON_EMISSIONS.id] * 10 ** 6;

  Object.keys(currentPercentiles).forEach((key: string) => {
    const percentile = (100 * currentPercentiles[parseInt(key)]) / totalMarketValue;

    universePercentiles[parseInt(key)] = percentile;
    universeQuintiles[parseInt(key)] = percentile !== 0 ? Math.floor(percentile / 20) + 1 : -1;
  });

  Object.keys(currentRawPercentiles).forEach((key: string) => {
    const rawPercentile = currentRawPercentiles[parseInt(key)] / totalMarketValue;

    rawPercentileValue[parseInt(key)] = rawPercentile;
  });

  return {
    percentiles: universePercentiles,
    quintiles: universeQuintiles,
    rawPercentileValue,
  };
};

export const setTypesFilters = (data: AggregatePercentileHolding[]) => {
  const typeFilters = JSON.parse(JSON.stringify(TypeValues));
  const classFilters = JSON.parse(
    JSON.stringify({Liquid: LiquidClassValues, Illiquid: IlliquidClassValues, all: allAssetsClassValues}),
  );
  const regionFilters = JSON.parse(JSON.stringify(RegionValues));

  const typeValues = Object.keys(typeFilters).map(key => typeFilters[key].value);
  const liquidClassValues = Object.keys(classFilters.Liquid).map(key => classFilters.Liquid[key].value);
  const illiquidClassValues = Object.keys(classFilters.Illiquid).map(key => classFilters.Illiquid[key].value);
  const allClassValues = Object.keys(classFilters.all).map(key => classFilters.all[key].value);
  const regionValues = Object.keys(regionFilters).map(key => regionFilters[key].value);

  data.forEach(datum => {
    if (typeValues.includes(datum.type)) {
      //@ts-ignore
      typeFilters[labelToKey[datum.type]].isDisabled = false;
    }

    if (liquidClassValues.includes(datum.class)) {
      //@ts-ignore
      classFilters.Liquid[labelToKey[datum.class]].isDisabled = false;
    }

    if (illiquidClassValues.includes(datum.class)) {
      //@ts-ignore
      classFilters.Illiquid[labelToKey[datum.class]].isDisabled = false;
    }

    if (allClassValues.includes(datum.class)) {
      //@ts-ignore
      classFilters.all[labelToKey[datum.class]].isDisabled = false;
    }

    if (regionValues.includes(datum.region)) {
      //@ts-ignore
      regionFilters[labelToKey[datum.region]].isDisabled = false;
    }
  });

  return {
    typeFilters,
    classFilters,
    regionFilters,
  };
};
