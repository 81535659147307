import React from 'react';

function VimeoPlayer(props) {
  const {vimeoId, ...restProps} = props;

  return (
    <div className="responsive-wrapper">
      <iframe
        title="vimeo-player"
        src={`https://player.vimeo.com/video/${vimeoId}`}
        width="640"
        height="360"
        frameBorder="0"
        // ref={(element) => console.log(element)}
        allowFullScreen
        {...restProps}
      ></iframe>
    </div>
  );
}

export {VimeoPlayer};
