import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LoadingSpinner = ({isActive}) => (
  <div className={cn('loading-spinner', {'is-active': isActive})}>
    <div className="loading-spinner-graphic" />
  </div>
);

LoadingSpinner.propTypes = {isActive: PropTypes.bool};

export {LoadingSpinner};
