import {FC} from 'react';
import Select from 'react-select';
import {map} from 'lodash';
import cn from 'classnames';
import {Theme} from '../atoms/theme';
import {SelectProps} from '../interfaces/Select';
import {ErrorField} from './ErrorField';
import {ParagraphS} from '../atoms/Typeface';
import styled from 'styled-components';

const Label = styled(ParagraphS)`
  white-space: nowrap;
`;

export const DropDown: React.FC<SelectProps> = ({
  placeholder,
  options,
  onChange,
  onFocus,
  isDark,
  value,
  disabled = false,
}) => {
  return (
    <Select
      onChange={value => onChange && onChange(value)}
      onFocus={() => onFocus && onFocus()}
      value={value}
      placeholder={placeholder}
      defaultValue={value}
      styles={{
        control: (provided: any, state: any) => ({
          ...provided,
          background: isDark ? Theme.colors.calypso : Theme.colors.white,
          borderRadius: Theme.constants.border_radius,
          cursor: 'pointer',
          border: isDark ? '0' : `1px solid ${Theme.colors.grey_3}`,
          ':hover': {
            border: isDark ? '0' : `1px solid ${Theme.colors.grey_3}`,
          },
          boxShadow: 'none',
          opacity: state.isDisabled && '0.5',
        }),
        dropdownIndicator: (provided: any, state: any) => ({
          ...provided,
          paddingRight: '1rem',
          color: isDark ? Theme.colors.white : Theme.colors.grey_5,
          ':hover': {
            color: isDark ? Theme.colors.white : Theme.colors.grey_5,
          },
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: (provided: any, state: any) => ({
          ...provided,
          background: Theme.colors.white,
          boxShadow: Theme.shadows.shadow_1,
          border: 'none',
        }),
        menuList: (provided: any, state: any) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: Theme.constants.border_radius,
        }),
        placeholder: (provided: any, state: any) => ({
          ...provided,
          color: isDark ? Theme.colors.white : Theme.colors.grey_5,
          paddingLeft: '2.625rem',
          textAlign: 'center',
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          background: state.isDisabled ? Theme.colors.grey_2 : Theme.colors.white,
          color: Theme.colors.grey_5,
          cursor: 'pointer',
          textAlign: 'center',
          verticalAlign: 'center',
          padding: '0.8rem',
          borderBottom: `1px solid ${Theme.colors.grey_2}`,
          ':last-child': {
            borderBottom: 0,
          },
          ':hover': {
            background: state.isDisabled ? Theme.colors.grey_2 : Theme.colors.white,
            color: state.isDisabled ? Theme.colors.grey_5 : Theme.colors.dark,
          },
        }),
        singleValue: (provided: any, state: any) => ({
          ...provided,
          color: isDark ? Theme.colors.white : Theme.colors.grey_5,
          paddingLeft: '2.625rem',
          textAlign: 'center',
        }),
      }}
      isSearchable={false}
      options={options}
      isDisabled={disabled}
    />
  );
};

export const GreyedDropDown: React.FC<SelectProps> = ({
  placeholder,
  options,
  onChange,
  onFocus,
  isDark,
  value,
  disabled = false,
  height,
  width,
}) => {
  return (
    <Select
      onChange={value => onChange && onChange(value)}
      onFocus={() => onFocus && onFocus()}
      value={value}
      placeholder={placeholder}
      defaultValue={value}
      styles={{
        control: (provided: any, state: any) => ({
          ...provided,
          background: Theme.colors.grey_2,
          borderRadius: Theme.constants.border_radius,
          cursor: 'pointer',
          border: '0',
          boxShadow: 'none',
          height: height ?? '2.75rem',
          width: width ?? 'auto',
          opacity: state.isDisabled && '0.5',
        }),
        dropdownIndicator: (provided: any, state: any) => ({
          ...provided,
          paddingRight: '1rem',
          color: Theme.colors.grey_5,
          ':hover': {
            color: Theme.colors.grey_5,
          },
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: (provided: any, state: any) => ({
          ...provided,
          background: Theme.colors.white,
          boxShadow: Theme.shadows.shadow_1,
          border: 'none',
        }),
        menuList: (provided: any, state: any) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: Theme.constants.border_radius,
        }),
        placeholder: (provided: any, state: any) => ({
          ...provided,
          color: Theme.colors.dark,
          fontSize: '.875rem',
          textAlign: 'center',
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          background: state.isDisabled ? Theme.colors.grey_3 : Theme.colors.grey_2,
          color: Theme.colors.dark,
          cursor: 'pointer',
          textAlign: 'center',
          verticalAlign: 'center',
          padding: '0.8rem',
          borderBottom: `1px solid ${Theme.colors.grey_2}`,
          ':last-child': {
            borderBottom: 0,
          },
          ':hover': {
            background: state.isDisabled ? Theme.colors.grey_2 : Theme.colors.white,
            color: state.isDisabled ? Theme.colors.grey_5 : Theme.colors.dark,
          },
        }),
        singleValue: (provided: any, state: any) => ({
          ...provided,
          color: Theme.colors.dark,
          padding: '0 2rem 0 1rem',
          textAlign: 'center',
        }),
      }}
      isSearchable={false}
      options={options}
      isDisabled={disabled}
    />
  );
};

interface ISelectFormGroup {
  input: any;
  label?: string;
  labelHint?: string;
  description?: string;
  className?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  options: Array<{[key: string]: any}>;
  optionValueProp?: string;
  optionLabelProp?: string;
  inputClassName?: string;
  id?: string;
  autoFocus?: boolean;
  shouldInsertDefault?: boolean;
  defaultLabel?: string;
  meta: any;
  size?: string;
  subtle?: boolean;
  placeholder?: string;
  width?: string;
  height?: string;
}

//@ts-ignore
const SelectAdapter = ({input, ...rest}) => <GreyedDropDown {...input} {...rest} />;

export const SelectFormGroup: FC<ISelectFormGroup> = ({
  input,
  label = '',
  labelHint,
  description,
  className,
  onChange,
  disabled,
  options,
  optionValueProp = 'value',
  optionLabelProp = 'label',
  id,
  autoFocus,
  shouldInsertDefault,
  defaultLabel,
  meta: {error, submitError, invalid},
  size,
  subtle,
  ...rest
}) => {
  const selectOptions: any =
    options.length > 0 && typeof options[0] === 'string'
      ? map(options, option => ({
          [optionLabelProp]: option,
          [optionValueProp]: option,
        }))
      : options.slice(0); // clone the array
  if (shouldInsertDefault) {
    selectOptions.unshift({[optionLabelProp]: defaultLabel});
  }

  const optionsMap: {label: string; value: string}[] = selectOptions.map((option: any, i: number) => ({
    value: option[optionValueProp] as string,
    label: option[optionLabelProp] as string,
  }));

  const selectedOption = optionsMap.find(option => option.value === input.value);
  return (
    <div className={cn(className)} style={{flex: 1}}>
      <div className="label-hint-container">
        {label && (
          <Label as="label" htmlFor={id || input.name}>
            {label}
          </Label>
        )}
        {labelHint && <span className="label-hint">{labelHint}</span>}
      </div>
      {description}
      <SelectAdapter
        {...input}
        value={selectedOption}
        id={id || input.name}
        disabled={disabled}
        autoFocus={autoFocus}
        options={optionsMap}
        onChange={(args: any) => {
          input.onChange(args.value);
          if (onChange) {
            onChange(args);
          }
        }}
        {...rest}
      />
      <ErrorField error={invalid && (error || submitError)} />
    </div>
  );
};
