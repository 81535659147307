import {map} from 'lodash';

export const ISSUE_CATEGORY = {
  OVERALL: 'Overall',
  ENVIRONMENT: 'Environmental',
  SOCIAL: 'Social',
  GOVERNANCE: 'Governance',
};

export const ISSUE_SUB_CATEGORY = {
  ENVIRONMENT: 'Environmental',
  ENVIRONMENTAL_POLICIES: 'Environmental Policies',
  DIVERSITY_INCLUSION: 'Diversity & Inclusion',
  COMPENSATION_EQUITY: 'Compensation Equity',
  WORKFORCE_WELL_BEING: 'Workforce Well-Being',
  BOARD_GOVERNANCE: 'Board Governance',
  CORPORATE_TRUST: 'Corporate Trust',
};

export const ISSUE_CATEGORY_KEYS = {
  OVERALL: 'OVERALL',
  ENVIRONMENTAL: 'ENVIRONMENTAL',
  SOCIAL: 'SOCIAL',
  GOVERNANCE: 'GOVERNANCE',
};

export const ISSUE_CATEGORIES = map(ISSUE_CATEGORY, value => value);
