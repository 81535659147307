import {isUndefined} from 'lodash';
import {useEffect} from 'react';
import {useField, useForm} from 'react-final-form';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {IssuePolicyCard as IssuePolicyCardProps} from 'v2/utilities/types/components/AdminFormCard';
import {FlexContainer} from '../atoms/Containers';
import {AdminFormCard} from '../molecules/AdminFormCard';
import {SelectFormGroup} from '../molecules/DropDown';
import {FileBrowserWithUploadInput} from './FileBrowserWithUploadInput';

type FormValues = {
  issueId: string | number;
  value: string;
  sourceLink: string;
};

const DROPDOWN_OPTIONS = [
  {
    value: '1',
    label: 'Yes',
  },
  {
    value: '0',
    label: 'No',
  },
];

const IssuePolicyFields = ({companyId, issueId}: {companyId: number; issueId: number}) => {
  const {change: setValue, getState} = useForm();
  const {meta, input} = useField('value');
  const {values} = getState();

  useEffect(() => {
    if (+input.value !== 1) {
      setValue('sourceLink', '');
    }
  }, [input.value]);

  return (
    <FlexContainer padding="3rem 0 0" gap="3rem" justify="center">
      <FlexContainer flex="1" direction="column">
        <FlexContainer direction="column" flex={1}>
          <label htmlFor={input.name}>Has Policy?</label>
          <SelectFormGroup
            input={input}
            placeholder={DATA_STATUS.NONE}
            options={DROPDOWN_OPTIONS}
            meta={meta.touched && meta}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer flex="1" direction="column">
        <label>Source File</label>
        <FileBrowserWithUploadInput
          isDisabled={+values.value !== 1}
          accept="application/pdf, .pdf"
          companyId={companyId}
          name="sourceLink"
          issueId={issueId}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export const AdminIssuePolicyCard = ({companyId, companyName, issueName, year, ...rest}: IssuePolicyCardProps) => {
  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (+values.value === 1 && !values.sourceLink) {
      errors.sourceLink = 'File Name is required if you have a policy';
    }

    if (values.sourceLink && +values.value !== 1) {
      errors.sourceLink = 'Cannot add filename if Has Policy is not Yes';
    }

    return errors;
  };

  const title = () => {
    if (isUndefined(companyName) || isUndefined(issueName) || isUndefined(year)) {
      return DATA_STATUS.NONE;
    }

    return `Viewing ${year} ${issueName} ${issueName.includes('Policy') ? '' : 'Policy'} For ${companyName}`;
  };

  return (
    <AdminFormCard title={title()} validate={validate} year={year} {...rest}>
      <IssuePolicyFields companyId={companyId} {...rest} />
    </AdminFormCard>
  );
};
