import {useRef} from 'react';
import {Field, Form} from 'react-final-form';
import styled, {createGlobalStyle} from 'styled-components/macro';
import {errorRequiredFields} from 'app/utilities';
import {useOutsideClick} from 'v2/hooks/helpers/useOutsideClick';
import {EmailPaywall as EmailPaywallProps} from 'v2/utilities/types/components/EmailPaywall';
import {Container, FlexContainer} from '../atoms/Containers';
import {ParagraphM, ParagraphS, SubtitleM, TitleXS} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';
import {CircleCheck, Close, ShieldLogo} from '../atoms/icons';
import {TextAreaFormGroup} from '../molecules/TextareaInput';
import {HarmonyButton} from '../molecules/Button';
import {Overlay} from '../atoms/Overlay';

const DisableBodyScrolling = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ComponentWrapper = styled(FlexContainer)<EmailPaywallProps>`
  max-width: 62.4375rem;
  width: auto;
  height: auto;
  border-radius: ${({theme}) => theme.constants.border_radius};
  box-shadow: ${({theme}) => theme.shadows.shadow_1};
`;

const HarmonyContentContainer = styled(FlexContainer)`
  background: ${({theme}) => theme.colors.harmony};
  background: ${({theme}) => theme.gradients.harmony_horizontal_blue};
  border-radius: ${({theme}) => `${theme.constants.border_radius} 0 0 ${theme.constants.border_radius}`};
  height: inherit;
`;

const EmailContentContainer = styled(FlexContainer)`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => `0 ${theme.constants.border_radius} ${theme.constants.border_radius} 0`};
  height: inherit;
`;

const TitleContainer = styled(Container)`
  position: relative;
  top: -0.1875rem;
`;

const IconButton = styled.button.attrs({as: 'button'})`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
`;

export const EmailPaywall = ({
  title = 'Title',
  onClose = () => {},
  onOutsideClick = () => {},
  onSubmit = () => {},
  isLoading = false,
  visible = false,
}: EmailPaywallProps) => {
  const ref = useRef(null);

  const refWithOutsideclick = useOutsideClick({ref, onOutsideClick});

  const validate = (values: any) => {
    return errorRequiredFields(values, ['message']);
  };

  return (
    <Overlay items="center" justify="center" visible={visible} fullWidth>
      {visible && <DisableBodyScrolling />}
      <ComponentWrapper ref={refWithOutsideclick} flex="1">
        <HarmonyContentContainer flex="1" items="center" justify="center" direction="column">
          <FlexContainer width="100%" padding="4rem 4rem 0" gap="1.0625rem" items="center" justify="flex-start">
            <Container>
              <ShieldLogo />
            </Container>
            <TitleContainer>
              <SubtitleM color={Theme.colors.white}>{title}</SubtitleM>
            </TitleContainer>
          </FlexContainer>
          <Container padding="3.5625rem 4rem 0">
            <TitleXS color={Theme.colors.white}>
              {`
                Thank you for joining the Harmony Analytics 
                Platform. A subscription is required to 
                view this feature.
            `}
            </TitleXS>
          </Container>
          <FlexContainer direction="column" gap="1.625rem" padding="3.125rem 4rem 5rem">
            <FlexContainer>
              <FlexContainer items="center" justify="center" padding="0 1.625rem 0 0">
                <CircleCheck color={Theme.colors.white} size={24} />
              </FlexContainer>
              <Container>
                <ParagraphM color={Theme.colors.white}>Access the Harmony universe of 10,000 companies</ParagraphM>
              </Container>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer items="center" justify="center" padding="0 1.625rem 0 0">
                <CircleCheck color={Theme.colors.white} size={24} />
              </FlexContainer>
              <Container>
                <ParagraphM color={Theme.colors.white}>
                  Understand your risk profile across companies, products and total holdings
                </ParagraphM>
              </Container>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer items="center" justify="center" padding="0 1.625rem 0 0">
                <CircleCheck color={Theme.colors.white} size={24} />
              </FlexContainer>
              <Container>
                <ParagraphM color={Theme.colors.white}>Evaluate risk using absolute and contextualized data</ParagraphM>
              </Container>
            </FlexContainer>
          </FlexContainer>
        </HarmonyContentContainer>
        <EmailContentContainer direction="column" flex="1">
          <FlexContainer justify="flex-end" padding="2.8125rem 2.8125rem 6.25rem">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </FlexContainer>
          <Container padding="0 1rem">
            <Form onSubmit={onSubmit} validate={validate} keepDirtyOnReinitialize>
              {({handleSubmit}) => {
                return (
                  <form method="POST" onSubmit={handleSubmit}>
                    <FlexContainer direction="column" padding="0 2.8125rem" flex="1">
                      <Container>
                        <ParagraphM color={Theme.colors.blue_1}>Get in touch</ParagraphM>
                      </Container>
                      <Container padding="0.5625rem 0 2rem">
                        <ParagraphS color={Theme.colors.dark}>
                          Please contact our support team for more information regarding our licenses.
                        </ParagraphS>
                      </Container>
                      <Container>
                        <Field name="message" type="text" component={TextAreaFormGroup} disabled={isLoading} />
                      </Container>
                      <FlexContainer justify="flex-end" padding="4rem 0">
                        <HarmonyButton width="10.625rem" type="submit" disabled={isLoading}>
                          Submit
                        </HarmonyButton>
                      </FlexContainer>
                    </FlexContainer>
                  </form>
                );
              }}
            </Form>
          </Container>
        </EmailContentContainer>
      </ComponentWrapper>
    </Overlay>
  );
};
