import styled from 'styled-components/macro';
import {ArrowLeft} from '../atoms/icons';
import {Body1} from '../atoms/Typeface';

const Button = styled.button`
  display: flex;
  border: none;
  border-radius: ${({theme}) => theme.constants.border_radius};
  padding: 0.7rem 1.2rem 0.7rem 1rem;
  margin: 0.15rem 0;
  align-items: center;
  gap: 1rem;
  background: transparent;

  &:hover {
    background: ${({theme}) => theme.colors.grey_11};
  }
`;

export const ReturnButton: React.FC<{label?: string; onClick?: Function}> = ({label, onClick}) => {
  return (
    <Button onClick={() => onClick?.()}>
      <ArrowLeft size={20} />
      <Body1>{label}</Body1>
    </Button>
  );
};
