import styled from 'styled-components/macro';
import {ChevronRight, Info} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {Caption1, Caption2} from '../atoms/Typeface';
import {BannerButtonProps} from '../interfaces/BannerButton';

const Wrapper = styled.button`
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.813rem;
  text-align: left;
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.constants.border_radius};
  cursor: pointer;
`;

const Flex = styled.div<{flex?: string | number; padding?: string; min?: string}>`
  flex: ${({flex}) => flex ?? 0};
  padding: ${({padding}) => padding ?? '0 0'};
  min-width: ${({min}) => min ?? 'auto'};
`;

const Body = styled(Caption1).attrs(({theme}) => ({
  color: theme.colors.grey_5,
}))`
  font-size: 0.813rem;
`;

export const BannerButton = ({description = '', label = '', onClick = () => {}}: BannerButtonProps) => {
  return (
    <Wrapper onClick={() => onClick()}>
      <Flex padding="0 1.25rem 0 1.875rem">
        <Info size={20.83} color="#D0D6DE" />
      </Flex>
      <Flex min="24.125rem">
        <Body>{description}</Body>
      </Flex>
      <Flex flex={1} />
      <Flex min="6.25rem">
        <Body>{label}</Body>
      </Flex>
      <Flex padding="0 1.25rem 0 1.875rem">
        <ChevronRight size={16} color={Theme.colors.grey_5} />
      </Flex>
    </Wrapper>
  );
};
