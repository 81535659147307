import {each} from 'lodash';
import {PERMISSIONS, ROUTE} from 'app/constants';

const getAdminPrimaryMenuRoutes = adminPermissions => {
  const routes = [];
  each(adminPermissions, permission => {
    switch (permission) {
      case PERMISSIONS.READ_USERS:
        routes.push(ROUTE.ADMIN_USERS);
        break;
      case PERMISSIONS.READ_FIRMS:
        routes.push(ROUTE.ADMIN_FIRMS);
        break;
      case PERMISSIONS.READ_COMPANIES:
        routes.push(ROUTE.ADMIN_SECURITY_LISTING);
        break;
      case PERMISSIONS.READ_HARMONY_DATA:
        routes.push(ROUTE.ADMIN_HARMONY_UPLOADS);
        break;
      case PERMISSIONS.READ_CLIENT_HOLDINGS:
        routes.push(ROUTE.ADMIN_CLIENT_HOLDINGS_UPLOAD);
        break;
      case PERMISSIONS.READ_REFERENCE_INDEXES:
        routes.push(ROUTE.ADMIN_MARKET_INDEXES);
        break;
      case PERMISSIONS.READ_SITE_SETTINGS:
        routes.push(ROUTE.ADMIN_SITE_SETTINGS);
        break;
      case PERMISSIONS.READ_ADMIN_PERMISSIONS:
        routes.push(ROUTE.ADMIN_EMPLOYEES);
        break;
      default:
        break;
    }
  });
  routes.push(ROUTE.V2_RESOURCE_CENTER);
  return routes;
};

export {getAdminPrimaryMenuRoutes};
