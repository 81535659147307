import {format} from 'date-fns';
import {FC} from 'react';
import {useParams} from 'react-router';
import {FlexContainer} from 'v2/components//atoms/Containers';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useManualEditCompanyIssues} from 'v2/hooks/useManualEditCompanyIssues';
import {Rubrica} from '../atoms/Typeface';

export const LastUpdatedBy: FC<{fields?: string[]; issueId?: number; year?: number}> = ({fields, issueId, year}) => {
  const {companyId} = useParams<{companyId: string}>();

  const {data, isLoading, lastUpdatedBy} = useManualEditCompanyIssues({
    companyId: +companyId,
    fields,
    issueId,
    year,
  });

  return !isLoading && !!data.length ? (
    <FlexContainer
      style={{
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Rubrica>Last Updated by:</Rubrica>
      <FlexContainer style={{gap: 16}}>
        <Rubrica> {lastUpdatedBy?.name || DATA_STATUS.NONE}</Rubrica>
        <Rubrica>{format(new Date(lastUpdatedBy?.date), 'PP')}</Rubrica>
      </FlexContainer>
    </FlexContainer>
  ) : null;
};
