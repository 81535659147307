import styled from 'styled-components/macro';
import {ParagraphS} from './Typeface';
import {Theme} from './theme';

export const Badge = styled(ParagraphS).attrs(({color = Theme.colors.harmony}) => ({
  color: color,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  border-radius: 9999px;
  padding: 0 0.625rem;
  border: ${({theme, color}) => `${color ?? theme.colors.harmony} 0.125rem solid`};
`;
