import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const PLAN_USER_ROLE = {
  SUPPORT: 'Support',
  ADMIN: 'Admin',
};

export const PLAN_USER_ROLES = map(PLAN_USER_ROLE, value => value);

export default PLAN_USER_ROLE;
