import React from 'react';
import styled from 'styled-components/macro';
import {Field, Form} from 'react-final-form';
import {animateScroll} from 'react-scroll';
import {push} from 'connected-react-router';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faPlusCircle from 'app/fontawesome-pro-light/faPlusCircle';
import faTimes from 'app/fontawesome-pro-light/faTimes';
import {FieldArray} from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import {CardContainer, Container, FlexContainer} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {ParagraphM, TitleM} from 'v2/components/atoms/Typeface';
import {NumberedPoint} from 'v2/components/molecules/NumberedPoint';
import {Checkbox} from 'v2/components/molecules/Checkbox';
import {HarmonyButton} from 'v2/components/molecules/Button';
import {TextInput} from 'v2/components/molecules/TextInput';

import CDP_Association from 'v2/assets/images/esg_associations/CDP_Association.svg';
import CDSB_Association from 'v2/assets/images/esg_associations/CDSB_Association.svg';
import GRI_Association from 'v2/assets/images/esg_associations/GRI_Association.svg';
import IR_Association from 'v2/assets/images/esg_associations/IR_Association.svg';
import PRI_Association from 'v2/assets/images/esg_associations/PRI_Association.svg';
import SASB_Association from 'v2/assets/images/esg_associations/SASB_Association.svg';
import SDG_Association from 'v2/assets/images/esg_associations/SDG_Association.svg';
import TCFD_Association from 'v2/assets/images/esg_associations/TCFD_Association.svg';
import UGC_Association from 'v2/assets/images/esg_associations/UGC_Association.svg';
import {OnboardingFooter} from 'v2/components/organisms/OnboardingFooter';
import {useHistory} from 'react-router';
import {ROUTE} from 'v2/constants/routes';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {FORM_ERROR} from 'final-form';
import {get, pick} from 'lodash';
import {useDispatch} from 'react-redux';
import AutoSave from './AutoSave';
import {FormFeedback} from 'app/components';
import {errorRequiredFields, isImpersonating} from 'app/utilities';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {useUpdateFirmInvitationMutation} from 'v2/redux/harmonyOnboardingApi';
import {handleUpdateForm} from './handleUpdateForm';
import {SpinnerDisplay} from 'app/components/Spinner/SpinnerDisplay';
import {ONBOARDING_STEPS} from 'app/constants';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 8rem;
`;

const CheckContainer = styled(FlexContainer)`
  align-items: center;
  gap: 1.1875rem;
  padding-right: 1.5rem;
  height: 100%;
  border-bottom: 2px solid ${({theme}) => theme.colors.grey_3};
`;

const Button = styled.button`
  width: 44px;
  height: 44px;
  background-color: ${({theme}) => theme.colors.grey_2};
  border: 0;
  border-radius: ${({theme}) => theme.constants.border_radius};
`;

const Spacer = styled.div`
  border-bottom: 2px solid ${({theme}) => theme.colors.grey_3};
  grid-column: 3 / 8;
`;

interface ImageCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  alt?: string;
  img?: any;
}

const ImageCheckbox = React.forwardRef<HTMLInputElement, ImageCheckboxProps>(({alt, img, id, ...rest}, ref) => {
  return (
    <Field name={id ?? ''} type="checkbox" {...rest}>
      {props => (
        <CheckContainer as="label" htmlFor={id}>
          <Checkbox {...props.input} />
          <Container>
            <img id={id} alt={alt ?? ''} src={img} />
          </Container>
        </CheckContainer>
      )}
    </Field>
  );
});

export const UT3ESGOnboardingAssociations = () => {
  const dispatch = useDispatch();
  const {onboardingUser, invitationCode, requestedStep, onboardingSteps, isLoading} = useOnboardingUser();
  const [updateInvitationFirm] = useUpdateFirmInvitationMutation();
  const [autoSaveError, setAutoSaveError] = React.useState<string | null>();

  const history = useHistory();

  const onboardingStep = get(requestedStep, 'step', '');
  const overallStep = ONBOARDING_STEPS.UserType3.MANAGER.key;
  const nextRoute = get(onboardingSteps, 'FINALIZE.route.path', '').replace(':invitationCode', invitationCode);

  const initialValues = () => {
    const user = get(onboardingUser, 'user.firm', {});
    const values = pick(user, [
      'hasPriAssociation',
      'hasGriAssociation',
      'hasSasbAssociation',
      'hasCdpAssociation',
      'hasTcfdAssociation',
      'hasSdgAssociation',
      'hasUgcAssociation',
      'hasCdspAssociation',
      'hasPriAssociation',
      'hasIrAssociation',
      'associations',
    ]);

    return values;
  };

  const handleSubmitCallback = React.useCallback(
    async (values: any) => {
      try {
        await handleUpdateForm({
          handleUpdate: updateInvitationFirm,
          invitationCode,
          onboardingStep: overallStep,
          values,
        });

        const res = await handleUpdateForm({
          handleUpdate: updateInvitationFirm,
          invitationCode,
          onboardingStep,
          values,
        });
        if (!res?.[FORM_ERROR]) {
          animateScroll.scrollToTop();
          dispatch(push(nextRoute));
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    [nextRoute, onboardingStep, invitationCode],
  );

  const validate = (values: any) => {
    const errors: any = errorRequiredFields(values, []);

    return errors;
  };

  const handleExit = () => {
    return isImpersonating() ? dispatch(removeImpersonationTokensUser()) : dispatch(logout());
  };

  const handleGoBack = () => {
    history.push(ROUTE.UT3.ONBOARDING.ESG_INTEGRATION.path.replace(':invitationCode', invitationCode));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitCallback}
      mutators={{...arrayMutators}}
      validate={validate}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        error,
        errors,
        invalid,
        submitFailed,
        submitError,
        form: {
          mutators: {push, remove},
          submit,
        },
        values,
        dirty,
      }) => {
        const isErrorFormFeedbackVisible = !!error || !!autoSaveError || (!!submitFailed && invalid);

        return (
          <form method="POST" onSubmit={handleSubmit}>
            <AutoSave
              debounce={1000}
              disabled={!dirty}
              onSave={async values => {
                const res = await handleUpdateForm({
                  handleUpdate: updateInvitationFirm,
                  invitationCode,
                  onboardingStep,
                  values,
                });
                if (res?.[FORM_ERROR]) {
                  setAutoSaveError(res[FORM_ERROR]);
                } else {
                  setAutoSaveError(null);
                }
              }}
              values={values}
              errors={errors}
            />
            <FlexContainer flex={1} direction="column" fullWidth>
              <CardContainer>
                <FlexContainer flex={1} direction="column" fullWidth>
                  <Container padding="0 0 2.75rem">
                    <TitleM color={Theme.colors.dark}>ESG Associations</TitleM>
                  </Container>
                  {!!submitError && (
                    <Container padding="0 0 0">
                      <FormFeedback message={submitError} visible={isErrorFormFeedbackVisible} children={undefined} />
                    </Container>
                  )}
                  <FlexContainer flex={1} items="center" gap="1.4375rem" fullWidth>
                    <NumberedPoint number={1} />
                    <ParagraphM>Select the ESG associations that your firm is already registered with.</ParagraphM>
                  </FlexContainer>
                  <FlexContainer direction="column" flex={1} fullWidth>
                    <GridContainer>
                      <ImageCheckbox id="hasGriAssociation" img={GRI_Association} alt="GRI Association" />
                      <ImageCheckbox id="hasSasbAssociation" img={SASB_Association} alt="SASB Association" />
                      <ImageCheckbox id="hasCdpAssociation" img={CDP_Association} alt="CDP Association" />
                      <ImageCheckbox id="hasTcfdAssociation" img={TCFD_Association} alt="TCFD Association" />
                      <ImageCheckbox id="hasSdgAssociation" img={SDG_Association} alt="TCFD Association" />
                      <ImageCheckbox id="hasUgcAssociation" img={UGC_Association} alt="UGC Association" />
                      <ImageCheckbox id="hasCdspAssociation" img={CDSB_Association} alt="CDSP Association" />
                      <ImageCheckbox id="hasPriAssociation" img={PRI_Association} alt="PRI Association" />
                      <ImageCheckbox id="hasIrAssociation" img={IR_Association} alt="IR Association" />
                      <Spacer />
                    </GridContainer>
                    <Container padding="2.9375rem 0.25rem">
                      <FieldArray
                        name="associations"
                        render={({fields}) => {
                          return (
                            <FlexContainer gap="1.875rem" padding="0 0 1.875rem" direction="column">
                              {fields.map((field, i) => {
                                return (
                                  <FlexContainer gap="1rem" key={i}>
                                    <Field name={field}>
                                      {props => (
                                        <>
                                          <TextInput {...props.input} />
                                          <Button type="button" onClick={() => remove('associations', i)}>
                                            <Icon
                                              // @ts-ignore
                                              icon={faTimes}
                                            />
                                          </Button>
                                        </>
                                      )}
                                    </Field>
                                  </FlexContainer>
                                );
                              })}
                            </FlexContainer>
                          );
                        }}
                      />
                      <HarmonyButton
                        type="button"
                        onClick={() => push('associations', undefined)}
                        variant="light"
                        width="14rem"
                      >
                        <FlexContainer items="center" gap="1rem" as="span">
                          <Icon
                            style={{paddingTop: 2}}
                            // @ts-ignore
                            icon={faPlusCircle}
                          />
                          <span>Add other associations</span>
                        </FlexContainer>
                      </HarmonyButton>
                    </Container>
                  </FlexContainer>
                </FlexContainer>
              </CardContainer>
              <OnboardingFooter
                backLabel="Return to ESG Integration"
                onSave={() => submit()?.then(() => handleExit())}
                onContinue={() => submit()}
                onBack={handleGoBack}
              />
            </FlexContainer>
            <SpinnerDisplay isActive={isLoading} />
          </form>
        );
      }}
    </Form>
  );
};
