import {get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {change, SubmissionError, untouch} from 'redux-form';

// Local Imports
import {AdminUploadHarmonyDataForm} from 'app/components';
import {createStagingUpload, getUploads, saveUploads} from 'app/redux/adminUploadHarmonyData';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {toastSuccess} from 'app/utilities/toast';

class AdminUploadHarmonyDataPage extends Component {
  componentDidMount() {
    this.props.dispatch(getUploads());
  }

  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <h1 className="mb-4">{ROUTE.ADMIN_HARMONY_UPLOADS.title}</h1>
          <AdminUploadHarmonyDataForm {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    securityDataAndRatingUploads: get(state, 'adminUploadHarmonyData.uploads.data', []),
    state,
    isCreatingStagingUpload: get(state, 'adminUploadHarmonyData.stagingUpload.isCreatingStagingUpload'),
    hasStagingUploadError: get(state, 'adminUploadHarmonyData.stagingUpload.hasStagingUploadError'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createStagingUpload: fileKey => dispatch(createStagingUpload(fileKey)),
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      return dispatch(saveUploads(values.date, values.harmonyDataUpload[0].name))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }
          if (get(response, 'payload.isProcessing')) {
            toastSuccess('Harmony Data has been uploaded and will continue to process in the background.');
          } else {
            toastSuccess('Harmony Data has been uploaded and processed.');
          }

          dispatch(change('upload-harmony-data', 'date', ''));
          dispatch(untouch('upload-harmony-data', 'date'));
          dispatch(change('upload-harmony-data', 'harmonyDataUpload', 'EMPTY'));
          dispatch(untouch('upload-harmony-data', 'harmonyDataUpload'));

          animateScroll.scrollToTop();
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message === 'Invalid CSV File') {
            throw new SubmissionError({_error: `Upload has failed: ${error.message}`});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
  };
};

export const AdminUploadHarmonyData = compose(connect(mapStateToProps, mapDispatchToProps))(AdminUploadHarmonyDataPage);
