import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {map} from 'lodash';

import {getIssueDataAnalysis} from 'app/utilities';

const BooleanLegend = ({data, iconic}) => {
  const issueDataAnalysis = getIssueDataAnalysis(data);

  // Prepare color sets for labels
  const classNames =
    data.length < 3
      ? [issueDataAnalysis.isWorrisome ? 'text-danger' : 'text-success', 'text-light-gray']
      : [issueDataAnalysis.isWorrisome ? 'text-danger' : 'text-success', 'text-navy', 'text-light-gray'];

  return (
    <div className="boolean-legend">
      {map(data, ({label, yesCount, noCount}, index) => {
        let yesPercent = 0;
        let noPercent = 100;
        const total = yesCount + noCount;
        if (total > 0) {
          yesPercent = Math.round((100 * yesCount) / total);
          noPercent = Math.round((100 * noCount) / total);
        }
        const values = (
          <Fragment>
            <span className={cn('boolean-legend-value', classNames[index])}>{yesPercent}%</span> Yes{' '}
            <span className="boolean-legend-value text-lighter-gray">{noPercent}%</span> No
          </Fragment>
        );

        if (index === 0) {
          if (iconic) {
            return (
              <div key={index} className="boolean-legend-item w-100 mb-6 mt-4">
                {noCount === 0 ? 'YES' : 'NO'}
              </div>
            );
          }
          if (data.length > 2) {
            return (
              <div key={index} className="boolean-legend-item w-100 mb-2">
                {values}
              </div>
            );
          }
        }

        return (
          <div key={index} className="boolean-legend-item">
            <div className="boolean-legend-label">{label}</div>
            {values}
          </div>
        );
      })}
    </div>
  );
};

BooleanLegend.propTypes = {
  data: PropTypes.array.isRequired,
  iconic: PropTypes.bool,
};

export {BooleanLegend};
