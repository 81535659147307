/* eslint-disable no-param-reassign */
import {createAction} from 'redux-actions';
import {clone, each, find} from 'lodash';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
  unreadCounts: [],
};

// ACTION
export const ACTION = {
  GET_ALL_REQUEST: 'USER_STRATEGY_ALERT_GET_ALL_REQUEST',
  GET_ALL_RECEIVE: 'USER_STRATEGY_ALERT_GET_ALL_RECEIVE',
  GET_UNREAD_COUNTS_REQUEST: 'USER_STRATEGY_ALERT_GET_UNREAD_COUNTS_REQUEST',
  GET_UNREAD_COUNTS_RECEIVE: 'USER_STRATEGY_ALERT_GET_UNREAD_COUNTS_RECEIVE',
  UPDATE_REQUEST: 'USER_STRATEGY_ALERT_UPDATE_REQUEST',
  UPDATE_RECEIVE: 'USER_STRATEGY_ALERT_UPDATE_RECEIVE',
};

const getAllRequest = createAction(ACTION.GET_ALL_REQUEST);
const getAllReceive = createAction(ACTION.GET_ALL_RECEIVE);
const getUnreadCountRequest = createAction(ACTION.GET_UNREAD_COUNTS_REQUEST);
const getUnreadCountReceive = createAction(ACTION.GET_UNREAD_COUNTS_RECEIVE);
const updateRequest = createAction(ACTION.UPDATE_REQUEST);
const updateReceive = createAction(ACTION.UPDATE_RECEIVE);

const api = {
  getAll: () => {
    return fetchAuthJSON('user/strategyAlerts', {method: 'get'});
  },
  getUnreadCount: () => {
    return fetchAuthJSON('user/strategyAlertsUnreadCount', {method: 'get'});
  },
  update: (id, updates) => {
    return fetchAuthJSON(`user/strategyAlerts/${id}`, {
      method: 'put',
      body: JSON.stringify(updates),
    });
  },
};

export function getAll() {
  return dispatch => {
    dispatch(getAllRequest());
    return dispatch(getAllReceive(api.getAll()));
  };
}

export function getUnreadCount() {
  return dispatch => {
    dispatch(getUnreadCountRequest());
    return dispatch(getUnreadCountReceive(api.getUnreadCount()));
  };
}

export function update(id, updates) {
  return dispatch => {
    dispatch(updateRequest());
    return dispatch(updateReceive(api.update(id, updates)));
  };
}

const mutateUnreadCounts = (unreadCounts, item, change) => {
  let unreadCount = find(unreadCounts, {strategyId: item.strategyId});
  if (!unreadCount) {
    unreadCount = {
      strategyId: item.strategyId,
      count: 0,
    };
    unreadCounts.push(unreadCount);
  }
  unreadCount.count += change;
};

const getCalculatedUnreadCounts = items => {
  const result = [];
  each(items, item => {
    if (!item.isViewed) {
      mutateUnreadCounts(result, item, 1);
    }
  });
  return result;
};

// REDUCER
export const userStrategyAlert = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_ALL_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_ALL_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                items: payload,
                // mutate state (!)
                unreadCounts: getCalculatedUnreadCounts(payload),
              },
        ),
      );
    case ACTION.GET_UNREAD_COUNTS_RECEIVE:
      return assignMergedState(hasError ? {error: payload} : {unreadCounts: payload});
    case ACTION.UPDATE_RECEIVE:
      if (!hasError && payload) {
        // mutate state with updates (!)
        Object.assign(
          find(state.items, item => {
            return Number(item.id) === Number(payload.id);
          }),
          payload,
        );
      }
      return assignMergedState(
        hasError
          ? {error: payload}
          : {
              items: clone(state.items), // a new prop is needed to trigger a new state
              unreadCounts: getCalculatedUnreadCounts(state.items),
            },
      );
    default:
      return state;
  }
};
