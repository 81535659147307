import React, {Component} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import {getDateStamps} from 'app/utilities/getReportDateStamps';

export class PageFooter extends Component {
  render() {
    const {date, year, quarter} = getDateStamps();
    const {pageNumber} = this.props;
    const page = pageNumber.toString();
    return (
      <div className="report-page-footer d-flex justify-content-between align-items-center">
        <div className="icon-container d-flex justify-content-between">
          <Icon icon={faSeedling} className="icon" />
          <Icon icon={faHandHeart} className="icon" />
          <Icon icon={faLandmark} className="icon" />
        </div>
        <div className="text-uppercase">
          <span>Progress Report / </span>
          <span className="report-page-quarter mr-8">{`Q${quarter} ${year}`}</span>
          <span>{page.length < 2 ? page.padStart(2, '0') : page}</span>
        </div>
      </div>
    );
  }
}

PageFooter.defaultProps = {
  pageNumber: 0,
};
