import {ROUTE as OLD_ROUTE} from 'app/constants';
import {format} from 'date-fns';
import {pick} from 'lodash';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {CardContainer, Container, FlexContainer} from 'v2/components/atoms/Containers';
import {Overlay} from 'v2/components/atoms/Overlay';
import {TitleM} from 'v2/components/atoms/Typeface';
import {EnvironmentHand} from 'v2/components/atoms/icons';
import {Theme} from 'v2/components/atoms/theme';
import {AdminCautionCard} from 'v2/components/molecules/AdminCautionCard';
import {ReturnButton} from 'v2/components/molecules/ReturnButton';
import {AdminCarbonNeutralPledgeCard} from 'v2/components/organisms/AdminCarbonNuetralPledgeCard';
import {AdminCompanyInfo} from 'v2/components/organisms/AdminCompanyInfo';
import {AdminEmailCard} from 'v2/components/organisms/AdminEmailCard';
import {AdminIssueCard} from 'v2/components/organisms/AdminIssueCard';
import {AdminIssuePolicyCard} from 'v2/components/organisms/AdminIssuePolicyCard';
import {IssueMetricTable} from 'v2/components/organisms/IssueMetricTable';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {CARBON_NEUTRAL_PLEDGE_STATUS} from 'v2/constants/issueOptions';
import {ISSUE_TYPES} from 'v2/constants/issueTypes';
import {ISSUES} from 'v2/constants/issues';
import {useAdminCompanyIssues} from 'v2/hooks/useAdminCompanyIssues';
import {useIssueTableData} from 'v2/hooks/useIssueTableData';
import {useUpdateManualEditCompanyIssueMutation} from 'v2/redux/typeormEndpoints';
import {isFixedUnit} from 'v2/utilities/helpers/unitFilters';

export const AdminEditSecurityPage = () => {
  const history = useHistory();
  const {companyId} = useParams<{companyId: string}>();

  const {data: environmentalData} = useIssueTableData({
    title: 'Environmental',
    companyId,
    category: 'ENVIRONMENTAL',
    icon: EnvironmentHand,
    color: Theme.colors.success,
  });

  const {data: socialData} = useIssueTableData({
    title: 'Social',
    companyId,
    category: 'SOCIAL',
    icon: EnvironmentHand,
    color: Theme.colors.blue_2,
  });

  const {data: governanceData} = useIssueTableData({
    title: 'Governance',
    companyId,
    category: 'GOVERNANCE',
    icon: EnvironmentHand,
    color: Theme.colors.harmony,
  });

  const {data: company} = useAdminCompanyIssues({companyId: Number(companyId)});

  const initialState = {
    showOverlay: false,
    editEmail: false,
    editPledge: false,
    editIssue: false,
    editPolicy: false,
    confirmAction: false,
  };

  const [state, updateState] = useState(initialState);
  const [selectedIssue, setSelectedIssue] = useState<any>(null);
  const [updateIssue] = useUpdateManualEditCompanyIssueMutation();

  const setState = (values: any) => {
    return updateState(prevState => ({
      ...prevState,
      ...values,
    }));
  };

  const handleOpenEditPledge = (values: any) => {
    setSelectedIssue(values);
    return setState({
      showOverlay: true,
      editPledge: true,
    });
  };

  const handleOpenEditEmail = () => {
    return setState({
      showOverlay: true,
      editEmail: true,
    });
  };

  const handleOpenIssueClick = (values: any) => {
    const issueType = values?.issue?.issueType;
    const isCarbonPolicy = values?.issueId === ISSUES.CARBON_NEUTRAL_PLEDGE.id;
    setSelectedIssue(values);
    if (issueType === ISSUE_TYPES.BOOLEAN) {
      if (!isCarbonPolicy) {
        return setState({
          showOverlay: true,
          editPolicy: true,
        });
      } else {
        return setState({
          showOverlay: true,
          editPledge: true,
        });
      }
    } else if (issueType === ISSUE_TYPES.NUMERIC) {
      return setState({
        showOverlay: true,
        editIssue: true,
      });
    }
  };

  const handleAcceptAction = () => {
    return setState({
      confirmAction: true,
    });
  };

  const handleClose = () => {
    setState(initialState);
    setSelectedIssue(null);
  };

  const userEmail = useSelector((state: any) => state.auth.email);

  const handleSubmitIssue = async (values: any) => {
    const {hasPolicy, ...rest} = values;
    const body = {companyId, ...rest, userEmail};

    const res = await updateIssue(body).unwrap();
    handleClose();
    return res;
  };

  const handleSubmitPolicy = async (values: any) => {
    const {hasPolicy, ...rest} = values;
    const body = {companyId, value: Number(hasPolicy), ...rest, userEmail};

    const res = await updateIssue(body).unwrap();
    handleClose();
    return res;
  };

  const handleSubmitEmail = async (values: any) => {
    const contactEmail = values.email;
    const res = await handleSubmitIssue({...values, contactEmail});
    return res;
  };

  const handleSubmitPledge = async (values: any) => {
    const res = await handleSubmitIssue(values);
    return res;
  };

  const cautionCardProps = {
    title: 'You are about to edit Production Data',
    description:
      'Changes are not reversible. Your changes will be associated with your Harmony Account and current date.',
  };

  const initialValues = pick(selectedIssue, [
    'carbonNeutralPledgePlan',
    'carbonNeutralPledgeTargetYear',
    'carbonNeutralPledgeStatus',
    'carbonNeutralPledgeSigned',
    'carbonNeutralSourceName',
    'companyId',
    'contactEmail',
    'confidenceLevel',
    'issueId',
    'sourceLink',
    'sourceType',
    'unit',
    'value',
    'year',
  ]);

  const carbonNeutralPledgeSigned = company?.carbonNeutralPledgeSigned
    ? new Date(company?.carbonNeutralPledgeSigned)
    : null;

  const ceoValues = {
    prefix: company?.ceoPrefix,
    firstName: company?.ceoFirstName,
    lastName: company?.ceoLastName,
    suffix: company?.ceoSuffix,
  };

  const pledgeStatus = company?.carbonNeutralPledgeSbtiStatus ?? CARBON_NEUTRAL_PLEDGE_STATUS.NOT_COMMITTED;

  const carbonNeutralPledgeValues = {
    pledgeStatus,
    targetYear: company?.carbonNeutralPledgeTargetYear,
    dateSigned: company?.carbonNeutralPledgeSigned
      ? format(new Date(company?.carbonNeutralPledgeSigned), 'PP')
      : DATA_STATUS.NONE,
  };

  const carbonNeutralInitialValues = {
    carbonNeutralPledgePlan: company?.carbonNeutralPledgePlan ?? '',
    carbonNeutralPledgeTargetYear: company?.carbonNeutralPledgeTargetYear ?? '',
    carbonNeutralPledgeStatus: company?.carbonNeutralPledgeStatus ?? CARBON_NEUTRAL_PLEDGE_STATUS.NOT_COMMITTED,
    carbonNeutralSourceName: company?.carbonNeutralSourceName ?? '',
    carbonNeutralPledgeSigned: carbonNeutralPledgeSigned,
  };

  return (
    <>
      <FlexContainer margin="0 6.25rem" padding="0 0 4.5rem" direction="column" id="issue_container" fullWidth>
        <Container padding="0.625rem 2rem">
          <ReturnButton
            label="Return to Companies"
            onClick={() => history.push(OLD_ROUTE.ADMIN_SECURITY_LISTING.path())}
          />
        </Container>
        <CardContainer padding="4.125rem 3.5rem">
          <Container>
            <TitleM>{`Edit "${company?.name ?? DATA_STATUS.NONE}"`}</TitleM>
          </Container>
          <Container padding="3.75rem 0 8.125rem">
            <AdminCompanyInfo
              description={company?.description}
              country={company?.country?.name}
              email={company?.contactEmail}
              ceo={ceoValues}
              carbonNeutralPledge={carbonNeutralPledgeValues}
              editPledge={handleOpenEditPledge}
              editEmail={handleOpenEditEmail}
            />
          </Container>
          <Container>
            <TitleM>Issue Metrics</TitleM>
          </Container>
          <Container padding="1.5rem 0 3.125rem">
            <IssueMetricTable {...environmentalData} onItemClick={handleOpenIssueClick} />
            <IssueMetricTable {...socialData} onItemClick={handleOpenIssueClick} />
            <IssueMetricTable {...governanceData} onItemClick={handleOpenIssueClick} />
          </Container>
        </CardContainer>
      </FlexContainer>
      <Overlay visible={state.showOverlay} items="center" justify="center" fullWidth>
        {!state.confirmAction ? (
          <AdminCautionCard
            {...cautionCardProps}
            onOutsideClick={handleClose}
            onClose={handleClose}
            onAccept={handleAcceptAction}
          />
        ) : (
          <>
            {state.editEmail && (
              <AdminEmailCard
                onOutsideClick={handleClose}
                onClose={handleClose}
                onSubmit={handleSubmitEmail}
                initialValues={{email: company?.contactEmail}}
                name={company?.name}
                companyId={+companyId}
                issueId={1}
              />
            )}
            {state.editPledge && (
              <AdminCarbonNeutralPledgeCard
                name={company?.name}
                initialValues={carbonNeutralInitialValues}
                companyId={+companyId}
                onOutsideClick={handleClose}
                onClose={handleClose}
                onSubmit={handleSubmitPledge}
                issueId={1}
              />
            )}
            {state.editIssue && (
              <AdminIssueCard
                companyName={company?.name}
                issueName={selectedIssue?.label}
                issueId={selectedIssue?.issueId}
                year={selectedIssue?.year}
                unit={selectedIssue?.unit}
                initialValues={{
                  ...initialValues,
                  value: selectedIssue?.value?.value ?? selectedIssue?.value,
                }}
                isFixed={isFixedUnit(selectedIssue?.unit)}
                onSubmit={handleSubmitIssue}
                onOutsideClick={handleClose}
                onClose={handleClose}
                companyId={+companyId}
              />
            )}
            {state.editPolicy && (
              <AdminIssuePolicyCard
                companyName={company?.name}
                companyId={+companyId}
                issueName={selectedIssue?.label}
                year={selectedIssue?.year}
                issueId={selectedIssue?.issueId}
                initialValues={{
                  ...initialValues,
                  value: initialValues.value?.value ?? initialValues.value,
                }}
                onSubmit={handleSubmitPolicy}
                onOutsideClick={handleClose}
                onClose={handleClose}
              />
            )}
          </>
        )}
      </Overlay>
      <ReactTooltip
        className="custom-form-tooltip"
        id="form-tooltip"
        type="light"
        effect="solid"
        arrowColor="transparent"
      />
      <ReactTooltip
        className="custom-table-tooltip"
        id="table-tooltip"
        type="light"
        effect="solid"
        arrowColor="transparent"
        place="right"
      />
    </>
  );
};
