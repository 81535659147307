import {assignWith, get, isEmpty, pick} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm} from 'redux-form';
import {Link} from 'react-router-dom';

// Local Imports
import {Button, FormFeedback} from 'app/components';
import {SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {REGEX_PATTERN, ROLE, ROUTE} from 'app/constants';
import {USER_ROLES} from 'app/constants/role';
import {errorRequiredFields, getEmailPrimaryDomain, normalizeEmail} from 'app/utilities';

const validate = (values, ownProps) => {
  const errors = errorRequiredFields(values, ['email', 'confirmEmail', 'userType']);

  if (!errors.email && !REGEX_PATTERN.VALID_EMAIL.test(values.email)) {
    errors.email = 'Invalid email address';
  } else if (getEmailPrimaryDomain(values.email) !== ownProps.allowedEmailPrimaryDomain) {
    errors.email = `Email address must end with "${ownProps.allowedEmailPrimaryDomain}"`;
  }

  if (values.confirmEmail !== values.email) {
    errors.confirmEmail = 'Must match Email Address';
  }

  return errors;
};

class SettingsUserManagerFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);
    const disableEmail = !!get(this.props, 'userId');
    const disableRole = get(this.props, 'userId') && get(this.props, 'editorRole') === ROLE.USER_TYPE_2;

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <Field
            label="Email Address*"
            name="email"
            type="text"
            autoComplete="email"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3"
            disabled={disableEmail}
          />

          <Field
            label="Confirm Email Address*"
            name="confirmEmail"
            type="text"
            autoComplete="email"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3"
            disabled={disableEmail}
          />

          <Field
            label="Role*"
            name="userType"
            shouldInsertDefault={true}
            component={SelectFormGroup}
            options={USER_ROLES}
            className="col-3"
            disabled={disableRole}
          />
        </div>

        <div className="form-footer is-right mt-12">
          <Link to={ROUTE.SETTINGS_USERS.path()} className="btn btn-outline">
            Cancel
          </Link>
          <Button type="submit" solid>
            {this.props.userId ? 'Save User' : 'Save User & Send Invite'}
          </Button>
        </div>
      </form>
    );
  }
}

SettingsUserManagerFormComponent.propTypes = {
  editUser: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {editUser = {}, editorEmail} = ownProps;

  const defaultValues = {};

  const allowedEmailPrimaryDomain = getEmailPrimaryDomain(editorEmail);

  // Pick which values will pre-populate the form.
  const userValues = pick(editUser, ['email', 'roles']);

  if (userValues.email) {
    userValues.confirmEmail = userValues.email;
  }
  if (!isEmpty(userValues.roles)) {
    [userValues.userType] = userValues.roles;
    delete userValues.roles;
  }

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    allowedEmailPrimaryDomain,
    initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
  };
};

export const SettingsUserManagerForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'settings-user-edit',
    validate,
  }),
)(SettingsUserManagerFormComponent);
