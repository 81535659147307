import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

// Local Imports
import {errorRequiredFields} from 'app/utilities';
import {Button, FormFeedback} from '.';
import {TextFormGroup} from './forms';

const validate = values => {
  const errors = errorRequiredFields(values, ['password', 'confirmPassword']);

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Must match Password';
  }

  if (values.password && values.password.length < 8) {
    errors.password = (
      <span>
        <strong>Password Strength:</strong> Too Short
      </span>
    );
  }

  return errors;
};

class ResetPasswordFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <p>Please choose a new password, then type it again to confirm before saving.</p>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div style={{maxWidth: 300}}>
          <Field
            label="Password*"
            name="password"
            autoComplete="current-password"
            type="password"
            component={TextFormGroup}
          />

          <Field label="Confirm Password*" name="confirmPassword" type="password" component={TextFormGroup} />
        </div>

        <div className="form-footer">
          <Button type="submit">Save New Password</Button>
        </div>
      </form>
    );
  }
}

ResetPasswordFormComponent.propTypes = {handleSubmit: PropTypes.func};

const mapStateToProps = (state, ownProps) => {
  return {form: ownProps.formName};
};

const mapDispatchToProps = dispatch => ({dispatch});

export const ResetPasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({validate})(ResetPasswordFormComponent));
