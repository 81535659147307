import React from 'react';
import styled from 'styled-components/macro';
import {Container, FlexContainer} from '../atoms/Containers';

import {
  OnboardingProgresController as OnboardingProgresControllerProps,
  OnboardingStep,
  StepBubble as StepBubbleProps,
} from 'v2/utilities/types/components/OnboardingProgressController';
import {SubtitleM} from '../atoms/Typeface';

const StepsContainer = styled(FlexContainer).attrs({as: 'ul'})`
  min-width: 16rem;
  padding: 0;
  margin: 0;
`;

const StepBubbleContainer = styled.a<React.AnchorHTMLAttributes<HTMLAnchorElement> & StepBubbleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.0625rem;
  height: 2.0625rem;
  background-color: ${({theme}) => theme.colors.harmony};
  border-radius: 100%;

  ${SubtitleM} {
    color: #fff;
  }
`;

const StepLabel = styled(SubtitleM)``;

const StepContainer = styled(FlexContainer).attrs({
  as: 'li',
})<StepBubbleProps & {mini?: boolean}>`
  position: relative;

  ${StepBubbleContainer} {
    opacity: ${({isActive, isSelected}) => (isSelected || isActive ? 1 : 0.3)};
    cursor: ${({isActive}) => (isActive ? 'pointer' : 'default')};
  }

  ${({isSelected, theme}) => {
    if (isSelected) {
      return `${StepBubbleContainer} {
        background-color: transparent;
        border: 1px solid ${theme.colors.harmony};

        ${SubtitleM} {
          color: ${theme.colors.harmony};
        }
      }`;
    }
  }}

  ${({mini}) => {
    if (mini) {
      return `${StepBubbleContainer} {
                width: 0.5rem;
                height: 0.5rem;
                margin: 0 0.75rem 0;
            }`;
    }
  }}

  ${StepLabel} {
    position: relative;
    left: 1.3125rem;
    opacity: ${({isActive, isSelected}) => (isSelected || isActive ? 1 : 0.3)};
  }
`;

const StepDotContainer = styled(StepBubbleContainer)`
  height: 0.5rem;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4375rem 0;
  width: 2.0625rem;

  &:after {
    content: '';
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.grey_4};
    height: 1.75rem;
  }
`;

const StepBubble = ({index, ...rest}: React.ComponentProps<typeof StepBubbleContainer>) => (
  <StepBubbleContainer {...rest}>
    <SubtitleM>{index}</SubtitleM>
  </StepBubbleContainer>
);

const StepDot = ({...rest}: React.ComponentProps<typeof StepBubbleContainer>) => <StepDotContainer {...rest} />;

export const OnboardingProgressController = ({steps = [], onStepClick}: OnboardingProgresControllerProps) => {
  const handleStepClick = (step: OnboardingStep) => {
    return step.isActive ? onStepClick?.(step) : () => null;
  };

  return (
    <Container>
      <StepsContainer direction="column">
        {steps.map((step, i) => {
          return (
            <>
              <StepContainer
                key={step.key}
                items="center"
                isSelected={step.isSelected}
                isActive={step.isActive}
                mini={step.subItem}
              >
                {step.subItem ? (
                  <StepDot index={step.key} onClick={() => handleStepClick(step)} />
                ) : (
                  <StepBubble index={step.key} onClick={() => handleStepClick(step)} />
                )}
                <StepLabel
                  onClick={() => handleStepClick(step)}
                  style={{cursor: step.isActive ? 'pointer' : 'default'}}
                >
                  {step.label}
                </StepLabel>
              </StepContainer>
              {steps.length - 1 !== i && <Separator />}
            </>
          );
        })}
      </StepsContainer>
    </Container>
  );
};
