import {ROLE} from 'app/constants/role';
import {removeImpersonationTokensUser, logout} from 'app/redux/auth';
import {getSessionJwt, isImpersonating} from 'app/utilities';
import {useDispatch} from 'react-redux';
import {NavigationHeader} from 'v2/components/organisms/NavigationHeader';
import {ADMIN_ROUTES, UT1_ROUTES, UT3_ROUTES} from 'v2/constants/navigationRoutes';
import {ROUTE} from 'v2/constants/routes';
import {MenuOption, NavigationHeaderProps} from 'v2/utilities/types/components/NavigationHeader';
import {Callback, Middleware} from './types';

const Layout: React.FC<{}> = ({children}) => {
  const roles: string[] = getSessionJwt(true)?.contents?.roles ?? [];
  const email: string = getSessionJwt(true)?.contents?.email ?? '';

  const dispatch = useDispatch();

  const isImpersonation = isImpersonating();

  const SETTINGS_PATH = ROUTE.SETTINGS.path;
  const MENU_OPTIONS: MenuOption[] = [
    {label: 'Alerts', path: '/alerts'},
    isImpersonation
      ? {
          label: 'Stop Impersonating',
          path: '',
          onClick: () => dispatch(removeImpersonationTokensUser()),
        }
      : {
          label: 'Logout',
          path: '',
          onClick: () => dispatch(logout()),
        },
  ];

  const HeaderProps = () => {
    let props: NavigationHeaderProps = {
      settingsPath: SETTINGS_PATH,
      paths: [],
      menuOptions: MENU_OPTIONS,
      impersonationEmail: isImpersonation ? email : undefined,
    };

    if (roles) {
      if (roles.includes(ROLE.ADMIN)) {
        props.paths = ADMIN_ROUTES;
        props.isAdmin = true;
        props.fullWidth = true;
      } else if (roles.includes(ROLE.USER_TYPE_1)) {
        props.paths = UT1_ROUTES;
      } else if (roles.includes(ROLE.USER_TYPE_3)) {
        props.paths = UT3_ROUTES;
      }
    }

    return props;
  };

  return (
    <>
      <NavigationHeader {...HeaderProps()} />
      {children}
    </>
  );
};

export const navigationHeaderMiddleware: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
