import React from 'react';
import {connect} from 'react-redux';
import {animateScroll} from 'react-scroll';
import {SubmissionError} from 'redux-form';
import {branch, compose, lifecycle, renderNothing, withHandlers, withProps} from 'recompose';
import {get, includes} from 'lodash';

// Local Imports
import {AdminEditSiteSettingsForm} from 'app/components/AdminEditSiteSettingsForm';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {getGlobalSettings, updateGlobalSettings} from 'app/redux/settings';
import {toastSuccess, toastError} from 'app/utilities/toast';

const {scrollToTop} = animateScroll;

const AdminEditSiteSettingsPage = props => (
  <div className="p-content-lg">
    <h1 className="mb-2">{ROUTE.ADMIN_SITE_SETTINGS.title}</h1>
    {props.globalSettings && <AdminEditSiteSettingsForm {...props} form="admin-edit-settings" />}
  </div>
);

// Loads and provides 'globalSettings'
const withGlobalSettings = compose(
  connect(
    // mapStateToProps
    ({settings}) => ({
      globalSettings: settings.global || {},
      isFetching: !!settings.isGlobalSettingsFetching,
    }),
    // mapDispatchToProps
    {getGlobalSettings},
  ),
  lifecycle({
    componentDidMount() {
      this.props
        .getGlobalSettings()
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }
          scrollToTop();
        })
        .catch(() => {
          scrollToTop();
          toastError('Error retrieving global settings.');
        });
    },
  }),
  branch(({isFetching}) => isFetching, renderNothing),
);

const mapStateToProps = state => {
  return {
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_SITE_SETTINGS),
  };
};

// Provides 'initialValues', plus 'onSubmitFail' and 'onSubmit' callbacks
const withGlobalSettingsForm = compose(
  connect(
    mapStateToProps,
    // mapDispatchToProps
    {updateGlobalSettings},
  ),
  withProps(ownProps => ({initialValues: {...ownProps.globalSettings}})),
  withHandlers({
    onSubmit: ownProps => values => {
      return ownProps
        .updateGlobalSettings(values)
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('Global settings have been updated.');
          scrollToTop();
        })
        .catch(() => {
          scrollToTop();
          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    onSubmitFail: () => () => scrollToTop(),
  }),
);

export const AdminEditSiteSettings = compose(withGlobalSettings, withGlobalSettingsForm)(AdminEditSiteSettingsPage);
