import React, {Component} from 'react';
import {isFunction} from 'lodash';
import cn from 'classnames';
import {UncontrolledTooltip} from 'reactstrap';

const TOOLTIP_TRANSITION_DURATION = 0.2; // Match in tooltip.less
const DEFAULT_TOOLTIP_PROPS = {
  delay: {
    show: 0,
    hide: TOOLTIP_TRANSITION_DURATION * 1000,
  },
  placement: 'top',
};

const findDOMElements = target => {
  if (isFunction(target)) {
    return target();
  }
  if (typeof target === 'string') {
    let selection = document.querySelectorAll(target);
    if (!selection.length) {
      selection = document.querySelectorAll(`#${target}`);
    }
    if (!selection.length) {
      throw new Error(`The target '${target}' could not be identified in the dom, tip: check spelling`);
    }
    return selection;
  }
  return target;
};

const isArrayOrNodeList = els => {
  return Array.isArray(els) || typeof els.length === 'number';
};

const getTarget = target => {
  const els = findDOMElements(target);
  if (isArrayOrNodeList(els)) {
    return els[0];
  }
  return els;
};

class ToolTip extends Component {
  constructor(props) {
    super(props);

    this.handleTargetMouseOver = this.handleTargetMouseOver.bind(this);
    this.handleTargetMouseLeave = this.handleTargetMouseLeave.bind(this);

    this.state = {};
  }

  componentDidMount() {
    try {
      this.target = getTarget(this.props.target);
    } catch (e) {
      this.target = null;
    }

    if (this.target) {
      this.target.addEventListener('mouseover', this.handleTargetMouseOver);
      this.target.addEventListener('mouseleave', this.handleTargetMouseLeave);
    }
  }

  componentWillUnmount() {
    if (this.target) {
      this.target.removeEventListener('mouseover', this.handleTargetMouseOver);
      this.target.removeEventListener('mouseleave', this.handleTargetMouseLeave);
    }
  }

  handleTargetMouseOver() {
    this.setState({isHiding: false});
  }

  handleTargetMouseLeave() {
    this.setState({isHiding: true});
  }

  render() {
    const props = Object.assign({}, DEFAULT_TOOLTIP_PROPS, this.props, {
      className: cn(this.props.className, {'is-hiding': this.state.isHiding}),
    });

    return <UncontrolledTooltip {...props} />;
  }
}

export {ToolTip};
