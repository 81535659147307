import {Theme} from '../theme';

export const BoardIndependance = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.44922C0 8.89693 0.447715 8.44922 1 8.44922H20.2337C20.786 8.44922 21.2337 8.89693 21.2337 9.44922V14.3502C21.2337 14.9025 20.786 15.3502 20.2337 15.3502H1C0.447715 15.3502 0 14.9025 0 14.3502V9.44922Z" />
      <path d="M3.5392 3.89285C4.61418 3.89285 5.48562 3.0214 5.48562 1.94642C5.48562 0.871443 4.61418 0 3.5392 0C2.46422 0 1.59277 0.871443 1.59277 1.94642C1.59277 3.0214 2.46422 3.89285 3.5392 3.89285Z" />
      <path d="M3.84861 3.89294H3.53895H3.22929C1.78894 3.89294 0.568987 4.83569 0.152751 6.13785C-0.0154045 6.66391 0.447715 7.12224 1 7.12224H6.0779C6.63018 7.12224 7.09331 6.66391 6.92515 6.13785C6.50891 4.83569 5.28896 3.89294 3.84861 3.89294Z" />
      <path d="M10.6173 3.89285C11.6923 3.89285 12.5637 3.0214 12.5637 1.94642C12.5637 0.871443 11.6923 0 10.6173 0C9.54234 0 8.6709 0.871443 8.6709 1.94642C8.6709 3.0214 9.54234 3.89285 10.6173 3.89285Z" />
      <path d="M10.9267 3.89294H10.6171H10.3074C8.86707 3.89294 7.64711 4.83569 7.23088 6.13785C7.06272 6.66391 7.52584 7.12224 8.07813 7.12224H13.156C13.7083 7.12224 14.1714 6.66391 14.0033 6.13785C13.587 4.83569 12.3671 3.89294 10.9267 3.89294Z" />
      <path d="M17.6945 3.89285C18.7694 3.89285 19.6409 3.0214 19.6409 1.94642C19.6409 0.871443 18.7694 0 17.6945 0C16.6195 0 15.748 0.871443 15.748 1.94642C15.748 3.0214 16.6195 3.89285 17.6945 3.89285Z" />
      <path d="M18.0049 3.89294H17.6952H17.3855C15.9452 3.89294 14.7252 4.83569 14.309 6.13785C14.1408 6.66391 14.604 7.12224 15.1563 7.12224H20.2341C20.7864 7.12224 21.2496 6.66391 21.0814 6.13785C20.6652 4.83569 19.4452 3.89294 18.0049 3.89294Z" />
    </svg>
  );
};
