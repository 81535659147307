import React from 'react';
import {createBrowserHistory} from 'history';
import {omit, merge} from 'lodash';
import isPromise from 'is-promise';
import numeral from 'numeral';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connectRouter, routerMiddleware, ConnectedRouter} from 'connected-react-router';
import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {persistReducer, persistStore, createTransform} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import reduxPromise from 'redux-promise';
import reduxThunk from 'redux-thunk';
import {defaults as chartDefaults} from 'chart.js';
import 'chart.js/auto';

// polyfills
import 'app/polyfills/find';
import 'app/polyfills/startsWith';
import 'app/polyfills/includes';
import 'app/polyfills/isNaN';

// Local Imports
import 'app/assets/styles/index.less';
import {expireReducer} from 'app/utilities/redux-persist-expire';
import {PrivateRoute, PublicRoute, ScrollToTop, ScrollToTopOnRouteChange, SimpleModal, Spinner} from 'app/components';
import {CONFIG, PERMISSIONS, ROLE, ROUTE} from 'app/constants';
import {
  AdminAddUser,
  AdminEditFirm,
  AdminEditSiteSettings,
  AdminEditUser,
  AdminAddEmployee,
  AdminEditEmployee,
  AdminEmployeeManager,
  AdminDashboard,
  AdminFirmManager,
  AdminMarketIndexEdit,
  AdminMarketIndexManager,
  AdminSecurityEdit,
  AdminSecurityListing,
  AdminUploadHarmonyData,
  AdminUserManager,
  Alerts,
  ClientHoldingsUpload,
  ComingSoon,
  ContactUs,
  Companies,
  CompanyDetail,
  CompanyEngagement,
  CompaniesUserType3,
  CompanyDashboard,
  CompanyData,
  CompanyRatingSystem,
  CompanyRoadmap,
  ComplianceManager,
  Dashboard,
  ForgotPassword,
  Holdings,
  Login,
  ManagerDetail,
  Managers,
  NotFound,
  OnboardingAccount,
  OnboardingESG,
  OnboardingFinalize,
  OnboardingInvestmentManagers,
  OnboardingPlan,
  OnboardingLanding,
  OnboardingUt3Invite,
  OnboardingUt3Manager,
  OnboardingUt3Strategies,
  OnboardingUT4CreateAccount,
  PayWall,
  ProgressReport,
  ProgressReportPrintable,
  RatingsCenter,
  ResetPassword,
  StrategyDashboard,
  StyleGuide,
} from 'app/pages';
import {Settings} from 'app/pages/Settings';
import * as reducers from 'app/redux/reducers';
import {ACTION as AUTH_ACTION} from 'app/redux/auth';
import {embedTypeKit, isAuthenticated, reduxGetImpersonatedUser} from 'app/utilities';
import {ThemeProvider} from 'styled-components';
import {Theme} from './v2/components/atoms/theme';
import {harmonyApi} from './v2/redux/harmonyApi';
import {typeOrm} from './v2/redux/typeormEndpoints';
import {V2Routes} from 'v2/app';
import {Changelog} from 'v2/pages/Changelog';
import './app/assets/styles/compiled/styles.css';
import {rtkQueryErrorLogger} from 'v2/redux/middleware/rtkQueryErrorLogger';
import {TermsOfUse} from 'v2/pages/TermsOfUse';
import {UT3OnboardingProfile} from 'v2/pages/UT3/Onboarding/UT3OnboardingProfile';
import {harmonyOnboardingApi} from 'v2/redux/harmonyOnboardingApi';
import {OnboardingProfile} from 'app/pages/OnboardingProfile';

// Numeral Setup
numeral.register('locale', 'harmonyAnalytics', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  currency: {symbol: '$'},
  abbreviations: {
    thousand: 'k',
    million: 'mm',
    billion: 'bn',
    trillion: 'tr',
  },
});
numeral.locale('harmonyAnalytics');

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const appReducer = combineReducers({
  ...reducers,
  [harmonyApi.reducerPath]: harmonyApi.reducer,
  [harmonyOnboardingApi.reducerPath]: harmonyOnboardingApi.reducer,
  [typeOrm.reducerPath]: typeOrm.reducer,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_ACTION.LOGOUT) {
    return appReducer({router: state.router}, action);
  }
  return appReducer(state, action);
};

const blacklistPaths = [];

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    expireReducer('progressReport', {expireSeconds: 86400 /* 1 day expiration */}),
    createTransform((inboundState, key) => {
      const blacklistPathsForKey = blacklistPaths
        .filter(path => path.startsWith(`${key}.`))
        .map(path => path.substr(key.length + 1));
      return omit(inboundState, ...blacklistPathsForKey);
    }, null),
  ],
  blacklist: blacklistPaths.filter(a => !a.includes('.')),
  whitelist: ['auth', 'progressReport', 'appliedFilters'],
  rootReducer,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Custom middleware that catches redux exceptions globally
 * @returns {function(*): Function}
 */
const reduxCatchMiddleware = () => next => action => {
  if (!isPromise(action.payload)) {
    return next(action);
  }
  return next(action).catch(error => {
    if (process.env.APP_ENV === 'local') {
      // to prevent eslint warnings
      const windowConsole = console;
      windowConsole.warn('Caught Redux Exception (Local Env Only)', error);
    }
    return {
      hasError: true,
      error,
    };
  });
};

// eslint-disable-next-line import/prefer-default-export
export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      reduxCatchMiddleware,
      reduxGetImpersonatedUser,
      reduxThunk,
      reduxPromise,
      routerMiddleware(history),
      harmonyApi.middleware,
      harmonyOnboardingApi.middleware,
      typeOrm.middleware,
      rtkQueryErrorLogger,
    ),
  ),
);

const persistor = persistStore(store);

const removeAppLoadingOverlay = () => {
  const appLoadingOverlay = document.querySelector('.app-loading-overlay');
  appLoadingOverlay.className += ' is-hidden';
  setTimeout(() => {
    appLoadingOverlay.parentNode.removeChild(appLoadingOverlay);
  }, 600);
};

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <PersistGate persistor={persistor}>
          {/* ConnectedRouter will use the store from Provider automatically */}
          <ConnectedRouter history={history}>
            <div id="perimeter-inner" className="perimeter-inner">
              <div id="content" className="content">
                <div id="content-inner" className="content-inner">
                  <Switch>
                    {/* Custom public layouts */}
                    <Route
                      exact
                      path={ROUTE.LOGIN.path()}
                      render={props => {
                        return isAuthenticated() ? <Redirect to="/" /> : <Login {...props} />;
                      }}
                    />
                    <Route
                      exact
                      path={ROUTE.ONBOARDING_LANDING.path(':invitationCode')}
                      component={OnboardingLanding}
                    />
                    <Route exact path={ROUTE.STYLE_GUIDE.path()} component={StyleGuide} />
                    {/* Private routes (with standard page layout) */}
                    <PrivateRoute
                      exact
                      path={ROUTE.DASHBOARD.path()}
                      component={Dashboard}
                      allowedRoles={[
                        ROLE.USER_TYPE_1,
                        ROLE.USER_TYPE_2,
                        ROLE.USER_TYPE_3,
                        ROLE.USER_TYPE_4,
                        ROLE.ADMIN,
                      ]}
                    />
                    <PrivateRoute
                      path={ROUTE.SETTINGS.path()}
                      component={Settings}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ALERTS.path()}
                      component={Alerts}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.PLAN.path()}
                      component={Holdings}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANIES.path()}
                      component={Companies}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.RATINGS_CENTER.path()}
                      component={RatingsCenter}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANY_DETAIL.path(':securityId')}
                      component={CompanyDetail}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANY_ENGAGEMENT.path()}
                      component={CompanyEngagement}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.MANAGERS.path()}
                      component={Managers}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.MANAGER_DETAIL.path(':strategyId')}
                      component={ManagerDetail}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.PROGRESS_REPORT.path()}
                      component={ProgressReport}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.PROGRESS_REPORT_PRINTABLE.path()}
                      component={ProgressReportPrintable}
                      allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_EMPLOYEES.path()}
                      component={AdminEmployeeManager}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_ADMIN_PERMISSIONS]}
                    />
                    <PrivateRoute
                      path={ROUTE.ADMIN_EMPLOYEES_EDIT.path(':userId')}
                      component={AdminEditEmployee}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_ADMIN_PERMISSIONS]}
                    />
                    <PrivateRoute
                      path={ROUTE.ADMIN_EMPLOYEES_ADD.path()}
                      component={AdminAddEmployee}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_ADMIN_PERMISSIONS]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_USERS.path()}
                      component={AdminUserManager}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_USERS]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_USERS_ADD.path()}
                      component={AdminAddUser}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_USERS, PERMISSIONS.WRITE_USERS]}
                    />
                    <PrivateRoute
                      path={ROUTE.ADMIN_USERS_EDIT.path(':userId')}
                      component={AdminEditUser}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_USERS]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_SECURITY_LISTING.path()}
                      component={AdminSecurityListing}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_COMPANIES]}
                    />
                    <PrivateRoute
                      path={ROUTE.ADMIN_SECURITY_EDIT.path(':securityId')}
                      component={AdminSecurityEdit}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_COMPANIES]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_FIRMS.path()}
                      component={AdminFirmManager}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_FIRMS]}
                    />
                    <PrivateRoute
                      path={ROUTE.ADMIN_FIRMS_EDIT.path(':firmId')}
                      component={AdminEditFirm}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_FIRMS]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_DASHBOARD.path()}
                      component={AdminDashboard}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_CLIENT_HOLDINGS_UPLOAD.path()}
                      component={ClientHoldingsUpload}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_CLIENT_HOLDINGS]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_HARMONY_UPLOADS.path()}
                      component={AdminUploadHarmonyData}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_HARMONY_DATA]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_MARKET_INDEXES.path()}
                      component={AdminMarketIndexManager}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_REFERENCE_INDEXES]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_MARKET_INDEX_EDIT.path(':marketIndexId')}
                      component={AdminMarketIndexEdit}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_REFERENCE_INDEXES]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.ADMIN_SITE_SETTINGS.path()}
                      component={AdminEditSiteSettings}
                      allowedRoles={[ROLE.ADMIN]}
                      requiredAdminPermissions={[PERMISSIONS.READ_SITE_SETTINGS]}
                    />
                    <PrivateRoute exact path={ROUTE.SITE_SEARCH.path()} component={ComingSoon} />
                    <PrivateRoute
                      exact
                      path={ROUTE.PAYWALL.path(':strategyId?')}
                      component={PayWall}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.STRATEGY_ALERTS.path(':strategyId')}
                      component={Alerts}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.STRATEGY_COMPANIES.path(':strategyId')}
                      component={CompaniesUserType3}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.STRATEGY_COMPLIANCE_MANAGER.path(':strategyId')}
                      component={ComplianceManager}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.STRATEGY_DASHBOARD.path(':strategyId')}
                      component={StrategyDashboard}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.STRATEGY_COMPANY_DETAIL.path(':strategyId', ':securityId')}
                      component={CompanyDetail}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    />
                    {/* <PrivateRoute
                      exact
                      path={ROUTE.UPLOAD_PORTFOLIO_STRATEGY.path(':strategyId?')}
                      component={UploadPortfolio}
                      allowedRoles={[ROLE.USER_TYPE_3]}
                    /> */}
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANY_DASHBOARD.path()}
                      component={CompanyDashboard}
                      allowedRoles={[ROLE.USER_TYPE_4]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANY_DATA.path()}
                      component={CompanyData}
                      allowedRoles={[ROLE.USER_TYPE_4]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANY_ROADMAP.path()}
                      component={CompanyRoadmap}
                      allowedRoles={[ROLE.USER_TYPE_4]}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE.COMPANY_RATING_SYSTEM.path()}
                      component={CompanyRatingSystem}
                      allowedRoles={[ROLE.USER_TYPE_4]}
                    />
                    <PublicRoute exact path={ROUTE.CHANGELOG.path()} component={Changelog} />
                    <PublicRoute exact path={ROUTE.TERMS.path()} component={TermsOfUse} />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_CREATE_ACCOUNT.path(':invitationCode')}
                      component={OnboardingAccount}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_PROFILE.path(':invitationCode')}
                      component={OnboardingProfile}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_PROFILE_V2.path(':invitationCode')}
                      component={UT3OnboardingProfile}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_PLAN_INFO.path(':invitationCode')}
                      component={OnboardingPlan}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_ESG_STRATEGY.path(':invitationCode')}
                      component={OnboardingESG}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_INVESTMENT_MANAGERS.path(':invitationCode')}
                      component={OnboardingInvestmentManagers}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_UT3_INVITE.path(':invitationCode')}
                      component={OnboardingUt3Invite}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_UT3_MANAGER.path(':invitationCode')}
                      component={OnboardingUt3Manager}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_UT3_STRATEGIES.path(':invitationCode')}
                      component={OnboardingUt3Strategies}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_FINALIZE.path(':invitationCode')}
                      component={OnboardingFinalize}
                    />
                    <PublicRoute
                      exact
                      path={ROUTE.ONBOARDING_UT4_CREATE_ACCOUNT.path(':invitationCode')}
                      component={OnboardingUT4CreateAccount}
                    />
                    <PublicRoute exact path={ROUTE.CONTACT.path()} component={ContactUs} />
                    <PublicRoute exact path={ROUTE.DISCLAIMER.path()} component={ComingSoon} />
                    <PublicRoute exact path={ROUTE.TERMS.path()} component={ComingSoon} />
                    <PublicRoute exact path={ROUTE.FORGOT_PASSWORD.path()} component={ForgotPassword} />
                    <PublicRoute exact path={ROUTE.RESET_PASSWORD.path(':resetToken')} component={ResetPassword} />
                    <V2Routes />
                    <PublicRoute
                      render={() => {
                        console.log('Sucess');
                        return isAuthenticated() ? <NotFound /> : <Redirect to="/login" />;
                      }}
                    />
                  </Switch>
                  <SimpleModal />
                </div>
              </div>
              <Spinner />
              <ScrollToTop />
              <ScrollToTopOnRouteChange />
            </div>
          </ConnectedRouter>
        </PersistGate>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
  );

  removeAppLoadingOverlay();
};

const isTouchFirst =
  !!navigator.platform &&
  /Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/.test(
    navigator.platform,
  );
document.documentElement.className += isTouchFirst ? ' is-touch-first' : ' is-mouse-first';

/* Browser Detection */
/* https://stackoverflow.com/a/34918134/4229568 */

// Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
const isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';
// Safari 3.0+
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (p => {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || window.safari.pushNotification); // eslint-disable-line dot-notation
// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode; // eslint-disable-line spaced-comment
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;
// Chrome 1+
const isChrome = !!window.chrome && !!window.chrome.webstore;
// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

if (isBlink) document.documentElement.className += ' is-blink';
if (isChrome) document.documentElement.className += ' is-chrome';
if (isEdge) document.documentElement.className += ' is-edge';
if (isFirefox) document.documentElement.className += ' is-firefox';
if (isIE) document.documentElement.className += ' is-ie';
if (isOpera) document.documentElement.className += ' is-opera';
if (isSafari) document.documentElement.className += ' is-safari';

// Set ChartJS Defaults
merge(chartDefaults, {
  global: {
    legend: {display: false},
    tooltips: {
      backgroundColor: 'rgba(64,66,75,0.94)', // match @tooltip-bg in tooltips.less
      displayColors: false,
      xPadding: 10,
      yPadding: 10,
    },
    animation: {duration: 300},
  },
});

embedTypeKit({
  kitId: CONFIG.TYPEKIT_KIT_ID,
  classes: 'false',
  active: renderApp,
  inactive: renderApp,
});
