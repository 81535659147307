export const INTRODUCTION = `The use of Harmony Analytics’ website and systems is subject to the following terms. By accessing
Harmony Analytics’ website and systems, you agree that you have read, understood, and agreed to be
bound by all of these terms. If you do not agree with all of these terms, then you may not access
Harmony Analytics’ website and systems and you must discontinue use immediately.`;

export const PERMISSIBLE_USE = `All right, title and interest in Harmony Analytics’ website and systems (“Harmony”)
and all content contained therein is the exclusive property of Harmony Analytics, except as
otherwise stated. You may share Harmony information with others inside and outside of your
organization provided that sharing Harmony information with companies that have a Harmony
rating or with any registered investment advisers requires the prior written consent of Harmony
Analytics. You may not share your log‐in information to Harmony with anyone outside of your
organization. If you download or print any information from Harmony, you agree that you will not
remove or obscure any logos, notices or legends contained therein. If you include Harmony
information in any publications or reports, you agree to reference Harmony Analytics as the source
of that information.`;

export const LIMITATION_OF_LIABILITY = `Although the information provided to you in Harmony is obtained or
compiled from sources we believe to be reliable, Harmony Analytics cannot and does not guarantee
the accuracy, validity, timeliness or completeness of any information or data made available to you
for any particular purpose. Neither Harmony Analytics, nor any of its affiliates, directors, officers or
employees, nor its successors or assigns, nor any third‐party vendor (together, the “Harmony
Parties”), will be liable for any loss or damage that you incur, arising from any failure or interruption
of Harmony’s website, or resulting from the data contained therein, or from any other cause relating
to your access to, inability to access, or use of Harmony’s website or the data contained herein,
whether or not the circumstances giving rise to such cause may have been within the control of a
Harmony Party. In no event will any Harmony Party be liable to you for any direct, special, indirect,
consequential, punitive, exemplary or incidental damages or any other damages of any kind even if
such Harmony Party has been advised of the possibility thereof. Where any applicable law does not
allow the limitation of liability in the manner described above, the Harmony Parties’ total liability to
you for all damages, loss and causes of action (whether in contract or in tort, including gross
negligence) shall not exceed the amount paid by you, if any, for accessing Harmony’s website.`;

export const DISCLAIMER_OF_WARRANTIES = `Harmony’s website is provided "as is" and the Harmony Parties do not
make any representation or warranty of any kind to you or any third party, either express or
implied, with respect to the website, the timeliness thereof, the results to be obtained by the use
thereof or any other matter. Further, the Harmony Parties expressly disclaim, and licensee waives,
any and all implied warranties, including, without limitation, warranties of originality, accuracy,
completeness, timeliness, non‐infringement, merchantability and fitness for a particular purpose.`;

export const NOT_FINANCIAL_ADVISE = `The material on Harmony’s website is for informational purposes only and is
not an offer or recommendation to buy or sell or a solicitation of an offer to buy or sell any security
or instrument or to participate in any particular trading or investment strategy. Further, none of the
information and material on Harmony’s website is intended to constitute investment advice or a
recommendation to make (or refrain from making) any kind of investment decision and may not be
relied on as such. Historical data and analysis should not be taken as an indication or guarantee of
any future performance, analysis, forecast or prediction.`;

export const ADDRESS = `1180 PEACHTREE STREET + SUITE 2300 + ATLANTA + GA + 30309`;
