import React, {Component, Fragment} from 'react';
import {get, isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ONBOARDING_STEPS, ROLE, ROUTE} from 'app/constants';
import {Alert, OnboardingPlanForm} from 'app/components';
import {fetchCurrentPlanSponsorIfNeeded, updatePlanSponsorByCurrentUser} from 'app/redux/planSponsor';
import {fetchCurrentUserIfNeeded} from 'app/redux/user';
import {toastSuccess} from 'app/utilities/toast';
import {withUser} from 'app/utilities';

class SettingsPlanPage extends Component {
  componentDidMount() {
    this.props.dispatch(fetchCurrentPlanSponsorIfNeeded());
    this.props.dispatch(fetchCurrentUserIfNeeded());
  }

  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="settings-form">
        <h1>{ROUTE.SETTINGS_PLAN_PROFILE.title}</h1>
        {this.props.hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT}</Alert>}
        {this.props.onboardingUser && this.props.onboardingPlanSponsor && (
          <Fragment>
            <OnboardingPlanForm {...this.props} hideInvitePlanUsers={true} />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  // Using Onboarding specific form.  Expects specific properties passed in to populate it
  return {
    hasError: get(state, 'planSponsor.hasError'),
    isFetching: get(state, 'planSponsor.isFetching'),
    onboardingPlanSponsor: get(state, 'planSponsor.editPlanSponsor'),
    onboardingUser: get(state, 'user.currentUser'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {authUser} = ownProps;
  const userRole = !isEmpty(authUser.roles) ? authUser.roles[0] : ROLE.USER_TYPE_1;
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const updateData = {
        ...values,
        onboardingStep: ONBOARDING_STEPS[userRole].PLAN.key,
      };
      return dispatch(updatePlanSponsorByCurrentUser(updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          toastSuccess('Plan Profile has been updated.');

          dispatch(push(ROUTE.SETTINGS.path()));
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const SettingsPlan = compose(withUser, connect(mapStateToProps, mapDispatchToProps))(SettingsPlanPage);
