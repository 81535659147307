import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {ModalProps} from 'v2/utilities/types/components/Modal';

import {Theme} from '../atoms/theme';
import {BorderlessIconButton} from './Button';

const ModalContainer = styled(Modal)<{width?: string; height?: string; minWidth?: string}>`
  background: #fff;
  padding: 2.188rem 3.5rem;
  border: 0.125rem solid #fff;
  box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  width: ${props => props.width ?? 'auto'};
  min-height: ${props => props.height ?? 'auto'};
  height: auto;
  max-height: 90vh;
  max-width: 90vw;
  min-width: ${({minWidth}) => minWidth ?? '0'};
`;

const PaddinglessModalContainer = styled(Modal)<{width?: string; height?: string}>`
  position: relative;
  background: #fff;
  box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  z-index: -1;
  width: ${props => props.width || 0};
  height: ${props => props.height};
`;
const CloseButtonContainer = styled.div`
  &&& {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width: 0.875rem;
    &:active {
      border: none;
      outline: none;
    }
  }
`;

const CloseButtonContainerAbsolutePos = styled.div`
  &&& {
    margin-left: auto;
    width: 1.875rem;
    position: absolute;
    right: 42px;
    top: 32px;
    z-index: 2;
    &:active {
      border: none;
      outline: none;
    }
  }
`;

export const GenericModalV2 = ({isOpen, children, width, minWidth, height = 'auto', setClose}: ModalProps) => {
  return (
    <ModalContainer
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      width={width}
      minWidth={minWidth}
      height={height}
      style={{
        overlay: {
          backgroundColor: `${Theme.colors.dark}80`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <CloseButtonContainer onClick={() => setClose(false)}>
        <BorderlessIconButton size="0.875rem" iconName={'Cross'} />
      </CloseButtonContainer>
      {children}
    </ModalContainer>
  );
};

export const PaddinglessModal = ({isOpen, children, width = 'auto', height = 'auto', setClose}: ModalProps) => {
  return (
    <PaddinglessModalContainer
      isOpen={isOpen}
      width={width}
      height={height}
      style={{
        overlay: {
          backgroundColor: `${Theme.colors.dark}80`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <CloseButtonContainerAbsolutePos onClick={() => setClose(false)}>
        <BorderlessIconButton size="0.875rem" iconName={'Cross'} onClick={() => setClose(false)} />
      </CloseButtonContainerAbsolutePos>
      {children}
    </PaddinglessModalContainer>
  );
};
