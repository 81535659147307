/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaults as chartJsDefaults, defaults, Chart as ChartJS} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {IssueDataCardChartLegendItem} from './IssueDataCardChartLegendItem';
import {ISSUE} from '../constants';
import {formatNumberCommas} from '../utilities/formatNumberCommas';
import {zeroCompensation} from '../utilities/zeroCompensation';
import {CONFIG} from '../constants';
import {DATA_STATUS} from 'v2/constants/dataStatus';

/**
 * This chart style is for boolean data, and displays the percentage of other
 * companies which meet the expectation as a bar chart
 */

class IssueDataCardChartStyle3 extends Component {
  constructor() {
    super();

    chartJsDefaults.global.defaultFontFamily = 'futura-pt';

    const tooltipPlugin = ChartJS.registry.getPlugin('tooltip');
    tooltipPlugin.positioners.center = function (elements, eventPosition) {
      if (!elements[0]) {
        return {x: 0, y: 0};
      }
      const {x, y, base} = elements[0].element;
      const height = base - y;
      return {x, y: y + height / 2};
    };
  }

  render() {
    const {data: issueData} = this.props;
    const {id: issueId, data, universe, sector} = issueData;
    this.thisYear = CONFIG.REPORTING_YEAR;
    this.keyYears = Array.from({length: CONFIG.REPORTING_YEAR_OFFSET}, (_, i) => this.thisYear - i).reverse();
    const precision = !!ISSUE?.[issueId] && ISSUE[issueId].precision;

    const universeData = this.keyYears
      .map((year, i) => {
        if (!!universe?.[year]) {
          return {
            x: i + 1,
            y: universe[year],
          };
        }

        return null;
      })
      .filter(value => value !== null);

    const sectorData = this.keyYears
      .map((year, i) => {
        if (!!sector?.[year]) {
          return {
            x: i + 1,
            y: sector[year],
          };
        }

        return null;
      })
      .filter(value => value !== null);

    const chartData = {
      labels: this.keyYears,
      datasets: [
        {
          label: 'Universe',
          fill: false,
          lineTension: 0,
          pointRadius: 5,
          backgroundColor: '#979ea7',
          borderColor: '#979ea7',
          data: universeData,
        },
        {
          label: 'Sector',
          fill: false,
          lineTension: 0,
          pointRadius: 5,
          backgroundColor: '#003462',
          borderColor: '#003462',
          data: sectorData,
        },
      ],
    };

    const chartOptions = {
      plugins: {
        datalabels: {
          display: false,
        },
        legend: false,
        tooltip: {
          position: 'center',
          displayColors: false,
          padding: 10,
          callbacks: {
            title(tooltipItem) {
              return tooltipItem[0].label;
            },
            label(tooltipItem) {
              if (tooltipItem.formattedValue === undefined) {
                return DATA_STATUS.NONE;
              }
              if (tooltipItem.formattedValue >= 10 ** precision) {
                return formatNumberCommas(parseFloat(tooltipItem.formattedValue).toFixed(0));
              }
              return formatNumberCommas(
                parseFloat(parseFloat(tooltipItem.formattedValue.replaceAll(',', '')).toPrecision(precision)),
              );
            },
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      barPercentage: 0.7,
      scales: {
        x: {
          offset: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          min: 0,
          max: CONFIG.REPORTING_YEAR_OFFSET - 1,
          ticks: {
            min: 1,
            max: CONFIG.REPORTING_YEAR_OFFSET - 1,
            stepSize: 1,
            callback(value, index) {
              return chartData.labels[index];
            },
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            min: 0,
            max: 100,
            stepSize: 10,
            callback(value) {
              return `${value}%`;
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    defaults.global.defaultFontFamily = 'futura-pt';
    // defaults.global.defaultFontSize = '16';

    return (
      <div className="w-100 h-100 d-flex flex-column">
        <div style={{flex: 1}}>
          <Bar plugins={[zeroCompensation]} options={chartOptions} data={chartData} />
        </div>
        <div className="w-100 d-flex flex-row justify-content-center">
          {universeData.length > 0 && <IssueDataCardChartLegendItem color="#979ea7" label="Universe" />}
          {sectorData.length > 0 && <IssueDataCardChartLegendItem color="#003462" label="Sector" />}
        </div>
      </div>
    );
  }
}

IssueDataCardChartStyle3.propTypes = {
  data: PropTypes.object.isRequired,
};

export {IssueDataCardChartStyle3};
