import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {defaults} from 'chart.js';
import {Line} from 'react-chartjs-2';
import * as AnnotationsPlugin from 'chartjs-plugin-annotation';
import {IssueDataCardChartLegendItem} from './IssueDataCardChartLegendItem';
import {CONFIG, ISSUE} from '../constants';
import {yAxisCalculation, formatNumberCommas} from '../utilities';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {reportingYears} from 'v2/utilities/helpers/reportingYears';
import {ISSUES} from 'v2/constants/issues';
import {ISSUE_UNITS} from 'v2/constants/issueOptions';
import {convertUnits, getPrecision} from 'v2/utilities/helpers/convertUnits';

/**
 * This chart style is for numerical data, and is displayed as a line chart
 */
class IssueDataCardChartStyle1 extends Component {
  render() {
    const {data: issueData} = this.props;
    const {id: issueId, data} = issueData;
    this.keyYears = reportingYears();
    const precision = !!ISSUE?.[issueId] && ISSUE[issueId].precision;

    const actualData = this.keyYears
      .map((year, i) => {
        if (data[year] !== undefined && data[year].value !== undefined) {
          if (issueId === ISSUES.CARBON_EMISSIONS.id) {
            return {
              x: i + 1,
              y: convertUnits(
                data[year].value,
                ISSUE_UNITS.MMTCO2E,
                ISSUE_UNITS.MTCO2E,
                getPrecision(data[year].value),
              ),
            };
          }
          return {
            x: i + 1,
            y: data[year].value,
          };
        }

        return null;
      })
      .filter(value => value !== null);

    const harmonyStandardData = this.keyYears
      .map((year, i) => {
        if (
          data[year] !== undefined &&
          data[year].harmonyStandardLower !== undefined &&
          data[year].harmonyStandardLower !== null
        ) {
          return {
            x: i + 1,
            y: data[year].harmonyStandardLower,
          };
        }

        return null;
      })
      .filter(value => value !== null);

    const [ymin, ymax] = yAxisCalculation(actualData, harmonyStandardData);

    const dynamicStepSize = yMax => {
      const stepValue = yMax / 10;
      let result = Math.ceil(stepValue / 5) * 5;

      // eslint-disable-next-line eqeqeq
      if (result == 5) {
        result = 0.5;
      }
      return result;
    };

    const chartData = {
      labels: this.keyYears,
      datasets: [
        {
          label: 'Actual',
          xAxisID: 'x',
          fill: false,
          lineTension: 0,
          pointRadius: 5,
          pointBorderColor: '#003462',
          pointBackgroundColor: '#003462',
          borderColor: '#003462',
          backgroundColor: '#003462',
          data: actualData,
        },
        {
          label: 'Benchmark',
          xAxisID: 'x',
          fill: false,
          lineTension: 0,
          pointRadius: 5,
          pointBorderColor: '#009CCD',
          pointBackgroundColor: '#009CCD',
          borderColor: '#009CCD',
          backgroundColor: '#009CCD',
          data: harmonyStandardData,
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: false,
        tooltip: {
          padding: 10,
          displayColors: false,
          callbacks: {
            title(tooltipItem) {
              return tooltipItem[0].label;
            },
            label(tooltipItem) {
              if (tooltipItem.formattedValue === undefined) {
                return DATA_STATUS.NONE;
              }
              if (tooltipItem.formattedValue >= 10 ** precision) {
                return formatNumberCommas(parseFloat(tooltipItem.formattedValue).toFixed(0));
              }
              return formatNumberCommas(
                parseFloat(parseFloat(tooltipItem.formattedValue.replaceAll(',', '')).toPrecision(precision)),
              );
            },
          },
        },
      },
      scales: {
        x: {
          offset: true,
          grid: {
            drawBorder: false,
            display: false,
          },
          min: 0,
          max: CONFIG.REPORTING_YEAR_OFFSET - 1,
          ticks: {
            stepSize: 1,
            callback(value, index) {
              return chartData.labels[index];
            },
          },
        },

        yAxes: {
          suggestedMax: ymax,
          suggestedMin: ymin,
          ticks: {
            stepSize: dynamicStepSize(ymax),
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    defaults.global.defaultFontFamily = 'futura-pt';
    // defaults.global.defaultFontSize = '16';

    return (
      <div className="w-100 h-100 d-flex flex-column">
        <div className="flex-1">
          <Line plugins={[AnnotationsPlugin]} options={chartOptions} data={chartData} />
        </div>
        <div className="w-100 d-flex flex-row justify-content-center">
          {actualData.length > 0 && <IssueDataCardChartLegendItem color="#003462" label="Actual" type="line" />}
          {harmonyStandardData.length > 0 && (
            <IssueDataCardChartLegendItem
              color="#009CCD"
              label="Benchmark"
              type="line"
              description="Benchmark is the target value for each issue defined by Harmony Analytics."
            />
          )}
        </div>
      </div>
    );
  }
}

IssueDataCardChartStyle1.propTypes = {
  data: PropTypes.object.isRequired,
};

export {IssueDataCardChartStyle1};
