import {createSlice} from '@reduxjs/toolkit';

// Local Imports
import {
  TypeFilters,
  LiquidClassFilters,
  IlliquidClassFilters,
  allAssetsClassValues,
  RegionFilters,
  TypeValues,
  LiquidClassValues,
  IlliquidClassValues,
  RegionValues,
} from '../constants/strategyFilters';

const defaultValues = {
  type: TypeValues.allAssets,
  class: {
    all: allAssetsClassValues.allProducts,
    liquid: LiquidClassValues.allProducts,
    illiquid: IlliquidClassValues.allProducts,
  },
  region: RegionValues.overall,
  classOptions: LiquidClassFilters,
  filters: {
    type: TypeValues.allAssets.value,
    class: LiquidClassValues.allProducts.value,
    region: RegionValues.overall.value,
  },
};

const initialState = {
  typeValue: defaultValues.type,
  classValue: defaultValues.class.liquid,
  regionValue: defaultValues.region,
  typeOptions: TypeFilters,
  classOptions: defaultValues.classOptions,
  regionOptions: RegionFilters,
  filters: defaultValues.filters,
};

export const strategyFilterSlice = createSlice({
  name: 'strategyFilters',
  initialState,
  reducers: {
    updateType: (state, action) => {
      state.typeValue = action.payload;
    },
    updateClass: (state, action) => {
      state.classValue = action.payload;
    },
    updateRegion: (state, action) => {
      state.regionValue = action.payload;
    },
    updateClassOptions: (state, action) => {
      if (action.payload === TypeValues.liquid) {
        state.classOptions = LiquidClassFilters;
        state.classValue = defaultValues.class.liquid;
      } else if (action.payload === TypeValues.illiquid) {
        state.classOptions = IlliquidClassFilters;
        state.classValue = defaultValues.class.illiquid;
      } else {
        state.classOptions = defaultValues.classOptions;
        state.classValue = defaultValues.class.all;
      }
    },
    updateFilterOptions: (state, action) => {
      state.typeOptions = action.payload.type;
      state.classOptions = action.payload.class;
      state.regionOptions = action.payload.region;
    },
    updateFilters: state => {
      state.filters = {
        type: state.typeValue.value,
        class: state.classValue.value,
        region: state.regionValue.value,
      };
    },
  },
});

export const {updateType, updateClass, updateRegion, updateClassOptions, updateFilterOptions, updateFilters} =
  strategyFilterSlice.actions;

export default strategyFilterSlice.reducer;
