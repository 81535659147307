import cn from 'classnames';
import {assignWith, pick} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Element as ScrollElement, scroller} from 'react-scroll';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm} from 'redux-form';

// Local Imports
import {Button, FormFeedback} from 'app/components';
import {SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {ADDRESS_TYPES, AUTOCOMPLETE, CHARACTER_LIMIT, PHONE_TYPES, STATES, USER_PREFIXES} from 'app/constants';
import {errorRequiredFields, normalizeExtensionlessPhone, normalizeZip, validateFieldArray} from 'app/utilities';

const validate = values => {
  const errors = errorRequiredFields(values, ['firstName', 'lastName']);

  const phoneErrors = validateFieldArray(values.phones, (phone, ownErrors) => {
    if (phone.number && !phone.type) {
      ownErrors.type = 'Required';
    }
  });
  phoneErrors && (errors.phones = phoneErrors);

  const addressErrors = validateFieldArray(values.addresses, (address, ownErrors) => {
    const hasAddress =
      address.name || address.line1 || address.line2 || address.line3 || address.city || address.state || address.zip;
    if (hasAddress && !address.type) {
      ownErrors.type = 'Required';
    }
  });
  addressErrors && (errors.addresses = addressErrors);

  return errors;
};

const renderPhones = ({fields: phones}) => (
  <Fragment>
    {phones.map((phone, index) => {
      const isFirst = index === 0;
      const isLast = index === phones.length - 1;
      return (
        <div className="form-grid" key={index}>
          <Field
            label={isFirst ? 'Phone Number' : null}
            name={`${phone}.number`}
            type="text"
            component={TextFormGroup}
            normalize={normalizeExtensionlessPhone}
            autoComplete={isFirst ? AUTOCOMPLETE.PHONE : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            placeholder="(       )        -"
            className="col-2 min-h-0 mb-3"
          />
          <Field
            label={isFirst ? 'Phone Type' : null}
            name={`${phone}.type`}
            component={SelectFormGroup}
            options={PHONE_TYPES}
            shouldInsertDefault={true}
            className="col-1 min-h-0 mb-3"
          />
          <div className={cn('flex-fixed w-8r form-grid-button', {'pt-0': !isFirst})}>
            {isLast ? (
              <Button key="add" onClick={() => phones.push({})}>
                Add Another
              </Button>
            ) : (
              <Button key="remove" onClick={() => phones.remove(index)} color="red" className="btn-throttled">
                Remove
              </Button>
            )}
          </div>
        </div>
      );
    })}
  </Fragment>
);

const renderAddresses = ({fields: addresses}) =>
  addresses.map((address, index) => {
    const isFirst = index === 0;
    const isLast = index === addresses.length - 1;
    return (
      <Fragment key={index}>
        <div className="form-grid">
          <Field
            label="Address Name"
            name={`${address}.name`}
            type="text"
            placeholder={'Ex: "Headquarters"'}
            component={TextFormGroup}
            autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            className="col-3"
          />
          <Field
            label="Address Type"
            name={`${address}.type`}
            component={SelectFormGroup}
            options={ADDRESS_TYPES}
            shouldInsertDefault={true}
            className="col-3"
          />
        </div>
        <div className="mb-9">
          <Field
            label="Street Address"
            name={`${address}.line1`}
            type="text"
            component={TextFormGroup}
            autoComplete={isFirst ? AUTOCOMPLETE.ADDRESS_LINE_1 : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            className="min-h-0 mb-3"
          />
          <Field
            name={`${address}.line2`}
            type="text"
            component={TextFormGroup}
            autoComplete={isFirst ? AUTOCOMPLETE.ADDRESS_LINE_2 : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            className="min-h-0 mb-3"
          />
          <Field name={`${address}.line3`} type="text" component={TextFormGroup} className="min-h-0 mb-3" />
        </div>
        <div className="form-grid">
          <Field
            label="City"
            name={`${address}.city`}
            type="text"
            component={TextFormGroup}
            autoComplete={isFirst ? AUTOCOMPLETE.CITY : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            maxLength={CHARACTER_LIMIT.CITY}
            className="col-2"
          />
          <Field
            label="State/US Territory"
            name={`${address}.state`}
            component={SelectFormGroup}
            autoComplete={isFirst ? AUTOCOMPLETE.STATE : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            options={STATES}
            shouldInsertDefault={true}
            className="col-2"
          />
          <Field
            label="Zip"
            name={`${address}.zip`}
            type="text"
            component={TextFormGroup}
            autoComplete={isFirst ? AUTOCOMPLETE.ZIP : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            normalize={normalizeZip}
            className="col-2"
          />
        </div>
        {isLast && <ScrollElement name="lastAddress" />}
        {isLast ? (
          <Button
            onClick={() => {
              addresses.push({});
              scroller.scrollTo('lastAddress', {
                duration: 800,
                delay: 100,
                smooth: true,
                offset: 0,
              });
            }}
          >
            Add Another Address
          </Button>
        ) : (
          <Button onClick={() => addresses.remove(index)} color="red">
            Remove Address
          </Button>
        )}
        {!isLast && <hr className="my-8" />}
      </Fragment>
    );
  });

class OnboardingProfileFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <Field
            label="Prefix"
            name="prefix"
            component={SelectFormGroup}
            shouldInsertDefault={true}
            options={USER_PREFIXES}
            className="col-1"
          />

          <Field
            label="First Name*"
            name="firstName"
            type="text"
            autoComplete={AUTOCOMPLETE.FIRST_NAME}
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.FIRST_NAME}
            className="col-2"
          />

          <Field
            label="Last Name*"
            name="lastName"
            type="text"
            autoComplete={AUTOCOMPLETE.LAST_NAME}
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.LAST_NAME}
            className="col-2"
          />

          <Field
            label="Suffix"
            name="suffix"
            type="text"
            autoComplete={AUTOCOMPLETE.SUFFIX}
            component={TextFormGroup}
            placeholder={'Ex: "CFA"'}
            maxLength={CHARACTER_LIMIT.NAME_SUFFIX}
            className="col-1"
          />

          <Field
            label="Title"
            name="jobTitle"
            type="text"
            autoComplete={AUTOCOMPLETE.ORGANIZATION_TITLE}
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.PROFILE_TITLE}
            className="col-3"
          />

          <Field
            label="Role"
            name="jobRole"
            type="text"
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.PROFILE_ROLE}
            className="col-3"
          />
        </div>

        <h3 className="mt-12">Contact Information</h3>
        <FieldArray name="phones" component={renderPhones} />

        <h3 className="mt-12">Address Information</h3>
        <FieldArray name="addresses" component={renderAddresses} />

        <div className="form-footer is-right mt-12">
          <Button type="submit" solid>
            Save &amp; Continue
          </Button>
        </div>
      </form>
    );
  }
}

OnboardingProfileFormComponent.propTypes = {
  onboardingUser: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingUser = {}} = ownProps;
  const defaultValues = {
    phones: [{}],
    addresses: [{}],
  };
  // Pick which values will pre-populate the form.
  const userValues = pick(onboardingUser, [
    'prefix',
    'firstName',
    'lastName',
    'suffix',
    'jobTitle',
    'jobRole',
    'phones',
    'addresses',
  ]);

  return Object.assign({}, ownProps, {
    initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
  });
};

export const OnboardingProfileForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'onboarding-profile',
    validate,
  }),
)(OnboardingProfileFormComponent);
