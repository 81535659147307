import {Theme} from '../theme';

export const PlusSquare = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3333 2.5H4.66667C3.74619 2.5 3 3.24619 3 4.16667V15.8333C3 16.7538 3.74619 17.5 4.66667 17.5H16.3333C17.2538 17.5 18 16.7538 18 15.8333V4.16667C18 3.24619 17.2538 2.5 16.3333 2.5Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.5 6.66699V13.3337" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.16663 10H13.8333" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
