import {Theme} from '../theme';

export const LogoBadge4 = ({size = 270}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 270 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M229.577 39.3689C187.663 -2.64715 124.815 -11.018 74.5404 14.2001L30.4531 184.334C34.897 178.139 40.4237 171.183 46.9419 164.277C71.6083 138.347 98.7287 126.759 125.385 130.878C140.032 133.103 154.587 144.55 171.413 157.8C194.329 175.872 220.366 196.31 251.98 196.31H253.913C280.091 145.676 272.012 81.8497 229.577 39.3689Z"
        fill="#E5E5E5"
      />
      <path
        d="M39.6644 39.3689C-4.3316 83.4196 -11.4194 150.414 18.3519 201.843L65.7441 18.9804C56.4415 24.5563 47.6522 31.3219 39.6644 39.3689Z"
        fill="#E5E5E5"
      />
      <path
        d="M124.302 138.108C65.3781 129.167 24.6448 207.137 24.2159 207.94C23.9346 208.51 23.5057 208.933 22.9854 209.313C27.8019 216.459 33.3356 223.274 39.6218 229.61C92.0765 282.13 177.129 282.13 229.584 229.61C237.572 221.612 244.329 212.861 249.856 203.589C216.73 202.688 190.313 181.969 166.927 163.566C150.902 150.928 137.057 140.044 124.302 138.108Z"
        fill="#E5E5E5"
      />
      <path
        d="M253.906 196.296H251.973C220.359 196.296 194.322 175.858 171.406 157.786C154.58 144.536 140.025 133.088 125.378 130.864C98.7289 126.752 71.6015 138.34 46.9351 164.27C40.4169 171.176 34.8902 178.132 30.4463 184.327L65.3435 49.6477C84.3496 50.7741 105.915 53.5973 129.71 59.0957C174.62 69.473 219.298 68.1072 249.624 64.9672C273.827 105.061 275.233 155.061 253.906 196.296Z"
        fill="#003462"
      />
      <path
        d="M57.8903 49.2886L18.3522 201.836C-9.23934 154.167 -5.16808 93.1281 30.601 49.3802C38.9123 48.9929 48.0251 48.9155 57.8903 49.2886Z"
        fill="#003462"
      />
      <path
        d="M124.295 138.108C65.3712 129.167 24.6379 207.138 24.209 207.94C23.9278 208.51 23.4988 208.933 22.9785 209.313C27.7951 216.459 33.3288 223.274 39.6149 229.61C92.0697 282.13 177.122 282.13 229.577 229.61C237.565 221.612 244.322 212.861 249.849 203.589C216.724 202.688 190.306 181.969 166.92 163.566C150.902 150.928 137.057 140.044 124.295 138.108Z"
        fill="#003462"
      />
    </svg>
  );
};
