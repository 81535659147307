/* eslint-disable no-param-reassign */
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, isEmpty, orderBy, map, sumBy} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {compose} from 'recompose';

import {COLOR, ROUTE} from 'app/constants';
import {DistributionPanels, IssueMatrix, HoldingsToggle} from 'app/components';
import faInfoCircle from 'app/fontawesome-pro-light/faInfoCircle';
import {
  getDetails,
  getDistributions,
  getSectorsByDistribution,
  getCompaniesByDistributionAndSector,
  getIssueMatrix,
} from 'app/redux/planDashboard';

const DASHBOARD_DISTRIBUTION_SECTORS = {datasets: [{}]};

class HoldingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWeight: true,
    };

    // TODO: Change all of these to fetchIfNeeded
    props.dispatch(getDetails());
    props.dispatch(getDistributions());
    props.dispatch(getIssueMatrix());

    this.onDistributionClick = this.onDistributionClick.bind(this);
    this.onSectorClick = this.onSectorClick.bind(this);
    this.onCompanyClick = this.onCompanyClick.bind(this);
  }

  onDistributionClick(distribution) {
    // dispatch after animation is complete (0.3s transition on panels)
    setTimeout(() => {
      this.props.dispatch(getSectorsByDistribution(distribution));
    }, 600);
  }

  onSectorClick(distribution, sectorId) {
    // dispatch after animation is complete (0.3s transition on panels)
    setTimeout(() => {
      this.props.dispatch(getCompaniesByDistributionAndSector(distribution, sectorId));
    }, 600);
  }

  onCompanyClick(isin) {
    this.props.dispatch(push(ROUTE.COMPANY_DETAIL.path(isin) + '?ref=holdings'));
  }

  render() {
    const {planDashboard} = this.props;

    if (planDashboard.hasError || planDashboard.error) {
      return (
        <div className="p-content-lg">
          <div className="card is-white mx-auto" style={{maxWidth: 800}}>
            <h1>An Error has Occurred</h1>
            <p>
              An unexpected error has occurred and your Harmony Ratings are not currently available. Please try
              refreshing the page, or check again later.
            </p>
            <p>If you have any questions or need any assistance, please contact us.</p>
            <Link to={ROUTE.CONTACT.path()} className="btn btn-primary btn-lg" style={{maxWidth: 250}}>
              Contact Us
            </Link>
          </div>
        </div>
      );
    }

    if (isEmpty(planDashboard) || isEmpty(planDashboard.details)) {
      return <span />;
    }

    if (planDashboard.details.isEmpty) {
      return (
        <div className="p-content-lg">
          <h1>{planDashboard.details.name}: Next Steps</h1>
          <div className="dashboard-card-grid">
            <div className="dashboard-card mr-auto ml-auto">
              <div className="dashboard-card-content">
                <div className="dashboard-card-header">
                  <div className="dashboard-card-name">Invite Managers</div>
                </div>
                <div className="dashboard-card-body">
                  <p>
                    Thank you for joining the movement. Continue to invite your managers to Harmony Analytics. Once they
                    have onboarded with Harmony Analytics and have uploaded their portfolios, your page will begin to
                    populate your ESG standing throughout your Plan.
                  </p>
                </div>
                <div className="dashboard-card-footer">
                  <Link to={ROUTE.SETTINGS_FIRMS_MANAGER.path()} className="w-100 btn btn-outline">
                    Invite Your Managers
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="dashboard-card is-main">
              <div className="dashboard-card-content">
                <div className="dashboard-card-header">
                  <div className="dashboard-card-name">Assign Harmony Index</div>
                </div>
                <div className="dashboard-card-body">
                  <p>If you have passive strategies in your portfolio, consider changing your passive
                    strategy to a Harmony Index. Harmony Indexes are developed to consider ESG measures
                    and security performance. Click below to access your Harmony Index Center and we will help
                    you change your index in three easy steps. You may also find these indexes located
                    in the Harmony Index Center under your Settings tab.</p>
                </div>
                <div className="dashboard-card-footer">
                  <Link to={ROUTE.HARMONY_INDEX_CENTER.path()} className="w-100 btn btn-primary"
                  >Learn about Harmony Index Center</Link>
                </div>
              </div>
            </div> */}
            {/* <div className="dashboard-card">
              <div className="dashboard-card-content">
                <div className="dashboard-card-header">
                  <div className="dashboard-card-name">Send IMA Amendment to Managers</div>
                </div>
                <div className="dashboard-card-body">
                  <p>Now that you have onboarded with Harmony Analytics, please consider the
                    following suggested Amendment organized for you based on your ESG settings
                    set during onboarding. If you wish to change any of these settings you may
                    update them under Manage ESG. This copy should be reviewed by your legal
                    team before sending to your managers.</p>
                </div>
                <div className="dashboard-card-footer">
                  <Link to={ROUTE.SETTINGS_ESG_DRAFT.path()} className="w-100 btn btn-outline"
                  >View IMA Template</Link>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row mt-12">
            <div className="col text-center">
              <h2 className="mb-0">Questions? Contact Us.</h2>
              <p className="mr-auto ml-auto">If you have any questions or need any assistance, please contact us.</p>
              <Link to={ROUTE.CONTACT.path()} className="btn btn-lg">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      );
    }

    const planSponsorName = get(planDashboard, 'details.name');
    const planDistributions = get(planDashboard, 'distributions.planDistributions', []);
    const planDistributionsByCount = get(planDashboard, 'distributions.planDistributionsByCount', []);
    const indexUniverseDistributions = get(planDashboard, 'distributions.indexUniverseDistributions', []);
    const indexUniverseDistributionsByCount = get(planDashboard, 'distributions.indexUniverseDistributionsByCount', []);
    const currentDistributionSectors = get(planDashboard, 'currentDistributionSectors', []);
    const currentSectorCompanies = get(planDashboard, 'currentSectorCompanies', []);
    const issues = get(planDashboard, 'issues');

    // Format Distribution data for bar chart
    const distributionData = [];
    distributionData.push({
      label: 'Holdings',
      data: map(
        orderBy(this.state.showWeight ? planDistributions : planDistributionsByCount, ['rating'], ['asc']),
        distribution => {
          return Math.round(distribution.percent, 0);
        },
      ),
    });
    distributionData.push({
      label: 'Universe',
      data: map(
        orderBy(
          this.state.showWeight ? indexUniverseDistributions : indexUniverseDistributionsByCount,
          ['rating'],
          ['asc'],
        ),
        distribution => {
          return Math.round(distribution.percent, 0);
        },
      ),
      color: COLOR.LIGHT_GRAY,
    });

    // Format Sectors-in-Distribution data for doughnut chart
    const sortedDistributionSectors = orderBy(
      currentDistributionSectors,
      ['totalMarketValue', 'name'],
      ['desc', 'asc'],
    );
    const distributionSectorsTotalMarketValue = sumBy(sortedDistributionSectors, 'totalMarketValue');
    const distributionSectors = DASHBOARD_DISTRIBUTION_SECTORS; // not cloning == chart transitions
    const sectorDataset = distributionSectors.datasets[0];
    if (distributionSectorsTotalMarketValue > 0) {
      distributionSectors.labels = map(sortedDistributionSectors, sector => sector.name);
      distributionSectors.sectorIds = map(sortedDistributionSectors, sector => sector.sectorId);
      let total = 0;
      sectorDataset.data = map(sortedDistributionSectors, sector => {
        const value = Number(((sector.totalMarketValue / distributionSectorsTotalMarketValue) * 100).toFixed(1));

        // Ensures that the total doesn't ever go past 100
        if (total + value > 100) {
          const final = Number((100 - total).toFixed(1));
          total += final;
          return final;
        }

        total += value;
        return value;
      });
      sectorDataset.label = distributionSectorsTotalMarketValue.toString();
    }

    // Format Companies-in-Sector data for list
    const sectorCompaniesTotalMarketValue = sumBy(currentSectorCompanies, 'totalMarketValue');
    let sectorCompanies = [];
    if (sectorCompaniesTotalMarketValue > 0) {
      sectorCompanies = map(currentSectorCompanies, company => {
        const holding = Number(((company.totalMarketValue / sectorCompaniesTotalMarketValue) * 100).toFixed(1));
        return {
          ...company,
          holding,
        };
      });
    }

    const isIssueDataValid = !isEmpty(issues);

    return (
      <div>
        <div className="dashboard-container pt-12 px-8" style={{display: 'flex'}}>
          <h1 className="mb-0">{planSponsorName}</h1>
          <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Link to={ROUTE.RATINGS_CENTER.path()}>
              <Icon icon={faInfoCircle} /> Harmony Ratings Center
            </Link>
          </div>
        </div>
        <div className="dashboard-section">
          <div className="dashboard-container px-8">
            <DistributionPanels
              distributionData={distributionData}
              sectorData={distributionSectors}
              companyData={sectorCompanies}
              sectorDataTimeStamp={planDashboard.currentDistributionSectorsTimeStamp}
              companyDataTimeStamp={planDashboard.currentSectorCompaniesTimeStamp}
              onDistributionClick={this.onDistributionClick}
              onSectorClick={this.onSectorClick}
              onCompanyClick={this.onCompanyClick}
              {...this.props}
            />
          </div>
        </div>
        <div className="dashboard-container">
          <HoldingsToggle
            label={this.state.showWeight ? 'Weight' : 'Count'}
            checked={this.state.showWeight}
            onChange={e => {
              this.setState({showWeight: e.target.checked});
            }}
          />
        </div>
        {isIssueDataValid && (
          <div className="dashboard-container p-8">
            <IssueMatrix issues={issues} title="Issue Rating" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {planDashboard: state.planDashboard};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const Holdings = compose(connect(mapStateToProps, null, mergeProps))(HoldingsPage);
