import {Theme} from '../theme';

export const Box = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 13.3329V6.66626C17.9997 6.37399 17.9225 6.08693 17.7763 5.8339C17.63 5.58086 17.4198 5.37073 17.1667 5.22459L11.3333 1.89126C11.08 1.74498 10.7926 1.66797 10.5 1.66797C10.2074 1.66797 9.92003 1.74498 9.66667 1.89126L3.83333 5.22459C3.58022 5.37073 3.36998 5.58086 3.22372 5.8339C3.07745 6.08693 3.0003 6.37399 3 6.66626V13.3329C3.0003 13.6252 3.07745 13.9123 3.22372 14.1653C3.36998 14.4183 3.58022 14.6285 3.83333 14.7746L9.66667 18.1079C9.92003 18.2542 10.2074 18.3312 10.5 18.3312C10.7926 18.3312 11.08 18.2542 11.3333 18.1079L17.1667 14.7746C17.4198 14.6285 17.63 14.4183 17.7763 14.1653C17.9225 13.9123 17.9997 13.6252 18 13.3329Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.22461 5.80078L10.4996 10.0091L17.7746 5.80078"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.5 18.4V10" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
