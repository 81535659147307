import {get, isEmpty, map, startsWith, sumBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';
import moment from 'moment';

// Local Imports
import {COLOR, PORTFOLIO_MANAGEMENT_TYPES} from 'app/constants';
import {errorRequiredFields} from 'app/utilities';
import {Button, FormFeedback} from '.';
import {TextFormGroup, Checkbox} from './forms';

const validate = values => {
  const errors = errorRequiredFields(values, ['name']);

  return errors;
};

const renderStrategies = ({fields: strategies, totalLicenseCount}) => {
  const styles = {
    container: {fontSize: '1rem'},
    itemHeading: {
      fontSize: '1.25rem',
      lineHeight: '1.25em',
      marginBottom: '0.5rem',
    },
    item: {
      paddingBottom: '0.25rem',
      paddingTop: '0.25rem',
    },
    itemWithHeading: {marginTop: '3rem'},
    itemWithoutHeading: {borderTop: `1px solid ${COLOR.LIGHTEST_GRAY}`},
  };

  // Prepare current & total license comparison
  const currentLicenseCount = sumBy(strategies.getAll(), ({hasLicense}) => (hasLicense ? 1 : 0));
  let licenseStatusClassName = 'mb-0 ';
  if (currentLicenseCount > totalLicenseCount) licenseStatusClassName += 'text-danger ';

  let lastPlanSponsorName;
  return (
    !isEmpty(strategies) && (
      <div style={styles.container}>
        <h1 className={licenseStatusClassName} style={{fontSize: '1.5rem'}}>
          {currentLicenseCount || 0} of {totalLicenseCount || 0} licenses in use
        </h1>
        <hr className="my-7" />

        {strategies.map((strategyKey, index) => {
          let optionalHeader;
          let style;
          const strategy = strategies.get(index);

          // Add a Plan Sponsor Name header on its first appearance in a row
          const planSponsorName = get(strategy, 'planSponsor.internalName', 'Untitled Strategy');
          if (planSponsorName !== lastPlanSponsorName) {
            lastPlanSponsorName = planSponsorName;
            optionalHeader = <h1 style={styles.itemHeading}>{planSponsorName}</h1>;
            style = index > 0 ? {...styles.item, ...styles.itemWithHeading} : styles.item;
          } else {
            optionalHeader = null;
            style = {...styles.item, ...styles.itemWithoutHeading};
          }

          const marketIndexName = get(strategy, 'marketIndex.name', 'Index not specified');
          // Harmony Indexes ideally are a paid feature, so highlight any discrepancies.
          const shouldBePaid = !strategy.hasLicense && startsWith(marketIndexName, 'Harmony ');

          return (
            <div key={index} style={style}>
              {optionalHeader}
              <div className="d-flex align-items-start">
                <div className="w-11r pr-3">
                  <strong>{strategy.name}</strong>
                </div>
                <div className="pr-3 flex-fill">
                  <div className={shouldBePaid ? 'text-danger' : ''}>
                    {marketIndexName} {shouldBePaid && ' (Unpaid)'}
                  </div>
                  <i>({strategy.portfolioManagementType || PORTFOLIO_MANAGEMENT_TYPES[0]} Management)</i>
                </div>
                <div className="w-8r">
                  <Field
                    label={'Has License'}
                    name={`${strategyKey}.hasLicense`}
                    type="checkbox"
                    component={Checkbox}
                  />
                </div>
                <div className="w-5r">
                  {strategy.hasLicense && (moment(strategy.licensedDate) || moment()).format('MM/DD/YYYY')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

class AdminEditFirmFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);
    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <Field
            label="Firm Name"
            name="name"
            type="text"
            autoComplete="firm-name"
            component={TextFormGroup}
            className="col-4"
            disabled={true}
          />
          <Field
            label="Total Licenses"
            name="totalLicenses"
            type="number"
            component={TextFormGroup}
            className="col-1"
            disabled={!this.props.canWrite}
          />

          <FieldArray
            name="strategies"
            component={renderStrategies}
            totalLicenseCount={this.props.totalLicenses}
            rerenderOnEveryChange={true}
          />

          <div className="form-footer is-right">
            <Button type="submit" disabled={!this.props.canWrite}>
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

AdminEditFirmFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  fieldValues: PropTypes.object,
  changeFieldValue: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);

  // Populate the "hasLicense" field if licensedDate is present.
  const initialValues = {...ownProps.firmData};
  initialValues.strategies = map(initialValues.strategies, strategy => ({
    ...strategy,
    hasLicense: !!strategy.licensedDate,
  }));

  return {
    form: ownProps.formName,
    initialValues,
    totalLicenses: selector(state, 'totalLicenses'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFieldValue: (field, value) => {
    dispatch(change(ownProps.formName, field, value));
  },
  dispatch,
});

export const AdminEditFirmForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
  })(AdminEditFirmFormComponent),
);
