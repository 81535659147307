import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';

// Local Imports
import {Alert, Button, FormFeedback} from 'app/components';
import {Checkbox, TextFormGroup} from 'app/components/forms';
import {ROUTE} from 'app/constants';
import {errorRequiredFields} from 'app/utilities';

const validate = values => {
  const errors = errorRequiredFields(values, ['password', 'confirmPassword', 'agreeToTerms']);

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Must match Password';
  }

  if (values.password && values.password.length < 8) {
    errors.password = (
      <span>
        <strong>Password Strength:</strong> Too Short
      </span>
    );
  }

  return errors;
};

class OnboardingAccountFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        {!this.props.onboardingSteps.ACCOUNT.isComplete && (
          <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        )}
        <div className="form-grid">
          <div className="form-group min-h-0 mb-12">
            <label>Email (Username)</label>
            <div className="text-muted">{get(this.props, 'onboardingUser.email')}</div>
            {/* Hidden Non Password field for accessibility  */}
            <input className="d-none" type="text" autoComplete="non-password" />
          </div>

          {this.props.onboardingSteps.ACCOUNT.isComplete ? (
            <div>
              <Alert color="white">
                You have already created your account. You will be able to change your password once you have completed
                the account setup process. If at any time you forget your password, please use the{' '}
                <Link to={ROUTE.FORGOT_PASSWORD.path()}>forgot password</Link> flow to reset it.
              </Alert>
            </div>
          ) : (
            <Fragment>
              <Field
                className="mb-6"
                autoComplete="new-password"
                label="Choose a Password*"
                name="password"
                type="password"
                component={TextFormGroup}
                hasSplitFeedback={true}
              >
                <span className="form-text text-muted float-left">Minimum of 8 characters</span>
              </Field>

              <Field
                className="mb-6"
                autoComplete="new-password"
                label="Confirm Your Password*"
                name="confirmPassword"
                type="password"
                component={TextFormGroup}
                hasSplitFeedback={true}
              >
                <span className="form-text text-muted float-left">Minimum of 8 characters</span>
              </Field>

              <small>
                <Field
                  label={
                    <span className="font-weight-normal">
                      I agree with the{' '}
                      <Link target="_blank" to={ROUTE.TERMS.path()}>
                        {ROUTE.TERMS.title}
                      </Link>
                      *
                    </span>
                  }
                  name="agreeToTerms"
                  value="yes"
                  component={Checkbox}
                />
              </small>
            </Fragment>
          )}

          <div className="form-footer">
            <Button type="submit">Continue</Button>
          </div>
        </div>
      </form>
    );
  }
}

OnboardingAccountFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingUser: PropTypes.object,
};

OnboardingAccountFormComponent.defaultProps = {onboardingUser: {}};

export const OnboardingAccountForm = reduxForm({
  form: 'onboarding-create-account',
  validate,
})(OnboardingAccountFormComponent);
