import React from 'react';
import {find, get, isEmpty, map, merge, pick, orderBy, some} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {branch, renderComponent} from 'recompose';
import {Redirect} from 'react-router-dom';

import {ONBOARDING_STEPS_V2, ROUTE, ROLE} from 'app/constants';
import {getInvitationByCode} from 'app/redux/onboardingUser';

const mapStateToProps = (state, ownProps) => {
  const {invitationCode} = ownProps?.match?.params || {};
  const onboardingSteps = get(state, 'onboardingUser.invitation.onboardingSteps');
  const isOnboardingComplete = get(state, 'onboardingUser.invitation.isOnboardingComplete') || false;
  const onboardingUser = get(state, 'onboardingUser.user', {});
  const userRole = get(state, 'onboardingUser.user.roles[0]', ROLE.USER_TYPE_1);
  const {pathname} = ownProps?.location || state?.router?.location || {};
  let onboardingInfo = {};

  if (!isEmpty(onboardingUser)) {
    // Merge ONBOARDING_STEPS with the user's saved onboardingSteps, picking only official steps
    const userOnboardingSteps = pick(
      merge({}, ONBOARDING_STEPS_V2[userRole], onboardingSteps),
      Object.keys(ONBOARDING_STEPS_V2[userRole]),
    );

    // Turn the merged onboarding steps into an array, to sort by stepOrder
    const userOnboardingStepsArray = orderBy(
      map(userOnboardingSteps, (value, key) => ({
        step: key,
        ...value,
        path: value.route.path(invitationCode),
        label: value.shortTitle,
      })),
      ['stepOrder'],
      ['asc'],
    );

    const hasOnboardingStarted = some(userOnboardingStepsArray, {isComplete: true});

    // Get the next available step based on what is not complete, defaulting to last step if one cannot be found
    let nextAvailableStep = find(userOnboardingStepsArray, {isComplete: false});
    if (!nextAvailableStep) {
      nextAvailableStep = find(userOnboardingStepsArray, {step: ONBOARDING_STEPS_V2[userRole].FINALIZE.key});
    }
    const requestedStep = find(
      userOnboardingStepsArray,
      onboardingStep => onboardingStep.path.toLowerCase() === pathname.toLowerCase(),
    );

    // Determine whether user has access to the current requested step.  They should not be able to get further than
    // the next available step, even if they completed a future step somehow
    const hasAccessToStep = requestedStep && requestedStep.stepOrder <= nextAvailableStep?.stepOrder;

    onboardingInfo = {
      onboardingSteps: userOnboardingSteps,
      onboardingStepsArray: userOnboardingStepsArray,
      onboardingUser,
      isOnboardingComplete,
      hasOnboardingStarted,
      nextAvailableStep,
      requestedStep,
      hasAccessToStep,
      userRole,
    };
  }

  return {
    invitationCode,
    error: get(state, 'onboardingUser.error'),
    hasInvitationCodeError: get(state, 'onboardingUser.hasError'),
    isFetching: get(state, 'onboardingUser.isFetching'),
    onboardingInvitation: get(state, 'onboardingUser.invitation', {}),
    onboardingPlanSponsor: get(state, 'onboardingUser.planSponsor', {}),
    ...onboardingInfo,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  const {
    error,
    hasInvitationCodeError,
    invitationCode,
    isFetching,
    isOnboardingComplete,
    onboardingUser,
    onboardingInvitation,
    hasAccessToStep,
    nextAvailableStep,
  } = stateProps;
  const {pathname} = ownProps?.location || {};
  const isOnboardingUserInState = !!get(onboardingUser, 'id');
  const isInvitationInvalid = hasInvitationCodeError && error.message.toString() === 'Invitation Code is not valid.';
  const isOnboardingLandingPage = pathname === ROUTE.ONBOARDING_LANDING.path(invitationCode);
  const hasUnexpectedError = !isInvitationInvalid && (!onboardingUser || !onboardingUser.id);

  let redirectPath;

  // If redux store is empty, go fetch the onboarding info, otherwise determine if redirects are needed
  if (invitationCode !== get(onboardingInvitation, 'code') && !hasInvitationCodeError && !isFetching) {
    dispatch(getInvitationByCode(invitationCode));
  } else if ((isOnboardingUserInState || isInvitationInvalid) && !isOnboardingLandingPage) {
    if (isInvitationInvalid || isOnboardingComplete) {
      redirectPath = ROUTE.ONBOARDING_LANDING.path(invitationCode);
    } else if (!hasAccessToStep) {
      redirectPath = nextAvailableStep.route.path(invitationCode);
    }
  }

  return Object.assign({}, stateProps, dispatchProps, ownProps, {redirectPath, hasUnexpectedError});
};

export const withOnboardingUser = compose(
  // First check for the user in state
  connect(mapStateToProps, null, mergeProps),
  branch(
    ({redirectPath}) => !!redirectPath,
    renderComponent(({redirectPath}) => {
      return <Redirect to={redirectPath} />;
    }),
  ),
);
