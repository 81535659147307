import {filter, find, forEach, get, isEmpty, map, orderBy} from 'lodash';
import numeral from 'numeral';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';

// Local Imports
import {withUser} from 'app/utilities';
import {ROUTE} from 'app/constants';
import {TooltipLink} from 'app/components';
import {getGlobalSettings} from 'app/redux/settings';
import {fetchIssuesIfNeeded} from 'app/redux/issue';
import {getCompanyEngagement} from 'app/redux/companyEngagement';

let count = 0;
const getChildrenCounts = (childIssues, allIssues, issuesToCount, currentCounts = {}) => {
  if (count > 1000) return 0;
  count += 1;

  let newCounts = currentCounts;
  forEach(childIssues, childIssue => {
    const issueToCount = find(issuesToCount, {issueId: childIssue.id});
    if (!isEmpty(issueToCount)) {
      newCounts.engagementsCount += Number(issueToCount.engagementsCount);
      newCounts.engagedCount += Number(issueToCount.engagedCount);
      newCounts.actionPlansImplementedCount += Number(issueToCount.actionPlansImplementedCount);
    }

    const grandChildIssues = filter(allIssues, {parentId: childIssue.id});
    if (!isEmpty(grandChildIssues)) {
      newCounts = getChildrenCounts(grandChildIssues, allIssues, issuesToCount, newCounts);
    }
  });

  return newCounts;
};

class CompanyEngagementPage extends Component {
  componentDidMount() {
    this.props.dispatch(getGlobalSettings());
    this.props.dispatch(fetchIssuesIfNeeded());
    this.props.dispatch(getCompanyEngagement());
  }

  /**
   * Renders all of the child issues based on an Issues ID
   */
  renderChildIssues = issueId => {
    const {issues, companyEngagementData, globalSettings} = this.props;

    if (isEmpty(companyEngagementData) || isEmpty(issues)) {
      return <span />;
    }

    const issue = find(issues, {id: issueId});

    const childIssues = orderBy(filter(issues, {parentId: issueId}), ['displayOrder']);

    const totalCounts = {
      engagementsCount: 0,
      engagedCount: 0,
      actionPlansImplementedCount: 0,
    };

    const issueCounts = map(childIssues, childIssue => {
      const counts = getChildrenCounts(
        filter(issues, {parentId: childIssue.id}),
        issues,
        companyEngagementData.companyEngagementIssues,
        {engagementsCount: 0, engagedCount: 0, actionPlansImplementedCount: 0},
      );

      totalCounts.engagementsCount += counts.engagementsCount;
      totalCounts.engagedCount += counts.engagedCount;
      totalCounts.actionPlansImplementedCount += counts.actionPlansImplementedCount;

      // Only show issues that have received engagement letters
      if (counts.engagementsCount === 0) {
        return null;
      }

      return (
        <tr key={childIssue.id}>
          <td>{childIssue.name}</td>
          <td className="company-engagement-count">{numeral(counts.engagementsCount).format('0,0')}</td>
          <td className="company-engagement-count">{numeral(counts.engagedCount).format('0,0')}</td>
          <td className="company-engagement-count">{numeral(counts.actionPlansImplementedCount).format('0,0')}</td>
        </tr>
      );
    });

    return (
      <table className="table company-engagement-table">
        <thead>
          <tr>
            <th>
              <h2 className="h2-alt mb-0">{issue.name}</h2>
            </th>
            <th className="company-engagement-count">
              {issueId === 1 ? (
                <React.Fragment>
                  {(globalSettings.engagementIssuesCountTitle || '').trim() || 'Number of Engagements'}
                  <TooltipLink className="ml-2" id="engagementIssuesCountTooltip" label="">
                    {(globalSettings.engagementIssuesCountDescription || '').trim() ||
                      'The total number of ESG issues addressed in engagement letters.'}
                  </TooltipLink>
                </React.Fragment>
              ) : (
                ''
              )}
            </th>
            <th className="company-engagement-count">
              {issueId === 1 ? (
                <React.Fragment>
                  {(globalSettings.completedStep1CountTitle || '').trim() || 'Engaged'}
                  <TooltipLink className="ml-2" id="step1CompletedTooltip" label="">
                    {(globalSettings.completedStep1CountDescription || '').trim() ||
                      'The total number of responses for each issue engaged on.'}
                  </TooltipLink>
                </React.Fragment>
              ) : (
                ''
              )}
            </th>
            <th className="company-engagement-count">
              {issueId === 1 ? (
                <React.Fragment>
                  {(globalSettings.completedStep5CountTitle || '').trim() || 'Action Plans Implemented'}
                  <TooltipLink className="ml-2" id="step5CompletedTooltip" label="">
                    {(globalSettings.completedStep5CountDescription || '').trim() ||
                      'The total number of action plans that have been completed, as informed by the company'}
                  </TooltipLink>
                </React.Fragment>
              ) : (
                ''
              )}
            </th>
          </tr>
        </thead>
        <tbody>{issueCounts}</tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td className="company-engagement-count">{numeral(totalCounts.engagementsCount).format('0,0')}</td>
            <td className="company-engagement-count">{numeral(totalCounts.engagedCount).format('0,0')}</td>
            <td className="company-engagement-count">
              {numeral(totalCounts.actionPlansImplementedCount).format('0,0')}
            </td>
          </tr>
        </tfoot>
      </table>
    );
  };

  render() {
    const {companyEngagementData} = this.props;

    return (
      <div className="ratings-center">
        <div className="p-content-lg">
          <h1>
            {companyEngagementData.year} {ROUTE.COMPANY_ENGAGEMENT.title}
          </h1>
          <p>
            Harmony Analytics'<sup>&reg;</sup> engages companies on behalf of capital owners who are signed up to
            empower change. Harmony's team identifies a company's issue in need of improvement and sends them an
            engagement letter requesting that the company addresses and describes the action plan for the issue
            identified.
          </p>
        </div>

        <div className="p-content-lg background-divider">
          {this.renderChildIssues(1)}
          {this.renderChildIssues(2)}
          {this.renderChildIssues(3)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    issues: !get(state, 'issue.isFetching') && get(state, 'issue.issues'),
    globalSettings: !get(state, 'settings.isGlobalSettingsFetching') && get(state, 'settings.global', {}),
    companyEngagementData:
      !get(state, 'companyEngagement.isFetching') && get(state, 'companyEngagement.companyEngagementData', {}),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const CompanyEngagement = compose(withUser, connect(mapStateToProps, null, mergeProps))(CompanyEngagementPage);
