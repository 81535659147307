import {times} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {compose, withProps} from 'recompose';

import {Checkbox, TextareaFormGroup, TextFormGroup} from 'app/components/forms';
import {normalizeMMDDYYYY} from 'app/utilities';
import {Button, FormFeedback, TooltipLink} from '.';

const AdminEditSiteSettingsFormComponent = ({error, handleSubmit, isErrorFormFeedbackVisible, canWrite}) => (
  <form method="POST" onSubmit={handleSubmit}>
    <FormFeedback message={error} visible={isErrorFormFeedbackVisible} />
    <div className="form-grid">
      <h2 className="h2-alt">Engagement Settings</h2>
      <div className="form-grid">
        <div className="mb-6">
          <Field
            label="Show Company Engagement Page Link on Dashboard"
            name="isCompanyEngagementPageLinkVisible"
            type="checkbox"
            component={Checkbox}
            disabled={!canWrite}
          />
        </div>
      </div>
      <div className="form-grid mt-6">
        <Field
          label="Begin Date"
          labelHint={
            <TooltipLink className="float-right" id="beginDateTooltip">
              This is the begin date that impacts the date range for which to pull engagement letter statistics meant
              for the Dashboard and Company Engagement Page. Note that the Begin Date's year is the year that will show
              up on the Dashboard and Company Engagement Page as part of the title.
            </TooltipLink>
          }
          name="engagementStatisticsBeginAt"
          type="text"
          component={TextFormGroup}
          normalize={normalizeMMDDYYYY}
          className="col-1"
          maxLength="10"
          placeholder="MM/DD/YYYY"
          disabled={!canWrite}
        />
        <Field
          label="End Date"
          labelHint={
            <TooltipLink className="float-right" id="endDateTooltip">
              This is the end date that impacts the date range for which to pull engagement letter statistics meant for
              the Dashboard and Company Engagement Page.
            </TooltipLink>
          }
          name="engagementStatisticsEndAt"
          type="text"
          component={TextFormGroup}
          normalize={normalizeMMDDYYYY}
          className="col-1"
          maxLength="10"
          placeholder="MM/DD/YYYY"
          disabled={!canWrite}
        />
      </div>
      <div className="form-grid mt-6">
        {times(5, i => (
          <div className="col-1">
            <h3>Step {i + 1}</h3>
          </div>
        ))}
      </div>
      <div className="form-grid">
        {times(5, i => (
          <Field
            label="Show on Company Details"
            name={`isEngagementStep${i + 1}Visible`}
            type="checkbox"
            component={Checkbox}
            className="col-1 pl-8"
            disabled={!canWrite}
          />
        ))}
      </div>
      <div className="form-grid mt-6">
        {times(5, i => (
          <Field
            label="Title"
            name={`engagementStep${i + 1}Title`}
            type="text"
            component={TextFormGroup}
            className="col-1"
            disabled={!canWrite}
          />
        ))}
      </div>
      <div className="form-grid">
        {times(5, i => (
          <Field
            label="Description"
            name={`engagementStep${i + 1}Description`}
            type="text"
            maxLength="500"
            component={TextareaFormGroup}
            className="col-1 description"
            disabled={!canWrite}
          />
        ))}
      </div>
      <div className="form-grid mt-12">
        <div className="col-1">
          <h3>Engagement Issues Count</h3>
        </div>
        <div className="col-1">
          <h3>Completed Step 1 Count</h3>
        </div>
        <div className="col-1">
          <h3>Completed Step 5 Count</h3>
        </div>
      </div>
      <div className="form-grid">
        <Field
          label="Title"
          name="engagementIssuesCountTitle"
          type="text"
          component={TextFormGroup}
          className="col-1"
          disabled={!canWrite}
        />
        <Field
          label="Title"
          name="completedStep1CountTitle"
          type="text"
          component={TextFormGroup}
          className="col-1"
          disabled={!canWrite}
        />
        <Field
          label="Title"
          name="completedStep5CountTitle"
          type="text"
          component={TextFormGroup}
          className="col-1"
          disabled={!canWrite}
        />
      </div>
      <div className="form-grid">
        <Field
          label="Description"
          name="engagementIssuesCountDescription"
          type="text"
          maxLength="500"
          component={TextareaFormGroup}
          className="col-1 description"
          disabled={!canWrite}
        />
        <Field
          label="Description"
          name="completedStep1CountDescription"
          type="text"
          maxLength="500"
          component={TextareaFormGroup}
          className="col-1 description"
          disabled={!canWrite}
        />
        <Field
          label="Description"
          name="completedStep5CountDescription"
          type="text"
          maxLength="500"
          component={TextareaFormGroup}
          className="col-1 description"
          disabled={!canWrite}
        />
      </div>
      <div className="form-footer">
        <Button type="submit" disabled={!canWrite}>
          Save
        </Button>
      </div>
    </div>
  </form>
);

AdminEditSiteSettingsFormComponent.propTypes = {
  form: PropTypes.string.isRequired,
  globalSettings: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

// Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
const withErrorFormFeedbackVisible = withProps(({error, invalid, submitFailed}) => {
  return {isErrorFormFeedbackVisible: !!error || (!!submitFailed && invalid)};
});

export const AdminEditSiteSettingsForm = compose(
  reduxForm(),
  withErrorFormFeedbackVisible,
)(AdminEditSiteSettingsFormComponent);
