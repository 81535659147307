import {getSessionJwt} from 'app/utilities';

export function urlWithToken(url: string) {
  try {
    const token = getSessionJwt();

    return `${url}?bearer=${token}`;
  } catch (err) {
    console.error({err});
  }
}
