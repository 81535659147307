import React, {Component} from 'react';
import {find} from 'lodash';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import {fetchJSON} from 'app/services/http';
import {FormGroup} from './FormGroup';

class AsyncTypeaheadFirmFormGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: props.input.value,
    };
  }

  render() {
    return (
      <FormGroup {...this.props} typeahead isAsync labelKey="name">
        {(inputProps, wrapperProps) => (
          <AsyncTypeahead
            {...wrapperProps}
            ref={typeahead => {
              this.typeahead = typeahead;
            }}
            allowNew={false}
            clearButton={true}
            isLoading={this.state.isLoading}
            inputProps={inputProps}
            onBlur={event => {
              const {
                props: {options},
                clear,
              } = this.typeahead;
              const validOption = find(options, {name: event.target.value});
              if (validOption) {
                this.props.input.onChange([validOption]);
              } else {
                clear();
              }
            }}
            onSearch={query => {
              this.setState({isLoading: true});
              fetchJSON(`firm?q=${query}`, {method: 'get'}).then(response => {
                this.setState({
                  isLoading: false,
                  options: response,
                });
              });
            }}
            options={this.state.options || []}
          />
        )}
      </FormGroup>
    );
  }
}

export {AsyncTypeaheadFirmFormGroup};
