import {useParams} from 'react-router';
import {useGetStrategyAggregatePercentileRankHoldingsQuery} from 'v2/redux/typeormEndpoints';

export const useRating = () => {
  //@ts-ignore
  const {strategyId} = useParams();
  const {
    data: holdingsData,
    isLoading,
    isError,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 1});
  const getRatingPercentileRank = () => {
    let totalWeight = 0;
    let totalWeightedRanks = 0;

    (holdingsData ?? []).forEach((datum: any) => {
      totalWeight += Number(datum.amountHeld);
      totalWeightedRanks += Number(datum.amountHeld) * Number(datum.companyPctRank);
    });

    const percentileRank = Math.round((totalWeightedRanks / totalWeight) * 100) / 100;
    return percentileRank;
  };

  return {
    isLoading,
    isError,
    getRatingPercentileRank,
  };
};
