import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {FilterListItemProps, FilterListOptionProps} from 'v2/utilities/types/components/FilterList';
import {FlexContainer} from '../atoms/Containers';
import {ChevronRight} from '../atoms/icons';
import {Body1, Link2} from '../atoms/Typeface';
import {CheckboxInput} from './Checkbox';

const ListItem = styled.li<{disabled?: boolean}>`
  margin: 0;
  padding: 0;
  width: auto;
  list-style-type: none;
  border-bottom: 0.0625rem solid ${({theme, disabled}) => (disabled ? theme.colors.grey_3 + '40' : theme.colors.grey_3)};
  &:last-of-type {
    border-bottom: none;
  }
`;

const ListItemButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  background: transparent;
  width: 100%;
  height: 3.875rem;
  padding: 0 1.75rem;
  font-size: 0;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? 0.3 : 1)};
  &:hover {
    background-color: ${({theme, disabled}) => (disabled ? 'transparent' : theme.colors.grey_1)};
  }
`;

const Label = styled(Body1)`
  font-size: 1rem;
`;

const CheckLabel = styled(Body1)`
  color: ${({theme}) => theme.colors.dark};
  font-size: 0.875rem;
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabelContainer = styled(FlexContainer)`
  text-align: left;
  flex: 1;
`;

const SubLabel = styled(Link2)`
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.colors.blue};
`;

const SubLabelContainer = styled(FlexContainer)`
  color: ${({theme}) => theme.colors.blue};
  flex: 1;
  max-width: 170px;
  padding: 0 1.5625rem;
`;

export const FilterListItem = ({disabled, label, subLabel, onClick}: FilterListItemProps) => {
  return (
    <ListItem>
      <ListItemButton disabled={disabled} onClick={() => onClick?.()}>
        <FlexContainer width="100%" items="center" flex={1}>
          <LabelContainer>
            <Label>{label}</Label>
          </LabelContainer>
          <SubLabelContainer>
            <SubLabel>{subLabel}</SubLabel>
          </SubLabelContainer>
        </FlexContainer>
        <FlexContainer padding="0">
          <ChevronRight size={16} />
        </FlexContainer>
      </ListItemButton>
    </ListItem>
  );
};

export const FilterListOption = React.forwardRef<HTMLInputElement, FilterListOptionProps>(
  ({checked = false, disabled = false, onChange = () => {}, label, value: id}, ref) => {
    const [isActive, setIsActive] = useState(false);

    const handleChange = () => {
      if (!disabled) {
        onChange({[id]: {checked: !checked, disabled, onChange, label, value: id}});
      }
    };

    const handleMouseEffect = (value: boolean, disabled: boolean): void => {
      if (!disabled) {
        setIsActive(value);
      }
    };

    return (
      <ListItem disabled={disabled}>
        <ListItemButton
          onMouseDown={handleChange}
          onMouseEnter={() => handleMouseEffect(true, disabled)}
          onMouseLeave={() => handleMouseEffect(false, disabled)}
          disabled={disabled}
        >
          <FlexContainer padding="0 1.5625rem 0 0.875rem">
            <CheckboxInput ref={ref} id={id} checked={checked} active={isActive} disabled={disabled} />
          </FlexContainer>
          <FlexContainer items="center" flex={1}>
            <LabelContainer>
              <CheckLabel>{label}</CheckLabel>
            </LabelContainer>
          </FlexContainer>
        </ListItemButton>
      </ListItem>
    );
  },
);
