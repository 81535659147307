import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const MANAGER_TYPE = {
  MANAGER_TYPE_PUBLIC_EQUITY: 'Public Equity',
  MANAGER_TYPE_FIXED_INCOME: 'Fixed Income',
  MANAGER_TYPE_PRIVATE_EQUITY: 'Private Equity',
  MANAGER_TYPE_VENTURE_CAPITAL: 'Venture Capital',
  MANAGER_TYPE_OTHER: 'Other',
};

export const MANAGER_TYPES = map(MANAGER_TYPE, value => value);
