import {ToolTip} from 'app/components';
import {get} from 'lodash';
import React from 'react';

/**
 * Format the content of a Country cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
export const countryFormatter = (cell, row) => {
  if (!cell || cell.length === 0) {
    return <span></span>;
  }

  const displayText = get(row, 'country.code');
  const tooltipDisplay = get(row, 'country.name');

  const tooltipId = 'country_' + row.isin;
  return (
    <div>
      <span id={tooltipId}>{displayText}</span>
      <ToolTip target={tooltipId}>
        <span className="text-white">{tooltipDisplay}</span>
      </ToolTip>
    </div>
  );
};
