import {indexOf, isEmpty, sortBy} from 'lodash';
import {PHONE_TYPES} from 'app/constants';

// Sort phones by order of PHONE_TYPE enum, with unspecified going last
export const sortPhonesByType = phones => {
  if (isEmpty(phones)) return null;

  return sortBy(phones, phone => {
    const phoneTypeIndex = indexOf(PHONE_TYPES, phone.type);
    return phoneTypeIndex !== -1 ? phoneTypeIndex : PHONE_TYPES.length;
  });
};
