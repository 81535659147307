import {Theme} from '../theme';

export const LogoBadge2 = ({size = 270}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 269 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M229.34 39.369C187.433 -2.6471 124.585 -11.025 74.3032 14.1931L30.2158 184.327C34.6597 178.132 40.1865 171.176 46.7046 164.27C71.371 138.34 98.4914 126.752 125.148 130.871C139.794 133.096 154.349 144.543 171.176 157.793C194.091 175.865 220.129 196.303 251.742 196.303H253.676C279.854 145.676 271.775 81.8497 229.34 39.369Z"
        fill="#E5E5E5"
      />
      <path
        d="M39.4338 39.369C-4.56209 83.4197 -11.6498 150.414 18.1214 201.843L65.5135 18.9805C56.2039 24.5563 47.4146 31.322 39.4338 39.369Z"
        fill="#E5E5E5"
      />
      <path
        d="M124.065 138.108C65.1408 129.167 24.4075 207.138 23.9786 207.94C23.6973 208.51 23.2684 208.933 22.748 209.313C27.5646 216.459 33.0984 223.274 39.3845 229.61C91.8393 282.13 176.892 282.13 229.347 229.61C237.334 221.612 244.092 212.861 249.618 203.589C216.493 202.688 190.076 181.969 166.689 163.566C150.665 150.928 136.82 140.044 124.065 138.108Z"
        fill="#E5E5E5"
      />
      <path
        d="M22.6564 184.341L18.1212 201.836C15.0625 196.549 12.3905 191.1 10.1123 185.524C14.0218 185.066 18.2055 184.658 22.6564 184.341Z"
        fill="#003462"
      />
      <path
        d="M249.618 203.582C244.092 212.861 237.334 221.612 229.347 229.603C176.892 282.123 91.8391 282.123 39.3843 229.603C33.1052 223.267 27.5714 216.452 22.7549 209.306C23.2752 208.933 23.6971 208.503 23.9854 207.94C24.1471 207.644 29.8426 196.739 39.9539 183.588C64.2757 183.201 94.4618 185.503 129.486 193.599C166.014 202.04 202.388 202.709 231.013 200.97C236.976 202.484 243.17 203.406 249.618 203.582Z"
        fill="#003462"
      />
    </svg>
  );
};
