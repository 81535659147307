import styled from 'styled-components/macro';

type ContainerProps = {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  padding?: string;
  margin?: string;
  fullWidth?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: block;
  max-width: ${({maxWidth}) => maxWidth ?? 'initial'};
  max-height: ${({maxHeight}) => maxHeight ?? 'initial'};
  min-width: ${({minWidth}) => minWidth ?? 'auto'};
  min-height: ${({minHeight}) => minHeight ?? 'auto'};
  width: ${({width}) => width ?? 'auto'};
  height: ${({height}) => height ?? 'auto'};
  padding: ${({padding}) => padding ?? '0'};
  margin: ${({margin}) => margin ?? '0'};
`;

export const CardContainer = styled(Container)`
  background-color: #fff;
  height: auto;
  width: auto;
  min-height: ${({minHeight}) => minHeight ?? '21.1875rem'};
  max-width: ${({maxWidth}) => maxWidth ?? 'initial'};
  max-height: ${({maxHeight}) => maxHeight ?? 'initial'};
  border-radius: ${({theme}) => theme.constants.border_radius};
  box-shadow: ${({theme}) => theme.shadows.shadow_2};
  padding: 3.8125rem 4.3125rem;
`;

export const Wrapper = styled.div<ContainerProps>`
  display: block;
  max-width: ${({theme, maxWidth, fullWidth}) => (maxWidth ?? fullWidth ? 'initial' : theme.constants.fixWidth)};
  max-height: ${({maxHeight}) => maxHeight ?? 'auto'};
  min-width: ${({minWidth}) => minWidth ?? 'auto'};
  min-height: ${({minHeight}) => minHeight ?? 'auto'};
  width: ${({width}) => width ?? 'auto'};
  height: ${({height}) => height ?? 'auto'};
  padding: ${({padding}) => padding ?? '0'};
  margin: ${({margin}) => margin ?? '0'};
`;

type FlexContainerProps = ContainerProps & {
  direction?: string;
  wrap?: string;
  justify?: string;
  items?: string;
  content?: string;
  gap?: string;
  flex?: string | number;
};

type CellProps = {
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  display?: string;
  justify?: string;
  align?: string;
};

export const FlexContainer = styled(Wrapper)<FlexContainerProps>`
  display: flex;
  flex: ${({flex}) => flex ?? 0};
  flex-direction: ${({direction}) => direction ?? 'row'};
  flex-wrap: ${({wrap}) => wrap ?? 'nowrap'};
  justify-content: ${({justify}) => justify ?? ' flex-start'};
  align-items: ${({items}) => items ?? 'stretch'};
  align-content: ${({content}) => content ?? 'stretch'};
  gap: ${({gap}) => gap ?? '0'};
  max-width: ${({theme, maxWidth, fullWidth}) =>
    maxWidth ? maxWidth : fullWidth ? 'initial' : theme.constants.fixWidth};
  max-height: ${({maxHeight}) => maxHeight ?? 'auto'};
  min-width: ${({minWidth}) => minWidth ?? 'auto'};
  min-height: ${({minHeight}) => minHeight ?? 'auto'};
  width: ${({width}) => width ?? 'auto'};
  height: ${({height}) => height ?? 'auto'};
  padding: ${({padding}) => padding ?? '0'};
  margin: ${({margin}) => margin ?? '0'};
`;

export const TableCell = styled.div<CellProps>`
  width: ${({width}) => width ?? 'auto'};
  max-width: ${({maxWidth}) => maxWidth ?? '100%'};
  min-width: ${({minWidth}) => minWidth ?? 'auto'};
  display: ${({display}) => display ?? 'block'};
  justify-content: ${({justify}) => justify ?? 'flex-start'};
  align-items: ${({align}) => align ?? 'stretch'};
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AttachedWrapper = styled(Wrapper)`
  box-shadow: ${({theme}) => theme.shadows.shadow_2};
  background: ${({theme}) => theme.colors.white};
  border-radius: 0 0 3.84px 3.84px;
  margin: 0 auto 2rem;
`;

export const FloatingWrapper = styled(Wrapper)`
  box-shadow: ${({theme}) => theme.shadows.shadow_2};
  background: ${({theme}) => theme.colors.white};
  border-radius: 3.84px;
  margin: 0 auto 2rem;
`;
