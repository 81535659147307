import {filter, isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {reduxForm} from 'redux-form';

// Local Imports
import {Button, FormFeedback, SimpleTable} from 'app/components';
import {ROUTE} from 'app/constants';
import {withViewportSize} from 'app/utilities';

const INVESTMENT_MANAGER_TABLE_COLUMNS = {
  email: 'Primary Contact Email',
  jobRole: 'Role',
};

const arrayHasData = array => {
  return array && array.length > 0 && !isEmpty(array[0]);
};

class OnboardingUt3FinalizeFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const {invitationCode, viewportWidth} = this.props;

    const investmentManagersToInvite = filter(
      this.props.onboardingInvitation.investmentManagersToInvite,
      manager => !isEmpty(manager),
    );

    const hasInvitesToSend = arrayHasData(investmentManagersToInvite);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div style={{maxWidth: viewportWidth >= 1400 ? 800 : null}}>
          {/* {hasInvitesToSend ? (
            <p className="mb-12">
              You have now finished the setup process. Please review the
              individuals listed below and confirm that their contact information is correct.
              Once you click on "Confirm & Send Invites" we will automatically
              send the invitation to these individuals to join your firm on
              Harmony Analytics.
            </p>
          ) : (
            <p className="mb-12">
              You have opted not to invite any colleagues at this time.
              You can invite individuals at a later time by accessing the User Manager in your Settings menu.
            </p>
          )} */}

          <p className="mb-12">
            Your profile and firm details have been successfully saved. Thank you for joining the Harmony Platform.
            Please <Link to={ROUTE.CONTACT.path()}>contact our support team</Link> if you have any questions or
            concerns.
          </p>
          {/* <h3 className="mb-5">Invite Firm Colleagues</h3>
          {arrayHasData(investmentManagersToInvite)
            ? <SimpleTable
              columns={INVESTMENT_MANAGER_TABLE_COLUMNS}
              dataset={investmentManagersToInvite}
              className="mb-12"
            />
            : <p>You won't be inviting any colleagues at this time.</p>
          } */}
          <div className="form-footer is-right">
            <Link
              to={ROUTE.ONBOARDING_PROFILE.path(invitationCode)}
              className="btn btn-outline"
              style={{marginTop: '-0.25rem'}}
              onClick={() => {
                animateScroll.scrollToTop();
              }}
            >
              Go to Profile
            </Link>
          </div>
          <div className="form-footer is-right">
            <Button type="submit" solid>
              {/* {hasInvitesToSend ? 'Confirm & Send Invites' : 'Continue Without Sending'} */}
              Continue
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

OnboardingUt3FinalizeFormComponent.propTypes = {
  invitationCode: PropTypes.string.isRequired,
  onboardingInvitation: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export const OnboardingUt3FinalizeForm = compose(reduxForm({form: 'onboarding-ut3-finalize'}))(
  withViewportSize(OnboardingUt3FinalizeFormComponent),
);
