/* eslint-disable no-param-reassign */
import {each, map} from 'lodash';

import {ISSUE_CATEGORY} from './issueCategory';
import {ISSUE_TYPE} from './issueType';
import {ISSUE_ICON_PATH} from './issueIconPath';

// Changing the order of these enums may change the order they appear in the UI
export const ISSUE_OLD = {
  E1: {
    name: 'Scope 1 & 2 Greenhouse Gas Emissions',
    description: 'CO2 produced by companies for every $1,000,000 in sales',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Mt CO<sub>2</sub>',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V2.CO2,
  },
  E2: {
    name: 'Scope 3 Greenhouse Gas Emissions',
    description: 'CO2 produced by companies for every $1,000,000 in sales',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Mt CO<sub>2</sub>',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.CO2,
  },
  E3: {
    name: 'Nitrogen Oxides',
    description: 'NOx produced by companies for every $1,000,000 in sales',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Mt NOx',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.NOX,
  },
  E4: {
    name: 'Sulfur Oxides',
    description: 'SOx produced by companies for every $1,000,000 in sales',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Mt SOx',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.SOX,
  },
  E5: {
    name: 'Climate Change Policy',
    description:
      'Have companies outlined their intention to help reduce global' +
      ' emissions of Greenhouse Gases, through ongoing operations and/or products and services',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.BOOLEAN,
    unit: ' Policies',
    iconPath: ISSUE_ICON_PATH.V1.POLAR_BEAR,
  },
  E6: {
    name: 'Energy Consumption',
    description: 'Total energy consumed by companies',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Gigajoules',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.LIGHT_BULB,
  },
  E7: {
    name: 'Renewable Energy',
    description: '% of Energy Consumed that was generated by a renewable energy source',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: '%',
    iconPath: ISSUE_ICON_PATH.V1.ENERGY_FARM,
  },
  E8: {
    name: 'Fossil Fuel % of Capacity',
    description: '% of total energy generation capacity from fossil fuels (e.g., coal,' + ' oil, gas, etc.)',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Capacity',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.OIL_RIG,
  },
  E9: {
    name: 'Total Water Consumption',
    description: 'Amount of water used by companies for every $1,000,000 in sales',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Gallons',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.DRIPPING_PIPE,
  },
  E10: {
    name: 'Water Policy',
    description:
      'Have the companies undertaken initiatives to reduce the quantity' +
      ' of water used or to improve the efficiency of its processes',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.BOOLEAN,
    unit: ' Policies',
    iconPath: ISSUE_ICON_PATH.V1.DROPLETS,
  },
  E11: {
    name: '% Water Recycled',
    description: 'Amount of water companies recycle as a % of total water consumption',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: '%',
    iconPath: ISSUE_ICON_PATH.V1.DROPLET_CYCLE,
  },
  E12: {
    name: 'Total Waste',
    description: 'Amount of total hazardous and non-hazardous waste discarded by companies',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Tons',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.TRASH,
  },
  E13: {
    name: 'Waste Reduction Policy',
    description: 'Have companies implemented any initiatives to reduce waste generated',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.BOOLEAN,
    unit: ' Policies',
    iconPath: ISSUE_ICON_PATH.V1.TRASH,
  },
  E14: {
    name: 'Waste Recycled',
    description: 'Amount of waste companies have recycled as related to the total' + ' amount of waste generated',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: '%',
    iconPath: ISSUE_ICON_PATH.V1.RECYCLE,
  },
  E15: {
    name: 'Hazardous Waste',
    description: 'Waste produced by companies for every $1,000,000 in sales',
    category: ISSUE_CATEGORY.ENVIRONMENT,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Tons',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.HAZARD,
  },
  S1: {
    name: '% of Women in Senior Management',
    description: '% of women employed in senior management positions',
    category: ISSUE_CATEGORY.SOCIAL,
    type: ISSUE_TYPE.PERCENTILE,
    unit: '%',
    iconPath: ISSUE_ICON_PATH.V1.FEMALE,
  },
  S2: {
    name: 'Lost Time Incident Rate',
    description: 'Total number of incidents at companies resulting in lost time from work',
    category: ISSUE_CATEGORY.SOCIAL,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Hours',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.TIME_DOWN,
  },
  S3: {
    name: 'Lost Time Incident Rate (Contractors)',
    description:
      'Number of incidents involving contractors at companies involving' +
      ' contractors resulting in lost time from work',
    category: ISSUE_CATEGORY.SOCIAL,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Hours',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.TIME_DOWN,
  },
  S4: {
    name: 'Total Recordable Incident Rate',
    description:
      'Total number of recordable incidents generally, recordable injury or' +
      ' illness under OSHA is one that requires medical treatment beyond first aid, as well' +
      ' as one that causes death, days away from work, restricted work or transfer to' +
      ' another job, or loss of consciousness',
    category: ISSUE_CATEGORY.SOCIAL,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Incidents',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.FINGER_BANDAGE,
  },
  S5: {
    name: 'Total Recordable Incident Rate (Contractors)',
    description: 'Number of recordable incidents for contractors',
    category: ISSUE_CATEGORY.SOCIAL,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Incidents',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.FINGER_BANDAGE,
  },
  S6: {
    name: 'Deaths per 1000',
    description: 'Total number of fatalities reported at companies inclusive of contractors',
    category: ISSUE_CATEGORY.SOCIAL,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' Deaths',
    isHigherWorse: true,
    iconPath: ISSUE_ICON_PATH.V1.HEART_BEAT,
  },
  G1: {
    name: 'Board Size',
    description: 'Board Size by number of board members across Index',
    category: ISSUE_CATEGORY.GOVERNANCE,
    type: ISSUE_TYPE.PERCENTILE,
    unit: ' People',
    iconPath: ISSUE_ICON_PATH.V1.MEETING_TABLE,
  },
  G2: {
    name: 'Women % of Board',
    description: '% of board that are women',
    category: ISSUE_CATEGORY.GOVERNANCE,
    type: ISSUE_TYPE.PERCENTILE,
    unit: '%',
    iconPath: ISSUE_ICON_PATH.V1.FEMALE,
  },
  G3: {
    name: 'Child Labor Policy',
    description:
      'Companies have implemented initiatives to ensure prevention' + ' of child labor in all parts of its business',
    category: ISSUE_CATEGORY.GOVERNANCE,
    type: ISSUE_TYPE.BOOLEAN,
    unit: ' Policies',
    iconPath: ISSUE_ICON_PATH.V1.CHILD_HAND,
  },
  G4: {
    name: 'Supply Chain Policy',
    description:
      'Companies have implemented initiatives to reduce social risks' +
      ' in its supply chain (e.g. poor working conditions, fair or minimum wage etc.)',
    category: ISSUE_CATEGORY.GOVERNANCE,
    type: ISSUE_TYPE.BOOLEAN,
    unit: ' Policies',
    iconPath: ISSUE_ICON_PATH.V1.GLOBE,
  },
  G5: {
    name: 'Community Spend % of Pre Tax Profit',
    description: 'Amount of money spent by companies on community-building activities' + ' as a % of pre-tax profits',
    category: ISSUE_CATEGORY.GOVERNANCE,
    type: ISSUE_TYPE.PERCENTILE,
    unit: '%',
    iconPath: ISSUE_ICON_PATH.V1.HEART_HANDS,
  },
};

// Assign enum as "code" property
let index = 0;
each(ISSUE_OLD, (value, code) => {
  value.code = code;
  value.codeLetter = code.substr(0, 1);
  value.codeNumber = code.substr(1);
  value.sortOrder = index;
  index += 1;
});

export const ISSUES_OLD = map(ISSUE_OLD, value => value);
