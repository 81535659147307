import styled from 'styled-components/macro';
import {Theme} from '../atoms/theme';

const CheckBoxWrapper = styled.div`
  position: relative;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 24px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${Theme.colors.harmony};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const ToggleText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #464255;
  margin: 0 1rem;
`;

export const Toggle = ({onChange = () => {}, checked = false, leftText = '', rightText = ''}) => {
  return (
    <ToggleContainer>
      <ToggleText>{leftText}</ToggleText>
      <CheckBoxWrapper>
        <CheckBox id="checkbox" type="checkbox" onChange={onChange} checked={checked} />
        <CheckBoxLabel htmlFor="checkbox" />
      </CheckBoxWrapper>
      <ToggleText>{rightText}</ToggleText>
    </ToggleContainer>
  );
};

export default Toggle;
