import {useMemo, useState} from 'react';
import {orderBy} from 'lodash';
import {getSessionJwt} from 'app/utilities';
import {useGetPlanSponsorFirmsQuery, useGetPlanSponsorManagerEmployeesQuery} from 'v2/redux/typeormEndpoints';
import {MANAGER_ETHNICITIES, MANAGER_GENDERS} from 'v2/constants/managers';
import {CONFIG} from 'app/constants';
import {useSelector} from 'react-redux';
import {filter} from 'v2/utilities/helpers/filter';

export interface ManagerData {
  yes: any[];
  no: any[];
}

export const MANAGER_LIST_TYPES = {
  INTEGRATING_ESG: 'integrating-esg',
  WORKFORCE_DIVERSITY: 'workforce-diversity',
  ORG_WIDE_ESG_POLICY: 'org-wide-esg-policy',
  ESG_COMMITTEE: 'esg-committee',
};

export const useDashboardManagers = ({initialEmployeeFilterType}: {initialEmployeeFilterType: string}) => {
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;
  let {
    data: unfilteredFirmsData,
    isLoading: firmsLoading,
    isError: firmsError,
    isSuccess: firmsSuccess,
  } = useGetPlanSponsorFirmsQuery({planSponsorId});
  let {
    data: unfilteredManagersEmployeesData,
    isLoading: managersEmployeesLoading,
    isError: managersEmployeesError,
    isSuccess: managersEmployeesSuccess,
  } = useGetPlanSponsorManagerEmployeesQuery({planSponsorId});

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const managersEmployeesData: any = {};
  //@ts-ignore
  managersEmployeesData.firms = unfilteredManagersEmployeesData?.firms?.filter((firm: any) => {
    return firm?.strategies?.some((strategy: any) => {
      if (filters.type === 'all' || filters.type === strategy.type) {
        if (filters.class === '' || filters.class === strategy.class) {
          return true;
        }
      }
      return false;
    });
  });

  const firmsData: any = {};
  //@ts-ignore
  firmsData.firms = unfilteredFirmsData?.firms?.filter((firm: any) => {
    return firm?.strategies?.some((strategy: any) => {
      if (filters.type === 'all' || filters.type === strategy.type) {
        if (filters.class === '' || filters.class === strategy.class) {
          return true;
        }
      }
      return false;
    });
  });

  const [employeeFilterType, setEmployeeFilterType] = useState(initialEmployeeFilterType);

  const integrationData = useMemo(() => {
    const initialData = {
      screening: 0,
      vetting: 0,
      voting: 0,
      scaling: 0,
      monitoring: 0,
      selling: 0,
      esgPolicy: 0,
      officer: 0,
      total: 0,
      workforceDiversity: 0,
    };

    if (!firmsData || !firmsData?.firms?.length) return initialData;

    const aggregatedCounts = firmsData?.firms?.reduce((prev: any, curr: any) => {
      const aggregation = {...prev};
      const incrementCount = (field: string) => {
        aggregation[field] += 1;
      };

      if (
        curr?.hasScreeningConsideration ||
        curr?.hasScalingConsideration ||
        curr?.hasVettingConsideration ||
        curr?.hasMonitoringConsideration ||
        curr?.hasVotingConsideration ||
        curr?.hasSellingConsideration
      ) {
        aggregation.total += 1;
      }
      if (curr?.hasScreeningConsideration) incrementCount('screening');
      if (curr?.hasVettingConsideration) incrementCount('vetting');
      if (curr?.hasVotingConsideration) incrementCount('voting');
      if (curr?.hasScalingConsideration) incrementCount('scaling');
      if (curr?.hasMonitoringConsideration) incrementCount('monitoring');
      if (curr?.hasSellingConsideration) incrementCount('selling');
      if (curr?.hasEsgPolicy) incrementCount('esgPolicy');
      if (curr?.hasESGCommitteeOfficer) incrementCount('officer');
      if (curr?.hasInclusion) {
        aggregation.workforceDiversity += 1;
      }

      return aggregation;
    }, initialData);

    return {
      screening: Math.round((aggregatedCounts.screening / firmsData?.firms?.length) * 100),
      vetting: Math.round((aggregatedCounts.vetting / firmsData?.firms?.length) * 100),
      voting: Math.round((aggregatedCounts.voting / firmsData?.firms?.length) * 100),
      scaling: Math.round((aggregatedCounts.scaling / firmsData?.firms?.length) * 100),
      monitoring: Math.round((aggregatedCounts.monitoring / firmsData?.firms?.length) * 100),
      selling: Math.round((aggregatedCounts.selling / firmsData?.firms?.length) * 100),
      esgPolicy: Math.round((aggregatedCounts.esgPolicy / firmsData?.firms?.length) * 100),
      officer: Math.round((aggregatedCounts.officer / firmsData?.firms?.length) * 100),
      total: Math.round((aggregatedCounts.total / firmsData?.firms?.length) * 100),
      workforceDiversity: Math.round((aggregatedCounts.workforceDiversity / firmsData?.firms?.length) * 100),
    };
  }, [firmsData]);

  const diversityData = useMemo(() => {
    if (!managersEmployeesData)
      return {
        genderData: {
          women: 0,
          men: 0,
        },
        ethnicityData: {
          asian: 0,
          black: 0,
          hispanic: 0,
          other: 0,
          white: 0,
        },
      };
    const overallEthnicityData = {
      asian: 0,
      black: 0,
      hispanic: 0,
      other: 0,
      white: 0,
      men: 0,
      women: 0,
    };
    let firmsWithData = 0;
    managersEmployeesData?.firms?.map((firm: any) => {
      const ethnicityData = {
        asian: 0,
        black: 0,
        hispanic: 0,
        other: 0,
        white: 0,
        men: 0,
        women: 0,
      };
      let firmTotal = 0;
      firm?.employees?.forEach((category: any) => {
        if (category.year === CONFIG.MANAGER_EMPLOYEE_DATA_YEAR) {
          if (employeeFilterType === 'ALL' || employeeFilterType === category.category) {
            firmTotal += category.count;
            if (category.gender === MANAGER_GENDERS.MALE) {
              ethnicityData.men += category.count;
            }
            if (category.gender === MANAGER_GENDERS.FEMALE) {
              ethnicityData.women += category.count;
            }
            switch (category.ethnicity) {
              case MANAGER_ETHNICITIES.ASIAN:
                ethnicityData.asian += category.count;
                break;
              case MANAGER_ETHNICITIES.BLACK:
                ethnicityData.black += category.count;
                break;
              case MANAGER_ETHNICITIES.HISPANIC:
                ethnicityData.hispanic += category.count;
                break;
              case MANAGER_ETHNICITIES.WHITE:
                ethnicityData.white += category.count;
                break;
              default:
                ethnicityData.other += category.count;
            }
          }
        }
      });
      if (firmTotal !== 0) {
        firmsWithData += 1;
        Object.keys(ethnicityData).forEach((key: string) => {
          //@ts-ignore
          overallEthnicityData[key] += ethnicityData[key] / firmTotal;
        });
      }
    });
    Object.keys(overallEthnicityData).forEach(key => {
      //@ts-ignore
      overallEthnicityData[key] = Math.round((overallEthnicityData[key] / firmsWithData) * 100);
    });
    return {ethnicityData: overallEthnicityData, genderData: overallEthnicityData};
  }, [managersEmployeesData, employeeFilterType]);

  const getFilteredManagerList = ({listType}: {listType: string}): ManagerData => {
    const firms = orderBy(firmsData?.firms, ['name'], ['asc']);

    switch (listType.toLowerCase()) {
      case MANAGER_LIST_TYPES.INTEGRATING_ESG:
        return {
          yes: firms.filter((firm: any) => {
            if (
              firm?.hasScreeningConsideration ||
              firm?.hasScalingConsideration ||
              firm?.hasVettingConsideration ||
              firm?.hasMonitoringConsideration ||
              firm?.hasVotingConsideration ||
              firm?.hasSellingConsideration
            ) {
              return firm;
            }
            return null;
          }),
          no: firms.filter((firm: any) => {
            if (
              !(
                firm?.hasScreeningConsideration ||
                firm?.hasScalingConsideration ||
                firm?.hasVettingConsideration ||
                firm?.hasMonitoringConsideration ||
                firm?.hasVotingConsideration ||
                firm?.hasSellingConsideration
              )
            ) {
              return firm;
            }
            return null;
          }),
        };
      case MANAGER_LIST_TYPES.WORKFORCE_DIVERSITY:
        return {
          yes: firms.filter((firm: any) => {
            if (firm?.diversityObjectives?.length > 0 && firm?.hasEsgPolicy && firm?.hasInclusion) {
              return firm;
            }
            return null;
          }),
          no: firms.filter((firm: any) => {
            if (!(firm?.diversityObjectives?.length > 0 && firm?.hasEsgPolicy && firm?.hasInclusion)) {
              return firm;
            }
            return null;
          }),
        };
      case MANAGER_LIST_TYPES.ORG_WIDE_ESG_POLICY:
        return {
          yes: firms.filter((firm: any) => {
            if (firm?.hasEsgPolicy) return firm;
            return null;
          }),
          no: firms.filter((firm: any) => {
            if (!firm?.hasEsgPolicy) return firm;
            return null;
          }),
        };
      case MANAGER_LIST_TYPES.ESG_COMMITTEE:
        return {
          yes: firms.filter((firm: any) => {
            if (firm?.hasESGCommitteeOfficer) return firm;
            return null;
          }),
          no: firms.filter((firm: any) => {
            if (!firm?.hasESGCommitteeOfficer) return firm;
            return null;
          }),
        };
      default:
        return {yes: [], no: []};
    }
  };

  return {
    isLoading: firmsLoading || managersEmployeesLoading,
    isSuccess: firmsSuccess && managersEmployeesSuccess,
    isError: firmsError || managersEmployeesError,
    integrationData,
    diversityData,
    setEmployeeFilterType,
    getFilteredManagerList,
  };
};
