import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules';
import {FORM_ERROR} from 'final-form';
import {FORM_MESSAGE} from 'app/constants';

type OnboardingSteps =
  | 'ACCOUNT'
  | 'PROFILE'
  | 'MANAGER'
  | 'MANAGER_INFORMATION'
  | 'ESG_INTGRATION'
  | 'ESG_ASSOCIATIONS'
  | 'FINALIZE';
interface IHandleSaveProps {
  values: any;
  onboardingStep: OnboardingSteps;
  dispatch: any;
  action: (invitationCode: string, updateData?: any) => any;
  nextRoute?: string;
  invitationCode: string;
}

export const handleSave = ({action, dispatch, invitationCode, nextRoute, onboardingStep, values}: IHandleSaveProps) => {
  const updateData = {
    ...values,
    onboardingStep,
  };

  return dispatch(action?.(invitationCode, updateData))
    .then((response: any) => {
      if (response.hasError) {
        // throw response.error;
        console.error(response);
        return {[FORM_ERROR]: response.error};
      }

      if (nextRoute) {
        animateScroll.scrollToTop();
        dispatch(push(nextRoute.replace(':invitationCode', invitationCode)));
      }
    })
    .catch((error: any) => {
      animateScroll.scrollToTop();
      if (error.message.trim() === 'Invitation Code is not valid.') {
        return {[FORM_ERROR]: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT};
      }
      console.error(error);
      return {[FORM_ERROR]: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT};
    });
};
