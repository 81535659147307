import {Theme} from '../theme';

export const GraphArrow = ({color = Theme.colors.grey_8, size = 32}) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_811_1267)">
      <path
        d="M19.1667 5L11.25 12.9167L7.08334 8.75L0.833344 15"
        stroke="#464255"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 5H19.1667V10"
        stroke="#464255"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_811_1267">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
