/* eslint-disable no-param-reassign */
import each from 'lodash/each';

import {COMPLIANCE_STATUS, PORTFOLIO_STATUS} from 'app/constants';

const RATING_FLOAT = {
  A: 5,
  B: 4,
  C: 3,
  D: 2,
  E: 1,
  UR: 0,
};

const RATING_LETTERS = ['UR', 'E', 'D', 'C', 'B', 'A'];

/**
 * Prepares the Securities for Data Table sorting and formatting
 */
export const prepareSecuritiesForDataTable = (securities, esgSettings, isPassiveHarmonyIndex) => {
  let portfolioCompliance = PORTFOLIO_STATUS.IN;
  let belowThresholdWeightSum = 0;
  let weightedRatingSum = 0;
  let weightSum = 0;
  const policyViolations = [];
  const isinCounts = {};

  each(securities, security => {
    isinCounts[security.securityIsin] = (isinCounts[security.securityIsin] || 0) + 1;

    // Set a backup key in case one isn't provided
    if (!security.key) {
      security.key = [security.securityIsin, isinCounts[security.securityIsin]].join('_');
    }

    const securityRating = RATING_FLOAT[security.rating];

    // Clear old compliance value
    security.compliance = null;

    // Ignore UR securities
    if (securityRating && security.weight > 0) {
      // We hope that weights total 1, but they might not.
      // So we need to sum the weights so the avg rating doesn't get skewed.
      weightedRatingSum += securityRating * security.weight;
      weightSum += security.weight;

      // Default to acceptable
      security.compliance = isPassiveHarmonyIndex
        ? {...COMPLIANCE_STATUS.PASSIVE_STRATEGY_ACCEPTABLE, sortOrder: securityRating}
        : COMPLIANCE_STATUS.ACCEPTABLE;

      // Skip ESG Compliance and use Harmony Index violation rules if Passive Harmony Index
      if (isPassiveHarmonyIndex) {
        // Creating condition for the one violation for Passive Harmony Index, organized to allow for more later
        if (securityRating === RATING_FLOAT.E) {
          security.compliance = {...COMPLIANCE_STATUS.PASSIVE_STRATEGY_VIOLATION, sortOrder: securityRating};
        }
      } else if (!esgSettings.isIspSettingNone) {
        // Skip this whole thing if "None" is checked.
        // (And why are you here?)

        // Only proceed if this security falls below the threshold rating.
        if (securityRating <= RATING_FLOAT[esgSettings.ispThresholdRating]) {
          if (esgSettings.isIspSettingMaxPercentHoldingOfThresholdRating) {
            // Soft average violation (possibly)
            security.compliance = COMPLIANCE_STATUS.RATINGS_DRAG;
          }

          // Always sum the weight.
          // Determine later if it's used for portfolio compliance.
          belowThresholdWeightSum += security.weight;
        }

        // Only proceed if this security falls below the Harmony rating.
        if (securityRating < RATING_FLOAT[esgSettings.ispHarmonyRating]) {
          if (esgSettings.isIspSettingMinimumAverageRating) {
            // Soft average violation (possibly)
            security.compliance = COMPLIANCE_STATUS.RATINGS_DRAG;
          }
        }
      }
    }

    security.compliance = {
      ...security.compliance,
      name: security.name,
      key: security.key,
      rating: securityRating,
    };

    // Set meta properties to use in anticipation of the dataTable sort "freezing"
    security.metaName = {key: security.key, value: security.name};
    security.metaSectorName = {key: security.key, value: security.sectorName};
    security.metaIndustryName = {key: security.key, value: security.industryName};
    security.metaCountryCode = {key: security.key, value: security.countryCode};
    security.metaRating = {key: security.key, value: security.rating};
    security.metaWeight = {key: security.key, value: security.weight};
  });

  const averageRating = weightedRatingSum / weightSum;

  // console.log('averageRating', averageRating);
  // console.log('belowThresholdWeightSum', belowThresholdWeightSum);

  if (esgSettings.isIspSettingMinimumAverageRating && averageRating < RATING_FLOAT[esgSettings.ispHarmonyRating]) {
    // Average rating falls below the threshold rating.
    portfolioCompliance = PORTFOLIO_STATUS.OUT;
    policyViolations.push(
      `The minimum weighted average Harmony Rating
      must be ${esgSettings.ispHarmonyRating},
      but is ${RATING_LETTERS[Math.round(averageRating)]}.`,
    );
  }
  if (
    esgSettings.isIspSettingMaxPercentHoldingOfThresholdRating &&
    belowThresholdWeightSum > esgSettings.ispMaxPercentHolding / 100
  ) {
    // Too many securities below the threshold are being held.
    portfolioCompliance = PORTFOLIO_STATUS.OUT;
    policyViolations.push(
      `${parseFloat(parseFloat(belowThresholdWeightSum * 100).toFixed(2))}%
      of security holdings are rated ${esgSettings.ispThresholdRating} or below,
      which is higher than the maximum  ${esgSettings.ispMaxPercentHolding}%.`,
    );
  }

  return {
    compliance: portfolioCompliance,
    policyViolation: policyViolations,
  };
};
