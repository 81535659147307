import {clone, compact, difference, get, isEmpty, keys, merge} from 'lodash';
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isConfigFetching: true,
  isDetailsFetching: true,
  isOptionsFetching: true,
  isPeriodDataFetching: true,
  isStatsFetching: true,
};

// ACTION
export const ACTION = {
  GET_CONFIG_REQUEST: 'PROGRESS_REPORT_GET_CONFIG_REQUEST',
  GET_CONFIG_RECEIVE: 'PROGRESS_REPORT_GET_CONFIG_RECEIVE',
  GET_DETAILS_REQUEST: 'PROGRESS_REPORT_GET_DETAILS_REQUEST',
  GET_DETAILS_RECEIVE: 'PROGRESS_REPORT_GET_DETAILS_RECEIVE',
  GET_STATS_REQUEST: 'PROGRESS_REPORT_GET_STATS_REQUEST',
  GET_STATS_RECEIVE: 'PROGRESS_REPORT_GET_STATS_RECEIVE',
  GET_OPTIONS_REQUEST: 'PROGRESS_REPORT_GET_OPTIONS_REQUEST',
  GET_OPTIONS_RECEIVE: 'PROGRESS_REPORT_GET_OPTIONS_RECEIVE',
  GET_PERIOD_DATA_REQUEST: 'PROGRESS_REPORT_GET_PERIOD_DATA_REQUEST',
  GET_PERIOD_DATA_RECEIVE: 'PROGRESS_REPORT_GET_PERIOD_DATA_RECEIVE',
  SAVE_CONFIG_REQUEST: 'PROGRESS_REPORT_SAVE_CONFIG_REQUEST',
  SAVE_CONFIG_RECEIVE: 'PROGRESS_REPORT_SAVE_CONFIG_RECEIVE',
};

const getConfigRequest = createAction(ACTION.GET_CONFIG_REQUEST);
const getConfigReceive = createAction(ACTION.GET_CONFIG_RECEIVE);
const getDetailsRequest = createAction(ACTION.GET_DETAILS_REQUEST);
const getDetailsReceive = createAction(ACTION.GET_DETAILS_RECEIVE);
const getStatsRequest = createAction(ACTION.GET_STATS_REQUEST);
const getStatsReceive = createAction(ACTION.GET_STATS_RECEIVE);
const getOptionsRequest = createAction(ACTION.GET_OPTIONS_REQUEST);
const getOptionsReceive = createAction(ACTION.GET_OPTIONS_RECEIVE);
const getPeriodDataRequest = createAction(ACTION.GET_PERIOD_DATA_REQUEST);
const getPeriodDataReceive = createAction(ACTION.GET_PERIOD_DATA_RECEIVE);
const saveConfigRequest = createAction(ACTION.SAVE_CONFIG_REQUEST);
const saveConfigReceive = createAction(ACTION.SAVE_CONFIG_RECEIVE);

const api = {
  getConfig: () => {
    return fetchAuthJSON('progressReport/config', {method: 'get'});
  },
  getDetails: () => fetchAuthJSON('planSponsor/current?fields=id,name,isEmpty', {method: 'get'}),
  getStats: () => fetchAuthJSON('progressReport/stats', {method: 'get'}),
  getPeriodData: periods => {
    return fetchAuthJSON('progressReport/periodData', {
      method: 'post',
      body: JSON.stringify({periods}),
    });
  },
  getOptions: () => {
    return fetchAuthJSON('progressReport/options', {method: 'get'});
  },
  saveConfig: progressReportConfig => {
    return fetchAuthJSON('progressReport/config', {
      method: 'put',
      body: JSON.stringify({progressReportConfig}),
    });
  },
};

export function getPeriodData(periods) {
  return dispatch => {
    dispatch(getPeriodDataRequest({periods}));
    return dispatch(getPeriodDataReceive(api.getPeriodData(periods)));
  };
}

/**
 * Dispatches getPeriodData if the periods requested are not already in state
 */
function fetchPeriodDataIfNeeded(periods, state, dispatch) {
  if (!isEmpty(periods)) {
    const periodsInState = keys(get(state, 'progressReport.periodData', {}));
    const periodsToFetch = compact(difference(periods, periodsInState));
    if (!isEmpty(periodsToFetch)) {
      dispatch(getPeriodData(periodsToFetch));
    }
  }
}

export function getDetails() {
  return dispatch => {
    dispatch(getDetailsRequest());
    return dispatch(getDetailsReceive(api.getDetails()));
  };
}

export function getConfig() {
  return (dispatch, getState) => {
    dispatch(getConfigRequest());
    return dispatch(
      getConfigReceive(
        api.getConfig().then(payload => {
          fetchPeriodDataIfNeeded(get(payload, 'periods'), getState(), dispatch);
          return payload;
        }),
      ),
    );
  };
}

export function getStats() {
  return dispatch => {
    dispatch(getStatsRequest());
    return dispatch(getStatsReceive(api.getStats()));
  };
}

export function getOptions() {
  return dispatch => {
    dispatch(getOptionsRequest());
    return dispatch(getOptionsReceive(api.getOptions()));
  };
}

export function saveConfig(config) {
  return (dispatch, getState) => {
    dispatch(saveConfigRequest(config));
    return dispatch(
      saveConfigReceive(
        api.saveConfig(config).then(payload => {
          fetchPeriodDataIfNeeded(get(payload, 'periods'), getState(), dispatch);
          return payload;
        }),
      ),
    );
  };
}

// REDUCER
export const progressReport = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  switch (type) {
    case ACTION.GET_CONFIG_REQUEST:
      return assignMergedState({isConfigFetching: true});
    case ACTION.GET_DETAILS_REQUEST:
      return assignMergedState({isDetailsFetching: true});
    case ACTION.GET_STATS_REQUEST:
      return assignMergedState({isStatsFetching: true});
    case ACTION.GET_OPTIONS_REQUEST:
      return assignMergedState({isOptionsFetching: true});
    case ACTION.GET_PERIOD_DATA_REQUEST:
      return assignMergedState({isPeriodDataFetching: true});
    case ACTION.SAVE_CONFIG_RECEIVE:
    case ACTION.GET_CONFIG_RECEIVE:
      return assignMergedState(
        Object.assign({isConfigFetching: false}, hasError ? {error: payload} : {config: payload}),
      );
    case ACTION.GET_DETAILS_RECEIVE:
      return assignMergedState(
        Object.assign({isDetailsFetching: false}, hasError ? {error: payload} : {details: payload}),
      );
    case ACTION.GET_STATS_RECEIVE:
      return assignMergedState(Object.assign({isStatsFetching: false}, hasError ? {error: payload} : {stats: payload}));
    case ACTION.GET_OPTIONS_RECEIVE:
      return assignMergedState(
        Object.assign({isOptionsFetching: false}, hasError ? {error: payload} : {options: payload}),
      );
    case ACTION.GET_PERIOD_DATA_RECEIVE: {
      const periodData = merge(state.periodData, payload);
      return assignMergedState(
        Object.assign(
          {isPeriodDataFetching: false, __persisted_at: new Date().toJSON()},
          hasError ? {error: payload} : {periodData},
        ),
      );
    }
    case ACTION.SAVE_CONFIG_REQUEST:
      return assignMergedState({
        config: {
          ...payload,
          includedIssues: clone(payload.includedIssues),
          periods: clone(payload.periods),
        },
      });
    default:
      return state;
  }
};
