import {createAction} from 'redux-actions';
import {endsWith, startsWith} from 'lodash';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

let fetchingCount = 0;

// ACTION
export const ACTION = {
  GET_DISTRIBUTIONS_REQUEST: 'CD_PLAN_DASHBOARD_GET_DISTRIBUTIONS_REQUEST',
  GET_DISTRIBUTIONS_RECEIVE: 'CD_PLAN_DASHBOARD_GET_DISTRIBUTIONS_RECEIVE',
  GET_ISSUES_REQUEST: 'CD_PLAN_DASHBOARD_GET_ISSUES_REQUEST',
  GET_ISSUES_RECEIVE: 'CD_PLAN_DASHBOARD_GET_ISSUES_RECEIVE',
  GET_ISSUE_MATRIX_REQUEST: 'CD_PLAN_DASHBOARD_GET_ISSUE_MATRIX_REQUEST',
  GET_ISSUE_MATRIX_RECEIVE: 'CD_PLAN_DASHBOARD_GET_ISSUE_MATRIX_RECEIVE',
  GET_ALL_MANAGER_REQUEST: 'CD_MANAGER_GET_ALL_REQUEST',
  GET_ALL_MANAGER_RECEIVE: 'CD_MANAGER_GET_ALL_RECEIVE',
  GET_ESG_ENGAGEMENT_ISSUES_REQUEST: 'CD_GET_ESG_ENGAGEMENT_ISSUE_REQUEST',
  GET_ESG_ENGAGEMENT_ISSUES_RECEIVE: 'CD_GET_ESG_ENGAGEMENT_RECEIVE',
};

const getDistributionsRequest = createAction(ACTION.GET_DISTRIBUTIONS_REQUEST);
const getDistributionsReceive = createAction(ACTION.GET_DISTRIBUTIONS_RECEIVE);
const getIssuesRequest = createAction(ACTION.GET_ISSUES_REQUEST);
const getIssuesReceive = createAction(ACTION.GET_ISSUES_RECEIVE);
const getIssueMatrixRequest = createAction(ACTION.GET_ISSUE_MATRIX_REQUEST);
const getIssueMatrixReceive = createAction(ACTION.GET_ISSUE_MATRIX_RECEIVE);
const managersGetAllRequest = createAction(ACTION.GET_ALL_MANAGER_REQUEST);
const managersGetAllReceive = createAction(ACTION.GET_ALL_MANAGER_RECEIVE);
const getEsgEngagementIssuesRequest = createAction(ACTION.GET_ESG_ENGAGEMENT_ISSUES_REQUEST);
const getEsgEngagementIssuesReceive = createAction(ACTION.GET_ESG_ENGAGEMENT_ISSUES_RECEIVE);

const api = {
  getDistributions: () => fetchAuthJSON('planSponsor/current/distribution', {method: 'get'}),
  getIssues: (isUserType3, securityId, strategyId) => {
    if (!isUserType3) {
      return fetchAuthJSON(`securities/${securityId}/issue`, {method: 'get'});
    }
    return fetchAuthJSON(`securities/${securityId}/strategy/${strategyId}/issue`, {method: 'get'});
  },
  getIssueMatrix: (isUserType3, securityId, strategyId) => {
    if (!isUserType3) {
      return fetchAuthJSON(`securities/${securityId}/issueMatrix`, {method: 'get'});
    }
    return fetchAuthJSON(`securities/${securityId}/strategy/${strategyId}/issueMatrix`, {method: 'get'});
  },
  getAllManagers: securityId => {
    const url = `firm/managers/companydetails/${securityId}?`;

    return fetchAuthJSON(url, {method: 'get'});
  },
  getEsgEngagementIssues: securityId => {
    const url = `securities/${securityId}/companyEngagementLetter`;

    return fetchAuthJSON(url, {method: 'get'});
  },
};

export function getDistributions() {
  return dispatch => {
    dispatch(getDistributionsRequest());
    return dispatch(getDistributionsReceive(api.getDistributions()));
  };
}

export function getIssues(isUserType3, securityId, strategyId) {
  return dispatch => {
    dispatch(getIssuesRequest({isUserType3, securityId, strategyId}));
    return dispatch(getIssuesReceive(api.getIssues(isUserType3, securityId, strategyId)));
  };
}

export function getIssueMatrix(isUserType3, securityId, strategyId) {
  return dispatch => {
    dispatch(getIssueMatrixRequest({isUserType3, securityId, strategyId}));
    return dispatch(getIssueMatrixReceive(api.getIssueMatrix(isUserType3, securityId, strategyId)));
  };
}

export function getAllManagers(securityId) {
  return dispatch => {
    dispatch(managersGetAllRequest());
    return dispatch(managersGetAllReceive(api.getAllManagers(securityId)));
  };
}

export function getEsgEngagementIssues(securityId) {
  return dispatch => {
    dispatch(getEsgEngagementIssuesRequest({securityId}));
    return dispatch(getEsgEngagementIssuesReceive(api.getEsgEngagementIssues(securityId)));
  };
}

// REDUCER
export const companyDetail = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  if (startsWith(type, 'CD_') && endsWith(type, '_REQUEST')) {
    fetchingCount += 1;
  }
  if (startsWith(type, 'CD_') && endsWith(type, '_RECEIVE')) {
    fetchingCount -= 1;
  }
  const isFetching = fetchingCount > 0;

  switch (type) {
    case ACTION.GET_DISTRIBUTIONS_REQUEST:
    case ACTION.GET_ISSUES_REQUEST:
    case ACTION.GET_ALL_MANAGER_REQUEST:
    case ACTION.GET_ISSUE_MATRIX_REQUEST:
    case ACTION.GET_ESG_ENGAGEMENT_ISSUES_REQUEST:
      return assignMergedState({isFetching});
    case ACTION.GET_DISTRIBUTIONS_RECEIVE: {
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {distributions: payload}));
    }
    case ACTION.GET_ISSUES_RECEIVE:
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {issues: payload}));
    case ACTION.GET_ISSUE_MATRIX_RECEIVE:
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {issues: payload}));
    case ACTION.GET_ESG_ENGAGEMENT_ISSUES_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching}, hasError ? {error: payload} : {esgEngagementIssues: payload}),
      );
    case ACTION.GET_ALL_MANAGER_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching}, hasError ? {error: payload} : {allFirmsWithStrategy: payload.items}),
      );
    default:
      return state;
  }
};
