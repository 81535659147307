import {Route} from 'react-router-dom';
import {PageLayout} from 'app/components';

export const PublicRoute = ({component: Component, layout: Layout = PageLayout, render, ...rest}) => {
  if (render) {
    return (
      <Layout>
        <Route {...rest} render={render} />
      </Layout>
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};
