/* eslint-disable no-param-reassign */
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isGlobalSettingsFetching: true,
};

// ACTION
export const ACTION = {
  GET_GLOBAL_SETTINGS_REQUEST: 'SETTINGS_GET_GLOBAL_SETTINGS_REQUEST',
  GET_GLOBAL_SETTINGS_RECEIVE: 'SETTINGS_GET_GLOBAL_SETTINGS_RECEIVE',
  UPDATE_GLOBAL_SETTINGS_REQUEST: 'SETTINGS_UPDATE_GLOBAL_SETTINGS_REQUEST',
  UPDATE_GLOBAL_SETTINGS_RECEIVE: 'SETTINGS_UPDATE_GLOBAL_SETTINGS_RECEIVE',
};

const getGlobalSettingsRequest = createAction(ACTION.GET_GLOBAL_SETTINGS_REQUEST);
const getGlobalSettingsReceive = createAction(ACTION.GET_GLOBAL_SETTINGS_RECEIVE);
const updateGlobalSettingsRequest = createAction(ACTION.UPDATE_GLOBAL_SETTINGS_REQUEST);
const updateGlobalSettingsReceive = createAction(ACTION.UPDATE_GLOBAL_SETTINGS_RECEIVE);

const api = {
  getGlobalSettings: () => fetchAuthJSON('siteSetting/Global', {method: 'get'}),
  updateGlobalSettings: settings =>
    fetchAuthJSON('siteSetting/Global', {
      method: 'put',
      body: JSON.stringify({settings}),
    }),
};

export function getGlobalSettings() {
  return dispatch => {
    dispatch(getGlobalSettingsRequest());
    return dispatch(getGlobalSettingsReceive(api.getGlobalSettings()));
  };
}

export function updateGlobalSettings(values) {
  return dispatch => {
    dispatch(updateGlobalSettingsRequest());
    return dispatch(updateGlobalSettingsReceive(api.updateGlobalSettings(values)));
  };
}

// REDUCER
export const settings = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_GLOBAL_SETTINGS_REQUEST:
      return assignMergedState({isGlobalSettingsFetching: true});
    case ACTION.GET_GLOBAL_SETTINGS_RECEIVE:
    case ACTION.UPDATE_GLOBAL_SETTINGS_RECEIVE:
      return assignMergedState(
        Object.assign({isGlobalSettingsFetching: false}, hasError ? {error: payload} : {global: payload.settings}),
      );
    default:
      return state;
  }
};
