import {forEach} from 'lodash';

export const errorRequiredFields = (values, requiredFields) => {
  const errors = {};
  forEach(requiredFields, field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};
