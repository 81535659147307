import {useMemo} from 'react';
import {TableCell} from 'v2/components/atoms/Containers';
import {Container, FlexContainer} from 'v2/components/atoms/Containers';
import {formatMillions} from 'v2/utilities/helpers';
import {ModalTableWithStateButtons} from 'v2/components/organisms/ModalTableWithStateButtons';

type OptionType = {
  label: string | unknown;
  value?: string | null | unknown;
};

export const CompaniesIssueTable: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  data: any[];
  title: string;
  buttons: string[];
  sector: OptionType;
  setSector: any;
  selectedButton: number;
  setSelectedButton: any;
  sectorOptions: OptionType[];
  companyPath: (id: string) => string;
  companiesRoute?: string;
}> = ({
  isOpen,
  onClose,
  data,
  sector,
  title,
  buttons,
  setSector,
  selectedButton,
  setSelectedButton,
  sectorOptions,
  companyPath,
  companiesRoute,
}) => {
  const companyColumns = useMemo(
    () => [
      {
        Header: () => (
          <TableCell>
            <Container padding="0 30px">Companies</Container>
          </TableCell>
        ),
        Cell: ({value}: {value: string}) => (
          <TableCell>
            <Container padding="0 30px">{value}</Container>
          </TableCell>
        ),
        style: {
          width: '50%',
        },
        accessor: 'company',
      },
      {
        Header: () => <TableCell>Sectors</TableCell>,
        Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
        accessor: 'sector',
      },
      {
        Header: () => (
          <TableCell>
            <Container padding="0 30px"> Held</Container>
          </TableCell>
        ),
        Cell: ({value}: {value: number}) => (
          <TableCell>
            <FlexContainer justify="flex-end">{formatMillions(value, 1, '$')}</FlexContainer>
          </TableCell>
        ),
        accessor: 'held',
      },
    ],
    [],
  );

  return (
    <ModalTableWithStateButtons
      isOpen={isOpen}
      setClose={onClose}
      data={data}
      columns={companyColumns}
      title={title}
      buttons={buttons}
      selectedButton={selectedButton}
      setSelectedButton={setSelectedButton}
      setSector={setSector}
      sector={sector}
      sectorOptions={sectorOptions}
      companyPath={companyPath}
      companiesRoute={companiesRoute}
    />
  );
};
