import React from 'react';
import {Callback, Middleware} from './types';
import {DashboardTabsCard, ManagerTabsCard} from 'v2/components/organisms/TabsCards';
import {Container} from 'v2/components/atoms/Containers';

const Layout: React.FC<{}> = ({children}) => {
  return (
    <Container>
      <DashboardTabsCard />
      {children}
    </Container>
  );
};

export const dashboardTabBar: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
