import {useState} from 'react';

export const useRadioList = (options: string[], defaultIndex?: number) => {
  const [selected, setSelected] = useState<string>(options[defaultIndex ?? 0]);

  const handleChange = (e: any) => {
    setSelected(e.target.value);
  };

  return {
    selected,
    handleChange,
  };
};
