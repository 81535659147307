import {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';
import {TextInput} from 'v2/components/molecules/TextInput';
import {Theme} from 'v2/components/atoms/theme';
import {Search} from 'v2/components/atoms/icons/Search';

export interface ISearchBar {
  placeholder?: string;
  onChange?: (value: string) => void;
}

export const SearchBarRow = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-direction: row;
  padding: 0.2rem 1.75rem 0.75rem;
  border-bottom: 0.0625rem solid ${Theme.colors.grey_3};
`;

export const SearchButton = styled.button`
  background: ${props => props.color || props.theme.colors.grey_2};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  border: 0px;
  height: 44px;
  border-radius: 8px;
  padding: 0;
`;

export const SearchBar: React.FC<ISearchBar> = ({
  onChange = () => {},
  handleSearch,
  placeholder = 'Search...',
}: any) => {
  const [searchValue, setSearchValue] = useState('');
  const searchOnEnter = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleSearch();
    }
  };

  const debounceHandler = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleChange = (value: string) => {
    setSearchValue(value);
    debounceHandler(value);
  };

  // cancel debounce on unmount
  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SearchBarRow>
      <TextInput
        placeholder={placeholder}
        value={searchValue}
        onChange={handleChange}
        onKeyUp={searchOnEnter}
        style={{width: 'auto', flex: 1}}
      />
      <SearchButton onClick={handleSearch}>
        <Search size={18} color={Theme.colors.dark} />
      </SearchButton>
    </SearchBarRow>
  );
};
