import {BarChart, Box, Briefcase, Globe, TrendingUp, User} from 'v2/components/atoms/icons';
import {dynamicRoute} from 'v2/utilities/helpers/dynamicRoute';
import {ROUTE} from './routes';

export const DASHBOARD_TAB_ROUTES = [
  {
    label: ROUTE.UT1.DASHBOARD.RISK.title,
    path: ROUTE.UT1.DASHBOARD.RISK.path,
    icon: BarChart,
  },
  {
    label: ROUTE.UT1.DASHBOARD.MANAGER.title,
    path: ROUTE.UT1.DASHBOARD.MANAGER.path,
    icon: User,
  },
  {
    label: ROUTE.UT1.DASHBOARD.HOLDINGS.title,
    path: ROUTE.UT1.DASHBOARD.HOLDINGS.path,
    icon: Box,
  },
  {
    label: ROUTE.UT1.DASHBOARD.ENGAGEMENT.title,
    path: ROUTE.UT1.DASHBOARD.ENGAGEMENT.path,
    icon: TrendingUp,
  },
];

export const MANAGERS_TAB_ROUTES = (strategyId: string) => [
  {
    label: 'Risk',
    path: dynamicRoute({
      route: ROUTE.UT1.STRATEGIES.RISK.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: BarChart,
  },
  {
    label: 'Profile',
    path: dynamicRoute({
      route: ROUTE.UT1.STRATEGIES.PROFILE.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: User,
  },
  {
    label: 'Holdings',
    path: dynamicRoute({
      route: ROUTE.UT1.STRATEGIES.HOLDINGS.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: Box,
  },
  {
    label: 'Engagement',
    path: dynamicRoute({
      route: ROUTE.UT1.STRATEGIES.ENGAGEMENT.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: TrendingUp,
  },
];

export const COMPANIES_TAB_ROUTES = (companyId: string) => [
  {
    label: 'Risk',
    path: dynamicRoute({
      route: ROUTE.UT1.COMPANIES.RISK.path,
      replace: ':companyId',
      value: companyId,
    }),
    icon: Globe,
  },
  {
    label: 'Ownership',
    path: dynamicRoute({
      route: ROUTE.UT1.COMPANIES.OWNERSHIP.path,
      replace: ':companyId',
      value: companyId,
    }),
    icon: Box,
  },
  {
    label: 'Engagement',
    path: dynamicRoute({
      route: ROUTE.UT1.COMPANIES.ENGAGEMENT.path,
      replace: ':companyId',
      value: companyId,
    }),
    icon: Briefcase,
  },
];

export const CLIENT_TAB_ROUTES = (strategyId: string) => [
  {
    label: 'Risk',
    path: dynamicRoute({
      route: ROUTE.UT3.CLIENTS.RISK.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: BarChart,
  },
  {
    label: 'Holdings',
    path: dynamicRoute({
      route: ROUTE.UT3.CLIENTS.HOLDINGS.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: Box,
  },
  {
    label: 'Engagement',
    path: dynamicRoute({
      route: ROUTE.UT3.CLIENTS.ENGAGEMENT.path,
      replace: ':strategyId',
      value: strategyId,
    }),
    icon: TrendingUp,
  },
];

export const UT3_COMPANIES_TAB_ROUTES = (companyId: string) => [
  {
    label: 'Risk',
    path: dynamicRoute({
      route: ROUTE.UT3.COMPANIES.RISK.path,
      replace: ':companyId',
      value: companyId,
    }),
    icon: Globe,
  },
  {
    label: 'Ownership',
    path: dynamicRoute({
      route: ROUTE.UT3.COMPANIES.OWNERSHIP.path,
      replace: ':companyId',
      value: companyId,
    }),
    icon: Box,
  },
  {
    label: 'Engagement',
    path: dynamicRoute({
      route: ROUTE.UT3.COMPANIES.ENGAGEMENT.path,
      replace: ':companyId',
      value: companyId,
    }),
    icon: Briefcase,
  },
];

export const UT3_DASHBOARD_TAB_ROUTES = () => [
  {
    label: 'Profile',
    path: ROUTE.UT3.DASHBOARD.PROFILE.path,
    icon: User,
  },
  {
    label: 'Holdings',
    path: ROUTE.UT3.DASHBOARD.HOLDINGS.path,
    icon: Box,
  },
  {
    label: 'Engagement',
    path: ROUTE.UT3.DASHBOARD.ENGAGEMENT.path,
    icon: Briefcase,
  },
];
