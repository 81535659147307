import React from 'react';
import {animateScroll} from 'react-scroll';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import throttleHandler from '@hocs/throttle-handler';

import faArrowUp from 'app/fontawesome-pro-light/faArrowUp';

const style = {
  hiddenAndUnclickable: {
    opacity: 0,
    pointerEvents: 'none',
  },
};

const ScrollToTop = compose(
  withState('isVisible', 'setIsVisible'),
  withHandlers({
    handleWindowScroll:
      ({setIsVisible}) =>
      () => {
        setIsVisible(window.scrollY > 200); // Could be a nice exposed prop
      },
  }),
  throttleHandler('handleWindowScroll', 100),
  lifecycle({
    componentDidMount() {
      window.addEventListener('scroll', this.props.handleWindowScroll);
    },
    componentWillUnmount() {
      window.removeEventListener('scroll', this.props.handleWindowScroll);
    },
  }),
)(({isVisible}) => (
  <button
    className="scroll-to-top"
    onClick={animateScroll.scrollToTop}
    style={isVisible ? null : style.hiddenAndUnclickable}
  >
    <Icon icon={faArrowUp} />
  </button>
));

export {ScrollToTop};
