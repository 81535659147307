import {getSessionJwt} from 'app/utilities';
import {uniqBy} from 'lodash';
import {useSelector} from 'react-redux';
import {useGetPlanSponsorHoldingsQuery} from 'v2/redux/typeormEndpoints';
import {filter} from 'v2/utilities/helpers/filter';

export const usePlanSponsorHoldings = () => {
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;
  const data: any[] = useGetPlanSponsorHoldingsQuery({planSponsorId}).data ?? [];

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const filterParams = [];
  if (filters.type !== 'all') {
    filterParams.push({key: 'type', target: filters.type, operation: 'eq'});
  }
  if (filters.class !== '') {
    filterParams.push({key: 'class', target: filters.class, operation: 'eq'});
  }
  if (filters.region !== '') {
    filterParams.push({key: 'region', target: filters.region, operation: 'eq'});
  }

  //@ts-ignore
  const filteredData = filter({data: data, filterItems: filterParams});

  const companies = new Set();
  filteredData.map(holding => companies.add(holding.companyId));
  const companyHoldingsCount = [companies.size] ?? [0];

  return {
    filteredData,
    companyHoldingsCount,
  };
};
