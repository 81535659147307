import vanillatoasts from 'vanillatoasts';
import faCheck from 'app/fontawesome-pro-light/faCheck';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import faExclamationCircle from 'app/fontawesome-pro-light/faExclamationCircle';

import {imgSrcFromFaIcon} from './imgSrcFromFaIcon';

const DEFAULT_TIMEOUT = 5000;
const DEFAULT_ICON_COLOR = 'white';

// Handles an object OR a string
const smartToast = defaultProps => {
  return props => {
    const customProps = Object.assign({}, defaultProps, typeof props === 'object' ? props : {text: props});
    if (customProps.faIcon && !customProps.icon) {
      customProps.icon = imgSrcFromFaIcon(customProps.faIcon, DEFAULT_ICON_COLOR);
    }
    if (customProps.icon) {
      customProps.type = `${customProps.type || ''} has-icon`;
    }
    vanillatoasts.create(customProps);
    return customProps;
  };
};

const toast = smartToast({timeout: DEFAULT_TIMEOUT});

const toastSuccess = smartToast({
  type: 'success',
  faIcon: faCheck,
  timeout: DEFAULT_TIMEOUT,
});

const toastSuccessExtended = smartToast({
  type: 'success',
  faIcon: faCheck,
  timeout: DEFAULT_TIMEOUT * 2,
});

const toastWarning = smartToast({
  type: 'warning',
  faIcon: faExclamationTriangle,
  timeout: DEFAULT_TIMEOUT,
});

const toastError = smartToast({
  type: 'danger',
  faIcon: faExclamationCircle,
  timeout: DEFAULT_TIMEOUT,
});
const toastDanger = toastError;

export {toast, toastSuccess, toastSuccessExtended, toastWarning, toastDanger, toastError};
