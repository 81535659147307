import {useGetAggregatePercentileHoldingsQuery} from 'v2/redux/harmonyApi';
import {getSessionJwt} from 'app/utilities';
import {useGetPlanSponsorStrategiesQuery} from 'v2/redux/typeormEndpoints';

export const useAggregatePercentile = () => {
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;
  const {data: holding1 = [], isLoading: isLoading1} = useGetAggregatePercentileHoldingsQuery({
    planSponsorId,
    issueId: 1,
  });
  const {data: holding2 = [], isLoading: isLoading2} = useGetAggregatePercentileHoldingsQuery({
    planSponsorId,
    issueId: 2,
  });
  const {data: holding3 = [], isLoading: isLoading3} = useGetAggregatePercentileHoldingsQuery({
    planSponsorId,
    issueId: 3,
  });
  const holdings = [...holding1, ...holding2, ...holding3];
  const isLoading = isLoading1 || isLoading2 || isLoading3;

  const {data: strategies} = useGetPlanSponsorStrategiesQuery({planSponsorId});

  const strategiesMap =
    strategies?.reduce((acc: Record<number, any>, strategy: any) => ({...acc, [strategy.id]: strategy}), {}) ?? {};

  //join holdings and strategies
  const holdingsStrategies = holdings.map((holding: any) => ({
    ...holding,
    ...strategiesMap[holding?.strategyId],
  }));

  return {holdingsStrategies, isLoading};
};
