import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';

import {RadioDot} from './RadioDot';

const DOT_SPACING = 96; // match with width of .quartile-legend-label in CSS
const LABEL_WIDTH = 60; // match with padding-left of .quartile-legend in CSS

class PercentileRating extends Component {
  render() {
    const {values} = this.props;

    return (
      <div className="quartile-set">
        <div className="quartile">Top 25%</div>
        <div className="quartile">50%-75%</div>
        <div className="quartile">25%-50%</div>
        <div className="quartile">Bottom 25%</div>
        {map(values, ({label, percentile}, index) => {
          let color;
          if (percentile >= 75) color = 'green';
          else if (percentile <= 25) color = 'red';

          const offset = ((values.length - 1) * -DOT_SPACING) / 2 + index * DOT_SPACING + LABEL_WIDTH / 2;

          return (
            <RadioDot
              key={index}
              color={color}
              floating
              style={{top: `${100 - percentile}%`, left: `calc(${offset}px + 50%)`}}
              tooltip={
                <Fragment>
                  <div className="tooltip-header">
                    Percentile <strong className={color && `text-${color}`}>{percentile}%</strong>
                  </div>
                  {label}
                </Fragment>
              }
            />
          );
        })}
      </div>
    );
  }
}

PercentileRating.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percentile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
};

export {PercentileRating};
