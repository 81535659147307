import React from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faCaretUp, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {getOrdinal} from '../utilities';
import {DATA_STATUS} from 'v2/constants/dataStatus';

const DashboardHeader = ({data, children}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const {icon, title, sector, universe, colorStart, colorStop, id} = data;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        id={id}
        className="row align-items-center justify-content-center dashboard-header-container"
        style={
          colorStart && colorStop
            ? {background: `linear-gradient(90deg, ${colorStart} 0%, ${colorStop} 100%)`}
            : {backgroundColor: '#000000'}
        }
      >
        <div className="col flex-grow-0 icon-container">{icon && <Icon icon={icon} className="icon" />}</div>
        <div className="col flex-grow-0 text-uppercase header-title">{title && <span>{title}</span>}</div>
        <div className="col row flex-grow-1 justify-content-end align-items-end text-right stats-container">
          <div className="col d-flex flex-grow-0 align-items-center score-container">
            <span className="text-uppercase label">Sector</span>
            <span className="score">{sector !== null && sector !== 0 ? sector : DATA_STATUS.NONE}</span>
            <span className="ordinal">{sector !== null && sector !== 0 && getOrdinal(sector)}</span>
          </div>
          <div className="col d-flex flex-grow-0 align-items-center score-container">
            <span className="text-uppercase label">Universe</span>
            <span className="score">{universe !== null && universe !== 0 ? universe : DATA_STATUS.NONE}</span>
            <span className="ordinal">{universe !== null && universe !== 0 ? getOrdinal(universe) : ''}</span>
          </div>
        </div>
        <div className="col flex-grow-0 toggle-icon-container">
          <button className="btn btn-link toggle-icon" onClick={toggle}>
            <Icon icon={isOpen ? faCaretDown : faCaretUp} />
          </button>
        </div>
      </div>
      {isOpen && <div className=" pt-8 pb-5 header-card-container">{children}</div>}
    </div>
  );
};

export {DashboardHeader};
