import styled from 'styled-components/macro';
import {SubtitleM, TitleXL, TitleM, TitleS, TitleXS} from '../atoms/Typeface';
import {ColorPill, LargeColorPill} from './Pills';
import {Quintile} from '../organisms/Quintile';
import {Container, FlexContainer} from '../atoms/Containers';
import {CompanyESGProps, ESGProps, IssueESGProps} from 'v2/utilities/types/components/ESGDisplay';
import {Theme} from '../atoms/theme';
import {getOrdinal} from 'app/utilities';
import {DATA_STATUS} from 'v2/constants/dataStatus';

const QuintileTitle = styled(TitleS)`
  margin: 0 42px 0 0.2rem;
`;

const Padding = styled.div`
  padding-left: 10px;
`;

export const OverallESGPanel: React.FC<ESGProps> = ({
  quintile,
  percentile = DATA_STATUS.NONE,
  benchmark = DATA_STATUS.NONE,
}) => {
  return (
    <Container>
      <FlexContainer items="center">
        <Quintile size="1.5rem" quintile={quintile} percent={quintile} displayCondensed />
        <QuintileTitle>Overall</QuintileTitle>
        <LargeColorPill
          text={quintile ? `${quintile}${getOrdinal(quintile)} Quintile` : '- Quintile'}
          color={Theme.colors.blue_1}
          stroke=".125rem"
        />
      </FlexContainer>
      <FlexContainer items="center">
        <Container width="10rem">
          <TitleXL>{percentile}</TitleXL>
        </Container>

        <FlexContainer direction="column">
          <Padding>
            <SubtitleM>Benchmark</SubtitleM>
            <TitleS color={Theme.colors.blue_1}>{benchmark}</TitleS>
          </Padding>
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};

export const OverallCompanyPanel: React.FC<CompanyESGProps> = ({
  quintile,
  percentile = DATA_STATUS.NONE,
  industry = DATA_STATUS.NONE,
}) => {
  return (
    <Container>
      <FlexContainer items="center">
        <Quintile size="1.5rem" quintile={quintile} percent={quintile} displayCondensed />
        <QuintileTitle>Overall</QuintileTitle>
        <ColorPill
          text={quintile ? `${quintile}${getOrdinal(quintile)} Quintile` : '- Quintile'}
          color={Theme.colors.blue_1}
          stroke=".125rem"
        />
      </FlexContainer>
      <FlexContainer items="center">
        <Container width="10rem">
          <TitleXL>{percentile}</TitleXL>
        </Container>
        <FlexContainer direction="column">
          <SubtitleM>Industry</SubtitleM>
          <TitleS color={Theme.colors.blue_1}>{industry}</TitleS>
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};

export const ESGIssuePannel: React.FC<IssueESGProps> = ({
  title = DATA_STATUS.NONE,
  quintile,
  percentile = DATA_STATUS.NONE,
  benchmark = DATA_STATUS.NONE,
}) => {
  return (
    <Container>
      <FlexContainer direction="column" gap=".8438rem">
        <Container>
          <TitleXS>{title}</TitleXS>
        </Container>
        <FlexContainer gap="1.2rem">
          <Quintile percent={quintile} quintile={quintile} rounded />
          <Container>
            <TitleM>{percentile}</TitleM>
          </Container>
        </FlexContainer>
        <FlexContainer gap=".4375rem">
          <SubtitleM>Benchmark</SubtitleM>
          <TitleXS color={Theme.colors.blue_1}>{benchmark}</TitleXS>
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};

export const ESGCompanyIssuePannel: React.FC<CompanyESGProps> = ({
  title = DATA_STATUS.NONE,
  quintile,
  percentile = DATA_STATUS.NONE,
  industry = DATA_STATUS.NONE,
}) => {
  return (
    <Container>
      <FlexContainer direction="column" gap=".8438rem">
        <Container>
          <TitleXS>{title}</TitleXS>
        </Container>
        <FlexContainer gap="1.2rem">
          <Quintile percent={quintile} quintile={quintile} rounded />
          <Container>
            <TitleM>{percentile}</TitleM>
          </Container>
        </FlexContainer>
        <FlexContainer gap=".4375rem">
          <SubtitleM>Industry</SubtitleM>
          <TitleXS color={Theme.colors.blue_1}>{industry}</TitleXS>
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};
