import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const INVESTMENT_VEHICLE = {
  SEPARATE_ACCOUNT: 'Separate Account',
  MUTUAL_FUND: 'Mutual Fund',
  ETF: 'ETF',
  COMMINGLED_FUND: 'Commingled Fund',
  OTHER: 'Other',
};

export const INVESTMENT_VEHICLES = map(INVESTMENT_VEHICLE, value => value);
