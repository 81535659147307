/* eslint-disable max-len */
import React, {Component} from 'react';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import {ISSUE_CATEGORY, ISSUE_CATEGORY_KEYS, RATINGS, DISTRIBUTION} from 'app/constants';
import {getIssueBadgeAssetUrl, getRatingBadgeAssetUrl, getOrdinal} from '../utilities';
import {DashboardHeader, BooleanCard, NumericalCard} from '../components';

class CompanyReport extends Component {
  getPercentileRanking(issue, distribution) {
    if (
      this.props.percentileRanking !== undefined &&
      this.props.percentileRanking[issue] !== undefined &&
      this.props.percentileRanking[issue][distribution] !== undefined
    ) {
      return this.props.percentileRanking[issue][distribution];
    }
    return '';
  }

  renderBadges() {
    const badges = [];
    const currentYear = new Date().getFullYear();
    const {companyAnnualIssues} = this.props;
    if (companyAnnualIssues) {
      Object.values(companyAnnualIssues).forEach(issue => {
        issue.forEach(item => {
          const {data} = item;
          const badge = data[currentYear - 1];
          if (badge && badge.receivesBadge) {
            badges.push(badge);
          }
        });
      });
    }
    return badges.map(({issueId}) => (
      <div className="company-dashboard-award-badge col ">
        <img crossOrigin="anonymous" key={issueId} src={getIssueBadgeAssetUrl(issueId)} />
      </div>
    ));
  }

  renderCards(key, category) {
    const {companyAnnualIssues} = this.props;
    if (companyAnnualIssues && companyAnnualIssues[key]) {
      return companyAnnualIssues[key].map(({data, id, issueType, name, sector, target, universe, unit}) => {
        const year = new Date().getFullYear() - 1;
        return issueType === 'Numeric' ? (
          <NumericalCard
            key={id}
            data={{
              id,
              category,
              issues: data,
              target,
              name,
              year,
              unit,
            }}
          />
        ) : (
          <BooleanCard
            key={id}
            data={{
              id,
              category,
              issues: data,
              value: data[year] && data[year].value,
              sector: Math.round(sector[year]),
              universe: Math.round(universe[year]),
              name,
              year,
            }}
          />
        );
      });
    }
    return <span>No Data</span>;
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const {companyPortal, percentileRanking} = this.props;
    const {name, rating, sector} = companyPortal;
    return (
      <React.Fragment>
        <div className="company-dashboard-backdrop w-100 position-absolute" />
        <div className="padded-container company-dashboard-container futura">
          <div className="company-dashboard-header-wrapper">
            <div className="company-dashboard-header d-flex flex-row">
              <div className="company-dashboard-header-title flex-fill pr-6 pb-9">
                <div className="company-dashboard-header-company-title text-uppercase">
                  <span>{name}</span>
                </div>
                <div className="company-dashboard-header-company-role text-uppercase">
                  <span>{sector.name}</span>
                </div>
              </div>
              <div className="company-dashboard-header-stats-wrapper d-flex flex-row justify-content-end">
                <div className="company-dashboard-header-stats d-flex flex-column">
                  <div className="company-dashboard-header-stats-title align-items-center justify-content-between row">
                    <div className="line-spacer col" />
                    <div className="text text-center text-uppercase col">
                      <span className="text-nowrap">Percentile Ranking</span>
                    </div>
                    <div className="line-spacer col" />
                  </div>
                  <div className="row">
                    <div className="col company-dashboard-header-score text-center">
                      <div>
                        <span className="score">
                          {this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.SECTOR)}
                        </span>
                        <span className="ordinal">
                          {getOrdinal(this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.SECTOR))}
                        </span>
                      </div>
                      <div className="label text-center text-uppercase">
                        <span>Sector</span>
                      </div>
                    </div>
                    <div className="col company-dashboard-header-score text-center">
                      <div>
                        <span className="score">
                          {this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.UNIVERSE)}
                        </span>
                        <span className="ordinal">
                          {getOrdinal(this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.UNIVERSE))}
                        </span>
                      </div>
                      <div className="label text-center text-uppercase">
                        <span>Universe</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="company-dashboard-emblem-wrapper d-flex flex-column align-items-center">
                  <div className="company-dashboard-emblem-ring d-flex align-items-center justify-content-center">
                    <div className="company-dashboard-emblem d-flex align-items-center justify-content-center text-uppercase">
                      <span>{RATINGS[rating]}</span>
                    </div>
                  </div>
                  <div className="emblem-label text-uppercase text-center">
                    <span>Company Rating</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="company-dashboard-awards-container d-flex flex-row row">
            <div className="company-dashboard-awards-label d-flex align-items-center justify-content-center overflow-hidden flex-grow-0 col">
              <span className="d-block text-uppercase">Awards</span>
            </div>
            <div className="company-dashboard-awards flex-1 flex-wrap row col">
              <div className="company-dashboard-award-badge col ">
                <img crossOrigin="anonymous" src={getRatingBadgeAssetUrl(rating)} />
              </div>
              {this.renderBadges()}
            </div>
          </div>
          {percentileRanking && (
            <React.Fragment>
              <DashboardHeader
                data={{
                  icon: faSeedling,
                  title: 'Environmental',
                  sector: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.ENVIRONMENTAL, DISTRIBUTION.SECTOR),
                  universe: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.ENVIRONMENTAL, DISTRIBUTION.UNIVERSE),
                  colorStart: '#34B991',
                  colorStop: '#27A37D',
                }}
              >
                {this.renderCards(ISSUE_CATEGORY_KEYS.ENVIRONMENTAL, ISSUE_CATEGORY.ENVIRONMENT)}
              </DashboardHeader>
              <DashboardHeader
                data={{
                  icon: faHandHeart,
                  title: 'Social',
                  sector: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.SOCIAL, DISTRIBUTION.SECTOR),
                  universe: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.SOCIAL, DISTRIBUTION.UNIVERSE),
                  colorStart: '#009BCC',
                  colorStop: '#008BB7',
                }}
              >
                {this.renderCards(ISSUE_CATEGORY_KEYS.SOCIAL, ISSUE_CATEGORY.SOCIAL)}
              </DashboardHeader>
              <DashboardHeader
                data={{
                  icon: faLandmark,
                  title: 'Governance',
                  sector: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.GOVERNANCE, DISTRIBUTION.SECTOR),
                  universe: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.GOVERNANCE, DISTRIBUTION.UNIVERSE),
                  colorStart: '#003462',
                  colorStop: '#034682',
                }}
              >
                {this.renderCards(ISSUE_CATEGORY_KEYS.GOVERNANCE, ISSUE_CATEGORY.GOVERNANCE)}
              </DashboardHeader>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export {CompanyReport};
