/* eslint-disable no-shadow */
import React, {Component, Fragment} from 'react';
import {cloneDeep, each, filter, find, get, map, sortBy, startsWith} from 'lodash';
import {connect} from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

import {ALERT_BUTTON, ALERT_TYPE, ROUTE} from 'app/constants';
import {getAll as getAllUserStrategyAlerts, update as updateUserStrategyAlert} from 'app/redux/userStrategyAlert';
import faPlusCircle from 'app/fontawesome-pro-light/faPlusCircle';
import faMinusCircle from 'app/fontawesome-pro-light/faMinusCircle';
import faInboxIn from 'app/fontawesome-pro-light/faInboxIn';
import faInboxOut from 'app/fontawesome-pro-light/faInboxOut';
import {Collapsible, APreventDefault} from 'app/components';

const BUCKET_TITLE = 'Notifications';
const ARCHIVE_TITLE = 'Archive';

const getAlertGroups = (alerts, strategyId) => {
  const INITIAL_ALERT_GROUP = {
    items: [],
    unreadCount: 0,
  };

  // Create list of alert groups based on type (plus Archive)
  const alertGroups = {};
  each(ALERT_TYPE, title => {
    alertGroups[title] = cloneDeep(INITIAL_ALERT_GROUP);
  });
  alertGroups[BUCKET_TITLE] = cloneDeep(INITIAL_ALERT_GROUP);
  alertGroups[ARCHIVE_TITLE] = cloneDeep(INITIAL_ALERT_GROUP);

  // Put each alert in the correct group, based on type and status
  each(alerts, alert => {
    if (!strategyId || Number(alert.strategyId) === Number(strategyId)) {
      // determine correct group
      let groupKey = alert.type;
      if (alert.isArchived) {
        groupKey = ARCHIVE_TITLE;
      } else if (!alertGroups[groupKey]) {
        groupKey = BUCKET_TITLE;
      }

      alertGroups[groupKey].items.push(alert);
      if (!alert.isViewed) {
        alertGroups[groupKey].unreadCount += 1;
      }
    }
  });

  return alertGroups;
};

class AlertsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {alertGroupsCollapsed: {[ARCHIVE_TITLE]: true}};

    props.dispatch(getAllUserStrategyAlerts());
  }

  handleArchiveClick = () => {
    const {dispatch} = this.props;
    const {userStrategyAlertId} = this.state;
    dispatch(updateUserStrategyAlert(userStrategyAlertId, {isArchived: true, isViewed: true}));
  };

  handleUnarchiveClick = () => {
    const {dispatch} = this.props;
    const {userStrategyAlertId} = this.state;
    dispatch(updateUserStrategyAlert(userStrategyAlertId, {isArchived: false}));
  };

  // This wrapper is needed to maintain state after the Collapsible components remount
  toggleWithState = (toggle, groupTitle) => {
    const {alertGroupsCollapsed} = this.state;
    alertGroupsCollapsed[groupTitle] = !alertGroupsCollapsed[groupTitle];
    toggle();
    this.setState({alertGroupsCollapsed});
  };

  selectAlert(userStrategyAlertId) {
    const {alerts, dispatch} = this.props;
    this.setState({userStrategyAlertId});

    // Mark as read
    const currentAlert = find(alerts, alert => {
      return Number(alert.id) === Number(userStrategyAlertId);
    });
    if (currentAlert && !currentAlert.isViewed) {
      dispatch(updateUserStrategyAlert(userStrategyAlertId, {isViewed: true}));
    }
  }

  componentDidUpdate() {
    const {
      alerts,
      match: {
        params: {strategyId},
      },
    } = this.props;
    const {userStrategyAlertId} = this.state;

    // Show first unarchived alert by default
    if (!userStrategyAlertId && alerts.length > 0) {
      let filteredAlerts = [];
      if (strategyId) {
        // also filter by strategy if we're in one
        filteredAlerts = filter(alerts, alert => {
          return Number(alert.strategyId) === Number(strategyId) && !alert.isArchived;
        });
      } else {
        filteredAlerts = filter(alerts, alert => {
          return !alert.isArchived;
        });
      }
      if (filteredAlerts.length > 0) {
        this.selectAlert(filteredAlerts[0].id);
      }
    }
  }

  render() {
    const {
      alerts,
      isFetching,
      match: {
        params: {strategyId},
      },
    } = this.props;
    const {alertGroupsCollapsed, userStrategyAlertId} = this.state;

    const alertGroups = getAlertGroups(alerts, strategyId);
    const alertsCount = strategyId
      ? filter(alerts, alert => Number(alert.strategyId) === Number(strategyId)).length
      : alerts.length;

    const currentAlert = find(alerts, alert => {
      if (strategyId && Number(alert.strategyId) !== Number(strategyId)) {
        return false;
      }
      return Number(alert.id) === Number(userStrategyAlertId);
    });

    return (
      <div className="alerts-layout">
        {currentAlert && (
          <div className="alerts-layout-current">
            <div className="alerts-layout-current-header">
              <div>
                <div className="alerts-layout-current-date">{moment(currentAlert.createdAt).format('MMM D, YYYY')}</div>
                <div className="alerts-layout-current-title">{currentAlert.title}</div>
              </div>
              {currentAlert.isArchived && (
                <button className="btn-archive" onClick={this.handleUnarchiveClick}>
                  <span>
                    <Icon icon={faInboxOut} />
                  </span>{' '}
                  Archived
                </button>
              )}
              {!currentAlert.isArchived && (
                <button className="btn-archive" onClick={this.handleArchiveClick}>
                  <span>
                    <Icon icon={faInboxIn} />
                  </span>{' '}
                  Archive
                </button>
              )}
            </div>
            <div className="alerts-layout-current-message">
              <div dangerouslySetInnerHTML={{__html: currentAlert.description}} />
              {currentAlert.buttons && currentAlert.buttons.length > 0 && (
                <div className="mt-5">
                  {map(currentAlert.buttons, (_alertButton, i) => {
                    const alertButton =
                      typeof _alertButton === 'string'
                        ? {...ALERT_BUTTON[_alertButton]}
                        : {
                            ...ALERT_BUTTON[_alertButton.key],
                            ..._alertButton,
                          };
                    const {label, color, solid, outline, className} = alertButton;
                    const linkClassName = cn(
                      'mr-3 mt-3 btn',
                      {
                        'btn-outline': solid ? false : outline,
                        [`btn-${color}`]: color,
                      },
                      className,
                    );
                    const to = alertButton.to || alertButton.route.path();
                    return startsWith(to, 'http') ? (
                      <a key={i} href={to} className={linkClassName} target="_blank">
                        {label}
                      </a>
                    ) : (
                      <Link key={i} to={to} className={linkClassName}>
                        {label}
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="alerts-layout-list">
          <h1 style={{fontSize: '1.5rem'}}>{alerts.title}</h1>
          {!isFetching && alertsCount === 0 && (
            <div className="bg-white p-8">
              If you receive any alerts on your account, you will be able to read them here.
            </div>
          )}
          {!isFetching &&
            alerts.length > 0 &&
            map(alertGroups, ({items, unreadCount}, groupTitle) => {
              return items.length ? (
                <Collapsible isOpenByDefault={!alertGroupsCollapsed[groupTitle]} isActiveByDefault key={groupTitle}>
                  {({container, content, isOpen, toggle}) => (
                    <Fragment>
                      <div className="link-list-header">
                        <APreventDefault
                          onClick={() => this.toggleWithState(toggle, groupTitle)}
                          className="link-list-toggle"
                        >
                          <Icon icon={isOpen ? faMinusCircle : faPlusCircle} className="d-print-none" />
                        </APreventDefault>
                        <span className="link-list-title">{groupTitle}</span>
                        {groupTitle !== ARCHIVE_TITLE && (
                          <span
                            className="link-list-badge badge badge-pill badge-danger"
                            style={unreadCount ? null : {visibility: 'hidden'}}
                          >
                            {unreadCount}
                          </span>
                        )}
                      </div>
                      <div className="transition" {...container}>
                        <div {...content}>
                          <ul className="link-list">
                            {map(items, ({isViewed, id, title}, alertIndex) => (
                              <li
                                key={alertIndex}
                                className={cn('link-list-item', {
                                  'is-unread': !isViewed && groupTitle !== ARCHIVE_TITLE,
                                  'is-active': Number(id) === Number(userStrategyAlertId),
                                })}
                              >
                                <APreventDefault onClick={() => this.selectAlert(id)}>{title}</APreventDefault>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Collapsible>
              ) : null;
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // put most recent alerts first
  return {
    alerts: sortBy(get(state, 'userStrategyAlert.items', []), 'createdAt').reverse(),
    isFetching: get(state, 'userStrategyAlert.isFetching'),
  };
};

const mapDispatchToProps = dispatch => {
  return {dispatch};
};

export const Alerts = connect(mapStateToProps, mapDispatchToProps())(AlertsPage);
