import {trimStart} from 'lodash';
/**
 * Normalizes a value to be an integer between 0 and 100
 * @param {string} value The current value being entered in
 * @returns {string} Returns the value, adding the elements of the recommended format
 */
export const normalizeForcedPercentInteger = value => {
  if (!value) return 0; // turn empty value into zero
  const number = trimStart(value.replace(/[^\d]/g, ''), '0') * 1; // cast as number
  return Math.min(number, 100) + ''; // cap at 100 and cast back to string
};
