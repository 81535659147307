// Wraps BookmarkToggle with redux add/remove actions

import PropTypes from 'prop-types';
import {branch, compose, renderNothing, withHandlers, withProps} from 'recompose';
import {connect} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {addUserBookmark, removeUserBookmark} from 'app/redux/security';
import {BookmarkToggle} from 'app/components/BookmarkToggle';

const CompanyBookmarkToggle = compose(
  connect(null, {
    addUserBookmark,
    removeUserBookmark,
  }),
  branch(({security}) => isEmpty(security), renderNothing),
  withHandlers({
    handleClick: props => e => {
      e.stopPropagation();

      if (props.security.isBookmarked) {
        props.removeUserBookmark(props.security.isin);
      } else {
        props.addUserBookmark(props.security.isin);
      }
    },
  }),
  withProps(({security}) => ({
    isActive: security.isBookmarked,
  })),
)(BookmarkToggle);
CompanyBookmarkToggle.propTypes = {
  security: PropTypes.object,
};

export {CompanyBookmarkToggle};
