import {map} from 'lodash';

export const ROLE = {
  ADMIN: 'Admin',
  USER_TYPE_1: 'UserType1',
  USER_TYPE_2: 'UserType2',
  USER_TYPE_3: 'UserType3',
  USER_TYPE_4: 'UserType4',
  USER_TYPE_5: 'UserType5',
};

export const USER_ROLES = [
  {
    value: ROLE.USER_TYPE_1,
    label: 'Admin',
  },
  {
    value: ROLE.USER_TYPE_2,
    label: 'Support',
  },
];

const setupDisplayNameObject = () => {
  const displayNames = {};
  map(USER_ROLES, item => {
    displayNames[item.value] = item.label;
  });

  return displayNames;
};

export const USER_ROLES_DISPLAY_NAMES = setupDisplayNameObject(USER_ROLES);
