import styled from 'styled-components';
import {useState, useEffect} from 'react';
import {TitleXS, TitleL} from '../../../components/atoms/Typeface';
import {Theme} from '../../../components/atoms/theme';
import {SingleBarChart} from 'v2/components/organisms/SingleBarChart';
import {useHoldings} from 'v2/hooks/useHoldings';
import {useEngagements} from 'v2/hooks/useEngagements';
import {useIssueTypes} from 'v2/hooks/useIssueTypes';
import {ColorBarChart} from 'v2/components/molecules/ColoredBarChart';
import {BorderlessTextButton} from 'v2/components/molecules/Button';
import {ManagersEngagementsDoughnutChart} from 'v2/components/molecules/AnnotatedDoughnutChart';
import {values, groupBy} from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import {RectShape} from 'react-placeholder/lib/placeholders';
import {AttachedWrapper} from 'v2/components/atoms/Containers';
import {CompaniesIssueTable} from '../../../components/organisms/CompaniesIssueTable';
import {ROUTE} from 'v2/constants/routes';

const FlexRow = styled.div`
  display: flex;
`;

const Column1 = styled.div`
  width: 30%;
  padding-right: 65px;
`;

const Column2 = styled.div`
  border-left: ${props => `1px solid ${props.theme.colors.grey_3}`};
  padding-left: 65px;
  width: 25%;
`;

const Column3 = styled.div`
  padding-left: 65px;
  width: 45%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  flex: 1;
`;

const ChartContentContainer = styled.div`
  padding: 1rem 2rem 1.6rem 2rem;
  height: 25.75rem;
`;

const ChartFooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 0px 0px 8px 8px;
  height: 5rem;
`;

const ChartHeaderContainer = styled.div`
  margin-bottom: 65px;
`;

const ChartTitle = styled(TitleXS)`
  color: ${props => props.theme.colors.dark};
  margin-bottom: 10px;
`;

const ChartCount = styled(TitleL)`
  color: ${props => props.theme.colors.dark};
`;

const modalLabels = ['ENVIRONMENTAL - ISSUES ENGAGED', 'SOCIAL - ISSUES ENGAGED', 'GOVERNANCE - ISSUES ENGAGED'];

const modalButtonLabels = [
  ['Carbon Emission', 'Energy Use', 'Water Consumption'],
  ['CEO Compensation Ratio', 'Women in Leadership', 'Women on Board'],
  ['Board Independence'],
];

const ReactPlaceholderEngagement = () => (
  <div>
    <FlexRow>
      <Column1>
        <RectShape color={Theme.colors.grey_2} style={{width: 175, height: 20}} />
        <RectShape color={Theme.colors.grey_2} style={{width: 75, height: 60, marginTop: 10}} />
        <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 300, marginTop: 50}} />
      </Column1>
      <Column2>
        <ChartHeaderContainer>
          <RectShape color={Theme.colors.grey_2} style={{width: 175, height: 20}} />
          <RectShape color={Theme.colors.grey_2} style={{width: 75, height: 60, marginTop: 10}} />
          <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 300, marginTop: 50}} />
        </ChartHeaderContainer>
      </Column2>

      <Column3>
        <ChartFooterContainer>
          <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 20}} />
          <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 20}} />
          <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 20}} />
        </ChartFooterContainer>
        <ChartContentContainer>
          <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 350}} />
        </ChartContentContainer>
      </Column3>
    </FlexRow>
  </div>
);

export const UT3ClientEngagement = () => {
  const [modalIsVisible, setIsModalVisable] = useState(false);
  const [selectedButton, setSelectedButton] = useState(0);
  const [sector, setSector] = useState({value: null, label: 'All Sectors'});
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const {companyHoldingsCount} = useHoldings();
  const {
    data: engagementData,
    isLoading,
    totalEngagementCount,
    getIssuesEngagedCount,
    getIssuesEngagedByCategory,
    getIssuesEngagedByNestedSubcategory,
    getCompaniesEngagedForIssue,
  } = useEngagements();

  const {data: issueTypes} = useIssueTypes();

  const issuesEngagedCount = getIssuesEngagedCount(engagementData);
  const donutChartData = getIssuesEngagedByCategory();

  useEffect(() => {
    if (donutChartData.length > 1) {
      setIsLoaded(true);
    }
  }, [donutChartData]);

  const handleClose = () => setIsModalVisable(false);

  const onBarChartClick = (e: any) => {
    const myChart = e.chart;
    const selectedElement = myChart.getElementsAtEventForMode(e, 'nearest', {intersect: true}, true)[0]?.index;
    if (selectedElement || selectedElement === 0) {
      setSelectedButton(selectedElement);
      setIsModalVisable(true);
    }
  };

  const barChartColors = [[Theme.colors.green], [Theme.colors.blue], [Theme.colors.blue_6]];

  const barChartCategories = [issueTypes.ENVIRONMENTAL, issueTypes.SOCIAL, issueTypes.GOVERNANCE];

  const barChartIssueTypes = [
    issueTypes.CARBON_EMISSIONS,
    issueTypes.ENERGY_USE,
    issueTypes.WATER_CONSUMPTION,
    issueTypes.CEO_COMPENSATION_RATIO,
    issueTypes.WOMEN_IN_LEADERSHIP,
    issueTypes.WOMEN_ON_BOARD,
    issueTypes.BOARD_INDEPENDENCE,
  ];

  const getBarChartLabels = (barChartIssueTypes: any[]) => {
    let issuesByCategory = values(groupBy(barChartIssueTypes, 'topLevelParentId'));
    let barChartLabels: any[] = [];

    issuesByCategory.forEach(category => {
      let categoryArray: any[] = [];
      category.forEach(subcategory => {
        categoryArray.push(subcategory.name);
      });
      barChartLabels.push(categoryArray);
    });

    return barChartLabels;
  };

  const barChartLabels = getBarChartLabels(barChartIssueTypes);
  const issueIds = [
    [issueTypes.CARBON_EMISSIONS.id, issueTypes.ENERGY_USE.id, issueTypes.WATER_CONSUMPTION.id],
    [issueTypes.CEO_COMPENSATION_RATIO.id, issueTypes.WOMEN_IN_LEADERSHIP.id, issueTypes.WOMEN_ON_BOARD.id],
    [issueTypes.BOARD_INDEPENDENCE.id],
  ];

  const companyPath = (id: string) => {
    return ROUTE.UT3.COMPANIES.RISK.path.replace(':companyId', id);
  };

  return (
    <AttachedWrapper>
      <CardContainer>
        <ReactPlaceholder ready={true} customPlaceholder={<ReactPlaceholderEngagement />}>
          <FlexRow>
            <Column1>
              <SingleBarChart
                title={'Companies Engaged'}
                titleCount={totalEngagementCount}
                colors={[Theme.colors.harmony]}
                colorsTotals={[Theme.colors.grey_3]}
                data={totalEngagementCount}
                dataTotals={companyHoldingsCount}
                labels={['']}
              />
            </Column1>
            <Column2>
              <ChartHeaderContainer>
                <ChartTitle>{'Issues Engaged'}</ChartTitle>
                <ChartCount>{issuesEngagedCount}</ChartCount>
              </ChartHeaderContainer>
              {isLoaded && (
                <ManagersEngagementsDoughnutChart
                  data={donutChartData}
                  colors={[Theme.colors.green, Theme.colors.blue, Theme.colors.blue_6]}
                  hideLabel
                  singleLegend
                  usesExternalState={true}
                  selectedArc={selectedCategory + 1}
                  setSelectedArc={setSelectedCategory}
                  sum={getIssuesEngagedByNestedSubcategory(barChartIssueTypes)[selectedCategory].reduce(
                    (partialSum, a) => partialSum + a,
                    0,
                  )}
                />
              )}
            </Column2>

            <Column3>
              <ChartFooterContainer>
                <BorderlessTextButton
                  text={barChartCategories[0].name || ''}
                  onClick={() => setSelectedCategory(0)}
                  hoverColor={Theme.colors.green}
                  isSelected={selectedCategory === 0}
                />
                <BorderlessTextButton
                  text={barChartCategories[1].name || ''}
                  onClick={() => setSelectedCategory(1)}
                  hoverColor={Theme.colors.blue}
                  isSelected={selectedCategory === 1}
                />
                <BorderlessTextButton
                  text={barChartCategories[2].name || ''}
                  onClick={() => setSelectedCategory(2)}
                  hoverColor={Theme.colors.blue_6}
                  isSelected={selectedCategory === 2}
                />
              </ChartFooterContainer>
              <ChartContentContainer>
                <ColorBarChart
                  labels={barChartLabels[selectedCategory]}
                  data={getIssuesEngagedByNestedSubcategory(barChartIssueTypes)[selectedCategory]}
                  colors={barChartColors[selectedCategory]}
                  breakLabels
                  barThickness={45}
                  onClick={(event: any) => onBarChartClick(event)}
                />
              </ChartContentContainer>
            </Column3>
          </FlexRow>
        </ReactPlaceholder>
        <CompaniesIssueTable
          isOpen={modalIsVisible}
          onClose={handleClose}
          data={getCompaniesEngagedForIssue(issueIds[selectedCategory][selectedButton], sector)?.holdings}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          setSector={setSector}
          title={modalLabels[selectedCategory]}
          buttons={modalButtonLabels[selectedCategory]}
          sector={sector}
          sectorOptions={getCompaniesEngagedForIssue(issueIds[selectedCategory][selectedButton], sector)?.sectors}
          companyPath={companyPath}
          companiesRoute={ROUTE.UT3.COMPANIES.basePath}
        />
      </CardContainer>
    </AttachedWrapper>
  );
};
