import {takeRight} from 'lodash';

/**
 * Takes in an email and returns the primary domain (e.g. harmonyanalytics.org), excluding any sub-domains
 * @param {string} email - An email to retrieve the primary domain from
 * @returns {string|null} The primary domain in lower case
 */
export const getEmailPrimaryDomain = email => {
  // Ensure that there is a value in the email and it contains the @ symbol correctly
  if (!email || email.toString().indexOf('@') <= 0 || email.toString().indexOf('@') === email.length - 1) {
    return null;
  }

  const fullDomain = email.substr(email.indexOf('@') + 1).trim();

  return takeRight(fullDomain.split('.'), 2).join('.').toLowerCase();
};
