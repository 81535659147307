import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {PageHeader1, PageHeader2, PageFooter} from '.';

const HEADER_TYPE = {
  TYPE_1: 'TYPE_1',
  TYPE_2: 'TYPE_2',
  NONE: null,
};

export class PageContainer extends Component {
  render() {
    const {headerType, footer, companyName, pageNumber, children} = this.props;

    let headerElement;

    switch (headerType) {
      case HEADER_TYPE.TYPE_1:
        headerElement = <PageHeader1 />;
        break;
      case HEADER_TYPE.TYPE_2:
        headerElement = <PageHeader2 companyName={companyName} />;
        break;
      default:
        headerElement = null;
    }

    return (
      <div className="report-page-container p-0 m-0 d-flex flex-column">
        {headerElement}
        <div className="report-page-content flex-1 d-flex flex-column">{children}</div>
        {footer && <PageFooter pageNumber={pageNumber} />}
      </div>
    );
  }
}

PageContainer.HEADER_TYPE = HEADER_TYPE;

PageContainer.defaultProps = {
  headerType: HEADER_TYPE.NONE,
  footer: true,
};

PageContainer.propTypes = {
  headerType: PropTypes.oneOf(Object.values(HEADER_TYPE)),
  footer: PropTypes.bool,
  companyName: PropTypes.string.isRequired,
  // eslint-disable-next-line consistent-return
  pageNumber: (props, propName, componentName) => {
    if (props.footer) {
      if (props[propName] === undefined) {
        return new Error(`Element ${componentName} doesn't have required attribute ${propName}`);
      }
      if (typeof props[propName] !== 'number') {
        return new Error(
          `Invalid prop ${propName} of type ` +
            `\`${typeof props[propName]}\` supplied to ${componentName}, expected \`number\`.`,
        );
      }
    }
  },
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
