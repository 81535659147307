import {CONFIG} from 'app/constants';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import ReactS3Uploader from 'react-s3-uploader';
import styled from 'styled-components/macro';
import {CardContainer, Container, FlexContainer} from 'v2/components/atoms/Containers';
import {SubtitleM, TitleS} from 'v2/components/atoms/Typeface';
import {Folder, Paperclip, Upload} from 'v2/components/atoms/icons';
import {Theme} from 'v2/components/atoms/theme';
import {HarmonyButton} from 'v2/components/molecules/Button';
import {TextFormGroup} from 'v2/components/molecules/TextInput';
import {useOutsideClick} from 'v2/hooks/helpers/useOutsideClick';
import {FileUploadInput} from 'v2/utilities/types/components/FileUploadInput';
import {getSessionJwt} from 'app/utilities';

type UploadState = 'initial' | 'type' | 'filelist';

interface SavedFile {
  fileName: string;
  sourceLink: string;
  id?: number;
  createdAt?: string | Date;
}

export interface UploadedFile extends SavedFile {
  createdAt: string | Date;
  id: number;
}

const token = getSessionJwt();
const S3_UPLOADER_PROPS = {
  s3path: 'uploads/resources/',
  server: CONFIG.API_URL,
  signingUrl: '/s3/sign',
  signingUrlMethod: 'GET',
  signingUrlQueryParams: {bearer: token},
  uploadRequestHeaders: {'x-amz-acl': 'private'},
};

const FileInput = styled(ReactS3Uploader)`
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  display: none;
`;

const InputContainer = styled(FlexContainer)``;

const Button = styled.button.attrs({type: 'button'})<{height?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({height}) => height ?? '2.75rem'};
  border: 0;
  background-color: transparent;
`;

const LabelContainer = styled(FlexContainer).attrs({flex: 1, items: 'center', justify: 'center'})`
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 15.625rem;
`;

const ListItem = styled(FlexContainer).attrs({
  as: 'button',
  items: 'center',
})`
  border: 0;
  background: transparent;
  &&& {
    min-height: 2.75rem;
  }
`;

const ItemLabel = styled.span`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-all;
`;

const FileItemButton = styled(HarmonyButton)`
  border: 1px solid transparent;
  height: auto;
`;

// User can choose to upload a file or select a file from the list
// Files from the list are files associated with the company.

export interface IFileInputField extends FileUploadInput {
  name: string;
  isDisabled?: boolean;
  saveFile: (file: UploadedFile) => void;
}

export const FileInputField = ({
  width,
  height,
  hideError,
  placeholder = 'No File Uploaded',
  icon = Paperclip,
  isDisabled,
  saveFile,
  name,
  ...rest
}: IFileInputField) => {
  const uploaderRef = useRef(null);
  const panelRef = useRef(null);

  const [popupState, setPopupState] = useState<UploadState>('initial');
  const [selectedFile, setSelectedFile] = useState<SavedFile | null>(null);
  const [uploadProgress, setUploadProgress] = useState<any[]>();

  const {change: setValue} = useForm();
  const {meta, input} = useField(name);

  const reset = () => {
    setPopupState('initial');
  };

  useOutsideClick({ref: panelRef, onOutsideClick: reset});

  const handleUploadClick = () => {
    reset();
    // @ts-ignore
    uploaderRef.current?.click?.();
    setUploadProgress(undefined);
  };

  const handleSubmitFile = async ({fileName, filePath}: any) => {
    //TODO
    //@ts-ignore
    const res: any = {};
    return res;
  };

  const handleFinishUpload = async (s3File: any, fileInfo: File) => {
    const res = await handleSubmitFile({fileName: fileInfo.name, filePath: s3File.fileKey, fileSize: fileInfo.size});
    if (!res.error) {
      const {id, createdAt} = res;
      setSelectedFile({id, fileName: fileInfo.name, sourceLink: s3File.fileKey, createdAt});
    }
    setValue('fileUrl', s3File.fileKey);
    setValue('fileName', fileInfo.name);
    const sizeinbytes = fileInfo.size;
    var fSExt = ['Bytes', 'KB', 'MB', 'GB'];
    var fSize = sizeinbytes;
    var i = 0;
    while (fSize > 900) {
      fSize /= 1024;
      i++;
    }

    const formattedSize = Math.round(fSize * 10) / 10 + ' ' + fSExt[i];
    setValue('fileSize', formattedSize);
  };

  const handleProgress = (progress: number, status: number, file: File) => {
    setUploadProgress([progress, status, file.name]);
  };

  const uploaderProps = {
    ...S3_UPLOADER_PROPS,
    autoUpload: true,
    disabled: false,
    onFinish: handleFinishUpload,
    onProgress: handleProgress,
    // ...input,
  };

  const handleFileListClick = () => {
    setPopupState('filelist');
  };

  const handleSubmitSelectedItem = useCallback(() => {
    if (selectedFile) {
      setValue(name, selectedFile?.sourceLink);
      setValue('fileName', selectedFile?.fileName);
      reset();
    }
  }, [selectedFile]);

  const sourceFile = input.value?.split('_').length && input.value.split('_').slice(1).join('');
  const value = selectedFile?.fileName || sourceFile || input.value;

  // Reset the selected file if the input value changes
  useEffect(() => {
    setSelectedFile(input.value);
  }, [input.value]);

  return (
    <Container>
      <InputContainer gap="0.6875rem">
        <FlexContainer flex="1">
          <FileInput inputRef={uploaderRef} {...uploaderProps} {...rest} />
          <TextFormGroup
            width="100%"
            meta={meta.touched && meta}
            placeholder={placeholder}
            input={{value}}
            onClick={handleUploadClick}
            disabled={isDisabled}
          />
        </FlexContainer>
        <Container>
          <Button onClick={handleUploadClick} type="button" disabled={isDisabled}>
            {React.createElement(icon, {
              size: 20,
              color: Theme.colors.harmony,
            })}
          </Button>
          <Container style={{position: 'absolute', width: 0, height: 0, overflow: 'visible'}} ref={panelRef}>
            {popupState === 'type' && (
              <CardContainer
                minHeight="8rem"
                minWidth="18.75rem"
                style={{
                  padding: '1rem',
                  position: 'relative',
                  top: '0.625rem',
                  right: '18.5625rem',
                  zIndex: 10,
                }}
              >
                <FlexContainer gap="1rem" direction="column">
                  <ListItem onClick={false ? handleFileListClick : () => null}>
                    <Container padding="0.125rem 0 0">
                      <Folder color={false ? Theme.colors.harmony : Theme.colors.grey_3} size={20} />
                    </Container>
                    <LabelContainer>
                      <SubtitleM color={false ? Theme.colors.harmony : Theme.colors.grey_3}>
                        Select from Existing File
                      </SubtitleM>
                    </LabelContainer>
                  </ListItem>
                  <ListItem onClick={handleUploadClick}>
                    <Container padding="0.125rem 0 0">
                      <Upload color={Theme.colors.harmony} size={20} />
                    </Container>
                    <LabelContainer>
                      <SubtitleM>Upload from my computer</SubtitleM>
                    </LabelContainer>
                  </ListItem>
                </FlexContainer>
              </CardContainer>
            )}
            {popupState === 'filelist' && (
              <CardContainer
                minWidth="100vw"
                maxHeight="100vh"
                style={{
                  padding: '3.375rem 2.5rem',
                  position: 'fixed',
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  top: 0,
                  left: 0,
                  zIndex: 1000,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FlexContainer
                  direction="column"
                  style={{
                    flex: 1,
                    width: 600,
                    backgroundColor: 'white',
                    padding: '50px 40px',
                    maxHeight: '600px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    gap: '1.25rem',
                  }}
                >
                  <FlexContainer>
                    <FlexContainer flex="1" style={{paddingLeft: 10}}>
                      <TitleS>File Name</TitleS>
                    </FlexContainer>
                    <FlexContainer flex="1" justify="flex-end" style={{paddingRight: 10}}>
                      <TitleS>Date Uploaded</TitleS>
                    </FlexContainer>
                    <FlexContainer minWidth="7rem" />
                  </FlexContainer>
                  <FlexContainer
                    flex="1"
                    direction="column"
                    style={{overflow: 'visible', overflowY: 'auto', gap: '16px'}}
                  >
                    {<ItemLabel>No files found</ItemLabel>}
                  </FlexContainer>
                  <FlexContainer justify="flex-end" gap="1rem">
                    <FileItemButton variant="ghost" width="6.9375rem" onClick={reset}>
                      Cancel
                    </FileItemButton>
                    <FileItemButton width="6.9375rem" onClick={handleSubmitSelectedItem} disabled={!selectedFile}>
                      Select
                    </FileItemButton>
                  </FlexContainer>
                </FlexContainer>
              </CardContainer>
            )}
          </Container>
        </Container>
      </InputContainer>

      {!!uploadProgress && (
        <ProgressBar progress={uploadProgress[0]} status={uploadProgress[1]} label={uploadProgress[2]} />
      )}
    </Container>
  );
};

// ProgressBar component with props progress, total, label
const ProgressBar = ({progress = 0, status, label = ''}: {progress: number; status?: string; label: string}) => {
  return (
    <Container
      style={{
        width: '100%',
        height: '0.5rem',
        backgroundColor: '#E5E5E5',
        borderRadius: '0.25rem',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Container
        style={{
          width: `${(progress / 100) * 100}%`,
          height: '100%',
          backgroundColor: '#00A6FF',
          borderRadius: '0.25rem',
          flex: 1,
        }}
      />
      <Container
        style={{
          // color: '#00A6FF',
          flex: 1,
        }}
      >
        {label} {status}
      </Container>
    </Container>
  );
};
