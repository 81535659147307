import React from 'react';
import {isNumber, isNil, isNaN} from 'lodash';

import {TREND_TYPE, TREND_DIRECTION, REPORT_TREND_COLOR, ISSUE} from 'app/constants';
import {DATA_STATUS} from 'v2/constants/dataStatus';

/**
 * Colors value input based on trend direction
 * @param value
 * @param target
 * @param unit
 * @return {*}
 */

export const calculatePercentDifference = (column1, column2) => {
  if (!isNumber(column1) || !isNumber(column2)) {
    return DATA_STATUS.NONE;
  }

  if (column2 === 0) {
    return DATA_STATUS.NOT_APPLICABLE;
  }

  const result = ((column1 - column2) / column2) * 100;

  return formatResult(result);

  function formatResult(num) {
    if (isNil(num)) {
      return DATA_STATUS.NONE;
    }

    const maxWholeNumLength = 3;
    const maxDecimalLength = 1;
    const [wholeNumValue, decimalNumValue] = String(num).split('.');

    if (wholeNumValue.length > maxWholeNumLength) {
      return wholeNumValue;
    }

    if (decimalNumValue && decimalNumValue.length > maxDecimalLength) {
      return num.toFixed(maxDecimalLength);
    }
  }
};

export const formattedPercentDifference = (col1, col2, target, unit) => {
  const value = calculatePercentDifference(col1, col2);
  const result = (color, prepend) =>
    !isNaN(parseFloat(value)) ? (
      <span style={{color: color}}>
        {prepend && prepend}
        {value}
        {unit && ' ' + unit}
      </span>
    ) : (
      <span>{value}</span>
    );

  const appendedPlus = value > 0 && '+';

  if (col1 === col2 || isNaN(parseFloat(value))) {
    return result(REPORT_TREND_COLOR[TREND_TYPE.NEUTRAL]);
  }

  if (!isNaN(parseFloat(target))) {
    if (target - col1 > target - col2) {
      return result(REPORT_TREND_COLOR[TREND_TYPE.BAD], appendedPlus);
    } else {
      return result(REPORT_TREND_COLOR[TREND_TYPE.GOOD], appendedPlus);
    }
  }

  if (target === TREND_DIRECTION.MAX) {
    if (value > 0) {
      return result(REPORT_TREND_COLOR[TREND_TYPE.GOOD], appendedPlus);
    } else {
      return result(REPORT_TREND_COLOR[TREND_TYPE.BAD], appendedPlus);
    }
  } else {
    if (value < 0) {
      return result(REPORT_TREND_COLOR[TREND_TYPE.GOOD], appendedPlus);
    } else {
      return result(REPORT_TREND_COLOR[TREND_TYPE.BAD], appendedPlus);
    }
  }
};
