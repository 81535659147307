import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {change, SubmissionError, untouch} from 'redux-form';

// Local Imports
import {AdminMarketIndexEditForm} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';
import {createStagingUpload, getMarketIndex, saveMarketIndex} from 'app/redux/adminMarketIndex';
import {toastSuccess} from 'app/utilities/toast';

class AdminMarketIndexEditPage extends Component {
  componentDidMount() {
    const {marketIndexId} = this.props.match.params;
    this.props.dispatch(getMarketIndex(marketIndexId));
  }

  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <Link
            className="d-inline-block mb-4 small"
            to={{
              pathname: ROUTE.ADMIN_MARKET_INDEXES.path(),
              search: this.props.location.search,
            }}
          >
            <Icon icon={faArrowLeft} className="mr-2" />
            Return to {ROUTE.ADMIN_MARKET_INDEXES.title}
          </Link>
          <h1 className="mb-4">{ROUTE.ADMIN_MARKET_INDEX_EDIT.title}</h1>
          <AdminMarketIndexEditForm {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {marketIndexId} = ownProps.match.params;

  const marketIndex = get(state, `adminMarketIndex.edit.${marketIndexId}.data`, []);
  const securityMarketIndexUploads = get(marketIndex, 'securityMarketIndexUploads');
  return {
    marketIndex,
    securityMarketIndexUploads,
    state,
    isCreatingStagingUpload: get(state, 'adminMarketIndex.stagingUpload.isCreatingStagingUpload'),
    hasStagingUploadError: get(state, 'adminMarketIndex.stagingUpload.hasStagingUploadError'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createStagingUpload: fileKey => dispatch(createStagingUpload(fileKey)),
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {marketIndexId} = ownProps.match.params;
      const marketIndex = {};
      const securityMarketIndexUploadData = {
        date: values.securityMarketIndexUploadDate,
        fileKey: values.securityMarketIndexUpload[0].name,
      };

      return dispatch(saveMarketIndex(marketIndexId, marketIndex, securityMarketIndexUploadData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          let toastMessage = 'The Reference Index has been saved. ';
          if (securityMarketIndexUploadData.fileKey) {
            if (get(response, 'payload.isProcessing')) {
              toastMessage += 'The upload provided will continue to process in the background.';
            } else {
              toastMessage += 'The upload provided has also been saved and processed.';
            }
          }
          toastSuccess(toastMessage);

          const formName = 'admin-market-index-edit-form';

          dispatch(change(formName, 'securityMarketIndexUploadDate', ''));
          dispatch(change(formName, 'securityMarketIndexUpload', 'EMPTY'));
          dispatch(untouch(formName, 'securityMarketIndexUploadDate'));
          dispatch(untouch(formName, 'securityMarketIndexUpload'));

          animateScroll.scrollToTop();
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message === 'Invalid CSV File') {
            throw new SubmissionError({_error: `Upload has failed: ${error.message}`});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
  };
};

export const AdminMarketIndexEdit = compose(connect(mapStateToProps, mapDispatchToProps))(AdminMarketIndexEditPage);
