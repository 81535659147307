import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {CONFIG} from '../../app/constants/config';
import {getSessionJwt} from 'app/utilities';
import {isNull} from 'lodash';

export const harmonyApi = createApi({
  reducerPath: 'harmonyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${CONFIG.API_URL}/`,
    prepareHeaders: (headers, {getState}) => {
      const token = getSessionJwt();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  keepUnusedDataFor: 120,
  endpoints: builder => ({
    getCompanyEngagement: builder.query({
      query: () => `companyEngagement`,
    }),
    getPercentileRanking: builder.query({
      query: companyId => `company/${companyId}/percentileRanking`,
    }),
    getAggregatePercentileUniverse: builder.query({
      query: ({planSponsorId, issueId = null}) => {
        let route = `v2/planSponsor/${planSponsorId}/aggregatePercentileUniverse`;
        if (!isNull(issueId)) {
          route += `?issueId=${issueId}`;
        }
        return route;
      },
    }),
    getAggregatePercentileHoldings: builder.query({
      query: ({planSponsorId, issueId = null}) => {
        let route = `v2/planSponsor/${planSponsorId}/aggregatePercentileHoldings`;
        if (!isNull(issueId)) {
          route += `?issueId=${issueId}`;
        }
        return route;
      },
    }),
    getManagerEmployees: builder.query({
      query: ({planSponsorId, issueId = null}) => {
        let route = `v2/planSponsor/${planSponsorId}/managerEmployees`;
        if (!isNull(issueId)) {
          route += `?issueId=${issueId}`;
        }
        return route;
      },
    }),
    getHoldings: builder.query({
      query: ({planSponsorId}) => {
        let route = `v2/planSponsor/${planSponsorId}/holdings`;
        return route;
      },
    }),
    getFirms: builder.query({
      query: ({planSponsorId}) => {
        let route = `v2/planSponsor/${planSponsorId}/firms`;
        return route;
      },
    }),
    getStrategies: builder.query({
      query: ({planSponsorId}) => {
        let route = `v2/planSponsor/${planSponsorId}/strategies`;
        return route;
      },
    }),
    getEngagement: builder.query({
      query: ({planSponsorId, parentIssueId}) => {
        let route = `v2/planSponsor/${planSponsorId}/engagement/summary?parentIssueId=${parentIssueId}`;
        return route;
      },
    }),
    getCompanies: builder.query({
      query: ({planSponsorId}) => {
        let route = `v2/planSponsor/${planSponsorId}/companies`;
        return route;
      },
    }),
    getAllCompanies: builder.query({
      query: () => {
        let route = `v2/companies`;
        return route;
      },
    }),
    getIssues: builder.query({
      query: () => `issue`,
      //At client request this issue needs to have different client
      //facing names and data side names (scope 1&2 emissions -> carbon emissions)
      transformResponse: data => {
        return data.map(issue => {
          if (issue.id === 11) {
            return {...issue, name: 'Carbon Emissions'};
          }
          return issue;
        });
      },
    }),
    getTemporaryToken: builder.query({
      query: ({scopes}) => {
        let route = `auth/temporary_token?scopes=${scopes}`;
        return route;
      },
    }),
    getStrategy: builder.query({
      query: ({strategyId}) => {
        let route = `v2/strategies/${strategyId}`;
        return route;
      },
    }),
    getStrategyAggregatePercentileRankHoldings: builder.query({
      query: ({strategyId}) => {
        let route = `v2/strategies/${strategyId}/aggregatePercentileRankHoldings`;
        return route;
      },
    }),
    getStrategyAggregatePercentileRankUniverse: builder.query({
      query: ({strategyId}) => {
        let route = `v2/strategies/${strategyId}/aggregatePercentileRankUniverse`;
        return route;
      },
    }),
    getStrategyHoldings: builder.query({
      query: ({strategyId}) => {
        let route = `v2/strategies/${strategyId}/strategyholdings`;
        return route;
      },
    }),
    getStrategySecurityHoldings: builder.query({
      query: ({strategyId}) => {
        let route = `v2/strategies/${strategyId}/holdings`;
        return route;
      },
    }),
    getMarketIndexSecurities: builder.query({
      query: ({marketIndexId}) => {
        let route = `v2/marketIndexes/${marketIndexId}/securities`;
        return route;
      },
    }),
    getPlanSponsorMarketIndexSecurities: builder.query({
      query: ({planSponsorId}) => {
        let route = `v2/planSponsor/${planSponsorId}/marketIndexSecurities`;
        return route;
      },
    }),
    getAnnualPendingIssuesByCompanyId: builder.query({
      query: ({companyId}) => {
        let route = `company/${companyId}/annualPendingIssues`;
        return route;
      },
    }),
    getAdminAnnualPendingIssuesByCompanyId: builder.query({
      query: ({companyId}) => {
        let route = `company/${companyId}/annualPendingIssuesAdmin`;
        return route;
      },
    }),
    deleteCompanyIssuesFileUploads: builder.mutation({
      query: fileId => ({
        url: `companyIssuesFileUploads/${encodeURIComponent(fileId)}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCompanyEngagementQuery,
  useGetPercentileRankingQuery,
  useGetAggregatePercentileUniverseQuery,
  useGetAggregatePercentileHoldingsQuery,
  useGetManagerEmployeesQuery,
  useGetHoldingsQuery,
  useGetFirmsQuery,
  useGetStrategiesQuery,
  useGetEngagementQuery,
  useGetCompaniesQuery,
  useGetAllCompaniesQuery,
  useGetIssuesQuery,
  useGetTemporaryTokenQuery,
  useGetStrategyQuery,
  useGetStrategyAggregatePercentileRankHoldingsQuery,
  useGetStrategyAggregatePercentileRankUniverseQuery,
  useGetStrategyHoldingsQuery,
  useGetStrategySecurityHoldingsQuery,
  useGetMarketIndexSecuritiesQuery,
  useGetPlanSponsorMarketIndexSecuritiesQuery,
  useGetAnnualPendingIssuesByCompanyIdQuery,
  useGetAdminAnnualPendingIssuesByCompanyIdQuery,
  useDeleteCompanyIssuesFileUploadsMutation,
  usePrefetch,
} = harmonyApi;

//v2 getStrategy
// null in compliance
//
