import {Callback, Middleware} from './types';
import {UT3CompanyTabsCard} from 'v2/components/organisms/TabsCards';
// import {DATA_STATUS} from 'v2/constants/dataStatus';
import {BackButton} from 'v2/components/molecules/BackButtons';
import {Container} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {ROUTE} from 'v2/constants/routes';
import {CompanyInfoBar} from 'v2/components/organisms/CompanyInfoBar';
import {useUT3CompanySummary} from 'v2/hooks/useUT3CompanySummary';

const Layout: React.FC<{}> = ({children}) => {
  const {data} = useUT3CompanySummary();

  return (
    <Container minWidth={Theme.constants.fixWidth}>
      <Container maxWidth={Theme.constants.fixWidth} margin="0 auto" padding="1.5rem 0">
        <BackButton path={ROUTE.UT3.COMPANIES.basePath}>Return to Companies</BackButton>
      </Container>
      <CompanyInfoBar {...data} />
      <UT3CompanyTabsCard />
      {children}
    </Container>
  );
};

export const ut3CompaniesInfoBar: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
