import {useGetChangelogQuery} from 'v2/redux/typeormEndpoints';
export const useChangelog = () => {
  const {data: changelogData, isLoading, isSuccess, isError} = useGetChangelogQuery({});
  const markdown = changelogData?.markdown || '# No Changelog At This Point';

  return {
    data: {
      markdown,
    },
    isLoading,
    isSuccess,
    isError,
  };
};
