import React, {Component} from 'react';
import {keyBy, chunk, get} from 'lodash';
import {LegendItem, PageContainer, BarChartPanel} from '../components';

export class ESGMeasuresBarGraphPage extends Component {
  render() {
    const {companyName, title, data, chartMetrics} = this.props;

    const chartMetricsChunked = chunk(chartMetrics, 3);

    const portfolioData = keyBy(
      data.planSponsor.filter(datum => chartMetrics.includes(datum.reportMetricId)),
      'reportMetricId',
    );
    const universeData = keyBy(
      data.universe.filter(datum => chartMetrics.includes(datum.reportMetricId)),
      'reportMetricId',
    );

    return (
      <PageContainer
        headerType={PageContainer.HEADER_TYPE.TYPE_2}
        companyName={companyName}
        pageNumber={this.props.page}
      >
        <div className={'w-100 d-flex justify-content-between'}>
          <h2 className={'text-uppercase'}>{title}</h2>
          <div>
            <LegendItem title={'Portfolio'} color={'#34B991'} />
            <LegendItem title={'Universe'} color={'#034682'} />
          </div>
        </div>
        {chartMetricsChunked.map((currentChunk, i) => {
          return (
            <div className={'d-flex w-100 justify-content-between'} style={{marginTop: i === 0 ? '30px' : '40px'}}>
              {currentChunk.map(metric => {
                return (
                  <BarChartPanel
                    title={universeData[metric].reportMetric.name}
                    unit={universeData[metric].reportMetric.unit}
                    description={universeData[metric].reportMetric.description}
                    widthPercent={30}
                    aspectRatio={150 / 169}
                    datasets={[[get(portfolioData, `${metric}.value`)], [get(universeData, `${metric}.value`)]]}
                  />
                );
              })}
              {currentChunk.length < 3 &&
                [...Array(3 - currentChunk.length).keys()].map(() => {
                  return <BarChartPanel empty widthPercent={30} aspectRatio={150 / 169} />;
                })}
            </div>
          );
        })}
      </PageContainer>
    );
  }
}
