import {LoadingSpinner} from 'app/components';
import {NotFound} from 'app/pages';
import {Route, useHistory, useLocation} from 'react-router-dom';
import {MultiRouteWithMiddlewareProps} from './middleware/types';
import {withMiddleware} from './withMiddleware';

export const MultiRouteWithMiddleware: React.FC<MultiRouteWithMiddlewareProps> = ({
  pages,
  middleware,
  path,
  exact = false,
  autoRedirect,
  ...rest
}) => {
  const {search, pathname} = useLocation();
  const history = useHistory();

  const initialRoute = Object.keys(pages)[0];

  if (autoRedirect && pathname + search === path) {
    history.replace(pathname + initialRoute);
  }

  if (pages[search] !== undefined) {
    return <Route {...rest}>{withMiddleware(pages[search], LoadingSpinner, middleware)}</Route>;
  }

  return <Route {...rest}>{withMiddleware(<NotFound />, LoadingSpinner, middleware)}</Route>;
};
