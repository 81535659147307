import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {SubmissionError} from 'redux-form';

// Local Imports
import {Alert, OnboardingUT4CreateAccountForm} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {updateByUserType4Code} from 'app/redux/onboardingUser';
import {withOnboardingUserType4} from 'app/utilities';
import {Redirect} from 'react-router';
import {toast, toastSuccessExtended} from 'app/utilities/toast';

class OnboardingUT4CreateAccountPage extends Component {
  render() {
    // Ensure OnboardingAccountForm doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;
    if (this.props.hasUnexpectedError) {
      toastSuccessExtended(
        "An account for this company already exists. Please login to view your organization's data.",
      );
    }
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <h1>{ROUTE.ONBOARDING_UT4_CREATE_ACCOUNT.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Redirect to={ROUTE.LOGIN.path()} />
          ) : (
            // <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
            <OnboardingUT4CreateAccountForm {...this.props} />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode} = ownProps;
      const userData = {
        prefix: values.prefix,
        firstName: values.firstName,
        lastName: values.lastName,
        suffix: values.suffix,
        email: values.email,
        password: values.password,
        jobTitle: values.title,
        jobRole: values.role,
        phones: {
          type: values.phoneType,
          phoneNumber: values.phoneNumber,
        },
      };

      return dispatch(updateByUserType4Code(invitationCode, userData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          } else {
            const path = ROUTE.COMPANY_DASHBOARD.path();
            dispatch(push(path));
          }
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const OnboardingUT4CreateAccount = compose(
  withOnboardingUserType4,
  connect(null, mapDispatchToProps),
)(OnboardingUT4CreateAccountPage);
