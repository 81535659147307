import React, {Fragment} from 'react';
import {each, map} from 'lodash';
import {Link} from 'react-router-dom';

import {ROUTE} from './route';

// Changing the order of these enums may change the order they appear in the UI
export const INDEX_CONVERSION_STEP = {
  ASSIGN_INDEX: {
    shortTitle: (
      <Fragment>
        Assign
        <br /> Index
      </Fragment>
    ),
    shortTitleComplete: (
      <Fragment>
        Index
        <br /> Assigned
      </Fragment>
    ),
  },
  DRAFT_EMAIL: {
    title: 'Draft Email',
    shortTitle: (
      <Fragment>
        Draft
        <br /> Email
      </Fragment>
    ),
    shortTitleComplete: (
      <Fragment>
        Email
        <br /> Drafted
      </Fragment>
    ),
    renderDescription: ({strategyId}) => (
      <Fragment>
        Contact your investment manager to start the process of changing your reference index to use the Harmony Index.
        You can start your email using our{' '}
        <Link to={ROUTE.HARMONY_INDEX_DRAFT.path(strategyId)}>email template and IMA template</Link> in this step.
      </Fragment>
    ),
    completeActionLabel: 'Start Email Draft',
  },
  SEND_IMA_AMENDMENT: {
    title: 'Send Amendment to IMA',
    shortTitle: (
      <Fragment>
        Send
        <br /> Amendment
      </Fragment>
    ),
    renderDescription: ({strategyId}) => (
      <Fragment>
        Confirm you have sent the Amendment to your investment manager to begin the process of converting to the Harmony
        Index. You can still copy the{' '}
        <Link to={ROUTE.HARMONY_INDEX_DRAFT.path(strategyId)}>email template and IMA template</Link> again if needed.
      </Fragment>
    ),
    completeActionLabel: 'Confirm Sent',
  },
};

each(INDEX_CONVERSION_STEP, (step, key) => {
  step.step = key; // eslint-disable-line no-param-reassign
});

export const INDEX_CONVERSION_STEPS = map(INDEX_CONVERSION_STEP, value => value);
