import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const SECURITY_LISTING_FILTER = {
  COMPANIES_NOT_EMPOWERING_CHANGE: 'Companies not empowering change',
  COMPANIES_NOT_CONTACTED: 'Companies not yet contacted',
  COMPANIES_NOT_RESPONDED: 'Companies not yet responded',
};

export const SECURITY_LISTING_FILTERS = map(SECURITY_LISTING_FILTER, value => value);
