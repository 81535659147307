import React, {ReactElement} from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components/macro';
import {Theme} from '../atoms/theme';
import {Title9, Title5, Overline} from '../atoms/Typeface';
import {Accordion, AccordionList} from '../molecules/Accordion';
import {Pill} from '../molecules/Pill';
import {Quintile} from './Quintile';

const Wrapper = styled.div`
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.constants.border_radius};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.625rem 0 1.625rem;
`;

const Title = styled(Title9)`
  margin: 0;
`;

const PercentileTitle = styled(Title5)`
  &&& {
    font-size: 1.938rem;
  }
  margin: 0;
`;

const BenchmarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BenchmartTitle = styled(Overline)`
  margin: 0;
  text-transform: uppercase;
`;

const BenchmarkPercentile = styled(Title9)`
  margin: 0 0.5rem;
  font-size: 1rem;
`;

const ContentContainer = styled.div`
  padding: 1rem 1rem 0;
`;

const Divider = styled.div`
  border-top: 1px solid #dcdcdc;
  margin: 3.125rem 0 0 0;
`;

const Row = styled.div<{rowHeight: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${props => props.rowHeight};
`;

const Col = styled.div<{colWidth?: string}>`
  width: ${props => (props.colWidth ? props.colWidth : 'auto')};
`;

export const SectionSummary: React.FC<{
  title: string;
  quintile: string;
  percentile: string | ReactElement;
  rating: number;
  universe: string | ReactElement;
  activeColor?: string;
  inactiveColor?: string;
  navigation: any[];
}> = ({title, quintile, percentile, rating, universe, navigation, activeColor, inactiveColor}) => {
  return (
    <Wrapper>
      <HeaderContainer>
        <Row rowHeight="2rem">
          <Col colWidth="11.5rem">
            <Title color={Theme.colors.harmony}>{title}</Title>
          </Col>
          <Col>
            <Pill text={quintile} state="DEFAULT" />
          </Col>
        </Row>
        <Row rowHeight="4rem">
          <Col colWidth="12rem">
            <Quintile percent={percentile} quintile={rating} />
          </Col>
          <Col>
            <PercentileTitle color={Theme.colors.grey_8}>{percentile}</PercentileTitle>
          </Col>
        </Row>
        <Row rowHeight="auto">
          <Col colWidth="auto">
            <BenchmarkContainer>
              <BenchmartTitle>Benchmark</BenchmartTitle>
              <BenchmarkPercentile color={Theme.colors.harmony}>{universe}</BenchmarkPercentile>
            </BenchmarkContainer>
          </Col>
          <Col />
        </Row>
      </HeaderContainer>
      <Divider />
      <ContentContainer>
        {navigation?.map(section => {
          const {issue, topics, isActive, onClick} = section;

          const handleClick = () => {
            if (onClick) {
              onClick();
            }
          };

          return (
            <Accordion
              title={issue?.name}
              isOpen={isActive}
              onClick={handleClick}
              openBackgroundColor={activeColor + '1A'}
              closedBackgroundColor={inactiveColor}
            >
              <AccordionList data={topics} activeColor={activeColor} />
            </Accordion>
          );
        })}
      </ContentContainer>
    </Wrapper>
  );
};
