import React from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import cn from 'classnames';

const SimpleTable = ({className, columns, dataset, style}) => (
  <table className={cn('table', className)} style={style}>
    <thead>
      <tr>
        {map(columns, (label, key) => (
          <th scope="col" key={key}>
            {label}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {map(dataset, (data, index) => (
        <tr key={index}>
          {map(columns, (label, key) => (
            <td key={key}>{data[key]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

SimpleTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.object.isRequired,
  dataset: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object,
};

export {SimpleTable};
