import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {compose, lifecycle, withProps} from 'recompose';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {ROUTE} from 'app/constants';
import {getBookmarkedSecurities} from 'app/redux/complianceManager';
import {ComplianceManagerSecurityMiniListing} from './ComplianceManagerSecurityMiniListing';

const defaultSorted = [
  {
    dataField: 'name',
    order: 'asc',
  },
];

const ComplianceManagerBookmarks = compose(
  connect(
    ({complianceManager}) => ({
      hasFetched: get(complianceManager, 'bookmarkedSecurities.hasFetched'),
      isFetching: get(complianceManager, 'bookmarkedSecurities.isFetching'),
      securities: get(complianceManager, 'bookmarkedSecurities.data.items', []),
    }),
    {getBookmarkedSecurities},
  ),
  lifecycle({
    componentDidMount() {
      this.props.getBookmarkedSecurities();
    },
  }),
  withProps(({strategyId}) => ({
    tableProps: {
      defaultSorted,
      noDataIndication: () => (
        <div className="p-4">
          There are no bookmarked companies to display.
          <br />
          Visit the <Link to={ROUTE.STRATEGY_COMPANIES.path(strategyId)}>{ROUTE.STRATEGY_COMPANIES.title}</Link> page to
          bookmark companies,
          <br />
          then they will appear here.
        </div>
      ),
    },
  })),
)(({hasFetched, isFetching, securities, strategyId, tableProps}) => (
  <div style={{fontSize: '0.9375rem'}}>
    {hasFetched && (
      <Fragment>
        <ComplianceManagerSecurityMiniListing
          isFetching={isFetching}
          securities={securities}
          strategyId={strategyId}
          tableProps={tableProps}
        />
      </Fragment>
    )}
  </div>
));

ComplianceManagerBookmarks.propTypes = {
  strategyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export {ComplianceManagerBookmarks};
