import {countBy, find, intersectionWith, map, sortBy, uniqBy} from 'lodash';
import {useIssueTypes} from './useIssueTypes';
import {
  useGetManagerEngagementQuery,
  useGetPlanSponsorEngagementsQuery,
  useGetPlanSponsorHoldingsQuery,
  useGetStrategyEngagementLettersQuery,
  useGetStrategyHoldingsQuery,
} from 'v2/redux/typeormEndpoints';
import {useParams} from 'react-router';
import {getSessionJwt} from 'app/utilities';
import faChevronDoubleLeft from 'app/fontawesome-pro-light/faChevronDoubleLeft';

export const useEngagements = () => {
  const {data: issuesData} = useIssueTypes();
  const {data: issueTypes} = useIssueTypes();

  const {strategyId}: any = useParams();
  let {data: rawData, isLoading: engagementLoading} = useGetManagerEngagementQuery({strategyId});
  rawData = rawData ?? [];
  let {data: holdingsData, isLoading: holdingsLoading} = useGetManagerEngagementQuery({strategyId});
  holdingsData = holdingsData ?? [];

  // data is flattened due to an extra array layer that causes issues
  // @ts-ignore
  let data: any = {};
  rawData?.forEach((issue: any) => {
    if (data[issue.companyid]) {
      data[issue.companyid].issues.push(issue);
    } else {
      data[issue.companyid] = {companyId: issue.companyid, issues: [{...issue}]};
    }
  });
  data = Object.values(data);

  // WORKING counts the amount of unique companies that have received at least one engagement letter (blue part of progress bar)
  let totalEngagementCount = Array.from({length: 1}, () => Object.keys(countBy(data, 'companyId')).length);

  // WORKING get a total COUNT of issues engaged unique to company (no duplicate issue ids inside a company)
  const getIssuesEngagedCount = (companies: any[]) => {
    const barChartIssueTypes = [
      issueTypes.CARBON_EMISSIONS.id,
      issueTypes.ENERGY_USE.id,
      issueTypes.WATER_CONSUMPTION.id,
      issueTypes.CEO_COMPENSATION_RATIO.id,
      issueTypes.WOMEN_IN_LEADERSHIP.id,
      issueTypes.WOMEN_ON_BOARD.id,
      issueTypes.BOARD_INDEPENDENCE.id,
    ];
    let count = 0;
    companies.forEach(company => {
      let issueIds: number[] = [];
      company.issues?.forEach((issue: any) => {
        if (!issueIds.includes(issue.issues_issueId) && barChartIssueTypes.includes(issue.issues_issueId)) {
          issueIds.push(issue.issues_issueId);
          count++;
        }
      });
    });

    return count;
  };

  // TODO get a total ARRAY of issues engaged UNIQUE to company (no duplicate issue ids inside a company)
  const getIssuesEngaged = (companies: any[]) => {
    let count = 0;
    let issuesEngaged: number[] = [];
    companies.forEach(company => {
      let issueIds: number[] = [];
      company.issues?.forEach((issue: any) => {
        if (!issueIds.includes(issue.issues_issueId)) {
          issueIds.push(issue.issues_issueId);
          issuesEngaged.push(issue.issues_issueId);

          count++;
        }
      });
    });

    return issuesEngaged;
  };

  const getIssueById = (id: number) => {
    const issueObject = find(issuesData, {id: id});
    return issueObject;
  };

  const getIssueNameById = (id: number) => {
    const issueObject = find(issuesData, {id: id});
    let issueName = issueObject?.name || '';
    return issueName;
  };

  // WORKING get a list of issues engaged WITH ISSUE INFORMATION
  const getIssuesEngaged2 = () => {
    const engagementLetters = getIssuesEngaged(data);
    const issuesEngaged: any[] = [];
    engagementLetters.forEach(engagement => {
      let engagementIssue = getIssueById(engagement);
      issuesEngaged.push(engagementIssue);
    });
    return issuesEngaged;
  };

  // TODO get a count of issues by category
  const getIssuesEngagedByCategory = () => {
    const engagementLetters = getIssuesEngaged2();
    // const issuesEngagedByCategory = Array.from({ length: 1 }, () => Object.keys(countBy(engagementLetters, 'id')).length);
    let mappedIssues = map(countBy(engagementLetters, 'topLevelParentId'), (val: any, key: any) => ({
      id: key,
      count: val,
    }));
    let totalIssues = 0;
    let includedIssues: any = {};
    mappedIssues.forEach(issue => {
      totalIssues += issue.count;
      let issueName = getIssueNameById(parseInt(issue.id));
      includedIssues = {...includedIssues, [issue.id]: issueName};
    });

    const basedCategoryId = [1, 2, 3];
    // Add basedCategoryId to prevent out of bound error and keep data consistent
    // between the bar and doughnut chart
    basedCategoryId.forEach(id => {
      if (!includedIssues[id]) {
        mappedIssues.push({id, count: 0});
      }
    });

    mappedIssues.sort((a, b) => parseInt(a.id) - parseInt(b.id));
    let newMappedIssues: any[] = [['Category', 'Percent']];

    mappedIssues.forEach(issue => {
      let issueId = issue.id;
      let parseIntResult: number = parseInt(issueId);
      let issueName = getIssueNameById(parseIntResult);
      let issueCount = parseInt(((issue.count / totalIssues) * 100).toFixed(2)) || 0;
      let newMapObject = map({issueName: issueName, count: issueCount});
      newMappedIssues.push(newMapObject);
    });

    return newMappedIssues;
  };

  const getIssuesEngagedByNestedSubcategory = (acceptedSubcategories: any[]) => {
    const engagementLetters = getIssuesEngaged2();
    let mappedIssues = map(countBy(engagementLetters, 'id'), (val: any, key: any) => ({id: key, count: val}));

    const myMap: any = {};
    let newMappedIssues: any[] = [];

    const acceptable = acceptedSubcategories.map(issue => issue.id);

    mappedIssues.forEach(issue => {
      let issueId = issue.id;
      let parseIntResult: number = parseInt(issueId);
      let issueObject = getIssueById(parseIntResult);
      let issueParentId = issueObject?.topLevelParentId;
      let issueName = issueObject?.name;

      let newMapObject = map({category: issueParentId, issueName: issueName, count: issue.count});
      if (acceptable.includes(parseInt(issueId))) newMappedIssues.push(newMapObject);

      myMap[issueId] = {category: issueParentId, issueName: issueName, count: issue.count};
    });

    acceptedSubcategories.forEach(issue => {
      if (!myMap[issue.id]) newMappedIssues.push([issue.topLevelParentId, issue.name, 0]);
    });

    let mappedCategories = {
      [issueTypes.ENVIRONMENTAL.id]: [] as any[],
      [issueTypes.SOCIAL.id]: [],
      [issueTypes.GOVERNANCE.id]: [],
    };

    newMappedIssues = sortBy(newMappedIssues, issue => issue[1]);
    newMappedIssues.forEach(issue => {
      if (mappedCategories[parseInt(issue[0])]) {
        mappedCategories[parseInt(issue[0])].push(issue[2]);
      } else {
        mappedCategories[issue.category] = [];
      }
    });
    return Object.values(mappedCategories);
  };

  const getCompaniesEngagedForIssue = (issueId: number, sector: any) => {
    const companyIds = new Set();
    data.map((datum: any) => {
      datum.issues.map((issue: any) => {
        if (issue.issues_issueId == issueId) {
          companyIds.add(datum.companyId);
        }
      });
    });
    let companyArr: any[] = [];
    let sectors = new Set();
    holdingsData.map((holding: any) => {
      if (companyIds.has(holding.companyid)) {
        companyArr.push({
          company: holding.companyname,
          sector: holding.sector_name,
          held: holding.amountheld,
          id: holding.companyid,
        });
        if (!sectors.has(holding.sector_name)) {
          sectors.add(holding.sector_name);
        }
      }
    });
    companyArr = sortBy(companyArr, company => -Number(company.held));
    let sectorsForDropDown = Array.from(sectors).map(sector => {
      return {value: sector, label: sector};
    });
    companyArr = uniqBy(companyArr, company => company.id);

    sectorsForDropDown.push({label: 'All Sectors', value: null});
    sectorsForDropDown = sortBy(sectorsForDropDown, (sector: any) => sector.label);
    if (sector?.value) {
      //@ts-ignore
      companyArr = companyArr.filter(company => company.sector === sector.value);
    }
    return {holdings: companyArr, sectors: sectorsForDropDown};
  };

  return {
    data,
    isLoading: holdingsLoading || engagementLoading,
    totalEngagementCount,
    getIssuesEngagedCount,
    getIssuesEngagedByCategory,
    getIssuesEngagedByNestedSubcategory,
    getCompaniesEngagedForIssue,
  };
};
