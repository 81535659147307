export const formatMillions = (value: number, decimals: number = 0, prefix?: string): string => {
  if (value === 0) {
    if (prefix) {
      return prefix + String(value);
    }
    return String(value);
  }

  if (value < 100000) {
    return `< ${prefix}0.1M`;
  }

  const millions = value / 10 ** 6;
  const millionsFormatted =
    millions.toLocaleString(undefined, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}) + 'M';
  return prefix ? prefix + millionsFormatted : millionsFormatted;
};
