export class DRating {
  holdingsData: any[]; //TODO: Use types
  percentileRank: number;

  constructor(holdingsData: any[]) {
    this.holdingsData = holdingsData;
    this.percentileRank = this.calcPercentileRank(this.holdingsData);
  }

  protected calcPercentileRank(holdingsData: any[]) {
    let totalWeight = 0;
    let totalWeightedRanks = 0;

    holdingsData.forEach((datum: any) => {
      totalWeight += Number(datum.amountHeld);
      totalWeightedRanks += Number(datum.amountHeld) * Number(datum.companyPctRank);
    });

    return totalWeightedRanks / totalWeight;
  }

  getPercentileRank(): number {
    return this.percentileRank; //TODO: Apply formatting
  }
}
