import {head, tail, trimStart} from 'lodash';
/**
 * Normalizes a float
 * @param {string} value The current value being entered in
 * @returns {string} Returns the value, adding the elements of the recommended format
 */

const getPercentHead = digitGroups => {
  const digitsHead = head(digitGroups);

  // Only allow three 3 before the decimal for 100, otherwise trim to 2 digits
  return digitsHead.substr(0, 3) === '100' ? '100' : digitsHead.substr(0, 2);
};

export const normalizePercentFloat = value => {
  if (!value) return '';

  // Get value with only digits and decimals
  const number = trimStart(value.replace(/[^\d.]/g, ''));

  if (!number) return '';

  // Split number into digits before and after decimal
  const digitGroups = number.split('.');
  const percentHead = getPercentHead(digitGroups);
  let percentTail = tail(digitGroups).join('').substr(0, 2);

  // Return integer
  if (digitGroups.length === 1) {
    return percentHead;
  }

  // For values 100+, replace decimal digits with zeros
  if (parseFloat(percentHead) >= 100) {
    percentTail = percentTail.replace(/[^0]/g, '0');
  }

  return `${percentHead}.${percentTail}`;
};
