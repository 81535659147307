import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {debounce, get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';

// Local Imports
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {Alert, Button, BootstrapTableRemote} from 'app/components';
import {getMarketIndexPaged} from 'app/redux/adminMarketIndex';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';

/**
 * Format the content of a Held By cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
function editCellFormatter() {
  return <Icon icon={faPencil} />;
}

/**
 * Format the content of a Held By cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
function editHeaderFormatter() {
  return <span className="sr-only">Edit</span>;
}

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    style: {minWidth: 500},
    headerStyle: {minWidth: 500},
  },
  {
    dataField: 'isHarmonyIndex',
    text: 'Harmony Index?',
    sort: true,
    headerStyle: {width: 175},
  },
  {
    dataField: 'edit',
    text: '',
    style: {width: 30},
    headerStyle: {width: 30},
    formatter: editCellFormatter,
    headerFormatter: editHeaderFormatter,
  },
];

class AdminMarketIndexManagerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {search: props.search};

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleSearchDebounced = debounce(this.handleSearch, 500);
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(this.props.sortField, this.props.sortOrder, this.props.page, this.props.pageSize, this.props.search);

    this.searchInput.focus();
    animateScroll.scrollToTop();
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search) {
      updatedPage = 1;
    }
    this.props.dispatch(push({search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search)}));
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    this.handleSearchDebounced();
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search} = this.props;

    this.props.dispatch(
      push({
        pathname: ROUTE.ADMIN_MARKET_INDEX_EDIT.path(row.id),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search),
      }),
    );
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleSearch = () => {
    this.updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search) => {
    this.props.dispatch(
      getMarketIndexPaged({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
      }),
    );
  };

  render() {
    const {data, hasError, sortField, sortOrder, page, pageSize, totalSize} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 800}}>
          <h1>{ROUTE.ADMIN_MARKET_INDEXES.title}</h1>

          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          <form method="GET" onSubmit={this.handleSearch}>
            <div className="d-flex mb-8">
              <div className="form-control-and-icon flex-grow-1 mr-2">
                <input
                  className="form-control"
                  ref={input => {
                    this.searchInput = input;
                  }}
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search by Reference Index Name"
                  value={this.state.search}
                  onChange={this.changeField}
                />
                <div className="icon">
                  <Icon icon={faSearch} />
                </div>
              </div>
              <Button color="gray" type="submit">
                Search
              </Button>
            </div>
          </form>

          <BootstrapTableRemote
            columns={columns}
            data={data}
            page={page}
            sizePerPage={pageSize}
            totalSize={totalSize}
            onTableChange={this.handleTableChange}
            defaultSorted={defaultSorted}
            selectRow={{
              mode: 'radio',
              hideSelectColumn: true,
              clickToSelect: true,
              onSelect: this.handleOnSelect,
            }}
          />
        </div>
      </div>
    );
  }
}

AdminMarketIndexManagerPage.defaultProps = {
  data: [],
  totalSize: 0,
};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(ownProps, {
    search: '',
    page: 1,
    pageSize: 25,
    sortField: 'name',
    sortOrder: 'asc',
  });
  newProps.data = get(state, 'adminMarketIndex.listing.data');
  newProps.totalSize = get(state, 'adminMarketIndex.listing.totalSize');
  newProps.hasError = get(state, 'adminMarketIndex.hasError');
  return newProps;
};

export const AdminMarketIndexManager = connect(mapStateToProps)(AdminMarketIndexManagerPage);
