import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {toastSuccess, toastError} from 'app/utilities/toast';
import {IssueDataCardHeader} from './IssueDataCardHeader';
import {DisplayInputField} from './DisplayInputField';
import {ISSUE, TREND_TEXT, TREND_TEXT_COLOR, CONFIG} from '../constants';
import {getAnnualPendingIssueData, restate} from '../redux/companyAnnualIssues';
import {withUser} from '../utilities/withUser';
import {getPercentChange, getTrendType} from '../utilities/dataAnalyzer';
import {formatNumberCommas} from '../utilities/formatNumberCommas';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {convertUnits, getPrecision} from 'v2/utilities/helpers/convertUnits';
import {ISSUE_UNITS} from 'v2/constants/issueOptions';

class IssueCardWithProgressComponent extends Component {
  constructor(props) {
    super(props);

    const {id: issueId, issueType, target, data: originalData} = props.data;
    this.thisYear = CONFIG.REPORTING_YEAR;
    this.keyYears = Array.from({length: CONFIG.REPORTING_YEAR_OFFSET}, (_, i) => this.thisYear - i).reverse();
    this.data = {};
    this.dataInputs = {};
    this.state = {
      editing: false,
      formValues: this.keyYears.map(year => null),
    };

    if (issueType === 'Boolean') {
      this.keyYears.forEach(year => {
        this.data[year] = originalData[year];
      });
    } else {
      this.keyYears.forEach(year => {
        const datum = Object.assign({}, originalData[year]);
        const value = datum.value === null || datum.value === undefined ? undefined : parseFloat(datum.value);
        const precision = !!ISSUE?.[issueId] && ISSUE[issueId].precision;

        let displayValue;

        if (value === undefined) {
          displayValue = undefined;
        } else if (value >= 10 ** precision) {
          displayValue = formatNumberCommas(value.toFixed(0));
        } else if (value !== 0) {
          displayValue = formatNumberCommas(value.toPrecision(precision));
        }

        this.data[year] = Object.assign(datum, {
          value,
          displayValue,
        });
      });

      this.trendType = getTrendType(
        target,
        this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 2]] &&
          this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 2]].value,
        this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 1]] &&
          this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 1]].value,
      );
      this.percentChange = getPercentChange(
        this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 2]] &&
          this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 2]].value,
        this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 1]] &&
          this.data[this.keyYears[CONFIG.REPORTING_YEAR_OFFSET - 1]].value,
      );
    }

    this.keyYears.forEach(year => {
      const datum = this.data[year];

      if (issueType === 'Boolean') {
        this.dataInputs[year] = {
          type: 'select',
          initialValue:
            datum !== undefined && datum.value !== undefined && datum.value !== null
              ? Boolean(parseInt(datum.value, 10))
              : DATA_STATUS.NONE,
          options: [
            {value: false, name: 'No'},
            {value: true, name: 'Yes'},
          ],
        };
      } else {
        this.dataInputs[year] = {
          type: 'number',
          initialValue: datum.displayValue,
        };
      }
    });
  }

  edit = () => {
    this.setState({editing: true});
  };

  onChangeInput = (i, value) => {
    const newFormValues = this.state.formValues;
    newFormValues[i] = value === this.dataInputs[this.keyYears[i]].initialValue ? null : value;

    this.setState({
      formValues: newFormValues,
    });
  };

  saveEdits = async event => {
    try {
      event.preventDefault();

      const companyId = this.props.authUser.companyPortals[0].id;
      const issueId = this.props.data.id;
      const years = this.keyYears;

      const submissionData = this.state.formValues
        .map((value, i) => {
          return {
            issueId,
            value,
            year: years[i],
          };
        })
        .filter(entry => entry.value !== null);

      await this.props.dispatch(restate(companyId, submissionData));
      await this.props.dispatch(getAnnualPendingIssueData(companyId));

      this.setState({
        formValues: [null, null, null, null],
      });

      toastSuccess(`
        Your restate request was submitted successfully!<br/>
        In the meantime, we'll continue to show the value we have on file.
      `);

      this.stopEditing();
    } catch (error) {
      toastError(`
        There was a problem processing your restate request.<br/>
        Please try again later, or contact support.
      `);
    }
  };

  stopEditing = () => {
    this.setState({editing: false});
  };

  fieldValue = (fieldValue, inputValue, unit) => {
    const value = !!fieldValue ? fieldValue : inputValue;

    if (!value) {
      return DATA_STATUS.NONE;
    }

    switch (unit) {
      case ISSUE_UNITS.MMTCO2E:
        return Number(convertUnits(value, ISSUE_UNITS.MMTCO2E, ISSUE_UNITS.MTCO2E, getPrecision(value))).toFixed(2);
      default:
        return value;
    }
  };

  issueUnit = unit => {
    switch (unit) {
      case ISSUE_UNITS.MMTCO2E:
        return ISSUE_UNITS.MTCO2E;
      default:
        return unit;
    }
  };

  render() {
    const {category, data: issueData, flip} = this.props;
    // eslint-disable-next-line no-unused-vars
    const {unit, data} = issueData;

    return (
      <div className="issue-data-card h-100 w-100 py-5 d-flex flex-column">
        <IssueDataCardHeader mode="table" category={category} issue={issueData} flip={flip} />
        <div className="issue-data-card-table-container w-100 d-flex flex-grow-1 pt-2" style={{overflowY: 'scroll'}}>
          <table className="issue-data-card-table flex-grow-1 h-100 w-100">
            <tbody>
              {this.percentChange === undefined ? (
                <tr />
              ) : (
                <tr style={{color: TREND_TEXT_COLOR[this.trendType]}}>
                  <td className="font-weight-medium" style={{fontSize: '1.25rem'}}>
                    {TREND_TEXT[this.trendType]}
                  </td>
                  <td className="text-right font-weight-bold" style={{fontSize: '1.5rem'}}>
                    {this.percentChange === null
                      ? DATA_STATUS.NONE
                      : formatNumberCommas(Math.abs(this.percentChange.toFixed(1))) + '%'}
                  </td>
                </tr>
              )}
              {this.keyYears.map((year, i) => {
                const input = this.dataInputs[year];
                return (
                  <tr>
                    <td>{this.keyYears[i]}</td>
                    <td className="text-right">
                      <div className="w-100 d-flex justify-content-end">
                        <DisplayInputField
                          editing={this.state.editing}
                          value={this.fieldValue(this.state.formValues[i], input.initialValue, unit)}
                          inputClassName="mw-0 flex-1 text-right"
                          type={input.type}
                          options={input.options}
                          onChange={value => {
                            this.onChangeInput(i, value, input.initialValue);
                          }}
                        />
                        {input.initialValue !== undefined && input.initialValue !== null && (
                          <span className={`${unit === '%' ? 'align-self-center' : 'ml-1 align-self-center'}`}>
                            {this.issueUnit(unit)}
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

IssueCardWithProgressComponent.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  flip: PropTypes.func.isRequired,
};

export const IssueDataCardFront = compose(withUser)(IssueCardWithProgressComponent);
