import {Theme} from '../theme';

export const LogoBadge5 = ({size = 270}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 270 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M229.666 39.3689C187.751 -2.64715 124.904 -11.018 74.6292 14.2001L30.542 184.334C34.9859 178.139 40.5126 171.183 47.0307 164.277C71.6971 138.347 98.8175 126.759 125.474 130.878C140.12 133.103 154.675 144.55 171.502 157.8C194.417 175.872 220.455 196.31 252.068 196.31H254.002C280.18 145.676 272.101 81.8497 229.666 39.3689Z"
        fill="#003462"
      />
      <path
        d="M39.753 39.3689C-4.24283 83.4196 -11.3304 150.414 18.4408 201.843L65.8328 18.9804C56.5231 24.5563 47.7337 31.3219 39.753 39.3689Z"
        fill="#003462"
      />
      <path
        d="M124.384 138.108C65.4602 129.167 24.7268 207.137 24.2979 207.94C24.0166 208.51 23.5877 208.933 23.0674 209.313C27.8839 216.459 33.4178 223.274 39.704 229.61C92.1587 282.13 177.211 282.13 229.666 229.61C237.654 221.612 244.411 212.861 249.938 203.589C216.813 202.688 190.395 181.969 167.009 163.566C150.984 150.928 137.139 140.044 124.384 138.108Z"
        fill="#003462"
      />
    </svg>
  );
};
