import {ISSUES} from 'v2/constants/issues';
import {
  IssueType,
  AggregatePercentileUniverse,
  AggregatePercentileHolding,
  MarketIndexBenchmark,
} from 'v2/utilities/types/typeOrm';
import {getOrdinal} from '../helpers';

import {
  calculateUniverseStatistics as calcUniverseStatistics,
  calculateHoldingsStatistics as calcHoldingsStatistics,
  calculateBenchmarkStatistics as calcBenchmarkStatistics,
} from 'v2/utilities/helpers/calculateStatistics';
import {DATA_STATUS} from 'v2/constants/dataStatus';

export class DSectionSummary {
  private rootIssueId: number;
  private issues: IssueType[];
  private issueNavigation: any[];
  private rootIssue: IssueType | undefined;
  private primaryColor: string | undefined;
  private secondaryColor: string | undefined;

  private filters: {[key: string]: string};
  private esgFilters: any;

  private accordionAction: any;
  private linkAction: any;
  private subLinkAction: any;

  private universePercentiles: {[key: number]: number} = {};
  private benchmarkPercentiles: {[key: number]: number} = {};
  private percentiles: {[key: number]: number} = {};
  private quintiles: {[key: number]: number} = {};
  private companies: Set<number>;

  constructor(
    rootIssueId: number,
    issues: IssueType[],
    holdingsData: AggregatePercentileHolding[],
    universeData: AggregatePercentileUniverse[],
    marketIndexesData: MarketIndexBenchmark[],
    filters: {[key: string]: string},
    esgFilters: any,
    accordionAction: any,
    linkAction: any,
    subLinkAction: any,
    primaryColor?: string,
    secondaryColor?: string,
  ) {
    this.rootIssueId = rootIssueId;
    this.issues = issues;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;

    this.filters = filters;
    this.companies = new Set();
    this.esgFilters = esgFilters;

    this.accordionAction = accordionAction;
    this.linkAction = linkAction;
    this.subLinkAction = subLinkAction;

    this.issueNavigation = this.getIssueNavigation();
    this.rootIssue = this.getRootIssue();
    this.calculateHoldingsStatistics(holdingsData);
    this.calculateUniverseStatistics(universeData);
    this.calculateBenchmarkStatistics(marketIndexesData ?? []);
  }

  private getRootIssue() {
    const rootIssue = this.issues.find(issue => issue.id === this.rootIssueId);

    return rootIssue;
  }

  private getIssueNavigation() {
    const categoryIssues = this.issues.filter(issue => issue.parentId === this.rootIssueId);

    let paths: any = [];

    categoryIssues.forEach(issue => {
      paths.push({
        issue,
        isActive: issue.id === this.esgFilters.topic?.id,
        onClick: () => this.accordionAction(issue),
        topics: this.issues
          .filter(currentIssue => {
            return currentIssue.parentId === issue.id;
          })
          .map(currentIssue => {
            return {
              issue: currentIssue,
              isActive: currentIssue.id === this.esgFilters.subTopic?.id,
              onClick: () => this.linkAction(currentIssue),
              topics: this.issues
                .filter(tertIssue => {
                  return tertIssue.parentId === currentIssue.id;
                })
                .map(currentIssue => {
                  return {
                    issue: currentIssue,
                    onClick: () => this.subLinkAction(currentIssue),
                    isActive: currentIssue.id === this.esgFilters.tertiaryTopic?.id,
                  };
                }),
            };
          }),
      });
    });

    return paths;
  }

  private getPercentile() {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 1,
      [ISSUES.SOCIAL.id]: 2,
      [ISSUES.GOVERNANCE.id]: 3,
    };

    return percentiles[this.rootIssueId];
  }

  private calculateHoldingsStatistics(data: AggregatePercentileHolding[]) {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 0,
      [ISSUES.SOCIAL.id]: 0,
      [ISSUES.GOVERNANCE.id]: 0,
    };

    let holdingsPercentiles = calcHoldingsStatistics(percentiles, this.companies, this.filters, this.issues, data);

    this.percentiles = holdingsPercentiles.percentiles;
    this.quintiles = holdingsPercentiles.quintiles;
  }

  private calculateUniverseStatistics(data: AggregatePercentileUniverse[]) {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 0,
      [ISSUES.SOCIAL.id]: 0,
      [ISSUES.GOVERNANCE.id]: 0,
    };

    this.universePercentiles = calcUniverseStatistics(percentiles, this.companies, data);
  }

  private calculateBenchmarkStatistics(data: MarketIndexBenchmark[]) {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 0,
      [ISSUES.SOCIAL.id]: 0,
      [ISSUES.GOVERNANCE.id]: 0,
    };

    this.benchmarkPercentiles = calcBenchmarkStatistics(percentiles, this.companies, data);
  }

  title() {
    return this.rootIssue?.name ?? DATA_STATUS.NONE;
  }

  quintile(): string {
    const ordinal = getOrdinal(this.quintiles[this.getPercentile()]);

    return this.quintiles[this.getPercentile()]
      ? this.quintiles[this.getPercentile()] + ordinal + ' quintile'
      : DATA_STATUS.NONE;
  }

  percentile(): any {
    const value = Math.round(this.percentiles[this.getPercentile()] ?? 0);
    const ordinal = getOrdinal(value);

    return this.percentiles[this.getPercentile()] ? (
      <span>
        {value}
        <sup>{ordinal}</sup>
      </span>
    ) : (
      DATA_STATUS.NONE
    );
  }

  rating(): number {
    return this.quintiles[this.getPercentile()];
  }

  universe(): any {
    const value = Math.round(this.universePercentiles[this.getPercentile()]);
    const ordinal = getOrdinal(value);
    return this.universePercentiles[this.getPercentile()] ? (
      <span>
        {value}
        <sup>{ordinal}</sup>
      </span>
    ) : (
      DATA_STATUS.NONE
    );
  }

  benchmark(): any {
    const value = Math.round(this.benchmarkPercentiles[this.getPercentile()]);
    const ordinal = getOrdinal(value);
    return this.benchmarkPercentiles[this.getPercentile()] ? (
      <span>
        {value}
        <sup>{ordinal}</sup>
      </span>
    ) : (
      DATA_STATUS.NONE
    );
  }

  navigation() {
    return this.issueNavigation;
  }

  activeColor() {
    return this.primaryColor ?? '';
  }

  inactiveColor() {
    return this.secondaryColor ?? '';
  }
}
