import {useCallback, useEffect, useMemo, useState} from 'react';
import {RootStateOrAny, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {get, isArray, isNull, isUndefined} from 'lodash';
import {EmailPaywall} from 'v2/components/organisms/EmailPaywall';
import {usePageTitle} from 'v2/hooks/usePageTitle';
import {Callback, Middleware} from './types';
import {getAuthJwt, getImpersonationJwt, isImpersonating} from 'app/utilities';
import {useGetStrategyByFirmIdQuery, useLazyGetStrategyQuery} from 'v2/redux/typeormEndpoints';
import {fetchAuthJSON} from 'app/services/http';
import {toastError, toastSuccess} from 'app/utilities/toast';

const Layout: React.FC<{}> = ({children}) => {
  const history = useHistory();
  const location = useLocation();
  const title = usePageTitle();

  const token = isImpersonating() ? getImpersonationJwt(true) : getAuthJwt(true);

  const firmId = useSelector((state: RootStateOrAny) => {
    if (isImpersonating()) {
      return get(state, 'auth.impersonatedUser.firmId');
    }

    return get(state, 'auth.firmId');
  });

  const {strategyId = null} = useParams<{strategyId?: string}>();

  const [trigger, result, lastPromiseInfo] = useLazyGetStrategyQuery();
  const {data: strategy, isLoading: isStrategyLoading, isSuccess: isStrategySuccess} = result;

  const shouldFetchStrategy = !isNull(strategyId) && result && location.pathname.includes('clients');

  useEffect(() => {
    if (shouldFetchStrategy && lastPromiseInfo.lastArg.strategyId !== strategyId) {
      trigger({strategyId});
    }
  }, [lastPromiseInfo, shouldFetchStrategy, strategyId, trigger]);

  const {
    data: strategies,
    isLoading: isStrategiesLoading,
    isSuccess: isStrategiesSuccess,
  } = useGetStrategyByFirmIdQuery({firmId});

  const hasLiscencedFirm = useMemo(() => {
    if (!isArray(strategies) || !isStrategiesSuccess) {
      return false;
    }

    return !isUndefined(strategies.find(strategy => !isNull(strategy.licensedDate)));
  }, [isStrategiesSuccess, strategies]);

  const isSelectedStrategyLicensed = useMemo(() => {
    if (!isStrategySuccess || isStrategyLoading) {
      return false;
    }

    return !isNull(strategy.licensedDate);
  }, [isStrategyLoading, isStrategySuccess, strategy]);

  const isLoading = isStrategiesLoading || isStrategyLoading;

  const isAllowed = shouldFetchStrategy ? isSelectedStrategyLicensed : hasLiscencedFirm;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitCallback = useCallback(
    async (values: {message: string}) => {
      const email = get(token, 'contents.email', null);
      const updatedValues = {
        email,
        ...values,
      };

      setIsSubmitting(true);

      return await fetchAuthJSON('user/contact', {
        method: 'post',
        body: JSON.stringify(updatedValues),
      })
        .then(res => {
          if (res?.message) {
            toastSuccess(res.message);
          }
          setIsSubmitting(false);
        })
        .catch(err => {
          toastError('Whoops... There was a problem generating your message. Please try again later.');

          console.error({err});
          setIsSubmitting(false);
        });
    },
    [token],
  );

  const handleClose = () => {
    history.goBack();
  };

  return isLoading ? (
    <>{children}</>
  ) : (
    <>
      <EmailPaywall
        title={title}
        onSubmit={handleSubmitCallback}
        onClose={handleClose}
        isLoading={isSubmitting}
        visible={!isImpersonating() && !isAllowed}
      />
      {children}
    </>
  );
};

export const emailPaywallMiddleware: Middleware = async (next: Callback) => {
  return <Layout>{await next()}</Layout>;
};
