import {Field} from 'react-final-form';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {CARBON_NEUTRAL_PLEDGE_STATUS_LIST} from 'v2/constants/issueOptions';
import {AdminCarbonNeutralPledgeCard as AdminCarbonNeutralPledgeCardProps} from 'v2/utilities/types/components/AdminFormCard';
import {Container, FlexContainer} from '../atoms/Containers';
import {AdminFormCard} from '../molecules/AdminFormCard';
import {DatePicker} from '../molecules/DatePicker';
import {SelectFormGroup} from '../molecules/DropDown';
import {TextFormGroup} from '../molecules/TextInput';
import {FileBrowserWithUploadInput} from './FileBrowserWithUploadInput';

export const AdminCarbonNeutralPledgeCard = ({
  name,
  companyId,
  issueId,
  ...rest
}: AdminCarbonNeutralPledgeCardProps) => {
  type FormValues = {
    carbonNeutralPledgePlan: string;
    carbonNeutralPledgeTargetYear: string;
    carbonNeutralPledgeStatus: string;
    carbonNeutralPledgeSigned: Date;
    carbonNeutralSourceName: string;
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.carbonNeutralSourceName) {
      errors.carbonNeutralSourceName = 'File Uploaded is required';
    }

    if (!values.carbonNeutralPledgePlan) {
      errors.carbonNeutralPledgePlan = 'File is required';
    }

    if (!values.carbonNeutralPledgeStatus) {
      errors.carbonNeutralPledgeStatus = 'Pledge Status is required';
    }

    if (!values.carbonNeutralSourceName) {
      errors.carbonNeutralSourceName = 'Source Name is required';
    }

    if (!values.carbonNeutralPledgeTargetYear) {
      errors.carbonNeutralPledgeTargetYear = 'Target Year is required';
    } else {
      if (
        String(values.carbonNeutralPledgeTargetYear).length !== 4 ||
        isNaN(Number(values.carbonNeutralPledgeTargetYear))
      ) {
        errors.carbonNeutralPledgeTargetYear = 'Target Year must be a valid year';
      }
    }

    if (!values.carbonNeutralPledgeSigned) {
      // @ts-ignore
      errors.carbonNeutralPledgeSigned = 'Date Signed is required';
    }

    return errors;
  };

  return (
    <AdminFormCard
      title={name ? `Editing Carbon Neutral Pledge For ${name}` : DATA_STATUS.NONE}
      validate={validate}
      fields={[
        'carbonNeutralPledgePlan',
        'carbonNeutralPledgeStatus',
        'carbonNeutralPledgeSigned',
        'carbonNeutralPledgeTargetYear',
        'carbonNeutralSourceName',
        'fileName',
      ]}
      issueId={issueId}
      {...rest}
    >
      <FlexContainer justify="space-between" gap="1rem">
        <Field name="carbonNeutralSourceName">
          {props => (
            <FlexContainer flex="1" direction="column">
              <label htmlFor={props.input.name}>Source Name</label>
              <TextFormGroup
                input={props.input}
                placeholder={DATA_STATUS.NONE}
                width="17rem"
                meta={props.meta.touched && props.meta}
              />
            </FlexContainer>
          )}
        </Field>

        <FlexContainer flex="1" direction="column">
          <label>Source File</label>
          <FileBrowserWithUploadInput
            accept="application/pdf, .pdf"
            companyId={companyId}
            name="carbonNeutralPledgePlan"
            issueId={issueId}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer padding="48px 0 0" justify="space-between" gap="2rem">
        <Field name="carbonNeutralPledgeStatus">
          {props => (
            <Container width="70%">
              <label htmlFor={props.input.name}>Pledge Status</label>
              <SelectFormGroup
                input={props.input}
                placeholder={DATA_STATUS.NONE}
                width="100%"
                options={CARBON_NEUTRAL_PLEDGE_STATUS_LIST}
                meta={props.meta.touched && props.meta}
              />
            </Container>
          )}
        </Field>
      </FlexContainer>
      <FlexContainer padding="48px 0 0" justify="space-between" gap="2rem">
        <Field name="carbonNeutralPledgeTargetYear">
          {props => (
            <FlexContainer flex="1" direction="column">
              <label htmlFor={props.input.name}>Target Year</label>
              <TextFormGroup
                input={props.input}
                placeholder={DATA_STATUS.NONE}
                meta={props.meta.touched && props.meta}
              />
            </FlexContainer>
          )}
        </Field>
        <Field name="carbonNeutralPledgeSigned">
          {props => (
            <FlexContainer flex="1" direction="column">
              <label htmlFor={props.input.name}>Date Signed</label>
              <DatePicker
                width="100%"
                selected={props.input.value}
                placeholderText={DATA_STATUS.NONE}
                {...props.input}
                meta={props.meta.touched && props.meta}
              />
            </FlexContainer>
          )}
        </Field>
      </FlexContainer>
    </AdminFormCard>
  );
};
