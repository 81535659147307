import {isFinite} from 'lodash';

export const toFriendlyPrecision = (value, precision = 4, defaultResult = 0) => {
  // Confirm that the value is a valid finite number, if not then return default result
  if (!isFinite(value)) {
    return defaultResult;
  }

  return parseFloat(value.toPrecision(precision));
};
