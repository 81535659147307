import styled from 'styled-components/macro';
import ReactPlaceholder from 'react-placeholder';
import {RectShape} from 'react-placeholder/lib/placeholders';
import {ParagraphS, Body1, Title4} from '../atoms/Typeface';
import {ManagerInfoBarProps} from '../interfaces/LayoutProps';
import {formatMillions, getOrdinal} from '../../utilities/helpers';
import {StrategyPercentileRank} from '../molecules/PercentileRankDisplays';
import {getDayMonthYear} from 'v2/utilities/helpers/getDayMonthYear';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {Theme} from '../atoms/theme';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  width: auto;
  max-width: ${props => props.theme.constants.fixWidth};
  min-width: 900px;
  min-height: 156px;
  margin: 0 auto;
  margin-top: 1.6875rem;
  border: 0.125rem solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0rem 0.125rem 0.25rem ${props => props.theme.colors.black}26;
  border-radius: 0.5rem;
  padding: 2rem 3rem;
`;

const CardTitleContainer = styled.div``;

const CardTitleText = styled(Title4)`
  &&& {
    margin: 0;
    color: ${props => props.theme.colors.harmony};
  }
`;

const DataLabel = styled(ParagraphS)`
  &&& {
    margin: 0;
    color: ${props => props.theme.colors.grey_5};
  }
`;

const DataText = styled(Body1)`
  &&& {
    margin: 0;
    color: ${props => props.theme.colors.dark};
  }
`;

const DataContainer = styled.div`
  flex: 1;
`;

const PercentileContainer = styled.div`
  marginbottom: '1.25rem';
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
`;
const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
  width: 33%;
`;

const EllipsesContainer = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ReactPlaceholderESGRisk = () => (
  <div>
    <GridWrapper style={{width: '100%'}}>
      <CardTitleContainer>
        <RectShape color={Theme.colors.grey_2} style={{width: 500, height: 20}} />
      </CardTitleContainer>
    </GridWrapper>
    <FlexRow>
      <DataContainer>
        <FlexRow>
          <GridWrapper>
            <RectShape color={Theme.colors.grey_2} style={{width: 70, height: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: 150, height: 10, marginTop: 10}} />
          </GridWrapper>
          <GridWrapper>
            <RectShape color={Theme.colors.grey_2} style={{width: 70, height: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: 150, height: 10, marginTop: 10}} />
          </GridWrapper>
          <GridWrapper>
            <RectShape color={Theme.colors.grey_2} style={{width: 70, height: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: 150, height: 10, marginTop: 10}} />
          </GridWrapper>
        </FlexRow>
        <FlexRow>
          <GridWrapper>
            <RectShape color={Theme.colors.grey_2} style={{width: 70, height: 10, marginTop: 30}} />
            <RectShape color={Theme.colors.grey_2} style={{width: 150, height: 10, marginTop: 10}} />
          </GridWrapper>

          <GridWrapper>
            <RectShape color={Theme.colors.grey_2} style={{width: 70, height: 10, marginTop: 30}} />
            <RectShape color={Theme.colors.grey_2} style={{width: 150, height: 10, marginTop: 10}} />
          </GridWrapper>
          <GridWrapper></GridWrapper>
        </FlexRow>
      </DataContainer>
      <FlexColumn>
        <RectShape color={Theme.colors.grey_2} style={{width: 70, height: 10}} />
        <RectShape color={Theme.colors.grey_2} style={{width: 120, height: 100, marginTop: 10}} />
      </FlexColumn>
    </FlexRow>
  </div>
);

export const ManagerInfoHeader: React.FC<ManagerInfoBarProps> = ({
  managerName,
  productName,
  indexName,
  portfolioValue = 0,
  portfolioManagementType,
  effectiveDate,
  percentileRank,
  marketIndexPercentileRank,
  isLoading,
}) => {
  const percentileOrdinal = getOrdinal(Math.round(percentileRank * 100));
  const marketIndexOrdinal = getOrdinal(Math.round(marketIndexPercentileRank * 100));

  return (
    <CardWrapper>
      <ReactPlaceholder ready={!isLoading} customPlaceholder={<ReactPlaceholderESGRisk />}>
        <GridWrapper style={{width: '100%'}}>
          <CardTitleContainer>
            <CardTitleText>{managerName}</CardTitleText>
          </CardTitleContainer>
        </GridWrapper>
        <FlexRow>
          <DataContainer>
            <FlexRow>
              <GridWrapper>
                <DataLabel>Product:</DataLabel>
                <DataText>
                  <EllipsesContainer>{productName}</EllipsesContainer>
                </DataText>
              </GridWrapper>
              <GridWrapper>
                <DataLabel>Portfolio Value:</DataLabel>
                {<DataText>{formatMillions(portfolioValue, 1, '$')}</DataText>}
              </GridWrapper>
              <GridWrapper>
                <DataLabel>Effective Date:</DataLabel>
                <DataText>{effectiveDate ? getDayMonthYear(effectiveDate) : DATA_STATUS.NONE}</DataText>
              </GridWrapper>
            </FlexRow>
            <FlexRow>
              <GridWrapper>
                <DataLabel>Index:</DataLabel>
                <DataText>
                  <EllipsesContainer>{indexName}</EllipsesContainer>
                </DataText>
              </GridWrapper>

              <GridWrapper>
                <DataLabel>Strategy:</DataLabel>
                <DataText>{portfolioManagementType}</DataText>
              </GridWrapper>
              <GridWrapper></GridWrapper>
            </FlexRow>
          </DataContainer>
          <FlexColumn>
            <PercentileContainer>
              <DataLabel>Percentile Rank:</DataLabel>
            </PercentileContainer>
            <StrategyPercentileRank
              percentile={
                percentileRank && isFinite(percentileRank)
                  ? Math.round(percentileRank * 100) + percentileOrdinal
                  : DATA_STATUS.NONE
              }
              quintile={percentileRank ? Math.floor(percentileRank * 5) + 1 : 0}
              benchmark={
                marketIndexPercentileRank
                  ? Math.round(marketIndexPercentileRank * 100) + marketIndexOrdinal
                  : DATA_STATUS.NONE
              }
            />
          </FlexColumn>
        </FlexRow>
      </ReactPlaceholder>
    </CardWrapper>
  );
};
