import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {compose} from 'recompose';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

// Local Imports
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {Alert, ProgressIndicator, OnboardingPlanForm} from 'app/components';
import {updatePlanSponsorByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';

class OnboardingPlanPage extends Component {
  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          <h1 className="mb-4">{ROUTE.ONBOARDING_PLAN_INFO.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <Fragment>
              <p className="mb-3">
                Please complete as much information as possible. You can edit the information below and add more
                information after your account is setup by going to the "Settings" portion of your account. Users
                invited to the system will not be invited until you complete your account setup process.
              </p>
              <OnboardingPlanForm {...this.props} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;
      const updateData = {
        ...values,
        onboardingStep: onboardingSteps.PLAN.onboardingStepName
          ? onboardingSteps.PLAN.onboardingStepName
          : onboardingSteps.PLAN.key,
      };
      return dispatch(updatePlanSponsorByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_ESG_STRATEGY.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE});
        });
    },
    dispatch,
  };
};

export const OnboardingPlan = compose(withOnboardingUser, connect(null, mapDispatchToProps))(OnboardingPlanPage);
