import React, {Component} from 'react';
import {get, map} from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, Field, reduxForm, formValueSelector, getFormValues} from 'redux-form';

// Local Imports
import {Toggle, Button, FormFeedback} from 'app/components';
import {ADMIN_PERMISSIONS, PERMISSIONS} from 'app/constants';
import {errorRequiredFields} from 'app/utilities';

class EditAdminEmployeeForm extends Component {
  toggleShouldBeDisabled = (section, permission) => {
    const sectionPermissions = ADMIN_PERMISSIONS[section].permissions;

    let readDisablesWrite = false;

    if (sectionPermissions.hasOwnProperty('read') && this.props.formValues) {
      const readKey = sectionPermissions.read.value;

      readDisablesWrite = permission === 'write' && !this.props.formValues[readKey];
    }

    return !this.props.canWrite || readDisablesWrite;
  };

  handleToggleChanged = (section, permission, event) => {
    if (permission === 'read' && !event.currentTarget.checked) {
      const sectionPermissions = ADMIN_PERMISSIONS[section].permissions;

      if (sectionPermissions.hasOwnProperty('write')) {
        const writeKey = sectionPermissions.write.value;

        this.props.changeFieldValue(writeKey, false);
      }
    }
  };

  renderPermissions = () =>
    map(Object.keys(ADMIN_PERMISSIONS), (section, i) => (
      <div
        className={`d-flex justify-content-between ${i === 0 ? 'mt-6 pt-6' : 'mt-6'}`}
        style={{
          borderBottom: '1px solid #ccc',
          borderTop: i === 0 ? '1px solid #ccc' : '',
        }}
        key={section}
      >
        <div>
          <h4>{ADMIN_PERMISSIONS[section].label}</h4>
        </div>
        <div className="d-flex justify-content-end">
          {map(Object.keys(ADMIN_PERMISSIONS[section].permissions), permission => (
            <div className="ml-5" key={ADMIN_PERMISSIONS[section].permissions[permission].value}>
              <Field
                label={ADMIN_PERMISSIONS[section].permissions[permission].label}
                name={ADMIN_PERMISSIONS[section].permissions[permission].value}
                disabled={this.toggleShouldBeDisabled(section, permission)}
                onChange={event => this.handleToggleChanged(section, permission, event)}
                component={Toggle}
              />
            </div>
          ))}
        </div>
      </div>
    ));

  render() {
    console.log(this.props.formValues);
    console.log(this.props.changeFieldValue);

    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div>{this.renderPermissions()}</div>
        <div className="form-footer is-right">
          <Button type="submit" disabled={!this.props.canWrite}>
            Save
          </Button>
        </div>
      </form>
    );
  }
}

EditAdminEmployeeForm.propTypes = {
  handleSubmit: PropTypes.func,
  fieldValues: PropTypes.object,
  changeFieldValue: PropTypes.func,
};

const validate = values => {
  const errors = errorRequiredFields(values, []);
  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  const fieldValues = selector(state, PERMISSIONS);
  const permissions = get(state, 'auth.adminPermission');
  const values = getFormValues(ownProps.formName)(state);

  return {
    formValues: values,
    fieldValues,
    form: ownProps.formName,
    permissions,
    initialValues: ownProps.userData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFieldValue: (field, value) => {
    dispatch(change(ownProps.formName, field, value));
  },
  dispatch,
});

export const AdminEmployeeForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({validate, enableReinitialize: true})(EditAdminEmployeeForm));
