import {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {ROUTE} from '../../constants/routes';
import {TextInput} from '../molecules/TextInput';
import {Theme} from '../atoms/theme';
import {Search} from '../atoms/icons/Search';
import {useManagers} from '../../hooks/useManagers';
import {compareNumbersWithNan, formatMillions} from 'v2/utilities/helpers';
import {PercentileRank} from '../molecules/PercentileRank';
import {TableCell} from '../atoms/Containers';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {updateManagerSearch} from 'v2/redux/searchFilters';
import {Download} from '../atoms/icons/Download';
import {makeCSVDownload} from '../../utilities/helpers/makeCSVDownload';
import {COLUMN_LABELS} from 'v2/constants/columnLabels';
import {ReactTable} from './ReactTable';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {localeCompareString} from 'v2/utilities/helpers/localeCompareString';

const PageWrapper = styled.div`
  margin-top: 5.25rem;
  padding-bottom: 1.5625rem;
`;

const FlexRow = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  min-width: 56.25rem;
  margin: 0 0 2rem;
`;

const SearchWrapper = styled(FlexRow)`
  margin: 0 4rem;
  justify-content: space-between;
`;

const SearchButton = styled.button`
  background: ${props => props.color || props.theme.colors.grey_2};
  width: 44px;
  border: 0px;
  height: 44px;
  border-radius: 8px;
  margin-left: 9px;
  padding-top: 5px;
`;

const DownloadButton = styled.button`
  background: ${props => props.color || props.theme.colors.grey_2};
  border: 0px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0 15px;
`;

const VsIndex = ({vsIndexRating}: {vsIndexRating: number}) => {
  if (vsIndexRating > 0) {
    return <span style={{color: Theme.colors.light_green_2}}>+{vsIndexRating}</span>;
  } else if (vsIndexRating < 0) {
    return <span style={{color: Theme.colors.red_2}}>{vsIndexRating}</span>;
  } else if (vsIndexRating === 0) {
    return <span>{vsIndexRating}</span>;
  } else {
    return <span>-</span>;
  }
};

const columnLabels = COLUMN_LABELS.managers;

export const ManagersIndex = () => {
  const {data, mapDataForCSV, handleSearchChange, isLoading} = useManagers();

  const handleCSVDownload = () => {
    makeCSVDownload({data: mapDataForCSV(), subject: 'managers', headerLabels: Object.values(columnLabels)});
  };

  const dispatch = useDispatch();

  const searchValue = String(useSelector((state: RootStateOrAny) => state.searchFilters.managers.searchText));
  const setSearchValue = (value: string) => dispatch(updateManagerSearch(value));

  useEffect(() => {
    handleSearchChange(searchValue);
  }, [searchValue]);

  const companyPath = (id: string) => {
    return ROUTE.UT1.STRATEGIES.RISK.path.replace(':strategyId', id);
  };

  const handleSearch = () => {
    handleSearchChange(searchValue);
  };

  const searchOnEnter = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const memoizedColumns = useMemo(
    () => [
      {
        Header: columnLabels.firmName,
        Cell: ({value}: {value: string}) => <TableCell title={value}>{value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '10rem',
          width: '18rem',
        },
        sortType: localeCompareString,
        accessor: 'firmName',
      },
      {
        Header: columnLabels.product,
        Cell: (row: any) => <TableCell title={row.value}>{row.value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '10rem',
          width: '10rem',
        },
        sortType: localeCompareString,
        accessor: 'product',
      },
      {
        Header: columnLabels.index,
        Cell: (row: any) => <TableCell title={row.value}>{row.value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '10rem',
          width: '10rem',
        },
        sortType: localeCompareString,
        accessor: 'index',
      },
      {
        Header: columnLabels.strategy,
        Cell: (row: any) => <TableCell>{row.value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '9rem',
          width: '9rem',
          justifyContent: 'center',
        },
        accessor: 'strategy',
      },
      {
        Header: columnLabels.percentileRank,
        Cell: ({value}: {value: number}) => (
          <TableCell>
            <PercentileRank value={value} superscript={false} />
          </TableCell>
        ),
        style: {
          maxWidth: 'auto',
          minWidth: '9rem',
          width: '9rem',
          justifyContent: 'center',
        },
        sortType: compareNumbersWithNan,
        accessor: 'percentileRank',
      },
      {
        Header: columnLabels.vsIndexRating,
        Cell: ({value}: {value: number}) => (
          <TableCell>
            <VsIndex vsIndexRating={value} />
          </TableCell>
        ),
        style: {
          maxWidth: 'auto',
          minWidth: '8rem',
          width: '8rem',
          justifyContent: 'center',
        },
        sortType: compareNumbersWithNan,
        accessor: 'vsIndexRating',
        align: 'center',
      },
      {
        Header: columnLabels.portfolioValue,
        Cell: ({value}: {value: number}) => <TableCell>{formatMillions(value, 1, '$')}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '9rem',
          width: '9rem',
        },
        accessor: 'portfolioValue',
      },
    ],
    [],
  );

  const memoizedData = useMemo(() => data, [data]);

  return (
    <PageWrapper>
      <SearchWrapper>
        <FlexRow>
          <TextInput
            placeholder="Search by Managers, Products, Index"
            value={searchValue}
            onChange={setSearchValue}
            onKeyUp={searchOnEnter}
          />
          <SearchButton onClick={handleSearch}>
            <Search size={18} color={Theme.colors.dark} />
          </SearchButton>
        </FlexRow>
        <div>
          <DownloadButton onClick={handleCSVDownload} disabled={isLoading}>
            <Download color={isLoading ? Theme.colors.grey_4 : Theme.colors.dark} size={20} />
            <span>Download CSV</span>
          </DownloadButton>
        </div>
      </SearchWrapper>
      <TableWrapper>
        <ReactTable
          data={memoizedData}
          columns={memoizedColumns}
          path={companyPath}
          initialState={{sortBy: [{id: 'portfolioValue', desc: true}]}}
          withHover
          withSorting
          withCardContainer
        />
      </TableWrapper>
    </PageWrapper>
  );
};
