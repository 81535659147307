import {assignWith, isEmpty, filter} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Link} from 'react-router-dom';
import cn from 'classnames';

// Local Imports
import {Button, FormFeedback} from 'app/components';
import {FormGroup, AsyncTypeaheadFirmFormGroup, SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {
  AUTOCOMPLETE,
  CHARACTER_LIMIT,
  MANAGER_TYPES,
  PORTFOLIO_MANAGEMENT_TYPES,
  REGEX_PATTERN,
  ROUTE,
  UPLOAD_FREQUENCIES,
} from 'app/constants';
import {errorRequiredFields, normalizeEmail, validateFieldArray, withViewportSize} from 'app/utilities';

const BATCH_SIZE = 3;
const INVESTMENT_MANAGER_INITIAL_VALUES = {
  class: MANAGER_TYPES[0],
  uploadFrequency: UPLOAD_FREQUENCIES[0],
};

const validate = values => {
  const errors = errorRequiredFields(values, ['firm']);

  // Check to see if anything in the listing has data
  const investmentManagersHas = validateFieldArray(values.investmentManagersToInvite, (manager, ownErrors) => {
    if (manager.email || manager.strategy || manager.marketIndex) {
      ownErrors.email = 'Exists';
    }
  });

  // if no data in listing, show required on first row
  if (isEmpty(investmentManagersHas)) {
    errors.investmentManagersToInvite = [
      {
        firm: 'Required',
        email: 'Required',
        strategy: 'Required',
        marketIndex: 'Required',
        class: 'Required',
      },
    ];
  } else {
    // check for valid rows / data in the rows
    const investmentManagersToInviteErrors = validateFieldArray(
      values.investmentManagersToInvite,
      (manager, ownErrors) => {
        if (manager.email || manager.strategy || manager.marketIndexe) {
          if (!manager.email) ownErrors.email = 'Required';
          if (!manager.strategy) ownErrors.strategy = 'Required';
          if (!manager.marketIndex) ownErrors.marketIndex = 'Required';
        }

        if (!ownErrors.email && manager.email) {
          if (!REGEX_PATTERN.VALID_EMAIL.test(manager.email)) {
            ownErrors.email = 'Invalid email address';
          }
        }
      },
    );
    investmentManagersToInviteErrors && (errors.investmentManagersToInvite = investmentManagersToInviteErrors);
  }

  return errors;
};

const renderInvestmentManagersToInvite = ({fields: investmentManagersToInvite, marketIndexes, isLayoutSmall}) => {
  const standardMarketIndexes = !isEmpty(marketIndexes) ? filter(marketIndexes, {isHarmonyIndex: false}) : [];
  const formFieldClassName = 'min-h-0 mb-3';
  const formFieldClassNameLg = cn(formFieldClassName, isLayoutSmall ? 'col-3' : 'col-1');
  const formFieldClassNameSm = cn(formFieldClassName, isLayoutSmall ? 'col-2' : 'flex-fixed w-10r');
  const hrClassName = cn('my-6 w-100', isLayoutSmall ? 'col-6' : 'col-3');

  return (
    <Fragment>
      {investmentManagersToInvite.map((investmentManagerToInvite, index) => {
        const isFirst = index === 0;
        const doShowLabel = isFirst || isLayoutSmall;
        return (
          <div key={index} className="form-grid">
            {isLayoutSmall && !isFirst && <hr className={hrClassName} />}
            <Field
              label={doShowLabel ? 'Firm Name*' : null}
              name={`${investmentManagerToInvite}.firm`}
              component={AsyncTypeaheadFirmFormGroup}
              className={formFieldClassNameLg}
            />
            <Field
              label={doShowLabel ? 'Manager Email*' : null}
              name={`${investmentManagerToInvite}.email`}
              type="text"
              component={TextFormGroup}
              normalize={normalizeEmail}
              className={formFieldClassNameLg}
              autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              maxLength={CHARACTER_LIMIT.EMAIL}
            />
            <Field
              label={doShowLabel ? 'Investment Strategy*' : null}
              name={`${investmentManagerToInvite}.strategy`}
              type="text"
              component={TextFormGroup}
              className={formFieldClassNameLg}
              maxLength={CHARACTER_LIMIT.INVESTMENT_STRATEGY}
            />
            <Field
              label={doShowLabel ? 'Manager Type*' : null}
              name={`${investmentManagerToInvite}.class`}
              component={SelectFormGroup}
              options={MANAGER_TYPES}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Index*' : null}
              name={`${investmentManagerToInvite}.marketIndex`}
              component={props => (
                <FormGroup {...props} typeahead options={standardMarketIndexes}>
                  {(inputProps, wrapperProps) => (
                    <Typeahead
                      {...wrapperProps}
                      placeholder="Select a Reference Index"
                      inputProps={inputProps}
                      labelKey="name"
                    />
                  )}
                </FormGroup>
              )}
              className={formFieldClassNameLg}
            />
            <Field
              label={doShowLabel ? 'Upload Frequency*' : null}
              name={`${investmentManagerToInvite}.uploadFrequency`}
              component={SelectFormGroup}
              options={UPLOAD_FREQUENCIES}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Portfolio Type*' : null}
              name={`${investmentManagerToInvite}.portfolioManagementType`}
              component={SelectFormGroup}
              options={PORTFOLIO_MANAGEMENT_TYPES}
              className={formFieldClassNameSm}
            />
          </div>
        );
      })}

      <Button
        className="mt-6"
        onClick={() => {
          for (let i = 0; i < BATCH_SIZE; i += 1) {
            investmentManagersToInvite.push({...INVESTMENT_MANAGER_INITIAL_VALUES});
          }
        }}
      >
        Add Another Strategy
      </Button>
    </Fragment>
  );
};

class SettingsFirmManagerAddFormComponent extends Component {
  render() {
    const {viewportWidth} = this.props;

    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const isLayoutSmall = viewportWidth < 1280;

    return (
      <form method="POST" onSubmit={this.props.handleSubmit} style={{width: '1450px'}}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />

        <FieldArray
          name="investmentManagersToInvite"
          component={renderInvestmentManagersToInvite}
          marketIndexes={this.props.marketIndexes}
          isLayoutSmall={isLayoutSmall}
        />

        <div className="form-footer is-right mt-12">
          <Link to={ROUTE.SETTINGS_FIRMS_MANAGER.path()} className="btn btn-outline">
            Cancel
          </Link>
          <Button type="submit" solid>
            Save Firm & Send Invite
          </Button>
        </div>
      </form>
    );
  }
}

SettingsFirmManagerAddFormComponent.propTypes = {onSubmit: PropTypes.func.isRequired};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const defaultValues = {};
  const managers = [];
  managers.push({...INVESTMENT_MANAGER_INITIAL_VALUES});

  // Fill to next BATCH_SIZE with empty rows
  const numberOfRows = Math.max(Math.ceil(managers.length / BATCH_SIZE) * BATCH_SIZE, BATCH_SIZE);
  for (let i = managers.length; i < numberOfRows; i += 1) {
    managers.push({...INVESTMENT_MANAGER_INITIAL_VALUES});
  }

  return Object.assign({}, ownProps, {
    initialValues: assignWith(
      {},
      defaultValues,
      {investmentManagersToInvite: managers},
      (objValue, srcValue) => srcValue || objValue,
    ),
  });
};

export const SettingsFirmManagerAddForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'settings-firm-add',
    validate,
  }),
  withViewportSize,
)(SettingsFirmManagerAddFormComponent);
