import {Theme} from '../theme';

export const Users = ({color = Theme.colors.dark, size = 32, style = {}}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clip-path="url(#clip0_7666_3772)">
        <path
          d="M19.667 17.4991V15.8324C19.6664 15.0939 19.4206 14.3764 18.9681 13.7927C18.5156 13.209 17.8821 12.7921 17.167 12.6074"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6663 17.5V15.8333C14.6663 14.9493 14.3152 14.1014 13.69 13.4763C13.0649 12.8512 12.2171 12.5 11.333 12.5H4.66634C3.78229 12.5 2.93444 12.8512 2.30932 13.4763C1.6842 14.1014 1.33301 14.9493 1.33301 15.8333V17.5"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.833 2.60742C14.55 2.79101 15.1855 3.20801 15.6394 3.79268C16.0932 4.37735 16.3395 5.09645 16.3395 5.83659C16.3395 6.57673 16.0932 7.29582 15.6394 7.8805C15.1855 8.46517 14.55 8.88217 13.833 9.06576"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00033 9.16667C9.84127 9.16667 11.3337 7.67428 11.3337 5.83333C11.3337 3.99238 9.84127 2.5 8.00033 2.5C6.15938 2.5 4.66699 3.99238 4.66699 5.83333C4.66699 7.67428 6.15938 9.16667 8.00033 9.16667Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7666_3772">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
