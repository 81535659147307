// A wrapper component that provides a state and methods for managing multiple file uploads.
// Note: props.children must consist of exactly one function.
//
// Usage:
//
// <MultiUploader>
//   {({ files, isBusy, percentComplete, removeFile, uploaderProps }) => (
//     <Fragment>
//       <FileList files={files} onRemoveFileClick={removeFile} />
//       <ButtonUploader
//         isBusy={isBusy}
//         percentComplete={percentComplete}
//         uploaderProps={uploaderProps}
//       />
//     </Fragment>
//   )}
// </MultiUploader>
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {each, remove, isEmpty} from 'lodash';

import {CONFIG} from 'app/constants';
import faSpinnerThird from 'app/fontawesome-pro-light/faSpinnerThird';

const S3_UPLOADER_PROPS = {
  s3path: 'uploads/',
  server: CONFIG.API_URL,
  signingUrl: '/s3/sign',
  signingUrlMethod: 'GET',
  uploadRequestHeaders: {'x-amz-acl': 'private'},
};

class MultiUploader extends Component {
  constructor(props) {
    super(props);

    this.preprocess = this.preprocess.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onError = this.onError.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.removeAllFiles = this.removeAllFiles.bind(this);

    this.state = {
      files: props.value || [],
      percentComplete: 0,
      showUploader: !this.hasMaxFilesSpecified(props.value || []),
    };
  }

  /**
   * Checks the maxFiles value (or 1 if not specified) against the number of files in the files array
   * @param {Array} files - The file list
   */
  hasMaxFilesSpecified(files) {
    let {maxFiles} = this.props;
    if (!maxFiles || maxFiles < 0) {
      maxFiles = 1;
    }
    if (isEmpty(files)) {
      return maxFiles === 0;
    }

    return files.length >= maxFiles;
  }

  preprocess(file, next) {
    const {preprocess} = this.props.uploaderProps;

    this.setState({isBusy: true});

    preprocess && preprocess(file, next, this);
    next(file);
  }

  onProgress(percentComplete, status, fileInfo) {
    const {onProgress} = this.props.uploaderProps;

    this.setState({percentComplete});

    onProgress && onProgress(percentComplete, status, fileInfo, this);
  }

  onFinish(s3file, fileInfo) {
    const {
      onFileAdded,
      onFilesChanged,
      onChange,
      uploaderProps: {onFinish},
      isValidatingFileVisible,
    } = this.props;
    const {files} = this.state;

    const updatedFileInfo = {
      name: fileInfo.name,
      meta: fileInfo.meta,
      fileKey: s3file.fileKey,
    };

    if (isValidatingFileVisible && !updatedFileInfo.meta) {
      updatedFileInfo.meta = (
        <Fragment>
          Validating File <Icon icon={faSpinnerThird} spin className="ml-1" />
        </Fragment>
      );
    }

    // add fileKey to fileInfo object
    files.push(updatedFileInfo);

    if (this.uploader) {
      this.uploader.clear();

      this.setState({
        files,
        isBusy: false,
        percentComplete: 0,
        showUploader: !this.hasMaxFilesSpecified(files),
      });

      // TODO(PeterJ): Figure out how to handle meta - onFinish gets it from API
      // onChange - passed in if used by redux-form Field
      onChange && onChange(files);
      onFinish && onFinish(updatedFileInfo, s3file, this);
      onFileAdded && onFileAdded(updatedFileInfo, s3file);
      onFilesChanged && onFilesChanged(files, s3file);
    }
  }

  onError(status, fileInfo) {
    const {onError} = this.props.uploaderProps;

    // TODO: toast error
    this.setState({
      isBusy: false,
      percentComplete: 0,
    });
    this.uploader.clear();

    onError && onError(status, fileInfo, this);
  }

  removeFile(file) {
    const {files} = this.state;
    const {onFileRemoved, onFilesChanged, onChange} = this.props;

    remove(files, f => f === file); // mutate
    this.setState({files, showUploader: !this.hasMaxFilesSpecified(files)}); // trigger render

    // onChange - passed in if used by redux-form Field
    onChange && onChange(files);
    onFileRemoved && onFileRemoved(file, this);
    onFilesChanged && onFilesChanged(files);
  }

  removeAllFiles() {
    const {files} = this.state;
    const {onFileRemoved, onFilesChanged, onChange} = this.props;

    this.setState({files: [], showUploader: true}); // trigger render

    // onChange - passed in if used by redux-form Field
    onChange && onChange(files);
    onFileRemoved &&
      each(files, file => {
        onFileRemoved(file, this);
      });
    onFilesChanged && onFilesChanged(files);
  }

  render() {
    const {files, isBusy, percentComplete, showUploader} = this.state;
    const {onRef} = this.props;

    const uploaderProps = {
      ...S3_UPLOADER_PROPS,
      autoUpload: true,
      ...this.props.uploaderProps,
      preprocess: this.preprocess,
      onProgress: this.onProgress,
      onError: this.onError,
      onFinish: this.onFinish,
      ref: element => {
        this.uploader = element;
        onRef && onRef({...this});
      },
      disabled: isBusy,
    };

    return this.props.children({
      files,
      isBusy,
      percentComplete,
      showUploader,
      removeFile: this.removeFile,
      uploaderProps,
    });
  }
}

MultiUploader.propTypes = {
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onFileAdded: PropTypes.func,
  onFilesChanged: PropTypes.func,
  onFileRemoved: PropTypes.func,
  uploaderProps: PropTypes.object,
  maxFiles: PropTypes.number,
};

MultiUploader.defaultProps = {uploaderProps: {}};

export {MultiUploader};
