import ReactPlaceholder from 'react-placeholder/lib';
import {RectShape} from 'react-placeholder/lib/placeholders';
import styled from 'styled-components/macro';
import {EsgAssociationView as EsgAssociationViewProps} from 'v2/utilities/types/components/EsgAssociationView';
import {Container, FlexContainer} from '../atoms/Containers';
import {Theme} from '../atoms/theme';
import {Body2, Title5} from '../atoms/Typeface';

const Title = styled(Title5)`
  &&& {
    font-weight: 600 !important;
  }
  color: ${({theme}) => theme.colors.dark};
  margin: 0;
`;

const Association = styled(Body2)`
  display: block;
  width: auto;
  font-size: 0.75rem;
  overflow: hidden;
`;

const AssociationsList = styled.div`
  display: flex;
  gap: 2.5rem;
  padding-left: 5rem;
  max-height: 6.5rem;
`;

const Placeholder = () => (
  <FlexContainer justify="space-between" items="center" gap="2rem">
    <RectShape color={Theme.colors.grey_2} style={{width: 150, height: 20}} />
    <RectShape color={Theme.colors.grey_2} style={{width: 160, height: 60}} />
    <RectShape color={Theme.colors.grey_2} style={{width: 160, height: 60}} />
    <RectShape color={Theme.colors.grey_2} style={{width: 160, height: 60}} />
    <RectShape color={Theme.colors.grey_2} style={{width: 160, height: 60}} />
  </FlexContainer>
);

export const EsgAssociationsView = ({isLoading, associations}: EsgAssociationViewProps) => {
  return (
    <ReactPlaceholder ready={!isLoading} customPlaceholder={<Placeholder />}>
      <Container>
        <Title>ESG Associations</Title>
      </Container>
      <AssociationsList>
        {associations?.map(item => {
          // @ts-ignore
          if (typeof item === 'string' || item instanceof String) {
            return <Association>{item}</Association>;
          }
          return item;
        })}
      </AssociationsList>
    </ReactPlaceholder>
  );
};
