import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {SubmissionError} from 'redux-form';

// Local Imports
import {Alert, OnboardingUt3InviteForm, ProgressIndicator} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {updateInvitationByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';

class OnboardingUt3InvitePage extends Component {
  render() {
    // Ensure OnboardingAccountForm doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          <h1>{ROUTE.ONBOARDING_UT3_INVITE.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <Fragment>
              <p className="mb-3">
                Thank you, you may now invite your colleagues at your firm to assist in managing your Harmony account.
                Please fill in as many colleagues as you'd like by adding their email and role to the list below. You
                can edit the information below and add more information after you finish your account setup by going to
                the "Settings" portion of your account. Colleagues invited to the system will not be sent an invite
                until confirmed on the next page.
              </p>
              <OnboardingUt3InviteForm {...this.props} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;
      const updateData = {
        ...values,
        onboardingStep: onboardingSteps.INVITE.key,
      };

      return dispatch(updateInvitationByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_FINALIZE.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const OnboardingUt3Invite = compose(
  withOnboardingUser,
  connect(null, mapDispatchToProps),
)(OnboardingUt3InvitePage);
