import React from 'react';
import {Container} from 'reactstrap';
import styled from 'styled-components/macro';
import {FlexContainer} from '../atoms/Containers';
import {CheckCircle, CircleX} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {Body2} from '../atoms/Typeface';

const Label = styled(Body2).attrs({
  color: '#434343',
})`
  text-transform: uppercase;
`;

type CheckedBadgeProps = {
  isChecked?: boolean;
  hideLabel?: boolean;
  iconSize?: number;
  label?: string;
};

export const CheckedBadge = ({
  isChecked: isEngaged,
  iconSize = 20,
  hideLabel = false,
  label = 'Engaged',
}: CheckedBadgeProps) => {
  return (
    <FlexContainer gap="0.5625rem" items="center" justify="center">
      {!hideLabel && (
        <FlexContainer>
          <Label>{label}</Label>
        </FlexContainer>
      )}
      <FlexContainer>
        {isEngaged ? (
          <CheckCircle size={iconSize} color={Theme.colors.light_green_2} />
        ) : (
          <CircleX size={iconSize} color={Theme.colors.grey_5} />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
