import {Theme} from '../theme';

export const CodeOfConductPolicyIcon = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M3.05948 0.360352C3.05894 0.360352 3.05833 0.360352 3.0578 0.360352C2.86863 0.360352 2.6907 0.434093 2.55635 0.568107C2.42052 0.703602 2.3457 0.884016 2.3457 1.07608V11.8083C2.3457 12.2019 2.66713 12.5228 3.06231 12.5238C4.72826 12.5278 7.51938 12.875 9.44487 14.89V3.65844C9.44487 3.52503 9.4108 3.39971 9.34649 3.296C7.76613 0.750945 4.7292 0.364257 3.05948 0.360352Z" />
      <path d="M17.6557 11.8084V1.07608C17.6557 0.884016 17.5809 0.703602 17.4451 0.568107C17.3107 0.434093 17.1327 0.360352 16.9437 0.360352C16.9431 0.360352 16.9425 0.360352 16.942 0.360352C15.2723 0.364325 12.2354 0.751012 10.655 3.29606C10.5906 3.39977 10.5566 3.5251 10.5566 3.65851V14.89C12.4821 12.875 15.2733 12.5278 16.9392 12.5238C17.3343 12.5228 17.6557 12.2019 17.6557 11.8084Z" />
      <path d="M19.285 2.83533H18.7661V11.8083C18.7661 12.8131 17.9474 13.6325 16.941 13.635C15.5279 13.6383 13.198 13.9146 11.5479 15.4764C14.4017 14.7777 17.4103 15.2319 19.1248 15.6226C19.3389 15.6714 19.5601 15.621 19.7317 15.4843C19.9026 15.3479 20.0006 15.1441 20.0006 14.9253V3.55092C20.0007 3.15635 19.6796 2.83533 19.285 2.83533Z" />
      <path d="M1.23454 11.8083V2.83533H0.715594C0.321095 2.83533 0 3.15635 0 3.55092V14.9251C0 15.1439 0.0980524 15.3477 0.268971 15.4841C0.44036 15.6207 0.661382 15.6713 0.875805 15.6224C2.59037 15.2316 5.59895 14.7775 8.45271 15.4762C6.80266 13.9145 4.4727 13.6383 3.05963 13.6349C2.05331 13.6325 1.23454 12.8131 1.23454 11.8083Z" />
    </svg>
  );
};
