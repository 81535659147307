import {FORM_ERROR} from 'final-form';
import {EmployeeType} from 'v2/utilities/types/components/EmployeeType';

export async function handleUpdateForm({
  handleUpdate,
  invitationCode,
  onboardingStep,
  values,
}: {
  handleUpdate: Function;
  invitationCode: any;
  onboardingStep: any;
  values: any;
}) {
  try {
    const res: any = await handleUpdate({
      invitationCode,
      values: {
        ...values,
        onboardingStep,
        employees: (values.employees || []).filter((e: EmployeeType) => e.count && e.count > 0),
      },
    });

    if (res?.error) {
      return {[FORM_ERROR]: res?.error?.data?.error};
    } else {
      return res?.data ? res?.data : {[FORM_ERROR]: 'Something went wrong'};
    }
  } catch (error) {
    console.error(error);
  }
}
