export const ISSUE_UNITS = {
  MMTCO2E: 'MMtCO2e',
  MTCO2E: 'MtCO2e',
  MWH: 'MWh',
  GJ: 'GJ',
  TCM: 'TCM',
  MGAL: 'MGal',
  ':1': ':1',
  USD: 'USD',
  TCIR: 'TCIR',
  LTIR: 'LTIR',
  PERCENT: '%',
  MMT: 'MMt',
};

export const ISSUE_UNIT_DESCRIPTIONS = (unit: string): string | null => {
  switch (unit) {
    case ISSUE_UNITS.MMTCO2E:
    case 'MMTCO2e':
      return 'Million metric tons of carbon dioxide equivalent';
    case ISSUE_UNITS.MTCO2E:
      return 'Metric tons of carbon dioxide equivalent';
    case ISSUE_UNITS.MWH:
      return 'Megawatt hours';
    case ISSUE_UNITS.GJ:
      return 'Gigajoules';
    case ISSUE_UNITS.TCM:
      return 'Thousand cubic meters';
    case ISSUE_UNITS.MGAL:
      return 'Mega gallons';
    case ISSUE_UNITS.TCIR:
      return 'Total case incident rate';
    case ISSUE_UNITS.LTIR:
      return 'Lost time incident rate';
    case ISSUE_UNITS.USD:
    case ISSUE_UNITS[':1']:
    case ISSUE_UNITS.PERCENT:
    case ISSUE_UNITS.MMT:
    default:
      return null;
  }
};

export const CONFIDENCE_LEVELS = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

export const SOURCE_TYPES = {
  COMPANY_REPORT: 'Company Report',
  REGULATED_FORM: 'Regulated Form',
  THIRD_PARTY: 'Third Party',
  OTHER: 'Other',
};

export const CARBON_NEUTRAL_PLEDGE_STATUS = {
  NOT_COMMITTED: 'Not Committed',
  ENGAGED: 'Engaged',
  TARGET_SET: 'Target Set',
  NET_ZERO: 'Net-Zero',
};

function toOptionList(values: any) {
  const arr = Object.values(values);
  return arr.map(value => ({
    value,
    label: value,
  }));
}

export const ISSUE_UNIT_OPTION_LIST = toOptionList(ISSUE_UNITS);

export const CONFIDENCE_LEVELS_OPTION_LIST = toOptionList(CONFIDENCE_LEVELS);

export const SOURCE_TYPES_OPTION_LIST = toOptionList(SOURCE_TYPES);

export const CARBON_NEUTRAL_PLEDGE_STATUS_LIST = toOptionList(CARBON_NEUTRAL_PLEDGE_STATUS);
