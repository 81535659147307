import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_PLAN_SELECTION_REQUEST: 'STRATEGY_DASHBOARD_GET_PLAN_SELECTION_REQUEST',
  GET_PLAN_SELECTION_RECEIVE: 'STRATEGY_DASHBOARD_GET_PLAN_SELECTION_RECEIVE',
  GET_STRATEGY_REQUEST: 'STRATEGY_DASHBOARD_GET_STRATEGY_REQUEST',
  GET_STRATEGY_RECEIVE: 'STRATEGY_DASHBOARD_GET_STRATEGY_RECEIVE',
  GET_DISTRIBUTIONS_REQUEST: 'STRATEGY_DASHBOARD_GET_DISTRIBUTIONS_REQUEST',
  GET_DISTRIBUTIONS_RECEIVE: 'STRATEGY_DASHBOARD_GET_DISTRIBUTIONS_RECEIVE',
  GET_SECTORS_BY_DISTRIBUTION_REQUEST: 'STRATEGY_DASHBOARD_GET_SECTORS_BY_DISTRIBUTION_REQUEST',
  GET_SECTORS_BY_DISTRIBUTION_RECEIVE: 'STRATEGY_DASHBOARD_GET_SECTORS_BY_DISTRIBUTION_RECEIVE',
  GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST:
    'STRATEGY_DASHBOARD_GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST',
  GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE:
    'STRATEGY_DASHBOARD_GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE',
  GET_ISSUES_REQUEST: 'STRATEGY_DASHBOARD_GET_ISSUES_REQUEST',
  GET_ISSUES_RECEIVE: 'STRATEGY_DASHBOARD_GET_ISSUES_RECEIVE',
  GET_ISSUE_MATRIX_REQUEST: 'STRATEGY_DASHBOARD_GET_ISSUE_MATRIX_REQUEST',
  GET_ISSUE_MATRIX_RECEIVE: 'STRATEGY_DASHBOARD_GET_ISSUE_MATRIX_RECEIVE',
  GET_HOLDING_METRICS_REQUEST: 'GET_HOLDING_METRICS_REQUEST',
  GET_HOLDING_METRICS_RECEIVE: 'GET_HOLDING_METRICS_RECEIVE',
  GET_STRATEGY_UNIVERSE_REQUEST: 'GET_STRATEGY_UNIVERSE_REQUEST',
  GET_STRATEGY_UNIVERSE_RECEIVE: 'GET_STRATEGY_UNIVERSE_RECEIVE',
};

const getPlanSelectionRequest = createAction(ACTION.GET_PLAN_SELECTION_REQUEST);
const getPlanSelectionReceive = createAction(ACTION.GET_PLAN_SELECTION_RECEIVE);
const getStrategyRequest = createAction(ACTION.GET_STRATEGY_REQUEST);
const getStrategyReceive = createAction(ACTION.GET_STRATEGY_RECEIVE);
const getDistributionsRequest = createAction(ACTION.GET_DISTRIBUTIONS_REQUEST);
const getDistributionsReceive = createAction(ACTION.GET_DISTRIBUTIONS_RECEIVE);
const getSectorsByDistributionRequest = createAction(ACTION.GET_SECTORS_BY_DISTRIBUTION_REQUEST);
const getSectorsByDistributionReceive = createAction(ACTION.GET_SECTORS_BY_DISTRIBUTION_RECEIVE);
const getCompaniesByDistributionAndSectorRequest = createAction(
  ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_REQUEST,
);
const getCompaniesByDistributionAndSectorReceive = createAction(
  ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE,
);
const getIssuesRequest = createAction(ACTION.GET_ISSUES_REQUEST);
const getIssuesReceive = createAction(ACTION.GET_ISSUES_RECEIVE);
const getIssueMatrixRequest = createAction(ACTION.GET_ISSUE_MATRIX_REQUEST);
const getIssueMatrixReceive = createAction(ACTION.GET_ISSUE_MATRIX_RECEIVE);
const getHoldingMetricsReguest = createAction(ACTION.GET_HOLDING_METRICS_REQUEST);
const getHoldingMetricsReceive = createAction(ACTION.GET_HOLDING_METRICS_RECEIVE);
const getStrategyUniverseRequest = createAction(ACTION.GET_STRATEGY_UNIVERSE_REQUEST);
const getStrategyUniverseReceive = createAction(ACTION.GET_STRATEGY_UNIVERSE_RECEIVE);

const api = {
  getPlanSelection: strategyId => fetchAuthJSON(`strategy/planSelection/${strategyId}`, {method: 'get'}),
  getStrategy: strategyId => fetchAuthJSON(`strategy/${strategyId}/firm`, {method: 'get'}),
  getHoldingsMetrics: strategyId => fetchAuthJSON(`strategy/${strategyId}/reportMetrics`, {method: 'get'}),
  getStrategyUniverse: strategyId => fetchAuthJSON(`strategy/${strategyId}/universePerformance`, {method: 'get'}),
  getDistributions: strategyId => fetchAuthJSON(`strategy/${strategyId}/distribution`, {method: 'get'}),
  getSectorsByDistribution: (strategyId, distribution) =>
    fetchAuthJSON(`strategy/${strategyId}/distribution/${distribution}/sector`, {method: 'get'}).then(results => ({
      results,
      parameters: {distribution},
    })),
  getCompaniesByDistributionAndSector: (strategyId, distribution, sectorId) =>
    fetchAuthJSON(`strategy/${strategyId}/distribution/${distribution}/sector/${sectorId}`, {method: 'get'}).then(
      results => ({results, parameters: {distribution, sectorId}}),
    ),
  getIssues: strategyId => fetchAuthJSON(`strategy/${strategyId}/issue`, {method: 'get'}),
  getIssueMatrix: strategyId => fetchAuthJSON(`strategy/${strategyId}/issueMatrix`, {method: 'get'}),
};

export function getPlanSelection(strategyId) {
  return dispatch => {
    dispatch(getPlanSelectionRequest(strategyId));
    return dispatch(getPlanSelectionReceive(api.getPlanSelection(strategyId)));
  };
}

export function getStrategy(strategyId) {
  return dispatch => {
    dispatch(getStrategyRequest(strategyId));
    return dispatch(getStrategyReceive(api.getStrategy(strategyId)));
  };
}

export function getDistributions(strategyId) {
  return dispatch => {
    dispatch(getDistributionsRequest(strategyId));
    return dispatch(getDistributionsReceive(api.getDistributions(strategyId)));
  };
}

export function getSectorsByDistribution(strategyId, distribution) {
  return dispatch => {
    dispatch(getSectorsByDistributionRequest({strategyId, distribution}));
    return dispatch(getSectorsByDistributionReceive(api.getSectorsByDistribution(strategyId, distribution)));
  };
}

export function getCompaniesByDistributionAndSector(strategyId, distribution, sectorId) {
  const apiCall = api.getCompaniesByDistributionAndSector;
  return dispatch => {
    dispatch(getCompaniesByDistributionAndSectorRequest({strategyId, distribution, sectorId}));
    return dispatch(getCompaniesByDistributionAndSectorReceive(apiCall(strategyId, distribution, sectorId)));
  };
}

export function getIssues(strategyId) {
  return dispatch => {
    dispatch(getIssuesRequest(strategyId));
    return dispatch(getIssuesReceive(api.getIssues(strategyId)));
  };
}

export function getIssueMatrix(strategyId) {
  return dispatch => {
    dispatch(getIssueMatrixRequest(strategyId));
    return dispatch(getIssueMatrixReceive(api.getIssueMatrix(strategyId)));
  };
}

export function getHoldingMetricsData(strategyId) {
  return dispatch => {
    dispatch(getHoldingMetricsReguest(strategyId));
    return dispatch(getHoldingMetricsReceive(api.getHoldingsMetrics(strategyId)));
  };
}
export function getStrategyUniversePerformance(strategyId) {
  return dispatch => {
    dispatch(getStrategyUniverseRequest(strategyId));
    return dispatch(getStrategyUniverseReceive(api.getStrategyUniverse(strategyId)));
  };
}

// REDUCER
export const strategyDashboard = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  switch (type) {
    case ACTION.GET_STRATEGY_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_STRATEGY_UNIVERSE_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_DISTRIBUTIONS_REQUEST:
      return assignMergedState({isFetchingDistributions: true});
    case ACTION.GET_ISSUES_REQUEST:
      return assignMergedState({isFetchingIssues: true});
    case ACTION.GET_HOLDING_METRICS_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_PLAN_SELECTION_RECEIVE:
      return assignMergedState(hasError ? {error: payload} : {planSelection: payload});
    case ACTION.GET_STRATEGY_UNIVERSE_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {benchmark: payload}));
    case ACTION.GET_STRATEGY_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {strategy: payload}));
    case ACTION.GET_DISTRIBUTIONS_RECEIVE: {
      return assignMergedState(
        Object.assign({isFetchingDistributions: false}, hasError ? {error: payload} : {distributions: payload}),
      );
    }
    case ACTION.GET_SECTORS_BY_DISTRIBUTION_RECEIVE: {
      const sectors = {};
      sectors[payload.parameters.distribution] = payload.results;
      return assignMergedState(
        hasError
          ? {error: payload}
          : {
              currentDistributionSectorsTimeStamp: new Date(),
              currentDistributionSectors: payload.results,
              sectors,
            },
      );
    }
    case ACTION.GET_COMPANIES_BY_DISTRIBUTION_AND_SECTOR_RECEIVE: {
      const companies = {};
      companies[payload.parameters.distribution] = {[payload.parameters.sectorId]: payload.results};
      return assignMergedState(
        hasError
          ? {error: payload}
          : {
              currentSectorCompaniesTimeStamp: new Date(),
              currentSectorCompanies: payload.results,
              companies,
            },
      );
    }
    case ACTION.GET_ISSUES_RECEIVE:
      return assignMergedState(
        Object.assign({isFetchingIssues: false}, hasError ? {error: payload} : {issues: payload}),
      );
    case ACTION.GET_ISSUE_MATRIX_RECEIVE:
      return assignMergedState(
        Object.assign({isFetchingIssues: false}, hasError ? {error: payload} : {issues: payload}),
      );
    case ACTION.GET_HOLDING_METRICS_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {holdingMetrics: payload}),
      );
    default:
      return state;
  }
};
