import {FC, useState} from 'react';
import {Field} from 'react-final-form';
import styled from 'styled-components';

//@ts-ignore
import isEmpty from 'redux-actions/lib/utils/isEmpty';
import {ParagraphS, SubtitleM} from 'v2/components/atoms/Typeface';
import {TextFormGroup} from 'v2/components/molecules/TextInput';
import {ManagerTableTabBar} from './ManagerTableTabBar';
import {Theme} from 'v2/components/atoms/theme';
import {ONBOARDING_CATEGORIES, ONBOARDING_ETHNICITIES, ONBOARDING_GENDERS, YEARS} from 'v2/constants/onboarding';
import {EmployeeType} from 'v2/utilities/types/components/EmployeeType';
import {formatNumberCommas} from 'app/utilities';

const TableItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-items: center;
`;

const TableGridItemHeader3 = styled(SubtitleM)`
justify-self: center;
text-align: center;
ma
x-width: 11rem;
`;
const TableGridItemHeader = styled(ParagraphS)`
  justify-self: start;
  padding: 0;
  min-width: 11rem;
  color: ${Theme.colors.harmony};
`;

const TableGridItemHeader2 = styled(ParagraphS)`
  color: ${Theme.colors.harmony};
  justify-self: center;
  text-align: center;
  justify-items: stretch;
`;

const TableRowOdd = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  justify-items: center;
  padding: 1.125rem;
  align-items: center;
`;

const RowHeader = styled(SubtitleM)`
  justify-self: start;
  min-width: 11rem;
`;

const TableRowEven = styled(TableRowOdd)`
  background-color: ${Theme.colors.blue_1}26;
`;
const InputContainer = styled.div`
  width: 6rem;
  flex: 1;
`;

const HeaderGroup = () => (
  <TableItemGrid>
    <TableGridItemHeader2>
      <InputContainer> Female</InputContainer>{' '}
    </TableGridItemHeader2>
    <TableGridItemHeader2>
      <InputContainer> Male</InputContainer>
    </TableGridItemHeader2>
  </TableItemGrid>
);

const normalizeNumber = ({value, name, values}: {value: any; name: string; values: any}) => {
  if (isEmpty(value)) {
    return 0;
  }

  const newValue = parseInt(value.replace(/,/g, ''), 10);

  if (isNaN(newValue) || newValue < 0) {
    return values[name];
  } else if (newValue >= 100000) {
    return values[name];
  } else return newValue;
};

const TableRowComponent: FC<{row: number}> = ({row, children}) => {
  return row % 2 !== 0 ? <TableRowOdd>{children}</TableRowOdd> : <TableRowEven>{children}</TableRowEven>;
};

/** getTotals
 * Returns an object with the totals from the employee list for each year, category and gender,
 * e.g.,
 * {
 *    [year]: {
 *       [category]: {
 *          [gender]: 12,
 *       },
 *    },
 * }
 */
const getTotals = (employees: EmployeeType[] = []) =>
  employees.reduce((acc: Record<string, Record<string, Record<string, number>>>, employee) => {
    const {year, category, gender, count} = employee;
    const previousYearCount = acc[year]?.[category]?.[gender] || 0;
    acc[year] = {
      ...acc[year], // keep the previous year values
      [category]: {
        ...acc[year]?.[category], // keep the previous category values
        [gender]: previousYearCount + (Number(count) || 0), // add the new value
      },
    };

    return acc;
  }, {});

export const EmployeesTableSection: FC<{employees?: EmployeeType[]; setFormAttribute: any; values: any}> = ({
  employees = [],
  setFormAttribute,
  values,
}) => {
  const [selectedYear, setSelectedYear] = useState(YEARS[YEARS.length - 1]);
  const yearIndex = YEARS.indexOf(selectedYear);
  const ethnicitiesKeys = Object.keys(ONBOARDING_ETHNICITIES);
  const categoriesKeys = Object.keys(ONBOARDING_CATEGORIES);
  const genderKeys = Object.keys(ONBOARDING_GENDERS);
  const tableLength = ethnicitiesKeys.length * categoriesKeys.length * genderKeys.length;

  const totals = getTotals(employees);

  const handleCopyPreviousYear = () => {
    const previousYearStartIndex = (yearIndex - 1) * tableLength;

    for (let i = previousYearStartIndex; i < previousYearStartIndex + tableLength; i++) {
      setFormAttribute(`employees[${i + tableLength}][count]`, employees?.[i]?.count);
    }
  };

  return (
    <>
      <ManagerTableTabBar
        onSelectYear={setSelectedYear}
        years={YEARS}
        onCopyPreviousYear={handleCopyPreviousYear}
        selectedIndex={yearIndex}
      />

      <TableRowOdd>
        <TableGridItemHeader> </TableGridItemHeader>
        <TableGridItemHeader3>Board of Directors and CEO, CFO & COO</TableGridItemHeader3>
        <TableGridItemHeader3>Investment Professionals</TableGridItemHeader3>
        <TableGridItemHeader3>Investment Support</TableGridItemHeader3>
      </TableRowOdd>
      <TableRowEven>
        <TableGridItemHeader>Race / Ethnicity</TableGridItemHeader>
        <HeaderGroup />
        <HeaderGroup />
        <HeaderGroup />
      </TableRowEven>

      {ethnicitiesKeys.map((ethnicity, ethnicityIndex) => {
        return (
          <TableRowComponent row={ethnicityIndex + 1} key={ethnicityIndex}>
            <RowHeader>{ONBOARDING_ETHNICITIES[ethnicity as keyof typeof ONBOARDING_ETHNICITIES]}</RowHeader>

            {categoriesKeys.map((_category, categoryIndex) => {
              return (
                <TableItemGrid key={categoryIndex}>
                  {genderKeys.map((_gender, genderIndex) => {
                    const currentIndex =
                      yearIndex * tableLength +
                      ethnicityIndex * categoriesKeys.length * genderKeys.length +
                      categoryIndex * genderKeys.length +
                      genderIndex;
                    return (
                      <InputContainer key={genderIndex}>
                        <Field
                          name={`employees[${currentIndex}][count]`}
                          type="text"
                          hideError
                          component={TextFormGroup}
                          format={value => formatNumberCommas(value || 0)}
                          parse={(value, name) => normalizeNumber({value, name, values})}
                        />
                      </InputContainer>
                    );
                  })}
                </TableItemGrid>
              );
            })}
          </TableRowComponent>
        );
      })}
      <TableRowComponent row={ethnicitiesKeys.length + 1}>
        <RowHeader>Total</RowHeader>
        {categoriesKeys.map((category, categoryIndex) => (
          <TableItemGrid key={categoryIndex}>
            {genderKeys.map((gender, genderIndex) => (
              <InputContainer
                key={`cat${categoryIndex}gen${genderIndex}`}
                style={{textAlign: 'center', font: '600 18px/20px Inter'}}
              >
                {formatNumberCommas(totals[selectedYear]?.[category]?.[gender])}
              </InputContainer>
            ))}
          </TableItemGrid>
        ))}
      </TableRowComponent>
    </>
  );
};
