import {intersection, isEmpty} from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {branch, renderComponent} from 'recompose';
import {Redirect} from 'react-router-dom';

import {ROLE, ROUTE} from 'app/constants';

const mapStateToProps = (state, ownProps) => {
  const {pathname} = ownProps.location;
  const authUser = state?.auth?.impersonatedUser ?? state?.auth;

  let redirectPath;

  if (
    pathname.indexOf('onboarding') < 0 &&
    authUser &&
    !isEmpty(intersection(authUser.roles, [ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3])) &&
    !authUser.isOnboardingComplete &&
    authUser.invitationCode
  ) {
    redirectPath = ROUTE.ONBOARDING_LANDING.path(authUser.invitationCode);
  }

  return {redirectPath};
};

export const withUserOnboardingCheck = compose(
  // First check for the user in state
  connect(mapStateToProps),
  branch(
    ({redirectPath}) => !!redirectPath,
    renderComponent(({redirectPath}) => {
      return <Redirect to={redirectPath} />;
    }),
  ),
);
