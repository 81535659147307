import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {format} from 'date-fns';
import {useGetCompanyIssuesQuery, useGetCompanyLatestEngagementLetterQuery} from 'v2/redux/typeormEndpoints';
import {ISSUES_BY_ID} from 'v2/constants/issues';
import {CONFIG} from 'app/constants';
import {keyBy} from 'lodash';

export const useCompanyEngagements = () => {
  const {companyId} = useParams<{companyId: string}>();

  const {
    data: issuesData,
    isLoading: issuesLoading,
    isError: issuesError,
    isSuccess: issuesSuccess,
  } = useGetCompanyIssuesQuery({companyId});

  const {
    data: engagementLetterData,
    isLoading: engagementLetterLoading,
    isError: engagementLetterError,
    isSuccess: engagementLetterSuccess,
  } = useGetCompanyLatestEngagementLetterQuery({companyId});

  const issueMap: any = useMemo(() => keyBy(issuesData, 'issueId'), [issuesData]);
  const issues: any[] = useMemo(() => {
    if (!engagementLetterData?.issues || !issueMap) return [];

    const filteredEngagementData = engagementLetterData?.issues?.filter(
      (issue: any) => issueMap[issue.issueId]?.pctRank > 0.6,
    );
    return (
      filteredEngagementData.map((issue: any) => ({
        ...issue,
        issue: issueMap[issue.issueId]?.issue,
        pctRank: Math.round(issueMap[issue.issueId].pctRank * 100),
        raw_value: issueMap[issue.issueId].raw_value,
        name: ISSUES_BY_ID[issue.issueId]?.name || '',
      })) || []
    );
  }, [engagementLetterData, issueMap]);

  const engagementLetter: any = useMemo(() => {
    return engagementLetterData?.sentAt
      ? {
          ...engagementLetterData,
          fileUrl: `${CONFIG.UPLOADS_URL}/${engagementLetterData?.filePath}`,
          formattedDate: format(new Date(engagementLetterData.sentAt), 'MMM yyyy'),
        }
      : {
          fileUrl: '',
          formattedDate: 'N/A',
        };
  }, [engagementLetterData]);

  return {
    issuesData: issues,
    engagementLetterData: engagementLetter,
    isLoading: issuesLoading || engagementLetterLoading,
    isError: issuesError || engagementLetterError,
    isSuccess: issuesSuccess && engagementLetterSuccess,
  };
};
