import {useMemo} from 'react';
import EsgAssociationPriImage from 'app/assets/images/ESG-association_PRI.png';
import EsgAssociationGriImage from 'v2/assets/images/GRI_Association.svg';
import {useGetFirmQuery} from 'v2/redux/typeormEndpoints';
import {pick} from 'lodash';

export const useFirmById = ({firmId}: {firmId?: string | number}) => {
  const {data, isError, isSuccess, isLoading} = useGetFirmQuery({firmId}, {skip: !firmId});

  const associations = [];

  const firm = useMemo(() => {
    return pick(data, [
      'hasPriAssociation',
      'hasGriAssociation',
      'hasPolicy',
      'hasComittee',
      'isEngaged',
      'esgApproach',
      'hasScreening',
      'hasVetting',
      'hasVoting',
      'hasScaling',
      'isSelling',
      'isMonitoring',
      'esgMeasurement',
      'esgAssociations',
      'workforceDiversity',
      'hasEsgPolicy',
      'hasESGCommitteeOfficer',
      'hasScreeningConsideration',
      'hasVotingConsideration',
      'hasScalingConsideration',
      'hasSellingConsideration',
      'hasMonitoringConsideration',
      'diversityObjectives',
    ]);
  }, [data]);

  if (firm?.hasPriAssociation) {
    associations.push(
      <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <img src={EsgAssociationPriImage} width="140px" height="26px" alt="pri" />
      </div>,
    );
  }

  if (firm?.hasGriAssociation) {
    associations.push(
      <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <img src={EsgAssociationGriImage} alt="gri" />
      </div>,
    );
  }

  // TODO: Add other associations and additional logo's

  const formattedData: {
    hasPolicy?: boolean;
    hasComittee?: boolean;
    isEngaged?: boolean;
    esgApproach?: string;
    hasScreening?: boolean;
    hasVetting?: boolean;
    hasVoting?: boolean;
    hasScaling?: boolean;
    isSelling?: boolean;
    isMonitoring?: boolean;
    esgMeasurement?: string;
    esgAssociations?: any;
    workforceDiversity?: any;
  } = {
    hasPolicy: firm?.hasEsgPolicy ?? false,
    hasComittee: firm?.hasESGCommitteeOfficer ?? false,
    isEngaged: firm?.isEngaged ?? false,
    hasVetting: firm?.hasScreeningConsideration ?? false,
    hasVoting: firm?.hasVotingConsideration ?? false,
    hasScaling: firm?.hasScalingConsideration ?? false,
    isSelling: firm?.hasSellingConsideration ?? false,
    hasScreening: firm?.hasScreeningConsideration ?? false,
    isMonitoring: firm?.hasMonitoringConsideration ?? false,
    esgApproach:
      firm?.esgApproach ??
      'Manager has not specified their ESG Approach. If you would want to learn about their approach please reach out to your key contact.',
    esgMeasurement:
      firm?.esgMeasurement ??
      'Manager has not specified how they measure ESG. If you would want to learn about their approach please reach out to your key contact.',
    esgAssociations: associations.length > 0 ? associations : ['Not currently affiliated with ESG Associations'],
    workforceDiversity:
      firm?.diversityObjectives ??
      'Manager has not specified their Diversity Objectives. If you would want to learn about their approach please reach out to your key contact.',
  };
  return {
    data: formattedData,
    isError,
    isSuccess,
    isLoading,
  };
};
