import {usePrefetch} from 'v2/redux/harmonyApi';
import {usePrefetch as useTypeOrmPrefetch} from 'v2/redux/typeormEndpoints';
import {getSessionJwt} from 'app/utilities';

export const usePrefetchPlanSponsorDashboard = () => {
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;
  usePrefetch('getHoldings')({planSponsorId});
  //@ts-ignore
  usePrefetch('getAllCompanies')();
  usePrefetch('getFirms')({planSponsorId});
  usePrefetch('getManagerEmployees')({planSponsorId});
  usePrefetch('getStrategies')({planSponsorId});
  usePrefetch('getAggregatePercentileHoldings')({planSponsorId, issueId: 1});
  usePrefetch('getPlanSponsorMarketIndexSecurities')({planSponsorId});
  useTypeOrmPrefetch('getPlanSponsorStrategies')({planSponsorId});
  useTypeOrmPrefetch('getCompanies')({});
  useTypeOrmPrefetch('getPlanSponsorEngagements')({planSponsorId});
  useTypeOrmPrefetch('getPlanSponsorHoldings')({planSponsorId});
  useTypeOrmPrefetch('getPlanSponsorManagerEmployees')({planSponsorId});
  useTypeOrmPrefetch('getPlanSponsorFirms')({planSponsorId});
};
