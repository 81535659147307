import {lifecycle} from 'recompose';

export const withBodyCssClass = cssClass => {
  if (!cssClass) return null;

  const cssClasses = cssClass.split(' ');

  return lifecycle({
    componentDidMount() {
      window.document.body.classList.add(cssClasses);
    },
    componentWillUnmount() {
      window.document.body.classList.remove(cssClasses);
    },
  });
};
