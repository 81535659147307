import {isNumber} from 'lodash';
import React from 'react';

import {COLOR} from 'app/constants/color';
import {RATINGS} from 'app/constants/ratings';

/**
 * Gets a formatted rating for display using the correct medal
 * @param {String} rating - The rating to format for display
 * @returns {Object} A react div element displaying the rating
 */
export const ratingFormatter = ratingValue => {
  const rating = isNumber(ratingValue) ? RATINGS[ratingValue] || 'UR' : ratingValue;
  const ratingColors = {A: COLOR.PLATINUM, B: COLOR.GOLD, C: COLOR.SILVER, D: COLOR.BRONZE, E: COLOR.SUB_STANDARD};
  const style = {
    width: 33,
    height: 33,
    lineHeight: '33px',
    borderRadius: '50%',
    border: '4px solid',
    borderColor: ratingColors[rating],
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1rem',
    color: COLOR.BLACK,
    display: 'flex',
    paddingRight: '1px',
    margin: 'auto',
  };
  return <div style={rating !== 'UR' ? style : {}}>{rating}</div>;
};
