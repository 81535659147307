import {createAction} from 'redux-actions';
import {endsWith, startsWith} from 'lodash';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

let fetchingCount = 0;

// ACTION
export const ACTION = {
  GET_ANNUAL_ISSUE_DATA_REQUEST: 'CI_GET_ANNUAL_ISSUE_DATA_REQUEST',
  GET_ANNUAL_ISSUE_DATA_RECEIVE: 'CI_GET_ANNUAL_ISSUE_DATA_RECEIVE',
  GET_ANNUAL_PENDING_ISSUE_DATA_REQUEST: 'CI_GET_ANNUAL_PENDING_ISSUE_DATA_REQUEST',
  GET_ANNUAL_PENDING_ISSUE_DATA_RECEIVE: 'CI_GET_ANNUAL_PENDING_ISSUE_DATA_RECEIVE',
  RESTATE_REQUEST: 'CI_RESTATE_REQUEST',
  RESTATE_RECEIVE: 'CI_RESTATE_RECEIVE',
};

const getAnnualIssueDataRequest = createAction(ACTION.GET_ANNUAL_ISSUE_DATA_REQUEST);
const getAnnualIssueDataReceive = createAction(ACTION.GET_ANNUAL_ISSUE_DATA_RECEIVE);
const getAnnualPendingIssueDataRequest = createAction(ACTION.GET_ANNUAL_PENDING_ISSUE_DATA_REQUEST);
const getAnnualPendingIssueDataReceive = createAction(ACTION.GET_ANNUAL_PENDING_ISSUE_DATA_RECEIVE);
const restateRequest = createAction(ACTION.RESTATE_REQUEST);
const restateReceive = createAction(ACTION.RESTATE_RECEIVE);

const api = {
  restate: (companyId, data) =>
    fetchAuthJSON(`company/${companyId}/restate`, {
      method: 'post',
      body: JSON.stringify(data),
    }),
  getAnnualIssueData: companyId => fetchAuthJSON(`company/${companyId}/annualIssues`, {method: 'get'}),
  getAnnualPendingIssueData: companyId => fetchAuthJSON(`company/${companyId}/annualPendingIssues`, {method: 'get'}),
};

export function restate(companyId, data) {
  return dispatch => {
    dispatch(restateRequest({companyId, data}));
    return dispatch(restateReceive(api.restate(companyId, data)));
  };
}

export function getAnnualIssueData(companyId) {
  return dispatch => {
    dispatch(getAnnualIssueDataRequest({companyId}));
    return dispatch(getAnnualIssueDataReceive(api.getAnnualIssueData(companyId)));
  };
}

export function getAnnualPendingIssueData(companyId) {
  return dispatch => {
    dispatch(getAnnualPendingIssueDataRequest({companyId}));
    return dispatch(getAnnualPendingIssueDataReceive(api.getAnnualPendingIssueData(companyId)));
  };
}

// REDUCER
export const companyAnnualIssues = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  if (startsWith(type, 'CI_') && endsWith(type, '_REQUEST')) {
    fetchingCount += 1;
  }
  if (startsWith(type, 'CI_') && endsWith(type, '_RECEIVE')) {
    fetchingCount -= 1;
  }
  const isFetching = fetchingCount > 0;

  switch (type) {
    case ACTION.GET_ANNUAL_ISSUE_DATA_REQUEST:
    case ACTION.GET_ANNUAL_PENDING_ISSUE_DATA_REQUEST:
      return assignMergedState({isFetching});
    case ACTION.GET_ANNUAL_PENDING_ISSUE_DATA_RECEIVE:
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {data: payload}));
    case ACTION.GET_ANNUAL_ISSUE_DATA_RECEIVE:
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {data: payload}));
    default:
      return state;
  }
};
