import {ISSUE_UNITS} from 'v2/constants/issueOptions';
import {ISSUES} from 'v2/constants/issues';

export function isFixedUnit(unit?: string): boolean {
  switch (unit) {
    case ISSUE_UNITS.MMTCO2E:
    case ISSUE_UNITS.MTCO2E:
    case 'MMTCO2e':
    case 'MTCO2e':
    case ISSUE_UNITS.MWH:
    case ISSUE_UNITS.GJ:
    case ISSUE_UNITS.TCM:
    case ISSUE_UNITS.MGAL:
    case ISSUE_UNITS.TCIR:
    case ISSUE_UNITS.LTIR:
    case ISSUE_UNITS.PERCENT:
    case ISSUE_UNITS.USD:
      return true;
    default:
      return false;
  }
}

export function unitOptions(unit?: string): {value: string; label: string}[] {
  switch (unit) {
    case ISSUE_UNITS.MMTCO2E:
    case ISSUE_UNITS.MTCO2E:
      return [
        {value: ISSUE_UNITS.MMTCO2E, label: ISSUE_UNITS.MMTCO2E},
        {value: ISSUE_UNITS.MTCO2E, label: ISSUE_UNITS.MTCO2E},
      ];
    case ISSUE_UNITS.MWH:
    case ISSUE_UNITS.GJ:
      return [
        {value: ISSUE_UNITS.MWH, label: ISSUE_UNITS.MWH},
        {value: ISSUE_UNITS.GJ, label: ISSUE_UNITS.GJ},
      ];
    case ISSUE_UNITS.TCM:
    case ISSUE_UNITS.MGAL:
      return [
        {value: ISSUE_UNITS.TCM, label: ISSUE_UNITS.TCM},
        {value: ISSUE_UNITS.MGAL, label: ISSUE_UNITS.MGAL},
      ];
    case ISSUE_UNITS.TCIR:
    case ISSUE_UNITS.LTIR:
      return [
        {value: ISSUE_UNITS.LTIR, label: ISSUE_UNITS.LTIR},
        {value: ISSUE_UNITS.TCIR, label: ISSUE_UNITS.TCIR},
      ];
    case ISSUE_UNITS.PERCENT:
    case ISSUE_UNITS.USD:
      return [
        {value: ISSUE_UNITS.PERCENT, label: ISSUE_UNITS.PERCENT},
        {value: ISSUE_UNITS.USD, label: ISSUE_UNITS.USD},
      ];
    default:
      return [];
  }
}

export const UNIT_PROPERTIES = {
  [ISSUE_UNITS.MMTCO2E]: {factor: 1, max: 800},
  [ISSUE_UNITS.MTCO2E]: {factor: 1000000, max: 500000000},
  [ISSUE_UNITS.MWH]: {factor: 1, max: 1000000000},
  [ISSUE_UNITS.GJ]: {factor: 3.6, max: 3600000000},
  [ISSUE_UNITS.TCM]: {factor: 1, max: 100000000},
  [ISSUE_UNITS.MGAL]: {factor: 0.26417205124156, max: 26417205.125156},
  [ISSUE_UNITS[':1']]: {factor: 1},
  [ISSUE_UNITS.USD]: {factor: 0, max: 1000000000},
  [ISSUE_UNITS.TCIR]: {factor: null, max: 10},
  [ISSUE_UNITS.LTIR]: {factor: null, max: 10},
  [ISSUE_UNITS.PERCENT]: {factor: 0, max: 100},
};

export function unitOptionByIssueId(id: number): {value: string; label: string}[] {
  switch (id) {
    case ISSUES.SCOPE_ONE_EMISSIONS.id:
    case ISSUES.SCOPE_TWO_EMISSIONS.id:
    case ISSUES.CARBON_EMISSIONS.id:
    case ISSUES.SCOPE_THREE_EMISSIONS.id:
    case ISSUES.CARBON_FOOTPRINT_OFFSETS.id:
      return [
        {value: ISSUE_UNITS.MMTCO2E, label: ISSUE_UNITS.MMTCO2E},
        {value: ISSUE_UNITS.MTCO2E, label: ISSUE_UNITS.MTCO2E},
      ];
    case ISSUES.ENERGY_USE.id:
    case ISSUES.RENEWABLE_ENERGY_CONSUMPTION.id:
    case ISSUES.ELECTRICITY_CONSUMPTION.id:
    case ISSUES.FUELS_CONSUMPTION.id:
      return [
        {value: ISSUE_UNITS.MWH, label: ISSUE_UNITS.MWH},
        {value: ISSUE_UNITS.GJ, label: ISSUE_UNITS.GJ},
      ];
    case ISSUES.WATER_WITHDRAWAL.id:
    case ISSUES.WATER_DISCHARGE.id:
    case ISSUES.WATER_CONSUMPTION.id:
      return [
        {value: ISSUE_UNITS.TCM, label: ISSUE_UNITS.TCM},
        {value: ISSUE_UNITS.MGAL, label: ISSUE_UNITS.MGAL},
      ];
    case ISSUES.INJURY_FREQUENCY.id:
    case ISSUES.SEVERE_INJURY_FREQUENCY.id:
      return [
        {value: ISSUE_UNITS.LTIR, label: ISSUE_UNITS.LTIR},
        {value: ISSUE_UNITS.TCIR, label: ISSUE_UNITS.TCIR},
      ];
    case ISSUES.CEO_COMPENSATION_RATIO.id:
    case ISSUES.WOMEN_IN_LEADERSHIP.id:
    case ISSUES.WOMEN_ON_BOARD.id:
    case ISSUES.BOARD_INDEPENDENCE.id:
    case ISSUES.CEO_COMPENSATION.id:
    case ISSUES.MEDIAN_EMPLOYEE_PAY.id:
    default:
      return [];
  }
}
