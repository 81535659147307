import React, {Component, Fragment} from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faCircleNotch from 'app/fontawesome-pro-light/faCircleNotch';
import {get, merge} from 'lodash';
// Local Imports
import {generatePdfFromPage} from 'app/utilities/generatePdfFromPage';
import {getEsgMeasuresForCurrentUser} from 'app/redux/planSponsor';
import {getHoldingMetricsData} from 'app/redux/strategyDashboard';
import {getSecurityByStrategyId} from 'app/redux/security';
import {
  AboutPage,
  BackCoverPage,
  CoverPage,
  ESGMeasuresPage,
  ESGRatingsPage,
  ESGRatingsPage2,
  HoldingsPerformancePage,
  ProductOverviewPage,
} from '../pdf-pages/PlanSponsorReport';
import {toastError} from 'app/utilities/toast';
import {getDateStamps} from 'app/utilities/getReportDateStamps';
import {getAll as getDashboardData} from 'app/redux/dashboard';
import {getStrategyUniversePerformance} from 'app/redux/strategyDashboard';
import {getDistributions, getIssueMatrix} from 'app/redux/planDashboard';
import {TableOfContentsPage, ESGMeasuresBarGraphPage} from '../pdf-pages/ProductReport';
import {PARENT_ISSUE} from '../constants';

export class ProductReportDownloadButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firm: this.props.firm || '',
      topHoldings: [],
      riskHoldings: [],
      isLoading: false,
    };

    this.fetchAllReportData();
  }

  fetchAllReportData = async () => {
    const {dispatch, strategyId} = this.props;

    const actions = [
      dispatch(getEsgMeasuresForCurrentUser()),
      dispatch(getDashboardData()),
      dispatch(getIssueMatrix()),
      dispatch(getHoldingMetricsData(strategyId)),
      dispatch(getDistributions()),
      dispatch(getStrategyUniversePerformance(strategyId)),
      dispatch(
        getSecurityByStrategyId(strategyId, {
          sortField: 'heldAmount',
          sortOrder: 'desc',
        }),
      ).then(data => {
        this.setState({topHoldings: data.payload.items});
      }),
      dispatch(
        getSecurityByStrategyId(strategyId, {
          sortField: 'companies.overallTransformedValue',
          sortOrder: 'desc',
        }),
      ).then(data => {
        this.setState({riskHoldings: data.payload.items});
      }),
    ];

    return await Promise.all(actions)
      .finally(values => values)
      .catch(err => {
        console.error({err});
        toastError('Whoops... There was a problem generating your report. Please try again later, or contact support.');
      });
  };

  generatePages = () => {
    const {
      benchMarkData,
      firm,
      holdingMetrics,
      planDashboard,
      strategyData,
      indexDistributions,
      issues,
      strategy,
      strategyDistributions,
      benchmarkData,
    } = this.props;

    const {topHoldings, riskHoldings} = this.state;

    const esgApproach =
      firm.esgApproach &&
      firm.esgApproach.split('\n').map((str, index) => (
        <Fragment key={index}>
          {str}
          <br />
        </Fragment>
      ));

    const esgMeasurement =
      firm.esgMeasurement &&
      firm.esgMeasurement.split('\n').map((str, index) => (
        <Fragment key={index}>
          {str}
          <br />
        </Fragment>
      ));

    return [
      <CoverPage companyName={firm && firm.name} />,
      <TableOfContentsPage
        companyName={firm && firm.name}
        product={strategy.name}
        benchMark={strategy.marketIndex.name}
      />,
      <ProductOverviewPage
        companyName={firm && firm.name}
        productData={strategy}
        benchMarkData={benchMarkData}
        planDistributions={strategyDistributions}
        indexUniverseDistributions={indexDistributions}
        esgApproach={esgApproach}
        esgMeasurement={esgMeasurement}
        page={3}
      />,
      <ESGRatingsPage companyName={firm.name} planDashboard={planDashboard} issues={issues} hidePeerRankings={true} />,
      <ESGRatingsPage2 companyName={firm.name} issues={issues} />,
      <ESGMeasuresPage
        companyName={firm.name}
        page={6}
        subjectHeader={'Product'}
        targetHeader={'Benchmark'}
        comparisonHeader={'Benchmark'}
        metrics={{
          [PARENT_ISSUE.ENVIRONMENTAL.name]: [1, 4, 6],
          [PARENT_ISSUE.SOCIAL.name]: [11, 10, 8, 9],
          [PARENT_ISSUE.GOVERNANCE.name]: [12],
        }}
        subjectData={holdingMetrics.strategy}
        targetData={holdingMetrics.benchmark}
      />,
      <ESGMeasuresBarGraphPage
        companyName={firm.name}
        title={PARENT_ISSUE.ENVIRONMENTAL.name}
        subjectHeader={'Product'}
        targetHeader={'Benchmark'}
        subjectData={strategyData}
        targetData={benchmarkData}
        chartMetrics={[1, 4, 6]}
        page={7}
      />,
      <ESGMeasuresBarGraphPage
        companyName={firm.name}
        title={PARENT_ISSUE.SOCIAL.name}
        subjectHeader={'Product'}
        targetHeader={'Benchmark'}
        subjectData={strategyData}
        targetData={benchmarkData}
        chartMetrics={[8, 9, 10, 11]}
        page={8}
      />,
      <ESGMeasuresBarGraphPage
        companyName={firm.name}
        title={PARENT_ISSUE.GOVERNANCE.name}
        subjectHeader={'Product'}
        targetHeader={'Benchmark'}
        subjectData={strategyData}
        targetData={benchmarkData}
        chartMetrics={[12]}
        page={9}
      />,
      <HoldingsPerformancePage
        companyName={firm.name}
        topHoldings={topHoldings}
        riskHoldings={riskHoldings}
        page={10}
      />,
      <AboutPage companyName={firm.name} page={11} />,
      <BackCoverPage />,
    ];
  };

  generateReport = async () => {
    const {quarter, year} = getDateStamps();

    try {
      this.setState({isLoading: true});
      await generatePdfFromPage(this.generatePages(), `harmony_analytics_product_report-q${quarter}_${year}.pdf`);
    } catch (err) {
      toastError('Whoops... There was a problem generating your report. Please try again later, or contact support.');
      console.error(err.message);
    } finally {
      this.setState({isLoading: false});
    }
  };

  render() {
    const {pendingTasks} = this.props;
    const {isLoading} = this.state;
    return (
      // <button
      //   type="button"
      //   className="btn btn-outline text-uppercase"
      //   style={{ height: '2.5rem' }}
      //   onClick={this.generateReport}
      //   disabled={isLoading && pendingTasks === 0}
      // >
      //   Generate Report
      //   {isLoading ? <Icon icon={faCircleNotch} className="fa-spin ml-2" /> : null}
      // </button>
      <></>
    );
  }
}

const mapStateToProps = state => {
  const planDashboardIsFetching = get(state, 'planDashboard.isFetching');
  const strategyIsFetching = get(state, 'strategy.isFetching');
  const managerDetailIsFetching = get(state, 'managerDetail.isFetching');
  const strategyDashboardIsFetching = get(state, 'strategyDashboard.isFetching');

  const planDashboard = !planDashboardIsFetching
    ? {
        planDashboard: get(state, 'planDashboard', {}),
      }
    : {};

  const strategy = !strategyIsFetching
    ? {
        strategy: get(state, 'strategy.strategyWithFirm', {}),
        firm: get(state, 'strategy.strategyWithFirm.firm', {}),
      }
    : {};

  const managerDetail = !managerDetailIsFetching
    ? {
        distributionAndIssues: get(state, 'managerDetail', {}),
        distributions: get(state, 'managerDetail.distributions', {}),
        issues: get(state, 'managerDetail.issues', []),
        strategyDistributions: get(state, 'managerDetail.distributions.strategyDistributions', []),
        indexDistributions: get(state, 'managerDetail.distributions.indexDistributions', []),
      }
    : {};

  const strategyDashboard = !strategyDashboardIsFetching
    ? {
        holdingMetrics: get(state, 'strategyDashboard.holdingMetrics', []),
        benchMarkData: get(state, 'strategyDashboard.benchmark', []),
        strategyData: get(state, 'strategyDashboard.holdingMetrics.strategy', []),
        benchmarkData: get(state, 'strategyDashboard.holdingMetrics.benchmark', []),
      }
    : {};

  return merge(planDashboard, strategy, managerDetail, strategyDashboard, {
    pendingTasks: get(state, 'pendingTasks'),
  });
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const ProductReportDownloadButton = compose(connect(mapStateToProps, null, mergeProps))(
  ProductReportDownloadButtonComponent,
);
