import {useState} from 'react';
import {TagProps} from 'reactstrap';
import styled from 'styled-components/macro';
import {FlexContainer} from '../atoms/Containers';
import {Close} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {ButtonM} from '../atoms/Typeface';

const Label = styled(ButtonM)`
  position: relative;
  top: -0.0625rem;
  white-space: nowrap;
`;

const Container = styled(FlexContainer).attrs({
  width: 'auto',
  height: '2.75rem',
  padding: '0.75rem 1rem',
  gap: '0.75rem',
  items: 'center',
  justify: 'center',
})`
  background-color: ${({theme}) => theme.colors.grey_2};
  border-radius: 0.5rem;
  border: 0;
  cursor: pointer;
`;

export const Tag = ({label = '', onClose}: TagProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <Container as="button" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={onClose}>
      <Label color={isHovered ? Theme.colors.blue_1 : Theme.colors.grey_5}>{label}</Label>
      <Close size={20} color={isHovered ? Theme.colors.blue_1 : Theme.colors.grey_5} />
    </Container>
  );
};
