import {createSlice} from '@reduxjs/toolkit';

export const testSlice = createSlice({
  name: 'test',
  initialState: {
    region_filter: '',
  },
  reducers: {
    set_region_filter(state, action) {
      state.region_filter = action.payload;
    },
  },
});

export const {set_region_filter} = testSlice.actions;

export default testSlice.reducer;
