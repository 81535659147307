/* eslint-disable no-param-reassign */
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
  data: {
    compliantManagersRatio: 0,
    engagedManagersRatio: 0,
    esgManagersRatio: 0,
    topHoldings: [],
  },
};

// ACTION
export const ACTION = {
  GET_ALL_REQUEST: 'DASHBOARD_GET_ALL_REQUEST',
  GET_ALL_RECEIVE: 'DASHBOARD_GET_ALL_RECEIVE',
};

const getAllRequest = createAction(ACTION.GET_ALL_REQUEST);
const getAllReceive = createAction(ACTION.GET_ALL_RECEIVE);

const api = {
  getAll: () => {
    return fetchAuthJSON('dashboardData', {method: 'get'});
  },
};

export function getAll() {
  return dispatch => {
    dispatch(getAllRequest());
    return dispatch(getAllReceive(api.getAll()));
  };
}

// REDUCER
export const dashboard = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_ALL_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_ALL_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError
            ? {error: payload}
            : {
                data: payload,
              },
        ),
      );
    default:
      return state;
  }
};
