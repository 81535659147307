import {Theme} from '../theme';

export const Globe = ({color = Theme.colors.dark, size = 32, style = {}}) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M9.99999 18.3332C14.6024 18.3332 18.3333 14.6022 18.3333 9.99984C18.3333 5.39746 14.6024 1.6665 9.99999 1.6665C5.39762 1.6665 1.66666 5.39746 1.66666 9.99984C1.66666 14.6022 5.39762 18.3332 9.99999 18.3332Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1.66666 10H18.3333" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9.99999 1.6665C12.0844 3.94846 13.269 6.90987 13.3333 9.99984C13.269 13.0898 12.0844 16.0512 9.99999 18.3332C7.91559 16.0512 6.73103 13.0898 6.66666 9.99984C6.73103 6.90987 7.91559 3.94846 9.99999 1.6665V1.6665Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
