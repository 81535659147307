import {IDPercentile} from './interfaces/IDPercentile';
import {IDQuintile} from './interfaces/IDQuintile';
import {IDUniversePercentile} from './interfaces/IDUniversePercentile';
import {AggregatePercentileHolding, AggregatePercentileUniverse, Issue, MarketIndexBenchmark} from '../types/typeOrm';
import {ISSUES} from '../../constants/issues';
import {IDIssue} from './interfaces/IDIssue';
import {SelectOption} from '../../components/interfaces/Select';
import {
  calculateUniverseStatistics as calcUniverseStatistics,
  calculateHoldingsStatistics as calcHoldingsStatistics,
  calculateBenchmarkStatistics as calcBenckmarksStatistics,
  setTypesFilters,
} from 'v2/utilities/helpers/calculateStatistics';

import {
  TypeValues,
  LiquidClassValues,
  IlliquidClassValues,
  allAssetsClassValues,
  RegionValues,
} from 'v2/constants/strategyFilters';

export class DAggregateHoldingsAndUniverse implements IDIssue, IDPercentile, IDQuintile, IDUniversePercentile {
  private type: string;
  private filters: {[key: string]: string};
  private typeFilters: any;
  private classFilters: any;
  private regionFilters: any;
  private issues: {[key: number]: Issue} = {};
  private regions: {[key: string]: SelectOption} = {
    Default: {value: '', label: 'Global', isDisabled: false},
  };
  private universePercentiles: {[key: number]: number} = {};
  private benchmarkPercentiles: {[key: number]: number} = {};
  private percentiles: {[key: number]: number} = {};
  private quintiles: {[key: number]: number} = {};
  private companies: Set<number>;

  constructor(
    holdingsData: AggregatePercentileHolding[],
    universeData: AggregatePercentileUniverse[],
    type: 'Liquid' | 'Illiquid' | 'all',
    filters: {[key: string]: string},
    marketIndexData: MarketIndexBenchmark[],
  ) {
    const typeToClassType = {
      'Liquid Assets': 'Liquid',
      'Illiquid Assets': 'Illiquid',
      all: 'all',
    };
    //@ts-ignore
    this.type = typeToClassType[type];
    this.filters = filters;
    this.typeFilters = JSON.parse(JSON.stringify(TypeValues));
    this.classFilters = JSON.parse(
      JSON.stringify({Liquid: LiquidClassValues, Illiquid: IlliquidClassValues, all: allAssetsClassValues}),
    );
    this.regionFilters = JSON.parse(JSON.stringify(RegionValues));
    this.companies = new Set();
    this.calculateHoldingsStatistics(holdingsData);
    this.calculateUniverseStatistics(universeData);
    this.calculateBenchmarkStatistic(marketIndexData);
    this.setFilters(holdingsData);
  }

  private setFilters = (data: AggregatePercentileHolding[]) => {
    let filters = setTypesFilters(data);

    this.typeFilters = filters.typeFilters;
    this.classFilters = filters.classFilters;
    this.regionFilters = filters.regionFilters;
  };

  private calculateHoldingsStatistics(data: AggregatePercentileHolding[]) {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 0,
      [ISSUES.SOCIAL.id]: 0,
      [ISSUES.GOVERNANCE.id]: 0,
    };

    let holdingsPercentiles = calcHoldingsStatistics(percentiles, this.companies, this.filters, this.issues, data);

    this.percentiles = holdingsPercentiles.percentiles;
    this.quintiles = holdingsPercentiles.quintiles;
  }

  private calculateUniverseStatistics(data: AggregatePercentileUniverse[]) {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 0,
      [ISSUES.SOCIAL.id]: 0,
      [ISSUES.GOVERNANCE.id]: 0,
    };

    this.universePercentiles = calcUniverseStatistics(percentiles, this.companies, data);
  }

  private calculateBenchmarkStatistic(data: MarketIndexBenchmark[]) {
    const percentiles = {
      [ISSUES.GLOBAL.id]: 0,
      [ISSUES.ENVIRONMENTAL.id]: 0,
      [ISSUES.SOCIAL.id]: 0,
      [ISSUES.GOVERNANCE.id]: 0,
    };

    this.benchmarkPercentiles = calcBenckmarksStatistics(percentiles, this.companies, data);
  }

  getIssueName(issueId: number): string {
    return this.issues[issueId]?.name ?? '';
  }

  getUniversePercentile(issueId: number): number {
    const percentile = Math.round(this.universePercentiles[issueId]);
    return isNaN(percentile) ? 0 : percentile;
  }

  getBenchmarkPercentile(issueId: number): number {
    const percentile = Math.round(this.benchmarkPercentiles[issueId]);
    return isNaN(percentile) ? 0 : percentile;
  }
  getPercentile(issueId: number): number {
    const percentile = Math.round(this.percentiles[issueId] ?? 0);
    return isNaN(percentile) ? 0 : percentile;
  }

  getQuintile(issueId: number): number {
    const quintile = this.quintiles[issueId];
    return isNaN(quintile) ? 0 : quintile;
  }

  getAllFilters() {
    return {
      type: Object.values(this.typeFilters),
      class: Object.values(this.classFilters[this.type]),
      region: Object.values(this.regionFilters),
    };
  }

  getTypeFilters() {
    return Object.values(this.typeFilters);
  }

  getClassFilters() {
    //@ts-ignore
    return Object.values(this.classFilters[typeToClassType[this.type]]);
  }

  getRegionFilters() {
    return Object.values(this.regionFilters);
  }
}
