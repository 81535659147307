import {getSessionJwt} from 'app/utilities';
import {groupBy, orderBy, sumBy} from 'lodash';
import {useSelector} from 'react-redux';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useGetPlanSponsorHoldingsQuery} from 'v2/redux/typeormEndpoints';
import {filter} from 'v2/utilities/helpers/filter';
import {PlanSponsorHolding} from 'v2/utilities/types/typeOrm';

export const useDashboardHoldings = () => {
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const {data, isLoading, isSuccess, isError} = useGetPlanSponsorHoldingsQuery({planSponsorId});

  const unfilteredPlanSponsorHoldings: PlanSponsorHolding[] = data ?? [];

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const filterParams = [];
  if (filters.type !== 'all') {
    filterParams.push({key: 'type', target: filters.type, operation: 'eq'});
  }
  if (filters.class !== '') {
    filterParams.push({key: 'class', target: filters.class, operation: 'eq'});
  }
  if (filters.region !== '') {
    filterParams.push({key: 'region', target: filters.region, operation: 'eq'});
  }

  //@ts-ignore
  const PlanSponsorHoldings = filter({data: unfilteredPlanSponsorHoldings, filterItems: filterParams});

  const totalMarketValue = sumBy(PlanSponsorHoldings, o => Number(o.marketValue));
  const groupedHoldings = groupBy(PlanSponsorHoldings, 'companyId');

  const filteredPlanSponsorHoldings = PlanSponsorHoldings.filter(
    (holding: PlanSponsorHolding) => Number(holding.marketValue) / totalMarketValue > 0.001,
  );

  const groupedPlanSponsorHoldings = groupBy(filteredPlanSponsorHoldings, 'companyId');
  const uniquedPlanSponsorHoldings = Object.values(groupedPlanSponsorHoldings).map((holding: any) => holding[0]);
  return {
    data: uniquedPlanSponsorHoldings.map(holding => ({
      id: holding.companyId,
      companyName: holding.name ?? DATA_STATUS.NONE,
      isEngaged: holding.engaged === 'TRUE' ?? DATA_STATUS.NONE,
      transparency: holding.transparency ?? DATA_STATUS.NONE,
      pctRank: holding.pctRank ?? DATA_STATUS.NONE,
      held: sumBy(groupedHoldings[holding.companyId], o => Number(o.marketValue ?? 0)) ?? DATA_STATUS.NONE,
      weight: (Number(holding.marketValue) / totalMarketValue).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
      }),
      carbon_predicted: holding?.carbon_emissions_is_predicted,
    })),
    isLoading,
    isSuccess,
    isError,
  };
};
