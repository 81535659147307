import React from 'react';
import PropTypes from 'prop-types';
import {forEach, isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import cn from 'classnames';
import {Link} from 'react-router-dom';

import {closeSimpleModal} from './redux';

const prepMessage = message => {
  if (typeof message !== 'string') return message; // JSX or array

  const isHtml = /<[a-z][\s\S]*>/i.test(message);
  if (isHtml) {
    /* eslint-disable react/no-danger */
    return <div dangerouslySetInnerHTML={{__html: message}} />;
    /* eslint-enable react/no-danger */
  }

  return <p>{message}</p>;
};

/**
 * Used by the <button> to handle the onClick functionality
 * If clickHandler is passed in fire it.
 * Then check for close flag passed in, if so, fire closeModal
 * @param {function} clickHandler - The click handler passed on the button information
 * @param {bool} close - Flag to determine if should close on clicking
 * @param {function} closeModal - Function used to close the modal
 */
const handleClick = (clickHandler, close, closeModal) => {
  if (clickHandler) {
    clickHandler();
  }
  if (close) {
    closeModal();
  }
};

const SimpleModalComponent = ({customClassName, isOpen, closeModal, title, message, buttons}) => {
  const footerElements = [];
  if (!isEmpty(buttons)) {
    forEach(buttons, ({label, className, href, path, isDownload, target, close, clickHandler}, index) => {
      if (href) {
        footerElements.push(
          <a
            key={index}
            className={cn('btn btn-outline', className)}
            href={href}
            onClick={close ? closeModal : null}
            target={target || '_self'}
            download={isDownload ? '' : undefined}
          >
            {label}
          </a>,
        );
      } else if (path) {
        footerElements.push(
          <Link key={index} className={cn('btn btn-outline', className)} to={path} onClick={close ? closeModal : null}>
            {label}
          </Link>,
        );
      } else {
        footerElements.push(
          <button
            key={index}
            className={cn('btn btn-outline', className)}
            onClick={() => handleClick(clickHandler, close, closeModal)}
          >
            {label}
          </button>,
        );
      }
    });
  } else {
    footerElements.push(
      <button key="dismiss" className="btn btn-outline" onClick={closeModal}>
        Close
      </button>,
    );
  }

  return (
    <Modal isOpen={isOpen} className={cn('modal-dialog-centered', customClassName)}>
      <ModalHeader toggle={closeModal} tag="h2">
        {title}
      </ModalHeader>
      <ModalBody>{message && prepMessage(message)}</ModalBody>
      <ModalFooter>{footerElements}</ModalFooter>
    </Modal>
  );
};

SimpleModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      className: PropTypes.string,
      isDownload: PropTypes.bool,
      href: PropTypes.string,
      path: PropTypes.string,
      target: PropTypes.string,
      close: PropTypes.bool,
      clickHandler: PropTypes.func,
    }),
  ),
};

const mapStateToProps = state => {
  const {
    simpleModal: {isOpen, title, message, buttons, customClassName},
  } = state;

  return {
    isOpen,
    title,
    message,
    buttons,
    customClassName,
  };
};

const mapDispatchToProps = dispatch => () => ({closeModal: () => dispatch(closeSimpleModal())});

export const SimpleModal = connect(mapStateToProps, mapDispatchToProps)(SimpleModalComponent);
