import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isNil, map, merge} from 'lodash';
import {Doughnut} from 'react-chartjs-2';

import {COLOR} from 'app/constants';
import {ConicalGradientProvider} from 'app/components';
import {getChartJsSegment, getIssueDataAnalysis} from 'app/utilities';
import {DatasetController} from 'chart.js';

const ISSUE_DOUGHNUT_CHART_OPTIONS = {
  cutout: '73%',
  elements: {arc: {borderWidth: 0}},
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 5,
      bottom: 5,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: false,
    tooltip: {
      displayColors: false,
      callbacks: {
        title: (segments, context) => {
          return segments[0].label;
        },
      },
    },
  },
};

const highlightSegment = ({chartInstance, datasetIndex, dataIndex}) => {
  const segment = getChartJsSegment({chartInstance, datasetIndex, dataIndex});
  const controller = new DatasetController(chartInstance, datasetIndex);
  if (segment && controller) {
    if (datasetIndex === 0) {
      controller.updateElement(segment, dataIndex, {outerRadius: segment.outerRadius + 4});
    } else {
      controller.updateElement(segment, dataIndex, {outerRadius: segment.outerRadius - 4});
    }
  }
};

class ComparisonDoughnut extends Component {
  constructor(props) {
    super(props);

    this.handleChartHover = this.handleChartHover.bind(this);
    this.handleChartLeave = this.handleChartLeave.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getLabelTextColor = this.getLabelTextColor.bind(this);
    this.chart = React.createRef(null);
    this.hoveredDataIndex = React.createRef(null);
    this.hoveredDatasetIndex = React.createRef(null);
  }

  handleChartHover(event, elements, chart) {
    const chartInstance = this.chart.current;
    let datasetIndex = null;
    let dataIndex = null;

    if (elements.length) {
      datasetIndex = elements[0].datasetIndex;
      dataIndex = elements[0].index;
    }
    if (datasetIndex !== this.hoveredDatasetIndex || dataIndex !== this.hoveredDataIndex) {
      chartInstance.update();
      if (!isNil(datasetIndex) && !isNil(dataIndex)) {
        highlightSegment({chartInstance, datasetIndex, dataIndex});
      }
      this.hoveredDatasetIndex = datasetIndex;
      this.hoveredDataIndex = dataIndex;
    }
  }

  handleChartLeave(event) {
    this.chart.current.update();
  }

  getLabel(context) {
    const dataset = context.dataset;
    const index = context.dataIndex;
    const {unit} = this.props;

    const label = dataset.data[index];
    return `${label}${unit}`;
  }

  getLabelTextColor({datasetIndex, dataIndex}) {
    const {data} = this.props;
    const issueDataAnalysis = getIssueDataAnalysis(data);
    const labelTextColor = issueDataAnalysis.isWorrisome ? COLOR.RED : COLOR.GREEN;

    if (datasetIndex === 0 && dataIndex === 0) return labelTextColor; // colorize if data
    return COLOR.LIGHT_GRAY; // else make gray for dataToCompare
  }

  render() {
    const {data, labels, caption} = this.props;
    return (
      <ConicalGradientProvider>
        {({conicalGradients}) => {
          if (!conicalGradients || isNil(data)) return null;

          // Cast data and gradients into the format ChartJS needs
          const datasetsForChart = map(data, ({label, active, inActive}) => {
            return {
              label,
              data: [active, inActive],
              backgroundColor: [conicalGradients.linearNavySmall, conicalGradients.lightestGray],
              hoverBackgroundColor: [conicalGradients.linearNavySmall, conicalGradients.lightestGray],
            };
          });

          return (
            <div className="comparison-doughnut">
              <Doughnut
                onMouseLeave={this.handleChartLeave}
                ref={this.chart}
                options={merge(
                  {
                    onHover: this.handleChartHover,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: this.getLabel,
                          labelTextColor: this.getLabelTextColor,
                        },
                      },
                    },
                  },
                  ISSUE_DOUGHNUT_CHART_OPTIONS,
                )}
                data={{
                  labels,
                  datasets: datasetsForChart,
                }}
              />
              <div className="caption">{caption}</div>
            </div>
          );
        }}
      </ConicalGradientProvider>
    );
  }
}

ComparisonDoughnut.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array,
  unit: PropTypes.string,
  caption: PropTypes.string,
};

export {ComparisonDoughnut};
