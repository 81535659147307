import {findKey} from 'lodash';

import {COLOR} from 'app/constants';

export const convertColorToClassName = color => {
  const colorKey = findKey(COLOR, o => o === color);

  if (!colorKey) return '';

  return `is-${colorKey.toLowerCase().replace('_', '-')}`;
};
