import {useMemo} from 'react';
import {useParams} from 'react-router';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useGetStrategyHoldingsQuery} from 'v2/redux/harmonyApi';
import {
  useGetStrategyAggregatePercentileRankHoldingsQuery,
  useGetStrategyAggregatePercentileRankIndexesQuery,
} from 'v2/redux/typeormEndpoints';

import {
  AggregatePercentileRankHoldings,
  AggregatePercentileRankIndex,
  PlanSponsorHolding,
} from 'v2/utilities/types/typeOrm';

export const useManagerHoldingsDistribution = () => {
  const {strategyId = ''} = useParams<{strategyId: string}>();

  const {
    data: holdingsData1,
    isError: isHoldingsError1,
    isSuccess: isHoldingsSuccess1,
    isLoading: isHoldingsLoading1,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 1});

  const {
    data: holdingsData2,
    isError: isHoldingsError2,
    isSuccess: isHoldingsSuccess2,
    isLoading: isHoldingsLoading2,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 2});

  const {
    data: holdingsData3,
    isError: isHoldingsError3,
    isSuccess: isHoldingsSuccess3,
    isLoading: isHoldingsLoading3,
  } = useGetStrategyAggregatePercentileRankHoldingsQuery({strategyId, issueId: 3});

  const {
    data: strategyHoldingsData,
    isError: strategyHoldingsIsError,
    isSuccess: strategyHoldingsIsSuccess,
    isLoading: strategyHoldingsIsLoading,
  } = useGetStrategyHoldingsQuery({strategyId}, {skip: !strategyId});

  const isHoldingsLoading = isHoldingsLoading1 || isHoldingsLoading2 || isHoldingsLoading3;
  const isHoldingsError = isHoldingsError1 || isHoldingsError2 || isHoldingsError3;
  const isHoldingsSuccess = isHoldingsSuccess1 && isHoldingsSuccess2 && isHoldingsSuccess3;
  const holdingsData = holdingsData1?.concat(holdingsData2, holdingsData3);

  const {
    data: benchmarkData1,
    isError: isBenchmarkError1,
    isSuccess: isBenchmarkSuccess1,
    isLoading: isBenchmarkLoading1,
  } = useGetStrategyAggregatePercentileRankIndexesQuery({strategyId, issueId: 1});

  const {
    data: benchmarkData2,
    isError: isBenchmarkError2,
    isSuccess: isBenchmarkSuccess2,
    isLoading: isBenchmarkLoading2,
  } = useGetStrategyAggregatePercentileRankIndexesQuery({strategyId, issueId: 2});

  const {
    data: benchmarkData3,
    isError: isBenchmarkError3,
    isSuccess: isBenchmarkSuccess3,
    isLoading: isBenchmarkLoading3,
  } = useGetStrategyAggregatePercentileRankIndexesQuery({strategyId, issueId: 3});

  const isBenchmarkLoading = isBenchmarkLoading1 || isBenchmarkLoading2 || isBenchmarkLoading3;
  const isBenchmarkError = isBenchmarkError1 || isBenchmarkError2 || isBenchmarkError3;
  const isBenchmarkSuccess = isBenchmarkSuccess1 && isBenchmarkSuccess2 && isBenchmarkSuccess3;
  const holdingsBenchmarkData = benchmarkData1?.concat(benchmarkData2, benchmarkData3);

  const data = (isHoldingsSuccess ? holdingsData : []) as AggregatePercentileRankHoldings[];
  const indexData = (isBenchmarkSuccess ? holdingsBenchmarkData : []) as AggregatePercentileRankIndex[];

  const groupedRankTiers = (strategies: AggregatePercentileRankHoldings[]) => {
    let tiers: {[index: string]: any[]} = {
      '100-81': [],
      '80-61': [],
      '60-41': [],
      '40-21': [],
      '20-0': [],
    };

    strategies.forEach(strategy => {
      if (Number(strategy.companyPctRank) > 0.81) {
        tiers['100-81'].push(strategy);
      } else if (Number(strategy.companyPctRank) > 0.61) {
        tiers['80-61'].push(strategy);
      } else if (Number(strategy.companyPctRank) > 0.41) {
        tiers['60-41'].push(strategy);
      } else if (Number(strategy.companyPctRank) > 0.21) {
        tiers['40-21'].push(strategy);
      } else {
        tiers['20-0'].push(strategy);
      }
    });

    return tiers;
  };

  const groupedIndexTiers = (strategies: AggregatePercentileRankIndex[]) => {
    let tiers: {[index: string]: any[]} = {
      '100-81': [],
      '80-61': [],
      '60-41': [],
      '40-21': [],
      '20-0': [],
    };

    strategies.forEach(strategy => {
      if (Number(strategy.company_pct_rank) > 0.81) {
        tiers['100-81'].push(strategy);
      } else if (Number(strategy.company_pct_rank) > 0.61) {
        tiers['80-61'].push(strategy);
      } else if (Number(strategy.company_pct_rank) > 0.41) {
        tiers['60-41'].push(strategy);
      } else if (Number(strategy.company_pct_rank) > 0.21) {
        tiers['40-21'].push(strategy);
      } else {
        tiers['20-0'].push(strategy);
      }
    });

    return tiers;
  };

  const holdingsByWeight = () => {
    const companies: any[] = [];
    const strategies = data.filter(datum => {
      if (!companies.includes(datum.companyId)) {
        companies.push(datum.companyId);
        return datum;
      }
    });

    const indexStrategies = indexData.filter(datum => {
      if (!companies.includes(datum?.company_id)) {
        companies.push(datum?.company_id);
        return datum;
      }
    });

    const tiers = groupedRankTiers(strategies);
    const indexTiers = groupedIndexTiers(indexStrategies);

    const calcHoldings = (index: string) => {
      let groupAmountHeld = 0,
        totalAmountHeld = 0;

      tiers[index].forEach(item => {
        groupAmountHeld += Number(item.amountHeld ?? 0);
      });

      strategies.forEach(item => {
        totalAmountHeld += Number(item.amountHeld ?? 0);
      });

      if (groupAmountHeld === 0 || totalAmountHeld === 0) {
        return 1;
      }

      return Math.round(100 * (groupAmountHeld / totalAmountHeld));
    };

    const calcIndex = (index: string) => {
      let groupMarketCap = 0,
        totalMarketCap = 0;

      indexTiers[index].forEach(item => {
        groupMarketCap += Number(item.weight ?? 0);
      });

      indexStrategies.forEach(item => {
        totalMarketCap += Number(item.weight ?? 0);
      });

      if (groupMarketCap === 0 || totalMarketCap === 0) {
        return 1;
      }

      return Math.round(100 * (groupMarketCap / totalMarketCap));
    };

    return {
      held: [
        calcHoldings('100-81'),
        calcHoldings('80-61'),
        calcHoldings('60-41'),
        calcHoldings('40-21'),
        calcHoldings('20-0'),
      ],
      index: [calcIndex('100-81'), calcIndex('80-61'), calcIndex('60-41'), calcIndex('40-21'), calcIndex('20-0')],
    };
  };

  const holdingsByCount = () => {
    const strategies = data;
    const indexStrategies = indexData;

    const tiers = groupedRankTiers(strategies);
    const indexTiers = groupedIndexTiers(indexStrategies);

    const calcHoldings = (index: string) => {
      const groupHoldingsCount = tiers[index].length;
      const totalHoldingsCount = strategies.length;

      if (groupHoldingsCount === 0 || totalHoldingsCount === 0) {
        return 1;
      }

      return Math.round(100 * (groupHoldingsCount / totalHoldingsCount));
    };

    // TODO: Switch to index endpoint data for index
    const calcIndex = (index: string) => {
      const groupHoldingsCount = indexTiers[index].length;
      const totalHoldingsCount = indexStrategies.length;

      if (groupHoldingsCount === 0 || totalHoldingsCount === 0) {
        return 1;
      }

      return Math.round(100 * (groupHoldingsCount / totalHoldingsCount));
    };

    return {
      held: [
        calcHoldings('100-81'),
        calcHoldings('80-61'),
        calcHoldings('60-41'),
        calcHoldings('40-21'),
        calcHoldings('20-0'),
      ],
      index: [calcIndex('100-81'), calcIndex('80-61'), calcIndex('60-41'), calcIndex('40-21'), calcIndex('20-0')],
    };
  };

  const holdings = {
    weight: holdingsByWeight().held,
    count: holdingsByCount().held,
  };

  const benchmark = {
    weight: holdingsByWeight().index,
    count: holdingsByCount().index,
  };

  const aggregatePercentileRankHoldings: any = useMemo(() => {
    return strategyHoldingsData?.map((holding: PlanSponsorHolding) => {
      return {
        id: holding.companyId,
        company: holding.name ?? DATA_STATUS.NONE,
        sector: holding.sectorName ?? DATA_STATUS.NONE,
        held: holding.marketValue ?? 0,
        pctRank: holding.pctRank ?? 0,
      };
    });
  }, [strategyHoldingsData]);

  return {
    data: {
      holdings,
      benchmark,
      aggregatePercentileRankHoldings,
    },
    isError: isHoldingsError && isBenchmarkError && strategyHoldingsIsError,
    isLoading: isHoldingsLoading && isBenchmarkLoading && strategyHoldingsIsLoading,
    isSuccess: isHoldingsSuccess && isBenchmarkSuccess && strategyHoldingsIsSuccess,
  };
};
