import styled from 'styled-components';
import {RatingByPercent} from './RatingByPercent';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {getOrdinal} from 'v2/utilities/helpers';
import {limitPctRank} from 'v2/utilities/helpers/limitPctRank';

export const RankDiv = styled.div<{showRating?: boolean}>`
  display: flex;
  justify-content: ${({showRating}) => (showRating ? 'flex-start' : 'center')};
  align-items: center;
  width: 5rem;
  gap: 0.5rem;
`;

export const Sup = styled.span`
  position: relative;
  top: -0.1875rem;
  font-size: 0.5625rem;
`;

export const LabelContainer = styled.div`
  position: relative;
  top: -1px;
`;

export const PercentileRank = ({
  showRating,
  superscript,
  value,
}: {
  showRating?: boolean;
  superscript?: boolean;
  value: number;
}) => {
  let rank = !isNaN(value) ? Math.round(value * 100) || 1 : NaN;
  rank = limitPctRank(rank);

  return (
    <RankDiv>
      {showRating && <RatingByPercent pctRank={value} size={26} />}
      {!isNaN(rank) ? (
        <LabelContainer>
          {rank}
          {superscript ? <Sup>{getOrdinal(rank)}</Sup> : getOrdinal(rank)}
        </LabelContainer>
      ) : (
        <div>{DATA_STATUS.NONE}</div>
      )}
    </RankDiv>
  );
};
