import {Theme} from '../theme';

export const PopCheckCircle = ({color = Theme.colors.light_green_2, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.1664 32.3164V34.9998C64.1628 41.2893 62.1261 47.4092 58.3602 52.4468C54.5943 57.4843 49.3009 61.1695 43.2695 62.9528C37.238 64.7361 30.7917 64.522 24.8919 62.3423C18.9921 60.1626 13.9549 56.1342 10.5316 50.8579C7.10837 45.5815 5.4824 39.34 5.89623 33.064C6.31006 26.7881 8.74153 20.8141 12.828 16.0329C16.9145 11.2517 22.437 7.91963 28.5719 6.53353C34.7068 5.14742 41.1255 5.78158 46.8705 8.34144"
        stroke="#42B23A"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1667 11.667L35 40.8628L26.25 32.1128"
        stroke={color}
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
