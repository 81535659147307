import {TREND_TYPE} from '../constants';

function getPercentChange(dataStart, dataEnd) {
  if (dataEnd === undefined || dataEnd === null || dataStart === undefined || dataStart === null || dataStart === 0) {
    return null;
  }

  return 100 * ((dataEnd - dataStart) / dataStart);
}

function getTrendType(target, dataStart, dataEnd) {
  const percentChange = getPercentChange(dataStart, dataEnd);

  if (percentChange !== null && percentChange !== 0) {
    if (target === 'MAX') {
      if (percentChange > 0) {
        return TREND_TYPE.GOOD;
      }
      if (percentChange < 0) {
        return TREND_TYPE.BAD;
      }
    } else if (target === 'MIN') {
      if (percentChange > 0) {
        return TREND_TYPE.BAD;
      }
      if (percentChange < 0) {
        return TREND_TYPE.GOOD;
      }
    } else {
      // Target is a specific number
      const targetVal = parseFloat(target);
      const diffStart = Math.abs(targetVal - dataStart);
      const diffEnd = Math.abs(targetVal - dataEnd);

      if (diffEnd < diffStart) {
        return TREND_TYPE.GOOD;
      }
      if (diffEnd > diffStart) {
        return TREND_TYPE.BAD;
      }
    }
  }

  return TREND_TYPE.NEUTRAL;
}

export {getPercentChange, getTrendType};
