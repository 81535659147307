import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, isNil, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError} from 'redux-form';

// Local Imports
import {AdminEditFirmForm} from 'app/components/AdminEditFirmForm';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {updateFirm, getByIdFirm} from 'app/redux/firm';
import {toastSuccess, toastError} from 'app/utilities/toast';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';

const formName = 'admin-edit-firm';

class AdminEditFirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {editUser: null};

    const {firmId} = props;

    props
      .dispatch(getByIdFirm(firmId))
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }

        animateScroll.scrollToTop();
      })
      .catch(() => {
        animateScroll.scrollToTop();
        toastError('Error retrieving firm information.');
      });
  }

  render() {
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 800}}>
          <Link
            className="d-inline-block mb-4 small"
            to={{
              pathname: ROUTE.ADMIN_FIRMS.path(),
              search: this.props.location.search,
            }}
          >
            <Icon icon={faArrowLeft} className="mr-2" />
            Return to {ROUTE.ADMIN_FIRMS.title}
          </Link>
          <h1 className="mb-2">{ROUTE.ADMIN_FIRMS_EDIT.title}</h1>
          {this.props.firmData && (
            <AdminEditFirmForm {...this.props} formName={formName} canWrite={this.props.canWrite} />
          )}
        </div>
      </div>
    );
  }
}

AdminEditFirmPage.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: {firmId},
    },
  } = ownProps;
  return {
    firmData: get(state, 'firm.editFirm'),
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_FIRMS),
    firmId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: {firmId},
    },
  } = ownProps;
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      return dispatch(updateFirm(firmId, values))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('The firm / strategies has been updated.');

          animateScroll.scrollToTop();

          dispatch(reset(formName));

          dispatch(
            push({
              pathname: ROUTE.ADMIN_FIRMS.path(),
              search: ownProps.location.search,
            }),
          );
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const AdminEditFirm = connect(mapStateToProps, mapDispatchToProps)(AdminEditFirmPage);
