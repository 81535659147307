import React, {Component, Fragment} from 'react';
import {compact, each, find, get, indexOf, isEmpty, map, uniq} from 'lodash';
import {Field, FieldArray} from 'redux-form';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {compose, withProps} from 'recompose';
import BootstrapTable from 'react-bootstrap-table-next';

import {
  COLOR,
  ISSUE_OLD,
  ISSUE_CATEGORIES,
  ISSUE_VIEW_MODE,
  ISSUE_VIEW_MODES,
  ISSUES_OLD,
  ROUTE,
  TIME_PERIOD_COLORS,
} from 'app/constants';
import {formatNumber, getPercentChangeInPeriodData, withViewportSize} from 'app/utilities';
import {
  BarChart,
  BarChartLegend,
  EmpoweringChangeStats,
  IssueCardWithProgress,
  IssueDataChangeDescription,
  RectangleImage,
  TooltipLink,
  VoidLink,
} from 'app/components';
import {Checkbox, SelectFormGroup} from 'app/components/forms';
import faCheck from 'app/fontawesome-pro-light/faCheck';
import faPrint from 'app/fontawesome-pro-light/faPrint';
// import faQuestionCircle from 'app/fontawesome-pro-light/faQuestionCircle';

const styles = {
  barChartLegendDatasets: {marginBottom: 100},
  focusIssues: {
    columnCount: 2,
    columnGap: '2rem',
  },
  focusIssue: {
    breakInside: 'avoid',
    pageBreakInside: 'avoid', // for Firefox
  },
  fontSizeSmall: {fontSize: '1rem'},
  noDataContainer: {maxWidth: 780},
  paragraph: {maxWidth: 580},
};

const ACTIVE_LINK_CLASSNAME = 'text-darker-gray pointer-events-none';

const metaIssueColumnDefinitions = {
  isIncludedStatusBar: {
    dataField: 'isESGFocus',
    text: '',
    formatter: isESGFocus => {
      const fill = isESGFocus ? COLOR.NAVY : COLOR.TRANSPARENT;
      return <RectangleImage fill={fill} />;
    },
    headerClasses: 'table-status-block',
    classes: 'table-status-block',
    style: isESGFocus => {
      const fill = isESGFocus ? COLOR.NAVY : COLOR.TRANSPARENT;
      return {backgroundColor: fill};
    },
  },
  isIncludedCheckbox: {
    dataField: 'isIncluded',
    text: 'Include in Report',
    formatter: (isIncluded, {issue}) => <Field name={`issues.${issue.code}`} component={Checkbox} label={null} />,
    headerStyle: {
      fontSize: '1rem',
      lineHeight: '1rem',
      paddingBottom: '0.75rem',
      width: 70,
    },
  },
  codeAndName: {
    dataField: 'issue.code',
    text: 'ESG Issue',
    sort: true,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return ISSUE_OLD[b].sortOrder - ISSUE_OLD[a].sortOrder;
      }
      return ISSUE_OLD[a].sortOrder - ISSUE_OLD[b].sortOrder; // desc
    },
    formatter: (code, {issue}) => (
      <Fragment>
        {code} {issue.name}
        <TooltipLink label={null} className="ml-1">
          {issue.description}
        </TooltipLink>
      </Fragment>
    ),
    headerStyle: {width: 350},
  },
  percentChange: {
    dataField: 'percentChangeSortOrder',
    text: 'Change',
    sort: true,
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        return b - a;
      }
      return a - b; // desc
    },
    formatter: (percentChangeSortOrder, {issue, percentChange}) => (
      <IssueDataChangeDescription
        classes="font-weight-bold"
        includeIcon={true}
        includeNoun={false}
        issue={issue}
        percentChange={percentChange}
      />
    ),
    headerStyle: {textAlign: 'right', width: 100},
    style: {textAlign: 'right'},
  },
};

const getMetaIssuePeriodColumns = config => {
  return map(uniq(compact(config.periods)), (period, index) => {
    return {
      dataField: `period_${index}`,
      text: period,
      formatter: (nothing, {issue, periodData}) => (
        <span dangerouslySetInnerHTML={{__html: formatNumber(periodData[period]) + issue.unit}} />
      ),
      headerStyle: {width: 140},
    };
  });
};

// Row of drop-downs underneath the bar chart
const renderPeriods = ({fields: periods, availablePeriods}) => (
  <div className="bar-chart-legend-datasets" style={styles.barChartLegendDatasets}>
    {periods.map((period, index) => {
      const color = TIME_PERIOD_COLORS[index];

      // Create options list with key/value pairs and null option
      const options = [{label: 'None', value: ''}];
      each(availablePeriods, availablePeriod => {
        options.push({label: availablePeriod, value: availablePeriod});
      });

      return (
        <div className="bar-chart-legend-dataset" key={index}>
          <RectangleImage fill={color} />
          <Field
            name={period}
            component={SelectFormGroup}
            options={options}
            size="sm"
            subtle
            className="min-h-0"
            inputClassName="min-w-6r"
            disabled={!availablePeriods}
          />
        </div>
      );
    })}
  </div>
);

class ProgressReportDisplayComponent extends Component {
  render() {
    const {
      changeFormValueAndSave,
      className,
      config,
      details,
      distributionData,
      handleDeselectAllClick,
      handleFormChange,
      handleSelectAllClick,
      hasAnySelectedIssue,
      hasNoSelectedIssue,
      isAUMStatHidden,
      isCompaniesEngagedStatHidden,
      isCompaniesRespondedStatHidden,
      isManagersInComplianceStatHidden,
      isManagersWithESGPolicyStatHidden,
      isStatsHidden,
      metaIssues,
      options,
      stats,
      viewportWidth,
    } = this.props;

    const availablePeriods = get(options, 'availablePeriods');
    const isSubscribedToEmpowerChange = get(options, 'isSubscribedToEmpowerChange');
    const hasEnoughData = !(isEmpty(availablePeriods) || availablePeriods.length < 2);

    const header = (
      <div className="section text-white bg-navy-green-gradient">
        <div className="dashboard-container p-8">
          <div className="empowering-change-header">
            <div className="empowering-change-title">Empowering Change</div>
            <div className="empowering-change-plan-name">{details.name}</div>
            {!isStatsHidden && isSubscribedToEmpowerChange && hasEnoughData && (
              <EmpoweringChangeStats
                className="mt-12 mb-4"
                isAUMStatHidden={isAUMStatHidden}
                isCompaniesEngagedStatHidden={isCompaniesEngagedStatHidden}
                isCompaniesRespondedStatHidden={isCompaniesRespondedStatHidden}
                isManagersInComplianceStatHidden={isManagersInComplianceStatHidden}
                isManagersWithESGPolicyStatHidden={isManagersWithESGPolicyStatHidden}
                stats={stats}
              />
            )}
          </div>
        </div>
      </div>
    );

    if (!isSubscribedToEmpowerChange) {
      return (
        <Fragment>
          {header}
          <div className="dashboard-container p-8">
            <p style={styles.paragraph}>
              The Harmony Analytics Progress Report will provide you with great insights into how your organization is
              improving the world.
            </p>
            <p style={styles.paragraph}>
              To view your progress on your ESG issues you've selected as important and other issues you may want to
              include in a Progress Report, sign up for Empowering Change within{' '}
              <Link to={ROUTE.MANAGE_ESG.path()}>Manage ESG</Link>.
            </p>
            <Link className="btn btn-primary" to={ROUTE.MANAGE_ESG.path()}>
              Visit Manage ESG
            </Link>
          </div>
        </Fragment>
      );
    }

    if (!hasEnoughData) {
      // Prepare focus issue lists
      const focusIssueCategories = map(ISSUE_CATEGORIES, name => {
        return {
          name,
          issues: [],
        };
      });
      if (!isEmpty(options.focusIssues)) {
        each(ISSUES_OLD, issue => {
          if (indexOf(options.focusIssues, issue.code) >= 0) {
            find(focusIssueCategories, {name: issue.category}).issues.push(issue);
          }
        });
      }

      return (
        <Fragment>
          {header}
          <div className="dashboard-container p-8">
            <div style={styles.noDataContainer}>
              <p>
                The Harmony Analytics Progress Report will provide you with great insights into how your organization is
                improving the world.
              </p>
              <p>
                In the meantime, you can review your ESG focus. Please check back next quarter once your investment
                managers have uploaded more data to be analyzed.
              </p>
              <div className="bg-white p-8">
                <h3>Plan ESG Focus</h3>
                <div className="d-flex justify-content-between align-items-start">
                  <p>
                    {isEmpty(options.focusIssues) ? 'No' : 'The following'} ESG issues are marked as important to the
                    goals for your organization. You can update your ESG focus in your{' '}
                    <Link to={ROUTE.MANAGE_ESG.path()}>ESG Settings</Link>.
                  </p>
                  <Link className="btn btn-primary ml-8" to={ROUTE.MANAGE_ESG.path()}>
                    Update in Settings
                  </Link>
                </div>
                <div style={styles.focusIssues}>
                  {map(focusIssueCategories, issueCategory => {
                    if (issueCategory.issues.length === 0) return null;
                    return (
                      <div key={issueCategory.name} className="mb-8" style={styles.focusIssue}>
                        <h4 className="mb-4">{issueCategory.name}</h4>
                        {map(issueCategory.issues, ({code, name}) => {
                          return (
                            <div key={code}>
                              {code} {name}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    return (
      <form onChange={handleFormChange} className={className}>
        {header}
        <div className="dashboard-container p-8">
          <div className="d-flex align-items-center mb-8" style={styles.fontSizeSmall}>
            <h4 className="mb-0 flex-fill">Distribution of Company Ratings for Plan</h4>
            <Field
              name="progressReportIncludeCertification"
              component={Checkbox}
              label="Include manager certifications in report"
            />
            <TooltipLink className="ml-2" label={null}>
              By selecting this option, the printable report will show a tally of your investment managers that are
              committed to ESG issues.
            </TooltipLink>
          </div>

          <BarChart
            datasets={distributionData}
            datasetsVisible={map(distributionData, ({isHidden}) => !isHidden)}
            hasOverlap={false}
            labels={null}
            tooltipMode="change"
          />
          <BarChartLegend />

          <FieldArray name="periods" component={renderPeriods} availablePeriods={availablePeriods} />

          <h4 className="mb-2">Measuring Change</h4>
          <div className="d-flex justify-content-between align-items-center mb-10" style={styles.fontSizeSmall}>
            <div>
              <b>
                <VoidLink onClick={handleSelectAllClick} className={hasAnySelectedIssue ? ACTIVE_LINK_CLASSNAME : ''}>
                  Select All
                </VoidLink>
                <span className="mx-2">|</span>
                <VoidLink onClick={handleDeselectAllClick} className={hasNoSelectedIssue ? ACTIVE_LINK_CLASSNAME : ''}>
                  Deselect All
                </VoidLink>
              </b>
            </div>
            <div>
              {map(ISSUE_VIEW_MODES, viewMode => (
                <VoidLink
                  key={viewMode.key}
                  className={cn('ml-6 hover:text-decoration-none', {
                    'text-gray cursor-default': viewMode.key === config.issueViewMode,
                  })}
                  onClick={() => changeFormValueAndSave('issueViewMode', viewMode.key)}
                >
                  <Icon icon={viewMode.icon} className="mr-2" />
                  {viewMode.label}
                </VoidLink>
              ))}
            </div>
          </div>

          {config.issueViewMode === ISSUE_VIEW_MODE.CARD.key && (
            <div className="issue-card-grid">
              {map(metaIssues, ({isESGFocus, isIncluded, issue, periodData}) => {
                return (
                  <IssueCardWithProgress key={issue.code} issue={issue} periodData={periodData} isESGFocus={isESGFocus}>
                    <Fragment key="activator">
                      <div className="d-flex align-items-center">
                        {isIncluded && (
                          <div className="flex-fill text-light-gray small">
                            <Icon icon={faCheck} /> Included in Report
                          </div>
                        )}
                        <Field
                          name={`issues.${issue.code}`}
                          component={Checkbox}
                          label={isIncluded ? 'Remove' : 'Include in Report'}
                          className={cn('is-btn', isIncluded ? 'flex-grow-0' : 'flex-fill')}
                        />
                      </div>
                    </Fragment>
                  </IssueCardWithProgress>
                );
              })}
            </div>
          )}

          {config.issueViewMode === ISSUE_VIEW_MODE.LIST.key && (
            <BootstrapTable
              bordered={false}
              keyField="issue.code"
              data={metaIssues}
              columns={[
                metaIssueColumnDefinitions.isIncludedStatusBar,
                metaIssueColumnDefinitions.isIncludedCheckbox,
                metaIssueColumnDefinitions.codeAndName,
                ...(viewportWidth >= 1160 ? getMetaIssuePeriodColumns(config) : []),
                metaIssueColumnDefinitions.percentChange,
              ]}
            />
          )}
        </div>

        <div className="side-tabs d-print-none">
          {/* <VoidLink className="side-tab">
            <Icon icon={faQuestionCircle} />
            FAQs
          </VoidLink> */}
          <Link className="side-tab" to={ROUTE.PROGRESS_REPORT_PRINTABLE.path()} target="_blank">
            <Icon icon={faPrint} />
            Print
          </Link>
        </div>
      </form>
    );
  }
}

export const ProgressReportDisplay = compose(
  withViewportSize,
  withProps(({focusIssueCodes, initialValues, issueData}) => ({
    metaIssues: map(ISSUES_OLD, issue => {
      const percentChange = getPercentChangeInPeriodData(issueData[issue.code]);
      return {
        isESGFocus: focusIssueCodes[issue.code],
        isIncluded: initialValues.issues[issue.code],
        issue,
        periodData: issueData[issue.code],
        percentChange,
        percentChangeSortOrder: issue.isHigherWorse ? -percentChange : percentChange,
      };
    }),
  })),
)(ProgressReportDisplayComponent);
