import {ROUTE} from 'v2/constants/routes';
export class DSectorSelector {
  private issue: any;
  private action: any;

  constructor(issue: any, action: any) {
    this.issue = issue;
    this.action = action;
  }

  title(): string {
    return this.issue.name;
  }

  onClick(): void {
    this.action(this.issue);
  }
}
