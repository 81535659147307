import {sum, drop, get} from 'lodash';
import styled from 'styled-components/macro';
import {MANAGER_ROLES} from 'v2/constants/managers';
import {useRadioList} from 'v2/hooks/components/useRadioList';
import {AttachedWrapper, Container, FloatingWrapper} from 'v2/components/atoms/Containers';
import {ESGIntegrationView} from 'v2/components/organisms/EsgIntegrationView';
import {EsgAssociationsView} from 'v2/components/organisms/EsgAssociationsView';
import {WorkforceDiversityView} from 'v2/components/organisms/WorkforceDiversityView';
import {useFirmById} from 'v2/hooks/useFirmById';
import {isImpersonating} from 'app/utilities';
import {useWorkforceDiversityByFirmId} from 'v2/hooks/useWorkforceDiversityByFirmId';
import {RootStateOrAny, useSelector} from 'react-redux';

const Spacer = styled.div<{space?: string}>`
  height: ${({space}) => space ?? '1rem'};
`;

const Associations = styled.div`
  padding: 0 3rem;
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.colors.grey_1};
  height: 7rem;
  width: auto;
`;

export const UT3DashboardProfilePage = () => {
  const RADIO_LABELS = ['All', 'Board & C-level', 'Professionals', 'Support'];
  const RADIO_VALUES = [MANAGER_ROLES.ALL, MANAGER_ROLES.BOARD, MANAGER_ROLES.PROFESSIONALS, MANAGER_ROLES.SUPPORT];

  const {selected, handleChange} = useRadioList(RADIO_VALUES, 0);

  const firmId =
    useSelector((state: RootStateOrAny) =>
      get(state, isImpersonating() ? 'auth.impersonatedUser.firmId' : 'auth.firmId'),
    ) ?? 0;

  const {data: firm, isLoading: firmIsLoading} = useFirmById({firmId});

  const {data: workforceData, isLoading: workforceisLoading} = useWorkforceDiversityByFirmId({
    filter: selected,
    firmId,
  });

  const hasGenderData =
    sum(
      drop(
        workforceData.genderData().map(a => a[1]),
        1,
      ),
    ) > 0;
  const hasEthnicityData =
    sum(
      drop(
        workforceData.ethnicityData().map(a => a[1]),
        1,
      ),
    ) > 0;

  const isLoading = workforceisLoading && workforceisLoading;

  return (
    <Container>
      <AttachedWrapper>
        <ESGIntegrationView {...firm} isLoading={isLoading} />
        <Associations>
          <EsgAssociationsView associations={firm.esgAssociations} isLoading={firmIsLoading} />
        </Associations>
      </AttachedWrapper>
      <Spacer space="1rem" />
      <FloatingWrapper>
        <WorkforceDiversityView
          isLoading={isLoading}
          genderData={workforceData.genderData()}
          ethnicityData={workforceData.ethnicityData()}
          hasEthnicityData={hasEthnicityData}
          hasGenderedData={hasGenderData}
          radioLabels={RADIO_LABELS}
          radioValues={RADIO_VALUES}
          handleRadioChange={handleChange}
          workforceDiversity={firm.workforceDiversity}
          selected={selected}
        />
      </FloatingWrapper>
      <Spacer space="8rem" />
    </Container>
  );
};
