import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {isNil, map} from 'lodash';
import cn from 'classnames';

import {convertColorToClassName} from 'app/utilities';
import {BarChart} from 'app/components';

class BarChartComparison extends Component {
  constructor(props) {
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

    this.state = {datasetsVisible: [true]};
  }

  toggleBarChartAlternate(index) {
    const {datasetsVisible} = this.state;
    datasetsVisible[index] = !datasetsVisible[index];
    this.setState({datasetsVisible});
  }

  handleDistributionClick(props) {
    const {onDistributionClick} = this.props;
    onDistributionClick && onDistributionClick(props);
  }

  handleCheckboxChange(event) {
    this.toggleBarChartAlternate(event.target.value);
  }

  render() {
    const {className, compact, datasets, labels, selectedDataIndex, selectedDatasetIndex, tooltipMode} = this.props;
    const {datasetsVisible} = this.state;

    const isBarSelected = !(isNil(selectedDataIndex) && isNil(selectedDatasetIndex));

    const optionsClassName = cn('bar-chart-comparison-options', className, {'has-selection': isBarSelected});

    return (
      <Fragment>
        <div className={optionsClassName}>
          {map(datasets, ({color, label}, datasetIndex) => {
            const id = `bar-chart-toggle-${datasetIndex}`;
            const checkboxClassName = convertColorToClassName(color) || 'is-navy';
            return (
              <div key={datasetIndex} className={cn('custom-control custom-checkbox ml-8', checkboxClassName)}>
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id={id}
                  value={datasetIndex}
                  onChange={this.handleCheckboxChange}
                  checked={!!datasetsVisible[datasetIndex]}
                  disabled={!datasetIndex}
                />
                <label className="custom-control-label" htmlFor={id}>
                  {datasetIndex ? `vs ${label}` : label}
                </label>
              </div>
            );
          })}
        </div>
        <BarChart
          compact={compact}
          datasetsVisible={datasetsVisible}
          datasets={datasets}
          labels={labels}
          selectedDatasetIndex={selectedDatasetIndex}
          selectedDataIndex={selectedDataIndex}
          onDistributionClick={props => this.handleDistributionClick(props)}
          tooltipMode={tooltipMode}
          showPercentiles={true}
        />
      </Fragment>
    );
  }
}

BarChartComparison.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  datasets: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.arrayOf(PropTypes.string),
  onDistributionClick: PropTypes.func,
  selectedDataIndex: PropTypes.number,
  selectedDatasetIndex: PropTypes.number,
  tooltipMode: PropTypes.string,
};

export {BarChartComparison};
