import {isUndefined} from 'lodash';
import {Field} from 'react-final-form';
import {FlexContainer} from '../atoms/Containers';
import {AdminFormCard} from '../molecules/AdminFormCard';
import {TextFormGroup} from '../molecules/TextInput';
import {AdminFormCard as AdminFormCardTypes} from 'v2/utilities/types/components/AdminFormCard';
import {FileInputField} from './FileInputField';
import {DATA_STATUS} from 'v2/constants/dataStatus';

export interface AdminResourceCardProps extends Omit<AdminFormCardTypes, 'title' | 'children' | 'issueId'> {
  resourceName?: string;
  category?: string;
  fileName?: string;
  fileUrl?: string;
}

type AdminResourceFormValues = {
  resourceName?: string;
  category?: string;
};

export const AdminResourceCard = ({resourceName, category, fileName, fileUrl, ...rest}: AdminResourceCardProps) => {
  const validate = (values: AdminResourceFormValues) => {
    const errors: Partial<AdminResourceFormValues> = {};
    if (!values.resourceName) {
      errors.resourceName = 'Resource name is required';
    }

    if (!values.category) {
      //@ts-ignore
      errors.category = 'Category is required';
    }

    return errors;
  };

  const title = () => {
    if (isUndefined(resourceName)) {
      return 'Adding New Resource';
    }

    return `Editing ${resourceName}`;
  };

  return (
    <AdminFormCard validate={validate} title={title()} issueId={0} year={2020} {...rest}>
      <FlexContainer gap="3rem">
        <FlexContainer direction="column" items="flex-end" gap="0" flex={1} margin="0 0 2rem 0">
          <Field name="resourceName">
            {props => (
              <FlexContainer direction="column" flex={1} width="100%">
                <label htmlFor={props.input.name}>Resource Name</label>
                <TextFormGroup
                  input={props.input}
                  placeholder={DATA_STATUS.NONE}
                  meta={props.meta.touched && props.meta}
                />
              </FlexContainer>
            )}
          </Field>
        </FlexContainer>
        <FlexContainer flex={1}>
          <Field name="category">
            {props => (
              <FlexContainer direction="column" flex={1}>
                <label htmlFor={props.input.name}>Category</label>
                <TextFormGroup
                  input={props.input}
                  placeholder={DATA_STATUS.NONE}
                  meta={props.meta.touched && props.meta}
                />
              </FlexContainer>
            )}
          </Field>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer gap="3rem" flex={1}>
        <FlexContainer flex="1" direction="column">
          <label>File</label>
          <FileInputField accept="application/pdf, .pdf" name="fileName" saveFile={file => console.log(file)} />
        </FlexContainer>
      </FlexContainer>
    </AdminFormCard>
  );
};
