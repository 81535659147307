import {useParams} from 'react-router-dom';
import {useGetStrategyQuery} from 'v2/redux/typeormEndpoints';
import {Firm} from 'v2/utilities/types/typeOrm';
import EsgAssociationPriImage from 'app/assets/images/ESG-association_PRI.png';
import EsgAssociationGriImage from 'v2/assets/images/GRI_Association.svg';

export const useStrategyFirm = () => {
  const {strategyId = ''} = useParams<{strategyId?: string}>();

  const {isError, isLoading, isSuccess, data} = useGetStrategyQuery({strategyId});

  const strategy = data ?? {};
  const firm: Firm = strategy?.firm;

  const associations = [];

  if (firm?.hasPriAssociation) {
    associations.push(
      <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <img src={EsgAssociationPriImage} width="140px" height="26px" alt="pri" />
      </div>,
    );
  }

  if (firm?.hasGriAssociation) {
    associations.push(
      <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
        <img src={EsgAssociationGriImage} alt="gri" />
      </div>,
    );
  }

  if (firm?.otherEsgAssociations) {
    associations.push(firm?.otherEsgAssociations);
  }

  const formattedData: {
    hasPolicy?: boolean;
    hasComittee?: boolean;
    isEngaged?: boolean;
    esgApproach?: string;
    hasScreening?: boolean;
    hasVetting?: boolean;
    hasVoting?: boolean;
    hasScaling?: boolean;
    isSelling?: boolean;
    isMonitoring?: boolean;
    esgMeasurement?: string;
    esgAssociations?: any;
    workforceDiversity?: any;
  } = {
    hasPolicy: strategy?.firm?.hasEsgPolicy ?? false,
    hasComittee: strategy?.firm?.hasESGCommitteeOfficer ?? false,
    isEngaged: strategy?.user?.firmId === strategy?.firmId ? true : false,
    hasVetting: strategy?.firm?.hasScreeningConsideration ?? false,
    hasVoting: strategy?.firm?.hasVotingConsideration ?? false,
    hasScaling: strategy?.firm?.hasScalingConsideration ?? false,
    isSelling: strategy?.firm?.hasSellingConsideration ?? false,
    hasScreening: strategy?.firm?.hasScreeningConsideration ?? false,
    isMonitoring: strategy?.firm?.hasMonitoringConsideration ?? false,
    esgApproach:
      strategy?.firm?.esgApproach ??
      'Manager has not specified their ESG Approach. If you would want to learn about their approach please reach out to your key contact.',
    esgMeasurement:
      strategy?.firm?.esgMeasurement ??
      'Manager has not specified how they measure ESG. If you would want to learn about their approach please reach out to your key contact.',
    esgAssociations: associations.length > 0 ? associations : ['Not currently affiliated with ESG Associations'],
    workforceDiversity:
      strategy?.firm?.diversityObjectives ??
      'Manager has not specified their Diversity Objectives. If you would want to learn about their approach please reach out to your key contact.',
  };

  return {
    data: formattedData,
    isError,
    isLoading,
    isSuccess,
  };
};
