import styled from 'styled-components/macro';
import {FileText} from '../atoms/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoDataPlacholder = () => {
  return (
    <Container>
      <FileText size={72} />
      <p style={{marginTop: '27px'}}>Manager has not provided Workforce Diversity Information</p>
    </Container>
  );
};
