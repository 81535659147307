import {ROUTE} from './routes';

export const ONBOARDING_STEPS = {
  UserType1: {
    ACCOUNT: {
      key: '1',
      onboardingStepName: 'ACCOUNT',
      route: ROUTE.UT1.ONBOARDING.ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      onboardingStepName: 'PROFILE',
      route: ROUTE.UT1.ONBOARDING.PROFILE,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: '3',
      onboardingStepName: 'PLAN',
      route: ROUTE.UT1.ONBOARDING.PLAN,
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: '3.1',
      onboardingStepName: 'ESG',
      route: ROUTE.UT1.ONBOARDING.ESG_STRATEGY,
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
      subItem: true,
    },
    MANAGERS: {
      key: '3.2',
      onboardingStepName: 'MANAGERS',
      route: ROUTE.UT1.ONBOARDING.INVESTMENT_MANAGERS,
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
      subItem: true,
    },
    FINALIZE: {
      key: '4',
      onboardingStepName: 'FINALIZE',
      route: ROUTE.UT1.ONBOARDING.FINALIZE,
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
  UserType2: {
    ACCOUNT: {
      key: '1',
      route: '',
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      route: '',
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: '3',
      route: '',
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: '4',
      route: '',
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
    },
    MANAGERS: {
      key: '5',
      route: '',
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
    },
    FINALIZE: {
      key: '6',
      route: '',
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
  UserType3: {
    ACCOUNT: {
      key: '1',
      route: ROUTE.UT3.ONBOARDING.ACCOUNT,
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      route: ROUTE.UT3.ONBOARDING.PROFILE,
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    MANAGER: {
      key: '3',
      route: ROUTE.UT3.ONBOARDING.MANAGER,
      shortTitle: 'Manager',
      isComplete: false,
      stepOrder: 3,
    },
    MANAGER_INFORMATION: {
      key: '3.1',
      route: ROUTE.UT3.ONBOARDING.MANAGER_INFORMATION,
      shortTitle: 'Manager Information',
      isComplete: false,
      isSubStep: true,
      stepOrder: 4,
      subItem: true,
    },
    ESG_INTGRATION: {
      key: '3.2',
      route: ROUTE.UT3.ONBOARDING.ESG_INTEGRATION,
      shortTitle: 'ESG Integration',
      isComplete: false,
      isSubStep: true,
      stepOrder: 5,
      subItem: true,
    },
    ESG_ASSOCIATIONS: {
      key: '3.3',
      route: ROUTE.UT3.ONBOARDING.ESG_ASSOCIATIONS,
      shortTitle: 'ESG Associations',
      isComplete: false,
      isSubStep: true,
      stepOrder: 6,
      subItem: true,
    },
    FINALIZE: {
      key: '4',
      route: ROUTE.UT3.ONBOARDING.SUCCESS,
      shortTitle: 'Success',
      isComplete: false,
      stepOrder: 7,
    },
  },
  Admin: {
    ACCOUNT: {
      key: '1',
      route: '',
      shortTitle: 'Account',
      isComplete: false,
      stepOrder: 1,
    },
    PROFILE: {
      key: '2',
      route: '',
      shortTitle: 'Profile',
      isComplete: false,
      stepOrder: 2,
    },
    PLAN: {
      key: '3',
      route: '',
      shortTitle: 'Plan',
      isComplete: false,
      stepOrder: 3,
    },
    ESG: {
      key: '4',
      route: '',
      shortTitle: 'ESG',
      isComplete: false,
      stepOrder: 4,
    },
    MANAGERS: {
      key: '5',
      route: '',
      shortTitle: 'Managers',
      isComplete: false,
      stepOrder: 5,
    },
    FINALIZE: {
      key: '6',
      route: '',
      shortTitle: 'Finalize',
      isComplete: false,
      stepOrder: 7,
    },
  },
};
