export enum Position {
  Top = 'up',
  Bottom = 'down',
  Left = 'left',
  Right = 'right',
}

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface TooltipProps {
  position?: Position;
  size?: Size;
  backgroundColor?: string;
  color?: string;
  variant?: 'default' | 'outline';
}
