import {get, isEmpty, unionBy} from 'lodash';
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {hasError: false};

// ACTION
export const ACTION = {
  CLIENT_HOLDINGS_CREATE_STAGING_UPLOAD_REQUEST: 'create-holdings-upload/request/silent',
  CLIENT_HOLDINGS_CREATE_STAGING_UPLOAD_RECEIVE: 'create-holdings-upload/receive/silent',
  CLIENT_HOLDINGS_GET_UPLOADS_REQUEST: 'CLIENT_HOLDINGS_UPLOADS_REQUEST',
  CLIENT_HOLDINGS_GET_UPLOADS_RECEIVE: 'CLIENT_HOLDINGS_GET_UPLOADS_RECEIVE',
  CLIENT_HOLDINGS_SAVE_UPLOADS_REQUEST: 'CLIENT_HOLDINGS_SAVE_UPLOADS_REQUEST',
  CLIENT_HOLDINGS_SAVE_UPLOADS_RECEIVE: 'CLIENT_HOLDINGS_SAVE_UPLOADS_RECEIVE',
};

const createStagingUploadRequest = createAction(ACTION.CLIENT_HOLDINGS_CREATE_STAGING_UPLOAD_REQUEST);
const createStagingUploadReceive = createAction(ACTION.CLIENT_HOLDINGS_CREATE_STAGING_UPLOAD_RECEIVE);
const getUploadsRequest = createAction(ACTION.CLIENT_HOLDINGS_GET_UPLOADS_REQUEST);
const getUploadsReceive = createAction(ACTION.CLIENT_HOLDINGS_GET_UPLOADS_RECEIVE);
const saveUploadsRequest = createAction(ACTION.CLIENT_HOLDINGS_SAVE_UPLOADS_REQUEST);
const saveUploadsReceive = createAction(ACTION.CLIENT_HOLDINGS_SAVE_UPLOADS_RECEIVE);

const api = {
  createStagingUpload: fileKey => {
    return fetchAuthJSON('stagingClientHoldingsUpload', {
      method: 'post',
      body: JSON.stringify({fileKey}),
    });
  },
  getUploads: () => {
    return fetchAuthJSON('clientHoldingsUpload', {method: 'get'});
  },
  saveUploads: (date, fileKey) => {
    return fetchAuthJSON('clientHoldingsUpload', {
      method: 'post',
      body: JSON.stringify({date, fileKey}),
    });
  },
};

export function createStagingUpload(fileKey) {
  return dispatch => {
    dispatch(createStagingUploadRequest({fileKey}));
    return dispatch(createStagingUploadReceive(api.createStagingUpload(fileKey)));
  };
}

export function getUploads() {
  return dispatch => {
    dispatch(getUploadsRequest());
    return dispatch(getUploadsReceive(api.getUploads()));
  };
}

export function saveUploads(date, fileKey) {
  return dispatch => {
    dispatch(saveUploadsRequest({date, fileKey}));
    return dispatch(saveUploadsReceive(api.saveUploads(date, fileKey)));
  };
}

// REDUCER
export const clientHoldingsUploadData = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  // console.log({ hasError, payload, type });
  switch (type) {
    case ACTION.CLIENT_HOLDINGS_CREATE_STAGING_UPLOAD_REQUEST:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: true,
          hasStagingUploadError: false,
          isFetching: true,
        },
      };
    case ACTION.CLIENT_HOLDINGS_CREATE_STAGING_UPLOAD_RECEIVE:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: false,
          hasStagingUploadError: hasError,
          isFetching: false,
        },
      };
    case ACTION.CLIENT_HOLDINGS_GET_UPLOADS_RECEIVE:
      return assignMergedState(hasError ? {error: payload} : {uploads: {data: payload}});
    case ACTION.CLIENT_HOLDINGS_SAVE_UPLOADS_RECEIVE: {
      return assignMergedState(hasError ? {error: payload} : state);
    }
    default:
      return state;
  }
};
