import {get, isEmpty, unionBy} from 'lodash';
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {hasError: false};

// ACTION
export const ACTION = {
  CREATE_STAGING_UPLOAD_REQUEST: 'admin-upload-harmony-data/create-staging-upload/request/silent',
  CREATE_STAGING_UPLOAD_RECEIVE: 'admin-upload-harmony-data/create-staging-upload/receive/silent',
  GET_UPLOADS_REQUEST: 'HARMONY_DATA_GET_UPLOADS_REQUEST',
  GET_UPLOADS_RECEIVE: 'HARMONY_DATA_GET_UPLOADS_RECEIVE',
  SAVE_UPLOADS_REQUEST: 'HARMONY_DATA_SAVE_UPLOADS_REQUEST',
  SAVE_UPLOADS_RECEIVE: 'HARMONY_DATA_SAVE_UPLOADS_RECEIVE',
};

const createStagingUploadRequest = createAction(ACTION.CREATE_STAGING_UPLOAD_REQUEST);
const createStagingUploadReceive = createAction(ACTION.CREATE_STAGING_UPLOAD_RECEIVE);
const getUploadsRequest = createAction(ACTION.GET_UPLOADS_REQUEST);
const getUploadsReceive = createAction(ACTION.GET_UPLOADS_RECEIVE);
const saveUploadsRequest = createAction(ACTION.SAVE_UPLOADS_REQUEST);
const saveUploadsReceive = createAction(ACTION.SAVE_UPLOADS_RECEIVE);

const api = {
  createStagingUpload: fileKey => {
    return fetchAuthJSON('stagingSecurityDataAndRatingUpload', {
      method: 'post',
      body: JSON.stringify({fileKey}),
    });
  },
  getUploads: () => {
    return fetchAuthJSON('securityDataAndRatingUpload', {method: 'get'});
  },
  saveUploads: (date, fileKey) => {
    return fetchAuthJSON('securityDataAndRatingUpload', {
      method: 'post',
      body: JSON.stringify({date, fileKey}),
    });
  },
};

export function createStagingUpload(fileKey) {
  return dispatch => {
    dispatch(createStagingUploadRequest({fileKey}));
    return dispatch(createStagingUploadReceive(api.createStagingUpload(fileKey)));
  };
}

export function getUploads() {
  return dispatch => {
    dispatch(getUploadsRequest());
    return dispatch(getUploadsReceive(api.getUploads()));
  };
}

export function saveUploads(date, fileKey) {
  return dispatch => {
    dispatch(saveUploadsRequest({date, fileKey}));
    return dispatch(saveUploadsReceive(api.saveUploads(date, fileKey)));
  };
}

// REDUCER
export const adminUploadHarmonyData = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.CREATE_STAGING_UPLOAD_REQUEST:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: true,
          hasStagingUploadError: false,
          isFetching: true,
        },
      };
    case ACTION.CREATE_STAGING_UPLOAD_RECEIVE:
      return {
        ...state,
        stagingUpload: {
          ...state.stagingUpload,
          isCreatingStagingUpload: false,
          hasStagingUploadError: hasError,
          isFetching: false,
        },
      };
    case ACTION.GET_UPLOADS_RECEIVE:
      return assignMergedState(hasError ? {error: payload} : {uploads: {data: payload}});
    case ACTION.SAVE_UPLOADS_RECEIVE: {
      if (hasError) {
        return assignMergedState({error: payload});
      }

      // Confirm that an upload completed processing and was returned
      if (isEmpty(payload) || payload.isProcessing) {
        return state;
      }

      // Get clone of the uploads within state
      const uploads = get(state, 'uploads.data', []).slice(0);

      // Merge saved upload with uploads, by using unionBy to do an upsert
      const mergedUploads = unionBy([payload], uploads, 'id');

      return assignMergedState({uploads: {data: mergedUploads}});
    }
    default:
      return state;
  }
};
