import {Theme} from '../theme';

export const HumanRightsPolicy = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 21" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.97542 9.32385H14.253C14.6127 9.32385 14.9042 9.03228 14.9042 8.67265V7.27764C14.9042 6.31464 14.543 5.43456 13.9492 4.76526C13.2704 5.57579 12.2517 6.09241 11.1142 6.09241C9.97674 6.09241 8.95804 5.57579 8.27923 4.76526C7.68542 5.43456 7.32422 6.31464 7.32422 7.27764V8.67265C7.32422 9.03228 7.61578 9.32385 7.97542 9.32385Z" />
      <path d="M11.1137 4.78994C12.4343 4.78994 13.5087 3.71559 13.5087 2.39499C13.5087 1.0744 12.4343 0 11.1137 0C9.79315 0 8.71875 1.0744 8.71875 2.39499C8.71875 3.71559 9.79315 4.78994 11.1137 4.78994Z" />
      <path d="M6.91195 10.4744C6.23778 9.8002 5.79592 9.14899 5.12175 9.82316C4.44758 10.4973 4.44758 11.5904 5.12175 12.2646L6.69171 13.8347C6.94603 14.089 6.94603 14.5013 6.69171 14.7556C6.4374 15.0099 6.02506 15.01 5.77078 14.7556L2.98077 11.9656C2.85865 11.8435 2.79001 11.6778 2.79001 11.5051L1.96127 5.9713C1.96127 5.20058 1.33802 4.57629 0.566263 4.57629L0.651203 4.57629C0.291565 4.57629 0 4.86786 0 5.2275V13.5976C0 13.7704 0.0685933 13.9359 0.190759 14.0581L4.18506 18.0524V19.8752C4.18506 20.2348 4.47663 20.5264 4.83626 20.5264H9.71881C10.0784 20.5264 10.37 20.2348 10.37 19.8752L9.71881 14.9958C9.71881 13.8904 9.28832 12.851 8.50675 12.0695L6.91195 10.4744Z" />
      <path d="M15.2257 10.7343C15.8999 10.0601 16.3418 9.40888 17.0159 10.0831C17.6901 10.7572 17.6901 11.8503 17.0159 12.5245L15.446 14.0946C15.1917 14.3489 15.1917 14.7612 15.446 15.0155C15.7003 15.2698 16.1126 15.2699 16.3669 15.0155L19.1569 12.2255C19.279 12.1033 19.3477 11.9377 19.3477 11.765L20.1764 6.23119C20.1764 5.46047 20.7997 4.83618 21.5714 4.83618L21.4865 4.83618C21.8461 4.83618 22.1377 5.12775 22.1377 5.48738V13.8575C22.1377 14.0302 22.0691 14.1958 21.9469 14.318L17.9526 18.3123V20.1351C17.9526 20.4947 17.6611 20.7863 17.3014 20.7863H12.4189C12.0592 20.7863 11.7677 20.4947 11.7677 20.1351L12.4189 15.2557C12.4189 14.1502 12.8494 13.1109 13.6309 12.3294L15.2257 10.7343Z" />
    </svg>
  );
};
