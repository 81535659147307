import {useEffect, useState, FC} from 'react';
import {ErrorField} from './ErrorField';
import {ParagraphS} from '../atoms/Typeface';
import {ITextFormGroupProps, TextInput} from './TextInput';

/**
 * Function that accepts a phone number and returns
 * object with the prefix and number
 */
const parsePhoneNumber = (phoneNumber: string = '') => {
  const areaCode = phoneNumber.substring(0, 3);
  const number = phoneNumber.substring(3);
  return {areaCode, number};
};

/**
 * Function that accepts an object with the prefix and number
 * and returns the full phone number
 */
const formatPhoneNumber = ({areaCode, phoneNumber}: {areaCode: string; phoneNumber: string}) => {
  return `${areaCode}${phoneNumber}`;
};

/**
 * An input field for phone numers using separate
 * TextFormGroup components for the prefix and the numbe
 */

export const PhoneFormGroup: FC<ITextFormGroupProps> = ({
  input: {onChange, name, ...input},
  label,
  labelHint,
  addOnPrepend,
  hideError,
  addOnAppend,
  totalRows,
  meta: {error, submitError, invalid},
  ...rest
}) => {
  const [areaCode, setAreaCode] = useState(parsePhoneNumber(input.value).areaCode);
  const [phoneNumber, setNumber] = useState(parsePhoneNumber(input.value).number);
  const handleChange = (value: string) => {
    setAreaCode(value.replace(/\D/g, '').substring(0, 3));
  };

  const handleChange2 = (value: string) => {
    setNumber(value.replace(/\D/g, '').substring(0, 7));
  };

  useEffect(() => {
    onChange(formatPhoneNumber({areaCode, phoneNumber}));
  }, [areaCode, phoneNumber]);

  useEffect(() => {
    setAreaCode(parsePhoneNumber(input.value).areaCode);
    setNumber(parsePhoneNumber(input.value).number);
  }, [totalRows]);

  return (
    <div style={{flex: 1}}>
      {/* hide the actual input field */}
      <TextInput maxLength={3} name={name} {...rest} {...input} style={{display: 'none'}} />
      <div>
        {label && <ParagraphS as="label">{label}</ParagraphS>}
        {labelHint && <span className="label-hint">{labelHint}</span>}
      </div>
      <div style={{display: 'flex', flexDirection: 'row', gap: '0.75rem'}}>
        <div style={{minWidth: '4.5rem'}}>
          <TextInput placeholder="000" onChange={handleChange} style={{width: '100%'}} value={areaCode} />
        </div>
        <div style={{width: '100%', minWidth: '7rem'}}>
          <TextInput placeholder="Number" onChange={handleChange2} style={{width: '100%'}} value={phoneNumber} />
        </div>
      </div>
      {!hideError && !addOnPrepend && !addOnAppend && <ErrorField error={invalid && (error || submitError)} />}
    </div>
  );
};
