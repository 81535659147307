import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {branch, compose, lifecycle, renderNothing, withHandlers, withProps, withState} from 'recompose';
import get from 'lodash/get';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faBookmark as faBookmarkSolid} from '@fortawesome/free-solid-svg-icons';

import {CONFIG, FORM_MESSAGE, PORTFOLIO_MANAGEMENT_TYPE, ROUTE} from 'app/constants';
import {
  Button,
  ComplianceManagerBookmarks,
  ComplianceManagerSecuritySearch,
  ComplianceManagerUploadForm,
  ComplianceSecuritiesListing,
  ComplianceStatusBar,
  ModalWithButton,
  SideDrawer,
  VoidLink,
} from 'app/components';
import {withBodyCssClass, withViewportSize} from 'app/utilities';
import {getAuthJwt} from 'app/utilities/tokenFunctions';
import {createPortfolio, getPortfolio, resetTrialPortfolio} from 'app/redux/complianceManager';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import faSave from 'app/fontawesome-pro-light/faSave';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import faBookmark from 'app/fontawesome-pro-light/faBookmark';
import faDownload from 'app/fontawesome-pro-light/faDownload';
import faUndo from 'app/fontawesome-pro-light/faUndo';
import {toastError, toastSuccess} from 'app/utilities/toast';

const styles = {
  introductionLarge: {
    maxWidth: '45rem',
  },
  layoutContainer: {
    margin: '0 auto',
    maxWidth: 1200,
  },
};

const ComplianceManager = compose(
  withBodyCssClass('has-absolute-header'),
  withViewportSize,
  withProps(props => ({
    isViewportLarge: props.viewportWidth >= 1100,
    strategyId: get(props, 'match.params.strategyId'),
  })),
  connect(({complianceManager}) => ({...complianceManager}), {
    createPortfolio,
    getPortfolio,
    openSimpleModal,
    resetTrialPortfolio,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getPortfolio(this.props.strategyId);
    },
  }),
  branch(({portfolio = {}}) => !portfolio.hasFetched, renderNothing),
  withProps(({portfolio}) => ({
    planSponsor: get(portfolio, 'data.planSponsor'),
    portfolio: get(portfolio, 'data'),
    securities: get(portfolio, 'data.securities'),
    isHarmonyIndex: !!get(portfolio, 'data.marketIndex.isHarmonyIndex'),
    isCreatingStagingPortfolio: get(portfolio, 'isCreatingStagingPortfolio'),
    hasStagingPortfolioError: get(portfolio, 'hasStagingPortfolioError'),
    isSortFrozen: get(portfolio, 'isSortFrozen'),
  })),
  branch(
    ({isHarmonyIndex, portfolio}) => {
      const portfolioManagementType = get(portfolio, 'portfolioManagementType');
      return !isHarmonyIndex && portfolioManagementType === PORTFOLIO_MANAGEMENT_TYPE.PASSIVE;
    },
    () => () =>
      (
        <div className="p-content-lg">
          <div className="card is-white mx-auto" style={{maxWidth: 800}}>
            <h1>Portfolio Center</h1>
            <p>This strategy does not yet qualify to use the Portfolio Center feature.</p>
            <p>
              Change this strategy to use a Harmony Index and your firm can then use the Portfolio Center to ensure this
              portfolio is ethically invested, in a way that represents the spirit of the ESG policies set by your
              client.
            </p>
          </div>
        </div>
      ),
  ),
  branch(
    ({portfolio, securities}) => {
      const isTrial = get(portfolio, 'isTrial');
      return !isTrial && (!securities || securities.length === 0);
    },
    () =>
      ({strategyId}) =>
        (
          <div className="p-content-lg">
            <div className="card is-white mx-auto" style={{maxWidth: 800}}>
              <h1>Portfolio Center</h1>
              <p>
                This strategy does not yet have data to display within the Portfolio Center. Please contact our
                investment manager support team for more information.
              </p>
              <p>If you have any questions or need any assistance, please contact us.</p>
              <Link to={ROUTE.CONTACT.path()} className="btn btn-primary btn-lg align-self-start">
                Contact Us
              </Link>
            </div>
          </div>
        ),
  ),
  withHandlers({
    confirmResetTrialPortfolio: props => () => {
      props.openSimpleModal({
        title: 'Reset to Last Uploaded Portfolio?',
        message:
          'Resetting your current portfolio to the last uploaded portfolio will remove any' +
          ' changes you have made, and will clear any companies you have added to' +
          ' the ledger. Do you want to proceed?',
        buttons: [
          {
            label: 'Cancel',
            close: true,
          },
          {
            className: 'btn-red ml-auto',
            label: 'Yes, Reset to Last Uploaded Portfolio',
            clickHandler: () => {
              props
                .resetTrialPortfolio(props.strategyId)
                .then(response => {
                  if (response.hasError) throw response.error;
                })
                .catch(() => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE));
            },
            close: true,
          },
        ],
      });
    },
    onSubmit: ownProps => (values, onSuccess) => {
      ownProps
        .createPortfolio(ownProps.strategyId, values.strategyTrialUpload[0].name)
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('The Current Portfolio has uploaded successfully.');

          onSuccess();
        })
        .catch(() => {
          toastError(`
            There was an unexpected error. Please try submitting again or try uploading
            a new file. If you continue to have issues, please contact us for further assistance.
          `);
        });
    },
    onExportPortfolioModal: ownProps => () => {
      const exportTrialPortfolioRoute = `${CONFIG.API_URL}/complianceManager/exportTrialPortfolio/`;
      const downloadLink = `${exportTrialPortfolioRoute}${ownProps.strategyId}?token=${getAuthJwt()}`;
      ownProps.dispatch(
        openSimpleModal({
          title: 'Export Current Portfolio',
          message:
            'Please upload this profile to Harmony Analytics once you have executed your trades for this portfolio',
          buttons: [
            {
              label: 'Cancel',
              close: true,
            },
            {
              className: 'btn-red ml-auto',
              label: 'Download Current Portfolio',
              href: downloadLink,
              isDownload: true,
              target: '_blank',
              close: true,
            },
          ],
        }),
      );
    },
    onExportHarmonyIndexModal: ownProps => () => {
      const exportTrialPortfolioRoute = `${CONFIG.API_URL}/marketIndex/exportByStrategy/`;
      const downloadLink = `${exportTrialPortfolioRoute}${ownProps.strategyId}?token=${getAuthJwt()}`;
      ownProps.dispatch(
        openSimpleModal({
          title: 'Export Harmony Index',
          message:
            'Please upload this profile to Harmony Analytics once you have executed your trades for your portfolio',
          buttons: [
            {
              label: 'Cancel',
              close: true,
            },
            {
              className: 'btn-red ml-auto',
              label: 'Download Harmony Index',
              href: downloadLink,
              isDownload: true,
              target: '_blank',
              close: true,
            },
          ],
        }),
      );
    },
  }),
  withState('searchSideDrawer', 'setSearchSideDrawer'),
  withHandlers({
    exposeSearchSideDrawerHandlers:
      ({setSearchSideDrawer}) =>
      e =>
        setSearchSideDrawer(e),
    openSearchSideDrawer:
      ({searchSideDrawer}) =>
      () => {
        if (searchSideDrawer) {
          searchSideDrawer.open();
          document.getElementById('complianceManagerSecuritySearchInput').focus();
        }
      },
  }),
  withState('bookmarkSideDrawer', 'setBookmarkSideDrawer'),
  withHandlers({
    exposeBookmarkSideDrawerHandlers:
      ({setBookmarkSideDrawer}) =>
      e =>
        setBookmarkSideDrawer(e),
    openBookmarkSideDrawer:
      ({bookmarkSideDrawer}) =>
      () => {
        if (bookmarkSideDrawer) {
          bookmarkSideDrawer.open();
        }
      },
  }),
)(
  ({
    confirmResetTrialPortfolio,
    exposeBookmarkSideDrawerHandlers,
    exposeSearchSideDrawerHandlers,
    hasStagingPortfolioError,
    isCreatingStagingPortfolio,
    isHarmonyIndex,
    isSortFrozen,
    isViewportLarge,
    onExportPortfolioModal,
    onExportHarmonyIndexModal,
    onSubmit,
    openBookmarkSideDrawer,
    openSearchSideDrawer,
    planSponsor,
    portfolio,
    securities,
    strategyId,
  }) => (
    <div>
      {!isHarmonyIndex && (
        <div className="p-content-lg">
          <div style={styles.layoutContainer}>
            <h1>{ROUTE.STRATEGY_COMPLIANCE_MANAGER.title}</h1>
            <div style={isViewportLarge ? styles.introductionLarge : null}>
              {portfolio.isTrial ? (
                <p className="mb-2">
                  Once the current portfolio is in compliance, you can export it. Note that any securities with a O%
                  weight will not show in the exported file. The current portfolio will be reset at 11:59pm US Eastern
                  Time.
                </p>
              ) : (
                <p className="mb-2">
                  To assist you in pre-trade clearance, you can see how the current portfolio is performing for this
                  strategy compared to the ESG policies set forth by {planSponsor.name}. You can also upload a trial
                  portfolio to test adding and removing securities from the portfolio, as well as adjusting the weights
                  for each security.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {!isHarmonyIndex && (
        <ComplianceStatusBar
          classes="mb-6"
          containerStyle={styles.layoutContainer}
          lastUploadedCompliance={portfolio.compliance}
          lastUploadedPolicyViolations={portfolio.policyViolation}
          trialCompliance={portfolio.trialCompliance}
          trialPolicyViolations={portfolio.trialPolicyViolation}
        />
      )}
      <div className="p-content-lg bg-off-white">
        <div style={styles.layoutContainer}>
          {portfolio.isMaxRecordsExceeded && (
            <div className="alert alert-danger mb-10">
              <Icon icon={faExclamationTriangle} className="mr-2" />
              In order to get the most use of the Portfolio Center, we have limited your portfolio to the 500 most
              relevant securities.
            </div>
          )}
          {portfolio.isTrial ? (
            <div className="mb-6">
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="mb-2">Modify Current Portfolio</h2>
                <div className="small text-gray">
                  <Icon icon={faSave} className="mr-2" />
                  Last Auto Saved at {moment(portfolio.savedAt).format('h:mm A')}
                </div>
              </div>
              <div className="d-flex flex-wrap align-items-start justify-content-between small">
                <strong className="flex-grow-100 mr-4 mb-2">
                  Last Upload on {moment(portfolio.lastUploadedAt).format('MMM D, YYYY')}
                  {' at '}
                  {moment(portfolio.lastUploadedAt).format('h:mm A')}
                </strong>
                <ul className="nav is-compact is-flush justify-content-between flex-grow-1 mb-2">
                  <li className="nav-item">
                    <VoidLink className="nav-link" onClick={openSearchSideDrawer}>
                      <Icon icon={faSearch} className="mr-2" />
                      {isViewportLarge ? 'Search for a Company to Add' : 'Find & Add Company'}
                    </VoidLink>
                  </li>
                  <li className="nav-item">
                    <VoidLink onClick={openBookmarkSideDrawer} className="nav-link">
                      <Icon icon={faBookmark} className="mr-2" />
                      {isViewportLarge ? 'Add Bookmarked Company' : 'Bookmarked Companies'}
                    </VoidLink>
                  </li>
                  <li className="nav-item">
                    <VoidLink onClick={onExportPortfolioModal} className="nav-link">
                      <Icon icon={faDownload} className="mr-2" />
                      <strong>{isViewportLarge ? 'Export Current Portfolio' : 'Export Portfolio'}</strong>
                    </VoidLink>
                  </li>
                  <li className="nav-item">
                    <VoidLink onClick={confirmResetTrialPortfolio} className="nav-link text-danger">
                      <Icon icon={faUndo} className="mr-2" />
                      {isViewportLarge ? 'Reset to Last Uploaded Portfolio' : 'Reset Portfolio'}
                    </VoidLink>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-start justify-content-between">
              <div>
                <h2 className="mb-2">Last Uploaded Portfolio</h2>
                <h4 className="futura text-uppercase font-weight-normal">
                  {moment(portfolio.lastUploadedAt).format('MMMM D, YYYY')}
                  {' at '}
                  {moment(portfolio.lastUploadedAt).format('h:mm A')}
                </h4>
              </div>
              {isHarmonyIndex ? (
                <Button name="downloadHarmonyIndex" onClick={onExportHarmonyIndexModal}>
                  Download Harmony Index
                </Button>
              ) : (
                <ModalWithButton
                  name="uploadTrialPortfolioModal"
                  buttonLabel="Upload Current Portfolio"
                  header="Upload Current Portfolio"
                  bodyClasses="bg-almost-white pt-4"
                >
                  {({handleCloseClick}) => (
                    <ComplianceManagerUploadForm
                      hasStagingPortfolioError={hasStagingPortfolioError}
                      isCreatingStagingPortfolio={isCreatingStagingPortfolio}
                      onCancel={handleCloseClick}
                      onSubmit={values => onSubmit(values, handleCloseClick)}
                    />
                  )}
                </ModalWithButton>
              )}
            </div>
          )}
          <ComplianceSecuritiesListing data={securities} isEditable={portfolio.isTrial} isSortFrozen={isSortFrozen} />
        </div>
      </div>
      {portfolio.isTrial && (
        <Fragment>
          <SideDrawer title="Search for Companies" titleIcon={faSearch} exposeHandlers={exposeSearchSideDrawerHandlers}>
            <ComplianceManagerSecuritySearch strategyId={strategyId} />
          </SideDrawer>
          <SideDrawer
            title="Bookmarked Companies"
            titleIcon={faBookmarkSolid}
            exposeHandlers={exposeBookmarkSideDrawerHandlers}
            footer={
              <Link className="btn btn-outline" to={ROUTE.STRATEGY_COMPANIES.path(strategyId)}>
                Find More Companies
              </Link>
            }
          >
            <ComplianceManagerBookmarks strategyId={strategyId} />
          </SideDrawer>
        </Fragment>
      )}
    </div>
  ),
);

export {ComplianceManager};
