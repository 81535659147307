import {useLocation} from 'react-router-dom';
import {Callback, Middleware} from './types';
import {UT3DashboardTabsCard} from 'v2/components/organisms/TabsCards';
import {Container} from 'v2/components/atoms/Containers';

const Layout: React.FC<{}> = ({children}) => {
  const {pathname} = useLocation();

  return (
    <>
      {pathname.includes('dashboard') && <UT3DashboardTabsCard />}
      <Container>{children}</Container>
    </>
  );
};

export const ut3DashboardHeader: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
