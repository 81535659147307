import React from 'react';
import {branch, compose, lifecycle, renderNothing, withProps} from 'recompose';
import {connect} from 'react-redux';

import {ROUTE} from 'app/constants';
import {withUser} from 'app/utilities';
import {fetchCurrentPlanSponsorIfNeeded} from 'app/redux/planSponsor';
import {Alert} from 'app/components';

const renderErrorMessage = () => () => <Alert color="warning">Sorry, there was a problem loading this page.</Alert>;

const SettingsESGDraft = compose(
  connect(
    ({planSponsor}) => ({
      hasError: planSponsor.hasError,
      isFetching: planSponsor.isFetching || !planSponsor.hasFetchedEditPlanSponsor,
      planSponsor: planSponsor.editPlanSponsor,
    }),
    {getPlanSponsor: fetchCurrentPlanSponsorIfNeeded},
  ),
  lifecycle({
    componentDidMount() {
      this.props.getPlanSponsor();
    },
  }),
  branch(({hasError}) => hasError, renderErrorMessage),
  branch(({isFetching}) => isFetching, renderNothing),
  withUser,
  withProps(({authUser: {firstName, lastName}}) => ({
    userFullName: `${firstName} ${lastName}`,
  })),
)(({planSponsor, userFullName}) => (
  <div style={{maxWidth: 680}}>
    <h1 className="mb-4">{ROUTE.SETTINGS_ESG_DRAFT.title}</h1>
    <p>
      In order to improve your organization’s investing strategies you will need to directly contact your investment
      managers to begin the process of changing the requirements of your investments for your portfolio.
    </p>
    <p>
      You can copy the following message into an email to send to your managers. You can also copy example content to
      fill out the necessary IMA form.
    </p>
    <h3>Template Email Copy</h3>
    <p>
      We’ve created an example email for you to start the conversation with your investment managers. Please customize
      the template as you and your legal team require.
    </p>
    <div className="well is-form-like mb-10">
      <p>Hello,</p>
      <p>
        As you may know, we have recently engaged Harmony Analytics, LLC which helps us improve our ethical investing by
        taking ESG factors into account. We&rsquo;re requesting a change from our current IMA policy, per the attached
        IMA Policy Change request.
      </p>
      <p>Regards,</p>
      <p>{userFullName}</p>
    </div>
    <h3>Template IMA Copy</h3>
    <p>Copy into a new document and edit as your legal team requires.</p>
    <p>
      Please note: you are required to seek legal counsel. Harmony Analytics does not render legal advice and is not
      responsible for the information you transmit to your investment manager. For more information, view the{' '}
      <a href={ROUTE.TERMS.path()} target="_blank">
        {ROUTE.TERMS.title}
      </a>
      .
    </p>
    <div className="well is-form-like mb-10">
      <p>This Amendment to the Agreement shall mandate the following:</p>
      <ol>
        <li>
          We’re requesting the Manager upload Portfolio Holdings to Harmony Analytics, LLC on a{' '}
          {String(planSponsor.portfolioUploadFrequency).toLowerCase()} basis, in the format provided by Harmony
          Analytics, LLC on or before 5 business days after the requested period.
        </li>
        {planSponsor.isIspSettingMinimumAverageRating && (
          <li>
            We’re requesting the Manager’s Policy Targets have a Minimum weighted average Harmony rating of{' '}
            {planSponsor.ispHarmonyRating}.
          </li>
        )}
        {planSponsor.isIspSettingMaxPercentHoldingOfThresholdRating && (
          <li>
            We’re requesting the Manager hold no more than {planSponsor.ispMaxPercentHolding}% of companies rated{' '}
            {planSponsor.ispThresholdRating} or lower.
          </li>
        )}
      </ol>
    </div>
  </div>
));

export {SettingsESGDraft};
