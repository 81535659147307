import cn from 'classnames';
import {assignWith, pick} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm} from 'redux-form';

// Local Imports
import {Button, FormFeedback, TooltipLink} from 'app/components';
import {SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {AUTOCOMPLETE, CHARACTER_LIMIT, PLAN_USER_ROLES, REGEX_PATTERN, UPLOAD_FREQUENCIES} from 'app/constants';
import faInfoCircle from 'app/fontawesome-pro-light/faInfoCircle';
import {
  getEmailPrimaryDomain,
  errorRequiredFields,
  normalizeCurrency,
  normalizeEmail,
  validateFieldArray,
} from 'app/utilities';

const validate = (values, ownProps) => {
  const {email: userEmail} = ownProps.onboardingUser;
  const errors = errorRequiredFields(values, ['name', 'portfolioUploadFrequency', 'totalAssets']);

  const allowedEmailPrimaryDomain = getEmailPrimaryDomain(userEmail);

  const investmentConsultantErrors = validateFieldArray(
    values.investmentConsultants,
    (investmentConsultant, ownErrors) => {
      if (investmentConsultant.name && !investmentConsultant.type) {
        ownErrors.type = 'Required';
      }
    },
  );
  investmentConsultantErrors && (errors.investmentConsultants = investmentConsultantErrors);

  const planUserToInviteErrors = validateFieldArray(values.planUsersToInvite, (planUserToInvite, ownErrors) => {
    if (planUserToInvite.email && !planUserToInvite.role) {
      ownErrors.role = 'Required';
    }
    if (planUserToInvite.email) {
      if (!REGEX_PATTERN.VALID_EMAIL.test(planUserToInvite.email)) {
        ownErrors.email = 'Invalid email address';
      } else if (getEmailPrimaryDomain(planUserToInvite.email) !== allowedEmailPrimaryDomain) {
        ownErrors.email = `Email address must end with "${allowedEmailPrimaryDomain}"`;
      }
    }
  });
  planUserToInviteErrors && (errors.planUsersToInvite = planUserToInviteErrors);

  return errors;
};

const renderCustodianBanks = ({fields: custodianBanks}) => (
  <Fragment>
    {custodianBanks.map((custodianBank, index) => {
      const isFirst = index === 0;
      const isLast = index === custodianBanks.length - 1;
      return (
        <div key={index} className="d-flex">
          <Field
            label={isFirst ? 'Custodian Bank Name' : null}
            name={`${custodianBank}.name`}
            type="text"
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.ORGANIZATION_NAME}
            className="flex-fill min-h-0 mb-3"
          />
          <div className={cn('ml-3 w-12r', {'form-grid-button': isFirst})}>
            {isLast ? (
              <Button key="add" onClick={() => custodianBanks.push({})} className="w-100">
                Add Another Custodian
              </Button>
            ) : (
              <Button
                key="remove"
                onClick={() => custodianBanks.remove(index)}
                color="red"
                className="btn-throttled w-100"
              >
                Remove Custodian
              </Button>
            )}
          </div>
        </div>
      );
    })}
  </Fragment>
);

const renderInvestmentConsultants = ({fields: investmentConsultants}) => (
  <div className="form-grid">
    {investmentConsultants.map((investmentConsultant, index) => {
      const isFirst = index === 0;
      const isLast = index === investmentConsultants.length - 1;
      return (
        <Fragment key={index}>
          <Field
            label={isFirst ? 'Name' : null}
            name={`${investmentConsultant}.name`}
            type="text"
            component={TextFormGroup}
            className="col-2 min-h-0 mb-3"
            autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
          />
          <Field
            label={isFirst ? 'Type' : null}
            name={`${investmentConsultant}.type`}
            type="text"
            component={TextFormGroup}
            placeholder="Ex: general consultant"
            className="col-3 min-h-0 mb-3"
          />
          <div className={cn('col-1 form-grid-button', {'pt-0': !isFirst})}>
            {isLast ? (
              <Button key="add" onClick={() => investmentConsultants.push({})}>
                Add Another
              </Button>
            ) : (
              <Button
                key="remove"
                onClick={() => investmentConsultants.remove(index)}
                color="red"
                className="btn-throttled"
              >
                Remove
              </Button>
            )}
          </div>
        </Fragment>
      );
    })}
  </div>
);

const renderPlanUsersToInvite = ({fields: planUsersToInvite}) => (
  <div className="form-grid">
    {planUsersToInvite.map((planUserToInvite, index) => {
      const isFirst = index === 0;
      const isLast = index === planUsersToInvite.length - 1;
      return (
        <Fragment key={index}>
          <Field
            label={isFirst ? 'Email Address' : null}
            name={`${planUserToInvite}.email`}
            type="text"
            component={TextFormGroup}
            normalize={normalizeEmail}
            className="col-3 min-h-0 mb-3"
            autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
          />
          <Field
            label={isFirst ? 'Role*' : null}
            name={`${planUserToInvite}.role`}
            component={SelectFormGroup}
            options={PLAN_USER_ROLES}
            shouldInsertDefault={true}
            className="col-2 min-h-0 mb-3"
          />
          <div className={cn('col-1 form-grid-button', {'pt-0': !isFirst})}>
            {isLast ? (
              <Button key="add" onClick={() => planUsersToInvite.push({})}>
                Add Another
              </Button>
            ) : (
              <Button
                key="remove"
                onClick={() => planUsersToInvite.remove(index)}
                color="red"
                className="btn-throttled"
              >
                Remove
              </Button>
            )}
          </div>
        </Fragment>
      );
    })}
  </div>
);

class OnboardingPlanFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const PlanNameTooltipLink = (
      <TooltipLink id="planNameTooltipLink">
        This is the official name of your organization, and it will be used throughout the system. For example, this
        name will be used when Harmony Analytics sends out automated messages to your investment managers.
      </TooltipLink>
    );

    const PortfolioUploadFrequencyTooltipLink = (
      <TooltipLink id="portfolioUploadFrequencyTooltipLink">
        Portfolio Managers will receive requests to upload their portfolios at the frequency you select here. You will
        be able to modify this frequency for individual managers on the next screen. For example, one of your managers
        may only report holdings on a quarterly basis.
      </TooltipLink>
    );

    const totalAssetsTooltipLink = (
      <TooltipLink id="totalAssetsTooltipLink">
        This is the total assets of the organization across all manager portfolios.
      </TooltipLink>
    );

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <Field
            label="Plan Name*"
            name="name"
            type="text"
            component={TextFormGroup}
            className="col-3"
            labelHint={PlanNameTooltipLink}
          />
          <Field
            label="Website/URL"
            name="websiteUrl"
            type="text"
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.URL}
            className="col-3"
          />
          <Field
            label="Portfolio Upload Frequency*"
            name="portfolioUploadFrequency"
            component={SelectFormGroup}
            shouldInsertDefault={true}
            options={UPLOAD_FREQUENCIES}
            className="col-3"
            labelHint={PortfolioUploadFrequencyTooltipLink}
          />
          <Field
            label="Total Assets*"
            name="totalAssets"
            type="text"
            addOnPrepend={
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
            }
            format={normalizeCurrency}
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.TOTAL_ASSETS}
            className="col-3"
            labelHint={totalAssetsTooltipLink}
          />
        </div>

        {!this.props.hideInvitePlanUsers && (
          <Fragment>
            <h3 className="mt-12">Invite Plan Users</h3>
            <FormFeedback
              color="gray"
              visible={true}
              icon={faInfoCircle}
              className="mb-6"
              message={
                <div>
                  <b>Support</b> roles are <b>not</b> permitted to make changes to the Harmony Rating thresholds for
                  investment managers.
                  <br />
                  <b>Admin</b> roles <b>are</b> permitted to make changes to the Harmony Rating thresholds for
                  investment managers.
                </div>
              }
            />

            <FieldArray name="planUsersToInvite" component={renderPlanUsersToInvite} />
          </Fragment>
        )}
        <h3 className="mt-12">Service Providers</h3>
        <FieldArray name="custodianBanks" component={renderCustodianBanks} />
        <div className="form-grid">
          <Field
            label="Plan Administrators"
            name="planAdministrators"
            type="text"
            component={TextFormGroup}
            maxLength={CHARACTER_LIMIT.ORGANIZATION_NAME}
            placeholder="Ex: Retirement Plan Administrators, LLC"
            className="col-3"
          />
        </div>

        <h3 className="mt-12">Investment Consultant(s)</h3>
        <FieldArray name="investmentConsultants" component={renderInvestmentConsultants} />

        <div className="form-footer is-right mt-12">
          <Button type="submit" solid>
            Save &amp; Continue
          </Button>
        </div>
      </form>
    );
  }
}

OnboardingPlanFormComponent.propTypes = {
  onboardingInvitation: PropTypes.object,
  onboardingUser: PropTypes.object,
  hideInvitePlanUsers: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingPlanSponsor = {}, onboardingInvitation = {}} = ownProps;
  const defaultValues = {
    custodianBanks: [{}],
    investmentConsultants: [{}],
    planUsersToInvite: [{}],
  };
  // Pick which values will pre-populate the form.
  const userValues = {
    ...pick(onboardingPlanSponsor, [
      'name',
      'websiteUrl',
      'portfolioUploadFrequency',
      'totalAssets',
      'custodianBanks',
      'planAdministrators',
      'investmentConsultants',
    ]),
    ...pick(onboardingInvitation, ['planUsersToInvite']),
  };
  return Object.assign({}, ownProps, {
    initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
  });
};

export const OnboardingPlanForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'onboarding-plan',
    validate,
  }),
)(OnboardingPlanFormComponent);
