import {useState, useEffect} from 'react';
import {DashboardHoldingsTableProps} from 'v2/utilities/types/components/DataTable';
import {Container, FlexContainer} from '../atoms/Containers';
import {SubtitleM} from '../atoms/Typeface';
import {GreyedDropDown} from '../molecules/DropDown';
import {holdingOptions} from 'v2/constants/selectOptions';
import {ReactTable} from './ReactTable';
import {ROUTE} from 'v2/constants/routes';

export const DashboardHoldingsTable = ({
  title = '',
  data = [],
  columns = [],
  path,
  onHoldingsChange,
}: DashboardHoldingsTableProps) => {
  const [option, setOption] = useState(holdingOptions[0]);

  useEffect(() => {
    onHoldingsChange?.(option.value);
  }, [onHoldingsChange, option]);

  const companyPath = (id: string) => {
    return ROUTE.UT1.COMPANIES.RISK.path.replace(':companyId', id);
  };

  return (
    <Container>
      <FlexContainer padding="2.25rem 2rem 0" margin="0 0 -2rem" items="center" justify="space-between">
        <FlexContainer flex={1}>
          <SubtitleM>{title.toUpperCase()}</SubtitleM>
        </FlexContainer>
        <Container>
          <GreyedDropDown value={option} options={holdingOptions} onChange={setOption} />
        </Container>
      </FlexContainer>
      <Container padding="38px 0 0">
        <ReactTable data={data} columns={columns} path={path ? path : companyPath} />
      </Container>
    </Container>
  );
};
