import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isNil, map, merge} from 'lodash';
import {Doughnut} from 'react-chartjs-2';

import {COLOR} from 'app/constants';
import {ConicalGradientProvider} from 'app/components';
import {getChartJsSegment, getIssueDataAnalysis} from 'app/utilities';

const ISSUE_DOUGHNUT_CHART_OPTIONS = {
  cutoutPercentage: 43,
  elements: {arc: {borderWidth: 0}},
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      title: (segments, {labels}) => {
        const {index} = segments[0];
        return labels[index];
      },
    },
  },
};

const highlightSegment = ({chartInstance, datasetIndex, dataIndex}) => {
  const segment = getChartJsSegment({chartInstance, datasetIndex, dataIndex});
  if (segment) {
    if (datasetIndex === 0) segment._model.outerRadius += 6;
    else segment._model.innerRadius -= 6;
  }
};

class BooleanComparisonDoughnut extends Component {
  constructor(props) {
    super(props);

    this.handleChartHover = this.handleChartHover.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getLabelTextColor = this.getLabelTextColor.bind(this);
  }

  handleChartHover(event, elements) {
    const chartInstance = this.chart;
    let datasetIndex = null;
    let dataIndex = null;

    if (elements.length) {
      datasetIndex = elements[0]._datasetIndex;
      dataIndex = elements[0]._index;
    }

    if (datasetIndex !== this.hoveredDatasetIndex || dataIndex !== this.hoveredDataIndex) {
      chartInstance.update();
      if (!isNil(datasetIndex) && !isNil(dataIndex)) {
        highlightSegment({chartInstance, datasetIndex, dataIndex});
      }
      this.hoveredDatasetIndex = datasetIndex;
      this.hoveredDataIndex = dataIndex;
    }
  }

  getLabel({datasetIndex, index}, {datasets}) {
    const {unit} = this.props;

    const label = datasets[datasetIndex].data[index];
    return `${label} ${unit}`;
  }

  getLabelTextColor({datasetIndex, index}) {
    const {data} = this.props;
    const issueDataAnalysis = getIssueDataAnalysis(data);
    const labelTextColor = issueDataAnalysis.isWorrisome ? COLOR.RED : COLOR.GREEN;

    if (datasetIndex === 0 && index === 0) return labelTextColor; // colorize if data
    return COLOR.LIGHT_GRAY; // else make gray for dataToCompare
  }

  render() {
    const {data} = this.props;

    return (
      <ConicalGradientProvider>
        {({conicalGradients}) => {
          if (!conicalGradients || isNil(data)) return null;

          const issueDataAnalysis = getIssueDataAnalysis(data);

          const gradient = issueDataAnalysis.isWorrisome ? conicalGradients.red : conicalGradients.green;

          // Double vs Triple color sets
          const backgroundColor =
            data.length < 3
              ? [
                  [gradient, conicalGradients.lightestGray],
                  [conicalGradients.lighterGray, conicalGradients.almostWhite],
                ]
              : [
                  [gradient, conicalGradients.lightestGray],
                  [conicalGradients.navy, conicalGradients.lightestGray],
                  [conicalGradients.lighterGray, conicalGradients.almostWhite],
                ];
          const hoverBackgroundColor =
            data.length < 3
              ? [
                  [gradient, conicalGradients.lightestGray],
                  [conicalGradients.lightGray, conicalGradients.lightestGray],
                ]
              : [
                  [gradient, conicalGradients.lightestGray],
                  [conicalGradients.navy, conicalGradients.lightestGray],
                  [conicalGradients.lightGray, conicalGradients.lightestGray],
                ];

          // Cast data and gradients into the format ChartJS needs
          const datasetsForChart = map(data, ({label, yesCount, noCount}, index) => {
            return {
              label,
              data: [yesCount, noCount],
              backgroundColor: backgroundColor[index],
              hoverBackgroundColor: hoverBackgroundColor[index],
            };
          });

          return (
            <div className="boolean-comparison-doughnut">
              <Doughnut
                ref={element => {
                  this.chart = element;
                }}
                options={merge(
                  {
                    onHover: this.handleChartHover,
                    tooltips: {
                      callbacks: {
                        label: this.getLabel,
                        labelTextColor: this.getLabelTextColor,
                      },
                    },
                  },
                  ISSUE_DOUGHNUT_CHART_OPTIONS,
                )}
                data={{
                  labels: ['YES', 'NO'],
                  datasets: datasetsForChart,
                }}
              />
            </div>
          );
        }}
      </ConicalGradientProvider>
    );
  }
}

BooleanComparisonDoughnut.propTypes = {
  data: PropTypes.array.isRequired,
  unit: PropTypes.string,
};

export {BooleanComparisonDoughnut};
