import {FilterNode} from 'v2/utilities/types/components/FilterList';

export const ROOT_NODE: FilterNode = {
  id: 'root',
  label: 'Filters',
  nodes: [
    {
      id: 'type',
      label: 'Type',
      options: [
        {
          label: 'Liquid Assets',
          value: 'Liquid Assets',
        },
        {
          label: 'Illiquid Assets',
          value: 'Illiquid Assets',
        },
      ],
    },
    {
      id: 'class',
      label: 'Class',
      // dependencies: ['type'],
      options: [
        {
          label: 'Public Equity',
          value: 'Public Equity',
        },
        {
          label: 'Fixed Income',
          value: 'fixedIncome',
        },
        {
          label: 'Private Equity',
          value: 'privateEquity',
        },
        {
          label: 'Venture Capital',
          value: 'ventureCapital',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
    },
    {
      id: 'region',
      label: 'Region',
      // dependencies: ['type'],
      options: [
        {label: 'US', value: 'US'},
        {label: 'Non-US Developed', value: 'Non-US Developed'},
        {label: 'Emerging Markets', value: 'Emerging Markets'},
        {label: 'Frontier Markets', value: 'Frontier Markets'},
      ],
    },
    {
      id: 'strategy',
      label: 'Strategy',
      // dependencies: ['type', 'class', 'region'],
      options: [
        {label: 'Active', value: 'Active'},
        {label: 'Passive', value: 'Passive'},
      ],
    },
    {
      id: 'manager',
      label: 'Manager',
      // dependencies: ['type', 'class', 'region'],
      options: [],
    },
    {
      id: 'index',
      label: 'Index',
      // dependencies: ['type', 'class', 'region'],
      nodes: [],
    },
    {
      id: 'product',
      label: 'Product',
      // dependencies: ['type', 'class', 'region'],
      options: [
        {label: 'Active', value: 'active'},
        {label: 'Passive', value: 'passive'},
      ],
    },
  ],
};
