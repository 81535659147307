import {useRef} from 'react';
import styled from 'styled-components/macro';
import {useOutsideClick} from 'v2/hooks/helpers/useOutsideClick';
import {AdminCautionCard as AdminCautionCardProps} from 'v2/utilities/types/components/AdminCautionCard';
import {CardContainer, Container, FlexContainer} from '../atoms/Containers';
import {HarmonyButton} from './Button';

const Title = styled.h1`
  font-family: Inter;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0em;
  text-align: center;
  padding: 0;
  margin: 0;
  text-align: center;
  text-transform: none;
  color: ${({theme}) => theme.colors.black};
`;

const Red = styled.span`
  color: ${({theme}) => theme.colors.danger};
`;

const Paragraph = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AdminCautionCard = ({title, description, onOutsideClick, onClose, onAccept}: AdminCautionCardProps) => {
  const containerRef = useRef(null);

  const handleAccept = () => {
    return onAccept?.();
  };

  const handleClose = () => {
    return onClose?.();
  };

  const handleOnOutsideClick = () => {
    return onOutsideClick?.();
  };

  const refWithOutsideclick = useOutsideClick({ref: containerRef, onOutsideClick: handleOnOutsideClick});

  return (
    <CardContainer ref={refWithOutsideclick} maxWidth="41.25rem" padding="3.125rem">
      <FlexContainer direction="column" items="center" justify="center">
        <Container width="24.1875rem">
          <Title>
            <Red>Caution!</Red>
            <br />
            <span>{title}</span>
          </Title>
        </Container>
        <Container width="24.1875rem" padding="2.1875rem 0 0">
          <Paragraph>{description}</Paragraph>
        </Container>
        <FlexContainer justify="flex-end" minWidth="34rem" gap="0.5rem" padding="5.25rem 0 0">
          <HarmonyButton width="6.9375rem" variant="ghost" onClick={handleClose}>
            Exit
          </HarmonyButton>
          <HarmonyButton width="6.9375rem" onClick={handleAccept}>
            I Accept
          </HarmonyButton>
        </FlexContainer>
      </FlexContainer>
    </CardContainer>
  );
};
