/* eslint-disable import/prefer-default-export */
import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {change, SubmissionError, untouch} from 'redux-form';
import {get} from 'lodash';

// Local Imports
import {ClientHoldingsDataUploadForm, Button, TooltipLink} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {toastSuccess} from 'app/utilities/toast';
import {createStagingUpload, getUploads, saveUploads} from 'app/redux/clientHoldingsUploadData';
import {typeOrm} from 'v2/redux/typeormEndpoints';

export const ClientHoldingsUpload = () => {
  const dispatch = useDispatch();

  const props = useSelector(state => ({
    clientHoldingsUploadData: get(state, 'clientHoldingsUploadData.uploads.data', []),
    isCreatingStagingUpload: get(state, 'clientHoldingsUploadData.stagingUpload.isCreatingStagingUpload'),
    hasStagingUploadError: get(state, 'clientHoldingsUploadData.stagingUpload.hasStagingUploadError'),
    state,
  }));

  const stagingUpload = fileKey => dispatch(createStagingUpload(fileKey));

  const onSubmit = values => {
    return dispatch(saveUploads(values.date, values.harmonyDataUpload[0].name))
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }
        if (get(response, 'payload.isProcessing')) {
          toastSuccess('Harmony Data has been uploaded and will continue to process in the background.');
        } else {
          toastSuccess('Harmony Data has been uploaded and processed.');
        }

        dispatch(change('upload-harmony-data', 'date', ''));
        dispatch(untouch('upload-harmony-data', 'date'));
        dispatch(change('upload-harmony-data', 'harmonyDataUpload', 'EMPTY'));
        dispatch(untouch('upload-harmony-data', 'harmonyDataUpload'));

        animateScroll.scrollToTop();
      })
      .then(() => {
        dispatch(getUploads());
      })
      .catch(error => {
        animateScroll.scrollToTop();

        if (error.message === 'Invalid CSV File') {
          throw new SubmissionError({_error: `Upload has failed: ${error.message}`});
        }

        throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
      });
  };

  const onSubmitFail = () => animateScroll.scrollToTop();

  // Get initial upload list data
  React.useEffect(() => {
    return dispatch(getUploads());
  }, []);

  const refetchUploads = async () => {
    await dispatch(getUploads());
  };

  // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
  return (
    <div className="p-content-lg">
      <div style={{maxWidth: 850}}>
        <h1 className="mb-4">{ROUTE.ADMIN_CLIENT_HOLDINGS_UPLOAD.title}</h1>
        <ClientHoldingsDataUploadForm
          createStagingUpload={stagingUpload}
          onSubmit={onSubmit}
          onSubmitFail={onSubmitFail}
          refetchUploads={refetchUploads}
          {...props}
        />
      </div>
    </div>
  );
};
