import {getSessionJwt} from 'app/utilities';
import {round, sumBy} from 'lodash';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {
  useGetAggregatePercentileRankUniverseQuery,
  useGetPlanSponsorHoldingsQuery,
  useGetAggregateMarketIndexBenchmarkQuery,
  useGetPlanSponsorStrategiesQuery,
} from 'v2/redux/typeormEndpoints';
import {filter} from 'v2/utilities/helpers/filter';
import {AggregatePercentileRankUniverse, PlanSponsorHolding, MarketIndexBenchmark} from 'v2/utilities/types/typeOrm';

export const useDashboardHoldingDistribution = ({issueId = 1}: {issueId?: number}) => {
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const {
    data: universeData,
    isLoading: universeIsLoading,
    isError: universeIsError,
    isSuccess: universeIsSuccess,
  } = useGetAggregatePercentileRankUniverseQuery({issueId}, {skip: !issueId});

  const {
    data: planSponsorData,
    isLoading: planSponsorIsLoading,
    isSuccess: planSponsorIsSuccess,
    isError: planSponsorIsError,
  } = useGetPlanSponsorHoldingsQuery({planSponsorId}, {skip: !planSponsorId});

  const {
    data: strategiesData,
    isLoading: strategiesIsLoading,
    isSuccess: strategiesIsSuccess,
    isError: strategiesIsError,
  } = useGetPlanSponsorStrategiesQuery({planSponsorId});

  let marketIndexes: number[] = strategiesData?.map((strategy: any) => strategy.marketIndexId) ?? [];

  const {
    data: marketIndexBenchmarkData,
    isLoading: marketIndexBenchmarkIsLoading,
    isSuccess: marketIndexBenchmarkIsSuccess,
    isError: marketIndexBenchmarkIsError,
  } = useGetAggregateMarketIndexBenchmarkQuery({marketIndexes, issueId}, {skip: !marketIndexes.length});

  const AggregatePercentileRankUniverse: AggregatePercentileRankUniverse[] = universeData ?? [];
  const MarketIndexBenchmark: MarketIndexBenchmark[] = marketIndexBenchmarkData ?? [];

  let PlanSponsorHoldings: PlanSponsorHolding[] = planSponsorData ?? [];

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const filterParams = [];
  if (filters.type !== 'all') {
    filterParams.push({key: 'type', target: filters.type, operation: 'eq'});
  }
  if (filters.class !== '') {
    filterParams.push({key: 'class', target: filters.class, operation: 'eq'});
  }
  if (filters.region !== '') {
    filterParams.push({key: 'region', target: filters.region, operation: 'eq'});
  }

  //@ts-ignore
  PlanSponsorHoldings = filter({data: PlanSponsorHoldings, filterItems: filterParams});

  const graphTiers = (): {[key: string]: number} => ({
    '100-81': 0,
    '80-61': 0,
    '60-41': 0,
    '40-21': 0,
    '20-0': 0,
  });

  const holdingsTotal = useMemo(() => sumBy(PlanSponsorHoldings, o => Number(o.marketValue)), [PlanSponsorHoldings]);

  const holdingsByWeight = () => {
    const total = holdingsTotal;
    let counts = graphTiers();

    (PlanSponsorHoldings as PlanSponsorHolding[]).forEach(({pctRank, marketValue}) => {
      if (pctRank >= 0.81) {
        counts['100-81'] += Number(marketValue) / total;
      } else if (pctRank >= 0.61) {
        counts['80-61'] += Number(marketValue) / total;
      } else if (pctRank >= 0.41) {
        counts['60-41'] += Number(marketValue) / total;
      } else if (pctRank >= 0.21) {
        counts['40-21'] += Number(marketValue) / total;
      } else {
        counts['20-0'] += Number(marketValue) / total;
      }
    });

    let roundedCounts = graphTiers();

    for (let tier in counts) {
      roundedCounts[tier] = Math.floor(counts[tier] * 100);
    }

    return Object.values(roundedCounts);
  };

  const holdingsByCount = () => {
    const total = PlanSponsorHoldings.length;
    let counts = graphTiers();

    (PlanSponsorHoldings as PlanSponsorHolding[]).forEach(({pctRank}) => {
      if (pctRank >= 0.81) {
        counts['100-81'] += 1;
      } else if (pctRank >= 0.61) {
        counts['80-61'] += 1;
      } else if (pctRank >= 0.41) {
        counts['60-41'] += 1;
      } else if (pctRank >= 0.21) {
        counts['40-21'] += 1;
      } else {
        counts['20-0'] += 1;
      }
    });

    let calculatedCounts = graphTiers();

    for (let tier in counts) {
      calculatedCounts[tier] = Math.floor(100 * (counts[tier] / total));
    }

    return Object.values(calculatedCounts);
  };
  const universeTotal = useMemo(() => sumBy(MarketIndexBenchmark, o => Number(o.market_cap)), [MarketIndexBenchmark]);
  const benchmarkByWeight = () => {
    const total = universeTotal;
    let counts = graphTiers();

    MarketIndexBenchmark.forEach(({company_pct_rank, market_cap}) => {
      if (company_pct_rank >= 0.81) {
        counts['100-81'] += market_cap / total;
      } else if (company_pct_rank >= 0.61) {
        counts['80-61'] += market_cap / total;
      } else if (company_pct_rank >= 0.41) {
        counts['60-41'] += market_cap / total;
      } else if (company_pct_rank >= 0.21) {
        counts['40-21'] += market_cap / total;
      } else {
        counts['20-0'] += market_cap / total;
      }
    });

    let roundedCounts = graphTiers();

    for (let tier in counts) {
      roundedCounts[tier] = Math.floor(counts[tier] * 100);
    }

    return Object.values(roundedCounts);
  };

  const benchmarkByCount = () => {
    const total = MarketIndexBenchmark.length;
    let counts = graphTiers();

    MarketIndexBenchmark.forEach(({company_pct_rank}) => {
      if (company_pct_rank >= 0.81) {
        counts['100-81'] += 1;
      } else if (company_pct_rank >= 0.61) {
        counts['80-61'] += 1;
      } else if (company_pct_rank >= 0.41) {
        counts['60-41'] += 1;
      } else if (company_pct_rank >= 0.21) {
        counts['40-21'] += 1;
      } else {
        counts['20-0'] += 1;
      }
    });

    let calculatedCounts = graphTiers();

    for (let tier in counts) {
      calculatedCounts[tier] = Math.floor(100 * (counts[tier] / total));
    }

    return Object.values(calculatedCounts);
  };

  const holdings = {
    weight: holdingsByWeight(),
    count: holdingsByCount(),
  };

  const benchmark = {
    weight: benchmarkByWeight(),
    count: benchmarkByCount(),
  };

  const aggregatePercentileRankHoldings = useMemo(() => {
    return PlanSponsorHoldings.map(holding => {
      return {
        id: holding.companyId,
        company: holding.name ?? DATA_STATUS.NONE,
        sector: holding.sectorName ?? DATA_STATUS.NONE,
        held: holding.marketValue ?? 0,
        pctRank: holding.pctRank ?? 0,
      };
    });
  }, [planSponsorData]);

  return {
    data: {
      holdings,
      benchmark,
      aggregatePercentileRankHoldings,
    },
    isLoading: universeIsLoading || planSponsorIsLoading || strategiesIsLoading || marketIndexBenchmarkIsLoading,
    isSuccess: universeIsSuccess && planSponsorIsSuccess && strategiesIsSuccess && marketIndexBenchmarkIsSuccess,
    isError: universeIsError && planSponsorIsError && strategiesIsError && marketIndexBenchmarkIsError,
  };
};
