import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';

import {DatePicker as DatePickerProps} from 'v2/utilities/types/components/DatePicker';
import {Container, FlexContainer} from '../atoms/Containers';
import {Calendar} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {ErrorField} from './ErrorField';

const DatePickerContainer = styled(FlexContainer)`
  border-radius: ${({theme}) => theme.constants.border_radius};
  border: 1px solid transparent;
  background: ${({theme}) => theme.colors.grey_2};
  height: ${({height}) => height ?? '2.75rem'};
  width: ${({width}) => width ?? '100%'};

  &:focus-within {
    border-color: ${({theme}) => theme.colors.harmony};
    box-shadow: 0 0 0 0.25rem rgba(143, 209, 239, 0.5);
  }
`;

const StyledDatePicker = styled(ReactDatePicker)<DatePickerProps>`
  border-radius: ${({theme}) => `${theme.constants.border_radius} 0 0 ${theme.constants.border_radius}`};
  border: 0;
  background: transparent;
  padding: ;
  height: ${({height}) => height ?? '2.75rem'};
  width: ${({width}) => width ?? '100%'};
  padding-left: 0.75rem;
  flex: 1;
  font-size: 1rem;
  font-family: Inter;

  &:focus {
    outline: 0;
    border: 0;

    ${DatePickerContainer}
  }
`;

export const DatePicker = ({
  height,
  width,
  hideError,
  inputClassName,
  meta: {invalid, error, submitError},
  ...rest
}: DatePickerProps) => {
  return (
    <Container>
      <DatePickerContainer
        items="center"
        width={width}
        height={height}
        className={cn('form-control', {'is-invalid': invalid && error}, inputClassName)}
      >
        <FlexContainer flex={1}>
          <StyledDatePicker width={width} height={height} showPopperArrow={false} {...rest} />
        </FlexContainer>
        <FlexContainer padding="0 0.625rem 0">
          <Calendar size={24} color={Theme.colors.harmony} />
        </FlexContainer>
      </DatePickerContainer>
      {!hideError && <ErrorField error={invalid && (error || submitError)} />}
    </Container>
  );
};
