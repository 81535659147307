import styled from 'styled-components/macro';
import {Pill} from 'v2/components/molecules/Pill';
import {Theme} from 'v2/components/atoms/theme';
import {Caption1} from 'v2/components/atoms/Typeface';
import {ESGChartExample} from './ESGChartExample';

const FlexRow = styled.div<{direction?: string; align?: string; justify?: string}>`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-items: ${props => (props.align ? props.align : ' stretch')};
  justify-content: ${props => (props.justify ? props.justify : ' flex-start')};
`;

const FlexItem = styled.div<{
  flex?: number;
  maxWidth?: number | string;
}>`
  flex: ${props => (props.flex ? props.flex : 0)};
  width: auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
`;

const PillContainer = styled(FlexItem)`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 1.4rem 0.5rem;
`;

const TopCaption = styled(Caption1)`
  display: block;
  padding: 0 12rem 2rem 0;
`;

const BottomCaption = styled(Caption1)`
  display: block;
  padding-top: 1.9375rem;
`;

const ESGInfoContainer = styled.div`
  padding: 3rem 4.375rem 1.938rem 4rem;
`;

const BottomCaptionContainer = styled.div`
  background-color: ${props => props.theme.colors.grey_1};
  margin-bottom: -1.9375rem;
  margin-left: -4rem;
  width: calc(100% + 4.375rem + 4rem);
  position: relative;
  padding: 0 4.375rem 1.9375rem 4rem;
  border-radius: 0 0 ${props => props.theme.constants.border_radius} ${props => props.theme.constants.border_radius};
`;

const Strong = styled.b`
  font-weight: 700;
`;

const SmallCaption = styled(Caption1)`
  display: block;
  padding: 1.5rem 0;
  font-size: 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const ESGInfo = () => {
  return (
    <ESGInfoContainer>
      <div>
        <TopCaption>
          The <Strong>Portfolio</Strong> status bar indicates your profile's standing according to Harmony's rating
          system.
        </TopCaption>
      </div>
      <FlexRow>
        <FlexItem flex={1} maxWidth="3rem">
          <SmallCaption>Low Risk</SmallCaption>
        </FlexItem>
        <FlexItem flex={1} />
      </FlexRow>
      <ESGChartExample />
      <FlexRow>
        <FlexItem flex={1} maxWidth="3rem">
          <SmallCaption>High Risk</SmallCaption>
        </FlexItem>
        <FlexItem flex={1}>
          <FlexRow justify="space-between">
            <PillContainer>
              <Caption1>5th</Caption1>
            </PillContainer>
            <PillContainer>
              <Caption1>4th</Caption1>
            </PillContainer>
            <PillContainer>
              <Caption1>3rd</Caption1>
            </PillContainer>
            <PillContainer>
              <Caption1>2nd</Caption1>
            </PillContainer>
            <PillContainer>
              <Caption1>1st</Caption1>
            </PillContainer>
          </FlexRow>
        </FlexItem>
      </FlexRow>
      <BottomCaptionContainer>
        <BottomCaption>
          The <Strong>Benchmark</Strong> represents the weighted average percentile rank for all companies in the
          Harmony Universe as contained within the regional filters selected.
        </BottomCaption>
      </BottomCaptionContainer>
    </ESGInfoContainer>
  );
};
