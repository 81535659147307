import {Theme} from '../theme';

export const LogoBadge3 = ({size = 270}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 270 269" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M230.143 39.3689C188.228 -2.64715 125.381 -11.018 75.1058 14.2001L31.0186 184.334C35.4624 178.139 40.9891 171.183 47.5073 164.277C72.1737 138.347 99.2941 126.759 125.95 130.878C140.597 133.103 155.152 144.55 171.978 157.8C194.894 175.872 220.931 196.31 252.545 196.31H254.479C280.657 145.676 272.578 81.8497 230.143 39.3689Z"
        fill="#E5E5E5"
      />
      <path
        d="M40.2297 39.3689C-3.76616 83.4196 -10.8539 150.414 18.9173 201.843L66.3094 18.9804C57.0068 24.5563 48.2175 31.3219 40.2297 39.3689Z"
        fill="#E5E5E5"
      />
      <path
        d="M124.86 138.108C65.9367 129.167 25.2034 207.137 24.7745 207.94C24.4932 208.51 24.0643 208.933 23.5439 209.313C28.3605 216.459 33.8943 223.274 40.1804 229.61C92.6352 282.13 177.688 282.13 230.143 229.61C238.13 221.612 244.888 212.861 250.414 203.589C217.289 202.688 190.872 181.969 167.485 163.566C151.46 150.928 137.616 140.044 124.86 138.108Z"
        fill="#E5E5E5"
      />
      <path
        d="M254.471 196.296H252.537C220.924 196.296 194.886 175.858 171.964 157.786C155.144 144.536 140.589 133.088 125.943 130.864C99.2934 126.752 72.1659 138.34 47.4995 164.27C40.9813 171.176 35.4476 178.132 31.0107 184.327L48.6386 116.305C71.4909 116.502 98.9488 119.107 130.274 126.344C185.991 139.22 241.357 134.018 269.392 129.772C270.179 152.576 265.201 175.548 254.471 196.296Z"
        fill="#003462"
      />
      <path
        d="M41.0732 116.326L18.9101 201.836C4.31977 176.625 -1.41796 147.669 1.71104 119.515C12.2512 117.889 25.4633 116.551 41.0732 116.326Z"
        fill="#003462"
      />
      <path
        d="M124.86 138.108C65.9367 129.167 25.2034 207.138 24.7745 207.94C24.4932 208.51 24.0643 208.933 23.5439 209.313C28.3605 216.459 33.8943 223.274 40.1804 229.61C92.6352 282.13 177.688 282.13 230.143 229.61C238.13 221.612 244.888 212.861 250.414 203.589C217.289 202.688 190.872 181.969 167.485 163.566C151.46 150.928 137.616 140.044 124.86 138.108Z"
        fill="#003462"
      />
    </svg>
  );
};
