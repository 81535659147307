import {DATA_STATUS} from 'v2/constants/dataStatus';
import {getOrdinal} from './getOrdinal';

export const formatOrdinalValue = (value: number) => {
  if (isNaN(value) || !isFinite(value) || value === 0) {
    return DATA_STATUS.NONE;
  }

  return value + getOrdinal(value);
};
