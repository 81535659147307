import {getSessionJwt} from 'app/utilities';
import {find, keyBy} from 'lodash';
import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ISSUES} from 'v2/constants/issues';
import {
  useGetAggregatePercentileRankUniverseQuery,
  useGetCompanyIssuesQuery,
  useGetCompanyQuery,
} from 'v2/redux/typeormEndpoints';
import {getOrdinal} from 'v2/utilities/helpers';
import {limitPctRank, limitPctRankDecimal} from 'v2/utilities/helpers/limitPctRank';
import {AggregatePercentileRankUniverse} from 'v2/utilities/types/typeOrm';

const calculateQuintile = (value: number) => Math.floor((value * 100) / 20 + 1);
const createOrdinalString = (value?: number) => {
  if (!value && value !== 0) return DATA_STATUS.NOT_APPLICABLE;

  let roundedValue = Math.round(value * 100);
  roundedValue = limitPctRank(roundedValue);

  return `${roundedValue}${getOrdinal(roundedValue)}`;
};

const defaultDataObject = {
  environmentalData: {
    percentileRank: DATA_STATUS.NOT_APPLICABLE,
    industry: DATA_STATUS.NOT_APPLICABLE,
    quintile: 0,
  },
  socialData: {
    percentileRank: DATA_STATUS.NOT_APPLICABLE,
    industry: DATA_STATUS.NOT_APPLICABLE,
    quintile: 0,
  },
  governanceData: {
    percentileRank: DATA_STATUS.NOT_APPLICABLE,
    industry: DATA_STATUS.NOT_APPLICABLE,
    quintile: 0,
  },
};

export const useCompaniesESGRisk = () => {
  const {companyId = ''} = useParams<{companyId: string}>();
  const planSponsorId = getSessionJwt(true).contents?.planSponsorId ?? 0;

  const {data: companyData, isLoading: companyLoading} = useGetCompanyQuery({companyId, planSponsorId});
  const {data: issueData, isLoading: issueLoading} = useGetCompanyIssuesQuery({companyId});
  const {data: environmentalPercentileData, isLoading: environmentalLoading} =
    useGetAggregatePercentileRankUniverseQuery({
      issueId: ISSUES.ENVIRONMENTAL.id,
    });
  const {data: socialPercentileData, isLoading: percentileLoading} = useGetAggregatePercentileRankUniverseQuery({
    issueId: ISSUES.SOCIAL.id,
  });
  const {data: governancePercentileData, isLoading: governanceLoading} = useGetAggregatePercentileRankUniverseQuery({
    issueId: ISSUES.GOVERNANCE.id,
  });

  const isLoading = companyLoading || issueLoading || environmentalLoading || percentileLoading || governanceLoading;

  const percentileData = useMemo(() => {
    if (!environmentalPercentileData || !socialPercentileData || !governancePercentileData) return null;

    return [...environmentalPercentileData, ...socialPercentileData, ...governancePercentileData];
  }, [environmentalPercentileData, socialPercentileData, governancePercentileData]);

  const industryPercentileMap = useMemo(() => {
    if (!percentileData || !companyData) return null;

    const industryPercentiles = percentileData.filter(
      (pd: AggregatePercentileRankUniverse) => pd.industry_id === companyData?.industryId,
    );

    return industryPercentiles.reduce(
      (prev: any, pd: AggregatePercentileRankUniverse) => {
        if ([ISSUES.ENVIRONMENTAL.id, ISSUES.SOCIAL.id, ISSUES.GOVERNANCE.id].indexOf(pd.issueId) < 0) {
          return prev;
        }

        const updatedRecord = {...prev};
        updatedRecord[pd.issueId].percentileSum += pd.issuePctRank;
        updatedRecord[pd.issueId].industryCount += 1;
        return updatedRecord;
      },
      {
        [ISSUES.ENVIRONMENTAL.id]: {
          percentileSum: 0,
          industryCount: 0,
        },
        [ISSUES.SOCIAL.id]: {
          percentileSum: 0,
          industryCount: 0,
        },
        [ISSUES.GOVERNANCE.id]: {
          percentileSum: 0,
          industryCount: 0,
        },
      },
    );
  }, [companyData, percentileData]);

  const calculateIndustryPctRank = () => {
    const lower = {[ISSUES.ENVIRONMENTAL.id]: 0, [ISSUES.SOCIAL.id]: 0, [ISSUES.GOVERNANCE.id]: 0, overall: 0};
    const higher = {[ISSUES.ENVIRONMENTAL.id]: 0, [ISSUES.SOCIAL.id]: 0, [ISSUES.GOVERNANCE.id]: 0, overall: 0};
    const companyPctRank = {
      [ISSUES.ENVIRONMENTAL.id]: find(
        environmentalPercentileData,
        datum => {
          return datum.companyId === Number(companyId);
        },
        0,
      )?.issuePctRank,
      [ISSUES.SOCIAL.id]: find(
        socialPercentileData,
        datum => {
          return datum.companyId === Number(companyId);
        },
        0,
      )?.issuePctRank,
      [ISSUES.GOVERNANCE.id]: find(
        governancePercentileData,
        datum => {
          return datum.companyId === Number(companyId);
        },
        0,
      )?.issuePctRank,
      overall: find(
        governancePercentileData,
        datum => {
          return datum.companyId === Number(companyId);
        },
        0,
      )?.companyPctRank,
    };

    const industryPercentiles = percentileData?.filter(
      (pd: AggregatePercentileRankUniverse) => pd.industry_id === companyData?.industryId,
    );
    industryPercentiles?.forEach((company: AggregatePercentileRankUniverse) => {
      if (company.issuePctRank > companyPctRank[company.issueId]) {
        higher[company.issueId] += 1;
      } else {
        lower[company.issueId] += 1;
      }
      if (company.companyPctRank > companyPctRank.overall) {
        higher.overall += 1;
      } else {
        lower.overall += 1;
      }
    });
    return {
      environmental:
        lower[ISSUES.ENVIRONMENTAL.id] / (lower[ISSUES.ENVIRONMENTAL.id] + higher[ISSUES.ENVIRONMENTAL.id]),
      social: lower[ISSUES.SOCIAL.id] / (lower[ISSUES.SOCIAL.id] + higher[ISSUES.SOCIAL.id]),
      governance: lower[ISSUES.GOVERNANCE.id] / (lower[ISSUES.GOVERNANCE.id] + higher[ISSUES.GOVERNANCE.id]),
      overall: lower.overall / (lower.overall + higher.overall),
    };
  };

  //@ts-ignore
  const {environmentalData, socialData, governanceData, overall} = useMemo(() => {
    if (!issueData || !industryPercentileMap) return defaultDataObject;

    const issueMap = keyBy(issueData, 'issueId');
    const {environmental, social, governance, overall} = calculateIndustryPctRank();

    return {
      environmentalData: {
        percentileRank: createOrdinalString(issueMap[ISSUES.ENVIRONMENTAL.id]?.pctRank),
        industry: createOrdinalString(limitPctRankDecimal(environmental)),
        quintile: calculateQuintile(issueMap[ISSUES.ENVIRONMENTAL.id]?.pctRank),
      },
      socialData: {
        percentileRank: createOrdinalString(issueMap[ISSUES.SOCIAL.id]?.pctRank),
        industry: createOrdinalString(limitPctRankDecimal(social)),
        quintile: calculateQuintile(issueMap[ISSUES.SOCIAL.id]?.pctRank),
      },
      governanceData: {
        percentileRank: createOrdinalString(issueMap[ISSUES.GOVERNANCE.id]?.pctRank),
        industry: createOrdinalString(limitPctRankDecimal(governance)),
        quintile: calculateQuintile(issueMap[ISSUES.GOVERNANCE.id]?.pctRank),
      },
      overall: {
        industry: createOrdinalString(limitPctRankDecimal(overall)),
      },
    };
  }, [issueData, industryPercentileMap]);

  return {
    environmentalData,
    socialData,
    governanceData,
    overall,
    isLoading,
  };
};
