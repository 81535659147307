import {Theme} from '../theme';

export const WomenLeadershipIcon = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 26" fill={color} stroke={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.1546 14.7787C-0.0515347 11.5736 -0.0515347 6.35896 3.1546 3.15384C6.3607 -0.0512824 11.5768 -0.0512805 14.7829 3.15384C17.989 6.35896 17.989 11.5736 14.7829 14.7787C13.4823 16.0789 11.8504 16.8516 10.1567 17.0968V19.2957H11.7826C12.4386 19.2957 12.9705 19.8274 12.9705 20.4834C12.9705 21.1393 12.4386 21.671 11.7826 21.671H10.1567V24.0623C10.1567 24.7183 9.62479 25.25 8.96873 25.25C8.31271 25.25 7.7808 24.7183 7.7808 24.0623V21.671H6.15491C5.49889 21.671 4.96699 21.1393 4.96699 20.4834C4.96699 19.8274 5.49889 19.2957 6.15491 19.2957H7.7808V17.0969C6.08712 16.8516 4.45524 16.0789 3.1546 14.7787ZM13.1029 13.099C15.3823 10.8203 15.3823 7.11215 13.1029 4.83345C10.8235 2.55481 7.11406 2.55467 4.83452 4.83345C2.55511 7.11214 2.55511 10.8203 4.83452 13.099C7.11397 15.3777 10.8235 15.3777 13.1029 13.099Z"
        strokeWidth="0.5"
      />
    </svg>
  );
};
