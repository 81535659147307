import React, {Component, Fragment} from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {Alert, OnboardingProfileForm} from 'app/components';
import {fetchCurrentUserIfNeeded, updateCurrentUser} from 'app/redux/user';
import {toastSuccess} from 'app/utilities/toast';

class SettingsProfilePage extends Component {
  componentDidMount() {
    this.props.dispatch(fetchCurrentUserIfNeeded());
  }

  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="settings-form">
        <h1>{ROUTE.SETTINGS_USER_PROFILE.title}</h1>
        {this.props.hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT}</Alert>}
        {this.props.onboardingUser && (
          <Fragment>
            <OnboardingProfileForm {...this.props} />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  // Using Onboarding specific form.  Expects 'onboardingUser' property passed in to populate it
  return {
    hasError: get(state, 'user.hasError'),
    isFetching: get(state, 'user.isFetching'),
    onboardingUser: get(state, 'user.currentUser'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      return dispatch(updateCurrentUser(values))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          toastSuccess('Your profile has been updated.');

          dispatch(push(ROUTE.SETTINGS.path()));
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const SettingsProfile = connect(mapStateToProps, mapDispatchToProps)(SettingsProfilePage);
