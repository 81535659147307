import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {compose, withHandlers, withProps} from 'recompose';
import PropTypes from 'prop-types';

import {GenericModal} from 'app/components/GenericModal';
import {Button} from 'app/components';
import {openGenericModal} from 'app/components/GenericModal/redux';

const ModalWithButton = compose(
  connect(null, {openGenericModal}),
  withProps({name: 'myModal'}),
  withHandlers({
    openMyGenericModal: props => () => {
      props.openGenericModal(props.name);
    },
  }),
)(({buttonLabel, openMyGenericModal, ...rest}) => (
  <Fragment>
    <GenericModal {...rest} />
    <Button solid onClick={openMyGenericModal}>
      {buttonLabel}
    </Button>
  </Fragment>
));

ModalWithButton.propTypes = {
  name: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
};

export {ModalWithButton};
