import moment from 'moment';

export const makeCSVDownload = ({
  data = [],
  headerLabels,
  subject = '',
}: {
  data: any[];
  headerLabels: string[];
  subject?: string;
}) => {
  const header = headerLabels?.join(',') || (data[0] ? Object.keys(data[0]).join(',') : '');
  const csv = data.map(row =>
    Object.values(row)
      .map(value => `"${value}"`) // wrap values in quotes  in case there are commas in the value
      .join(','),
  );
  csv.unshift(header);
  const blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8;'});
  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', `harmonyanalytics-${subject}-${moment().format('YYYYMMDD')}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
