import {ReactElement, useEffect, useMemo, useState} from 'react';
import {useTable, useSortBy, useFilters, usePagination, useFlexLayout} from 'react-table';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components/macro';
import {FlexContainer} from '../atoms/Containers';
import {ChevronDown, ChevronUp} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {ReactTablePagination, CompactReactTablePagination} from '../molecules/ReactTablePagination';
import {ReactTableProps} from 'v2/utilities/types/components/ReactTable';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  width: auto;
  min-width: 56.25rem;
  min-height: 10rem;
  margin: 2rem 4rem 0;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${props => props.theme.colors.black}26;
  border-radius: 8px;
`;

const Default = styled.div`
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Table = styled(Default)`
  border-spacing: 0;
`;

const THead = styled(Default)<{isEmpty?: boolean}>`
  color: ${({theme}) => theme.colors.grey_5};
  border-bottom: 1px solid ${({theme, isEmpty}) => (isEmpty ? 'transparent' : theme.colors.grey_3)};
`;

const TH = styled(Default)<{isPadded?: boolean}>`
  display: flex;
  align-items: center;
  padding: 1.625rem 0.5rem 1rem 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    padding-left: ${({isPadded}) => (isPadded ? '1.875rem' : '0')};
  }

  &:last-child {
    justify-content: flex-end;
    padding-right: ${({isPadded}) => (isPadded ? '1.875rem' : '0')};
  }
`;

const THCompact = styled(TH)`
  &&& {
    padding: 0.8125rem 1.875rem;
  }
`;

const TBody = styled(Default)<{contentHeight?: string | number}>`
  overflow-y: auto;
  max-height: ${({contentHeight}) => contentHeight ?? 'auto'};
  color: ${({theme}) => theme.colors.black};
`;

const TR = styled(Default)<{hover?: boolean}>`
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.grey_3};
  border-bottom-style: solid;

  &:last-child {
    border-bottom-style: none;
  }

  cursor: ${({hover}) => (!hover ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({theme, hover}) => (!hover ? 'transparent' : theme.colors.grey_2)};
  }
`;

const TD = styled(Default)<{isPadded?: boolean}>`
  display: flex;
  align-items: center;
  padding: 0.8125rem 0.5rem;

  &:first-child {
    padding-left: ${({isPadded}) => (isPadded ? '1.875rem' : '0')};
  }

  &:last-child {
    justify-content: flex-end;
    padding-right: ${({isPadded}) => (isPadded ? '1.875rem' : '0')};
  }
`;

const TDCompact = styled(TD)`
  &&& {
    padding: 0.8125rem 1.875rem;
    font-size: 1.125rem;
    color: ${({theme}) => theme.colors.dark};
  }
`;

const TFoot = styled(Default)`
  border-top: 1px solid ${({theme}) => theme.colors.grey_3};
`;

const PaginationWrapper = styled(Default)<{isPadded?: boolean}>`
  margin: ${({isPadded}) => (isPadded ? '0 3rem' : '0')};
`;

const Wrapper = ({
  withCardContainer,
  children,
}: {
  withCardContainer: boolean;
  children: ReactElement | ReactElement[];
}) => {
  if (withCardContainer) {
    return <CardWrapper>{children}</CardWrapper>;
  }

  return <>{children}</>;
};

const SortIcon = ({isSorted, isSortedDesc}: {isSorted: boolean; isSortedDesc?: boolean}) => {
  if (isSortedDesc) {
    return (
      <FlexContainer direction="column" padding="0 0 0 .875rem">
        <ChevronUp size={6} color={Theme.colors.grey_5} />
        <ChevronDown size={6} color={Theme.colors.black} />
      </FlexContainer>
    );
  }

  if (isSorted) {
    return (
      <FlexContainer direction="column" padding="0 0 0 .875rem">
        <ChevronUp size={6} color={Theme.colors.black} />
        <ChevronDown size={6} color={Theme.colors.grey_5} />
      </FlexContainer>
    );
  }

  return (
    <FlexContainer direction="column" padding="0 0 0 .875rem">
      <ChevronUp size={6} color={Theme.colors.grey_5} />
      <ChevronDown size={6} color={Theme.colors.grey_5} />
    </FlexContainer>
  );
};

export const ReactTable = ({
  data = [],
  columns = [],
  initialState = {},
  withSorting = false,
  withFooter = false,
  withHover = false,
  withCardContainer = false,
  isPadded = true,
  path,
}: ReactTableProps) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 300,
    }),
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow} = useTable(
    {columns, data, initialState, defaultColumn, disableSortBy: !withSorting, autoResetSortBy: false},
    useFilters,
    useSortBy,
    useFlexLayout,
  );

  const history = useHistory();

  const handleRowClick = (id: string | number) => {
    if (path) {
      history.push(path(id));
    }
  };

  return (
    <Wrapper withCardContainer={withCardContainer}>
      <Table {...getTableProps()}>
        <THead isEmpty={data.length === 0}>
          {headerGroups.map(headerGroup => (
            <TR {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TH
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({title: undefined, style: (column as any).style}),
                  )}
                  isPadded={isPadded}
                >
                  <span>{column.render('Header')}</span>
                  {withSorting && <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />}
                </TH>
              ))}
            </TR>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <TR
                {...row.getRowProps()}
                onClick={() => handleRowClick(((row.original as any).id || (row.original as any).company_id) ?? '')}
                hover={withHover}
              >
                {row.cells.map(cell => (
                  <TD {...cell.getCellProps({style: (cell.column as any).style})} isPadded={isPadded}>
                    {cell.render('Cell')}
                  </TD>
                ))}
              </TR>
            );
          })}
        </TBody>
        {withFooter && (
          <TFoot>
            {footerGroups.map(footerGroup => (
              <TR {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map(column => (
                  <TD {...column.getFooterProps({style: (column as any).style})} isPadded={isPadded}>
                    {column.render('Footer')}
                  </TD>
                ))}
              </TR>
            ))}
          </TFoot>
        )}
      </Table>
    </Wrapper>
  );
};

export const PaginatedReactTable = ({
  data = [],
  columns = [],
  initialState = {},
  withSorting = false,
  withFooter = false,
  withCardContainer = false,
  withHover = false,
  isPadded = true,
  path,
}: ReactTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {columns, data, initialState, disableSortBy: !withSorting, autoResetSortBy: false},
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination,
  );

  const history = useHistory();

  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const activePage = pageIndex + 1;

  const dataLength = data.length;

  const filterPages = (visiblePages: number[], pageCount: number) => {
    return visiblePages.filter((page: number) => page <= pageCount);
  };

  const getVisiblePages = (page: number) => {
    if (pageCount < 7) {
      return filterPages([1, 2, 3, 4, 5], pageCount);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < pageCount) {
        return [1, page - 1, page, page + 1, pageCount];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= pageCount) {
        return [1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      } else {
        return [1, 2, 3, 4, 5, pageCount];
      }
    }
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(activePage);

    setVisiblePages(visiblePages);
  }, [activePage, pageCount]);

  const handleRowClick = (id: string | number) => {
    if (path) {
      history.push(path(id));
    }
  };

  return (
    <>
      <Wrapper withCardContainer={withCardContainer}>
        <Table {...getTableProps()}>
          <THead isEmpty={data.length === 0}>
            {headerGroups.map(headerGroup => (
              <TR {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TH
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({title: undefined, style: (column as any).style}),
                    )}
                    isPadded={isPadded}
                  >
                    <span>{column.render('Header')}</span>
                    {withSorting && <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />}
                  </TH>
                ))}
              </TR>
            ))}
          </THead>
          <TBody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <TR
                  {...row.getRowProps()}
                  onClick={() => handleRowClick((row.original as any).id ?? '')}
                  hover={withHover}
                >
                  {row.cells.map(cell => (
                    <TD {...cell.getCellProps({style: (cell.column as any).style})} isPadded={isPadded}>
                      {cell.render('Cell')}
                    </TD>
                  ))}
                </TR>
              );
            })}
          </TBody>
          {withFooter && (
            <TFoot>
              {footerGroups.map(footerGroup => (
                <TR {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map(column => (
                    <TD {...column.getFooterProps({style: (column as any).style})} isPadded={isPadded}>
                      {column.render('Footer')}
                    </TD>
                  ))}
                </TR>
              ))}
            </TFoot>
          )}
        </Table>
      </Wrapper>
      {dataLength > 0 && (
        <PaginationWrapper isPadded={withCardContainer}>
          <ReactTablePagination
            {...{
              canPreviousPage,
              canNextPage,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              visiblePages,
              pageSize,
              activePage,
              dataLength,
            }}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export const CompactPaginatedReactTable = ({
  data = [],
  columns = [],
  initialState = {},
  withSorting = false,
  withFooter = false,
  withCardContainer = false,
  withHover = false,
  isPadded = true,
  path,
  contentHeight,
}: ReactTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {columns, data, initialState, disableSortBy: !withSorting, autoResetSortBy: false},
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination,
  );

  const history = useHistory();

  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const activePage = pageIndex + 1;

  const dataLength = data.length;

  const filterPages = (visiblePages: number[], pageCount: number) => {
    return visiblePages.filter((page: number) => page <= pageCount);
  };

  const getVisiblePages = (page: number) => {
    if (pageCount < 7) {
      return filterPages([1, 2, 3, 4, 5], pageCount);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < pageCount) {
        return [1, page - 1, page, page + 1, pageCount];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= pageCount) {
        return [1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      } else {
        return [1, 2, 3, 4, 5, pageCount];
      }
    }
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(activePage);

    setVisiblePages(visiblePages);
  }, [activePage, pageCount]);

  const handleRowClick = (id: string | number) => {
    if (path) {
      history.push(path(id));
    }
  };

  return (
    <>
      <Wrapper withCardContainer={withCardContainer}>
        <Table {...getTableProps()}>
          <THead isEmpty={data.length === 0}>
            {headerGroups.map(headerGroup => (
              <TR {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <THCompact
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({title: undefined, style: (column as any).style}),
                    )}
                    isPadded={isPadded}
                  >
                    <span>{column.render('Header')}</span>
                    {withSorting && <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />}
                  </THCompact>
                ))}
              </TR>
            ))}
          </THead>
          <TBody contentHeight={contentHeight} {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <TR
                  {...row.getRowProps()}
                  onClick={() => handleRowClick((row.original as any).id ?? '')}
                  hover={withHover}
                >
                  {row.cells.map(cell => (
                    <TDCompact {...cell.getCellProps({style: (cell.column as any).style})} isPadded={isPadded}>
                      {cell.render('Cell')}
                    </TDCompact>
                  ))}
                </TR>
              );
            })}
          </TBody>
          {withFooter && (
            <TFoot>
              {footerGroups.map(footerGroup => (
                <TR {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map(column => (
                    <TD {...column.getFooterProps({style: (column as any).style})} isPadded={isPadded}>
                      {column.render('Footer')}
                    </TD>
                  ))}
                </TR>
              ))}
            </TFoot>
          )}
        </Table>
      </Wrapper>
      {dataLength > 0 && (
        <PaginationWrapper isPadded={withCardContainer}>
          <CompactReactTablePagination
            {...{
              canPreviousPage,
              canNextPage,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              visiblePages,
              pageSize,
              activePage,
              dataLength,
            }}
          />
        </PaginationWrapper>
      )}
    </>
  );
};
