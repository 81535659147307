import React from 'react';
import styled from 'styled-components';
import {Theme} from '../atoms/theme';

import {Overline, Batao} from '../atoms/Typeface';

const DefaultPill = styled.div`
  width: 4.625rem;
  height: 1.188rem;
  border: 0.063rem solid #464255;
  box-sizing: border-box;
  border-radius: 0.594rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StatePill = styled.div`
  width: 6.438rem;
  height: 1.75rem;
  border-radius: 0.844rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HighPill = styled(StatePill)`
  background: rgba(52, 185, 145, 0.2);
`;
const LowPill = styled(StatePill)`
  background: rgba(240, 85, 107, 0.2);
`;
const MediumPill = styled(StatePill)`
  background: rgba(238, 227, 43, 0.2);
`;

const renderPill = (text, state) => {
  switch (state) {
    case 'HIGH':
      return (
        <HighPill>
          <Batao color={Theme.colors.green}>{text}</Batao>
        </HighPill>
      );
    case 'MEDIUM':
      return (
        <MediumPill>
          <Batao color={Theme.colors.yellow}>{text}</Batao>
        </MediumPill>
      );
    case 'LOW':
      return (
        <LowPill>
          <Batao color={Theme.colors.red}>{text}</Batao>
        </LowPill>
      );
    default:
      return (
        <DefaultPill>
          <Overline>{text}</Overline>
        </DefaultPill>
      );
  }
};

export const Pill = ({text, state}) => {
  return renderPill(text, state);
};
