/* eslint-disable no-nested-ternary */
import {intersection, get, includes} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useHistory, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'recompose';

// Local Imports
import {PageLayout} from 'app/components';
import {ROLE} from 'app/constants';
import {BrowserStorageRequired, NotAuthorized} from 'app/pages';
import {
  isImpersonating,
  tokenIsExpired,
  getAuthJwt,
  getAuthRt,
  getImpersonationJwt,
  removeAuthTokens,
  refreshAuthTokens,
  refreshImpersonationTokens,
  getSessionJwt,
  withUser,
  withUserOnboardingCheck,
} from 'app/utilities';
import {LoadingSpinner} from './LoadingSpinner';

const PrivateRouteState = {
  LOADING: 'LOADING',
  ADMIN_UNAUTHORIZED: 'ADMIN UNAUTHORIZED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  AUTHORIZED: 'AUTHORIZED',
};

const PrivateRouteComponent = ({
  component: Component,
  allowedRoles = [],
  requiredAdminPermissions = [],
  layout: Layout = PageLayout,
  adminPermissions,
  ...rest
}) => {
  const [state, setState] = useState(PrivateRouteState.LOADING);

  const history = useHistory();

  useEffect(async () => {
    const authRt = getAuthRt(true);

    if (tokenIsExpired(authRt)) {
      removeAuthTokens();
      history.push('/login');
      return;
    }

    const authJwt = getAuthJwt(true);

    if (tokenIsExpired(authJwt)) {
      await refreshAuthTokens();
    }

    if (isImpersonating()) {
      const impersonationJwt = getImpersonationJwt(true);

      if (tokenIsExpired(impersonationJwt)) {
        await refreshImpersonationTokens();
      }
    }

    const sessionJwt = getSessionJwt(true);

    if (allowedRoles.length > 0 && intersection(allowedRoles, sessionJwt?.contents?.roles).length === 0) {
      setState(PrivateRouteState.UNAUTHORIZED);
      return;
    }

    if (
      includes(sessionJwt?.contents?.roles, ROLE.ADMIN) &&
      requiredAdminPermissions.length > 0 &&
      intersection(requiredAdminPermissions, sessionJwt.contents.adminPermission).length === 0
    ) {
      setState(PrivateRouteState.ADMIN_UNAUTHORIZED);
      return;
    }

    setState(PrivateRouteState.AUTHORIZED);
  });

  if (!navigator.cookieEnabled) {
    return (
      <Layout>
        <BrowserStorageRequired />
      </Layout>
    );
  }

  switch (state) {
    case PrivateRouteState.ADMIN_UNAUTHORIZED:
      return (
        <Layout>
          <NotAuthorized isAdmin={true} />
        </Layout>
      );
    case PrivateRouteState.UNAUTHORIZED:
      return (
        <Layout>
          <NotAuthorized />
        </Layout>
      );
    case PrivateRouteState.AUTHORIZED:
      const WithUserComponent = compose(withUser, withUserOnboardingCheck)(Component);
      return (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <WithUserComponent {...props} />
            </Layout>
          )}
        />
      );
    default:
      return (
        <Layout>
          <LoadingSpinner isActive />
        </Layout>
      );
  }
};

// Ensure component re-renders any time there is a change to state.user
function mapStateToProps(state) {
  const authUser = get(state, 'auth.impersonatedUser') || get(state, 'auth');
  const returnState = {
    authUser,
  };

  if (includes(authUser.roles, ROLE.ADMIN)) {
    returnState.adminPermissions = get(state, 'auth.adminPermission');
  }

  return {...returnState};
}

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
