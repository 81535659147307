import PropTypes from 'prop-types';
import {Component} from 'react';
import {each, get, isFinite, map, omit} from 'lodash';
import {compose} from 'recompose';
import {connect} from 'react-redux';

import {ISSUE_VIEW_MODES, ISSUES_OLD, TIME_PERIOD_COLORS} from 'app/constants';
import {getGlobalSettings} from 'app/redux/settings';
import {getConfig, getDetails, getStats} from 'app/redux/progressReport';
import {fetchCurrentPlanSponsorIfNeeded} from 'app/redux/planSponsor';

// Returns an object, where
// given an array of issues each with a `code` property,
// assigns a property to the object,
// using the code as the key, and `true` as the value
const assignIssuesAsCodeKeys = issues => {
  const o = {};
  each(issues, ({code}) => {
    o[code] = true;
  });
  return o;
};

class ProgressReportDataContainerComponent extends Component {
  componentDidMount() {
    this.props.getDetails();
    this.props.getGlobalSettings();
    this.props.getStats();
    this.props.getConfig();
    this.props.fetchCurrentPlanSponsorIfNeeded();
  }

  render() {
    if (this.props.isFetching) return null;

    return this.props.children(omit(this.props, 'isFetching'));
  }
}

ProgressReportDataContainerComponent.propTypes = {children: PropTypes.func.isRequired};

export const ProgressReportDataContainer = compose(
  connect(
    // mapStateToProps:
    state => {
      const {
        config = {},
        details = {},
        isConfigFetching,
        isDetailsFetching,
        isStatsFetching,
        periodData,
        stats = {},
      } = state.progressReport;
      const {isGlobalSettingsFetching, global: globalSettings = {}} = state.settings;
      const {isFetching: isPlanSponsorFetching, editPlanSponsor: planSponsor = {}} = state.planSponsor;

      const isFetching = isConfigFetching || isDetailsFetching || isGlobalSettingsFetching || isStatsFetching;

      // Wait to sync up values until everything is loaded
      if (isFetching) return {isFetching};

      const isAUMStatHidden = !!globalSettings.isProgressReportAUMStatHidden;
      const isCompaniesEngagedStatHidden = !!globalSettings.isProgressReportCompaniesEngagedStatHidden;
      const isCompaniesRespondedStatHidden = !!globalSettings.isProgressReportCompaniesRespondedStatHidden;
      const isManagersInComplianceStatHidden = !!globalSettings.isProgressReportManagersInComplianceStatHidden;
      const isManagersWithESGPolicyStatHidden = !!globalSettings.isProgressReportManagersWithESGPolicyStatHidden;
      const isStatsHidden = !!globalSettings.isProgressReportStatsHidden;

      // Merge config.periods, periodData and TIME_PERIOD_COLORS into distributionData
      const distributionData = [];

      each(config.periods, (period, index) => {
        distributionData.push(
          period
            ? {
                label: period,
                color: TIME_PERIOD_COLORS[index],
                data: map(get(periodData, `[${period}].distributions`, []), data => Math.round(data.percent)),
              }
            : {
                label: 'No data',
                color: TIME_PERIOD_COLORS[index],
                data: [0, 0, 0, 0, 0],
                isHidden: true,
              },
        );
      });

      // Merge config.periods and periodData into issueData
      const issueData = {};
      each(ISSUES_OLD, ({code}) => {
        const periods = {};
        each(config.periods, period => {
          const value = get(periodData, `[${period}].issues.[${code.toLowerCase()}]`);
          if (isFinite(value)) periods[period] = value;
        });
        issueData[code] = periods;
      });

      // Reduce ESG issue focus into a hash object for lookups
      const focusIssueCodes = {};
      if (!isPlanSponsorFetching) {
        if (planSponsor.isFocusEnvironmental) {
          Object.assign(focusIssueCodes, assignIssuesAsCodeKeys(planSponsor.environmentalIssues, focusIssueCodes));
        }
        if (planSponsor.isFocusSocial) {
          Object.assign(focusIssueCodes, assignIssuesAsCodeKeys(planSponsor.socialIssues, focusIssueCodes));
        }
        if (planSponsor.isFocusGovernance) {
          Object.assign(focusIssueCodes, assignIssuesAsCodeKeys(planSponsor.governanceIssues, focusIssueCodes));
        }
      }

      return {
        config: {
          ...config,
          issueViewMode: config.issueViewMode || ISSUE_VIEW_MODES[0].key,
          progressReportIncludeCertification: !!config.progressReportIncludeCertification,
        },
        details,
        distributionData,
        focusIssueCodes,
        isAUMStatHidden,
        isCompaniesEngagedStatHidden,
        isCompaniesRespondedStatHidden,
        isManagersInComplianceStatHidden,
        isManagersWithESGPolicyStatHidden,
        isStatsHidden,
        issueData,
        periodData,
        stats,
      };
    },
    // mapDispatchToProps:
    {
      fetchCurrentPlanSponsorIfNeeded,
      getConfig,
      getDetails,
      getGlobalSettings,
      getStats,
    },
  ),
)(ProgressReportDataContainerComponent);
