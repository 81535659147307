import {useMemo, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {get} from 'lodash';
import {useDispatch} from 'react-redux';
import {animateScroll} from 'react-scroll';
// Local Imports
import {FormFeedback} from 'app/components';
import {CHARACTER_LIMIT} from 'app/constants';
//@ts-ignore
import {errorRequiredFields, isImpersonating} from 'app/utilities';
import AutoSave from './AutoSave';
import {HR, TitleM} from 'v2/components/atoms/Typeface';

import {TextFormGroup} from 'v2/components/molecules/TextInput';

import {CardContainer, FlexContainer} from 'v2/components/atoms/Containers';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {ROUTE as V2_ROUTE} from 'v2/constants/routes';

import {TextAreaFormGroup} from 'v2/components/molecules/TextareaInput';
import {Theme} from 'v2/components/atoms/theme';
import {RadioField} from '../../../components/molecules/RadioField';
import {OnboardingFooter} from 'v2/components/organisms/OnboardingFooter';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {useHistory} from 'react-router';
import {EmployeesTableSection} from './FormSections/EmployeesTableSection';
import {BulletedSection} from './FormSections/BulletedSection';
import {ONBOARDING_CATEGORIES, ONBOARDING_ETHNICITIES, ONBOARDING_GENDERS, YEARS} from 'v2/constants/onboarding';
import {EmployeeType} from 'v2/utilities/types/components/EmployeeType';
import {useUpdateFirmInvitationMutation} from 'v2/redux/harmonyOnboardingApi';
import {push} from 'connected-react-router';
import {SpinnerDisplay} from 'app/components/Spinner/SpinnerDisplay';
import {handleUpdateForm} from './handleUpdateForm';

const validate = (values: any) => {
  const errors: any = errorRequiredFields(values, []);

  return errors;
};

const setFormAttribute = ([fieldName, fieldVal]: any, state: any, {changeValue}: {changeValue: any}) => {
  changeValue(state, fieldName, () => fieldVal);
};

const buildEmployeeList = (existingEmployees: EmployeeType[] = []) => {
  const employees: EmployeeType[] = [];

  YEARS.forEach(year => {
    Object.keys(ONBOARDING_ETHNICITIES).forEach(ethnicity => {
      Object.keys(ONBOARDING_CATEGORIES).forEach(category => {
        Object.keys(ONBOARDING_GENDERS).forEach(gender => {
          const existingEmployee = existingEmployees?.find(e => {
            return e?.category === category && e?.ethnicity === ethnicity && e?.gender === gender && e?.year === year;
          });

          const prefillValues: EmployeeType = {
            ethnicity,
            gender,
            category,
            year,
            count: 0,
          };

          if (existingEmployee) {
            prefillValues.count = existingEmployee.count;
          }

          employees.push(prefillValues);
        });
      });
    });
  });

  return employees;
};

export const UT3OnboardingMangerInformation = () => {
  const onboardingUserItems = useOnboardingUser();
  const [autoSaveError, setAutoSaveError] = useState<string | null>();

  const {onboardingUser, invitationCode, requestedStep, isLoading} = onboardingUserItems;
  const [updateInvitationFirm] = useUpdateFirmInvitationMutation();

  const onboardingStep = get(requestedStep, 'step', '');

  const initialValues = useMemo(
    () => ({...(onboardingUser?.user?.firm || {})}),
    [onboardingUser?.user?.firm?.employees],
  );

  initialValues.employees = buildEmployeeList(onboardingUser?.user?.firm?.employees || []);

  const handleSubmit = async (values: any) => {
    try {
      const nextRoute = V2_ROUTE.UT3.ONBOARDING.ESG_INTEGRATION.path;
      const res = await handleUpdateForm({handleUpdate: updateInvitationFirm, invitationCode, onboardingStep, values});
      if (!res?.[FORM_ERROR]) {
        animateScroll.scrollToTop();
        dispatch(push(nextRoute.replace(':invitationCode', invitationCode)));
      }
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const handleExit = () => {
    return isImpersonating() ? dispatch(removeImpersonationTokensUser()) : dispatch(logout());
  };

  const handleGoToProfile = () => {
    history.push(V2_ROUTE.UT3.ONBOARDING.PROFILE.path.replace(':invitationCode', invitationCode));
  };

  return (
    <Form initialValues={initialValues} mutators={{setFormAttribute}} onSubmit={handleSubmit} validate={validate}>
      {({form: {mutators, submit}, handleSubmit, error, errors, invalid, submitFailed, submitError, values, dirty}) => {
        const isErrorFormFeedbackVisible = !!error || !!autoSaveError || (!!submitFailed && invalid);
        return (
          <form
            method="POST"
            onSubmit={handleSubmit}
            style={{display: 'flex', gap: '1rem', flexDirection: 'column', flex: 1}}
          >
            {(!!submitError || !!autoSaveError) && (
              <FormFeedback
                message={submitError || autoSaveError}
                visible={isErrorFormFeedbackVisible}
                children={undefined}
              />
            )}
            <AutoSave
              debounce={1000}
              disabled={!dirty}
              onSave={async values => {
                const res = await handleUpdateForm({
                  handleUpdate: updateInvitationFirm,
                  invitationCode,
                  onboardingStep,
                  values,
                });
                if (res?.[FORM_ERROR]) {
                  setAutoSaveError(res[FORM_ERROR]);
                } else {
                  setAutoSaveError(null);
                }
              }}
              values={values}
              errors={errors}
            />
            <CardContainer>
              <TitleM style={{marginBottom: '3rem'}} color={Theme.colors.dark}>
                Manager Information
              </TitleM>

              <FlexContainer flex={1} gap="2.5625rem" maxWidth="40rem">
                <FlexContainer flex={1} direction="column" margin="0.375rem 0 1.5rem">
                  <Field label="Manager Name" name="name" type="text" component={TextFormGroup} disabled={true} />
                </FlexContainer>
                <FlexContainer flex={1} direction="column" margin="0.375rem 0 1.5rem">
                  <Field
                    label="Website URL"
                    name="url"
                    maxLength={CHARACTER_LIMIT.URL}
                    type="text"
                    component={TextFormGroup}
                    className="col-3"
                  />
                </FlexContainer>
              </FlexContainer>
              <div style={{maxWidth: '40rem', marginBottom: '1.5rem'}}>
                <BulletedSection
                  number="1"
                  text="Please complete all columns in the table below by entering in the number of employees for each
                      category (not percentage of employees)."
                />
              </div>

              <EmployeesTableSection
                employees={values.employees}
                setFormAttribute={mutators.setFormAttribute}
                values={values}
              />
            </CardContainer>
            <CardContainer>
              <FlexContainer flex={1} gap="2.5625rem" fullWidth>
                <FlexContainer flex={1} direction="column">
                  <BulletedSection number="2" text="Does your firm account for non-binary employees?">
                    <RadioField field="accounts_for_non_binary" adjustChecked />
                  </BulletedSection>
                </FlexContainer>
                <FlexContainer flex={1} direction="column">
                  <BulletedSection
                    number="3"
                    text="Does your firm have a formal Diversity, Equity, and Inclusion policy or initiative in place?"
                  >
                    <RadioField field="hasInclusion" adjustChecked />
                  </BulletedSection>
                </FlexContainer>
              </FlexContainer>
              <HR style={{borderColor: Theme.colors.grey_3, margin: '2.5rem 0 3rem'}} />
              <FlexContainer flex={1} gap="2.5625rem" fullWidth>
                <FlexContainer flex={1} direction="column">
                  <BulletedSection
                    number="4"
                    text="Please describe your D&I practices that help you meet your objectives. "
                  ></BulletedSection>{' '}
                  <Field
                    name="diversityObjectives"
                    component={TextAreaFormGroup}
                    labelHint="(1000 characters max)"
                    maxLength="1000"
                  />
                </FlexContainer>
                <FlexContainer flex={1} direction="column">
                  <BulletedSection
                    number="5"
                    text="Please describe your firm's family and parental leave policy."
                  ></BulletedSection>
                  <Field
                    name="familyLeavePolicy"
                    component={TextAreaFormGroup}
                    labelHint="(500 characters max)"
                    maxLength="500"
                  />
                </FlexContainer>
              </FlexContainer>
            </CardContainer>
            <OnboardingFooter
              onBack={handleGoToProfile}
              onSave={() => submit()?.then(() => handleExit())}
              backLabel="Return to Profile"
              onContinue={() => submit()}
            />
            <SpinnerDisplay isActive={isLoading} />
          </form>
        );
      }}
    </Form>
  );
};
