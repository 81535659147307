import map from 'lodash/map';

import faCheck from 'app/fontawesome-pro-light/faCheck';
import faExclamationCircle from 'app/fontawesome-pro-light/faExclamationCircle';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import faTimesCircle from 'app/fontawesome-pro-light/faTimesCircle';

import {COLOR} from './color';

// Changing the order of these enums may change the order they appear in the UI
export const COMPLIANCE_STATUS = {
  VIOLATION: {
    label: 'Violation',
    color: COLOR.RED,
    className: 'text-static-danger',
    icon: faExclamationCircle,
    sortOrder: 10,
    tooltip: `This holding violates your client's ESG Policy Targets and is prohibited from being in the portfolio
      due to its poor Harmony Rating. Remove it from the portfolio to ensure the portfolio meets the portfolio's
      ESG Policy Targets.`,
  },
  MISSING: {
    label: 'Missing',
    color: COLOR.RED,
    className: 'text-static-danger',
    icon: faExclamationCircle,
    sortOrder: 20,
    tooltip: `This holding is in the Harmony Index but is not currently in the portfolio. Add this company and the
      weight outlined in the Harmony Index to the portfolio.`,
  },
  NOT_IN_INDEX: {
    label: 'Not in Index',
    color: COLOR.RED,
    className: 'text-static-danger',
    icon: faTimesCircle,
    sortOrder: 30,
    tooltip: `This company is not in the Harmony Index, but has been included in this portfolio. Remove this company
      from the portfolio to match the Harmony Index.`,
  },
  RATINGS_DRAG: {
    label: 'Ratings Drag',
    color: COLOR.ORANGE,
    className: 'text-static-warning',
    icon: faExclamationTriangle,
    sortOrder: 40,
    tooltip: `This holding may have a negative affect on the average Harmony Rating of the portfolio. Adjust the weight
      of this company or remove it from the portfolio to reduce the rating drag this company contributes.`,
  },
  WEIGHT_DEVIATION: {
    label: 'Weight Deviation',
    color: COLOR.ORANGE,
    className: 'text-static-warning',
    icon: faExclamationTriangle,
    sortOrder: 50,
    tooltip: `This weight of this company does not match the Harmony Index. Adjust the weight of this company to match
      the Harmony Index.`,
  },
  ACCEPTABLE: {
    label: 'Acceptable',
    className: 'text-static-success',
    icon: faCheck,
    sortOrder: 60,
    tooltip: "This company meets your client's ESG requirements of the portfolio.",
  },
  PASSIVE_STRATEGY_VIOLATION: {
    label: 'Violation',
    color: COLOR.RED,
    className: 'text-static-danger',
    icon: faExclamationCircle,
    sortOrder: 10,
  },
  PASSIVE_STRATEGY_ACCEPTABLE: {
    label: 'Acceptable',
    className: 'text-static-success',
    icon: faCheck,
    sortOrder: 20,
  },
};

export const COMPLIANCE_STATUSES = map(COMPLIANCE_STATUS, value => value);
