/* eslint-disable no-else-return */
import {createAction} from 'redux-actions';
import {endsWith, startsWith} from 'lodash';
import axios from 'axios';

// Local Imports
import {fetchAuthJSON, getAuthHeaders} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';
import {CONFIG} from 'app/constants';

const initialState = {
  hasError: false,
  isFetching: false,
};

let fetchingCount = 0;

// ACTION
export const ACTION = {
  GET_ROADMAP_DATA_REQUEST: 'CI_GET_ROADMAP_DATA_REQUEST',
  GET_ROADMAP_DATA_RECEIVE: 'CI_GET_ROADMAP_DATA_RECEIVE',
  POST_ROADMAP_DATA_REQUEST: 'CI_POST_ROADMAP_DATA_REQUEST',
  POST_ROADMAP_DATA_RECEIVE: 'CI_POST_ROADMAP_DATA_RECEIVE',
};

const getRoadmapDataRequest = createAction(ACTION.GET_ROADMAP_DATA_REQUEST);
const getRoadmapDataReceive = createAction(ACTION.GET_ROADMAP_DATA_RECEIVE);
const postRoadmapDataRequest = createAction(ACTION.POST_ROADMAP_DATA_REQUEST);
const postRoadmapDataReceive = createAction(ACTION.POST_ROADMAP_DATA_RECEIVE);

const api = {
  getRoadmapData: () => fetchAuthJSON('roadmap', {method: 'get'}),
  postRoadmapData: async (id, category, value, companyId) => {
    const headers = await getAuthHeaders();
    if (category === 'actionPlan' || category === 'carbonNeutralPledgePlan') {
      const formData = new FormData();
      formData.append('file', value);
      formData.append('id', id);
      formData.append('category', category);
      formData.append('companyId', companyId);
      const response = await axios.post(`${CONFIG.API_URL}/roadmap`, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } else {
      const response = await axios.post(
        `${CONFIG.API_URL}/roadmap`,
        {
          id,
          category,
          value,
          companyId,
        },
        {
          headers,
        },
      );
      return response;
    }
  },
};

export function getRoadmapData() {
  return dispatch => {
    dispatch(getRoadmapDataRequest());
    return dispatch(getRoadmapDataReceive(api.getRoadmapData()));
  };
}

export function postRoadmapData(id, category, value, companyId) {
  return dispatch => {
    dispatch(postRoadmapDataRequest());
    return dispatch(postRoadmapDataReceive(api.postRoadmapData(id, category, value, companyId)));
  };
}

// REDUCER
export const companyRoadmap = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  if (startsWith(type, 'CI_') && endsWith(type, '_REQUEST')) {
    fetchingCount += 1;
  }
  if (startsWith(type, 'CI_') && endsWith(type, '_RECEIVE')) {
    fetchingCount -= 1;
  }
  const isFetching = fetchingCount > 0;

  switch (type) {
    case ACTION.GET_ROADMAP_DATA_REQUEST:
    case ACTION.POST_ROADMAP_DATA_REQUEST:
      return assignMergedState({isFetching});
    case ACTION.GET_ROADMAP_DATA_RECEIVE: {
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {data: payload}));
    }
    case ACTION.POST_ROADMAP_DATA_RECEIVE: {
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {data: payload.data}));
    }
    default:
      return state;
  }
};
