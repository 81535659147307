/**
 * Normalizes a currency to only allow numbers display formatted with commas. No cents included.
 * @param {string} value The amount to normalize
 * @returns {string} Returns the value, stripping all non numeric characters and displaying commas
 */
export const normalizeCurrency = input => {
  if (!input) return input;

  return input
    .replace(/[^\d]/g, '') // Remove any non-numeric value
    .replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for every fourth digit
};
