export const REPORT_METRICS = {
  1: {
    // Carbon Emissions
    precision: 3,
  },
  2: {
    // Carbon Footprint
    precision: 3,
  },
  3: {
    // Carbon Intensity
    precision: 3,
  },
  4: {
    // Energy Use
    precision: 3,
  },
  5: {
    // Energy Intensity
    precision: 3,
  },
  6: {
    // Water Consumption
    precision: 3,
  },
  7: {
    // Water Intensity
    precision: 3,
  },
  8: {
    // Women in Leadership
    precision: 0,
  },
  9: {
    // Women on Board
    precision: 0,
  },
  10: {
    // Injury Frequency
    precision: 3,
  },
  11: {
    // CEO Compensation Ratio
    precision: 2,
  },
  12: {
    // Board Independence
    precision: 0,
  },
};
