import {Theme} from '../theme';

export const HarmonyLogo = ({color = Theme.colors.grey_4, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 34 34" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M3.74316 23.3715C16.1906 7.34232 21.0726 24.3808 32.0765 24.8975C39.7498 9.00051 22.8876 -5.05808 9.33195 1.76695L3.74316 23.3715Z" />
      <path d="M8.21771 2.37988C0.682126 6.51335 -2.49264 18.0366 2.20961 25.6006L8.21771 2.37988Z" />
      <path d="M21.0428 20.7336C11.8779 11.5594 3.82119 24.6507 2.79688 26.5433C9.40399 36.6005 25.3497 36.6066 31.5615 25.8163C27.3624 25.7081 24.0079 23.0707 21.0428 20.7336Z" />
    </svg>
  );
};
