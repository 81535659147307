import {Link as ReactLink} from 'react-router-dom';
import styled from 'styled-components/macro';
import {ROUTE} from 'v2/constants/routes';
import {ModalTableWithStateButtonsProps} from 'v2/utilities/types/components/Modal';
import {Container, FlexContainer} from '../atoms/Containers';
import {Theme} from '../atoms/theme';
import {Title5, SubtitleM} from '../atoms/Typeface';
import {GreyedDropDown} from '../molecules/DropDown';
import {GenericModalV2} from '../molecules/Modal';
import {DataTable} from './CompaniesDataTable';
import {Link, SelectableTextButton} from '../molecules/Button';

const LinkContainer = styled(Container)`
  margin: -3rem 1.875rem 0;
`;

const getCompanyPath = (id: string) => {
  return ROUTE.UT1.COMPANIES.RISK.path.replace(':companyId', id);
};

export const ModalTableWithStateButtons = ({
  isOpen,
  setClose,
  data = [],
  columns = [],
  label = '',
  title = '',
  buttons = [],
  selectedButton = 0,
  setSelectedButton,
  sector,
  setSector,
  sectorOptions,
  companyPath = getCompanyPath,
  companiesRoute = ROUTE.UT1.COMPANIES.INDEX.path,
}: ModalTableWithStateButtonsProps) => {
  return (
    <GenericModalV2 width="1185px" isOpen={isOpen} setClose={setClose} minWidth="43.75rem">
      <FlexContainer direction="column" height="100%" margin="-32px 0 0">
        <Container>
          <Title5 color={Theme.colors.dark}>{title}</Title5>
        </Container>
        <FlexContainer wrap="wrap" padding="0 1.875rem" gap="1rem" justify="space-between" items="center">
          <FlexContainer items="center" gap="1rem" width="242px">
            <Container>
              <SubtitleM>Sector:</SubtitleM>
            </Container>
            <FlexContainer minWidth="242px">
              <GreyedDropDown
                placeholder="Sector"
                options={sectorOptions}
                value={sector}
                onChange={(value: any) => setSector(value)}
              />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer gap="1rem">
            {buttons.map((button, i) => {
              return (
                <FlexContainer items="center" justify="center" height="44px" width="174px" minWidth="174px">
                  {/* bUTTONS here */}
                  <SelectableTextButton
                    text={button}
                    selected={selectedButton === i}
                    onClick={() => setSelectedButton(i)}
                    color={Theme.colors.grey_2}
                    selectedColor={Theme.colors.harmony}
                    textColor={Theme.colors.dark}
                    selectedTextColor={Theme.colors.white}
                  />
                </FlexContainer>
              );
            })}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer padding="2rem 0 0" direction="column" flex="1">
          <DataTable data={data} columns={columns} withPagination withHover hideCard slim path={companyPath} />
        </FlexContainer>
      </FlexContainer>
      <LinkContainer>
        <Link as={ReactLink} to={companiesRoute}>
          Go to Companies
        </Link>
      </LinkContainer>
    </GenericModalV2>
  );
};
