import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_ALL_BY_SECURITY_ID_REQUEST: 'COMPANY_ENGAGEMENT_LETTER_GET_ALL_BY_SECURITY_ID_REQUEST',
  GET_ALL_BY_SECURITY_ID_RECEIVE: 'COMPANY_ENGAGEMENT_LETTER_GET_ALL_BY_SECURITY_ID_RECEIVE',
};

const getAllBySecurityIdRequest = createAction(ACTION.GET_ALL_BY_SECURITY_ID_REQUEST);
const getAllBySecurityIdReceive = createAction(ACTION.GET_ALL_BY_SECURITY_ID_RECEIVE);

const api = {
  getAllBySecurityId: securityId => {
    const url = `companyEngagementLetter/security/${securityId}`;

    return fetchAuthJSON(url, {method: 'get'});
  },
};

export function getAllBySecurityId(securityId) {
  return dispatch => {
    dispatch(getAllBySecurityIdRequest({securityId}));
    return dispatch(getAllBySecurityIdReceive(api.getAllBySecurityId(securityId)));
  };
}

// REDUCER
export const companyEngagementLetter = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_ALL_BY_SECURITY_ID_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_ALL_BY_SECURITY_ID_RECEIVE: {
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {companyEngagementLetters: payload}),
      );
    }
    default:
      return state;
  }
};
