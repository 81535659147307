export const ROUTE = {
  ADMIN: {
    EDIT_COMPANY: {
      title: 'Edit Company',
      path: '/v2/admin/securities/edit/:companyId',
    },
  },
  UT1: {
    DASHBOARD: {
      title: 'Dashboard',
      path: '/v2/ut1/dashboard',
      RISK: {
        title: 'Risk',
        path: '/v2/ut1/dashboard/risk',
      },
      MANAGER: {
        title: 'Manager',
        path: '/v2/ut1/dashboard/manager',
      },
      PROFILE: {
        title: 'Profile',
        path: '/v2/ut1/dashboard/profile',
      },
      HOLDINGS: {
        title: 'Holdings',
        path: '/v2/ut1/dashboard/holdings',
      },
      ENGAGEMENT: {
        title: 'Engagement',
        path: '/v2/ut1/dashboard/engagement',
      },
    },
    DATA_EXPLORER: {
      title: 'Data Explorer',
      path: '/v2/ut1/data-explorer',
    },
    STRATEGIES: {
      INDEX: {
        title: 'Managers',
        path: '/v2/ut1/strategies',
      },
      RISK: {
        title: 'Managers - Risk',
        path: '/v2/ut1/strategies/:strategyId/risk',
      },
      PROFILE: {
        title: 'Managers - Profile',
        path: '/v2/ut1/strategies/:strategyId/manager_profile',
      },
      HOLDINGS: {
        title: 'Managers - Holdings',
        path: '/v2/ut1/strategies/:strategyId/holdings',
      },
      ENGAGEMENT: {
        title: 'Managers - Engagement',
        path: '/v2/ut1/strategies/:strategyId/engagement',
      },
    },
    COMPANIES: {
      INDEX: {
        title: 'Companies',
        path: '/v2/ut1/companies',
      },
      RISK: {
        title: 'Companies - Risk',
        path: '/v2/ut1/companies/:companyId/risk',
      },
      OWNERSHIP: {
        title: 'Companies - Ownership',
        path: '/v2/ut1/companies/:companyId/ownership',
      },
      ENGAGEMENT: {
        title: 'Companies - Engagement',
        path: '/v2/ut1/companies/:companyId/engagement',
      },
    },
    ONBOARDING: {
      ACCOUNT: {
        title: 'Welcome to Harmony Analytics',
        path: '/onboarding-account/:invitationCode',
      },
      PROFILE: {
        title: 'Welcome to Harmony Analytics',
        path: '/onboarding-profile/:invitationCode',
      },
      PLAN: {
        title: 'Welcome to Harmony Analytics',
        path: '/onboarding-plan/:invitationCode',
      },
      INVESTMENT_MANAGERS: {
        title: 'Welcome to Harmony Analytics',
        path: '/onboarding-managers/:invitationCode',
      },
      ESG_STRATEGY: {
        title: 'Welcome to Harmony Analytics',
        path: '/onboarding-esg/:invitationCode',
      },
      FINALIZE: {
        title: 'Welcome to Harmony Analytics',
        path: '/onboarding-finalize/:invitationCode',
      },
    },
  },
  UT3: {
    DASHBOARD: {
      basePath: '/v2/ut3/dashboard',
      path: '/v2/ut3/dashboard',
      title: 'Dashboard',
      RISK: {
        title: 'Dashboard - Risk',
        search: '?t=risk',
        path: '/v2/ut3/dashboard?t=risk',
      },
      PROFILE: {
        title: 'Dashboard - Profile',
        search: '?t=manager_profile',
        path: '/v2/ut3/dashboard?t=manager_profile',
      },
      HOLDINGS: {
        title: 'Dashboard - Holdings',
        search: '?t=holdings',
        path: '/v2/ut3/dashboard?t=holdings',
      },
      ENGAGEMENT: {
        title: 'Dashboard - Engagement',
        search: '?t=engagement',
        path: '/v2/ut3/dashboard?t=engagement',
      },
    },
    CLIENTS: {
      basePath: '/v2/ut3/clients',
      path: '/v2/ut3/clients/:strategyId',
      title: 'Clients',
      RISK: {
        title: 'Clients - Risk',
        search: '?t=risk',
        path: '/v2/ut3/clients/:strategyId?t=risk',
      },
      HOLDINGS: {
        title: 'Clients - Holdings',
        search: '?t=holdings',
        path: '/v2/ut3/clients/:strategyId?t=holdings',
      },
      ENGAGEMENT: {
        title: 'Clients - Engagement',
        search: '?t=engagement',
        path: '/v2/ut3/clients/:strategyId?t=engagement',
      },
    },
    COMPANIES: {
      basePath: '/v2/ut3/companies',
      path: '/v2/ut3/companies/:companyId',
      title: 'Companies',
      RISK: {
        title: 'Companies - Risk',
        search: '?t=risk',
        path: '/v2/ut3/companies/:companyId?t=risk',
      },
      OWNERSHIP: {
        title: 'Companies - Ownership',
        search: '?t=ownership',
        path: '/v2/ut3/companies/:companyId?t=ownership',
      },
      ENGAGEMENT: {
        title: 'Companies - Engagement',
        search: '?t=engagement',
        path: '/v2/ut3/companies/:companyId?t=engagement',
      },
    },
    ONBOARDING: {
      ACCOUNT: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-account/:invitationCode',
      },
      PROFILE: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-profile/:invitationCode',
      },
      MANAGER: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-manager/:invitationCode',
      },
      MANAGER_INFORMATION: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-manager-information/:invitationCode',
      },
      ESG_INTEGRATION: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-esg-integration/:invitationCode',
      },
      ESG_ASSOCIATIONS: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-esg-associations/:invitationCode',
      },
      SUCCESS: {
        title: 'Welcome to Harmony Analytics',
        path: '/v2/onboarding-finalize/:invitationCode',
      },
    },
  },
  RESOURCE_CENTER: {
    title: 'Resource Center',
    path: '/v2/ratings-center',
  },
  TERMS_OF_USE: {
    title: 'Terms of Use',
    path: '/terms-of-use',
  },
  CHANGELOG: {
    title: 'Changelog',
    path: '/changelog',
  },
  SETTINGS: {
    title: 'Settings',
    path: '/v2/settings',
    USER_PROFILE: {
      title: 'User Profile',
      path: '/v2/settings/user-profile',
      menuMessage: 'Edit your contact information.',
    },
    MANAGER_PROFILE: {
      title: 'Manager Profile',
      path: '/v2/settings/manager-profile',
      menuMessage: 'Update service provider and investment consultants information.',
    },
  },
};

export const NAV_ROUTES: {label: string; path: string; basePath: string}[] = [
  {label: 'Dashboard', path: ROUTE.UT1.DASHBOARD.RISK.path, basePath: ROUTE.UT1.DASHBOARD.path},
  {label: 'Data Explorer', path: ROUTE.UT1.DATA_EXPLORER.path, basePath: ROUTE.UT1.DATA_EXPLORER.path},
  {label: 'Managers', path: ROUTE.UT1.STRATEGIES.INDEX.path, basePath: ROUTE.UT1.STRATEGIES.INDEX.path},
  {label: 'Companies', path: ROUTE.UT1.COMPANIES.INDEX.path, basePath: ROUTE.UT1.COMPANIES.INDEX.path},
  {label: 'Resource Center', path: ROUTE.RESOURCE_CENTER.path, basePath: ROUTE.RESOURCE_CENTER.path},
];

export const MENU_ROUTES = [
  {
    label: 'Dashboard',
    path: '#link1',
  },
  // {
  //     label: "Risk",
  //     path: "#link2"
  // },
  // {
  //     label: "Managers",
  //     path: "#link3"
  // },
  // {
  //     label: "Holdings",
  //     path: "#link4"
  // },
  // {
  //     label: "Engagement",
  //     path: "#link5"
  // },
];
