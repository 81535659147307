import {Container} from 'v2/components/atoms/Containers';
import {SimpleHeader} from 'v2/components/organisms/PageHeaders';
import {usePageTitle} from 'v2/hooks/usePageTitle';
import {Callback, Middleware} from './types';

const Layout: React.FC<{containerFullWidth: boolean; pageTitle: string}> = ({
  children,
  pageTitle,
  containerFullWidth = false,
}) => {
  const companyTitle = usePageTitle();

  return (
    <>
      <SimpleHeader companyTitle={companyTitle} pageTitle={pageTitle} />
      <Container fullWidth={containerFullWidth}>{children}</Container>
    </>
  );
};

export const simpleHeader =
  ({fullWidth = false, title = ''}): Middleware =>
  async (next: Callback) => {
    const response = await next();

    return (
      <Layout containerFullWidth={fullWidth} pageTitle={title}>
        {response}
      </Layout>
    );
  };
