import {renderToString} from 'react-dom/server';
import ReactDOM from 'react-dom';
import {CONFIG} from 'app/constants';

const parser = new DOMParser();

const _getContainer = () => {
  const docHtml = document.documentElement.outerHTML;
  const doc = parser.parseFromString(docHtml, 'text/html');

  const querySelectorsToRemove = [
    'noscript',
    'header',
    'footer',
    'meta',
    'script',
    'link[rel]:not([rel="stylesheet"])',
    'style:not([type]), style[id=""]',
    'title',
    '.scroll-to-top',
    '.spinner',
    '#vanillatoasts-container',
    'ff-chrome-app-container',
  ];

  querySelectorsToRemove.forEach(qs => {
    const elements = Array.from(doc.querySelectorAll(qs));
    elements.forEach(element => {
      element.parentNode.removeChild(element);
    });
  });

  return doc;
};

const _canvasesToImages = async (page, pageNumber) => {
  let renderContainer = document.createElement('div');
  renderContainer.setAttribute('id', `pdf-render-container-${pageNumber}`);
  renderContainer.classList.add('pdf-render-container');

  document.getElementById('content').appendChild(renderContainer);

  ReactDOM.render(page, renderContainer);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let canvases = document.querySelectorAll(`#pdf-render-container-${pageNumber} canvas`);

      canvases.forEach(canvas => {
        const img = document.createElement('img');
        img.src = canvas.toDataURL('img/png', 1.0);
        img.style.objectFit = 'contain';
        img.style.width = '100%';
        img.style.height = '100%';

        canvas.parentNode.replaceChild(img, canvas);
      });

      let content = renderContainer.innerHTML;

      renderContainer.remove();

      resolve(content);
    }, 500);
  });
};

const _getPageHtml = async (container, page, pageNumber) => {
  container.getElementById('content').innerHTML = await _canvasesToImages(page, pageNumber);

  let fullHtml = container.documentElement.outerHTML;

  fullHtml = fullHtml.replaceAll('href="/index.min.css"', `href="${window.location.origin}/index.min.css"`);

  return fullHtml;
};

export const generatePdfFromPage = async (pages, fileName = 'report.pdf') => {
  let html;

  const container = _getContainer();

  if (Array.isArray(pages)) {
    // eslint-disable-next-line no-unused-vars
    html = await Promise.all(pages.map((page, i) => _getPageHtml(container, page, i)));
  } else {
    html = await _getPageHtml(container, pages, 0);
  }

  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    fetch(`${CONFIG.API_URL}/pdf/convert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        html,
        fileName,
      }),
    })
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
};
