import {assignWithState} from 'app/redux/helpers';
import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON, fetchIfNeeded} from 'app/services/http';

const initialState = {
  hasFetched: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  LIST_ALL_REQUEST: 'MARKET_INDEX_LIST_ALL_REQUEST',
  LIST_ALL_RECEIVE: 'MARKET_INDEX_LIST_ALL_RECEIVE',
};

const marketIndexListAllRequest = createAction(ACTION.LIST_ALL_REQUEST);
const marketIndexListAllReceive = createAction(ACTION.LIST_ALL_RECEIVE);

const api = {get: () => fetchAuthJSON('marketIndex', {method: 'get'})};

export function fetchMarketIndexes() {
  return dispatch => {
    dispatch(marketIndexListAllRequest());
    return dispatch(marketIndexListAllReceive(api.get()));
  };
}

export function fetchMarketIndexesIfNeeded() {
  return fetchIfNeeded('marketIndex', 'all', () => fetchMarketIndexes());
}

// REDUCER
export const marketIndex = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.LIST_ALL_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.LIST_ALL_RECEIVE:
      return assignMergedState(
        Object.assign({hasFetched: true, isFetching: false}, hasError ? {error: payload} : {all: payload}),
      );
    default:
      return state;
  }
};
