import React from 'react';
import {Redirect, Switch} from 'react-router-dom';
import {ROUTE} from './constants/routes';
import 'react-datepicker/dist/react-datepicker.css';

import {
  UT1ESGExplorerPage,
  UT1DashboardEsgRisk,
  UT1HoldingsPage,
  UT1EngagementPage,
  UT1ManagersIndex,
  UT1ManagersEsgRisk,
  UT1ManagersProfile,
  UT1ManagersHoldings,
  UT1ManagersEngagement,
  UT1CompaniesIndex,
  UT1CompaniesESGRisk,
  UT1CompaniesOwnership,
  UT1CompaniesEngagement,
  UT3ClientsESGRisk,
  UT3ClientHoldings,
  UT3ClientEngagement,
  UT3CompaniesIndex,
  UT3CompaniesESGRisk,
  UT3CompaniesOwnership,
  UT3CompaniesEngagement,
  UT3ClientIndex,
  UT3OnboardingProfile,
  UT3OnboardingAccount,
  UT3OnboardingCompletion,
  UT3ESGOnboardingAssociations,
  UT3ESGIntegration as UT3OnboardingESGIntegration,
  UT3OnboardingMangerInformation,
  UT3DashboardHoldingsPage,
  UT3DashboardProfilePage,
  // UT3DashboardEsgRiskPage,
  UT3EngagementPage,
  Settings,
  AdminEditSecurityPage,
  ResourcCenterPage,
  UT1ManagersPage,
} from './pages';
import {
  authenticationMiddleware,
  pageLayout,
  userTypeMiddleware,
  managersInfoBar,
  simpleHeader,
  companiesInfoBar,
  filterHeader,
  clientInfoBar,
  ut3CompaniesInfoBar,
  ut3DashboardHeader,
  onboardingHeaderMiddleware,
  emailPaywallMiddleware,
  dashboardTabBar,
  navigationHeaderMiddleware,
  scrollPreservation,
  graphicHeaderMiddleware,
} from './routing/middleware';
import {ROLE} from './constants/role';
import {NotFound} from 'app/pages';
import {UT1DashboardProfile} from './pages/UT1/Dashboard/UT1DashboardProfile';
import {onboardingLayoutMiddleware} from './routing/middleware/onbardingLayoutMiddleware';
import {RouteWithMiddleware} from './routing/RouteWithMiddleware';
import {MultiRouteWithMiddleware} from './routing/MultiRouteWithMiddleware';

const AdminMiddleware = [
  navigationHeaderMiddleware,
  pageLayout,
  authenticationMiddleware,
  userTypeMiddleware([ROLE.ADMIN]),
];

const UT1DashboardMiddleware = [
  navigationHeaderMiddleware,
  scrollPreservation,
  pageLayout,
  authenticationMiddleware,
  userTypeMiddleware([ROLE.USER_TYPE_1]),
  simpleHeader({title: 'Dashboard'}),
  dashboardTabBar,
];

const UT1EsgExplorerMiddleware = [
  navigationHeaderMiddleware,
  pageLayout,
  authenticationMiddleware,
  userTypeMiddleware([ROLE.USER_TYPE_1]),
  filterHeader({title: 'Data Explorer'}),
];

const UT1ManagersMiddleware = [
  navigationHeaderMiddleware,
  scrollPreservation,
  pageLayout,
  authenticationMiddleware,
  userTypeMiddleware([ROLE.USER_TYPE_1]),
  simpleHeader({title: 'Managers'}),
];

const UT1CompaniesMiddleware = [
  navigationHeaderMiddleware,
  scrollPreservation,
  pageLayout,
  authenticationMiddleware,
  userTypeMiddleware([ROLE.USER_TYPE_1]),
  simpleHeader({title: 'Companies'}),
];

const UT3ClientMiddleware = [
  navigationHeaderMiddleware,
  pageLayout,
  userTypeMiddleware([ROLE.USER_TYPE_3]),
  simpleHeader({title: 'Clients', fullWidth: true}),
  clientInfoBar,
  emailPaywallMiddleware,
];
const UT3CompanyMiddleware = [
  navigationHeaderMiddleware,
  pageLayout,
  userTypeMiddleware([ROLE.USER_TYPE_3]),
  simpleHeader({title: 'Companies', fullWidth: true}),
  ut3CompaniesInfoBar,
];
const UT3DashboardMiddleware = [
  navigationHeaderMiddleware,
  pageLayout,
  userTypeMiddleware([ROLE.USER_TYPE_3]),
  simpleHeader({title: 'Dashboard', fullWidth: true}),
  ut3DashboardHeader,
];

const UT3OnboardingMiddleware = [onboardingHeaderMiddleware, onboardingLayoutMiddleware];

export const V2Routes = () => {
  return (
    <Switch>
      <RouteWithMiddleware
        path={ROUTE.ADMIN.EDIT_COMPANY.path}
        middleware={AdminMiddleware}
        Component={<AdminEditSecurityPage />}
      />
      <RouteWithMiddleware
        path={ROUTE.UT1.DATA_EXPLORER.path}
        middleware={UT1EsgExplorerMiddleware}
        Component={<UT1ESGExplorerPage />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.DASHBOARD.RISK.path}
        middleware={UT1DashboardMiddleware}
        Component={<UT1DashboardEsgRisk />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.DASHBOARD.MANAGER.path}
        middleware={UT1DashboardMiddleware}
        Component={<UT1ManagersPage />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.DASHBOARD.HOLDINGS.path}
        middleware={UT1DashboardMiddleware}
        Component={<UT1HoldingsPage />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.DASHBOARD.ENGAGEMENT.path}
        middleware={UT1DashboardMiddleware}
        Component={<UT1EngagementPage />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.STRATEGIES.INDEX.path}
        middleware={[
          navigationHeaderMiddleware,
          pageLayout,
          authenticationMiddleware,
          userTypeMiddleware([ROLE.USER_TYPE_1]),
          simpleHeader({title: 'Managers', fullWidth: true}),
        ]}
        Component={<UT1ManagersIndex />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.STRATEGIES.RISK.path}
        middleware={[...UT1ManagersMiddleware, managersInfoBar]}
        Component={<UT1ManagersEsgRisk />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.STRATEGIES.PROFILE.path}
        middleware={[...UT1ManagersMiddleware, managersInfoBar]}
        Component={<UT1ManagersProfile />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.STRATEGIES.HOLDINGS.path}
        middleware={[...UT1ManagersMiddleware, managersInfoBar]}
        Component={<UT1ManagersHoldings />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.STRATEGIES.ENGAGEMENT.path}
        middleware={[...UT1ManagersMiddleware, managersInfoBar]}
        Component={<UT1ManagersEngagement />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.COMPANIES.INDEX.path}
        middleware={[
          navigationHeaderMiddleware,
          pageLayout,
          authenticationMiddleware,
          userTypeMiddleware([ROLE.USER_TYPE_1]),
          simpleHeader({title: 'Companies', fullWidth: true}),
        ]}
        Component={<UT1CompaniesIndex />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.COMPANIES.RISK.path}
        middleware={[...UT1CompaniesMiddleware, companiesInfoBar]}
        Component={<UT1CompaniesESGRisk />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.COMPANIES.OWNERSHIP.path}
        middleware={[...UT1CompaniesMiddleware, companiesInfoBar]}
        Component={<UT1CompaniesOwnership />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT1.COMPANIES.ENGAGEMENT.path}
        middleware={[...UT1CompaniesMiddleware, companiesInfoBar]}
        Component={<UT1CompaniesEngagement />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.CLIENTS.basePath}
        middleware={[
          navigationHeaderMiddleware,
          userTypeMiddleware([ROLE.USER_TYPE_3]),
          simpleHeader({title: 'Clients', fullWidth: true}),
          emailPaywallMiddleware,
        ]}
        Component={<UT3ClientIndex />}
      />
      <MultiRouteWithMiddleware
        pages={{
          [ROUTE.UT3.DASHBOARD.PROFILE.search]: <UT3DashboardProfilePage />,
          [ROUTE.UT3.DASHBOARD.ENGAGEMENT.search]: <UT3EngagementPage />,
          [ROUTE.UT3.DASHBOARD.HOLDINGS.search]: <UT3DashboardHoldingsPage />,
        }}
        path={ROUTE.UT3.DASHBOARD.path}
        middleware={UT3DashboardMiddleware}
        autoRedirect
      />
      <MultiRouteWithMiddleware
        pages={{
          [ROUTE.UT3.CLIENTS.RISK.search]: <UT3ClientsESGRisk />,
          [ROUTE.UT3.CLIENTS.ENGAGEMENT.search]: <UT3ClientEngagement />,
          [ROUTE.UT3.CLIENTS.HOLDINGS.search]: <UT3ClientHoldings />,
        }}
        path={ROUTE.UT3.CLIENTS.path}
        middleware={UT3ClientMiddleware}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.COMPANIES.basePath}
        middleware={[
          navigationHeaderMiddleware,
          userTypeMiddleware([ROLE.USER_TYPE_3]),
          simpleHeader({title: 'Companies', fullWidth: true}),
          emailPaywallMiddleware,
        ]}
        Component={<UT3CompaniesIndex />}
      />
      <MultiRouteWithMiddleware
        pages={{
          [ROUTE.UT3.COMPANIES.RISK.search]: <UT3CompaniesESGRisk />,
          [ROUTE.UT3.COMPANIES.OWNERSHIP.search]: <UT3CompaniesOwnership />,
          [ROUTE.UT3.COMPANIES.ENGAGEMENT.search]: <UT3CompaniesEngagement />,
        }}
        path={ROUTE.UT3.COMPANIES.path}
        middleware={UT3CompanyMiddleware}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.ONBOARDING.ACCOUNT.path}
        middleware={UT3OnboardingMiddleware}
        Component={<UT3OnboardingAccount />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.ONBOARDING.PROFILE.path}
        middleware={UT3OnboardingMiddleware}
        Component={<UT3OnboardingProfile />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.ONBOARDING.MANAGER_INFORMATION.path}
        middleware={UT3OnboardingMiddleware}
        Component={<UT3OnboardingMangerInformation />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.ONBOARDING.ESG_INTEGRATION.path}
        middleware={UT3OnboardingMiddleware}
        Component={<UT3OnboardingESGIntegration />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.ONBOARDING.ESG_ASSOCIATIONS.path}
        middleware={UT3OnboardingMiddleware}
        Component={<UT3ESGOnboardingAssociations />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.UT3.ONBOARDING.SUCCESS.path}
        middleware={UT3OnboardingMiddleware}
        Component={<UT3OnboardingCompletion />}
      />
      <RouteWithMiddleware
        exact
        path={ROUTE.RESOURCE_CENTER.path}
        middleware={[navigationHeaderMiddleware, pageLayout, graphicHeaderMiddleware]}
        Component={<ResourcCenterPage />}
      />
      <RouteWithMiddleware
        path={ROUTE.SETTINGS.path}
        middleware={[
          userTypeMiddleware([ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]),
          navigationHeaderMiddleware,
        ]}
        Component={<Settings />}
      />
      <Redirect from={`/admin${ROUTE.RESOURCE_CENTER.path}`} to={ROUTE.RESOURCE_CENTER.path} />
      <Redirect from={ROUTE.UT1.DASHBOARD.path} to={ROUTE.UT1.DASHBOARD.RISK.path} />
      <Redirect from={ROUTE.UT3.ONBOARDING.MANAGER.path} to={ROUTE.UT3.ONBOARDING.MANAGER_INFORMATION.path} />
      <RouteWithMiddleware middleware={[pageLayout]} Component={<NotFound />} />
    </Switch>
  );
};
