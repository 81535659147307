import {Theme} from '../theme';

export const InfoCircle = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.3936C17.5228 22.3936 22 17.9164 22 12.3936C22 6.87071 17.5228 2.39355 12 2.39355C6.47715 2.39355 2 6.87071 2 12.3936C2 17.9164 6.47715 22.3936 12 22.3936Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 16.3936V12.3936" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 8.39355H12.01" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
