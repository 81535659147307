import styled from 'styled-components';
import {Container, FlexContainer, Wrapper} from '../atoms/Containers';
import {CompanyInfoBarProps} from 'v2/utilities/types/components/InfoBars';
import {AG, Body1, Content, Title4} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';
import {CARBON_PLEDGE_VALUES} from 'v2/constants/carbonPledgeValues';
import {Tooltip} from '../molecules/Tooltip';
import {Position, Size} from '../interfaces/Tooltip';
import {GreyPill} from '../molecules/Pills';
import {CompanyPercentileRank} from '../molecules/PercentileRankDisplays';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import ReactPlaceholder from 'react-placeholder';
import {TextBlock, RectShape} from 'react-placeholder/lib/placeholders';
import {CheckedBadge} from '../molecules/CheckedBadge';

const BorderContainer = styled(Container)`
  background: ${({theme}) => theme.colors.blueGradientStart};
  border-radius: ${({theme}) => theme.constants.info_bar_border_radius};
  box-shadow: ${({theme}) => theme.shadows.shadow_2};
  min-height: 18.9375rem;
  padding: 0 0 0 0.3125rem;
`;

const ContentContainer = styled(FlexContainer)`
  height: 100%;
  min-height: 18.9375rem;
  background: ${({theme}) => theme.colors.white};
`;

const TargetsContainer = styled(FlexContainer)`
  background: ${({theme}) => theme.colors.grey_1};
  height: 3.375rem;
  min-height: 3.375rem;
  padding: 0 2.625rem;
`;

const TargetsLabel = styled(AG)<{fontSize?: string}>`
  padding-top: 0.1875rem;
  font-size: ${({fontSize}) => fontSize ?? '.875rem'};
  text-transform: uppercase;
  white-space: nowrap;
`;

const Adjustment = styled.div`
  position: relative;
  top: 0.1875rem;
`;

const TooltipContainer = styled(FlexContainer)`
  text-align: left;
  margin: -1rem -1rem -0.5rem;
  font-size: 0.8125rem;
`;

const TooltipHeader = styled.span<{color?: string}>`
  font-size: 0.8125rem;
  font-weight: 600;
  color: ${({color, theme}) => color ?? theme.colors.dark};
`;

const TooltipText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 0.8125rem;
  line-height: 1rem;
  padding: 0;
  margin: 0;
`;

const Title = styled(Title4)`
  color: ${({theme}) => theme.colors.blueGradientStart};
`;

const InfoTitle = styled(AG)`
  text-transform: uppercase;
  font-size: 12px;
`;

const InfoContent = styled(AG)`
  display: block;
  line-height: 1.0588rem;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
`;

const Description = styled(Body1)`
  display: block;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 400;
`;

const ReactPlaceholderESGRisk = () => (
  <div>
    <FlexContainer flex="1" padding="2.6875rem 5.75rem 1.25rem 2.6875rem" gap="6.1875rem">
      <FlexContainer flex="1" direction="column" maxWidth="25.5625rem">
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
          <br></br>
          <TextBlock rows={5} color={Theme.colors.grey_2} style={{marginBottom: 40}} />
        </Container>
      </FlexContainer>
      <FlexContainer direction="column" flex="1">
        <FlexContainer gap="2.25rem" flex="1">
          <FlexContainer direction="column" gap=".5625rem" flex="1">
            <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '80%', height: 10, marginTop: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '60%', height: 10, marginTop: 0}} />
          </FlexContainer>
          <FlexContainer direction="column" gap=".5625rem" flex="1">
            <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '80%', height: 10, marginTop: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '60%', height: 10, marginTop: 0}} />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer gap="2.25rem" flex="1">
          <FlexContainer direction="column" gap=".5625rem" flex="1">
            <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 10, marginTop: 30}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '80%', height: 10, marginTop: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '60%', height: 10, marginTop: 0}} />
          </FlexContainer>
          <FlexContainer direction="column" gap=".5625rem" flex="1">
            <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 10, marginTop: 30}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '80%', height: 10, marginTop: 10}} />
            <RectShape color={Theme.colors.grey_2} style={{width: '60%', height: 10, marginTop: 0}} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer direction="column" maxWidth="7.5rem" gap="1.5rem" flex="1">
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 10, marginTop: 0}} />
        </Container>
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 130, marginTop: 0}} />
        </Container>
      </FlexContainer>
    </FlexContainer>
    <TargetsContainer flex="1" items="center" justify="space-between">
      <FlexContainer gap=".625rem"></FlexContainer>
      <FlexContainer padding="0 4.9375rem 0 0" items="center" gap="10px">
        <div style={{height: 40}}></div>
      </FlexContainer>
    </TargetsContainer>
  </div>
);

export const CompanyInfoBar = ({
  title = DATA_STATUS.NONE,
  description = DATA_STATUS.NONE,
  country = DATA_STATUS.NONE,
  sector = DATA_STATUS.NONE,
  industry = DATA_STATUS.NONE,
  transparency = DATA_STATUS.NONE,
  isEngaged = false,
  carbonNeutralPledgeSbtiStatus = DATA_STATUS.NONE,
  overall,
  isLoading,
}: CompanyInfoBarProps) => {
  console.log('carbonNeutralPledge', carbonNeutralPledgeSbtiStatus);
  const carbonNeutral =
    carbonNeutralPledgeSbtiStatus === DATA_STATUS.NONE && title !== DATA_STATUS.NONE
      ? 'Not Committed'
      : carbonNeutralPledgeSbtiStatus;
  return (
    <Wrapper margin="0 auto">
      <BorderContainer>
        <ContentContainer direction="column">
          <ReactPlaceholder ready={!isLoading} customPlaceholder={<ReactPlaceholderESGRisk />}>
            <FlexContainer flex="1" padding="2.6875rem 5.75rem 1.25rem 2.6875rem" gap="6.1875rem">
              <FlexContainer flex="1" direction="column" maxWidth="25.5625rem">
                <Container>
                  <Title color={Theme.colors.blueGradientStart}>{title}</Title>
                </Container>
                <Container>
                  <Description>
                    {description.length > 480 ? description.substring(0, 480) + '...' : description}
                  </Description>
                </Container>
              </FlexContainer>
              <FlexContainer direction="column" flex="1">
                <FlexContainer gap="2.25rem" flex="1">
                  <FlexContainer direction="column" gap=".5625rem" flex="1">
                    <Container>
                      <InfoTitle>Country</InfoTitle>
                    </Container>
                    <Container maxWidth="6.5625rem">
                      <InfoContent>{country}</InfoContent>
                    </Container>
                  </FlexContainer>
                  <FlexContainer direction="column" gap=".5625rem" flex="1">
                    <Container>
                      <InfoTitle>Industry</InfoTitle>
                    </Container>
                    <Container maxWidth="6.5625rem">
                      <InfoContent>{industry}</InfoContent>
                    </Container>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer gap="2.25rem" flex="1">
                  <FlexContainer direction="column" gap=".5625rem" flex="1">
                    <Container>
                      <InfoTitle>Sector</InfoTitle>
                    </Container>
                    <Container maxWidth="3.75rem">
                      <InfoContent>{sector}</InfoContent>
                    </Container>
                  </FlexContainer>
                  <FlexContainer direction="column" gap=".5625rem" flex="1">
                    <Container>
                      <InfoTitle>Transparency</InfoTitle>
                    </Container>
                    <Container maxWidth="3.75rem">
                      <InfoContent>
                        <GreyPill>{transparency}</GreyPill>
                      </InfoContent>
                    </Container>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer direction="column" maxWidth="7.5rem" gap="1.5rem" flex="1">
                <Container>
                  <InfoTitle>Percentile Rank</InfoTitle>
                </Container>
                <Container>
                  <CompanyPercentileRank {...overall} />
                </Container>
              </FlexContainer>
            </FlexContainer>
            <TargetsContainer flex="1" items="center" justify="space-between">
              <FlexContainer gap=".625rem">
                <CheckedBadge isChecked={isEngaged} />
              </FlexContainer>
              <FlexContainer padding="0 4.9375rem 0 0" items="center" gap="10px">
                <Adjustment>
                  <Tooltip position={Position.Bottom} size={Size.Large}>
                    <TooltipContainer direction="column" gap="1rem">
                      <Container>
                        <TooltipHeader color={Theme.colors.blueGradientStart}>Not Commited</TooltipHeader>
                        <TooltipText>Companies have not publicly committed to any emissions reductions.</TooltipText>
                      </Container>
                      <Container>
                        <TooltipHeader color={Theme.colors.green}>Engaged</TooltipHeader>
                        <TooltipText>
                          Companies have engaged with the Science Based Targets initiative (SBTi) or Carbon Disclosure
                          Project (CDP), but have not set targets.
                        </TooltipText>
                      </Container>
                      <Container>
                        <TooltipHeader color={Theme.colors.green}>Target Set</TooltipHeader>
                        <TooltipText>
                          Companies have set emissions reductions targets for years, short of 100% for all scopes.
                        </TooltipText>
                      </Container>
                      <Container>
                        <TooltipHeader color={Theme.colors.green}>Net-Zero</TooltipHeader>
                        <TooltipText>
                          Companies have set a target of 100% emissions reduction for all scopes by 2050 or sooner.
                        </TooltipText>
                      </Container>
                    </TooltipContainer>
                  </Tooltip>
                </Adjustment>
                <TargetsLabel fontSize=".75rem">Carbon Neutral Pledge:</TargetsLabel>
                <Content
                  color={
                    carbonNeutral === CARBON_PLEDGE_VALUES.NOT_COMMITTED
                      ? Theme.colors.blueGradientStart
                      : Theme.colors.green
                  }
                  style={{whiteSpace: 'nowrap'}}
                >
                  {carbonNeutral}
                </Content>
              </FlexContainer>
            </TargetsContainer>
          </ReactPlaceholder>
        </ContentContainer>
      </BorderContainer>
    </Wrapper>
  );
};
