import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {debounce, get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';

import {Alert, Button, BootstrapTableRemote} from 'app/components';
import {AUTOCOMPLETE, FORM_MESSAGE, ROUTE} from 'app/constants';
import {COLOR} from 'app/constants/color';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import faInfoCircle from 'app/fontawesome-pro-light/faInfoCircle';
import {getAllPagedSecurities} from 'app/redux/security';
import {formatCurrency} from 'app/utilities/formatCurrency';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {ratingFormatter} from 'app/utilities/ratingFormatter';
import {countryFormatter} from 'app/utilities/formatCountry';

/**
 * Format the content of a Held By cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {JSX.Element} - The HTML to display
 */
function heldByFormatter(cell, row) {
  if (!cell || cell.length === 0) {
    return <span></span>;
  }

  return (
    <div>
      <span>{formatCurrency(row.heldAmount)}</span>
    </div>
  );
}

const columns = [
  {
    dataField: 'name',
    text: 'Company Name',
    sort: true,
    style: {width: 340, color: COLOR.BLUE},
    headerStyle: {width: 340},
  },
  {
    dataField: 'isin',
    text: 'ISIN',
    sort: true,
    style: {width: 150},
    headerStyle: {width: 150},
  },
  {
    dataField: 'ticker',
    text: 'Ticker',
    sort: true,
    style: {width: 110},
    headerStyle: {width: 110},
  },
  {
    dataField: 'country.code',
    text: 'Country',
    formatter: countryFormatter,
    sort: true,
    style: {width: 130},
    headerStyle: {width: 130},
  },
  {
    dataField: 'sector.name',
    text: 'Sector',
    sort: true,
    style: {width: 200},
    headerStyle: {width: 200},
  },
  {
    dataField: 'companies.rating',
    text: 'Harmony Rating',
    sort: true,
    formatter: ratingFormatter,
    style: {
      paddingRight: 32,
      textAlign: 'center',
    },
    headerStyle: {width: 165, whiteSpace: 'nowrap'},
  },
  {
    dataField: 'heldAmount',
    sort: true,
    text: 'Held',
    formatter: heldByFormatter,
    style: {textAlign: 'right'},
    headerStyle: {textAlign: 'right', width: 100},
  },
];

class CompaniesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {search: props.search, filter: props.filter};

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleSearchDebounced = debounce(this.handleCompanySearch, 500);
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.props.search,
      this.props.filter,
    );

    this.searchInput.focus();
    animateScroll.scrollToTop();
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
    filter = this.state.filter,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search || filter !== this.props.filter) {
      updatedPage = 1;
    }
    this.props.dispatch(
      push({
        search: `?${setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search, filter)}`,
      }),
    );
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    this.handleSearchDebounced();
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      // data,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
      // filters,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search, filter} = this.props;

    this.props.dispatch(
      push({
        pathname: ROUTE.COMPANY_DETAIL.path(row.isin),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, filter),
      }),
    );
    animateScroll.scrollToTop();
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleCompanySearch = () => {
    this.updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search, filter) => {
    this.props.dispatch(
      getAllPagedSecurities({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        filter,
      }),
    );
  };

  render() {
    const {data, hasError, sortField, sortOrder, page, pageSize, totalSize} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg" style={{paddingRight: '3rem'}}>
        <div>
          <div className="mb-5" style={{display: 'flex'}}>
            <h1 className="mb-0">{ROUTE.COMPANIES.title}</h1>
            <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Link to={ROUTE.RATINGS_CENTER.path()}>
                <Icon icon={faInfoCircle} /> Harmony Ratings Center
              </Link>
            </div>
          </div>

          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          <form method="GET" onSubmit={this.handleCompanySearch}>
            <div className="d-flex" style={{justifyContent: 'space-between'}}>
              <div style={{width: 600}} className="d-flex mr-2 mb-8">
                <div className="form-control-and-icon mr-2 flex-grow-1">
                  <input
                    className="form-control is-subtle"
                    ref={input => {
                      this.searchInput = input;
                    }}
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search by Company Name, Ticker, CUSIP, or ISIN"
                    value={this.state.search}
                    onChange={this.changeField}
                    autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                  />
                  <div className="icon">
                    <Icon icon={faSearch} />
                  </div>
                </div>

                <Button color="gray" type="submit">
                  Search
                </Button>
              </div>

              <div className="d-flex">
                <select
                  id="filter"
                  name="filter"
                  onChange={this.changeField}
                  className="custom-select form-control"
                  value={this.state.filter}
                  style={{width: 200}}
                >
                  <option value="all">All Companies</option>
                  <option value="held">Companies Held</option>
                </select>
              </div>
            </div>
          </form>

          <BootstrapTableRemote
            keyField="isin"
            columns={columns}
            data={data}
            page={page}
            sizePerPage={pageSize}
            totalSize={totalSize}
            onTableChange={this.handleTableChange}
            defaultSorted={defaultSorted}
            selectRow={{
              mode: 'radio',
              hideSelectColumn: true,
              clickToSelect: true,
              onSelect: this.handleOnSelect,
            }}
          />
        </div>
      </div>
    );
  }
}

CompaniesPage.defaultProps = {
  data: [],
  totalSize: 0,
};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(ownProps, {
    search: '',
    filter: 'held',
    page: 1,
    pageSize: 25,
    sortField: 'heldAmount',
    sortOrder: 'desc',
  });
  newProps.data = get(state, 'security.allSecurities');
  // if (newProps.data && newProps.data.length === 1) {
  //   // add 1 as fix for pagination
  //   newProps.data.push({ isin: '-1' });
  // }
  newProps.totalSize = get(state, 'security.allSecuritiesTotal');
  newProps.hasError = get(state, 'security.hasError');
  return newProps;
};

export const Companies = connect(mapStateToProps)(CompaniesPage);
