import React, {Component} from 'react';
import {throttle} from 'lodash';

const calculateState = () => ({
  viewportWidth: window.innerWidth,
  viewportHeight: window.innerHeight,
});

export const withViewportSize = InnerComponent =>
  class ResponsiveMixin extends Component {
    constructor(props) {
      super(props);
      this.state = calculateState();
      this.handleResize = throttle(this.handleResize.bind(this), 200);
    }

    handleResize() {
      this.setState(calculateState());
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    render() {
      return <InnerComponent {...this.props} {...this.state} />;
    }
  };
