import {Theme} from '../theme';

export const SearchIndex = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 10L2 10L2 14L9 14" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M8.75 18L2 18L2 22L20 22"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 6L2 6L2 2L20 2L20 6L18.3125 6"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 17C18.3137 17 21 14.3137 21 11C21 7.68629 18.3137 5 15 5C11.6863 5 9 7.68629 9 11C9 14.3137 11.6863 17 15 17Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M22 18L19.5 15.5" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
