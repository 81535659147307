import {Callback, Middleware} from './types';
import {ManagerInfoHeader} from '../../components/organisms/ManagerInfoHeader';
import {ClientTabsCard} from 'v2/components/organisms/TabsCards';
// import {DATA_STATUS} from 'v2/constants/dataStatus';
import {BackButton} from 'v2/components/molecules/BackButtons';
import {Container} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {useParams} from 'react-router';
import {useGetStrategyQuery} from 'v2/redux/typeormEndpoints';
import {useMarketIndexRating} from 'v2/hooks/useMarketIndexRating';
import {useRating} from 'v2/hooks/useRating';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ROUTE} from 'v2/constants/routes';

const Layout: React.FC<{}> = ({children}) => {
  //@ts-ignore
  const {strategyId} = useParams();
  const {getMarketIndexPercentileRank, isLoading: indexRatingIsLoading} = useMarketIndexRating();
  const {getRatingPercentileRank, isLoading: ratingIsLoading} = useRating();

  const {data: strategy, isLoading} = useGetStrategyQuery({strategyId});

  return (
    <Container minWidth={Theme.constants.fixWidth}>
      <Container maxWidth={Theme.constants.fixWidth} margin="0 auto" padding="1.5rem 0 0">
        <BackButton path={ROUTE.UT3.CLIENTS.basePath}>Return to Clients</BackButton>
      </Container>
      <ManagerInfoHeader
        managerName={strategy?.planSponsor?.name ?? DATA_STATUS.NONE}
        productName={strategy?.name ?? DATA_STATUS.NONE}
        indexName={strategy?.marketIndex?.name ?? DATA_STATUS.NONE}
        portfolioValue={strategy?.portfolio_value}
        portfolioManagementType={strategy?.portfolioManagementType ?? DATA_STATUS.NONE}
        effectiveDate={strategy?.equity_date}
        percentileRank={getRatingPercentileRank() ?? DATA_STATUS.NONE}
        marketIndexPercentileRank={getMarketIndexPercentileRank() ?? DATA_STATUS.NONE}
        isLoading={isLoading && ratingIsLoading && indexRatingIsLoading}
      />
      <ClientTabsCard />
      {children}
    </Container>
  );
};

export const clientInfoBar: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
