import React, {Component} from 'react';
import {isNumber, find} from 'lodash';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {COLOR} from 'app/constants/color';
import {RATINGS} from 'app/constants/ratings';
import {STRATEGY_TREND, STRATEGY_TRENDS} from 'app/constants/strategyTrend';
import {PageContainer, ManagersDoughnutChart} from '../components';

export const ManagersPage = ({
  companyName,
  managersData,
  esgManagersPct,
  compliantManagersPct,
  engagedManagersPct,
  page,
  removeGraphs,
  amount,
  amountOfPages,
}) => {
  const ratingFormatter = ratingValue => {
    const rating = isNumber(ratingValue) ? RATINGS[ratingValue] || 'UR' : ratingValue;
    const ratingColors = {A: COLOR.PLATINUM, B: COLOR.GOLD, C: COLOR.SILVER, D: COLOR.BRONZE, E: COLOR.SUB_STANDARD};
    const style = {
      width: '22px',
      height: '22px',
      lineHeight: '22px',
      borderRadius: '50%',
      border: '2.5px solid',
      borderColor: ratingColors[rating],
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      color: COLOR.BLACK,
      display: 'flex',
      paddingRight: '0.5px',
    };
    return <div style={rating !== 'UR' ? style : {}}>{rating}</div>;
  };

  const trendFormatter = row => {
    const trend = find(STRATEGY_TRENDS, {key: row.trending}) || STRATEGY_TREND.SAME;

    const displayText = <Icon className={`is-${trend.key}`} icon={trend.icon} />;

    return (
      <div className="trend-arrow">
        <span>{displayText}</span>
      </div>
    );
  };

  return (
    <PageContainer
      headerType={PageContainer.HEADER_TYPE.TYPE_2}
      footer={true}
      companyName={companyName}
      pageNumber={page}
    >
      {removeGraphs && (
        <React.Fragment>
          <h2>Managers</h2>
          <h3 className={'text-uppercase'}>Overview</h3>
          <div className="manager-page-chart-container">
            <div className="manager-page-chart-card-container">
              <ManagersDoughnutChart
                data={[{active: esgManagersPct, inActive: 100 - esgManagersPct}]}
                labels={['Integrating', 'Not Integrating']}
                unit={'%'}
                caption={esgManagersPct + '%'}
              />
              <div className="align-self-center">
                <p className="manager-page-chart-text">Managers</p>
                <p className="manager-page-chart-text">Integrating ESG</p>
              </div>
            </div>
            <div className="manager-page-chart-card-container">
              <ManagersDoughnutChart
                data={[{active: compliantManagersPct, inActive: 100 - compliantManagersPct}]}
                labels={['Compliant', 'Not Compliant']}
                unit={'%'}
                caption={compliantManagersPct + '%'}
              />
              <div className="align-self-center">
                <p className="manager-page-chart-text">Managers Compliant</p>
                <p className="manager-page-chart-text">with Guidelines</p>
              </div>
            </div>
            <div className="manager-page-chart-card-container">
              <ManagersDoughnutChart
                data={[{active: engagedManagersPct, inActive: 100 - engagedManagersPct}]}
                labels={['Engaged', 'Not Engaged']}
                unit={'%'}
                caption={engagedManagersPct + '%'}
              />
              <div className="align-self-center">
                <p className="manager-page-chart-text">Managers Engaged</p>
                <p className="manager-page-chart-text">on Harmony Platform</p>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <h3 className={'text-uppercase'}>Products {amountOfPages ? `${amount} of ${amountOfPages}` : ''}</h3>
      <table style={{width: '100%', borderCollapse: 'collapse'}}>
        <thead>
          <tr style={{borderBottom: '1px solid black'}}>
            <th className="manager-page-table-header text-uppercase">Manager</th>
            <th className="manager-page-table-header text-uppercase">Product</th>
            <th className="manager-page-table-header text-uppercase">Index</th>
            <th className="manager-page-table-header text-uppercase">Strategy</th>
            <th className="manager-page-table-header text-uppercase">Compliance</th>
            <th className="manager-page-table-header text-uppercase">Rating</th>
            <th className="manager-page-table-header text-uppercase">Trend</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{paddingBottom: 20}}></td>
          </tr>
          {managersData.map(data => {
            return (
              <tr key={data.id} className="manager-page-row">
                <td className="manager-page-cell">{data.firm.name}</td>
                <td className="manager-page-cell">{data.name}</td>
                <td className="manager-page-cell">{data && data.marketIndex ? data.marketIndex.name : 'N/A'}</td>
                <td className="manager-page-cell">{data.portfolioManagementType}</td>
                <td className="manager-page-cell text-center">{data.policyViolation}</td>
                <td className="manager-page-cell">{ratingFormatter(data.weightedAverageRating)}</td>
                <td className="manager-page-cell">{trendFormatter(data)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </PageContainer>
  );
};
