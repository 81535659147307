import map from 'lodash/map';

import faBars from 'app/fontawesome-pro-light/faBars';
import faThLarge from 'app/fontawesome-pro-light/faThLarge';

export const ISSUE_VIEW_MODE = {
  CARD: {
    key: 'CARD',
    icon: faThLarge,
    label: 'Card View',
  },
  LIST: {
    key: 'LIST',
    icon: faBars,
    label: 'List View',
  },
};

export const ISSUE_VIEW_MODES = map(ISSUE_VIEW_MODE, value => value);
