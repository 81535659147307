import React, {useEffect} from 'react';
import styled from 'styled-components';

import {Theme} from '../atoms/theme';
import {Subtitle} from '../atoms/Typeface';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1.563rem;
`;

const CustomRadio = styled.div`
  min-width: 1.25em;
  height: 1.25em;
  border: 2px solid ${Theme.colors.grey_3};
  border-radius: 50%;
  margin-right: 0.938rem;
  box-sizing: border-box;
  padding: 2px;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: ${Theme.colors.harmony};
    border-radius: 50%;
    transform: scale(0);
  }
`;

const NativeRadio = styled.input`
  display: none;
  &:checked + ${CustomRadio}::after {
    transform: scale(1);
  }
`;

export const Radio = ({labels = [], values = [], name = 'food', onChange, value}) => {
  const handleChange = e => {
    onChange(e);
  };

  return (
    <Container onChange={handleChange}>
      {labels.map((label, index) => (
        <Label for={`${label}${index}`} key={index}>
          <NativeRadio
            type="radio"
            id={`${label}${index}`}
            value={values[index]}
            name={name}
            checked={values[index] === value}
          />
          <CustomRadio class="radio-radio" />
          <Subtitle color={Theme.colors.dark}>{label}</Subtitle>
        </Label>
      ))}
    </Container>
  );
};
