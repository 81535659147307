import React, {useState} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import faAngleRight from 'app/fontawesome-pro-light/faAngleRight';
import faAngleLeft from 'app/fontawesome-pro-light/faAngleLeft';
import faChevronDown from 'app/fontawesome-pro-light/faChevronDown';

/**
 * Format the message to diaplay for an empty table (no records)
 */
const formatEmptyTable = () => {
  return 'No records found';
};

const BootstrapTableRemote = ({
  keyField = 'id',
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  defaultSorted,
  selectRow,
  onSelect,
  disablePagination,
  tableProps,
  rowEvents,
  hover = true,
}) => {
  const [visible, setVisible] = useState(false);
  const pagination = paginationFactory({
    page,
    sizePerPage,
    totalSize,
    sizePerPageList: [
      {text: '25 per page', value: 25},
      {text: '50 per page', value: 50},
      {text: '75 per page', value: 75},
      {text: '100 per page', value: 100},
    ],
    showTotal: true,
    hidePageListOnlyOnePage: true,
    firstPageText: 'First',
    prePageText: <Icon icon={faAngleLeft} />,
    nextPageText: <Icon icon={faAngleRight} />,
    lastPageText: 'Last',
    sizePerPageClass: 'btn-outline',
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({options, currSizePerPage, onSizePerPageChange}) => (
      <div style={{display: 'inline-block'}}>
        <div className="react-bootstrap-per-page">
          <button className="btn btn-outline" style={{zIndex: 1}} onClick={() => setVisible(!visible)}>
            {currSizePerPage} per page <Icon icon={faChevronDown} className="btn-icon-after" />
          </button>
          {options.map(option => (
            <button
              className="react-bootstrap-per-page-list"
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              style={{display: visible ? 'block' : 'none'}}
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>
    ),
    paginationTotalRenderer: (from, to, size) => {
      return size ? (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size}
        </span>
      ) : null;
    },
  });

  return (
    <div>
      <BootstrapTable
        hover={hover}
        bordered={false}
        remote={{pagination: !disablePagination}}
        keyField={keyField}
        data={data}
        columns={columns}
        rowEvents={rowEvents}
        pagination={disablePagination ? null : pagination}
        onTableChange={onTableChange}
        defaultSorted={defaultSorted}
        noDataIndication={formatEmptyTable}
        selectRow={selectRow}
        onSelect={onSelect}
        {...tableProps}
      />
    </div>
  );
};

BootstrapTableRemote.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  defaultSorted: PropTypes.array,
  disablePagination: PropTypes.bool,
  tableProps: PropTypes.object,
  hover: PropTypes.bool,
};

export {BootstrapTableRemote};
