import {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {flatMap, reduce} from 'lodash';
import styled from 'styled-components';
import {DWDashboardHeader} from 'v2/components/delegated-wrappers/DWDashboardHeader';
import {ROUTE} from 'v2/constants/routes';
import {DSecondaryHeader} from 'v2/utilities/delegates/DSecondaryHeader';
import {BackButton} from 'v2/components/molecules/BackButtons';
import {Callback, Middleware} from './types';
import {updateFilterOptions} from 'v2/redux/strategyFilter';
import {getSessionJwt} from 'app/utilities';
import {useGetAggregatePercentileHoldingsQuery} from 'v2/redux/harmonyApi';
import {getData} from 'v2/utilities/helpers';
import {setTypesFilters} from 'v2/utilities/helpers/calculateStatistics';
import {DashboardTabsCard} from 'v2/components/organisms/TabsCards';
import {FlexContainer} from 'v2/components/atoms/Containers';

const ContentContainer = styled.div`
  flex: 1;
  width: auto;
  max-width: ${props => props.theme.constants.fixWidth};
  margin: 0 auto;
`;

const Layout: React.FC<{}> = ({children}) => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {strategyId = ''} = useParams<{strategyId?: string}>();

  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const holdingsQueries = [
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 1}),
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 2}),
    useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 3}),
  ];

  const filters = useSelector((state: RootStateOrAny) => {
    const {
      strategyFilter: {typeOptions, classOptions, regionOptions},
    } = state;

    return {
      typeOptions,
      classOptions,
      regionOptions,
    };
  });

  const selectedFilters = useSelector((state: RootStateOrAny) => {
    const {
      strategyFilter: {typeValue, classValue, regionValue},
    } = state;

    return {
      typeValue,
      classValue,
      regionValue,
    };
  });

  const type = useSelector((state: any) => {
    return state?.strategyFilter?.typeValue?.value;
  });

  const planDashboardIsLoading = useSelector((state: any) => {
    return state?.planDashboard?.isFetching;
  });

  const isLoading = holdingsQueries.filter(query => query.status !== 'fulfilled').length > 0;

  const companyTitle: string = useSelector((state: RootStateOrAny) => state?.planDashboard?.details?.name);

  const delegate = new DSecondaryHeader(
    filters,
    selectedFilters,
    'Dashboard',
    // @ts-ignore
    companyTitle,
    pathname,
    //@ts-ignore
    pathname === ROUTE.UT1.DASHBOARD.MANAGER.path,
    isLoading || planDashboardIsLoading,
  );

  useEffect(() => {
    let holdings = reduce(holdingsQueries, getData, []);

    const {typeFilters, classFilters, regionFilters} = setTypesFilters(holdings);

    dispatch(
      updateFilterOptions({
        type: Object.values(typeFilters),
        class: Object.values(classFilters[type]),
        region: Object.values(regionFilters),
      }),
    );
  }, []);

  return (
    <>
      <DWDashboardHeader delegate={delegate} />
      {pathname.includes('dashboard') && <DashboardTabsCard />}
      {pathname.includes('data-explorer') && (
        <FlexContainer margin="1.5rem auto 0">
          <BackButton path={ROUTE.UT1.DASHBOARD.RISK.path}>Return to Dashboard</BackButton>
        </FlexContainer>
      )}
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

export const dashboardHeader: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
