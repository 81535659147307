import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Local Imports
import {FORM_MESSAGE} from 'app/constants';
import faExclamationCircle from 'app/fontawesome-pro-light/faExclamationCircle';

const FormFeedback = ({children, className, color, icon, message, visible}) => (
  <div className={cn('form-feedback-summary', {[`is-${color}`]: color}, className)} style={{opacity: visible ? 1 : 0}}>
    <div className="form-feedback-summary-icon">
      <Icon icon={icon} />
    </div>
    <div className="form-feedback-summary-message">{children || message}</div>
  </div>
);

FormFeedback.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.object,
  message: PropTypes.node,
  visible: PropTypes.bool,
};

FormFeedback.defaultProps = {
  icon: faExclamationCircle,
  visible: false,
  message: FORM_MESSAGE.DEFAULT_ERROR_SUMMARY_MESSAGE,
};

export {FormFeedback};
