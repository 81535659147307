import React, {FC} from 'react';
import styled from 'styled-components/macro';
import TextareaAutosize from 'react-textarea-autosize';
import {TextareaInput as TextareaInputProps} from 'v2/utilities/types/components/TextareaInput';
import {Container, FlexContainer} from '../atoms/Containers';
import {ParagraphS} from '../atoms/Typeface';

const StyledInput = styled(TextareaAutosize)<TextareaInputProps>`
  flex: 1;
  background-color: transparent;
  border-width: 0;
  padding: 0.75rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  width: inherit;
  height: inherit;
  resize: none;
  color: ${({theme}) => theme.colors.dark};

  &:focus {
    outline: none;
  }
`;

const HintSpan = styled.span`
  font-size: 0.75rem;
  color: ${({theme}) => theme.colors.grey_5};
  line-height: 1.25rem;
  margin-top: 0.5rem;
`;

const Subtext = styled(ParagraphS)`
  font-size: 0.75rem;
  color: ${({theme}) => theme.colors.grey_5};
`;

const InputContainer = styled(FlexContainer)<
  React.HTMLAttributes<HTMLDivElement> & {hasError?: boolean; disabled?: boolean}
>`
  height: ${({height}) => height ?? '9.625rem'};
  width: ${({width}) => width ?? '100%'};
  background-color: ${({theme, hasError}) => (hasError ? theme.colors.red_2 : theme.colors.grey_2)};
  border-radius: ${({theme}) => theme.constants.border_radius};
  border: 1px solid transparent;

  &:focus-within {
    border-color: ${({theme}) => theme.colors.harmony};
    box-shadow: 0px 0px 0px 4px ${({hasError}) => (hasError ? 'rgba(250, 82, 82, 0.4)' : 'rgba(143, 209, 239, 0.5)')};
  }

  opacity: ${({disabled}) => (disabled ? 0.8 : 1)};
`;

export const TextareaInput = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof StyledInput>>(
  ({id, label, required, requirementText, containerProps, hasError, disabled, ...rest}, ref) => {
    return (
      <FlexContainer flex={1} direction="column" minWidth="18.75rem" fullWidth>
        {label && (
          <Container padding="0 0 0.125rem">
            <ParagraphS as="label" htmlFor={id}>
              {label}
              {required && '*'}
            </ParagraphS>
          </Container>
        )}
        <InputContainer disabled={disabled} {...containerProps}>
          <StyledInput ref={ref} id={id} required={required} disabled={disabled} minRows={6} {...rest} />
        </InputContainer>
        {requirementText && (
          <Container>
            <Subtext>{requirementText}</Subtext>
          </Container>
        )}
      </FlexContainer>
    );
  },
);

interface TextAreaFormGroupProps {
  disabled: boolean;
  input: any;
  label: string;
  autoComplete: string;
  maxLength: string;
  meta: {touched: boolean; error: string};
  placeholder: string;
  className: string;
  inputClassName: string | {};
  autoFocus: boolean;
  labelHint: any;
  id: string;
}

export const TextAreaFormGroup: FC<TextAreaFormGroupProps> = ({
  disabled,
  input,
  label,
  placeholder,
  maxLength,
  autoComplete,
  className,
  inputClassName,
  id,
  autoFocus,
  labelHint,
  meta: {touched, error},
}) => (
  <div>
    <div>{label && <label htmlFor={id || input.name}>{label}</label>}</div>
    <TextareaInput
      {...input}
      id={id || input.name}
      disabled={disabled}
      maxLength={maxLength}
      autoComplete={autoComplete}
      placeholder={placeholder}
      autoFocus={autoFocus}
      hasError={touched && Boolean(error)}
    />
    <FlexContainer justify="flex-end">{labelHint && <HintSpan style={{}}>{labelHint}</HintSpan>}</FlexContainer>
    <div className="help-block">{touched && error}</div>
  </div>
);
