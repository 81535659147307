import React, {Component} from 'react';
import {connect} from 'react-redux';
import {find, isEmpty, get} from 'lodash';
import {compose} from 'recompose';

import {ROUTE} from 'app/constants';
import {EmailAndImaDraft} from 'app/components';
import {getAllManagersPaged} from 'app/redux/firm';
import {fetchMarketIndexesIfNeeded} from 'app/redux/marketIndex';
import {sortAddressesByType, sortPhonesByType, withUser} from 'app/utilities';

class HarmonyIndexDraftPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchMarketIndexesIfNeeded());

    props.dispatch(
      getAllManagersPaged({
        search: '',
        page: 1,
        pageSize: 500, // effectively not paged
        sortField: 'name',
        sortOrder: 'asc',
      }),
    );
  }

  render() {
    const {isFetching, proposedMarketIndex, strategy, userFullName} = this.props;

    const user = get(strategy, 'user') || {};
    const {addresses, email, phones} = user;

    const draftProps = {
      address: isEmpty(addresses) ? null : sortAddressesByType(addresses)[0],
      email,
      firmName: get(strategy, 'firm.name'),
      fullName: [user.prefix, user.firstName, user.lastName, user.suffix].join(' '),
      isFetching,
      marketIndexName: get(proposedMarketIndex, 'name'),
      phone: isEmpty(phones) ? null : sortPhonesByType(phones)[0],
      userFullName,
    };

    return (
      <div style={{maxWidth: 680}}>
        <h1 className="mb-4">{ROUTE.HARMONY_INDEX_DRAFT.title}</h1>
        <EmailAndImaDraft {...draftProps} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {firm, marketIndex} = state;
  const {
    match: {
      params: {strategyId},
    },
    authUser: {firstName, lastName},
  } = ownProps;

  const isFetching = firm.isFetching || !marketIndex.hasFetched;
  const strategy = isFetching ? null : find(firm.allFirmsWithStrategy, {id: parseInt(strategyId, 10)});

  // Harmony Index conversion in progress
  let proposedMarketIndex;
  if (strategy) {
    proposedMarketIndex = strategy.marketIndex;
    if (strategy.harmonyIndexConversionMarketIndexId) {
      proposedMarketIndex =
        find(marketIndex.all, {id: strategy.harmonyIndexConversionMarketIndexId}) || proposedMarketIndex;
    }
  }

  return {
    isFetching,
    proposedMarketIndex,
    strategy,
    userFullName: `${firstName} ${lastName}`,
  };
};

const mapDispatchToProps = dispatch => {
  return {dispatch};
};

export const HarmonyIndexDraft = compose(withUser, connect(mapStateToProps, mapDispatchToProps))(HarmonyIndexDraftPage);
