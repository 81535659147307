export class DMarketIndexRating {
  indexData: any[]; //TODO: Use types
  percentileRank: number;

  constructor(indexData: any[]) {
    this.indexData = indexData;
    this.percentileRank = this.calcPercentileRank(this.indexData);
  }

  protected calcPercentileRank(indexData: any[]) {
    let totalWeight = 0;
    let totalWeightedRanks = 0;

    console.log('indexData', indexData);
    indexData.forEach((datum: any) => {
      totalWeight += Number(datum.market_cap);
      totalWeightedRanks += Number(datum.market_cap) * Number(datum.company_pct_rank);
    });

    console.log(totalWeight, 'totalWeight');
    return totalWeightedRanks / totalWeight;
  }

  getPercentileRank(): number {
    return this.percentileRank; //TODO: Apply formatting
  }
}
