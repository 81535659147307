import PropTypes from 'prop-types';
import {Component} from 'react';
import {each, fill, indexOf, omit} from 'lodash';
import {change, getFormValues, reduxForm} from 'redux-form';
import {compose} from 'recompose';
import {connect} from 'react-redux';

import {ISSUES_OLD, TIME_PERIOD_COLORS} from 'app/constants';
import {getOptions, saveConfig} from 'app/redux/progressReport';

const FORM_NAME = 'progressReport';

const ALL_ISSUES_INCLUDED = {};
const NO_ISSUES_INCLUDED = {};
each(ISSUES_OLD, ({code}) => {
  ALL_ISSUES_INCLUDED[code] = true;
  NO_ISSUES_INCLUDED[code] = false;
});

class ProgressReportContainerComponent extends Component {
  componentDidMount() {
    this.props.getOptions();
  }

  handleFormChange = () => {
    setTimeout(this.props.handleSubmit);
  };

  handleSelectAllClick = () => {
    this.props.selectAllIssues();
    this.handleFormChange();
  };

  handleDeselectAllClick = () => {
    this.props.deselectAllIssues();
    this.handleFormChange();
  };

  render() {
    if (this.props.isFetching) return null;

    return this.props.children({
      ...omit(this.props, 'isFetching'),
      handleDeselectAllClick: this.handleDeselectAllClick,
      handleFormChange: this.handleFormChange,
      handleSelectAllClick: this.handleSelectAllClick,
    });
  }
}

ProgressReportContainerComponent.propTypes = {children: PropTypes.func.isRequired};

export const ProgressReportFormContainer = compose(
  // mapStateToProps:
  connect(
    (state, ownProps) => {
      const {isOptionsFetching, options} = state.progressReport;
      const {config = {}, periodData} = ownProps;

      const isFetching = isOptionsFetching;

      // Wait to sync up values until everything is loaded
      // This is super important for not setting initialValues too early
      if (isFetching) return {isFetching};

      // Merge blank time slot list with saved preferences
      const initialPeriods = new Array(TIME_PERIOD_COLORS.length);
      fill(initialPeriods, null);
      each(config.periods, (period, index) => {
        initialPeriods[index] = period;
      });

      // Map included issues to list of *all* issues
      const initialIssues = {};
      let hasAnySelectedIssue = true;
      let hasNoSelectedIssue = true;
      each(ISSUES_OLD, ({code}) => {
        if (config.includedIssues) {
          const isIncluded = indexOf(config.includedIssues, code) >= 0;
          initialIssues[code] = isIncluded;
          if (isIncluded) {
            hasNoSelectedIssue = false;
          } else {
            hasAnySelectedIssue = false;
          }
        } else {
          // default to all issues included
          initialIssues[code] = true;
          hasNoSelectedIssue = false;
        }
      });

      return {
        formValues: getFormValues(FORM_NAME)(state),
        hasAnySelectedIssue,
        hasNoSelectedIssue,
        isFetching,
        options,
        periodData,
        initialValues: {
          periods: initialPeriods,
          issues: initialIssues,
          issueViewMode: config.issueViewMode,
          progressReportIncludeCertification: config.progressReportIncludeCertification,
        },
      };
    },
    // mapDispatchToProps:
    dispatch => ({
      getOptions: () => dispatch(getOptions()),
      saveConfig: config => dispatch(saveConfig(config)),
      setAllIssues: issueValues => {
        dispatch(change(FORM_NAME, 'issues', issueValues));
      },
    }),
    // mergeProps:
    (stateProps, dispatchProps, ownProps) => {
      return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmit: ({issues, periods, issueViewMode, progressReportIncludeCertification}) => {
          const includedIssues = [];
          each(issues, (isIncluded, issueCode) => {
            if (isIncluded) includedIssues.push(issueCode);
          });
          const config = {includedIssues, periods, issueViewMode, progressReportIncludeCertification};
          dispatchProps.saveConfig(config);
        },
        deselectAllIssues: () => {
          dispatchProps.setAllIssues(NO_ISSUES_INCLUDED);
        },
        selectAllIssues: () => {
          dispatchProps.setAllIssues(ALL_ISSUES_INCLUDED);
        },
      };
    },
  ),
  reduxForm({form: FORM_NAME}),
  connect(
    null,
    // mapDispatchToProps (with access to formValues and saveConfig):
    (dispatch, props) => ({
      changeFormValueAndSave: (field, value) => {
        dispatch(change(FORM_NAME, field, value));
        props.onSubmit({...props.formValues, [field]: value});
      },
    }),
  ),
)(ProgressReportContainerComponent);
