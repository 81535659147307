import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, isEmpty, includes} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {compose, lifecycle, withHandlers, withProps} from 'recompose';
import {SubmissionError} from 'redux-form';

// Local Imports
import {AdminSecurityEditForm} from 'app/components/AdminSecurityEditForm';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {getSecurity, saveSecurity} from 'app/redux/adminSecurity';
import {toastError, toastSuccess} from 'app/utilities/toast';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';

const formName = 'admin-security-edit';
const {scrollToTop} = animateScroll;

class AdminSecurityEditPage extends Component {
  render() {
    const {security} = this.props;
    const securityName = get(security, 'name', '');
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <Link
            className="d-inline-block mb-4 small"
            to={{
              pathname: ROUTE.ADMIN_SECURITY_LISTING.path(),
              search: this.props.location.search,
            }}
          >
            <Icon icon={faArrowLeft} className="mr-2" />
            Return to {ROUTE.ADMIN_SECURITY_LISTING.title}
          </Link>
          {!isEmpty(security) && (
            <Fragment>
              <h1 className="mb-4">Edit "{securityName.trim()}"</h1>
              <AdminSecurityEditForm {...this.props} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

// Loads and provides 'Security' by id from state
const withSecurity = compose(
  connect(
    // mapStateToProps
    (state, ownProps) => {
      const {securityId} = ownProps.match.params;

      const security = {...get(state, `adminSecurity.edit.${securityId}.data`, {})};

      // Clean up null values from the database
      if (!isEmpty(security)) {
        security.isEmpoweringChange = !!security.isEmpoweringChange;
        security.hasBeenContactedToEmpowerChange = !!security.hasBeenContactedToEmpowerChange;
        security.hasRespondedToEmpowerChange = !!security.hasRespondedToEmpowerChange;
      }

      return {
        form: formName,
        security,
      };
    },
    // mapDispatchToProps
    {getSecurity},
  ),
  lifecycle({
    componentDidMount() {
      this.props
        .getSecurity(this.props.match.params.securityId)
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }
          scrollToTop();
        })
        .catch(() => {
          scrollToTop();
          toastError('Error retrieving Company.');
        });
    },
  }),
);

const mapStateToProps = state => {
  return {
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_COMPANIES),
  };
};

// Provides 'initialValues', plus 'onSubmitFail' and 'onSubmit' callbacks
const withSecurityForm = compose(
  connect(
    mapStateToProps,
    // mapDispatchToProps
    {saveSecurity},
  ),
  withProps(ownProps => ({initialValues: {...ownProps.security}, enableReinitialization: true})),
  withHandlers({
    onSubmit: props => values => {
      const {securityId} = props.match.params;

      return props
        .saveSecurity(securityId, values)
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('The Company has been saved.');

          scrollToTop();

          props.dispatch(
            push({
              pathname: ROUTE.ADMIN_SECURITY_LISTING.path(),
              search: props.location.search,
            }),
          );
        })
        .catch(() => {
          scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    onSubmitFail: () => () => scrollToTop(),
  }),
);

export const AdminSecurityEdit = compose(withSecurity, withSecurityForm)(AdminSecurityEditPage);
