import {useMemo, useState} from 'react';
import styled from 'styled-components';
import ReactPlaceHolder from 'react-placeholder';
import {TextBlock, RectShape} from 'react-placeholder/lib/placeholders';
import {AttachedWrapper, Container, FlexContainer} from 'v2/components/atoms/Containers';
import {Search} from 'v2/components/atoms/icons/Search';
import {SearchIndex} from 'v2/components/atoms/icons/SearchIndex';
import {Voting} from 'v2/components/atoms/icons/Voting';
import {Theme} from 'v2/components/atoms/theme';
import {AnnotatedDoughnutChart} from 'v2/components/molecules/AnnotatedDoughnutChart';
import {HorizontalBarChart} from 'v2/components/molecules/HorizontalBarChart';
import {IconPercentDisplay} from 'v2/components/molecules/IconPercentDisplay';
import {Radio} from 'v2/components/molecules/Radio';
import {MANAGER_ROLES} from 'v2/constants/managers';
import {Eye} from 'v2/components/atoms/icons/Eye';
import {Sliders} from 'v2/components/atoms/icons/Sliders';
import {TrendingDown} from 'v2/components/atoms/icons/TrendingDown';
import {ManagerModal} from 'v2/components/molecules/ManagerModal';
import {ManagerData, MANAGER_LIST_TYPES, useDashboardManagers} from 'v2/hooks/useDashboardManagers';
import {useSetUT1DashFilterOptions} from 'v2/utilities/helpers/setUT1DashFilterOptions';

const RadioContainer = styled.div`
  padding: 1.7rem 0 1.875rem 0;
  & > div label {
    white-space: nowrap;
  }
`;

const MainHeaderContainer = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0 0 1.876rem 0;
`;

const HeaderContainer = styled.div`
  padding-right: 20px;
  margin: 1.875rem 0 0 0;
  cursor: pointer;
`;

const ESGSubheader = styled.div`
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0.875rem 0;
`;

const BarChartContainer = styled.div`
  margin-left: -10px;
  width: 100%;
  cursor: pointer;
`;

const LargePercentContainer = styled.div`
  font-family: Inter;
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  margin-right: 0.875rem;
`;

const SubHeadingContainer = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
`;

const radioOptions = {
  labels: ['All', 'Board\xa0& C-level', 'Professionals', 'Support'],
  values: [MANAGER_ROLES.ALL, MANAGER_ROLES.BOARD, MANAGER_ROLES.PROFESSIONALS, MANAGER_ROLES.SUPPORT],
};

interface ManagersData {
  title: string;
  data: ManagerData;
}

const maxIndex = (array: any[]) =>
  array.reduce((maxIndex, current, i, arr) => (current > arr[maxIndex] ? i : maxIndex), 0);

const ReactManagerPlaceholder = () => {
  return (
    <>
      <FlexContainer padding="2.5rem 1.75rem 3rem 1.75rem" gap="0.75rem">
        <FlexContainer direction="column" flex={1}>
          <HeaderContainer>
            <TextBlock rows={1} color={Theme.colors.grey_2} style={{width: 200, height: 40, marginBottom: 20}} />
            <FlexContainer items="center">
              <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 60}} />
              <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 30, marginLeft: 20}} />
            </FlexContainer>
            <FlexContainer items="center">
              <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 20, margin: '20px 0'}} />
            </FlexContainer>
            <FlexContainer items="center">
              <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 10, margin: '20px 20px'}} />
            </FlexContainer>
          </HeaderContainer>
          <FlexContainer direction="column" gap="2.875rem">
            <FlexContainer direction="column" padding="0 1.875rem 0 0.75rem" gap="1.875rem">
              <FlexContainer justify="space-between" padding="2rem 0 .5rem" gap="1rem">
                <FlexContainer gap="0.25rem">
                  <RectShape color={Theme.colors.grey_2} style={{width: 40, height: 40}} />
                  <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 80}} />
                </FlexContainer>
                <FlexContainer gap="0.25rem">
                  <RectShape color={Theme.colors.grey_2} style={{width: 40, height: 40}} />
                  <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 80}} />
                </FlexContainer>
                <FlexContainer gap="0.25rem">
                  <RectShape color={Theme.colors.grey_2} style={{width: 40, height: 40}} />
                  <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 80}} />
                </FlexContainer>
              </FlexContainer>
              <FlexContainer justify="space-between" gap="1rem">
                <FlexContainer gap="0.25rem">
                  <RectShape color={Theme.colors.grey_2} style={{width: 40, height: 40}} />
                  <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 80}} />
                </FlexContainer>
                <FlexContainer gap="0.25rem">
                  <RectShape color={Theme.colors.grey_2} style={{width: 40, height: 40}} />
                  <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 80}} />
                </FlexContainer>
                <FlexContainer gap="0.25rem">
                  <RectShape color={Theme.colors.grey_2} style={{width: 40, height: 40}} />
                  <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 80}} />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer gap="0.875rem" justify="space-between">
              <FlexContainer direction="column" flex={1}>
                <TextBlock rows={1} color={Theme.colors.grey_2} style={{width: '100%', height: 40}} />
                <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 30}} />
              </FlexContainer>
              <FlexContainer direction="column" flex={1}>
                <TextBlock rows={1} color={Theme.colors.grey_2} style={{width: '100%', height: 40}} />
                <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 30}} />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer direction="column" flex={1}>
          <HeaderContainer>
            <TextBlock rows={1} color={Theme.colors.grey_2} style={{width: 200, height: 40, marginBottom: 20}} />
            <FlexContainer items="center">
              <RectShape color={Theme.colors.grey_2} style={{width: 80, height: 60}} />
              <RectShape color={Theme.colors.grey_2} style={{width: 300, height: 30, marginLeft: 20}} />
            </FlexContainer>
            <FlexContainer items="center">
              <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 20, margin: '20px 0'}} />
            </FlexContainer>
            <FlexContainer justify="center" items="center" margin="1rem 0 0" gap=".5rem">
              <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 20}} />
              <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 20}} />
              <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 20}} />
              <RectShape color={Theme.colors.grey_2} style={{width: 100, height: 20}} />
            </FlexContainer>
          </HeaderContainer>
          <FlexContainer justify="center" padding="3rem 0 0" gap="3rem">
            <FlexContainer direction="column" gap="1rem">
              <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 200}} />
              <TextBlock rows={2} color={Theme.colors.grey_2} style={{width: 200, height: 40, marginBottom: 20}} />
            </FlexContainer>
            <FlexContainer direction="column" gap="1rem">
              <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 200}} />
              <TextBlock rows={2} color={Theme.colors.grey_2} style={{width: 200, height: 40, marginBottom: 20}} />
            </FlexContainer>{' '}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export const UT1ManagersPage = () => {
  const [filter, setFilter] = useState(MANAGER_ROLES.ALL);
  useSetUT1DashFilterOptions();
  const {
    integrationData,
    diversityData: {genderData, ethnicityData},
    setEmployeeFilterType,
    getFilteredManagerList,
    isLoading,
    isSuccess,
  } = useDashboardManagers({initialEmployeeFilterType: MANAGER_ROLES.ALL});

  const [showModal, setShowModal] = useState(false);
  const [managersData, setManagersData] = useState<ManagersData>({
    title: '',
    data: {yes: [], no: []},
  });

  const {
    screening = 0,
    vetting = 0,
    voting = 0,
    scaling = 0,
    monitoring = 0,
    selling = 0,
    esgPolicy = 0,
    officer = 0,
    total = 0,
    workforceDiversity = 0,
  } = integrationData || {};

  const {men = 0, women = 0} = genderData || {};
  const {asian = 0, black = 0, hispanic = 0, other = 0, white = 0} = ethnicityData || {};

  const genderChartData = useMemo(
    () => [
      ['Gender', 'Percent'],
      ['Men', men],
      ['Women', women],
    ],
    [men, women],
  );

  const ethnicityChartData = useMemo(
    () => [
      ['Ethnicity', 'Percent'],
      ['White', white],
      ['Hispanic', hispanic],
      ['Asian', asian],
      ['Black', black],
      ['Other', other],
    ],
    [white, hispanic, asian, black, other],
  );

  const handleFilterChange = (e: {target: {value: any}}) => {
    setFilter(e?.target?.value);
    setEmployeeFilterType(e?.target?.value);
  };

  const handleShowModal = (managersData: ManagersData) => {
    setManagersData(managersData);
    setShowModal(true);
  };

  const selectedGenderArc = useMemo(() => maxIndex(genderChartData.slice(1).flatMap(s => s[1])) + 1, [genderChartData]);
  const selectedEthnicityArc = useMemo(
    () => maxIndex(ethnicityChartData.slice(1).flatMap(s => s[1])) + 1,
    [ethnicityChartData],
  );

  return (
    <AttachedWrapper>
      <ReactPlaceHolder ready={!isLoading && isSuccess} customPlaceholder={<ReactManagerPlaceholder />}>
        <FlexContainer padding="0 1.75rem 0 1.75rem" gap="0.75rem">
          <FlexContainer direction="column" flex={1}>
            <HeaderContainer
              onClick={() =>
                handleShowModal({
                  title: 'Managers Integrating ESG',
                  data: getFilteredManagerList({listType: MANAGER_LIST_TYPES.INTEGRATING_ESG}),
                })
              }
            >
              <MainHeaderContainer>ESG Integration</MainHeaderContainer>
              <FlexContainer items="center" margin="0 0 .75rem 0">
                <LargePercentContainer>{total}%</LargePercentContainer>
                <SubHeadingContainer>Managers Integrating ESG</SubHeadingContainer>
              </FlexContainer>
              <BarChartContainer>
                <HorizontalBarChart
                  labels={['Yes', 'No']}
                  datasets={[
                    {
                      label: 'Yes',
                      data: [total],
                      backgroundColor: Theme.colors.harmony,
                      barPercentage: 1,
                    },
                    {
                      label: 'No',
                      data: [100 - total],
                      backgroundColor: Theme.colors.grey_4,
                      barPercentage: 1,
                    },
                  ]}
                  height={14}
                  hideDataLabels
                />
              </BarChartContainer>
            </HeaderContainer>
            <FlexContainer direction="column" gap="2.875rem">
              <FlexContainer direction="column" padding="0 1.875rem 0 0.75rem" gap="1.875rem">
                <ESGSubheader>How Managers Integrate ESG</ESGSubheader>{' '}
                <FlexContainer justify="space-between">
                  <IconPercentDisplay
                    percent={screening}
                    title="Screening"
                    icon={<Search color={Theme.colors.blue_2} />}
                  />
                  <IconPercentDisplay
                    percent={vetting}
                    title="Vetting"
                    icon={<SearchIndex color={Theme.colors.blue_2} />}
                  />
                  <IconPercentDisplay percent={voting} title="Voting" icon={<Voting color={Theme.colors.blue_2} />} />
                </FlexContainer>
                <FlexContainer justify="space-between">
                  <IconPercentDisplay
                    percent={scaling}
                    title="Scaling"
                    icon={<Sliders color={Theme.colors.blue_2} />}
                  />
                  <IconPercentDisplay
                    percent={monitoring}
                    title="Monitoring"
                    icon={<Eye color={Theme.colors.blue_2} />}
                  />
                  <IconPercentDisplay
                    percent={selling}
                    title="Selling"
                    icon={<TrendingDown color={Theme.colors.blue_2} />}
                  />
                </FlexContainer>
              </FlexContainer>
              <FlexContainer gap="0.875rem" justify="space-between">
                <FlexContainer
                  direction="column"
                  flex={1}
                  style={{cursor: 'pointer'}}
                  onClick={() =>
                    handleShowModal({
                      title: 'Have an organization-level ESG Policy',
                      data: getFilteredManagerList({listType: MANAGER_LIST_TYPES.ORG_WIDE_ESG_POLICY}),
                    })
                  }
                >
                  <ESGSubheader>Have an organization-level ESG Policy</ESGSubheader>
                  <BarChartContainer>
                    <HorizontalBarChart
                      labels={['Yes', 'No']}
                      datasets={[
                        {
                          label: 'Yes',
                          data: [esgPolicy],
                          backgroundColor: Theme.colors.harmony,
                          barPercentage: 1,
                          datalabels: {
                            color: Theme.colors.white,
                          },
                        },
                        {
                          label: 'No',
                          data: [100 - esgPolicy],
                          backgroundColor: Theme.colors.grey_4,
                          barPercentage: 1,
                          datalabels: {
                            color: Theme.colors.dark,
                          },
                        },
                      ]}
                      height={78}
                    />
                  </BarChartContainer>
                </FlexContainer>
                <FlexContainer
                  direction="column"
                  flex={1}
                  style={{cursor: 'pointer'}}
                  onClick={() =>
                    handleShowModal({
                      title: 'Have an ESG Committee / Officer',
                      data: getFilteredManagerList({listType: MANAGER_LIST_TYPES.ESG_COMMITTEE}),
                    })
                  }
                >
                  <ESGSubheader>Have an ESG Committee / Officer</ESGSubheader>
                  <BarChartContainer>
                    <HorizontalBarChart
                      labels={['Yes', 'No']}
                      datasets={[
                        {
                          label: 'Yes',
                          data: [officer],
                          backgroundColor: Theme.colors.harmony,
                          barPercentage: 1,
                          datalabels: {
                            color: Theme.colors.white,
                          },
                        },
                        {
                          label: 'No',
                          data: [100 - officer],
                          backgroundColor: Theme.colors.grey_4,
                          barPercentage: 1,
                          datalabels: {
                            color: Theme.colors.dark,
                          },
                        },
                      ]}
                      height={78}
                    />
                  </BarChartContainer>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer direction="column" flex={1}>
            <HeaderContainer
              onClick={() =>
                handleShowModal({
                  title: 'Managers having a formal Diversity, Equity, and Inclusion policy',
                  data: getFilteredManagerList({listType: MANAGER_LIST_TYPES.WORKFORCE_DIVERSITY}),
                })
              }
            >
              <MainHeaderContainer>Workforce Diversity</MainHeaderContainer>
              <FlexContainer items="center" margin="0 0 .75rem 0">
                <LargePercentContainer>{workforceDiversity}%</LargePercentContainer>
                <SubHeadingContainer>
                  of managers have a formal Diversity, Equity, and Inclusion policy
                </SubHeadingContainer>
              </FlexContainer>
              <BarChartContainer>
                <HorizontalBarChart
                  labels={['Yes', 'No']}
                  datasets={[
                    {
                      label: 'Yes',
                      data: [workforceDiversity],
                      backgroundColor: Theme.colors.harmony,
                      barPercentage: 1,
                    },
                    {
                      label: 'No',
                      data: [100 - workforceDiversity],
                      backgroundColor: Theme.colors.grey_4,
                      barPercentage: 1,
                    },
                  ]}
                  height={14}
                  hideDataLabels
                />
              </BarChartContainer>
            </HeaderContainer>
            <RadioContainer
              // @ts-ignore
              onChange={handleFilterChange}
            >
              <Radio
                name="type"
                labels={radioOptions.labels}
                values={radioOptions.values}
                onChange={handleFilterChange}
                value={filter}
              />
            </RadioContainer>

            <FlexContainer justify="center">
              <Container>
                <AnnotatedDoughnutChart
                  data={genderChartData}
                  title={'Gender'}
                  colors={[Theme.colors.harmony, Theme.colors.blue_5]}
                  bottomTitle
                  selectedArc={selectedGenderArc}
                  usesExternalState
                />
              </Container>
              <Container>
                <AnnotatedDoughnutChart
                  data={ethnicityChartData}
                  title={'Ethnicity'}
                  colors={[
                    Theme.colors.harmony,
                    Theme.colors.blue_1,
                    Theme.colors.blue_2,
                    Theme.colors.blue_3,
                    Theme.colors.blue_4,
                  ]}
                  bottomTitle
                  selectedArc={selectedEthnicityArc}
                  usesExternalState
                  legendAlign="left"
                />
              </Container>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <ManagerModal
          title={managersData.title}
          data={managersData.data}
          isOpen={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      </ReactPlaceHolder>
    </AttachedWrapper>
  );
};
