import React, {Component, Fragment} from 'react';
import {get, isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ONBOARDING_STEPS, ROLE, ROUTE} from 'app/constants';
import {Alert, OnboardingUt3ManagerForm} from 'app/components';
import {getByCurrentUserFirm, updateByCurrentUserFirm, upsertEmployees} from 'app/redux/firm';
import {toastSuccess} from 'app/utilities/toast';
import {withUser} from 'app/utilities';
import {retrieveFirstFileKeyIfTrueFromArray, retrieveFirstFileKeyFromArray} from 'app/utilities/fileFunctions';

class SettingsFirmProfilePage extends Component {
  componentDidMount() {
    this.props.dispatch(getByCurrentUserFirm());
  }

  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="settings-form">
        <h1>{ROUTE.SETTINGS_FIRM_PROFILE.title}</h1>
        {this.props.hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT}</Alert>}
        {!isEmpty(get(this.props, 'onboardingUser.firm')) && (
          <Fragment>
            <OnboardingUt3ManagerForm {...this.props} />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {authUser} = ownProps;
  const userRole = !isEmpty(authUser.roles) ? authUser.roles[0] : ROLE.USER_TYPE_1;

  // Using Onboarding specific form.  Expects 'onboardingUser' property passed in to populate it
  const onboardingUser = {firm: get(state, 'firm.editUserFirm')};

  return {
    hasError: get(state, 'firm.hasError'),
    isFetching: get(state, 'firm.isFetching'),
    onboardingUser,
    userRole,
  };
};
// TODO(PeterJ): Work on a better way to do this...changes are causing form to redraw and losing text changes
const mapDispatchToProps = (dispatch, ownProps) => {
  const {authUser} = ownProps;
  const userRole = !isEmpty(authUser.roles) ? authUser.roles[0] : ROLE.USER_TYPE_1;
  return {
    /*
     * TODO(PeterJ): Disabled for now to not automatically update dispatch a call to the API.
     * On response and store update, was causing rerender and losing unsaved changes to url / esg description
     */
    // onFileRemove: (fileInfo, multiUploader) => {
    //   const updateData = {
    //     onboardingStep: ONBOARDING_STEPS[userRole].MANAGER.key,
    //     [multiUploader.props.name || multiUploader.props.id]: null,
    //   };
    //
    //   return dispatch(updateByCurrentUserFirm(updateData));
    // },
    // onFileUpload: (fileInfo, s3file, multiUploader) => {
    //   const updateData = {
    //     onboardingStep: ONBOARDING_STEPS[userRole].MANAGER.key,
    //     [multiUploader.props.name || multiUploader.props.id]: s3file.fileKey,
    //   };
    //   return dispatch(updateByCurrentUserFirm(updateData));
    // },
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const updateData = {
        ...values,
        onboardingStep: ONBOARDING_STEPS[userRole].MANAGER.key,
      };

      // convert arrays to files
      updateData.adv1FilePath = retrieveFirstFileKeyFromArray(updateData.adv1FilePath);
      updateData.adv2FilePath = retrieveFirstFileKeyFromArray(updateData.adv2FilePath);
      updateData.priDocFilePath = retrieveFirstFileKeyIfTrueFromArray(
        updateData.priDocFilePath,
        updateData.hasPriAssociation,
      );
      updateData.griDocFilePath = retrieveFirstFileKeyIfTrueFromArray(
        updateData.griDocFilePath,
        updateData.hasGriAssociation,
      );
      updateData.esgPolicyFilePath = retrieveFirstFileKeyFromArray(updateData.esgPolicyFilePath);
      updateData.esgPolicyEstablishedAt = updateData.esgPolicyEstablishedAt || null;

      if (values.employees) {
        dispatch(upsertEmployees(values.employees));
      }

      return dispatch(updateByCurrentUserFirm(updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          toastSuccess('Manager Profile has been updated.');

          dispatch(push(ROUTE.SETTINGS.path()));
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const SettingsFirmProfile = compose(
  withUser,
  connect(mapStateToProps, mapDispatchToProps),
)(SettingsFirmProfilePage);
