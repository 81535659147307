import React, {Component} from 'react';
import {RANKING_TIER} from 'app/constants';
import {HoldingsPerformanceBarChart, PageContainer} from '../components';

export class SummaryPage extends Component {
  render() {
    const {
      companyName,
      overallUniverseRankingTier,
      overallOrgTypeRankingTier,
      compliantMangersPercent,
      engagedCompaniesCount,
      holdingsDataByWeight,
      universeDataByWeight,
      holdingsDataByCount,
      universeDataByCount,
    } = this.props;

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_2} companyName={companyName} pageNumber={3}>
        <h2>Performance Overview</h2>
        <div className={'summary-page'}>
          <div className={'d-flex flex-row'}>
            <div className={'stat-column flex-0'}>
              <h3 className={'text-uppercase'}>Peer Ranking</h3>
              <div className={'stat-block'}>
                <h3 className={'text-uppercase'}>{RANKING_TIER[overallUniverseRankingTier]}</h3>
                <span>Harmony Universe</span>
              </div>
              {/* <div className={'stat-block'}>
                <h3 className={'text-uppercase'}>{RANKING_TIER[overallOrgTypeRankingTier]}</h3>
                <span>Foundations</span>
              </div> */}
            </div>
            <div className={'stat-column flex-0'}>
              <h3 className={'text-uppercase'}>Engagement</h3>
              {/* <div className={'stat-block'}>
                <h3>{compliantMangersPercent}</h3>
                <span>Of your managers in<br/>compliance</span>
              </div> */}
              <div className={'stat-block'}>
                <h3>{engagedCompaniesCount}</h3>
                <span>Companies Engaged</span>
              </div>
            </div>
          </div>
          <div>
            <h3 className={'text-uppercase'}>Rating Distribution of Holdings</h3>
            <HoldingsPerformanceBarChart
              mode={HoldingsPerformanceBarChart.MODE.BY_WEIGHT}
              holdingsData={holdingsDataByWeight}
              universeData={universeDataByWeight}
            />
            <HoldingsPerformanceBarChart
              mode={HoldingsPerformanceBarChart.MODE.BY_COUNT}
              holdingsData={holdingsDataByCount}
              universeData={universeDataByCount}
            />
          </div>
        </div>
      </PageContainer>
    );
  }
}
