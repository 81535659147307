import styled from 'styled-components/macro';
import {Container, FlexContainer, FloatingWrapper, Wrapper} from 'v2/components/atoms/Containers';
import HarmonyLogo from '../assets/images/Logo_Harmony.svg';
import * as TERMS from 'v2/constants/termsOfUse';

const Title = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

const OrderedList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  margin: 0;
  padding: 0 0 0 1rem;
`;

const ListItem = styled.li`
  padding: 0 1rem 0;
`;

const Paragraph = styled.span`
  &:before {
    content: '\u00A0 \u00A0';
  }
`;

const Logo = styled.img.attrs({
  src: HarmonyLogo,
})`
  width: 25rem;
  margin: 8rem auto 0;
`;

const Footer = styled.span`
  color: ${({theme}) => theme.colors.grey_7};
`;

export const TermsOfUse = () => {
  return (
    <Wrapper margin="2rem auto">
      <FloatingWrapper>
        <FlexContainer>
          <Logo />
        </FlexContainer>
        <FlexContainer direction="column" gap="1.7rem" padding="3rem">
          <FlexContainer justify="center">
            <Title>Terms of Use</Title>
          </FlexContainer>
          <Container>{TERMS.INTRODUCTION}</Container>
          <Container>
            <OrderedList>
              <ListItem>
                <Title>Permissible Use:</Title>
                <Paragraph>{TERMS.PERMISSIBLE_USE}</Paragraph>
              </ListItem>
              <ListItem>
                <Title>Limitaition of Liability:</Title>
                <Paragraph>{TERMS.LIMITATION_OF_LIABILITY}</Paragraph>
              </ListItem>
              <ListItem>
                <Title>Disclaimer of Warranties:</Title>
                <Paragraph>{TERMS.DISCLAIMER_OF_WARRANTIES}</Paragraph>
              </ListItem>
              <ListItem>
                <Title>Not Financial Advice:</Title>
                <Paragraph>{TERMS.NOT_FINANCIAL_ADVISE}</Paragraph>
              </ListItem>
            </OrderedList>
          </Container>
          <FlexContainer padding="3rem 0" justify="center">
            <Footer>{TERMS.ADDRESS}</Footer>
          </FlexContainer>
        </FlexContainer>
      </FloatingWrapper>
    </Wrapper>
  );
};
