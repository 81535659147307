import {includes, trim, get} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

// Local Imports
import {Button, FormFeedback} from 'app/components';
import {FORM_MESSAGE, ROLE} from 'app/constants';
import {login, authLoginComplete} from 'app/redux/auth';

class LoginFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: null,
        password: null,
        rememberMe: false,
      },
      formErrorMessage: null,
    };
  }

  onFormChange = event => {
    const self = this;
    const formChange = {};
    formChange[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    self.setState({form: Object.assign(self.state.form, formChange)});
  };

  login = event => {
    event.preventDefault();

    const body = Object.assign({}, this.state.form);
    if (!body.email || !body.password) {
      this.setState({formErrorMessage: 'Please provide a valid email and password'});
      return;
    }

    this.props
      .login(trim(body.email), body.password, body.rememberMe)
      .then(async user => {
        const isAdmin = includes(user.roles, ROLE.ADMIN);
        const isUserType4 = includes(user.roles, ROLE.USER_TYPE_4);
        const onAdminPage = this.props.history.location.pathname.indexOf('/admin') >= 0;
        const onLoginPage = this.props.history.location.pathname.indexOf('/login') >= 0;
        const onRootPage = this.props.history.location.pathname === '/';

        return onLoginPage || ((isAdmin || isUserType4) && onRootPage) || (!isAdmin && onAdminPage)
          ? this.props.onLoginComplete(user.roles)
          : this.props.dispatch(authLoginComplete());
      })
      .catch(error => {
        if (error.message.trim() === 'Invalid email or password') {
          return this.setState({formErrorMessage: FORM_MESSAGE.INVALID_CREDENTIALS});
        }

        return this.setState({formErrorMessage: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
      });
  };

  render() {
    const {hideAuthenticatedError, isNotAuthenticated, history} = this.props;
    const {formErrorMessage} = this.state;
    const feedback = {color: 'danger'};

    if (formErrorMessage) {
      feedback.message = formErrorMessage;
    } else if (isNotAuthenticated && history.location.pathname !== '/' && !hideAuthenticatedError) {
      feedback.message = 'You must be logged in to view this page.';
    }

    return (
      <form action="post" onSubmit={this.login} className="mb-3">
        <FormFeedback {...feedback} visible={!!feedback.message} />
        <div className="form-group">
          <label className="small">Email</label>
          <input className="form-control" name={'email'} autoComplete="email" onChange={this.onFormChange} />
        </div>
        <div className="form-group">
          <label className="small">Password</label>
          <input
            className="form-control"
            autoComplete="current-password"
            name={'password'}
            type={'password'}
            onChange={this.onFormChange}
          />
        </div>
        <div className="custom-control custom-checkbox mb-8">
          <input
            type="checkbox"
            className="custom-control-input"
            name={'rememberMe'}
            id="customCheck1"
            onChange={this.onFormChange}
          />
          <label className="custom-control-label small" htmlFor="customCheck1">
            Remember Me
          </label>
        </div>
        <Button
          type="submit"
          disabled={!this.state.form.email || !this.state.form.password}
          color="white"
          size="lg"
          className="min-w-9r"
        >
          Agree to Terms of Use &amp; Log In
        </Button>
      </form>
    );
  }
}

LoginFormComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isNotAuthenticated: PropTypes.bool,
  isAdmin: PropTypes.bool,
  login: PropTypes.func.isRequired,
  onLoginComplete: PropTypes.func.isRequired,
  hideAuthenticatedError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAdmin: includes(get(state, 'auth.roles')),
    userId: get(state, 'auth.id'),
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password, rememberMe) => dispatch(login(email, password, rememberMe)),
    dispatch,
  };
};

export const LoginForm = connect(null, mapDispatchToProps)(LoginFormComponent);
