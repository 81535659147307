import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {Theme} from '../atoms/theme';
import {ColoredBarChartProps} from '../interfaces/ChartProps';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ChartJSBarChartProps extends ColoredBarChartProps {
  barLabelFormatter: Function;
  yTickCallback?: (value: any, index: any, values: any) => any | undefined;
}

export const ChartJSBarChart: React.FC<ChartJSBarChartProps> = ({
  labels,
  data,
  colors,
  breakLabels,
  onClick,
  barLabelFormatter,
  yTickCallback,
}) => {
  const isLoading = useSelector((state: any) => state?.pendingTasks > 0 || false);

  const chartRef = useRef<any>(null);

  const [chartData, setChartData] = useState({
    labels: breakLabels ? labels.map(label => label && label.split(' ')) : labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderRadius: 0,
        barPercentage: 0.7,
        categoryPercentage: 1,
      },
    ],
  });

  const handleChart = (chart: any, labels: string[], datasets: any[]) => {
    const createGradient = (ctx: CanvasRenderingContext2D, area: any) => {
      const colorStart = Theme.colors.harmonyGradientStart;
      const colorEnd = Theme.colors.harmonyGradientEnd;

      const gradient = ctx.createLinearGradient(60, 60, -40, 280);

      gradient.addColorStop(0, colorStart);
      gradient.addColorStop(0.3, colorEnd);
      return gradient;
    };
    return {
      labels: labels,
      datasets: [
        {
          ...datasets[0],
          //@ts-ignore
          backgroundColor: datasets[0].backgroundColor.map(color => {
            return color !== Theme.colors.harmony ? color : createGradient(chart.ctx, chart.area);
          }),
        },
      ],
    };
  };

  useEffect(() => {
    if (handleChart && chartRef.current) {
      let datasets = chartData.datasets;

      datasets[0].data = data;
      //@ts-ignore
      setChartData(handleChart(chartRef.current, labels, chartData.datasets));
    }
  }, [data]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Bar
      ref={chartRef}
      options={{
        layout: {
          padding: {
            top: 30,
          },
        },
        animation: false,
        responsive: true,
        //@ts-ignore
        onClick: onClick ? e => onClick(e) : null,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: breakLabels ? 0 : 70,
              minRotation: 0,
            },
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              color: Theme.colors.dark,
              callback: yTickCallback && yTickCallback,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
            displayColors: false,
            backgroundColor: Theme.colors.white,
            borderColor: Theme.colors.grey_3,
            borderWidth: 1,
            yAlign: 'bottom',
            bodyColor: Theme.colors.black,
            padding: {left: 35, right: 0, top: 6, bottom: 6},
            callbacks: {
              title: () => '',
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            //@ts-ignore
            formatter: barLabelFormatter,
            color: Theme.colors.dark,
            anchor: 'end',
            align: 'top',
            clip: false,
            font: {
              family: 'Inter',
              size: 16,
              weight: 600,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
      }}
      //@ts-ignore
      data={chartData}
    />
  );
};
