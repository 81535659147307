/* eslint-disable no-param-reassign */
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {get, isEmpty, orderBy, map, sumBy, take} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {compose} from 'recompose';
import cn from 'classnames';
import {currentImpersonatedUser} from 'app/utilities';

import {COLOR, CONFIG, ROUTE, FORM_MESSAGE} from 'app/constants';
import {
  APreventDefault,
  Collapsible,
  DistributionPanels,
  ProductReportDownloadButton,
  IssueMatrix,
  LoadingSpinner,
  ToolTip,
} from 'app/components';
import {getStrategyByIdWithFirm} from 'app/redux/strategy';
import {
  getDistributions,
  getSectorsByDistribution,
  getCompaniesByDistributionAndSector,
  getIssueMatrix,
} from 'app/redux/managerDetail';
import EsgAssociationPriImage from 'app/assets/images/ESG-association_PRI.png';
import EsgAssociationGriImage from 'app/assets/images/ESG-association_GRI.png';
import {checkDueDates, sortPhonesByType} from 'app/utilities';
import faInfoCircle from 'app/fontawesome-pro-light/faInfoCircle';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {formatCurrency} from 'app/utilities/formatCurrency';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';

const DASHBOARD_DISTRIBUTION_SECTORS = {datasets: [{}]};
const MAX_KEY_CONTACT_PHONES = 1;

class DistributionAndIssues extends Component {
  render() {
    const {
      distributionData,
      distributionSectors,
      sectorCompanies,
      currentDistributionSectorsTimeStamp,
      currentSectorCompaniesTimeStamp,
      onDistributionClick,
      onSectorClick,
      onCompanyClick,
      issues,
      isIssueDataValid,
      isDistributionDataValid,
      distributionAndIssues,
    } = this.props;
    return (
      <Fragment>
        {isDistributionDataValid && (
          <div className="background-divider is-lighter-gray">
            <div className="p-content-lg pb-0">
              <div className="portfolio-page-section">
                <DistributionPanels
                  distributionTitle="Distribution of Company Ratings for Product"
                  distributionData={distributionData}
                  sectorData={distributionSectors}
                  companyData={sectorCompanies}
                  sectorDataTimeStamp={currentDistributionSectorsTimeStamp}
                  companyDataTimeStamp={currentSectorCompaniesTimeStamp}
                  onDistributionClick={onDistributionClick}
                  onSectorClick={onSectorClick}
                  onCompanyClick={onCompanyClick}
                  managerDetails={distributionAndIssues}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        )}
        {isIssueDataValid && (
          <div className="p-content-lg">
            <div className="portfolio-page-section">{/* <IssueMatrix issues={issues} title="Issue Rating" /> */}</div>
          </div>
        )}
      </Fragment>
    );
  }
}

class ManagerDetailPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(getStrategyByIdWithFirm(props.strategyId));
    // TODO: Change all of these to a version of fetchIfNeeded
    props.dispatch(getDistributions(props.strategyId));
    props.dispatch(getIssueMatrix(props.strategyId));

    this.onDistributionClick = this.onDistributionClick.bind(this);
    this.onSectorClick = this.onSectorClick.bind(this);
    this.onCompanyClick = this.onCompanyClick.bind(this);
  }

  onDistributionClick(distribution) {
    // dispatch after animation is complete (0.3s transition on panels)
    setTimeout(() => {
      this.props.dispatch(getSectorsByDistribution(this.props.strategyId, -(distribution - 6)));
    }, 600);
  }

  onSectorClick(distribution, sectorId) {
    // dispatch after animation is complete (0.3s transition on panels)
    setTimeout(() => {
      this.props.dispatch(getCompaniesByDistributionAndSector(this.props.strategyId, -(distribution - 6), sectorId));
    }, 600);
  }

  onCompanyClick(isin) {
    this.props.dispatch(
      push(ROUTE.COMPANY_DETAIL.path(isin) + '?ref=managerDetail&strategyId=' + this.props.strategyId),
    );
  }

  render() {
    const {hasUnexpectedError, isFetching, distributionAndIssues, strategy} = this.props;
    const firm = get(strategy, 'firm');
    let uploadDates;
    let keyContactPhones;

    if (isFetching) return null;

    if (hasUnexpectedError) {
      return (
        <div className="p-content-lg">
          <div className="card is-white mx-auto" style={{maxWidth: 800}}>
            <h1>An Error has Occurred</h1>
            {FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}
          </div>
        </div>
      );
    }

    // TODO: Confirm the page loads properly for all states of the Strategy.  For example, right after UT1 creates...
    // TODO: the Plan and Strategy, then right after UT3 edits some data, then after an upload occurs, etc...

    if (strategy) {
      // Setup for due date information
      uploadDates = checkDueDates(strategy.lastHoldingsUploadAt, strategy.uploadFrequency);

      // Sort phones by order of PHONE_TYPE enum, with unspecified going last
      if (strategy.user && !isEmpty(strategy.user.phones)) {
        keyContactPhones = sortPhonesByType(strategy.user.phones);
      }
    }

    const strategyDistributions = get(distributionAndIssues, 'distributions.strategyDistributions', []);
    const indexDistributions = get(distributionAndIssues, 'distributions.indexDistributions', []);
    const planDistributions = get(distributionAndIssues, 'distributions.planDistributions', []);
    const currentDistributionSectors = get(distributionAndIssues, 'currentDistributionSectors', []);
    const currentSectorCompanies = get(distributionAndIssues, 'currentSectorCompanies', []);
    const issues = get(distributionAndIssues, 'issues');
    const currentDistributionSectorsTimeStamp = get(distributionAndIssues, 'currentDistributionSectorsTimeStamp');
    const currentSectorCompaniesTimeStamp = get(distributionAndIssues, 'currentSectorCompaniesTimeStamp');

    const isIssueDataValid = !isEmpty(issues);

    // Format Distribution data for bar chart
    const distributionData = [];
    const isDistributionDataValid = sumBy(strategyDistributions, 'percent') > 0;
    if (isDistributionDataValid) {
      distributionData.push({
        label: 'Product',
        data: map(orderBy(strategyDistributions, ['rating'], ['desc']), distribution => {
          return Math.round(distribution.percent, 0);
        }),
        color: COLOR.BLUE,
      });

      if (!isEmpty(planDistributions) && sumBy(planDistributions, 'percent') > 0) {
        distributionData.push({
          label: 'Holdings',
          data: map(orderBy(planDistributions, ['rating'], ['desc']), distribution => {
            return Math.round(distribution.percent, 0);
          }),
        });
      }

      if (!isEmpty(indexDistributions) && sumBy(indexDistributions, 'percent') > 0) {
        distributionData.push({
          label: 'Index',
          data: map(orderBy(indexDistributions, ['rating'], ['desc']), distribution => {
            return Math.round(distribution.percent, 0);
          }),
          color: COLOR.LIGHT_GRAY,
        });
      }
    }

    // TODO: abstract some of this logic so it isn't copied and pasted on all distribution charts
    // Format Sectors-in-Distribution data for doughnut chart
    const sortedDistributionSectors = orderBy(
      currentDistributionSectors,
      ['totalMarketValue', 'name'],
      ['desc', 'asc'],
    );
    const distributionSectorsTotalMarketValue = sumBy(sortedDistributionSectors, 'totalMarketValue');
    const distributionSectors = DASHBOARD_DISTRIBUTION_SECTORS; // not cloning == chart transitions
    const sectorDataset = distributionSectors.datasets[0];
    if (distributionSectorsTotalMarketValue > 0) {
      distributionSectors.labels = map(sortedDistributionSectors, sector => sector.name);
      distributionSectors.sectorIds = map(sortedDistributionSectors, sector => sector.sectorId);
      let total = 0;
      sectorDataset.data = map(sortedDistributionSectors, sector => {
        const value = Number(((sector.totalMarketValue / distributionSectorsTotalMarketValue) * 100).toFixed(1));

        // Ensures that the total doesn't ever go past 100
        if (total + value > 100) {
          const final = Number((100 - total).toFixed(1));
          total += final;
          return final;
        }

        total += value;
        return value;
      });
      sectorDataset.label = distributionSectorsTotalMarketValue.toString();
    }

    // Format Companies-in-Sector data for list
    const sectorCompaniesTotalMarketValue = sumBy(currentSectorCompanies, 'totalMarketValue');
    let sectorCompanies = [];
    if (sectorCompaniesTotalMarketValue > 0) {
      sectorCompanies = map(currentSectorCompanies, company => {
        const holding = Number(((company.totalMarketValue / sectorCompaniesTotalMarketValue) * 100).toFixed(1));
        return {
          ...company,
          holding,
        };
      });
    }

    const isCompliant = !get(strategy, 'policyViolation');

    const rating = get(strategy, 'weightedAverageRating');

    console.log('HIDE PRODUCT REPORT: ', CONFIG.HIDE_PRODUCT_REPORT, !CONFIG.HIDE_PRODUCT_REPORT);

    return (
      <div>
        {!strategy ? (
          <div style={{minHeight: '450px'}}>
            <div className="p-content-lg">
              <div className="portfolio-page-section">
                <LoadingSpinner isActive />
              </div>
            </div>
          </div>
        ) : (
          <div className="p-content-lg">
            <div className="portfolio-page-section">
              <div className="row">
                <div className="col-9">
                  <h1>{firm.name}</h1>
                </div>
                {!isEmpty(get(strategy, 'marketIndex')) && isIssueDataValid && !CONFIG.HIDE_PRODUCT_REPORT ? (
                  <div className="col">
                    <ProductReportDownloadButton
                      strategyId={this.props.strategyId}
                      managerDetail={distributionAndIssues}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="strategy-stats is-3-col mb-5">
                <div>
                  <div>
                    <strong>Product:</strong> {strategy.name}
                  </div>
                  <div>
                    <strong>Portfolio Value:</strong> {formatCurrency(strategy.portfolioValue)}
                  </div>
                  <div>
                    <strong>Strategy:</strong> {strategy.portfolioManagementType}
                  </div>
                  <div>
                    <strong>Index:</strong> {get(strategy, 'marketIndex.name', 'N/A')}
                  </div>
                  <div>
                    <strong>In Compliance:</strong>{' '}
                    <strong className={cn(`text-${isCompliant ? 'green' : 'red'}`)}>
                      {isCompliant ? (
                        'Yes'
                      ) : (
                        <Fragment>
                          No{' '}
                          <APreventDefault onClick={() => this.props.onComplianceViolations(strategy.policyViolation)}>
                            <Icon icon={faInfoCircle} /> more info
                          </APreventDefault>
                        </Fragment>
                      )}
                    </strong>
                  </div>
                </div>
                {strategy.user && (
                  <div>
                    <strong>Key Contact</strong>
                    {/* <div>
                            {strategy.user.firstName} {strategy.user.lastName}
                            {strategy.user.suffix && `, ${strategy.user.suffix}`}
                          </div>
                          {
                            !isEmpty(keyContactPhones)
                            && map(
                              take(keyContactPhones, MAX_KEY_CONTACT_PHONES),
                              (keyContactPhone, index) => {
                                return isEmpty(keyContactPhone)
                                  ? null
                                  : (
                                    <div key={index}>
                                      {keyContactPhone.type}:
                                      {' '}
                                      <a href={`tel:${keyContactPhone.number.replace(/\D/g, '')}`}>
                                        {keyContactPhone.number}
                                      </a>
                                    </div>
                                  );
                              },
                            )
                          }
                          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <a href={`mailto:${strategy.user.email}`} target="_blank" id={'email'}>
                              {strategy.user.email}
                            </a>
                            <ToolTip target={'email'}>
                              <span className="text-white">{strategy.user.email}</span>
                            </Tooltip>
                          </div> */}
                  </div>
                )}
                <div>
                  <div>
                    <strong>Rating: </strong>
                    <span className={cn(rating !== 'UR' ? 'small-rating-circle' : '', `is-${rating}`)}>
                      <span id="rating">{rating}</span>
                      <ToolTip target="rating">
                        <span className="text-white">
                          {rating === 'UR' ? 'Unrated' : `Weighted Average Rating: "${rating}"`}
                        </span>
                      </ToolTip>
                    </span>
                  </div>
                  <strong>Effective Date:</strong>
                  <div>
                    {this.props?.strategy?.equityDate
                      ? moment(this.props?.strategy?.equityDate).format('MM/DD/YYYY')
                      : 'No Effective Date Available'}
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-page-section">
              <h3 className="mb-5">ESG Integration</h3>
              <div className="strategy-stats is-3-col">
                <div>
                  <Collapsible collapsedContainerHeight={144}>
                    {({container, content, isOpen, isUseless, toggle}) => (
                      <Fragment>
                        <div className="transition mb-0" {...container}>
                          <div {...content}>
                            <strong>ESG Approach</strong>
                            <p className="mb-0">
                              {(firm.esgApproach || '').trim() ? (
                                firm.esgApproach.split('\n').map((str, index) => (
                                  <Fragment key={index}>
                                    {str}
                                    <br />
                                  </Fragment>
                                ))
                              ) : (
                                <i>
                                  Manager has not specified their ESG Approach. If you would want to learn about their
                                  approach please reach out to your key contact.
                                </i>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="mb-4">
                          {isUseless || (
                            <a href="javascript:void(0);" onClick={toggle}>
                              {isOpen ? 'Read Less' : 'Read More'}
                            </a>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </Collapsible>
                  {firm.esgPolicyFilePath && (
                    <div>
                      <a target="_blank" href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/${firm.esgPolicyFilePath}`)}>
                        Download ESG Policy
                      </a>
                      {firm.esgPolicyEstablishedAt && (
                        <span>- {moment(firm.esgPolicyEstablishedAt).format('MM/DD/YYYY')}</span>
                      )}
                    </div>
                  )}
                  <div>
                    {firm.hasESGCommitteeOfficer ? (
                      'Manager currently has an ESG Committee / Officer'
                    ) : (
                      <i>Manager currently does not have an ESG Committee / Officer</i>
                    )}{' '}
                  </div>
                </div>
                <div>
                  <strong className="mb-0">ESG Associations</strong>
                  {!firm.hasPriAssociation && !firm.hasGriAssociation && (
                    <i>Not currently affiliated with ESG Associations</i>
                  )}
                  {firm.hasPriAssociation && (
                    <div className="organization-stamp mt-3 mb-3">
                      <div className="organization-stamp-logo">
                        <img src={EsgAssociationPriImage} />
                      </div>
                      {firm.priDocFilePath && (
                        <div className="organization-stamp-details">
                          <a target="_blank" href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/${firm.priDocFilePath}`)}>
                            Download PRI Documentation
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {firm.hasGriAssociation && (
                    <div className="organization-stamp mb-3">
                      <div className="organization-stamp-logo">
                        <img src={EsgAssociationGriImage} />
                      </div>
                      {firm.griDocFilePath && (
                        <div className="organization-stamp-details">
                          <a target="_blank" href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/${firm.griDocFilePath}`)}>
                            Download GRI Documentation
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {(firm.otherEsgAssociations || '').trim() && (
                    <div className="mt-3">
                      <Collapsible collapsedContainerHeight={40}>
                        {({container, content, isOpen, isUseless, toggle}) => (
                          <Fragment>
                            <div className="transition mb-0" {...container}>
                              <div {...content}>
                                <strong>Other ESG Associations</strong>
                                <p className="mb-0">
                                  {firm.otherEsgAssociations.split('\n').map((str, index) => (
                                    <Fragment key={index}>
                                      {str}
                                      <br />
                                    </Fragment>
                                  ))}
                                </p>
                              </div>
                            </div>
                            <div className="mb-4">
                              {isUseless || (
                                <a href="javascript:void(0);" onClick={toggle}>
                                  {isOpen ? 'View Less' : 'View More'}
                                </a>
                              )}
                            </div>
                          </Fragment>
                        )}
                      </Collapsible>
                    </div>
                  )}
                </div>
                <div>
                  <Collapsible collapsedContainerHeight={144}>
                    {({container, content, isOpen, isUseless, toggle}) => (
                      <Fragment>
                        <div className="transition mb-0" {...container}>
                          <div {...content}>
                            <strong>ESG Measurement</strong>
                            <p className="mb-0">
                              {(firm.esgMeasurement || '').trim() ? (
                                firm.esgMeasurement.split('\n').map((str, index) => (
                                  <Fragment key={index}>
                                    {str}
                                    <br />
                                  </Fragment>
                                ))
                              ) : (
                                <i>
                                  Manager has not specified how they measure ESG. If you would want to learn about their
                                  approach please reach out to your key contact.
                                </i>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="mb-4">
                          {isUseless || (
                            <a href="javascript:void(0);" onClick={toggle}>
                              {isOpen ? 'Read Less' : 'Read More'}
                            </a>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </Collapsible>
                </div>
              </div>
            </div>
          </div>
        )}
        {
          // TODO: Handle an empty state if no upload has been done (keep in mind that hiding and showing the...
          // TODO: ...component by clicking on a strategy, then going back to the list and clicking on another...
          // TODO: ...strategy seems to cause an error due to it running an onUnmount for the doughnuts. That is...
          // TODO: ... why this is not in the conditions above. Therefore an empty state may be difficult to solve.
        }
        {
          // TODO: [LOW PRIORITY] The distribution histogram is missing a legend for the blue strategy bar charts. ...
          // TODO: ...Currently it has in the upper right the "vs Holdings" and "vs Index", but it doesn't show...
          // TODO: ...what the light blue bars represent (at least not until you however over them.
        }
        {
          // TODO: [LOW PRIORITY] According to the prototype, the "vs Holdings" and "vs Index"...
          // TODO: ...when checked should be the color of their respective bar chart
        }
        <DistributionAndIssues
          distributionData={distributionData}
          distributionSectors={distributionSectors}
          sectorCompanies={sectorCompanies}
          currentDistributionSectorsTimeStamp={currentDistributionSectorsTimeStamp}
          currentSectorCompaniesTimeStamp={currentSectorCompaniesTimeStamp}
          onDistributionClick={this.onDistributionClick}
          onSectorClick={this.onSectorClick}
          onCompanyClick={this.onCompanyClick}
          issues={issues}
          isDistributionDataValid={isDistributionDataValid}
          isIssueDataValid={isIssueDataValid}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const strategyId = get(ownProps, 'match.params.strategyId');
  const hasUnexpectedError = get(state, 'strategy.error');
  const isFetching = get(state, 'strategy.isFetching');
  return {
    hasUnexpectedError,
    isFetching,
    distributionAndIssues: state.managerDetail,
    strategyId,
    strategy: !isFetching && get(state, 'strategy.strategyWithFirm'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onComplianceViolations: violations => {
      return dispatch(
        openSimpleModal({
          title: 'Compliance Violations',
          message: FORM_MESSAGE.COMPLIANCE_VIOLATIONS(violations),
        }),
      );
    },
    dispatch,
  };
};

export const ManagerDetail = compose(connect(mapStateToProps, mapDispatchToProps))(ManagerDetailPage);
