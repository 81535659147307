import {map} from 'lodash';

export const PARENT_ISSUE = {
  ENVIRONMENTAL: {
    id: 1,
    name: 'Environmental Issues',
  },
  SOCIAL: {
    id: 2,
    name: 'Social Issues',
  },
  GOVERNANCE: {
    id: 3,
    name: 'Governance Issues',
  },
};

export const PARENT_ISSUES = map(PARENT_ISSUE, value => value);
