import {CONFIG} from 'app/constants';
import {isUndefined, isNaN, isEmpty} from 'lodash';
import {useMemo} from 'react';
import {convertUnits} from 'v2/utilities/helpers/convertUnits';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useGetAdminAnnualPendingIssuesByCompanyIdQuery} from 'v2/redux/harmonyApi';
import {useGetManualEditCompanyIssuesQuery} from 'v2/redux/typeormEndpoints';
import {ISSUES} from 'v2/constants/issues';

type useIssueTableDataProps = {
  companyId?: string;
  category: 'ENVIRONMENTAL' | 'GOVERNANCE' | 'SOCIAL';
  icon?: any;
  color?: string;
  title?: string;
};

const getValue = (value: any) => {
  if (isUndefined(value)) {
    return null;
  }

  if (!isNaN(Number(value))) {
    return Number(value);
  }

  return value;
};

const formatValues = (values: any, type?: string) => {
  if (values) {
    if (type === 'Numeric') {
      return Object.values(values).map((datum: any) => getValue(datum.value));
    } else if (type === 'Boolean') {
      return Object.values(values).map((datum: any) => {
        if (datum?.value === null) {
          return {value: DATA_STATUS.NONE};
        }

        if (datum.value?.value) {
          return {
            value: String(datum.value.value) === '1' ? 'Yes' : 'No',
            highlighted: datum.value?.highlighted,
            unit: datum.unit,
          };
        }

        return {value: String(datum?.value) === '1' ? 'Yes' : 'No'};
      });
    }
  }

  return [];
};

const formatDiff = (values: any[], type?: string) => {
  const data: any = formatValues(values, type)?.map(item => {
    if (!!item) {
      return {value: item?.value ?? item, unit: item?.unit};
    } else {
      return {value: null, unit: null};
    }
  });

  while (data.length < CONFIG.ADMIN_REPORTING_YEAR_OFFSET) {
    data.push({value: null, unit: null});
  }

  const index1 = data.length - 1;
  const index2 = data.length - 2;
  const current = convertUnits(Number(data[index1]?.value) ?? 0, data[index1]?.unit, data[index2]?.unit, 0);
  const prev = Number(data[index2]?.value) ?? 0;

  if (type === 'Boolean') {
    return null;
  }

  if (!current || !prev) {
    return null;
  }
  return 100 * Math.abs((prev - Number(current)) / ((prev + Number(current)) / 2));
};

export const useIssueTableData = ({
  companyId,
  category,
  icon,
  color,
  title = DATA_STATUS.NONE,
}: useIssueTableDataProps) => {
  const {
    data: issueData = {},
    isLoading: issueIsLoading,
    isSuccess: issueIsSuccess,
    isError: issueIsError,
  } = useGetAdminAnnualPendingIssuesByCompanyIdQuery({companyId}, {skip: !companyId});

  const {
    data: manualEditTable = [],
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
    isError: isEditError,
  } = useGetManualEditCompanyIssuesQuery({companyId});

  const isError = issueIsError && isEditError;
  const isLoading = issueIsLoading && isEditLoading;
  const isSuccess = issueIsSuccess && isEditSuccess;

  const data = useMemo(() => {
    const issues = issueData?.[category] ?? [];
    const years = Array.from(
      {length: CONFIG.ADMIN_REPORTING_YEAR_OFFSET},
      (_, i) => CONFIG.ADMIN_REPORTING_YEAR - i,
    ).reverse();

    const count = years.length;
    const emptyData = Array.from({length: count}, _ => DATA_STATUS.NONE);

    const mergedIssues = issues.map((issue: any) => {
      const data = issue.data ?? [];
      let mergedData: any = {};

      for (let i = 0; i < CONFIG.ADMIN_REPORTING_YEAR_OFFSET; i++) {
        const year = years[i];
        const cur = data[year] ?? {
          issueId: issue.id,
          value: null,
          year,
        };
        const edits = manualEditTable
          .map((issue: any) => ({
            ...issue,
            value: {
              highlighted: true,
              value: issue.value,
              unit: issue.unit,
            },
          }))
          .filter((edit: any) => {
            return String(edit.year) === String(year) && String(edit.issueId) === String(cur.issueId);
          })
          .sort((x: any, y: any) => {
            const prev = new Date(x.createdAt);
            const cur = new Date(y.createdAt);

            return Number(cur) - Number(prev);
          });
        if (edits.length) {
          mergedData[year] = edits[0];
        } else {
          mergedData[year] = cur;
        }
      }

      return {...issue, data: mergedData};
    });

    const data =
      mergedIssues
        ?.filter((issue: any) => issue.id !== ISSUES.CARBON_NEUTRAL_PLEDGE.id)
        .map((issue: any) => {
          let issueData: any = {};
          years.forEach(year => {
            issueData[year] = {
              annualCompanyIssueId: null,
              companyId,
              confidenceLevel: null,
              harmonyStandardLower: null,
              harmonyStandardUpper: null,
              issueId: issue?.id,
              rating: null,
              receivesBadge: false,
              sourceLink: null,
              sourceType: null,
              transformed: null,
              value: null,
              year,
            };
          });

          const currentIssue = {
            ...issue,
            data: isEmpty(issue?.data ?? {}) ? issueData : issue?.data,
            values: emptyData,
          };

          return {
            label: issue?.name ?? DATA_STATUS.NONE,
            info: issue?.description ?? DATA_STATUS.NONE,
            unit: issue?.unit ?? null,
            values: !isEmpty(issue?.data) ? formatValues(issue?.data, issue?.issueType) : emptyData,
            diff: formatDiff(issue?.data, issue?.issueType),
            issueId: issue?.id,
            issue: currentIssue,
            years,
          };
        }) ?? [];

    const initialData = {
      title,
      icon,
      color,
      years: emptyData,
      data: [],
    };

    const tableData = {
      ...initialData,
      years,
      data,
    };

    return !isLoading && isSuccess ? tableData : initialData;
  }, [issueData, category, title, icon, color, isLoading, isSuccess, manualEditTable, companyId]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
  };
};
