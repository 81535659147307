import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {Theme} from '../atoms/theme';
import {Body1} from '../atoms/Typeface';

const Container = styled.a<{isActive?: boolean}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
  width: auto;
  border: none;
  text-decoration: none;
  background: ${({theme, isActive}) => (isActive ? theme.colors.white : theme.colors.grey_2)};

  &:hover {
    text-decoration: none;
    background: ${({theme, isActive}) => (isActive ? theme.colors.white : theme.colors.grey_11)};
  }

  &:first-of-type {
    border-radius: '0.24rem 0 0 0';
  }

  &:last-of-type {
    border-radius: '0 0.24rem 0 0';
  }
`;

const Label = styled(Body1)<{color?: string}>`
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  color: ${({theme, color}) => color ?? theme.colors.dark};
`;

export const ManagersTab: React.FC<{
  Icon: any;
  isActive?: boolean;
  label?: string;
  to?: any;
  onClick?: Function;
}> = ({Icon, isActive, label = DATA_STATUS.NONE, to = '', onClick}) => {
  if (onClick) {
    return (
      <Container as="button" onClick={() => onClick?.()} isActive={isActive}>
        <Icon size="1.25rem" color={isActive ? Theme.colors.green : Theme.colors.dark} />
        <Label>{label}</Label>
      </Container>
    );
  }

  return (
    <Container as={Link} to={to} isActive={isActive}>
      <Icon size="1.25rem" color={isActive ? Theme.colors.green : Theme.colors.dark} />
      <Label color={isActive ? Theme.colors.green : Theme.colors.dark}>{label}</Label>
    </Container>
  );
};
