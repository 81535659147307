import {MutableRefObject, useEffect} from 'react';

export const useOutsideClick = ({ref, onOutsideClick}: {ref: MutableRefObject<any>; onOutsideClick?: () => any}) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target)) {
        onOutsideClick?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, ref]);

  return ref;
};
