import {includes} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import {ROLE, ROUTE} from 'app/constants';
import {ROUTE as V2_ROUTE} from '../../v2/constants/routes';
import {DashboardUserType1, DashboardUserType3} from 'app/components';
import {getSessionJwt, withUser} from 'app/utilities';
import {Redirect} from 'react-router';

class DashboardPage extends Component {
  // eslint-disable-next-line consistent-return
  render() {
    const sessionRoles = getSessionJwt(true)?.contents?.roles;

    if (includes(sessionRoles, ROLE.USER_TYPE_1) || includes(sessionRoles, ROLE.USER_TYPE_2)) {
      return <Redirect to={V2_ROUTE.UT1.DASHBOARD.RISK.path} />;
    }

    if (includes(sessionRoles, ROLE.USER_TYPE_3)) {
      return <Redirect to={V2_ROUTE.UT3.DASHBOARD.path} />;
    }

    if (includes(sessionRoles, ROLE.USER_TYPE_4)) {
      return <Redirect to={ROUTE.COMPANY_DASHBOARD.path()} />;
    }

    if (includes(sessionRoles, ROLE.ADMIN)) {
      return <Redirect to={ROUTE.ADMIN_DASHBOARD.path()} />;
    }
  }
}

export const Dashboard = compose(withUser, connect(null, null))(DashboardPage);
