import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {compose} from 'recompose';
import {animateScroll} from 'react-scroll/modules';
import {SubmissionError} from 'redux-form';

import {FORM_MESSAGE, ROLE, ROUTE} from 'app/constants';
import {Alert, ProgressIndicator, OnboardingProfileForm} from 'app/components';
import {updateOnboardingUserByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';

class OnboardingProfilePage extends Component {
  render() {
    // Ensure the page doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 850}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          <h1 className="mb-4">{ROUTE.ONBOARDING_PROFILE.title}</h1>
          {this.props.hasUnexpectedError ? (
            <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
          ) : (
            <Fragment>
              <p className="mb-3">
                Please complete as much information as possible. You can edit the information below and add more
                information after your account is setup by going to the "Settings" portion of your account.
              </p>
              <OnboardingProfileForm {...this.props} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {invitationCode, onboardingSteps} = ownProps;
      const updateData = {
        ...values,
        onboardingStep: onboardingSteps.PROFILE.onboardingStepName
          ? onboardingSteps.PROFILE.onboardingStepName
          : onboardingSteps.PROFILE.key,
      };
      return dispatch(updateOnboardingUserByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          const nextRoute =
            ownProps.userRole === ROLE.USER_TYPE_3 ? ROUTE.ONBOARDING_UT3_MANAGER : ROUTE.ONBOARDING_PLAN_INFO;

          dispatch(push(nextRoute.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const OnboardingProfile = compose(withOnboardingUser, connect(null, mapDispatchToProps))(OnboardingProfilePage);
