import {isArray, get, includes, isEmpty, map} from 'lodash';
import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import cn from 'classnames';

import faSearch from 'app/fontawesome-pro-light/faSearch';
import faTrash from 'app/fontawesome-pro-light/faTrash';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import {FORM_MESSAGE, ROLE, ROUTE} from 'app/constants';
import {USER_ROLES, USER_ROLES_DISPLAY_NAMES} from 'app/constants/role';
import {getAllPagedForSettingsUsers, deleteUser} from 'app/redux/user';
import {Alert, Button, BootstrapTableRemote, APreventDefault} from 'app/components';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {withUser, withViewportSize} from 'app/utilities';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {toastSuccess, toastError} from 'app/utilities/toast';

const formatRoleData = (roleData, isUserType3) => {
  if (isEmpty(roleData)) {
    return null;
  }

  if (isUserType3) {
    return roleData;
  }

  if (isArray(roleData)) {
    return USER_ROLES_DISPLAY_NAMES[roleData[0]];
  }

  return roleData;
};

/**
 * Format the content of a Held By cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
const editCellFormatter = (cell, row) => {
  return (
    <Link to={ROUTE.SETTINGS_USERS_EDIT.path(row.id)}>
      <Icon icon={faPencil} />
    </Link>
  );
};

/**
 * Format the content of the Role cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
const editRoleCellFormatter = (cell, row, rowIndex, formatExtraData) => {
  return formatRoleData(cell, !!(formatExtraData && formatExtraData.isUserType3));
};

/**
 * Format the content of a Edit cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
const editHeaderFormatter = () => {
  return <span className="sr-only">Edit</span>;
};

/**
 * Format the content of a Remove cell
 * @param {Array} cell - The content to be used for outputting
 * @param {Object} row - The content used for the row
 * @returns {Any} - The HTML to display
 */
const removeHeaderFormatter = () => {
  return <span className="sr-only">Delete</span>;
};

const getColumns = (
  userId,
  isUserType2,
  isUserType3,
  removeCellFormatter,
  isSmallerLayout,
  responsiveCellFormatter,
) => {
  if (isSmallerLayout) {
    return [
      {
        dataField: 'userData',
        text: 'User Info',
        formatter: responsiveCellFormatter,
        formatExtraData: {isUserType3, userId},
      },
    ];
  }

  const columnDefinitions = [
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
      style: {minWidth: 170},
      headerStyle: {minWidth: 170},
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      sort: true,
      style: {minWidth: 170},
      headerStyle: {minWidth: 170},
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      style: {width: 310},
      headerStyle: {width: 310},
      classes: 'table-overflow-ellipsis',
    },
    {
      dataField: isUserType3 ? 'jobRole' : 'roles',
      text: 'Role',
      style: {minWidth: 200},
      headerStyle: {minWidth: 200},
      formatExtraData: {isUserType3},
      formatter: editRoleCellFormatter,
    },
  ];

  if (!isUserType2) {
    columnDefinitions.push({
      dataField: 'Edit',
      text: '',
      style: {width: 30},
      headerStyle: {width: 30},
      formatter: editCellFormatter,
      headerFormatter: editHeaderFormatter,
    });
  }

  columnDefinitions.push({
    dataField: 'Remove',
    text: '',
    style: {width: 30},
    headerStyle: {width: 30},
    formatExtraData: {userId},
    formatter: removeCellFormatter,
    headerFormatter: removeHeaderFormatter,
  });

  return columnDefinitions;
};

function SettingsUserManagerPage(props) {
  const [stateFilter, setStateFilter] = useState({
    search: props.search,
    userType: props.userType,
  });

  /**
   * Handle initial loading of the data
   */
  useEffect(() => {
    fetchData(props.sortField, props.sortOrder, props.page, props.pageSize, props.search, props.userType);

    animateScroll.scrollToTop();
  }, []);

  const handleDelete = userId => {
    return props
      .dispatch(deleteUser(userId))
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }

        toastSuccess('The user has been deleted.');

        animateScroll.scrollToTop();

        // update querystring which will force update of data
        updateCurrentQueryString();
      })
      .catch(() => {
        animateScroll.scrollToTop();
        toastError('Error deleting user.');
      });
  };

  /**
   * Event handler for when the delete icon / link is clicked.  Opens the delete user modal
   * @param {Object} user - The user for the row the delete button is clicked on
   */
  const onDeleteClick = user => {
    let displayName = user.email;
    if (user.firstName && user.lastName) {
      displayName = `${user.firstName} ${user.lastName}`;
    }
    props.dispatch(
      openSimpleModal({
        title: 'Delete User',
        message: `Do you really want to delete ${displayName} from your plan?`,
        buttons: [
          {
            label: 'Cancel',
            close: true,
          },
          {
            className: 'btn-red ml-auto',
            label: 'Yes, Delete User',
            clickHandler: () => handleDelete(user.id),
            close: true,
          },
        ],
      }),
    );
  };

  /**
   * Format the content of a delete cell
   * @param {Array} cell - The content to be used for outputting
   * @param {Object} row - The content used for the row
   * @returns {Any} - The HTML to display
   */
  const removeCellFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (formatExtraData && formatExtraData.userId && row.id === formatExtraData.userId) {
      return null;
    }
    return (
      <APreventDefault className="text-danger" onClick={() => onDeleteClick(row)}>
        <Icon icon={faTrash} />
      </APreventDefault>
    );
  };

  /**
   * Format the content of a responsive cell
   * @param {Array} cell - The content to be used for outputting
   * @param {Object} row - The content used for the row
   * @returns {Any} - The HTML to display
   */
  const responsiveCellFormatter = (cell, row, rowIndex, formatExtraData) => {
    let userRole = null;
    const hideDelete = formatExtraData && formatExtraData.userId && row.id === formatExtraData.userId;
    if (formatExtraData && formatExtraData.isUserType3) {
      userRole = row.jobRole;
    } else {
      userRole = formatRoleData(row.roles);
    }
    return (
      <div className="bootstrap-table-card">
        <div className="table-card-title">
          {row.firstName} {row.lastName}
        </div>
        <div>{userRole}</div>
        <div>{row.email}</div>
        <div className="d-flex mt-3">
          <div className="mr-4">
            <Link to={ROUTE.SETTINGS_USERS_EDIT.path(row.id)}>
              <Icon icon={faPencil} /> Edit
            </Link>
          </div>
          {!hideDelete && (
            <div>
              <APreventDefault className="text-danger" onClick={() => onDeleteClick(row)}>
                <Icon icon={faTrash} /> Delete
              </APreventDefault>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderRoleFilterSearchArea = doNotShow => {
    if (doNotShow) {
      return null;
    }

    return (
      <div className="bootstrap-table-filter">
        <label className="col-form-label">Filter Users</label>
        <select
          id="userType"
          name="userType"
          value={stateFilter.userType}
          onChange={changeField}
          className="custom-select form-control"
        >
          <option value="">Select</option>
          {map(USER_ROLES, (option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  /**
   * Intermediate function called from changeField when the roles dropdown is used.
   * stateFilter is not updated by the time updateCurrentQueryString is called, so pass it in manually
   */
  const updateCurrentQueryStringByUserType = userType => {
    updateCurrentQueryString(
      props.sortField,
      props.sortOrder,
      props.page,
      props.pageSize,
      stateFilter.search,
      userType,
    );
  };

  /**
   * Uses the passed in or props/stateFilter values and updates the querystring.
   */
  const updateCurrentQueryString = (
    sortField = props.sortField,
    sortOrder = props.sortOrder,
    page = props.page,
    pageSize = props.pageSize,
    search = stateFilter.search,
    userType = stateFilter.userType,
  ) => {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== props.search) {
      updatedPage = 1;
    }

    // Setup to pass extra filters only if they have data
    const extraFilters = {};
    if (userType) {
      extraFilters.userType = userType;
    }

    props.dispatch(
      push({
        search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search, null, extraFilters),
      }),
    );
  };

  /**
   * Handles the changing of the text in the field.  Updates the stateFilter with the value.
   */
  const changeField = event => {
    const newstateFilter = Object.assign({}, stateFilter);
    newstateFilter[event.target.id] = event.target.value;
    setStateFilter(newstateFilter);

    if (event.target.id === 'userType') {
      updateCurrentQueryStringByUserType(event.target.value);
    }
  };

  /**
   * Handles when the table fires an event
   */
  const handleTableChange = (
    type,
    {
      sortField = props.sortField,
      sortOrder = props.sortOrder,
      // data,
      page = props.page,
      sizePerPage = props.pageSize,
      // filters,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && props.sortField === sortField && props.sortOrder === sortOrder) {
      return;
    }

    updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  const handleCompanySearch = () => {
    updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  const fetchData = (sortField, sortOrder, page, pageSize, search, userType) => {
    props.dispatch(
      getAllPagedForSettingsUsers({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        userType,
      }),
    );
  };

  const {authUser, data, hasError, isUserType3, sortField, sortOrder, page, pageSize, totalSize, viewportWidth} = props;
  const isUserType2 = includes(authUser.roles || [], ROLE.USER_TYPE_2);

  const isLayoutSmall = viewportWidth < 1280;

  // Pass in the sort to be displayed in the table
  const defaultSorted = [];
  if (sortField) {
    defaultSorted.push({
      dataField: sortField,
      order: sortOrder || 'asc',
    });
  }

  return (
    <div className="settings-form-lg">
      <div className="d-flex justify-content-between mb-8">
        <h1>{ROUTE.SETTINGS_USERS.title}</h1>
        <div>
          <Link className="btn btn-outline" to={ROUTE.SETTINGS_USERS_ADD.path()}>
            Add New User
          </Link>
        </div>
      </div>
      {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

      <form method="GET" onSubmit={handleCompanySearch}>
        <div className={cn('bootstrap-table-controls', {'search-only': isUserType3})}>
          <div className="bootstrap-table-search">
            <div className="form-control-and-icon">
              <input
                className="form-control is-subtle"
                type="text"
                id="search"
                name="search"
                placeholder="Search by Name or Email"
                value={stateFilter.search}
                onChange={changeField}
              />
              <div className="icon">
                <Icon icon={faSearch} />
              </div>
            </div>
            <div>
              <Button color="gray" onClick={handleCompanySearch}>
                Search
              </Button>
            </div>
          </div>
          {renderRoleFilterSearchArea(isUserType3)}
        </div>
      </form>

      <BootstrapTableRemote
        keyField="id"
        columns={getColumns(
          authUser.id,
          isUserType2,
          isUserType3,
          removeCellFormatter,
          isLayoutSmall,
          responsiveCellFormatter,
        )}
        data={data}
        page={page}
        sizePerPage={pageSize}
        totalSize={totalSize}
        onTableChange={handleTableChange}
        defaultSorted={defaultSorted}
      />
    </div>
  );
}

SettingsUserManagerPage.defaultProps = {
  data: [],
  totalSize: 0,
};

const mapStateToProps = (state, ownProps) => {
  const {authUser} = ownProps;
  const isUserType3 = includes(authUser.roles || [], ROLE.USER_TYPE_3);

  const newProps = getPrametersFromQuery(
    ownProps,
    {
      search: '',
      userType: '',
      page: 1,
      pageSize: 25,
      sortField: 'email',
      sortOrder: 'asc',
    },
    ['userType'],
  );
  newProps.data = get(state, 'user.allUsersSettings');
  // if (newProps.data && newProps.data.length === 1) {
  //   // add 1 as fix for pagination
  //   newProps.data.push({ id: '-1' });
  // }
  newProps.totalSize = get(state, 'user.allUsersSettingsTotal');
  newProps.hasError = get(state, 'user.hasError');
  newProps.isUserType3 = isUserType3;
  return newProps;
};

export const SettingsUserManager = compose(
  withUser,
  connect(mapStateToProps),
  withViewportSize,
)(SettingsUserManagerPage);
