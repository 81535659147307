import styled from 'styled-components';
import {getOrdinal} from './getOrdinal';

const Sup = styled.span`
  position: relative;
  top: -0.1875rem;
  font-size: 0.5625rem;
`;

export const getRank = (rank: number) => {
  const value = Math.round(rank * 100);

  if (value <= 1) {
    return (
      <>
        1<Sup>st</Sup>
      </>
    );
  }

  return (
    <>
      {value}
      <Sup>{getOrdinal(value)}</Sup>
    </>
  );
};
