export const PERMISSIONS = {
  READ_USERS: 'readUsers',
  WRITE_USERS: 'writeUsers',
  READ_FIRMS: 'readFirms',
  WRITE_FIRMS: 'writeFirms',
  READ_COMPANIES: 'readCompanies',
  WRITE_COMPANIES: 'writeCompanies',
  READ_HARMONY_DATA: 'readHarmonyData',
  WRITE_HARMONY_DATA: 'writeHarmonyData',
  READ_CLIENT_HOLDINGS: 'readClientHoldings',
  WRITE_CLIENT_HOLDINGS: 'writeClientHoldings',
  READ_REFERENCE_INDEXES: 'readReferenceIndexes',
  WRITE_REFERENCE_INDEXES: 'writeReferenceIndexes',
  READ_SITE_SETTINGS: 'readSiteSettings',
  WRITE_SITE_SETTINGS: 'writeSiteSettings',
  READ_ADMIN_PERMISSIONS: 'readAdminPermissions',
  WRITE_ADMIN_PERMISSIONS: 'writeAdminPermissions',
  IMPERSONATE_UT1: 'impersonateUT1',
  IMPERSONATE_UT2: 'impersonateUT2',
  IMPERSONATE_UT3: 'impersonateUT3',
  IMPERSONATE_UT4: 'impersonateUT4',
};

export const ADMIN_PERMISSIONS = {
  users: {
    label: 'User Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_USERS,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_USERS,
        label: 'Write',
      },
    },
  },
  firms: {
    label: 'Firm Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_FIRMS,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_FIRMS,
        label: 'Write',
      },
    },
  },
  companies: {
    key: 'companies',
    label: 'Companies Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_COMPANIES,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_COMPANIES,
        label: 'Write',
      },
    },
  },
  harmonyData: {
    key: 'harmonyData',
    label: 'Harmony Data Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_HARMONY_DATA,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_HARMONY_DATA,
        label: 'Write',
      },
    },
  },
  clientHoldings: {
    key: 'clientHoldings',
    label: 'Client Holding Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_CLIENT_HOLDINGS,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_CLIENT_HOLDINGS,
        label: 'Write',
      },
    },
  },
  referenceIndexes: {
    key: 'referenceIndexes',
    label: 'Reference Index Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_REFERENCE_INDEXES,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_REFERENCE_INDEXES,
        label: 'Write',
      },
    },
  },
  siteSettings: {
    key: 'siteSettings',
    label: 'Settings Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_SITE_SETTINGS,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_SITE_SETTINGS,
        label: 'Write',
      },
    },
  },
  employee: {
    key: 'employee',
    label: 'Employee Permissions',
    permissions: {
      read: {
        value: PERMISSIONS.READ_ADMIN_PERMISSIONS,
        label: 'Read',
      },
      write: {
        value: PERMISSIONS.WRITE_ADMIN_PERMISSIONS,
        label: 'Write',
      },
    },
  },
  impersonateUsers: {
    key: 'impersonateUsers',
    label: 'Impersonation Permissions',
    permissions: {
      ut1: {
        value: PERMISSIONS.IMPERSONATE_UT1,
        label: 'Impersonate UT1',
      },
      ut2: {
        value: PERMISSIONS.IMPERSONATE_UT2,
        label: 'Impersonate UT2',
      },
      ut3: {
        value: PERMISSIONS.IMPERSONATE_UT3,
        label: 'Impersonate UT3',
      },
      ut4: {
        value: PERMISSIONS.IMPERSONATE_UT4,
        label: 'Impersonate UT4',
      },
    },
  },
};
