import {getOrdinal, getSessionJwt, isImpersonating} from 'app/utilities';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useGetCompanyQuery, useGetFirmHoldingsByIdQuery} from 'v2/redux/typeormEndpoints';
import {limitPctRank} from 'v2/utilities/helpers/limitPctRank';
import {Company, FirmHolding} from 'v2/utilities/types/typeOrm';

export const useUT3CompanySummary = () => {
  const {companyId = ''} = useParams<{companyId: string}>();
  const planSponsorId = getSessionJwt(true).contents?.planSponsorId ?? 0;
  const firmId = useSelector((state: any) =>
    isImpersonating() ? state.auth.impersonatedUser.firmId ?? 0 : state.auth.firmId ?? 0,
  );

  const {
    data: companyData,
    isLoading: companyIsLoading,
    isError: companyIsError,
    isSuccess: companyIsSuccess,
  } = useGetCompanyQuery({companyId, planSponsorId});

  const {
    data: firmData,
    isLoading: firmIsLoading,
    isError: firmIsError,
    isSuccess: firmIsSuccess,
  } = useGetFirmHoldingsByIdQuery({firmId});

  const company = (companyData ?? {}) as Company;
  const firms = (firmData ?? []) as FirmHolding[];

  const transparency = () => {
    if (company.transparency) {
      if (Number(company.transparency) <= 0.33) {
        return 'Low';
      }

      if (Number(company.transparency) >= 0.33 && Number(company.transparency) <= 0.66) {
        return 'Medium';
      }

      if (Number(company.transparency) >= 0.66) {
        return 'High';
      }
    }

    return DATA_STATUS.NONE;
  };

  const percentile = () => {
    if (company.pctRank || company.pctRank === 0) {
      let percentile = Math.round(company.pctRank * 100);
      percentile = limitPctRank(percentile);
      const ordinal = getOrdinal(percentile);
      return percentile + ordinal;
    }

    return DATA_STATUS.NONE;
  };

  const quintile = () => {
    if (company.pctRank) {
      return Math.floor((company.pctRank * 100) / 20 + 1);
    }

    return 1;
  };

  const industry = () => {
    const count = firms.length;
    let lower = 0;
    let total = 0;

    firms.forEach(firm => {
      if ((company.pctRank ?? 0) < firm.issuePctRank) {
        total += 1;
      } else {
        total += 1;
        lower += 1;
      }
    });

    let calculatedIndustry = Math.round((lower / total) * 100);
    calculatedIndustry = limitPctRank(calculatedIndustry);
    const ordinal = getOrdinal(calculatedIndustry);

    if (!count || isNaN(calculatedIndustry)) {
      return DATA_STATUS.NONE;
    }

    return calculatedIndustry + ordinal;
  };

  return {
    isLoading: companyIsLoading && firmIsLoading,
    isError: companyIsError && firmIsError,
    isSuccess: companyIsSuccess && firmIsSuccess,
    data: {
      title: company?.name ?? DATA_STATUS.NONE,
      description: company?.description ?? DATA_STATUS.NONE,
      country: company.country?.name ?? DATA_STATUS.NONE,
      sector: company.sector?.name ?? DATA_STATUS.NONE,
      industry: company.industry?.name ?? DATA_STATUS.NONE,
      transparency: transparency(),
      isEngaged: (company.engagementLetters ?? []).length > 0,
      overall: {
        percentile: percentile(),
        industry: industry(),
        quintile: quintile(),
      },
      carbonNeutralPledgeSbtiStatus: company.carbonNeutralPledgeSbtiStatus ?? DATA_STATUS.NONE,
    },
  };
};
