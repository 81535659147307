import {Theme} from '../theme';

export const GenderEquality = ({color = Theme.colors.dark, size = 32}) => (
  <svg width={size} height={size} viewBox="0 0 23 23" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M2.53582 12.9018C-0.31142 10.0545 -0.31142 5.42775 2.53582 2.5805C5.38307 -0.266743 10.0098 -0.266743 12.8571 2.5805C15.7043 5.42775 15.7043 10.0545 12.8571 12.9018C11.7004 14.0585 10.2768 14.7703 8.76417 14.9482V16.9057H10.1878C10.8106 16.9057 11.2555 17.3506 11.2555 17.9734C11.2555 18.5963 10.8106 19.0411 10.1878 19.0411H8.76417V21.1766C8.76417 21.7994 8.31929 22.2443 7.69645 22.2443C7.07362 22.2443 6.62874 21.7994 6.62874 21.1766V19.0411H5.20511C4.58228 19.0411 4.1374 18.5963 4.1374 17.9734C4.1374 17.3506 4.58228 16.9057 5.20511 16.9057H6.62874V14.9482C5.11614 14.7703 3.69252 14.0585 2.53582 12.9018ZM11.3445 11.3892C13.3909 9.34271 13.3909 6.05058 11.3445 4.00412C9.29803 1.95767 6.0059 1.95767 3.95945 4.00412C1.91299 6.05058 1.91299 9.34271 3.95945 11.3892C6.0059 13.4356 9.29803 13.4356 11.3445 11.3892Z" />
    <path d="M20.8649 12.9016L20.6869 12.7237L20.8649 12.9016C19.7082 14.0583 18.2846 14.7701 16.772 14.9481V17.0835V17.8843V18.6851V20.9985C16.772 21.6213 16.3271 22.0662 15.7043 22.0662C15.0814 22.0662 14.6365 21.6213 14.6365 20.9985V18.6851V16.9945V14.8591C13.124 14.6812 11.7003 13.9693 10.5436 12.8127C7.69639 9.96541 7.69639 5.33863 10.5436 2.49139C13.3909 -0.355854 18.0177 -0.355854 20.8649 2.49139C23.7121 5.42761 23.7121 10.0544 20.8649 12.9016ZM19.3523 11.389C21.3988 9.34257 21.3988 6.05045 19.3523 4.00399C17.3058 1.95753 14.0137 1.95753 11.9673 4.00399C9.9208 6.05045 9.9208 9.34257 11.9673 11.389C14.0137 13.4355 17.3058 13.4355 19.3523 11.389Z" />
    <path d="M16.505 21.8883C16.1491 22.3331 15.4373 22.3331 14.9924 21.9772L12.4121 19.6639C11.9672 19.3079 11.9672 18.5961 12.3231 18.1513C12.679 17.7064 13.3909 17.7064 13.8357 18.0623L16.4161 20.3757C16.8609 20.7316 16.8609 21.4434 16.505 21.8883Z" />
    <path d="M14.9038 21.8884C15.2597 22.3332 15.9715 22.3332 16.4164 21.9773L19.1747 19.486C19.6196 19.1301 19.6196 18.4183 19.2637 17.9734C18.9078 17.5285 18.1959 17.5285 17.7511 17.8844L14.9928 20.3758C14.5479 20.7317 14.4589 21.4435 14.9038 21.8884Z" />
  </svg>
);
