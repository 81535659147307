import styled from 'styled-components';
import {ParagraphS} from '../atoms/Typeface';

const InvalidFieldFeedback = styled(ParagraphS)`
  color: ${props => props.theme.colors.red_2};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
`;

const InvalidFieldFiller = styled.div`
  height: 20px;
  margin-top: 4px;
`;

export const ErrorField = ({error}: {error: any}) => {
  return <>{error ? <InvalidFieldFeedback>{error}</InvalidFieldFeedback> : <InvalidFieldFiller />}</>;
};
