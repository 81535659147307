/* eslint-disable max-len */
import {createAction} from 'redux-actions';

// Local Imports
import {fetchJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';
import {authLoginReceive, authLoginComplete} from 'app/redux/auth'; // eslint-disable-line import/no-cycle
import {isImpersonating, setAuthTokens, setImpersonationTokens} from 'app/utilities';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_INVITATION_BY_CODE_REQUEST: 'ONBOARDING_USER_GET_INVITATION_BY_CODE_REQUEST',
  GET_INVITATION_BY_CODE_RECEIVE: 'ONBOARDING_USER_GET_INVITATION_BY_CODE_RECEIVE',
  UPDATE_BY_INVITATION_CODE_REQUEST: 'ONBOARDING_USER_UPDATE_BY_INVITATION_CODE_REQUEST',
  UPDATE_BY_INVITATION_CODE_RECEIVE: 'ONBOARDING_USER_UPDATE_BY_INVITATION_CODE_RECEIVE',
  UPDATE_PLAN_BY_INVITATION_CODE_REQUEST: 'ONBOARDING_USER_UPDATE_PLAN_BY_INVITATION_CODE_REQUEST',
  UPDATE_PLAN_BY_INVITATION_CODE_RECEIVE: 'ONBOARDING_USER_UPDATE_PLAN_BY_INVITATION_CODE_RECEIVE',
  UPDATE_FIRM_BY_INVITATION_CODE_REQUEST: 'ONBOARDING_USER_UPDATE_FIRM_BY_INVITATION_CODE_REQUEST',
  UPDATE_FIRM_BY_INVITATION_CODE_RECEIVE: 'ONBOARDING_USER_UPDATE_FIRM_BY_INVITATION_CODE_RECEIVE',
  UPDATE_STRATEGIES_BY_INVITATION_CODE_REQUEST: 'ONBOARDING_USER_UPDATE_STRATEGIES_BY_INVITATION_CODE_REQUEST',
  UPDATE_STRATEGIES_BY_INVITATION_CODE_RECEIVE: 'ONBOARDING_USER_UPDATE_STRATEGIES_BY_INVITATION_CODE_RECEIVE',
  UPDATE_INVITATION_BY_INVITATION_CODE_REQUEST: 'ONBOARDING_USER_UPDATE_INVITATION_BY_INVITATION_CODE_REQUEST',
  UPDATE_INVITATION_BY_INVITATION_CODE_RECEIVE: 'ONBOARDING_USER_UPDATE_INVITATION_BY_INVITATION_CODE_RECEIVE',
  FINALIZE_ONBOARDING_REQUEST: 'ONBOARDING_USER_FINALIZE_REQUEST',
  FINALIZE_ONBOARDING_RECEIVE: 'ONBOARDING_USER_FINALIZE_RECEIVE',
  GET_USER_TYPE_4_BY_CODE_REQUEST: 'COMPANY_GET_USER_TYPE_4_BY_CODE_REQUEST',
  GET_USER_TYPE_4_BY_CODE_RECEIVE: 'COMPANY_GET_USER_TYPE_4_BY_CODE_RECEIVE',
  UPDATE_USER_TYPE_4_BY_CODE_REQUEST: 'COMPANY_UPDATE_USER_TYPE_4_BY_CODE_REQUEST',
  UPDATE_USER_TYPE_4_BY_CODE_RECEIVE: 'COMPANY_UPDATE_USER_TYPE_4_BY_CODE_RECEIVE',
};

const getInvitationByCodeRequest = createAction(ACTION.GET_INVITATION_BY_CODE_REQUEST);
const getInvitationByCodeReceive = createAction(ACTION.GET_INVITATION_BY_CODE_RECEIVE);
const updateOnboardingUserByInvitationCodeRequest = createAction(ACTION.UPDATE_BY_INVITATION_CODE_REQUEST);
const updateOnboardingUserByInvitationCodeReceive = createAction(ACTION.UPDATE_BY_INVITATION_CODE_RECEIVE);
const updatePlanSponsorByInvitationCodeRequest = createAction(ACTION.UPDATE_PLAN_BY_INVITATION_CODE_REQUEST);
const updatePlanSponsorByInvitationCodeReceive = createAction(ACTION.UPDATE_PLAN_BY_INVITATION_CODE_RECEIVE);
const updateFirmByInvitationCodeRequest = createAction(ACTION.UPDATE_FIRM_BY_INVITATION_CODE_REQUEST);
const updateFirmByInvitationCodeReceive = createAction(ACTION.UPDATE_FIRM_BY_INVITATION_CODE_RECEIVE);
const updateStrategiesByInvitationCodeRequest = createAction(ACTION.UPDATE_STRATEGIES_BY_INVITATION_CODE_REQUEST);
const updateStrategiesByInvitationCodeReceive = createAction(ACTION.UPDATE_STRATEGIES_BY_INVITATION_CODE_RECEIVE);
const updateInvitationByInvitationCodeRequest = createAction(ACTION.UPDATE_INVITATION_BY_INVITATION_CODE_REQUEST);
const updateInvitationByInvitationCodeReceive = createAction(ACTION.UPDATE_INVITATION_BY_INVITATION_CODE_RECEIVE);
const finalizeOnboardingRequest = createAction(ACTION.FINALIZE_ONBOARDING_REQUEST);
const finalizeOnboardingReceive = createAction(ACTION.FINALIZE_ONBOARDING_RECEIVE);
const getUserType4ByCodeRequest = createAction(ACTION.GET_USER_TYPE_4_BY_CODE_REQUEST);
const getUserType4ByCodeReceive = createAction(ACTION.GET_USER_TYPE_4_BY_CODE_RECEIVE);
const updateByUserType4CodeRequest = createAction(ACTION.UPDATE_USER_TYPE_4_BY_CODE_REQUEST);
// const updateByUserType4CodeReceive = createAction(ACTION.UPDATE_USER_TYPE_4_BY_CODE_RECEIVE);

const api = {
  getInvitationByCode: invitationCode => fetchJSON(`invitation/code/${invitationCode}`, {method: 'get'}),
  updateByInvitationCode: (invitationCode, user) =>
    fetchJSON(`user/invitationCode/${invitationCode}`, {
      method: 'post',
      body: JSON.stringify(user),
    }),
  updateFirmByInvitationCode: (invitationCode, values) =>
    fetchJSON(`firm/invitationCode/${invitationCode}`, {
      method: 'post',
      body: JSON.stringify(values),
    }),
  updateStrategiesByInvitationCode: (invitationCode, values) =>
    fetchJSON(`strategy/invitationCode/${invitationCode}`, {
      method: 'post',
      body: JSON.stringify(values),
    }),
  updateInvitationByInvitationCode: (invitationCode, values) =>
    fetchJSON(`invitation/code/${invitationCode}`, {
      method: 'put',
      body: JSON.stringify(values),
    }),
  updatePlanSponsorByInvitationCode: (invitationCode, planSponsor) => {
    return fetchJSON(`planSponsor/invitationCode/${invitationCode}`, {
      method: 'post',
      body: JSON.stringify(planSponsor),
    });
  },
  finalizeOnboarding: invitationCode => {
    return fetchJSON(`invitation/code/${invitationCode}`, {method: 'post'});
  },
  getUserType4ByCode: invitationCode => fetchJSON(`company/code/${invitationCode}`, {method: 'get'}),
  updateByUserType4Code: (invitationCode, user) =>
    fetchJSON(`user/companyPortalCode/${invitationCode}`, {
      method: 'post',
      body: JSON.stringify(user),
    }),
};

export function getInvitationByCode(invitationCode) {
  return dispatch => {
    dispatch(getInvitationByCodeRequest({invitationCode}));
    return dispatch(getInvitationByCodeReceive(api.getInvitationByCode(invitationCode)));
  };
}

export function updateOnboardingUserByInvitationCode(invitationCode, payload) {
  return dispatch => {
    dispatch(updateOnboardingUserByInvitationCodeRequest({invitationCode}));
    return dispatch(
      updateOnboardingUserByInvitationCodeReceive(api.updateByInvitationCode(invitationCode, payload)),
    ).then(response => {
      if (response.hasError) {
        throw response.error;
      }

      if (response.payload.access_token) {
        if (isImpersonating()) {
          setImpersonationTokens(response.payload.access_token, response.payload.refresh_token);
        } else {
          setAuthTokens(response.payload.access_token, response.payload.refresh_token);
        }
        dispatch(authLoginComplete());
      }

      return response.payload;
    });
  };
}

export function updatePlanSponsorByInvitationCode(invitationCode, payload) {
  return dispatch => {
    dispatch(updatePlanSponsorByInvitationCodeRequest({invitationCode}));
    return dispatch(
      updatePlanSponsorByInvitationCodeReceive(api.updatePlanSponsorByInvitationCode(invitationCode, payload)),
    );
  };
}

export function updateFirmByInvitationCode(invitationCode, payload) {
  return dispatch => {
    dispatch(updateFirmByInvitationCodeRequest({invitationCode, payload}));
    return dispatch(updateFirmByInvitationCodeReceive(api.updateFirmByInvitationCode(invitationCode, payload)));
  };
}

export function updateStategiesByInvitationCode(invitationCode, payload) {
  return dispatch => {
    dispatch(updateStrategiesByInvitationCodeRequest({invitationCode, payload}));
    return dispatch(
      updateStrategiesByInvitationCodeReceive(api.updateStrategiesByInvitationCode(invitationCode, payload)),
    );
  };
}

export function updateInvitationByInvitationCode(invitationCode, payload) {
  return dispatch => {
    dispatch(updateInvitationByInvitationCodeRequest({invitationCode, payload}));
    return dispatch(
      updateInvitationByInvitationCodeReceive(api.updateInvitationByInvitationCode(invitationCode, payload)),
    );
  };
}

export function finalizeOnboarding(invitationCode) {
  return dispatch => {
    dispatch(finalizeOnboardingRequest({invitationCode}));
    return dispatch(finalizeOnboardingReceive(api.finalizeOnboarding(invitationCode)));
  };
}

export function getUserType4ByCode(invitationCode) {
  return dispatch => {
    dispatch(getUserType4ByCodeRequest({invitationCode}));
    return dispatch(getUserType4ByCodeReceive(api.getUserType4ByCode(invitationCode)));
  };
}

export function updateByUserType4Code(invitationCode, payload) {
  return dispatch => {
    dispatch(updateByUserType4CodeRequest({invitationCode, payload}));
    return dispatch(authLoginReceive(api.updateByUserType4Code(invitationCode, payload))).then(response => {
      if (response.hasError) {
        throw response.error;
      }

      if (response.payload.access_token) {
        setAuthTokens(response.payload.access_token, response.payload.refresh_token);
        dispatch(authLoginComplete());
      }

      return response.payload;
    });
  };
}

// REDUCER
export const onboardingUser = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  switch (type) {
    case ACTION.GET_INVITATION_BY_CODE_REQUEST:
    case ACTION.UPDATE_BY_INVITATION_CODE_REQUEST:
    case ACTION.UPDATE_PLAN_BY_INVITATION_CODE_REQUEST:
    case ACTION.FINALIZE_ONBOARDING_REQUEST:
    case ACTION.GET_USER_TYPE_4_BY_CODE_REQUEST:
    case ACTION.UPDATE_USER_TYPE_4_BY_CODE_RECEIVE:
      return assignMergedState({isFetching: true});
    case ACTION.GET_INVITATION_BY_CODE_RECEIVE:
    case ACTION.UPDATE_BY_INVITATION_CODE_RECEIVE:
    case ACTION.UPDATE_PLAN_BY_INVITATION_CODE_RECEIVE:
    case ACTION.UPDATE_FIRM_BY_INVITATION_CODE_RECEIVE:
    case ACTION.UPDATE_STRATEGIES_BY_INVITATION_CODE_RECEIVE:
    case ACTION.UPDATE_INVITATION_BY_INVITATION_CODE_RECEIVE:
    case ACTION.FINALIZE_ONBOARDING_RECEIVE:
    case ACTION.UPDATE_USER_TYPE_4_BY_CODE_REQUEST:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : payload));
    case ACTION.GET_USER_TYPE_4_BY_CODE_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {company: payload}));
    default:
      return state;
  }
};
