import find from 'lodash/find';
import get from 'lodash/get';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';

import faBookmark from 'app/fontawesome-pro-light/faBookmark';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import {canBookmarkCompaniesForStrategy} from 'app/utilities';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {getAllPagedUT3Securities} from 'app/redux/security';
import {Alert, Button, BootstrapTableRemote, CompanyBookmarkToggle} from 'app/components';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {ratingFormatter} from 'app/utilities/ratingFormatter';
import {countryFormatter} from 'app/utilities/formatCountry';

const columnDefinitions = {
  isBookmarked: {
    dataField: 'isBookmarked',
    text: '',
    headerFormatter: (column, colIndex, components) => (
      <Fragment>
        <Icon className="text-light-gray" icon={faBookmark} />
        {components.sortElement}
      </Fragment>
    ),
    sort: true,
    formatter: (isBookmarked, security) => (
      <CompanyBookmarkToggle activeLabel="" className="d-block py-2" inactiveLabel="Save" security={security} />
    ),
    headerStyle: {width: 70},
    classes: 'p-0',
  },
  name: {
    dataField: 'name',
    text: 'Company Name',
    sort: true,
    style: {minWidth: 270},
    headerStyle: {minWidth: 270},
  },
  isin: {
    dataField: 'isin',
    text: 'ISIN',
    sort: true,
    style: {minWidth: 150},
    headerStyle: {minWidth: 150},
  },
  ticker: {
    dataField: 'ticker',
    text: 'Ticker',
    sort: true,
    style: {width: 110},
    headerStyle: {width: 110},
  },
  country: {
    dataField: 'country.code',
    text: 'Country',
    sort: true,
    formatter: countryFormatter,
    style: {width: 130},
    headerStyle: {width: 130},
  },
  sector: {
    dataField: 'sector.name',
    text: 'Sector',
    sort: true,
    style: {minWidth: 150},
    headerStyle: {minWidth: 150},
  },
  rating: {
    dataField: 'companies.rating',
    text: 'Harmony Rating',
    sort: true,
    formatter: ratingFormatter,
    style: {
      paddingRight: 52,
      textAlign: 'center',
      width: 165,
    },
    headerStyle: {width: 165},
  },
};

const columns = [
  columnDefinitions.name,
  columnDefinitions.isin,
  columnDefinitions.ticker,
  columnDefinitions.country,
  columnDefinitions.sector,
  columnDefinitions.rating,
];

const columnsWithBookmarks = [
  columnDefinitions.isBookmarked,
  columnDefinitions.name,
  columnDefinitions.isin,
  columnDefinitions.ticker,
  columnDefinitions.country,
  columnDefinitions.sector,
  columnDefinitions.rating,
];

class CompaniesUserType3Page extends Component {
  constructor(props) {
    super(props);

    this.state = {search: props.search};

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.changeField = this.changeField.bind(this);
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(this.props.sortField, this.props.sortOrder, this.props.page, this.props.pageSize, this.props.search);

    animateScroll.scrollToTop();
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search) {
      updatedPage = 1;
    }
    this.props.dispatch(push({search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search)}));
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      // data,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
      // filters,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search, strategyId} = this.props;

    animateScroll.scrollToTop();
    this.props.dispatch(
      push({
        pathname: ROUTE.STRATEGY_COMPANY_DETAIL.path(strategyId, row.isin),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search),
      }),
    );
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleCompanySearch = () => {
    this.updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search) => {
    this.props.dispatch(
      getAllPagedUT3Securities(
        {
          page,
          pageSize,
          sortField,
          sortOrder,
          search,
        },
        this.props.strategyId,
      ),
    );
  };

  render() {
    const {data, hasError, isBookmarkToggleVisible, sortField, sortOrder, page, pageSize, totalSize} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 1200}}>
          <h1>{ROUTE.STRATEGY_COMPANIES.title}</h1>

          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          <form method="GET" onSubmit={this.handleCompanySearch}>
            <div className="d-flex mb-8">
              <div className="form-control-and-icon flex-grow-1 mr-2">
                <input
                  className="form-control is-subtle"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search by Company Name, Ticker, CUSIP, or ISIN"
                  value={this.state.search}
                  onChange={this.changeField}
                />
                <div className="icon">
                  <Icon icon={faSearch} />
                </div>
              </div>
              <Button color="gray" onClick={this.handleCompanySearch}>
                Search
              </Button>
            </div>
          </form>

          <BootstrapTableRemote
            keyField="isin"
            columns={isBookmarkToggleVisible ? columnsWithBookmarks : columns}
            data={data}
            page={page}
            sizePerPage={pageSize}
            totalSize={totalSize}
            onTableChange={this.handleTableChange}
            defaultSorted={defaultSorted}
            selectRow={{
              mode: 'radio',
              hideSelectColumn: true,
              clickToSelect: true,
              onSelect: this.handleOnSelect,
            }}
          />
        </div>
      </div>
    );
  }
}

CompaniesUserType3Page.defaultProps = {
  data: [],
  totalSize: 0,
};

const mapStateToProps = (state, ownProps) => {
  const {strategyId} = ownProps.match.params;
  const newProps = getPrametersFromQuery(ownProps, {
    search: '',
    page: 1,
    pageSize: 25,
    sortField: 'companies.rating',
    sortOrder: 'asc',
  });
  newProps.data = get(state, 'security.allSecurities');
  // if (newProps.data && newProps.data.length === 1) {
  //   // add 1 as fix for pagination
  //   newProps.data.push({ isin: '-1' });
  // }
  newProps.totalSize = get(state, 'security.allSecuritiesTotal');
  newProps.hasError = get(state, 'security.hasError');
  newProps.strategyId = strategyId;

  // Determine if Bookmark Toggle is visible
  const strategy = find(state.strategy.planSelection, ({id}) => {
    return parseInt(id, 10) === parseInt(strategyId, 10);
  });
  newProps.isBookmarkToggleVisible = canBookmarkCompaniesForStrategy(strategy);

  return newProps;
};

export const CompaniesUserType3 = connect(mapStateToProps)(CompaniesUserType3Page);
