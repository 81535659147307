import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {SpringSystem} from 'rebound';

class SpringScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this);
  }

  componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({onSpringUpdate: this.handleSpringUpdate});
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  }

  getScrollTop() {
    return this.scrollbars.getScrollTop();
  }

  getScrollHeight() {
    return this.scrollbars.getScrollHeight();
  }

  getHeight() {
    return this.scrollbars.getClientHeight();
  }

  scrollTop(top) {
    const scrollTop = this.scrollbars.getScrollTop();
    this.spring.setCurrentValue(scrollTop).setAtRest();
    this.spring.setEndValue(top);
  }

  handleSpringUpdate(spring) {
    const val = spring.getCurrentValue();
    this.scrollbars.scrollTop(val);
  }

  render() {
    return (
      <Scrollbars
        {...this.props}
        ref={e => {
          this.scrollbars = e;
        }}
      />
    );
  }
}

export {SpringScrollbars};
