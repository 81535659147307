import {useMemo, useState} from 'react';
import {useGetCompaniesQuery} from 'v2/redux/typeormEndpoints';
import {keyBy, map, sum} from 'lodash';
import {useGetHoldingsQuery} from 'v2/redux/harmonyApi';
import {Holding, CompanyHolding} from 'v2/utilities/types/typeOrm';
import {getSessionJwt} from 'app/utilities';
import {calcRank, calcRankRating, formatMillions, getOrdinal} from 'v2/utilities/helpers';
import {FIRM_RATINGS} from 'v2/constants/firmRatings';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {number} from 'prop-types';

export const useCompaniesTable = (defaultOnlyShowHoldings: boolean = true) => {
  const [searchValue, setSearchValue] = useState('');
  const [onlyShowHoldings, setOnlyShowHoldings] = useState(defaultOnlyShowHoldings);
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const {
    data: companiesData,
    isError: companiesError,
    isLoading: companiesLoading,
    isSuccess: companiesSuccess,
  } = useGetCompaniesQuery({});

  const {
    data: holdingsData,
    isError: holdingsError,
    isLoading: holdingsLoading,
    isSuccess: holdingsSuccess,
  } = useGetHoldingsQuery({planSponsorId});

  const holdings = useMemo(() => keyBy((holdingsData ?? []) as Holding[], 'companyId'), [holdingsData]);

  const calcMarketValue = (companyId: number) => {
    const companyHoldings = ((holdingsData ?? []) as Holding[]).filter(datum => datum.companyId === companyId);

    return sum(map(companyHoldings, data => Number(data.marketValue)));
  };
  const companiesWithHoldings: any[] = useMemo(
    () =>
      ((companiesData ?? []) as CompanyHolding[]).map(company => ({
        ...company,
        heldMarketValue: company.company_id ? calcMarketValue(company.company_id) : null,
      })),
    [companiesData, holdings],
  );

  const handleSearchChange = (searchString: string) => {
    setSearchValue(searchString);
  };

  const handleShowHoldingsChange = (showHoldings: boolean) => {
    setOnlyShowHoldings(showHoldings);
  };

  const filteredData = useMemo(() => {
    return companiesWithHoldings
      ?.filter(datum => {
        const searchable = (datum?.name + datum?.tickers[0] + datum?.isins[0]).toUpperCase();
        return searchValue ? searchable.includes(searchValue.toUpperCase()) : true;
      })
      ?.filter((company: any) => (onlyShowHoldings ? company.heldMarketValue > 0 : true));
  }, [companiesWithHoldings, searchValue, onlyShowHoldings]);

  const formattedData = filteredData?.map((company: any) => ({
    id: company.company_id,
    name: company.name,
    engaged: company.engaged,
    transparency: company.transparency,
    country: company.country_name,
    countryCode: company.country_code,
    sector: company.sector_name,
    pctRank: company.pct_rank,
    held: Number(company.heldMarketValue),
  }));

  const calcTransparencyRating = (transparency: number) => {
    if (transparency > 0.66) return 'High';
    else if (transparency > 0.33) return 'Medium';
    else return 'Low';
  };

  const mapDataForCSV = () => {
    return formattedData?.map(row => {
      const {id, ...rest} = row;
      return {
        ...rest,
        engaged: Number(row.engaged) ? 'Yes' : 'No',
        transparency: calcTransparencyRating(row.transparency),
        pctRank: !isNaN(row.pctRank)
          ? `${Object.keys(FIRM_RATINGS)[calcRankRating(row.pctRank, 1) - 1]} ${calcRank(row.pctRank)}${getOrdinal(
              calcRank(row.pctRank),
            )}`
          : DATA_STATUS.NONE,
        held: formatMillions(row.held ?? 0, 1, '$'),
      };
    });
  };

  return {
    data: formattedData,
    isError: companiesError || holdingsError,
    isLoading: companiesLoading || holdingsLoading,
    isSuccess: companiesSuccess && holdingsSuccess,
    mapDataForCSV,
    handleSearchChange,
    handleShowHoldingsChange,
  };
};
