import {Theme} from '../theme';

export const Voting = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 13H2" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 9.3501L8 9.3501" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15 5L16.76 5C17.1321 5.0002 17.4967 5.10419 17.813 5.30028C18.1292 5.49637 18.3844 5.77679 18.55 6.11L22 13V20.5C22 21.0304 21.7893 21.5391 21.4142 21.9142C21.0391 22.2893 20.5304 22.5 20 22.5H4C3.46957 22.5 2.96086 22.2893 2.58579 21.9142C2.21071 21.5391 2 21.0304 2 20.5V13L5.45 6.11C5.61558 5.77679 5.87083 5.49637 6.18704 5.30028C6.50326 5.10419 6.86792 5.0002 7.24 5H8"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 9V3.15999C15 2.51948 14.5867 2.00024 14.0769 2.00024H9.92308C9.41328 2.00024 9 2.51948 9 3.15999V9"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
