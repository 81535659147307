import {createSlice} from '@reduxjs/toolkit';

const initialState = {};

export const filterOptionsSlice = createSlice({
  name: 'filterOptions',
  initialState,
  reducers: {
    updateFilterOptions: (state, action) => {
      const {options, pathname} = action.payload;
      state[pathname] = options;
    },
    resetFilterOptions: (state, action) => {
      const {pathname} = action.payload;
      state[pathname] = {};
    },
  },
});

export const {resetFilterOptions, updateFilterOptions} = filterOptionsSlice.actions;

export default filterOptionsSlice.reducer;
