import React from 'react';
import styled from 'styled-components/macro';
import {useLocation, useParams} from 'react-router-dom';
import {
  MANAGERS_TAB_ROUTES,
  COMPANIES_TAB_ROUTES,
  DASHBOARD_TAB_ROUTES,
  CLIENT_TAB_ROUTES,
  UT3_COMPANIES_TAB_ROUTES,
  UT3_DASHBOARD_TAB_ROUTES,
} from 'v2/constants/tabRoutes';
import {ParamTabBar, TabBar} from '../molecules/TabBar';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  width: auto;
  max-width: ${props => props.theme.constants.fixWidth};
  min-width: 56.25rem;
  margin: 0 auto;
  margin-top: 32px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${props => props.theme.colors.black}26;
  border-radius: 8px;
`;

export const ManagerTabsCard: React.FC<{}> = ({children}) => {
  const {pathname} = useLocation();
  const {strategyId = ''} = useParams<{strategyId?: string}>();

  return (
    <>
      <CardWrapper>
        <TabBar
          // @ts-ignore
          tabs={MANAGERS_TAB_ROUTES(strategyId)}
          selectedTab={pathname}
        />
        {children}
      </CardWrapper>
    </>
  );
};

export const CompanyTabsCard: React.FC<{}> = ({children}) => {
  const {pathname} = useLocation();
  const {companyId = ''} = useParams<{companyId?: string}>();

  return (
    <>
      <CardWrapper>
        <TabBar
          // @ts-ignore
          tabs={COMPANIES_TAB_ROUTES(companyId)}
          selectedTab={pathname}
        />
        {children}
      </CardWrapper>
    </>
  );
};

export const DashboardTabsCard: React.FC<{}> = ({children}) => {
  const {pathname} = useLocation();

  return (
    <>
      <CardWrapper>
        <TabBar
          // @ts-ignore
          tabs={DASHBOARD_TAB_ROUTES}
          selectedTab={pathname}
        />
        {children}
      </CardWrapper>
    </>
  );
};

export const ClientTabsCard: React.FC<{}> = ({children}) => {
  const {pathname, search} = useLocation();
  const {strategyId = ''} = useParams<{strategyId?: string}>();

  return (
    <>
      <CardWrapper>
        <ParamTabBar
          // @ts-ignore
          tabs={CLIENT_TAB_ROUTES(strategyId)}
          selectedTab={pathname + search}
        />
        {children}
      </CardWrapper>
    </>
  );
};

export const UT3CompanyTabsCard: React.FC<{}> = ({children}) => {
  const {pathname, search} = useLocation();
  const {companyId = ''} = useParams<{companyId?: string}>();

  return (
    <>
      <CardWrapper>
        <ParamTabBar
          // @ts-ignore
          tabs={UT3_COMPANIES_TAB_ROUTES(companyId)}
          selectedTab={pathname + search}
        />
        {children}
      </CardWrapper>
    </>
  );
};

export const UT3DashboardTabsCard: React.FC<{}> = ({children}) => {
  const {pathname, search} = useLocation();
  return (
    <>
      <CardWrapper>
        <ParamTabBar
          // @ts-ignore
          tabs={UT3_DASHBOARD_TAB_ROUTES()}
          selectedTab={pathname + search}
        />
        {children}
      </CardWrapper>
    </>
  );
};
