import {assignWith, clone, isEmpty, find, get, map, orderBy, pick, filter, some} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import {connect} from 'react-redux';
import {Element as ScrollElement, scroller} from 'react-scroll';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm, formValueSelector} from 'redux-form';

// Local Imports
import {FormGroup, SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {Button, FormFeedback, MultiUploaderWrapper} from 'app/components';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {
  AUTOCOMPLETE,
  CHARACTER_LIMIT,
  FORM_MESSAGE,
  INVESTMENT_VEHICLES,
  PORTFOLIO_MANAGEMENT_TYPES,
} from 'app/constants';
import {errorRequiredFields, formatUtcDate, validateFieldArray} from 'app/utilities';
import {validateStrategyUpload} from 'app/utilities/fileFunctions';
import planHoldingsTemplateCsv from 'app/assets/docs/plan-holdings-template.csv';
import {toastError} from 'app/utilities/toast';

const INITIAL_STRATEGY_VALUES = {portfolioManagementType: PORTFOLIO_MANAGEMENT_TYPES[0]};

const validate = values => {
  const errors = errorRequiredFields(values, ['']);

  const strategyErrors = validateFieldArray(values.strategies, (strategy, ownErrors) => {
    const hasValues = strategy.name || strategy.marketIndex || strategy.investmentVehicle;

    if (hasValues) {
      if (!strategy.name) {
        ownErrors.name = 'Required';
      }
      if (!strategy.marketIndex) {
        ownErrors.marketIndex = 'Required';
      }
    }
  });

  strategyErrors && (errors.strategies = strategyErrors);

  return errors;
};

class StrategyUploader extends Component {
  render() {
    return (
      <MultiUploaderWrapper
        value={this.props.input.value}
        isValidatingFileVisible={true}
        onFilesChanged={(files, s3file) => {
          if (!s3file) {
            this.props.input.onChange([]);
          } else {
            this.props.input.onChange([{name: get(s3file, 'fileKey', null), isNewFile: true}]);
          }
        }}
        uploaderProps={{
          accept: 'text/csv, .csv',
          onError: () => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE),
          onFinish: this.props.onFinish,
          onSignedUrl: () => {
            /* Suppress Console logs */
          },
        }}
      />
    );
  }
}

class OnboardingUt3StrategiesFormComponent extends Component {
  constructor(props) {
    super(props);

    this.onStrategyClick = this.onStrategyClick.bind(this);
    this.renderStrategies = this.renderStrategies.bind(this);
  }

  renderStrategies = ({fields: strategies, marketIndexes, onStrategyClick, getCsvFileMeta}) =>
    strategies.map((strategy, index) => {
      if (isEmpty(marketIndexes)) {
        return null;
      }

      const strategyEntity = strategies.get(index);
      const isHarmonyIndex = get(strategyEntity, 'isHarmonyIndex');

      const isLast = index === strategies.length - 1;

      const standardMarketIndexes = !isEmpty(marketIndexes) ? filter(marketIndexes, {isHarmonyIndex: false}) : [];

      return (
        <Fragment key={index}>
          <Field
            label="Strategy Name*"
            name={`${strategy}.name`}
            type="text"
            component={TextFormGroup}
            autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            maxLength={CHARACTER_LIMIT.STRATEGY_NAME}
          />
          <div className="form-grid">
            <Field
              label="Portfolio Management Type"
              name={`${strategy}.portfolioManagementType`}
              component={SelectFormGroup}
              options={PORTFOLIO_MANAGEMENT_TYPES}
              className="col-3"
              disabled={isHarmonyIndex}
            />
            <Field
              label="Investment Vehicle"
              name={`${strategy}.investmentVehicle`}
              component={SelectFormGroup}
              options={INVESTMENT_VEHICLES}
              shouldInsertDefault={true}
              className="col-3"
            />
          </div>
          <Field
            label="Reference Index*"
            name={`${strategy}.marketIndex`}
            component={props => (
              <FormGroup {...props} typeahead options={standardMarketIndexes}>
                {(inputProps, wrapperProps) => (
                  <Typeahead
                    {...wrapperProps}
                    disabled={isHarmonyIndex}
                    ref={typeahead => {
                      this.typeahead = typeahead;
                    }}
                    placeholder="Select a Reference Index"
                    inputProps={inputProps}
                    labelKey="name"
                  />
                )}
              </FormGroup>
            )}
          />
          <div className="mb-9">
            <div className="label-hint-container mb-3">
              <label>Upload Plan Holdings (CSV)</label>
              <span className="label-hint">
                <a href={planHoldingsTemplateCsv} target="_blank">
                  Download CSV Template
                </a>
              </span>
            </div>
            <Field name={`${strategy}.planHoldingsUpload`} component={StrategyUploader} onFinish={getCsvFileMeta} />
          </div>

          {isLast && <ScrollElement name="lastStrategy" />}
          {isLast ? (
            <Button
              onClick={() => {
                strategies.push(clone(INITIAL_STRATEGY_VALUES));
                scroller.scrollTo('lastStrategy', {
                  duration: 800,
                  delay: 100,
                  smooth: true,
                  offset: 0,
                });
              }}
            >
              Add Another Strategy
            </Button>
          ) : (
            <Button onClick={() => onStrategyClick(strategies, index)} color="red">
              Remove Strategy
            </Button>
          )}
          {!isLast && <hr className="my-8" />}
        </Fragment>
      );
    });

  onStrategyClick(strategies, index) {
    this.props.dispatch(
      openSimpleModal({
        title: 'Delete Confirmation',
        message: 'Do you really want to delete this strategy?',
        buttons: [
          {
            label: 'Cancel',
            close: true,
          },
          {
            className: 'btn-red ml-auto',
            label: 'Yes, Delete Strategy',
            clickHandler: () => strategies.remove(index),
            close: true,
          },
        ],
      }),
    );
  }

  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />

        {this.props.marketIndexes && (
          <FieldArray
            name="strategies"
            component={this.renderStrategies}
            marketIndexes={this.props.marketIndexes}
            onStrategyClick={this.onStrategyClick}
            changeFieldValue={this.props.changeFieldValue}
            getCsvFileMeta={this.props.getCsvFileMeta}
          />
        )}

        <div className="form-footer is-right mt-12">
          <Button
            type="submit"
            disabled={
              this.props.areNewFilesWaitingForUpload &&
              (this.props.isCreatingStagingUpload || this.props.hasStagingUploadError)
            }
            solid
          >
            Save &amp; Continue
          </Button>
        </div>
      </form>
    );
  }
}

OnboardingUt3StrategiesFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingUser: PropTypes.object,
  marketIndexes: PropTypes.array.isRequired,
};

OnboardingUt3StrategiesFormComponent.defaultProps = {onboardingUser: {}};

const mapStateToProps = state => {
  const form = 'onboarding-ut3-strategies-form';
  const selector = formValueSelector(form);
  const formStrategies = selector(state, 'strategies');
  const areNewFilesWaitingForUpload = some(formStrategies, formStrategy =>
    get(formStrategy, 'planHoldingsUpload[0].isNewFile'),
  );

  return {
    form,
    areNewFilesWaitingForUpload,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStrategyUploadInfoModal: (title, message) =>
      dispatch(
        openSimpleModal({
          title,
          message,
          customClassName: 'modal-lg',
        }),
      ),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingPlanSponsor = {}} = ownProps;
  const defaultValues = {strategies: [clone(INITIAL_STRATEGY_VALUES)]};

  // Pick which values will pre-populate the form.
  let userValues = pick(onboardingPlanSponsor, ['strategies']);

  if (!isEmpty(ownProps.marketIndexes)) {
    const strategies = map(orderBy(onboardingPlanSponsor.strategies, ['id']), strategy => {
      const marketIndex = find(ownProps.marketIndexes, {id: strategy.marketIndexId});
      return {
        ...clone(INITIAL_STRATEGY_VALUES),
        ...strategy,
        marketIndex: !isEmpty(marketIndex) ? marketIndex.name : null,
        isHarmonyIndex: get(marketIndex, 'isHarmonyIndex'),
        planHoldingsUpload: map(strategy.strategyUploads, strategyUpload => {
          // TODO: Make a utility function
          const formattedDateFrom = formatUtcDate(strategyUpload.startDate, 'MMM DD, YYYY');
          const formattedDateTo = formatUtcDate(strategyUpload.endDate, 'MMM DD, YYYY');
          const meta = `${formattedDateFrom} - ${formattedDateTo}`;
          return {
            name: strategyUpload.originalFileName,
            meta,
          };
        }),
      };
    });

    userValues = {strategies: isEmpty(strategies) ? defaultValues.strategies : strategies};
  }

  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
    getCsvFileMeta: validateStrategyUpload(ownProps.createStagingUpload, dispatchProps.onStrategyUploadInfoModal),
  });
};

export const OnboardingUt3StrategiesForm = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    validate,
  }),
)(OnboardingUt3StrategiesFormComponent);
