import {clone, get, includes, map} from 'lodash';
import React, {Component} from 'react';
import {Switch} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Link} from 'react-router-dom';

import {ROLE, ROUTE} from 'app/constants';
import {PrivateRoute, SettingsSideNav} from 'app/components';
import {
  HarmonyIndexDraft,
  IndexCenter,
  SettingsESGDraft,
  SettingsFirmManager,
  SettingsFirmManagerAdd,
  SettingsFirmManagerEdit,
  SettingsFirmProfile,
  SettingsPlan,
  SettingsProfile,
  SettingsUserManager,
  SettingsUserManagerEdit,
} from 'app/pages';
import {getSessionJwt, withUser} from 'app/utilities';
import {userType1MenuProps, userType3MenuProps} from 'app/constants/settingsNavigation';
import {EmptyLayout} from 'app/components/EmptyLayout';

const renderSingleCard = item => {
  return (
    <div className="setting-card" key={item.path()}>
      <div className="setting-card-content">
        <div className="setting-card-header">
          <div className="setting-card-name">{item.menuTitle || item.title}</div>
        </div>
        <div className="setting-card-body">
          <p>{item.menuMessage}</p>
        </div>
        <div className="setting-card-footer">
          <Link className="w-100 btn btn-outline" to={item.path()}>
            View Settings
          </Link>
        </div>
      </div>
    </div>
  );
};

const renderAllCards = menuProps => {
  return map(menuProps, item => renderSingleCard(item));
};

class SettingsPage extends Component {
  render() {
    const authUser = getSessionJwt(true)?.contents;

    let menuProps = null;
    if (includes(authUser.roles, ROLE.USER_TYPE_1)) {
      menuProps = clone(userType1MenuProps);
    } else if (includes(authUser.roles, ROLE.USER_TYPE_2)) {
      menuProps = clone(userType1MenuProps);
    } else if (includes(authUser.roles, ROLE.USER_TYPE_3)) {
      menuProps = clone(userType3MenuProps);
    }

    if (get(this.props, 'location.pathname') === ROUTE.SETTINGS.path()) {
      return (
        <div className="p-content-lg">
          <h1>Settings</h1>
          <div className="setting-card-grid">{renderAllCards(menuProps)}</div>
        </div>
      );
    }

    return (
      <div className="flex-grow-1 d-flex">
        <div className="setting-nav">
          <div className="settings-nav-title">
            <Link to={ROUTE.SETTINGS.path()}>{ROUTE.SETTINGS.title}</Link>
          </div>
          <SettingsSideNav menuRoutes={menuProps} />
        </div>
        <div className="settings-body">
          <Switch>
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_ESG_DRAFT.path()}
              component={SettingsESGDraft}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_FIRMS_ADD.path()}
              component={SettingsFirmManagerAdd}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_FIRMS_EDIT.path(':firmId')}
              component={SettingsFirmManagerEdit}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_FIRMS_MANAGER.path()}
              component={SettingsFirmManager}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_FIRM_PROFILE.path()}
              component={SettingsFirmProfile}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.HARMONY_INDEX_CENTER.path()}
              component={IndexCenter}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.HARMONY_INDEX_DRAFT.path(':strategyId')}
              component={HarmonyIndexDraft}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_PLAN_PROFILE.path()}
              component={SettingsPlan}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_USER_PROFILE.path()}
              component={SettingsProfile}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_USERS.path()}
              component={SettingsUserManager}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_USERS_ADD.path()}
              component={SettingsUserManagerEdit}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
            />
            <PrivateRoute
              exact
              layout={EmptyLayout}
              path={ROUTE.SETTINGS_USERS_EDIT.path(':userId')}
              component={SettingsUserManagerEdit}
              allowedRoles={[ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export const Settings = compose(withUser, connect(null, null))(SettingsPage);
