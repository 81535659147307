import {Theme} from '../theme';

export const EthnicAndCulturalIdentity = ({color = Theme.colors.dark, size = 32}) => (
  <svg width={size} height={size} viewBox="0 0 22 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M5.68799 5.49277C5.32242 4.51942 4.37862 3.86499 3.33897 3.86499C2.32688 3.86499 1.4055 4.4844 0.990578 5.52988C0.96739 5.5883 0.952073 5.64956 0.942161 5.71164L0.119317 10.8649C0.056706 11.257 0.359632 11.6121 0.756717 11.6121C1.1132 11.6121 1.40219 11.9011 1.40219 12.2576V14.4857C1.40219 15.038 1.84991 15.4857 2.40219 15.4857H4.26703C4.81931 15.4857 5.26703 15.038 5.26703 14.4857V12.2616C5.26703 11.9029 5.55782 11.6121 5.91652 11.6121C6.31608 11.6121 6.62089 11.2548 6.55789 10.8602L5.72089 5.61832L5.68799 5.49277Z" />
    <path d="M5.26718 1.92822C5.26718 0.860214 4.40714 0 3.33913 0C2.27112 0 1.40234 0.860214 1.40234 1.92822C1.40234 2.99623 2.27112 3.86501 3.33913 3.86501C4.40714 3.86501 5.26718 2.99623 5.26718 1.92822Z" />
    <path d="M13.3493 5.49277C12.9836 4.51942 12.0397 3.86499 11.0001 3.86499C9.98803 3.86499 9.06682 4.48438 8.65175 5.52982C8.62853 5.58828 8.6132 5.64958 8.60328 5.7117L7.78045 10.8649C7.71784 11.257 8.02076 11.6121 8.41785 11.6121C8.77434 11.6121 9.06332 11.9011 9.06332 12.2576V14.4857C9.06332 15.038 9.51104 15.4857 10.0633 15.4857H11.9283C12.4806 15.4857 12.9283 15.038 12.9283 14.4857V12.2615C12.9283 11.9029 13.2191 11.6121 13.5777 11.6121C13.9773 11.6121 14.282 11.2548 14.219 10.8603L13.382 5.61832L13.3493 5.49277Z" />
    <path d="M12.937 1.92822C12.937 0.860214 12.0683 0 11.0003 0C9.93225 0 9.06348 0.860214 9.06348 1.92822C9.06348 2.99623 9.93225 3.86501 11.0003 3.86501C12.0683 3.86501 12.937 2.99623 12.937 1.92822Z" />
    <path d="M21.0432 5.61832L21.0104 5.49277C20.6447 4.51942 19.7009 3.86499 18.6612 3.86499C17.6493 3.86499 16.728 4.48438 16.3129 5.52982C16.2897 5.58828 16.2743 5.64958 16.2644 5.7117L15.4416 10.8649C15.379 11.257 15.6819 11.6121 16.079 11.6121C16.4355 11.6121 16.7245 11.9011 16.7245 12.2576V14.4857C16.7245 15.038 17.1722 15.4857 17.7245 15.4857H19.5895C20.1417 15.4857 20.5895 15.038 20.5895 14.4857V12.2615C20.5895 11.9029 20.8802 11.6121 21.2389 11.6121C21.6384 11.6121 21.9432 11.2548 21.8802 10.8603L21.0432 5.61832Z" />
    <path d="M20.5896 1.92822C20.5896 0.860214 19.7294 0 18.6614 0C17.5934 0 16.7246 0.860214 16.7246 1.92822C16.7246 2.99623 17.5934 3.86501 18.6614 3.86501C19.7294 3.86501 20.5896 2.99623 20.5896 1.92822Z" />
  </svg>
);
