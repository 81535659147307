import React, {Component} from 'react';
import moment from 'moment';
import {CONFIG} from 'app/constants';

export class CoverPage extends Component {
  render() {
    const date = moment().subtract(3, 'months');
    const year = date.year();
    const quarter = Math.floor(date.month() / 3) + 1;

    const {companyName} = this.props;
    return (
      <React.Fragment>
        <div
          className="w-100 cover-page-container"
          style={{
            backgroundImage: `url(${CONFIG.ASSETS_URL}/images/plansponsor_report-front_cover-background.png)`,
          }}
        >
          <div className="cover-page-title-block" />
          <img src={`${CONFIG.ASSETS_URL}/logos/ha-logo_white.svg`} className="cover-page-logo-img-style" />
          <img src={`${CONFIG.ASSETS_URL}/logos/ha-logo_watermark.svg`} className="cover-page-watermark-img" />
          <div className="cover-page-title-container">
            <h1 className="cover-page-company-name-text text-uppercase">{companyName}</h1>
            <h1 className="cover-page-progress-text text-uppercase">PROGRESS REPORT</h1>
            <div>
              <h1 className="cover-page-quarter-text">
                Q{quarter} {year}
              </h1>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
