// A wrapper component that loads several conical gradient PNGs into CanvasPattern objects.
// Note: props.children must consist of exactly one function.
//
// Usage:
//
// <ConicalGradientProvider>
//   {({ conicalGradients }) => {
//     <Doughnut data={{
//       datasets: [{
//         data: [80, 20],
//         backgroundColor: [conicalGradients.red, conicalGradients.lightestGray],
//       }],
//     }} />
//   }
// <ConicalGradientProvider />

import {Component} from 'react';
import PropTypes from 'prop-types';
import {each} from 'lodash';

import conicalGradientAlmostWhite from 'app/assets/images/conical-gradient-almost-white.png';
import conicalGradientGreen from 'app/assets/images/conical-gradient-green.png';
import conicalGradientLightGray from 'app/assets/images/conical-gradient-light-gray.png';
import conicalGradientLighterGray from 'app/assets/images/conical-gradient-lighter-gray.png';
import conicalGradientLightestGray from 'app/assets/images/conical-gradient-lightest-gray.png';
import conicalGradientNavy from 'app/assets/images/conical-gradient-navy.png';
import linearGradientNavySmall from 'app/assets/images/linear-gradient-navy-small.png';
import conicalGradientRed from 'app/assets/images/conical-gradient-red.png';

const images = {
  almostWhite: conicalGradientAlmostWhite,
  green: conicalGradientGreen,
  lightGray: conicalGradientLightGray,
  lighterGray: conicalGradientLighterGray,
  lightestGray: conicalGradientLightestGray,
  navy: conicalGradientNavy,
  linearNavySmall: linearGradientNavySmall,
  red: conicalGradientRed,
};

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

let imagesLoaded = 0;

each(images, (path, key) => {
  const img = new Image();
  img.addEventListener('load', () => {
    images[key] = ctx.createPattern(img, 'no-repeat');
    imagesLoaded += 1;
  });
  img.src = path;
});

class ConicalGradientProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadingCheckInterval = setInterval(() => {
      if (imagesLoaded === Object.keys(images).length) {
        !this.isUnmounted && this.setState({conicalGradients: images});
        clearInterval(this.loadingCheckInterval);
      }
    }, 100);
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    return this.props.children({...this.state});
  }
}

ConicalGradientProvider.propTypes = {children: PropTypes.func.isRequired};

export {ConicalGradientProvider};
