import {endsWith, startsWith} from 'lodash';

// Ignore actions beginning with these strings
const blackList = ['ONBOARDING', 'harmonyOnboardingApi'];

// Only count actions ending with these strings as pending
const whiteListUp = ['_REQUEST', '/request', '/pending'];
const whiteListDown = ['_RECEIVE', '/receive', '/fulfilled', '/rejected'];

/**
 * Controls the pending tasks counter which is used to show
 * the loading indicator (Spinner).
 */
export function pendingTasks(state = 0, action) {
  if (
    !blackList.some(blackListed => startsWith(action.type, blackListed)) &&
    whiteListUp.some(whiteListed => endsWith(action.type, whiteListed))
  ) {
    return state + 1;
  }

  if (
    !blackList.some(blackListed => startsWith(action.type, blackListed)) &&
    whiteListDown.some(whiteListed => endsWith(action.type, whiteListed))
  ) {
    return state > 0 && state - 1;
  }

  return state;
}
