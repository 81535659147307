/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {DATA_CATEGORY} from '../constants';
import {IssueDataCardDeck, IssueDataTable} from '../components';
import {getAnnualPendingIssueData} from '../redux/companyAnnualIssues';

class CompanyDataPage extends Component {
  constructor(props) {
    super();

    // TODO: Currently, this call references the first and only companyPortal for the current user
    // At a later date, this will behave as a many-to-many relationship, and a cookie or session
    // Variable should be used to track which company portal is currently selected
    props.dispatch(getAnnualPendingIssueData(props?.authUser?.companyPortals[0]?.id));
  }

  state = {
    mode: 'table',
  };

  render() {
    const {mode} = this.state;
    const {isFetching, data} = this.props;

    if (isFetching || data === undefined) {
      return null;
    }

    return (
      <div className="padded-container">
        <h1 className="title-fade" style={{marginBottom: '1rem'}}>
          Your Data
        </h1>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="btn btn-primary btn-link"
            style={{fontSize: '1.5rem'}}
            onClick={() => {
              this.setState({mode: 'table'});
            }}
          >
            {mode === 'table' ? (
              <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 8.33337H2.66667V5.66671H0V8.33337ZM0 13.6667H2.66667V11H0V13.6667ZM0 3.00004H2.66667V0.333374H0V3.00004ZM5.33333 8.33337H24V5.66671H5.33333V8.33337ZM5.33333 13.6667H24V11H5.33333V13.6667ZM5.33333 0.333374V3.00004H24V0.333374H5.33333Z"
                  fill="#003462"
                />
              </svg>
            ) : (
              <div>
                <svg
                  className="not-hover"
                  width="24"
                  height="14"
                  viewBox="0 0 24 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 8.33337H2.66667V5.66671H0V8.33337ZM0 13.6667H2.66667V11H0V13.6667ZM0 3.00004H2.66667V0.333374H0V3.00004ZM5.33333 8.33337H24V5.66671H5.33333V8.33337ZM5.33333 13.6667H24V11H5.33333V13.6667ZM5.33333 0.333374V3.00004H24V0.333374H5.33333Z"
                    fill="#636363"
                  />
                </svg>
                <svg
                  className="hover"
                  width="24"
                  height="14"
                  viewBox="0 0 24 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 8.33337H2.66667V5.66671H0V8.33337ZM0 13.6667H2.66667V11H0V13.6667ZM0 3.00004H2.66667V0.333374H0V3.00004ZM5.33333 8.33337H24V5.66671H5.33333V8.33337ZM5.33333 13.6667H24V11H5.33333V13.6667ZM5.33333 0.333374V3.00004H24V0.333374H5.33333Z"
                    fill="#003462"
                  />
                </svg>
              </div>
            )}
          </button>
          <button
            className={`btn btn-primary btn-link ${mode === 'card' ? '' : 'toggle-off'}`}
            style={{fontSize: '1.5rem'}}
            onClick={() => {
              this.setState({mode: 'card'});
            }}
          >
            {mode === 'card' ? (
              <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.333252 8.66663H6.99992V0.666626H0.333252V8.66663ZM0.333252 18H6.99992V9.99996H0.333252V18ZM8.33325 18H14.9999V9.99996H8.33325V18ZM16.3333 18H22.9999V9.99996H16.3333V18ZM8.33325 8.66663H14.9999V0.666626H8.33325V8.66663ZM16.3333 0.666626V8.66663H22.9999V0.666626H16.3333Z"
                  fill="#003462"
                />
              </svg>
            ) : (
              <div>
                <svg
                  className="not-hover"
                  width="23"
                  height="18"
                  viewBox="0 0 23 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.333252 8.66663H6.99992V0.666626H0.333252V8.66663ZM0.333252 18H6.99992V9.99996H0.333252V18ZM8.33325 18H14.9999V9.99996H8.33325V18ZM16.3333 18H22.9999V9.99996H16.3333V18ZM8.33325 8.66663H14.9999V0.666626H8.33325V8.66663ZM16.3333 0.666626V8.66663H22.9999V0.666626H16.3333Z"
                    fill="#636363"
                  />
                </svg>
                <svg
                  className="hover"
                  width="23"
                  height="18"
                  viewBox="0 0 23 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.333252 8.66663H6.99992V0.666626H0.333252V8.66663ZM0.333252 18H6.99992V9.99996H0.333252V18ZM8.33325 18H14.9999V9.99996H8.33325V18ZM16.3333 18H22.9999V9.99996H16.3333V18ZM8.33325 8.66663H14.9999V0.666626H8.33325V8.66663ZM16.3333 0.666626V8.66663H22.9999V0.666626H16.3333Z"
                    fill="#003462"
                  />
                </svg>
              </div>
            )}
          </button>
        </div>
        {/* BEGIN DATA TABLE */}
        <div hidden={this.state.mode !== 'table'}>
          <IssueDataTable category={DATA_CATEGORY.ENVIRONMENT} data={data.ENVIRONMENTAL} />
          <IssueDataTable category={DATA_CATEGORY.SOCIAL} data={data.SOCIAL} />
          <IssueDataTable category={DATA_CATEGORY.GOVERNANCE} data={data.GOVERNANCE} />
        </div>
        {/* END DATA TABLE */}
        {/* BEGIN DATA CARDS */}
        <div hidden={this.state.mode !== 'card'}>
          <IssueDataCardDeck category={DATA_CATEGORY.ENVIRONMENT} data={data.ENVIRONMENTAL} />
          <IssueDataCardDeck category={DATA_CATEGORY.SOCIAL} data={data.SOCIAL} />
          <IssueDataCardDeck category={DATA_CATEGORY.GOVERNANCE} data={data.GOVERNANCE} />
        </div>
        {/* END DATA CARDS */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.companyAnnualIssues.isFetching,
    data: state.companyAnnualIssues.data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const CompanyData = compose(connect(mapStateToProps, null, mergeProps))(CompanyDataPage);
