import {useState} from 'react';
import {RootStateOrAny, useSelector} from 'react-redux';
import {keyBy, map, sum} from 'lodash';
import {useGetCompaniesQuery, useGetFirmHoldingsByIdQuery} from 'v2/redux/typeormEndpoints';
import {CompanyHolding, FirmHolding} from 'v2/utilities/types/typeOrm';
import {calcRank, formatMillions, getOrdinal} from 'v2/utilities/helpers';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {isImpersonating} from 'app/utilities';

export const useUT3CompaniesTable = (defaultOnlyShowHoldings: boolean = true) => {
  const [searchValue, setSearchValue] = useState('');
  const [onlyShowHoldings, setOnlyShowHoldings] = useState(defaultOnlyShowHoldings);

  const firmId: number =
    useSelector((state: RootStateOrAny) =>
      isImpersonating() ? state.auth.impersonatedUser.firmId : state.auth.firmId,
    ) ?? 0;

  const {
    data: companiesData,
    isError: companiesError,
    isLoading: companiesLoading,
    isSuccess: companiesSuccess,
  } = useGetCompaniesQuery({});

  const {
    data: firmData,
    isError: firmIsError,
    isLoading: firmIsLoading,
    isSuccess: firmIsSuccess,
  } = useGetFirmHoldingsByIdQuery({firmId});

  const firms = keyBy((firmData ?? []) as FirmHolding[], 'companyId');

  const companiesWithHoldings: any[] = ((companiesData ?? []) as CompanyHolding[]).map(company => ({
    ...company,
    heldMarketValue: Number(firms[company.company_id]?.amountHeld || 0),
  }));

  const handleSearchChange = (searchString: string) => {
    setSearchValue(searchString);
  };

  const handleShowHoldingsChange = (showHoldings: boolean) => {
    setOnlyShowHoldings(showHoldings);
  };

  const calcMarketValue = (companyId: number) => {
    const firmHoldings = ((firmData ?? []) as FirmHolding[]).filter(datum => datum.companyId === companyId);

    return sum(map(firmHoldings, data => Number(data.amountHeld)));
  };

  const filteredData = companiesWithHoldings
    ?.filter(datum => {
      let searchString = '';

      if (datum?.name) searchString += datum.name;
      if (datum?.tickers?.[0]) searchString += ` ${datum.tickers[0]}`;
      if (datum?.isins?.[0]) searchString += ` ${datum.isins[0]}`;

      return searchString?.toUpperCase().includes(searchValue.toUpperCase());
    })
    ?.filter((company: any) => (onlyShowHoldings ? company.heldMarketValue > 0 : true));

  const formattedData = filteredData?.map((company: any) => ({
    id: company.company_id,
    name: company.name,
    engaged: company.engaged,
    transparency: company.transparency,
    country: company.country_name,
    countryCode: company.country_code,
    sector: company.sector_name,
    pctRank: company.pct_rank,
    held: calcMarketValue(company.company_id),
  }));

  const calcTransparencyRating = (transparency: number) => {
    if (transparency > 0.66) return 'High';
    else if (transparency > 0.33) return 'Medium';
    else return 'Low';
  };

  const mapDataForCSV = () => {
    return formattedData?.map(row => {
      const {id, ...rest} = row;
      return {
        ...rest,
        engaged: Number(row.engaged) ? 'Yes' : 'No',
        transparency: calcTransparencyRating(row.transparency),
        pctRank: !isNaN(row.pctRank)
          ? `${calcRank(row.pctRank)}${getOrdinal(calcRank(row.pctRank))}`
          : DATA_STATUS.NONE,
        held: formatMillions(row.held ?? 0, 1, '$'),
      };
    });
  };

  return {
    data: formattedData,
    isError: companiesError || firmIsError,
    isLoading: companiesLoading || firmIsLoading,
    isSuccess: companiesSuccess && firmIsSuccess,
    mapDataForCSV,
    handleSearchChange,
    handleShowHoldingsChange,
  };
};
