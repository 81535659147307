import {Theme} from '../theme';

export const BarChart = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 16.666V11.666" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.5 16.6673V8.33398" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.5 16.6673V3.33398" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
