import {Theme} from '../theme';

export const Eye = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33331 15.9997C1.33331 15.9997 6.66665 5.33301 16 5.33301C25.3333 5.33301 30.6666 15.9997 30.6666 15.9997C30.6666 15.9997 25.3333 26.6663 16 26.6663C6.66665 26.6663 1.33331 15.9997 1.33331 15.9997Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
