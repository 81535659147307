import {useGetCompanyIssuesFileUploadsQuery} from 'v2/redux/typeormEndpoints';
import {useGetAdminAnnualPendingIssuesByCompanyIdQuery} from 'v2/redux/harmonyApi';
import {UploadedFile} from 'v2/components/organisms/FileInputField';

export const useCompanyIssueFileUploads = ({companyId}: {companyId: number}) => {
  const {data: fileUploads} = useGetCompanyIssuesFileUploadsQuery({companyId});

  const {data: issueData = {}} = useGetAdminAnnualPendingIssuesByCompanyIdQuery({companyId}, {skip: !companyId});

  // Flatten the issues data
  const issuesList = Object.values(issueData)
    .flat()
    .map((item: any) => Object.values(item.data))
    .flat();

  // Flag files that are in use by AdminCompanyIssues
  const fileInUse = (filePath: string) => {
    return issuesList.some((item: any) => item?.sourceLink === filePath);
  };

  const unsortedFileList: UploadedFile[] = fileUploads
    ?.filter((item: any) => item.filePath)
    ?.map((item: any) => {
      return {
        id: item.id,
        fileName: item.fileName,
        sourceLink: item.filePath,
        inUse: fileInUse(item.filePath),
        createdAt: item.createdAt,
      };
    });

  const fileList = unsortedFileList?.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return {fileList};
};
