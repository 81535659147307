import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import {map} from 'lodash';

const SelectFormGroup = ({
  input,
  label,
  labelHint,
  description,
  className,
  onChange,
  disabled,
  options,
  optionValueProp,
  optionLabelProp,
  inputClassName,
  id,
  autoFocus,
  shouldInsertDefault,
  defaultLabel,
  meta: {touched, error},
  size,
  subtle,
}) => {
  let selectOptions = options.slice(0); // clone the array
  if (options.length > 0 && typeof options[0] === 'string') {
    selectOptions = map(selectOptions, option => ({
      [optionLabelProp]: option,
      [optionValueProp]: option,
    }));
  }
  if (shouldInsertDefault) {
    selectOptions.unshift({[optionLabelProp]: defaultLabel});
  }
  const selectClassName = cn(
    'custom-select',
    {
      'is-invalid': touched && error,
      [`custom-select-${size}`]: size,
      'custom-select-subtle': subtle,
    },
    inputClassName,
  );
  return (
    <div className={cn('form-group', className)}>
      <div className="label-hint-container">
        {label && <label htmlFor={id || input.name}>{label}</label>}
        {labelHint && <span className="label-hint">{labelHint}</span>}
      </div>
      {description}
      <select
        {...input}
        id={id || input.name}
        className={selectClassName}
        disabled={disabled}
        onChange={(...args) => {
          input.onChange(...args);
          if (onChange) {
            onChange(...args);
          }
        }}
        autoFocus={autoFocus}
      >
        {map(selectOptions, (option, i) => (
          <option key={i} value={option[optionValueProp]}>
            {option[optionLabelProp]}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">{touched && error}</div>
    </div>
  );
};

SelectFormGroup.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  labelHint: PropTypes.node,
  description: PropTypes.node,
  meta: PropTypes.object,
  options: PropTypes.any,
  optionValueProp: PropTypes.string,
  optionLabelProp: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  autoFocus: PropTypes.bool,
  shouldInsertDefault: PropTypes.bool,
  defaultLabel: PropTypes.string,
  size: PropTypes.string,
  subtle: PropTypes.bool,
};

SelectFormGroup.defaultProps = {
  optionValueProp: 'value',
  optionLabelProp: 'label',
  defaultLabel: '',
  subtle: false,
};

export {SelectFormGroup};
