import {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {ROUTE} from '../../constants/routes';
import {TextInput} from '../molecules/TextInput';
import {Theme} from '../atoms/theme';
import {Search} from '../atoms/icons/Search';
import {useCompaniesTable} from '../../hooks/useCompaniesTable';
import {PercentileRank} from '../molecules/PercentileRank';
import {formatMillions} from 'v2/utilities/helpers';
import Toggle from '../molecules/Toggle';
import {GreyPill} from '../molecules/Pills';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {TableCell} from '../atoms/Containers';
import {Download} from '../atoms/icons/Download';
import {makeCSVDownload} from 'v2/utilities/helpers/makeCSVDownload';
import {COLUMN_LABELS} from 'v2/constants/columnLabels';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {updateCompanySearch, updateShowCompaniesWithHoldings} from 'v2/redux/searchFilters';
import {PaginatedReactTable} from './ReactTable';
import {localeCompareString} from 'v2/utilities/helpers/localeCompareString';
import {CheckedBadge} from '../molecules/CheckedBadge';
import {CellProps} from 'react-table';

const PageWrapper = styled.div`
  margin-top: 5.25rem;
  padding-bottom: 1.5625rem;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const SearchWrapper = styled(FlexRow)`
  margin: 0 4rem;
  justify-content: space-between;
`;

const SearchButton = styled.button`
  background: ${props => props.color || props.theme.colors.grey_2};
  width: 44px;
  border: 0px;
  height: 44px;
  border-radius: 8px;
  padding-top: 5px;
`;

const DownloadButton = styled.button`
  background: ${props => props.color || props.theme.colors.grey_2};
  border: 0px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0 15px;
  white-space: nowrap;
`;

const columnLabels = COLUMN_LABELS.companies;

export const CompaniesIndex = () => {
  const {
    data: companies,
    mapDataForCSV,
    handleSearchChange,
    handleShowHoldingsChange,
    isLoading,
  } = useCompaniesTable(true);

  const dispatch = useDispatch();

  const searchValue = String(useSelector((state: RootStateOrAny) => state.searchFilters.companies.searchText));
  const setSearchValue = (value: string) => dispatch(updateCompanySearch(value));

  const isChecked = useSelector(
    (state: RootStateOrAny) => state.searchFilters.companies.filterOptions.showCompaniesWithHoldings,
  );

  const setIsChecked = (value: boolean) => dispatch(updateShowCompaniesWithHoldings(value));

  useEffect(() => {
    handleSearchChange(searchValue);
  }, [searchValue]);

  const companyPath = (id: string) => {
    return ROUTE.UT1.COMPANIES.RISK.path.replace(':companyId', id);
  };

  const handleSearch = () => {
    handleSearchChange(searchValue);
  };

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
    handleShowHoldingsChange(!isChecked);
  };

  useEffect(() => {
    handleSearch();
    handleShowHoldingsChange(isChecked);
  }, []);

  const searchOnEnter = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleSearch();
    }
  };

  const memoizedData = useMemo(() => companies, [companies, isLoading]);

  type CompanyColumn = typeof memoizedData[0];
  const memoizedColumns = useMemo(
    () => [
      {
        Header: columnLabels.name,
        Cell: ({value}: CellProps<CompanyColumn, any>) => <TableCell title={value}>{value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '18rem',
          width: '18rem',
        },
        sortType: localeCompareString,
        accessor: 'name',
      },
      {
        Header: columnLabels.engaged,
        Cell: ({value}: CellProps<CompanyColumn, any>) => (
          <TableCell>
            <CheckedBadge isChecked={Number(value) > 0} iconSize={24} hideLabel />
          </TableCell>
        ),
        style: {
          maxWidth: 'auto',
          minWidth: '8rem',
          width: '8rem',
          justifyContent: 'center',
        },
        accessor: 'engaged',
      },
      {
        Header: columnLabels.transparency,
        Cell: ({value}: CellProps<CompanyColumn, number>) => {
          if (value > 0.66) {
            return (
              <TableCell>
                <GreyPill>High</GreyPill>
              </TableCell>
            );
          }

          if (value > 0.33) {
            return (
              <TableCell>
                <GreyPill>Medium</GreyPill>
              </TableCell>
            );
          }

          return (
            <TableCell>
              <GreyPill>Low</GreyPill>
            </TableCell>
          );
        },
        style: {
          maxWidth: 'auto',
          minWidth: '8rem',
          width: '8rem',
          justifyContent: 'center',
        },
        accessor: 'transparency',
      },
      {
        Header: columnLabels.country,
        Cell: ({row, value}: CellProps<CompanyColumn, string>) => (
          <TableCell title={row.original.country}>{value ?? DATA_STATUS.NONE}</TableCell>
        ),
        style: {
          maxWidth: 'auto',
          minWidth: '10rem',
          width: '10rem',
          justifyContent: 'center',
        },
        accessor: 'countryCode',
      },
      {
        Header: columnLabels.sector,
        Cell: ({value}: CellProps<CompanyColumn, string>) => (
          <TableCell title={value}>{value ?? DATA_STATUS.NONE}</TableCell>
        ),
        accessor: 'sector',
        style: {
          maxWidth: 'auto',
          minWidth: '10rem',
          width: '10rem',
        },
      },
      {
        Header: columnLabels.pctRank,
        Cell: ({value}: CellProps<CompanyColumn, number>) => (
          <TableCell>
            <PercentileRank value={value} />
          </TableCell>
        ),
        style: {
          maxWidth: 'auto',
          minWidth: '8rem',
          width: '8rem',
          justifyContent: 'center',
        },
        accessor: 'pctRank',
        sortType: 'basic',
      },
      {
        Header: columnLabels.held,
        accessor: 'held',
        Cell: ({value}: CellProps<CompanyColumn, number>) => formatMillions(value, 1, '$'),
      },
    ],
    [],
  );

  const handleCSVDownload = () => {
    makeCSVDownload({data: mapDataForCSV(), subject: 'companies', headerLabels: Object.values(columnLabels)});
  };

  return (
    <PageWrapper>
      <SearchWrapper>
        <FlexRow>
          <div>
            <TextInput
              placeholder="Search by Company Name, Ticker, CUSIP, or ISIN"
              value={searchValue}
              onChange={setSearchValue}
              onKeyUp={searchOnEnter}
            />
          </div>
          <div>
            <SearchButton onClick={handleSearch}>
              <Search size={18} color={Theme.colors.dark} />
            </SearchButton>
          </div>
        </FlexRow>
        <FlexRow>
          <Toggle onChange={handleToggleChange} checked={isChecked} leftText="All Companies" rightText="Holdings" />
          <DownloadButton onClick={handleCSVDownload} disabled={isLoading}>
            <Download color={isLoading ? Theme.colors.grey_4 : Theme.colors.dark} size={20} />
            <span>Download CSV</span>
          </DownloadButton>
        </FlexRow>
      </SearchWrapper>
      <PaginatedReactTable
        data={memoizedData}
        columns={memoizedColumns}
        path={companyPath}
        initialState={{sortBy: [{id: 'held', desc: true}]}}
        withSorting
        withCardContainer
        withHover
      />
    </PageWrapper>
  );
};
