import styled from 'styled-components';
import {TitleXS} from 'v2/components/atoms/Typeface';

export const CardWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 4px ${props => props.theme.colors.black}26;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  min-width: 30.25rem;
  padding: 3.5rem 4.5rem;
  min-width: 100%;
`;

export const SectionTitle = styled(TitleXS)`
  margin-top: 0 !important;
  margin-bottom: 2rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
`;

export const FieldBlock = styled.div`
  max-width: 940px;
  width: 100%;
  flex::1
`;

export const ButtonContainer = styled.div`
  flex: 0;
`;

export const FormGridRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flexwrap: nowrap;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  flex: 1;
`;

export const Flex0 = styled.div`
  flex: 0;
  display: flex;
  align-items: flex-end;
`;

export const Flex1 = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

export const Flex2 = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-end;
`;

export const Flex3 = styled.div`
  flex: 3;
  display: flex;
  align-items: flex-end;
`;
