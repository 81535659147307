import {ROUTE} from './route';

export const ALERT_BUTTON = {
  HARMONY_INDEX_CENTER: {
    route: ROUTE.HARMONY_INDEX_CENTER,
    label: 'View the Harmony Index Center',
  },
  ORGANIZATION_PROFILE: {
    route: ROUTE.SETTINGS_PLAN_PROFILE,
    label: 'View the Organization Profile',
  },
};
