import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {removeOption, resetOptions, updateOptions} from 'v2/redux/appliedFilters';
import {
  AppliedFiltersByListItem,
  AppliedFiltersByPathname,
  AppliedFiltersProps,
} from 'v2/utilities/types/components/FilterList';
import {TagProps} from 'v2/utilities/types/components/TagHeader';

export const useAppliedFilters = (args: {listItemId?: string} = {}) => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {listItemId = ''} = args;

  // all filters for
  const appliedFilters =
    useSelector((state: {appliedFilters: AppliedFiltersByPathname}) => state.appliedFilters[pathname]) ||
    ({} as AppliedFiltersByListItem);

  const applyFilters = (options: AppliedFiltersByListItem) => {
    dispatch(updateOptions({pathname, options}));
  };

  const resetFilters = () => {
    dispatch(resetOptions({pathname}));
  };

  const removeFilter = (option: AppliedFiltersProps, key: string) => {
    dispatch(removeOption({pathname, listItemId: key, optionId: option.value}));
  };

  const selectedOptions = Object.values(appliedFilters[listItemId] || {}).filter(option => option.checked);

  const getSelectedTags = () => {
    const keys = Object.keys(appliedFilters || {});
    const filters = keys.reduce((acc: TagProps[], key) => {
      const options: TagProps[] = Object.values(appliedFilters[key] || {})
        .filter(option => option.checked)
        .map(option => ({
          label: option.label,
          value: option.value,
          selected: true,
          listItemId: key,
          onClose: () => removeFilter(option, key),
        }));
      return options.length ? [...acc, ...options] : acc;
    }, [] as TagProps[]);

    return filters || [];
  };

  return {
    appliedFilters,
    applyFilters,
    resetFilters,
    removeFilter,
    selectedOptions,
    getSelectedTags,
  };
};
