import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const ADDRESS_TYPE = {
  ADDRESS_TYPE_OFFICE: 'Office',
  ADDRESS_TYPE_HQ: 'HQ',
  ADDRESS_TYPE_OTHER: 'Other',
};

export const ADDRESS_TYPES = map(ADDRESS_TYPE, value => value);
