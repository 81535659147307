import {CONFIG} from 'app/constants';

export const ONBOARDING_CATEGORIES = {
  BOARD: 'Board of Directors and CEO, CFO & COO',
  PROFESSIONALS: 'Investment Professionals',
  SUPPORT: 'Investment Support',
};

export const ONBOARDING_ETHNICITIES = {
  ASIAN: 'Asian',
  BLACK: 'Black or African American',
  HISPANIC: 'Hispanic or Latino',
  NATIVE_AMERICAN: 'Native American or Alaskan Native',
  PACIFIC_ISLANDER: 'Native Hawaiian or Pacific Islander',
  WHITE: 'White',
  MULTIPLE: 'Two or more races',
  OTHER: 'Other / undisclosed Race',
};

export const ONBOARDING_GENDERS = {
  FEMALE: 'Female',
  MALE: 'Male',
};

const thisYear = CONFIG.MANAGER_EMPLOYEE_DATA_YEAR;
const numberOfYears = 4;

export const YEARS = Array.from({length: numberOfYears}, (_v, k) => thisYear - k).reverse();
