import {ROLE} from 'app/constants';
import {getSessionJwt} from 'app/utilities';
import {includes} from 'lodash';
import ReactPlaceholder from 'react-placeholder/lib';
import {RectShape, TextBlock} from 'react-placeholder/lib/placeholders';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CheckedBadge} from 'v2/components/molecules/CheckedBadge';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ROUTE} from 'v2/constants/routes';
import {EsgIntegrationView as EsgIntegrationViewProps} from 'v2/utilities/types/components/EsgIntegrationView';
import {Container, FlexContainer} from '../atoms/Containers';
import {Body2, Title5} from '../atoms/Typeface';
import {Theme} from '../atoms/theme';
import {HarmonyButton} from '../molecules/Button';

const Title = styled(Title5)`
  /* Old CSS is forcing 700 */
  &&& {
    font-weight: 600 !important;
  }
  color: ${({theme}) => theme.colors.dark};
  margin: 0;
`;

const Integrations = styled(FlexContainer)`
  gap: 3.375rem;
  padding: 2.188rem 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({theme}) => theme.colors.grey_3};
`;

const FlexItem = styled.div`
  flex: 2;
`;

const Body = styled(Body2)`
  font-size: 0.875rem;
  word-break: break-word;
`;

const Paragraph = styled(Body)`
  line-height: 1.25rem;
`;

const AdditionalIntegrations = styled(FlexContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.0625rem 0 5rem;
`;

const Placeholder = () => (
  <div>
    <FlexContainer padding="4.3125rem 0 0" items="center">
      <FlexContainer flex="1">
        <RectShape color={Theme.colors.grey_2} style={{width: 210, height: 18}} />
      </FlexContainer>
      <Container width="13.125rem">
        <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 44}} />
      </Container>
    </FlexContainer>
    <Integrations padding="1.625rem 0 0" gap="2rem">
      <Container>
        <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
      </Container>
      <Container>
        <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
      </Container>
      <Container>
        <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
      </Container>
    </Integrations>
    <FlexContainer gap="4.563rem" padding="2.188rem 0 0">
      <FlexItem>
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
        </Container>
        <Container padding="1.438rem 0 0">
          <TextBlock rows={7} color={Theme.colors.grey_2} />
        </Container>
        <AdditionalIntegrations>
          <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 50, height: 20}} />
          </FlexContainer>
          <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 50, height: 20}} />
          </FlexContainer>
          <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 50, height: 20}} />
          </FlexContainer>
          <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 50, height: 20}} />
          </FlexContainer>
          <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 50, height: 20}} />
          </FlexContainer>
          <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
            <RectShape color={Theme.colors.grey_2} style={{width: 50, height: 20}} />
          </FlexContainer>
        </AdditionalIntegrations>
      </FlexItem>
      <FlexItem>
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
        </Container>
        <Container padding="1.438rem 0 0">
          <TextBlock rows={2} color={Theme.colors.grey_2} />
        </Container>
      </FlexItem>
    </FlexContainer>
  </div>
);

export const ESGIntegrationView = ({
  isLoading = false,
  esgApproach,
  esgMeasurement,
  hasComittee,
  hasPolicy,
  hasScaling,
  hasScreening,
  hasVetting,
  hasVoting,
  isEngaged,
  isMonitoring,
  isSelling,
}: EsgIntegrationViewProps) => {
  const roles = getSessionJwt(true)?.contents?.roles || [];

  const isUT3 = includes(roles, ROLE.USER_TYPE_3);

  return (
    <Container padding="0 2.75rem">
      <ReactPlaceholder ready={!isLoading} customPlaceholder={<Placeholder />}>
        <FlexContainer padding="4.3125rem 0 0" items="center">
          <FlexContainer flex="1">
            <Title>ESG Integration</Title>
          </FlexContainer>
          {isUT3 && (
            <Container width="210px">
              <HarmonyButton as={Link} to={ROUTE.SETTINGS.MANAGER_PROFILE.path}>
                Edit Profile
              </HarmonyButton>
            </Container>
          )}
        </FlexContainer>
        <Integrations>
          <FlexItem>
            <FlexContainer gap="1rem" width="max-content">
              <CheckedBadge isChecked={hasPolicy} hideLabel />
              <Body>Have an organization-level ESG Policy</Body>
            </FlexContainer>
          </FlexItem>
          <FlexItem>
            <FlexContainer gap="1rem" width="max-content">
              <CheckedBadge isChecked={hasComittee} hideLabel />
              <Body>Have an ESG Committee / Officer</Body>
            </FlexContainer>
          </FlexItem>
          <FlexItem>
            <FlexContainer gap="1rem" width="max-content">
              <CheckedBadge isChecked={isEngaged} hideLabel />
              <Body>Engaged on Harmony Platform</Body>
            </FlexContainer>
          </FlexItem>
          <FlexContainer flex={1} />
        </Integrations>
        <FlexContainer gap="114px" padding="3rem 0 0">
          <FlexContainer minHeight="25rem" direction="column" flex={1}>
            <Container>
              <Title>ESG Approach</Title>
            </Container>
            <FlexContainer flex={2} padding="1.438rem 0 0">
              <Paragraph>{esgApproach && esgApproach.length ? esgApproach : DATA_STATUS.NONE}</Paragraph>
            </FlexContainer>
            <FlexContainer items="center" justify="space-between" padding="1rem 0 4.375rem">
              <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
                <CheckedBadge isChecked={hasScreening} hideLabel />
                <Body>Screening</Body>
              </FlexContainer>
              <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
                <CheckedBadge isChecked={hasVetting} hideLabel />
                <Body>Vetting</Body>
              </FlexContainer>
              <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
                <CheckedBadge isChecked={hasVoting} hideLabel />
                <Body>Voting</Body>
              </FlexContainer>
              <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
                <CheckedBadge isChecked={hasScaling} hideLabel />
                <Body>Scaling</Body>
              </FlexContainer>
              <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
                <CheckedBadge isChecked={isSelling} hideLabel />
                <Body>Sellling</Body>
              </FlexContainer>
              <FlexContainer direction="column" flex={1} justify="center" items="center" gap="1rem">
                <CheckedBadge isChecked={isMonitoring} hideLabel />
                <Body>Monitoring</Body>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer direction="column" flex={1}>
            <Container>
              <Title>ESG Measurement</Title>
            </Container>
            <Container padding="1.438rem 0 0">
              <Paragraph>{esgMeasurement && esgMeasurement.length ? esgMeasurement : DATA_STATUS.NONE}</Paragraph>
            </Container>
          </FlexContainer>
        </FlexContainer>
      </ReactPlaceholder>
    </Container>
  );
};
