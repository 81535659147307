import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {debounce, get, isEmpty} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';

import {Alert, Button, BootstrapTableRemote} from 'app/components';
import {ROUTE, FORM_MESSAGE} from 'app/constants';
import {COLOR} from 'app/constants/color';
import faArrowFromBottom from 'app/fontawesome-pro-light/faArrowFromBottom';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import {getPlanSelectionPaged} from 'app/redux/strategy';
import {checkDueDates} from 'app/utilities';
import {ratingFormatter} from 'app/utilities/ratingFormatter';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';

/**
 * Gets a formatted compliance for display
 * @param {Array} policyViolation - The policy violation array if it exists
 * @returns {Object} A react div element displaying the compliance
 */
const complianceFormatter = policyViolation => {
  const compliant = isEmpty(policyViolation) ? 'Yes' : 'No';

  const style = {
    color: COLOR.RED,
  };

  return <div style={compliant === 'Yes' ? {} : style}>{compliant}</div>;
};

/**
 * Gets a formatted next upload date for display
 * @param {Array} policyViolation - The policy violation array if it exists
 * @returns {Object} A react div element displaying next upload message
 */
const nextUploadFormatter = (cell, row) => {
  // Get due date data
  const dueDateData = checkDueDates(row.lastHoldingsUploadAt, row.uploadFrequency);

  // Set a flag where initial upload was not made or when upload is past due date
  let status = 'pending';
  let nextUploadDisplay = moment(dueDateData.nextUploadDate).format('MMM D, YYYY');
  if (!row.lastHoldingsUploadAt || !row.uploadFrequency) {
    // Check if there was no record of an upload
    status = 'initial upload required';
    nextUploadDisplay = 'Initial Upload Required';
  } else if (dueDateData.pastDueBy) {
    // Check for frequency / due - overdue
    status = 'past due date';
    nextUploadDisplay = `Past due by ${dueDateData.pastDueBy} days`;
  }

  const style = {
    color: COLOR.RED,
  };

  // [1] necessary to capture the click on the whole cell
  const commonStyle = {
    pointerEvents: 'none', // [1]
  };

  return (
    <div style={status !== 'pending' ? {...style, ...commonStyle} : {...commonStyle}}>
      {nextUploadDisplay}
      {/* <Icon className='ml-3 tr-nextUpload-cell' icon={faArrowFromBottom} /> */}
    </div>
  );
};

const columns = [
  {
    dataField: 'planSponsor.name',
    text: 'Client',
    sort: true,
    style: {color: COLOR.BLUE},
    headerStyle: {width: 150},
    classes: 'tr-client-cell',
    events: {
      onMouseEnter: e => {
        Object.assign(e.target.style, {textDecoration: 'underline', cursor: 'pointer'});
      },
      onMouseLeave: e => {
        Object.assign(e.target.style, {textDecoration: 'none', cursor: 'default'});
      },
    },
  },
  {
    dataField: 'name',
    text: 'Product',
    sort: true,
    style: {width: 250},
    headerStyle: {width: 250},
  },
  {
    dataField: 'marketIndex.name',
    text: 'Index',
    sort: true,
    headerStyle: {width: 200},
  },
  {
    dataField: 'portfolioManagementType',
    text: 'Strategy',
    sort: true,
    style: {textAlign: 'center'},
    headerStyle: {width: 120, textAlign: 'center'},
  },
  {
    dataField: 'weightedAverageRating',
    text: 'Rating',
    sort: false,
    formatter: ratingFormatter,
    style: {
      textAlign: 'center',
    },
    headerStyle: {width: 100, whiteSpace: 'nowrap', textAlign: 'center'},
  },
  {
    dataField: 'policyViolation',
    text: 'Compliance',
    sort: false,
    formatter: complianceFormatter,
    style: {textAlign: 'center'},
    headerStyle: {width: 200, textAlign: 'center'},
  },
  // {
  //   dataField: 'lastHoldingsUploadAt',
  //   sort: false,
  //   text: 'Next Upload',
  //   formatter: nextUploadFormatter,
  //   style: { textAlign: 'right' },
  //   headerStyle: { textAlign: 'right', width: 200 },
  //   classes: 'tr-nextUpload-cell',
  //   events: {
  //     onMouseEnter: (e) => {
  //       Object.assign(e.target.style, { textDecoration: 'underline', cursor: 'pointer' });
  //     },
  //     onMouseLeave: (e) => {
  //       Object.assign(e.target.style, { textDecoration: 'none', cursor: 'default' });
  //     },
  //   },
  // },
];

class PlanSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {search: props.search};

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleClientSearch = this.handleClientSearch.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleSearchDebounced = debounce(this.handleClientSearch, 500);

    // TODO: The following rowEvents object and class names is a temporary workaround
    // due to issues in implementing table column onClick event -> To Be Replaced
    this.rowEvents = {
      onClick: (e, row) => {
        // Check if the target cell belongs to the correct column
        if (e.target.className === 'tr-client-cell') {
          const complianceViolationRoute = ROUTE.STRATEGY_DASHBOARD;

          this.props.dispatch(
            push({
              pathname: (row.licensedDate ? complianceViolationRoute : ROUTE.PAYWALL).path(row.id),
            }),
          );
        }
        // else if (e.target.className === 'tr-nextUpload-cell') {
        //   this.props.dispatch(push(
        //     {
        //       pathname: ROUTE.UPLOAD_PORTFOLIO_STRATEGY.path(row.id),
        //     },
        //   ));
        // }

        animateScroll.scrollToTop();
      },
    };
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.props.search,
      this.props.filter,
    );

    this.searchInput.focus();
    animateScroll.scrollToTop();
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search) {
      updatedPage = 1;
    }
    this.props.dispatch(
      push({
        search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search),
      }),
    );
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    this.handleSearchDebounced();
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  handleClientSearch = () => {
    this.updateCurrentQueryString();
  };

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search) => {
    this.props.dispatch(
      getPlanSelectionPaged({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
      }),
    );
  };

  render() {
    const {data, hasError, sortField, sortOrder, page, pageSize, totalSize} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg">
        <div>
          <div className="mb-5" style={{display: 'flex'}}>
            <h1 className="mb-0">Clients</h1>
          </div>
          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          <div className="card p-7 shadow-sm" style={{width: 1400}}>
            <div className="card-body">
              <form method="GET" onSubmit={this.handleCompanySearch}>
                <div className="d-flex" style={{justifyContent: 'center'}}>
                  <div style={{width: 600}} className="d-flex mr-2 mb-8">
                    <div className="form-control-and-icon mr-2 flex-grow-1">
                      <input
                        className="form-control is-subtle"
                        ref={input => {
                          this.searchInput = input;
                        }}
                        type="text"
                        id="search"
                        name="search"
                        placeholder="Search by Client Name"
                        value={this.state.search}
                        onChange={this.changeField}
                      />
                      <div className="icon">
                        <Icon icon={faSearch} />
                      </div>
                    </div>

                    <Button color="gray" type="submit">
                      Search
                    </Button>
                  </div>
                </div>
              </form>

              <BootstrapTableRemote
                columns={columns}
                rowEvents={this.rowEvents}
                data={data}
                page={page}
                sizePerPage={pageSize}
                totalSize={totalSize}
                onTableChange={this.handleTableChange}
                defaultSorted={defaultSorted}
                hover={false}
                selectRow={{
                  mode: 'radio',
                  hideSelectColumn: true,
                  clickToSelect: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PlanSelection.defaultProps = {data: [], totalSize: 0};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(ownProps, {
    search: '',
    page: 1,
    pageSize: 25,
    sortField: 'name',
    sortOrder: 'desc',
  });

  newProps.data = get(state, 'strategy.planSelectionPaged');

  newProps.totalSize = get(state, 'strategy.planSelectionPagedTotal');

  newProps.hasError = get(state, 'strategy.hasError');

  return newProps;
};

export const DashboardUserType3 = connect(mapStateToProps)(PlanSelection);
