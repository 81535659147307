import {Theme} from '../theme';

export const ShieldLogo = ({color = Theme.colors.white, width = 36, height = 42}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5251 6.27774C25.1392 5.86677 20.8348 3.81191 17.7201 1C14.5837 3.81191 10.2793 5.86677 4.89342 6.27774C3.6605 6.36426 2.3627 6.38589 1 6.27774V20.3806C1 22.8464 1.51912 25.2906 2.62226 27.4969C5.71536 33.7047 11.9232 38.5498 17.6984 40.3451C23.4737 38.5498 29.7031 33.6831 32.7746 27.4969C33.8777 25.2906 34.3969 22.8464 34.3969 20.3806V6.27774C33.0558 6.36426 31.758 6.36426 30.5251 6.27774Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3228 24.0579C10.6472 23.6253 11.0365 23.1278 11.4908 22.6303C13.2428 20.7918 15.1679 19.9698 17.0497 20.2726C18.088 20.424 19.1262 21.246 20.3159 22.1761C21.9381 23.4523 23.7983 24.9015 26.0262 24.9015H26.156C28.0162 21.3109 27.4322 16.7902 24.4256 13.7836C21.4839 10.7986 17.0065 10.2146 13.4375 12.0099L10.3228 24.0579Z"
        fill={color}
      />
      <path
        d="M12.8104 12.334C12.1399 12.7233 11.5342 13.1992 10.9502 13.7832C7.8355 16.8979 7.31638 21.6566 9.43613 25.312L12.8104 12.334Z"
        fill={color}
      />
      <path
        d="M20.0131 22.5872C18.8667 21.7004 17.8934 20.9217 16.9849 20.7919C12.8103 20.1646 9.91186 25.6803 9.89023 25.7452C9.8686 25.7884 9.84697 25.8101 9.80371 25.8317C10.1498 26.3292 10.5391 26.8267 10.9934 27.2809C14.7137 31.0013 20.7485 31.0013 24.4689 27.2809C25.0313 26.7185 25.5072 26.0913 25.9181 25.4424C23.5388 25.3775 21.657 23.9066 20.0131 22.5872Z"
        fill={color}
      />
    </svg>
  );
};
