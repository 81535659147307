import React, {Fragment} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';

import {ROUTE} from 'app/constants';
import faPhone from 'app/fontawesome-pro-light/faPhone';
import faEnvelope from 'app/fontawesome-pro-light/faEnvelope';

const EmailAndImaDraft = ({address, email, firmName, fullName, isFetching, marketIndexName, phone, userFullName}) => (
  <Fragment>
    <p>
      In order to change the reference index for this strategy, you will need to directly contact your investment
      manager to begin the process of changing the reference index for the strategy.
    </p>
    <p>
      You can copy the following message into an email to send to your manager. You can also copy example content to
      fill out the necessary IMA form.
    </p>
    {!isFetching && (
      <Fragment>
        {firmName && marketIndexName ? (
          <Fragment>
            <h3>Template Email Copy</h3>
            <p>
              We’ve created an example email for you to start the conversation with your investment manager. Please
              customize the template as you and your legal team require.
            </p>

            <div className="well is-form-like mb-10">
              Hello,
              <br />
              <br />
              As you may know, we have recently engaged Harmony Analytics. Harmony Analytics currently provides an index
              called {marketIndexName} that takes ESG factors into account. As our passive manager we're requesting a
              change from our current IMA reference index to {marketIndexName}. Please contact Harmony Analytics if you
              have any questions: <a href="mailto:support@harmonyanalytics.org">support@harmonyanalytics.org</a>.<br />
              <br />
              You can access the index information in the Harmony Analytics website.
              <br />
              <br />
              Regards,
              <br />
              {userFullName}
            </div>
            <div className="well mb-10" style={{maxWidth: '25rem'}}>
              <h4>Manager Contact Information</h4>
              {!!address && (
                <p>
                  {!!fullName && (
                    <Fragment>
                      {fullName}
                      <br />
                    </Fragment>
                  )}
                  {!!address.line1 && (
                    <Fragment>
                      {address.line1}
                      <br />
                    </Fragment>
                  )}
                  {!!address.line2 && (
                    <Fragment>
                      {address.line2}
                      <br />
                    </Fragment>
                  )}
                  {address.city}
                  {!!(address.city && (address.state || address.zip)) && ', '}
                  {address.state} {address.zip}
                </p>
              )}
              {(!isEmpty(phone) || !!email) && (
                <p>
                  {!isEmpty(phone) && (
                    <a href={`tel://${phone.number.replace(/[^0-9]/g, '')}`}>
                      <Icon icon={faPhone} className="mr-2" />
                      {phone.number}
                    </a>
                  )}
                  {!isEmpty(phone) && !!email && <br />}
                  {!!email && (
                    <a href={`mailto:${email}`}>
                      <Icon icon={faEnvelope} className="mr-2" />
                      {email}
                    </a>
                  )}
                </p>
              )}
            </div>

            <h3>Template IMA Copy</h3>
            <p>Copy into a new document and edit as your legal team requires.</p>
            <p>
              <strong>Please note:</strong> you are required to seek legal counsel. Harmony Analytics does not render
              legal advice and is not responsible for the information you transmit to your investment manager. For more
              information, view the{' '}
              <a href={ROUTE.TERMS.path()} target="_blank">
                {ROUTE.TERMS.title}
              </a>
              .
            </p>

            <div className="well is-form-like mb-10">
              We're mandating {firmName} change their current reference index to the {marketIndexName} provided by
              Harmony Analytics, LLC. This will require {firmName} to acquire asset information from Harmony Analytics,
              LLC.
            </div>
          </Fragment>
        ) : (
          <p>Sorry, there was a problem loading your strategy information.</p>
        )}
      </Fragment>
    )}
  </Fragment>
);

export {EmailAndImaDraft};
