import {Theme} from '../theme';

export const BadgeLockIcon = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 21" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00221 6.2511C8.31297 6.2511 7.75195 6.81211 7.75195 7.50136V8.75162H10.2525V7.50136C10.2525 6.81211 9.69146 6.2511 9.00221 6.2511Z" />
      <path d="M17.0441 0.122859C16.61 -0.0853095 16.096 -0.0254924 15.7199 0.276094C14.1578 1.53186 11.3459 1.53124 9.78612 0.276719C9.32825 -0.0920296 8.67628 -0.0914045 8.21841 0.276094C6.65692 1.53182 3.84383 1.5312 2.28464 0.27668C1.90913 -0.0254924 1.39344 -0.0853486 0.961046 0.12282C0.527167 0.330402 0.250625 0.769321 0.250625 1.2504L0.25 11.2525C0.25 15.1711 3.39151 18.4241 8.65307 19.9546C8.76724 19.9875 8.88445 20.004 9.00233 20.004C9.12008 20.004 9.23737 19.9875 9.35154 19.9546C14.6132 18.4241 17.7541 15.1718 17.7541 11.2525V1.2504C17.7541 0.769321 17.4781 0.330402 17.0441 0.122859ZM12.7531 12.5024C12.7531 13.1932 12.1933 13.7527 11.5028 13.7527H6.50177C5.81123 13.7527 5.25151 13.1932 5.25151 12.5024V10.0019C5.25151 9.31145 5.81123 8.75164 6.50177 8.75164V7.50138C6.50177 6.12293 7.62321 5.00086 9.00229 5.00086C10.3814 5.00086 11.5028 6.12293 11.5028 7.50138V8.75164C12.1933 8.75164 12.7531 9.31145 12.7531 10.0019V12.5024Z" />
      <path d="M9.00208 10.6271C8.65685 10.6271 8.37695 10.907 8.37695 11.2522C8.37695 11.5977 8.65685 11.8773 9.00208 11.8773C9.34731 11.8773 9.62721 11.5977 9.62721 11.2522C9.62721 10.907 9.34731 10.6271 9.00208 10.6271Z" />
    </svg>
  );
};
