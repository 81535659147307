import styled from 'styled-components/macro';
import {Container, FlexContainer} from '../atoms/Containers';
import {Theme} from '../atoms/theme';
import {AG, ParagraphS} from '../atoms/Typeface';

const FlexItem = styled.div<{
  flex?: number;
  maxWidth?: number | string;
}>`
  flex: ${props => (props.flex ? props.flex : 0)};
  width: auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
`;

const ChartContainer = styled(FlexItem)`
  display: flex;
`;

const ChartLegendContainer = styled(FlexItem)<{height?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #a8a9ab;
  height: ${({height}) => height ?? '11.688rem'};
`;

const ChartItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 11.688rem;
  margin: 0 0.5rem;
`;

const ChartItemSpacer = styled.div<{height: number | string}>`
  height: ${props => props.height};
`;

const ChartItemBlock = styled.div<{background: string}>`
  width: 1.425rem;
  height: 1.425rem;
  border-radius: 3px;
  background-color: ${props => props.background};
  z-index: 3;
`;

const ChartBorder = styled.div`
  border-top: 1px solid #a8a9ab;
  height: 1px;
  width: auto;
  position: relative;
  bottom: -6.6rem;
  margin: 0 0.5rem 0 3rem;
`;

const Legend = styled.span`
  font-size: 0.95rem;
  text-align: right;
  padding-right: 0.8rem;
  color: ${props => props.theme.colors.black};
`;

const LegendItem = styled(ParagraphS)`
  text-align: center;
`;

const ChartItem = ({color, tier}: {color: string; tier: number}) => {
  const spacerHeight = (): string => {
    switch (tier) {
      case 5:
        return '0rem;';
      case 4:
        return '2.6rem;';
      case 3:
        return '5.1rem;';
      case 2:
        return '7.6rem;';
      case 1:
      default:
        return '10.1rem;';
    }
  };

  return (
    <ChartItemContainer>
      <ChartItemSpacer height={spacerHeight()} />
      <ChartItemBlock background={color} />
    </ChartItemContainer>
  );
};

export const ESGChartExample = ({chartHeight, withLegend}: {chartHeight?: string; withLegend?: boolean}) => {
  return (
    <Container>
      {withLegend && (
        <FlexContainer>
          <Container>
            <AG color={Theme.colors.grey_5}>Low Risk</AG>
          </Container>
          <FlexContainer flex="1" />
        </FlexContainer>
      )}
      <ChartBorder />
      <FlexContainer padding=".7231rem 0">
        <ChartLegendContainer height={chartHeight} flex={1} maxWidth="3rem">
          <Legend>0</Legend>
          <Legend>20</Legend>
          <Legend>50</Legend>
          <Legend>80</Legend>
          <Legend>100</Legend>
        </ChartLegendContainer>
        <ChartContainer flex={1}>
          <ChartItem color={Theme.colors.red} tier={1} />
          <ChartItem color={Theme.colors.orange} tier={2} />
          <ChartItem color={Theme.colors.yellow} tier={3} />
          <ChartItem color={Theme.colors.light_green} tier={4} />
          <ChartItem color={Theme.colors.green} tier={5} />
        </ChartContainer>
      </FlexContainer>
      {withLegend && (
        <FlexContainer items="center">
          <Container width="3rem">
            <AG color={Theme.colors.grey_5}>High Risk</AG>
          </Container>
          <FlexContainer items="center" justify="space-around" flex="1">
            <Container>
              <LegendItem>5th</LegendItem>
            </Container>
            <Container>
              <LegendItem>4th</LegendItem>
            </Container>
            <Container>
              <LegendItem>3rd</LegendItem>
            </Container>
            <Container>
              <LegendItem>2nd</LegendItem>
            </Container>
            <Container>
              <LegendItem>1st</LegendItem>
            </Container>
          </FlexContainer>
        </FlexContainer>
      )}
    </Container>
  );
};
