import * as Icons from 'v2/components/atoms/icons';
import {ISSUES} from './issues';

export const ESG_CONTENT = {
  [ISSUES.ENVIRONMENT.id]: {
    description:
      'Quantifies the direct impact an organization has on global environmental health. Organizations are currently rated on their resource consumption and contribution to climate change.',
  },
  [ISSUES.CARBON_EMISSIONS.id]: {
    description: 'Emissions that contribute to the greenhouse effect by absorbing infrared radiation (Scope 1 & 2).',
    icon: Icons.CarbonEmissions,
    summaryChart: {
      title: 'Relative Carbon Footprint',
      description: 'Carbon emissions by companies in the portfolio per million dollars invested.',
      unit: '(tCO2e/$M)',
    },
  },
  [ISSUES.ENERGY_USE.id]: {
    description:
      'Fuel consumption in various forms (fuel, electricity, heating, cooling, or steam) from renewable and non-renewable sources.',
    icon: Icons.EnergyUse,
    summaryChart: {
      title: 'Relative Energy Use',
      description: 'Energy consumption by companies in the portfolio per million dollars invested.',
      unit: '(MWH)',
    },
  },
  [ISSUES.WATER_CONSUMPTION.id]: {
    description: 'Water taken out of the ecosystem or local community by an organization, temporarily or permanently.',
    icon: Icons.WaterConsumption,
    summaryChart: {
      title: 'Water Consumption',
      description: 'Water use by companies in the portfolio per million dollars invested.',
      unit: '(TCM)',
    },
  },
  [ISSUES.ENVIRONMENTAL_POLICIES.id]: {
    description:
      "Evaluates an organization's intention to operate sustainably. Organizations are currently rated on how well their internal environmental policies and programs align with sustainability goals.",
  },
  [ISSUES.CLIMATE_CHANGE_POLICY.id]: {
    description:
      'Policy to implement a plan of action to address issues related to climate change such as reduction of CO2 emissions, use of renewable energy, waste management, etc.',
    icon: Icons.ClimateChangePolicy,
  },
  [ISSUES.ENVIRONMENTAL_RISK_POLICY.id]: {
    description:
      'Policies to mitigate occurrences of environmental stressors by assessing the probability of occurrence and acceptable risk levels. Policies encompass activities such as waste management, waste treatment, pollutants, oil and gas spillage, etc.',
    icon: Icons.EnvironmentalRiskManagement,
  },
  [ISSUES.ENVIRONMENTAL_INCENTIVES_POLICY.id]: {
    description:
      'Policies that provide incentives to executives for meeting targets as to encourage implementation of environmental policies.',
    icon: Icons.EnvironmentalIncentivesPolicy,
  },
  [ISSUES.DIVERSITY_AND_INCLUSION.id]: {
    description:
      'Measures leadership diversity within an organization. Organizations are currently rated by the inclusion of talent from diversified backgrounds on their board of directors and executive and management teams.',
  },
  [ISSUES.GENDER_EQUALITY.id]: {
    description: 'Measures an organizations inclusion of women in leadership and executive roles. ',
    icon: Icons.GenderEquality,
  },
  [ISSUES.WOMEN_IN_LEADERSHIP.id]: {
    description: 'The percentage of women in senior leadership roles.',
    icon: Icons.WomenLeadershipIcon,
    summaryChart: {
      title: 'Women in Leadership',
      description: 'Women in leadership by companies in the portfolio.',
      unit: '',
    },
  },
  [ISSUES.WOMEN_ON_BOARD.id]: {
    description: 'The percentage of women on the board of directors.',
    icon: Icons.WomenOnTheBoard,
    summaryChart: {
      title: 'Women on Board',
      description: 'Women on board by companies in the portfolio.',
      unit: '',
    },
  },
  [ISSUES.ETHNIC_AND_CULTURAL_IDENTITY.id]: {
    description: 'Measures an organizations inclusion of diverse talent in leadership and executive roles.',
    icon: Icons.EthnicAndCulturalIdentity,
  },
  [ISSUES.COMPENSATION_EQUITY.id]: {
    description:
      'Quantifies how wealth generated by an organization is distributed among employees. Organizations are currently rated on the proportion of compensation allocated to executives and employees.',
  },
  [ISSUES.CEO_COMPENSATION_RATIO.id]: {
    description: 'The ratio of total CEO compensation to median employee compensation.',
    icon: Icons.CeoCompensationRation,
    summaryChart: {
      title: 'CEO Compensation Ratio',
      description: 'CEO compensation ratio by companies in the portfolio.',
      unit: '(to 1)',
    },
  },
  [ISSUES.WORKFORCE_WELLBEING.id]: {
    description:
      'Evaluates health, safety, and protections of employees within an organization. Organizations are currently rated on the quality and performance of their procedures and standards.',
  },
  [ISSUES.INJURY_FREQUENCY.id]: {
    description:
      'The frequency of severe, work-related injuries, defined as an amputation, in-patient hospitalization, or loss of an eye.',
    icon: Icons.InjuryFrequency,
    summaryChart: {
      title: 'Injury Frequency',
      description: 'Injury frequency by companies in the portfolio.',
      unit: '(TCIR)',
    },
  },
  [ISSUES.HUMAN_RIGHTS_POLICY.id]: {
    description: 'Policies that defend human rights by promoting fairness, safety, health, and workforce wellbeing.',
    icon: Icons.HumanRightsPolicy,
  },
  [ISSUES.NON_DISCRIMINATION_POLICY.id]: {
    description:
      'Policies that prohibit discrimination of individuals based on age, sex, religion, disability, identity, and other characteristics protected by law. ',
    icon: Icons.NonDiscriminationPolicy,
  },
  [ISSUES.HEALTH_AND_SAFETY_POLICY.id]: {
    description: 'Policies to prevent injury and illness, reduce hazards, and reinforce training and awareness.',
    icon: Icons.HealthAndSafetyPolicy,
  },
  [ISSUES.CHILD_AND_FORCED_LABOR_POLICY.id]: {
    description:
      'Policies to reinforce the rights of children and prevent human exploitation within the organization and its supply chain.',
    icon: Icons.ChildAndForcedLaborPolicy,
  },
  [ISSUES.BOARD_GOVERNANCE.id]: {
    description:
      'Evaluate the practices and policies that govern the corporate structure of an organization. Organizations are rated based on their decision-making independence and ability to mitigate potential conflicts of interest.',
    summaryChart: {
      title: 'Board Independence',
      description: 'Board independence by companies in the portfolio.',
      unit: '',
    },
  },
  [ISSUES.BOARD_INDEPENDENCE.id]: {
    description: 'The percentage of independent members on the board of directors.',
    icon: Icons.BoardIndependance,
  },
  [ISSUES.SEPARATION_OF_POWERS_POLICY.id]: {
    description: 'Policies to enforce a balanced distribution of power among decision-making bodies.',
    icon: Icons.SeparationOfPowersPolicyIcon,
  },
  [ISSUES.CORPORATE_TRUST.id]: {
    description:
      'Evaluates an organization’s oversight of procedures and practices pertaining to corporate integrity. Organizations are rated on the security, transparency, and moral principles outlined in their policies.',
  },
  [ISSUES.CODE_OF_CONDUCT_POLICY.id]: {
    description: 'Policies to promote and enforce high moral, ethical, and behavioral standards.',
    icon: Icons.CodeOfConductPolicyIcon,
  },
  [ISSUES.ETHICS_AND_ANTI_CORRUPTION_POLICY.id]: {
    description:
      'Policies to encourage integrity, fairness, and honesty in the workplace. Examples: bribery, recruitment based on merit, and conflicts of interest.',
    icon: Icons.EthicsAntiIcon,
  },
  [ISSUES.DATA_PRIVACY_POLICY.id]: {
    description:
      'Policies to ensure that data is collected and processed in a manner that protects confidentiality and is consistent with laws and regulations. ',
    icon: Icons.DataPrivacyPolicy, // Find right icon
  },
};
