import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import {each, uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Element as ScrollElement} from 'react-scroll';

import {Toggleable, ToolTip} from 'app/components';
import faTimes from 'app/fontawesome-pro-light/faTimes';

class IssueCard extends Component {
  constructor(props) {
    super(props);
    this.tooltipId = 'tooltip_' + uniqueId();
  }

  render() {
    const {children, isESGFocus, size} = this.props;

    const front = [];
    const back = [];
    const activator = [];

    if (children) {
      if (children.length) {
        each(children, child => {
          if (child) {
            if (child.key === 'back') {
              back.push(child);
            } else if (child.key === 'activator') {
              activator.push(child);
            } else {
              front.push(child);
            }
          }
        });
      } else if (children.key === 'back') {
        back.push(children);
      } else if (children.key === 'activator') {
        activator.push(children);
      } else {
        front.push(children);
      }
    }

    const isFlippable = back.length > 0;

    return (
      <Toggleable>
        {({isActive, toggle}) => (
          <ScrollElement
            className={cn('issue-card', {
              'has-activator': activator.length > 0,
              'is-flipped': isActive,
              'is-esg-focus': isESGFocus,
              [`is-${size}`]: size,
            })}
            name={`issue-card-${this.props.id}`}
          >
            <div className="issue-card-front-container">
              <div className="issue-card-front">
                {front}
                {isFlippable && (
                  <Fragment>
                    <button type="button" className="issue-card-toggle is-ringed" onClick={toggle} id={this.tooltipId}>
                      i
                    </button>
                    <ToolTip target={this.tooltipId}>
                      <span className="text-white">More Information</span>
                    </ToolTip>
                  </Fragment>
                )}
              </div>
              {activator.length > 0 && <div className="issue-card-activator">{activator}</div>}
            </div>
            {isFlippable && (
              <div className="issue-card-back-container">
                <div className="issue-card-back">
                  {back}
                  <button type="button" className="issue-card-toggle text-white" onClick={toggle}>
                    <Icon icon={faTimes} />
                  </button>
                </div>
              </div>
            )}
          </ScrollElement>
        )}
      </Toggleable>
    );
  }
}

IssueCard.propTypes = {
  children: PropTypes.any,
  isESGFocus: PropTypes.bool,
  size: PropTypes.string,
};

export {IssueCard};
