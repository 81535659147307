import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {CONFIG} from '../../app/constants/config';
import {getSessionJwt} from 'app/utilities';

export const typeOrm = createApi({
  reducerPath: 'typeOrmEndpoints',
  tagTypes: ['company', 'Missing Engagements', 'manualCompanyIssue', 'issueFileUpload', 'resourceCenterFiles'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${CONFIG.TYPEORM_URL}/`,
    prepareHeaders: (headers, {getState}) => {
      const token = getSessionJwt();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  keepUnusedDataFor: 120,
  endpoints: builder => ({
    getFirm: builder.query({
      query: ({firmId}) => `/firms/${firmId}`,
    }),
    getPlanSponsorStrategies: builder.query({
      query: ({planSponsorId}) => `/planSponsors/${planSponsorId}/strategies`,
    }),
    getStrategy: builder.query({
      query: ({strategyId}) => `/strategies/${strategyId}`,
    }),
    getStrategyHoldings: builder.query({
      query: ({strategyId}) => `/strategies/${strategyId}/holdings`,
    }),
    getStrategySecurityHoldings: builder.query({
      query: ({strategyId}) => `/strategies/${strategyId}/securityCompanies`,
    }),
    getStrategyEngagementLetters: builder.query({
      query: ({strategyId}) => `/strategies/${strategyId}/engagementLetters`,
    }),
    getFirmEmployees: builder.query({
      query: ({firmId}) => `firms/${firmId}/employees`,
    }),
    getFirmEsgAssociations: builder.query({
      query: ({firmId}) => `firms/${firmId}/esgAssociations`,
    }),
    getStrategyAggregatePercentileRankIndexes: builder.query({
      query: ({strategyId, issueId}) =>
        `strategies/${strategyId}/aggregatePercentileRankIndexes?issueId=${issueId ?? 1}`,
    }),
    getPlanSponsorStrategyMarketIndexes: builder.query({
      query: ({planSponsorId, issueId}) =>
        `/planSponsors/${planSponsorId}/aggregatePercentileRankIndexes?issueId=${issueId ?? 1}`,
    }),
    getPlanSponsorAggregatePercentileRankHoldings: builder.query({
      query: ({planSponsorId, issueId}) =>
        `/planSponsors/${planSponsorId}/aggregatePercentileRankHoldings?issueId=${issueId ?? 1}`,
    }),
    getStrategyAggregatePercentileRankHoldings: builder.query({
      query: ({strategyId, issueId}) =>
        `strategies/${strategyId}/aggregatePercentileRankHoldings?issueId=${issueId ?? 1}`,
    }),
    getCompany: builder.query({
      query: ({companyId, planSponsorId}) => `companies/${companyId}?planSponsorId=${planSponsorId}`,
    }),
    getCompanyByFirm: builder.query({
      query: ({companyId, firmId}) => `companies/${companyId}?firmId=${firmId}`,
    }),
    getCompanies: builder.query({
      query: () => `companies`,
    }),
    getCompanyIssues: builder.query({
      query: ({companyId}) => `companies/${companyId}/issues`,
      //At client request this issue needs to have different client
      //facing names and data side names (scope 1&2 emissions -> carbon emissions)
      transformResponse: data => {
        return data.map(issueEngagement => {
          if (issueEngagement.issueId === 11) {
            return {...issueEngagement, issue: {...issueEngagement.issue, name: 'Carbon Emissions'}};
          }
          return issueEngagement;
        });
      },
    }),
    getCompanyLatestEngagementLetter: builder.query({
      query: ({companyId}) => `companies/${companyId}/engagementLetters/latest`,
      //At client request this issue needs to have different client
      //facing names and data side names (scope 1&2 emissions -> carbon emissions)
      transformResponse: data => {
        const issues = data.issues.map(issue => {
          if (issue.issueId === 11) {
            return {...issue, issueName: 'Carbon Emissions'};
          }
          return issue;
        });
        return {...data, issues};
      },
    }),
    getCompanyById: builder.query({
      query: ({companyId}) => `companies/${companyId}`,
      providesTags: ['company'],
    }),
    getMissingLetters: builder.query({
      query: () => `missingLetters`,
      providesTags: ['Missing Engagements'],
    }),
    getAggregatePercentileRankUniverse: builder.query({
      query: ({issueId}) => `aggregatePercentileRankUniverse?issueId=${issueId ?? 1}`,
      //At client request this issue needs to have different client
      //facing names and data side names (scope 1&2 emissions -> carbon emissions)
      transformResponse: data => {
        return data.map(holdingWithIssueData => {
          if (holdingWithIssueData.issueId === 11) {
            return {...holdingWithIssueData, issue: 'Carbon Emissions'};
          }
          return holdingWithIssueData;
        });
      },
    }),
    getPlanSponsorManagerEmployees: builder.query({
      query: ({planSponsorId}) => `/planSponsors/${planSponsorId}/managerEmployees`,
    }),
    getPlanSponsorHoldings: builder.query({
      query: ({planSponsorId}) => `/planSponsors/${planSponsorId}/planSponsorHoldings`,
    }),
    getPlanSponsorEngagements: builder.query({
      query: ({planSponsorId}) => `/planSponsors/${planSponsorId}/engagement`,
    }),
    getPlanSponsorFirms: builder.query({
      query: ({planSponsorId}) => `/planSponsors/${planSponsorId}/firms`,
    }),
    getChangelog: builder.query({
      query: () => `changelog`,
    }),
    getIssues: builder.query({
      query: () => `/issues`,
    }),
    getManagerEngagement: builder.query({
      query: ({strategyId}) => `strategies/${strategyId}/managerEngagement`,
    }),
    getStrategyByFirmId: builder.query({
      query: ({firmId}) => `/firms/${firmId}/strategies`,
    }),
    getFirmHoldingsById: builder.query({
      query: ({firmId}) => `/firms/${firmId}/holdings`,
    }),
    getFirmEngagementById: builder.query({
      query: ({firmId}) => `/firms/${firmId}/engagement`,
    }),
    getFirmStrategyMarketIndexes: builder.query({
      query: ({firmId}) => `/firms/${firmId}/aggregatePercentileRankIndexes`,
    }),
    getAggregateMarketIndexBenchmark: builder.query({
      query: ({marketIndexes, issueId}) => {
        const marketIndexesParam = new URLSearchParams({marketIndexes: JSON.stringify(marketIndexes)}).toString();
        return `/aggregateMarketIndexBenchmark?${marketIndexesParam}&issueId=${issueId ?? 1}`;
      },
    }),
    getOldestManualEditCompanyIssue: builder.query({
      query: ({}) => `/oldestManualEditCompanyIssue`,
    }),
    updateCompanies: builder.mutation({
      query: ({companyId, ...values}) => ({
        url: `companies/${companyId}`,
        method: `PUT`,
        body: {...values},
      }),
      invalidatesTags: ['company', 'issueFileUpload'],
    }),
    createManualEditCompanyIssue: builder.mutation({
      query: ({...values}) => ({
        url: `manualEditCompanyIssue`,
        method: `POST`,
        body: {...values},
      }),
      invalidatesTags: ['company'],
    }),
    deleteMissingLetters: builder.mutation({
      query: () => ({url: `/missingLetters`, method: 'DELETE'}),
      invalidatesTags: ['Missing Engagements'],
    }),
    getManualEditCompanyIssue: builder.query({
      query: ({companyId}) => `/manualEditCompanyIssue/${companyId}/latest`,
      providesTags: ['manualCompanyIssue'],
    }),
    getManualEditCompanyIssues: builder.query({
      query: ({companyId}) => `/manualEditCompanyIssue/${companyId}`,
      providesTags: ['manualCompanyIssue'],
    }),
    updateManualEditCompanyIssue: builder.mutation({
      query: ({...values}) => ({
        url: '/manualEditCompanyIssue',
        method: 'POST',
        body: {...values},
      }),
      invalidatesTags: ['manualCompanyIssue'],
    }),
    getCompanyIssuesFileUploads: builder.query({
      query: ({companyId, issueId}) => ({
        url: `/companies/${companyId}/fileUploads`,
        params: {
          issueId,
        },
      }),
      providesTags: ['issueFileUpload'],
    }),
    createCompanyIssuesFileUploads: builder.mutation({
      query: ({companyId, ...body}) => ({
        url: `/companies/${companyId}/fileUploads`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['issueFileUpload'],
    }),
    getResourceCenterFiles: builder.query({
      query: () => ({
        url: `/resourceCenterFiles`,
      }),
      providesTags: ['resourceCenterFiles'],
    }),
    updateResourceCenterFile: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/resourceCenterFiles/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['resourceCenterFiles'],
    }),
    createResourceCenterFile: builder.mutation({
      query: ({...body}) => ({
        url: `/resourceCenterFiles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['resourceCenterFiles'],
    }),
    deleteResourceCenterFile: builder.mutation({
      query: ({id}) => ({
        url: `/resourceCenterFiles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['resourceCenterFiles'],
    }),
    deleteUpdloadWithArchives: builder.mutation({
      query: ({uploadId}) => ({
        url: `/clientHoldingsUploads/${uploadId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetFirmQuery,
  useGetPlanSponsorStrategiesQuery,
  useGetStrategyQuery,
  useLazyGetStrategyQuery,
  useGetStrategyHoldingsQuery,
  useGetStrategySecurityHoldingsQuery,
  useGetStrategyEngagementLettersQuery,
  useGetMissingLettersQuery,
  useGetFirmEmployeesQuery,
  useGetFirmEsgAssociationsQuery,
  useGetStrategyAggregatePercentileRankIndexesQuery,
  useGetPlanSponsorAggregatePercentileRankHoldingsQuery,
  useGetStrategyAggregatePercentileRankHoldingsQuery,
  useGetPlanSponsorStrategyMarketIndexesQuery,
  useGetChangelogQuery,
  useGetCompanyQuery,
  useGetCompanyByFirmQuery,
  useGetCompaniesQuery,
  useGetCompanyIssuesQuery,
  useGetCompanyLatestEngagementLetterQuery,
  useGetAggregatePercentileRankUniverseQuery,
  useGetPlanSponsorManagerEmployeesQuery,
  useGetPlanSponsorHoldingsQuery,
  useGetPlanSponsorEngagementsQuery,
  useGetPlanSponsorFirmsQuery,
  usePrefetch,
  useGetIssuesQuery,
  usePutAggregatePercentileRankHoldingsQuery,
  useDeleteMissingLettersMutation,
  useGetManagerEngagementQuery,
  useGetStrategyByFirmIdQuery,
  useGetFirmEngagementByIdQuery,
  useGetFirmHoldingsByIdQuery,
  useGetFirmStrategyMarketIndexesQuery,
  useGetAggregateMarketIndexBenchmarkQuery,
  useGetOldestManualEditCompanyIssueQuery,
  useGetManualEditCompanyIssueQuery,
  useGetCompanyByIdQuery,
  useUpdateCompaniesMutation,
  useUpdateManualEditCompanyIssueMutation,
  useGetManualEditCompanyIssuesQuery,
  useCreateManualEditCompanyIssueMutation,
  useGetCompanyIssuesFileUploadsQuery,
  useLazyGetCompanyIssuesFileUploadsQuery,
  useCreateCompanyIssuesFileUploadsMutation,
  useDeleteUpdloadWithArchivesMutation,
  useGetResourceCenterFilesQuery,
  useCreateResourceCenterFileMutation,
  useUpdateResourceCenterFileMutation,
  useDeleteResourceCenterFileMutation,
} = typeOrm;
