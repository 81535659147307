import React, {Component} from 'react';

class MedalDescriptions extends Component {
  render() {
    const {data} = this.props;
    return (
      <div className="row medal-description-container d-inline-flex flex-wrap">
        {data.map((item, i) => (
          <div className="col-1 medal-description flex-grow-0" key={i}>
            <div className="medal-description-image d-flex justify-content-center">
              {item && item.image && <img crossOrigin="anonymous" src={item.image} alt="Badge" />}
            </div>
            <div className="medal-description-title text-uppercase text-center">
              {item && item.title && <span>{item.title}</span>}
            </div>
            <div className="medal-description-summary text-center">
              {item && item.description && <p>{item.description}</p>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export {MedalDescriptions};
