/* eslint-disable max-len */
import React from 'react';

import {CONFIG, ROUTE} from 'app/constants';
import {VimeoPlayer} from 'app/components/VimeoPlayer';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';

function RatingsCenter() {
  return (
    <div className="ratings-center">
      <div className="p-content-lg">
        <h1>{ROUTE.RATINGS_CENTER.title}</h1>
        <p>
          Welcome to Harmony Analytics'<sup>&reg;</sup> Ratings Center. In-depth, focused research is integral to our
          mission and responsibility to provide better data and Changing How the World Wins<sup>&reg;</sup>. Discover
          more and gain insight from top thought leaders in the field.
        </p>
      </div>

      <div className="background-divider">
        <div className="p-content-lg">
          <h2 className="h2-alt">Videos</h2>
          <div className="card-deck card-deck-videos">
            <div className="card card-video">
              <div className="card-body-video">
                <VimeoPlayer vimeoId="508581850" />
              </div>
              <div className="card-body">
                <h3>Harmonizing Returns &amp; Values</h3>
                <span className="label-time">49 Secs</span>
                <p>Harmony Analytics promotes both strong investment returns &amp; positive social impact.</p>
              </div>
            </div>
            <div className="card card-video">
              <div className="card-body-video">
                <VimeoPlayer vimeoId="508586976" />
              </div>
              <div className="card-body">
                <h3>Meaningful Measurements</h3>
                <span className="label-time">1 Min and 23 Secs</span>
                <p>
                  To assign meaningful ratings Harmony leverages technology and independent research to triangulate on
                  ESG measures.
                </p>
              </div>
            </div>
            <div className="card card-video">
              <div className="card-body-video">
                <VimeoPlayer vimeoId="556450072" />
              </div>
              <div className="card-body">
                <h3>Changing How The World Wins™</h3>
                <span className="label-time">1 Min</span>
                <p>
                  Empower organizations towards more sustainable value creation, with comprehensive and meaningful ESG
                  analytics.
                </p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="h2-alt">Whitepapers</h2>
            <div className="card-deck">
              <div className="card card-resource">
                <div className="card-body">
                  <h3>Harmony Methodology</h3>
                  <span className="label-time">10 minute read</span>
                  <p>Harmony provides clients with a solution to screen companies from an ESG Perspective</p>
                </div>
                <div className="card-footer">
                  <a
                    className="btn btn-outline"
                    href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/uploads/harmony-methodology-whitepaper.pdf`)}
                    target="_blank"
                  >
                    Download Whitepaper
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export {RatingsCenter};
