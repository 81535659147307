import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';

// Local Imports
import {LoginForm, LogoGlyph, ParticleBackground} from 'app/components';
import {ROUTE, ROLE} from 'app/constants';
import {AUTH_STATUS, authLogoutAcknowledge} from 'app/redux/auth';
import {includes} from 'lodash';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {hideAuthenticatedError: props.authUser.status === AUTH_STATUS.LOGOUT_COMPLETE};
  }

  componentDidMount() {
    if (this.state.hideAuthenticatedError) {
      this.props.dispatch(authLogoutAcknowledge());
    }
  }

  onLoginComplete = roles => {
    const isAdmin = includes(roles, ROLE.ADMIN);
    const isUserType4 = includes(roles, ROLE.USER_TYPE_4);

    let path = ROUTE.DASHBOARD.path();

    if (isAdmin) {
      path = ROUTE.ADMIN_DASHBOARD.path();
    } else if (isUserType4) {
      path = ROUTE.COMPANY_DASHBOARD.path();
    }

    this.props.dispatch(push(path));
  };

  render() {
    return (
      <div className="login-layout">
        <ParticleBackground />
        <div className="login-layout-inner">
          <LogoGlyph color="white" />
          <LoginForm {...this.props} {...this.state} onLoginComplete={this.onLoginComplete} />
          <Link to={ROUTE.FORGOT_PASSWORD.path()} className="small d-inline-block">
            {ROUTE.FORGOT_PASSWORD.title}
          </Link>
          <span className="mx-3">|</span>
          <Link to={ROUTE.TERMS.path()} className="small d-inline-block" target="_blank" title="View our Terms of Use">
            Terms of Use
          </Link>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {authUser: state.auth};
};

export const Login = connect(mapStateToProps, dispatch => ({dispatch}))(LoginPage);
