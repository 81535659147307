import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError, change} from 'redux-form';

// Local Imports
import {AdminEmployeeForm, AdminAddEmployeeForm} from 'app/components';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {createUser, getByIdUser} from 'app/redux/user';
import {getEditAdminPermissions, updateAdminPermissions} from 'app/redux/adminPermissions';
import {toastSuccess} from 'app/utilities/toast';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';
import {refreshAuthTokens} from 'app/utilities/tokenFunctions';

const formName = 'admin-edit-employee';

/**
 * Formats the user data, retrieved from the API, into the format needed by the edit form
 * @returns {Object} An object formatted for the admin user form
 */

class AdminAddEmployeePage extends Component {
  constructor(props) {
    super(props);
    this.state = {permissions: null};
  }

  render() {
    return (
      <div className="p-content-lg">
        <Link
          className="d-inline-block mb-4 small"
          to={{
            pathname: ROUTE.ADMIN_EMPLOYEES.path(),
            search: this.props.location.search,
          }}
        >
          <Icon icon={faArrowLeft} className="mr-2" />
          Return to {ROUTE.ADMIN_EMPLOYEES.title}
        </Link>
        <h1 className="mb-2">{ROUTE.ADMIN_USERS_ADD.title}</h1>
        {
          // this.props.adminPermissions.hasError
          //   ? <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>
          //   : (
          <AdminAddEmployeeForm {...this.props} formName={formName} canWrite={this.props.canWrite} autoComplete="off" />
          // )
        }
      </div>
    );
  }
}

AdminAddEmployeePage.propTypes = {match: PropTypes.object.isRequired};

const mapStateToProps = state => {
  const hasError = get(state, 'adminPermissions.hasError');
  const permissions = get(state, 'auth.adminPermission') || get(state, 'user.hasGetUserError');
  return {
    hasError,
    planSponsors: get(state, 'planSponsor.planSponsors'),
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_ADMIN_PERMISSIONS),
    permissions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const user = {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        userType: 'Admin',
        adminPermissions: {
          readUsers: values.readUsers,
          writeUsers: values.writeUsers,
          readFirms: values.readFirms,
          writeFirms: values.writeFirms,
          readCompanies: values.readCompanies,
          writeCompanies: values.writeCompanies,
          readHarmonyData: values.readHarmonyData,
          writeHarmonyData: values.writeHarmonyData,
          readClientHoldings: values.readClientHoldings,
          writeClientHoldings: values.writeClientHoldings,
          readReferenceIndexes: values.readReferenceIndexes,
          writeReferenceIndexes: values.writeReferenceIndexes,
          readSiteSettings: values.readSiteSettings,
          writeSiteSettings: values.writeSiteSettings,
          readAdminPermissions: values.readAdminPermissions,
          writeAdminPermissions: values.writeAdminPermissions,
          impersonateUT1: values.impersonateUT1,
          impersonateUT2: values.impersonateUT2,
          impersonateUT3: values.impersonateUT3,
          impersonateUT4: values.impersonateUT4,
        },
      };
      const currentUser = ownProps.authUser.id;
      return dispatch(createUser(user))
        .then(response => {
          if (response.hasError) {
            console.error({response});
            throw response.error;
          } else {
            toastSuccess('The employee has been created.');
          }

          animateScroll.scrollToTop();

          dispatch(reset(formName));

          dispatch(
            push({
              pathname: ROUTE.ADMIN_EMPLOYEES.path(),
              search: ownProps.location.search,
            }),
          );
        })
        .catch(error => {
          animateScroll.scrollToTop();
          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    changeFieldValue: (field, value) => {
      dispatch(change(formName, field, value));
    },
    dispatch,
  };
};

export const AdminAddEmployee = connect(mapStateToProps, mapDispatchToProps)(AdminAddEmployeePage);
