import {Theme} from '../atoms/theme';
import {ProgressSquares} from '../molecules/ProgressSquares';
import {QuintileProps} from 'v2/utilities/types/components/Quintiles';

export const Quintile = ({
  displayCondensed = false,
  size = '1.375rem',
  percent,
  quintile = 0,
  rounded,
}: QuintileProps) => {
  const colors = {
    0: Theme.colors.red,
    1: Theme.colors.orange,
    2: Theme.colors.yellow,
    3: Theme.colors.light_green,
    4: Theme.colors.green,
  };
  return (
    <ProgressSquares
      size={size}
      colors={colors}
      selected={-(quintile - 5)}
      displayCondensed={displayCondensed}
      rounded={rounded}
    />
  );
};
