import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import {faCaretUp, faCaretDown} from '@fortawesome/free-solid-svg-icons';

const IssueDataChangeDescription = ({classes, includeIcon, includeNoun, issue, percentChange}) => {
  let className = 'opacity-0';
  let text = 'No measurable change';
  let noun = 'Change';
  let icon;

  if (percentChange !== null) {
    className = 'text-gray';

    if (percentChange < 0) icon = faCaretDown;
    else if (percentChange > 0) icon = faCaretUp;

    const percentLabel = `${Math.abs(percentChange)}%`;

    if (issue.isHigherWorse) {
      if (percentChange > 0) {
        className = 'text-danger';
        noun = 'Increase';
      } else if (percentChange < 0) {
        className = 'text-success';
        noun = 'Reduction';
      }
    } else {
      if (percentChange > 0) {
        // eslint-disable-line no-lonely-if
        className = 'text-success';
        noun = 'Improvement';
      } else if (percentChange < 0) {
        className = 'text-danger';
        noun = 'Decline';
      }
    }
    text = includeNoun ? `${percentLabel} ${noun}` : percentLabel;
  }

  return (
    <span className={cn(classes, className)}>
      {!!includeIcon && !!icon && <Icon icon={icon} className="mr-1" />}
      {text}
    </span>
  );
};

IssueDataChangeDescription.propTypes = {
  classes: PropTypes.string,
  includeIcon: PropTypes.bool,
  includeNoun: PropTypes.bool,
  issue: PropTypes.shape({
    isHigherWorse: PropTypes.bool,
  }).isRequired,
  percentChange: PropTypes.number,
};

IssueDataChangeDescription.defaultProps = {
  includeNoun: true,
};

export {IssueDataChangeDescription};
