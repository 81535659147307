import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {SideDrawer} from 'v2/components/molecules/Drawer/Drawer';
import {AppliedFiltersByListItem, AppliedFiltersByOption, FilterNode} from 'v2/utilities/types/components/FilterList';
import {FilterList, FilterListOptions} from './FilterList';
import {useAppliedFilters} from 'v2/hooks/filters/useAppliedFilters';
import {SearchBar} from '../../molecules/SearchBar';
import {useAggregatePercentile} from 'v2/hooks/filters/useAggregatePercentile';

const STRATEGY_MAP: Record<string, string> = {
  manager: 'firmId',
  product: 'id',
  index: 'marketIndexId',
  strategy: 'portfolioManagementType',
};

export type NodeType = 'list' | 'options';

interface IFilterDrawer {
  isOpen: boolean;
  onClose: () => void;
  rootNode: FilterNode;
}

const Container = styled.div`
  position: relative;
  padding: 0;
  flex-direction: column;
  display: flex;
`;

const getSelectedOptions = (appliedFilters: AppliedFiltersByListItem) => {
  const keys = Object.keys(appliedFilters || {});
  const filters = keys
    .map(key => {
      const options: string[] = Object.values(appliedFilters[key] || {})
        .filter(option => option.checked)
        .map(option => option.value);

      return {key, options};
    })
    .filter(filter => filter.options.length);

  return filters || [];
};

/**
 * Renders a drawer with a list of filters and options.
 * It accepts a rootNode containing details about the panels to be rendered.
 */
export const FilterDrawer: React.FC<IFilterDrawer> = ({rootNode, isOpen, onClose}) => {
  const [nodeHistory, setHistory] = useState<FilterNode[]>([rootNode]);
  const currentNode = nodeHistory[nodeHistory.length - 1];

  const {appliedFilters, applyFilters, resetFilters} = useAppliedFilters({listItemId: currentNode.id});
  const [checkedOptions, setChecked] = useState<AppliedFiltersByListItem>(appliedFilters);
  const [searchValue, setSearchValue] = useState('');
  const {holdingsStrategies, isLoading} = useAggregatePercentile();
  const selectedOptions = getSelectedOptions(checkedOptions);
  const currentNodeOptions = currentNode.options || [];

  const goBack = () => {
    setHistory(nodeHistory.slice(0, -1));
    setSearchValue('');
  };

  const handleApply = () => {
    applyFilters(checkedOptions);
    handleClose();
  };

  const handleReset = () => {
    resetFilters();
    setChecked({});
    setHistory([rootNode]);
    setSearchValue('');
  };

  const handleSelectNode = (node: FilterNode) => {
    setHistory([...nodeHistory, node]);
  };

  const handleSelectOption = (option: AppliedFiltersByOption) => {
    setChecked({
      ...checkedOptions,
      [currentNode.id]: {...checkedOptions[currentNode.id], ...option},
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setHistory([rootNode]);
    }, 300);
  };

  useEffect(() => {
    setHistory([rootNode]);
  }, [rootNode]);

  useEffect(() => {
    setChecked(appliedFilters);
  }, [isOpen]);

  const getSublabel = (node: FilterNode) => {
    const optionLabels =
      node.options?.filter(({value}) => checkedOptions[node.id]?.[value]?.checked).map(({label}) => label) || [];
    return optionLabels?.length > 1 ? `${optionLabels.length} items selected` : optionLabels?.[0] || '';
  };

  const filteredOptions = searchValue
    ? currentNodeOptions.filter((option: any) => option.label.toLowerCase().includes(searchValue.toLowerCase()))
    : currentNodeOptions;

  const filteredHoldingsStrategies = selectedOptions.reduce((acc: any[], option) => {
    const {key, options} = option;
    const includeThis = key === currentNode.id;

    return (
      acc?.filter((holdingStrategy: any) => {
        return options.includes(holdingStrategy[STRATEGY_MAP[key] || key]) || includeThis;
      }) ?? []
    );
  }, holdingsStrategies);

  const disableOptions = filteredOptions.map(option => ({
    ...option,
    disabled: !filteredHoldingsStrategies.some(
      (strategy: any) => strategy[STRATEGY_MAP[currentNode.id] || currentNode.id] === option.value,
    ),
  }));

  return (
    <SideDrawer
      isOpen={isOpen}
      onApply={handleApply}
      onBack={nodeHistory.length > 1 ? goBack : undefined}
      onClose={handleClose}
      onReset={handleReset}
      title={currentNode?.label}
    >
      <Container>
        {currentNodeOptions.length > 12 && (
          <SearchBar placeholder={`Search by ${currentNode?.label}`} onChange={setSearchValue} />
        )}
        <FilterList
          getSublabel={getSublabel}
          filters={currentNode.nodes || []}
          onSelect={handleSelectNode}
          disabled={isLoading}
        />
        <FilterListOptions
          options={disableOptions || []}
          onSelect={handleSelectOption}
          values={checkedOptions[currentNode.id]}
        />
      </Container>
    </SideDrawer>
  );
};
