import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {fill, map} from 'lodash';
import cn from 'classnames';

import {RATING_OPTIONS} from 'app/constants';
import {RectangleImage} from 'app/components';

class BarChartLegend extends Component {
  render() {
    const {className, datasets, labels, style} = this.props;
    let {datasetsVisible = []} = this.props;

    const hasDatasets = !!datasets && datasets.length > 0;

    if (hasDatasets) {
      // Fallback datasetsVisible to all if not supplied
      if (datasetsVisible.length === 0) datasetsVisible = fill(Array(datasets.length), true);
    }

    return (
      <div className={cn('bar-chart-legend', className)} style={style}>
        <div className="bar-chart-legend-labels">
          {map(labels, (label, labelIndex) => (
            <div key={labelIndex} className="bar-chart-legend-label">
              {label}
            </div>
          ))}
        </div>
        {hasDatasets && (
          <div className="bar-chart-legend-datasets">
            {map(datasets, ({color, label}, datasetIndex) => {
              const datasetClassName = cn('bar-chart-legend-dataset', {'is-hidden': !datasetsVisible[datasetIndex]});

              return (
                <div key={datasetIndex} className={datasetClassName}>
                  <RectangleImage fill={color} />
                  {label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

BarChartLegend.propTypes = {
  className: PropTypes.string,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string,
    }),
  ),
  datasetsVisible: PropTypes.arrayOf(PropTypes.bool),
  labels: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
};

BarChartLegend.defaultProps = {labels: RATING_OPTIONS};

export {BarChartLegend};
