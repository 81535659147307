import {getSessionJwt} from 'app/utilities';
import {useGetAggregatePercentileHoldingsQuery} from 'v2/redux/harmonyApi';
import {updateFilterOptions} from 'v2/redux/strategyFilter';
import {setTypesFilters} from 'v2/utilities/helpers/calculateStatistics';
import {useDispatch, useSelector} from 'react-redux';

const typeToClassType = {
  'Liquid Assets': 'Liquid',
  'Illiquid Assets': 'Illiquid',
  all: 'all',
};

export const useSetUT1DashFilterOptions = () => {
  const dispatch = useDispatch();
  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;
  //ts-ignore
  const data = useGetAggregatePercentileHoldingsQuery({planSponsorId, issueId: 1})?.data ?? [];
  const {typeFilters: type, classFilters: classOptions, regionFilters: region} = setTypesFilters(data);
  //@ts-ignore
  const typeValue: keyof typeof typeToClassType = useSelector(state => state.strategyFilter.typeValue.value);

  //@ts-ignore
  dispatch(
    updateFilterOptions({
      type: Object.values(type),
      class: Object.values(classOptions[typeToClassType[typeValue]]),
      region: Object.values(region),
    }),
  );
};
