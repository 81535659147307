import styled from 'styled-components/macro';
import {FlexContainer} from './Containers';

export const Overlay = styled(FlexContainer)<{visible?: boolean}>`
  display: ${({visible}) => (visible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  background-color: ${({theme}) => theme.colors.black + '26'};
`;
