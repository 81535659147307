import React, {Component} from 'react';
import {map} from 'lodash';
import {Doughnut} from 'react-chartjs-2';

export const ManagersDoughnutChart = ({data, labels, caption}) => {
  // eslint-disable-next-line no-unused-vars
  const styles = {
    chartWrapper: {
      position: 'relative',
      marginRight: 15,
      width: 65,
      height: 65,
      objectFit: 'fill',
    },
    percentText: {
      position: 'absolute',
      top: 9,
      left: 0,
      height: '70px',
      width: '80px',
      lineHeight: '70px',
      textAlign: 'center',
      fontSize: '0.95em',
      color: '#333A47',
      fontFamily: 'futura-pt',
      fontWeight: 600,
    },
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    animation: false,
    cutout: '80%',
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 10,
        bottom: 5,
      },
    },
    maintainAspectRatio: true,
  };

  const datasetsForChart = map(data, ({label, active, inActive}) => {
    return {
      label,
      data: [active, inActive],
      backgroundColor: ['#00446C', '#E5E5E5'],
    };
  });

  const chartData = {
    datasets: datasetsForChart,
  };

  const percent = chartData.datasets[0].data[0];

  const plugins = [
    {
      beforeDraw: function (chart) {
        let width = chart.width;
        let height = chart.height;
        let ctx = chart.ctx;

        ctx.restore();

        ctx.font = `${styles.percentText.fontSize} ${styles.percentText.fontFamily}`;
        ctx.fontWeight = styles.percentText.fontWeight;
        ctx.fontColor = styles.percentText.color;

        ctx.textBaseline = 'top';

        let text = percent + '%';
        let textX = Math.round((width - ctx.measureText(text).width) / 2);
        let textY = 28;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <div className="manager-page-chart-col" style={styles.chartWrapper}>
      <Doughnut data={chartData} options={doughnutChartOptions} width={65} height={65} plugins={plugins} />
    </div>
  );
};
