import {ROUTE as V2} from 'v2/constants/routes';

export const ROUTE = {
  ADMIN_CLIENT_HOLDINGS_UPLOAD: {
    path: () => '/admin/client-holdings-upload',
    title: 'Upload Client Holdings',
    menuTitle: 'Client Holdings Upload',
    menuMessage: 'Upload a CSV containing Client Holdings for a specific sponsor.',
  },
  ADMIN_DASHBOARD: {
    path: () => '/admin',
    title: 'Admin Dashboard',
  },
  ADMIN_EMPLOYEES: {
    path: () => '/admin/employees',
    title: 'Employee Manager',
    menuTitle: 'Employees',
    menuMessage: 'View and Edit Employee Permissions, with the ability to create new employees.',
  },
  ADMIN_FIRMS: {
    path: () => '/admin/firms',
    title: 'Firm Manager',
    menuTitle: 'Firms',
    menuMessage: 'View a list of all Firms, with the ability to edit the Firm Name and licensing per Strategy.',
  },
  ADMIN_FIRMS_EDIT: {
    path: key => `/admin/firms/edit/${key}`,
    title: 'Edit Firm',
  },
  ADMIN_HARMONY_UPLOADS: {
    path: () => '/admin/harmony-uploads',
    title: 'Upload Harmony Data',
    menuTitle: 'Harmony Data',
    menuMessage: 'Upload a CSV containing Harmony Data (aka ESG Raw Data) for a specific point in time.',
  },
  ADMIN_PLAN_SPONSORS: {
    path: () => '/admin/plan-sponsors',
    title: 'Plan Sponsors',
  },
  ADMIN_MARKET_INDEXES: {
    path: () => '/admin/market-indexes',
    title: 'Reference Index Manager',
    menuTitle: 'Reference Indexes',
    menuMessage: `View a list of Reference Indexes and upload a CSV containing the securities that exist within
     a selected Index`,
  },
  ADMIN_MARKET_INDEX_EDIT: {
    path: marketIndexId => `/admin/market-index-edit/${marketIndexId}`,
    title: 'Edit Reference Index',
  },
  ADMIN_SECURITY_LISTING: {
    path: () => '/admin/securities',
    title: 'Company Manager',
    menuTitle: 'Companies',
    menuMessage: 'View a list of all Companies, with the ability to view and edit data.',
  },
  ADMIN_SECURITY_EDIT: {
    path: key => `/admin/securities/edit/${key}`,
    title: 'Edit Company',
  },
  ADMIN_SITE_SETTINGS: {
    path: () => '/admin/site-settings',
    title: 'Site Settings Manager',
    menuTitle: 'Site Settings',
    menuMessage: 'View and update site settings.',
  },
  ADMIN_USERS: {
    path: () => '/admin/users',
    title: 'User Manager',
    menuTitle: 'Users',
    menuMessage: "View, Add and Edit all User Type 1's, with the ability send invitations.",
  },
  ADMIN_USERS_ADD: {
    path: () => '/admin/users/add',
    title: 'Add New User',
  },
  ADMIN_USERS_EDIT: {
    path: key => `/admin/users/edit/${key}`,
    title: 'Edit User',
  },
  ADMIN_EMPLOYEES_ADD: {
    path: () => `/admin/employees/add`,
    title: 'Add New Employee',
  },
  ADMIN_EMPLOYEES_EDIT: {
    path: key => `/admin/employees/edit/${key}`,
    title: 'Edit Employee',
  },
  ALERTS: {
    path: () => '/alerts',
    title: 'Alerts',
  },
  COMPANIES: {
    path: () => '/companies',
    title: 'Companies',
  },
  COMPANY_DETAIL: {
    path: securityId => `/company-detail/${securityId}`,
    title: 'Company Detail',
  },
  COMPANY_ENGAGEMENT: {
    path: () => '/company-engagement',
    title: 'Company Engagement',
  },
  ONBOARDING_LANDING: {
    path: invitationCode => `/onboarding/${invitationCode}`,
    title: 'Welcome to Harmony Analytics',
  },
  ONBOARDING_CREATE_ACCOUNT: {
    path: invitationCode => `/onboarding-account/${invitationCode}`,
    title: 'Create an Account',
  },
  ONBOARDING_PROFILE: {
    path: invitationCode => `/onboarding-profile/${invitationCode}`,
    title: 'Your Profile',
  },
  ONBOARDING_PROFILE_V2: {
    path: invitationCode => `/v2/onboarding-profile/${invitationCode}`,
    title: 'Your Profile',
  },
  ONBOARDING_PLAN_INFO: {
    path: invitationCode => `/onboarding-plan/${invitationCode}`,
    title: 'Plan Information',
  },
  ONBOARDING_ESG_STRATEGY: {
    path: invitationCode => `/onboarding-esg/${invitationCode}`,
    title: 'Create ESG Profile',
  },
  ONBOARDING_INVESTMENT_MANAGERS: {
    path: invitationCode => `/onboarding-managers/${invitationCode}`,
    title: 'Investment Manager Information',
  },
  ONBOARDING_UT3_MANAGER: {
    path: invitationCode => `/onboarding-manager/${invitationCode}`,
    title: 'Manager Information',
  },
  ONBOARDING_UT3_STRATEGIES: {
    path: invitationCode => `/onboarding-strategies/${invitationCode}`,
    title: 'Strategies',
  },
  ONBOARDING_UT3_INVITE: {
    path: invitationCode => `/onboarding-invite/${invitationCode}`,
    title: 'Invite Firm Colleagues',
  },
  ONBOARDING_FINALIZE: {
    path: invitationCode => `/onboarding-finalize/${invitationCode}`,
    title: 'Finalize',
  },
  ONBOARDING_UT4_CREATE_ACCOUNT: {
    path: invitationCode => `/onboarding-company/${invitationCode}`,
    title: 'Create an Account',
  },
  DASHBOARD: {
    path: () => '/',
    title: 'Dashboard',
  },
  DISCLAIMER: {
    path: () => '/disclaimer',
    title: 'Legal Disclaimer',
  },
  FORGOT_PASSWORD: {
    path: () => '/forgot-password',
    title: 'Forgot Password?',
  },
  HELP: {
    path: () => '/help',
    title: 'Help',
  },
  LOGIN: {
    path: () => '/login',
    title: 'Log In',
  },
  MANAGE_ESG: {
    path: () => '/esg-profile',
    title: 'Manage ESG',
    menuMessage: 'Update the ESG Focus and ESG Policy Targets for your organization.',
  },
  MANAGERS: {
    path: () => '/managers',
    title: 'Managers',
  },
  MANAGER_DETAIL: {
    path: strategyId => `/manager-detail/${strategyId}`,
    title: 'Manager Detail',
  },
  PAYWALL: {
    path: strategyId => `/strategy/${strategyId}/paywall`,
    title: 'Harmony Analytics License Required',
  },
  ALL_PLANS: {
    path: () => '/',
    title: 'Clients',
  },
  PLAN: {
    path: () => '/holdings',
    title: 'Holdings',
  },
  PROGRESS_REPORT: {
    path: () => '/progress-report',
    title: 'Progress Report',
  },
  PROGRESS_REPORT_PRINTABLE: {
    path: () => '/printable-progress-report',
    title: 'Printable Progress Report',
  },
  REGISTER: {
    path: () => '/register',
    title: 'Register',
  },
  RESET_PASSWORD: {
    path: resetToken => `/reset-password/${resetToken}`,
    title: 'Reset Password',
  },
  SITE_SEARCH: {
    path: () => '/search',
    title: 'Site Search',
  },
  SETTINGS: {
    path: () => '/settings',
    title: 'Settings',
  },
  SETTINGS_ESG_DRAFT: {
    path: () => '/settings/esg-draft',
    title: 'Suggested Amendment to the IMA',
  },
  SETTINGS_FIRMS_ADD: {
    path: () => '/settings/firm-manager/add',
    title: 'Investment Firms & Strategies',
    menuTitle: 'Firm Manager',
  },
  SETTINGS_FIRMS_EDIT: {
    path: firmId => `/settings/firm-manager/edit/${firmId}`,
    title: 'Investment Firms & Strategies',
    menuTitle: 'Investment Managers',
  },
  SETTINGS_FIRMS_MANAGER: {
    path: () => '/settings/firm-manager',
    title: 'Investment Managers',
    menuTitle: 'Investment Managers',
    menuMessage: 'Invite & Edit managers and strategies for your organization.',
  },
  SETTINGS_FIRM_PROFILE: {
    path: () => '/settings/firm-profile',
    title: 'Manager Profile',
    menuMessage: 'Modify information about your firm, such as your ESG associations and your ESG approach.',
  },
  HARMONY_INDEX_CENTER: {
    path: () => '/settings/harmony-index-center',
    title: 'Harmony Index Center',
    menuMessage: 'Learn about Harmony Indexes.',
  },
  HARMONY_INDEX_DRAFT: {
    path: strategyId => `/settings/harmony-index-draft/strategy/${strategyId}`,
    title: 'Copy Email & IMA Template',
  },
  SETTINGS_PLAN_PROFILE: {
    path: () => '/settings/plan-profile',
    title: 'Organization Profile',
    menuMessage: 'Update service provider and investment consultants information.',
  },
  SETTINGS_USER_PROFILE: {
    path: () => '/settings/user-profile',
    title: 'Your Profile',
    menuMessage: 'Edit your contact information.',
  },
  SETTINGS_USERS: {
    path: () => '/settings/user-manager',
    title: 'Manage Users',
    menuMessage: 'Invite and modify permissions for users at your organization.',
  },
  SETTINGS_USERS_ADD: {
    path: () => '/settings/user-manager/add',
    title: 'Invite New User',
    titleUT3: 'Add New Colleague',
  },
  SETTINGS_USERS_EDIT: {
    path: userId => `/settings/user-manager/edit/${userId}`,
    title: 'Edit User',
    titleUT3: 'Edit Colleague',
  },
  STRATEGY_ALERTS: {
    path: strategyId => `/strategy/${strategyId}/alerts`,
    title: 'Alerts',
  },
  STRATEGY_COMPANIES: {
    path: strategyId => `/strategy/${strategyId}/companies`,
    title: 'Companies',
  },
  STRATEGY_COMPLIANCE_MANAGER: {
    path: strategyId => `/strategy/${strategyId}/compliance-manager`,
    title: 'Portfolio Center',
  },
  STRATEGY_DASHBOARD: {
    path: strategyId => `/strategy/${strategyId}`,
    title: 'Plan Strategy',
  },
  STRATEGY_COMPANY_DETAIL: {
    path: (strategyId, securityId) => `/strategy/${strategyId}/companies/${securityId}`,
    title: 'Company Detail',
  },
  STYLE_GUIDE: {
    path: () => '/style-guide',
    title: 'Style Guide',
  },
  TERMS: {
    path: () => V2.TERMS_OF_USE.path,
    title: 'Terms of Use',
  },
  UPLOAD_PORTFOLIO_HEADER: {
    path: () => '/upload-portfolio',
    title: 'Upload Portfolio',
  },
  UPLOAD_PORTFOLIO_STRATEGY: {
    path: strategyId => `/upload-portfolio/${strategyId}`,
    title: 'Upload Portfolio',
  },
  CONTACT: {
    path: () => '/contact',
    title: 'Contact Us',
  },
  RATINGS_CENTER: {
    path: () => '/ratings-center',
    title: 'Ratings Center',
  },
  COMPANY_DASHBOARD: {
    path: () => '/company/dashboard',
    title: 'Your Profile',
  },
  COMPANY_DATA: {
    path: () => '/company/data',
    title: 'Your Data',
  },
  COMPANY_ROADMAP: {
    path: () => '/company/roadmap',
    title: 'Roadmap',
  },
  COMPANY_RATING_SYSTEM: {
    path: () => '/company/rating-system',
    title: 'Rating System',
  },
  ESG_EXPLORER: {
    path: () => '/v2/ut1/data-explorer',
    title: 'Data Explorer',
  },
  CHANGELOG: {
    path: () => '/changelog',
    title: 'Changelog',
  },
  V2_RESOURCE_CENTER: {
    path: () => 'v2/ratings-center',
    title: 'Resource Center',
  },
};
