module.exports = {
  prefix: 'fal',
  iconName: 'arrow-alt-up',
  icon: [
    448,
    512,
    [],
    'f357',
    'M272 480h-96c-13.3 0-24-10.7-24-24V256H48.2c-21.4 0-32.1-25.8-17-41L207 39c9.4-9.4 24.6-9.4 34 0l175.8 176c15.1 15.1 4.4 41-17 41H296v200c0 13.3-10.7 24-24 24z',
  ],
};
