import React, {Component} from 'react';
import cn from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faTimes from 'app/fontawesome-pro-light/faTimes';
import faSearch from 'app/fontawesome-pro-light/faSearch';

class HeaderSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {open: false};

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({open: true});
  }

  close() {
    this.setState({open: false});
  }

  render() {
    return (
      <div className={cn('header-search', {'is-open': this.state.open})}>
        <button type="button" className="header-search-open">
          <Icon icon={faSearch} />
        </button>
        <input onFocus={this.open} placeholder="Search..." />
        <button type="button" onClick={this.close} className="header-search-close">
          <Icon icon={faTimes} />
        </button>
      </div>
    );
  }
}

export {HeaderSearch};
