import React, {Component, Fragment} from 'react';
import {PageContainer} from '../components';

class TableOfContentsPage extends Component {
  render() {
    const {companyName, product, benchMark} = this.props;

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_1} footer={false} companyName={companyName}>
        <div className="table-contents-body-section flex-1 d-flex flex-column justify-content-between">
          <div className="table-contents-main-content-container">
            <Fragment>
              <h1 className="table-contents-company-name-styles" style={{marginBottom: 100}}>
                {companyName}
              </h1>
              <p className="main-text-font-styles1 text-uppercase">Product</p>
              <p className="main-text-font-styles2 font-weight-bold">{product}</p>
              <p className="main-text-font-styles1 text-uppercase">Benchmark</p>
              <p className="main-text-font-styles2 font-weight-bold">{benchMark}</p>
            </Fragment>
          </div>
          <div>
            <h1 className="table-contents-section-font-styles text-uppercase">Sections</h1>
            <hr style={{marginBottom: 100}} />

            <div className="d-flex flex-row justify-content-between">
              <div style={{width: '33.3%'}}>
                <p className="table-contents-number-items-styles">03</p>
                <p className="table-contents-title-items-styles text-uppercase">Product Overview</p>
                <p className="table-contents-list-items-styles">ESG Integration</p>
                <p className="table-contents-list-items-styles">
                  Rating Distribution
                  <br />
                  of Holdings
                </p>
              </div>

              <div style={{width: '33.3%'}}>
                <p className="table-contents-number-items-styles">04</p>
                <p className="table-contents-title-items-styles text-uppercase">ESG by Issue</p>
                <p className="table-contents-list-items-styles">Issue Ratings</p>
                <p className="table-contents-list-items-styles">Holdings Metrics</p>
              </div>

              <div style={{width: '33.3%'}}>
                <p className="table-contents-number-items-styles">09</p>
                <p className="table-contents-title-items-styles text-uppercase">Holdings</p>
                <p className="table-contents-list-items-styles">Most Held</p>
                <p className="table-contents-list-items-styles">High Risk</p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

export {TableOfContentsPage};
