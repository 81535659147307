import styled from 'styled-components/macro';
import {GraphicHeader as GraphicHeaderProps} from 'v2/utilities/types/components/GraphicHeader';
import {FlexContainer, Container} from '../atoms/Containers';
import {TitleXL} from '../atoms/Typeface';
import {DATA_STATUS} from 'v2/constants/dataStatus';

import BACKGROUND_IMG from 'v2/assets/images/abstract-technology-design-wallpaper.png';

const Wrapper = styled(FlexContainer).attrs({
  fullWidth: true,
  items: 'flex-end',
  justify: 'flex-start',
  height: '12.625rem',
  minHeight: '12.625rem',
})`
  background-color: ${({theme}) => theme.colors.harmony};
  background-image: url(${BACKGROUND_IMG});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Title = styled(TitleXL).attrs(({theme}) => ({
  color: theme.colors.white,
}))`
  opacity: 0.8;
  cursor: default;
`;

export const GraphicHeader = ({title}: GraphicHeaderProps) => (
  <Wrapper>
    <Container padding="1.875rem 5.625rem">
      <Title>{title ?? DATA_STATUS.NONE}</Title>
    </Container>
  </Wrapper>
);
