import React from 'react';
import PropTypes from 'prop-types';
import ReactS3Uploader from 'react-s3-uploader';
import cn from 'classnames';

const ButtonUploader = ({className, color, isBusy, outline, percentComplete, size, solid, style, uploaderProps}) => (
  <div
    className={cn(
      'btn position-relative cursor-pointer',
      {'btn-outline': solid ? false : outline, [`btn-${size}`]: size, [`btn-${color}`]: color},
      {'pointer-events-none': isBusy},
      className,
    )}
    style={style}
  >
    Browse
    {isBusy && <div className="btn-progress-fill transition" style={{width: `${percentComplete}%`}} />}
    <ReactS3Uploader {...uploaderProps} className="absolute-fill opacity-0" />
  </div>
);

ButtonUploader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isBusy: PropTypes.bool,
  outline: PropTypes.bool,
  percentComplete: PropTypes.number,
  size: PropTypes.string,
  solid: PropTypes.bool,
  style: PropTypes.object,
  uploaderProps: PropTypes.object,
};

ButtonUploader.defaultProps = {
  outline: true,
};

export {ButtonUploader};
