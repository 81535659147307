import {withRouter} from 'react-router';
import {compose, lifecycle, renderNothing} from 'recompose';

const ScrollToTopOnRouteChange = compose(
  withRouter,
  lifecycle({
    componentDidUpdate() {
      if (this.props.history.action === 'PUSH') {
        window.scrollTo(0, 0); // Instantaneous
      }
    },
  }),
  renderNothing,
)();

export {ScrollToTopOnRouteChange};
