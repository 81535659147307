import {DATA_STATUS} from 'v2/constants/dataStatus';
import {ISSUE_UNITS} from 'v2/constants/issueOptions';
import {ISSUES} from 'v2/constants/issues';
import {convertUnits, getPrecision} from './convertUnits';
import {isNull} from 'lodash';
import {ISSUE} from 'app/constants';

export function defaultIssueUnit(issueId: string | number, dataUnit: string) {
  switch (Number(issueId)) {
    case ISSUES.ELECTRICITY_CONSUMPTION.id:
    case ISSUES.ENERGY_USE.id:
    case ISSUES.FUELS_CONSUMPTION.id:
    case ISSUES.RENEWABLE_ENERGY_CONSUMPTION.id:
      return ISSUE_UNITS.MWH;
    case ISSUES.CARBON_FOOTPRINT_OFFSETS.id:
    case ISSUES.SCOPE_ONE_EMISSIONS.id:
    case ISSUES.CARBON_EMISSIONS.id:
    case ISSUES.SCOPE_TWO_EMISSIONS.id:
    case ISSUES.SCOPE_THREE_EMISSIONS.id:
      return ISSUE_UNITS.MTCO2E;
    case ISSUES.WATER_CONSUMPTION.id:
    case ISSUES.WATER_DISCHARGE.id:
    case ISSUES.WATER_WITHDRAWAL.id:
      return ISSUE_UNITS.TCM;
    case ISSUES.CEO_COMPENSATION.id:
    case ISSUES.MEDIAN_EMPLOYEE_PAY.id:
      return ISSUE_UNITS.USD;
    case ISSUES.CEO_COMPENSATION_RATIO.id:
      return ISSUE_UNITS[':1'];
    case ISSUES.SEVERE_INJURY_FREQUENCY.id:
      return ISSUE_UNITS.TCIR;
    case ISSUES.WOMEN_IN_LEADERSHIP.id:
    case ISSUES.WOMEN_ON_BOARD.id:
    case ISSUES.BOARD_INDEPENDENCE.id:
      return ISSUE_UNITS.PERCENT;
    default:
      if (isNull(dataUnit) || dataUnit === 'null') {
        return 'No Unit';
      }
      return !!dataUnit.length ? dataUnit : 'No Unit';
  }
}

export function defaultIssueValue(issueId: string | number, unit: string, value?: number) {
  if (value) {
    if (unit === defaultIssueUnit(issueId, unit)) {
      return value;
    } else {
      switch (Number(issueId)) {
        case ISSUES.ELECTRICITY_CONSUMPTION.id:
        case ISSUES.ENERGY_USE.id:
        case ISSUES.FUELS_CONSUMPTION.id:
        case ISSUES.RENEWABLE_ENERGY_CONSUMPTION.id:
          return convertUnits(value, ISSUE_UNITS.GJ, ISSUE_UNITS.MWH, getPrecision(value));
        case ISSUES.SCOPE_ONE_EMISSIONS.id:
        case ISSUES.CARBON_EMISSIONS.id:
        case ISSUES.SCOPE_TWO_EMISSIONS.id:
        case ISSUES.SCOPE_THREE_EMISSIONS.id:
        case ISSUES.CARBON_FOOTPRINT_OFFSETS.id:
          return convertUnits(value, ISSUE_UNITS.MMTCO2E, ISSUE_UNITS.MTCO2E, getPrecision(value));
        case ISSUES.WATER_CONSUMPTION.id:
        case ISSUES.WATER_DISCHARGE.id:
        case ISSUES.WATER_WITHDRAWAL.id:
          return convertUnits(value, ISSUE_UNITS.MGAL, ISSUE_UNITS.TCM, getPrecision(value));
        case ISSUES.SEVERE_INJURY_FREQUENCY.id:
          return convertUnits(value, ISSUE_UNITS.LTIR, ISSUE_UNITS.TCIR, getPrecision(value));
        case ISSUES.WOMEN_IN_LEADERSHIP.id:
        case ISSUES.WOMEN_ON_BOARD.id:
        case ISSUES.BOARD_INDEPENDENCE.id:
        default:
          return value;
      }
    }
  } else {
    return DATA_STATUS.NONE;
  }
}
