import {clone, find, get, includes, intersection, isEmpty, isNil, map, orderBy, sumBy} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {matchPath, withRouter} from 'react-router';
import {push} from 'connected-react-router';

// Local Imports
import {ROLE, ROUTE} from 'app/constants';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {getPlanSelection} from 'app/redux/strategy';
import {getUnreadCount} from 'app/redux/userStrategyAlert';
import {getSessionJwt, isDemoAccount, isImpersonating, withUser} from 'app/utilities';
import {toast} from 'app/utilities/toast';
import faBullhorn from 'app/fontawesome-pro-light/faBullhorn';
import {
  adminMenuProps,
  demoAccountMenuProps,
  publicMenuProps,
  userType1MenuProps,
  userType3MenuProps,
  userType4MenuProps,
} from './menuProps';
import {HeaderDisplay} from './HeaderDisplay';
import {currentImpersonatedUser} from 'app/utilities';

export const getAlertNotificationText = unreadCount => `You
  have ${unreadCount} notification${unreadCount > 1 ? 's' : ''} that
  require${unreadCount > 1 ? '' : 's'} your attention.`;

class HeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.didToastPlanAlerts = false;
    this.didToastStrategyAlerts = [];

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  handleLogoutClick() {
    if (isImpersonating()) {
      this.props.impersonationLogout();
    } else {
      this.props.logout();
    }
  }

  componentDidMount() {
    const {authUser} = this.props;

    // Only retrieve the strategies if UT3
    if (includes(authUser?.roles, ROLE.USER_TYPE_3)) {
      this.props.dispatch(getPlanSelection());
    }

    if (intersection(authUser?.roles, [ROLE.USER_TYPE_1, ROLE.USER_TYPE_2, ROLE.USER_TYPE_3]).length > 0) {
      this.props.dispatch(getUnreadCount());
    }
  }

  componentWillUnmount() {
    clearTimeout(this.notificationToastTimeout);
  }

  render() {
    const {history, strategyId, strategies, unreadCounts} = this.props;
    const authUser = getSessionJwt(true)?.contents;
    let menuProps = publicMenuProps;

    const secondaryHeaderProps = {
      currentStrategyId: strategyId || 0,
      strategies: null,
      isStrategyMenuVisible: false,
    };

    if (includes(authUser?.roles, ROLE.ADMIN)) {
      menuProps = clone(adminMenuProps);
    } else if (intersection(authUser?.roles, [ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]).length > 0) {
      // Show demo account menu if appropriate
      if (isDemoAccount(authUser?.email)) {
        menuProps = clone(demoAccountMenuProps);
      } else {
        menuProps = clone(userType1MenuProps);
      }
    } else if (includes(authUser?.roles, ROLE.USER_TYPE_3)) {
      menuProps = clone(userType3MenuProps);
      if (strategyId || strategyId > 0) {
        secondaryHeaderProps.isStrategyMenuVisible = true;
        secondaryHeaderProps.strategies = strategies;
      }
    } else if (includes(authUser?.roles, ROLE.USER_TYPE_4)) {
      menuProps = clone(userType4MenuProps);
    }

    // Total the alert notifications
    let unreadCount = 0;
    if (includes(authUser?.roles, ROLE.USER_TYPE_3)) {
      unreadCount = get(find(unreadCounts, {strategyId: Number(strategyId)}), 'count', 0);
      if (unreadCount > 0 && !this.didToastStrategyAlerts[strategyId]) {
        this.didToastStrategyAlerts[strategyId] = true;
        this.notificationToastTimeout = setTimeout(() => {
          toast({
            text: getAlertNotificationText(unreadCount),
            faIcon: faBullhorn,
            callback: () => {
              history.push(ROUTE.STRATEGY_ALERTS.path(strategyId));
            },
          });
        }, 3500);
      }
    } else {
      unreadCount = sumBy(unreadCounts, 'count');
      if (unreadCount > 0 && !this.didToastPlanAlerts) {
        this.didToastPlanAlerts = true;
        this.notificationToastTimeout = setTimeout(() => {
          toast({
            text: getAlertNotificationText(unreadCount),
            faIcon: faBullhorn,
            callback: () => {
              history.push(ROUTE.ALERTS.path());
            },
          });
        }, 3500);
      }
    }

    const showNotification = intersection(authUser?.roles, [ROLE.USER_TYPE_1, ROLE.USER_TYPE_2]).length > 0;

    return (
      <HeaderDisplay
        {...menuProps}
        {...secondaryHeaderProps}
        onLogoutClick={this.handleLogoutClick}
        showNotifications={showNotification}
        notificationCount={unreadCount}
      />
    );
  }
}

HeaderComponent.propTypes = {
  logout: PropTypes.func.isRequired,
  authUser: PropTypes.object,
};

/**
 * Retrieves the strategyId param from the url assuming it is in the format /strategy/:strategyId
 * @param {String} pathname The location object's pathname value
 * @returns {Object} Contains a property for strategyId if found on the url, otherwise an empty object
 */
export const getParams = pathname => {
  // Check if the url is the paywall url
  const matchPaywallProfile = matchPath(pathname, {path: '/strategy/:strategyId/:paywall'});
  if (!isEmpty(matchPaywallProfile) && !isEmpty(matchPaywallProfile.params)) {
    if (matchPaywallProfile.params.paywall && matchPaywallProfile.params.paywall.toLowerCase() === 'paywall') {
      return matchPaywallProfile.params;
    }
  }

  const matchProfile = matchPath(pathname, {path: '/strategy/:strategyId'});
  return (matchProfile && matchProfile.params) || {};
};

const formatStrategyData = state => {
  const updatedList = map(get(state, 'strategy.planSelection'), item => {
    const invites = get(item, 'planSponsor.invitations');
    return {
      strategyId: item.id,
      strategyName: item.name,
      licensedDate: item.licensedDate,
      planSponsor: get(item, 'planSponsor.name'),
      portfolioManagementType: item.portfolioManagementType,
      incompleteInviteCode: !isEmpty(invites) ? invites[0].code : null,
    };
  });
  return orderBy(updatedList, ['planSponsor', 'name'], ['asc', 'asc']);
};

const mapStateToProps = state => {
  const unreadCounts = get(state, 'userStrategyAlert.unreadCounts', []) || [];

  const locationData = get(state, 'router.location.pathname');
  if (!locationData) {
    return {unreadCounts};
  }

  const paramList = getParams(locationData);
  if (isEmpty(paramList) || !paramList.strategyId) {
    return {unreadCounts};
  }

  const strategyId = Number(paramList.strategyId);
  const {paywall} = paramList;

  return {
    paywall,
    strategyId: Number.isNaN(strategyId) ? 0 : strategyId,
    strategies: formatStrategyData(state),
    hasError: get(state, 'strategy.hasError'),
    unreadCounts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    impersonationLogout: () => dispatch(removeImpersonationTokensUser()),
    dispatch,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  if (!isEmpty(stateProps.strategies) && stateProps.strategyId) {
    const currentStrategy = find(stateProps.strategies, {strategyId: stateProps.strategyId});

    // if not paywall, check if it shoudl be redirected
    if (!stateProps.paywall && !isEmpty(currentStrategy) && !currentStrategy.licensedDate) {
      dispatchProps.dispatch(push(ROUTE.PAYWALL.path(currentStrategy.strategyId)));
    }
  }

  return Object.assign({}, stateProps, dispatchProps, ownProps);
};

export const Header = compose(
  withRouter,
  withUser,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(HeaderComponent);
