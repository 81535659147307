import React from 'react';
import {setPreventDefault} from 'app/utilities';

const VoidLink = props => (
  <a
    href="#"
    onClick={e => {
      setPreventDefault(e);
      if (props.onClick) {
        return props.onClick();
      }
    }}
    {...props}
  />
);

export {VoidLink};
