import {GraphicHeader} from 'v2/components/molecules/GraphicHeader';
import {Callback, Middleware} from './types';

const Layout: React.FC<{}> = ({children}) => {
  return (
    <>
      <GraphicHeader title="Resource Center" />
      {children}
    </>
  );
};

export const graphicHeaderMiddleware: Middleware = async (next: Callback) => {
  const response = await next();

  return <Layout>{response}</Layout>;
};
