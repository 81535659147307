import {DATA_STATUS} from 'v2/constants/dataStatus';

export const RANKING_TIER = {
  TOP_10: 'Top 10%',
  TOP_20: 'Top 20%',
  TOP_30: 'Top 30%',
  TOP_40: 'Top 40%',
  TOP_50: 'Top 50%',
  INADEQUATE: 'Review Policy',
  NO_VALUE: DATA_STATUS.NONE,
};
