import styled from 'styled-components';
import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';
import {useState, useEffect} from 'react';
import {useChangelog} from 'v2/hooks/useChangelogHooks';
import {useRadioList} from 'v2/hooks/components/useRadioList';
import {Radio} from 'v2/components/molecules/Radio';
const changelogUrl = `${process.env.PUBLIC_URL}/CHANGELOG.md`;

const RadioContainer = styled.div`
  position: absolute;
  padding-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 45vw;
`;

const ChangelogPage = styled.div`
  height: 95%;
`;
const ChangelogContainer = styled.div`
  height: 90%;
`;

export const Changelog = () => {
  const radioLabels = ['Frontend', 'Backend'];
  const radioValues = ['Frontend', 'Backend'];
  const [frontendChangelog, setFrontendChangelog] = useState('');
  const [backendChangelog, setBackendChangelog] = useState('');
  const [isFrontend, setIsFrontend] = useState(true);
  const {selected, handleChange} = useRadioList(radioValues, 0);

  fetch(changelogUrl)
    .then(res => res.text())
    .then(res => setFrontendChangelog(res))
    .catch(err => console.log(err));

  const {data} = useChangelog();

  useEffect(() => {
    setBackendChangelog(data.markdown);
  }, [data.markdown]);

  useEffect(() => {
    if (selected === 'Frontend') {
      setIsFrontend(true);
    }

    if (selected === 'Backend') {
      setIsFrontend(false);
    }
  }, [selected]);

  return (
    <ChangelogPage>
      <RadioContainer>
        <Radio name="type" labels={radioLabels} values={radioValues} onChange={handleChange} value={selected} />
      </RadioContainer>
      <ChangelogContainer>
        <ReactMarkdown>{isFrontend ? frontendChangelog : backendChangelog}</ReactMarkdown>
      </ChangelogContainer>
    </ChangelogPage>
  );
};
