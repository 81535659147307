import {useMemo, useState} from 'react';
import {Link as ReactLink} from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder';
import {TextRow, RectShape} from 'react-placeholder/lib/placeholders';
import {orderBy} from 'lodash';
import {AttachedWrapper, Container, FlexContainer, FloatingWrapper, TableCell} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {Link} from 'v2/components/molecules/Button';
import {DashboardHoldingsDistributionChart} from 'v2/components/organisms/DashboardHoldingsDistributionChart';
import {DashboardHoldingsTable} from 'v2/components/organisms/DashboardHoldingsTable';
import {compareNumbersWithNan, formatMillions, getTransparency} from 'v2/utilities/helpers';
import {HoldingDistributionModal} from 'v2/components/organisms/HoldingDistributionModal';
import {ROUTE} from 'v2/constants/routes';
import {useUT3DashboardHoldingDistribution} from 'v2/hooks/useUT3DashboardHoldingDistribution';
import {useUT3DashboardHoldings} from 'v2/hooks/useUT3DashboardHoldings';
import {PercentileRank} from 'v2/components/molecules/PercentileRank';
import {CheckedBadge} from 'v2/components/molecules/CheckedBadge';

export const UT3DashboardHoldingsPage = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [currentPct, setCurrentPct] = useState<{element: any; data: any}>({element: null, data: null});
  const [distributionView, setDistributionView] = useState<string | number | null>(null);
  const [holdingView, setHoldingView] = useState<string | number | null>(null);

  const {
    data: distributionData,
    // isLoading: distributionIsLoading,
    isSuccess: distributionIsSuccess,
  } = useUT3DashboardHoldingDistribution({});

  const {data: holdingsData, isLoading: holdingsIsLoading, isSuccess: holdingsIsSuccess} = useUT3DashboardHoldings();

  const handleDistributionChange = (value: string | number) => {
    setDistributionView(value);
  };

  const handleHoldingsChange = (value: string | number) => {
    setHoldingView(value);
  };

  const handleSectorClick = ({element, data}: any) => {
    if (data.label === 'Holdings') {
      setCurrentPct({element, data});
      setModalIsVisible(true);
    }
  };

  const handleClose = () => {
    setModalIsVisible(false);
  };

  const companyPath = (id: string) => {
    return `${ROUTE.UT3.COMPANIES.path.replace(':companyId', id)}${ROUTE.UT3.COMPANIES.RISK.search}`;
  };

  const memoizedDistributionDatasets = useMemo(() => {
    if (!distributionIsSuccess) {
      return [
        {
          label: 'Holdings',
          data: [],
        },
        {
          label: 'Benchmark',
          data: [],
        },
      ];
    }

    return [
      {
        label: 'Holdings',
        data: distributionView === 'count' ? distributionData.holdings.count : distributionData.holdings.weight,
      },
      {
        label: 'Benchmark',
        data: distributionView === 'count' ? distributionData.benchmark.count : distributionData.benchmark.weight,
      },
    ];
  }, [distributionData, distributionView, distributionIsSuccess]);

  const memoizedHoldings = useMemo(() => {
    const orderKeys = () => {
      switch (holdingView) {
        case 'esg_leaders':
          return ['pctRank', 'held', 'name'];
        case 'esg_laggards':
          return ['pctRank', 'held', 'name'];
        default:
          return ['held'];
      }
    };

    const orderDirection = () => {
      switch (holdingView) {
        case 'esg_leaders':
          return ['asc', 'desc', 'asc'];
        case 'esg_laggards':
          return ['desc', 'asc', 'desc'];
        default:
          return ['desc'];
      }
    };

    const filteredHoldings = holdingsData.filter((holding: any) => !holding.predicted || holdingView === 'most_held');

    return orderBy(filteredHoldings, orderKeys(), orderDirection() as any);
  }, [holdingsData, holdingView]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const labels = ['100 - 81', '80 - 61', '60 - 41', '40 - 21', '20 - 0'];

  const memoizedCompaniesByTier = useMemo(() => {
    return orderBy(distributionData.aggregatePercentileRankHoldings, holding => Number(holding.held), 'desc').filter(
      holding => {
        const tier = labels[currentPct?.element?.index ?? ''];

        if (tier === '100 - 81') {
          return holding.pctRank >= 0.81;
        } else if (tier === '80 - 61') {
          return holding.pctRank >= 0.61 && holding.pctRank < 0.8;
        } else if (tier === '60 - 41') {
          return holding.pctRank >= 0.41 && holding.pctRank < 0.6;
        } else if (tier === '40 - 21') {
          return holding.pctRank >= 0.21 && holding.pctRank < 0.4;
        } else {
          return holding.pctRank <= 0.21;
        }
      },
    );
  }, [distributionData, labels, currentPct]);

  const memoizedColumns = useMemo(
    () => [
      {
        Header: 'Company Name',
        Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
        style: {
          maxWidth: 'auto',
          width: '20rem',
          minWidth: '20rem',
        },
        accessor: 'companyName',
      },
      {
        Header: 'Engaged',
        style: {
          maxWidth: 'auto',
          width: '9rem',
          minWidth: '9rem',
          justifyContent: 'center',
        },
        Cell: ({value}: {value: boolean}) => <CheckedBadge iconSize={24} isChecked={value} hideLabel />,
        accessor: 'isEngaged',
      },
      {
        Header: 'Transparency',
        style: {
          maxWidth: 'auto',
          width: '9rem',
          minWidth: '9rem',
          justifyContent: 'center',
        },
        Cell: ({value}: {value: number}) => <TableCell>{getTransparency(value)}</TableCell>,
        sortType: compareNumbersWithNan,
        accessor: 'transparency',
      },
      {
        Header: 'Percentile Rank',
        style: {
          maxWidth: 'auto',
          width: '9rem',
          minWidth: '9rem',
          justifyContent: 'center',
        },
        Cell: ({value}: {value: number}) => (
          <TableCell>
            <PercentileRank value={value} />
          </TableCell>
        ),
        sortType: compareNumbersWithNan,
        accessor: 'pctRank',
      },
      {
        Header: 'Held',
        style: {
          maxWidth: 'auto',
          width: '9rem',
          minWidth: '9rem',
          justifyContent: 'center',
        },
        Cell: ({value}: {value: number}) => <TableCell>{formatMillions(value, 1, '$')}</TableCell>,
        accessor: 'held',
      },
      {
        Header: 'Weight',
        style: {
          maxWidth: 'auto',
          width: '8rem',
          minWidth: '8rem',
        },
        Cell: ({value}: {value: number}) => <TableCell>{value}</TableCell>,
        accessor: 'weight',
      },
    ],
    [],
  );

  const memoizedCompanyColumns = useMemo(
    () => [
      {
        Header: 'Companies',
        Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '45%',
          width: '45%',
        },
        accessor: 'company',
      },
      {
        Header: 'Sectors',
        Cell: ({value}: {value: string}) => <TableCell>{value}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '40%',
          width: '40%',
        },
        accessor: 'sector',
      },
      {
        Header: 'Held',
        Cell: ({value}: {value: number}) => <TableCell>{formatMillions(value, 1, '$')}</TableCell>,
        style: {
          maxWidth: 'auto',
          minWidth: '15%',
          width: '15%',
        },
        accessor: 'held',
      },
    ],
    [],
  );

  const DistributionPlaceholder = () => (
    <FlexContainer direction="column" gap="4rem">
      <FlexContainer gap="2rem">
        <RectShape color={Theme.colors.grey_2} style={{height: 60, flex: 1}} />
        <RectShape color={Theme.colors.grey_2} style={{height: 60, width: 300}} />
      </FlexContainer>
      <FlexContainer gap="2rem">
        <RectShape color={Theme.colors.grey_2} style={{height: 400, flex: 1}} />
      </FlexContainer>
    </FlexContainer>
  );

  const HoldingsPlaceholder = () => (
    <FlexContainer direction="column" gap="2rem" padding="3rem">
      <FlexContainer gap="2rem">
        <RectShape color={Theme.colors.grey_2} style={{height: 60, flex: 1}} />
        <RectShape color={Theme.colors.grey_2} style={{height: 60, width: 200}} />
      </FlexContainer>
      <Container>
        <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
        <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
        <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
        <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
        <TextRow color={Theme.colors.grey_2} style={{height: 40, flex: 1}} />
      </Container>
    </FlexContainer>
  );

  const limitedData = useMemo(() => memoizedHoldings.slice(0, 5), [memoizedHoldings]);

  return (
    <>
      <AttachedWrapper height="612px" padding="44px 35px 35px">
        <ReactPlaceholder ready={distributionIsSuccess} customPlaceholder={<DistributionPlaceholder />}>
          <DashboardHoldingsDistributionChart
            title="Holding Distribution"
            subTitle="Distribution of Company Ratings for Holdings"
            labels={labels}
            indexColor={Theme.colors.grey_4}
            colors={[
              Theme.colors.red,
              Theme.colors.orange,
              Theme.colors.yellow,
              Theme.colors.light_green_3,
              Theme.colors.green,
            ]}
            datasets={memoizedDistributionDatasets}
            onSectorClick={handleSectorClick}
            onDistributionsChange={handleDistributionChange}
          />
        </ReactPlaceholder>
      </AttachedWrapper>
      <FloatingWrapper padding="0 0 1.8rem">
        <ReactPlaceholder ready={!holdingsIsLoading && holdingsIsSuccess} customPlaceholder={<HoldingsPlaceholder />}>
          <DashboardHoldingsTable
            title="Holdings"
            data={limitedData}
            columns={memoizedColumns}
            onHoldingsChange={handleHoldingsChange}
            path={companyPath}
          />
        </ReactPlaceholder>
      </FloatingWrapper>
      <Container maxWidth={Theme.constants.fixWidth} margin="0 auto 3rem">
        <Link as={ReactLink} to={ROUTE.UT3.COMPANIES.basePath}>
          Go to Companies
        </Link>
      </Container>
      <HoldingDistributionModal
        isOpen={modalIsVisible}
        setClose={handleClose}
        color={currentPct?.data?.backgroundColor[currentPct?.element?.index]}
        data={memoizedCompaniesByTier}
        columns={memoizedCompanyColumns}
        label={labels[currentPct?.element?.index ?? '']}
        defaultCompanyPath={companyPath}
      />
    </>
  );
};
