import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {isNil, map, merge, orderBy} from 'lodash';
import {reduxForm} from 'redux-form';
import {Doughnut} from 'react-chartjs-2';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import {COLOR, ISSUE_OLD, ISSUE_TYPE, ONBOARDING_STEPS, RATING_OPTIONS, ROUTE} from 'app/constants';
import {formatLabelAsPercent, prepDataForChart} from 'app/utilities';
import {
  SourceViewer,
  Alert,
  BarChart,
  BarChartComparison,
  BarChartLegend,
  BookmarkToggle,
  Button,
  APreventDefault,
  Collapsible,
  DoughnutSelector,
  FormFeedback,
  IssueCard,
  IssueCardWithData,
  IssueProgress,
  LoadingSpinner,
  PercentileRating,
  ProgressIndicator,
  RadioDot,
  RadioDotLegend,
  SideDrawer,
  TooltipLink,
} from 'app/components';
import {toast, toastSuccess, toastWarning, toastError} from 'app/utilities/toast';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {RadioLine} from 'app/components/forms';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import faTrash from 'app/fontawesome-pro-light/faTrash';
import faChevronRight from 'app/fontawesome-pro-light/faChevronRight';
import faChevronLeft from 'app/fontawesome-pro-light/faChevronLeft';
import faChevronDown from 'app/fontawesome-pro-light/faChevronDown';
import faCheck from 'app/fontawesome-pro-light/faCheck';
import faTimes from 'app/fontawesome-pro-light/faTimes';
import faBookmark from 'app/fontawesome-pro-light/faBookmark';

const LOREM_IPSUM = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem justo, venenatis ut aliquam vitae,
rutrum non elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut sit amet magna
placerat, ornare sem at, efficitur urna. Nulla vitae nunc consectetur, mattis libero vitae,
convallis turpis. Pellentesque ac ex ultricies, dignissim massa vitae, ultricies ligula.
Pellentesque vulputate nec risus eu porta. Vivamus id dapibus justo. Donec dapibus lorem eget
viverra placerat. Ut non mattis lorem. Etiam sed nulla sit amet nisl finibus varius. Interdum et
malesuada fames ac ante ipsum primis in faucibus. Integer bibendum felis facilisis, blandit ipsum
elementum, tempor diam. Donec gravida ex eu leo lacinia dictum.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sem justo, venenatis ut aliquam vitae,
rutrum non elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut sit amet magna
placerat, ornare sem at, efficitur urna. Nulla vitae nunc consectetur, mattis libero vitae,
convallis turpis. Pellentesque ac ex ultricies, dignissim massa vitae, ultricies ligula.
Pellentesque vulputate nec risus eu porta. Vivamus id dapibus justo. Donec dapibus lorem eget
viverra placerat. Ut non mattis lorem. Etiam sed nulla sit amet nisl finibus varius. Interdum et
malesuada fames ac ante ipsum primis in faucibus. Integer bibendum felis facilisis, blandit ipsum
elementum, tempor diam. Donec gravida ex eu leo lacinia dictum.
`;

const EXAMPLE_STEPS = orderBy(
  map(ONBOARDING_STEPS.UserType1, (value, key) => ({step: key, ...value, path: value.route.path('invitationCode')})),
  ['stepOrder'],
  ['asc'],
);
EXAMPLE_STEPS[0].isComplete = true;
EXAMPLE_STEPS[1].isComplete = true;
EXAMPLE_STEPS[2].isComplete = true;
EXAMPLE_STEPS[3].isComplete = true;

const EXAMPLE_CHART_DATA = prepDataForChart({
  labels: [
    'Information Tech',
    'Financials',
    'Healthcare',
    'Consumer Staples',
    'Consumer Discretionary',
    'Utilities',
    'Real Estate',
    'Telecom',
    'Industrials',
    'Materials',
    'Energy',
    'Cash',
  ],
  datasets: [{data: [14.9, 13.1, 13, 11.9, 11, 10, 9.5, 6.3, 4.9, 3.2, 2.2, 1.1]}],
});

const EXAMPLE_CHART_OPTIONS = {
  layout: {
    padding: {
      left: 32,
      right: 32,
      top: 32,
      bottom: 32,
    },
  },
  tooltips: {callbacks: {label: formatLabelAsPercent}},
};

const EXAMPLE_BAR_CHART_DATA = [
  {
    label: 'Manager',
    data: [17, 40, 24, 8, 5],
    color: COLOR.BLUE,
  },
  {
    label: 'Holdings',
    data: [15, 34, 29, 12, 7],
  },
  {
    label: 'Russell 1000',
    data: [8, 23, 36, 18, 8],
    color: COLOR.LIGHT_GRAY,
  },
];

const EXAMPLE_BAR_CHART_DATA_2 = [
  {
    label: 'Q1 2016',
    color: COLOR.LIGHT_GRAY,
    data: [0, 23, 36, 18, 8],
  },
  {
    label: 'Q2 2016',
    data: [8, 23, 36, 18, 8],
    color: COLOR.DARK_GREEN,
  },
  {
    label: 'Q3 2016',
    data: [15, 34, 29, 12, 7],
    color: COLOR.BLUE,
  },
  {
    label: 'Q4 2016',
    data: [17, 40, 24, 8, 5],
    color: COLOR.NAVY,
  },
];

const EXAMPLE_PERCENTILE_VALUES = [
  {
    label: 'vs Plan',
    percentile: 52,
  },
  {
    label: 'vs Manager',
    percentile: 23,
  },
  {
    label: 'vs Index Universe',
    percentile: 87,
  },
];

const EXAMPLE_ISSUE_PERIOD_DATA = {
  'Q1 2018': 100,
  'Q2 2018': 98,
  'Q3 2018': 106,
  'Q4 2018': 112,
};

class StyleGuidePage extends Component {
  constructor(props) {
    super(props);

    this.onDoughnutSegmentClick = this.onDoughnutSegmentClick.bind(this);
    this.toggleBarChartAlternate = this.toggleBarChartAlternate.bind(this);
    this.toggleExampleDropdown = this.toggleExampleDropdown.bind(this);

    this.state = {datasetsVisible: [true, true, true, true]};
  }

  toggleBarChartAlternate(index) {
    const {datasetsVisible} = this.state;
    datasetsVisible[index] = !datasetsVisible[index];
    this.setState({datasetsVisible});
  }

  onDoughnutSegmentClick({datasetIndex, dataIndex}) {
    this.setState({
      doughnutDatasetIndex: datasetIndex,
      doughnutDataIndex: dataIndex,
    });
  }

  toggleExampleDropdown() {
    this.setState({isExampleDropdownOpen: !this.state.isExampleDropdownOpen});
  }

  toggleSideDrawer = () => {
    this.sideDrawer.toggle();
  };

  render() {
    const {doughnutDatasetIndex, doughnutDataIndex} = this.state;

    return (
      <div className="p-content-lg flex-grow-1 bg-white">
        <h1>{ROUTE.STYLE_GUIDE.title}</h1>

        <SourceViewer title="Headlines">
          <h1>Harmony Analytics</h1>
          <h2>Inspiring positive action</h2>
          <h3>Plan Overview</h3>
        </SourceViewer>

        <SourceViewer title="Buttons">
          <Button>Log in</Button>
        </SourceViewer>

        <SourceViewer>
          <Button size="lg">Log in</Button>
        </SourceViewer>

        <SourceViewer containerClassName="bg-gray">
          <Button color="white">Log in</Button>
        </SourceViewer>

        <SourceViewer containerClassName="bg-gray">
          <Button color="white" size="lg">
            Log in
          </Button>
        </SourceViewer>

        <SourceViewer title="Buttons with Icons">
          <Button iconBefore={faChevronLeft}>Back</Button> <Button iconAfter={faChevronRight}>Next</Button>
        </SourceViewer>

        <SourceViewer title="Drop Downs">
          <Dropdown isOpen={this.state.isExampleDropdownOpen} toggle={this.toggleExampleDropdown}>
            <DropdownToggle>
              Dropdown <Icon icon={faChevronDown} className="btn-icon-after" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem disabled>Action</DropdownItem>
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </SourceViewer>

        <SourceViewer title="Form Feedback Summary">
          <FormFeedback
            message={
              <span>
                Something has gone <a href="http://google.com">terribly</a> awry.
              </span>
            }
            visible={true}
          />
        </SourceViewer>

        <SourceViewer title="Alerts">
          <Alert>Information can be saved at any time.</Alert>
          <Alert color="danger">
            There was a problem saving{' '}
            <a className="alert-link" href="http://google.com">
              your
            </a>{' '}
            information.
          </Alert>
          <Alert color="warning">
            Your information will be{' '}
            <a className="alert-link" href="http://google.com">
              saved
            </a>{' '}
            within 24 hours.
          </Alert>
          <Alert color="success">
            Your information{' '}
            <a className="alert-link" href="http://google.com">
              has
            </a>{' '}
            been saved.
          </Alert>
        </SourceViewer>

        <SourceViewer title="Radio Dots" description="A generic class for use in other components">
          <div className="d-flex">
            <RadioDot />
            <RadioDot color="blue" />
            <RadioDot color="red" />
            <RadioDot color="green" />
            <RadioDot color="light" />
          </div>
        </SourceViewer>

        <SourceViewer
          title="Floating Radio Dots"
          description="Can be positioned anywhere in a container that has relative, absolute, or fixed positioning."
        >
          <div className="position-relative py-12">
            <RadioDot floating style={{top: '50%', left: '50%'}} />
          </div>
        </SourceViewer>

        <SourceViewer title="Radio Lines" showHtmlOptions={false}>
          <RadioLine name="exampleRadioLine" options={RATING_OPTIONS} />
        </SourceViewer>

        <SourceViewer title="Bookmark Toggles">
          <BookmarkToggle />
          <br />
          <BookmarkToggle isActive={true} />
        </SourceViewer>

        <h2>Progress Indicators</h2>
        <SourceViewer>
          <ProgressIndicator
            steps={EXAMPLE_STEPS}
            currentStep={EXAMPLE_STEPS[4]}
            nextAvailableStep={EXAMPLE_STEPS[4]}
          />
        </SourceViewer>

        <SourceViewer title="Percentile Ratings" description="Accepts a series of values and labels.">
          <PercentileRating values={EXAMPLE_PERCENTILE_VALUES} />
        </SourceViewer>

        <SourceViewer title="Radio Dot Legends" containerClassName="bg-gray text-white">
          <RadioDotLegend />
        </SourceViewer>

        <SourceViewer
          title="Issue Cards"
          description="Can be one-sided or flippable. Cards become 5% smaller on tablet views."
        >
          <IssueCard>
            <Fragment key="front">
              <div className="issue-card-header">Header</div>
            </Fragment>

            <Fragment key="back">
              <div className="issue-card-header">Header</div>
              <div className="issue-card-description flex-fill">
                <strong>Description</strong>
              </div>
              <div className="issue-card-footer">Footer</div>
            </Fragment>
          </IssueCard>
        </SourceViewer>

        <SourceViewer
          title="Issue Card Grids"
          description="White space and overflow should be controlled by the
            containing element, to preserve 3d effects."
        >
          <div className="issue-card-grid">
            <IssueCard>
              <Fragment key="front" />
              <Fragment key="back" />
            </IssueCard>
            <IssueCard>
              <Fragment key="front" />
              <Fragment key="back" />
            </IssueCard>
            <IssueCard>
              <Fragment key="front" />
              <Fragment key="back" />
            </IssueCard>
            <IssueCard>
              <Fragment key="front" />
              <Fragment key="back" />
            </IssueCard>
            <IssueCard>
              <Fragment key="front" />
              <Fragment key="back" />
            </IssueCard>
            <IssueCard>
              <Fragment key="front" />
              <Fragment key="back" />
            </IssueCard>
          </div>
        </SourceViewer>

        <SourceViewer title="Issue Cards with Data">
          <div className="issue-card-grid">
            <IssueCardWithData
              iconic
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.BOOLEAN,
                data: [
                  {
                    yesCount: 1,
                    noCount: 0,
                  },
                  {
                    label: 'Holdings',
                    yesCount: 80,
                    noCount: 50,
                  },
                  {
                    label: 'Index Universe',
                    yesCount: 50,
                    noCount: 80,
                  },
                ],
              }}
            />
            <IssueCardWithData
              iconic
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.BOOLEAN,
                data: [
                  {
                    yesCount: 0,
                    noCount: 1,
                  },
                  {
                    label: 'Reference Index',
                    yesCount: 50,
                    noCount: 80,
                  },
                ],
              }}
            />
            <IssueCardWithData
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.BOOLEAN,
                data: [
                  {
                    label: 'Holdings',
                    yesCount: 80,
                    noCount: 50,
                  },
                  {
                    label: 'Index Universe',
                    yesCount: 50,
                    noCount: 80,
                  },
                ],
                unit: 'Companies',
              }}
            />
            <IssueCardWithData
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.BOOLEAN,
                data: [
                  {
                    label: 'Holdings',
                    yesCount: 50,
                    noCount: 80,
                  },
                  {
                    label: 'Index Universe',
                    yesCount: 80,
                    noCount: 50,
                  },
                ],
                unit: 'Companies',
              }}
            />
            <IssueCardWithData
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.BOOLEAN,
                data: [
                  {
                    yesCount: 100,
                    noCount: 30,
                  },
                  {
                    label: 'Holdings',
                    yesCount: 80,
                    noCount: 50,
                  },
                  {
                    label: 'Index Universe',
                    yesCount: 50,
                    noCount: 80,
                  },
                ],
                unit: 'Companies',
              }}
            />
            <IssueCardWithData
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.BOOLEAN,
                data: [
                  {
                    yesCount: 30,
                    noCount: 100,
                  },
                  {
                    label: 'Holdings',
                    yesCount: 80,
                    noCount: 50,
                  },
                  {
                    label: 'Index Universe',
                    yesCount: 50,
                    noCount: 80,
                  },
                ],
                unit: 'Companies',
              }}
            />
            <IssueCardWithData
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.PERCENTILE,
                data: [
                  {
                    label: 'vs Reference Index',
                    percentile: 89,
                  },
                ],
              }}
            />
            <IssueCardWithData
              issue={{
                code: 'E1',
                name: 'Issue Name',
                description: 'Issue description',
                type: ISSUE_TYPE.PERCENTILE,
                data: [
                  {
                    label: 'vs Plan',
                    percentile: 52,
                  },
                  {
                    label: 'vs Index Universe',
                    percentile: 11,
                  },
                ],
              }}
            />
          </div>
        </SourceViewer>

        <SourceViewer title="Issue Cards: Tall">
          <div className="issue-card-grid">
            <IssueCard size="tall">
              <Fragment key="front">
                <div className="issue-card-header">Header</div>
              </Fragment>

              <Fragment key="back">
                <div className="issue-card-header">Header</div>
                <div className="issue-card-description flex-fill">
                  <strong>Description</strong>
                </div>
                <div className="issue-card-footer">Footer</div>
              </Fragment>
            </IssueCard>
          </div>
        </SourceViewer>

        <SourceViewer title="Issue Cards: Tall, with Issue Progress">
          <div className="issue-card-grid">
            <IssueCard size="tall">
              <Fragment key="front">
                <div className="issue-card-header">Header</div>
                <div className="issue-card-description flex-fill">
                  <IssueProgress issue={ISSUE_OLD.E1} className="pt-4" periodData={EXAMPLE_ISSUE_PERIOD_DATA} />
                </div>
              </Fragment>

              <Fragment key="back">
                <div className="issue-card-header">Header</div>
                <div className="issue-card-description flex-fill">
                  <strong>Description</strong>
                </div>
                <div className="issue-card-footer">Footer</div>
              </Fragment>
            </IssueCard>
          </div>
        </SourceViewer>

        <SourceViewer
          title="Issue Cards: Tall, with Activator"
          description="Note, at this time only tall issue cards can comfortably accommodate an activator."
        >
          <div className="issue-card-grid">
            <IssueCard size="tall">
              <Fragment key="front">
                <div className="issue-card-header">Header</div>
              </Fragment>

              <Fragment key="back">
                <div className="issue-card-header">Header</div>
                <div className="issue-card-description flex-fill">
                  <strong>Description</strong>
                </div>
                <div className="issue-card-footer">Footer</div>
              </Fragment>

              <Fragment key="activator">
                <Button solid className="w-100">
                  Include in Report
                </Button>
              </Fragment>
            </IssueCard>
            <IssueCard size="tall">
              <Fragment key="front">
                <div className="issue-card-header">Header</div>
              </Fragment>

              <Fragment key="back">
                <div className="issue-card-header">Header</div>
                <div className="issue-card-description flex-fill">
                  <strong>Description</strong>
                </div>
                <div className="issue-card-footer">Footer</div>
              </Fragment>

              <Fragment key="activator">
                <div className="issue-card-activator-status">
                  <span className="issue-card-activator-label">
                    <Icon icon={faCheck} className="mr-2" />
                    Included in Report
                  </span>
                  <APreventDefault className="issue-card-activator-button" onClick={() => false}>
                    <Icon icon={faTimes} className="mr-2" />
                    Remove
                  </APreventDefault>
                </div>
              </Fragment>
            </IssueCard>
          </div>
        </SourceViewer>

        <SourceViewer title="Multi Uploader" description="Markup only demonstrated here.">
          <ul className="file-list mb-4">
            <li className="file-list-item">
              <span className="file-list-item-name">Q4.2017 Portfolio.csv</span>
              <span className="file-list-item-meta">07/01/2017 - 09/30/2017</span>
              <button type="button" className="btn btn-outline btn-red">
                Remove
              </button>
            </li>
            <li className="file-list-item">
              <span className="file-list-item-name">Copy of November 2018 Asset Mix.csv</span>
              <span className="file-list-item-meta">11/01/2018 - 11/30/2018</span>
              <button type="button" className="btn btn-outline btn-red">
                Remove
              </button>
            </li>
          </ul>
          <div className="btn btn-outline position-relative cursor-pointer mb-4">Browse</div>
        </SourceViewer>

        <SourceViewer title="Tables">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
                <th scope="col">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td className="w-2r text-center">
                  <APreventDefault onClick={() => false}>
                    <Icon icon={faPencil} />
                  </APreventDefault>
                </td>
                <td className="w-2r text-center">
                  <APreventDefault className="text-danger" onClick={() => false}>
                    <Icon icon={faTrash} />
                  </APreventDefault>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td className="w-2r text-center">
                  <APreventDefault onClick={() => false}>
                    <Icon icon={faPencil} />
                  </APreventDefault>
                </td>
                <td className="w-2r text-center">
                  <APreventDefault className="text-danger" onClick={() => false}>
                    <Icon icon={faTrash} />
                  </APreventDefault>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
                <td className="w-2r text-center">
                  <APreventDefault onClick={() => false}>
                    <Icon icon={faPencil} />
                  </APreventDefault>
                </td>
                <td className="w-2r text-center">
                  <APreventDefault className="text-danger" onClick={() => false}>
                    <Icon icon={faTrash} />
                  </APreventDefault>
                </td>
              </tr>
            </tbody>
          </table>
        </SourceViewer>

        <SourceViewer title="Pagination Controls">
          <ul className="pagination">
            <li className="page-item disabled">
              <a className="page-link" href="#" title="previous page">
                <Icon icon={faChevronLeft} />
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" title="next page">
                <Icon icon={faChevronRight} />
              </a>
            </li>
          </ul>
        </SourceViewer>

        <SourceViewer title="Bar Charts">
          <BarChart datasets={[EXAMPLE_BAR_CHART_DATA[1]]} className="mt-6" />
        </SourceViewer>

        <SourceViewer
          title="Bar Charts: Custom labels"
          description="Charts without explicit labels will display small bar values instead."
        >
          <BarChart datasets={[EXAMPLE_BAR_CHART_DATA[1]]} className="mt-6" labels={null} />
        </SourceViewer>

        <SourceViewer title="Bar Charts: Multiple Datasets" showHtmlOptions={false}>
          <BarChart datasets={EXAMPLE_BAR_CHART_DATA} className="mt-12" />
        </SourceViewer>

        <SourceViewer title="Bar Charts: No Overlap" showHtmlOptions={false}>
          <BarChart hasOverlap={false} datasets={EXAMPLE_BAR_CHART_DATA} className="mt-12" />
        </SourceViewer>

        <SourceViewer title="Bar Chart Legend" description="Optionally placed below a Bar Chart.">
          <BarChartLegend datasets={EXAMPLE_BAR_CHART_DATA} />
        </SourceViewer>

        <SourceViewer title="Comparison Bar Charts" showHtmlOptions={false}>
          <BarChartComparison className="pt-12" datasets={EXAMPLE_BAR_CHART_DATA} />
        </SourceViewer>

        <SourceViewer
          title="Comparison Bar Charts: Combined Customizations"
          showHtmlOptions={false}
          description="This example has custom labels, no overlap, custom colors,
            percent-change tooltips,and a maximum 5 data sets."
        >
          <BarChart
            hasOverlap={false}
            datasets={EXAMPLE_BAR_CHART_DATA_2}
            className="mt-12"
            labels={null}
            tooltipMode="change"
          />
          <BarChartLegend datasetsVisible={this.state.datasetsVisible} datasets={EXAMPLE_BAR_CHART_DATA_2} />
        </SourceViewer>

        <SourceViewer title="Doughnut Charts" showHtmlOptions={false}>
          <DoughnutSelector
            datasetIndex={doughnutDatasetIndex}
            dataIndex={doughnutDataIndex}
            onSegmentClick={this.onDoughnutSegmentClick}
          >
            {({props, options}) => (
              <Doughnut {...props} data={EXAMPLE_CHART_DATA} options={merge({}, EXAMPLE_CHART_OPTIONS, options)} />
            )}
          </DoughnutSelector>
          <h4 className="text-center my-6">
            Selected: {!isNil(doughnutDataIndex) ? EXAMPLE_CHART_DATA.labels[doughnutDataIndex] : 'None'}
          </h4>
        </SourceViewer>

        <SourceViewer
          title="Loading Spinners"
          containerClassName="bg-almost-white position-relative min-h-8r"
          description="Must be placed inside an absolute, fixed, or relative container."
        >
          <LoadingSpinner isActive />
        </SourceViewer>

        <h2>Tooltip Links</h2>
        <TooltipLink>Example tooltip content.</TooltipLink>
        <hr className="my-12" />

        <SourceViewer
          title="Collapsible"
          showHtmlOptions={false}
          description="A versatile React wrapper utility component that has no styling by default."
        >
          <Collapsible collapsedContainerHeight={144}>
            {({container, content, isOpen, isUseless, toggle}) => (
              <Fragment>
                <div className="transition" {...container}>
                  <div {...content}>{LOREM_IPSUM}</div>
                </div>
                {isUseless || (
                  <a href="#" onClick={toggle}>
                    {isOpen ? 'Read Less' : 'Read More'}
                  </a>
                )}
              </Fragment>
            )}
          </Collapsible>
        </SourceViewer>

        <h2>Side Drawers</h2>
        <p>Side drawers can be positioned on the right or left. Overflow content will scroll.</p>
        <SideDrawer
          title="Side Drawer Title"
          titleIcon={faBookmark}
          exposeHandlers={e => {
            this.sideDrawer = e;
          }}
        >
          <p>{LOREM_IPSUM}</p>
        </SideDrawer>
        <Button onClick={this.toggleSideDrawer}>Toggle Side Drawer</Button>
        <hr className="my-12" />

        <h2>Modals</h2>
        <Button className="mr-4" onClick={this.props.openExampleModal}>
          Open Example Modal
        </Button>
        <hr className="my-12" />

        <h2>Toast Notifications</h2>
        <Button className="mr-4" onClick={() => toast('This is an informative message.')}>
          Toast
        </Button>
        <Button className="mr-4" onClick={() => toastSuccess('An action was performed successfully.')}>
          Success Toast
        </Button>
        <Button className="mr-4" onClick={() => toastWarning('Proceed with caution.')}>
          Warning Toast
        </Button>
        <Button className="mr-4" onClick={() => toastError('There was a problem performing the action.')}>
          Error Toast
        </Button>
        <hr className="my-12" />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openExampleModal: () =>
      dispatch(
        openSimpleModal({
          title: 'Modal title',
          message: `Modal body text goes here. The modal can support string,
        HTML or jSX content, plus a custom set of buttons.`,
          buttons: [
            {
              label: 'Cancel',
              close: true,
            },
            {
              className: 'ml-auto',
              label: 'Visit Dashboard',
              path: ROUTE.DASHBOARD.path(),
              close: true,
            },
          ],
        }),
      ),
  };
};

export const StyleGuide = compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'style-guide',
    initialValues: {exampleRadioLine: 'C'},
  }),
)(StyleGuidePage);
