import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {get, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError} from 'redux-form';

// Local Imports
import {AdminAddUserForm} from 'app/components/AdminAddUserForm';
import {Alert} from 'app/components';
import {FORM_MESSAGE, ROUTE, PERMISSIONS} from 'app/constants';
import {fetchOrganizationTypesIfNeeded} from 'app/redux/organizationType';
import {fetchPlanSponsors, fetchPlanSponsorsIfNeeded} from 'app/redux/planSponsor';
import {updateUser, getByIdUser} from 'app/redux/user';
import {toastSuccess} from 'app/utilities/toast';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';

const formName = 'admin-edit-user';

/**
 * Formats the user data, retrieved from the API, into the format needed by the edit form
 * @param {Object} payload - The user data returned from the API
 * @returns {Object} An object formatted for the admin user form
 */
function formatUserData(payload) {
  // adjust data for names in form
  const userData = {
    email: payload.email,
    confirmEmail: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    prefix: payload.prefix,
    suffix: payload.suffix,
    hasInvitationBeenSent: payload.hasInvitationBeenSent,
    editMode: true,
  };
  if (payload.roles && payload.roles.length > 0) {
    [userData.userType] = payload.roles;
  }

  if (payload.planSponsor) {
    userData.internalPlanSponsorName = payload.planSponsor.internalName;
    userData.organizationType = payload.planSponsor.organizationTypeId;
  }

  return userData;
}

class AdminEditUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {editUser: null};

    const {
      match: {
        params: {userId},
      },
    } = props;

    props.dispatch(fetchPlanSponsorsIfNeeded({areUserCountsIncluded: true}));
    props.dispatch(fetchOrganizationTypesIfNeeded());
    // pass in admin so it knows to setup response for admin edit
    props
      .dispatch(getByIdUser(userId, 'admin'))
      .then(response => {
        if (response.hasError) {
          throw response.error;
        }

        this.setState({editUser: formatUserData(response.payload)});

        animateScroll.scrollToTop();
      })
      .catch(() => {
        animateScroll.scrollToTop();
      });
  }

  render() {
    const {
      match: {
        params: {userId},
      },
    } = this.props;
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 800}}>
          <Link
            className="d-inline-block mb-4 small"
            to={{
              pathname: ROUTE.ADMIN_USERS.path(),
              search: this.props.location.search,
            }}
          >
            <Icon icon={faArrowLeft} className="mr-2" />
            Return to {ROUTE.ADMIN_USERS.title}
          </Link>
          <h1 className="mb-2">{userId ? ROUTE.ADMIN_USERS_EDIT.title : ROUTE.ADMIN_USERS_ADD.title}</h1>
          {this.props.hasError ? (
            <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>
          ) : (
            this.state.editUser && (
              <AdminAddUserForm
                {...this.props}
                formName={formName}
                userData={this.state.editUser}
                canWrite={this.props.canWrite}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

AdminEditUserPage.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const organizationTypes = get(state, 'organizationType.organizationTypes', []);
  const hasError =
    get(state, 'organizationType.hasError') || get(state, 'planSponsor.hasError') || get(state, 'user.hasGetUserError');

  return {
    hasError,
    planSponsors: get(state, 'planSponsor.planSponsors'),
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_USERS),
    organizationTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: {userId},
    },
  } = ownProps;
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      return dispatch(updateUser(userId, values))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          if (values.shouldSendInvite) {
            toastSuccess('The user has been updated and the invite has been sent.');
          } else {
            toastSuccess('The user has been updated, however the invite still needs to be sent.');
          }

          animateScroll.scrollToTop();

          dispatch(fetchPlanSponsors({areUserCountsIncluded: true, isFetchSilent: true}));
          dispatch(reset(formName));

          dispatch(
            push({
              pathname: ROUTE.ADMIN_USERS.path(),
              search: ownProps.location.search,
            }),
          );
        })
        .catch(error => {
          animateScroll.scrollToTop();
          if (error.message.trim() === 'email must be unique') {
            throw new SubmissionError({
              _error: FORM_MESSAGE.DEFAULT_ERROR_SUMMARY_MESSAGE,
              email: FORM_MESSAGE.EMAIL_EXISTS_ERROR_MESSAGE,
            });
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const AdminEditUser = connect(mapStateToProps, mapDispatchToProps)(AdminEditUserPage);
