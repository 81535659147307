import {find, get, isEmpty, filter} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {AsyncTypeahead, Typeahead} from 'react-bootstrap-typeahead';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm} from 'redux-form';
import cn from 'classnames';

// Local Imports
import {
  AUTOCOMPLETE,
  CHARACTER_LIMIT,
  MANAGER_TYPES,
  PORTFOLIO_MANAGEMENT_TYPES,
  REGEX_PATTERN,
  UPLOAD_FREQUENCIES,
} from 'app/constants';
import {Button, FormFeedback} from 'app/components';
import {FormGroup, SelectFormGroup, TextFormGroup} from 'app/components/forms';
import {normalizeEmail, validateFieldArray, withViewportSize} from 'app/utilities';
import {fetchJSON} from 'app/services/http';

const BATCH_SIZE = 3;
const INVESTMENT_MANAGER_INITIAL_VALUES = {
  type: MANAGER_TYPES[0],
  uploadFrequency: UPLOAD_FREQUENCIES[0],
  portfolioManagementType: PORTFOLIO_MANAGEMENT_TYPES[0],
};

const validate = values => {
  const errors = {};

  const investmentManagersToInviteErrors = validateFieldArray(
    values.investmentManagersToInvite,
    (manager, ownErrors) => {
      if (manager.email || manager.marketIndex || manager.strategy || !isEmpty(manager.firm)) {
        if (!manager.email) ownErrors.email = 'Required';
        if (!manager.strategy) ownErrors.strategy = 'Required';
        if (!manager.marketIndex) ownErrors.marketIndex = 'Required';
        if (isEmpty(manager.firm)) ownErrors.firm = 'Required';
      }

      if (!ownErrors.email && manager.email) {
        if (!REGEX_PATTERN.VALID_EMAIL.test(manager.email)) {
          ownErrors.email = 'Invalid email address';
        }
      }
    },
  );
  investmentManagersToInviteErrors && (errors.investmentManagersToInvite = investmentManagersToInviteErrors);

  return errors;
};

class AsyncTypeaheadFormGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: props.input.value,
    };
  }

  render() {
    return (
      <FormGroup {...this.props} style={this.props.style} typeahead isAsync labelKey="name">
        {(inputProps, wrapperProps) => (
          <AsyncTypeahead
            {...wrapperProps}
            ref={typeahead => {
              this.asyncTypeahead = typeahead;
            }}
            allowNew={false}
            clearButton={true}
            isLoading={this.state.isLoading}
            inputProps={inputProps}
            onBlur={event => {
              const {
                props: {options},
                clear,
              } = this.asyncTypeahead;
              const validOption = find(options, {name: event.target.value});
              if (validOption) {
                this.props.input.onChange([validOption]);
              } else {
                clear();
              }
            }}
            onSearch={query => {
              this.setState({isLoading: true});
              fetchJSON(`firm?q=${query}`, {method: 'get'}).then(response => {
                this.setState({
                  isLoading: false,
                  options: response,
                });
              });
            }}
            options={this.state.options || []}
            placeholder="Type and select..."
          />
        )}
      </FormGroup>
    );
  }
}

const renderInvestmentManagersToInvite = ({fields: investmentManagersToInvite, marketIndexes, isLayoutSmall}) => {
  const standardMarketIndexes = !isEmpty(marketIndexes) ? filter(marketIndexes, {isHarmonyIndex: false}) : [];
  const formFieldClassName = 'min-h-0 mb-3';
  const formFieldClassNameLg = cn(formFieldClassName, isLayoutSmall ? 'col-2' : 'col-1');
  const formFieldClassNameMd = cn(formFieldClassName, isLayoutSmall ? 'col-2' : 'col-1');
  const formFieldClassNameSm = cn(formFieldClassName, isLayoutSmall ? 'col-2' : 'w-12r flex-fixed');
  const hrClassName = cn('my-6 w-100', isLayoutSmall ? 'col-6' : 'col-3');

  return (
    <Fragment>
      {investmentManagersToInvite.map((investmentManagerToInvite, index) => {
        const isFirst = index === 0;
        const doShowLabel = true;
        return (
          <div className="form-grid" key={index}>
            {isLayoutSmall && !isFirst && <hr className={hrClassName} />}
            <Field
              label={doShowLabel ? 'Manager Email*' : null}
              name={`${investmentManagerToInvite}.email`}
              type="text"
              component={TextFormGroup}
              normalize={normalizeEmail}
              className={formFieldClassNameLg}
              autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              maxLength={CHARACTER_LIMIT.EMAIL}
            />
            <Field
              label={doShowLabel ? 'Investment Strategy*' : null}
              name={`${investmentManagerToInvite}.strategy`}
              type="text"
              component={TextFormGroup}
              className={formFieldClassNameLg}
              maxLength={CHARACTER_LIMIT.INVESTMENT_STRATEGY}
            />
            <Field
              label={doShowLabel ? 'Investment Firm*' : null}
              name={`${investmentManagerToInvite}.firm`}
              className={formFieldClassNameMd}
              component={AsyncTypeaheadFormGroup}
            />
            <Field
              label={doShowLabel ? 'Manager Type*' : null}
              name={`${investmentManagerToInvite}.type`}
              component={SelectFormGroup}
              options={MANAGER_TYPES}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Index*' : null}
              name={`${investmentManagerToInvite}.marketIndex`}
              component={props => (
                <FormGroup {...props} typeahead options={standardMarketIndexes}>
                  {(inputProps, wrapperProps) => (
                    <Typeahead
                      {...wrapperProps}
                      placeholder="Select a Reference Index"
                      inputProps={inputProps}
                      labelKey="name"
                      id={index}
                    />
                  )}
                </FormGroup>
              )}
              className={formFieldClassNameLg}
            />
            <Field
              label={doShowLabel ? 'Upload Frequency*' : null}
              name={`${investmentManagerToInvite}.uploadFrequency`}
              component={SelectFormGroup}
              options={UPLOAD_FREQUENCIES}
              className={formFieldClassNameSm}
            />
            <Field
              label={doShowLabel ? 'Investment Approach*' : null}
              name={`${investmentManagerToInvite}.portfolioManagementType`}
              component={SelectFormGroup}
              options={PORTFOLIO_MANAGEMENT_TYPES}
              className={formFieldClassNameSm}
            />
          </div>
        );
      })}

      <Button
        className="mt-6"
        onClick={() => {
          for (let i = 0; i < BATCH_SIZE; i += 1) {
            investmentManagersToInvite.push({...INVESTMENT_MANAGER_INITIAL_VALUES});
          }
        }}
      >
        Add Additional Managers
      </Button>
    </Fragment>
  );
};

class OnboardingInvestmentManagersFormComponent extends Component {
  render() {
    const {error, handleSubmit, invalid, submitFailed, viewportWidth} = this.props;

    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!submitFailed && invalid);

    const isLayoutSmall = viewportWidth < 1600;

    return (
      <form method="POST" onSubmit={handleSubmit}>
        <FormFeedback message={error} visible={isErrorFormFeedbackVisible} />

        <FieldArray
          name="investmentManagersToInvite"
          component={renderInvestmentManagersToInvite}
          marketIndexes={this.props.marketIndexes}
          isLayoutSmall={isLayoutSmall}
        />

        <div className="form-footer is-right mt-12">
          <Button type="submit" solid>
            Save &amp; Continue
          </Button>
        </div>
      </form>
    );
  }
}

OnboardingInvestmentManagersFormComponent.propTypes = {
  onboardingInvitation: PropTypes.object,
  onboardingPlanSponsor: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingInvitation = {}, onboardingPlanSponsor = {}} = ownProps;

  // Modify default upload frequency if the plan sponsor has specified one
  const portfolioUploadFrequency = get(onboardingPlanSponsor, 'portfolioUploadFrequency');
  if (portfolioUploadFrequency) {
    INVESTMENT_MANAGER_INITIAL_VALUES.uploadFrequency = portfolioUploadFrequency;
  }

  let managers = get(onboardingInvitation, 'investmentManagersToInvite');

  // Make sure it's an array
  if (!managers || !managers.length) managers = [];

  // Fill to next BATCH_SIZE with empty rows
  const numberOfRows = Math.max(Math.ceil(managers.length / BATCH_SIZE) * BATCH_SIZE, BATCH_SIZE);
  for (let i = managers.length; i < numberOfRows; i += 1) {
    managers.push({...INVESTMENT_MANAGER_INITIAL_VALUES});
  }

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    initialValues: {investmentManagersToInvite: managers},
  };
};

export const OnboardingInvestmentManagersForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'onboarding-investment-managers',
    validate,
  }),
  withViewportSize,
)(OnboardingInvestmentManagersFormComponent);
