import {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {Theme} from '../atoms/theme';
import {Batao} from '../atoms/Typeface';

const ComponentContainer = styled.button`
  display: flex;
  align-items: center;
  height: 3.75rem;
  background-color: transparent;
  border: none;
  position: relative;
  &:focus {
    outline: none;
  }
`;

const ContentContainer = styled.div`
  display: ${props => props.isOpen};
  flex-direction: column;
  justify-content: flex-end;
  width: 13.3125rem;
  position: absolute;
  top: 3.75rem;
  right: ${props => props.align};
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
  box-shadow: ${props => props.theme.shadows.shadow_1};
  & > a + a {
    border-top: 1px solid ${props => props.theme.colors.grey_6};
  }
`;

const LinkBox = styled.a`
  &&& {
    height: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    &:hover {
      background: ${props => props.theme.colors.blue + '1A'};
    }
  }
`;

export const Menu = ({buttonLabel, options = [], alignment = 'left', title = 'Options', inverse = false}) => {
  const [isOpen, setIsOpen] = useState(false);
  const alignmentToCSS = {
    left: '0rem',
    center: 'calc( 50% -  6.6563rem)',
    right: 'calc( 100% - 13.3125rem)',
  };
  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  const onClickOutside = () => {
    setIsOpen(false);
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
  return (
    <ComponentContainer onClick={handleClick} title={title ?? 'Options'} ref={ref}>
      {typeof buttonLabel === 'string' ? (
        <Batao color={inverse ? Theme.colors.harmony : Theme.colors.white}>{buttonLabel}</Batao>
      ) : (
        buttonLabel
      )}
      <ContentContainer isOpen={isOpen ? 'flex' : 'none'} align={alignmentToCSS[alignment]}>
        {options.map((option, i) => {
          return option.onClick ? (
            <LinkBox key={i} onClick={option.onClick}>
              <Batao>{option.label}</Batao>
            </LinkBox>
          ) : (
            <LinkBox as={Link} to={option.path} key={i}>
              <Batao>{option.label}</Batao>
            </LinkBox>
          );
        })}
      </ContentContainer>
    </ComponentContainer>
  );
};
