import {useEffect} from 'react';
import {Element as ScrollElement} from 'react-scroll';
import {Field} from 'react-final-form';
import {ADDRESS_TYPES, AUTOCOMPLETE, CHARACTER_LIMIT, STATES} from 'app/constants';
import {normalizeZip} from 'app/utilities';
import {HR} from 'v2/components/atoms/Typeface';
import {TextFormGroup} from 'v2/components/molecules/TextInput';
import {SelectFormGroup} from 'v2/components/molecules/DropDown';
import {SecondaryIconButton} from 'v2/components/molecules/Button';
import {Flex1, Flex2, Flex3, FormGridRow, RowsWrapper} from '../../pages/UT3/Onboarding/FormStyles';
import {IProfileFormProps} from '../../pages/UT3/Onboarding/UT3OnboardingProfile';

export const AddressesSection = ({fields: addresses}: any) => {
  useEffect(() => {
    if (addresses?.value && addresses.length === 0) {
      addresses.push({});
    }
  }, [addresses]);

  return (
    <RowsWrapper>
      {addresses.map((address: IProfileFormProps, index: number) => {
        const isFirst = index === 0;
        const isLast = index === addresses.length - 1;
        return (
          <RowsWrapper key={index}>
            <FormGridRow>
              <Flex1>
                <Field
                  label="Address Name"
                  name={`${address}.name`}
                  type="text"
                  placeholder={'Ex: "Headquarters"'}
                  component={TextFormGroup}
                  autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                />
              </Flex1>
              <Flex1 style={{maxWidth: 180}}>
                <Field
                  label="Address Type"
                  name={`${address}.type`}
                  component={SelectFormGroup}
                  options={ADDRESS_TYPES}
                  shouldInsertDefault={true}
                />
              </Flex1>
            </FormGridRow>
            <Flex1>
              <Field
                label="Street Address"
                name={`${address}.line1`}
                type="text"
                component={TextFormGroup}
                autoComplete={isFirst ? AUTOCOMPLETE.ADDRESS_LINE_1 : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              />
            </Flex1>
            <Flex1>
              <Field
                label="Address Complement"
                name={`${address}.line2`}
                type="text"
                component={TextFormGroup}
                autoComplete={isFirst ? AUTOCOMPLETE.ADDRESS_LINE_2 : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              />
            </Flex1>
            <FormGridRow>
              <Flex3 style={{minWidth: 300}}>
                <Field
                  label="City"
                  name={`${address}.city`}
                  type="text"
                  component={TextFormGroup}
                  autoComplete={isFirst ? AUTOCOMPLETE.CITY : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                  maxLength={CHARACTER_LIMIT.CITY}
                  className="col-2"
                />
              </Flex3>
              <Flex2 style={{minWidth: 240}}>
                <Field
                  label="State/US Territory"
                  name={`${address}.state`}
                  component={SelectFormGroup}
                  autoComplete={isFirst ? AUTOCOMPLETE.STATE : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                  options={STATES}
                  shouldInsertDefault={true}
                  className="col-2"
                />
              </Flex2>
              <Flex3 style={{maxWidth: 120, width: 120}}>
                <Field
                  label="Zip"
                  name={`${address}.zip`}
                  type="text"
                  component={TextFormGroup}
                  autoComplete={isFirst ? AUTOCOMPLETE.ZIP : AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                  parse={normalizeZip}
                  className="col-2"
                />
              </Flex3>
            </FormGridRow>
            {isLast && <ScrollElement name="lastAddress" />}
            {addresses.length > 1 && (
              <Flex1 style={{justifyContent: 'flex-end'}}>
                <SecondaryIconButton
                  onClick={() => addresses.remove(index)}
                  text="Remove Address"
                  iconName="Cross"
                  size="17"
                  type="button"
                />
              </Flex1>
            )}
            {!isLast && <HR />}
          </RowsWrapper>
        );
      })}
    </RowsWrapper>
  );
};
