import styled from 'styled-components/macro';
import {Title8, Rubrica, Overline, Caption4, Body1} from '../atoms/Typeface';
import {IssueSummaryChartProps} from '../interfaces/IssueProps';
import {ChartJSBarChart} from '../molecules/ChartJSBarChart';

const Container = styled.div`
  height: auto;
  padding-bottom: 27px;
  width: 335px;
  border-radius: ${props => props.theme.constants.border_radius};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey_10};
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  min-height: 110px;
  padding-bottom: 20px;
  width: 335px;
  border-bottom: 1px solid ${props => props.theme.colors.grey_10};
`;
const Title = styled(Title8)`
  display: block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.dark};
  margin: 0 10px 0 0;
`;

const SubTitle = styled(Rubrica)`
  color: ${props => props.theme.colors.dark};
`;

const SubTitleBox = styled.div`
  margin: 0px 29px 10px 29px;
`;
const Units = styled(Overline)`
  color: ${props => props.theme.colors.dark};
`;

const ChartBox = styled.div`
  height: auto;
  width: 334px;
`;

const ChartInnerBox = styled.div`
  height: 200px;
  width: 200x;
  padding: 0 29px;
  z-index: 10;
  position: relative;
`;

const UnitsBox = styled.div`
  // margin: 27px 0 10px 29px;
  margin: 27px 0 -15px 29px;
  position: relative;
  z-index: 1;
`;

const ComparisonText = styled(Caption4)`
  margin-left: 29px;
  color: ${props => props.theme.colors.dark};
`;

const PercentText = styled(Body1)`
  font-weight: 600;
  color: ${props => props.color};
  margin-left: 6px;
`;

const ComparisonTextBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  margin: 22px 29px 15px 29px;
`;

const SubUnit = styled(Body1)`
  flex: 1;
  font-size: 12px;
`;

export const IssueSummaryChart = ({
  title,
  subtitle,
  units,
  secondaryUnit,
  data,
  labels,
  colors,
  comparisonText,
  comparisonColor,
  description,
  barLabelFormatter,
  yTickFormatter,
}: IssueSummaryChartProps) => {
  return (
    <Container>
      <TitleBox>
        <TitleFlex>
          <Title>{title}</Title>
          <SubUnit>{secondaryUnit}</SubUnit>
        </TitleFlex>
        <SubTitleBox>
          <SubTitle>{subtitle}</SubTitle>
        </SubTitleBox>
      </TitleBox>
      <ChartBox>
        <UnitsBox>
          <Units>{units}</Units>
        </UnitsBox>
        <ChartInnerBox>
          <ChartJSBarChart
            data={data}
            labels={labels}
            colors={colors}
            barLabelFormatter={barLabelFormatter}
            yTickCallback={yTickFormatter}
          />
        </ChartInnerBox>
        <ComparisonTextBox>
          <ComparisonText>{'PORTFOLIO VS BENCHMARK:'}</ComparisonText>
          <PercentText color={comparisonColor}>{comparisonText}</PercentText>
        </ComparisonTextBox>
        <SubTitleBox>
          <SubTitle>{description}</SubTitle>
        </SubTitleBox>
      </ChartBox>
    </Container>
  );
};
