import styled from 'styled-components/macro';
import {ResourceRow as ResourceRowProps} from 'v2/utilities/types/components/ResourceTable';
import {Container, FlexContainer} from '../atoms/Containers';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {format} from 'date-fns';
import {ParagraphS, TitleS} from '../atoms/Typeface';
import {Badge} from '../atoms/Badge';
import {HarmonyButton} from './Button';
import {CircleX, Pencil, Upload} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {useState} from 'react';

const RowContainer = styled(FlexContainer).attrs({
  gap: '2rem',
  padding: '0.625rem 2rem',
  justify: 'center',
  items: 'center',
  wrap: 'wrap',
})`
  min-height: 5.5rem;
  height: auto;
  width: auto;
  border-radius: 0.25rem;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: ${({theme}) => theme.shadows.shadow_2};
`;

const Button = styled(HarmonyButton).attrs({
  variant: 'light',
})`
  padding: 0.75rem 1rem;

  &&& {
    border: 0;
  }
`;

const IconButton = styled(Button)`
  &&& {
    padding: 0;
    min-width: 2.75rem;
    width: 2.75rem;
    height: 2.75rem;
  }
`;

export const ResourceRow = ({
  title,
  updatedAt,
  fileSize,
  label,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  onDownload,
  ...rest
}: ResourceRowProps) => {
  const [iconColors, setIconColors] = useState({
    download: Theme.colors.dark,
    edit: Theme.colors.dark,
    delete: Theme.colors.dark,
  });

  const handleMouseOver = (parameter: 'download' | 'edit' | 'delete') => {
    setIconColors(prevState => ({
      ...prevState,
      [parameter]: Theme.colors.white,
    }));
  };

  const handleMouseOut = (parameter: 'download' | 'edit' | 'delete') => {
    setIconColors(prevState => ({
      ...prevState,
      [parameter]: Theme.colors.dark,
    }));
  };

  const resourceInfo = (): string => {
    if (updatedAt && fileSize) {
      return `Last updated in ${format(updatedAt, 'MMM dd, yyyy')} - ${fileSize}`;
    }

    if (updatedAt && !fileSize) {
      return `Last updated in ${format(updatedAt, 'MMM dd, yyyy')}`;
    }

    if (!updatedAt && fileSize) {
      return fileSize;
    }

    return DATA_STATUS.NONE;
  };

  return (
    <RowContainer fullWidth minWidth="37.5rem">
      <FlexContainer direction="column" minWidth="17.5rem" flex={1} gap="0.625rem" wrap="wrap" fullWidth>
        <Container fullWidth>
          <TitleS>{title ?? DATA_STATUS.NONE}</TitleS>
        </Container>
        <Container fullWidth>
          <ParagraphS>{resourceInfo()}</ParagraphS>
        </Container>
      </FlexContainer>
      <Container>
        <Badge>{label ?? DATA_STATUS.NONE}</Badge>
      </Container>
      <FlexContainer gap="0.75rem" wrap="wrap" minWidth={canEdit ? '250px' : 'auto'}>
        <Button
          onMouseOver={() => handleMouseOver('download')}
          onMouseOut={() => handleMouseOut('download')}
          onClick={() => onDownload?.({title, updatedAt, fileSize, label, ...rest})}
          variant="light"
        >
          <FlexContainer as="span" gap="0.75rem">
            <Upload size={17} color={iconColors.download} />
            <span>DOWNLOAD</span>
          </FlexContainer>
        </Button>
        {canEdit && (
          <Container>
            <IconButton
              onMouseOver={() => handleMouseOver('edit')}
              onMouseOut={() => handleMouseOut('edit')}
              onClick={() => onEdit?.({title, updatedAt, fileSize, label, ...rest})}
              variant="light"
            >
              <Pencil size={20} color={iconColors.edit} />
            </IconButton>
          </Container>
        )}
        {canDelete && (
          <Container>
            <IconButton
              onMouseOver={() => handleMouseOver('delete')}
              onMouseOut={() => handleMouseOut('delete')}
              onClick={() => onDelete?.({title, updatedAt, fileSize, label, ...rest})}
              variant="light"
            >
              <CircleX size={20} color={iconColors.delete} />
            </IconButton>
          </Container>
        )}
      </FlexContainer>
    </RowContainer>
  );
};
