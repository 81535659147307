import {countBy, find, groupBy, map, sortBy} from 'lodash';
import {useIssueTypes} from './useIssueTypes';
import {useGetFirmEngagementByIdQuery, useGetFirmHoldingsByIdQuery} from 'v2/redux/typeormEndpoints';
import {isImpersonating} from 'app/utilities';
import {useSelector} from 'react-redux';
import {filter} from 'v2/utilities/helpers/filter';

export const useFirmEngagements = () => {
  const {data: issuesData} = useIssueTypes();
  const {data: issueTypes} = useIssueTypes();

  const firmId = useSelector((state: any) =>
    isImpersonating() ? state.auth.impersonatedUser.firmId ?? 0 : state.auth.firmId ?? 0,
  );
  let rawData: any[] = useGetFirmEngagementByIdQuery({firmId}).data ?? [];
  let holdingsData = useGetFirmHoldingsByIdQuery({firmId}).data ?? [];

  const filters = {
    type: useSelector((state: any) => state?.strategyFilter?.filters?.type),
    class: useSelector((state: any) => state?.strategyFilter?.filters?.class),
    region: useSelector((state: any) => state?.strategyFilter?.filters?.region),
  };

  const filterParams = [];
  if (filters.type !== 'all') {
    filterParams.push({key: 'type', target: filters.type, operation: 'eq'});
  }
  if (filters.class !== '') {
    filterParams.push({key: 'class', target: filters.class, operation: 'eq'});
  }
  if (filters.region !== '') {
    filterParams.push({key: 'region', target: filters.region, operation: 'eq'});
  }

  //@ts-ignore
  holdingsData = filter({data: holdingsData, filterItems: filterParams});
  const companyIds = holdingsData.map((holding: any) => holding.companyId);
  const companyIdsSet = new Set(companyIds);
  rawData = rawData.filter(engagement => companyIdsSet.has(engagement.companyId));

  // data is flattened due to an extra array layer that causes issues
  // const data = rawData?.flatMap(company => company);
  let data: any = {};
  rawData?.forEach((issue: any) => {
    if (data[issue.companyId]) {
      data[issue.companyId].issues.push(issue);
    } else {
      data[issue.companyId] = {companyId: issue.companyId, issues: [{...issue}]};
    }
  });
  data = Object.values(data);

  // WORKING counts the amount of unique companies that have received at least one engagement letter (blue part of progress bar)
  let totalEngagementCount = Array.from({length: 1}, () => Object.keys(countBy(data, 'companyId')).length);

  // WORKING get a total COUNT of issues engaged unique to company (no duplicate issue ids inside a company)
  const getIssuesEngagedCount = (companies: any[]) => {
    const barChartIssueTypes = [
      issueTypes.CARBON_EMISSIONS.id,
      issueTypes.ENERGY_USE.id,
      issueTypes.WATER_CONSUMPTION.id,
      issueTypes.CEO_COMPENSATION_RATIO.id,
      issueTypes.WOMEN_IN_LEADERSHIP.id,
      issueTypes.WOMEN_ON_BOARD.id,
      issueTypes.BOARD_INDEPENDENCE.id,
    ];
    let count = 0;
    companies.forEach(company => {
      let issueIds: number[] = [];
      company.issues?.forEach((issue: any) => {
        if (!issueIds.includes(issue.issueId) && barChartIssueTypes.includes(issue.issueId)) {
          issueIds.push(issue.issueId);
          count++;
        }
      });
    });

    return count;
  };

  // TODO get a total ARRAY of issues engaged UNIQUE to company (no duplicate issue ids inside a company)
  const getIssuesEngaged = (companies: any[]) => {
    let count = 0;
    let issuesEngaged: number[] = [];
    companies.forEach(company => {
      let issueIds: number[] = [];
      company.issues?.forEach((issue: any) => {
        if (!issueIds.includes(issue.issueId)) {
          issueIds.push(issue.issueId);
          issuesEngaged.push(issue.issueId);

          count++;
        }
      });
    });

    return issuesEngaged;
  };

  const getIssueById = (id: number) => {
    const issueObject = find(issuesData, {id: id});
    return issueObject;
  };

  const getIssueNameById = (id: number) => {
    const issueObject = find(issuesData, {id: id});
    let issueName = issueObject?.name || '';
    return issueName;
  };

  // WORKING get a list of issues engaged WITH ISSUE INFORMATION
  const getIssuesEngaged2 = () => {
    const engagementLetters = getIssuesEngaged(data);
    const issuesEngaged: any[] = [];
    engagementLetters.forEach(engagement => {
      let engagementIssue = getIssueById(engagement);
      issuesEngaged.push(engagementIssue);
    });
    return issuesEngaged;
  };

  // TODO get a count of issues by category
  const getIssuesEngagedByCategory = () => {
    const engagementLetters = getIssuesEngaged2();
    // const issuesEngagedByCategory = Array.from({ length: 1 }, () => Object.keys(countBy(engagementLetters, 'id')).length);

    let mappedIssues = map(countBy(engagementLetters, 'topLevelParentId'), (val: any, key: any) => ({
      id: key,
      count: val,
    }));
    let totalIssues = 0;
    mappedIssues.forEach(issue => (totalIssues += issue.count));
    let newMappedIssues: any[] = [['Category', 'Percent']];
    mappedIssues.forEach(issue => {
      let issueId = issue.id;
      let parseIntResult: number = parseInt(issueId);
      let issueName = getIssueNameById(parseIntResult);
      let issueCount = parseInt(((issue.count / totalIssues) * 100).toFixed(2));
      let newMapObject = map({issueName: issueName, count: issueCount});
      newMappedIssues.push(newMapObject);
    });
    return newMappedIssues;
  };

  const getIssuesEngagedByNestedSubcategory = (acceptedSubcategories: any[]) => {
    const engagementLetters = getIssuesEngaged2();
    let mappedIssues = map(countBy(engagementLetters, 'id'), (val: any, key: any) => ({id: key, count: val}));

    const myMap: any = {};
    let newMappedIssues: any[] = [];

    const acceptable = acceptedSubcategories.map(issue => issue.id);

    mappedIssues.forEach(issue => {
      let issueId = issue.id;
      let parseIntResult: number = parseInt(issueId);
      let issueObject = getIssueById(parseIntResult);
      let issueParentId = issueObject?.topLevelParentId;
      let issueName = issueObject?.name;

      let newMapObject = map({
        category: issueParentId,
        issueName: issueName,
        count: issue.count,
        issueId: Number(issueId),
      });
      if (acceptable.includes(parseInt(issueId))) newMappedIssues.push(newMapObject);

      myMap[issueId] = {category: issueParentId, issueName: issueName, count: issue.count};
    });

    acceptedSubcategories.forEach(issue => {
      if (!myMap[issue.id]) newMappedIssues.push([issue.topLevelParentId, issue.name, 0, Number(issue.id)]);
    });

    let mappedCategories = {
      [issueTypes.ENVIRONMENTAL.id]: [] as any[],
      [issueTypes.SOCIAL.id]: [],
      [issueTypes.GOVERNANCE.id]: [],
    };

    newMappedIssues = sortBy(newMappedIssues, issue => issue[3]);
    newMappedIssues.forEach(issue => {
      if (mappedCategories[parseInt(issue[0])]) {
        mappedCategories[parseInt(issue[0])].push(issue[2]);
      } else {
        mappedCategories[issue.category] = [];
      }
    });
    return Object.values(mappedCategories);
  };

  const getCompaniesEngagedForIssue = (issueId: number, sector: any) => {
    const companyIds = new Set();
    data.map((datum: any) => {
      datum.issues.map((issue: any) => {
        if (issue.issueId == issueId) {
          companyIds.add(datum.companyId);
        }
      });
    });
    let companyArr: any[] = [];
    let sectors = new Set();
    holdingsData.map((holding: any) => {
      if (companyIds.has(holding.companyId)) {
        companyArr.push({
          company: holding.companyName,
          sector: holding.sectorName,
          held: holding.amountHeld,
          id: holding.companyId,
        });
        if (!sectors.has(holding.sectorName)) {
          sectors.add(holding.sectorName);
        }
      }
    });
    companyArr = Object.values(groupBy(companyArr, company => company.id));
    companyArr = companyArr.map((company: any) => {
      const held = company.reduce((acc: any, curr: any) => {
        return acc + Number(curr.held);
      }, 0);
      return {...company[0], held};
    });
    companyArr = sortBy(companyArr, company => -Number(company.held));
    let sectorsForDropDown = Array.from(sectors).map(sector => {
      return {value: sector, label: sector};
    });
    sectorsForDropDown.push({label: 'All Sectors', value: null});
    sectorsForDropDown = sortBy(sectorsForDropDown, (sector: any) => sector.label);
    if (sector?.value) {
      //@ts-ignore
      companyArr = companyArr.filter(company => company.sector === sector.value);
    }

    return {holdings: companyArr, sectors: sectorsForDropDown};
  };

  return {
    data,
    totalEngagementCount,
    getIssuesEngagedCount,
    getIssuesEngagedByCategory,
    getIssuesEngagedByNestedSubcategory,
    getCompaniesEngagedForIssue,
  };
};
