import cn from 'classnames';
import moment from 'moment';
import {assignWith, isEmpty, find, filter, get, map} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, formValueSelector} from 'redux-form';

// Local Imports
import {FormGroup, SelectFormGroup} from 'app/components/forms';
import {Button, FormFeedback, MultiUploaderWrapper} from 'app/components';
import {FORM_MESSAGE, INVESTMENT_VEHICLES, PORTFOLIO_MANAGEMENT_TYPES} from 'app/constants';
import {errorRequiredFields, formatUtcDate} from 'app/utilities';
import {validateStrategyUpload} from 'app/utilities/fileFunctions';
import planHoldingsTemplateCsv from 'app/assets/docs/plan-holdings-template.csv';
import {toastError} from 'app/utilities/toast';
import {openSimpleModal} from './SimpleModal/redux';

const validate = values => {
  const errors = errorRequiredFields(values, ['strategyId', 'name', 'marketIndex', 'planHoldingsUpload']);

  if (isEmpty(values.planHoldingsUpload)) {
    errors.planHoldingsUpload = 'Required';
  }

  return errors;
};

class StrategyUploader extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.input.value !== this.props.input.value && nextProps.input.value === 'EMPTY' && this.multiUploader) {
      this.multiUploader.removeAllFiles();
    }

    return true;
  }

  render() {
    const {
      onFinish,
      meta: {touched, error},
    } = this.props;
    return (
      <div className={cn('rbt form-group', {'is-invalid': touched && error})}>
        <div className="label-hint-container uploader mb-3">
          <label>Upload Plan Holdings (CSV)* {touched && error && `(${error})`}</label>
          <span className="label-hint">
            <a href={planHoldingsTemplateCsv} target="_blank">
              Download CSV Template
            </a>
          </span>
        </div>
        <MultiUploaderWrapper
          value={this.props.input.value}
          isValidatingFileVisible={true}
          onFilesChanged={(files, s3file) => {
            if (!s3file) {
              this.props.input.onChange([]);
            } else {
              this.props.input.onChange([{name: get(s3file, 'fileKey', null), isNewFile: true}]);
            }
          }}
          multiUploaderProps={{
            onRef: e => {
              this.multiUploader = e;
            },
          }}
          uploaderProps={{
            accept: 'text/csv, .csv',
            onError: () => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE),
            onFinish,
            onSignedUrl: () => {
              /* Suppress Console logs */
            },
          }}
        />
      </div>
    );
  }
}

class UploadPortfolioFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const {initialValues, marketIndexes, strategy, strategies} = this.props;

    const pastUploads = get(strategy, 'strategyUploads', []);

    const standardMarketIndexes = !isEmpty(marketIndexes) ? filter(marketIndexes, {isHarmonyIndex: false}) : [];

    const isHarmonyIndex = get(initialValues, 'isHarmonyIndex');

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />

        <Field
          name="strategyId"
          label="Strategy Name*"
          type="text"
          optionLabelProp="name"
          optionValueProp="id"
          onChange={this.props.onStrategyChange}
          component={SelectFormGroup}
          shouldInsertDefault={true}
          options={strategies}
        />
        <div className="form-grid">
          <Field
            label="Portfolio Management Type"
            name="portfolioManagementType"
            component={SelectFormGroup}
            options={PORTFOLIO_MANAGEMENT_TYPES}
            className="col-3"
            disabled={isHarmonyIndex}
          />
          <Field
            label="Investment Vehicle"
            name="investmentVehicle"
            component={SelectFormGroup}
            options={INVESTMENT_VEHICLES}
            shouldInsertDefault={true}
            className="col-3"
          />
        </div>

        <Field
          label="Reference Index*"
          name="marketIndex"
          component={props => (
            <FormGroup {...props} typeahead options={standardMarketIndexes}>
              {(inputProps, wrapperProps) => (
                <Typeahead
                  {...wrapperProps}
                  ref={typeahead => {
                    this.typeahead = typeahead;
                  }}
                  disabled={isHarmonyIndex}
                  placeholder="Select a Reference Index"
                  inputProps={inputProps}
                  labelKey="name"
                />
              )}
            </FormGroup>
          )}
        />

        <div className="mb-9">
          <Field name="planHoldingsUpload" component={StrategyUploader} onFinish={this.props.getCsvFileMeta} />
        </div>
        <div className="mb-9">
          <div className="label-hint-container mb-3">
            <label>5 Most Recent Processed Uploads</label>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Filename</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Upload Date</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(pastUploads) ? (
                map(pastUploads, (strategyUpload, index) => (
                  <tr key={index}>
                    <td>{strategyUpload.originalFileName}</td>
                    <td>{formatUtcDate(strategyUpload.startDate, 'MMM D, YYYY')}</td>
                    <td>{formatUtcDate(strategyUpload.endDate, 'MMM D, YYYY')}</td>
                    <td>{moment(strategyUpload.createdAt).format('MMM D, YYYY hh:mm A')}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">There are no uploads at this time</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="form-footer is-right mt-12">
          <Button
            type="submit"
            disabled={
              isEmpty(this.props.planHoldingsUpload) ||
              this.props.isCreatingStagingUpload ||
              this.props.hasStagingUploadError
            }
            solid
          >
            Save
          </Button>
        </div>
      </form>
    );
  }
}

UploadPortfolioFormComponent.propTypes = {handleSubmit: PropTypes.func};

const mapStateToProps = state => {
  const form = 'upload-portfolio';
  const selector = formValueSelector(form);
  return {
    form,
    planHoldingsUpload: selector(state, 'planHoldingsUpload'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStrategyUploadInfoModal: (title, message) =>
      dispatch(
        openSimpleModal({
          title,
          message,
          customClassName: 'modal-lg',
        }),
      ),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {strategy, marketIndexes} = ownProps;

  const defaultValues = {portfolioManagementType: PORTFOLIO_MANAGEMENT_TYPES[0]};

  let userValues;

  if (!isEmpty(marketIndexes) && strategy) {
    const marketIndex = find(marketIndexes, {id: strategy.marketIndexId});

    userValues = {
      strategyId: strategy.id,
      marketIndex: !isEmpty(marketIndex) ? marketIndex.name : null,
      isHarmonyIndex: get(marketIndex, 'isHarmonyIndex'),
      investmentVehicle: strategy.investmentVehicle,
      planHoldingsUpload: null,
      portfolioManagementType: strategy.portfolioManagementType,
    };
  }

  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    initialValues: assignWith(defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
    getCsvFileMeta: validateStrategyUpload(ownProps.createStagingUpload, dispatchProps.onStrategyUploadInfoModal),
  });
};

export const UploadPortfolioForm = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
)(UploadPortfolioFormComponent);
