import {FIRM_RATINGS} from 'v2/constants/firmRatings';
import {calcRankRating} from 'v2/utilities/helpers';
import {HarmonyRatingA, HarmonyRatingB, HarmonyRatingC, HarmonyRatingD, HarmonyRatingE} from '../atoms/icons';

/**
 * Use either percent or rating to render the icon
 */
export const RatingByPercent = ({
  pctRank,
  rating = 1,
  size = 26,
}: {
  pctRank?: number;
  rating?: number;
  size?: number;
}) => {
  switch (calcRankRating(pctRank, rating)) {
    case FIRM_RATINGS.A:
      return <HarmonyRatingA size={size} />;
    case FIRM_RATINGS.B:
      return <HarmonyRatingB size={size} />;
    case FIRM_RATINGS.C:
      return <HarmonyRatingC size={size} />;
    case FIRM_RATINGS.D:
      return <HarmonyRatingD size={size} />;
    default:
      return <HarmonyRatingE size={size} />;
  }
};
