import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IssueDataCardHeader} from './IssueDataCardHeader';
import {ISSUE, CARD_CHART_STYLE} from '../constants';
import {IssueDataCardChartStyle1, IssueDataCardChartStyle2, IssueDataCardChartStyle3} from '.';

class IssueDataCardBack extends Component {
  renderChart() {
    const {data: issueData = []} = this.props;
    const issueCardStyle = ISSUE?.[issueData.id]?.cardChartStyle;

    if (issueCardStyle === CARD_CHART_STYLE.STYLE_1) {
      return <IssueDataCardChartStyle1 data={issueData} />;
    }

    if (issueCardStyle === CARD_CHART_STYLE.STYLE_2) {
      return <IssueDataCardChartStyle2 data={issueData} />;
    }

    return <IssueDataCardChartStyle3 data={issueData} />;
  }

  render() {
    const {category, data: issueData, flip, visible} = this.props;
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      // eslint-disable-next-line func-names
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
        // eslint-disable-next-line no-undef
      })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));
    const showCard = () => {
      if (isSafari) {
        if (visible) {
          return true;
        }
        return false;
      }
      return true;
    };
    return (
      <div key="back" className="h-100 w-100 py-5 issue-data-card flex-column d-flex">
        <IssueDataCardHeader mode="card" category={category} issue={issueData} flip={flip} />
        <div style={{zIndex: 0}} className={`h-100 align-items-center pt-5 ${!showCard() ? 'd-none' : ''}`}>
          {this.renderChart()}
        </div>
      </div>
    );
  }
}

IssueDataCardBack.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  flip: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export {IssueDataCardBack};
