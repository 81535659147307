export const PORTFOLIO_STATUS = {
  IN: {
    label: 'In Compliance',
    color: 'success',
  },
  OUT: {
    label: 'Out of Compliance',
    color: 'danger',
  },
  NA: {
    label: 'Not Applicable',
    color: 'light-gray',
  },
};
