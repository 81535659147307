import {Field} from 'react-final-form';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {isValidEmail} from 'v2/utilities/helpers';
import {AdminEmailCard as AdminEmailCardProps} from 'v2/utilities/types/components/AdminFormCard';
import {Container, FlexContainer} from '../atoms/Containers';
import {AdminFormCard} from '../molecules/AdminFormCard';
import {TextFormGroup} from '../molecules/TextInput';

export const AdminEmailCard = ({name, ...rest}: AdminEmailCardProps) => {
  type FormValues = {
    email: string;
  };

  const validate = (values: FormValues) => {
    let errors: Partial<FormValues> = {};

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Must be a valid email';
    }

    return errors;
  };

  return (
    <AdminFormCard
      title={name ? `Editing Contact Email for ${name}` : DATA_STATUS.NONE}
      validate={validate}
      fields={['contactEmail']}
      {...rest}
    >
      <FlexContainer justify="center">
        <Field name="email">
          {props => (
            <Container width="50%">
              <label htmlFor={props.input.name}>Email Address</label>
              <TextFormGroup
                input={props.input}
                meta={props.meta.touched && props.meta}
                placeholder={DATA_STATUS.NONE}
              />
            </Container>
          )}
        </Field>
      </FlexContainer>
    </AdminFormCard>
  );
};
