/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Joyride, {STATUS} from 'react-joyride';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import {ISSUE_CATEGORY, ISSUE_CATEGORY_KEYS, DISTRIBUTION, CONFIG} from 'app/constants';
import {getAnnualPendingIssueData} from '../redux/companyAnnualIssues';
import {getRequestData} from '../redux/percentileRanking';
import {getIssueBadgeAssetUrl, getOrdinal} from '../utilities';
import {DashboardHeader, BooleanCard, NumericalCard, JoyrideBeacon, JoyrideTooltip} from '../components';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {keyBy} from 'lodash';
import {typeOrm} from 'v2/redux/typeormEndpoints';

class CompanyDashboardPage extends Component {
  state = {
    run: false,
    steps: [
      {
        title: 'Welcome to your ESG portal.',
        content: 'Here you can see your percentiles ranks, ESG metrics, awards, and alerts.',
        placement: 'center',
        target: 'body',
      },
      {
        title: 'Company Overview',
        target: '#overview',
        content: `Look at general information about your company. At the company overview, you can check your overall percentiles ranks. `,
        placement: 'bottom',
      },
      {
        title: 'Company Percentile Rank',
        target: '#dashboard-percentile-ranking',
        content: `Harmony uses a relative risk approach to measure all ESG issues and display them in percentile ranks. For example, the median company falls at the 50th percentile rank, and a high-risk company would be in the 80th percentile.`,
        placement: 'bottom',
      },
      {
        title: 'Company Cohort',
        target: '#dashboard-company-rating',
        content: `Groups the companies on 5 different categories based on percentile ranks. The median company falls at the C cohort, and a high-risk company would be in the E cohort.`,
        placement: 'bottom',
      },
      {
        title: 'ESG Dimensions overview',
        target: '#environment-header',
        content:
          'Look at aggregate information for the environmental performance. There are three dimensions that you can explore in detail: Environmental, Social, and Governance.',
        placement: 'bottom',
      },
      {
        title: 'Awards and Badges',
        target: '#awards-badges',
        content:
          'Look at what awards your company has received. The awards are given based on the overall or individual ESG issues performances.',
        placement: 'right',
      },
    ],
  };
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    const {dispatch, companyPortals} = this.props;
    if (companyPortals && companyPortals.length > 0) {
      dispatch(typeOrm.endpoints.getIssues.initiate());
      dispatch(getAnnualPendingIssueData(companyPortals[0].id));
      dispatch(getRequestData(companyPortals[0].id));
    }
  }

  getPercentileRanking(issue, distribution) {
    if (
      this.props.percentileRanking !== undefined &&
      this.props.percentileRanking[issue] !== undefined &&
      this.props.percentileRanking[issue][distribution] !== undefined
    ) {
      return this.props.percentileRanking[issue][distribution] === 0
        ? 1
        : this.props.percentileRanking[issue][distribution];
    }

    return '';
  }

  renderBadges() {
    const badges = [];
    // const currentYear = new Date().getFullYear();
    const currentYear = CONFIG.REPORTING_YEAR;
    const {companyAnnualIssues} = this.props;
    if (companyAnnualIssues) {
      for (const issueType in companyAnnualIssues) {
        const issue = companyAnnualIssues[issueType];
        issue.forEach(item => {
          const {data} = item;
          for (const year in data) {
            const badge = data[year];
            // eslint-disable-next-line eqeqeq
            if (year == currentYear - 1) {
              if (badge.receivesBadge) {
                badges.push(badge);
              }
            }
          }
        });
      }
    }
    return badges.map(({issueId}) => (
      <div className="company-dashboard-award-badge col ">
        <img crossOrigin="anonymous" key={issueId} src={getIssueBadgeAssetUrl(issueId)} />
      </div>
    ));
  }

  renderCards(key, category) {
    const selectIssues = state => typeOrm.endpoints.getIssues.select()(state);
    const {data: issues} = selectIssues(this.props.state);
    const keyedIssues = keyBy(issues, issue => issue.id);
    const {companyAnnualIssues} = this.props;
    if (companyAnnualIssues && companyAnnualIssues[key]) {
      let year = 0;
      companyAnnualIssues[key].forEach(({data}) => {
        Object.keys(data).forEach(key => {
          if (data[key]?.value !== null && key > year) {
            year = key;
          }
        });
      });
      if (year == 0) {
        year = CONFIG.REPORTING_YEAR;
      }
      return companyAnnualIssues[key].map(({data, id, issueType, name, sector, target, universe, unit}) => {
        year = 0;
        Object.keys(data)?.forEach(key => {
          if (key > year && (data[key].value || data[key].value === 0)) {
            year = key;
          }
        });
        if (year === 0) {
          year = CONFIG.REPORTING_YEAR;
        }
        if (!keyedIssues[id]?.enabled_ut4) {
          return <></>;
        }
        return (
          <div className="header-card-wrapper">
            {issueType === 'Numeric' ? (
              <NumericalCard
                key={id}
                data={{
                  id,
                  category,
                  issues: data,
                  target,
                  name,
                  year,
                  unit,
                }}
              />
            ) : (
              <BooleanCard
                key={id}
                data={{
                  id,
                  category,
                  issues: data,
                  value: data[year] && data[year].value,
                  sector: Math.round(sector[year] * 100),
                  universe: Math.round(universe[year] * 100),
                  name,
                  year,
                }}
              />
            )}
          </div>
        );
      });
    }
    return <span>No Data</span>;
  }

  handleClickStart = e => {
    this.setState({
      run: true,
    });
  };

  handleJoyrideCallback = data => {
    const {status, type} = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({run: false});
    }
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const {steps, run} = this.state;
    const {companyPortals = [{}], history, percentileRanking} = this.props;
    const {name, rating, sector = {}} = companyPortals?.[0] || {};

    return (
      <React.Fragment>
        <Joyride
          autostart
          steps={steps}
          run={run}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          scrollOffset={300}
          callback={this.handleJoyrideCallback}
          tooltipComponent={JoyrideTooltip}
          beaconComponent={JoyrideBeacon}
          styles={{
            options: {
              arrowColor: '#009BCC',
              zIndex: 10000,
            },
          }}
        />
        <div className="company-dashboard-backdrop w-100 position-absolute" />
        <div className="padded-container company-dashboard-container futura">
          <div className="company-dashboard-header-wrapper" id="overview">
            <div className="company-dashboard-header d-flex flex-row">
              <div className="company-dashboard-header-title flex-fill pr-6 pb-9">
                <div className="company-dashboard-header-company-title text-uppercase">
                  <span>{name}</span>
                </div>
                <div className="company-dashboard-header-company-role text-uppercase">
                  <span>{sector.name}</span>
                </div>
              </div>
              <div className="company-dashboard-header-stats-wrapper d-flex flex-row justify-content-end pr-8">
                <div className="company-dashboard-header-stats d-flex flex-column" id="dashboard-percentile-ranking">
                  <div className="company-dashboard-header-stats-title align-items-center justify-content-between row">
                    <div className="line-spacer col" />
                    <div className="text text-center text-uppercase col">
                      <span className="text-nowrap">Percentile Ranking</span>
                    </div>
                    <div className="line-spacer col" />
                  </div>
                  <div className="row">
                    <div className="col company-dashboard-header-score text-center">
                      <div>
                        <span className="score">
                          {(this.props.percentileRanking &&
                            this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.SECTOR)) ||
                            DATA_STATUS.NONE}
                        </span>
                        <span className="ordinal">
                          {this.props.percentileRanking !== null &&
                            getOrdinal(this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.SECTOR))}
                        </span>
                      </div>
                      <div className="label text-center text-uppercase">
                        <span>Sector</span>
                      </div>
                    </div>
                    <div className="col company-dashboard-header-score text-center">
                      <div>
                        <span className="score">
                          {(this.props.percentileRanking &&
                            this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.UNIVERSE)) ||
                            DATA_STATUS.NONE}
                        </span>
                        <span className="ordinal">
                          {this.props.percentileRanking !== null &&
                            getOrdinal(this.getPercentileRanking(ISSUE_CATEGORY_KEYS.OVERALL, DISTRIBUTION.UNIVERSE))}
                        </span>
                      </div>
                      <div className="label text-center text-uppercase">
                        <span>Universe</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {percentileRanking && (
            <React.Fragment>
              <DashboardHeader
                data={{
                  id: 'environment-header',
                  icon: faSeedling,
                  title: 'Environmental',
                  sector: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.ENVIRONMENTAL, DISTRIBUTION.SECTOR),
                  universe: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.ENVIRONMENTAL, DISTRIBUTION.UNIVERSE),
                  colorStart: '#34B991',
                  colorStop: '#27A37D',
                }}
              >
                {this.renderCards(ISSUE_CATEGORY_KEYS.ENVIRONMENTAL, ISSUE_CATEGORY.ENVIRONMENT)}
              </DashboardHeader>
              <DashboardHeader
                data={{
                  icon: faHandHeart,
                  title: 'Social',
                  sector: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.SOCIAL, DISTRIBUTION.SECTOR),
                  universe: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.SOCIAL, DISTRIBUTION.UNIVERSE),
                  colorStart: '#009BCC',
                  colorStop: '#008BB7',
                }}
              >
                {this.renderCards(ISSUE_CATEGORY_KEYS.SOCIAL, ISSUE_CATEGORY.SOCIAL)}
              </DashboardHeader>
              <DashboardHeader
                data={{
                  icon: faLandmark,
                  title: 'Governance',
                  sector: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.GOVERNANCE, DISTRIBUTION.SECTOR),
                  universe: this.getPercentileRanking(ISSUE_CATEGORY_KEYS.GOVERNANCE, DISTRIBUTION.UNIVERSE),
                  colorStart: '#003462',
                  colorStop: '#034682',
                }}
              >
                {this.renderCards(ISSUE_CATEGORY_KEYS.GOVERNANCE, ISSUE_CATEGORY.GOVERNANCE)}
              </DashboardHeader>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const selectIssues = state => typeOrm.endpoints.getIssues.select({})(state);
  const {data} = selectIssues(state);
  return {
    companyAnnualIssues: state.companyAnnualIssues.data,
    companyPortals: state.auth.impersonatedUser
      ? state.auth.impersonatedUser.companyPortals
      : state.auth.companyPortals || [],
    percentileRanking: state.percentileRanking.issues,
    issues: typeOrm.endpoints.getIssues.select({})(state),
    state,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const CompanyDashboard = connect(mapStateToProps, null, mergeProps)(CompanyDashboardPage);
