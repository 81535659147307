import styled from 'styled-components/macro';
import {ChevronRight} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {Title5} from '../atoms/Typeface';

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${props => props.theme.constants.border_radius};
  background-color: ${props => props.theme.colors.white};
  border: 0;
  width: 100%;
  height: 5.063rem;
  padding: 0 1.875rem;
  cursor: pointer;
  opacity: 0.6;
  transition: ${props => props.theme.transitions.default};
  &:hover {
    opacity: 1;
    box-shadow: ${props => props.theme.shadows.shadow_1};
  }
`;

const Title = styled(Title5)`
  &&& {
    font-weight: normal !important;
    color: ${props => props.theme.colors.dark};
    margin: 0 !important;
  }
`;

export const SectorSelector: React.FC<{
  title: string;
  onClick: Function | undefined;
}> = ({title, onClick}) => {
  const handleClick = (e: any): Function | undefined => {
    if (onClick) {
      return onClick(e);
    }

    return;
  };

  return (
    <Button onClick={handleClick}>
      <Title>{title}</Title>
      <ChevronRight size={13} color={Theme.colors.grey_5} />
    </Button>
  );
};
