import {find, get, map, merge, orderBy, pick, some} from 'lodash';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router';
import {ROUTE} from 'app/constants';
import {ONBOARDING_STEPS} from 'v2/constants/onboardingSteps';
import {getInvitationByCode} from 'app/redux/onboardingUser';
import {useGetInvitationUserQuery} from 'v2/redux/harmonyOnboardingApi';

export const useOnboardingUser = () => {
  const {pathname} = useLocation();
  const {invitationCode = ''} = useParams<{invitationCode?: string}>();

  const {
    data: onboardingUser = {},
    isFetching,
    isLoading,
  } = useGetInvitationUserQuery(invitationCode, {
    skip: !invitationCode,
    refetchOnMountOrArgChange: true,
  });

  const onboardingSteps = get(onboardingUser, 'invitation.onboardingSteps', {});
  const isOnboardingComplete = get(onboardingUser, 'invitation.isOnboardingComplete', false);
  const userRole = get(onboardingUser, 'user.roles[0]');

  const dispatch = useDispatch();

  const routeWithInvitecode = (route: string) => {
    if (route) {
      return route.replace(':invitationCode', invitationCode);
    }

    return '';
  };

  // Merge ONBOARDING_STEPS with the saved onboardingSteps, picking only official steps
  const userOnboardingSteps = pick(
    // @ts-ignore
    merge({}, ONBOARDING_STEPS[userRole], onboardingSteps),
    // @ts-ignore
    Object.keys(ONBOARDING_STEPS[userRole] || {}),
  );

  // Turn the merged onboarding steps into an array,
  // to sort by stepOrder
  let userOnboardingStepsArray = orderBy(
    map(userOnboardingSteps, (value, key) => {
      return {
        step: key,
        ...value,
        isActive: value.isComplete,
        isSelected: value.route.path.replace(':invitationCode', invitationCode) === pathname.toLowerCase(),
        path: routeWithInvitecode(value.route.path),
        label: value.shortTitle,
      };
    }),
    ['stepOrder'],
    ['asc'],
  );

  userOnboardingStepsArray = userOnboardingStepsArray.map(currentStep => {
    const stepGroup = userOnboardingStepsArray.filter(step => {
      const key = get(step, 'key', '0');
      const currentKey = get(currentStep, 'key', '0');
      return key.charAt(0) === currentKey.charAt(0);
    });

    if (stepGroup.length > 1 && !currentStep.isSubStep) {
      if (stepGroup.find((step: any) => step.isSelected) !== undefined) {
        return {
          ...currentStep,
          isSelected: true,
        };
      }
      if (stepGroup.find((step: any) => step.isComplete)) {
        return {
          ...currentStep,
          isActive: true,
        };
      }
    }

    return currentStep;
  });

  const hasOnboardingStarted = some(userOnboardingStepsArray, {isComplete: true});

  // Get the next available step based on what is not complete,
  // defaulting to last step if one cannot be found
  let nextAvailableStep = find(userOnboardingStepsArray, {isComplete: false});

  if (!nextAvailableStep) {
    // @ts-ignore
    nextAvailableStep = find(userOnboardingStepsArray, {step: ONBOARDING_STEPS[userRole]?.FINALIZE?.key});
  }

  const requestedStep = find(
    userOnboardingStepsArray,
    onboardingStep => onboardingStep.path.toLowerCase() === pathname.toLowerCase(),
  );

  // Determine whether user has access to the current requested step.  They should not be able to get further than
  // the next available step, even if they completed a future step somehow
  const hasAccessToStep = requestedStep && requestedStep.stepOrder <= nextAvailableStep.stepOrder;

  const error = get(onboardingUser, 'error', null);
  const hasInvitationCodeError = get(onboardingUser, 'hasError', false);
  const onboardingInvitation = get(onboardingUser, 'invitation', {});
  const onboardingPlanSponsor = get(onboardingUser, 'planSponsor', {});

  const isOnboardingUserInState = !!get(onboardingUser, 'id');
  const isInvitationInvalid = hasInvitationCodeError && error?.message?.toString() === 'Invitation Code is not valid.';
  const isOnboardingLandingPage = pathname === ROUTE.ONBOARDING_LANDING.path(invitationCode);
  const hasUnexpectedError = !isInvitationInvalid && (!onboardingUser || !onboardingUser?.user?.id);

  let redirectPath;

  const fetchOnboardingInfo = () => {
    dispatch(getInvitationByCode(invitationCode));
  };

  // If redux store is empty, go fetch the onboarding info, otherwise determine if redirects are needed
  if (invitationCode !== get(onboardingInvitation, 'code') && !hasInvitationCodeError && !isFetching) {
    fetchOnboardingInfo();
  } else if ((isOnboardingUserInState || isInvitationInvalid) && !isOnboardingLandingPage) {
    if (isInvitationInvalid || isOnboardingComplete) {
      redirectPath = ROUTE.ONBOARDING_LANDING.path(invitationCode);
    } else if (!hasAccessToStep) {
      redirectPath = nextAvailableStep.route.path(invitationCode);
    }
  }

  return {
    invitationCode,
    error,
    hasInvitationCodeError,
    isFetching,
    isLoading,
    onboardingInvitation,
    onboardingPlanSponsor,
    hasUnexpectedError,
    redirectPath,
    fetchOnboardingInfo,
    onboardingSteps: userOnboardingSteps,
    onboardingStepsArray: userOnboardingStepsArray,
    onboardingUser,
    isOnboardingComplete,
    hasOnboardingStarted,
    nextAvailableStep,
    requestedStep,
    hasAccessToStep,
    userRole,
  };
};
