import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import {toastSuccess, toastError} from 'app/utilities/toast';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {DisplayInputField} from './DisplayInputField';
import {CONFIG, ISSUE, TREND_TEXT_COLOR} from '../constants';
import {getAnnualPendingIssueData, restate} from '../redux/companyAnnualIssues';
import {withUser} from '../utilities/withUser';
import {ToolTip} from '.';
import {getPercentChange, getTrendType} from '../utilities/dataAnalyzer';
import {formatNumberCommas} from '../utilities/formatNumberCommas';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {reportingYears} from 'v2/utilities/helpers/reportingYears';
import {ISSUE_UNITS} from 'v2/constants/issueOptions';
import {convertUnits, getPrecision} from 'v2/utilities/helpers/convertUnits';

const Cell = styled.div`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class IssueDataTableRowComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      formValues: reportingYears().map(() => null),
    };

    const {id: issueId, issueType, target, data: originalData} = props.data;
    this.keyYears = reportingYears();
    this.data = {};
    this.dataInputs = {};

    if (issueType === 'Boolean') {
      this.keyYears.forEach(year => {
        this.data[year] = originalData[year];
      });
    } else {
      this.keyYears.forEach(year => {
        const datum = Object.assign({}, originalData[year]);
        const value = datum.value === null || datum.value === undefined ? undefined : parseFloat(datum.value);
        const precision = ISSUE?.[issueId]?.precision;

        let displayValue;

        if (value === undefined) {
          displayValue = undefined;
        } else if (value >= 10 ** precision) {
          displayValue = formatNumberCommas(value.toFixed(0));
        } else if (value !== 0) {
          displayValue = formatNumberCommas(value.toPrecision(precision));
        }

        this.data[year] = Object.assign(datum, {
          value,
          displayValue,
        });
      });

      this.last = CONFIG.REPORTING_YEAR;
      this.secondLast = CONFIG.REPORTING_YEAR - 1;

      this.trendType = getTrendType(target, this.data?.[this.secondLast]?.value, this.data?.[this.last]?.value);
      this.percentChange = getPercentChange(this.data?.[this.secondLast]?.value, this.data?.[this.last]?.value);
    }

    this.keyYears.forEach(year => {
      const datum = this.data[year];

      if (issueType === 'Boolean') {
        this.dataInputs[year] = {
          type: 'select',
          // eslint-disable-next-line max-len
          initialValue:
            datum !== undefined &&
            datum?.value !== undefined &&
            datum?.value !== null &&
            Boolean(parseInt(datum?.value, 10)),
          options: [
            {value: true, name: 'Yes'},
            {value: false, name: 'No'},
          ],
          pending: datum?.pending === true ? true : false,
        };
      } else {
        this.dataInputs[year] = {
          type: 'number',
          initialValue: datum?.displayValue,
          pending: datum?.pending === true ? true : false,
        };
      }
    });
  }

  edit = () => {
    this.setState({editing: true});
  };

  onChangeInput = (i, value) => {
    const newFormValues = this.state.formValues;
    newFormValues[i] = value === this.dataInputs[this.keyYears[i]].initialValue ? null : value;

    this.setState({
      formValues: newFormValues,
    });
  };

  saveEdits = async event => {
    try {
      event.preventDefault();

      const companyId = this.props.authUser.companyPortals[0].id;
      const issueId = this.props.data.id;
      const years = this.keyYears;

      const submissionData = this.state.formValues
        .map((value, i) => {
          return {
            issueId,
            value,
            year: years[i],
          };
        })
        .filter(entry => entry.value !== null);

      await this.props.dispatch(restate(companyId, submissionData));
      await this.props.dispatch(getAnnualPendingIssueData(companyId));

      years.forEach((year, index) => {
        const datum = this.data[year];
        datum.value = datum.displayValue =
          this.state.formValues[index] === null ? datum.value : this.state.formValues[index];
        datum.pending = true;

        if (this.props.data.issueType === 'Boolean') {
          this.dataInputs[year] = {
            type: 'select',
            // eslint-disable-next-line max-len
            initialValue:
              datum !== undefined && datum.value !== undefined && datum.value !== null
                ? Boolean(parseInt(datum.value, 10))
                : DATA_STATUS.NONE,
            options: [
              {value: true, name: 'Yes'},
              {value: false, name: 'No'},
            ],
            pending: datum.pending === true ? true : false,
          };
        } else {
          this.dataInputs[year] = {
            type: 'number',
            initialValue: datum.displayValue,
            pending: datum.pending === true ? true : false,
          };
        }
      });

      this.setState({
        formValues: reportingYears().map(() => null),
      });

      toastSuccess(`
        Your restate request was submitted successfully!<br/>
        In the meantime, we'll continue to show the value we have on file.
      `);

      this.stopEditing();
    } catch (error) {
      toastError(`
        There was a problem processing your restate request.<br/>
        Please try again later, or contact support.
      `);
    }
  };

  stopEditing = () => {
    this.setState({editing: false});
  };

  renderButton() {
    if (!this.state.editing) {
      const pendingClass = this.props.data.hasPendingChangeRequests ? 'btn-pending text-static-navy ' : 'btn-restate ';
      return (
        <button className={pendingClass + ' btn btn-link p-1 no-border'} onClick={this.edit}>
          {this.props.data.hasPendingChangeRequests ? 'Review' : 'Refine'}
        </button>
      );
    }

    if (this.state.formValues.some(value => value !== null)) {
      return (
        <button className="btn-restate btn btn-link p-1 no-border" onClick={this.saveEdits}>
          Save
        </button>
      );
    }

    return (
      <button className="btn-restate btn btn-link p-1 no-border" onClick={this.stopEditing}>
        Cancel
      </button>
    );
  }

  issueUnit = (unit, input) => {
    if (isEmpty(input.initialValue) || input.initialValue === 'NaN') {
      return '';
    }

    switch (unit) {
      case ISSUE_UNITS.MMTCO2E:
        return ISSUE_UNITS.MTCO2E;
      default:
        return unit;
    }
  };

  issueTitle = name => {
    switch (name) {
      case 'Scope 1&2 Emissions':
        return 'Carbon Emissions';
      default:
        return name;
    }
  };

  issueDescription = description => {
    if (description === 'Emissions that contribute to the greenhouse effect (Scope 1 & 2).') {
      return 'Emissions that contribute to the greenhouse effect (Carbon Emissions).';
    }
    return description;
  };

  unitDescription = description => {
    if ((description = 'Million Metric tons (MMt) of CO2 equivalents')) {
      return 'Metric tons (Mt) of CO2 equivalents';
    }
    return description;
  };

  render() {
    const {id: issueId = 0, name = '', description = '', unit = '', unitDescription = ''} = this.props.data;

    return (
      <tr>
        <td>
          <span>{this.issueTitle(name)}</span>
          {(!isEmpty(description) || !isEmpty(unitDescription)) && (
            <Fragment>
              <Icon id={`tooltip-issue-${issueId}`} icon={faInfoCircle} className="ml-2 tooltip-info" />
              <ToolTip target={`#tooltip-issue-${issueId}`}>
                {this.issueDescription(description)}
                {!isEmpty(description) && !isEmpty(unitDescription) && (
                  <React.Fragment>
                    <br />
                    <br />
                  </React.Fragment>
                )}
                {this.unitDescription(unitDescription)}
              </ToolTip>
            </Fragment>
          )}
        </td>
        <td>
          {this.renderButton()}
          {this.props.data.hasPendingChangeRequests ? (
            <Fragment>
              <Icon id={`reviewing-${issueId}`} icon={faInfoCircle} className="ml-2 tooltip-info" />
              <ToolTip target={`reviewing-${issueId}`}>Harmony is reviewing your data.</ToolTip>
            </Fragment>
          ) : null}
        </td>
        {this.keyYears.map((year, i) => {
          const input = this.dataInputs[year];
          let pendingClass;
          if (input.pending) {
            pendingClass = ' futura-gray';
          }

          const value = () => {
            if (!!this.state.formValues[i]) {
              return this.state.formValues[i];
            }

            if (input.initialValue === 'NaN' || input.initialValue === '') {
              return DATA_STATUS.NONE;
            }

            if (!!input.initialValue && unit === ISSUE_UNITS.MMTCO2E) {
              return Number(
                convertUnits(
                  input.initialValue,
                  ISSUE_UNITS.MMTCO2E,
                  ISSUE_UNITS.MTCO2E,
                  getPrecision(input.initialValue),
                ),
              ).toFixed(2);
            }

            return input.initialValue;
          };

          return (
            <td key={`data-${year}`}>
              <Cell>
                <DisplayInputField
                  editing={this.state.editing}
                  value={value()}
                  pending={input.pending}
                  type={input.type}
                  options={input.options}
                  onChange={value => {
                    this.onChangeInput(i, value);
                  }}
                  inputClassName="mw-0 flex-1 text-right"
                />
                {input.initialValue !== undefined && unit !== 'null' && (
                  <span className={`${unit === '%' ? 'align-self-center' : 'ml-1 align-self-center'}` + pendingClass}>
                    {this.issueUnit(unit, input)}
                  </span>
                )}
              </Cell>
            </td>
          );
        })}
        {
          <td className="text-right font-weight-bold" style={{color: TREND_TEXT_COLOR[this.trendType]}}>
            {!!this.percentChange
              ? this.percentChange.toLocaleString('en-US', {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
                }) + '%'
              : DATA_STATUS.NONE}
          </td>
        }
      </tr>
    );
  }
}

IssueDataTableRowComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export const IssueDataTableRow = compose(withUser)(IssueDataTableRowComponent);
