import {createAction} from 'redux-actions';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  GET_REQUEST: 'COMPANY_ENGAGEMENT_GET_REQUEST',
  GET_RECEIVE: 'COMPANY_ENGAGEMENT_GET_RECEIVE',
};

const getRequest = createAction(ACTION.GET_REQUEST);
const getReceive = createAction(ACTION.GET_RECEIVE);

const api = {
  getCompanyEngagement: () => {
    const url = 'companyEngagement/';

    return fetchAuthJSON(url, {method: 'get'});
  },
};

export function getCompanyEngagement() {
  return dispatch => {
    dispatch(getRequest());
    return dispatch(getReceive(api.getCompanyEngagement()));
  };
}

// REDUCER
export const companyEngagement = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.GET_RECEIVE: {
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {companyEngagementData: payload}),
      );
    }
    default:
      return state;
  }
};
