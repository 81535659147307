/**
 * Normalizes a date to ensure the format of MM/DD/YYYY
 * @param {string} value The current date being entered in
 * @param {string} previousValue The previous value for the date
 * @returns {string} Returns the value, adding the elements of the recommended format
 */
export const normalizeMMDDYYYY = value => {
  const minDate = new Date(new Date().getFullYear() - 4, 0, 1);
  const currentDate = new Date(value);

  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (value.length === 3 && onlyNums.length === 2) {
    return `${onlyNums}/`;
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (value.length === 6 && onlyNums.length === 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/`;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
};
