import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import {ButtonUploader, FileList, MultiUploader} from 'app/components';

class MultiUploaderWrapper extends Component {
  render() {
    const {browseButtonProps, ...props} = this.props;
    return (
      <MultiUploader {...props} {...props.multiUploaderProps} uploaderProps={props.uploaderProps}>
        {({files, isBusy, percentComplete, showUploader, removeFile, uploaderProps}) => (
          <Fragment>
            <FileList className="mb-4" files={files} onRemoveFileClick={removeFile} />
            {showUploader && (
              <ButtonUploader
                className="mb-4"
                {...browseButtonProps}
                isBusy={isBusy}
                percentComplete={percentComplete}
                uploaderProps={uploaderProps}
              />
            )}
          </Fragment>
        )}
      </MultiUploader>
    );
  }
}

MultiUploaderWrapper.propTypes = {
  browseButtonProps: PropTypes.object,
  className: PropTypes.string,
  onFileAdded: PropTypes.func,
  onFilesChanged: PropTypes.func,
  onFileRemoved: PropTypes.func,
  onFinish: PropTypes.func,
  multiUploaderProps: PropTypes.object,
  uploaderProps: PropTypes.object,
};

export {MultiUploaderWrapper};
