import {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {TagBarProps, TagProps} from 'v2/utilities/types/components/TagHeader';
import {Container, FlexContainer} from '../atoms/Containers';
import {ParagraphS} from '../atoms/Typeface';
import {Tag} from '../molecules/Tag';

const Wrapper = styled(Container)`
  display: flex;
  background: ${({theme}) => theme.colors.white};
`;

const TagContainer = styled(FlexContainer).attrs({
  items: 'center',
  padding: '0.75rem 0 0.75rem 2rem',
  gap: '1rem',
})`
  min-height: 4.25rem;
`;

const Label = styled(ParagraphS)`
  position: relative;
  top: -0.0625rem;
  white-space: nowrap;
`;

const renderTags = ({tags}: TagBarProps) => {
  const tagTypes: {[key: string]: TagProps[]} = {
    type: tags.filter(({listItemId}) => listItemId === 'type'),
    class: tags.filter(({listItemId}) => listItemId === 'class'),
    region: tags.filter(({listItemId}) => listItemId === 'region'),
    strategy: tags.filter(({listItemId}) => listItemId === 'strategy'),
    manager: tags.filter(({listItemId}) => listItemId === 'manager'),
    index: tags.filter(({listItemId}) => listItemId === 'index'),
    product: tags.filter(({listItemId}) => listItemId === 'product'),
  };

  const sortedTags: TagProps[] = [];

  Object.keys(tagTypes).forEach(key => {
    const current = tagTypes[key];

    if (current.length > 1) {
      sortedTags.push({
        label: `${key.charAt(0).toUpperCase() + key.slice(1)} (${current.length})`,
        value: key,
        selected: true,
        onClose: () => {
          current.forEach(({onClose}) => onClose?.());
        },
        listItemId: key,
      });
    } else if (current.length === 1) {
      sortedTags.push(current[0]);
    }
  });

  return sortedTags.map((tag, index) => <Tag key={index} {...tag} />);
};

export const TagBar = ({tags}: TagBarProps) => {
  return (
    <Wrapper>
      <FlexContainer margin="0 auto" flex={1} items="center">
        <Container height="100%">
          <FlexContainer height="4.25rem" items="center">
            <Label>Selected Filters:</Label>
          </FlexContainer>
        </Container>
        <TagContainer id="tag-container" wrap="wrap" direction="row" flex={1}>
          {renderTags({tags})}
        </TagContainer>
      </FlexContainer>
    </Wrapper>
  );
};
