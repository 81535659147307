import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {uniqueId} from 'lodash';

import faInfoSquare from 'app/fontawesome-pro-light/faInfoSquare';
import {APreventDefault} from 'app/components';
import {ToolTip} from '.';

class TooltipLink extends Component {
  constructor(props) {
    super(props);
    this.fallbackId = 'tooltipLink_' + uniqueId();
  }

  render() {
    const {id = this.fallbackId, children, className, label} = this.props;
    return (
      <Fragment>
        <APreventDefault id={id} className={className} onClick={() => false}>
          <Icon icon={faInfoSquare} /> {label}
        </APreventDefault>
        <ToolTip target={id}>{children}</ToolTip>
      </Fragment>
    );
  }
}

TooltipLink.defaultProps = {label: 'More info'};

TooltipLink.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.any,
  className: PropTypes.string,
};

export {TooltipLink};
