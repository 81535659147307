import React, {Component} from 'react';
import {CONFIG} from '../../constants';

export class HarmonyLogo extends Component {
  render() {
    const {color, className, containerProps, size} = this.props;
    return (
      <div
        {...containerProps}
        style={{
          width: 'auto',
          height: size,
        }}
      >
        <img
          className={`h-100 ${className || ''}`}
          src={color ? `${CONFIG.ASSETS_URL}/logos/ha-logo_blue.svg` : `${CONFIG.ASSETS_URL}/logos/ha-logo_white.svg`}
        />
      </div>
    );
  }
}

HarmonyLogo.defaultProps = {
  size: '48px',
};
