import React, {useCallback} from 'react';
import styled from 'styled-components/macro';
import {get, isArray, isString, pick} from 'lodash';
import {Field, Form} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {FORM_ERROR} from 'final-form';
import {addMinutes, format} from 'date-fns';

import {errorRequiredFields, isImpersonating, normalizeMMDDYYYY} from 'app/utilities';
import {CardContainer, Container, FlexContainer} from 'v2/components/atoms/Containers';
import {Separator} from 'v2/components/atoms/Separator';
import {Theme} from 'v2/components/atoms/theme';
import {ParagraphM, TitleM} from 'v2/components/atoms/Typeface';
import {Checkbox} from 'v2/components/molecules/Checkbox';
import {NumberedPoint} from 'v2/components/molecules/NumberedPoint';
import {TextAreaFormGroup} from 'v2/components/molecules/TextareaInput';
import {OnboardingFooter} from 'v2/components/organisms/OnboardingFooter';
import {ROUTE} from 'v2/constants/routes';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {RadioField as RadioFieldComponent} from '../../../components/molecules/RadioField';
import AutoSave from './AutoSave';
import {FormFeedback, MultiUploaderWrapper} from 'app/components';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {Tooltip} from 'v2/components/molecules/Tooltip';
import {Position, Size} from 'v2/components/interfaces/Tooltip';
import {handleUpdateForm} from './handleUpdateForm';
import {animateScroll} from 'react-scroll';
import {push} from 'connected-react-router';
import {useUpdateFirmInvitationMutation} from 'v2/redux/harmonyOnboardingApi';
import {SpinnerDisplay} from 'app/components/Spinner/SpinnerDisplay';
import {TextFormGroup} from 'app/components/forms';
import {AUTOCOMPLETE} from 'app/constants';
import {retrieveFirstFileKeyFromArray, retrieveFirstFileKeyIfTrueFromArray} from 'app/utilities/fileFunctions';
import {parseISO} from 'date-fns/esm';

const RadioField = styled(RadioFieldComponent)`
  margin: 1.875rem 0 0;
`;

const Title = styled(TitleM)`
  text-transform: none;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.9375rem;
  padding: 2.5625rem 1.125rem;
`;

const Label = styled(ParagraphM)`
  margin: 0;
`;

const INTEGRATION_OPTIONS = [
  {
    key: 'hasQualitativeAnalysis',
    label: 'Qualitative Analysis',
    tooltip:
      'Reviewing company attributes like economy, industry, strategy, or quality of management to identify material ESG factors',
  },
  {
    key: 'hasScreeningConsideration',
    label: 'Screening',
    tooltip:
      'Excluding specific companies, industries, or countries from an investment portfolio based on ESG factors or risks',
  },
  {
    key: 'hasProxyVoting',
    label: 'Proxy Voting',
    tooltip:
      'Exercising voting rights on management and/or shareholder resolutions to formally express views on relevant ESG matters',
  },
  {
    key: 'hasDoNotIntegrate',
    label: 'Do Not Integrate',
  },
  {
    key: 'hasQuantitativeAnalysis',
    label: 'Quantitative Analysis',
    tooltip: 'Assessing impact of material ESG factors using measured data or models',
  },
  {
    key: 'hasThematicInvestment',
    label: 'Thematic Investment',
    tooltip: 'Identifying and allocating capital to themes or assets related to certain ESG outcomes',
  },
  {
    key: 'hasCompanyEngagement',
    label: 'Company Engagement',
    tooltip:
      'Communicating with current or potential investees, conducted with the purpose of improving practice on an ESG issue',
  },
];

export const UT3ESGIntegration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {invitationCode, onboardingUser, onboardingSteps, requestedStep, isLoading} = useOnboardingUser();
  const [updateInvitationFirm] = useUpdateFirmInvitationMutation();
  const [autoSaveError, setAutoSaveError] = React.useState<string | null>();

  const onboardingStep = get(requestedStep, 'step', '');
  const nextRoute = get(onboardingSteps, 'ESG_ASSOCIATIONS.route.path', '').replace(':invitationCode', invitationCode);

  const validate = (values: any) => {
    const errors: any = errorRequiredFields(values, []);

    return errors;
  };

  const initialValues = React.useMemo(() => {
    const user = get(onboardingUser, 'user.firm', {});
    const values = pick(user, [
      'name',
      'url',
      'adv1FilePath',
      'adv2FilePath',
      'employees',
      'esgApproach',
      'esgMeasurement',
      'esgPolicyFilePath',
      'esgPolicyEstablishedAt',
      'hasESGCommitteeOfficer',
      'hasEsgTraining',
      'hasScreeningConsideration',
      'hasEsgPolicy',
      'hasQualitativeAnalysis',
      'hasProxyVoting',
      'hasDoNotIntegrate',
      'hasQuantitativeAnalysis',
      'hasThematicInvestment',
      'hasCompanyEngagement',
      'griDocFilePath',
      'hasGriAssociation',
      'hasPriAssociation',
      'priDocFilePath',
    ]);

    // Format incomming date from upload
    if (values.esgPolicyEstablishedAt) {
      const date = new Date(values.esgPolicyEstablishedAt);
      const isoDate = date.toISOString();

      const parsedTime = parseISO(isoDate);

      values.esgPolicyEstablishedAt = format(addMinutes(parsedTime, parsedTime.getTimezoneOffset()), 'MM/dd/yyyy');
    }

    return values;
  }, [onboardingUser]);

  const handleValues = useCallback((values: typeof initialValues) => {
    if (values.hasEsgPolicy === (true || 'true')) {
      // convert arrays to files
      values.adv1FilePath = retrieveFirstFileKeyFromArray(values.adv1FilePath);
      values.adv2FilePath = retrieveFirstFileKeyFromArray(values.adv2FilePath);
      values.priDocFilePath = retrieveFirstFileKeyIfTrueFromArray(values.priDocFilePath, values.hasPriAssociation);
      values.griDocFilePath = retrieveFirstFileKeyIfTrueFromArray(values.griDocFilePath, values.hasGriAssociation);
      values.esgPolicyFilePath = retrieveFirstFileKeyFromArray(values.esgPolicyFilePath);
      values.esgPolicyEstablishedAt = values.esgPolicyEstablishedAt || null;
    }

    if (isArray(values.esgPolicyFilePath)) {
      if (values.esgPolicyFilePath.length) {
        values.esgPolicyFilePath = values.esgPolicyFilePath[0]?.fileKey;
      } else {
        return null;
      }
    }

    return values;
  }, []);

  const handleExit = () => {
    return isImpersonating() ? dispatch(removeImpersonationTokensUser()) : dispatch(logout());
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const res = await handleUpdateForm({
          handleUpdate: updateInvitationFirm,
          invitationCode,
          onboardingStep,
          values: handleValues(values),
        });

        if (!res?.[FORM_ERROR]) {
          animateScroll.scrollToTop();
          dispatch(push(nextRoute));
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, handleValues, invitationCode, nextRoute, onboardingStep, updateInvitationFirm],
  );

  const handleGoBack = () => {
    history.push(ROUTE.UT3.ONBOARDING.MANAGER_INFORMATION.path.replace(':invitationCode', invitationCode));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        // Reset checkbox fields
        setValues: (_, state, utils) => {
          utils.changeValue(state, 'hasQualitativeAnalysis', () => false);
          utils.changeValue(state, 'hasQuantitativeAnalysis', () => false);
          utils.changeValue(state, 'hasScreeningConsideration', () => false);
          utils.changeValue(state, 'hasThematicInvestment', () => false);
          utils.changeValue(state, 'hasProxyVoting', () => false);
          utils.changeValue(state, 'hasCompanyEngagement', () => false);
        },
      }}
      keepDirtyOnReinitialize
    >
      {({handleSubmit, values, error, errors, submitError, submitFailed, invalid, form: {submit, mutators}, dirty}) => {
        const isErrorFormFeedbackVisible = !!error || !!autoSaveError || (!!submitFailed && invalid);
        // Fix to prevent component from iterating the name string
        const fileUploadValue = () => {
          const value = values.esgPolicyFilePath;
          if (isString(value)) {
            return [{name: value}];
          }
          return value;
        };

        return (
          <form method="POST" onSubmit={handleSubmit}>
            <AutoSave
              debounce={1000}
              disabled={!dirty}
              onSave={async values => {
                const res = await handleUpdateForm({
                  handleUpdate: updateInvitationFirm,
                  invitationCode,
                  onboardingStep,
                  values: handleValues(values),
                });
                if (res?.[FORM_ERROR]) {
                  setAutoSaveError(res[FORM_ERROR]);
                } else {
                  setAutoSaveError(null);
                }
              }}
              values={values}
              errors={errors}
            />
            <CardContainer>
              <FlexContainer direction="column" fullWidth>
                <Container padding="0 0 2.1875rem">
                  <Title color={Theme.colors.dark}>ESG Integration</Title>
                </Container>
                {!!submitError && (
                  <Container padding="0 0 0">
                    <FormFeedback message={submitError} visible={isErrorFormFeedbackVisible} children={undefined} />
                  </Container>
                )}
                <FlexContainer flex={1} gap="2.5625rem">
                  <FlexContainer flex={1}>
                    <FlexContainer flex={1} items="flex-start" gap="1.4375rem">
                      <NumberedPoint number="1" />
                      <FlexContainer flex={1} direction="column" margin="0.375rem 0 1.5rem">
                        <FlexContainer flex={1}>
                          <ParagraphM color={Theme.colors.dark}>Do you have an ESG Committee / Officer?</ParagraphM>
                        </FlexContainer>
                        <FlexContainer padding="1.875rem 0 0">
                          <RadioField field="hasESGCommitteeOfficer" adjustChecked />
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                  <FlexContainer flex={1}>
                    <FlexContainer flex={1} items="flex-start" gap="1.4375rem">
                      <NumberedPoint number="2" />
                      <FlexContainer flex={1} direction="column" margin="0.375rem 0 1.5rem">
                        <FlexContainer flex={1}>
                          <ParagraphM color={Theme.colors.dark}>
                            Do you provide ESG training to the investment team?
                          </ParagraphM>
                        </FlexContainer>
                        <FlexContainer gap="3.6875rem" padding="1.875rem 0 0">
                          <RadioField field="hasEsgTraining" adjustChecked />
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <Separator />
              <FlexContainer flex={1} direction="column" padding="2.1875rem 0 0">
                <FlexContainer flex={1} gap="1.4375rem" items="center" margin="0.375rem 0">
                  <NumberedPoint number="3" />
                  <FlexContainer flex={1}>
                    <ParagraphM color={Theme.colors.dark}>
                      How do you integrate ESG considerations into your investment process?
                    </ParagraphM>
                  </FlexContainer>
                </FlexContainer>
                <GridContainer>
                  {INTEGRATION_OPTIONS.map(({key, label, tooltip}) => (
                    <Field name={key} key={key} type="checkbox">
                      {props => {
                        const hasDoNotIntegrate: boolean =
                          key !== 'hasDoNotIntegrate' && Boolean(values.hasDoNotIntegrate);
                        const isChecked = hasDoNotIntegrate ? false : props.input.checked;

                        const handleChange = (e: any) => {
                          if (key === 'hasDoNotIntegrate') {
                            mutators.setValues();
                          }

                          return props.input.onChange(e);
                        };

                        return (
                          <FlexContainer items="center" justify="flex-start" gap="0.75rem">
                            <Checkbox
                              id={key}
                              {...props.input}
                              checked={isChecked}
                              onChange={handleChange}
                              disabled={hasDoNotIntegrate}
                            />
                            <Label as="label" htmlFor={key} color={Theme.colors.dark}>
                              {label}
                            </Label>
                            {tooltip && (
                              <Tooltip
                                position={Position.Right}
                                size={Size.Large}
                                color="#FFFFFF"
                                backgroundColor={Theme.colors.blue_1}
                              >
                                <strong>{label}</strong>
                                <br />
                                <span>{tooltip}</span>
                              </Tooltip>
                            )}
                          </FlexContainer>
                        );
                      }}
                    </Field>
                  ))}
                </GridContainer>
              </FlexContainer>
              <Separator />
              <FlexContainer flex={1} items="flex-start" gap="2.5625rem" padding="2.1875rem 0">
                <FlexContainer flex={1} direction="column">
                  <FlexContainer flex={1} gap="1.4375rem">
                    <NumberedPoint number="4" />
                    <FlexContainer flex={1}>
                      <ParagraphM color={Theme.colors.dark}>
                        Describe your organization's approach to integrating ESG.
                      </ParagraphM>
                    </FlexContainer>
                  </FlexContainer>
                  <Container padding="2.375rem 0 0">
                    <Field
                      name="esgApproach"
                      component={TextAreaFormGroup}
                      labelHint="(1000 character max)"
                      maxLength="1000"
                    />
                  </Container>
                </FlexContainer>
                <FlexContainer flex={1} direction="column">
                  <FlexContainer flex={1} gap="1.4375rem">
                    <NumberedPoint number="5" />
                    <FlexContainer flex={1}>
                      <ParagraphM color={Theme.colors.dark}>Describe how your organization measures ESG.</ParagraphM>
                    </FlexContainer>
                  </FlexContainer>
                  <Container padding="2.375rem 0 0">
                    <Field
                      name="esgMeasurement"
                      component={TextAreaFormGroup}
                      labelHint="(500 character max)"
                      maxLength="500"
                    />
                  </Container>
                </FlexContainer>
              </FlexContainer>
              <Separator />
              <FlexContainer flex={1} items="flex-start" gap="1.4375rem" padding="2.1875rem 0 0">
                <NumberedPoint number="6" />
                <FlexContainer flex={1} direction="column" margin="0.375rem 0">
                  <FlexContainer flex={1}>
                    <ParagraphM color={Theme.colors.dark}>Do you have an organization-level ESG Policy?</ParagraphM>
                  </FlexContainer>
                  <FlexContainer gap="3.6875rem" padding="1.875rem 0 1.5rem">
                    <RadioField field="hasEsgPolicy" adjustChecked />
                  </FlexContainer>
                  {(values?.hasEsgPolicy === 'true' || values?.hasEsgPolicy === true) && (
                    <>
                      <FlexContainer padding="1.5rem 0 0">
                        <ParagraphM color={Theme.colors.dark}>
                          If so, please enter the date it was established and upload below.
                        </ParagraphM>
                      </FlexContainer>
                      <FlexContainer padding="1.875rem 0 0">
                        <Field
                          label="ESG Policy established on"
                          name="esgPolicyEstablishedAt"
                          type="text"
                          // @ts-ignore
                          component={TextFormGroup}
                          autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                          format={normalizeMMDDYYYY}
                          className="col-1"
                          maxLength="10"
                          placeholder="MM/DD/YYYY"
                          variant="gray"
                        />
                      </FlexContainer>
                      <FlexContainer>
                        <Label as="label" htmlFor="name" color={Theme.colors.dark}>
                          Upload ESG Policy (PDF)
                        </Label>
                      </FlexContainer>
                      <FlexContainer padding="1.5rem 0 0">
                        <Field
                          name="esgPolicyFilePath"
                          component={props => {
                            return (
                              <MultiUploaderWrapper
                                {...props.input}
                                uploaderProps={{
                                  accept: 'application/pdf, .pdf',
                                }}
                                value={fileUploadValue()}
                              />
                            );
                          }}
                        />
                      </FlexContainer>
                    </>
                  )}
                </FlexContainer>
              </FlexContainer>
            </CardContainer>
            <OnboardingFooter
              backLabel="Return to Manager Information"
              onSave={() => {
                submit()?.then(() => handleExit());
              }}
              onContinue={() => submit()}
              onBack={handleGoBack}
            />
            <SpinnerDisplay isActive={isLoading} />
          </form>
        );
      }}
    </Form>
  );
};
