import {createAction} from 'redux-actions';
import get from 'lodash/get';
import map from 'lodash/map';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';
import {isNil} from 'lodash';

const initialState = {
  hasError: false,
  isFetching: false,
};

// ACTION
export const ACTION = {
  ADD_USER_BOOKMARK_REQUEST: 'SECURITY_ADD_USER_BOOKMARK_REQUEST',
  ADD_USER_BOOKMARK_RECEIVE: 'SECURITY_ADD_USER_BOOKMARK_RECEIVE',
  GET_ALL_PAGED_REQUEST: 'SECURITY_GET_ALL_PAGED_REQUEST',
  GET_ALL_PAGED_RECEIVE: 'SECURITY_GET_ALL_PAGED_RECEIVE',
  GET_BY_ID_REQUEST: 'SECURITY_GET_BY_ID_REQUEST',
  GET_BY_ID_RECEIVE: 'SECURITY_GET_BY_ID_RECEIVE',
  GET_ALL_PAGED_UT3_REQUEST: 'SECURITY_GET_ALL_PAGED_UT3_REQUEST',
  GET_ALL_PAGED_UT3_RECEIVE: 'SECURITY_GET_ALL_PAGED_UT3_RECEIVE',
  REMOVE_USER_BOOKMARK_REQUEST: 'SECURITY_REMOVE_USER_BOOKMARK_REQUEST',
  REMOVE_USER_BOOKMARK_RECEIVE: 'SECURITY_REMOVE_USER_BOOKMARK_RECEIVE',
  GET_BY_STRATEGY_REQUEST: 'GET_BY_STRATEGY_REQUEST',
  GET_BY_STRATEGY_RECEIVE: 'GET_BY_STRATEGY_RECEIVE',
};

const securityAddUserBookmarkRequest = createAction(ACTION.ADD_USER_BOOKMARK_REQUEST);
const securityAddUserBookmarkReceive = createAction(ACTION.ADD_USER_BOOKMARK_RECEIVE);
const securityGetAllRequest = createAction(ACTION.GET_ALL_PAGED_REQUEST);
const securityGetAllReceive = createAction(ACTION.GET_ALL_PAGED_RECEIVE);
const securityGetByIdRequest = createAction(ACTION.GET_BY_ID_REQUEST);
const securityGetByIdReceive = createAction(ACTION.GET_BY_ID_RECEIVE);
const securityGetPagedUT3Request = createAction(ACTION.GET_ALL_PAGED_UT3_REQUEST);
const securityGetPagedUT3Receive = createAction(ACTION.GET_ALL_PAGED_UT3_RECEIVE);
const securityRemoveUserBookmarkRequest = createAction(ACTION.REMOVE_USER_BOOKMARK_REQUEST);
const securityRemoveUserBookmarkReceive = createAction(ACTION.REMOVE_USER_BOOKMARK_RECEIVE);
const securityGetByStrategyRequest = createAction(ACTION.GET_BY_STRATEGY_REQUEST);
const securityGetByStrategyReceive = createAction(ACTION.GET_BY_STRATEGY_RECEIVE);

const api = {
  addUserBookmark: securityIsin => fetchAuthJSON(`userSecurityBookmark/${securityIsin}`, {method: 'post'}),
  getAllPaged: options => {
    let url = 'securities?';
    let sortField = options.sortField || '';
    let sortOrder = options.sortOrder || '';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;

    if (sortField === 'name') {
      sortField = 'companies.name';
    }
    url += `&sort=${sortField}:${sortOrder}`;
    url += `&search=${options.search || ''}`;
    if (options.filter) {
      // format field:direction
      url += `&filter=${options.filter}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getById: (securityId, strategyId) => {
    let url = `securities/${securityId}`;
    if (strategyId) {
      url += `/${strategyId}`;
    }
    return fetchAuthJSON(url, {method: 'get'});
  },
  getByStrategyId: (strategyId, options) => {
    let url = `securities/strategy/${strategyId}?`;
    if (!isNil(options.sortField)) {
      // format field:direction
      url += `sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getAllPagedUT3: (options, strategyId) => {
    let url = 'securities';
    url += `/list/${strategyId}?`;
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    url += `&search=${options.search || ''}`;

    return fetchAuthJSON(url, {method: 'get'});
  },
  removeUserBookmark: securityIsin => fetchAuthJSON(`userSecurityBookmark/${securityIsin}`, {method: 'delete'}),
};

export function addUserBookmark(securityIsin) {
  return dispatch => {
    dispatch(securityAddUserBookmarkRequest({securityIsin}));
    return dispatch(securityAddUserBookmarkReceive(api.addUserBookmark(securityIsin)));
  };
}

export function getAllPagedSecurities(options) {
  return dispatch => {
    dispatch(securityGetAllRequest());
    return dispatch(securityGetAllReceive(api.getAllPaged(options)));
  };
}

export function getByIdSecurity(securityId, strategyId) {
  return dispatch => {
    dispatch(securityGetByIdRequest());
    return dispatch(securityGetByIdReceive(api.getById(securityId, strategyId)));
  };
}

export function getSecurityByStrategyId(strategyId, options) {
  return dispatch => {
    dispatch(securityGetByStrategyRequest());
    return dispatch(securityGetByStrategyReceive(api.getByStrategyId(strategyId, options)));
  };
}

export function getAllPagedUT3Securities(options, strategyId) {
  return dispatch => {
    dispatch(securityGetPagedUT3Request());
    return dispatch(securityGetPagedUT3Receive(api.getAllPagedUT3(options, strategyId)));
  };
}

export function removeUserBookmark(securityIsin) {
  return dispatch => {
    dispatch(securityRemoveUserBookmarkRequest({securityIsin}));
    return dispatch(securityRemoveUserBookmarkReceive(api.removeUserBookmark(securityIsin)));
  };
}

const getNewStateWithSecurityBookmark = (state, securityIsin, isBookmarked) => {
  let newState = state;

  // Security Detail match
  if (get(state, 'securityDetail.isin') === securityIsin) {
    newState = {
      ...newState,
      securityDetail: {
        ...newState.securityDetail,
        isBookmarked,
      },
    };
  }

  // Security Listing match
  let foundMatch = false;
  const allSecurities = map(newState.allSecurities, security => {
    if (security.isin === securityIsin) {
      foundMatch = true;
      return {
        ...security,
        isBookmarked,
      };
    }
    return security;
  });
  if (foundMatch) {
    return {
      ...newState,
      allSecurities,
    };
  }

  return newState;
};

// REDUCER
export const security = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_ALL_PAGED_REQUEST:
    case ACTION.GET_BY_ID_REQUEST:
    case ACTION.GET_ALL_PAGED_UT3_REQUEST:
    case ACTION.GET_BY_STRATEGY_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.ADD_USER_BOOKMARK_REQUEST:
      // Optimistic update assumes the api call will succeed.
      return getNewStateWithSecurityBookmark(state, payload.securityIsin, true);
    case ACTION.REMOVE_USER_BOOKMARK_REQUEST:
      // Optimistic update assumes the api call will succeed.
      return getNewStateWithSecurityBookmark(state, payload.securityIsin, false);
    case ACTION.GET_ALL_PAGED_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError ? {error: payload} : {allSecurities: payload.items, allSecuritiesTotal: payload.total},
        ),
      );
    case ACTION.GET_BY_ID_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {securityDetail: payload}),
      );
    case ACTION.GET_ALL_PAGED_UT3_RECEIVE:
      return assignMergedState(
        Object.assign(
          {isFetching: false},
          hasError ? {error: payload} : {allSecurities: payload.items, allSecuritiesTotal: payload.total},
        ),
      );
    case ACTION.GET_BY_STRATEGY_RECEIVE:
      return assignMergedState(
        Object.assign({isFetching: false}, hasError ? {error: payload} : {currentStrategies: payload}),
      );
    default:
      return state;
  }
};
