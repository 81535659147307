// Allows config vars to be set via Heroku environment variables
// https://github.com/mars/create-react-app-buildpack#runtime-configuration
import runtimeEnv from '@mars/heroku-js-runtime-env';

// Override any buildtime variables with those set via Heroku
const mergedRuntimeConfig = Object.assign({}, process.env, runtimeEnv());

// Alias and apply default values for required constants
export const CONFIG = {
  API_URL: mergedRuntimeConfig.REACT_APP_API_URL || 'http://localhost:10003',
  TYPEORM_URL: mergedRuntimeConfig.REACT_APP_TYPEORM_API_URL || 'https://api.dev.harmonyanalytics.org',
  ASSETS_URL: mergedRuntimeConfig.REACT_APP_ASSETS_URL,
  BADGE_ASSETS_YEAR: 2020,
  REPORTING_YEAR: 2022,
  REPORTING_YEAR_OFFSET: 6,
  ADMIN_REPORTING_YEAR: new Date().getFullYear() - 1,
  ADMIN_REPORTING_YEAR_OFFSET: 6,
  MANAGER_EMPLOYEE_DATA_YEAR: 2021,
  HIDE_PRODUCT_REPORT: JSON.parse(mergedRuntimeConfig.REACT_APP_HIDE_PRODUCT_REPORT || false),
  SOURCE_VERSION: mergedRuntimeConfig.REACT_APP_SOURCE_VERSION || 'local',
  TYPEKIT_KIT_ID: 'lpn6mvs',
  UPLOADS_URL: mergedRuntimeConfig.REACT_APP_UPLOADS_URL,
};
