import styled from 'styled-components/macro';
import {Theme} from '../atoms/theme';
import {Body2, Overline, Title5} from '../atoms/Typeface';

const StyledPill = styled.div<{color?: string; stroke?: string}>`
  width: 4.625rem;
  height: 1.188rem;
  border: ${({theme, color, stroke}) => (stroke ?? '.0625rem') + ' solid ' + (color ?? theme.colors.dark)};
  box-sizing: border-box;
  border-radius: 0.594rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme, color}) => color ?? theme.colors.black};
`;

const LargeStyledPill = styled.div<{color?: string; stroke?: string}>`
  width: 99px;
  height: 28px;
  border: ${({theme, color, stroke}) => (stroke ?? '.0625rem') + ' solid ' + (color ?? theme.colors.dark)};
  box-sizing: border-box;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme, color}) => color ?? theme.colors.black};
`;

const GreyedPill = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 6.4375rem;
  height: 1.75rem;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;

  background: ${({theme}) => theme.colors.grey_2};
  border-radius: 0.8438rem;
`;

export const PlainPill = ({text}: {text?: string}) => {
  return (
    <StyledPill>
      <Overline>{text}</Overline>
    </StyledPill>
  );
};

export const ColorPill = ({
  text = '',
  stroke,
  color = Theme.colors.harmony,
}: {
  text?: string;
  stroke?: string;
  color?: string;
}) => {
  return (
    <StyledPill color={color} stroke={stroke}>
      <Overline color={color}>{text}</Overline>
    </StyledPill>
  );
};

export const GreyPill: React.FC<{}> = ({children}) => {
  return <GreyedPill>{children}</GreyedPill>;
};

export const LargeColorPill = ({
  text = '',
  stroke,
  color = Theme.colors.harmony,
}: {
  text?: string;
  stroke?: string;
  color?: string;
}) => {
  return (
    <LargeStyledPill color={color} stroke={stroke}>
      <Body2 color={color}>{text}</Body2>
    </LargeStyledPill>
  );
};
