import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {debounce, get} from 'lodash';
import moment from 'moment';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {animateScroll} from 'react-scroll';

// Local Imports
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {Alert, Button, BootstrapTableRemote, MissingEngagementLetters, TooltipLink} from 'app/components';
import {getPaged} from 'app/redux/adminSecurity';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {useGetOldestManualEditCompanyIssueQuery} from 'v2/redux/typeormEndpoints';
import {ParagraphS} from 'v2/components/atoms/Typeface';
import {ROUTE as V2_ROUTES} from 'v2/constants/routes';
import {dynamicRoute} from 'v2/utilities/helpers';
import styled from 'styled-components';

const Cell = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`;

const columns = [
  {
    dataField: 'name',
    text: 'Company Name',
    sort: true,
    formatter: (cell, row) => <div>{row.name}</div>,
    headerStyle: {minWidth: 300},
  },
  {
    dataField: 'ticker',
    text: 'Ticker',
    sort: true,
    formatter: (cell, row) => (
      <div>
        {row.ticker && (
          <Cell>
            <span>
              {row.ticker} {row.exchange && <Fragment>({row.exchange})</Fragment>}
            </span>
          </Cell>
        )}
      </div>
    ),
    headerStyle: {minWidth: 300},
  },
  {
    dataField: 'companyId',
    text: 'Company Id',
    sort: true,
    formatter: (cell, row) => <div>{row.company.id}</div>,
    headerStyle: {minWidth: 30},
  },
  {
    dataField: 'isin',
    text: 'ISIN',
    sort: true,
    formatter: (cell, row) => <div>{row.isin}</div>,
    headerStyle: {minWidth: 30},
  },
  {
    dataField: 'edit',
    text: '',
    formatter: () => <Icon icon={faPencil} />,
    headerFormatter: () => <span className="sr-only">Edit</span>,
    headerStyle: {width: 30},
  },
];

function AdminSecurityListingPage(props) {
  const [state, setState] = useState({
    search: props.search,
    filter: props.filter,
  });

  /**
   * Handle initial loading of the data
   */
  useEffect(() => {
    fetchData(props.sortField, props.sortOrder, props.page, props.pageSize, props.search, props.filter);

    animateScroll.scrollToTop();
  }, []);

  const updateCurrentQueryString = (
    sortField = props.sortField,
    sortOrder = props.sortOrder,
    page = props.page,
    pageSize = props.pageSize,
    search = state.search,
    filter = state.filter,
  ) => {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== props.search) {
      updatedPage = 1;
    }
    props.dispatch(push({search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search, filter)}));
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  const handleSearch = (event, search = state.search, filter = state.filter) => {
    updateCurrentQueryString(props.sortField, props.sortOrder, props.page, props.pageSize, search, filter);
  };

  const handleSearchDebounced = useCallback(debounce(handleSearch, 500), []);

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  const changeField = event => {
    const newState = Object.assign({}, state);
    newState[event.target.id] = event.target.value;
    setState(newState);
    handleSearchDebounced(null, newState.search, newState.filter);
  };

  /**
   * Handles when the table fires an event
   */
  const handleTableChange = (
    type,
    {sortField = props.sortField, sortOrder = props.sortOrder, page = props.page, sizePerPage = props.pageSize},
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && props.sortField === sortField && props.sortOrder === sortOrder) {
      return;
    }

    updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  const handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search, filter} = props;
    props.dispatch(
      push({
        // pathname: ROUTE.ADMIN_SECURITY_EDIT.path(row.isin),
        pathname: dynamicRoute({
          route: V2_ROUTES.ADMIN.EDIT_COMPANY.path,
          replace: ':companyId',
          value: row.company.id,
        }),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, filter),
      }),
    );
  };

  /**
   * Performs the actual data retrieval
   */
  const fetchData = (sortField, sortOrder, page, pageSize, search, filter) => {
    props.dispatch(
      getPaged({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        filter,
      }),
    );
  };

  const {data, listing, hasError, sortField, sortOrder, page, pageSize, totalSize} = props;
  const {data: manualEditData} = useGetOldestManualEditCompanyIssueQuery({});

  // Pass in the sort to be displayed in the table
  const defaultSorted = [];
  if (sortField) {
    defaultSorted.push({
      dataField: sortField,
      order: sortOrder || 'asc',
    });
  }

  return (
    <div className="p-content-lg">
      <div>
        {manualEditData && (
          <div>
            <ParagraphS style={{float: 'right'}}>
              {`Oldest admin data: ${moment(manualEditData.createdAt).format('MM/DD/YY')}`}
            </ParagraphS>
          </div>
        )}
        <h1>{ROUTE.ADMIN_SECURITY_LISTING.title}</h1>
        {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

        <form method="GET" onSubmit={handleSearch}>
          <div className="bootstrap-table-controls">
            <div className="bootstrap-table-search" style={{width: '65%'}}>
              <div className="form-control-and-icon">
                <input
                  autoFocus
                  className="form-control"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search by Company Name, ID, ticker, or isin"
                  value={state.search}
                  onChange={changeField}
                />
                <div className="icon">
                  <Icon icon={faSearch} />
                </div>
                <TooltipLink className="float-right mt-1" label="Search Techniques" id="adminSecurityListingSearch">
                  Surround your search with double quotes to do an exact search. Additionally when using double quotes,
                  you can do wildcard searches. Simply use a percent sign ( % ) to match any character zero or more
                  times and an underscore ( _ ) to match any character exactly one time. For example, "H_rmony%" will
                  find a result for <i>Harmony Analytics</i> as would "%Analytic_".
                </TooltipLink>
              </div>
              <div>
                <Button color="gray" type="submit">
                  Search
                </Button>
              </div>
            </div>
            {/* <div className="bootstrap-table-filter" style={{width: '35%'}}>
              <label className="col-form-label">Filter By</label>
              <select
                id="filter"
                name="filter"
                value={state.filter}
                onChange={changeField}
                className="custom-select form-control"
              >
                <option value="">Select</option>
                {map(SECURITY_LISTING_FILTER, (label, value) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div> */}
          </div>
        </form>
        <MissingEngagementLetters />
        {data && (
          <BootstrapTableRemote
            keyField="isin"
            columns={columns}
            data={data}
            page={page}
            sizePerPage={pageSize}
            totalSize={totalSize}
            onTableChange={handleTableChange}
            defaultSorted={defaultSorted}
            selectRow={{
              mode: 'radio',
              hideSelectColumn: true,
              clickToSelect: true,
              onSelect: handleOnSelect,
            }}
          />
        )}
      </div>
    </div>
  );
}

AdminSecurityListingPage.defaultProps = {totalSize: 0};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(
    ownProps,
    {
      search: '',
      page: 1,
      pageSize: 25,
      sortField: 'company.rating',
      sortOrder: 'asc',
    },
    ['filter'],
  );
  newProps.isFetching = get(state, 'adminSecurity.listing.isFetching');
  newProps.data = get(state, 'adminSecurity.listing.data');
  newProps.totalSize = get(state, 'adminSecurity.listing.totalSize');
  newProps.hasError = get(state, 'adminSecurity.listing.hasError');
  return newProps;
};

export const AdminSecurityListing = connect(mapStateToProps)(AdminSecurityListingPage);
