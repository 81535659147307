import {
  FilterListProps,
  FilterListOptionsProps,
  AppliedFiltersByOption,
} from 'v2/utilities/types/components/FilterList';
import {FlexContainer} from '../../atoms/Containers';
import {FilterListItem, FilterListOption} from '../../molecules/FilterListItem';

export const FilterList = ({
  filters = [],
  onSelect = () => {},
  getSublabel,
  disabled: listIsDisabled,
}: FilterListProps) => {
  const labelMap: Record<string, string | undefined> = {};
  for (const node of filters) {
    const subLabel = getSublabel(node);
    if (subLabel) {
      labelMap[node.id] = getSublabel(node);
    }
  }

  return (
    <FlexContainer direction="column" as="ul">
      {filters.map((node, i) => {
        const {id, disabled, label, dependencies} = node;
        const isDisabled = listIsDisabled || disabled || dependencies?.some(dep => !labelMap[dep]);

        return (
          <FilterListItem
            key={i}
            label={label}
            subLabel={labelMap[id]}
            onClick={() => {
              onSelect(node);
            }}
            disabled={isDisabled}
          />
        );
      })}
    </FlexContainer>
  );
};

export const FilterListOptions = ({options = [], values = {}, onSelect = () => {}}: FilterListOptionsProps) => {
  const handleChange = (value: AppliedFiltersByOption) => {
    onSelect(value);
  };

  return (
    <FlexContainer direction="column" as="ul">
      {options.map(({disabled, label, value}, i) => (
        <FilterListOption
          key={i}
          checked={values[value]?.checked}
          disabled={disabled}
          value={value}
          label={label}
          onChange={handleChange}
        />
      ))}
    </FlexContainer>
  );
};
