import {FC} from 'react';
import {ParagraphM} from 'v2/components/atoms/Typeface';
import styled from 'styled-components';
import {Theme} from 'v2/components/atoms/theme';
import {NumberedPoint} from 'v2/components/molecules/NumberedPoint';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 0fr 1fr;
  grid-gap: 1.5rem;
`;

// BulletItem is in first column of first row
const BulletItem = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: center;
`;
// BulletText is in second column of first row
const BulletText = styled.div`
  align-self: center;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
`;

// BulletContent is in second column of second row
const BulletContent = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;

export const BulletedSection: FC<{number: string; text: string}> = ({number, text, children}) => {
  return (
    <GridContainer>
      <BulletItem>
        <NumberedPoint number={number} />
      </BulletItem>
      <BulletText>
        <ParagraphM color={Theme.colors.dark}>{text}</ParagraphM>
      </BulletText>
      <BulletContent>{children}</BulletContent>
    </GridContainer>
  );
};
