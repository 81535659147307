/* eslint-disable max-len */
import React, {Component, Fragment} from 'react';
import {PageContainer} from '../components';

export class TableOfContentsPage extends Component {
  render() {
    const {companyName} = this.props;

    return (
      <PageContainer headerType={PageContainer.HEADER_TYPE.TYPE_1} footer={false} companyName={companyName}>
        <div className="table-contents-body-section flex-1 d-flex flex-column justify-content-between">
          <div className="table-contents-main-content-container">
            <div className={'w-100'}>
              <h1 className="table-contents-company-name-styles" style={{marginBottom: 100}}>
                {companyName}
              </h1>
              <p>
                This report is a comprehensive snapshot of your ESG integration. It draws from the same evolving
                ecosystem of data that powers the Harmony Platform. Review your managers and holdings, compare their
                performance to reference indexes and benchmarks, and track your improvement over time. Take ownership of
                your ESG integration and communicate progress towards your goals with confidence.
              </p>
            </div>
          </div>
          <div>
            <h1 className="table-contents-section-font-styles text-uppercase">Sections</h1>
            <hr style={{marginBottom: 100}} />

            <div className="d-flex flex-row justify-content-between">
              <div>
                <p className="table-contents-number-items-styles">03</p>
                <p className="table-contents-title-items-styles text-uppercase">
                  Performance
                  <br />
                  Overview
                </p>
                <p className="table-contents-list-items-styles">Peer Ranking</p>
                <p className="table-contents-list-items-styles">Engangement</p>
                <p className="table-contents-list-items-styles">
                  Rating Distribution
                  <br />
                  of Holdings
                </p>
              </div>

              <div>
                <p className="table-contents-number-items-styles">04</p>
                <p className="table-contents-title-items-styles text-uppercase">ESG by Issue</p>
                <p className="table-contents-list-items-styles">Peer Rankings</p>
                <p className="table-contents-list-items-styles">Issue Ratings</p>
                <p className="table-contents-list-items-styles">Holdings Metrics</p>
              </div>

              <div>
                <p className="table-contents-number-items-styles">10</p>
                <p className="table-contents-title-items-styles text-uppercase">Managers</p>
                <p className="table-contents-list-items-styles">Overview</p>
                <p className="table-contents-list-items-styles">Products</p>
              </div>

              <div>
                <p className="table-contents-number-items-styles">11</p>
                <p className="table-contents-title-items-styles text-uppercase">Holdings</p>
                <p className="table-contents-list-items-styles">Most Held</p>
                <p className="table-contents-list-items-styles">High Risk</p>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}
