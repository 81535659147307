import {Theme} from '../theme';

export const EnergyUse = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2593_10505)">
        <path d="M17.7704 0.347283C17.6486 0.132463 17.4211 0 17.1742 0H8.95232C8.63784 0 8.36419 0.214134 8.28791 0.519211L5.54728 11.5274C5.49576 11.7321 5.54194 11.9489 5.67175 12.1148C5.80157 12.2814 6.00095 12.3784 6.2117 12.3784H9.33373L5.57008 22.4606C5.44963 22.7817 5.58547 23.1424 5.88721 23.305C6.19032 23.4674 6.56533 23.3805 6.76572 23.1042L17.7282 7.98512C17.8801 7.77702 17.9015 7.50136 17.7851 7.27119C17.668 7.04171 17.4318 6.89719 17.1742 6.89719H14.2737L17.7616 1.03774C17.8888 0.825658 17.8921 0.562056 17.7704 0.347283Z" />
      </g>
      <defs>
        <clipPath id="clip0_2593_10505">
          <rect width="23.3866" height="23.3866" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
