import {indexOf, isEmpty, sortBy} from 'lodash';
import {ADDRESS_TYPES} from 'app/constants';

// Sort addresses by order of ADDRESS_TYPES enum, with unspecified going last
export const sortAddressesByType = addresses => {
  if (isEmpty(addresses)) return null;

  return sortBy(addresses, address => {
    const addressTypeIndex = indexOf(ADDRESS_TYPES, address.type);
    return addressTypeIndex !== -1 ? addressTypeIndex : ADDRESS_TYPES.length;
  });
};
