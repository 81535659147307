import {map} from 'lodash';

// Changing the order of these enums may change the order they appear in the UI
export const USER_MANAGER_FILTER = {
  USER_TYPE_1: 'User Type 1',
  USER_TYPE_2: 'User Type 2',
  USER_TYPE_3: 'User Type 3',
  USER_TYPE_4: 'User Type 4',
  INVITATIONS_SENT: 'Invitations Sent',
  INVITATIONS_NOT_SENT: 'Invitation Not Sent',
};

export const USER_MANAGER_FILTERS = map(USER_MANAGER_FILTER, value => value);
