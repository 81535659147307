import {isImpersonating} from 'app/utilities';
import {groupBy, sumBy, uniqueId} from 'lodash';
import {useSelector} from 'react-redux';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useGetFirmHoldingsByIdQuery} from 'v2/redux/typeormEndpoints';
import {filter} from 'v2/utilities/helpers/filter';
import {FirmHolding} from 'v2/utilities/types/typeOrm';

export const useUT3DashboardHoldings = () => {
  const firmId = useSelector((state: any) =>
    isImpersonating() ? state.auth.impersonatedUser.firmId ?? 0 : state.auth.firmId ?? 0,
  );

  const {data, isLoading, isError, isSuccess} = useGetFirmHoldingsByIdQuery({firmId});

  const unfilteredFirmHoldings: FirmHolding[] = data ?? [];

  const filterParams: any[] = [];
  //@ts-ignore
  const FirmHoldings = filter({data: unfilteredFirmHoldings, filterItems: filterParams});

  const totalMarketValue = sumBy(FirmHoldings, o => Number(o.amountHeld));
  const groupedHoldings = groupBy(FirmHoldings, 'companyId');

  const filteredFirmHoldings = FirmHoldings.filter(
    (holding: FirmHolding) => Number(holding.amountHeld) / totalMarketValue > 0.001,
  ).reduce((holding, current) => {
    const x = holding.find((item: any) => item.companyId === current.companyId);

    if (!x) {
      return holding.concat([current]);
    } else {
      return holding;
    }
  }, []);

  const held = (holding: any) => sumBy(groupedHoldings[holding.companyId], o => Number(o.amountHeld ?? 0));

  return {
    data: filteredFirmHoldings.map((holding: any) => ({
      id: holding.companyId,
      companyName: holding.companyName ?? DATA_STATUS.NONE,
      sector: holding.sectorName ?? DATA_STATUS.NONE,
      isEngaged: Number(holding.engaged) > 0 ?? DATA_STATUS.NONE,
      transparency: holding.transparency ?? DATA_STATUS.NONE,
      pctRank: holding.companyPctRank ?? DATA_STATUS.NONE,
      held: held(holding),
      weight: (held(holding) / totalMarketValue).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
      }),
      carbon_predicted: holding?.carbon_emissions_is_predicted,
    })),
    isLoading,
    isSuccess,
    isError,
  };
};
