import moment from 'moment';
import {assignWith, pick} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, formValueSelector} from 'redux-form';

// Local Imports
import {CHARACTER_LIMIT, AUTOCOMPLETE} from 'app/constants';
import {normalizeMMDDYYYY} from 'app/utilities';
import {Button, FormFeedback, MultiUploaderWrapper} from 'app/components';
import {TextFormGroup, TextareaFormGroup, Checkbox} from 'app/components/forms';
import EsgAssociationPriImage from 'app/assets/images/ESG-association_PRI.png';
import EsgAssociationGriImage from 'app/assets/images/ESG-association_GRI.png';
import {setupFileInfoIntoArray} from 'app/utilities/fileFunctions';
import '../assets/styles/components/managers-profile-table.less';
import isEmpty from 'redux-actions/lib/utils/isEmpty';
import {CONFIG} from 'app/constants';

const thisYear = CONFIG.MANAGER_EMPLOYEE_DATA_YEAR;

const categories = {
  BOARD: 'Board of Directors and CEO, CFO & COO',
  PROFESSIONALS: 'Investment Professionals',
  SUPPORT: 'Investment Support',
};

const ethnicities = {
  ASIAN: 'Asian',
  BLACK: 'Black or African American',
  HISPANIC: 'Hispanic or Latino',
  NATIVE_AMERICAN: 'Native American or Alaskan Native',
  PACIFIC_ISLANDER: 'Native Hawaiian or Pacific Islander',
  WHITE: 'White',
  MULTIPLE: 'Two or more races',
  OTHER: 'Other / undisclosed Race',
};

const genders = {
  MALE: 'Male',
  FEMALE: 'Female',
};

const years = [thisYear, thisYear - 1];

const FORM_NAME = 'onboarding-ut3-manager-form';

const validate = values => {
  const errors = {};

  if (values.hasPriAssociation) {
    if (!values.priDocFilePath || values.priDocFilePath.length === 0) {
      errors.hasPriAssociation = 'Upload PRI documentation is required.';
    }
  }
  if (values.hasGriAssociation) {
    if (!values.griDocFilePath || values.griDocFilePath.length === 0) {
      errors.hasGriAssociation = 'Upload GRI documentation is required.';
    }
  }
  if (values.hasEsgPolicy) {
    if (!values.esgPolicyFilePath || values.esgPolicyFilePath.length === 0) {
      errors.hasEsgPolicy = 'Upload ESG documentation is required.';
    } else if (!values.esgPolicyEstablishedAt) {
      errors.hasEsgPolicy = 'The Date at which ESG was established is required.';
    }
  }

  return errors;
};

const createRange = (min, max) => {
  const range = [];
  for (let i = min; i <= max; i++) {
    range.push(i);
  }
  return range;
};

const getTotalOfTableColumns = val => {
  const categories = val.employees.map(employee => employee.category);
  const years = val.employees.map(employee => employee.year);
  const uniqCategories = [...new Set(categories)];
  const uniqyears = [...new Set(years)];
  const columns = createRange(0, uniqCategories.length + uniqyears.length);

  const createColumn = columnPosition => {
    const columnIndexValues = [];
    for (let i = columnPosition; i < val.employees.length; i += columns.length) {
      columnIndexValues[i] = i;
    }
    return columnIndexValues;
  };

  const totalValuesForColumns = columns
    .map(columnIndex => createColumn(columnIndex))
    .map(columnIndex => columnIndex.map(data => val.employees[data].count))
    .map(rowOfColumn => rowOfColumn.reduce((previousValue, currentValue) => previousValue + currentValue));

  totalValuesForColumns.forEach((total, index) => (val[`total${index + 1}`] = total));
};

const renderEmployeesTable = () => {
  let currentIndex = 0;
  let index = 1;
  let columns = [];
  return (
    <table className="managers-table-container w-100">
      <tr className="managers-table-container">
        <th className="managers-table-container" style={{width: '25%'}} />
        <th className="managers-table-container" style={{width: '10%'}} />
        {Object.keys(categories).map(category => {
          return (
            <th
              colSpan="2"
              className="table-header managers-table-container"
              style={{width: `${65 / Object.keys(categories).count}%`}}
            >
              {categories[category]}
            </th>
          );
        })}
      </tr>
      <tr className="managers-table-container">
        <td className="managers-table-container" />
        <td className="managers-table-container" />
        {Object.keys(categories).map(() => {
          return years.map(year => {
            columns.push(index++);
            return <td className="year-text managers-table-container">{year}</td>;
          });
        })}
      </tr>
      {Object.keys(ethnicities).map((ethnicity, ethInd) => {
        return Object.keys(genders).map((gender, genInd) => {
          return (
            <tr key={`employees.${genInd}`}>
              {genInd === 0 && (
                <th rowSpan="2" className="text-center pl-3 pr-3 h-80px managers-table-container">
                  <div>
                    <p className="pb-3 pt-3 mb-0">{ethnicities[ethnicity]}</p>
                  </div>
                </th>
              )}
              <td className="gender-text managers-table-container">{genders[gender]}</td>
              {Object.keys(categories).map((_category, catInd) => {
                return years.map((_year, yearInd) => {
                  const cell = (
                    <td
                      className="text-center managers-table-container"
                      key={`employees.${ethInd}.${genInd}.${catInd}.${yearInd}`}
                    >
                      <Field
                        name={`employees[${currentIndex}][count]`}
                        type="text"
                        component="input"
                        className="managers-profile-input"
                        normalize={(value, oldValue) => {
                          if (isEmpty(value)) {
                            return 0;
                          }

                          const newValue = parseInt(value, 10);
                          return isNaN(newValue) ? oldValue : newValue;
                        }}
                      />
                    </td>
                  );

                  currentIndex += 1;

                  return cell;
                });
              })}
            </tr>
          );
        });
      })}
      <tr>
        <td className="text-center pl-3 pr-3 managers-table-container">
          <div>
            <p className="pb-3 pt-3 mb-0 font-weight-bold">Total</p>
          </div>
        </td>
        <td></td>
        {columns.map(data => {
          return (
            <td className="text-center managers-table-container">
              <Field
                name={'total' + data}
                disabled
                type="text"
                component="input"
                className="managers-profile-input transparent-border"
                normalize={(value, previousValue, allValues) => parseInt(allValues)}
              />
            </td>
          );
        })}
      </tr>
    </table>
  );
};

class OnboardingUt3ManagerFormComponent extends Component {
  S3_UPLOADER_PROPS = {
    accept: 'application/pdf, .pdf',
    onFinish: this.props.onFileUpload,
  };

  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);
    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <Fragment>
          <h3 className="ml-2 mb-5">General Firm Information</h3>

          <div className="form-grid ml-1">
            <Field
              name="name"
              label="Manager Name"
              type="text"
              component={TextFormGroup}
              className="col-3"
              disabled={true}
            />
            <Field
              name="url"
              label="Website URL"
              maxLength={CHARACTER_LIMIT.URL}
              type="text"
              component={TextFormGroup}
              className="col-3"
            />
            <div>
              <div className="mt-5 mb-4">
                <label htmlFor="hasESGCommitteeOfficer" className="mr-3">
                  1. Please complete all columns in the table below by entering in the number of employees for each
                  category (not percentage of employees).
                </label>
              </div>
              {renderEmployeesTable()}
              <div className="mb-4 mt-5 d-flex">
                <label htmlFor="hasInclusion" className="mr-3">
                  2. Does your firm have a formal Diversity, Equity, and Inclusion policy or initiative in place?
                </label>
                <div>
                  <Field name="hasInclusion" id="hasInclusion" component={Checkbox} />
                </div>
              </div>

              <div className="mt-5 mb-4">
                <div className="float-left">
                  <label>3. </label>
                </div>
                <Field
                  name="diversityObjectives"
                  label="Please describe your D&I practices that help you meet your objectives."
                  component={TextareaFormGroup}
                  maxLength="1000"
                  className="firm-ESG-description ml-4"
                  labelHint="(1000 characters max)"
                />
              </div>
            </div>
          </div>

          <div>
            <div>
              <h3 className="mb-0 ml-2 d-inline-block">ESG Integration</h3> <span> Check the box if yes.</span>
            </div>
            <div className="form-grid ml-1">
              <div className="mb-4 mt-5 d-flex">
                <label htmlFor="hasESGCommitteeOfficer" className="mr-3">
                  1. Do you have an ESG Committee / Officer?
                </label>
                <div>
                  <Field name="hasESGCommitteeOfficer" id="hasESGCommitteeOfficer" component={Checkbox} />
                </div>
              </div>
              <div className="mb-4 mt-5 d-flex">
                <label htmlFor="hasEsgTraining" className="mr-3">
                  2. Do you provide ESG training to the investment team?
                </label>
                <div>
                  <Field name="hasEsgTraining" id="hasEsgTraining" component={Checkbox} />
                </div>
              </div>
              <div className="mb-4 mt-5">
                <label>3. How do you integrate ESG considerations into your investment process?</label>
                <div>
                  <div className="d-flex">
                    <Field
                      label="Screening"
                      name="hasScreeningConsideration"
                      component={Checkbox}
                      className="mb-2 mt-1 ml-4"
                    />
                    <Field
                      label="Vetting"
                      name="hasVettingConsideration"
                      component={Checkbox}
                      className="mb-2 mt-1 ml-4"
                    />
                    <Field
                      label="Voting"
                      name="hasVotingConsideration"
                      component={Checkbox}
                      className="mb-2 mt-1 ml-4"
                    />
                  </div>
                  <div className="d-flex">
                    <Field
                      label="Scaling"
                      name="hasScalingConsideration"
                      component={Checkbox}
                      className="mb-2 mt-1 ml-4"
                    />
                    <Field
                      label="Monitoring"
                      name="hasMonitoringConsideration"
                      component={Checkbox}
                      className="mb-2 mt-1 ml-4"
                    />
                    <Field
                      label="Selling"
                      name="hasSellingConsideration"
                      component={Checkbox}
                      className="mb-2 mt-1 ml-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-grid ml-1">
            <div className="mt-5">
              <div className="float-left">
                <label>4. </label>
              </div>
              <Field
                name="esgApproach"
                label="Describe your organization's approach to integrating ESG"
                component={TextareaFormGroup}
                maxLength="1000"
                className="firm-ESG-description ml-4"
                labelHint="(1000 characters max)"
              />
            </div>
            <div className="mt-5">
              <div className="float-left">
                <label>5. </label>
              </div>
              <Field
                name="esgMeasurement"
                label="Describe how your organization measures ESG"
                component={TextareaFormGroup}
                maxLength="1000"
                className="col-12 firm-ESG-description ml-4"
                labelHint="(1000 characters max)"
              />
            </div>
            <div className="mb-1 mt-5">
              <label htmlFor="hasEsgPolicy" className="mr-3">
                <span className="position-absolute">6.</span>
                <div className="ml-4 d-flex">
                  <div className="mr-3">Do you have an organization-level ESG Policy?</div>
                  <Field name="hasEsgPolicy" id="hasEsgPolicy" component={Checkbox} />
                </div>
                {this.props.hasEsgPolicy && (
                  <div className="ml-4">If so, please enter the date it was established and upload below.</div>
                )}
              </label>
              {this.props.hasEsgPolicy && (
                <div className="d-flex ml-4 mt-5">
                  <div>
                    <Field
                      label="ESG Established At"
                      name="esgPolicyEstablishedAt"
                      type="text"
                      component={TextFormGroup}
                      autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
                      format={normalizeMMDDYYYY}
                      className="col-1"
                      maxLength="10"
                      placeholder="MM/DD/YYYY"
                    />
                  </div>
                  <div className="ml-6" style={{width: '100%'}}>
                    <div className="label-hint-container">
                      <label htmlFor="name">Upload ESG Policy (PDF)</label>
                    </div>
                    <Field
                      name="esgPolicyFilePath"
                      component={props => {
                        return (
                          <MultiUploaderWrapper
                            {...props.input}
                            uploaderProps={this.S3_UPLOADER_PROPS}
                            onFileRemoved={this.props.onFileRemove}
                          ></MultiUploaderWrapper>
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="ESG-associations">
              <h3 className="mt-4">ESG Associations</h3>
              <p>Select the ESG associations that your firm is already registered with.</p>
              <Field
                label="Principles for Responsible Investment (PRI)"
                name="hasPriAssociation"
                component={Checkbox}
                className="mb-4"
              />
              <img src={EsgAssociationPriImage} className="ml-7" />
              {this.props.hasPriAssociation && (
                <div className="mt-7 ml-7">
                  <div className="label-hint-container">
                    <label htmlFor="name">Upload PRI documentation*</label>
                  </div>
                  <Field
                    name="priDocFilePath"
                    component={props => {
                      return (
                        <MultiUploaderWrapper
                          {...props.input}
                          uploaderProps={this.S3_UPLOADER_PROPS}
                          onFileRemoved={this.props.onFileRemove}
                        ></MultiUploaderWrapper>
                      );
                    }}
                  />
                </div>
              )}
              <Field
                label="GRI Empowering Sustainable Decisions"
                name="hasGriAssociation"
                component={Checkbox}
                className="mb-4 mt-10"
              />
              <img src={EsgAssociationGriImage} className="ml-7" />
              {this.props.hasGriAssociation && (
                <div className="mt-7 ml-7">
                  <div className="label-hint-container">
                    <label htmlFor="name">Upload GRI documentation*</label>
                  </div>
                  <Field
                    name="griDocFilePath"
                    component={props => {
                      return (
                        <MultiUploaderWrapper
                          {...props.input}
                          uploaderProps={this.S3_UPLOADER_PROPS}
                          onFileRemoved={this.props.onFileRemove}
                        ></MultiUploaderWrapper>
                      );
                    }}
                  />
                </div>
              )}
            </div>
            <div className="form-grid mt-10">
              <Field
                name="otherEsgAssociations"
                label="Other Associations"
                component={TextareaFormGroup}
                maxLength="1000"
                className="col-12 firm-ESG-description"
                labelHint="(1000 characters max)"
              />
            </div>
          </div>
        </Fragment>

        <div className="form-footer is-right mt-12">
          <Button type="submit" solid>
            Save &amp; Continue
          </Button>
        </div>
      </form>
    );
  }
}

OnboardingUt3ManagerFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingUser: PropTypes.object,
};

OnboardingUt3ManagerFormComponent.defaultProps = {onboardingUser: {}};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
  const fieldValues = selector(state, 'hasEsgPolicy', 'hasPriAssociation', 'hasGriAssociation', 'hasInclusion');
  return fieldValues || {};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingUser = {}} = ownProps;
  const defaultValues = {
    hasEsgPolicy: false,
    hasPriAssociation: false,
    hasGriAssociation: false,
  };

  // Pick which values will pre-populate the form.
  const userValues = pick(onboardingUser.firm, [
    'name',
    'url',
    'adv1FilePath',
    'adv2FilePath',
    'hasPriAssociation',
    'hasGriAssociation',
    'priDocFilePath',
    'griDocFilePath',
    'employees',
    'esgPolicyFilePath',
    'esgPolicyEstablishedAt',
    'esgApproach',
    'esgMeasurement',
    'hasESGCommitteeOfficer',
    'hasEsgTraining',
    'hasScreeningConsideration',
    'hasScalingConsideration',
    'hasVettingConsideration',
    'hasMonitoringConsideration',
    'hasVotingConsideration',
    'hasSellingConsideration',
    'hasEsgPolicy',
    'otherEsgAssociations',
    'hasInclusion',
    'diversityObjectives',
  ]);

  // setup for file arrays
  userValues.adv1FilePath = setupFileInfoIntoArray(userValues.adv1FilePath);
  userValues.adv2FilePath = setupFileInfoIntoArray(userValues.adv2FilePath);
  userValues.priDocFilePath = setupFileInfoIntoArray(userValues.priDocFilePath);
  userValues.griDocFilePath = setupFileInfoIntoArray(userValues.griDocFilePath);
  userValues.esgPolicyFilePath = setupFileInfoIntoArray(userValues.esgPolicyFilePath);
  userValues.esgPolicyEstablishedAt = userValues.esgPolicyEstablishedAt
    ? moment(userValues.esgPolicyEstablishedAt).format('MM/DD/YYYY')
    : '';

  const existingEmployees = userValues.employees || [];
  userValues.employees = [];

  Object.keys(ethnicities).forEach(ethnicity => {
    Object.keys(genders).forEach(gender => {
      Object.keys(categories).forEach(category => {
        years.forEach(year => {
          const existingEmployee = existingEmployees.find(e => {
            return e.category === category && e.ethnicity === ethnicity && e.gender === gender && e.year === year;
          });

          const prefillValues = {
            ethnicity,
            gender,
            category,
            year,
          };

          if (existingEmployee) {
            prefillValues.count = existingEmployee.count;
          } else {
            prefillValues.count = 0;
          }

          userValues.employees.push(prefillValues);
        });
      });
    });
  });

  return Object.assign({}, stateProps, ownProps, {
    initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
  });
};

export const OnboardingUt3ManagerForm = compose(
  connect(mapStateToProps, null, mergeProps),
  reduxForm({
    form: FORM_NAME,
    validate,
    onChange: val => getTotalOfTableColumns(val),
  }),
)(OnboardingUt3ManagerFormComponent);
