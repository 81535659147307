import {uniqBy} from 'lodash';
import {useParams} from 'react-router';
import {useGetStrategySecurityHoldingsQuery} from 'v2/redux/harmonyApi';

export const useHoldings = () => {
  const {strategyId}: any = useParams();
  const data: any[] = useGetStrategySecurityHoldingsQuery({strategyId}).data;

  const companyHoldingsCount = Array.from({length: 1}, () => Object.keys(uniqBy(data, 'company_id')).length);

  return {
    data,
    companyHoldingsCount,
  };
};
