import {ENVIRONMENTS} from 'v2/constants/environments';

const reactEnv = process.env.REACT_APP_ENV || '';

export const env = {
  isLocal: () => {
    return reactEnv === ENVIRONMENTS.LOCAL || window.location.host === 'localhost:3000';
  },
  isDev: () => {
    return reactEnv === ENVIRONMENTS.DEV;
  },
  isStaging: () => {
    return reactEnv === ENVIRONMENTS.STAGING;
  },
  isProduction: () => {
    return reactEnv === ENVIRONMENTS.PRODUCTION;
  },
};
