import {Theme} from '../theme';

export const EnvironmentHand = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_782_505)">
        <path
          d="M11.3493 7.28089C11.5656 7.351 11.7779 7.17942 11.7062 6.99686C11.2139 5.74368 9.43157 5.27619 7.77469 6.38801C6.90315 6.97291 6.57564 7.09719 6.56738 7.10032C7.14194 7.57489 7.79834 9.16738 9.23085 9.3435C10.8089 9.57985 12.2055 8.2973 11.0628 7.7104C10.3114 7.32447 9.42659 7.10028 8.47819 7.10028C9.47247 6.91514 10.465 6.99453 11.3493 7.28089Z"
          fill={color}
        />
        <path
          d="M18.4949 6.38763C15.6349 4.46842 13.8109 7.49503 14.9447 7.19502C15.7499 6.98194 16.6302 6.93552 17.5132 7.09994C16.0171 7.09994 14.638 7.66636 13.7185 8.58833C13.794 7.13945 13.9082 6.23253 14.0406 5.5579C14.8049 5.53619 15.8235 5.02636 16.3829 4.13424C17.3472 2.7085 16.9 1.24665 16.7561 0.324219C16.0025 0.930398 12.7109 0.220116 11.406 2.06735C10.8296 2.88335 10.9971 3.87631 11.6035 4.60295C11.7954 4.83299 12.2544 4.72261 12.2675 4.44345C12.3244 3.22906 13.0561 2.31154 14.42 1.80336C13.8079 2.45846 13.4061 3.19188 13.1985 3.94994C12.8758 5.1308 12.5388 5.90543 12.415 9.6692C12.4024 10.0509 12.7767 10.3669 13.2389 10.3621C13.5735 10.3586 13.8601 10.1832 13.9888 9.92802C14.1845 9.53992 14.503 9.13826 15.0214 8.77025C15.5139 9.22002 16.2635 9.45934 17.039 9.34316C18.4654 9.1678 19.1311 7.57184 19.7024 7.0999C19.694 7.09685 19.3665 6.97257 18.4949 6.38763Z"
          fill={color}
        />
        <path
          d="M22.6995 11.0782C21.8054 10.577 20.8323 10.9341 20.3854 11.1473L14.7771 14.5383L11.4933 14.5389L10.6723 13.8607H13.6881C14.5951 13.8607 15.6108 13.3726 15.6108 12.504C15.6108 11.5818 14.5951 11.1473 13.6881 11.1473H12.5579C11.7184 11.1473 10.7373 11.2091 10.0456 10.9032C9.38837 10.6269 8.63156 10.4697 7.82369 10.4697C6.46712 10.4697 5.25299 10.9165 4.42101 11.621L0 15.2165L4.92569 19.2856L6.56759 17.251H14.3264C15.1538 17.251 15.9526 17.0003 16.5712 16.5463L22.7557 12.0077C23.0995 11.7554 23.0801 11.2915 22.6995 11.0782Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_782_505">
          <rect width="23" height="19.0006" fill={color} transform="translate(0 0.304688)" />
        </clipPath>
      </defs>
    </svg>
  );
};
