import {Theme} from '../theme';

export const TrendingUp = ({color = Theme.colors.dark, size = 32}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7666_3785)">
        <path
          d="M19.1663 5L11.2497 12.9167L7.08301 8.75L0.833008 15"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M14.167 5H19.167V10" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7666_3785">
          <rect width="20" height="20" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
};
