import {useState} from 'react';
import {DRating} from 'v2/utilities/delegates/DRating';
import {DMarketIndexRating} from 'v2/utilities/delegates/DMarketIndexRating';
import {getSessionJwt} from 'app/utilities';
import {
  useGetPlanSponsorStrategiesQuery,
  useGetStrategyAggregatePercentileRankHoldingsQuery,
  useGetPlanSponsorAggregatePercentileRankHoldingsQuery,
} from 'v2/redux/typeormEndpoints';
import {groupBy} from 'lodash';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {PlanSponsorStrategy} from 'v2/utilities/types/typeOrm';
import {calcRank, formatMillions, getOrdinal} from 'v2/utilities/helpers';
import {FIRM_RATINGS} from 'v2/constants/firmRatings';
import {calcRankRating} from 'v2/utilities/helpers/calcRankRating';
import {useGetAggregatePercentileHoldingsQuery, useGetPlanSponsorMarketIndexSecuritiesQuery} from 'v2/redux/harmonyApi';

export const useManagers = () => {
  const [searchValue, setSearchValue] = useState('');

  const planSponsorId = getSessionJwt(true)?.contents?.planSponsorId;

  const {data = [], isLoading: isLoadingStrategies} = useGetPlanSponsorStrategiesQuery({planSponsorId});
  const {data: marketIndexesData = [], isLoading: isLoadingMarketIndexes} = useGetPlanSponsorMarketIndexSecuritiesQuery(
    {planSponsorId},
  );
  console.log(marketIndexesData);
  const {data: holdingsData = [], isLoading: isLoadingHoldings} = useGetAggregatePercentileHoldingsQuery({
    planSponsorId,
    issueId: 1,
  });
  const marketIndexes = groupBy(marketIndexesData, marketIndex => marketIndex?.market_index_id);
  const holdings = groupBy(holdingsData, holdings => holdings?.strategyId);

  const handleSearchChange = (arg: string) => {
    setSearchValue(arg);
  };

  const filteredData = (data as PlanSponsorStrategy[]).filter(datum => {
    const searchable = (datum?.firm?.name + datum?.name + datum?.marketIndex?.name).toUpperCase();

    return searchValue ? searchable.includes(searchValue.toUpperCase()) : true;
  });

  const mappedData = filteredData.map((datum, i) => {
    const {id, firm, name, marketIndex, portfolioManagementType, portfolio_value} = datum;

    const rating = new DRating(holdings[datum.id] ?? []);
    const miRating = new DMarketIndexRating(marketIndexes[datum?.marketIndexId ?? 0] ?? []);
    const vsIndexRating = Math.round((miRating.getPercentileRank() - rating.getPercentileRank()) * 100);

    let percentileOrdinal = `${Math.round(rating.getPercentileRank() * 100)}${getOrdinal(
      Math.round(rating.getPercentileRank() * 100),
    )}`;
    if (percentileOrdinal === 'NaNundefined') {
      percentileOrdinal = DATA_STATUS.NONE;
    }

    return {
      id,
      firmName: firm?.name,
      product: name,
      index: marketIndex?.name,
      strategy: portfolioManagementType,
      percentileRank: rating.getPercentileRank(),
      vsIndexRating: vsIndexRating,
      portfolioValue: portfolio_value,
    };
  });

  const mapDataForCSV = () => {
    return mappedData.map(row => {
      const {id, ...rest} = row;
      return {
        ...rest,
        percentileRank: !isNaN(row.percentileRank)
          ? `${Object.keys(FIRM_RATINGS)[calcRankRating(row.percentileRank, 1) - 1]} ${calcRank(
              row.percentileRank,
            )}${getOrdinal(calcRank(row.percentileRank))}`
          : DATA_STATUS.NONE,
        vsIndexRating: !isNaN(row.vsIndexRating) ? row.vsIndexRating : DATA_STATUS.NONE,
        portfolioValue: formatMillions(row.portfolioValue ?? 0, 1, '$'),
      };
    });
  };

  return {
    data: mappedData,
    mapDataForCSV,
    handleSearchChange,
    isLoading: isLoadingStrategies || isLoadingMarketIndexes || isLoadingHoldings,
  };
};
