import React from 'react';
import {Container, FlexContainer} from '../atoms/Containers';
import {HarmonyButton} from '../molecules/Button';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faArrowLeft from 'app/fontawesome-pro-light/faArrowLeft';
import {OnboardingFooter as OnboardingFooterProps} from 'v2/utilities/types/components/OnboardingFooter';
import {FormSpy} from 'react-final-form';

export const OnboardingFooter = ({
  backLabel = 'Return',
  continueLabel = 'Continue',
  completeLabel = 'Continue',
  saveLabel = 'Save progress and exit',
  profileLabel = 'Go to profile',
  onBack,
  onSave,
  onContinue,
  onReturnToProfile,
  onFinish,
  state,
  disabled,
}: OnboardingFooterProps) => {
  switch (state) {
    case 'initial':
      return (
        <FormSpy subscription={{error: true, invalid: true}}>
          {({error, invalid}) => (
            <FlexContainer padding="2rem 0" justify="flex-end" fullWidth>
              <FlexContainer gap="1rem" flex={0}>
                <HarmonyButton type="button" onClick={onContinue} disabled={error || invalid}>
                  {continueLabel}
                </HarmonyButton>
              </FlexContainer>
            </FlexContainer>
          )}
        </FormSpy>
      );
    case 'no-return':
      return (
        <FormSpy subscription={{error: true, invalid: true}}>
          {({error, invalid}) => (
            <FlexContainer padding="2rem 0" justify="flex-end" fullWidth>
              <FlexContainer gap="1rem" flex={0}>
                <HarmonyButton type="button" variant="ghost" onClick={onSave}>
                  {saveLabel}
                </HarmonyButton>
                <HarmonyButton type="button" onClick={onContinue} disabled={error || invalid}>
                  {continueLabel}
                </HarmonyButton>
              </FlexContainer>
            </FlexContainer>
          )}
        </FormSpy>
      );
    case 'complete':
      return (
        <FlexContainer padding="2rem 0" justify="flex-end" fullWidth>
          <FlexContainer gap="1rem" flex={0}>
            {/* <HarmonyButton type="button" onClick={onReturnToProfile}>
              {profileLabel}
            </HarmonyButton> */}
            <HarmonyButton type="button" onClick={onFinish}>
              {completeLabel}
            </HarmonyButton>
          </FlexContainer>
        </FlexContainer>
      );
    case 'default':
    default:
      return (
        <FormSpy subscription={{error: true, invalid: true}}>
          {({error, invalid}) => (
            <FlexContainer padding="2rem 0" justify="space-between" fullWidth>
              <Container>
                <HarmonyButton type="button" variant="ghost" onClick={onBack}>
                  <FlexContainer items="center" gap="1rem" as="span">
                    <Icon
                      style={{paddingTop: 2}}
                      // @ts-ignore
                      icon={faArrowLeft}
                    />
                    <span>{backLabel}</span>
                  </FlexContainer>
                </HarmonyButton>
              </Container>
              <FlexContainer gap="1rem" flex={0}>
                <HarmonyButton type="button" variant="ghost" onClick={onSave} disabled={disabled}>
                  {saveLabel}
                </HarmonyButton>
                <HarmonyButton type="button" onClick={onContinue} disabled={error || invalid}>
                  {continueLabel}
                </HarmonyButton>
              </FlexContainer>
            </FlexContainer>
          )}
        </FormSpy>
      );
  }
};
