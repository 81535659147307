import React, {Component} from 'react';

import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {connect} from 'react-redux';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError} from 'redux-form';
import {resetPassword} from 'app/redux/user';
import {push} from 'connected-react-router';
import {toastSuccess} from 'app/utilities/toast';
import {ResetPasswordForm} from 'app/components/ResetPasswordForm';

const formName = 'reset-password';

class ResetPasswordPage extends Component {
  render() {
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 530}}>
          <h1>{ROUTE.RESET_PASSWORD.title}</h1>
          <ResetPasswordForm {...this.props} formName={formName} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {resetToken} = ownProps.match.params;
      return dispatch(resetPassword(resetToken, values.password))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess('The password has been successfully updated.');

          animateScroll.scrollToTop();

          dispatch(reset(formName));

          dispatch(push(ROUTE.DASHBOARD.path()));
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.RESET_PASSWORD_UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const ResetPassword = connect(null, mapDispatchToProps)(ResetPasswordPage);
