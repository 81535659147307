import {createAction} from 'redux-actions';
import {endsWith, startsWith} from 'lodash';

// Local Imports
import {fetchAuthJSON} from 'app/services/http';
import {assignWithState} from 'app/redux/helpers';

const initialState = {
  hasError: false,
  isFetching: false,
};

let fetchingCount = 0;

// ACTION
export const ACTION = {
  GET_COMPANYVIDEOS_DATA_REQUEST: 'CI_GET_COMPANYVIDEOS_DATA_REQUEST',
  GET_COMPANYVIDEOS_DATA_RECEIVE: 'CI_GET_COMPANYVIDEOS_DATA_RECEIVE',
};

const getCompanyVideosDataRequest = createAction(ACTION.GET_COMPANYVIDEOS_DATA_REQUEST);
const getCompanyVideosDataReceive = createAction(ACTION.GET_COMPANYVIDEOS_DATA_RECEIVE);

const api = {
  getCompanyVideosData: () => fetchAuthJSON('companyVideos', {method: 'get'}),
};

export function getCompanyVideosData() {
  return dispatch => {
    dispatch(getCompanyVideosDataRequest());
    return dispatch(getCompanyVideosDataReceive(api.getCompanyVideosData()));
  };
}

// REDUCER
export const companyVideos = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, !!hasError);

  if (startsWith(type, 'CI_') && endsWith(type, '_REQUEST')) {
    fetchingCount += 1;
  }
  if (startsWith(type, 'CI_') && endsWith(type, '_RECEIVE')) {
    fetchingCount -= 1;
  }
  const isFetching = fetchingCount > 0;

  switch (type) {
    case ACTION.GET_COMPANYVIDEOS_DATA_REQUEST:
      return assignMergedState({isFetching});
    case ACTION.GET_COMPANYVIDEOS_DATA_RECEIVE: {
      return assignMergedState(Object.assign({isFetching}, hasError ? {error: payload} : {data: payload}));
    }
    default:
      return state;
  }
};
