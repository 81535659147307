/* eslint-disable no-param-reassign */
export const hideChartJsTooltip = ({chartInstance, datasetIndex, dataIndex}) => {
  const activeElements = chartInstance.tooltip._active;
  if (activeElements === undefined || activeElements.length === 0) {
    return;
  }
  const requestedElem = chartInstance.getDatasetMeta(datasetIndex).data[dataIndex];
  if (requestedElem) {
    for (let i = 0; i < activeElements.length; i += 1) {
      if (requestedElem._index === activeElements[i]._index) {
        activeElements.splice(i, 1);
        break;
      }
    }
  }
  chartInstance.tooltip._active = activeElements;
  chartInstance.tooltip.update(true);
  chartInstance.draw();
};
