import ReactPlaceholder from 'react-placeholder/lib';
import {RectShape, TextBlock} from 'react-placeholder/lib/placeholders';
import styled from 'styled-components/macro';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {WorkforceDiversityView as WorkforceDiversityViewProps} from 'v2/utilities/types/components/WorkforceDiversityView';
import {Container, FlexContainer} from '../atoms/Containers';
import {Theme} from '../atoms/theme';
import {Body2, Title5} from '../atoms/Typeface';
import {AnnotatedDoughnutChart} from '../molecules/AnnotatedDoughnutChart';
import {Radio} from '../molecules/Radio';
import {NoDataPlacholder} from './NoDataPlaceholder';

const FlexItem = styled.div`
  flex: 2;
`;

const Title = styled(Title5)`
  &&& {
    font-weight: 700;
  }
  color: ${({theme}) => theme.colors.dark};
  margin: 0;
`;

const Body = styled(Body2)`
  font-size: 0.875rem;
  word-break: break-word;
  line-height: 1.25rem;
`;

const Placeholder = () => (
  <FlexContainer gap="4.563rem" padding="2.688rem 3rem">
    <FlexItem>
      <Container>
        <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
      </Container>
      <Container padding="1.438rem 0 0">
        <TextBlock rows={6} color={Theme.colors.grey_2} />
      </Container>
    </FlexItem>
    <FlexItem>
      <FlexItem>
        <FlexContainer gap="3rem" padding="1rem 0 0">
          <RectShape color={Theme.colors.grey_2} style={{width: '100%', height: 350}} />
        </FlexContainer>
      </FlexItem>
    </FlexItem>
  </FlexContainer>
);

export const WorkforceDiversityView = ({
  isLoading = true,
  hasEthnicityData,
  hasGenderedData,
  ethnicityData,
  genderData,
  workforceDiversity,
  selected,
  handleRadioChange,
  radioValues,
  radioLabels,
}: WorkforceDiversityViewProps) => {
  const GENDER_COLORS = [Theme.colors.harmony, Theme.colors.blue_5];
  const ETHNICITY_COLORS = [
    Theme.colors.harmony,
    Theme.colors.blue_1,
    Theme.colors.blue_2,
    Theme.colors.blue_3,
    Theme.colors.blue_4,
  ];

  return (
    <ReactPlaceholder ready={!isLoading} customPlaceholder={<Placeholder />}>
      <Container padding="2.688rem 3rem">
        <FlexContainer gap="4.563rem">
          <FlexItem>
            <Container>
              <Title>Workforce Diversity</Title>
            </Container>
            <Container padding="1.438rem 0 0">
              {!isLoading &&
                (hasGenderedData && hasEthnicityData ? (
                  <Body>{workforceDiversity && workforceDiversity.length ? workforceDiversity : DATA_STATUS.NONE}</Body>
                ) : (
                  <FlexContainer height="25rem" justify="center" items="center">
                    <NoDataPlacholder />
                  </FlexContainer>
                ))}
            </Container>
          </FlexItem>
          {hasGenderedData && hasEthnicityData && (
            <FlexItem>
              <Container
                padding="1.875rem 0"
                // @ts-ignore
                onChange={handleRadioChange}
              >
                <Radio
                  name="type"
                  labels={radioLabels}
                  values={radioValues}
                  onChange={handleRadioChange}
                  value={selected}
                />
              </Container>
              <FlexItem>
                <FlexContainer gap="3rem" padding="1rem 0 0">
                  <AnnotatedDoughnutChart title={'GENDER'} data={genderData} colors={GENDER_COLORS} bottomTitle />
                  <AnnotatedDoughnutChart
                    title={'ETHNICITY'}
                    data={ethnicityData}
                    colors={ETHNICITY_COLORS}
                    bottomTitle
                  />
                </FlexContainer>
              </FlexItem>
            </FlexItem>
          )}
        </FlexContainer>
      </Container>
    </ReactPlaceholder>
  );
};
