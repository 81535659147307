import {Link, NavLink, useLocation} from 'react-router-dom';
import styled from 'styled-components/macro';
import {NavigationHeaderProps} from 'v2/utilities/types/components/NavigationHeader';
import {Container, FlexContainer, Wrapper} from '../atoms/Containers';
import {ArrowDown, HarmonyLogo, User} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {Overline} from '../atoms/Typeface';
import {Menu} from '../molecules/Menu';

const HeaderContainer = styled(Container).attrs({
  height: '3.75rem',
  width: '100vw',
})<{inverse?: boolean}>`
  position: fixed;
  z-index: 99;
  background: ${({theme, inverse}) => (inverse ? theme.colors.white : theme.colors.harmony)};
`;

const Spacer = styled.div`
  height: 3.75rem;
`;

const LinkItem = styled.a<{selected?: boolean}>`
  &&& {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-decoration: none;
    white-space: nowrap;
    padding: 0 1rem;
    color: ${({theme, selected}) => (selected ? theme.colors.green : theme.colors.white)};
    transition: none 0s;
    &:hover {
      color: ${({theme, selected}) => (selected ? theme.colors.green : theme.colors.white)};
      text-decoration: none;
    }
    &.onHover {
      text-decoration: none;
    }
  }
`;

const AdminLinkItem = styled(LinkItem)`
  font-weight: 600;
  line-height: 1.25rem;
  height: 3.75rem;

  &&& {
    color: ${({theme}) => theme.colors.harmony};

    &:hover {
      color: ${({theme}) => theme.colors.green};
      text-decoration: none;
    }

    border-bottom: ${({theme, selected}) => {
      if (selected) {
        return `3px solid ${theme.colors.harmony}`;
      }

      return '0';
    }};
  }
`;

const SettingsLink = styled.a`
  &&& {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-decoration: none;
    margin-left: 1rem;
    margin-right: 1rem;
    white-space: no-wrap;
    color: ${props => props.theme.colors.white};

    &:hover {
      text-decoration: none;
    }
  }
`;

const MenuContainer = styled.div`
  padding-bottom: 22.5px;
  padding-top: 23.5px;
  display: flex;
  align-items: flex-end;
`;

const SettingsText = styled(Overline)`
  &&& {
    color: ${props => props.theme.colors.white};
    margin-right: 1.0625rem;
    margin-top: 1.625rem;
    margin-bottom: 1rem;
  }
`;

const PaddedArrow = styled(ArrowDown)`
  margin-bottom: 30px;
  padding-left: 3.5px;
`;

const ImpersonationEmail = styled(Overline)`
  &&& {
    color: ${props => props.theme.colors.white};
    align-self: center;
    width: 0;
    white-space: nowrap;
  }
`;

const MenuIcon = ({inverse = false}) => (
  <MenuContainer>
    <User size={14} color={inverse ? Theme.colors.harmony : Theme.colors.white} />
    <PaddedArrow size={10} color={inverse ? Theme.colors.harmony : Theme.colors.white} />
  </MenuContainer>
);

export const NavigationHeader = ({
  paths,
  menuOptions,
  settingsPath,
  impersonationEmail,
  fullWidth,
  isAdmin,
}: NavigationHeaderProps) => {
  const {pathname, search} = useLocation();

  const comparePaths = (currentPath: string, selectedPath: string) => {
    const path = selectedPath.replace(search, '');
    return currentPath.includes(path);
  };

  if (isAdmin) {
    return (
      <>
        <Spacer />
        <HeaderContainer inverse>
          <Wrapper margin={fullWidth ? '0 6.25rem' : '0 auto'} padding="0 0.75rem" fullWidth={fullWidth}>
            <FlexContainer direction="row" height="3.75rem" items="center" justify="space-between" maxWidth="100%">
              <FlexContainer flex={0}>
                <FlexContainer padding="0.2rem 2.6875rem 0 0" items="center" as={Link} to="/">
                  <HarmonyLogo color={Theme.colors.harmony} size={34} />
                </FlexContainer>
                <FlexContainer>
                  {paths.map(({path, label}, i) => (
                    <AdminLinkItem as={Link} to={path} key={i} selected={comparePaths(pathname, path)}>
                      {label}
                    </AdminLinkItem>
                  ))}
                </FlexContainer>
              </FlexContainer>
              <FlexContainer items="center">
                {!!settingsPath && (
                  <SettingsLink to={settingsPath} as={NavLink}>
                    <SettingsText color={Theme.colors.white}>SETTINGS</SettingsText>
                  </SettingsLink>
                )}
                <Menu buttonLabel={<MenuIcon inverse />} options={menuOptions} alignment="left" inverse />
              </FlexContainer>
            </FlexContainer>
          </Wrapper>
        </HeaderContainer>
      </>
    );
  }

  return (
    <>
      <Spacer />
      <HeaderContainer>
        <Wrapper margin={fullWidth ? '0 6.25rem' : '0 auto'} padding="0 0.75rem" fullWidth={fullWidth}>
          <FlexContainer direction="row" height="3.75rem" items="center" justify="space-between" maxWidth="100%">
            <FlexContainer flex={0}>
              <Container padding="0.2rem 2.6875rem 0 0" as={Link} to="/">
                <HarmonyLogo color={Theme.colors.white} size={34} />
              </Container>
              <FlexContainer>
                {paths.map(({path, label}, i) => (
                  <LinkItem as={Link} to={path} key={i} selected={comparePaths(pathname, path)}>
                    {label}
                  </LinkItem>
                ))}
              </FlexContainer>
            </FlexContainer>
            <FlexContainer items="center">
              {!!settingsPath && (
                <SettingsLink to={settingsPath} as={NavLink}>
                  <SettingsText color={Theme.colors.white}>SETTINGS</SettingsText>
                </SettingsLink>
              )}
              <Menu buttonLabel={<MenuIcon />} options={menuOptions} alignment="left" />
              {impersonationEmail && (
                <Container>
                  <ImpersonationEmail title={`Impersonating ${impersonationEmail}`}>Admin</ImpersonationEmail>
                </Container>
              )}
            </FlexContainer>
          </FlexContainer>
        </Wrapper>
      </HeaderContainer>
    </>
  );
};
