import {LoadingSpinner} from 'app/components';
import {Route} from 'react-router-dom';
import {RouteWithMiddlewareProps} from './middleware/types';
import {withMiddleware} from './withMiddleware';

export const RouteWithMiddleware: React.FC<RouteWithMiddlewareProps> = ({
  Component,
  middleware,
  path,
  exact = false,
  ...rest
}) => <Route {...rest}>{withMiddleware(Component, LoadingSpinner, middleware)}</Route>;
