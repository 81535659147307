import {getChartJsSegment} from './getChartJsSegment';

export const pullOutChartJsSegment = ({chartInstance, datasetIndex, dataIndex}) => {
  const segment = getChartJsSegment({chartInstance, datasetIndex, dataIndex});
  if (segment) {
    const model = segment;

    // pull segment out
    const outerRadius = model.outerRadius + 20;
    const innerRadius = model.innerRadius + 20;

    // contract segment relative to its overall angle
    const angle = Math.abs(model.startAngle - model.endAngle);
    const endAngle = model.endAngle - angle * 0.05;
    const startAngle = model.startAngle + angle * 0.05;

    model.outerRadius = outerRadius;
    model.innerRadius = innerRadius;
    model.endAngle = endAngle;
    model.startAngle = startAngle;
  }
};
