import {useMemo} from 'react';
import styled from 'styled-components/macro';
import {DataTable} from 'v2/components/organisms/ManagersDataTable';
import {getOrdinal} from 'v2/utilities/helpers';
import {limitPctRank} from 'v2/utilities/helpers/limitPctRank';
import {toSciNotation} from 'v2/utilities/helpers/toScientificNotation';
import {ReactTable} from './ReactTable';
import {ISSUE} from 'app/constants/issue';

const Exponent = styled.sup`
  line-height: 14px;
  margin-left: 2px;
`;

const UnitValue = styled.div`
  margin-left: 2px;
`;

export const CompanyIssueDataTable = ({data = []}: {data: any[]}) => {
  const memoizedColumns = useMemo(
    () => [
      {
        Header: 'Issues',
        accessor: 'name',
        style: {
          maxWidth: 'auto',
          minWidth: '60%',
          width: '60%',
        },
      },
      {
        Header: 'Percentile Rank',
        accessor: 'pctRank',
        style: {
          justifyContent: 'center',
        },
        Cell: ({value}: {value: number}) => (value ? `${limitPctRank(value)}${getOrdinal(limitPctRank(value))}` : ''),
      },
      {
        Header: 'Company Metric',
        accessor: 'raw_value',
        Cell: ({row: {original}}: {row: {original: any}}) => {
          if (original.raw_value && `${Math.round(original.raw_value)}`.length > 4) {
            const sciNot = toSciNotation(Number(original.raw_value), 3);
            return (
              <>
                {sciNot.coefficient} x 10<Exponent>{sciNot.exponent}</Exponent>
                {!!original.issue.unit && <UnitValue>{original.issue.unit}</UnitValue>}
              </>
            );
          } else if (original.raw_value || original.raw_value === 0) {
            //@ts-ignore
            original.raw_value = Number(original.raw_value).toFixed(ISSUE[original?.issueId]?.precision);
          }
          return (
            <>
              {original.raw_value}
              {original.issue.unit || ''}
            </>
          );
        },
      },
    ],
    [],
  );

  const memoizedData = useMemo(() => data, [data]);

  return <ReactTable columns={memoizedColumns} data={memoizedData} isPadded={false} withSorting />;
};
