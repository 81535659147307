import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  companies: {
    searchText: '',
    filterOptions: {
      showCompaniesWithHoldings: true,
    },
  },
  managers: {
    searchText: '',
    filterOptions: {},
  },
};

export const searchFilterSlice = createSlice({
  name: 'searchFilters',
  initialState,
  reducers: {
    updateManagerSearch: (state, action) => {
      state.managers.searchText = action.payload;
    },
    updateCompanySearch: (state, action) => {
      state.companies.searchText = action.payload;
    },
    updateShowCompaniesWithHoldings: (state, action) => {
      state.companies.filterOptions.showCompaniesWithHoldings = action.payload;
    },
  },
});

export const {updateManagerSearch, updateCompanySearch, updateShowCompaniesWithHoldings} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
