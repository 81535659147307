import {FC, MouseEventHandler} from 'react';
import styled from 'styled-components';
import {ButtonM} from 'v2/components/atoms/Typeface';
import {FlexContainer} from 'v2/components/atoms/Containers';
import {Theme} from 'v2/components/atoms/theme';
import {Copy} from 'v2/components/atoms/icons';
import {HarmonyButton as HarmonyButtonComponent} from 'v2/components/molecules/Button';

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex: 1;
  width: 100%;
  background-color: ${Theme.colors.blue_1}26;
  margin-bottom: 1rem;
`;

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

  border-bottom: 12px solid ${props => props.theme.colors.white};
  position: absolute;
  bottom: 0;
`;

const TabItemContainer = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: 'column';
  align-items: 'center';
`;

const HarmonyButton = styled(HarmonyButtonComponent)`
  &&& {
    gap: 0.75rem;
    // font-family: 'Inter';
    height: 4rem;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${Theme.colors.dark};

    &:active,
    &:disabled {
      background-color: transparent;
    }
  }
`;

export const ManagerTableTabBar: FC<{
  onCopyPreviousYear: MouseEventHandler<HTMLButtonElement>;
  onSelectYear: Function;
  selectedIndex: number;
  years?: number[];
}> = ({onCopyPreviousYear, selectedIndex, onSelectYear, years}) => {
  return (
    <TabsContainer>
      <FlexContainer>
        {years?.map((year, index) => {
          const isSelectedYear = index === selectedIndex;

          return (
            <TabItemContainer key={index}>
              <HarmonyButton
                disabled={isSelectedYear}
                key={year}
                variant="ghost"
                type="button"
                width="3rem"
                style={{
                  ...((isSelectedYear && {color: Theme.colors.blue_1}) || {}),
                }}
                onClick={() => onSelectYear?.(year)}
              >
                {year}
              </HarmonyButton>
              {isSelectedYear && <ArrowUp />}
            </TabItemContainer>
          );
        })}
      </FlexContainer>
      <FlexContainer>
        <HarmonyButton
          variant="ghost"
          type="button"
          style={{
            justifyContent: 'flex-end',
          }}
          color={Theme.colors.white}
          onClick={onCopyPreviousYear}
          disabled={selectedIndex === 0}
        >
          <Copy color={selectedIndex === 0 ? Theme.colors.grey_4 : Theme.colors.harmony} size={20} />
          <ButtonM style={{color: selectedIndex === 0 ? Theme.colors.grey_4 : Theme.colors.harmony}}>
            Copy From Previous Year
          </ButtonM>
        </HarmonyButton>
      </FlexContainer>
    </TabsContainer>
  );
};
