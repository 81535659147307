import {ROUTE} from 'app/constants';

export const userType1MenuProps = [
  ROUTE.SETTINGS_USER_PROFILE,
  ROUTE.SETTINGS_USERS,
  ROUTE.SETTINGS_PLAN_PROFILE,
  ROUTE.HARMONY_INDEX_CENTER,
  ROUTE.SETTINGS_FIRMS_MANAGER,
];
export const userType3MenuProps = [ROUTE.SETTINGS_USER_PROFILE, ROUTE.SETTINGS_FIRM_PROFILE, ROUTE.SETTINGS_USERS];
