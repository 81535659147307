import {getSessionJwt} from 'app/utilities';
import {RootStateOrAny, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {DATA_STATUS} from 'v2/constants/dataStatus';

import {useGetAggregatePercentileRankUniverseQuery, useGetCompanyQuery} from 'v2/redux/typeormEndpoints';
import {getOrdinal} from 'v2/utilities/helpers';
import {limitPctRank} from 'v2/utilities/helpers/limitPctRank';
import {Company, AggregatePercentileRankUniverse} from 'v2/utilities/types/typeOrm';

export const useCompanySummary = () => {
  const {companyId = ''} = useParams<{companyId: string}>();
  const planSponsorId = getSessionJwt(true).contents?.planSponsorId ?? 0;

  const {
    data: companyData,
    isLoading: companyIsLoading,
    isError: companyIsError,
    isSuccess: companyIsSuccess,
  } = useGetCompanyQuery({companyId, planSponsorId});

  const {
    data: percentileData,
    isLoading: percentileIsLoading,
    isError: percentileIsError,
    isSuccess: percentileIsSuccess,
  } = useGetAggregatePercentileRankUniverseQuery({});

  const company = (companyData as Company) ?? {};
  const aggregatePercentiles = ((percentileData ?? []) as AggregatePercentileRankUniverse[]).filter(percentile => {
    if (company.industryId) {
      if (percentile.industry_id === company.industryId) {
        return percentile;
      }
    }
  });

  const transparency = () => {
    if (company.transparency) {
      if (Number(company.transparency) <= 0.33) {
        return 'Low';
      }

      if (Number(company.transparency) >= 0.33 && Number(company.transparency) <= 0.66) {
        return 'Medium';
      }

      if (Number(company.transparency) >= 0.66) {
        return 'High';
      }
    }

    return DATA_STATUS.NONE;
  };

  const percentile = () => {
    if (company.pctRank || company.pctRank === 0) {
      let percentile = Math.round(company.pctRank * 100);
      percentile = limitPctRank(percentile);
      const ordinal = getOrdinal(percentile);
      return percentile + ordinal;
    }

    return DATA_STATUS.NONE;
  };

  const quintile = () => {
    if (company.pctRank) {
      return Math.floor((company.pctRank * 100) / 20 + 1);
    }

    return 1;
  };

  const industry = () => {
    const count = aggregatePercentiles.length;
    let lower = 0;
    let total = 0;

    aggregatePercentiles.forEach(percentile => {
      if (companyData.pctRank < percentile.companyPctRank) {
        total += 1;
      } else {
        total += 1;
        lower += 1;
      }
    });

    let calculatedSector = Math.round((lower / total) * 100);
    calculatedSector = limitPctRank(calculatedSector);
    const ordinal = getOrdinal(calculatedSector);

    if (!count || isNaN(calculatedSector)) {
      return DATA_STATUS.NONE;
    }

    return calculatedSector + ordinal;
  };

  return {
    isLoading: companyIsLoading && percentileIsLoading,
    isError: companyIsError && percentileIsError,
    isSuccess: companyIsSuccess && percentileIsSuccess,
    data: {
      name: company.name ?? DATA_STATUS.NONE,
      description: company.description ?? DATA_STATUS.NONE,
      country: company.country?.name ?? DATA_STATUS.NONE,
      sector: company.sector?.name ?? DATA_STATUS.NONE,
      industry: company.industry?.name ?? DATA_STATUS.NONE,
      transparency: transparency(),
      isEngaged: (company.engagementLetters ?? []).length > 0,
      overall: {
        percentile: percentile(),
        industry: industry(),
        quintile: quintile(),
      },
      carbonNeutralPledgeSbtiStatus: company.carbonNeutralPledgeSbtiStatus ?? DATA_STATUS.NONE,
    },
  };
};
