import PropTypes from 'prop-types';
import React from 'react';

// Harmony Analytics Logo
const Logo = ({color}) => (
  <svg className="logo-glyph" fill={color} viewBox="0 0 1024 1024">
    <path
      d="M186.36,186.433c-146.646,146.592-173.677,367.421-81.2,541.458l180.206-616.695
      C249.931,131.236,216.551,156.265,186.36,186.433z"
    />
    <path
      d="M837.669,186.433C699.349,48.138,494.966,16.353,326.12,90.741L161.177,655.248
      c12.976-15.853,27.882-32.607,44.635-49.205c87.39-86.441,182.568-124.625,275.175-110.57
      c50.465,7.684,101.651,43.607,160.907,85.233c81.25,57.096,173.34,121.771,281.521,121.771h7.915
      C1008.441,532.81,977.248,325.959,837.669,186.433z"
    />
    <path
      d="M475.977,528.467c-199.502-30.399-343.32,225.223-344.759,227.818c-1.415,2.543-3.417,4.547-5.757,6.013
      c17.293,26.595,37.542,51.88,60.9,75.211c179.842,179.767,471.438,179.767,651.309,0c31.038-31.016,56.579-65.421,76.907-101.96
      c-114.681-3.289-208.727-69.121-291.878-127.53C566.888,568.836,518.681,534.967,475.977,528.467z"
    />
  </svg>
);

Logo.propTypes = {color: PropTypes.string};

Logo.defaultProps = {color: 'currentColor'};

export {Logo as LogoGlyph};
