import cn from 'classnames';
import {isEmpty, get} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, formValueSelector} from 'redux-form';

// Local Imports
import complianceManagerTrialTemplateCsv from 'app/assets/docs/compliance-manager-trial-template.csv';
import {Button, MultiUploaderWrapper} from 'app/components';
import {FORM_MESSAGE} from 'app/constants';
import {errorRequiredFields} from 'app/utilities';
import {validateStrategyTrialUpload} from 'app/utilities/fileFunctions';
import {createStagingPortfolio} from 'app/redux/complianceManager';
import {toastError} from 'app/utilities/toast';
import {openSimpleModal} from './SimpleModal/redux';

const validate = values => {
  const errors = errorRequiredFields(values, ['strategyTrialUpload']);

  if (isEmpty(values.strategyTrialUpload)) {
    errors.strategyTrialUpload = 'Required';
  }

  return errors;
};

class StrategyTrialUploader extends Component {
  render() {
    const {
      onFinish,
      meta: {touched, error},
    } = this.props;
    return (
      <div className={cn('form-group', {'is-invalid': touched && error})}>
        <div className="label-hint-container uploader mb-3">
          <label>Select & Upload a CSV File* {touched && error && `(${error})`}</label>
          <span className="label-hint">
            <a href={complianceManagerTrialTemplateCsv} target="_blank">
              Download CSV Template
            </a>
          </span>
        </div>
        <MultiUploaderWrapper
          browseButtonProps={{solid: true}}
          value={this.props.input.value}
          isValidatingFileVisible={true}
          onFilesChanged={(files, s3file) => {
            if (!s3file) {
              this.props.input.onChange([]);
            } else {
              this.props.input.onChange([{name: get(s3file, 'fileKey', null), isNewFile: true}]);
            }
          }}
          uploaderProps={{
            accept: 'text/csv, .csv',
            onError: () => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE),
            onFinish,
            onSignedUrl: () => {
              /* Suppress Console logs */
            },
          }}
        />
      </div>
    );
  }
}

class ComplianceManagerUploadFormComponent extends Component {
  render() {
    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <p>
          You can upload a CSV of a portfolio you would like to test. Uploading a portfolio will remove any changes you
          have made, and will clear any other companies you have added to the ledge.
        </p>

        <div className="mb-9">
          <Field name="strategyTrialUpload" component={StrategyTrialUploader} onFinish={this.props.getCsvFileMeta} />
        </div>

        <div className="d-flex justify-content-between mt-4">
          <Button onClick={this.props.onCancel}>Cancel</Button>
          <Button
            type="submit"
            disabled={
              isEmpty(this.props.strategyTrialUpload) ||
              this.props.isCreatingStagingPortfolio ||
              this.props.hasStagingPortfolioError
            }
            solid
          >
            Save
          </Button>
        </div>
      </form>
    );
  }
}

ComplianceManagerUploadFormComponent.propTypes = {handleSubmit: PropTypes.func, onCancel: PropTypes.func};

const mapStateProps = state => {
  const form = 'compliance-manager-upload-form';
  const selector = formValueSelector(form);
  return {
    form,
    strategyTrialUpload: selector(state, 'strategyTrialUpload'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadInfoModal: (title, message) =>
      dispatch(
        openSimpleModal({
          title,
          message,
          customClassName: 'modal-lg',
        }),
      ),
    createStagingPortfolio: fileKey => dispatch(createStagingPortfolio(fileKey)),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const initialValues = ownProps.marketIndex;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initialValues,
    getCsvFileMeta: validateStrategyTrialUpload(dispatchProps.createStagingPortfolio, dispatchProps.onUploadInfoModal),
  };
};

export const ComplianceManagerUploadForm = compose(
  connect(mapStateProps, mapDispatchToProps, mergeProps),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
)(ComplianceManagerUploadFormComponent);
