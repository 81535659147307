import {filter, get} from 'lodash';
import {createAction} from 'redux-actions';

// Local Imports
import {assignWithState} from 'app/redux/helpers';
import {fetchAuthJSON} from 'app/services/http';
import {ACTION as USER_ACTION} from './user';

// The initial state, including expected shape of the state
const initialState = {
  hasError: false,
  isFetching: false,
  listing: null,
};

// The default state for REQUEST actions
const defaultRequestState = {hasError: false, error: null, isFetching: true};

// ACTION
export const ACTION = {
  GET_PAGED_REQUEST: 'admin-security/get-paged-admins/request',
  GET_PAGED_RECEIVE: 'admin-security/get-paged-admins/receive',
  GET_PAGED_ADMINS_REQUEST: 'admin-security/get-admin-paged-admins/request',
  GET_PAGED_ADMINS_RECEIVE: 'admin-security/get-admin-paged-admins/receive',
};

const getPagedRequest = createAction(ACTION.GET_PAGED_REQUEST);
const getPagedReceive = createAction(ACTION.GET_PAGED_RECEIVE);
const getPagedAdminRequest = createAction(ACTION.GET_PAGED_ADMINS_REQUEST);
const getPagedAdminReceive = createAction(ACTION.GET_PAGED_ADMINS_RECEIVE);

const api = {
  getPaged: options => {
    let url = 'user/pagedAdmin?';
    url += `page=${options.page}`;
    url += `&pageSize=${options.pageSize}`;
    if (options.sortField) {
      // format field:direction
      url += `&sort=${options.sortField || ''}:${options.sortOrder || ''}`;
    }
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`;
    }
    if (options.filter) {
      url += `&filter=${encodeURIComponent(options.filter)}`;
    }

    return fetchAuthJSON(url, {method: 'get'});
  },
  getPagedAdmin: options => {
    const url = 'user/admins';

    return fetchAuthJSON(url, {method: 'get'});
  },
  impersonateUser: userId => {
    const url = `user/impersonateUserAdmin/${userId}`;
    return fetchAuthJSON(url, {method: 'get'});
  },
};

export function getPaged(options) {
  return dispatch => {
    dispatch(getPagedRequest({options}));
    return dispatch(getPagedReceive(api.getPaged(options)));
  };
}

export function getPagedAdmin(options) {
  return dispatch => {
    dispatch(getPagedAdminRequest({options}));
    return dispatch(getPagedAdminReceive(api.getPagedAdmin(options)));
  };
}

// REDUCER
export const adminUser = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_PAGED_REQUEST:
      return assignMergedState({listing: defaultRequestState});
    case ACTION.GET_PAGED_ADMINS_REQUEST:
      return assignMergedState({listing: defaultRequestState});
    case USER_ACTION.DELETE_RECEIVE: {
      const data = get(state, 'listing.data', []);
      const totalSize = get(state, 'listing.totalSize', 0);
      return assignMergedState({
        listing: {
          hasError,
          isFetching: false,
          ...(hasError
            ? {error: payload}
            : {data: filter(data, user => user.id !== Number(payload)), totalSize: totalSize - 1}),
        },
      });
    }
    case ACTION.GET_PAGED_RECEIVE:
      return assignMergedState({
        listing: {
          hasError,
          isFetching: false,
          ...(hasError ? {error: payload} : {data: payload.items, totalSize: payload.total}),
        },
      });
    case ACTION.GET_PAGED_ADMINS_RECEIVE:
      return assignMergedState({
        listing: {
          hasError,
          isFetching: false,
          ...(hasError ? {error: payload} : {data: payload.admins, totalSize: payload.admins.length}),
        },
      });
    default:
      return state;
  }
};
