import {RootStateOrAny, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {ROOT_NODE} from 'v2/constants/rootNode';
import {FilteredOptionsProps, FilterNode} from 'v2/utilities/types/components/FilterList';

export const useFilterNodes = (rootNode = ROOT_NODE) => {
  const {pathname} = useLocation();
  const filterOptions: Record<string, FilteredOptionsProps> = (useSelector<RootStateOrAny>(
    state => state?.filterOptions,
  ) || {}) as Record<string, FilteredOptionsProps>;
  const filterOptionsForPage = filterOptions?.[pathname];
  const newRootNodes: FilterNode[] =
    rootNode.nodes?.map(node => ({...node, options: filterOptionsForPage?.[node.id]})) || [];
  const newRootNode: FilterNode = {...rootNode, nodes: newRootNodes};

  return newRootNode;
};
