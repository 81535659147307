import cn from 'classnames';
import {isValid} from 'date-fns';
import {isEmpty, get, map, orderBy, includes} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import moment from 'moment';

// Local Imports
import harmonyDataTemplateCsv from 'app/assets/docs/harmony-data-template.csv';
import {AUTOCOMPLETE, CONFIG, FORM_MESSAGE, PERMISSIONS} from 'app/constants';
import {Button, FormFeedback, MultiUploaderWrapper, TooltipLink} from 'app/components';
import {errorRequiredFields, normalizeMMDDYYYY} from 'app/utilities';
import {validateUploadedHarmonyData} from 'app/utilities/fileFunctions';
import {toastError} from 'app/utilities/toast';
import {openSimpleModal} from './SimpleModal/redux';
import {TextFormGroup} from './forms';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';

const validate = values => {
  const errors = errorRequiredFields(values, ['harmonyDataUpload', 'date']);

  if (isEmpty(values.harmonyDataUpload)) {
    errors.harmonyDataUpload = 'Required';
  }

  if (!isValid(new Date(values.date))) {
    errors.date = 'Invalid Date';
  }

  return errors;
};

class HarmonyDataUploader extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.input.value !== this.props.input.value && nextProps.input.value === 'EMPTY' && this.multiUploader) {
      this.multiUploader.removeAllFiles();
    }

    return true;
  }

  render() {
    const {
      onFinish,
      meta: {touched, error},
    } = this.props;
    return (
      <div className={cn('form-group', {'is-invalid': touched && error})}>
        <div className="label-hint-container uploader mb-3">
          <label>Select & Upload a CSV File* {touched && error && `(${error})`}</label>
          <span className="label-hint">
            <a href={harmonyDataTemplateCsv} target="_blank">
              Download CSV Template
            </a>
          </span>
        </div>
        <MultiUploaderWrapper
          value={this.props.input.value}
          isValidatingFileVisible={true}
          onFilesChanged={(files, s3file) => {
            if (!s3file) {
              this.props.input.onChange([]);
            } else {
              this.props.input.onChange([{name: get(s3file, 'fileKey', null), isNewFile: true}]);
            }
          }}
          multiUploaderProps={{
            onRef: e => {
              this.multiUploader = e;
            },
          }}
          uploaderProps={{
            accept: 'text/csv, .csv',
            onFinish,
            onError: () => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE),
            onSignedUrl: () => {
              /* Suppress Console logs */
            },
          }}
        />
      </div>
    );
  }
}

class AdminUploadHarmonyDataFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    const pastUploads = orderBy(this.props.securityDataAndRatingUploads || [], ['date', 'createdAt'], ['desc', 'desc']);
    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />

        <div className="form-grid">
          <Field
            label="Effective Date*"
            labelHint={
              <TooltipLink className="float-right" id="effectiveDateTooltip">
                The data in the uploaded CSV should represent a snapshot for a specific date.
              </TooltipLink>
            }
            name="date"
            type="text"
            component={TextFormGroup}
            autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
            normalize={normalizeMMDDYYYY}
            className="col-2"
            maxLength="10"
            placeholder="MM/DD/YYYY"
            disabled={!this.props.canWrite}
          />
          <div className="form-group col-4" />
        </div>
        {this.props.canWrite && (
          <div className="mb-9">
            <Field name="harmonyDataUpload" component={HarmonyDataUploader} onFinish={this.props.getCsvFileMeta} />
          </div>
        )}

        <div className="form-footer is-right mt-12">
          <Button
            type="submit"
            disabled={
              isEmpty(this.props.harmonyDataUpload) ||
              this.props.isCreatingStagingUpload ||
              this.props.hasStagingUploadError ||
              !this.props.canWrite
            }
            solid
          >
            Save
          </Button>
        </div>
        <div className="mb-9">
          <div className="label-hint-container mb-3">
            <label>History of Processed Uploads</label>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Filename</th>
                <th scope="col">Effective Date</th>
                <th scope="col">Date Uploaded</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(pastUploads) ? (
                map(pastUploads, (securityDataAndRatingUpload, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={urlWithToken(
                          `${CONFIG.API_URL}/s3/uploads/${securityDataAndRatingUpload.uploadFilePath}`,
                        )}
                        target="_blank"
                      >
                        {securityDataAndRatingUpload.originalFileName}
                      </a>
                    </td>
                    <td>{moment(securityDataAndRatingUpload.date).utc().format('MMM D, YYYY')}</td>
                    <td>{moment(securityDataAndRatingUpload.createdAt).format('MMM D, YYYY hh:mm A')}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">There are no uploads at this time</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </form>
    );
  }
}

AdminUploadHarmonyDataFormComponent.propTypes = {handleSubmit: PropTypes.func};

const mapStateToProps = state => {
  const form = 'upload-harmony-data';
  const selector = formValueSelector(form);
  return {
    form,
    harmonyDataUpload: selector(state, 'harmonyDataUpload'),
    canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_HARMONY_DATA),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadInfoModal: (title, message) =>
      dispatch(
        openSimpleModal({
          title,
          message,
          customClassName: 'modal-lg',
        }),
      ),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getCsvFileMeta: validateUploadedHarmonyData(ownProps.createStagingUpload, dispatchProps.onUploadInfoModal),
  };
};

export const AdminUploadHarmonyDataForm = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    validate,
    enableReinitialize: true,
  }),
)(AdminUploadHarmonyDataFormComponent);
