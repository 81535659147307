import {isUndefined} from 'lodash';
import {useState} from 'react';
import {Field, useField, useForm} from 'react-final-form';
import styled from 'styled-components/macro';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {CONFIDENCE_LEVELS_OPTION_LIST, SOURCE_TYPES_OPTION_LIST} from 'v2/constants/issueOptions';
import {UNIT_PROPERTIES, unitOptions} from 'v2/utilities/helpers/unitFilters';
import {AdminIssueCard as AdminIssueCardProps} from 'v2/utilities/types/components/AdminFormCard';
import {FlexContainer} from '../atoms/Containers';
import {AdminFormCard} from '../molecules/AdminFormCard';
import {SelectFormGroup} from '../molecules/DropDown';
import {TextFormGroup} from '../molecules/TextInput';
import {FileBrowserWithUploadInput} from './FileBrowserWithUploadInput';
import {convertUnits, getPrecision} from '../../utilities/helpers/convertUnits';

type FormValues = {
  value: string | number;
  unit?: string;
  confidenceLevel: string;
  sourceType: string;
  sourceLink: string;
};

const Paragraph = styled.span`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
`;

const usePrecision = ({amount}: {amount: number}) => {
  const [precision] = useState(getPrecision(amount));

  return {precision};
};

const validateUnitMax = (value: string | number, unit: string = '') => {
  return Number(value) <= (UNIT_PROPERTIES[unit]?.max || Infinity)
    ? undefined
    : `Value must be less than ${UNIT_PROPERTIES[unit]?.max}`;
};

const UnitField = () => {
  const name = 'unit';
  const valueFieldName = 'value';

  const {change: setValue} = useForm();
  const {meta, input} = useField(name);
  const {input: valueInput} = useField(valueFieldName);

  const {value: amount} = valueInput;
  const {value: oldUnit} = input;

  const {precision} = usePrecision({amount});

  const handleChangeUnit = (unit: any) => {
    const newValue = convertUnits(amount, oldUnit, unit, precision);
    setValue(name, unit);
    setValue(valueFieldName, newValue);
  };

  return (
    <FlexContainer items="center" gap="0.375rem">
      <label htmlFor={input.name} style={{marginBottom: '1.625rem'}}>
        in
      </label>
      <SelectFormGroup
        input={{...input, onChange: handleChangeUnit}}
        placeholder={DATA_STATUS.NONE}
        options={unitOptions(input?.value)}
        meta={meta.touched && meta}
      />
    </FlexContainer>
  );
};

export const AdminIssueCard = ({
  companyId,
  companyName,
  isFixed,
  issueId,
  issueName,
  unit,
  year,
  ...rest
}: AdminIssueCardProps) => {
  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.value) {
      errors.value = 'Value is required';
    }

    if (validateUnitMax(values.value, values.unit)) {
      errors.value = validateUnitMax(values.value, values.unit);
    }

    if (isFixed && !values.unit) {
      errors.unit = 'Unit is required';
    }

    if (!values.confidenceLevel) {
      //@ts-ignore
      errors.confidenceLevel = 'Confidence level is required';
    }

    if (!values.sourceType) {
      //@ts-ignore
      errors.sourceType = 'Source type is required';
    }

    if (!values.sourceLink) {
      errors.sourceLink = 'Source link is required';
    }

    return errors;
  };

  const title = () => {
    if (isUndefined(companyName) || isUndefined(issueName) || isUndefined(year)) {
      return DATA_STATUS.NONE;
    }

    return `Editing ${year} ${issueName} for ${companyName}`;
  };

  return (
    <AdminFormCard validate={validate} title={title()} year={year} issueId={issueId} {...rest}>
      <FlexContainer gap="3rem">
        <FlexContainer direction="column" items="flex-end" gap="0" flex={1} margin="0 0 2rem 0">
          <Field name="value">
            {props => (
              <FlexContainer direction="column" flex={1} width="100%">
                <label htmlFor={props.input.name}>Value</label>
                <TextFormGroup
                  input={props.input}
                  placeholder={DATA_STATUS.NONE}
                  meta={props.meta.touched && props.meta}
                />
              </FlexContainer>
            )}
          </Field>
          {isFixed ? <UnitField /> : <Paragraph>in {unit ?? DATA_STATUS.NONE}</Paragraph>}
        </FlexContainer>
        <FlexContainer flex={1}>
          <Field name="confidenceLevel">
            {props => (
              <FlexContainer direction="column" flex={1}>
                <label htmlFor={props.input.name}>Confidence Level</label>
                <SelectFormGroup
                  input={props.input}
                  placeholder={DATA_STATUS.NONE}
                  options={CONFIDENCE_LEVELS_OPTION_LIST}
                  meta={props.meta.touched && props.meta}
                />
              </FlexContainer>
            )}
          </Field>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer gap="3rem" flex={1}>
        <FlexContainer flex="1">
          <Field name="sourceType">
            {props => (
              <FlexContainer direction="column" flex={1}>
                <label htmlFor={props.input.name}>Source Type</label>
                <SelectFormGroup
                  input={props.input}
                  placeholder={DATA_STATUS.NONE}
                  options={SOURCE_TYPES_OPTION_LIST}
                  meta={props.meta.touched && props.meta}
                />
              </FlexContainer>
            )}
          </Field>
        </FlexContainer>
        <FlexContainer flex="1" direction="column">
          <label>Source File</label>
          <FileBrowserWithUploadInput
            accept="application/pdf, .pdf"
            companyId={companyId}
            name="sourceLink"
            issueId={issueId}
          />
        </FlexContainer>
      </FlexContainer>
    </AdminFormCard>
  );
};
