import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  margin-right: 1.875rem;
`;

const PercentContainer = styled.div`
  font-family: Inter;
  font-size: 34px;
  line-height: 40px;
  font-weight: 600;
`;

const TitleContainer = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  margin-top: 0.675rem;
  width: 75px;
`;

export type IconPercentDisplayProps = {
  percent: number;
  title: string;
  icon: any;
};

export const IconPercentDisplay = ({percent = 0, title = '', icon = null}: IconPercentDisplayProps) => {
  return (
    <MainContainer>
      <IconContainer>{icon}</IconContainer>
      <RightContainer>
        <PercentContainer>{percent}%</PercentContainer>
        <TitleContainer>{title}</TitleContainer>
      </RightContainer>
    </MainContainer>
  );
};
