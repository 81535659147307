import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import faUsdCircle from 'app/fontawesome-pro-light/faUsdCircle';
import {faCaretUp} from '@fortawesome/free-solid-svg-icons/faCaretUp';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown';
import {DATA_CATEGORY} from '../constants';
import {IssueDataCard} from '.';

class IssueDataCardDeck extends Component {
  state = {
    open: true,
  };

  cardBaseWidth = 20;

  styles = {
    cardDeckHeader: {
      background: this.getHeaderGradient(),
      cursor: 'pointer',
    },
    cardBox: {
      minWidth: `${this.cardBaseWidth}rem`,
      height: `${(this.cardBaseWidth / 3) * 4}rem`,
      flex: '1 0 0',
    },
    spacer: {
      height: 0,
      minWidth: `${this.cardBaseWidth}rem`,
      flex: '1 0 0',
    },
  };

  getHeaderGradient() {
    switch (this.props.category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return 'linear-gradient(to right, #27A37D, #34B991)';
      case DATA_CATEGORY.SOCIAL:
        return 'linear-gradient(to right, #008BB7, #009BCC)';
      case DATA_CATEGORY.GOVERNANCE:
        return 'linear-gradient(to right, #034682, #003462)';
      case DATA_CATEGORY.FINANCIAL:
        return 'linear-gradient(to right, #7E7E7E, #6D6D6D)';
      default:
        return '';
    }
  }

  renderIcon() {
    switch (this.props.category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return <Icon icon={faSeedling} className="header-icon" />;
      case DATA_CATEGORY.SOCIAL:
        return <Icon icon={faHandHeart} className="header-icon" />;
      case DATA_CATEGORY.GOVERNANCE:
        return <Icon icon={faLandmark} className="header-icon" />;
      case DATA_CATEGORY.FINANCIAL:
        return <Icon icon={faUsdCircle} className="header-icon" />;
      default:
        return <div />;
    }
  }

  toggle = () => {
    this.setState({open: !this.state.open});
  };

  render() {
    const {category = '', data = []} = this.props;

    return (
      <div className="issue-data-group">
        <div className="issue-data-group-header" style={this.styles.cardDeckHeader} onClick={this.toggle}>
          <table>
            <thead>
              <tr>
                <th>
                  {this.renderIcon()}
                  <span className="issue-data-group-title">{category}</span>
                </th>
                <th className="text-right">
                  <Icon icon={this.state.open ? faCaretUp : faCaretDown} style={{fontSize: '1.5rem'}} />
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          className={`${this.state.open ? 'd-flex' : 'd-none'} flex-row flex-wrap mt-2`}
          style={{marginLeft: '-0.75rem', marginRight: '-0.75rem'}}
        >
          {data?.map((datum, i) => {
            return (
              <div key={`card-deck-${i}`} className="my-3 mx-3" style={this.styles.cardBox}>
                <IssueDataCard category={category} data={datum} />
              </div>
            );
          })}
          {data?.slice(1).map((datum, i) => {
            return <div key={`card-spacer-${i}`} className="mx-3" style={this.styles.spacer} />;
          })}
        </div>
      </div>
    );
  }
}

IssueDataCardDeck.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export {IssueDataCardDeck};
