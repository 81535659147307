import styled from 'styled-components/macro';
import {Container, AttachedWrapper, FlexContainer} from 'v2/components/atoms/Containers';
import {SubtitleM, ParagraphS} from 'v2/components/atoms/Typeface';
import {ESGCompanyIssuePannel, OverallCompanyPanel} from 'v2/components/molecules/ESGDisplays';
import {esgCompanyDescription} from 'v2/constants/esgPageDescriptions';
import {useCompaniesESGRisk} from 'v2/hooks/useCompaniesESGRisk';
import {useCompanySummary} from 'v2/hooks/useCompanySummary';
import ESGChartExample from '../../../assets/images/ESG_Risk_chart.svg';
import ReactPlaceholder from 'react-placeholder';
import {TextBlock, MediaBlock, TextRow, RectShape, RoundShape} from 'react-placeholder/lib/placeholders';
import {Theme} from 'v2/components/atoms/theme';

const PercentileRankeTitle = styled(SubtitleM)`
  display: block;
  text-transform: uppercase;
  padding-bottom: 1.0625rem;
`;

const ESGRow = styled(Container)`
  border-bottom: 1px solid #d0d6de;
  &:last-of-type {
    border-bottom: none;
  }
`;

const ESGChart = styled.img`
  max-width: 28.375rem;
  margin-bottom: 5rem;
`;

const ReactPlaceholderESGRisk = () => (
  <div style={{marginBottom: 50}}>
    <FlexContainer gap="14rem">
      <FlexContainer direction="column" justify="space-between">
        <Container>
          <Container>
            <RectShape color={Theme.colors.grey_2} style={{width: 275, height: 100}} />
          </Container>
          <Container padding="2.875rem 0 0">
            <RectShape color={Theme.colors.grey_2} style={{width: 200, height: 20}} />
            <br></br>
            <TextBlock rows={4} color={Theme.colors.grey_2} />
          </Container>
        </Container>
        <Container>
          <RectShape color={Theme.colors.grey_2} style={{width: 450, height: 180}} />
        </Container>
      </FlexContainer>
      <Container minWidth="321px">
        <FlexContainer direction="column">
          <ESGRow padding=".6875rem 0.5rem 3.5rem">
            <TextBlock rows={3} color={Theme.colors.grey_2} />
          </ESGRow>
          <ESGRow padding="3.5rem 0.5rem">
            <TextBlock rows={3} color={Theme.colors.grey_2} />
          </ESGRow>
          <ESGRow padding="3.5rem 0.5rem">
            <TextBlock rows={3} color={Theme.colors.grey_2} />
          </ESGRow>
        </FlexContainer>
      </Container>
    </FlexContainer>
  </div>
);

export const UT1CompaniesESGRisk = () => {
  const {
    data: {overall: overallData},
    isLoading: overallLoading,
  } = useCompanySummary();
  const {environmentalData, socialData, governanceData, overall, isLoading: esgLoading} = useCompaniesESGRisk();

  const isLoading = overallLoading || esgLoading;

  return (
    <AttachedWrapper>
      <FlexContainer padding="2.8125rem 2.8125rem 0 4.75rem" gap="14rem">
        <ReactPlaceholder ready={!isLoading} customPlaceholder={<ReactPlaceholderESGRisk />}>
          <FlexContainer direction="column" justify="space-between">
            <Container>
              <Container>
                <OverallCompanyPanel
                  quintile={overallData.quintile}
                  percentile={overallData.percentile}
                  industry={overall?.industry}
                />
              </Container>
              <Container padding="2.875rem 0 0">
                <PercentileRankeTitle>Percentile Rank</PercentileRankeTitle>
                <ParagraphS as="p">{esgCompanyDescription}</ParagraphS>
              </Container>
            </Container>
            <Container>
              <ESGChart src={ESGChartExample} />
            </Container>
          </FlexContainer>
          <Container minWidth="321px">
            <FlexContainer direction="column">
              <ESGRow padding=".6875rem 0.5rem 3.5rem">
                <ESGCompanyIssuePannel
                  title="Environmental"
                  percentile={environmentalData.percentileRank}
                  industry={environmentalData.industry}
                  quintile={environmentalData.quintile}
                />
              </ESGRow>
              <ESGRow padding="3.5rem 0.5rem">
                <ESGCompanyIssuePannel
                  title="Social"
                  percentile={socialData.percentileRank}
                  industry={socialData.industry}
                  quintile={socialData.quintile}
                />
              </ESGRow>
              <ESGRow padding="3.5rem 0.5rem">
                <ESGCompanyIssuePannel
                  title="Governance"
                  percentile={governanceData.percentileRank}
                  industry={governanceData.industry}
                  quintile={governanceData.quintile}
                />
              </ESGRow>
            </FlexContainer>
          </Container>
        </ReactPlaceholder>
      </FlexContainer>
    </AttachedWrapper>
  );
};
