import React from 'react';
import PropTypes from 'prop-types';
import {each, isEmpty, map} from 'lodash';
import numeral from 'numeral';
import cn from 'classnames';

const POWER_ROUNDING_PRECISION = 10;

const FORMAT = {
  PERCENT: value => Math.round(value * 100) + '%',
  NEAREST_POWER: value => {
    const preFormat = numeral(value).format('0a+');
    const roundedValue = parseInt(preFormat, 10);
    const formattedRoundedValue = Math.max(
      1,
      numeral(Math.floor(roundedValue / POWER_ROUNDING_PRECISION) * POWER_ROUNDING_PRECISION).format(0, 0),
    );
    const suffix = preFormat.substr(roundedValue.toString().length);

    return `$${formattedRoundedValue}${suffix}`;
  },
  INTEGER: value => numeral(value).format('0,0'),
};

const STAT_TYPES = [
  {
    label: 'of your managers are in compliance',
    formula: stats => stats.managerInComplianceCount / stats.managerCount,
    format: FORMAT.PERCENT,
    isVisible: props => !props.isManagersInComplianceStatHidden,
  },
  {
    label: 'companies engaged to improve their ESG metrics',
    formula: stats => stats.companyEngagedCount,
    threshold: 25,
    format: FORMAT.INTEGER,
    isVisible: props => !props.isCompaniesEngagedStatHidden,
  },
  {
    label: 'of engaged companies have responded with a plan of action describing how they’ll do better',
    formula: stats => stats.companyRespondedCount / stats.companyEngagedCount,
    format: FORMAT.PERCENT,
    threshold: 0.5,
    isVisible: props => !props.isCompaniesRespondedStatHidden,
  },
  {
    label: 'AUM have joined you to empower ESG change',
    formula: stats => stats.aum,
    threshold: 1000000000,
    format: FORMAT.NEAREST_POWER,
    isVisible: props => !props.isAUMStatHidden,
  },
  {
    label: 'of your managers compelled to add an ESG Policy',
    formula: stats => stats.managerWithESGPolicyCount / stats.managerCount,
    format: FORMAT.PERCENT,
    isVisible: props => !props.isManagersWithESGPolicyStatHidden,
  },
];

const EmpoweringChangeStats = props => {
  const stats = [];
  each(STAT_TYPES, ({isVisible, label, formula, format, threshold}) => {
    if (isVisible(props)) {
      const value = formula(props.stats);
      if (!threshold || value >= threshold) {
        stats.push({
          label,
          value: format ? format(value) : value,
        });
      }
    }
  });

  if (isEmpty(stats)) return null;

  return (
    <ul className={cn('empowering-change-stats', props.className, {[`is-${props.type}`]: props.type})}>
      {map(stats, ({label, value}) => (
        <li key={label} className="empowering-change-stat">
          <div className="empowering-change-stat-value">{value}</div>
          <div className="empowering-change-stat-label">{label}</div>
        </li>
      ))}
    </ul>
  );
};

EmpoweringChangeStats.propTypes = {
  className: PropTypes.string,
  isAUMStatHidden: PropTypes.bool,
  isCompaniesEngagedStatHidden: PropTypes.bool,
  isCompaniesRespondedStatHidden: PropTypes.bool,
  isManagersInComplianceStatHidden: PropTypes.bool,
  isManagersWithESGPolicyStatHidden: PropTypes.bool,
  type: PropTypes.oneOf(['print', 'screen']),
};

EmpoweringChangeStats.defaultProps = {type: 'screen'};

export {EmpoweringChangeStats};
