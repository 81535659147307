import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faTimes from 'app/fontawesome-pro-light/faTimes';

const JoyrideTooltip = ({index, isLastStep, step, backProps, primaryProps, skipProps, tooltipProps}) => {
  return (
    <div {...tooltipProps} className="joyride-tooltip-bg">
      <div className="joyride-tooltip-header-div">
        {step.title && <span className="futura-bold text-white">{step.title}</span>}
        <Icon icon={faTimes} color="white" size={20} {...skipProps} className="joyride-tooltip-close" />
      </div>
      {step.content && <span className="text-white">{step.content}</span>}
      <div className="joyride-tooltip-footer">
        {index === 0 ? null : (
          <button {...backProps} className="joyride-tooltip-button">
            {' '}
            back{' '}
          </button>
        )}
        <button {...primaryProps} className="joyride-tooltip-button">
          {' '}
          {isLastStep ? 'close' : 'next'}{' '}
        </button>
      </div>
    </div>
  );
};

export {JoyrideTooltip};
