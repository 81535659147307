import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faBookmark as faBookmarkSolid} from '@fortawesome/free-solid-svg-icons';
import {compose, withProps, withState} from 'recompose';
import uniqueId from 'lodash/uniqueId';
import cn from 'classnames';

import faBookmark from 'app/fontawesome-pro-light/faBookmark';
import {ToolTip} from './Tooltip';
import {VoidLink} from './VoidLink';

const BookmarkToggle = compose(
  withState('tooltipId', null, () => uniqueId('bookmarkToggle_tooltip_')),
  withProps(({isActive, ...rest}) => ({
    icon: isActive ? faBookmarkSolid : faBookmark,
    label: isActive ? rest.activeLabel : rest.inactiveLabel,
    tooltip: isActive ? rest.activeTooltip : rest.inactiveTooltip,
  })),
)(({className, handleClick, icon, label, style, tooltip, tooltipId}) => (
  <VoidLink className={cn('hover:text-decoration-none', className)} onClick={handleClick} id={tooltipId} style={style}>
    <Icon icon={icon} className={label ? 'mr-2' : ''} />
    {label}
    {!!tooltip && <ToolTip target={tooltipId}>{tooltip}</ToolTip>}
  </VoidLink>
));

BookmarkToggle.propTypes = {
  activeLabel: PropTypes.node,
  activeTooltip: PropTypes.node,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool,
  inactiveLabel: PropTypes.node,
  inactiveTooltip: PropTypes.node,
  style: PropTypes.object,
};

BookmarkToggle.defaultProps = {
  activeLabel: 'Bookmark Saved',
  activeTooltip: `This company has been saved to your Bookmarks.
    You can now add it in your Portfolio Center and
    see how it affects the compliance of the portfolio.`,
  inactiveLabel: 'Save to Bookmarks',
  inactiveTooltip: `Save this company to Bookmarks to add into a test portfolio
    when evaluating your holdings in the Portfolio Center.`,
};

export {BookmarkToggle};
