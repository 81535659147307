import React from 'react';
import styled from 'styled-components/macro';
import {RadioButton as RadioButtonProps} from 'v2/utilities/types/components/RadioButton';

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const CustomRadio = styled.div`
  min-width: 1.25em;
  height: 1.25em;
  border: 2px solid ${({theme}) => theme.colors.grey_3};
  border-radius: 50%;
  box-sizing: border-box;
  padding: 2px;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: ${({theme}) => theme.colors.harmony};
    border-radius: 50%;
    transform: scale(0);
  }
`;

const NativeRadio = styled.input.attrs({type: 'radio'})<RadioButtonProps>`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  &:checked + ${CustomRadio}::after {
    transform: scale(1);
  }
`;

const NativeRadioRing = styled(NativeRadio)`
  &:checked + ${CustomRadio} {
    border: 4px solid ${({theme}) => theme.colors.harmony};
  }
`;

const ChildContainer = styled.div`
  position: relative;
  top: -0.0625rem;
  padding-left: 1rem;
`;

export const RadioButton = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof NativeRadio>>(
  ({id, children, ...rest}, ref) => {
    return (
      <Label htmlFor={id}>
        <NativeRadio id={id} ref={ref} {...rest} />
        <CustomRadio />
        <ChildContainer>{children}</ChildContainer>
      </Label>
    );
  },
);

const CustomRadioRing = styled(CustomRadio)`
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: ${({theme}) => theme.colors.white};
    border-radius: 50%;
    transform: scale(0);
  }
`;

export const RadioRingButton = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof NativeRadio>>(
  ({id, children, ...rest}, ref) => {
    return (
      <Label htmlFor={id}>
        <NativeRadioRing id={id} ref={ref} {...rest} />
        <CustomRadioRing />
        <ChildContainer>{children}</ChildContainer>
      </Label>
    );
  },
);
