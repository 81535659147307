import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, FieldArray, reduxForm} from 'redux-form';

// Local Imports
import {AUTOCOMPLETE, CHARACTER_LIMIT, REGEX_PATTERN} from 'app/constants';
import {Button, FormFeedback} from 'app/components';
import {TextFormGroup} from 'app/components/forms';
import {getEmailPrimaryDomain, normalizeEmail, validateFieldArray} from 'app/utilities';

const BATCH_SIZE = 3;

const validate = (values, ownProps) => {
  const errors = {};

  const investmentManagersToInviteErrors = validateFieldArray(
    values.investmentManagersToInvite,
    (manager, ownErrors) => {
      if (manager.email || manager.jobRole) {
        if (!manager.email) ownErrors.email = 'Required';
        if (!manager.jobRole) ownErrors.jobRole = 'Required';
      }

      if (!ownErrors.email && manager.email) {
        if (!REGEX_PATTERN.VALID_EMAIL.test(manager.email)) {
          ownErrors.email = 'Invalid email address';
        } else if (getEmailPrimaryDomain(manager.email) !== ownProps.allowedEmailPrimaryDomain) {
          ownErrors.email = `Email address must end with "${ownProps.allowedEmailPrimaryDomain}"`;
        }
      }
    },
  );
  investmentManagersToInviteErrors && (errors.investmentManagersToInvite = investmentManagersToInviteErrors);

  return errors;
};

const renderInvestmentManagersToInvite = ({fields: investmentManagersToInvite, allowedEmailPrimaryDomain}) => (
  <Fragment>
    <div className="form-grid">
      {investmentManagersToInvite.map((investmentManagerToInvite, index) => {
        const doShowLabel = index === 0; // first item
        return (
          <Fragment key={index}>
            <Field
              label={doShowLabel ? 'Email Address*' : null}
              name={`${investmentManagerToInvite}.email`}
              type="text"
              placeholder={`EX: joe@${allowedEmailPrimaryDomain}`}
              component={TextFormGroup}
              normalize={normalizeEmail}
              className="col-3 min-h-0 mb-3"
              autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              maxLength={CHARACTER_LIMIT.EMAIL}
            />
            <Field
              label={doShowLabel ? 'Role*' : null}
              name={`${investmentManagerToInvite}.jobRole`}
              type="text"
              placeholder="EX: Data Analyst"
              component={TextFormGroup}
              className="col-3 min-h-0 mb-3"
              autoComplete={AUTOCOMPLETE.FORCE_AUTOCOMPLETE_OFF}
              maxLength={CHARACTER_LIMIT.PROFILE_ROLE}
            />
          </Fragment>
        );
      })}
    </div>

    <Button
      className="mt-6"
      onClick={() => {
        for (let i = 0; i < BATCH_SIZE; i += 1) {
          investmentManagersToInvite.push({});
        }
      }}
    >
      Add Additional Managers
    </Button>
  </Fragment>
);

class OnboardingUt3InviteFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />

        <FieldArray
          name="investmentManagersToInvite"
          component={renderInvestmentManagersToInvite}
          allowedEmailPrimaryDomain={this.props.allowedEmailPrimaryDomain}
        />

        <div className="form-footer is-right mt-12">
          <Button type="submit" solid>
            Save &amp; Continue
          </Button>
        </div>
      </form>
    );
  }
}

OnboardingUt3InviteFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingUser: PropTypes.object,
};

OnboardingUt3InviteFormComponent.defaultProps = {onboardingUser: {}};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingInvitation = {}, onboardingUser} = ownProps;
  const {email: userEmail} = onboardingUser;

  const allowedEmailPrimaryDomain = getEmailPrimaryDomain(userEmail);

  let managers = get(onboardingInvitation, 'investmentManagersToInvite');

  // Make sure it's an array
  if (!managers || !managers.length) managers = [];

  // Fill to next BATCH_SIZE with empty rows
  const numberOfRows = Math.max(Math.ceil(managers.length / BATCH_SIZE) * BATCH_SIZE, BATCH_SIZE);
  for (let i = managers.length; i < numberOfRows; i += 1) {
    managers.push({});
  }

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    allowedEmailPrimaryDomain,
    initialValues: {investmentManagersToInvite: managers},
  };
};

export const OnboardingUt3InviteForm = compose(
  connect(null, null, mergeProps),
  reduxForm({
    form: 'onboarding-ut3-invite-form',
    validate,
  }),
)(OnboardingUt3InviteFormComponent);
