/* eslint-disable max-len */
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import moment from 'moment';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faArrowToBottom from 'app/fontawesome-pro-light/faArrowToBottom';
import faPaperclip from 'app/fontawesome-pro-light/faPaperclip';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';
import faChevronLeft from 'app/fontawesome-pro-light/faChevronLeft';
import faChevronRight from 'app/fontawesome-pro-light/faChevronRight';
import {VimeoPlayer} from 'app/components/VimeoPlayer';
import {getRoadmapData, postRoadmapData} from 'app/redux/companyRoadmap';
import {getCompanyWhitePapersData} from '../redux/companyWhitePapers';
import {getCompanyVideosData} from '../redux/companyVideos';

class CompanyRoadmapPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(getRoadmapData());
    props.dispatch(getCompanyWhitePapersData());
    props.dispatch(getCompanyVideosData());

    this.state = {
      engagementLetterIndex: 0,
      currentActionPlanIssueId: 0,
      openModal: false,
      dataId: '',
      dataValue: '',
      dataCategory: '',
      currentVideoIndex: 1,
      actionPlanFiles: {},
      carbonPlanFile: null,
    };

    this.actionPlanFile = React.createRef();
    this.carbonPlanFile = React.createRef();
  }

  // eslint-disable-next-line class-methods-use-this
  mod(n, m) {
    return ((n % m) + m) % m;
  }

  actionPlanFileAction = id => {
    this.setState({currentActionPlanIssueId: id});
    this.actionPlanFile.current.click();
  };

  actionPlanFileChange = e => {
    window.scroll(0, 0);
    this.setState({
      openModal: true,
      dataId: this.state.currentActionPlanIssueId,
      dataValue: e.target.files[0],
      dataCategory: 'actionPlan',
    });
  };

  carbonPlanFileAction = () => {
    this.carbonPlanFile.current.click();
  };

  carbonPlanFileChange = e => {
    window.scroll(0, 0);
    this.setState({
      openModal: true,
      dataId: this.props.dataRoadmap.company.id,
      dataValue: e.target.files[0],
      dataCategory: 'carbonNeutralPledgePlan',
    });
  };

  determineTarget = (issue, target) => {
    if (issue.issueType === 'Numeric' && target) {
      return `${target}${this.determineUnit(issue)}`;
    }
    return '';
  };

  determineTargetLastMeasure = (issue, issueValue) => {
    if (issue.issueType === 'Numeric' && issue.target && issueValue) {
      let targetLastMeasure = issueValue / issue.target;
      const targetUnit = this.determineUnit(issue);
      if (targetUnit === '%') {
        targetLastMeasure *= 100;
      }
      return `${targetLastMeasure}${targetUnit}`;
    }
    return 'N/A';
  };

  determineUnit = issue => {
    // eslint-disable-next-line no-nested-ternary
    return `${issue.unit ? (issue.unit === '%' ? issue.unit : ' ' + issue.unit) : ''}`;
  };

  setConfirmValue = (id, category, value) => {
    if (value !== '') {
      window.scroll(0, 0);
      this.setState({
        openModal: true,
        dataId: id,
        dataValue: value,
        dataCategory: category,
      });
    }
  };

  saveRoadmapData = () => {
    this.setState({openModal: false});
    if (this.state.dataCategory === 'actionPlan') {
      const actionPlanFiles = {...this.state.actionPlanFiles};
      actionPlanFiles[this.state.dataId] = {
        file: this.state.dataValue,
        date: moment().format('YYYY-MM-DD'),
      };
      this.setState({actionPlanFiles});
    } else if (this.state.dataCategory === 'carbonNeutralPledgePlan') {
      const carbonPlanFile = this.state.dataValue;
      this.setState({carbonPlanFile});
    }
    this.props.dispatch(
      postRoadmapData(
        this.state.dataId,
        this.state.dataCategory,
        this.state.dataValue,
        this.props.dataRoadmap.company.id,
      ),
    );
  };

  render() {
    const {
      engagementLetterIndex,
      openModal,
      dataValue,
      dataCategory,
      currentVideoIndex,
      actionPlanFiles,
      carbonPlanFile,
    } = this.state;
    // eslint-disable-next-line no-unused-vars
    const {isFetchingRoadmap, dataRoadmap, isFetchingWhitePapers, dataWhitePapers, dataVideos} = this.props;

    if (dataRoadmap === undefined) {
      return null;
    }

    return (
      <div className="company-roadmap-wrapper">
        <div className="company-roadmap-container">
          <div className="company-roadmap-title">
            <h1>ROADMAP</h1>
          </div>
          <div className="company-roadmap-table">
            <div className="pledge-table-header">
              <div className="header-1" />
              <div className="header-2">
                <p>SIGNED</p>
              </div>
              <div className="header-3">
                <p>TARGET YEAR</p>
              </div>
              <div className="header-4" />
            </div>
            <table className="pledege-table">
              <tbody>
                <tr>
                  <td className="cell-1">
                    <p>CARBON NEUTRAL PLEDGE</p>
                  </td>
                  <td className="cell-2">
                    <input
                      type="date"
                      className={dataRoadmap.company.carbonNeutralPledgeSigned ? 'readOnly-input' : ''}
                      defaultValue={
                        dataRoadmap.company.carbonNeutralPledgeSigned
                          ? moment(dataRoadmap.company.carbonNeutralPledgeSigned).utc().format('YYYY-MM-DD')
                          : ''
                      }
                      onBlur={e => {
                        if (e.target.value.length === 10) {
                          this.setConfirmValue(dataRoadmap.company.id, 'carbonNeutralPledgeSigned', e.target.value);
                        }
                      }}
                      disabled={dataRoadmap.company.carbonNeutralPledgeSigned}
                    />
                  </td>
                  <td className="cell-3">
                    <input
                      type="number"
                      min={2000}
                      max={new Date().getFullYear() + 100}
                      className={dataRoadmap.company.carbonNeutralPledgeTargetYear ? 'readOnly-input' : ''}
                      defaultValue={
                        dataRoadmap.company.carbonNeutralPledgeTargetYear
                          ? dataRoadmap.company.carbonNeutralPledgeTargetYear
                          : ''
                      }
                      onBlur={e => {
                        if (e.target.value.length === 4) {
                          if (e.target.value > 2000 && e.target.value <= new Date().getFullYear() + 100) {
                            this.setConfirmValue(
                              dataRoadmap.company.id,
                              'carbonNeutralPledgeTargetYear',
                              e.target.value,
                            );
                          }
                        }
                      }}
                      disabled={dataRoadmap.company.carbonNeutralPledgeTargetYear}
                    />
                  </td>
                  <td className="cell-4">
                    <input
                      key={`carbonNeutralPledgePlan-${dataRoadmap.company.id}`}
                      type="file"
                      hidden
                      ref={this.carbonPlanFile}
                      onChange={this.carbonPlanFileChange}
                    />
                    <button
                      type="file"
                      className="download-button"
                      style={
                        dataRoadmap.company.carbonNeutralPledgePlan || carbonPlanFile
                          ? {}
                          : {backgroundColor: 'rgba(249, 249, 249, 1)'}
                      }
                      onClick={() => {
                        if (dataRoadmap.company.carbonNeutralPledgePlan) {
                          window.open(dataRoadmap.company.carbonNeutralPledgePlan, '_blank');
                        } else if (carbonPlanFile) {
                          const url = window.URL.createObjectURL(carbonPlanFile);
                          window.open(url, '_blank');
                        } else {
                          this.carbonPlanFileAction();
                        }
                      }}
                    >
                      <Icon
                        className="download-icon"
                        icon={dataRoadmap.company.carbonNeutralPledgePlan ? faArrowToBottom : faPaperclip}
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="small-spacing" />
          {dataRoadmap.companyEngagementLetters[engagementLetterIndex] && (
            <div className="company-roadmap-table">
              <div className="engagement-table-header">
                <div className="header-start">
                  <p className="engagement-letter-text">Engagement Letter</p>
                  <p className="engagement-date-text">
                    {moment(dataRoadmap.companyEngagementLetters[engagementLetterIndex].sentAt)
                      .utc()
                      .format('MMMM DD, YYYY')}
                  </p>
                </div>
                {dataRoadmap.companyEngagementLetters
                  .sort((a, b) => {
                    return moment(a.sentAt) - moment(b.sentAt);
                  })
                  .map((letter, index) => {
                    return (
                      <div className="header-section-container" key={`engagementLetter-${letter.id}`}>
                        <div className="header-section" onClick={() => this.setState({engagementLetterIndex: index})}>
                          <p className={index === engagementLetterIndex ? 'selected' : 'unselected'}>
                            {moment(letter.sentAt).utc().year() - 1}
                          </p>
                        </div>
                        {index === engagementLetterIndex && <div className="selected-arrow" />}
                      </div>
                    );
                  })}
              </div>
              <table className="engagement-table">
                <thead>
                  <tr>
                    <th>
                      <p className="first-section">Issues</p>
                    </th>
                    <th>
                      <div style={{display: 'flex'}}>
                        <p className="other-section">Issues Reviewed</p>
                      </div>
                    </th>
                    <th>
                      <div style={{display: 'flex'}}>
                        <p className="other-section">Action Plan</p>
                      </div>
                    </th>
                    <th />
                    <th>
                      <div style={{display: 'flex'}}>
                        <p className="other-section">Last Measure</p>
                      </div>
                    </th>
                    <th>
                      <div style={{display: 'flex'}}>
                        <p className="other-section">Target</p>
                      </div>
                    </th>
                    <th>
                      <div style={{display: 'flex'}}>
                        <p className="other-section">Target Date</p>
                      </div>
                    </th>
                    <th>
                      <div style={{display: 'flex'}}>
                        <p className="other-section">Target Met</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataRoadmap.companyEngagementLetters[engagementLetterIndex].companyEngagementLetterIssues
                    .sort((a, b) => {
                      return a.id - b.id;
                    })
                    .map(issue => {
                      return (
                        <tr className="table-row" key={`engagementLetterIssue-${issue.id}`}>
                          <td className="cell-1">
                            <p>{issue.issueName}</p>
                          </td>
                          <td className="cell-2">
                            <input
                              placeholder={issue.step1CompletedAt ? 'mm/dd/yyyy' : ''}
                              type={issue.step1CompletedAt ? 'date' : 'text'}
                              className="readOnly-input"
                              defaultValue={
                                issue.step1CompletedAt ? moment(issue.step1CompletedAt).utc().format('YYYY-MM-DD') : ''
                              }
                              disabled={issue.step1CompletedAt}
                            />
                          </td>
                          <td className="cell-3">
                            <input
                              placeholder={issue.actionPlanUploadDate || actionPlanFiles[issue.id] ? 'mm/dd/yyyy' : ''}
                              type={issue.actionPlanUploadDate || actionPlanFiles[issue.id] ? 'date' : 'text'}
                              className="readOnly-input"
                              value={
                                // eslint-disable-next-line no-nested-ternary
                                issue.actionPlanUploadDate
                                  ? moment(issue.actionPlanUploadDate).utc().format('YYYY-MM-DD')
                                  : actionPlanFiles[issue.id]
                                  ? actionPlanFiles[issue.id].date
                                  : ''
                              }
                              disabled
                            />
                          </td>
                          <td className="cell-4">
                            <input
                              key={`actionPlan-${issue.id}`}
                              type="file"
                              hidden
                              ref={this.actionPlanFile}
                              onChange={this.actionPlanFileChange}
                            />
                            <button
                              type="file"
                              className="download-button"
                              style={
                                issue.actionPlan || actionPlanFiles[issue.id]
                                  ? {}
                                  : {backgroundColor: 'rgba(249, 249, 249, 1)'}
                              }
                              onClick={() => {
                                if (issue.actionPlan) {
                                  window.open(issue.actionPlan, '_blank');
                                } else if (actionPlanFiles[issue.id]) {
                                  const url = window.URL.createObjectURL(actionPlanFiles[issue.id].file);
                                  window.open(url, '_blank');
                                } else {
                                  this.actionPlanFileAction(issue.id);
                                }
                              }}
                            >
                              <Icon
                                className="download-icon"
                                icon={issue.actionPlan || actionPlanFiles[issue.id] ? faArrowToBottom : faPaperclip}
                              />
                            </button>
                          </td>
                          <td className="cell-5">
                            <div className="w-100 d-flex justify-content-start align-items-center pl-8">
                              {/* <input type="text" className="readOnly-input" style={{ margin: 0, textAlign: right }} value={issue.issueValue} disabled /> */}
                              {issue.issueValue && (
                                <React.Fragment>
                                  <span>{issue.issueValue}</span>
                                  <span className={`text-uppercase ${issue.issue.unit === '%' ? '' : 'ml-1'}`}>
                                    {issue.issue.unit}
                                  </span>
                                </React.Fragment>
                              )}
                            </div>
                          </td>
                          <td className="cell-6">
                            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                              <div className="w-100 d-flex">
                                <input
                                  type={issue.targetGoal ? 'text' : 'number'}
                                  className={`
                                    ${issue.targetGoal || issue.issue.issueType === 'Boolean' ? 'readOnly-input' : ''}
                                    flex-1
                                  `}
                                  defaultValue={
                                    issue.targetGoal ? this.determineTarget(issue.issue, issue.targetGoal) : ''
                                  }
                                  onBlur={e => {
                                    this.setConfirmValue(issue.id, 'targetGoal', e.target.value);
                                  }}
                                  disabled={issue.targetGoal || issue.issue.issueType === 'Boolean'}
                                />
                                {issue.issue.issueType === 'Numeric' && !issue.targetGoal && (
                                  <span className="ml-1 mb-0 pt-1">{this.determineUnit(issue.issue)}</span>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="cell-7">
                            <input
                              placeholder="mm/dd/yyyy"
                              type="date"
                              className={issue.targetDate ? 'readOnly-input' : ''}
                              defaultValue={issue.targetDate ? moment(issue.targetDate).utc().format('YYYY-MM-DD') : ''}
                              onBlur={e => {
                                if (e.target.value.length === 10) {
                                  this.setConfirmValue(issue.id, 'targetDate', e.target.value);
                                }
                              }}
                              disabled={issue.targetDate}
                            />
                          </td>
                          <td className="cell-8">
                            <input
                              placeholder={issue.step5CompletedAt ? 'mm/dd/yyyy' : ''}
                              type={issue.step5CompletedAt ? 'date' : 'text'}
                              className="readOnly-input"
                              value={
                                issue.step5CompletedAt ? moment(issue.step5CompletedAt).utc().format('YYYY-MM-DD') : ''
                              }
                              disabled
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="large-spacing" />
        {openModal && (
          <div className="confirmation-modal-background">
            <div className="confirmation-modal">
              <Icon className="confirmation-modal-icon" icon={faExclamationTriangle} />
              <div className="confirmation-modal-text">
                Please note that once you submit your data for this issue you will not be able to resubmit.
              </div>
              <div className="confirmation-modal-text">
                {dataCategory === 'actionPlan' || dataCategory === 'carbonNeutralPledgePlan'
                  ? dataValue.name
                  : dataValue}
              </div>
              <div className="confirmation-modal-button-container">
                <button className="confirmation-modal-button-cancel" onClick={() => this.setState({openModal: false})}>
                  CANCEL
                </button>
                <button className="confirmation-modal-button-continue" onClick={() => this.saveRoadmapData()}>
                  SAVE AND CONTINUE
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingRoadmap: state.companyRoadmap.isFetching,
    dataRoadmap: state.companyRoadmap.data,
    isFetchingWhitePapers: state.companyWhitePapers.isFetching,
    dataWhitePapers: state.companyWhitePapers.data,
    dataVideos: state.companyVideos.data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const CompanyRoadmap = compose(connect(mapStateToProps, null, mergeProps))(CompanyRoadmapPage);
