import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import {ROUTE} from 'app/constants';
import faExclamationTriangle from 'app/fontawesome-pro-light/faExclamationTriangle';

function NotFoundPage() {
  return (
    <div className="p-content-lg">
      <div className="card is-white">
        <h1>
          <Icon icon={faExclamationTriangle} /> Page Not Found
        </h1>
        <p>
          You can use the <Link to={ROUTE.SITE_SEARCH.path()}>site search</Link> to find what you are looking for, or
          you can return to your <Link to={ROUTE.DASHBOARD.path()}>dashboard</Link> to start a new task.
        </p>
      </div>
    </div>
  );
}

export const NotFound = NotFoundPage;
