import styled from 'styled-components/macro';
import {ProgressSquaresProps} from 'v2/utilities/types/components/ProgressSquares';
import {Theme} from '../atoms/theme';

const Square = styled.div<{size?: string | number; rounded?: boolean}>`
  height: ${props => props.size};
  width: ${props => props.size};
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: ${props => props.color};
  border-radius: ${({rounded, theme}) => (rounded ? theme.constants.info_bar_border_radius : '0')};
`;

const SquareContainer = styled.div`
  display: flex;
`;

export const ProgressSquares = ({displayCondensed, size, colors, selected, rounded}: ProgressSquaresProps) => {
  return (
    <SquareContainer>
      {displayCondensed && (
        <Square size={size} color={colors[selected ?? 0] ?? Theme.colors.grey_6} rounded={rounded} />
      )}

      {!displayCondensed &&
        Object.keys(colors).map((key: any, i) => {
          return (
            <Square key={i} size={size} color={i === selected ? colors[key] : Theme.colors.grey_6} rounded={rounded} />
          );
        })}
    </SquareContainer>
  );
};
