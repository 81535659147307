import {get} from 'lodash';

export const filter = ({filterItems, data}: filterProps) => {
  const targetArr = filterItems.map(({target}) => new Set(Array.isArray(target) ? target : [target]));
  if (filterItems.length === 0) {
    return data;
  }
  return data.filter(datum => {
    return filterItems.every(({key, operation}, index) => {
      return evaluateOperation({operation, targetSet: targetArr[index], value: get(datum, key)});
    });
  });
};

const evaluateOperation = ({operation, targetSet, value}: operationProps) => {
  switch (operation) {
    case 'eq':
      //@ts-ignore
      return targetSet.has(value);
    case 'gte':
      targetSet.forEach(target => {
        if (value >= target) {
          return true;
        }
      });
      return false;
    case 'gt':
      targetSet.forEach(target => {
        if (value > target) {
          return true;
        }
      });
      return false;
    case 'lte':
      targetSet.forEach(target => {
        if (value <= target) {
          return true;
        }
      });
      return false;
    case 'lt':
      targetSet.forEach(target => {
        if (value < target) {
          return true;
        }
      });
      return false;
    case 'contains':
      targetSet.forEach(target => {
        if (typeof target != 'string' || Array.isArray(value)) {
          throw new Error('The contains operator is only valid for strings.');
        }
        //@ts-ignore
        if (value.includes(target)) {
          return true;
        }
      });
      return false;
  }
};

type filterProps = {
  filterItems: filterItem[];
  data: any[];
};

type filterItem = {
  key: string;
  target: string | number | boolean | string[] | number[] | boolean[];
  operation: 'eq' | 'gte' | 'lte' | 'lt' | 'gt' | 'contains';
};

type operationProps = {
  value: string | number | boolean;
  targetSet: Set<string | number | boolean>;
  operation: 'eq' | 'gte' | 'lte' | 'lt' | 'gt' | 'contains';
};
