// A wrapper component that provides a a collapsible state.
// Bring your own styles and transitions via CSS.
// Note: props.children must consist of exactly one function.
//
// Usage:
//
// <Collapsible collapsedContainerHeight={144} isOpenByDefault={true}>
//   {({ container, content, isOpen, isUseless, toggle }) => (
//     <Fragment>
//       <div className="transition" {...container}>
//         <div {...content}>
//           Lorem ipsum...
//         </div>
//       </div>
//       {isUseless || <Button onClick={toggle}>{ isOpen ? 'Read Less' : 'Read More' }</Button>}
//     </Fragment>
//   )}
// </Collapsible>

import {Component} from 'react';
import PropTypes from 'prop-types';

class Collapsible extends Component {
  constructor(props) {
    super(props);

    this.contentRef = this.contentRef.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.toggle = this.toggle.bind(this);

    this.state = {
      contentHeight: 'auto',
      isOpen: props.isOpenByDefault,
    };
    this.mutationObserver = new MutationObserver(this.updateSize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
    this.mutationObserver.disconnect();
  }

  contentRef(element) {
    this.content = element;

    this.mutationObserver.disconnect();
    if (element) {
      this.mutationObserver.observe(element, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }

    this.updateSize();
  }

  updateSize() {
    this.content && this.setState({contentHeight: this.content.offsetHeight});
  }

  toggle() {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const {children, collapsedContainerHeight} = this.props;
    const {contentHeight, isOpen} = this.state;

    const tempHeight = isOpen ? contentHeight : Math.min(collapsedContainerHeight, contentHeight);
    const container = {
      style: {
        height: Number.isNaN(tempHeight) ? 0 : tempHeight,
        overflow: 'hidden',
      },
    };
    const content = {ref: this.contentRef};
    const isUseless = contentHeight <= collapsedContainerHeight;

    return children({container, content, isOpen, toggle: this.toggle, isUseless});
  }
}

Collapsible.propTypes = {
  children: PropTypes.func.isRequired,
  collapsedContainerHeight: PropTypes.number,
  isOpenByDefault: PropTypes.bool,
};

Collapsible.defaultProps = {collapsedContainerHeight: 0};

export {Collapsible};
