import React from 'react';
import {animateScroll} from 'react-scroll/modules';
import {push} from 'connected-react-router';
import {SubmissionError} from 'redux-form';
import {finalizeOnboarding} from 'app/redux/onboardingUser';
import {toastSuccess} from 'app/utilities/toast';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import styled from 'styled-components/macro';
import {Container, CardContainer, FlexContainer} from 'v2/components/atoms/Containers';
import {PopCheckCircle} from 'v2/components/atoms/icons';
import {Theme} from 'v2/components/atoms/theme';
import {Link1, ParagraphS, TitleM, TitleS} from 'v2/components/atoms/Typeface';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {ROUTE as V2_ROUTE} from 'v2/constants/routes';
import {OnboardingFooter} from 'v2/components/organisms/OnboardingFooter';
import {SpinnerDisplay} from 'app/components/Spinner/SpinnerDisplay';

const SubTitle = styled(TitleS).attrs(({theme}) => ({
  color: theme.colors.harmony,
}))`
  display: block;
  max-width: 23.375rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: none;
`;

const Link = styled(Link1).attrs({as: 'a'})`
  font-size: inherit;
  text-decoration: none;
  color: ${({theme}) => theme.colors.blue_1};
`;

export const UT3OnboardingCompletion = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {invitationCode, isLoading} = useOnboardingUser();

  const handleGoToProfile = () => {
    history.push(V2_ROUTE.UT3.ONBOARDING.PROFILE.path.replace(':invitationCode', invitationCode));
  };

  const handleSubmit = async () => {
    try {
      return await Promise.resolve(dispatch(finalizeOnboarding(invitationCode)))
        .then((response: any) => {
          if (response.hasError) {
            throw response.error;
          }

          toastSuccess({
            text:
              'Congratulations, you have completed your account setup. Any invites that you created have ' +
              'now been sent. You can send additional invites by going to Settings, then to Manage Users.',
            timeout: 15000,
          });

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.DASHBOARD.path()));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE});
        });
    } catch (error) {
      console.log({error});
    }
  };

  return (
    <FlexContainer flex={1} direction="column" gap="2rem" fullWidth>
      <CardContainer>
        <FlexContainer fullWidth>
          <FlexContainer flex={1} direction="column" fullWidth>
            <Container padding="0 0 2.75rem">
              <TitleM color={Theme.colors.dark}>Success</TitleM>
            </Container>
            <Container>
              <SubTitle>Your profile and firm details have been successfully saved.</SubTitle>
            </Container>
            <Container maxWidth="30.25rem" padding="1.1875rem 0 0">
              <ParagraphS>
                Thank you for joining the Harmony Platform Please{' '}
                <Link href="mailto:support@harmonyanalytics.org">contact our support team</Link> if you have any
                questions or concerns.
              </ParagraphS>
            </Container>
          </FlexContainer>
          <FlexContainer justify="flex-end">
            <PopCheckCircle size={70} />
          </FlexContainer>
        </FlexContainer>
      </CardContainer>
      <OnboardingFooter state="complete" onReturnToProfile={handleGoToProfile} onFinish={handleSubmit} />
      <SpinnerDisplay isActive={isLoading} />
    </FlexContainer>
  );
};
