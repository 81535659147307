import {
  addDays,
  addQuarters,
  addMonths,
  compareAsc,
  differenceInCalendarDays,
  isSameDay,
  lastDayOfMonth,
  lastDayOfQuarter,
  startOfDay,
  startOfMonth,
  startOfQuarter,
} from 'date-fns';
import moment from 'moment';

import {UPLOAD_FREQUENCY} from '../constants';

/**
 * Determines the frequency period as well as (if / when) a file upload (is / was) due
 * @param {DATE} lastUploaded - When the last upload occured
 * @param {String} frequency - The upload frequency constant (UPLOAD_FREQUENCY)
 * @returns {Object}
 */
export const checkDueDates = (lastUploaded, uploadFrequency) => {
  // TODO: Rewrite to make the logic in this function simpler
  // Create date function based on period
  const addPeriods = UPLOAD_FREQUENCY.QUARTERLY === uploadFrequency ? addQuarters : addMonths;
  const startOfPeriod = UPLOAD_FREQUENCY.QUARTERLY === uploadFrequency ? startOfQuarter : startOfMonth;
  const lastDayOfPeriod = UPLOAD_FREQUENCY.QUARTERLY === uploadFrequency ? lastDayOfQuarter : lastDayOfMonth;

  // Get current dates
  const currentDate = new Date();
  const startOfCurrentDate = startOfDay(currentDate);

  // Get period dates.
  // NOTE: If today is the due date, set the start of the current period to be the month/quarter from before
  // FOR EXAMPLE: If it is November 1st for a monthly frequency, then the start of the current period should be
  // October 1st.  Since date_fns.startOfMonth would return November 1st on November 1st, you need to subtract 1 day
  const startOfCurrentPeriod = startOfPeriod(addDays(startOfCurrentDate, -1));
  const endOfCurrentPeriod = lastDayOfPeriod(startOfCurrentPeriod);
  const startOfNextPeriod = addDays(endOfCurrentPeriod, 1);

  // Get next upload and due dates
  const nextUploadDate =
    differenceInCalendarDays(new Date(lastUploaded), startOfNextPeriod) >= 0
      ? addPeriods(startOfNextPeriod, 1)
      : startOfNextPeriod;
  const dueIn = differenceInCalendarDays(nextUploadDate, currentDate);

  const dateInfo = {
    lastUploadDisplay: moment(lastUploaded).format('MMM D, YYYY'),
    nextUploadDisplay: moment(nextUploadDate).format('MMM D, YYYY'),
    lastUploadDate: moment(lastUploaded),
    nextUploadDate,
    hasUpload: !!lastUploaded,
  };

  // no upload
  if (!lastUploaded) {
    return dateInfo;
  }

  // is file uploaded on the start of the period and the current date is the start of the period
  if (isSameDay(lastUploaded, startOfCurrentPeriod) && isSameDay(currentDate, startOfCurrentPeriod)) {
    dateInfo.uploadedOnStart = true;
  } else if (compareAsc(lastUploaded, startOfCurrentPeriod) < 0) {
    // last updated date is before start of the period
    dateInfo.pastDueBy = differenceInCalendarDays(currentDate, startOfCurrentPeriod);
  } else {
    // get time till start of next period
    dateInfo.dueIn = dueIn;
    dateInfo.dueInDisplay = dueIn === 0 ? 'Due Today' : `Due in ${Math.abs(dueIn)} day${dueIn !== 1 ? 's' : ''}`;
  }

  return dateInfo;
};
