/* eslint-disable camelcase */
import React, {Component} from 'react';
import {throttle} from 'lodash';
import transform from 'from-3d-to-2d';

const AMOUNT_X = 27;
const AMOUNT_Y = 27;
const SEPARATION = 100;
const DOT_OPACITY = 0.2;
const DOT_COLOR = `rgba(255, 255, 255, ${DOT_OPACITY})`;
const MAX_FPS = 30;

const getWindowDimensions = () => {
  const width = window.innerWidth * window.devicePixelRatio;
  const height = window.innerHeight * window.devicePixelRatio;
  const fillSize = Math.max(width, height);
  const xOffset = (width - fillSize) / 2;
  const yOffset = (height - fillSize) / 2;
  return {
    width,
    height,
    fillSize,
    xOffset,
    yOffset,
  };
};

class ParticleBackground extends Component {
  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);
    this.throttledResize = throttle(this.onResize, 1000 / MAX_FPS).bind(this);

    // Camera transform culled from THREEjs prototype
    // projectionMatrix * matrixWorldInverse
    this.projection = new Float32Array([
      0.5767691135406494, 0.11227966099977493, -0.6807989478111267, -0.6806628108024597, -0.22508063912391663,
      1.2262879610061646, -0.18438304960727692, -0.18434616923332214, -0.49517741799354553, -0.4266233742237091,
      -0.7091655135154724, -0.7090237140655518, 5.773978978317398e-14, 0, 1408.6719970703125, 1410.3900146484375,
    ]);

    this.point = new Float32Array(2);
    this.timer = 0;
    this.particles = [];

    // Create particle objects and arrange in grid
    for (let ix = 0; ix < AMOUNT_X; ix += 1) {
      for (let iy = 0; iy < AMOUNT_Y; iy += 1) {
        const particle = {
          x: ix * SEPARATION - (AMOUNT_X * SEPARATION) / 2,
          y: 0,
          z: iy * SEPARATION - (AMOUNT_Y * SEPARATION) / 2,
        };
        particle.dist = Math.sqrt((particle.x - 1000) ** 2 + (particle.z - 960) ** 2);
        this.particles.push(particle);
      }
    }

    this.state = getWindowDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResize);
    this.willUnmount = true;
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = DOT_COLOR;
    this.gameLoop();
  }

  gameLoop() {
    if (this.willUnmount) return;

    this.draw();
    requestAnimationFrame(() => {
      setTimeout(this.gameLoop.bind(this), 1000 / MAX_FPS); // throttle fps
    });
  }

  draw() {
    this.timer += 0.04;
    this.ctx.clearRect(0, 0, this.state.width, this.state.height);

    let particle;
    let circleSize;
    let x;
    let y;
    let i = 0;
    for (let ix = 0; ix < AMOUNT_X; ix += 1) {
      for (let iy = 0; iy < AMOUNT_Y; iy += 1) {
        particle = this.particles[i];

        // Cull into camera view
        if (particle.x < 1000 || particle.z < 960) {
          // Wave algorithm
          const sinX = Math.sin((ix + this.timer) * 0.3);
          const sinY = Math.sin((iy + this.timer) * 0.5);
          // Undulate to waveform
          particle.y = sinX * 65 + sinY * 65; // increase wave height by 30%
          particle.scale = (sinX + 1) * 4 + (sinY + 1) * 2;

          // Project values for 2d drawing
          transform(this.point, [particle.x, particle.y, particle.z], this.projection);
          circleSize = ((particle.scale / particle.dist) * 400 * this.state.fillSize) / 1400;
          x = this.point[0] * this.state.fillSize + this.state.xOffset;
          // flatten and downshift wave render
          y = ((this.point[1] + 0.5) / 2) * this.state.fillSize + this.state.yOffset - 100;

          // Draw dot
          this.ctx.beginPath();
          this.ctx.arc(x, y, circleSize, 0, 2 * Math.PI);
          this.ctx.fill();
        }
        i += 1;
      }
    }
  }

  onResize() {
    this.setState(getWindowDimensions());
    this.ctx.fillStyle = DOT_COLOR;
  }

  render() {
    return (
      <canvas
        width={this.state.width}
        height={this.state.height}
        ref={element => {
          this.canvas = element;
        }}
      />
    );
  }
}

export {ParticleBackground};
