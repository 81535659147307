import {find, isEmpty, padStart} from 'lodash';

/**
 * Recursively gets an Issue's display order based on its ancestry to be used when flattening all issues for display
 * @param {number} issueId - The issue Id to get a display order for
 * @param {Array} allIssues - list of all issues to look up the issue and its family tree
 * @returns {string} The display order for an issue with a similar format to <parentDisplayOrder>_<issueDisplayOrder)
 * using leading zeros to ensure that the sort upon a string properly works (e.g. 1 vs 11 as opposed to 01 vs 11)
 */
export const getRecursiveIssueDisplayOrder = (issueId, allIssues) => {
  // Find the issue
  const issue = find(allIssues, {id: issueId});

  // Set the display order using leading zeros so string sorting will work properly
  let displayOrder = `${padStart(issue.displayOrder, 3, '0')}`;

  // Determine if there is a parent issue and if so, recursively append it to the current display order
  const parentIssue = find(allIssues, {id: issue.parentId});
  if (!isEmpty(parentIssue)) {
    const parentIssueDisplayOrder = getRecursiveIssueDisplayOrder(parentIssue.id, allIssues);
    displayOrder = `${padStart(parentIssueDisplayOrder, 3, '0')}_${displayOrder}`;
  }

  return displayOrder;
};
