import React from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

import faPrint from 'app/fontawesome-pro-light/faPrint';

import {
  Button,
  ProgressReportDataContainer,
  ProgressReportFormContainer,
  ProgressReportPrintDisplay,
} from 'app/components';

const ProgressReportPrintable = () => (
  <React.Fragment>
    <div className="print-simulation-hint">
      <div className="flex-grow-1 px-4">
        <b>Preview your report below before printing.</b>
      </div>
      <Button solid onClick={window.print}>
        <Icon icon={faPrint} className="mr-2" />
        Print
      </Button>
    </div>
    <ProgressReportDataContainer>
      {dataProps => (
        <ProgressReportFormContainer {...dataProps}>
          {formProps => <ProgressReportPrintDisplay {...dataProps} {...formProps} />}
        </ProgressReportFormContainer>
      )}
    </ProgressReportDataContainer>
  </React.Fragment>
);

export {ProgressReportPrintable};
