import React, {Component} from 'react';
import {LegendItem} from './LegendItem';
import {Bar} from 'react-chartjs-2';
import {zeroCompensation} from '../../utilities/zeroCompensation';

const MODE = {
  BY_WEIGHT: 'By Weight',
  BY_COUNT: 'By Count',
};

class HoldingsPerformanceBarChart extends Component {
  render() {
    const {mode, holdingsData, universeData, legendTitle1, legendTitle2} = this.props;
    const maxValue = Math.max(...[...holdingsData, ...universeData]);
    const maxTick = maxValue + 10 - (maxValue % 10);

    // const chartWidth = 475;
    const chartWidth = 950;
    // const chartHeight = 125;
    const chartHeight = 250;

    const chartData = {
      labels: ['A', 'B', 'C', 'D', 'E'],
      datasets: [
        {
          label: 'Holdings',
          fill: false,
          borderColor: '#34B991',
          backgroundColor: '#34B991',
          data: holdingsData,
        },
        {
          label: 'Harmony Universe',
          fill: false,
          borderColor: '#034682',
          backgroundColor: '#034682',
          data: universeData,
        },
      ],
    };

    const chartOptions = {
      barPercentage: 0.7,
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
      animation: false,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          min: 0,
          max: 5,
          ticks: {
            font: {
              size: 18,
            },
            stepSize: 1,
            callback(value, index) {
              return chartData.labels[index];
            },
          },
        },
        y: {
          min: 0,
          max: maxTick,
          ticks: {
            font: {
              size: 18,
            },
            stepSize: 10,
            padding: 20,
            callback(value) {
              if (value !== 0) {
                return `${Math.round(value)}%`;
              }
            },
          },
          grid: {
            display: true,
            drawBorder: false,
            color: '#606060',
            lineWidth: 0.5,
            tickWidth: 0.5,
          },
        },
      },
    };

    return (
      <div className={'holdings-performance'}>
        <div className={'w-100 d-flex justify-content-between'}>
          <h3 className={'text-uppercase'}>{mode}</h3>
          <div>
            <LegendItem title={legendTitle1} color={'#34B991'} />
            <span className={'d-inline-block'}>vs.</span>
            <LegendItem title={legendTitle2} color={'#034682'} />
          </div>
        </div>
        <div className="canvas-container">
          <Bar
            plugins={[zeroCompensation]}
            data={chartData}
            options={chartOptions}
            width={chartWidth}
            height={chartHeight}
          />
        </div>
      </div>
    );
  }
}

HoldingsPerformanceBarChart.MODE = MODE;

HoldingsPerformanceBarChart.defaultProps = {
  mode: MODE.BY_WEIGHT,
  legendTitle1: 'Holdings',
  legendTitle2: 'Harmony Universe',
};

export {HoldingsPerformanceBarChart};
