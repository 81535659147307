import {NavLink, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Tabs, Path, IconTabs} from '../interfaces/LayoutProps';
import {Title5, Link2} from '../atoms/Typeface';
import {ManagersTab} from './ManagersTab';
import {BarChart} from '../atoms/icons/BarChart';

const TabWrapper = styled.div`
  flex-direction: row;
  height: 4.625rem;
  width: auto;
  background: ${props => props.theme.colors.white};
`;

const TabContainer = styled.div`
  display: flex;
  height: inherit;
  max-width: ${props => props.theme.constants.fixWidth};
  margin: 0 auto;
`;

const Tab = styled(Title5)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 17.625rem;
    text-decoration: none;
    text-transform: uppercase;
    color: ${props => props.theme.colors.dark};
    &:onhover {
      text-decoration: none;
    }
  }
`;

const SelectedTab = styled(Tab)`
  &&& {
    height: calc(100%);
    padding-top: 0.25rem;
    color: ${props => props.theme.colors.green};
    border-bottom: 0.25rem solid ${props => props.theme.colors.green};
    &:onhover {
      text-decoration: none;
      color: ${props => props.theme.colors.green};
    }
  }
`;

// MANAGER STYLES

const ManagerTabWrapper = styled.div`
  flex-direction: row;
  height: 44px;
  width: auto;
  background: ${props => props.theme.colors.grey_2};
`;

const ManagerTabContainer = styled.div`
  display: flex;
  height: inherit;
  max-width: ${props => props.theme.constants.fixWidth};
  margin: 0 auto;
`;

const ManagerTab = styled(Link2)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 17.625rem;
    text-decoration: none;
    text-transform: uppercase;
    color: ${props => props.theme.colors.dark};
    &:onhover {
      text-decoration: none;
    }
  }
`;

const ManagerSelectedTab = styled(ManagerTab)`
  &&& {
    height: calc(100%);
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.harmony};
    &:onhover {
      text-decoration: none;
      color: ${props => props.theme.colors.green};
    }
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const TabBar: React.FC<IconTabs> = ({tabs}) => {
  const location = useLocation();
  return (
    <Container>
      {tabs &&
        tabs.map((tab, i) => (
          <ManagersTab
            key={i}
            to={tab.path}
            Icon={tab.icon}
            label={tab.label}
            isActive={tab.path === location.pathname}
          />
        ))}
    </Container>
  );
};

export const ParamTabBar: React.FC<IconTabs> = ({tabs}) => {
  const location = useLocation();
  return (
    <Container>
      {tabs &&
        tabs.map((tab, i) => (
          <ManagersTab
            key={i}
            to={tab.path}
            Icon={tab.icon}
            label={tab.label}
            isActive={tab.path === location.pathname + location.search}
          />
        ))}
    </Container>
  );
};
