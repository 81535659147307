import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faSeedling from 'app/fontawesome-pro-light/faSeedling';
import faHandHeart from 'app/fontawesome-pro-light/faHandHeart';
import faLandmark from 'app/fontawesome-pro-light/faLandmark';
import faUsdCircle from 'app/fontawesome-pro-light/faUsdCircle';
import ReactCardFlip from 'react-card-flip';
import {DATA_CATEGORY} from '../constants';
import {IssueDataCardFront} from './IssueDataCardFront';
import {IssueDataCardBack} from './IssueDataCardBack';

class IssueDataCard extends Component {
  constructor() {
    super();

    this.state = {
      isFlipped: false,
    };

    this.flip = this.flip.bind(this);
  }

  getIssueColor() {
    switch (this.props.category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return '#34B991';
      case DATA_CATEGORY.SOCIAL:
        return '#009BCC';
      case DATA_CATEGORY.GOVERNANCE:
        return '#003462';
      case DATA_CATEGORY.FINANCIAL:
        return '#6D6D6D';
      default:
        return '';
    }
  }

  renderIcon() {
    switch (this.props.category) {
      case DATA_CATEGORY.ENVIRONMENT:
        return <Icon icon={faSeedling} className="header-icon" />;
      case DATA_CATEGORY.SOCIAL:
        return <Icon icon={faHandHeart} className="header-icon" />;
      case DATA_CATEGORY.GOVERNANCE:
        return <Icon icon={faLandmark} className="header-icon" />;
      case DATA_CATEGORY.FINANCIAL:
        return <Icon icon={faUsdCircle} className="header-icon" />;
      default:
        return <div />;
    }
  }

  flip(e) {
    e.preventDefault();
    this.setState(prevState => ({isFlipped: !prevState.isFlipped}));
  }

  render() {
    const {category = '', data = []} = this.props;
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      // eslint-disable-next-line func-names
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
        // eslint-disable-next-line no-undef
      })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection="horizontal"
        flipSpeedFrontToBack={isSafari ? 0.3 : 1}
        flipSpeedBackToFront={isSafari ? 0.3 : 1}
        containerStyle={{height: '100%', width: '100%', zIndex: 10000}}
      >
        <IssueDataCardFront key="front" category={category} data={data} flip={this.flip} />
        <IssueDataCardBack key="back" category={category} data={data} flip={this.flip} visible={this.state.isFlipped} />
      </ReactCardFlip>
    );
  }
}

IssueDataCard.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export {IssueDataCard};
