import React, {Component} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import faCircleNotch from 'app/fontawesome-pro-light/faCircleNotch';
import {generatePdfFromPage} from 'app/utilities/generatePdfFromPage';
import {getAllPagedSecurities} from 'app/redux/security';
import {getAll as getDashboardData} from 'app/redux/dashboard';
import {getAllManagersPaged} from 'app/redux/firm';
import {getDistributions, getIssueMatrix} from 'app/redux/planDashboard';
import {getEngagementsForCurrentUser, getEsgMeasuresForCurrentUser} from 'app/redux/planSponsor';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {get, keyBy, map, orderBy, chunk} from 'lodash';
import {toastError} from 'app/utilities/toast';
import moment from 'moment';
import {
  CoverPage,
  ESGRatingsPage,
  ESGRatingsPage2,
  TableOfContentsPage,
  SummaryPage,
  HoldingsPerformancePage,
  // eslint-disable-next-line no-unused-vars
  CompanyEngagementPage,
  ManagersPage,
  ESGMeasuresPage,
  AboutPage,
  BackCoverPage,
} from '../pdf-pages/PlanSponsorReport';
import {ESGMeasuresBarGraphPage} from '../pdf-pages/ProductReport';
import {PARENT_ISSUE} from '../constants';

class PlanSponsorReportDownloadButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      generatingReport: false,
      topHoldings: [],
      riskHoldings: [],
      managers: [],
      holdManagerPages: 10,
    };

    props.dispatch(getEsgMeasuresForCurrentUser());
    props.dispatch(getEngagementsForCurrentUser());
    props.dispatch(getDashboardData());
    props.dispatch(getDistributions());
    props.dispatch(getIssueMatrix());
    props.dispatch(getAllManagersPaged({page: 1, pageSize: 20}));
    props
      .dispatch(
        getAllPagedSecurities({
          page: 1,
          pageSize: 5,
          sortField: 'heldAmount',
          sortOrder: 'desc',
          search: '',
          filter: 'held',
        }),
      )
      .then(data => {
        this.setState({topHoldings: get(data, 'payload.items', [])});
      });
    props
      .dispatch(
        getAllPagedSecurities({
          page: 1,
          pageSize: 5,
          sortField: 'companies.overallTransformedValue',
          sortOrder: 'desc',
          search: '',
          filter: 'held',
        }),
      )
      .then(data => {
        this.setState({riskHoldings: get(data, 'payload.items', [])});
      });
  }

  generateReport = async () => {
    await this.setState({generatingReport: true});

    const {
      dashboard,
      planDashboard,
      issues,
      // eslint-disable-next-line no-unused-vars
      planSponsorEngagements,
      planSponsorEsgMeasures,
      managers,
      roles,
    } = this.props;
    const {topHoldings, riskHoldings} = this.state;

    const {
      details: {name},
      distributions,
    } = planDashboard;

    const {companiesCount: engagedCompaniesCount} = dashboard.data.companyEngagementData;

    const esgManagersPct = (get(dashboard, 'data.esgManagersRatio') * 100).toFixed(0);
    const engagedManagersPct = (get(dashboard, 'data.engagedManagersRatio') * 100).toFixed(0);
    const peerRankings = keyBy(planDashboard.details.peerRankings, 'issueId');
    const peerRankingsInOrgType = keyBy(planDashboard.details.peerRankingsInOrgType, 'issueId');
    const compliantManagersPct = (dashboard.data.compliantManagersRatio * 100).toFixed(0);
    const holdingsPerformanceByWeight = map(
      orderBy(distributions.planDistributions, ['rating'], ['asc']),
      distribution => {
        return Math.round(distribution.percent, 0);
      },
    );
    const universePerformanceByWeight = map(
      orderBy(distributions.indexUniverseDistributions, ['rating'], ['asc']),
      distribution => {
        return Math.round(distribution.percent, 0);
      },
    );
    const holdingsPerformanceByCount = map(
      orderBy(distributions.planDistributionsByCount, ['rating'], ['asc']),
      distribution => {
        return Math.round(distribution.percent, 0);
      },
    );
    const universePerformanceByCount = map(
      orderBy(distributions.indexUniverseDistributionsByCount, ['rating'], ['asc']),
      distribution => {
        return Math.round(distribution.percent, 0);
      },
    );

    const managerPage = () => {
      let firstListOfManagers;
      let secondListOfManagers;
      if (managers.length > 15) {
        firstListOfManagers = managers.slice(0, 15);
        secondListOfManagers = managers.slice(15);
        const groupedList = chunk(secondListOfManagers, 20);
        this.setState({holdManagerPages: this.state.holdManagerPages + groupedList.length});
        const otherPages = groupedList.map((list, index) => {
          return (
            <ManagersPage
              key={index}
              companyName={name}
              managersData={list}
              esgManagersPct={esgManagersPct}
              compliantManagersPct={compliantManagersPct}
              engagedManagersPct={engagedManagersPct}
              page={11 + index}
              removeGraphs={false}
              amount={index + 2}
              amountOfPages={groupedList.length + 1}
            />
          );
        });
        return [
          <ManagersPage
            companyName={name}
            managersData={firstListOfManagers}
            esgManagersPct={esgManagersPct}
            compliantManagersPct={compliantManagersPct}
            engagedManagersPct={engagedManagersPct}
            page={10}
            removeGraphs={true}
            amount={1}
            amountOfPages={groupedList.length + 1}
          />,
          ...otherPages,
        ];
      }
      return [
        <ManagersPage
          companyName={name}
          managersData={managers}
          esgManagersPct={esgManagersPct}
          compliantManagersPct={compliantManagersPct}
          engagedManagersPct={engagedManagersPct}
          page={10}
          removeGraphs={true}
        />,
      ];
    };

    const date = moment().subtract(3, 'months');
    const year = date.year();
    const quarter = Math.floor(date.month() / 3) + 1;

    try {
      const pdfPages = [
        <CoverPage companyName={name} />,
        <TableOfContentsPage companyName={name} />,
        <SummaryPage
          companyName={name}
          overallUniverseRankingTier={get(
            keyBy(get(planDashboard, 'details.planSponsorRankingOverrides'), 'issueId'),
            'null.value',
            peerRankings.null.tier,
          )}
          overallOrgTypeRankingTier={get(
            keyBy(get(planDashboard, 'details.planSponsorRankingOverrides'), 'issueId'),
            'null.value',
            peerRankingsInOrgType.null.tier,
          )}
          compliantMangersPercent={`${compliantManagersPct}%`}
          engagedCompaniesCount={engagedCompaniesCount}
          holdingsDataByWeight={holdingsPerformanceByWeight}
          universeDataByWeight={universePerformanceByWeight}
          holdingsDataByCount={holdingsPerformanceByCount}
          universeDataByCount={universePerformanceByCount}
        />,
        <ESGRatingsPage companyName={name} planDashboard={planDashboard} issues={issues} />,
        <ESGRatingsPage2 companyName={name} issues={issues} />,
        <ESGMeasuresPage
          companyName={name}
          subjectHeader={'Portfolio'}
          targetHeader={'Universe'}
          comparisonHeader={'Universe'}
          metrics={{
            [PARENT_ISSUE.ENVIRONMENTAL.name]: [1, 4, 6],
            [PARENT_ISSUE.SOCIAL.name]: [11, 10, 8, 9],
            [PARENT_ISSUE.GOVERNANCE.name]: [12],
          }}
          subjectData={planSponsorEsgMeasures.planSponsor}
          targetData={planSponsorEsgMeasures.universe}
        />,
        <ESGMeasuresBarGraphPage
          companyName={name}
          title={PARENT_ISSUE.ENVIRONMENTAL.name}
          subjectHeader={'Portfolio'}
          targetHeader={'Index'}
          subjectData={planSponsorEsgMeasures.planSponsor}
          targetData={planSponsorEsgMeasures.universe}
          chartMetrics={[1, 4, 6]}
          page={7}
        />,
        <ESGMeasuresBarGraphPage
          companyName={name}
          title={PARENT_ISSUE.SOCIAL.name}
          subjectHeader={'Portfolio'}
          targetHeader={'Index'}
          subjectData={planSponsorEsgMeasures.planSponsor}
          targetData={planSponsorEsgMeasures.universe}
          chartMetrics={[8, 9, 10, 11]}
          page={8}
        />,
        <ESGMeasuresBarGraphPage
          companyName={name}
          title={PARENT_ISSUE.GOVERNANCE.name}
          subjectHeader={'Portfolio'}
          targetHeader={'Index'}
          subjectData={planSponsorEsgMeasures.planSponsor}
          targetData={planSponsorEsgMeasures.universe}
          chartMetrics={[12]}
          page={9}
        />,
        ...managerPage(),
        <HoldingsPerformancePage
          companyName={name}
          topHoldings={topHoldings}
          riskHoldings={riskHoldings}
          page={this.state.holdManagerPages + 1}
        />,
        // <CompanyEngagementPage companyName={name} data={planSponsorEngagements} />,
        <AboutPage page={this.state.holdManagerPages + 2} />,
        <BackCoverPage />,
      ];

      await generatePdfFromPage(pdfPages, `harmony_analytics_report-q${quarter}_${year}.pdf`);
    } catch (error) {
      toastError('Whoops... There was a problem generating your report. Please try again later, or contact support.');
      throw error;
    } finally {
      await this.setState({generatingReport: false});
    }
  };

  render() {
    return (
      // <button
      //   type="button"
      //   className={'btn btn-outline'}
      //   style={{ height: '2.5rem' }}
      //   onClick={this.generateReport}
      //   disabled={this.state.generatingReport}
      // >
      //   GENERATE REPORT
      //   {this.state.generatingReport ? <Icon icon={faCircleNotch} className="fa-spin ml-2" /> : null}
      // </button>
      <></>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    planDashboard: state.planDashboard,
    topHoldings: state.topHoldings,
    issues: state.planDashboard.issues,
    riskHoldings: state.riskHoldings,
    planSponsorEngagements: state.planSponsor.engagements,
    planSponsorEsgMeasures: state.planSponsor.esgMeasures,
    managers: state.firm.allFirmsWithStrategy,
    portfolioData: !state.strategyDashboard && state.strategyDashboard.holdingMetrics.strategy,
    universeData: !state.strategyDashboard && state.strategyDashboard.holdingMetrics.universeData,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {...stateProps, ...dispatchProps, ...ownProps};
};

export const PlanSponsorReportDownloadButton = compose(connect(mapStateToProps, null, mergeProps))(
  PlanSponsorReportDownloadButtonComponent,
);
