import {createAction} from 'redux-actions';
import {push} from 'connected-react-router';

// Local Imports
import {ROUTE} from 'app/constants';
import faDoorClosed from 'app/fontawesome-pro-light/faDoorClosed';
import {assignWithState} from 'app/redux/helpers';
import {fetchAuthJSON, fetchJSON} from 'app/services/http';
import {toastSuccess} from 'app/utilities/toast';
import {ACTION as ONBOARDING_USER_ACTIONS} from './onboardingUser'; // eslint-disable-line import/no-cycle
import {
  isDemoAccount,
  setAuthTokens,
  setImpersonationTokens,
  removeAuthTokens,
  removeImpersonationTokens,
  isImpersonating,
} from 'app/utilities';
import {resetAllOptions} from 'v2/redux/appliedFilters';

export const AUTH_STATUS = {
  INITIAL: 'INITIAL',
  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_RECEIVED: 'LOGIN_RECEIVED',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGOUT_COMPLETE: 'LOGOUT_COMPLETE',
};

const initialState = {
  status: AUTH_STATUS.INITIAL,
  hasError: false,
  isFetching: false,
  impersonatedUser: null,
  isImpersonation: false,
};

// ACTION
export const ACTION = {
  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_RECEIVE: 'AUTH_LOGIN_RECEIVE',
  LOGIN_COMPLETE: 'AUTH_LOGIN_COMPLETE',
  LOGOUT: 'AUTH_LOGOUT',
  LOGOUT_ACKNOWLEDGE: 'AUTH_LOGOUT_ACKNOWLEDGE',
  GET_CURRENT_REQUEST: 'USER_GET_CURRENT_REQUEST',
  GET_CURRENT_RECEIVE: 'USER_GET_CURRENT_RECEIVE',
  IMPERSONATION_REQUEST: 'AUTH_IMPERSONATION_LOGIN_REQUEST',
  IMPERSONATION_RECEIVE: 'AUTH_IMPERSONATION_LOGIN_RECEIVE',
  IMPERSONATION_COMPLETE: 'AUTH_IMPERSONATION_LOGIN_COMPLETE',
  IMPERSONATION_LOGOUT: 'AUTH_IMPERSONATION_LOGOUT',
};

const authLoginRequest = createAction(ACTION.LOGIN_REQUEST);
export const authLoginReceive = createAction(ACTION.LOGIN_RECEIVE);
export const authLoginComplete = createAction(ACTION.LOGIN_COMPLETE);
const authLogout = createAction(ACTION.LOGOUT);
export const authLogoutAcknowledge = createAction(ACTION.LOGOUT_ACKNOWLEDGE);
const authGetCurrentRequest = createAction(ACTION.GET_CURRENT_REQUEST);
const authGetCurrentReceive = createAction(ACTION.GET_CURRENT_RECEIVE);
const impersonationRequest = createAction(ACTION.IMPERSONATION_REQUEST);
const impersonationReceive = createAction(ACTION.IMPERSONATION_RECEIVE);
export const impersonationComplete = createAction(ACTION.IMPERSONATION_COMPLETE);
const impersonationLogout = createAction(ACTION.IMPERSONATION_LOGOUT);

const api = {
  login: (email, password, rememberMe) =>
    fetchJSON('auth/login', {
      method: 'post',
      body: JSON.stringify({email, password, rememberMe}),
    }),
  getCurrent: () => fetchAuthJSON('auth/current', {method: 'get'}),
  impersonateUser: userId => {
    const url = `user/impersonateUserAdmin/${userId}`;
    return fetchAuthJSON(url, {method: 'get'});
  },
};

export function login(username, password, rememberMe) {
  return dispatch => {
    dispatch(authLoginRequest({username, rememberMe}));
    return dispatch(authLoginReceive(api.login(username, password, rememberMe))).then(response => {
      if (response.hasError) {
        throw response.error;
      }

      setAuthTokens(response.payload.access_token, response.payload.refresh_token);

      // Redirect demo account to Progress Report when logging in
      if (isDemoAccount(username)) {
        dispatch(push(ROUTE.PROGRESS_REPORT.path()));
      }

      return response.payload;
    });
  };
}

export function logout() {
  return dispatch => {
    removeAuthTokens();
    removeImpersonationTokens();
    toastSuccess({text: 'You have been logged out.', faIcon: faDoorClosed});
    dispatch(push(ROUTE.LOGIN.path()));
    return dispatch(authLogout());
  };
}

export function getCurrent() {
  return dispatch => {
    dispatch(authGetCurrentRequest());
    return dispatch(authGetCurrentReceive(api.getCurrent()));
  };
}

export function impersonateUser(userId) {
  return dispatch => {
    dispatch(impersonationRequest);
    return dispatch(impersonationReceive(api.impersonateUser(userId))).then(response => {
      if (response.hasError) {
        throw response.error;
      }

      setImpersonationTokens(response.payload.access_token, response.payload.refresh_token);

      if (isDemoAccount(response.payload.email)) {
        dispatch(push(ROUTE.PROGRESS_REPORT.path()));
      } else {
        dispatch(push(ROUTE.DASHBOARD.path()));
      }

      return response.payload;
    });
  };
}

export function removeImpersonationTokensUser() {
  return dispatch => {
    removeImpersonationTokens();
    toastSuccess({text: 'You have stopped impersonating this account.', faIcon: faDoorClosed});
    dispatch(resetAllOptions());
    dispatch(push(ROUTE.ADMIN_USERS.path()));
    return dispatch(impersonationLogout());
  };
}

// REDUCER
export const auth = (state = initialState, {error: hasError, payload, type}) => {
  const assignMergedState = assignWithState(state, hasError);

  switch (type) {
    case ACTION.GET_CURRENT_REQUEST:
      return assignMergedState({isFetching: true});
    case ACTION.LOGIN_RECEIVE:
      return assignMergedState({...payload, status: AUTH_STATUS.LOGIN_RECEIVED});
    case ACTION.LOGIN_COMPLETE:
      return assignMergedState({status: AUTH_STATUS.LOGIN_COMPLETE});
    case ACTION.LOGOUT:
      return assignWithState(initialState)({status: AUTH_STATUS.LOGOUT_COMPLETE});
    case ACTION.LOGOUT_ACKNOWLEDGE:
      return assignMergedState({status: AUTH_STATUS.INITIAL});
    case ACTION.GET_CURRENT_RECEIVE:
      return assignMergedState(Object.assign({isFetching: false}, hasError ? {error: payload} : {...payload}));
    case ONBOARDING_USER_ACTIONS.UPDATE_BY_INVITATION_CODE_RECEIVE: {
      if (!hasError && payload.access_token) {
        if (isImpersonating()) {
          return assignMergedState({impersonatedUser: {...payload.user}});
        }
        return assignMergedState({...payload.user});
      }
      return state;
    }
    case ONBOARDING_USER_ACTIONS.FINALIZE_ONBOARDING_RECEIVE: {
      if (!hasError) {
        if (isImpersonating()) {
          return assignMergedState({impersonatedUser: {...payload.user}});
        }
        return assignMergedState({...payload.user});
      }
      return state;
    }
    case ACTION.IMPERSONATION_REQUEST:
      return assignMergedState({isFetching: true, status: AUTH_STATUS.LOGIN_REQUESTED});
    case ACTION.IMPERSONATION_RECEIVE:
      return assignMergedState({impersonatedUser: payload, status: AUTH_STATUS.LOGIN_RECEIVED});
    case ACTION.IMPERSONATION_COMPLETE:
      return assignMergedState({status: AUTH_STATUS.LOGIN_COMPLETE});
    case ACTION.IMPERSONATION_LOGOUT:
      return assignMergedState({impersonatedUser: null});
    default:
      return state;
  }
};
