import styled from 'styled-components/macro';
import {Overline} from '../atoms/Typeface';
import {HarmonyLogo, User, ArrowDown} from '../atoms/icons';
import {Theme} from '../atoms/theme';
import {Menu} from '../molecules/Menu';
import {NavLink, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Bar = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  background: ${props => props.theme.colors.harmony};
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

const InnerContent = styled.div`
  max-width: ${props => props.theme.constants.fixWidth};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NavigationItemPadding = styled.div`
  margin-bottom: 4px;
  flex-shrink: 0;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10rem;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  padding-top: 14px;
  padding-bottom: 12px;
  margin-left: 12px;
  margin-right: 43px;
`;

const SettingsText = styled(Overline)`
  &&& {
    color: ${props => props.theme.colors.white};
    margin-right: 17px;
    margin-top: 26px;
    margin-bottom: 16px;
  }
`;

const SettingsLink = styled.a`
  text-decoration: none;
`;

const Nav = styled(NavLink)`
  &&& {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-decoration: none;
    margin-left: 1rem;
    margin-right: 1rem;
    white-space: no-wrap;
    color: ${props => props.theme.colors.white};
    &.onHover {
      text-decoration: none;
    }
  }
`;

const SelectedNav = styled(Nav)`
  &&& {
    color: ${props => props.theme.colors.green};
  }
`;

const PaddedArrow = styled(ArrowDown)`
  margin-bottom: 30px;
  padding-left: 3.5px;
`;

const MenuContainer = styled.div`
  padding-bottom: 22.5px;
  padding-top: 23.5px;
  display: flex;
  align-items: flex-end;
`;

const ImpersonationEmail = styled(Overline)`
  &&& {
    color: ${props => props.theme.colors.white};
    align-self: center;
    width: 0;
    white-space: nowrap;
  }
`;

interface NavigationBarProps {
  navItems: {label: string; path: string}[];
  menuOptions: {label: string; path: string; onClick?: Function}[];
  settingsPath: string;
}

export const NavigationBar = ({navItems, menuOptions, settingsPath}: NavigationBarProps) => {
  const impersonationEmail = useSelector((state: any) => {
    //@ts-ignore
    return state?.auth?.impersonatedUser?.email;
  });
  const MenuIcon = (
    <MenuContainer>
      <User size={14} color={Theme.colors.white} />
      <PaddedArrow size={10} color={Theme.colors.white} />
    </MenuContainer>
  );

  const location = useLocation();
  const comparePaths = (currentPath: string, navItem: any) => {
    if (currentPath === navItem.path) {
      return true;
    }
    return currentPath.startsWith(navItem.basePath);
  };

  return (
    <Bar>
      <InnerContent>
        <LeftContent>
          <LogoContainer>
            <HarmonyLogo color={Theme.colors.white} size={34} />
          </LogoContainer>
          {navItems.map((navItem, i) => {
            return (
              <NavigationItemPadding key={i}>
                {
                  //@ts-ignore
                  comparePaths(location.pathname, navItem) ? (
                    <SelectedNav to={navItem.path}>{navItem.label}</SelectedNav>
                  ) : (
                    <Nav to={navItem.path}>{navItem.label}</Nav>
                  )
                }
              </NavigationItemPadding>
            );
          })}
        </LeftContent>
        <RightContent>
          <SettingsLink to={settingsPath} as={NavLink}>
            <SettingsText color={Theme.colors.white}>SETTINGS</SettingsText>
          </SettingsLink>
          <Menu buttonLabel={MenuIcon} options={menuOptions} alignment="left" />
        </RightContent>
      </InnerContent>
      {impersonationEmail && <ImpersonationEmail>{impersonationEmail}</ImpersonationEmail>}
    </Bar>
  );
};
