import {NavigationPath} from 'v2/utilities/types/components/NavigationHeader';
import {ROUTE} from './routes';
import {ROUTE as OLD_ROUTES} from 'app/constants/route';

export const UT1_ROUTES: NavigationPath[] = [
  {
    path: ROUTE.UT1.DASHBOARD.path,
    label: ROUTE.UT1.DASHBOARD.title,
  },
  {
    path: ROUTE.UT1.DATA_EXPLORER.path,
    label: ROUTE.UT1.DATA_EXPLORER.title,
  },
  {
    path: ROUTE.UT1.STRATEGIES.INDEX.path,
    label: ROUTE.UT1.STRATEGIES.INDEX.title,
  },
  {
    path: ROUTE.UT1.COMPANIES.INDEX.path,
    label: ROUTE.UT1.COMPANIES.INDEX.title,
  },
  {
    path: ROUTE.RESOURCE_CENTER.path,
    label: ROUTE.RESOURCE_CENTER.title,
  },
];

export const UT3_ROUTES: NavigationPath[] = [
  {
    path: ROUTE.UT3.DASHBOARD.basePath,
    label: ROUTE.UT3.DASHBOARD.title,
  },
  {
    path: ROUTE.UT3.CLIENTS.basePath,
    label: ROUTE.UT3.CLIENTS.title,
  },
  {
    path: ROUTE.UT3.COMPANIES.basePath,
    label: ROUTE.UT3.COMPANIES.title,
  },
  {
    path: ROUTE.RESOURCE_CENTER.path,
    label: ROUTE.RESOURCE_CENTER.title,
  },
];

export const ADMIN_ROUTES: NavigationPath[] = [
  {
    path: OLD_ROUTES.ADMIN_USERS.path(),
    label: OLD_ROUTES.ADMIN_USERS.menuTitle,
  },
  {
    path: OLD_ROUTES.ADMIN_FIRMS.path(),
    label: OLD_ROUTES.ADMIN_FIRMS.menuTitle,
  },
  {
    path: OLD_ROUTES.ADMIN_SECURITY_LISTING.path(),
    label: OLD_ROUTES.ADMIN_SECURITY_LISTING.menuTitle,
  },
  {
    path: OLD_ROUTES.ADMIN_CLIENT_HOLDINGS_UPLOAD.path(),
    label: OLD_ROUTES.ADMIN_CLIENT_HOLDINGS_UPLOAD.menuTitle,
  },
  {
    path: OLD_ROUTES.ADMIN_EMPLOYEES.path(),
    label: OLD_ROUTES.ADMIN_EMPLOYEES.menuTitle,
  },
  {
    path: ROUTE.RESOURCE_CENTER.path,
    label: ROUTE.RESOURCE_CENTER.title,
  },
];
