import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {debounce, map, merge, isNil, isEqual} from 'lodash';
import {Doughnut} from 'react-chartjs-2';
import faTimes from 'app/fontawesome-pro-light/faTimes';

import {COLOR, RATING_OPTIONS} from 'app/constants';
import {
  disableChartJsSegmentHover,
  enableChartJsSegmentHover,
  formatLabelAsPercent,
  hideChartJsTooltip,
  prepDataForChart,
  pullOutChartJsSegment,
  showChartJsTooltip,
  formatCurrency,
} from 'app/utilities';
import {BarChartComparison, APreventDefault, LoadingSpinner, SpringScrollbars} from 'app/components';
import {Overline} from 'v2/components/atoms/Typeface';

class DistributionPanels extends Component {
  constructor(props) {
    super(props);

    this.debouncedHandleResize = debounce(this.handleResize.bind(this), 400);
    this.handleSectorClick = this.handleSectorClick.bind(this);
    this.handleDistributionClick = this.handleDistributionClick.bind(this);
    this.closeDistribution = this.closeDistribution.bind(this);
    this.closeSector = this.closeSector.bind(this);
    this.onAnimationComplete = this.onAnimationComplete.bind(this);
    this.onElementsClick = this.onElementsClick.bind(this);

    this.sectorsChart = React.createRef();

    this.state = {
      isStrategyOpen: true,
      didIntroAnimationComplete: false,
      shouldRedraw: true,
    };

    window.addEventListener('resize', this.debouncedHandleResize);
  }

  UNSAFE_componentWillUpdate(prevProps) {
    if (prevProps !== this.props || !isEqual(prevProps.sectorData, this.props.sectorData)) {
      this.setState({shouldRedraw: true});
    } else if (this.state.shouldRedraw) {
      this.setState({shouldRedraw: false});
    }
  }

  handleDistributionClick({datasetIndex, dataIndex}) {
    // In this component, we only respect clicks on the first dataset (Plan)
    // and only if the data is more than 0%
    if (datasetIndex === 0 && this.props.distributionData[datasetIndex].data[dataIndex] > 0) {
      this.props.onDistributionClick(dataIndex + 1);
      this.setState({
        distributionDatasetIndex: datasetIndex,
        distributionDataIndex: dataIndex,
        sectorDatasetIndex: null,
        sectorDataIndex: null,

        isDistributionOpen: true,
        selectedDistribution: RATING_OPTIONS[dataIndex],
        isSectorOpen: false,
        isClosingAPanel: this.state.isSectorOpen,
        isSectorDataExpired: true,
      });
    }
  }

  handleSectorClick({datasetIndex, dataIndex}) {
    this.props.onSectorClick(this.state.distributionDataIndex + 1, this.props.sectorData.sectorIds[dataIndex]);
    this.setState({
      sectorDatasetIndex: datasetIndex,
      sectorDataIndex: dataIndex,

      isSectorOpen: true,
      selectedSector: this.props.sectorData.labels[dataIndex],
      isClosingAPanel: false,
      isCompanyDataExpired: true,
    });
  }

  // When you mouse over a sector chart segment, highlight the sector list item
  handleSectorChartElementMouseOver(mouseEvent, chartElements) {
    const sectorHoverDatasetIndex = chartElements[0].datasetIndex;
    const sectorHoverDataIndex = chartElements[0].index;

    if (
      this.state.sectorHoverDatasetIndex !== sectorHoverDatasetIndex ||
      this.state.sectorHoverDataIndex !== sectorHoverDataIndex
    ) {
      this.setState({
        sectorHoverDatasetIndex,
        sectorHoverDataIndex,
      });
    }

    if (sectorHoverDatasetIndex !== null && sectorHoverDataIndex !== null) {
      // Scroll to the % position relative to the index
      const dataCount = this.props.sectorData.datasets[sectorHoverDatasetIndex].data.length;
      const height = this.sectorScrollList.getHeight();
      const scrollHeight = this.sectorScrollList.getScrollHeight();
      const scrollTop = ((scrollHeight - height) * sectorHoverDataIndex) / (dataCount - 1);
      this.sectorScrollList.scrollTop(scrollTop);
    }
  }

  // When you mouse out a sector chart segment, un-highlight the sector list item
  handleSectorChartElementMouseOut() {
    if (this.state.sectorHoverDatasetIndex !== null || this.state.sectorHoverDataIndex !== null) {
      this.setState({
        sectorHoverDatasetIndex: null,
        sectorHoverDataIndex: null,
      });
    }
  }

  // When you mouse over a sector list item, highlight the sector chart segment
  handleSectorListItemMouseOver({datasetIndex, dataIndex}) {
    const chartInstance = this.sectorsChart.current;
    if (chartInstance) {
      enableChartJsSegmentHover({chartInstance, datasetIndex, dataIndex});
      showChartJsTooltip({chartInstance, datasetIndex, dataIndex});
    }
  }

  // When you mouse out a sector list item, un-highlight the sector chart segment
  handleSectorListItemMouseOut({datasetIndex, dataIndex}) {
    const chartInstance = this.sectorsChart.current;
    if (chartInstance) {
      disableChartJsSegmentHover({chartInstance, datasetIndex, dataIndex});
      hideChartJsTooltip({chartInstance, datasetIndex, dataIndex});
    }
  }

  pullOutSegment({datasetIndex, dataIndex}) {
    const chartInstance = this.sectorsChart.current;
    if (!isNil(datasetIndex) && !isNil(dataIndex)) {
      pullOutChartJsSegment({chartInstance, datasetIndex, dataIndex});
    }
  }

  handleResize() {
    const {sectorDatasetIndex, sectorDataIndex} = this.state;
    this.pullOutSegment({sectorDatasetIndex, sectorDataIndex});
  }

  onAnimationComplete() {
    // after first intro animation is complete, pull out the selected segment
    if (!this.didIntroAnimationComplete) {
      const {sectorDatasetIndex, sectorDataIndex} = this.state;
      this.pullOutSegment({sectorDatasetIndex, sectorDataIndex});
      this.didIntroAnimationComplete = true;
    }
  }

  onElementsClick(event, element) {
    // when the chart is clicked, pass the info along to the container
    if (element.length > 0) {
      const datasetIndex = element[0].datasetIndex;
      const dataIndex = element[0].index;
      this.handleSectorClick({datasetIndex, dataIndex});
      this.pullOutSegment({datasetIndex, dataIndex});
    }
  }

  closeDistribution() {
    this.setState({
      distributionDatasetIndex: null,
      distributionDataIndex: null,
      sectorDatasetIndex: null,
      sectorDataIndex: null,

      isDistributionOpen: false,
      isSectorOpen: false,
      isClosingAPanel: true,
    });
  }

  closeSector() {
    this.setState({
      sectorDatasetIndex: null,
      sectorDataIndex: null,

      isSectorOpen: false,
      isClosingAPanel: true,
    });
  }

  componentDidUpdate(prevProps) {
    // clear expiration when new data arrives
    const stateUpdates = {};

    const sectorDataTimeStamp = this.props.sectorDataTimeStamp ? this.props.sectorDataTimeStamp.getTime() : 0;
    const oldSectorDataTimeStamp = prevProps.sectorDataTimeStamp ? prevProps.sectorDataTimeStamp.getTime() : 0;
    if (sectorDataTimeStamp !== oldSectorDataTimeStamp) {
      stateUpdates.isSectorDataExpired = false;
    }

    const companyDataTimeStamp = this.props.companyDataTimeStamp ? this.props.companyDataTimeStamp.getTime() : 0;
    const oldCompanyDataTimeStamp = prevProps.companyDataTimeStamp ? prevProps.companyDataTimeStamp.getTime() : 0;
    if (companyDataTimeStamp !== oldCompanyDataTimeStamp) {
      stateUpdates.isCompanyDataExpired = false;
    }

    if (Object.keys(stateUpdates).length) {
      this.setState(stateUpdates);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  render() {
    const {distributionData, distributionTitle, sectorData, companyData} = this.props;

    const orderedCompanyData = companyData.sort((a, b) => b.totalMarketValue - a.totalMarketValue);

    const {
      isStrategyOpen,
      isDistributionOpen,
      isSectorOpen,
      isClosingAPanel,
      distributionDatasetIndex,
      distributionDataIndex,
      sectorDatasetIndex,
      sectorDataIndex,
      sectorHoverDatasetIndex,
      sectorHoverDataIndex,
      isSectorDataExpired,
      isCompanyDataExpired,
    } = this.state;

    const preppedSectorData = prepDataForChart(sectorData);

    const options = {
      cutout: '44%',
      resposive: true,
      elements: {
        arc: {
          borderColor: COLOR.LIGHTEST_GRAY,
          borderWidth: 0.7,
        },
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: false,
        datalabels: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: formatLabelAsPercent,
          },
        },
      },
      animation: {
        onComplete: this.onAnimationComplete,
        duration: 0,
      },
      onClick: this.onElementsClick,
      onHover: (mouseEvent, chartElements) => {
        if (chartElements.length) {
          // mouse over
          this.handleSectorChartElementMouseOver(mouseEvent, chartElements);
        } else {
          // mouse out
          this.handleSectorChartElementMouseOut(mouseEvent);
        }
      },
    };

    return (
      <div
        className={cn('panels is-portfolio mb-12', {
          'is-panel-1-open': isStrategyOpen,
          'is-panel-2-open': isDistributionOpen,
          'is-panel-3-open': isSectorOpen,
          'is-closing-a-panel': isClosingAPanel,
        })}
        style={{overflow: 'visible'}}
      >
        <div className={cn('panel is-distributions', {'is-closed': !isStrategyOpen})} style={{overflow: 'visible'}}>
          <h4>{distributionTitle}</h4>
          <BarChartComparison
            datasets={distributionData}
            selectedDatasetIndex={distributionDatasetIndex}
            selectedDataIndex={distributionDataIndex}
            onDistributionClick={this.handleDistributionClick}
          />
          <Overline
            style={{
              top: '.5rem',
              position: 'relative',
              display: 'inline-block',
              width: '13%',
              textAlign: isDistributionOpen ? 'center' : 'left',
            }}
          >
            {'Percentile Rank'}
          </Overline>
        </div>
        <div
          className={cn('panel is-sectors', {
            'is-closed': !isDistributionOpen,
            'is-loading': isSectorDataExpired,
          })}
        >
          <div className="panel-graph">
            <h4 className="mb-0">Sectors</h4>
            <div className="mx-auto transition" style={{maxWidth: 230, height: 230, cursor: 'pointer'}}>
              {this.sectorsChart && (
                <Doughnut
                  onMouseLeave={() => this.handleSectorChartElementMouseOut()}
                  ref={this.sectorsChart}
                  data={preppedSectorData}
                  options={options}
                  redraw={this.state.shouldRedraw}
                />
              )}
            </div>
            <button className="panel-close" onClick={this.closeDistribution}>
              <Icon icon={faTimes} />
            </button>
          </div>
          <div className="panel-scroll-list">
            <SpringScrollbars
              ref={e => {
                this.sectorScrollList = e;
              }}
            >
              <div className="panel-scroll-list-content">
                {map(preppedSectorData.datasets, ({data, backgroundColor}, datasetIndex) => (
                  <ol className="value-list is-responsive has-colors" key={datasetIndex}>
                    {map(data, (value, dataIndex) => {
                      if (!value) return null; // Hide 0% sectors from list

                      const isSelected = datasetIndex === sectorDatasetIndex && dataIndex === sectorDataIndex;
                      const isHovered = datasetIndex === sectorHoverDatasetIndex && dataIndex === sectorHoverDataIndex;
                      const color = backgroundColor[dataIndex];

                      return (
                        <li className={cn('value-list-item', {'is-selected': isSelected})} key={dataIndex}>
                          <APreventDefault
                            className={cn('value-list-item-inner', {'is-hovered': isHovered})}
                            onClick={() => this.handleSectorClick({datasetIndex, dataIndex})}
                            onMouseOver={() => this.handleSectorListItemMouseOver({datasetIndex, dataIndex})}
                            onMouseOut={() => this.handleSectorListItemMouseOut({datasetIndex, dataIndex})}
                          >
                            <span className="value-list-item-color" style={{backgroundColor: color}} />
                            <span className="value-list-item-label">{preppedSectorData.labels[dataIndex]}</span>
                            <span className="value-list-item-value" style={{color: isSelected ? color : null}}>
                              {Number(value).toFixed(1)}%
                            </span>
                          </APreventDefault>
                        </li>
                      );
                    })}
                  </ol>
                ))}
              </div>
            </SpringScrollbars>
          </div>
          <LoadingSpinner isActive={isSectorDataExpired} />
        </div>
        <div className={cn('panel is-companies', {'is-closed': !isSectorOpen, 'is-loading': isCompanyDataExpired})}>
          <h4>Companies</h4>
          <div className="panel-scroll-list">
            <SpringScrollbars>
              <div className="panel-scroll-list-content">
                <ol className="value-list is-responsive">
                  {map(orderedCompanyData, ({isin, name, totalMarketValue, companyId}) => {
                    if (!totalMarketValue) return null; // Hide $0 companies from list=
                    return (
                      <li key={`${isin}.${name}`} className="value-list-item">
                        <APreventDefault
                          className="value-list-item-inner is-link"
                          onClick={() => this.props.onCompanyClick(companyId)}
                        >
                          <span className="value-list-item-label">{name}</span>
                          <span className="value-list-item-value">{formatCurrency(totalMarketValue)}</span>
                        </APreventDefault>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </SpringScrollbars>
          </div>
          <button className="panel-close" onClick={this.closeSector}>
            <Icon icon={faTimes} />
          </button>
          <LoadingSpinner isActive={isCompanyDataExpired} />
        </div>
      </div>
    );
  }
}

DistributionPanels.propTypes = {
  distributionData: PropTypes.array.isRequired,
  sectorData: PropTypes.object.isRequired,
  companyData: PropTypes.array.isRequired,
  distributionTitle: PropTypes.string,
  sectorDataTimeStamp: PropTypes.object,
  companyDataTimeStamp: PropTypes.object,
};

DistributionPanels.defaultProps = {distributionTitle: 'Distribution of Company Ratings for Holdings'};

export {DistributionPanels};
