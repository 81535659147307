import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {SelectOption} from '../interfaces/Select';
import {Title1, Title3, Body1, Caption1} from '../atoms/Typeface';
import {DropDown} from '../molecules/DropDown';
import {FilterList} from '../atoms/icons';
import {DashboardHeaderProps} from '../interfaces/LayoutProps';
import {useDispatch, useSelector} from 'react-redux';
import {updateType, updateClass, updateRegion, updateFilters} from '../../redux/strategyFilter';
import {allAssetsClassValues, RegionValues, TypeValues} from 'v2/constants/strategyFilters';
import {DATA_STATUS} from 'v2/constants/dataStatus';
import {useLocation} from 'react-router';
import {useSideDrawer} from 'v2/components/molecules/Drawer/Drawer';

const Wrapper = styled.div`
  background: ${props => props.theme.gradients.harmony_to_green};
  height: 216px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.constants.fixWidth};
  height: inherit;
  padding: 0 1rem;
`;

const TitleFlexWrapper = styled.div`
  padding-top: 3.375rem;
  display: flex;
`;

const TitleContainer = styled.div`
  position: relative;
  top: -0.5rem;
  flex: 1;
`;

const CompanyTitle = styled(Title1)`
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1rem;
  color: ${props => props.theme.colors.white};
`;

const PageTitle = styled(Title3)`
  text-transform: uppercase;
  line-height: 1rem;
  font-weight: normal !important;
  color: ${props => props.theme.colors.white};
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 0 0;
`;

const OptionContainer = styled.div`
  display: flexbox;
  align-items: center;
  margin-right: 0.5rem;
  min-width: 14rem;
`;

const OptionLabel = styled(Caption1)`
  padding: 0 1rem 0 0.5rem;
  color: ${props => props.theme.colors.white};
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.grey_3};
  border-radius: ${props => props.theme.constants.border_radius};
  border: none;
  height: 2.35rem;
  width: 11.063rem;
  padding: 0 2.5rem;
  cursor: pointer;
`;

const FilterButtonLabel = styled(Body1)`
  display: block;
  white-space: nowrap;
  padding: 0 0.5rem;
  flex: 2;
`;

const FilterButtonFlex = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  companyTitle,
  pageTitle,
  typeOptions,
  classOptions,
  regionOptions,
  typeValue,
  classValue,
  regionValue,
  selectedPath,
  isRegionDisabled,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [showReset, setShowReset] = useState(false);
  const {pathname} = useLocation();
  const {isOpen, handleOpen, handleClose} = useSideDrawer();

  const filter = useSelector((state: any) => {
    return {
      filters: state.strategyFilter.filters,
      type: state.strategyFilter.typeValue,
      class: state.strategyFilter.classValue,
      region: state.strategyFilter.regionValue,
    };
  });

  const handleUpdateType = (value: SelectOption): void => {
    dispatch(updateType(value));
    // dispatch(updateClassOptions(value));
  };

  const handleUpdateClass = (value: SelectOption): void => {
    dispatch(updateClass(value));
  };

  const handleUpdateRegion = (value: SelectOption): void => {
    dispatch(updateRegion(value));
  };

  const handleUpdateFilters = () => {
    dispatch(updateFilters());
  };

  const dispatchUpdateFilters = () => {
    setShowReset(true);
    dispatch(updateFilters());
  };

  async function handleResetFilters() {
    setShowReset(false);
    await dispatch(updateType(TypeValues.allAssets));
    await dispatch(updateClass(allAssetsClassValues.allProducts));
    await dispatch(updateRegion(RegionValues.overall));
    dispatchUpdateFilters();
  }

  const filtersAreDefault = (filters: any) => {
    const appliedFilters = filters.filters;
    if (!appliedFilters) {
      return true;
    }
    if (appliedFilters.type === 'all' && appliedFilters.class === '' && appliedFilters.region === '') {
      return true;
    }
    if (filters.type.value !== appliedFilters.type) {
      return true;
    }
    if (filters.class.value !== appliedFilters.class) {
      return true;
    }
    if (filters.region.value !== appliedFilters.region) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let areDefault = filtersAreDefault(filter);
    setShowReset(!areDefault);
  }, [filter]);

  const filterAction = showReset ? handleResetFilters : handleUpdateFilters;
  const dataExplorerClick = pathname.includes('data-explorer') ? handleOpen : filterAction;
  const filterButtonLabel = showReset ? 'Reset Filters' : 'Apply Filters';
  const filterButtonOnClick = dataExplorerClick;

  return (
    <Wrapper>
      <Container>
        <TitleFlexWrapper>
          <TitleContainer>
            <CompanyTitle>{isLoading ? DATA_STATUS.NONE : companyTitle ?? DATA_STATUS.NONE}</CompanyTitle>
            <PageTitle>{pageTitle}</PageTitle>
          </TitleContainer>
          {/* <div><GenerateReportButton /></div> */}
        </TitleFlexWrapper>
        <OptionsWrapper>
          <OptionContainer>
            <OptionLabel>Type</OptionLabel>
            <DropDown isDark onChange={handleUpdateType} options={typeOptions} value={typeValue} />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>Class</OptionLabel>
            <DropDown isDark onChange={handleUpdateClass} options={classOptions} value={classValue} />
          </OptionContainer>
          <OptionContainer>
            <OptionLabel>Region</OptionLabel>
            <DropDown
              isDark
              onChange={handleUpdateRegion}
              options={regionOptions}
              value={regionValue}
              disabled={isRegionDisabled ?? false}
            />
          </OptionContainer>
          <FilterButton onClick={filterButtonOnClick}>
            <FilterButtonFlex>
              <FilterList size={18} />
            </FilterButtonFlex>
            <FilterButtonLabel>{filterButtonLabel}</FilterButtonLabel>
            <FilterButtonFlex />
          </FilterButton>
        </OptionsWrapper>
      </Container>
    </Wrapper>
  );
};
