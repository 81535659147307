import {useState} from 'react';
import styled from 'styled-components/macro';

import {Theme} from 'v2/components/atoms/theme';
import {Body1, Title1} from 'v2/components/atoms/Typeface';
import {PaddinglessModal} from 'v2/components/molecules/Modal';
import {ESGExplorer} from 'v2/components/organisms/ESGExplorer';
import {ESGInfo} from 'v2/components/organisms/ESGInfo';
import {ROUTE} from 'v2/constants/routes';
import {BannerButton} from 'v2/components/molecules/BannerButton';
import {bannerDescription, bannerLabel, percentileRankDescription} from 'v2/constants/esgPageDescriptions';

const Container = styled.div`
  position: relative;
  padding-bottom: 4rem;
`;

const Body = styled(Body1)`
  &&& {
    color: ${props => props.theme.colors.dark};
  }
`;

const Title = styled(Title1)`
  &&& {
    color: ${props => props.theme.colors.dark};
    letter-spacing: 0.05rem;
    text-transform: none;
  }
`;

const FlexRow = styled.div<{direction?: string; align?: string; justify?: string}>`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-items: ${props => (props.align ? props.align : ' stretch')};
  justify-content: ${props => (props.justify ? props.justify : ' flex-start')};
`;

const FlexItem = styled.div<{
  flex?: number;
  maxWidth?: number | string;
  padding?: string;
}>`
  flex: ${props => (props.flex ? props.flex : 0)};
  width: auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  padding: ${({padding}) => padding ?? 0};
`;

export const UT1ESGExplorerPage = () => {
  const [showInfo, setShowInfo] = useState(false);

  // Future toggle for list view
  // const [esgView, setEsgView] = useState(true);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const closeInfo = () => {
    setShowInfo(false);
  };

  return (
    <Container>
      <FlexRow direction="column">
        <FlexItem>
          <FlexRow align="flex-start" justify="flex-center">
            <Title color={Theme.colors.dark}>{ROUTE.UT1.DATA_EXPLORER.title}</Title>
          </FlexRow>
        </FlexItem>
        <FlexItem>
          <FlexRow>
            <FlexItem flex={1} padding="0 0 2rem">
              <Body color={Theme.colors.dark}>{percentileRankDescription}</Body>
            </FlexItem>
          </FlexRow>
        </FlexItem>
        <FlexItem>
          <FlexRow>
            <BannerButton description={bannerDescription} label={bannerLabel} onClick={toggleInfo} />
          </FlexRow>
        </FlexItem>
      </FlexRow>
      <PaddinglessModal isOpen={showInfo} setClose={closeInfo} width="48rem">
        <ESGInfo />
      </PaddinglessModal>
      <ESGExplorer />
    </Container>
  );
};
