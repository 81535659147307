import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {intersection, debounce, get, includes, map} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll';
import {compose, getContext, withContext, withHandlers} from 'recompose';

// Local Imports
import {FORM_MESSAGE, ROUTE, USER_MANAGER_FILTER, PERMISSIONS, ROLE} from 'app/constants';
import {Alert, Button, BootstrapTableRemote, APreventDefault, TooltipLink, VoidLink} from 'app/components';
import {getPaged} from 'app/redux/adminUser';
import faTrash from 'app/fontawesome-pro-light/faTrash';
import faPencil from 'app/fontawesome-pro-light/faPencil';
import faSearch from 'app/fontawesome-pro-light/faSearch';
import faUser from 'app/fontawesome-pro-light/faUser';
import {getPrametersFromQuery, setupQueryForLink} from 'app/utilities/querystringManipulation';
import {deleteUser} from 'app/redux/user';
import {impersonateUser, impersonationComplete} from 'app/redux/auth';
import {toastError, toastSuccess} from 'app/utilities/toast';
import {currentImpersonatedUser} from 'app/utilities/reduxGetImpersonatedUser';
import {openSimpleModal} from 'app/components/SimpleModal/redux';
import {getAuthJwt} from 'app/utilities/tokenFunctions';

import isNull from 'redux-actions/lib/utils/isNull';
import moment from 'moment';
import {DATA_STATUS} from 'v2/constants/dataStatus';
/**
 * Formats the supplied roles for the column type.  Only uses the first item in the array
 * @param {Array} roles - The list of roles to output
 * @return {Object} The data to be displayed
 */
function formatType(roles) {
  if (!roles || roles.length < 1) {
    return '';
  }

  return roles[0].replace('UserType', '');
}

const DeleteLinkIcon = compose(
  getContext({
    handleUserDelete: PropTypes.func,
  }),
  withHandlers({
    onDeleteClick: props => () => {
      props.handleUserDelete(props.userId);
    },
  }),
)(({onDeleteClick}) => (
  <VoidLink className="text-danger" onClick={onDeleteClick}>
    <Icon icon={faTrash} />
  </VoidLink>
));

const EditLinkIcon = compose(
  getContext({
    getEditLink: PropTypes.func,
  }),
)(({getEditLink, userId}) => (
  <Link className="text-info" to={getEditLink(userId)}>
    <Icon icon={faPencil} />
  </Link>
));

const ImpersonateLinkIcon = compose(
  getContext({
    handleUserImpersonation: PropTypes.func,
  }),
  withHandlers({
    onImpersonateClick: props => () => {
      props.handleUserImpersonation(props.userId);
    },
  }),
)(({onImpersonateClick}) => (
  <APreventDefault className="text-info" onClick={() => onImpersonateClick()}>
    <Icon icon={faUser} />
  </APreventDefault>
));

class AdminUserManagerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.search,
      filter: props.filter,
      permissions: props.adminPermissions,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.changeField = this.changeField.bind(this);
    this.handleSearchDebounced = debounce(this.handleSearch, 500);
  }

  shouldComponentUpdate(nextProps) {
    return !!nextProps.data;
  }

  /**
   * Handle initial loading of the data
   */
  componentDidMount() {
    this.fetchData(
      this.props.sortField,
      this.props.sortOrder,
      this.props.page,
      this.props.pageSize,
      this.props.search,
      this.props.filter,
    );
    this.setState({permissions: getAuthJwt(true)?.contents?.adminPermission});
    animateScroll.scrollToTop();
  }

  updateCurrentQueryString(
    sortField = this.props.sortField,
    sortOrder = this.props.sortOrder,
    page = this.props.page,
    pageSize = this.props.pageSize,
    search = this.state.search,
    filter = this.state.filter,
  ) {
    let updatedPage = page;
    // if adding new search, reset page to 1
    if (search !== this.props.search || filter !== this.props.filter) {
      updatedPage = 1;
    }

    this.props.dispatch(push({search: setupQueryForLink(sortField, sortOrder, updatedPage, pageSize, search, filter)}));
  }

  /**
   * Handles the changing of the text in the field.  Updates the state with the value.
   */
  changeField(event) {
    const newState = Object.assign({}, this.state);
    newState[event.target.id] = event.target.value;

    this.setState(newState);

    this.handleSearchDebounced();
  }

  /**
   * Handles when the table fires an event
   */
  handleTableChange = (
    type,
    {
      sortField = this.props.sortField,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      sizePerPage = this.props.pageSize,
    },
  ) => {
    /* 'sort' is fired when a defaultSorted (initial sorting) specified on the table.
     * if so, ignore it and do not update the querystring
     */
    if (type === 'sort' && this.props.sortField === sortField && this.props.sortOrder === sortOrder) {
      return;
    }

    this.updateCurrentQueryString(sortField, sortOrder, page, sizePerPage);
  };

  /**
   * Handles the selecting / clicking of a row
   * potential parameters are (row, isSelect, rowIndex, e)
   */
  handleOnSelect = row => {
    const {sortField, sortOrder, page, pageSize, search, filter} = this.props;

    this.props.dispatch(
      push({
        pathname: ROUTE.ADMIN_USERS_EDIT.path(row.id),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, {filter}),
      }),
    );
  };

  /**
   * Handle click of search button to retrieve data from the API
   */
  async handleSearch() {
    await this.updateCurrentQueryString();
    var element = document.getElementById('search');
    element.focus();
    element.click();
  }

  /**
   * Performs the actual data retrieval
   */
  fetchData = (sortField, sortOrder, page, pageSize, search, filter) => {
    this.props.dispatch(
      getPaged({
        page,
        pageSize,
        sortField,
        sortOrder,
        search,
        filter,
      }),
    );
  };

  checkImpersonatePermissions = (roles, permissions, id) => {
    if (includes(roles, ROLE.USER_TYPE_1) && includes(permissions, PERMISSIONS.IMPERSONATE_UT1)) {
      return <ImpersonateLinkIcon userId={id} />;
    } else if (includes(roles, ROLE.USER_TYPE_2) && includes(permissions, PERMISSIONS.IMPERSONATE_UT2)) {
      return <ImpersonateLinkIcon userId={id} />;
    } else if (includes(roles, ROLE.USER_TYPE_3) && includes(permissions, PERMISSIONS.IMPERSONATE_UT3)) {
      return <ImpersonateLinkIcon userId={id} />;
    } else if (includes(roles, ROLE.USER_TYPE_4) && includes(permissions, PERMISSIONS.IMPERSONATE_UT4)) {
      return <ImpersonateLinkIcon userId={id} />;
    }

    return null;
  };

  checkEditPermissions = (permissions, id) => {
    if (includes(permissions, PERMISSIONS.WRITE_USERS)) {
      return <EditLinkIcon userId={id} />;
    }
    return null;
  };

  checkDeletePermissions = (permissions, id) => {
    if (includes(permissions, PERMISSIONS.WRITE_USERS)) {
      return <DeleteLinkIcon userId={id} />;
    }
    return null;
  };

  render() {
    const {permissions} = this.state;

    const columns = [
      {
        dataField: 'lastName',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => (
          <div>
            {row.firstName} {row.lastName}
          </div>
        ),
        headerStyle: {minWidth: 200},
      },
      {
        dataField: 'planSponsor.internalName',
        text: 'Plan Sponsor',
        sort: true,
        headerStyle: {minWidth: 200},
      },
      {
        dataField: 'roles',
        text: 'Type',
        sort: true,
        formatter: (cell, row) => formatType(row.roles),
        headerStyle: {width: 95},
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
        headerStyle: {minWidth: 200},
        classes: 'table-overflow-ellipsis',
      },
      {
        dataField: 'invitationsToUser.code',
        text: 'Invitation',
        sort: true,
        formatter: (cell, row) => (row.hasInvitationBeenSent ? 'Sent' : 'Not sent'),
        headerStyle: {width: 120},
      },
      {
        dataField: 'lastLoginAt',
        text: 'Last Login',
        sort: true,
        formatter: (cell, row) =>
          isNull(row.lastLoginAt) ? DATA_STATUS.NONE : moment(row.lastLoginAt).format('MM/DD/YY LT'),
      },
      {
        dataField: 'impersonate',
        text: '',
        formatter: (value, {id, roles}) => this.checkImpersonatePermissions(roles, permissions, id),
        headerFormatter: () => <span className="sr-only">Impersonate User</span>,
        headerStyle: {width: 30},
      },
      {
        dataField: 'edit',
        text: '',
        formatter: (value, {id}) => this.checkEditPermissions(permissions, id),
        headerFormatter: () => <span className="sr-only">Edit</span>,
        headerStyle: {width: 30},
      },
      {
        dataField: 'delete',
        text: '',
        formatter: (value, {id}) => this.checkDeletePermissions(permissions, id),
        headerFormatter: () => <span className="sr-only">Edit</span>,
        headerStyle: {width: 30},
      },
    ];
    const {data, hasError, sortField, sortOrder, page, search, filter, pageSize, totalSize, canWrite} = this.props;

    // Pass in the sort to be displayed in the table
    const defaultSorted = [];
    if (sortField) {
      defaultSorted.push({
        dataField: sortField,
        order: sortOrder || 'asc',
      });
    }

    return (
      <div className="p-content-lg">
        <div>
          {canWrite && (
            <Link
              className="btn btn-outline float-right"
              to={{
                pathname: ROUTE.ADMIN_USERS_ADD.path(),
                search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, {filter}),
              }}
            >
              {ROUTE.ADMIN_USERS_ADD.title}
            </Link>
          )}
          <h1>{ROUTE.ADMIN_USERS.title}</h1>

          {hasError && <Alert color="danger">{FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE}</Alert>}

          <form method="GET" onSubmit={this.handleSearch}>
            <div className="bootstrap-table-controls">
              <div className="bootstrap-table-search" style={{width: '65%'}}>
                <div className="form-control-and-icon">
                  <input
                    className="form-control"
                    ref={input => {
                      this.searchInput = input;
                    }}
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search by Names, Email, or Plan Sponsor Name"
                    value={this.state.search}
                    onChange={this.changeField}
                  />
                  <div className="icon">
                    <Icon icon={faSearch} />
                  </div>
                  <TooltipLink className="float-right mt-1" label="Search Techniques" id="adminSearch">
                    Surround your search with double quotes to do an exact search. Additionally when using double
                    quotes, you can do wildcard searches. Simply use a percent sign ( % ) to match any character zero or
                    more times and an underscore ( _ ) to match any character exactly one time. For example, "H_rmony%"
                    will find a result for <i>Harmony Analytics</i> as would "%Analytic_".
                  </TooltipLink>
                </div>
                <div>
                  <Button color="gray" type="submit">
                    Search
                  </Button>
                </div>
              </div>
              <div className="bootstrap-table-filter" style={{width: '35%'}}>
                <label className="col-form-label">Filter By</label>
                <select
                  id="filter"
                  name="filter"
                  value={this.state.filter}
                  onChange={this.changeField}
                  className="custom-select form-control"
                >
                  <option value="">Select</option>
                  {map(USER_MANAGER_FILTER, (label, value) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>

          {data && (
            <BootstrapTableRemote
              tableProps={{hover: false}}
              keyField="id"
              columns={columns}
              data={data}
              page={page}
              sizePerPage={pageSize}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              defaultSorted={defaultSorted}
            />
          )}
        </div>
      </div>
    );
  }
}

AdminUserManagerPage.defaultProps = {totalSize: 0};

const mapStateToProps = (state, ownProps) => {
  const newProps = getPrametersFromQuery(
    ownProps,
    {
      search: '',
      page: 1,
      pageSize: 25,
      sortField: 'planSponsor.name',
      sortOrder: 'asc',
      canWrite: includes(get(state, 'auth.adminPermission'), PERMISSIONS.WRITE_USERS),
    },
    ['filter'],
  );
  newProps.isFetching = get(state, 'adminUser.listing.isFetching');
  newProps.data = get(state, 'adminUser.listing.data');
  newProps.totalSize = get(state, 'adminUser.listing.totalSize');
  newProps.hasError = get(state, 'adminUser.listing.hasError');
  newProps.adminPermissions = get(state, 'auth.adminPermission');
  return newProps;
};

export const AdminUserManager = compose(
  connect(mapStateToProps, {deleteUser, openSimpleModal, impersonateUser}),
  withHandlers({
    handleUserDelete: props => userId => {
      return props.openSimpleModal({
        title: 'Delete Confirmation',
        message: 'Do you really want to delete this user?',
        buttons: [
          {
            label: 'Cancel',
            close: true,
          },
          {
            className: 'btn-red ml-auto',
            label: 'Yes, Delete User',
            clickHandler: () =>
              props
                .deleteUser(userId)
                .then(response => {
                  if (response.hasError) throw response.error;
                  toastSuccess('User has been deleted');
                })
                .catch(() => toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE)),
            close: true,
          },
        ],
      });
    },
    getEditLink: props => userId => {
      const {sortField, sortOrder, page, pageSize, search, filter} = props;
      return {
        pathname: ROUTE.ADMIN_USERS_EDIT.path(userId),
        search: setupQueryForLink(sortField, sortOrder, page, pageSize, search, {filter}),
      };
    },
    handleUserImpersonation: props => userId => {
      return props
        .impersonateUser(userId)
        .then(() => {
          props.dispatch(impersonationComplete());
        })
        .catch(err => {
          throw new Error(err);
        });
    },
  }),
  withContext(
    {
      handleUserDelete: PropTypes.func,
      getEditLink: PropTypes.func,
      handleUserImpersonation: PropTypes.func,
    },
    ({handleUserDelete, getEditLink, handleUserImpersonation}) => ({
      handleUserDelete,
      getEditLink,
      handleUserImpersonation,
    }),
  ),
)(AdminUserManagerPage);
