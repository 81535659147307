import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {map} from 'lodash';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import faCheck from 'app/fontawesome-pro-light/faCheck';
import faEllipsisH from 'app/fontawesome-pro-light/faEllipsisH';

const StepWrapper = props => {
  const {isCurrent, children, nextAvailableStep, path, stepOrder} = props;
  // Only link the step if there is path, we're not already on it, and thee previous step is complete
  return path && !isCurrent && nextAvailableStep.stepOrder >= stepOrder ? (
    <Link to={path}>{children}</Link>
  ) : (
    <span>{children}</span>
  );
};

const Step = props => {
  const {shortTitle, shortTitleComplete, isComplete, currentStep, nextAvailableStep, step} = props;
  const isCurrent = currentStep.step === step;
  const isNextAvailable = nextAvailableStep.step === step;
  const className = cn('progress-indicator-icon-step', {
    'is-complete': isComplete,
    'is-current': isCurrent,
  });

  let icon;
  if (isNextAvailable) icon = faEllipsisH;
  if (isComplete) icon = faCheck;

  return (
    <li className={className}>
      <StepWrapper {...props} isCurrent={isCurrent}>
        {icon && (
          <span className="progress-indicator-step-icon">
            <Icon icon={icon} />
          </span>
        )}
        {isComplete && shortTitleComplete ? shortTitleComplete : shortTitle}
      </StepWrapper>
    </li>
  );
};

const Steps = ({steps, currentStep, nextAvailableStep}) => {
  // Keep track of previous step state and inject into the next step's props
  return map(steps, step => {
    const stepProps = Object.assign({}, step);
    return <Step {...stepProps} currentStep={currentStep} nextAvailableStep={nextAvailableStep} key={stepProps.step} />;
  });
};

const ProgressIndicator = ({className, currentStep, nextAvailableStep, small, steps, style}) => (
  <div className={cn('progress-indicator', {'is-small': small}, className)} style={style}>
    <ol className="progress-indicator-inner">
      <Steps steps={steps} currentStep={currentStep} nextAvailableStep={nextAvailableStep} />
    </ol>
  </div>
);

const stepShape = {
  isComplete: PropTypes.bool,
  path: PropTypes.string,
  shortTitle: PropTypes.node.isRequired,
  step: PropTypes.string.isRequired,
  stepOrder: PropTypes.number,
};

ProgressIndicator.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.shape(stepShape),
  nextAvailableStep: PropTypes.shape(stepShape),
  small: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape(stepShape)),
  style: PropTypes.object,
};

ProgressIndicator.defaultProps = {className: 'py-4'};

export {ProgressIndicator};
