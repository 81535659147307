import styled from 'styled-components';
import {PageFooter} from './PageFooter';
import {PageLayoutProps} from '../interfaces/LayoutProps';
import {NavigationBar} from './NavigationBar';
import {isImpersonating} from '../../../app/utilities';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {useDispatch} from 'react-redux';
import {NAV_ROUTES} from '../../constants/routes';
import {useEffect} from 'react';
import {getDetails} from 'app/redux/planDashboard';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div<{background?: string}>`
  flex: 1;
  background: ${({theme, background}) => background ?? theme.colors.grey_2};
`;

export const PageLayout: React.FC<PageLayoutProps> = ({background, children}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetails());
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper background={background}>{children}</ContentWrapper>
      <PageFooter />
    </PageWrapper>
  );
};
