import {Redirect, Route} from 'react-router';
import {Middleware} from './types';
import {
  isImpersonating,
  tokenIsExpired,
  getAuthJwt,
  getAuthRt,
  getImpersonationJwt,
  removeAuthTokens,
  refreshAuthTokens,
  refreshImpersonationTokens,
} from 'app/utilities';

export const authenticationMiddleware: Middleware = async (next: any) => {
  const authRt = await getAuthRt(true);
  const authJwt = await getAuthJwt(true);

  if (tokenIsExpired(authRt)) {
    removeAuthTokens();
    return (
      <Route
        render={() => {
          return <Redirect to="/login" />;
        }}
      />
    );
  }

  if (tokenIsExpired(authJwt)) {
    await refreshAuthTokens();
  }

  if (isImpersonating()) {
    const impersonationJwt = getImpersonationJwt(true);

    if (tokenIsExpired(impersonationJwt)) {
      await refreshImpersonationTokens();
    }
  }

  return next();
};
