export const RATINGS_METADATA = {
  1: {
    badgeAssetBaseName: 'harmony-platinum',
  },
  2: {
    badgeAssetBaseName: 'harmony-gold',
  },
  3: {
    badgeAssetBaseName: 'harmony-silver',
  },
  4: {
    badgeAssetBaseName: 'harmony-bronze',
  },
  5: {
    badgeAssetBaseName: 'harmony-substandard',
  },
};

export const RATINGS_COLORS = {
  1: '#758DA4',
  2: '#BF9763',
  3: '#B4B2AD',
  4: '#967867',
  5: '#333A46',
};
