/* eslint-disable no-unused-vars */
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';

// Local Imports
import {Alert, Button, FormFeedback} from 'app/components';
import {Checkbox, TextFormGroup, SelectFormGroup} from 'app/components/forms';
import {ROUTE, REGEX_PATTERN, CHARACTER_LIMIT, AUTOCOMPLETE, USER_PREFIXES, PHONE_TYPES} from 'app/constants';
import {errorRequiredFields, validatePassword, normalizeExtensionlessPhone} from 'app/utilities';

const validate = values => {
  const errors = errorRequiredFields(values, [
    'firstName',
    'lastName',
    'email',
    'password',
    'confirmPassword',
    'agreeToTerms',
  ]);

  if (values.firstName && !values.firstName.match(REGEX_PATTERN.VALID_ALPHA_ONLY_CHARACTERS)) {
    errors.firstName = 'No Numeric or Special Characters';
  }

  if (values.lastName && !values.lastName.match(REGEX_PATTERN.VALID_ALPHA_ONLY_CHARACTERS)) {
    errors.lastName = 'No Numeric or Special Characters';
  }

  if (values.suffix && !values.suffix.match(REGEX_PATTERN.VALID_ALPHANUMERIC_CHARACTERS)) {
    errors.suffix = 'No Special Characters';
  }

  if (values.title && !values.title.match(REGEX_PATTERN.VALID_ALPHANUMERIC_SPACE_CHARACTERS)) {
    errors.title = 'No Special Characters';
  }

  if (values.role && !values.role.match(REGEX_PATTERN.VALID_ALPHANUMERIC_SPACE_CHARACTERS)) {
    errors.role = 'No Special Characters';
  }
  if (values.phoneNumber) {
    if (!values.phoneNumber.match(REGEX_PATTERN.VALID_PHONE_NUMBER)) {
      errors.phoneNumber = 'Must be a valid phone number';
    }
  }

  if (values.email && !values.email.match(REGEX_PATTERN.VALID_EMAIL)) {
    errors.email = 'Invalid Email Address';
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Must match Password';
  }

  if (values.password) {
    errors.password = validatePassword(values.password, null);
  }

  return errors;
};

class OnboardingUT4CreateAccountFormComponent extends Component {
  render() {
    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!this.props.submitFailed && this.props.invalid);

    return (
      <form method="POST" onSubmit={this.props.handleSubmit}>
        <FormFeedback message={this.props.error} visible={isErrorFormFeedbackVisible} />
        <div className="form-grid">
          <div className="form-row">
            <div className="col-1">
              <Field
                autoComplete="prefix"
                label="Prefix"
                name="prefix"
                component={SelectFormGroup}
                options={USER_PREFIXES}
                shouldInsertDefault={true}
              />
            </div>
            <div className="col-2">
              <Field
                autoComplete="first-name"
                label="First Name*"
                name="firstName"
                type="text"
                component={TextFormGroup}
                hasSplitFeedback={true}
                maxLength={CHARACTER_LIMIT.FIRST_NAME}
              />
            </div>
            <div className="col-2">
              <Field
                autoComplete="last-name"
                label="Last Name*"
                name="lastName"
                type="text"
                component={TextFormGroup}
                hasSplitFeedback={true}
                maxLength={CHARACTER_LIMIT.LAST_NAME}
              />
            </div>
            <div className="col-1">
              <Field
                autoComplete="suffix"
                label="Suffix"
                name="suffix"
                type="text"
                component={TextFormGroup}
                hasSplitFeedback={true}
                maxLength={CHARACTER_LIMIT.NAME_SUFFIX}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-3">
              <Field
                autoComplete="title"
                label="Title"
                name="title"
                type="text"
                component={TextFormGroup}
                hasSplitFeedback={true}
                maxLength={CHARACTER_LIMIT.PROFILE_TITLE}
              />
            </div>
            <div className="col-3">
              <Field
                autoComplete="role"
                label="Role"
                name="role"
                type="text"
                component={TextFormGroup}
                hasSplitFeedback={true}
                maxLength={CHARACTER_LIMIT.PROFILE_ROLE}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-1">
              <Field
                autoComplete="phone-number"
                label="Phone Type"
                name="phoneType"
                component={SelectFormGroup}
                options={PHONE_TYPES}
                shouldInsertDefault={true}
              />
            </div>
            <div className="col-2">
              <Field
                autoComplete="phone-number"
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                // autoComplete={AUTOCOMPLETE.PHONE}
                component={TextFormGroup}
                hasSplitFeedback={true}
              />
            </div>
            <div className="col-3">
              <Field
                autoComplete="email"
                label="Email*"
                name="email"
                type="text"
                component={TextFormGroup}
                hasSplitFeedback={true}
                maxLength={CHARACTER_LIMIT.EMAIL}
              />
            </div>
          </div>
          <div className="form-row mb-6">
            <div className="col-3">
              <Field
                autoComplete="new-password"
                label="Choose a Password*"
                name="password"
                type="password"
                component={TextFormGroup}
                hasSplitFeedback={true}
              >
                <span className="form-text text-muted float-left">Minimum of 8 characters</span>
              </Field>
            </div>
            <div className="col-3">
              <Field
                autoComplete="new-password"
                label="Confirm Your Password*"
                name="confirmPassword"
                type="password"
                component={TextFormGroup}
                hasSplitFeedback={true}
              >
                <span className="form-text text-muted float-left">Minimum of 8 characters</span>
              </Field>
            </div>
          </div>
          <small>
            <Field
              label={
                <span className="font-weight-normal">
                  I agree with the{' '}
                  <Link target="_blank" to={ROUTE.TERMS.path()}>
                    {ROUTE.TERMS.title}
                  </Link>
                  *
                </span>
              }
              name="agreeToTerms"
              value="yes"
              component={Checkbox}
            />
          </small>
        </div>
        <div className="form-footer">
          <Button type="submit">Continue</Button>
        </div>
      </form>
    );
  }
}

OnboardingUT4CreateAccountFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingUser: PropTypes.object,
};

OnboardingUT4CreateAccountFormComponent.defaultProps = {onboardingUser: {}};

export const OnboardingUT4CreateAccountForm = reduxForm({
  form: 'onboarding-create-account',
  validate,
})(OnboardingUT4CreateAccountFormComponent);
