/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import {get, isEmpty, map, orderBy, sum, some, sumBy} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {matchPath} from 'react-router';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {compose} from 'recompose';

import {ROUTE, FORM_MESSAGE, COLOR} from 'app/constants';
import {APreventDefault, DistributionPanels, IssueMatrix} from 'app/components';
import {
  getPlanSelection,
  getStrategy,
  getDistributions,
  getSectorsByDistribution,
  getCompaniesByDistributionAndSector,
  getIssueMatrix,
} from 'app/redux/strategyDashboard';
import {checkDueDates} from 'app/utilities';
import faInfoCircle from 'app/fontawesome-pro-light/faInfoCircle';
import {openSimpleModal} from '../components/SimpleModal/redux';

const DASHBOARD_DISTRIBUTION_SECTORS = {datasets: [{}]};

/**
 * Setup the Due in message area
 * @param {Object} dueDateData - the object genenrated by utilities/dateFunctions/checkDueDates()
 * @returns {String}
 */
function generateDueDateMessage(dueDateData) {
  // if no object, no file uploaded, or uploaded on start day
  if (!dueDateData || !dueDateData.hasUpload || dueDateData.uploadedOnStart) {
    return null;
  }

  if (dueDateData.pastDueBy) return null;

  return dueDateData.dueInDisplay;
}

/**
 * Setup the Past due message area
 * @param {Object} dueDateData - the object genenrated by utilities/dateFunctions/checkDueDates()
 * @returns {String}
 */
function generatePastDueMessage(dueDateData) {
  // if no object, no file uploaded, or uploaded on start day
  if (!dueDateData || !dueDateData.hasUpload || dueDateData.uploadedOnStart) {
    return null;
  }

  if (!dueDateData.pastDueBy) return null;

  return `Past due by ${Math.abs(dueDateData.pastDueBy)} days`;
}

/**
 * Generates a proper message to show the percentage of companies held
 * are rated below the assigned rating threshold
 * @param {Array} distributionPcts - An array
 * @param {string} letter - The letter that represents threshold rating
 * @returns {string} The message to display
 */
function generatePctBelowThresholdMessage(distributionPcts, letter) {
  const mapPctToIndex = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
  };

  const pct = sum(distributionPcts.slice(mapPctToIndex[letter]));

  if (letter === 'E') {
    return `Holding ${pct}% of ${letter} rated companies`;
  }
  return `Holding ${pct}% of ${letter} rated companies or lower`;
}

class DashboardUserType extends Component {
  constructor(props) {
    super(props);

    const {strategyId} = props;

    // TODO: Change all of these to fetchIfNeeded
    props.dispatch(getPlanSelection(strategyId));
    props.dispatch(getStrategy(strategyId));
    props.dispatch(getDistributions(strategyId));
    props.dispatch(getIssueMatrix(props.strategyId));

    this.onDistributionClick = this.onDistributionClick.bind(this);
    this.onSectorClick = this.onSectorClick.bind(this);
    this.onCompanyClick = this.onCompanyClick.bind(this);
  }

  onDistributionClick(distribution) {
    // dispatch after animation is complete (0.3s transition on panels)
    setTimeout(() => {
      this.props.dispatch(getSectorsByDistribution(this.props.strategyId, distribution));
    }, 600);
  }

  onSectorClick(distribution, sectorId) {
    // dispatch after animation is complete (0.3s transition on panels)
    setTimeout(() => {
      this.props.dispatch(getCompaniesByDistributionAndSector(this.props.strategyId, distribution, sectorId));
    }, 600);
  }

  onCompanyClick(isin) {
    const {dispatch, strategyDashboard} = this.props;
    const strategy = get(strategyDashboard, 'strategy');

    if (strategy) {
      dispatch(push(ROUTE.STRATEGY_COMPANY_DETAIL.path(strategy.id, isin) + '?ref=strategyDashboard'));
    }
  }

  render() {
    const {isFetching, strategyDashboard} = this.props;

    if (isFetching) return null;

    let uploadDates;
    let dueDateMessage;
    let pastDueMessage;

    const planSponsor = get(strategyDashboard, 'planSelection.planSponsor');
    const strategy = get(strategyDashboard, 'strategy');
    const weightedAverageRating = get(strategy, 'weightedAverageRating');
    const strategyDistributions = get(strategyDashboard, 'distributions.strategyDistributions', []);
    const indexDistributions = get(strategyDashboard, 'distributions.indexDistributions', []);
    const currentDistributionSectors = get(strategyDashboard, 'currentDistributionSectors', []);
    const currentSectorCompanies = get(strategyDashboard, 'currentSectorCompanies', []);
    const issues = get(strategyDashboard, 'issues');
    const isIspSettingMaxPercentHoldingOfThresholdRating = get(
      planSponsor,
      'isIspSettingMaxPercentHoldingOfThresholdRating',
    );
    const ispThresholdRating = get(planSponsor, 'ispThresholdRating');

    if (strategy) {
      // Setup for due date information
      uploadDates = checkDueDates(strategy.lastHoldingsUploadAt, strategy.uploadFrequency);

      const dueDateObject = checkDueDates(strategy.lastHoldingsUploadAt, strategy.uploadFrequency);

      dueDateMessage = generateDueDateMessage(dueDateObject);
      pastDueMessage = generatePastDueMessage(dueDateObject);
    }

    const isIssueDataValid = !isEmpty(issues);

    const distributionData = [];
    const isDistributionDataValid = sumBy(strategyDistributions, 'percent') > 0;
    if (isDistributionDataValid) {
      // Format Distribution data for bar chart
      distributionData.push({
        label: 'My Strategy',
        data: map(orderBy(strategyDistributions, ['rating'], ['asc']), distribution => {
          return Math.round(distribution.percent, 0);
        }),
      });
    }

    const isIndexDistributionDataValid = sumBy(indexDistributions, 'percent') > 0;
    if (isIndexDistributionDataValid) {
      distributionData.push({
        label: 'Reference Index',
        data: map(orderBy(indexDistributions, ['rating'], ['asc']), distribution => {
          return Math.round(distribution.percent, 0);
        }),
        color: COLOR.LIGHT_GRAY,
      });
    }

    // Describe the percentage of rated companies held which are rated below the assigned rating threshold
    let belowThresholdDisplayText;
    if (isIspSettingMaxPercentHoldingOfThresholdRating && distributionData[0] && distributionData[0].data) {
      belowThresholdDisplayText = generatePctBelowThresholdMessage(distributionData[0].data, ispThresholdRating);
    }

    // Format Sectors-in-Distribution data for doughnut chart
    const sortedDistributionSectors = orderBy(
      currentDistributionSectors,
      ['totalMarketValue', 'name'],
      ['desc', 'asc'],
    );
    const distributionSectorsTotalMarketValue = sumBy(sortedDistributionSectors, 'totalMarketValue');
    const distributionSectors = DASHBOARD_DISTRIBUTION_SECTORS; // not cloning == chart transitions
    const sectorDataset = distributionSectors.datasets[0];
    if (distributionSectorsTotalMarketValue > 0) {
      distributionSectors.labels = map(sortedDistributionSectors, sector => sector.name);
      distributionSectors.sectorIds = map(sortedDistributionSectors, sector => sector.sectorId);
      let total = 0;
      sectorDataset.data = map(sortedDistributionSectors, sector => {
        const value = Number(((sector.totalMarketValue / distributionSectorsTotalMarketValue) * 100).toFixed(1));

        // Ensures that the total doesn't ever go past 100
        if (total + value > 100) {
          const final = Number((100 - total).toFixed(1));
          total += final;
          return final;
        }

        total += value;
        return value;
      });
      sectorDataset.label = distributionSectorsTotalMarketValue.toString();
    }

    // Format Companies-in-Sector data for list
    const sectorCompaniesTotalMarketValue = sumBy(currentSectorCompanies, 'totalMarketValue');
    let sectorCompanies = [];
    if (sectorCompaniesTotalMarketValue > 0) {
      sectorCompanies = map(currentSectorCompanies, company => {
        const holding = Number(((company.totalMarketValue / sectorCompaniesTotalMarketValue) * 100).toFixed(1));
        return {
          ...company,
          holding,
        };
      });
    }

    const policyViolations = get(strategy, 'policyViolation') || [];
    const isRequiredRatingInCompliance = !some(policyViolations, violation =>
      violation.toLowerCase().startsWith('minimum weighted average'),
    );
    const isMinPercentThresholdInCompliance = !some(policyViolations, violation =>
      violation.toLowerCase().startsWith('holds no more than'),
    );
    const isCompliant = !get(strategy, 'policyViolation');

    if (!isDistributionDataValid || !isIssueDataValid) {
      return (
        <div>
          {strategy && (
            <div className="p-content-lg">
              <div className="card is-white mx-auto" style={{maxWidth: 800}}>
                <h1>{strategy.name}</h1>
                <h3>{planSponsor.name}</h3>
                <p>
                  Welcome to Harmony Analytics we’re glad that you’ve joined the movement. After the first investment
                  manager has uploaded their portfolio your insights into ESG will begin to come alive.
                </p>
                <p>If you have any questions or need any assistance, please contact us.</p>
                <Link to={ROUTE.CONTACT.path()} className="btn btn-primary btn-lg align-self-start">
                  Contact Us
                </Link>
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        {planSponsor && strategy && (
          <div className="p-content-lg pb-4 flex-grow-1">
            <div className="portfolio-page-section">
              <div>
                <h1 className="mb-10">{planSponsor.name}</h1>
              </div>
              <div>
                <div className="d-flex mb-10">
                  <div className="mr-12">
                    <div className="">
                      <span className="portfolio-rating-medal-heading text-center">Portfolio Rating</span>
                    </div>
                    <span className={cn('rating-circle', `is-${weightedAverageRating}`)}>{weightedAverageRating}</span>
                  </div>
                  <div className="strategy-stats is-3-col is-sm-2-col flex-grow-1 align-items-center mt-10">
                    <div className="pb-0">
                      <div>
                        <strong>Product:</strong> {strategy.name}
                      </div>
                      <div>
                        <strong>Index:</strong> {get(strategy, 'marketIndex.name', 'N/A')}
                      </div>
                      <div>
                        <strong>Portfolio Value:</strong> {numeral(strategy.portfolioValue).format('($0.[0]a)')}
                      </div>
                      <div>
                        <strong>In Compliance:</strong>{' '}
                        <strong className={cn(`text-${isCompliant ? 'green' : 'red'}`)}>
                          {isCompliant ? (
                            'Yes'
                          ) : (
                            <Fragment>
                              No{' '}
                              <APreventDefault
                                onClick={() => this.props.onComplianceViolations(strategy.policyViolation)}
                              >
                                <Icon icon={faInfoCircle} /> more info
                              </APreventDefault>
                            </Fragment>
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="pb-0">
                      <div>
                        <strong>Last Upload:</strong>{' '}
                        {uploadDates.hasUpload
                          ? moment(uploadDates.lastUploadDate).format('MM/DD/YYYY')
                          : 'Not Uploaded Yet'}{' '}
                        {pastDueMessage && <span className="text-danger">({pastDueMessage})</span>}
                      </div>
                      <div>
                        <strong>Next Upload:</strong> {moment(uploadDates.nextUploadDate).format('MM/DD/YYYY')}{' '}
                        {dueDateMessage && <span>({dueDateMessage})</span>}
                      </div>
                      <div style={{color: COLOR.NAVY}}>{belowThresholdDisplayText}</div>
                    </div>
                  </div>
                </div>

                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Compliance Requirements</th>
                      <th width="200" className="text-center">
                        Requirements
                      </th>
                      <th width="300" className="text-center mr-6">
                        Yes/No
                      </th>
                      <th width="150">Resolution</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planSponsor.isIspSettingMinimumAverageRating ? (
                      <tr>
                        <td className="text-nowrap">Meet Required Portfolio Rating?</td>
                        <td
                          className={`text-center text-nowrap ${
                            isRequiredRatingInCompliance ? 'text-green' : 'text-red'
                          }`}
                        >
                          {planSponsor.ispHarmonyRating}
                        </td>
                        <td
                          className={`text-center text-nowrap ${
                            isRequiredRatingInCompliance ? 'text-green' : 'text-red'
                          }`}
                        >
                          {isRequiredRatingInCompliance ? 'Yes' : 'No'}
                        </td>
                        <td className="text-nowrap">
                          <Link to={ROUTE.STRATEGY_COMPLIANCE_MANAGER.path(strategy.id)}>Portfolio Center</Link>
                        </td>
                      </tr>
                    ) : null}
                    {planSponsor.isIspSettingMaxPercentHoldingOfThresholdRating ? (
                      <tr>
                        <td className="text-nowrap">Meet Minimum Percent Threshold?</td>
                        <td
                          className={`text-center text-nowrap ${
                            isMinPercentThresholdInCompliance ? 'text-green' : 'text-red'
                          }`}
                        >
                          {planSponsor.ispMaxPercentHolding}% {planSponsor.ispThresholdRating} rated
                        </td>
                        <td
                          className={`text-center text-nowrap ${
                            isMinPercentThresholdInCompliance ? 'text-green' : 'text-red'
                          }`}
                        >
                          {isMinPercentThresholdInCompliance ? 'Yes' : 'No'}
                        </td>
                        <td className="text-nowrap">
                          <Link to={ROUTE.STRATEGY_COMPLIANCE_MANAGER.path(strategy.id)}>Portfolio Center</Link>
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td className="text-nowrap">Portfolio Uploaded?</td>
                      <td className={`text-center text-nowrap ${!uploadDates.pastDueBy ? 'text-green' : 'text-red'}`}>
                        {strategy.uploadFrequency}
                      </td>
                      <td className={`text-center text-nowrap ${!uploadDates.pastDueBy ? 'text-green' : 'text-red'}`}>
                        {!uploadDates.pastDueBy ? 'Yes' : 'No'}
                      </td>
                      <td>
                        <Link to={ROUTE.UPLOAD_PORTFOLIO_STRATEGY.path(strategy.id)}>Upload Portfolio</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">Completed ESG Integration Profile?</td>
                      <td
                        className={`text-center text-nowrap ${
                          strategy.firm.hasCompletedESGIntegration ? 'text-green' : 'text-red'
                        }`}
                      >
                        100%
                      </td>
                      <td
                        className={`text-center text-nowrap ${
                          strategy.firm.hasCompletedESGIntegration ? 'text-green' : 'text-red'
                        }`}
                      >
                        {strategy.firm.hasCompletedESGIntegration ? 'Yes' : 'No'}
                      </td>
                      <td className="text-nowrap">
                        <Link to={ROUTE.SETTINGS_FIRM_PROFILE.path()}>Manager Profile</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {isDistributionDataValid && (
          <div className="background-divider is-lighter-gray">
            <div className="p-content-lg pb-0">
              <div className="portfolio-page-section">
                <DistributionPanels
                  distributionTitle="Distribution of Company Ratings for Strategy"
                  distributionData={distributionData}
                  sectorData={distributionSectors}
                  companyData={sectorCompanies}
                  sectorDataTimeStamp={strategyDashboard.currentDistributionSectorsTimeStamp}
                  companyDataTimeStamp={strategyDashboard.currentSectorCompaniesTimeStamp}
                  onDistributionClick={this.onDistributionClick}
                  onSectorClick={this.onSectorClick}
                  onCompanyClick={this.onCompanyClick}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        )}
        {isIssueDataValid && (
          <div className="p-content-lg">
            <div className="portfolio-page-section">
              <IssueMatrix issues={issues} title="Issue Rating" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 * Retrieves the strategyId param from the url assuming it is in the format /strategy/:strategyId
 * @param {String} pathname The location object's pathname value
 * @returns {Object} Contains a property for strategyId if found on the url, otherwise an empty object
 */
const getParams = pathname => {
  const matchStrategyDashboardPath = matchPath(pathname, {path: ROUTE.STRATEGY_DASHBOARD.path(':strategyId')});
  return (matchStrategyDashboardPath && matchStrategyDashboardPath.params) || {};
};

const mapStateToProps = state => {
  const locationData = get(state, 'router.location.pathname');
  if (!locationData) {
    return {};
  }

  const paramList = getParams(locationData);
  if (isEmpty(paramList) || !paramList.strategyId) {
    return {};
  }

  const isFetching =
    get(state, 'strategyDashboard.isFetching') ||
    get(state, 'strategyDashboard.isFetchingDistributions') ||
    get(state, 'strategyDashboard.isFetchingIssues');

  return {
    isFetching,
    strategyId: paramList.strategyId,
    strategyDashboard: state.strategyDashboard,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onComplianceViolations: violations =>
      dispatch(
        openSimpleModal({
          title: 'Compliance Violations',
          message: FORM_MESSAGE.COMPLIANCE_VIOLATIONS(violations),
        }),
      ),
    dispatch,
  };
};

export const StrategyDashboard = compose(connect(mapStateToProps, mapDispatchToProps))(DashboardUserType);
