export const REGEX_PATTERN = {
  DISALLOWED_PASSWORD_CHARACTERS: /[^0-9a-zA-Z^$*.[\]{}()?\-"\\!@#%&/,><':;|_~`\+]/,
  ALLOWED_PASSWORD_SYMBOLS: /[\^$*.[\]{}()?\-"\\!@#%&/,><':;|_~`]/,
  // From AWS IAM Docs:
  // Names of users [...] must be alphanumeric, including the following common characters:
  // plus (+), equal (=), comma (,), period (.), at (@), underscore (_), and hyphen (-).
  // http://docs.aws.amazon.com/IAM/latest/UserGuide/reference_iam-limits.html
  DISALLOWED_USERNAME_SYMBOLS: /([^A-Za-z0-9+=,.@_-]|\s)/,
  VALID_EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]{1}[A-Z0-9-]*$/i,
  VALID_ALPHA_ONLY_CHARACTERS: /^[A-Za-z]+$/,
  VALID_PHONE_NUMBER: /^[0-9\-()\+]+$/,
  VALID_ALPHANUMERIC_CHARACTERS: /^[a-zA-Z0-9]+$/,
  VALID_ALPHANUMERIC_SPACE_CHARACTERS: /^[a-zA-Z0-9 ]+$/,
};
