const getYValue = arrayOfValues => {
  const a = arrayOfValues.map(yValue => yValue.y);
  return a;
};

const calcMinMax = (actualDataPoints, benchPoints) => {
  const allPoints = actualDataPoints.concat(benchPoints);
  const yValues = getYValue(allPoints);
  const allPointsMax = Math.max(...yValues);
  const allPointsMin = Math.min(...yValues);

  return [allPointsMax, allPointsMin];
};

const calcPadding = (allPointsMin, allPointsMax) => {
  const delta = (allPointsMax - allPointsMin) / allPointsMax;
  const x = Math.log1p(allPointsMax - allPointsMin);
  const pad = delta * allPointsMax * 0.075;
  const buffer = (100 - x) * 0.02;

  return [pad, buffer];
};

export const getYLimits = (actualDataPoints, benchPoints) => {
  const [allPointsMax, allPointsMin] = calcMinMax(actualDataPoints, benchPoints);
  const [pad, buffer] = calcPadding(allPointsMin, allPointsMax);
  let lowerYLimits = allPointsMin - pad - buffer;

  if (lowerYLimits <= 0) {
    lowerYLimits = 0;
  }
  const upperYLimits = allPointsMax + pad + buffer;
  return [lowerYLimits, upperYLimits];
};
