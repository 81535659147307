import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

const Button = ({
  className,
  children,
  color,
  disabled,
  iconAfter,
  iconBefore,
  style,
  onClick,
  outline,
  size,
  solid,
  type,
}) => (
  <button
    type={type}
    className={cn(
      'btn',
      {'btn-outline': solid ? false : outline, [`btn-${size}`]: size, [`btn-${color}`]: color},
      className,
    )}
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    {iconBefore && <Icon icon={iconBefore} className="btn-icon btn-icon-before" />}
    {children}
    {iconAfter && <Icon icon={iconAfter} className="btn-icon btn-icon-after" />}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconAfter: PropTypes.object,
  iconBefore: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  size: PropTypes.string,
  solid: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

Button.defaultProps = {
  outline: true,
  type: 'button',
};

export {Button};
