import {createAction} from 'redux-actions';

const initialState = {};

const ACTION = {
  OPEN: 'genericModal/open',
  CLOSE: 'genericModal/close',
};

export const openGenericModal = createAction(ACTION.OPEN);
export const closeGenericModal = createAction(ACTION.CLOSE);

export const modal = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case ACTION.OPEN:
      return {...state, [payload]: true};
    case ACTION.CLOSE:
      return {...state, [payload]: false};
    default:
      return state;
  }
};
