import {get, indexOf, isNil, includes} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import {ROLE} from 'app/constants';
import {getCurrent} from 'app/redux/auth';

const mapStateToProps = state => {
  const isAdmin = includes(get(state, 'auth.roles'), ROLE.ADMIN);

  let returnState = {
    authUser: get(state, 'auth.impersonatedUser') || get(state, 'auth'),
    isFetching: get(state, 'auth.isFetching'),
    hasError: get(state, 'auth.hasError'),
  };

  if (isAdmin) {
    returnState.adminPermissions = get(state, 'adminPermissions');
  }

  return returnState;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  const {isFetching, hasError, authUser, impersonatedUser} = stateProps;

  const currentUser = impersonatedUser || authUser;

  if (!get(currentUser, 'id') && !isFetching && !hasError) {
    dispatch(getCurrent());
  }

  const isUserType1 = indexOf(get(currentUser, 'roles', []), ROLE.USER_TYPE_1) >= 0;
  const isUserType2 = indexOf(get(currentUser, 'roles', []), ROLE.USER_TYPE_2) >= 0;
  const isUserType3 = indexOf(get(currentUser, 'roles', []), ROLE.USER_TYPE_3) >= 0;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    isUserType1,
    isUserType2,
    isUserType3,
  };
};

export const withUser = compose(connect(mapStateToProps, null, mergeProps));
