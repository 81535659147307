export const TREND_TYPE = {
  GOOD: 'GOOD',
  NEUTRAL: 'NEUTRAL',
  BAD: 'BAD',
};

export const TREND_DIRECTION = {
  MAX: 'MAX',
  MIN: 'MIN',
};

export const TREND_TEXT_COLOR = {
  [TREND_TYPE.GOOD]: '#34B991',
  [TREND_TYPE.BAD]: '#E80000',
  [TREND_TYPE.NEUTRAL]: '#333a46',
};

export const TREND_TEXT = {
  [TREND_TYPE.GOOD]: 'Improvement',
  [TREND_TYPE.BAD]: 'Decline',
  [TREND_TYPE.NEUTRAL]: 'Change',
};

export const TREND_ARROW_COLOR = {
  [TREND_TYPE.GOOD]: '#34B991',
  [TREND_TYPE.BAD]: '#E80000',
  [TREND_TYPE.NEUTRAL]: '#333a46',
};

export const REPORT_TREND_COLOR = {
  [TREND_TYPE.GOOD]: '#34B991',
  [TREND_TYPE.BAD]: '#FD0000',
  [TREND_TYPE.NEUTRAL]: '#434343',
};
