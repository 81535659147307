import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {Link2, Rubrica} from '../atoms/Typeface';
import {ScrollToTopButton} from '../molecules/Button';
import {HarmonyLogo} from '../atoms/icons';
import {ROUTE} from 'v2/constants/routes';

const FooterWrapper = styled.footer`
  background: ${props => props.theme.colors.white};
  height: 4rem;
`;

//TODO: Get correct container sizes
const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 4rem;
  max-width: ${props => props.theme.constants.fixWidth};
`;

const FooterSpacer = styled.div`
  flex: 1;
`;

const Footnote = styled(Rubrica)`
  padding: 0 2rem 0 0;
  color: ${props => props.theme.colors.grey_4};
`;

const FooterLink = styled(Link2)`
  &&& {
    padding: 0 0 0 3rem;
    color: ${props => props.theme.colors.grey_4};
    &:onhover {
    }
  }
`;

const LogoContainer = styled.div``;

export const PageFooter = () => (
  <FooterWrapper>
    <FooterContainer>
      <LogoContainer>
        <HarmonyLogo />
      </LogoContainer>
      <div>
        <FooterLink as={Link} to={ROUTE.TERMS_OF_USE.path}>
          Terms of Use
        </FooterLink>
      </div>
      <div>
        <FooterLink as={Link} to={ROUTE.CHANGELOG.path}>
          {process.env.REACT_APP_VERSION || '0.1.0'}
        </FooterLink>
      </div>
      <FooterSpacer />
      <div>
        <Footnote>© 2022 Harmony Analytics™. All Rights Reserved</Footnote>
      </div>
      <div>
        <ScrollToTopButton />
      </div>
    </FooterContainer>
  </FooterWrapper>
);
