import {useEffect, useState} from 'react';

export const usePageDimensions = () => {
  const {innerWidth, innerHeight} = window;
  const [dimensions, setDimensions] = useState({
    width: innerWidth,
    height: innerHeight,
  });

  useEffect(() => {
    function updateDimension() {
      setDimensions({
        width: innerWidth,
        height: innerHeight,
      });
    }

    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [innerHeight, innerWidth]);

  return dimensions;
};
