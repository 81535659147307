import {MiddlewareAPI, Middleware, isRejectedWithValue} from '@reduxjs/toolkit';
import {FORM_MESSAGE} from 'app/constants';
import {toastError} from 'app/utilities/toast';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
  if (isRejectedWithValue(action)) {
    toastError(FORM_MESSAGE.DEFAULT_API_ERROR_MESSAGE);
  }

  return next(action);
};
