import styled from 'styled-components/macro';
import {format} from 'date-fns';
import {FlexContainer} from 'v2/components/atoms/Containers';
import {ParagraphS, SubtitleM} from 'v2/components/atoms/Typeface';
import {Download} from 'v2/components/atoms/icons/Download';
import {Theme} from 'v2/components/atoms/theme';
import {CONFIG} from 'app/constants';
import {urlWithToken} from 'v2/utilities/helpers/urlWithToken';
const EngagementHeader = styled(SubtitleM)`
  display: block;
  text-transform: uppercase;
  padding-bottom: 1.0625rem;
`;

const LetterSentHeader = styled(ParagraphS)`
  padding: 12px;
`;

const LetterDateHeader = styled(ParagraphS)`
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 150px;
  background: #f3f2f7;
  border-radius: 8px;
  margin: 0px 12px;
`;

const DownloadButton = styled.a`
  padding: 9px 12px;
  background: #f3f2f7;
  border-radius: 8px;
`;

export const CompanyEngagementHeader = ({
  latestEngagementLetter,
}: {
  latestEngagementLetter: {formattedDate: Date; filePath: string} | null;
}) => (
  <FlexContainer direction="row" justify="space-between">
    <FlexContainer flex="1">
      <EngagementHeader>Engagement</EngagementHeader>
    </FlexContainer>
    <FlexContainer flex="1" justify="flex-end" items="center">
      <LetterSentHeader>Letter Sent</LetterSentHeader>
      <LetterDateHeader>{latestEngagementLetter?.formattedDate}</LetterDateHeader>
      {latestEngagementLetter?.filePath && (
        <DownloadButton
          href={urlWithToken(`${CONFIG.API_URL}/s3/uploads/${latestEngagementLetter?.filePath}`)}
          target="_blank"
        >
          <Download color={Theme.colors.dark} size={20} />
        </DownloadButton>
      )}
    </FlexContainer>
  </FlexContainer>
);
