import React from 'react';
import {get, pick} from 'lodash';
import styled from 'styled-components';
import {animateScroll, scroller} from 'react-scroll';
import {Field, Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {push} from 'connected-react-router';
import {FieldArray} from 'react-final-form-arrays';
import {useDispatch} from 'react-redux';
// Local Imports
import {FormFeedback} from 'app/components';
import {AUTOCOMPLETE, CHARACTER_LIMIT, ROUTE, USER_PREFIXES} from 'app/constants';
import {errorRequiredFields, isImpersonating, validateFieldArray} from 'app/utilities';
import arrayMutators from 'final-form-arrays';
import AutoSave from './AutoSave';
import {HR, ParagraphS, TitleM} from 'v2/components/atoms/Typeface';

import {TextFormGroup} from 'v2/components/molecules/TextInput';
import {SelectFormGroup} from 'v2/components/molecules/DropDown';
import {SecondaryIconButton} from 'v2/components/molecules/Button';
import {
  ButtonContainer,
  FieldBlock,
  FieldContainer,
  Flex1,
  Flex3,
  FormGridRow,
  RowsWrapper,
  SectionTitle,
} from './FormStyles';
import {CardContainer} from 'v2/components/atoms/Containers';
import {useOnboardingUser} from 'v2/hooks/useOnboardingUser';
import {PhonesSection} from './FormSections/PhonesSection';
import {AddressesSection} from '../../../components/molecules/AddressesSection';
import {OnboardingFooter} from 'v2/components/organisms/OnboardingFooter';
import {logout, removeImpersonationTokensUser} from 'app/redux/auth';
import {Theme} from 'v2/components/atoms/theme';
import {handleUpdateForm} from './handleUpdateForm';
import {useUpdateUserInvitationMutation} from 'v2/redux/harmonyOnboardingApi';
import {ROUTE as V2_ROUTE} from 'v2/constants/routes';
import {SpinnerDisplay} from 'app/components/Spinner/SpinnerDisplay';

export type phoneType = {
  number: string;
  type: string;
};

export interface IProfileFormProps {
  address: {
    name: string;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
    type: string;
  };
  phones: phoneType[];
}

const SmallField = styled(Flex1)`
  min-width: 152px;
`;

const validate = (values: any) => {
  const errors: any = errorRequiredFields(values, ['firstName', 'lastName']);

  const phoneErrors =
    !errors.phones &&
    validateFieldArray(values?.phones, (phone: phoneType, ownErrors: any) => {
      if (phone.number?.length < 10) {
        ownErrors.number = 'Invalid phone number';
      }
      if (phone.number && !phone.type) {
        ownErrors.type = 'Required';
      }

      if (phone.type && !phone.number) {
        ownErrors.number = 'Required';
      }
    });

  phoneErrors && (errors.phones = phoneErrors);

  const addressErrors = validateFieldArray(
    values.addresses,
    (address: IProfileFormProps['address'], ownErrors: any) => {
      const hasAddress = !!(
        address.name ||
        address.line1 ||
        address.line2 ||
        address.city ||
        address.state ||
        address.zip
      );
      if (hasAddress && !address.type) {
        ownErrors.type = 'Required';
      }

      if (!!address.type) {
        !address.name && (ownErrors.name = 'Required');
        !address.line1 && (ownErrors.line1 = 'Required');
        !address.city && (ownErrors.city = 'Required');
        !address.state && (ownErrors.state = 'Required');
        !address.zip && (ownErrors.zip = 'Required');
      }
    },
  );

  addressErrors && (errors.addresses = addressErrors);

  return errors;
};

export const UT3OnboardingProfile = (props: any) => {
  const dispatch = useDispatch();
  const {onboardingUser, invitationCode, requestedStep, isLoading} = useOnboardingUser();

  const [autoSaveError, setAutoSaveError] = React.useState<string | null>();
  const [updateInvitationUser] = useUpdateUserInvitationMutation();

  const initialValues = () => {
    const user = get(onboardingUser, 'user', {});
    const values = pick(user, [
      'prefix',
      'firstName',
      'lastName',
      'suffix',
      'jobTitle',
      'jobRole',
      'phones',
      'addresses',
    ]);

    return values;
  };

  const onboardingStep = get(requestedStep, 'step', '');

  const handleSubmit = async (values: any) => {
    try {
      const nextRoute = V2_ROUTE.UT3.ONBOARDING.MANAGER_INFORMATION.path.replace(':invitationCode', invitationCode);
      const res = await handleUpdateForm({handleUpdate: updateInvitationUser, invitationCode, onboardingStep, values});
      if (!res?.[FORM_ERROR]) {
        animateScroll.scrollToTop();
        dispatch(push(nextRoute));
      }
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const handleExit = () => {
    return isImpersonating() ? dispatch(removeImpersonationTokensUser()) : dispatch(logout());
  };

  return (
    <Form
      initialValues={initialValues}
      mutators={{...arrayMutators}}
      onSubmit={handleSubmit}
      validate={validate}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        error,
        errors,
        invalid,
        submitFailed,
        submitError,
        form: {
          mutators: {push},
          submit,
        },
        values,
        dirty,
      }) => {
        // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
        const isErrorFormFeedbackVisible = !!error || !!autoSaveError || (!!submitFailed && invalid);
        return (
          <form
            method="POST"
            onSubmit={handleSubmit}
            style={{display: 'flex', gap: '1rem', flexDirection: 'column', flex: 1}}
          >
            <AutoSave
              debounce={1000}
              disabled={!dirty}
              onSave={async values => {
                const res = await handleUpdateForm({
                  handleUpdate: updateInvitationUser,
                  invitationCode,
                  onboardingStep,
                  values,
                });
                if (res?.[FORM_ERROR]) {
                  setAutoSaveError(res[FORM_ERROR]);
                } else {
                  setAutoSaveError(null);
                }
              }}
              values={values}
              errors={errors}
            />

            <CardContainer>
              <TitleM className="mb-4">{ROUTE.ONBOARDING_PROFILE.title}</TitleM>
              <RowsWrapper style={{marginBottom: '2rem'}}>
                <FieldBlock>
                  <ParagraphS style={{marginBottom: '3.25rem'}}>
                    Please complete as much information as possible. You can edit the information below and add more
                    information after your account is setup by going to the "Settings" portion of your account.
                  </ParagraphS>
                </FieldBlock>
                <HR style={{borderColor: Theme.colors.grey_3}} />
              </RowsWrapper>
              <FieldBlock>
                {(!!submitError || !!autoSaveError) && (
                  <FormFeedback
                    message={submitError || autoSaveError}
                    visible={isErrorFormFeedbackVisible}
                    children={undefined}
                  />
                )}
                <RowsWrapper>
                  <FormGridRow>
                    <SmallField>
                      <Field
                        label="Prefix"
                        name="prefix"
                        component={SelectFormGroup}
                        shouldInsertDefault={true}
                        options={USER_PREFIXES}
                        className="col-1"
                      />
                    </SmallField>
                    <Flex3>
                      <Field
                        label="First Name*"
                        name="firstName"
                        type="text"
                        autoComplete={AUTOCOMPLETE.FIRST_NAME}
                        component={TextFormGroup}
                        maxLength={CHARACTER_LIMIT.FIRST_NAME}
                        className="col-2"
                      />
                    </Flex3>
                    <Flex3>
                      <Field
                        label="Last Name*"
                        name="lastName"
                        type="text"
                        autoComplete={AUTOCOMPLETE.LAST_NAME}
                        component={TextFormGroup}
                        maxLength={CHARACTER_LIMIT.LAST_NAME}
                        className="col-2"
                      />
                    </Flex3>
                    <SmallField>
                      <Field
                        label="Suffix"
                        name="suffix"
                        type="text"
                        autoComplete={AUTOCOMPLETE.SUFFIX}
                        component={TextFormGroup}
                        placeholder={'Ex: "CFA"'}
                        maxLength={CHARACTER_LIMIT.NAME_SUFFIX}
                        className="col-1"
                      />
                    </SmallField>
                  </FormGridRow>
                  <FormGridRow>
                    <Flex1>
                      <Field
                        label="Title"
                        name="jobTitle"
                        type="text"
                        autoComplete={AUTOCOMPLETE.ORGANIZATION_TITLE}
                        component={TextFormGroup}
                        maxLength={CHARACTER_LIMIT.PROFILE_TITLE}
                        className="col-3"
                      />
                    </Flex1>
                    <Flex1>
                      <Field
                        label="Role"
                        name="jobRole"
                        type="text"
                        component={TextFormGroup}
                        maxLength={CHARACTER_LIMIT.PROFILE_ROLE}
                        className="col-3"
                      />
                    </Flex1>
                  </FormGridRow>
                </RowsWrapper>
              </FieldBlock>
            </CardContainer>
            <CardContainer>
              <FieldContainer>
                <FieldBlock>
                  <SectionTitle className="mt-12">Contact Information</SectionTitle>
                  <FieldArray name="phones" component={PhonesSection} />
                </FieldBlock>
                <ButtonContainer>
                  <SecondaryIconButton
                    disabled={!!errors?.phones || values?.phones?.length >= 4}
                    iconName="PlusSquare"
                    onClick={() => {
                      push('phones', undefined);
                    }}
                    type="button"
                    text="Add Another Phone"
                  />
                </ButtonContainer>
              </FieldContainer>
            </CardContainer>
            <CardContainer>
              <FieldContainer>
                <FieldBlock>
                  <SectionTitle className="mt-12">Address Information</SectionTitle>
                  <FieldArray name="addresses" component={AddressesSection} />
                </FieldBlock>
                <ButtonContainer>
                  <SecondaryIconButton
                    disabled={!!errors?.addresses || values?.addresses?.length >= 3}
                    iconName="PlusSquare"
                    onClick={() => {
                      push('addresses', undefined);
                      scroller.scrollTo('lastAddress', {
                        duration: 800,
                        delay: 100,
                        smooth: true,
                        offset: 0,
                      });
                    }}
                    text="Add Another Address"
                    type="button"
                  />
                </ButtonContainer>
              </FieldContainer>
            </CardContainer>
            <OnboardingFooter
              state="no-return"
              onSave={() => {
                submit()?.then(() => handleExit());
              }}
              onContinue={() => submit()}
            />
            <SpinnerDisplay isActive={isLoading} />
          </form>
        );
      }}
    </Form>
  );
};
