import React, {Component} from 'react';

import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {connect} from 'react-redux';
import {animateScroll} from 'react-scroll';
import {reset, SubmissionError} from 'redux-form';
import {forgotPassword} from 'app/redux/user';

import {ForgotPasswordForm} from 'app/components/ForgotPasswordForm';

const formName = 'forgot-password';

class ForgotPasswordPage extends Component {
  render() {
    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 440}}>
          <h1>{ROUTE.FORGOT_PASSWORD.title}</h1>
          <ForgotPasswordForm {...this.props} formName={formName} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      return dispatch(forgotPassword(values))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(reset(formName));
        })
        .catch(() => {
          animateScroll.scrollToTop();

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
    dispatch,
  };
};

export const ForgotPassword = connect(null, mapDispatchToProps)(ForgotPasswordPage);
