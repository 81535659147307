import {assignWith, map, pick} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';
import {Link} from 'react-router-dom';

// Local Imports
import {Button, FormFeedback, TooltipLink} from 'app/components';
import {Checkbox} from 'app/components/forms';
import {RATING_OPTIONS, ROUTE} from 'app/constants';
import {normalizeForcedPercentInteger} from 'app/utilities';
import {AccordionCard} from './AccordionCard';

const FORM_NAME = 'onboarding-esg';

const THRESHOLD_RATING_OPTIONS = [...RATING_OPTIONS];
THRESHOLD_RATING_OPTIONS.shift();

const validate = values => {
  const errors = {};

  if (values.isFocusEnvironmental) {
    if (!values.environmentalIssues || values.environmentalIssues.length === 0) {
      errors.environmentalIssues = 'If checked, at least one issue is required.';
    }
  }
  if (values.isFocusSocial) {
    if (!values.socialIssues || values.socialIssues.length === 0) {
      errors.socialIssues = 'If checked, at least one issue is required.';
    }
  }
  if (values.isFocusGovernance) {
    if (!values.governanceIssues || values.governanceIssues.length === 0) {
      errors.governanceIssues = 'If checked, at least one issue is required.';
    }
  }

  return errors;
};

class OnboardingESGFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleIspSettingChange = this.handleIspSettingChange.bind(this);
    this.handleIspNoSettingChange = this.handleIspNoSettingChange.bind(this);
  }

  handleIspSettingChange(event, isChecked) {
    if (isChecked) {
      this.props.changeFieldValue('isIspSettingNone', false);
    }
  }

  handleIspNoSettingChange(event, isChecked) {
    if (isChecked) {
      this.props.changeFieldValue('isIspSettingMinimumAverageRating', false);
      this.props.changeFieldValue('isIspSettingMaxPercentHoldingOfThresholdRating', false);
    }
  }

  render() {
    const {error, handleSubmit, invalid, onboardingPlanSponsor, submitFailed, isImaVisible, isReadOnly} = this.props;

    // Display error <FormFeedback> if there is a server error or client error but only as long as it is invalid
    const isErrorFormFeedbackVisible = !!this.props.error || (!!submitFailed && invalid);

    return (
      <form method="POST" onSubmit={handleSubmit}>
        {isErrorFormFeedbackVisible && <FormFeedback message={error} visible={isErrorFormFeedbackVisible} />}

        <h3 className="position-relative">
          ESG Focus
          <span className="label-hint">
            <TooltipLink id="planEsgFocusTooltipLink">
              Selecting your ESG Focus issues will help Harmony understand the ESG issues that matter most to you.
            </TooltipLink>
          </span>
        </h3>

        <h4>Environmental Issues</h4>
        <AccordionCard
          title={
            <Field
              label="Environment Issues"
              name="isFocusEnvironmentIssues"
              component={Checkbox}
              disabled={isReadOnly}
            />
          }
          column1={
            'Aims to quantify the direct impact an organization has on global environmental health. ' +
            'Organizations are currently rated on their resource consumption and contribution to climate change.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              Carbon Emissions
              <br />
              Energy Efficiency
              <br />
              Water Consumption
              <br />
            </React.Fragment>
          }
        />
        <AccordionCard
          title={
            <Field
              label="Environmental Policies"
              name="isFocusEnvironmentalPolicies"
              component={Checkbox}
              disabled={isReadOnly}
            />
          }
          column1={
            'Aims to evaluate an organization’s intention to operate sustainably. Organizations are currently ' +
            'rated on how well their internal environmental policies and programs align with sustainability goals.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              Climate Change Policy
              <br />
              Environmental Risk Management
              <br />
              Environmental Incentives
              <br />
            </React.Fragment>
          }
        />

        <h4>Social Issues</h4>
        <AccordionCard
          title={
            <Field
              label="Diversity & Inclusion"
              name="isFocusDiversityAndInclusion"
              component={Checkbox}
              disabled={isReadOnly}
            />
          }
          column1={
            'Aims to measure leadership diversity within an organization. Organizations are currently rated by ' +
            'the inclusion of talent from diversified backgrounds on their board of directors and executive and ' +
            'management teams.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              Gender Equality
              <br />
              Ethnic & Cultural Identity
              <br />
            </React.Fragment>
          }
        />
        <AccordionCard
          title={
            <Field
              label="Compensation Equity"
              name="isFocusCompensationEquity"
              component={Checkbox}
              disabled={isReadOnly}
            />
          }
          column1={
            'Aims to quantify how wealth generated by an organization is distributed to stakeholders. ' +
            'Organizations are currently rated on the proportion of compensation allocated to executives and ' +
            'employees.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              CEO Compensation Ratio
              <br />
            </React.Fragment>
          }
        />
        <AccordionCard
          title={
            <Field
              label="Workforce Well-Being"
              name="isFocusWorkforceWellBeing"
              component={Checkbox}
              disabled={isReadOnly}
            />
          }
          column1={
            'Aims to evaluate the employee protections defined by an organization. Organizations are currently ' +
            'rated on the quality and performance of their procedures and standards.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              Injury Frequency
              <br />
              Human Rights
              <br />
              Non-discrimination
              <br />
              Health & Safety
              <br />
              Child & Forced Labor
              <br />
            </React.Fragment>
          }
        />

        <h4>Governance Issues</h4>
        <AccordionCard
          title={
            <Field label="Board Governance" name="isFocusBoardGovernance" component={Checkbox} disabled={isReadOnly} />
          }
          column1={
            'Aims to evaluate the practices and policies that govern the corporate structure of an organization.' +
            ' Organizations are rated based on their decision-making independence and ability to mitigate potential' +
            ' conflicts of interest.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              Board Independence
              <br />
              Separation of Powers
              <br />
            </React.Fragment>
          }
        />
        <AccordionCard
          title={
            <Field label="Corporate Trust" name="isFocusCorporateTrust" component={Checkbox} disabled={isReadOnly} />
          }
          column1={
            'Aims to evaluate an organization’s oversight of its internal and external relationships. ' +
            'Organizations are rated on the security, transparency, and moral principles outlined in their policies.'
          }
          column2={
            <React.Fragment>
              <b>Issues</b>
              <br />
              Code of Conduct
              <br />
              Ethics & Anti-corruption
              <br />
              Data Privacy
              <br />
            </React.Fragment>
          }
        />

        <h3 className="mt-8 position-relative">
          Create Policy Targets
          <span className="label-hint">
            <TooltipLink id="planEsgPolicyTooltipLink">
              Setting your ESG Policy Target allow you to set the standard and guidelines you want your managers to
              follow. We notify you when your managers break these standards and become out of compliance.
            </TooltipLink>
          </span>
        </h3>

        <div className="form-inline mb-2 font-weight-medium  ISP-settings">
          <Field
            component={Checkbox}
            name="isIspSettingMinimumAverageRating"
            onChange={this.handleIspSettingChange}
            label=" Minimum weighted average Harmony rating of"
            disabled={isReadOnly}
          />
          <Field component="select" className="custom-select w-4r mx-2" name="ispHarmonyRating" disabled={isReadOnly}>
            {map(RATING_OPTIONS, (ratingOption, i) => (
              <option key={i} value={ratingOption}>
                {ratingOption}
              </option>
            ))}
          </Field>
        </div>

        <div className="form-inline mb-2 font-weight-medium  ISP-settings">
          <Field
            component={Checkbox}
            name="isIspSettingMaxPercentHoldingOfThresholdRating"
            onChange={this.handleIspSettingChange}
            label="Hold no more than"
            disabled={isReadOnly}
          />
          <label className="sr-only" htmlFor="ispMaxPercentHolding">
            Company rating threshold
          </label>
          <div className="input-group mx-2">
            <Field
              component="input"
              type="text"
              className="form-control w-4r text-center px-0"
              name="ispMaxPercentHolding"
              maxLength="3"
              normalize={normalizeForcedPercentInteger}
              disabled={isReadOnly}
            />
            <div className="input-group-append">
              <div className="input-group-text">%</div>
            </div>
          </div>
          of companies rated
          <label className="sr-only" htmlFor="ispThresholdRating">
            Minimum holding percent
          </label>
          <Field component="select" className="custom-select w-8r mx-2" name="ispThresholdRating" disabled={isReadOnly}>
            {map(THRESHOLD_RATING_OPTIONS, (ratingOption, i) => (
              <option key={i} value={ratingOption}>
                {ratingOption}
                {i < THRESHOLD_RATING_OPTIONS.length - 1 ? ' or lower' : ''}
              </option>
            ))}
          </Field>
        </div>

        <div className="form-inline mb-2">
          <Field
            component={Checkbox}
            name="isIspSettingNone"
            onChange={this.handleIspNoSettingChange}
            label="I do not want to implement a policy"
            disabled={isReadOnly}
          />
        </div>

        <h3 className="mt-8 position-relative">
          Empower Change
          <span className="label-hint">
            <TooltipLink id="empowerChangeTooltipLink">
              By joining empowering change, Harmony will engage with companies on your behalf to instigate ESG
              improvements and change.
            </TooltipLink>
          </span>
        </h3>
        <p>Join a coalition of the asset owners to drive influence on ESG issues that you care about.</p>
        <Field
          component={Checkbox}
          name="isSubscribedToEmpowerChange"
          label={`Include ${onboardingPlanSponsor.name} in the Coalition`}
          disabled={isReadOnly}
        />

        <div className="form-footer is-right mt-12">
          <Button type="submit" solid>
            Save &amp; Continue
          </Button>
        </div>

        {isImaVisible && (
          <div className="bg-white p-8 pt-4 mt-8">
            <h3 className="mb-2">Notify Investment Managers of Investment Manager Agreement Change</h3>
            <p>
              Based on your organization&rsquo;s ESG Focus, we recommend you contact your investment managers to update
              your IMA Policy to reflect your commitment to ethical investing.
            </p>
            <Link to={ROUTE.SETTINGS_ESG_DRAFT.path()} className="btn btn-outline">
              Copy Suggested IMA Amendment
            </Link>
          </div>
        )}
      </form>
    );
  }
}

OnboardingESGFormComponent.propTypes = {
  onboardingPlanSponsor: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isImaVisible: PropTypes.bool,
};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
  const ispHarmonyRating = selector(state, 'ispHarmonyRating');
  return {ispHarmonyRating};
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change(FORM_NAME, field, value));
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {onboardingPlanSponsor = {}} = ownProps;
  const defaultValues = {
    environmentalIssues: [],
    socialIssues: [],
    governanceIssues: [],
    ispHarmonyRating: 'C',
    ispMaxPercentHolding: 5,
    ispThresholdRating: RATING_OPTIONS[RATING_OPTIONS.length - 1],
  };
  // Pick which values will pre-populate the form.
  const userValues = pick(onboardingPlanSponsor, [
    'isFocusEnvironmental',
    'isFocusSocial',
    'isFocusGovernance',
    'ispHarmonyRating',
    'isIspSettingMinimumAverageRating',
    'isIspSettingMaxPercentHoldingOfThresholdRating',
    'ispMaxPercentHolding',
    'ispThresholdRating',
    'isIspSettingNone',
    'isSubscribedToEmpowerChange',
    'environmentalIssues',
    'socialIssues',
    'governanceIssues',
    'isFocusEnvironmentIssues',
    'isFocusEnvironmentalPolicies',
    'isFocusDiversityAndInclusion',
    'isFocusCompensationEquity',
    'isFocusWorkforceWellBeing',
    'isFocusBoardGovernance',
    'isFocusCorporateTrust',
  ]);

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    initialValues: assignWith({}, defaultValues, userValues, (objValue, srcValue) => srcValue || objValue),
  };
};

export const OnboardingESGForm = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: FORM_NAME,
    validate,
  }),
)(OnboardingESGFormComponent);
