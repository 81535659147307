import {find, get, isEmpty, map} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {animateScroll} from 'react-scroll/modules/index';
import {compose} from 'recompose';
import {SubmissionError} from 'redux-form';

// Local Imports
import {Alert, OnboardingUt3StrategiesForm, ProgressIndicator} from 'app/components';
import {FORM_MESSAGE, ROUTE} from 'app/constants';
import {fetchMarketIndexesIfNeeded} from 'app/redux/marketIndex';
import {updateStategiesByInvitationCode} from 'app/redux/onboardingUser';
import {withOnboardingUser} from 'app/utilities';
import {createStagingUpload} from 'app/redux/strategy';

class OnboardingUt3StrategiesPage extends Component {
  constructor(props) {
    super(props);

    props.dispatch(fetchMarketIndexesIfNeeded());
  }

  render() {
    // Ensure OnboardingAccountForm doesn't flash and doesn't display an invalid error while fetching
    if (this.props.isFetching) return null;

    return (
      <div className="p-content-lg">
        <div style={{maxWidth: 700}}>
          <ProgressIndicator
            steps={this.props.onboardingStepsArray}
            currentStep={this.props.requestedStep}
            nextAvailableStep={this.props.nextAvailableStep}
            className="pt-0 pb-12"
            style={{marginLeft: '-1.5rem'}}
          />
          {this.props.hasUnexpectedError ? (
            <Fragment>
              <h1>Strategies</h1>
              <Alert color="danger">{FORM_MESSAGE.ONBOARDING_UNEXPECTED_ERROR}</Alert>
            </Fragment>
          ) : (
            <Fragment>
              <h1>Strategies for {this.props.onboardingPlanSponsor.name}</h1>
              <p className="mb-3">
                Please complete as much information as possible. You can edit the information below and add more
                information after your account setup by going to the "Settings" portion of your account.
              </p>
              {this.props.marketIndexes && <OnboardingUt3StrategiesForm {...this.props} />}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  marketIndexes: get(state, 'marketIndex.all'),
  isCreatingStagingUpload: get(state, 'strategy.stagingUpload.isCreatingStagingUpload'),
  hasStagingUploadError: get(state, 'strategy.stagingUpload.hasStagingUploadError'),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {dispatch} = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    createStagingUpload: fileKey => dispatch(createStagingUpload(fileKey)),
    onSubmitFail: () => {
      animateScroll.scrollToTop();
    },
    onSubmit: values => {
      const {marketIndexes} = stateProps;
      const {invitationCode, onboardingSteps} = ownProps;

      const formValues = map(values.strategies, formValue => {
        const marketIndex = find(marketIndexes, {name: formValue.marketIndex});
        return {
          ...formValue,
          marketIndexId: isEmpty(marketIndex) ? null : marketIndex.id,
          fileKey: get(formValue, 'planHoldingsUpload[0].name'),
          isUploadingSkipped: !get(formValue, 'planHoldingsUpload[0].isNewFile'),
        };
      });
      const updateData = {
        strategies: formValues,
        onboardingStep: onboardingSteps.STRATEGIES.key,
      };

      return dispatch(updateStategiesByInvitationCode(invitationCode, updateData))
        .then(response => {
          if (response.hasError) {
            throw response.error;
          }

          animateScroll.scrollToTop();

          dispatch(push(ROUTE.ONBOARDING_UT3_INVITE.path(invitationCode)));
        })
        .catch(error => {
          animateScroll.scrollToTop();

          if (error.message.trim() === 'Invitation Code is not valid.') {
            throw new SubmissionError({_error: FORM_MESSAGE.INVITATION_CODE_INVALID_MESSAGE_ON_SUBMIT});
          }

          throw new SubmissionError({_error: FORM_MESSAGE.UNEXPECTED_ERROR_MESSAGE_ON_SUBMIT});
        });
    },
  };
};

export const OnboardingUt3Strategies = compose(
  withOnboardingUser,
  connect(mapStateToProps, null, mergeProps),
)(OnboardingUt3StrategiesPage);
